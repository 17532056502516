import { createRouter,createWebHistory } from 'vue-router'
import SignIn from '../components/SignIn.vue'
import SignUp from '../components/SignUp.vue'
import RequestForm from '../components/RequestForm.vue'
import { useAuthStore } from '@/store';

const routes = [
  { path: '/login', component: SignIn },
  { path: '/ask', component: RequestForm },
  { path: '/signup', component: SignUp },
]

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    linkActiveClass: 'active'
  });

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/signup'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();
    

    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        return '/login';
    } else if (!authRequired && auth.user){
        return '/ask';
    } else if (to.path== '/'){
        return '/ask';
    }
  });
export default router