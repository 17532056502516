import {defineStore} from 'pinia';

export const useThreadStore = defineStore({
  id: 'thread',
  state: () => ({
    threadId: '',
  }),

  actions: {
    setThreadId(newValue) {
      this.threadId = newValue;
    },
    resetThread() {
      this.threadId = '';
    },

  },
// plugins: [createPersistedStatePlugin('threadStore')],

});