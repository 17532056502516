<script setup>
import { RouterLink, RouterView } from 'vue-router';

import { useAuthStore } from '@/store';
import {useThreadStore} from "@/store/modules/thread";
import {logout} from "@/controllers";

const authStore = useAuthStore();
const threadStore = useThreadStore();

function resetSession() {
  threadStore.resetThread()
}
</script>

<template>
    <div class="app-container">
        <nav v-show="authStore.user" class="navbar navbar-expand navbar-dark bg-dark">
            <div class="navbar-nav">
                <div class="nav-item nav-link">Hello {{authStore.name}}</div>
                <RouterLink to="/ask" class="nav-item nav-link">Ask</RouterLink>
                <a @click="resetSession()" class="nav-item nav-link">New Thread</a>
                <a @click="logout()" class="nav-item nav-link">Logout</a>
            </div>
        </nav>
        <nav v-show="!authStore.user" class="navbar navbar-expand navbar-dark bg-dark">
            <div class="navbar-nav">
                <RouterLink to="/login" class="nav-item nav-link">Login</RouterLink>
                <RouterLink to="/signup" class="nav-item nav-link">Sign Up</RouterLink>
            </div>
        </nav>
        <div class="app_wrapper">
            <RouterView />
        </div>
    </div>
</template>

<style>

</style>
