import signup from './signup.js';
import signin from './signin.js';
import refresh, { validateToken } from './refreshToken';

export default {
    signup,
    signin,
    refresh,
    validateToken,
};
