//import axios from "axios";
import { defineStore } from 'pinia';
import {router} from "@/router";

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: '',
    data: '',
    token: '',
    name: '',
    returnUrl: null
  }),

  actions: {
    // async Register({dispatch}, user) {
    setUserToken(newValue){
        this.token = newValue;
    },
    setUser(newValue){
      this.user = newValue;
    },
    setData(newValue){
      this.data = newValue;
    },
    setName(firstname, lastname){
      this.name = firstname + ' ' + lastname;
    },


    async logout() {
      this.user = null;
      this.token = null;
      this.data = null;
      this.name = null;
      router.push('/login');
    },
  },
  persist: true,

});
