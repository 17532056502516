import CognitoItentityMethods from './methods/index.js';

const poolData = {
    UserPoolId: 'us-east-1_POK0dfX81',
    ClientId: 'sem4i9d4idqbtab7vlic529sd',
};

/**
 * @param {{UserPoolId, ClientId, Paranoia }}
 *  The @param Paranoia setting helps to prevent accidental deletion of user accounts or other critical settings.
 */

class CognitoIdentityService {
    constructor() {
        this.poolData = poolData;
    }

    async signup(body) {
        return await CognitoItentityMethods.signup(this.poolData, body);
    }

    async signin(body) {
        return await CognitoItentityMethods.signin(this.poolData, body);
    }

    async validateToken() {
        return await CognitoItentityMethods.validateToken();
    }
    async refreshToken(user, auth) {
        return await CognitoItentityMethods.refresh(this.poolData,user, auth);
    }
}

export default function Wrapper() {
    return new CognitoIdentityService();
}
