export const fileDict = {"  A Systematic Review and Meta-analysis..pdf": "E5BCC1F6-8981-4158-8C2B-B0873793FF97",
    " - Hissah Al Abdulsalam - 2019.pdf": "4C2217AA-AE3F-4E1D-BBB8-2B719DFA4E0D",
    " 1209 JNS board certification.pdf": "EE9A8523-B00A-4D13-B426-074E1BF46E0E",
    " Abstract Submission.pdf": "4CD3CBDA-29C2-4F6E-B4D0-A8E34F7E500A",
    " Carta MARCO DEL PONT .pdf": "286055F4-D25A-4B78-B2EB-5C8E0BDD7AEE",
    " Carta recomendación dr. Toledo .pdf": "FE4E65CE-7EDE-4016-866D-237023EA4D15",
    " CV .pdf": "3BB0EA86-BB95-47B7-B073-98B3BA9750F5",
    " CV English.pdf": "A0A3166F-3273-4F1A-A94F-34928C0B50C0",
    " FlexMed Program.pdf": "7E0B6EC1-2336-4E2E-AB08-49073ADC703C",
    " Untitled.pdf": "57A6B57F-8F8D-4D7E-B7B4-E5F8FDAAA3C6",
    " VERIFICATION LETTER -signed.pdf": "E2819DBE-BC83-4AB0-8E91-54F93DBC9C8A",
    "!!! CURRICULUM VITAE. ESPECIALISTA. ENGLISH.pdf": "245BD1D8-A5D4-4B62-AFCE-703620CEF486",
    "#1195 Mekonnen, Mahlet - Malignant Meningioma - Tuesday, September 12, 600-730 pm.pdf": "98C9FB46-D617-4667-8BCC-839E189DDD1E",
    "#141.pdf": "72A61B82-4367-4C94-BDE4-5B47770E0A1D",
    "-CV-Abdallah Groshi-2024 4.pdf": "013462A6-2347-41F8-A2EA-545E1939AEC7",
    "-perrymq-2018-03-09(2).pdf": "7B1F38CE-941D-4A35-9C70-6387C96C6EDC",
    "-Radwa Abbas PA-C Resume.pdf": "8DEA56E9-E32E-430B-8DB8-83009165EF49",
    "-readdywj-2014-08-28.pdf": "29173B39-C765-4C0A-8243-B84D633DAF54",
    "??? ??????? ?????????.pdf": "438CD71D-D4DF-4861-BD7E-B2BB7E3DEEEE",
    "???.pdf": "665345D0-C1A5-43F9-AC91-70FDC0A7B118",
    "???? ????? _signed.pdf": "B90BD31D-96FB-455D-B87B-28056DD9DCEA",
    "????.pdf": "7A4D06B9-F5F0-496F-B80A-7988C5B6BC9A",
    "????? ???? ????-??-?? (2)??????? .pdf": "2394BB10-4E11-4163-BB95-1D26928E4407",
    "????? ???? ????-??-?? 3 ??? .pdf": "6BC9BAC4-D3B6-41DD-AE18-094266AEFDE4",
    "????? ???? ????-??-??.pdf": "1F1D145A-0E28-48DA-B655-EC57DA48D606",
    "????? ????? ??? ??? 2021.pdf": "B53C852E-4EA1-41F0-8EC7-886D844B577E",
    "????? ????? ???????.pdf": "A8815DBF-AC1A-4549-92A8-C8662B7646A4",
    "????? ?????? ???.pdf": "F9FEB4BB-4358-4209-BC9D-0E0416379BE2",
    "????? ?????? 2020.pdf": "FB4BFB14-FE72-4EF7-91D4-D04AF1D1A52A",
    "????? ??????-1.pdf": "22465127-A13D-40A0-B6B7-9F5086AE6D02",
    "?????? ???? ??? ???????.pdf": "00C2C0A5-EE52-4D30-9A4F-2BF6428FB19D",
    "??????.pdf": "5530F303-CB59-4905-85B2-34F6D4A26C95",
    "??????? ????? 1.pdf": "6522387A-058B-4ADB-BC85-777778CD56AF",
    "??????? ?????? ??????.pdf": "A380089D-A3AE-426C-B40D-72DFA8E40398",
    "??????? ????????-1.pdf": "8132C4E5-24CD-4C6D-8C7E-FA841D07ED2C",
    "??????? 1.pdf": "D83FBA72-1674-4B64-8B31-BB6954727E23",
    "??????? certificate of membership.pdf": "C2FD05AA-3481-4AE6-9E53-98745166C320",
    "???????.pdf": "E3C43916-0B41-4974-9A32-484561FB6BB1",
    "???????? ????? (2714489) - Certificate.pdf": "DC007C24-D897-4959-86ED-D2B69E6D5D60",
    "????????.pdf": "91235DE5-B826-4CE6-A9FF-6637405193EC",
    "??????????.pdf": "87D13517-11AB-45D5-A7BE-13C401A0A1A5",
    "????????????.pdf": "91EB217D-A618-4191-B869-9C37EFE7196D",
    "????????????????.pdf": "C3EDCF38-F335-4A10-AED9-5DE3FA7786A1",
    "??????????????????.pdf": "C9023C6B-6543-4F02-9067-710B05FF2B2F",
    "??????????????????????????????.pdf": "B6D35D17-4411-4EF1-8EA2-4B77F357F774",
    "???????????20161025.pdf": "C542E922-8BA3-4EB7-87A8-6381DF1E1BDB",
    "???????120254.pdf": "843C388B-4CE4-436F-AEFC-8F66B17DDC8F",
    "???00541.pdf": "495EB8C1-8449-4921-B352-71CA982ADDDB",
    "??KNS?????_???.pdf": "7057A0E1-2421-4814-9973-84C07306904C",
    "?URRICULUM VITAE.pdf": "D2B20FF9-EABA-4C66-8929-F0E2D049B7A2",
    "[10920684 - Neurosurgical Focus] Introduction. Robotics in neurosurgery.pdf": "8665B0B8-40DB-4CBC-B201-0D6F778CB33F",
    "[3]Resume_MSC_Sep2013.pdf": "FC712F85-8ED5-4B55-B065-89669221638F",
    "[UPDATED] CV_Jeremiah_Hilkiah_Wijaya.pdf": "7C11986F-981F-460E-A195-A60883FFE6A5",
    "__CV_Nahed.pdf": "4A3B14BC-15A4-4852-8481-5E847768AB9B",
    "_0810145114_001.pdf": "BCD86CFB-68B3-4680-8AB3-9E1B90FC64E3",
    "~LWF0003.pdf": "AA7B7856-8CB9-41A6-95A7-E63EEFD0C285",
    "00 - Introduction - Dr Robert Heary.pdf": "7D800BD9-773A-460A-9598-FB7B54DC792B",
    "0000 Curriculum Vitae David Paulson.pdf": "AF14D298-C20D-4ED0-A2E9-088F5C930647",
    "00006123-200208000-00058.pdf": "1BC1BA62-B1C5-4CBD-A9A5-35B3AC61AF95",
    "00200571_José David Peña Cisneros_EST ENG-signed.pdf": "AA9BAF37-8DE3-4B2D-A1CE-61DE78DDBA7A",
    "005-Declaracao Dr[1].Jose Claudio Marinho da Nobrega.pdf": "142A774A-1734-4FAF-9C7B-4D7226339E21",
    "00Program.pdf": "CE977B56-67ED-449D-84A8-AF2A74D77A05",
    "00Round Robin August 2000.pdf": "9085C976-08E5-4071-8F1E-75076C80581B",
    "00Round Robin Letter, Oct. 2000.pdf": "F1F711AF-98C7-4624-9D4E-CF46A4806BB5",
    "00Round Robin, September 2000.pdf": "2530E815-956D-408F-B5E2-5157B42BEA60",
    "01 - Introduction and Methodology.pdf": "423E6BEC-1C77-442A-9CC9-2FA75ADEEC76",
    "01-TaskMES.pdf": "3FE473E4-68A1-49A4-98FE-125975734F8D",
    "01.pdf": "82A7AEEC-61B8-4A6B-85A3-5210B415AF4F",
    "01.str.0000441965.15164.d6.pdf": "050C74C3-B043-4902-8F5A-4D3A3620A5F8",
    "01.str.0000442009.06663.48.pdf": "1C393C54-BF8D-45F7-A014-4F4B57263AB5",
    "01042022_Mayur CV .pdf": "1F865F3F-D34B-480D-947A-41830FB6770A",
    "01312021_Mayur CV .pdf": "91AD092A-740B-403E-86F2-CCFE5E2EBB84",
    "01312021_SEO_CNS Neurosurgery.pdf": "7A40F01E-4812-4824-BC32-3AA4C87AE605",
    "01PresidentialAddress1.pdf": "E0FCD1B0-0315-4283-8B0F-D7F19AD3CC3C",
    "01PresidentialAddress2.pdf": "E9549949-3FA7-4F3A-B7B3-3212E0DCA4ED",
    "01Program.pdf": "AAF06CC1-ACB1-4722-A6B2-59F4637340EF",
    "01Round Robin, November 2001.pdf": "BDAA4D3F-C9A9-40EE-BE22-E54E702A3721",
    "01Round Robin, September 2001.pdf": "C469BFA4-EF24-4265-8E88-140D9EB65996",
    "02 - Assessment of Functional Outcome.pdf": "BE020154-5484-4E92-AE57-92F337ECF301",
    "02.28.2018_Anil Mahavadi - Letter of Good Standing (CNS).pdf": "814FDA86-1A86-439F-858D-7214407B09B2",
    "02PresidentialAddress.pdf": "E8BD2384-480C-47E0-87B6-D1B70A0A1828",
    "02Program.pdf": "F9BC1C3F-3974-412A-B533-6DCFC3C07803",
    "02Round Robin, July 2002.pdf": "2833493E-4596-465D-9BCF-5057FE8B0D28",
    "02Round Robin, October 2002.pdf": "87C0BA16-2AC8-4C50-B447-68C1126E7E00",
    "03 - Assessment of Economic Outcome.pdf": "6D074559-85E1-403B-8061-5ECBDAED6E09",
    "030921Verification of Training Form_Joseph Morrison-signed.pdf": "8788FDD5-CDE6-43B9-AF62-BDEE2F2176AC",
    "031196.pdf": "A195106E-291B-4E79-96D4-02F0FB0F9B72",
    "031526.pdf": "1106EF2F-DF83-4616-A881-E73E69944F89",
    "031536.pdf": "0A40D751-0719-4871-97AA-630780C90F8F",
    "031564.pdf": "C6DB3E1A-7E3F-4D69-883D-204BE65E71F6",
    "031886.pdf": "43FF556C-C1C8-4618-9139-7DFE106E05D3",
    "032113.pdf": "CD7B4583-C586-463B-802E-462A07C3213D",
    "032116_house_letter_to_lhhs_re_fy17_trauma_funding.pdf": "CCABED42-1D20-4581-9ABD-75475B094FB1",
    "032165.pdf": "D38D28CC-F033-42D0-B116-7AA19C410E8B",
    "032196.pdf": "68A5259F-C36A-4750-A0CF-132C35CF50DA",
    "032360_Ukachukwu_Impact of Regional Neurosurgical Training Programs on Specialist Neurosurgical Workforce Growth in Sub-Saharan Africa.pdf": "CDB274B9-D04A-4981-957E-8AFB64305775",
    "032509.pdf": "520BB7CC-8B68-4A85-9653-67A385CE4701",
    "032779_Hauser.pdf": "573E4384-6AED-4E4A-AD90-350AC893D315",
    "032860 Merkaj.pdf": "C66A2C35-4872-4165-9783-D739AAC5B2FF",
    "032903.pdf": "FADD0812-193F-4B46-8A9F-92C76B49431B",
    "033099-ParikshitJuvekar.pdf": "0F88675C-196E-4FB2-8434-30C591709B92",
    "0331_001 (1).pdf": "CE54021C-5936-4C74-BA8C-C8816D9E062B",
    "034837_Besley.pdf": "B3B54A49-E239-47BA-9D36-699E51B9FC60",
    "034954.pdf": "FE528332-A150-4BF1-B001-3FBF8F2EB25B",
    "034995_Bajaj.pdf": "21794537-48D5-45E1-9CCA-4D4AF04507CC",
    "035461 Mendlen.pdf": "8733D900-161F-414D-AE59-40B1188BCE0C",
    "036119_DocetaxelMeningiomas_MWYoungblood2.pdf": "F416B072-A95F-40AF-9AF1-E532EF462EA2",
    "036552.pdf": "A2FC654C-19FF-421C-89F3-168B80D5647A",
    "037021-Costa.pdf": "8F3C7856-7D14-4D5C-853F-9B9230877C0D",
    "037672 Lu.pdf": "8D17D115-290C-4045-AA75-A2F227FA660C",
    "03fullprogram.pdf": "AB964755-97F8-4933-A5E6-092924D6246C",
    "03PresidentialAddress1.pdf": "0CCB0D54-B5BF-4E63-9FD1-403957CA8860",
    "03PresidentialAddress2.pdf": "597EA358-51FE-48D9-A003-5BAA520ADDD2",
    "03Program.pdf": "C898C479-6AE7-44B1-B36B-276ACC394607",
    "03Round Robin Letter, Oct. 2003.pdf": "4A00638E-99C7-4329-94E4-E7EA163A5189",
    "03Round Robin, August 2003.pdf": "72A83DAA-808F-4DC1-BAE1-ED5A35FA4929",
    "03Round Robin, August 28, 2003.pdf": "BF089D85-2EE2-4E6F-9898-4D784DEDB6E8",
    "03Round Robin, September 2003.pdf": "70C69324-AB87-4CBD-9E0F-47136255A6CB",
    "04 - Radiographic Assessment of Fusion.pdf": "E8B355C4-FEBC-40D0-9467-A76DEBD089DA",
    "04 30  2015 CNS application.pdf": "EEAD274F-EF2A-43E6-8311-892BB84C3CB3",
    "04-DecodingWinsizes.pdf": "59432006-6111-4460-966B-FD9C747AA0A2",
    "04.26.2021_Tomoko Tanaka CV.pdf": "A08DF0E6-B780-4738-8817-C07E80F67BFE",
    "040615 CNS Medical Student Member Application - Dean Letter.pdf": "4E83F227-7AC9-42B9-8996-2E01CD405ECB",
    "04Joint Meeting-Taschenbergpalais Dresden, Oct. 5-8, 2004.pdf": "9120B6D0-9A7D-4CDD-AC8B-51C373889A0C",
    "04PPBK.pdf": "92BA3CB8-3D7B-46A8-B9CB-5EA9ABAF97E8",
    "04Program.pdf": "68B8AAD9-F98D-4ABC-B75E-952EBFC456BD",
    "04Round Robin.pdf": "A0719C07-22B8-4D48-BA43-592E8FC5B5BE",
    "05 - Corellation Between Radiographic and Functional Outcome.pdf": "3F89BFAB-680F-4926-A3FC-CFCD54ED3670",
    "05-27-22 Costello Meredith.pdf": "3AD19BDA-E891-482C-8020-B27B6F326C33",
    "05-PatientSpecificity.pdf": "EFB88816-B454-46CF-9BAE-01FC62E57228",
    "05.19.2020_Wyant Austin - Enrollment Verification.pdf": "7B9E98F0-ECAA-4035-9C36-683148F2B51F",
    "0516 certificate of membership.pdf": "DFDF4C2E-97EF-488D-A363-B98C7D5225B4",
    "052023.pdf": "04E9B0E0-123E-4A6D-B482-7B8500DB72D9",
    "05PPBK.pdf": "C45824D0-ADB7-45EC-B002-4A1675BC4FCD",
    "05PresAddr1.pdf": "0317281F-D62E-43EE-8F90-9B6F256D24A0",
    "05PresAddr2.pdf": "89861E17-6E41-42FC-97D7-C8638007B7BB",
    "05PresAddr3.pdf": "F47C9303-0089-4A93-956C-16365A7D446A",
    "05PresAddr4.pdf": "839252DE-1022-43E9-8B16-1ED5068B62C1",
    "05PresAddr5.pdf": "5E5E8244-E10E-48FC-9D3F-C332A43EC6F0",
    "05PresAddr6.pdf": "EB1816C4-C778-47C6-A4EE-BCE07AAE29E8",
    "05PresAddr7.pdf": "C4933A0A-FB01-4474-A600-4C88FD03A2A6",
    "05PresAddr8.pdf": "34AC20D5-7920-4538-A8A9-35ABBC70B596",
    "05PresAddr9.pdf": "54E0DCE8-F838-4F0A-BAAB-15AB5F9412D2",
    "05Program.pdf": "097F008C-9B4B-40CC-9E77-8292887EDF49",
    "05Round Robin.pdf": "37A737FD-DA3A-49DA-9481-8EE21CABFB6D",
    "06 - MRI and Discography for Patient Selection for Lumbar Fusion.pdf": "C6B874BF-2EA0-4D04-B6D4-629AC412BF38",
    "06_standing.pdf": "54D14DDF-89F6-46A9-9F34-0BDC3B31E665",
    "060611.pdf": "DE01AC0F-0B51-4B7B-B776-530C021C032D",
    "067-21_Catapano_7331_3.12.21.pdf": "C355F240-D3E7-4CC5-AB90-B0B8E04FA150",
    "06PPBK.pdf": "2FBC128E-46AF-447F-9CBD-65B641109FCD",
    "06Program.pdf": "B5319B57-03D1-4DC1-B277-99CA66E99A19",
    "06Round Robin.pdf": "E509B4CD-6E38-4DA7-A56B-78C9DF7045BF",
    "07 - Intractable Low-Back Pain Without Stenosis or Spondylolisthesis.pdf": "3CA269BA-5AA2-43F8-A552-993D13116E16",
    "07 Qualification Certificate  of Speciality and Technology.pdf": "932F0ECF-F1CD-4E88-AD40-9A01A41E3266",
    "07.20.2020-HHS-CMS-MA-Prior-Authorization-Letter.pdf": "FA58C2A2-40A3-4CE1-B041-566B52D259A4",
    "07.23.2019_Victoria Pinilla Escobar - Enrollment Verification.pdf": "85C9A5A5-9EFE-459F-882E-AB8C2223D1A1",
    "07_2022_Hatefi_Dustin_CV.pdf": "96CA21F9-1B61-470D-A7F6-415B9E73EE10",
    "071618_verma_risk_adjustment_sign_on_letter.pdf": "6278C1F5-1C2B-4F3B-803C-333A741C1196",
    "0728 Bera Bucshon Medicare Payment Reform Letter FINAL.pdf": "6944AE58-3FAE-40B1-B06F-9346C8F11D0E",
    "0754_0001.pdf": "0CB79B0A-1C2F-49F6-8F91-227341DC47CE",
    "0763_001.pdf": "B2DB05DA-F308-462B-9F4C-CA37F382F7E5",
    "07PPBK.pdf": "7DD7FB73-4EBE-4E2E-89EA-01DB4953DF77",
    "07PresAddr1.pdf": "D2E8F737-2F6B-4A58-85CF-F6A3F3B6A8F7",
    "07PresAddr2.pdf": "34CDEDC5-C35A-4278-B396-3B3FB4361E74",
    "07Program.pdf": "8C2F5200-895C-4773-A254-ACA1C52EE9C1",
    "07Round Robin.pdf": "47CBC36F-CEC9-4D29-9851-E2F6917F447D",
    "08 - Lumbar Fusion for Disc Herniation and Radiculopathy.pdf": "D7448244-E876-4A47-B53B-5AE4C14309D2",
    "08.24.2021_Jamie Clarke Enrollment Verification.pdf": "94B4037C-6327-421B-A7FC-A5FD69E600C1",
    "080319 Robertson CNS-Poster.pdf": "BACC5582-1D18-462A-9E48-822F77738771",
    "08Program.pdf": "554F5375-D36C-44FD-8611-80F5EF95E889",
    "09 - Fusion in Patients with Stenosis and Spondylolisthesis.pdf": "6B328610-52F8-4703-9DF0-0BC6CA434028",
    "09-07-22 Ramsay Ian.pdf": "2E3BA91E-3497-414B-8F44-EDC2E0B4482C",
    "09.20.2019_Anthony Diaz - Enrollment Verification.pdf": "54D8F253-B9D2-400C-801A-24F52937B602",
    "09.30.2020_Karen Eliahu - Enrollment Verification.pdf": "7F8C6300-EE57-4D76-A945-F750FFF84857",
    "09.30.2021__Henry Chang_Enrollment Verification.pdf": "43EBBA0A-E6C2-483B-BBD9-A2C0C02104B4",
    "09.30.22_CNS_LinFliegner_FINAL.pdf": "B1C92915-D16D-4731-A68E-7BEF5BC34EC0",
    "09Historical Synopsis of the 2009 Academy Annual Meeting.pdf": "66BD1A58-0C3F-45CD-8D34-CE3C693C3F1A",
    "09Program.pdf": "02F60B06-55E1-4289-BFDB-C51463B73DBB",
    "0adwLF4wK657.pdf": "C5AFFDFE-855F-41A6-AF92-8BBD4E917465",
    "1 CV_Yaghi.pdf": "43DF5C5E-ABB3-4075-9860-86A255F0C030",
    "1 of 2 Research trainee verifications from Mayo.pdf": "9C52FF27-0034-4DE4-9829-666A11BBF5FB",
    "1 opioid eval.pdf": "9D6D373D-CD02-4B97-9F5E-51176086808F",
    "1 Spine_4-21-2022F (1).pdf": "EF708DE2-11E3-4DDC-B22C-5D08ACC9D113",
    "1-CNS Fellowship Verification 07_2015.pdf": "06FD79E6-8E7E-41D2-B721-082A42735250",
    "1-Kitagawa-Trauma and CC.pdf": "1CD7581A-6D92-4842-BC3C-0492D45D533F",
    "1. 17.03.08 Las Vegas Agenda-Minutes.pdf": "EC981D3A-3651-4B42-B4F0-FB398BBFB2ED",
    "1. CV - Gregory Cannarsa MD.pdf": "EA4EF2F1-E97C-42C1-A4A8-3BF8EA683F18",
    "1. Letter of good standing.pdf": "8847D26E-E64F-42C7-91BD-55B3F8501A09",
    "1.Batjer.pdf": "5DCCF075-3F30-4907-A9D6-162E275641F5",
    "1.CNS Zo Poster.pdf": "2ED7D3F5-2287-49E7-96B4-5A076A20A858",
    "1.Dr_Chen_Xin_Full CV2023-1-8.pdf": "A2FFEB41-E125-41CB-B186-3FC2A46D8DB5",
    "1.Harbaugh.pdf": "86617EB5-F4A1-4384-8416-30D6FF3BF67E",
    "1.pdf": "37AD7CCF-5F55-4AA6-A47E-0740FC6DD691",
    "1_155066_chagoya_-_gazcon._gustavo.pdf": "3ACAAC49-5AC7-45E2-BD3E-F0235B626912",
    "1_1ucsfNeuroSurg2022bro_sk.pdf": "C121E6C3-4F48-4676-9D26-514A05132EFB",
    "1_47191_wf1015305-griffisjulie-gme-.pdf": "2863D36D-A6B0-4C5B-992D-2817C4269973",
    "1_59087_de_la_pena.pdf": "23937EA8-7222-4187-8965-EA5FE05D5856",
    "1_61396_rangel_2022.02.01_sparc_let.pdf": "04DB987F-AC65-4D7B-AD35-7C8E61620C92",
    "1_61403_stonnington__2022.02.01_spa (2).pdf": "9ADD1761-79FC-4E4F-AC00-42B52970A98D",
    "1_66569_hanna_2023.07.06_sparc 1.pdf": "C4EC96AA-F62B-4493-A36A-489020420004",
    "1_international_tuberculum_sellae_meningioma_study_.8.pdf": "F48BCE00-092E-4ECA-8476-D35B267F9687",
    "1_prisma.pdf": "79531B6C-6A2E-4536-A26C-43694CB8093A",
    "10 - Fusion following Decompression in Patients with Steosis without Spondylolisthesis.pdf": "CCBD3A3C-A0E2-4B79-93C8-CCBB7DF0BD24",
    "10-31-2023 Signed On Letter - Budget-neutrality.pdf": "584FBCB4-A888-4977-BD5E-8FB1097DC47F",
    "10-Hauptman-Pediatric_Optimizer.pdf": "FCFCC762-69E6-4594-9799-C70C67716E7C",
    "10.20.2020 Infection Prevention Tables.pdf": "97664933-D405-4CE3-95EB-CCDAD1BD45AA",
    "10.25.23_Giuseppe Lanzino_JLeonardo_SNS Nomination Letter.pdf": "B27D4B40-763B-427B-A817-3182FF1233C3",
    "10.30.23_AYu_DMW SNS Nomination Letter.pdf": "348C3D67-70B4-497A-80DE-6F95FD0A4869",
    "10.30.23_JLeonardo_DMW SNS Nomination Letter.pdf": "63A80997-765A-4A7D-A25E-9A9947341F23",
    "10_9.pdf": "F3297EAC-0C84-4961-9ED2-B32CCB86DFAA",
    "10_CV.pdf": "11DCF5EA-B315-4C68-B70D-2CD3CE270B65",
    "10003.pdf": "701F8EEE-86D3-4649-B694-B0DF6F64F04D",
    "10007.pdf": "EC683381-2DDC-4751-9150-E138800503D6",
    "10009.pdf": "4E181029-F05E-49E5-B1F0-09DF00890651",
    "10011.pdf": "95623128-25A4-4E6D-BDC0-CF148270F5D7",
    "10014.pdf": "F5098BAF-B2F0-4E5D-851A-B4DC649E9F27",
    "10015.pdf": "737CCF38-8AA3-401D-A503-12CAA7C0F8A1",
    "10016.pdf": "294A140E-0D5B-444F-B1FE-B5AF5BD01D4E",
    "10017.pdf": "92EB5426-9C4D-493D-B03E-7F661D9813A5",
    "10020.pdf": "4ECD5BD7-2F88-44DD-BF51-FE41E092DB5B",
    "10021.pdf": "27D3D982-FB28-4419-8150-CB58D3FC6FAE",
    "10022.pdf": "E6150828-4805-4A9D-8D9C-5E36CB7DC97C",
    "10023.pdf": "76DB471F-D158-45F0-AD46-6D24AE7DABDA",
    "10025.pdf": "A32B935F-384F-47AB-B83D-F315C945A78E",
    "10026.pdf": "34C774D8-27C3-428C-86B2-C6C16BBF71B5",
    "10027.pdf": "F06C2D15-E129-4AB7-8AE9-7BC9632FD098",
    "10028.pdf": "23270437-0AFE-4FD9-8DD3-0F66957DC059",
    "10029.pdf": "CAD03A60-0A96-44A7-9D79-03F5AEF3537C",
    "1003_NOTE.PDF": "A06D2F29-79E8-47FB-8D1D-0753EE9D9093",
    "10031.pdf": "6A998A18-BF62-45CA-B3FB-1C64B588444B",
    "10032.pdf": "766CC1C6-D2F6-4400-8789-62E1A69A6FC0",
    "10033.pdf": "92046E5A-4BDC-452F-A064-740D68B7A356",
    "10034.pdf": "3F628811-87BB-4AE4-B5FE-A78B7D2012BB",
    "10038.pdf": "6CA444B4-82C8-48CE-A518-F3288204569E",
    "1004.pdf": "D77F89C6-4837-45DD-A058-F47F8307BE76",
    "10042.pdf": "51D634AD-8028-4615-97FD-C29B98A35ABC",
    "10043.pdf": "DA52B5F9-B88C-45E7-8395-39272A939D63",
    "10044.pdf": "6A8C6ABF-CF14-4E15-9A67-202BA2E3C97C",
    "10045.pdf": "39F384E3-699F-49BA-A043-802ABCFE7540",
    "10046.pdf": "41019110-B637-482F-B686-28F4C2BCD228",
    "10048.pdf": "FAE5AC6C-C40B-41E8-A4EC-9FFBBACC686B",
    "10049.pdf": "E78BED15-2BED-465D-B9A4-6FA7408481F7",
    "10050.pdf": "298752DB-CE81-478B-A926-6AED287ED169",
    "10055.pdf": "ACD2AA2C-A6C7-4E31-9C0E-EE871FE0F370",
    "10058.pdf": "B5331FE8-F771-4D52-91AE-18CA75D0F949",
    "10059.pdf": "3E7651ED-11B4-4F07-9835-C3F43A9FDCC1",
    "10060.pdf": "929CD4E6-9CBF-4720-BCBF-16BB7EDAE380",
    "10061.pdf": "D02F072F-4E9C-4F02-A4FA-D85F756ADF29",
    "10062.pdf": "BFED8E2D-D32F-4AA4-A120-2E60FB5C982C",
    "10063.pdf": "36E2F67F-CCD4-4E56-BC51-BDE5DDBA213C",
    "10065.pdf": "1B748763-0EE3-4BE6-9B82-B5B9B12F4791",
    "10068.pdf": "C2521C77-F98F-4413-8CE0-3C2B3AF61946",
    "1007_NOTE.PDF": "AD1CF413-BD2C-477C-8BE5-B65C1B91349A",
    "10070.pdf": "2ED7864C-9A36-4C84-B3C8-92DFFC761DF6",
    "10074.pdf": "CE55F5D3-271C-4F38-84B9-AAC43D48326B",
    "10076.pdf": "072A8691-B074-4BFB-AE47-74C78B43F534",
    "10077.pdf": "783F1FAE-8AD4-4647-BE76-E92D6C296D66",
    "10081.pdf": "A2C4515A-8764-4EC3-9D85-5FA2E91E7456",
    "10082.pdf": "80E62B07-EA1A-4A01-A0DC-D32769CB77EA",
    "10083.pdf": "264D8CF7-76B2-4D49-97F7-7F23329864B0",
    "10084.pdf": "573137F6-8A35-4689-B99A-F23A707434D1",
    "10085.pdf": "B8EFB015-7E88-41F5-AA57-12A875EE5A56",
    "10089.pdf": "55DB5FAC-BCFB-49DA-AEE7-9022AD899CFD",
    "10090.pdf": "80537106-8EB5-4724-A695-E6A3E7C3CE63",
    "10091.pdf": "2120A3BA-33BC-485B-850B-A101A338FD51",
    "10092.pdf": "4410D100-A74B-4790-B949-579605F30C82",
    "10094.pdf": "9CA76E5D-6DAF-4AF6-A99E-732735A50260",
    "10095.pdf": "61C5850D-8C9C-43B1-9917-4927A6C55C46",
    "10096.pdf": "F1625EAE-4483-4A5B-9F3B-3101898C0AE3",
    "10098.pdf": "2C0C314B-5639-4509-97B7-CB09CBFF066F",
    "10099.pdf": "E96560B5-1636-4B59-8D90-1051F323B36F",
    "100Dr ???????? .pdf": "401153BE-D0EC-460D-9D46-B37ABBC7C1E2",
    "10104.pdf": "E23410F9-94CF-41FE-AA4C-F3E11C2CAD55",
    "10107.pdf": "EFBCCB23-7D01-499D-8040-7E8754B224F7",
    "10111.pdf": "A72F1F83-2A1F-4DBD-9376-6535A88890CA",
    "10113.pdf": "D5DE3DC0-1DC0-4858-90D1-B9DDA43EB6A7",
    "10115.pdf": "5EED5F83-5A9D-4E99-99C2-79CD51934B50",
    "10118.pdf": "416120F9-A1B3-41F0-A693-11AA75E1C908",
    "10119.pdf": "A94CD28E-FDE6-4B28-BC94-C6FA35660553",
    "10122.pdf": "9F5671A6-8CA3-45FF-8992-598657BDB9F7",
    "10125.pdf": "017B97E5-E8F4-477D-B1C5-B390B3EFF4ED",
    "10126.pdf": "DE298615-2A08-43C6-A6D2-BE752D3E5609",
    "10129.pdf": "5717F15A-5857-4720-8FED-B9BB27F5BFF2",
    "10130.pdf": "A55797C7-AA59-4BAA-B5AA-0E49D8D65FFF",
    "10132.pdf": "D9C1A2D5-7855-474C-B19E-AFF69D3BEDA4",
    "10133.pdf": "F2066BE1-FC80-44E7-93EB-EE75386C8F79",
    "10134.pdf": "EC273A3E-AA36-46CE-B114-D40EF273E986",
    "10135.pdf": "0A71ADBE-4D6A-4136-BF97-4FF8F309BF46",
    "10137.pdf": "D09EB24A-4CE1-4543-BA91-3900BBAAE6AC",
    "10138.pdf": "0B042675-22D9-49E0-842C-AA8E8BE2135B",
    "10144.pdf": "F2B112C8-4456-498A-840B-EFC5138CD661",
    "10145.pdf": "066C6357-9568-4934-842B-7F5B9DED29E9",
    "10146.pdf": "32D31F9A-79AE-4B8D-9C50-08D1F31BE0F3",
    "10147.pdf": "E0487B06-37D8-4F87-9430-152AF6A0FABB",
    "10148.pdf": "51F4FB10-067E-47FE-88F8-1F449EF1E9F7",
    "10151.pdf": "E285C454-1EDE-4646-90A2-C5B3735309D3",
    "10152.pdf": "14993B75-476E-4D88-8373-2F6759703D29",
    "10153.pdf": "B3D6ED5D-AEDC-408B-8558-C3FD6829F9E0",
    "10154.pdf": "74DAEB32-9EAF-416E-82AC-CFB7917CCC29",
    "10155.pdf": "29789D34-36E1-4CF5-BA22-D6A30DD2E59B",
    "10157.pdf": "81651E6F-AA25-4BFD-AE43-0D51B38B49B1",
    "1016_NOTE.PDF": "C136B37C-D6E1-47B0-BE04-B3A57CA3B42F",
    "10162.pdf": "D5BACBC8-D938-4C68-8B17-65D6C587C1FB",
    "10163.pdf": "223D70DD-CFCA-4781-AA24-1E6FDB59A406",
    "10164.pdf": "5914041F-0AA7-487B-99EE-70FA1F9EE63A",
    "10165.pdf": "8E1C77C2-75A7-4D8C-AEC1-F366FCF714C7",
    "10166.pdf": "4CEE81AC-C477-4B91-9E82-977453C7F7F0",
    "10168.pdf": "0F4E15C2-731A-4BE3-9DD5-84CDDC7B209E",
    "10169.pdf": "892EBA26-732C-4862-81A8-8026B8CC51C1",
    "10170.pdf": "BD9FF470-3329-47B7-B8B6-599814615598",
    "10171.pdf": "E69615BE-1093-4F46-B8F7-508AD5C16BD5",
    "10172.pdf": "F766905A-B9EB-43EF-AF62-C2FB2EF967BF",
    "10173.pdf": "1F4477A3-3D70-47B8-93F8-E574A3C5E6B1",
    "10174.pdf": "C009DEE8-E970-407D-B52C-B3F32E17A32B",
    "10175.pdf": "4E54122B-4997-4BD4-8A1B-4744B1853E4B",
    "10176.pdf": "02E4E92A-C8BA-47D8-802B-6C64E2EF17DF",
    "10177.pdf": "2FBD5988-E249-4F4A-A3F2-590F95615578",
    "10180.pdf": "83286EC5-76ED-456C-823C-80422B5FF0D5",
    "10184.pdf": "AE7D3D45-03E9-4851-91D0-4825BFE3F444",
    "10190.pdf": "36A5801D-03C9-4838-A9BA-40EEFA85079D",
    "10191.pdf": "8787D5BD-C3E4-4D70-A892-CB97126CD919",
    "10195.pdf": "364CEC72-BFE0-4B88-B96B-E66A75C2B32D",
    "10196.pdf": "DBC3570C-CAE4-4FBA-AD12-D0AD068A93BC",
    "1019888 R.S. Deniz.pdf": "5858B792-2798-4665-B600-1070CEF1EEE7",
    "10199.pdf": "FBDE8739-A54A-49FB-B4E9-75E484541BA4",
    "10203.pdf": "263FC44E-BB58-4BBB-8E6C-3C1BF5595EAE",
    "10208.pdf": "5CBCA3A9-8406-41C3-9539-12F2E00755B6",
    "10210.pdf": "C00C9892-273C-4FA9-B75A-5F3DA3FB7540",
    "10211.pdf": "46EE0D08-FDD8-4965-9099-E0045B1D4497",
    "10212.pdf": "BEC95BF8-A89F-4FB5-87BB-3541F64DA5B0",
    "10213.pdf": "CD930C0C-40F6-4D7B-8030-3C6C97D0A414",
    "10218.pdf": "813A1EAF-D696-49EF-B1E4-144B54F79F8C",
    "10222.pdf": "4C7B8F99-66C2-491C-BBC1-3E7B58B7C838",
    "1023 30645.pdf": "3062B1B8-72AA-4CF4-8E53-71355B58EEB0",
    "1023 Predictors of Complications Functional Outcome and Mortality in a Large Cohort Treated with Flow Diversion-1023.pdf": "610B6089-985C-479A-BE67-D0A3B3D4A0D3",
    "10234.pdf": "8FB510E3-AE79-4040-B641-7662A8626D1A",
    "10237.pdf": "92ADFBC9-3AAE-48EA-B645-AEFF9151475A",
    "10238.pdf": "4AF3C85C-F52B-4A4D-A44E-163B2E72C68E",
    "10242.pdf": "1A286E7A-E2BC-454F-9FBF-DE12E00B2F99",
    "10246.pdf": "9200521C-E527-4646-B8F4-C695C0CA8B1A",
    "10247.pdf": "D0364F25-1F37-44DA-B777-9C466EC08733",
    "10249.pdf": "4ABDE001-7C2F-491A-A4E0-4308D9C6D8D8",
    "10250.pdf": "EE744136-217B-43BB-87A3-5DD97CCAA350",
    "10252.pdf": "24854532-5D1F-4897-B1F6-FE0472841FD8",
    "10254.pdf": "286E2B0C-B725-4F23-A8D4-603A6477308A",
    "10255.pdf": "F557E40C-ED4E-4483-830D-3A23C06A234F",
    "10258.pdf": "D593508C-0062-489A-82D3-742473C47D88",
    "10259.pdf": "1E417638-95D0-4BCD-8117-E3C45385B94A",
    "10269.pdf": "457F8104-2F49-40C0-9B7C-966942AE49C4",
    "10270.pdf": "161A4422-2B5D-4A2F-BF73-F22EC5FE0CC1",
    "10271.pdf": "CD3ABDDC-495B-46E1-9924-B3510EF478C1",
    "10272.pdf": "9E798430-91DA-42F0-8396-36E117F66A1B",
    "10276.pdf": "06CF6132-9BF7-47EC-9869-40ECA3BF3487",
    "10279.pdf": "277C1D86-831B-48B4-97B3-45D757CB4DA0",
    "10281.pdf": "5960339E-A5FE-4B29-B461-C5BF8C9A71ED",
    "10283.pdf": "FBE5C982-0C95-4ECD-A52F-E6BFD7E45E76",
    "10286.pdf": "FDEF715B-BA08-4430-8F13-1611732FDA92",
    "10287.pdf": "2A91E553-BDAD-4FD2-ACDA-839E06A538F8",
    "10292.pdf": "7FFE5242-457B-4804-8CCA-25420A97EB9E",
    "10294.pdf": "CC84C256-F628-44BD-AEDD-D6EFD21B3692",
    "10295.pdf": "BCFBA0D8-A1BE-4FF1-87E8-8AAB2C44BE55",
    "10298.pdf": "CF86D89F-09AC-45C4-86FF-691C45F975FC",
    "10300.pdf": "358823CD-6E45-46E2-8BEE-5259B0BC520D",
    "10302.pdf": "904EB10E-131A-469B-9402-CA184759C6F3",
    "103023MedMal2023_Peds_DigitalPosterTemplate.pdf": "66323C37-D62B-4F26-B28C-6BB458DFEC2B",
    "10303.pdf": "93694418-FEF4-433B-871C-E82D8D1C6AAC",
    "10304.pdf": "552D467A-5DE6-4DE4-851F-8C543110D990",
    "10306.pdf": "FC8FE354-2CC4-4B99-B4CB-61086D36A42E",
    "10307.pdf": "7365476B-8D63-439E-A56C-EFB876FFE0C4",
    "10314.pdf": "A45D5C62-4560-4522-9D35-D680A4C667EE",
    "10316.pdf": "94CEC235-D787-4AA0-9A67-982FADAFE839",
    "10317.pdf": "59339427-262F-4DAC-9859-F814F2D976D6",
    "10318.pdf": "34C9BC36-350F-4231-83D5-7BDCA507C502",
    "10319.pdf": "636C3E1B-22A4-4A4F-9D59-1DCEA4D8C89D",
    "10320.pdf": "BA02B6D9-89BE-4FC7-8B4D-5DA3242A1386",
    "10321.pdf": "2F1A226C-8ABA-45F1-923F-8766DD709E71",
    "10322.pdf": "17FC73EA-335F-4860-ACFE-F33C577C5AC3",
    "10323.pdf": "71FC148C-ABBE-45A0-9BC0-EDE14D5143F3",
    "10324.pdf": "915CD560-9F07-4CC3-B147-9CCA44C24826",
    "10325.pdf": "C7C63B6B-0D8A-454E-9BE5-975FBADC9D60",
    "10327.pdf": "3D541D89-B641-4790-9BB7-B3E98D52FC7C",
    "10328.pdf": "71741348-066C-4536-A2E6-14D90E5F920E",
    "10329.pdf": "C777F313-A578-48F2-BBF9-F5C5975109EC",
    "10330.pdf": "A2184EA5-D2AA-413E-B262-2348E7941A09",
    "10331.pdf": "C767F654-202D-4D57-9D22-0B9073DE5ADE",
    "10333.pdf": "ACC803A0-B076-4D4F-AAC5-B202749E2595",
    "10334.pdf": "1186D005-438A-49A6-987B-18876EEDB755",
    "10335.pdf": "DBF2C910-D062-40BB-856A-7C139A138EE4",
    "10337.pdf": "441340AB-C842-45FA-9FF1-011ED428F350",
    "10338.pdf": "409E6779-0AE6-4A62-BDE1-94881755B218",
    "10340.pdf": "0A01477E-9F78-47A6-8896-6F5D086D6CAE",
    "10341.pdf": "C1411D02-6234-4E03-900E-4C010D293AE3",
    "10342.pdf": "EE605AB9-117E-4000-828C-1F5B4F872CC0",
    "10344.pdf": "5CDD4D7B-9640-47E1-813B-1AB7A29BEA36",
    "10347.pdf": "FE338CCA-8AA3-4F35-97C7-74AFFE8FA75D",
    "10349.pdf": "5FDD2FA9-1175-40BF-9FDB-3FF73BBEC0AB",
    "10350.pdf": "4C1B328D-4072-4628-A06C-ED95E18EFA4C",
    "10354.pdf": "13931563-7E33-4E5E-ACA8-7BCBA02814A3",
    "10355.pdf": "EED1E792-CFFE-4782-A5F7-A46D84A3F5FA",
    "10356.pdf": "8C85092B-8FB0-4071-AF60-D42CB59BA405",
    "10357.pdf": "A9273C79-C988-46F2-909E-5D7B43E97ABF",
    "10359.pdf": "F3B812D8-4CBD-4402-B5D0-672D0DBC331B",
    "1036 031476.pdf": "EC813623-3299-4217-8A4F-61464580174C",
    "10360.pdf": "3A61D5AD-5945-437C-A764-5676A789F7B2",
    "10361.pdf": "9AEBDD0D-B3E8-4742-8071-A38DDD922461",
    "10362.pdf": "282A69F2-C654-4AD0-9EE0-39A54C61FDEF",
    "10363.pdf": "D20F257F-56B1-41FF-B6A3-6B95A0FD147E",
    "10364.pdf": "4B1614F6-FAE3-4EDA-A5C9-3364789CFED6",
    "10366.pdf": "535711C8-BA30-41BD-8A4C-E863EC4423AA",
    "10367.pdf": "B418CC9C-27F4-4E69-86F8-9E17F1E17A6F",
    "10369.pdf": "F98A7EED-6A6F-4C81-9994-B9EF42203BDB",
    "10370.pdf": "24509432-4B83-4B5E-96CA-B3542A87A9B2",
    "10373.pdf": "B183EBA2-7501-42F2-A025-B4979908BC84",
    "10377.pdf": "67B34A94-9470-48F3-BA89-B0D1121317CE",
    "10380.pdf": "39F7CB27-6D84-4DFC-9FD9-0A85E8041656",
    "10381.pdf": "9660E6E0-C8A5-47F1-BB0C-D6DD2CCACDA7",
    "10382.pdf": "3102F610-893C-4C4F-B21A-EE2B64F5E9B1",
    "10383.pdf": "23D62A68-37AE-46F5-9AD4-2004043D337A",
    "10384.pdf": "7D888F00-DA02-4B89-B2C3-7D7BEBE29FA9",
    "10386.pdf": "8363C3B1-78A7-48FD-A7BD-5E29DCC12A99",
    "10387.pdf": "F7A44A09-D32B-44AD-B65F-24AF6FF10FDC",
    "10388.pdf": "3DE9E765-D8C0-4747-B53D-ADC22CD2A631",
    "10389.pdf": "F27FEFAB-4515-4393-BF7B-E6594D227740",
    "10390.pdf": "D40FF0FC-DA55-4972-9DB6-9B0EECB58D5D",
    "10395.pdf": "DCC29526-1496-4AE7-9C0B-E5457562F93E",
    "10396.pdf": "E23D047B-56EF-4CF2-BF22-F3A1569C4F8B",
    "10398.pdf": "AA4B3C12-BAFA-4A8C-B08B-02BBDA860CF1",
    "10401.pdf": "89F3AA86-5AC7-4956-99CD-0A1A6E5204DA",
    "10408.pdf": "061BA4EB-1CE0-4262-A640-5036C1F9B819",
    "10410.pdf": "AC1B3B86-AC11-4AD1-B0CB-CB8E5558676C",
    "10418.pdf": "1E6D09A0-6D31-4133-92A3-1F015ACD78E9",
    "10419.pdf": "266214B1-9933-47B3-9574-0AE58A614D85",
    "10421.pdf": "CF729189-4E3A-4F31-8A05-17990889CD54",
    "10422.pdf": "B431F170-8505-46CE-B9F1-4F8B5D21BB73",
    "10423.pdf": "2EEA38AC-47BB-4D7B-B16B-B4B25F5A0FFB",
    "10425.pdf": "2B0A7B28-2D2D-4773-A850-223D278E8597",
    "10427.pdf": "8087722B-4D93-4DFE-A3AB-9B82BFDA5652",
    "10429.pdf": "DB54D519-EEA2-4D41-8D11-DB730904CA98",
    "1043 31554.pdf": "91AB5A7A-63E4-4366-ABBA-3E4F60237007",
    "10430.pdf": "E7927F13-8836-47A3-A12A-F80875DE3F2B",
    "10431.pdf": "D54E18B7-2F75-4EEA-8421-CF54950B7713",
    "10433.pdf": "1EA00C3E-5E17-4AA3-9E7F-7EA36DA96886",
    "10434.pdf": "032998C7-4F1E-43CA-9468-639B59D173C6",
    "10435.pdf": "BFD142CC-207B-48CE-91E4-DE55ECEE430F",
    "10437.pdf": "2BB94E60-C4CC-42DC-8924-8E23471F63A7",
    "10439.pdf": "68E62398-99FB-42A0-A69E-5102384674AB",
    "1044_NOTE.PDF": "D53A000D-5253-430B-A1C3-38F3C7DC43BC",
    "10440.pdf": "B86E7288-E118-4445-B12E-8497D978E5F7",
    "10441.pdf": "C47A4E5F-C297-45EC-9E6B-93B2724B200C",
    "10442.pdf": "3E8625ED-E3A5-4A07-9CCF-9FECF987703B",
    "10443.pdf": "9B0F538D-7AB7-48C8-ADBF-321D11D8BC6F",
    "10444.pdf": "40DC9AE2-42ED-445E-84AA-27D7260D3A00",
    "10445.pdf": "931FA816-C607-4732-89FD-06C846FEC41A",
    "10446.pdf": "E82825A8-69DA-4E3A-ACF4-70ED4D51C6E8",
    "10447.pdf": "F5658258-1206-4131-84D9-7F666848C5AF",
    "10449.pdf": "87B898DE-F9CC-468C-9622-1B7F297BD5FB",
    "10452.pdf": "D71D9250-D991-455A-8B02-55D363B85266",
    "10453.pdf": "714495B8-0BD8-46E7-AED8-F2FF345B3AF6",
    "10454.pdf": "6B320A8F-222C-495B-8516-1CBF4D55C368",
    "10455.pdf": "3B3B527A-C1BE-4F1E-830D-5EF31BBF23C0",
    "10457.pdf": "DFA83D56-2B7A-4E8D-B2FC-635CA4093636",
    "10461.pdf": "2AAF01AF-02AB-4BCC-BFB1-45E4FD89DED2",
    "10464.pdf": "88E5B2D4-04B7-48CB-A59B-6D7682DA01B4",
    "10465.pdf": "5FF9A402-8D68-4FC0-BFB1-7924B9FB5083",
    "10466.pdf": "01D13CCC-E56D-465A-A0DF-89289D9AD290",
    "10469.pdf": "B9735C2A-B297-4317-8699-05FDBA25DC7D",
    "10472.pdf": "8A94C029-D7F4-494D-9E4A-FDDCDC352695",
    "10480.pdf": "3521DB9C-00AB-4622-99D1-8B05707C1DC0",
    "10485.pdf": "572962BF-F848-4F49-8C12-A12AB9EAF726",
    "10487.pdf": "FDECDBCC-859E-4858-B686-7FD7A7AC0316",
    "10488.pdf": "9BEBC621-3551-4D8A-BC72-CB913A87B1F9",
    "10489.pdf": "449E2911-62A3-4581-BB06-7032DCE5F402",
    "10490.pdf": "021666D9-50E1-4045-953F-1C1ED1B9744D",
    "10491.pdf": "C35813F2-C272-47A4-83C8-22125298F26C",
    "10494.pdf": "10BCFBC9-D0EC-483F-A468-3F81E7FD0B64",
    "10498.pdf": "5D999397-36CF-4417-BED5-23F51A3ADC13",
    "10499.pdf": "3772016F-C14C-47BA-B90D-4A4CE7E84C2F",
    "105-21_Srinivasan_7352_3.15.21_R1.pdf": "0FF471BB-21DD-4C5E-BB91-77C9BCD81566",
    "10500.pdf": "BA951802-A6F4-41E0-BAF5-74B9A608F8B4",
    "10503.pdf": "3286868B-FCF1-4216-8132-C181EB9C6D13",
    "10504.pdf": "2F12F138-E955-4B3A-B8D3-F8134639D47C",
    "10505.pdf": "3A874325-32D8-4FC9-AB8A-E3C8269DD7BC",
    "10508.pdf": "54730C32-C0CC-496E-A36D-67130A00F974",
    "10510.pdf": "D6B280D0-9701-46A5-82D3-85AFE9949171",
    "10512.pdf": "957D3B94-3991-41A4-AAE1-BE88C052E182",
    "10513.pdf": "2CC7E7C8-536D-49AC-9DD3-D97409F6E41B",
    "10514.pdf": "B7CA2756-CFA4-47E6-BA81-BDE684E02789",
    "10515.pdf": "D1969CF8-9B66-434A-A631-D814D60B0E4D",
    "10516.pdf": "A61E811B-4381-4CD3-B12D-733F098B0040",
    "10517.pdf": "E3D53959-C5B3-4C80-8A31-70123764C5BE",
    "10522.pdf": "F3207E02-4593-4E06-8C65-9081A83443C4",
    "10524.pdf": "EC67EEFA-4C25-481C-896A-08BF7E23853A",
    "1053_BANQUET.PDF": "BADF1949-3234-4E74-8CAC-3A1C13832210",
    "10532.pdf": "47AAADBF-DA65-4026-92C2-84F4B725A32D",
    "10536.pdf": "1F7E9307-AEDC-4558-8E3B-2C20A4117DC4",
    "10538.pdf": "591EFB26-76C8-4853-83B2-2E8DCDEEB58B",
    "10539.pdf": "2CC5E501-2E9F-4CDF-9813-DBA0B7666276",
    "10541.pdf": "88F634B5-37C1-4548-BE1D-52F3ED474A8E",
    "10544.pdf": "90A9049C-1456-44E9-BA52-2142864D37DA",
    "10546.pdf": "8B57E772-6A5B-474B-9516-5C09413CDCD3",
    "10547.pdf": "8EABE33C-4767-4529-A375-838B82A6CE6F",
    "1056 31769.pdf": "5E8AE19C-E091-4F18-9FC7-2981479A83BE",
    "1056_DINNER.PDF": "EE9857A7-8E4B-4CB0-8058-5CDD6BDBB601",
    "10565.pdf": "10C77669-82F4-48B5-9D13-635C77EE37AC",
    "10567.pdf": "3319F0F1-3C50-4A1D-B3BF-E2738DCA173B",
    "10568.pdf": "D20E8F36-17FA-4CB1-B325-AE1B8C4A151A",
    "10569.pdf": "246C49BF-3516-4AF3-AF3A-25D573B2C9AA",
    "1057_NEW ORLEANS.PDF": "C4C7931C-CB9D-4F14-8D0F-46DE34165AA0",
    "10570.pdf": "B66049A0-990E-4C38-B897-85BE2F9FB912",
    "10571.pdf": "7601FA1A-C001-4C4C-A5B5-37B299AA2DB1",
    "10573.pdf": "94C2B89B-72AC-47D7-82B4-C6CF2E2E3C46",
    "10574.pdf": "6D776065-9CC7-4553-80E9-E3D0A0F2D338",
    "10577.pdf": "71288B7F-8ABC-4737-801D-DD35F66E0549",
    "10578.pdf": "F8AEFD51-8F5C-41B8-BD2D-87CC7C1B5F95",
    "1058 032568.pdf": "FA83B6F5-D5C0-4C9B-921F-62B8C3D49E58",
    "1058_NEW ORLEANS.PDF": "F6A0D55D-76C5-4191-91F3-9452C2BCF844",
    "10583.pdf": "7705DF30-B606-4D24-8C00-DDBCB25963B1",
    "10584.pdf": "FD09274F-6C80-490E-94B8-0545B5252657",
    "1059_NEW ORLEANS.PDF": "D27E5D84-F31A-4BEC-8840-949AEE0F4B2D",
    "10592.pdf": "6DE59630-6A32-40DB-A99B-B3E801C7BD69",
    "1060_REPORT.PDF": "4B8C394E-654F-49DC-A5AD-61A463756A8D",
    "10602.pdf": "D71FF374-D3B0-457A-A704-727715944402",
    "10603.pdf": "84BD8E0D-216D-43BE-8E72-62D394F8969D",
    "10605.pdf": "F1974147-1820-494C-8BD7-383D7CCB2659",
    "1061 031387.pdf": "01FED5B5-665C-4CCE-B7DF-DAFE471615AE",
    "10611.pdf": "95EF956D-3666-4954-86B5-EB711E4921B6",
    "10616.pdf": "CCC71C71-4C13-4CE7-BEE7-B1DA2C67F12F",
    "10621.pdf": "6BC6AA7D-244F-4D8F-B4EE-63456DC76288",
    "10622.pdf": "AFEA0FA5-9B24-4D3D-8D37-3A35E8835F9A",
    "10623.pdf": "B57C3323-39DA-4714-83C0-E16240C454CD",
    "10628.pdf": "A1E9B539-1902-4DB2-A0FE-576EAE1767A7",
    "1063_NEWSLETTER.PDF": "CCC770AF-5541-4BB8-9CC2-08ECF028917B",
    "10630.pdf": "C509776F-0B28-469B-90B8-073257148403",
    "10632.pdf": "BCA538A1-A28B-428E-962A-8F16AD90BF65",
    "10634.pdf": "A2DA7BFF-F274-4E61-8009-5140D91EC3D4",
    "10635.pdf": "FBF0DD98-E990-4DD2-BC92-9D561DD5BEFA",
    "10637.pdf": "D92D49AB-8C26-437E-8C2B-93CDFC5E6B8D",
    "1064_NEWSLETTER.PDF": "332C5C32-9DD8-4D99-AE18-27B65D2E1556",
    "10640.pdf": "0635119E-1AB5-4A53-B53E-39A47B9256C4",
    "10646.pdf": "5FCB45D7-473D-4AD2-8366-D1C3D4779C4A",
    "10648.pdf": "1C46043B-FB44-4555-B378-50380B16E7F1",
    "1065_NEWSLETTER.PDF": "2F1F3601-4E4D-44AA-B29E-2B81AE2DD615",
    "10653.pdf": "EA5F1941-5FDC-46E1-9579-E08BC5831E43",
    "10657.pdf": "21DA2900-5EA9-424B-A842-2A82F6D929CC",
    "10668.pdf": "8DEF5F4F-6402-49F6-95E5-98DA2CA776DC",
    "10669.pdf": "BD74C19F-51C1-4EFA-BBB3-E5EE68B4D1C7",
    "10672.pdf": "A7838342-4CF0-462F-871F-A607ED74D079",
    "10676.pdf": "7530E10F-9B73-4AE0-B035-AA687C4B29B6",
    "10677.pdf": "FD378770-3FE5-4959-8A54-D5F675E5F45A",
    "10678.pdf": "AFC725AE-2277-46A2-A8EC-E772DD0641A4",
    "10680.pdf": "E629F13B-B32A-4696-8026-D16FA6554DAA",
    "10681.pdf": "77D624BB-F331-422A-96D7-3C090E86B137",
    "10682.pdf": "411F8FC8-1B87-4F3C-9E1C-32A4AB9E37AE",
    "10683.pdf": "1C605EFB-BDE8-412E-984C-E3AC9E3F39C4",
    "10685.pdf": "8740FD25-7DEC-4E48-AAAC-9DC3D6BE4224",
    "10690.pdf": "7B455A93-32FF-4832-9A79-E6D45C463F96",
    "10692.pdf": "5B5420E2-1484-430D-BE9B-F449CB754CBA",
    "10698.pdf": "D7182DF6-2BD7-4D3B-9948-F9AAB4C140EB",
    "10699.pdf": "82C53198-01C7-4DE1-A8AD-94575046CE90",
    "107_Carta_CursoPreparatorio_Provadetitulo_Rodrigo Moreira Faleiro.pdf": "E7074A2E-1597-48D1-80EF-932CBEDD91E7",
    "10702.pdf": "9BF6E766-72BB-4ECF-82A7-02D9C984C314",
    "10707.pdf": "A5563508-C431-4BFC-BCC2-5BE924AA372D",
    "10710.pdf": "834E780F-7429-4031-87DB-1CA6DEB65F99",
    "10711.pdf": "2A5731DC-C7F3-48D1-824F-D3DF3581820A",
    "10715.pdf": "CD835247-1738-4F6D-B1F2-361DC0490148",
    "10717.pdf": "FA9CB36B-2FC7-4CC1-BE05-81E2B7145E12",
    "1072_0001.pdf": "ABEB401B-B29E-4C42-9C40-350063F9A5B5",
    "10720.pdf": "233F75DE-7C5C-476E-9E1B-F2CEA059EF0B",
    "10727.pdf": "B65A3743-9AD1-4977-B540-F7B9D1DA654A",
    "10730.pdf": "D6ADD0BA-C14F-4D1C-93CD-2482B3AD615D",
    "10731.pdf": "B65D4210-AE0C-40BC-A415-DCFF1BB7FDF5",
    "10733.pdf": "40A738CF-763C-49AF-94BE-4B3BCF0A3EE4",
    "10736.pdf": "52F56301-515E-43E2-9C22-71933527BA4B",
    "10738.pdf": "50D06D55-275D-4A1A-A152-D20051BF42F2",
    "1074_GUIDE.PDF": "C72BC4BB-94A6-40E9-8555-60A3FB29E680",
    "10741.pdf": "9ACC0820-CBF5-4D7A-B263-F0EEBFAF8427",
    "10742.pdf": "6ADC7740-A970-4F4A-9BC0-49C879536D26",
    "10743.pdf": "5DC6CB04-4205-45E8-A4BF-EB2187C547AD",
    "10744.pdf": "4AB0F0D6-D0D6-4F31-846F-750F78316B67",
    "10745.pdf": "DB51265B-F4DA-4352-BB0D-CFCFEF8601A0",
    "10749.pdf": "52B36975-A21C-4D63-A3FD-B2306E138AD3",
    "1075 LDS CNS poster.pdf": "433B202A-12BE-41B9-B036-11F46F58DAEB",
    "1075_PROGRAM.PDF": "8D1E5050-9D5C-44DE-B875-1C5F50F1892B",
    "10750.pdf": "5735BE4E-5FDC-4B56-BDDD-78BF2B287DAF",
    "10754.pdf": "82BF9044-C5DB-471F-AE39-B89CFDF7FC7B",
    "10758.pdf": "4F0CCE98-B7C6-4C36-BB86-2CAA72C94664",
    "10759.pdf": "454BDE92-7715-41E0-ACEF-10CD23E72B11",
    "1076_JOURNAL.PDF": "35A522A7-F9EB-4E7A-93E9-71EA6DE64D55",
    "10761.pdf": "111792B0-36DE-47FF-A461-E359FA8182EC",
    "1077_JOURNAL.PDF": "185C37B3-3FC2-4EEB-86A2-0E02AA5E1995",
    "10772.pdf": "177C519C-ABA9-4C1A-BEC2-DEEF5BCA8A9E",
    "10774.pdf": "10AE53C3-8C37-4652-A62D-366D1DBC8A90",
    "10776.pdf": "4EC1BE26-EEDF-4CE8-9E41-75887D8ADB9B",
    "10778.pdf": "401B17B5-DA13-415F-A4F8-93DC239FDF51",
    "1078 CNS-Poster (images)-(Abstract ID 28623 Final #1078).pdf": "ECFD669B-C90D-40A3-AE32-2140908B41FC",
    "10780.pdf": "271E5835-5301-4A3C-A0F4-89D3D2B7492A",
    "10781.pdf": "8B521E28-F59E-45C5-AB07-3F731EAF9680",
    "10783.pdf": "EF0DA6DC-C107-4760-980B-8758E42EF75D",
    "10785.pdf": "550C1B14-2D8F-4C87-8E95-ADC12AB7F0C6",
    "10786.pdf": "D82D4188-8E99-482F-AF00-404C46A03B71",
    "10787.pdf": "6EAFA237-77B3-4E0D-8271-CE51C8743963",
    "10788.pdf": "0E03EBA8-A6B8-4264-85A9-586B3D8C90B4",
    "10789.pdf": "38678A8B-A737-4CAA-B59F-E39773B0C9B3",
    "1079 Ogando-Rivas Hydrocephalus.pdf": "7220CA2C-91B7-4C21-BF00-CAB8075BEFCF",
    "10790.pdf": "7BB2E039-861C-467C-8C6F-634F64117D08",
    "10791.pdf": "49C47FD1-10E2-49C3-971C-903553E25A57",
    "10792.pdf": "C81CFCAB-04E9-438B-9BA3-82DEBC14BF45",
    "10793.pdf": "0A81D031-9F42-429E-BB60-E4D52402B2E4",
    "10794.pdf": "166AAF09-79F2-4EE6-A7FD-DE60F8C491D0",
    "10795.pdf": "EC94EC00-DCB7-4C29-8E48-8CC463BDAA46",
    "10796.pdf": "C34A49E6-3B21-4659-9410-DDFF0F21BD90",
    "10797.pdf": "E2A51237-0D52-4DDB-9221-1CCBC7004FB7",
    "10798.pdf": "F71BE87D-F10C-46BC-A4A0-994BC8BE2B8F",
    "10799.pdf": "78FCB2BC-3E12-42C8-8E7F-6C7F3D18C782",
    "1080 033095.pdf": "ABF4ACA0-41B9-4BB0-A08D-8A6562CB8D46",
    "10803.pdf": "F5C8AF9D-221E-426D-86D2-C35DFB52254B",
    "10808.pdf": "09657593-D18C-48A2-9291-AB8DA9B4D883",
    "10809.pdf": "10FE1F31-AAF3-408B-9C43-2CEDF4941037",
    "10810.pdf": "9469CA0A-29BB-4266-9379-B5B7C392B30B",
    "10813.pdf": "FDAC6BBF-72DA-4B12-BD5B-F0BF0040839A",
    "10814.pdf": "F9C4248E-10C0-4FE6-BF42-ED76C0EB5776",
    "10815.pdf": "C1DB225D-CF8D-4503-8CAA-042B9079403A",
    "10816.pdf": "53A0E534-F742-4049-A368-487ADE37E5CB",
    "10817.pdf": "5272F03C-D13F-4118-9A02-A72E34410719",
    "10818.pdf": "0297BC7A-7F0D-40FE-BEA5-EE9206D727B3",
    "10821.pdf": "EF06F1D7-946B-49E3-9D68-D522A5DE1405",
    "10822.pdf": "C7C9A284-F0C6-4910-9652-682FBDD0B890",
    "10823.pdf": "67EFBA14-768F-4F20-8C33-4FB6C7595E0B",
    "10830.pdf": "F023A9E5-8B51-4A53-8044-4A39EED1F53E",
    "10831.pdf": "E859AF3B-4BE5-418C-854B-CC01CDD1EA58",
    "10834.pdf": "2ED371F6-4FF6-4324-892F-01AB2E304609",
    "10837.pdf": "CE27FD3E-E015-452D-837E-541A1345775D",
    "10838.pdf": "BC6D014E-C066-4F84-8604-76AFE66A7723",
    "10839.pdf": "C6AF2414-36DA-4535-9FC5-01ECABFE5197",
    "1084 032984.pdf": "395C9291-3846-4CEA-86DD-89666BB7FF2B",
    "10840.pdf": "97BDEE22-2AF9-4D2B-A487-6CAD654BD93C",
    "10843.pdf": "6E65ED3E-3724-47CA-A7BF-65780826320A",
    "10844.pdf": "57235640-F7E6-4C22-96B1-FAA39B210AE7",
    "10845.pdf": "5854A108-53B4-4511-A9B1-5201FA4C4054",
    "10846.pdf": "49B9308C-C267-4DCF-BE70-06C647D07821",
    "10847.pdf": "B96E20AA-11F8-46A6-94F5-5123C5E040E7",
    "10853.pdf": "B8F6AFC4-DAC6-4680-8A09-AFA325A29DB8",
    "10855.pdf": "6D898400-C5BA-45B9-9FCF-082EA1D553F5",
    "10858.pdf": "224658CC-9DF8-4AEC-8EAD-2B8923A5FB42",
    "10860.pdf": "0E26FDF6-8F67-4977-8D35-643981DFD40C",
    "10864.pdf": "F9F7C59A-C3D9-4318-9528-879460E02113",
    "10866.pdf": "B0FA8D7C-5554-4EC0-A298-AB64D1F34A4E",
    "1087 032221.pdf": "E6F7DCBB-0843-4908-AC95-E8F2B8752E37",
    "10873.pdf": "813B1EF6-5A14-4A12-94F5-FC35318DB9C4",
    "10875.pdf": "76E98DE5-9244-48A4-A195-1FDA6735C155",
    "10876.pdf": "75A40E0C-E235-4778-A254-A2366348C276",
    "10879.pdf": "9EF2D860-01C2-4256-A173-E3A62E5B095A",
    "1088_ORDER FORM.PDF": "182BB17D-D799-4D52-B511-A8890E1C0E99",
    "10880.pdf": "FC011FBC-0A8E-49F6-883B-E5108BBE157C",
    "10883.pdf": "1D899F9D-284D-4231-B7E0-8F141EB0CCB7",
    "10886.pdf": "AA1ADB12-2206-4369-B734-36E9B457C043",
    "10887.pdf": "8047FFAC-79CA-4CCC-8FA6-C444D4083B75",
    "10888.pdf": "1ED7C0F8-8680-4D2C-B54E-148042D017CD",
    "10892.pdf": "B9D1EE62-AB0E-45CD-BD15-1F75952971AE",
    "10893.pdf": "0311B6F9-89B8-4E5E-9208-73E4C5444621",
    "10894.pdf": "704584B5-33DB-41D7-B142-09E881E13A93",
    "10895.pdf": "F0E9DA26-D980-465E-A460-AA3D542D24F0",
    "10896.pdf": "EB0C14D2-63DE-43B4-B3BC-8097FBEC0BAC",
    "10897.pdf": "F7A00617-8E88-4A96-AC94-B2F727C656A8",
    "10900.pdf": "04FF611E-B674-48D6-A2CA-8BF9E6A02528",
    "10902.pdf": "678B9041-07D7-4744-BAF1-2817289A2291",
    "10906.pdf": "B8922D6D-30B4-42F9-B83A-9B0AD06B0ED9",
    "10908.pdf": "A44C7F3D-82E4-490A-A545-16615F57B6C0",
    "10909.pdf": "5F1D0C99-83A5-45DA-8B14-F1C38BB0069F",
    "1092_AUXILIARY.PDF": "48AD074E-70E5-42B6-9556-37A8F5518ABC",
    "10921.pdf": "54CFAA1F-D187-42CB-A01D-E7A981AA0F18",
    "10926.pdf": "26156388-A658-43E1-97F1-782FE093C049",
    "10929.pdf": "9EE89A2D-51BE-46FF-9346-51F5598A9971",
    "1093_PROGRAM.PDF": "3BF6B2A9-50C0-4F99-AAF9-45373ACB8E4A",
    "10930.pdf": "9FE21DD3-F088-4C18-B750-B6F8F65E3279",
    "10931.pdf": "26F42A73-7CB1-4B61-8A93-A95322132AD6",
    "10933.pdf": "BBC2A29D-25F0-468B-9D81-F915431FFEAF",
    "10935.pdf": "552F8380-D4E3-49BC-8FD7-5204BFC1A1DD",
    "10936.pdf": "1650C9AC-DF47-4F9F-857E-CD045D3BC4EA",
    "10939.pdf": "D631504F-99F0-4EEA-BF7E-50BF1F5E11EB",
    "1094_ORDER FORM.PDF": "C656F04F-CAB5-447E-89A8-7284D64422C2",
    "10941.pdf": "6DF62113-0B80-4802-86B7-FF47C50F52B2",
    "10943.pdf": "A1D967A1-B925-4895-BDF5-1CB84266909F",
    "10944.pdf": "DDF0B61A-D2FD-4D80-978B-0E84315EC80B",
    "10945.pdf": "4151603D-BF7A-4213-B467-A1AAE9007032",
    "10947.pdf": "94BA3F00-62A6-463F-A2B4-0A0B93C04DB3",
    "10948.pdf": "AD896106-0405-4C71-9A86-8C0E50864138",
    "10949.pdf": "9E11A25B-62E5-4748-A67D-EFF3E0CCE893",
    "1095_NEWSLETTER.PDF": "20346529-F3C3-4C0E-86C3-7880190AA546",
    "10951.pdf": "D554FD0A-4B79-4B22-9B66-7EB6C1FD6087",
    "10953.pdf": "C2C5BFF8-6957-4D65-ACDB-0A1BA02DAFA0",
    "10955.pdf": "A07B5CBD-B1F2-47FE-A2BF-9B3C1553064F",
    "10958.pdf": "C2FADD48-D690-456E-B402-9ADB4F8998BF",
    "1096 033093.pdf": "A3D741DA-3AED-416C-BDBB-D64C2CE9576C",
    "10960.pdf": "7253A9AB-793B-40A1-9C30-AEE1C3A299A7",
    "10961.pdf": "713A5017-340F-423A-8B7D-7E4EDF614C38",
    "10963.pdf": "A4FFC11F-9490-4C51-A2DF-FE46A19272A9",
    "10964.pdf": "FFF3A4B2-A21D-4F76-8B58-261F6CF78559",
    "10971.pdf": "2B705A23-F0DF-42F8-8904-293F78D253A1",
    "10972.pdf": "7641C6C6-7FD3-4F8A-ADDD-46E3C43A433C",
    "10975.pdf": "58AB4B31-A8EC-4790-AE1D-78ED65AC141D",
    "10978.pdf": "2C573582-8CA0-4123-9C60-555135C9A49A",
    "1098_PROGRAM.PDF": "2C9D4E5C-C053-4C79-B411-3565F4F3357D",
    "10980.pdf": "C8B5DAB3-7150-4290-A08D-237FED2DBCAC",
    "10983.pdf": "96604021-AFA1-41AA-AE1A-F936DD2A080A",
    "10984.pdf": "D29D69D7-CC6C-405B-BFC4-353DFB7CC6ED",
    "10985.pdf": "4CAC162D-B593-4B58-B517-93A56E0B7F36",
    "10986.pdf": "7D5815E0-D897-48D6-9357-3C9DA9CD3EA5",
    "10987.pdf": "ED0BBCBC-8BD8-470E-99D6-04E9827AA985",
    "10989.pdf": "A5D7B7A4-AEFB-482D-B041-06967A3A5027",
    "1099_BY-LAWS.PDF": "665EFBBF-DC6B-4EE4-B170-0132233B0145",
    "10990.pdf": "F3CFF427-700B-4814-8AB0-2BDD3AA814D1",
    "10991.pdf": "F818D49E-8509-4CC4-91AD-32A3FDE8C2AE",
    "10993.pdf": "CF510818-2D52-4D01-9A36-616B8ECA43D1",
    "10994.pdf": "F46FEA43-0140-4185-BA0E-710BADE060EA",
    "10996.pdf": "DD106C09-3909-4DD5-82B7-8E050674080A",
    "10997.pdf": "BB8BE1B1-E05B-44F9-9C6E-3D511916B22E",
    "10998.pdf": "E4D24599-8479-46A5-990F-D97C92ECC483",
    "10Historical Synopsis for the 2010 Academy Annual Meeting.pdf": "FCAB8A99-D2D9-42FC-9B67-D35AEA250D9E",
    "10Program.pdf": "ACD8A2D0-8AE0-49D0-A0BC-9E0C3B37A244",
    "10th 12th MBBS Marks Card.pdf": "7F54D669-715D-424A-833C-77AFE1F5A1AA",
    "10yr Site Visit DocumentList.pdf": "9B2D9AB9-8541-42DC-9809-9D01D8CE7187",
    "11 - Interbody Techniques for Lumbar Fusion.pdf": "F482E8B2-647F-42B1-8574-96232E6CF343",
    "11-16-16_pslrp_mh_reauthorization_coalition_letter.pdf": "A61E585B-3D32-4742-81AD-C47A5B5AB4E1",
    "11-Viswanathan-Raslan-Functional_Optimizer.pdf": "0E100678-B03A-4830-BB82-56937819E80D",
    "11.18 CDC National Concussion Surveillance System.pdf": "079827E0-C938-4C8B-850E-490E1BAC3764",
    "11.6.20 Sign-on Letter to Congress on AUC Program.pdf": "3198FB35-5B8F-4CD6-8BC1-A6162DDD0AFB",
    "11.pdf": "F63CA1F6-2A8B-468B-9D6B-CE80C19CD828",
    "1100_PROGRAM.PDF": "5EC6C79C-04C2-4DF4-94A2-3B7A7A216156",
    "11001.pdf": "A1B46BBF-FB29-44D4-B907-C57D2FE8EA82",
    "11004.pdf": "62A22575-5CC3-4FD4-98EE-079F8B35FB87",
    "11008.pdf": "2D7706AE-C600-4CA5-9B51-9AD3A1DA0CE7",
    "11011.pdf": "568D7677-4484-4B9F-87F7-A949669AE358",
    "11013.pdf": "CEDA6945-BD69-4DA4-8801-1AE8B58B6883",
    "11014.pdf": "CFA26DB2-B05B-4440-B77A-4471AD5D6296",
    "11015.pdf": "EEBF38AD-DC6B-4A0F-8EBC-BCA2B54CD5FE",
    "11016.pdf": "11C3F955-7525-4FFC-87A0-C8555FF013D9",
    "11017.pdf": "3D2CC14C-1325-404F-98CD-463A46AE5E91",
    "11019.pdf": "FD558DF0-A845-4D1F-A3D8-7F3775766AA8",
    "11020.pdf": "B459B20A-DC8A-4292-98F0-EBC09B1D7418",
    "11021.pdf": "B36C876D-431D-419D-9421-DD1655C99E5E",
    "11022.pdf": "3083CDBD-AE92-4857-B529-0EBA492F0537",
    "11023.pdf": "B89F66B3-E4EA-47AC-82E6-4B2F5E717005",
    "11024.pdf": "BA49B975-9189-4505-8743-773BEFE98393",
    "11026.pdf": "B7D5BA40-D016-4E1C-B799-84AD6C4FF263",
    "11027.pdf": "975A414F-C3FD-4F6A-BC98-58A1AA98C1E4",
    "11028.pdf": "CBDA4B0D-2637-4BFF-93E9-67580758174D",
    "11030.pdf": "2ABF255E-C14D-4221-87D1-46D340F59823",
    "11032.pdf": "F923815E-52A2-4331-B0D5-43075BCF0B93",
    "11034.pdf": "DDB48D27-5E62-4879-BE18-EEA0E92B1FD5",
    "11035.pdf": "0DFA6F60-7548-4BBA-9481-58F3884CC823",
    "11037.pdf": "A0B9D241-7BCC-4F82-B96F-92CB81A2C7D8",
    "11038.pdf": "5A58735E-0E45-487E-800B-E066897546D7",
    "11039.pdf": "47606A76-F82C-4F3A-90C5-A451597EF475",
    "11040.pdf": "6F4EC6EC-D428-4469-8321-12752C8C144F",
    "11041.pdf": "9045DD12-443E-4F73-86DE-774F0A9F30D0",
    "11044.pdf": "1E275F5C-1886-48A1-907D-684D5D5FD104",
    "11045.pdf": "036AE360-C6CC-4F64-B4E0-4035D57D01D6",
    "11046.pdf": "521914A9-FE4A-4E29-925A-10131E4C51A4",
    "11050.pdf": "FB393B6B-96D9-4845-9E45-DF8C436CE35D",
    "11051.pdf": "EDBC8809-012A-4FE5-B55C-7C2DB9678445",
    "11053.pdf": "89F72992-2E25-4EF7-B21C-00AC45817616",
    "11056.pdf": "B85E4C18-D463-4727-BFD7-5E701550CC7F",
    "11058.pdf": "5F3A5A22-A346-4382-8629-F8B1A520BE25",
    "11059.pdf": "AC43E444-582E-42EA-B316-4E5529702A94",
    "1106_MEMO.PDF": "ECCCEC71-C0FF-419F-8DBE-0AF2491DAA15",
    "11061.pdf": "6A7B2F2B-DEC0-4D1F-9718-83B7B8FF85A2",
    "11062.pdf": "BE141DBB-2401-4ADA-9AFD-C95221C77C4C",
    "11063.pdf": "0EF3A369-6912-4706-A613-DC76BCB3585A",
    "11066.pdf": "DFE8B104-6770-4577-A211-0E758E777E41",
    "1107_PROGRAM.PDF": "F932EF62-CCAD-43B6-909A-4BDE57BA21E5",
    "11070.pdf": "08ECCBFC-E85E-44BC-9C9C-F1FEA597B30B",
    "11072.pdf": "A2BC1315-5B29-4306-AD5E-CFD6DB29E17D",
    "11073.pdf": "E72F623C-039D-466B-9DDF-B8B1E6376BF2",
    "11075.pdf": "7585CCA8-88DF-4EB5-A6CE-F0682D47A390",
    "11076.pdf": "5646F725-251D-4A07-BCE5-976021C16A7D",
    "11077.pdf": "7842ABCC-F3DE-4AB6-A372-429A82D86AC1",
    "11078.pdf": "2678F97A-BFE9-4F3D-A6A2-D6C72A9781AF",
    "11079.pdf": "4D016F6F-4118-4EA2-BCAA-87383202BEEE",
    "11080.pdf": "4A2D99C0-D9F6-4402-9E33-520DAEF66489",
    "11081.pdf": "8FF320DF-6609-4ADE-8882-7AC79A9FD5AA",
    "11082.pdf": "6A9222B9-5CFF-46F1-A1CF-C54C04593B62",
    "11084.pdf": "86CD3A8D-A2FA-4DE5-91CA-E21A00289312",
    "11086.pdf": "34FF36BD-7611-4869-9C5D-0146109742A8",
    "11087.pdf": "53100B84-0575-40E7-9E26-EC99C0D629F5",
    "11088.pdf": "DE5BE19E-F483-42C4-BAC3-687C71B7D291",
    "11092.pdf": "2104C52F-583A-4045-84A3-F9310D57DF72",
    "11093.pdf": "AC2A0C97-406D-4185-BC5D-E1B30D16B4EF",
    "11097.pdf": "EC278939-0C6D-4F8D-837F-B61A2DAFB7CA",
    "111 002.pdf": "7BF172AE-60EF-42DD-976C-3ED5A330C223",
    "11102.pdf": "2739CBF0-9EB7-4E0E-89EF-DF7D569222E8",
    "11103.pdf": "A5D4C32C-26D1-44EC-B84B-8DAA261BF67F",
    "11104.pdf": "1A5B85AC-8F0E-4B43-90D8-72A7AFEF7129",
    "11107.pdf": "77C4C058-BB4E-403F-80E8-8149786CB0B5",
    "11108.pdf": "641437D8-7812-4CA5-B3F4-D6C459F7E8DA",
    "11110.pdf": "368DB0E9-9685-45DA-BF9E-5E9FBDAF0C48",
    "11111.pdf": "1ADE7031-4FCD-4112-BF90-A455661EAD43",
    "11112.pdf": "EFDA7EFC-A2A0-4D49-A041-9E9A30074673",
    "11113.pdf": "3007BAEC-F0C6-4BB7-93EC-A4E52EC02B29",
    "11114.pdf": "B11AF52E-0888-4367-A584-57A42E27C1EB",
    "11115.pdf": "B3C5CB61-47AB-4DC6-AD6F-1C5D72AC9E5D",
    "11119.pdf": "8F28651C-BEE8-4456-B2B6-F4CA53485785",
    "111220 - coalition letter Medicare Sequester - FINAL.pdf": "1D630F4D-1C33-4832-A737-C69CFD6EA49A",
    "11124.pdf": "CADA6AC8-4A72-4C0C-81AB-7819E4965D80",
    "1113_Johnson_CNS_Poster.pdf": "F1A969A4-AB4C-495E-A6C3-F69AC87340B3",
    "1113_PROGRAM.PDF": "B972F455-1479-4014-8EEA-7C93FE84CD46",
    "11132.pdf": "935A1DAB-0A90-4BDD-874E-52D3D9603E40",
    "11133.pdf": "AE2E8736-F34E-4F1C-8C48-D1CE2DABCE5F",
    "11135.pdf": "DDEF3342-295C-439A-90CF-B9B12F1B9B3C",
    "11137.pdf": "F46C1F40-4FC6-4BBA-9B75-72ED1C8030C5",
    "1114_PROGRAM.PDF": "AE940D3F-F1EC-4DCB-8FB3-49AF48B34D53",
    "11140.pdf": "8BCFF2FC-F80C-400D-BCA6-C2BE46821214",
    "11142.pdf": "A0D123AA-BE70-4ABB-A559-E62BC0D86A18",
    "11143.pdf": "B65CD22C-82D8-471F-AB8F-CC3435CD1FEC",
    "11145.pdf": "10484B3E-7059-4ABD-82DB-1C1A2AD65A91",
    "11146.pdf": "EE182E90-A5A7-43D8-9F9B-6695EA1B469F",
    "11147.pdf": "B869875C-B663-454C-8D0A-3228DC55D9BC",
    "11148.pdf": "630DE137-562D-49C4-8C33-E8C92657BA2B",
    "11149.pdf": "47A64697-82DA-404F-9AFC-8F126A50A6A6",
    "1115_PROGRAM.PDF": "296F71A8-1A20-4882-AFDC-D0ECBFD2AEAB",
    "11150.pdf": "2FE140D9-7754-4FDA-B522-684B1AE79C21",
    "11151.pdf": "54A8620D-2345-41EC-A229-5F5A8D69AE63",
    "11152.pdf": "33CC336E-A92B-44C1-8F0D-A23F0636E3D4",
    "11154.pdf": "3942F1F2-A1D0-4E64-B4DA-2E98056E58F8",
    "11155.pdf": "0FCD6970-4CA8-48B9-B35C-745D588397BE",
    "11157.pdf": "19864C5C-FE2A-4DAD-8FC8-3721787C3FB1",
    "11159.pdf": "3009EA13-69A9-4E27-8300-E1C3DF335953",
    "1116_MEMO.PDF": "9EB25D8C-51C2-4DC2-9BBF-A0E76F1531C9",
    "11160.pdf": "754277EA-3502-4124-BBCD-0C1C622E1CBD",
    "11162.pdf": "D285D96A-7613-4157-921D-6FCD63DABAFB",
    "11163.pdf": "DF9B955D-CDC1-4625-A680-5C07674D01BE",
    "11165.pdf": "01CE4D47-6237-4A96-AC8B-7AE2326959B9",
    "11166.pdf": "580191BA-17CD-4A3D-844B-0C9B18D9EAF8",
    "11169.pdf": "445293BB-29B1-4F31-B858-74BDAF8D2EFD",
    "1117_BY-LAWS.PDF": "81A4866F-E196-41A2-9F20-CCF265742B25",
    "11170.pdf": "785CD169-0D0C-4BE6-9494-092DBE98DE05",
    "11172.pdf": "C25ED016-561B-4AC1-85CD-83851BC3FF0F",
    "11173.pdf": "8F5B3718-0791-483C-8013-1C58CDD3AB30",
    "11177.pdf": "CC70CBF3-B334-46EA-83FA-6AE808F64716",
    "11178.pdf": "9AB1DD2D-839F-4EF6-A7EE-55652ACE7D67",
    "1118_PROGRAM_compressed.pdf": "A823EA27-CDE4-4C27-9417-2B4032FBF5CA",
    "11180.pdf": "D66FDD82-739B-4D58-ABFD-03CD760C82CE",
    "11181.pdf": "C745934D-1FE7-4040-ABCD-68E4D46D4E9A",
    "11183.pdf": "632094C3-9A6D-4B6A-90E7-D3E540484C82",
    "11184.pdf": "1774EB19-5DC2-491D-913E-9B30340B8C1C",
    "11186.pdf": "830C3EFC-D4A7-4952-8A5C-A4FA6E08786C",
    "1119 032106.pdf": "DC108B9F-00A6-4133-B5EC-8E539E6B62FA",
    "1119_PROGRAM.PDF": "2FD0EE08-4852-4046-AEFD-2D6BD7726D8B",
    "11191.pdf": "4A4195AD-4D3B-40A3-9ED1-ADC0F3568271",
    "11192.pdf": "CAAD6FF8-DBE4-402D-8E0B-70521E18F98B",
    "11195.pdf": "4D6B3374-99C5-4DE6-8DE3-87353AF9C89A",
    "11197.pdf": "D788B9C0-7176-413A-BC85-3E3298BDE9CB",
    "11198.pdf": "823F308F-0FD3-401F-820B-84F950F94081",
    "11199.pdf": "84DF46F5-CF08-4699-9408-C52BDF8337A7",
    "11202.pdf": "A7050EE6-B668-4BE8-A60D-9A12D9F6B260",
    "11203.pdf": "EEAC7906-B72F-4D8D-A970-470D422D8A0F",
    "11205.pdf": "AFDD438E-6F86-4415-AC2D-1C3BC4766238",
    "11206.pdf": "C80B1562-0DF8-4012-832E-6C2B4FA102D4",
    "11207.pdf": "B569AE38-843E-4FD3-8021-080825DEEF52",
    "11208.pdf": "4D41CE80-559E-40DA-BEA4-16D85BEBB0C3",
    "11209.pdf": "6848FF19-AECC-45AA-80EF-4384250F34B1",
    "11210.pdf": "3E9E67AA-EBC4-4A6D-A7EB-BCF9D084D6C8",
    "11211.pdf": "060AE3F0-89F3-49F1-B507-29E85B6C1747",
    "11216.pdf": "06A41C6F-C6EE-4C68-9313-8FA7DB4F34C7",
    "11217.pdf": "BEE91210-1574-441F-BBBE-2C7F8A2011B3",
    "11220.pdf": "9E20DEA4-1C88-4BE7-AD02-07D6040A57C9",
    "11223.pdf": "EEDC2B04-6260-4811-B23C-797E21E64A69",
    "11224.pdf": "99A3834C-2617-4F07-97BE-B1A6BF4B3798",
    "11225.pdf": "09192457-CDFE-4E13-8A5D-8060AFE54FE6",
    "11226.pdf": "85B780F9-51F5-479B-B41D-19E77E24220A",
    "11227.pdf": "7719CE48-55D6-4531-90A3-0F9E81D4416D",
    "11228.pdf": "BFAD9730-8EF0-4230-9F6E-6F84D71B97BA",
    "11229.pdf": "65278ED9-D08D-4A93-9283-AA444313D8B2",
    "11230.pdf": "02A36633-76BB-46AC-A36C-56BB4B5737C3",
    "11232.pdf": "D751C8F7-6F18-42FD-B61B-8DA82F768D21",
    "11235.pdf": "A2EFEFE4-3D3F-4B70-B626-7A4A7FA7459D",
    "11237.pdf": "BE3D6A83-F282-492E-B3BF-E9F094D846F9",
    "11241.pdf": "95E5E8E3-AAF8-49E6-A653-E2716E81ED9F",
    "11243.pdf": "52BE4723-2355-48E5-AEF8-F63C2BB85AD2",
    "11244.pdf": "21F51FC9-440A-4655-A32E-B2249293B779",
    "11245.pdf": "875FC07E-7FF8-4F89-B252-41E1BC7EE991",
    "11246.pdf": "0B9D6D61-9FC7-4650-9A5F-935F2776B43A",
    "11248.pdf": "C0469BAC-C029-474B-8B7A-63F9758544D4",
    "11250.pdf": "43D0EF8A-D08F-4A5F-AF14-4B873A0AF503",
    "11252.pdf": "F62756E5-0AA4-4637-B0C0-66F1C3C72026",
    "11253.pdf": "D714EFC2-0109-45E3-B6CA-B80766C22D58",
    "11254.pdf": "116B8231-C8A4-4AEC-BE3E-9BA95237DA1F",
    "11255.pdf": "1DA2D59C-3EF4-4CE3-B16F-0917FD4E44B5",
    "11256.pdf": "4B5676A7-783A-4405-95DC-0C6816768F69",
    "11257.pdf": "B5D87B49-6DCE-46BC-9810-E477B2F5F770",
    "11258.pdf": "58F8E2D0-289E-4F4B-B850-7C5A5BC0B91F",
    "11259.pdf": "03B05FD1-68BF-43AB-AC1F-CFF9F6EABFFB",
    "1126 .pdf": "E359962E-01F1-440E-976A-22EED571F28E",
    "11262.pdf": "5AF508DD-E53B-46E1-B8A7-7EF6D3E72987",
    "11263.pdf": "09235F3E-D932-498D-B290-EBED36E73DAF",
    "11266.pdf": "07DCA0A1-0F1F-4BD3-83D2-E3F5C7CE4D2E",
    "11267.pdf": "BEA855AA-6B85-4E61-95E6-9238FB75AE89",
    "11269.pdf": "15DB80F1-FBF3-49EF-B568-D60876C8F8B0",
    "11270.pdf": "0E937E60-E719-4C3D-9720-46C87985CACF",
    "11273.pdf": "E3D19159-5ACC-4608-A8F5-5DE325D5034B",
    "11278.pdf": "E4F555A6-3CAA-4375-8452-E22F56B1EEDF",
    "11279.pdf": "410E4695-24A7-4B4F-8677-05EBAA80B9E7",
    "1128_MEMO.PDF": "4D72C4DA-6CFB-4B76-946F-0D9A4D75A381",
    "11282.pdf": "4574ECA8-9AD9-435F-A718-0C31C75C7EB1",
    "11283.pdf": "9D2CB03C-398F-4B9B-B963-7F25C61032DA",
    "11284.pdf": "C347EDAC-ABE6-47AC-A012-DB5793CCB057",
    "11285.pdf": "7F95BCAB-FF18-4006-A234-507298E24BAF",
    "11287.pdf": "14882394-530D-45B2-9B37-5DE8B4F702C0",
    "11289.pdf": "98381253-4C3B-438F-A977-465961FEC19E",
    "11290.pdf": "689684D5-9EC8-4AF4-A95E-50173B4DFD17",
    "11292.pdf": "7D3EF1F9-C02F-40E5-9CEE-5B1F760B6A16",
    "11293.pdf": "C44C8AC6-EDC2-4F2C-959B-D63FA880588E",
    "11296.pdf": "A191DE91-C131-4E63-9DB4-4B4CECF1E989",
    "11297.pdf": "1041FBD6-3293-4B44-81B4-A5CC3D0E74BF",
    "11298.pdf": "225F4200-D664-4364-A54F-3677CDEB9374",
    "11299.pdf": "917B929C-5E51-49ED-8F2E-CF1CADDB2E81",
    "11300.pdf": "F88D8AB6-E982-4B55-B99C-C37B5A04A4B7",
    "11302.pdf": "6782468C-2E03-4D55-868C-F0EEA14F1B00",
    "11303.pdf": "03BB0855-5721-4338-88A6-64463AB64595",
    "11304.pdf": "7FAF341C-FCAF-4202-B203-6EB9DE21EFF0",
    "11305.pdf": "C490A7FF-8FAB-4E7F-8D52-2155046BE649",
    "11306.pdf": "17456420-E374-409E-A717-CBC35590976C",
    "11308.pdf": "437F9E2B-DA18-4B94-946E-CF6F97645DCE",
    "11310.pdf": "179A1652-D52C-4847-A462-123366361CC5",
    "11311.pdf": "1F4EAB77-EC8E-461E-8C94-68E91C334185",
    "11312.pdf": "30242B19-B8E1-4985-93C6-B96274066AA6",
    "11315.pdf": "54379A55-097D-4CDD-B7A4-A478C835E0E5",
    "11316.pdf": "1BD1908B-6912-4646-8B36-8D5570C3E69B",
    "11317.pdf": "BF2C8990-91C9-49AA-A98B-B12ACF75072B",
    "11318.pdf": "841CBB31-F8F7-463D-BE7B-81C0B456A5CB",
    "11319.pdf": "EEEA2B08-1DCD-4908-A1CA-88F80D72EADC",
    "1132 032217.pdf": "70FFDBF8-2AB1-425E-879D-9A3A2E6FCB7A",
    "11320.pdf": "6F509D69-890D-4CEF-AFB6-1EA4D9FF1878",
    "11321.pdf": "7A4DE6D7-2D9F-4023-B614-94B103CD3C51",
    "11322.pdf": "263F4AD7-4296-4FB3-9BE6-EF9AE28CBC12",
    "11326.pdf": "87A35DB4-CF78-4F3B-9E50-DDF1F4D823BE",
    "11327.pdf": "FB18652A-69F9-4184-B300-D268421A9D3F",
    "11328.pdf": "07166347-B98D-4C18-ABA5-6488F17A746F",
    "11331.pdf": "7424225B-A886-4E02-956F-2113A593BE52",
    "11332.pdf": "40D22957-58BE-4D24-87C0-7EEAE4FFE729",
    "11333.pdf": "F978CE52-2019-4D98-8E3D-8E735CE171F9",
    "11334.pdf": "2377429A-5CFC-407A-AA8E-30DCAABAC9F4",
    "11335.pdf": "2FAAE23D-36EA-4A65-BE75-FBC7A4A376AF",
    "11336.pdf": "77E5C192-CD78-474E-BF3E-5B481494FDD7",
    "11339.pdf": "B5B9586C-42C1-46D2-82CD-9712766C8FDE",
    "11340.pdf": "92833194-0BF7-4ADD-BC7F-335F585AE742",
    "11342.pdf": "CE4AF158-CA3A-4C6B-B815-164AC701EF53",
    "11344.pdf": "10075FFC-F5D7-4A16-85D4-D311F07886C0",
    "11346.pdf": "69301128-0389-4582-ADC3-C170A31B7D2F",
    "11347.pdf": "5E3EB1FA-F909-4BE2-A2AB-9FBBC0998442",
    "11348.pdf": "4E2D8BDB-98B1-47A1-876F-497F3EC1D78E",
    "11351.pdf": "2E726B65-2028-4F20-899A-A1D8E922291F",
    "11353.pdf": "554D6D1C-2563-4453-808D-BC06D6B0A3F2",
    "11354.pdf": "20E88023-CB99-46C3-8028-9CDE0C4BF5FE",
    "11356.pdf": "C0AE9E51-C0CD-40E9-98DE-67B198B539BD",
    "11358.pdf": "2B311C68-4901-4075-B71F-0EA879EADF51",
    "11359.pdf": "C32AF977-E303-4954-96ED-0AA592F2C785",
    "11360.pdf": "FAEDFBFF-DC81-4B67-A79B-5C0BCB9C16C1",
    "11361.pdf": "6FA6E7EC-9FEC-4E41-A1A5-BD9545CA6516",
    "11362.pdf": "E792C406-783D-4EA4-B32A-E126DFCB8209",
    "11363.pdf": "A1B05482-3C4E-4FCF-AA2F-DB15DA40D40B",
    "11364.pdf": "800915E6-6AD8-42E9-AD27-C4030699562D",
    "11367.pdf": "BC0FA198-C877-4EF9-997D-653F19BE5D3D",
    "11368.pdf": "1C0B13FE-B6BE-4811-BD6F-4247B62E071D",
    "11369.pdf": "7AD64220-8EA4-4A40-8851-9605C4907FC2",
    "11370.pdf": "59E1BACD-6076-4DAD-898B-5EF300F9D267",
    "11374.pdf": "3B8BB5F3-7D40-4CFE-95C4-214A6BC7CECB",
    "11377.pdf": "2C5ADFC1-E052-44A0-9B51-0B61B617D58D",
    "11378.pdf": "8872DDC8-BF6F-403D-BA7D-642CBA52AD2D",
    "11380.pdf": "B9FDC711-1362-45C3-9202-747D0F3A607F",
    "11384.pdf": "38571540-997F-40CB-8D53-0A6634BFAEC7",
    "11386.pdf": "C882C7AA-4CE1-41AD-92BF-83410FBDED84",
    "11388.pdf": "B727CF41-ACE4-4FB8-838E-9777F2F41B4F",
    "11390.pdf": "BE6BE1B8-E4D6-4C5C-AB1B-9DABFF9BE3EC",
    "11391.pdf": "419C926C-FDEF-4F79-A2B3-07D1570FBBA2",
    "11394.pdf": "635E2223-AC5D-457D-A1C3-58A817406234",
    "11395.pdf": "A85B1BF7-0C95-44B7-B78B-98C7BAD8D070",
    "11398.pdf": "6BF5516E-0393-4806-9DF6-73860857B2FB",
    "11399.pdf": "CD6C2351-5C0A-4214-8DC8-5BF5709D3D6B",
    "11401.pdf": "726EDB55-1D94-45A1-8C6B-EF3F3F14A9F0",
    "11403.pdf": "E74E8E6F-34FA-411E-8136-57577060329C",
    "11406.pdf": "2626F51D-70A0-4A01-B7D0-12A1F5408DF2",
    "11408.pdf": "B3C7B05D-7F2D-4182-8FB6-66909F31C89E",
    "11409.pdf": "72503AB1-85C5-46B7-8771-1485C968DC6D",
    "11413.pdf": "8A4C625C-871C-4811-8E74-E77E4486F886",
    "11414.pdf": "00904343-BB00-42BF-B23B-F40F467BDAFC",
    "11416.pdf": "C01C6813-63F7-498B-91A5-CD1889D67216",
    "11417.pdf": "C0502FE0-EC17-4379-90E2-B8D09D434DBA",
    "11418.pdf": "E1185C3B-072A-4204-A904-B15209C5C62D",
    "11419.pdf": "22C066C0-528A-4F5A-B997-A321986CBE25",
    "11423.pdf": "5DD4D969-0D2B-4CC9-AE12-F7E408D13545",
    "11424.pdf": "BCBB3A71-6FC5-4938-BC0E-B8E22F589211",
    "11426.pdf": "1D2FC5CB-D129-47B2-A7CD-9C6CBE6F3001",
    "11427.pdf": "6723EA63-B88A-4FE5-BB9B-A934889586CB",
    "11428.pdf": "B13C1933-4B75-4768-83FE-8E0FB59EC939",
    "11431.pdf": "E1A5A650-7F86-4218-ADB6-9684DDF7B432",
    "11434.pdf": "17E021AC-667F-4494-BFF4-6A3E0EEDF75A",
    "11435.pdf": "13CF6EA6-A68A-48C3-8F23-21B46D4B42E2",
    "11436.pdf": "A0F1D96A-22A0-4B87-86E5-1F6DFCD9BBCA",
    "11439.pdf": "7FC376E4-C223-4812-8894-DAEFB655C60A",
    "11440.pdf": "EE137E78-B69A-4891-B36B-05EA306DAA53",
    "11444.pdf": "E4AC7A59-9B17-4C19-9992-625E53A1706B",
    "11447.pdf": "F7089BA6-5402-42A3-B728-6D7150BC2EAD",
    "11449.pdf": "D9D259BF-8124-43DD-AEF0-BEEA766AFD01",
    "1145_MEMO.PDF": "CE620901-EA47-4EC5-91C3-D185853594B4",
    "11450.pdf": "3A61ECC5-18E6-47E0-B018-40E7F5661CFD",
    "11451.pdf": "D7209E40-1B45-4AF6-98C4-DAE6B40A09CD",
    "11452.pdf": "751EC347-678E-4A3C-8BF9-1DAD15A3438F",
    "11453.pdf": "D1BC2517-9EDA-412E-9958-6A52B8A67B70",
    "11454.pdf": "D204E2F4-14E9-4561-B202-64DD2A36D6B1",
    "11455.pdf": "E4D1D368-883B-4E40-BBA6-EDF6778E3D5C",
    "11456.pdf": "083C7CC7-78CC-4E90-843C-A58DBAD685A7",
    "11457.pdf": "8DAF391A-81C4-41E1-93F9-4D304EEED3F2",
    "11459.pdf": "CF31004F-D5EA-4178-A42A-143CD11ED63C",
    "11460.pdf": "1C3947D3-75ED-4729-B611-F4BD726849D3",
    "11461.pdf": "5A36D08E-52F1-42F4-89A3-C4BFFC656378",
    "11462.pdf": "933574CA-E262-41D5-8DB6-1D7E8B3571D7",
    "11463.pdf": "A4673375-C81B-47F8-80A8-BDF9D07740EC",
    "11465.pdf": "3A7E112F-3B32-4262-9B2C-DEDC52D6FCCE",
    "11466.pdf": "C2A02C7F-4823-4D5B-AD52-7E56BF1E75CE",
    "11468.pdf": "2605EFCB-00EA-4AE6-8AAD-3A5954C30B87",
    "11469.pdf": "47B761C0-2ED3-439D-AEB9-A307A24A002F",
    "1147_PROGRAM.PDF": "EA1EA5B4-4DF7-4C37-A0EB-8B2851277C93",
    "11470.pdf": "D4002BEB-0A5A-453B-B917-60D8DF64420B",
    "11472.pdf": "6A78B1E7-EC83-4F16-BFF7-EDBF76B57D78",
    "11473.pdf": "757BED74-00FD-4525-9335-0AA4090AC007",
    "11475.pdf": "3CAADFA0-95B7-4287-B614-949C89B9B6C0",
    "11476.pdf": "CE0352A6-8FD0-4B11-8602-017ED5CD733A",
    "11477.pdf": "EF60E06F-E583-4D35-AEBB-54836FF95F3A",
    "11478.pdf": "3545EBFC-5FE3-4867-A174-C75BCAF5A411",
    "1148 - Pennington et al.pdf": "1C26D475-B1CE-4700-BEB6-0D6713DFCFAA",
    "1148_PROGRAM_compressed.pdf": "6B7F6288-E6E2-4217-8152-428445E17121",
    "11480.pdf": "CD2AD2AD-4FA5-4F0F-9236-000A00AA77E7",
    "11481.pdf": "DD8561A2-9879-472B-BD22-BF2B960EEFCD",
    "11483.pdf": "C7CBF70A-0A66-4F94-8B40-F43295EDAE8A",
    "11485.pdf": "364FF44A-3906-46AD-9181-3B5CDFB9C526",
    "11486.pdf": "8C548A43-3095-464D-B94A-3E1AEF9254E2",
    "11487.pdf": "A3D8886B-15EA-433C-A6B8-BFCC1C73D70F",
    "11488.pdf": "CC792E74-72FB-455D-ABCB-1E1CE8466950",
    "11489.pdf": "8FEA9407-41FC-46FB-AAAA-59C87302D606",
    "11491.pdf": "0C7318D8-8D00-4587-91F2-2D5AE257D6A7",
    "11492.pdf": "7E198E13-94F2-4861-AD2A-9C44D29A3A02",
    "11494.pdf": "40B5B274-E77E-4E26-94DD-E121117F1F4A",
    "11495.pdf": "061E3741-8424-4E1A-B3AE-0CFD46AFC993",
    "11497.pdf": "F9623C54-F0B6-4927-9718-226544F6CC1B",
    "11498.pdf": "BC7B127C-F02C-40F8-9714-4E797CDF3D5A",
    "11499.pdf": "BBB56DB3-0DEA-4DEC-820A-B6003E7C0BE5",
    "11501.pdf": "984C2847-BEA8-4A79-973F-E8043119605B",
    "11502.pdf": "2A98E41D-B25C-4876-B09F-A958EC544C99",
    "11503.pdf": "15A4749A-D87A-4E75-9B8F-03CB225ECF57",
    "11504.pdf": "ED9913F8-524B-4C06-9A2F-0694A56C06D6",
    "11506.pdf": "549ADDE5-1903-4075-BE23-864C0898E938",
    "11507.pdf": "07459737-A2F7-4CE2-9BBF-2745B34AA6E3",
    "11508.pdf": "782C16BA-E6D4-4C50-82E0-8544A4E80D7C",
    "11509.pdf": "61A1E86E-EE70-4B9F-9E10-9606ED404AD0",
    "11511.pdf": "02595261-5114-46C7-AFA2-D7D9F36AE05C",
    "11512.pdf": "346D0B76-4A77-43A7-8285-09F7D4DD5705",
    "11516.pdf": "527E556C-EE0F-4010-A4DF-21A53FBF9EB8",
    "11517.pdf": "BB0FE970-80F5-41CE-8412-AD204A32B7A3",
    "11519.pdf": "76863CEF-3813-4005-B6E0-8BF92D51650F",
    "11520.pdf": "32C2301D-9572-40FA-BBC5-7E56101E5E5E",
    "11521.pdf": "198BC367-8284-479D-96B2-D9A8DDE7C63A",
    "11524.pdf": "0663AA93-A407-4C6E-BFB4-2732F2524E26",
    "11525.pdf": "4BD14C71-8000-4898-9047-963EC747084E",
    "11526.pdf": "78864773-B8CF-46B5-9B77-7DD1590FFFA8",
    "11527.pdf": "C32F3BA8-5BC5-43E3-94A2-6A84D4E58098",
    "11528.pdf": "C5CBB6C7-002E-4E7D-A04D-1789BDBB82CE",
    "1153 Effects of silk solution against laminectomy-induced dural adhesion formation and inflammation in a rat model- 1153.pdf": "3CD39756-A344-4F2D-81B1-6F9884238AE1",
    "11531.pdf": "A23830D7-31B1-4A7E-9EDA-939EDABB0D20",
    "11532.pdf": "83E0F4D8-BA4C-488D-8EB9-5AD5C4137053",
    "11534.pdf": "F40C40CE-C6FE-4B07-A587-19727BC61AE6",
    "11535.pdf": "438E80D1-1E6A-428F-843B-09D0D5F62294",
    "11537.pdf": "82EFB69C-CDA1-46D3-9747-A649677029A5",
    "11539.pdf": "3ED74629-E7C2-4363-A631-8A83D197E3F6",
    "11540.pdf": "65DE5598-EC30-4C41-BBB6-8E3E7E7904FC",
    "11541.pdf": "BBF6C4EA-7F26-42C7-867B-1762F099614A",
    "11545.pdf": "390F8B76-8CCE-4BF8-A67F-4C1F5FCFA605",
    "11547.pdf": "1B24F81C-F520-4340-8E93-8F45D65AC2FC",
    "11548.pdf": "2E4BE592-1C0D-4A5A-95C1-C0426E23AE40",
    "11550.pdf": "F89554C9-D330-43FE-9660-EA90FB25BE5B",
    "11551.pdf": "FB348AD0-3D06-428F-90FE-098421922AF0",
    "11553.pdf": "41F73561-3547-4FE0-8FC8-EC5BC3134E6B",
    "11554.pdf": "F6CE2864-6712-4175-8A56-F3BE3AA8E71F",
    "11555.pdf": "9A2C4EFB-3043-40AC-BA9A-2D94C506CE2D",
    "11556.pdf": "CCCAA3CC-79E2-4788-B439-8ECB9F9C3F32",
    "11557.pdf": "BFDA33AD-4C43-40F9-A9AD-17E3F4AC2FD8",
    "11558.pdf": "E002A706-814C-46A6-882B-1B9AEEE66B62",
    "11561.pdf": "FFFDF130-4CD8-4904-A03D-F4FBDC9CCB4A",
    "11562.pdf": "D061AB3A-122D-4DD6-BE53-F7F8F641C3F4",
    "11564.pdf": "5D64ABC8-7074-4F62-92DB-5934F1247232",
    "11565.pdf": "F76E5D3C-144B-48E3-B950-86B857316FF1",
    "11566.pdf": "D95E4AD2-AE62-401F-B628-2A0AF042AEAB",
    "11568.pdf": "995BAF96-380F-4626-A157-71428F13B7CB",
    "11569.pdf": "49DD8908-CAEC-4094-91D5-C8EFE48D50F6",
    "1157_MEMO.PDF": "B040B5A5-1634-4DCE-A2CD-0B208CA966CB",
    "11570.pdf": "7B6DD3D1-8BCF-4C67-BA77-19BD3D19AC75",
    "11571.pdf": "0B9FDA1C-0162-48A4-8C84-2701F98ABBF2",
    "11572.pdf": "D5DA4428-28B7-433C-9A6A-8EC14D085B0D",
    "11573.pdf": "AE624BAF-56B5-4103-B243-E65BE1561F47",
    "11574.pdf": "1CA42B3E-5493-4A93-9072-2188186EBC47",
    "11575.pdf": "CDCE5321-0469-494A-B226-9AF1214F338C",
    "11576.pdf": "7341E818-260A-412D-87C1-90596C8EEA64",
    "11577.pdf": "95356A8B-A2AC-4030-BFBE-E263F14FED4B",
    "11579.pdf": "202B4215-A549-4BF7-9DEE-E9696EC04B14",
    "1158_PROGRAM.PDF": "016D6C54-0DC0-4C96-B7A9-00803F1D5884",
    "11580.pdf": "3906C14C-5068-47E4-915E-DC846BF11C19",
    "11583.pdf": "AD402064-2F5D-4FF1-8776-B68896D8A722",
    "11584.pdf": "AA6EB168-01A1-4ECF-9D6B-7EFCCE3B440F",
    "11585.pdf": "D9233953-92E1-4CCE-8A64-ABF172D3EECD",
    "11586.pdf": "6116B9CD-1154-499F-9BC0-42E4F15B1D8A",
    "11588.pdf": "45A9820A-300F-475A-A0E6-5BC03FF751C1",
    "11589.pdf": "70F9CBE4-D016-4DC7-86F3-84AFBE33BA12",
    "11590.pdf": "45012D4E-AC85-49B4-8ECA-0E5E297F2F24",
    "11591.pdf": "19F23AA7-C2AD-4D8F-857B-2022F088E2AE",
    "11593.pdf": "7C607C40-08F5-421D-A512-3A4BC6245CC0",
    "11594.pdf": "A05F7A36-9A06-4A97-95E9-E6F3C50364D2",
    "11595.pdf": "B549AC88-BE1D-4169-A7B1-7C35487299EE",
    "11596.pdf": "63C69749-7D71-4D3E-9B53-2798993CF12A",
    "11598.pdf": "26C3A003-53F8-48C4-97D1-953E8B9B4A0F",
    "1160 032273.pdf": "BCACD007-5CFE-422C-873E-6E0D7273A23F",
    "11600.pdf": "9B4015F2-5335-4DAA-9F5E-BD7AB117883B",
    "11602.pdf": "C03DA38A-2548-4BF6-977E-155B5CF50D34",
    "11603.pdf": "B48D24E7-0A92-443A-9FC8-A8F280C9918E",
    "11604.pdf": "61CE9693-2C3A-4F7B-9A47-EFDD4F6A74FC",
    "11605.pdf": "1D5B4946-3A05-4DB9-AC15-8274BA1874F4",
    "11606.pdf": "D5075D12-41E7-41A7-92B2-DB3706472230",
    "11608.pdf": "55B9AA37-EA20-48F2-80F1-249087055908",
    "11609.pdf": "89E3CFF4-77AC-443A-91B2-26F692E709D9",
    "11611.pdf": "3162CB73-F26F-45A8-8238-6AAFAA4B5C80",
    "11614.pdf": "5DBD51CE-6CB3-430A-9503-2596B5F32EC3",
    "11616.pdf": "A60E6B28-AD5E-49D1-B4EA-189419E708DB",
    "11618.pdf": "72857F70-BF05-4E94-9687-C9953290C291",
    "11619.pdf": "625D3DE2-1CDF-48A7-B151-06B1A38A818F",
    "11620.pdf": "6945411F-822A-4B61-A4AC-5A89DF5A3700",
    "11621.pdf": "469558BD-63AC-435D-B6E8-840C1DCF6B55",
    "11623.pdf": "1EBE0A7D-A0A0-49BA-9B67-F62EABB63D1F",
    "11625.pdf": "DFF84E28-50A4-4500-9700-297EE088A103",
    "11627.pdf": "BE2DF142-908E-48B2-997A-3E4DAA3D46DF",
    "11628.pdf": "6540FED1-1B49-4DAD-9E83-8EC6F8C29BAA",
    "11630.pdf": "B8791C35-A634-4FD1-9B43-BEC17DA96419",
    "11631.pdf": "8B1EDEC1-0C07-44A5-B784-552CB1BA7380",
    "11632.pdf": "E2EB33A3-7AEB-4F75-AF6E-CA7EA8E32179",
    "11633.pdf": "72448652-BBD5-4A2D-86B5-49BCBF9F0DCB",
    "11634.pdf": "8A927746-29B0-4229-91CB-951E831A285C",
    "11635.pdf": "952F641F-7FC6-4E3F-B9DB-F14836C293C6",
    "11636.pdf": "F42CC242-D034-4E28-8B1D-0A4591ADAEA4",
    "11637.pdf": "C156D519-5E80-442A-A731-3BF626336D3E",
    "11639.pdf": "42DA8AAE-545F-4B28-B91C-ADFEF29EAB4E",
    "11642.pdf": "699BBBAB-315F-4BBC-B69A-49CEF65BA9CC",
    "11642528_Issued Patent_2023-5-9.pdf": "5EF5EEE9-B72B-495D-8C30-28E60E763D26",
    "11643.pdf": "5C152234-F197-49DB-9251-715427F09130",
    "11644.pdf": "24949119-B287-44C6-953D-1EF550902BD8",
    "11648.pdf": "EF038494-EEA3-4D29-B5C5-7E7BDE5A4A4D",
    "11649.pdf": "4851B15A-7C9E-403B-B149-EDCB4DCFB4FF",
    "11650.pdf": "162A96A0-6936-455C-B8E7-18D5E17C56ED",
    "11651.pdf": "D514E682-8189-424B-ADBB-2E8ECA482A21",
    "11652.pdf": "9D714D67-9521-4BCF-AE63-7B4C3E7EB563",
    "11656.pdf": "5B3DC458-2204-4891-87A0-6436B90C40F3",
    "11657.pdf": "03758A9C-0F26-47A7-97CB-7A1DA032A02B",
    "11659.pdf": "77352ED5-2E81-4794-A179-13671C4FF323",
    "11660.pdf": "09E7B01F-FC8A-4C42-B6FC-E4D9F845F7B2",
    "11662.pdf": "DC149B33-75D2-4FF8-80B4-C129B9CEE42C",
    "11664.pdf": "14329D5D-FF22-44CF-B2CB-D0FB68356FD3",
    "11665.pdf": "0E5DE203-B3A0-4CCE-A171-BAC37F2B75A5",
    "11666.pdf": "7C8AC542-2FE5-4383-848A-4563CF4D8950",
    "11667.pdf": "0CC163AD-4902-4DB0-B499-ECB7CB7BD651",
    "11668.pdf": "D707CE99-B3E6-4107-91B3-7C42ACE2FE35",
    "11669.pdf": "22058E03-A0A2-4DB6-B706-D0937BD169D8",
    "1167 033103.pdf": "6E556CA0-369A-40DD-99DD-793C6673A67D",
    "11671.pdf": "B111B766-7408-46CA-A70E-72751F82C5AE",
    "11672.pdf": "338B7028-F227-4609-B60C-EAD3C66E6A97",
    "11674.pdf": "4FF533E5-4F57-4013-A507-88A3D24F4D70",
    "11675.pdf": "96E0F2AC-D17C-4B8F-B7CE-23ADFF0E0857",
    "11676.pdf": "167B6031-5A45-4D54-9B61-77350EE80C25",
    "11678.pdf": "9EB0EF2D-53C0-4902-9CBC-98CD7AE96A8D",
    "1168 - Pennington et al.pdf": "5DE09747-E5FF-41E7-8BE1-3ADEA75A7DB7",
    "11680.pdf": "6EBCFAA3-960D-49BD-9CF3-35A97316A174",
    "11681.pdf": "B7064049-8A6B-41BE-BEE8-75A7B3ACFB29",
    "11682.pdf": "0B4FBBA4-0CAF-437D-8214-6CCA2E7D62EF",
    "11683.pdf": "3CA9C5CC-B6B5-4B07-88BF-F715AAA61F32",
    "11684.pdf": "78569606-D6AD-43C6-9021-D5529505C729",
    "11685.pdf": "AD4174E3-E455-4E10-9F00-3BEFA83767EF",
    "11686.pdf": "F4CA3599-796E-4353-A2A1-FDD64DB4A695",
    "11687.pdf": "8B8252B1-20A0-4344-B1DA-85EAF830D779",
    "11688.pdf": "0EDB2639-5969-4FC3-AC95-83A85FCAE486",
    "11691.pdf": "7C6752ED-A394-41D4-AC46-5DFB91E18D5C",
    "11694.pdf": "3D0DCC8E-3257-4384-8877-1517C81354CA",
    "11695.pdf": "B8A9EC1D-FBD4-4A2D-8B8F-9D8C39CBDCCB",
    "11697.pdf": "51083819-3E7D-4811-A867-9A0A8FD18565",
    "11698.pdf": "C3174E3D-6955-436F-B82A-3DD097D5EFD5",
    "11699.pdf": "D81AF76B-C202-4820-A9E3-7F3935E843F5",
    "1170 033109.pdf": "BADC21F8-1817-4E7E-8CFF-8A646C9F9F8B",
    "11702.pdf": "AE469E01-5E1D-4D92-925F-F5D0EA4E9B78",
    "11703.pdf": "10B35A1E-D03E-473D-9C00-B518529A9844",
    "11704.pdf": "70F755E1-41A8-42C1-9C41-5C8BF52174F9",
    "11707.pdf": "D2DF8926-88DC-460D-B56E-098193170D40",
    "11708.pdf": "A7F3011C-60EC-4E42-8B0C-97C68027C7AD",
    "11709.pdf": "3B42E34C-12D0-4CAB-873B-0DFA096CB833",
    "1171 Hypoxic Preconditioning Of Adipose-Derived Mesenchymal Stem Cells.pdf": "25C304CC-F267-444A-AA26-74559B1782A8",
    "11710.pdf": "0A2B622B-CE55-429F-BCFA-3BDE9742EE6E",
    "11712.pdf": "1D9BDD1F-B5E9-4EB9-A43D-5A6F2FC845C5",
    "11713.pdf": "BCBB2EE3-64FE-4320-9914-04842E1C3464",
    "11714.pdf": "663D29AB-D36B-4A14-B4CD-8EC27927447F",
    "11715.pdf": "0FF3FF57-682E-44FA-9363-D2920B1181F0",
    "11716.pdf": "3A2C584C-7F3A-4B14-BE64-B3A97F30EDF0",
    "11717.pdf": "221B43FC-437E-472F-A144-B36B414F56A3",
    "11720.pdf": "B68A45A0-9B32-41EA-A9E7-FBF4B781B7BB",
    "11721.pdf": "DC5D25E8-23C3-431C-971D-6F15C34C35D6",
    "11727.pdf": "DA481382-A418-4BC9-8136-CA1A31362FB7",
    "11728.pdf": "6138D8F3-85B4-4654-9AC4-C93661917E1D",
    "11730.pdf": "D0CA7A5F-54DF-49A1-A003-20D709B88F81",
    "11732.pdf": "17CF256E-D462-4609-BA02-B40E4C4A22A4",
    "11733.pdf": "32B143BF-1355-432D-B9B2-8D5A6F8EF402",
    "11735.pdf": "822D1784-F628-462C-963A-AE0C293FE0C5",
    "11736.pdf": "9DAEEED8-148F-41E0-8583-7654B1E5E08A",
    "11737.pdf": "7F628F20-AF63-4F12-A814-D85D1B05C136",
    "11739.pdf": "F84DA9FF-1083-4D61-ACD1-529A78B65599",
    "11740.pdf": "5ADA0511-CAB5-46C8-BA96-4BD01CC04F7E",
    "11742.pdf": "9DB021EC-2A3E-4A92-9B18-4F7A182A537C",
    "11743.pdf": "5C638664-1122-4B39-99FF-35791F17471F",
    "11744.pdf": "A64B1059-1763-4034-8C13-E6FDD3EBC53A",
    "11747.pdf": "6788C73A-2B85-41E0-9F59-CD4BEADEC27A",
    "11748.pdf": "E823745C-27C5-49C1-93DA-0609D64D3813",
    "1175 Mesenchymal Stem Cells (MSCs) Treated Ex Vivo with Bone Morphogenetic Protein-2.pdf": "1D6E2BF2-B737-4CED-B1E8-6E5D62D0A7EA",
    "11750.pdf": "9E836539-641E-4B64-990E-43EDA4AE46A0",
    "11751.pdf": "946F3A71-2AAE-4064-8DFE-8B543028A7F1",
    "11752.pdf": "51FB7374-EB0B-47AB-894E-7031CD605ED5",
    "11754.pdf": "3A79495B-6EB4-4D4A-B30E-5EC287F8BF99",
    "11757.pdf": "8F711A27-8B8A-4738-B0FB-3C75D9DF5DFB",
    "11758.pdf": "3D3F7EDC-C5A1-4B77-B341-0EA9667B58EE",
    "11765.pdf": "850E9727-2C5F-4522-AF47-D8E91D6F76A5",
    "11767.pdf": "E8E553BC-C4B8-42EC-8165-EB2EFC7AB2A0",
    "11770.pdf": "B275AB02-AA15-4977-925E-D90210DE8E22",
    "11772.pdf": "412DC4CA-422C-417A-AE44-5B9E4FABD22C",
    "11773.pdf": "CE27682D-CDC9-43DF-9976-22FC454294BF",
    "11776.pdf": "F99EC35E-FFE6-4C73-A0E6-F1F0F6ED54ED",
    "11777.pdf": "7A2E2FA0-ED8B-44C4-8437-81A6A7C961A7",
    "11786.pdf": "CB971BD5-AD9F-4D7B-817A-7396D8DB4CF1",
    "11787.pdf": "1513C4B6-F271-4271-B4A5-05F72627F872",
    "11793.pdf": "88A30BFE-D24C-402B-927E-415D771C8237",
    "11794.pdf": "0429FFA5-868D-4072-A904-532A93376F96",
    "11795.pdf": "BE04743A-F313-4801-8DE7-FBA19B76672D",
    "11796.pdf": "A795CD08-C542-403D-8BD6-AE05C80EF9B5",
    "11799.pdf": "4A1D5773-661D-4875-B36A-4FC6465284CC",
    "11802.pdf": "1CF11A9E-9FAD-4291-BD84-FB273EB91A46",
    "11803.pdf": "2C11FCE7-291A-4156-928A-CAD5712D416D",
    "11808.pdf": "8043085A-0173-4A38-838F-7F19AE1B9476",
    "1181_NEWSLETTER.PDF": "ADE705E8-2F9C-458D-B0FB-C47809F79019",
    "11810.pdf": "A321C2AD-2482-4FAB-B7BB-2C72B2C2EE2A",
    "11812.pdf": "DB83DC36-B521-40D6-AAAB-904114EFD546",
    "11813.pdf": "EAD0FE36-BA1B-476A-A4E8-64D15C275F0B",
    "11815.pdf": "7E3CB4B2-4A9C-4915-A674-89FE01DCCDEF",
    "11816.pdf": "DB957DF5-ED22-432A-9A9F-9425AA378C63",
    "11817.pdf": "65DDE2AA-5FDE-4B0F-9533-AE57FEBEC035",
    "11820.pdf": "68DE51D7-705C-4DC0-B77B-691AC0B8F55A",
    "11821.pdf": "D73C2482-EFCA-4AF6-A489-F44D90B4D3D0",
    "11828.pdf": "232EB5D0-BC06-476E-8B21-E63A75B12175",
    "1183_MEMO.PDF": "76A46808-325D-45FC-86A8-37D72437702B",
    "11830.pdf": "BC5C0569-F5C7-4B99-8B62-6F013F09AA2E",
    "11831.pdf": "F725CF3D-60EB-4F91-AB52-A54BB143FAFB",
    "11832.pdf": "E55D2F9F-93F0-45DD-9725-F05EBB69DBC3",
    "11833.pdf": "4B7C470E-356A-4253-9B95-B9575BDB2E5D",
    "11834.pdf": "0E26A6BD-4103-4078-923B-D63EE33E18BA",
    "11835.pdf": "A6F2F112-C02C-4294-BF75-91213FF0172E",
    "11836.pdf": "E6101529-2BE1-4F17-8F23-DB0838290DE2",
    "11837.pdf": "2D09D4DF-8A61-44F2-BE01-505667FAAA2F",
    "11838.pdf": "F9F7A805-3724-43B7-B044-E4D798B65207",
    "11839.pdf": "C4297DE8-7C3C-495F-B614-7FDE9E041BF4",
    "11845.pdf": "BDA92540-BBCF-46BB-AEFB-9F2CC9E5A1E2",
    "11846.pdf": "54A48536-7585-4F05-9819-57E9FDDFE463",
    "11847.pdf": "17CD5750-07DB-476A-AE93-66732C47626D",
    "11849.pdf": "71874204-739C-4A32-8004-29FC2FC239ED",
    "11851.pdf": "44E0E6B8-0071-4076-90FA-70B77CD98706",
    "11852.pdf": "BD3DD841-39F5-44EC-8914-650AEF58B9C5",
    "11853.pdf": "63F90CEA-4576-4ADD-B27D-3A13D7D7A010",
    "11854.pdf": "AFB335A1-12B1-436B-9B7D-49EAEC99588D",
    "11855.pdf": "CD1DB1CC-7B16-49E6-AE0C-2E0D8239DEE9",
    "11857.pdf": "33BE10A3-F81F-40CF-91AF-188413846AFE",
    "11858.pdf": "85B0002D-6570-4357-9B30-499F84685353",
    "11859.pdf": "1CCAB3A7-3ED1-4FB0-948B-C8DF1AB7DCE0",
    "11860.pdf": "F0B64EB8-2908-477A-B9C8-FFCD9D6F3455",
    "11863.pdf": "BEC78078-7D94-4B45-B55D-40ED69B88A19",
    "11864.pdf": "82E8B505-192E-49C2-8E34-EC717A677510",
    "1187 CNS LBP.pdf": "9079FE3F-1D13-4BB6-BA86-CC93D8602BF7",
    "1187_MEMO.PDF": "39B8AA68-2EDF-4FC0-A48B-606DB4D17E9B",
    "11871.pdf": "09EAE754-AC26-4FA5-BFFF-2BAB3A13FF29",
    "11872.pdf": "B99E098D-B46B-4A3B-9B71-D85BB71D8907",
    "11874.pdf": "3B59D2EF-07E1-43BC-937B-97DD1C4A3871",
    "11878.pdf": "833A700F-5ADC-486D-83BF-16F9D42495DA",
    "11879.pdf": "FF9C6982-8CA4-4A63-AB31-CFEEEC5853D6",
    "1188_MEMO.PDF": "C36389FF-F680-48BF-B278-A6C9D961A30A",
    "11881.pdf": "61EEEABF-3AEC-4D6C-989B-454FDC34D43C",
    "11884.pdf": "94F92ADD-C0DB-4F1A-82BD-950C096FE00B",
    "1189_MEMO.PDF": "2F8C4B44-83F7-4312-87CC-531328886EB5",
    "11891.pdf": "4B320CD1-0A9C-431B-B86C-55F28F4BE7B5",
    "11893.pdf": "AD872DB5-E951-42D4-BCB9-B764AC5BC93F",
    "11895.pdf": "DF5C012C-03A3-42A3-A951-25DD81F80086",
    "11898.pdf": "CC7F2B90-3DD2-4B57-A24E-DAB8043EE13A",
    "11899.pdf": "45D2407F-CD1A-4D22-97EF-664E456F3DF2",
    "118th CF Letter FINAL.pdf": "7CE326D0-5309-4403-9E2D-F5C0D4E2ACFD",
    "11900.pdf": "C2DB54C1-8881-4766-B184-85EFFD922B08",
    "11901.pdf": "2CC71EC7-4AE8-4F06-B71F-D927DE70F9CE",
    "11902.pdf": "70FB0505-2E77-4862-B82C-7FE026A21ADE",
    "11903.pdf": "44DFD372-D4E3-4839-8B10-DD2D0D8A2C67",
    "11904.pdf": "CE7A11EB-9426-4634-A7B8-54AE4F4CB1B6",
    "11905.pdf": "4B137551-7A53-49FF-ACF8-400188C464C1",
    "11906.pdf": "C7814603-142E-402D-8DD1-20C8529F353A",
    "11907.pdf": "80978BC8-8522-45B3-8DC9-A10E9A1E239F",
    "11909.pdf": "9910EFD2-C732-4FB3-BFEA-4F20EEE9BD93",
    "11910.pdf": "097F1CA1-C46E-4492-9313-F39D760E33DA",
    "11911.pdf": "DA4F7537-BA18-4A0B-BFC5-F6BB5F8F080C",
    "11912.pdf": "621F1A7B-907A-4E59-B372-9E93AA002ED6",
    "11913.pdf": "D438E736-4DE9-42EC-8B37-79047C3DEBFD",
    "11914.pdf": "A6DA8CEB-3135-4F02-B7D4-545E3CCDD444",
    "11915.pdf": "27CBDCAF-6D29-4341-A712-720B092DF7B8",
    "11917.pdf": "6DF6B57D-2471-40FA-BB95-D9B5185B44D7",
    "1192_NEWSLETTER.PDF": "C751A2C7-EDBA-457A-B5E6-0AFA355DEB24",
    "11921.pdf": "36FED8D1-3A39-4B6F-B437-02F006BC4F26",
    "11922.pdf": "50861630-9B2A-44BE-ADE8-AB2E8FB4E7A5",
    "11923.pdf": "8A3A2EFF-7C26-4697-9CC6-50FE6D5AD9B7",
    "11926.pdf": "F0117539-B8DE-494C-AFE1-948F19B2FD41",
    "11927.pdf": "A744212F-7C07-4535-AC24-48243C10E196",
    "11928.pdf": "0B34AEBD-3E6E-4E75-A52E-FFE1FCFE491B",
    "11930.pdf": "40B31ADC-911B-4E55-B923-D9A8B595EF64",
    "11931.pdf": "A1EC582D-D549-49BD-88E3-9ED97FE6985C",
    "11933.pdf": "54C8C708-06A5-4B33-971F-26ECAEC9D3C9",
    "11934.pdf": "46F12846-8F42-4715-B4F6-CF73137E6B5C",
    "11935.pdf": "588A2353-86D3-4902-819E-D47A75365251",
    "11936.pdf": "14BDC5A9-1076-4DDB-A6BB-3B27149D744B",
    "11938.pdf": "A7098D03-E92B-4541-B110-F487F1E4BF33",
    "11939.pdf": "7E7FB0EF-F291-4BED-A711-13CB57FA9F0C",
    "11941.pdf": "F0A72D0F-E3C2-492A-A726-B14F90AF8809",
    "11942.pdf": "E45FDB16-F7BA-4364-BD36-4C02163BDCC5",
    "11945.pdf": "4EA54438-BDAE-4268-967E-84BA0131DD27",
    "11949.pdf": "84A518DC-AE36-4734-8F5B-D9B6859DAA78",
    "11950.pdf": "10E8DB3E-D4DF-41DF-9E63-F110B6EBC836",
    "11952.pdf": "CFF4EFEA-964E-444D-AE49-9ED63E410E08",
    "11953.pdf": "90A8356A-4620-4A54-A6CD-F93A57112C2E",
    "11956.pdf": "AC562A40-5122-4ABB-858A-5EFEE3C4D0FC",
    "11957.pdf": "B0EA597A-6611-46CF-B582-2F30FDBE141E",
    "11958.pdf": "653FAE21-A3B1-4014-A1CC-1340CE217D82",
    "11959.pdf": "C3B3E295-864D-4AAA-B270-CA81D12225A6",
    "11960.pdf": "121FDE30-A7A5-47D3-985D-E272962EA7FE",
    "11965.pdf": "7526E2B9-2B0F-495A-B3FC-28D1A28FAD36",
    "1197_NEWSLETTER.PDF": "7C095844-E7BB-4C8C-ADDC-4DAFFB494819",
    "11970.pdf": "DB94A830-E0DD-44F3-A346-20D455ACADB8",
    "11971.pdf": "6B1A7F70-26D5-4E59-9D05-3C99E152ED36",
    "11972.pdf": "6375F65B-F217-4D6D-87AA-97332AAF8825",
    "11973.pdf": "80BCC3DE-EAE9-4051-8FB0-9D112E525B14",
    "11974.pdf": "E4AA5F54-73EF-451C-A224-A1929BF3B5A1",
    "11976.pdf": "1D0CAE0A-17E1-443C-AB00-82253FBE5C0A",
    "11979.pdf": "2C4EB137-C566-44C7-934F-3EF4FA18157C",
    "11980.pdf": "A3758BBB-8DFF-4639-BFC4-043616FC817B",
    "11981.pdf": "6C2830E1-6468-4D83-AF55-F4F5115F52C7",
    "11986.pdf": "6ABE9E93-72B6-4593-A92D-B6DD14EA6538",
    "11987.pdf": "1528486C-8592-434D-B8E1-D8F7061DBD54",
    "11988.pdf": "AE4867A3-5866-4EA9-99D2-8C8D8F5D4695",
    "1199_MEMO.PDF": "05ED4573-F573-4CD8-A85C-857266071376",
    "11990.pdf": "7A5F2416-E116-4F73-9BE5-ADD6B389486D",
    "11992.pdf": "7DFF76E5-B6C6-4316-BFBF-D210607DDA8F",
    "11994.pdf": "73F65AB8-2D2D-4062-A95F-BDFAABFE8A4D",
    "11995.pdf": "46F04F0E-7394-4BFE-A702-70A9B7FD9061",
    "11996.pdf": "FFB143E2-B731-4C7E-95D0-EA1B8F4F6CC3",
    "11997.pdf": "1B16CC1F-3481-44E6-B24D-73AA464F382E",
    "11999.pdf": "F3C01491-48E8-4D76-B56A-438B14EBF08B",
    "11Historical Synopsis of the 2011 Academy Annual Meeting.pdf": "D42650D3-22AF-4ED8-9BFE-9B0E34309921",
    "11Program.pdf": "5F46D9F0-6494-423A-A053-17C1FB85E026",
    "12 - Pedicle Screw Fixation as an Adjunct to Posterolateral Fusion for Low Back Pain.pdf": "12FB6A12-2EA4-4D87-8487-1FC48DCE7757",
    "12 tips twitter.pdf": "06F2B702-0000-470A-93AC-3933D023ED7C",
    "12.13.2023 - Final - Reps. Miller-Meeks Bera Bucschon Schrier Medicare Payment Cuts Letter to Leadership.pdf": "051FB34E-857C-4968-8E18-84D8B3672DD8",
    "12.4.20 Ad Hoc Group for Medical Research Letter on NIH to President-elect Biden.pdf": "CDA0CF0F-B124-49D7-BA1F-A1FAB32D053B",
    "12.pdf": "1E370B06-C110-4B55-9F0D-3EA1ECA14F2E",
    "12_21_21 DBK CV.pdf": "189E30D3-15D3-42ED-A91C-973CD3BF6EAC",
    "12_23 Newsletter.pdf": "FEF7D2FA-95F0-4C07-9D89-B7F852141EC7",
    "1200 030624.pdf": "6CDBE276-F15E-4F44-A2FC-ACD47DDE297B",
    "1200_PROGRAM.PDF": "232DFDD6-3CF1-46EE-9EFD-C4E6E256F359",
    "12003.pdf": "D1B7C6DA-C26E-4EF3-99E1-CFA5C3B174D6",
    "12004.pdf": "AE1B4168-26FE-41A4-98A3-6DC9794551AB",
    "12008.pdf": "C4302F18-C201-4D0B-BA28-704A30AD40CD",
    "12009.pdf": "F4FBEB49-2A3F-4BC9-8919-BB0AEFF678B5",
    "1201_NEWSLETTER.PDF": "D0A7A1F5-FEB9-4319-92B9-168B46DABF86",
    "12011.pdf": "011DBFB8-15B3-4225-95B6-AD54BA9964E6",
    "12013.pdf": "B4C1EB8F-D102-4CAE-A23A-2600580FABAC",
    "12016.pdf": "B78F1274-7EC5-42E7-A47F-91AAE9EB21D1",
    "12019.pdf": "4ABFFA3E-C501-473A-9362-93EA5A4A922D",
    "1202_MEMO.PDF": "C532883D-7BAE-458B-8483-41C6F2483B7E",
    "12022.pdf": "9D932A5F-DF41-413F-88BA-A7039C6808CE",
    "12024.pdf": "9FF38117-62DF-479C-8B46-5D49EDBDD053",
    "12025.pdf": "FEFA5CE0-E579-4BDB-9E43-3EE684E50FCF",
    "12026.pdf": "947CBD20-49FB-407F-AC5E-8AC74259982E",
    "12028.pdf": "AA46CDF3-01AD-4A56-9981-553630843E79",
    "12029.pdf": "4207DC10-3C85-49CB-ADE5-49512461FB98",
    "1203_MEMO.PDF": "3160DE90-DB27-4D1B-B42F-9B3450864012",
    "12033.pdf": "32248F54-5CEB-4BEB-8B9B-490B92A78CB3",
    "12036.pdf": "ECB46353-DA97-4AA1-B413-B9EF92563DEA",
    "12037.pdf": "D3A790D6-F4C5-40C4-A38F-D62BEB21B8FF",
    "12038.pdf": "3F48DBF4-5451-4878-BCE9-782893638D1B",
    "12039.pdf": "A23F1DE1-B1DF-420A-B185-C50F6FDE47EC",
    "1204_BY-LAWS.PDF": "1F4F7805-EC02-434A-846B-44DFA89A70AA",
    "12040.pdf": "2DA64CA1-4EBA-4947-969E-EC2F1FCA2236",
    "12041.pdf": "4555CE8C-F83A-440C-B920-53704BDD1FCF",
    "12042.pdf": "FF39D196-632E-4EB2-AFF5-7345C26ECBC0",
    "12043.pdf": "0BDC43FF-1E54-4F68-A3E0-9DD778076217",
    "12044.pdf": "8A3E96C6-4C09-4441-8F22-21BE8B7AF4C4",
    "12046.pdf": "14C1A8C9-E5BC-411E-85C0-EA55C0B31705",
    "12047.pdf": "8642F028-081C-40E6-AA24-8DCE48D613FA",
    "12048.pdf": "081A8098-29B5-40FE-B39F-FBE4F178D713",
    "12049.pdf": "50F84CCB-2319-45CD-AA60-D87D106F45BB",
    "1205_PROGRAM.PDF": "1BF2843D-9C97-45B7-BE71-000C035F2CD6",
    "12050.pdf": "5C0390FA-6364-47B0-9305-CCEC2E920FD5",
    "12051.pdf": "9D544C69-94B1-46B4-B202-B853502273D2",
    "12055.pdf": "DB1A8C94-12F3-4DAF-B7B6-979EA182DCA4",
    "12056.pdf": "D8D271DE-125D-4491-8EEB-7ACC4ECE5E42",
    "1206_PROGRAM.PDF": "3C377448-854E-4B2E-ADBD-B89612EA4CD7",
    "12061.pdf": "0650F022-BFB0-4FFE-A387-3A0A5E371A75",
    "12063.pdf": "43873E9E-BC8B-4507-8EA2-4E0E55383979",
    "12064.pdf": "3DC0FD05-0D26-45EE-916C-4F1289D072AA",
    "12065.pdf": "F545C466-BD9E-462F-918C-EE5666862B88",
    "12066.pdf": "4C261486-DE47-410D-9F8B-19BE49257651",
    "12067.pdf": "16424CCD-5B00-483E-ACFE-761B9EEB00F9",
    "12068.pdf": "42108930-4424-479B-8B40-A2CB110E3AE3",
    "12069.pdf": "D7E97026-C25D-4C6C-A0E4-6053F549C12D",
    "1207 033174.pdf": "A578FBDA-BFEA-44B9-90F4-A59FFF11DDF9",
    "1207_ARTICLE.PDF": "5729DBF0-DC0C-4EA7-8672-14F71EBF6048",
    "12070.pdf": "A85AE0B7-327D-492B-8699-BF20489312EF",
    "12073.pdf": "607220BB-4234-4783-A73B-081598B4A54C",
    "12075.pdf": "F57A0EE5-F035-4357-83EB-96DCCF520ADE",
    "12078.pdf": "23A178C2-5D87-45E1-A0B5-F75A2C1F90A4",
    "1208_PROGRAM.PDF": "F8F4D5FA-E1DB-4CCF-B06C-99C833F669B4",
    "12080.pdf": "7D248637-42D0-4998-8374-032CC4C6CFA8",
    "12092022_CNS_Poster_HATCH.pdf": "37A7BA41-A499-4447-BC7C-AD1970473CA3",
    "12096.pdf": "ACECE6BF-4BD6-4ADC-B2E4-5762AC5C4B84",
    "12097.pdf": "EB33D866-A7D5-47F1-9866-CE162676338F",
    "12098.pdf": "E84F8624-C27A-4F01-9CBC-B0AD355E5D7C",
    "1210 Vascularized Bone Formation By Freshly-Isolated Mesenchymal Stem Cells.pdf": "95E9B6BC-0D72-491C-BF10-8E566306CBD7",
    "12107.pdf": "CC4AE22B-3713-4686-8658-B10596D6510C",
    "12109.pdf": "3FA027DA-15EE-46A0-B3A9-93888ADF3E5F",
    "12110.pdf": "60D47678-9261-4811-97ED-F594057CFB1F",
    "12115.pdf": "C50C853F-0A88-437E-8521-016B5827730B",
    "12116.pdf": "7EF2F75E-4638-48B3-9749-D870C759FC1B",
    "12117.pdf": "872A9285-035C-48A0-BD37-1707D1981573",
    "12119.pdf": "864F5B75-EC1C-4A5C-A64D-B082463C572F",
    "1212 032194.pdf": "7C342679-9033-4AED-8CE2-432E0D8F9E9E",
    "12121.pdf": "84421264-9CE4-4A10-A470-0C0E6F766B2E",
    "12122.pdf": "3901EB83-EF49-4FE0-A097-CAE9C0C14728",
    "12123.pdf": "FC4C6460-A211-4638-9BE1-477C887E38B1",
    "12124.pdf": "8C8D82CF-8A2B-4065-B5C7-0B422FBACC4E",
    "12126.pdf": "A41CE18D-1D38-4A9F-83DF-180ADF57A41B",
    "12128.pdf": "ABE60BDE-57F2-4AC0-B4FB-0159C364F418",
    "12131.pdf": "D54BE542-3564-4E35-9BE8-5F43D0DE5DD3",
    "12133.pdf": "A4590F6A-479E-496F-A646-56F69D55EABE",
    "12134.pdf": "17041152-7175-4254-AFD3-FF5EDCAF83DA",
    "12135.pdf": "4C63DDFE-4240-40B1-9105-3C577E3B50D1",
    "12136.pdf": "4B4B7BFE-F7B8-4758-A603-BBE817411081",
    "12137.pdf": "400323CC-20A9-40D2-9B7C-5379A743498F",
    "12139.pdf": "E800B843-9B5A-45E1-9932-2F17BA67E5C6",
    "12142.pdf": "BCC43281-65F3-4FED-9505-2352BBEE5A74",
    "12144.pdf": "F0C6C023-1B37-4AE1-900B-7AF891B37702",
    "12147.pdf": "1A9CF618-D266-47EE-BADA-017ED95334CE",
    "12148.pdf": "61ECC01D-B797-4E6D-8EF6-11F8A3EBA604",
    "12149.pdf": "229AD745-730C-40C3-B466-778A5B28DBC2",
    "12155.pdf": "6586127A-D196-4123-9853-CB0ED48ADB3B",
    "12158.pdf": "1E4E4EA9-5186-48EB-8950-783A3119360F",
    "12164.pdf": "DF5E2CC2-00C3-42B9-AC43-41733AAFBF13",
    "12165.pdf": "759CD4F4-5D82-4A3D-802B-26FA87F995EA",
    "12168.pdf": "E7E6DB07-8C4D-4315-92F6-70B9E81E27C5",
    "12171.pdf": "FBAD4C96-8B65-42D4-A455-55A2E655B155",
    "12173.pdf": "A6907B5C-47E8-4FB5-9917-9F191300AAE9",
    "12174.pdf": "7AAEB751-386F-4F7E-82B5-DA8C2C0C1981",
    "12176.pdf": "21B94EEA-80AC-4757-AEDE-AEE9BCAB80FB",
    "12179.pdf": "1ADDD08A-440C-4F1A-A419-FDAE0B56B259",
    "1218 32970.pdf": "2CD79DCB-8EF0-4331-AFB5-EBB1326FFACA",
    "12181.pdf": "D8CBB7C5-39BB-43B3-A1C6-D89DB45D26B0",
    "12183.pdf": "164AC52F-0286-43C7-97FD-7B48C0DB591B",
    "12184.pdf": "0ED3F444-7BF4-42B1-B3B3-F8A6982820EA",
    "12186.pdf": "21D6DBA4-3C99-4933-B17E-C6FA0BFF5B5C",
    "1219_ARTICLE.PDF": "9C1850EA-A851-4FDC-8C96-9408946157D7",
    "12190.pdf": "2DD455CE-C2B3-4507-AA22-3D32528F0ED0",
    "12196.pdf": "DBDEAD66-8DB2-4C70-9BB5-46B5D87638BC",
    "12197.pdf": "D71B7956-8538-4341-AB9C-58D2BD236122",
    "1220 CNS poster 2019.pdf": "3531731A-321B-4A9F-A9A1-7FA4C5215119",
    "12204.pdf": "DCC3639C-2C58-44B6-9FE3-925DEAE034CD",
    "12205.pdf": "5D3DD022-2DDB-47EF-AA6F-121FF19895C7",
    "12206.pdf": "DF65DC44-2B9F-4D0C-855F-6CCC674B01E4",
    "12217.pdf": "62E66E90-8262-49D8-B6AA-B280FE03A841",
    "12218.pdf": "FD1F10D4-78E7-4B26-8DF7-C35D1CDFDA96",
    "1223 CNS Poster 1223 Leuthardt.pdf": "3087B36D-EBDF-461A-BFED-E62FB5ED47DC",
    "12235.pdf": "F61DC4B4-411C-4195-9B24-CA6816FD8CA2",
    "12236.pdf": "6AE881A4-B149-4CCE-B086-0C86C9C79765",
    "12239.pdf": "8268B611-034F-4442-A567-5FF776D53916",
    "12240.pdf": "D316F2CE-81DA-412D-8968-C9EE45298FE1",
    "12241.pdf": "6AFF68E8-6EAC-4C04-9777-2A9B8DEC49F0",
    "12244.pdf": "FDDDDD04-A571-4706-8DC5-8ABF0B2A54F6",
    "12245.pdf": "46F0F901-50F3-47F1-B150-8598DF6181C1",
    "12246.pdf": "EC130DCA-EEC2-4834-A3DD-2C7E3246BA9D",
    "12247.pdf": "4F32CEDA-C7E7-4CD8-ADD1-DA13BAB10F59",
    "12248.pdf": "EFF41A23-79BB-4FB9-9720-64364A8EF0EE",
    "12249.pdf": "37D11616-2540-4D20-827A-8E520414C0D4",
    "12250.pdf": "D98CB1D8-F6B5-4021-AFD1-CD46A97BCA9C",
    "12251.pdf": "C9539F9F-C74F-4C8E-BA35-0A80B2BAA89C",
    "12252.pdf": "F373A516-E660-433C-89CE-A6C11C47A49F",
    "12253.pdf": "09DA4A76-8687-41F4-BB87-F2D9C0D1BF60",
    "12254.pdf": "2DF55832-5B2A-40B0-AE40-E1AB2680C625",
    "12255.pdf": "39B6FF5D-F6E3-4971-B741-63DF275F9587",
    "12256.pdf": "2CFB1541-AC74-4C65-AAE8-1E0810E39903",
    "12257.pdf": "C0BD5AC6-2266-493B-8B63-F0624A97B286",
    "12259.pdf": "FF002544-34E1-4C67-8AA1-72D5C11D4A96",
    "12260.pdf": "A2FD5E27-ACAF-4F87-A966-EA4B3E921249",
    "12261.pdf": "0CD838AE-DF23-4E33-AA03-3989D8E264C5",
    "12265.pdf": "1D36AC80-DE20-4005-B65E-8109EBC357A4",
    "12266.pdf": "FD301274-59A1-4C67-86D8-8695E9370D77",
    "12270.pdf": "6645C630-9A62-4D80-BA47-ADFA89AD6F49",
    "12275.pdf": "674EDCB4-497E-4D03-8AFD-639C5B8ECD2D",
    "12278.pdf": "4516BBBE-E97F-4A6E-BD05-2600710066F1",
    "12279.pdf": "216BFD57-1BA1-4433-8909-62C052A63220",
    "12281.pdf": "3509454D-6468-423D-AE5F-634D0FA44CEA",
    "12284.pdf": "3B045EC6-21C7-4886-963A-F6A85299C43C",
    "12289.pdf": "8E185156-A469-4C01-B1DF-4FD9C8A55AC5",
    "1229_MEMO.PDF": "BFA48556-4338-4746-9B7A-1E4017B52DD1",
    "12292020_Mayur CV .pdf": "A3F236A8-C5D2-4520-853C-FE3301D62802",
    "12293.pdf": "41925293-6C57-4156-950A-3C69470AD609",
    "12295.pdf": "BCBA903C-3E08-4E44-BE2F-9FAD5183E615",
    "12298.pdf": "31DBA016-B891-4BE0-9BBD-88C60BE6BFCE",
    "1230_NEWSLETTER.PDF": "1F3FE1A3-613F-4DBE-B8FE-B8DBEBC4D6E3",
    "12300.pdf": "5B1D0611-F489-4930-99C0-DA5B50FD248A",
    "12302.pdf": "E702EE33-F42E-4071-AC15-786D3A8EBA74",
    "12303.pdf": "A59203C6-CF0A-4559-815F-4E15A5767689",
    "12306.pdf": "2652AD82-C166-429E-81A9-B88561B590D9",
    "12308.pdf": "6DF2B973-0B54-4C4C-8227-40E91AE12055",
    "1231_MEMO.PDF": "3DAF54F4-D68B-493D-B88F-229B6080275F",
    "12311.pdf": "46B4EFE8-B9C0-466C-8EBC-64B3B2CE3FB4",
    "12312.pdf": "B7224221-14FB-473C-AEAD-D4E7F911DABC",
    "12313.pdf": "A70C3320-2DF9-4935-A524-755F729DA4CB",
    "12316.pdf": "5E40F374-3A74-4778-9938-BBA0F0EEA44E",
    "1232_MEMO.PDF": "6A9D4EAB-27BC-4274-AD33-332730AED25A",
    "12320.pdf": "8044BD58-40AD-4C53-A0BC-36DAEB1C2084",
    "12321.pdf": "709F3DFA-2D21-490E-9BD5-4460212DE8B2",
    "12322.pdf": "126F95BD-0E74-4B66-92B5-A60500BAC337",
    "12323.pdf": "EC912451-CF59-455C-A948-E91290DF5946",
    "12324.pdf": "C999B89C-82A6-44A7-9A00-D473210CEF6B",
    "12325.pdf": "4376C7FB-5C31-4621-964C-2CFDF8E02FA1",
    "12326.pdf": "338753F2-07FD-461F-A191-29108522CB39",
    "12327.pdf": "0D7C7E7F-2858-4080-8ECE-DE2AB6BA702D",
    "12328.pdf": "6D58ACCF-2349-4497-872E-11000B17BFF8",
    "12329.pdf": "C72E997F-103C-4434-A68F-8679839DDFF6",
    "12330.pdf": "6ECBBD30-ED45-45DC-AE1B-9FB33AE7E113",
    "12333.pdf": "809C730C-4913-4DF1-A8E1-DE4984BCEFDE",
    "12337.pdf": "409BFD45-17A3-4EB0-A391-683105389B6E",
    "12340.pdf": "B7994753-6AAA-40A7-B73E-716E9A4C3837",
    "12342.pdf": "BB1FFAE9-9EBB-48EF-A23B-41227CE8B071",
    "12343.pdf": "552C395D-79B7-46B1-99E4-A2234468B98C",
    "12345.pdf": "3B6C257B-2665-4121-B60B-1D6C02DEFA6F",
    "12346.pdf": "55FC7AD1-5F64-4376-A70D-4E3448ACD49F",
    "12347.pdf": "88F252E0-D0CE-4D1F-A8C5-A93ADF4CBE55",
    "12348.pdf": "F2F50F07-7785-47F6-91A8-1DA83E9FCAB6",
    "12349.pdf": "FCAABA7A-C299-4807-88B4-1B96D8E429F2",
    "12350.pdf": "57DFAA1D-2367-47BD-9A9F-7E46625F6A06",
    "12351.pdf": "DB6340F7-6974-41DB-A9EB-4488EBB887D8",
    "12352.pdf": "542BB854-023E-4A6B-96FB-6BC53F14FD4C",
    "12353.pdf": "F3892B2D-C643-4AFA-A86C-F78DA07BC827",
    "12357.pdf": "4C54402E-81F9-4900-BF14-AC55BD68055D",
    "12359.pdf": "8361A183-195B-4CE4-86DE-E6ACA171671A",
    "12360.pdf": "3C7A924B-B285-44F8-96BE-DA3EDC64BA1D",
    "12361.pdf": "4C3C2C89-A9EF-418F-A837-CA4FD85E6150",
    "12363.pdf": "28B682A0-384E-4E44-B29E-2A53EBFD89EA",
    "12364.pdf": "802E3E1C-4BD6-4654-8D59-2A5FF4D852E1",
    "12365.pdf": "FDD5B41E-3191-4589-B10E-C786F64D32BB",
    "12367.pdf": "27E331E2-263D-4299-B867-E53430E59F38",
    "12368.pdf": "DB027FE7-612A-4663-A56C-7073551D556E",
    "1237 32482.pdf": "63619987-BB5A-407D-B63C-DF8FF99F6668",
    "12370.pdf": "F8873E5A-89B5-403C-A577-EC62720CCFAE",
    "12372.pdf": "B5E3CFFD-3623-45FB-BDC8-D19593C62152",
    "12373.pdf": "A536B973-777A-4460-A652-8F10AC59F749",
    "12375.pdf": "C7ACB602-9EE5-4185-80A4-4A533C056E4E",
    "12376.pdf": "12B3FDE6-F88C-44E7-80C9-A7CCDCB6729B",
    "12378.pdf": "52D9B8DB-B57C-4DC8-8C2A-68DA0527CFB6",
    "12380.pdf": "99735180-8A33-4B59-92B0-305BA904DDF4",
    "12381.pdf": "1456E479-F22F-4B0B-A387-C7ADB5FB7A2B",
    "12382.pdf": "F625627A-69AE-4357-A54A-160FDFFF51EE",
    "12383.pdf": "BCF7BB5D-0416-458A-827F-DDF7F6738EE1",
    "12384.pdf": "30D4A3FB-276A-44C6-BF9A-1549C194C551",
    "12386.pdf": "E3C828EE-56B2-4C05-ABBF-13EF065FD346",
    "12389.pdf": "79960AB9-03BC-4292-B16C-37BDF7C9EDFA",
    "12390.pdf": "303F34BF-72CF-47BC-BBC9-D1BA4B431CE1",
    "12391.pdf": "41D5B05C-5429-410D-B7CD-A7A00B5589E8",
    "12392.pdf": "29128DE7-1FF9-4921-9D85-827737F7AC30",
    "12394.pdf": "C85AC7C0-8DE1-4634-B947-E5338B1ADD8F",
    "12397.pdf": "8AC9E9BF-7B4A-42CB-B8FE-BFCCD258506D",
    "12398.pdf": "D67739BC-DF15-4184-9B37-B3D76E745B53",
    "12404.pdf": "1C0155A6-478A-484E-BFCA-96FFE3F005AF",
    "12405.pdf": "40513AD6-7BB9-47A7-A209-1E97000D0ED3",
    "12406.pdf": "0C2CA495-87F9-4A08-A6EA-2AF078174990",
    "12407.pdf": "4AB144AF-6507-4EAA-AC39-32E42FBAD089",
    "12408.pdf": "D498A5CF-565F-4CCF-8CD1-6A64A37756BA",
    "12409.pdf": "5C2FAE12-4FCE-4605-9490-E28E33514A11",
    "12410.pdf": "C491D13E-4928-44C8-A0A5-D884EB3ACE4F",
    "12411.pdf": "5BCBA690-9118-413E-AC8E-DFF7AC904D40",
    "12412.pdf": "E3682739-FD1A-4B90-B661-2B7BAD356438",
    "12413.pdf": "90C8CA9C-FB84-4498-8376-D35778B08049",
    "12422.pdf": "9DA09699-25E6-476F-8E7D-503AB081C059",
    "12424.pdf": "26EE63EB-761F-47E0-835F-83C28F644866",
    "12426.pdf": "391B9122-26C0-4CEC-A44D-A36E830DEA95",
    "12429.pdf": "DAA34C8D-45AA-4B8F-A1D5-E115F1F486DC",
    "12431.pdf": "D117F78B-F8FF-47E8-B963-A9B43BF94542",
    "12433.pdf": "6B5CE141-1718-483E-A5CA-EA9AD3D3CFDF",
    "12435.pdf": "90CBA9D7-F7C9-4786-8730-2923DAC2E7E2",
    "12439.pdf": "879582B6-6966-446C-8D60-8E35BDA49731",
    "12441.pdf": "0871C6F2-8740-4E4F-8F09-B087115EB720",
    "12442.pdf": "7DCE0A50-4567-44CD-BB89-7772320A6B4A",
    "12443.pdf": "1886433E-B0A1-42D4-ABF9-BEE25B10112A",
    "12445.pdf": "170C2865-E740-4542-B834-BF711CAD9BE5",
    "12446.pdf": "41533828-CBE9-4F16-AF4D-C1A2BB2D1BDA",
    "12447.pdf": "B5CE5F17-D495-43A7-AE40-92A2E1BAF51D",
    "12448.pdf": "1BE4491C-ED28-4DEA-80A3-6EDF3B240810",
    "1245_PHOTOGRAPH.PDF": "134FDAC3-581E-40C0-AA9E-49458C6A527A",
    "12451.pdf": "D3DC7DDD-C075-4571-8989-78FF9D0B6822",
    "12453.pdf": "6D31BDA2-43FA-49E4-ABDF-1DC725BD902F",
    "12455.pdf": "F03A1FC6-37FC-41B8-8D99-2C80CFF5391A",
    "1246_ARTICLE.PDF": "6B64FD0C-A08C-4B8E-B156-096DBDD6230F",
    "12460.pdf": "97D50B04-C288-4DAF-8ACE-0B7BF9552A7F",
    "12462.pdf": "C5467114-414C-4FDB-82B6-EFE938B9B099",
    "12463.pdf": "AD35B8D6-8619-4EBF-94EF-A86E69358C40",
    "12466.pdf": "FB2BAA23-CFA7-498A-ABA3-1391080DE0F9",
    "12467.pdf": "C192B2B2-0C4A-438E-9DDE-F795F3DD8B82",
    "12469.pdf": "5D6AFD6E-AD83-4895-8FE6-976C3555D2D5",
    "12470.pdf": "23303728-C823-48CE-A853-46B1B71C201C",
    "12474.pdf": "D0549A6D-D893-437F-A34D-0A1F1296D413",
    "12478.pdf": "02E91358-24BF-4D6D-B51D-1702EAABD68B",
    "12479.pdf": "52E3EC93-0686-4F93-9325-5E62CF90C1C3",
    "12481.pdf": "245F9354-4690-4BE1-9B11-D6E8296A5C51",
    "12482.pdf": "FC6EF791-1CE2-4C63-B52A-C257E3FCED32",
    "12483.pdf": "FA1B3E65-95F2-4957-9094-7602ECA386A7",
    "12485.pdf": "D85E75A6-01C1-421D-942A-DE8777C04B7C",
    "12486.pdf": "99AE26F0-4E27-41D2-9D8A-A1CBD54CF0E6",
    "12487.pdf": "38E570B2-D185-4CA5-8C71-4C57F2892A5D",
    "12488.pdf": "89DC8906-FC84-4C61-B78E-11F597894A5E",
    "12490.pdf": "BB8EB6D1-165D-47A9-ABA6-9119298377F7",
    "12492.pdf": "29A11BB4-A4D0-4BBE-8A08-592E9DC0ADA2",
    "12496.pdf": "5887BE44-6A17-4F35-8FD9-77E2AF49F033",
    "12498.pdf": "1BAB435E-972E-4D25-B073-EF6BACEF812D",
    "125 TBI and dementia CNS Mezzalira.pdf": "C568EEB9-B870-410D-9C40-7ACFA5051F67",
    "12500.pdf": "D6C2F68A-D686-4B8F-839E-20C23F0C7FBD",
    "12502.pdf": "83B3C5CD-6CB3-409A-B6F8-94F1FD9F6DCF",
    "12503.pdf": "4D45E7BB-7EEA-41C6-83E6-E28F2B383270",
    "12505.pdf": "5A9BC0D7-1968-417B-A864-09D9804BC5B6",
    "12506.pdf": "73B1DFCB-1FF8-4393-85FB-57E77875A660",
    "12507.pdf": "AD9425FB-D6C4-4049-81E1-2B533011F1F3",
    "12508.pdf": "87A5DA41-EADB-4E1C-837E-D4B4A8CD9950",
    "12509.pdf": "F84F343B-78D5-4592-A147-D207612C32EB",
    "12510.pdf": "49F58F43-E688-4191-A770-F8F61220EFCC",
    "12513.pdf": "539BAF24-A4D0-4E50-8670-EF6DB1CF7594",
    "12515.pdf": "1BB21B08-9D5E-44D3-9193-39EF3880E189",
    "12516.pdf": "19B435CB-D145-43B4-8483-39EE9D8B0630",
    "12517.pdf": "D6F61491-DAD0-44DF-A298-7C28C52303C5",
    "12518.pdf": "DE850907-F986-4446-BB78-D5522E216539",
    "12519.pdf": "4AB4842B-4098-49E7-A1CA-F5E1691EF5B0",
    "12520.pdf": "A6CD5CFB-BF05-4709-AA21-D1DDCACB5A32",
    "12521.pdf": "DF95834C-BBE1-4457-8E1A-AD6D28B7D916",
    "12522.pdf": "54A7C99B-04CA-4500-A686-CCC3A084B3B9",
    "12523.pdf": "78E19962-7121-459C-8BEE-9AF3F0F1B78A",
    "12524.pdf": "5B183DAA-FC9B-466D-AB42-BBD04F7FF364",
    "12530.pdf": "9ECFD777-50CE-4188-AE91-2D2597B7A363",
    "12531.pdf": "AD3DA747-9355-4D6C-BE08-0BE3B077DCB2",
    "12533.pdf": "56C6D17B-FE1B-4231-830B-16A6CC0C0C70",
    "12535.pdf": "B1AB6FE0-DA20-4127-8ABC-FDA8907F1E8E",
    "12536.pdf": "45F9D7CC-F964-424B-9B65-187A565A4E6E",
    "12537.pdf": "AA496B6F-53F8-4115-AA73-7B37B0E1A5BE",
    "12538.pdf": "F618AE98-5A5E-4196-AB5B-50DAECF0FD81",
    "12539.pdf": "8AAC4488-48BE-4DBC-8F74-17840BFAB38A",
    "12541.pdf": "CFB3C303-7CC5-483E-89B8-6F42C6DF4F54",
    "12542.pdf": "9B500287-4E4C-483E-A04D-EAB977F5A15C",
    "12543.pdf": "9B156ABA-E10D-4C1E-A057-174FB4A39D7E",
    "12546.pdf": "448A80BF-5EFB-41C6-B30F-95B85C19F131",
    "12547.pdf": "2E01EE1A-58C4-480B-A3ED-0552859C05A1",
    "12548.pdf": "C7428C8C-DA6A-4DFF-AAC1-661A9D20E503",
    "12549.pdf": "D96EA507-8E06-4C95-B318-C86947CD4BF2",
    "12550.pdf": "B0305706-2BB2-4C1D-918B-4B739E48DE97",
    "12551.pdf": "9DC4FD62-1A9E-4503-A7E3-D1001372490A",
    "12552.pdf": "49960DA0-A834-4725-AB71-28F1F799A35D",
    "12553.pdf": "2E80C87E-2AAB-45ED-A6F5-769274F26782",
    "12554.pdf": "08418E5C-51AA-4CDC-8E4F-35885450A577",
    "12555.pdf": "B4FC5AFB-0BBD-4AAD-95CB-8EA49E6DEA3B",
    "12557.pdf": "AACA8DC0-7F3E-4135-BF8E-6EF5083EA621",
    "12560.pdf": "C1E83A6B-65DE-447C-8D0F-2F5E05F337F5",
    "12561.pdf": "7B4AAB66-416E-45BB-AFE8-BBD70AE57BF2",
    "12562.pdf": "A6FDBBFC-8045-4E95-ABD3-CC9698139238",
    "12564.pdf": "EE20FCF0-EE50-4E86-B9C4-E40153B34939",
    "12565.pdf": "9621DFDE-15B5-4D8B-9316-A4A9E5BC60AD",
    "12567.pdf": "CD6BA546-AB34-4AA5-9336-EA85D929F49D",
    "1257_PROGRAM.PDF": "8459EC9B-7972-4415-8058-312DA77BFE47",
    "12571.pdf": "67AD08A6-CD88-4556-9297-4CA4F29FAAA7",
    "12575.pdf": "034DFE59-3F56-418F-AD43-5D587E5768C9",
    "12576.pdf": "3845046B-3A02-4F19-A7E1-B93D3F966D5D",
    "12577.pdf": "0521A97B-0978-4AAB-8B7F-7EC2074FFA19",
    "12579.pdf": "0109A28C-E7B8-4CE1-8201-3698D695A15A",
    "1258_NEWSLETTER.PDF": "84F8CC1A-C58F-436D-9AF5-8401992E4415",
    "12580.pdf": "29F1A4A7-5E43-4C2A-9C73-76865A8822F2",
    "12581.pdf": "2B85FC50-73A4-471F-90A6-5C49729C35F6",
    "12582.pdf": "CE38A1C1-B65D-4571-91A2-D4EC67B467AA",
    "12584.pdf": "6A49210B-5D6B-4C68-BD4D-2456FB73F5FB",
    "12586.pdf": "6694CF8F-3A97-4974-A208-6CF34CD69F18",
    "12589.pdf": "E150F5A3-AF55-4F31-8C60-2AD7FF3F8315",
    "12591.pdf": "79FED90B-40C4-423B-9C50-5880EFB30F9A",
    "12592.pdf": "552EF346-521C-4652-8679-E9AA81CEDDCE",
    "12593.pdf": "45C87DB4-E5CC-429F-A2E9-66F2487CB7EE",
    "12594.pdf": "06D77E0C-F53D-4A02-9C06-680BDC54FA50",
    "12597.pdf": "B3A1811A-E1E3-4CB0-BE55-F12EF976176F",
    "12601.pdf": "944E25F2-3FEF-433F-96C9-551A2A33C757",
    "12603.pdf": "674D7DED-70FA-4F0E-925C-7284FFBF72F8",
    "12606.pdf": "8559AF2F-4F81-4B77-8828-3809C9634218",
    "12609.pdf": "B1C37A48-24CE-465C-BE53-C9B5EAD825B0",
    "12611.pdf": "67195C89-E55E-4E64-96FF-2E9D01E359E8",
    "12614.pdf": "329015F6-CED0-4F5A-AAAA-2AD002A7A507",
    "12618.pdf": "5B209E72-0498-4463-832F-F8473070B3D7",
    "12621.pdf": "8C63E307-B415-4021-9D68-97CF5B978893",
    "12623.pdf": "37B52456-9AC7-4E87-B693-10FF52260082",
    "12624.pdf": "F8CBB6EF-53B1-4FAA-A8F6-1BAD9EB4852C",
    "12625.pdf": "2AB7B749-A4F5-4347-92F6-A14E3BD28D17",
    "12629.pdf": "688DC49B-D8B9-42B8-8703-661A12CE4893",
    "12630.pdf": "FFB91C40-D7D8-4705-8D0B-06B2E173FD5F",
    "12634.pdf": "E67310E5-7D36-4454-B02A-50275287AB31",
    "12635.pdf": "462E387C-3C89-4FCE-83F2-2051CBE8A801",
    "12636.pdf": "EBD53D2B-85A0-4946-AAC5-E46BC7994BC1",
    "12637.pdf": "F80B9F6C-CB38-4DF8-A4E8-5EF342450FCD",
    "12640.pdf": "96D2314C-E8C0-4DCC-8253-25DB49888159",
    "12642.pdf": "2A010AAC-D51E-42F6-BFB4-A6DBEEBF8593",
    "12644.pdf": "78C44878-5C79-4C7F-946C-191D6DADF376",
    "12645.pdf": "FBD7F196-9B2F-461C-A901-F2D21C1A5A9E",
    "12647.pdf": "DADF44FF-E80B-4F54-BD89-599B11B987EC",
    "12648.pdf": "44CD633F-4D8F-4E0D-B3ED-79133E5DF18B",
    "12649.pdf": "B0EB207D-C905-4C50-A9B4-B82F9260778E",
    "1265 CNS Poster with Center Picture PDF.pdf": "75B383C3-ABDE-4D2D-81B3-6D0A0801E3C8",
    "1265_MEMO.PDF": "A0BF602B-E90E-4FAC-B3D3-046EFA0D53D2",
    "12656.pdf": "51869722-B1A9-4EBD-A0B5-2BB25CC1EA12",
    "12658.pdf": "53FB9DDE-2FF3-465A-9BFF-BA5A72553F14",
    "1266_PROGRAM.PDF": "3DB5C0FE-6995-422D-BD9A-1D92A594FAE5",
    "12661.pdf": "CDD81450-4944-42E2-BFF4-00CB93E23CE1",
    "12662.pdf": "45CB62F7-7E35-4E0A-BDF4-70272DB81A35",
    "12663.pdf": "98496614-6393-4187-BAA4-C1BE01C19E9A",
    "12665.pdf": "C09DEB63-A80F-4C0C-8430-B6713D3C3C4D",
    "12667.pdf": "E631A688-02EF-4AD4-8BD1-2EBC21E05CB5",
    "12668.pdf": "4EC11887-0FC8-4044-9B0B-21B20CCB9EBF",
    "12669.pdf": "98CDFB16-A539-4C45-9A80-C52B70762252",
    "12671.pdf": "D4C8EC83-B928-44E4-804D-FDDD79E68D17",
    "12672.pdf": "9DDB6D17-535F-4BE3-AC85-8B86019DE682",
    "12676.pdf": "731931A3-8329-492B-96A8-1A2D9CB8E0A2",
    "12678.pdf": "083EB8B8-8A09-4D98-B779-D60A7DAC5C06",
    "12680.pdf": "9F7D41D1-581E-44E3-8E1C-87FEF43ABF87",
    "12681.pdf": "ED565080-C576-464D-B591-603B057C12B5",
    "12682.pdf": "A0A337D5-C147-4FDE-9582-D03030B270A0",
    "12683.pdf": "46A5AADF-897A-4AE3-AFA8-5F6CE0BD7D86",
    "12685.pdf": "4D625076-0945-4CD4-8924-BD3F8AE7327D",
    "12692.pdf": "A6024103-AE19-4166-A2CE-91E8CF59C635",
    "12693.pdf": "1A37ACA2-6BE9-4FDB-ADFC-6D8C7E430047",
    "12697.pdf": "58161E5A-205C-4DE5-8722-35AD8BBF45FB",
    "12698.pdf": "A06C30DF-42B9-4956-AC5B-B74E4E84AD3D",
    "12699.pdf": "4D2ED485-6A92-4E53-AFB4-7A2B3BAA9BFC",
    "127 Nandan Lad.pdf": "90258328-D5D6-4956-8B38-A4939E87038C",
    "12700.pdf": "4F2A4FA4-3C3E-4A12-823B-F9BD38770E13",
    "12703.pdf": "5F91CE10-11EF-4399-870E-8B328AEBDABB",
    "12704.pdf": "5D8D4B7E-BEB4-42BE-91B6-BCBF70111C2D",
    "12705.pdf": "53E4375F-B436-4F76-9FAB-7C1872146C6F",
    "12706.pdf": "346AAF7C-7C5A-4161-A704-CB193757CA04",
    "12708.pdf": "0EB6B15A-94A1-4872-BA0B-582483FF7D2E",
    "12709.pdf": "CE42417B-70A4-4814-8FBB-60C110BC4599",
    "12710.pdf": "83530B6F-1942-4966-96B4-FF6BC05145C7",
    "12715.pdf": "74F9B524-4085-405D-AC91-3CC236F5E9FD",
    "12717.pdf": "BC5FEFDD-6AF9-424D-B80B-E871434B00C8",
    "12718.pdf": "78577A62-C008-4621-97F4-59A4FC07E093",
    "12719.pdf": "B6BBC15D-5603-462B-B328-BEFD41D4F212",
    "12720.pdf": "D9046EFF-7A44-4D3C-8D0B-AF47C1762A43",
    "12721.pdf": "1082B84E-932A-45B9-959D-24575F2FC9CC",
    "12722.pdf": "06D31C8B-27BA-4F4C-ABCD-8C812202CE3F",
    "12723.pdf": "D91C6691-CC3E-41BA-ADE3-87227FC0E3D1",
    "12725.pdf": "896A6C8F-834A-4152-8C83-44C2EDD8ADE8",
    "12730.pdf": "AD952C8D-57EC-4FCE-B2ED-D83E4D730925",
    "12731.pdf": "2D9475AA-82FB-4AD4-9520-D428E1B28CAE",
    "12732.pdf": "34D575BD-1F7D-4566-A2F0-55D9794004B9",
    "12734.pdf": "39268041-26FA-4A90-9E0C-5DC1FDFE08A6",
    "12735.pdf": "2DE9A9BB-87C0-42CA-8A09-31E0FC163328",
    "12736.pdf": "EB1AF176-04C8-485E-B580-C871AE0B53B0",
    "12737.pdf": "9FE981FF-3ED0-4240-9E7C-7B156623D436",
    "12738.pdf": "0674A528-906E-4C79-BD40-B914A167BC2D",
    "12741.pdf": "09C17BBF-62F1-47BB-9EA7-5FB1E68141DE",
    "12743.pdf": "3AC651E1-4EF5-4D48-A3F2-B88859B6D4AA",
    "12745.pdf": "3740B9C9-78F9-4BF6-B604-E352E9CFC55E",
    "12751.pdf": "AF55D62E-22D1-472C-BC8F-D7B4F90C8A0E",
    "12753.pdf": "69E28C7C-E6C3-4D5C-8AFB-8F40D1DACD62",
    "12754.pdf": "EA5293AF-0FEC-4485-8FA8-02A2B4B800D9",
    "12755.pdf": "CB25F6C8-FAD1-48DD-BE7A-73A113DF622C",
    "12756.pdf": "1E015437-2195-42C0-B67C-A194EBAEB571",
    "12758.pdf": "5DB6EE4F-6139-4059-A2D3-9D24A61B7B8D",
    "12759.pdf": "7E2C125C-4BE0-47AC-961F-CDB46667F88D",
    "12760.pdf": "4D185B4E-FB92-4706-90F9-5288DC95E623",
    "12761.pdf": "5D3E07D7-6ED1-4906-B804-401EEB210C80",
    "12762.pdf": "4F91DBB2-6378-4988-ADDE-3EBEC7F8F370",
    "12763.pdf": "38DAEFBD-231C-4687-B23B-89ED1AC21580",
    "12765.pdf": "F397187D-72E5-4968-8EA5-8E5993088E40",
    "12767.pdf": "315AC69B-2BA7-432B-B201-FF767336259B",
    "12770.pdf": "BC1BBF54-0B22-49C6-8C8C-BFBC76F76EA8",
    "12775.pdf": "B91CA45E-5A3D-4E3E-BBA8-971A33FAF280",
    "12776.pdf": "85B46AB9-B2DD-488B-B098-F41ABFE9FA87",
    "12777.pdf": "079A4BD7-F0B3-47BD-8BB9-E6B66FC565ED",
    "12778.pdf": "CDBADE4D-6E5C-44BE-A6D7-7EF4CDE345CC",
    "12781.pdf": "F16E7E71-694E-4938-9522-37F5E6D7F04A",
    "12785.pdf": "3A8295FC-4E15-44EC-9E8E-0A89BD58B11A",
    "12786.pdf": "8DF64A56-8E25-48B9-B3BE-286A24361119",
    "12787.pdf": "5889E9F7-9447-4977-A917-33314A85582F",
    "12795.pdf": "20E92066-831A-4126-9624-534FBE2CA5A7",
    "12796.pdf": "5E97B818-C560-4096-BF40-C8955A1A1B1C",
    "12797.pdf": "17DD3E3A-5364-41CE-847E-02669BFC8D71",
    "1280_NEWSLETTER.PDF": "3DE66595-764F-4551-B9F0-246045D9AC9F",
    "12802.pdf": "BE3340DE-38E9-4636-AB00-B991D415794F",
    "12805.pdf": "229E9127-F46A-4BB1-9684-B38AB774C8D7",
    "12806.pdf": "F448B498-9819-4FFB-9EBA-6489ACC853B5",
    "12808.pdf": "C4F1F4D8-5DD3-4128-91B2-2C58335E142C",
    "12809.pdf": "E68FFC35-3AD7-4383-A91A-8AE21F51DCCA",
    "1281 GKRS poster.pdf": "D8BE0293-E3EB-4F51-A2D6-83166E4303A2",
    "12810.pdf": "90566E49-973E-4538-8593-152C06BB1E03",
    "12811.pdf": "AE4A9661-1E4A-4ADC-9302-AE223D982107",
    "12812.pdf": "A18ADEFA-A665-4A6E-8C29-F2FC93B08EBE",
    "12813.pdf": "4C70C346-BAE0-4B64-B0D9-470EBE98AF0C",
    "12815.pdf": "E6CC5FD7-A40F-45E5-A1AD-EA620B21B4B2",
    "12816.pdf": "E7418594-766C-4D61-9890-B1E5E820F279",
    "12817.pdf": "6B46EEB4-62EB-40DD-B6D2-527B4AA4CD84",
    "12818.pdf": "56BAF5AD-A723-405E-917B-851521925843",
    "12820.pdf": "EF8A64E2-7F33-4210-B569-01C42424FEEF",
    "12821.pdf": "66D944C4-5BDC-4D76-9E9D-061C30DE2B9B",
    "12822.pdf": "4D368545-DB9D-4051-9CB9-C897F119C928",
    "12823.pdf": "345BB698-CC6C-4EC8-9291-295C2A5C8487",
    "12825.pdf": "6CFDA9BC-C68B-4FAC-A23A-79D906586DD6",
    "12830.pdf": "9C556220-20E5-407A-9D68-4CBB73E18AF4",
    "12835.pdf": "B450966B-58AC-4CFC-9314-A5F36ED2EF42",
    "1284 32677.pdf": "36968491-84A0-456B-B857-88BE492CECA5",
    "12841.pdf": "1E88D667-0955-42DC-8CEE-73BE92D5AA16",
    "12842.pdf": "DBE39F6C-7FC4-44B5-8306-0477676A22B4",
    "12843.pdf": "FABDDEA2-A79F-4254-A1E9-3202225AEAF8",
    "12845.pdf": "03C373EA-AED7-4014-9081-1CD4D9EDF7C5",
    "12849.pdf": "17E3E509-203B-457B-8B46-61EE61FD42EC",
    "12850.pdf": "F1B89D7C-D170-44C1-865E-598A014C5D93",
    "12853.pdf": "06973C25-AF79-48C5-A2E2-4F281920A543",
    "12854.pdf": "87F190A3-4403-45B3-83BE-19EEEACF00D0",
    "12855.pdf": "828DF99A-A178-49F4-ABDE-7106BD1BA42F",
    "12856.pdf": "D05BFBF0-1E41-4FC1-A172-3336DC8CF40F",
    "12857.pdf": "0A57D9D7-1F7E-42F3-B511-9A9AF3E11935",
    "12858.pdf": "6F7E3336-1FB1-4629-A5F8-0A42176F01F6",
    "12860.pdf": "359743AB-1F9B-4AFD-AA4E-898999633559",
    "12861.pdf": "96A9A4AA-AAC8-43B0-A6A4-80EBF0FFB4D0",
    "12866.pdf": "2BCD3BF4-7805-4F75-9390-16B5D1089817",
    "12869.pdf": "AA0C15CD-435B-4DA6-A9D4-971D69462324",
    "12871.pdf": "2E115510-7497-4D36-9F2B-5298A05059FF",
    "12875.pdf": "4A6D4D50-F0DE-44A8-B3A7-CCD45729095B",
    "12877.pdf": "8D84F951-29E1-4798-9721-8F5A8DD7BDF2",
    "12878.pdf": "C1C27CA4-83B8-4BAB-A630-6B0FE5D98443",
    "12879.pdf": "052FD44F-0B96-4D41-BB13-21C9FC397AA0",
    "12881.pdf": "6CFD26F4-DFB5-4438-B2C1-5FB84BF92DF9",
    "12882.pdf": "BB5D3E7D-23CB-486E-BD7B-0EA174BA2EC2",
    "12884.pdf": "668FA4A5-0F54-4C31-AD2B-07609DD442C8",
    "12885.pdf": "AFCF12B8-A971-421F-963A-6E6206D91545",
    "12888.pdf": "D34AAA67-BC19-475D-BFE0-39857C2E2D54",
    "1289 32323.pdf": "C64FD572-C1BC-49A0-9C53-9D6780A5F333",
    "1289.pdf": "23C358DD-FE59-4905-9460-1B19D11C508E",
    "12893.pdf": "5DFED336-E391-40EA-AB6C-582DEA9E99DC",
    "12895.pdf": "349C21B1-FF13-464A-A492-EDF32C9812DF",
    "12897.pdf": "4EC4E8F7-5F6E-4B92-86CB-EF83AF4A9309",
    "12898.pdf": "3462FD41-ADD6-496F-801E-1BBD704E6913",
    "12900.pdf": "644CF6A0-7880-4706-9702-D275C6D0CC9E",
    "12902.pdf": "82B74F0B-69CC-40E4-9CF6-962CED3B419C",
    "12903.pdf": "1B5E009C-CB63-41FE-ADED-15426BF3A6B6",
    "12909.pdf": "32F31330-9132-4756-95EF-A87ADF368FEE",
    "12910.pdf": "72281CB3-9DBB-4AA7-98A8-F04652DF8B49",
    "12911.pdf": "F4775BD1-3F70-4BE2-8CDE-0368F8F545D3",
    "12913.pdf": "B080FAF5-6250-405E-8333-6F38BE084454",
    "12915.pdf": "59509BAB-4A60-4115-B786-8D918862FBAD",
    "12917.pdf": "3009E565-946C-48B6-A896-E9E601DAA3C7",
    "12918.pdf": "6F90E579-8F74-44D5-A982-3453C7A28152",
    "12919.pdf": "AAA6BBAC-0572-4858-A497-4EF9A7835FE9",
    "12920.pdf": "7268D933-AC25-4C99-84B0-386753081CBB",
    "12921.pdf": "E3C2B232-AE9F-4D32-AE02-64DA28505D83",
    "12922.pdf": "574F310A-A24F-4BCB-B65C-EC7FBB7F841F",
    "12923.pdf": "A03BE252-4FEB-4211-BA3A-EA69F385E8B2",
    "12924.pdf": "F1ABBA15-A0BF-4A69-A226-D84B236E8576",
    "12927.pdf": "B95D3955-215D-44F8-B59F-8A8E6F00355E",
    "12929.pdf": "907D2AD3-D2B7-4D9C-AA55-9C438659F658",
    "12931.pdf": "5984309C-C54E-4467-BBFD-3DE6F6DE5FDC",
    "12932.pdf": "28141E62-E699-4A15-93DF-90F9687CE845",
    "12934.pdf": "8AF44602-7EC3-4BED-B208-4DCD881B154D",
    "12935.pdf": "F6F47962-004E-4661-A35C-40403CA7CCF6",
    "12936.pdf": "A77BE004-B76B-4A7F-BD67-3F97024C9226",
    "12937.pdf": "F07E0F5B-FF83-4C84-8916-39626DFA557A",
    "12939.pdf": "2E45AE3D-B6CB-4030-B475-3497E29F84FF",
    "12941.pdf": "9C01DC3B-2CDB-4E07-8E57-985CCB7905ED",
    "12942.pdf": "5DC4349B-BCAF-4ACB-AAEE-2ACF9FB9842F",
    "12946.pdf": "F0E1E613-2332-4BE8-B50C-3A0FC8557396",
    "12947.pdf": "B6CE0EB2-C2CD-4DC7-A772-4758CB05827E",
    "12948.pdf": "C22F74F0-A252-483A-AD69-3A24D7D9390D",
    "12949.pdf": "ABF0EBE3-B77A-46C5-A0E6-5C4E314FB2DE",
    "12950.pdf": "EC8C2F2D-EEC9-4338-82DD-EA78160FF160",
    "12952.pdf": "12284354-D209-40EF-8E40-ADDF530B87B7",
    "12955.pdf": "A4406F7D-9EC1-4478-9CCD-8DBA53BDFE40",
    "12956.pdf": "5D17B4E2-7AE4-4524-9929-29B976C8C48C",
    "12957.pdf": "07431A2B-1FEB-45E3-824E-0BBD2D45ECAF",
    "12959.pdf": "225091C8-30BB-4588-9F66-836425539F90",
    "12960.pdf": "A2B53006-6415-45ED-89CF-C94C75929176",
    "12961.pdf": "63F29859-DEA1-42F4-BA0B-E814E80ECC29",
    "12965.pdf": "B37F45B2-C94A-4D41-B4E4-106CCB44A25E",
    "12966.pdf": "4605CFE6-2A96-404A-AF6B-FB4DE45AD8E8",
    "12967.pdf": "51FACD56-135E-4876-AD2F-3044D31754D3",
    "12968.pdf": "24783AFE-EE30-40E8-AF7B-72FCBB9AE4A2",
    "12970.pdf": "A69EF4D5-3AC2-4902-A45D-044AB1652A7A",
    "12971.pdf": "F3BAE30C-E439-4F26-A9BA-9A62B3AD7F45",
    "12972.pdf": "09B8FFF4-CA41-4243-8B01-2719562A32DA",
    "12973.pdf": "0D917015-408E-4E43-9131-546975F54069",
    "12976.pdf": "59CCCA12-0A83-4A2E-8A72-9E34E48884B5",
    "12978.pdf": "0912A092-7CFB-4A56-BFEB-0BEE2516CAF0",
    "12981.pdf": "9BC43B3B-137D-458B-B8B5-5F322CCD3184",
    "12982.pdf": "8CE79234-0989-47E4-8EA6-CEABCFE8659A",
    "12983.pdf": "E6CF329C-5129-44F3-86D1-7186A7AE4051",
    "12986.pdf": "CD4F0DA6-A253-4186-81B9-A155FD72D05D",
    "12987.pdf": "B43B4C06-A6B2-42C6-8FF8-25F1202F465A",
    "12990.pdf": "D18E2D2F-2232-4732-96E1-588CA589BAD4",
    "12993.pdf": "B915384D-80AF-451A-A2E8-2171EEBAE441",
    "12994.pdf": "73E1E974-B6F5-41F1-A688-C000D4BD7C79",
    "12996.pdf": "0406BB08-FB88-43E9-B302-BEE27096CB1F",
    "12997.pdf": "BDAEDB8A-FE26-4D21-AE69-5B8EDB4EB722",
    "12Historical Synopsis of the 2012 Academy Annual Meeting.pdf": "5A3BF646-C893-41FA-AFBA-003386F5D391",
    "12Program.pdf": "786A697F-A2A8-4D95-B6CD-6908BCFC3BD3",
    "12th????Dr. Szu-Yuan , Liu.pdf": "E3D7AB90-F1B6-4881-8F7A-637CF02B2A7B",
    "13 - First Clinical Experience with a Noninvasive Ventricular Catheter Retrograde Maintenance Flushing Device .pdf": "A47B990A-6053-4E3C-BEA7-1A473DCF1D07",
    "13 - Injection Therapies, Low Back Pain, and Lumbar Fusion.pdf": "3DAFF3D0-74D0-419F-8B74-3CCE6BD746F0",
    "13.Deans Letter.pdf": "5F4BF768-2686-427E-9A39-36DDA947464F",
    "13.pdf": "0C1E4D79-A3C5-46CF-8CBA-38F614349486",
    "13003.pdf": "6EB7E810-16CF-4852-A263-B87D2CB38D4F",
    "13004.pdf": "C554023F-3997-404F-85B3-86AF670CDA38",
    "13005.pdf": "45E3298C-F25F-402B-B501-DAE946C2D02E",
    "13007.pdf": "562AA4C5-0C38-41E1-880B-BAAC406E4FFD",
    "13010.pdf": "F89E3665-96B6-4F62-90DF-8AE496A1E7AB",
    "13011.pdf": "AD99A799-2D84-4EFA-AD40-67D49689C25D",
    "13012.pdf": "1C389B90-48A2-4DA2-A965-4BA2A134AC3F",
    "13013.pdf": "7922855A-BA79-4CB9-AFE9-4131238EC7D4",
    "13014.pdf": "CBFF15D3-83B0-4DA5-8327-F107B2658790",
    "13015.pdf": "3BA62804-81A3-49D4-8076-A2A000FE0C78",
    "13016.pdf": "E7CD4375-294B-46CB-93F1-381C938B8052",
    "13018.pdf": "411054D2-4685-47C0-8EFB-A68E970B00BA",
    "13022.pdf": "0DF70BFA-A962-443D-BA0D-507E90E3386E",
    "13024.pdf": "FCC74BCF-5EDD-4C2B-ABD9-6D65B9A6E297",
    "13027.pdf": "26530853-1289-4C3B-B4A6-62F905DA051F",
    "13029.pdf": "5B404853-6409-4218-8FD0-93CF86B02462",
    "13032.pdf": "D1B4D986-9B8D-4A76-8B78-A8A521E04CB1",
    "13033.pdf": "2A71D587-2CB0-4EC9-B2DF-0C29A9E74B40",
    "13034.pdf": "C82D3108-4B5F-4EDA-88DF-272D09434296",
    "13037.pdf": "538B3A2F-60FC-4624-B2C9-AB9ED0F3671B",
    "13040.pdf": "DB5B4ADE-EA9D-46D2-AED6-49051BFB82F5",
    "13041.pdf": "066D462D-1289-4775-9087-C36E763DFFA8",
    "13042.pdf": "8BCC6048-73B3-4006-90A0-39552DD2D57E",
    "13043.pdf": "95F78D07-040F-43C3-A788-48C13BDC44D6",
    "13044.pdf": "E80F1ACB-7E58-46BF-A094-2B66222A5CDB",
    "13045.pdf": "53A0B578-89F9-4E2B-B7E1-8766A8CEFE9C",
    "13046.pdf": "6DE9899B-CBBB-4E77-A676-07AAE372A68E",
    "13047.pdf": "D8D2CAE5-ED1A-4970-9DEB-4AAAA465C729",
    "13050.pdf": "102792D0-DB07-407A-9A8F-843AAA5CD0F2",
    "13051.pdf": "6AEE74F0-1297-4AAD-83E0-9FA559E6C289",
    "13052.pdf": "2FDAB83E-BA25-4C51-95FD-3A803A325BBE",
    "13053.pdf": "A1F42A5B-A3FE-4503-9BFB-1036FBB557DB",
    "13055.pdf": "1D1A4B83-7616-49AF-8F42-7A4BD3CF4C62",
    "13057.pdf": "80FEFC92-F28F-4890-BBF4-4EA154A96CC8",
    "13059.pdf": "1D4CDE7F-0A6E-4EC8-8B1C-0F9C1AACF06A",
    "13060.pdf": "3100010F-AD29-4D5B-AF1C-B34173F7720F",
    "13061.pdf": "265E11C1-ED56-4E82-9CBF-A201A77B11EF",
    "13066.pdf": "B4EDDF3F-6223-41E6-80EE-4F82A1F402B9",
    "13069.pdf": "D92EA38D-EC23-43CE-A658-BB851C3DD1D3",
    "13071.pdf": "D565BA0F-2AF6-45B0-959D-AB3C12C081D1",
    "13072.pdf": "DE79AEC3-8FC9-4753-B9A6-A13AB1DBE1A0",
    "13073.pdf": "6CBB7D96-2740-47BC-9547-F8900D4BCE1F",
    "13075.pdf": "8EED6C6B-8434-4044-9243-E4639CA86D62",
    "13077.pdf": "DA04AD09-E48B-4B80-93D4-8F84A5EFE532",
    "13079.pdf": "E1B0143E-0DDA-4914-AE34-4BD0D569F7F1",
    "13080.pdf": "F7643AF8-87A5-4120-B3D9-DAA79C03F662",
    "13081.pdf": "B69D922B-03E7-43AF-B367-33C717014175",
    "13082.pdf": "113AD3C2-8E31-4D0F-B5B4-61CFCF945578",
    "13083.pdf": "DB19E792-59EB-4630-9897-810F718916D7",
    "13084.pdf": "CF53F1F7-9AEF-47B1-A9CA-D1089ACAF96C",
    "13086.pdf": "E1BF9112-094B-4043-8ACE-287ABE1911A5",
    "13089.pdf": "B366D436-1E24-4B0A-94AF-AE4CF7A34B76",
    "1309 31574.pdf": "8FC9FBA3-4A77-4BA5-860B-0DC214691DAE",
    "13090.pdf": "716DB1DF-BF1A-4C6F-9009-467BF7F4DCAB",
    "13091.pdf": "B6A5861E-ED79-4DDA-AF6A-027453924423",
    "13092.pdf": "B85E9D61-C22D-4782-8D1A-8C9300FFCE4E",
    "13094.pdf": "7E35A601-3CA0-44CB-8BB3-BA5F7EB6A757",
    "13095.pdf": "BB90C4B0-6CB4-4CDF-B077-676EC58A9A95",
    "13098.pdf": "65EB2280-B041-4A8B-9CF0-F6BFDADF6FA9",
    "13099.pdf": "D627728D-8450-4897-A573-8A03E44F30C6",
    "13102.pdf": "E3F62C5B-5B30-4C60-8A99-850BDC8C9D3A",
    "13103.pdf": "0639DEAC-E004-48A0-B8EC-CF433A4574FA",
    "13107.pdf": "D975DA6D-9E11-46A8-95DA-70C6F556910A",
    "13109.pdf": "1AF2A408-31C3-40C7-ACBE-F2F23019D5BB",
    "13110.pdf": "744B4EBF-A6C1-40A2-BD7E-53F369198CC9",
    "13111.pdf": "FAFC0262-5A4D-422B-9353-D29164877FEB",
    "13112.pdf": "45F71977-E348-4C10-88A4-5FDDF2EB0832",
    "13113.pdf": "91DBCBC9-F6BD-4009-A091-8C1613640A61",
    "13116.pdf": "B27D9CF7-01EA-4879-8DBD-EDDBED257F2E",
    "13117.pdf": "F4D75927-6DC3-4CD8-BB91-31C7A1352A7E",
    "13118.pdf": "6B7A26E9-7D60-4859-A95B-E8764011E91A",
    "13119.pdf": "49353233-371A-4F71-87C5-F120238C0A68",
    "13120.pdf": "865EC47D-4C87-4530-9543-87ED491798A6",
    "13121.pdf": "B8B0F1E5-5979-44B5-B78D-6E5EDC800639",
    "13122.pdf": "F8083AEE-7147-4FD7-8C46-A8FDEA7AEFCC",
    "13127.pdf": "80C8B0B4-9031-4DA8-90A1-BE1A8775801C",
    "13129.pdf": "ECADD3DE-5409-49E6-B143-0BFAEE7480CC",
    "13130.pdf": "B7E01A36-FB19-45F8-BB3C-1F57B067B3E0",
    "13132.pdf": "3AFA517C-5BB0-4A7F-9DA4-3548AFFD0BEA",
    "13133.pdf": "B5031C1C-AAA3-42DA-AD3E-C5CED22E54DF",
    "13134.pdf": "A5A53D44-6697-4E9F-B5A9-021470C30C01",
    "13136.pdf": "593B7276-E407-473F-BC5D-EC28687667BC",
    "13137.pdf": "8585D2B6-00A5-4B49-833F-817731E5BA30",
    "13138.pdf": "6E6E4EA4-5BD5-4BAA-9C9F-B039D24B4488",
    "13141.pdf": "941C654A-B513-42F0-A810-C0323D30F154",
    "13143.pdf": "411FB4D3-1BE5-43BB-BEE0-29D524B696B2",
    "13144.pdf": "C8E21163-8C96-4E66-9C0F-51A6AE7C5DA0",
    "13146.pdf": "9B417BE3-5016-4735-B148-A16654FA2ADA",
    "13148.pdf": "C0FAF866-13BE-42F1-8414-57CAA0DE5AEA",
    "13150.pdf": "601163B4-C9A1-4B41-A8FD-2749F1A60F1D",
    "13151.pdf": "9FD14FA5-3171-4E0E-AB2B-A39410416AA8",
    "13152.pdf": "0DDACF9D-735E-4C75-A210-1BF240364557",
    "13153.pdf": "DB13DA7C-AF34-4B71-A50A-F4F92577B1C9",
    "13154.pdf": "87E1C5E9-8894-4705-B1BF-29599647E7AC",
    "13157.pdf": "1F53C25F-49CD-4CC2-B277-57A2D674A275",
    "13158.pdf": "65AF71C3-CD90-4E54-90BC-FC671B22D78F",
    "13159.pdf": "0CB874C6-F97C-4832-A77D-0180E4AB3D09",
    "13161.pdf": "2D0502B7-982B-4B7E-9ACA-2D1B1D8D3FC3",
    "13162.pdf": "95D95C76-2673-4018-A451-5DF9718AB302",
    "13163.pdf": "8D32574F-2FB8-4E56-8526-2DB0F81159D0",
    "13164.pdf": "48C825F8-578D-4727-A064-F35F2802E896",
    "13165.pdf": "6CE9D4EB-1F9F-4337-8ADB-CFA55A76D583",
    "13167.pdf": "5E8A2C28-ADCE-4AE2-81A4-14C9634D8451",
    "13168.pdf": "B90BABB3-D42E-45B0-8A4F-7D47D2066F94",
    "13169.pdf": "6E9A4617-0BA1-470A-B9A5-ACF4184DFAE5",
    "1317_MEMO.PDF": "6B475353-724A-4EA3-A896-35417DB8810F",
    "13170.pdf": "D15DAFD2-E402-4803-9134-BA86CD11481C",
    "13171.pdf": "7CA5E9A7-354C-41B2-9FD7-7420193D2299",
    "13172.pdf": "2AFEAB72-DDDD-4027-A005-FB49428B0BCA",
    "13175.pdf": "3C4E0415-F0DC-40AE-A4A9-21A7F0CFA2E2",
    "13176.pdf": "05C49A74-F508-48A1-A1B2-32E0B868D84C",
    "13178.pdf": "86EDC9CF-A569-45CD-8FA0-22ACF9F0BC16",
    "13179.pdf": "251815B4-30E8-4264-8904-92E545021BCA",
    "1318_MEMO.PDF": "1E618180-2C37-4392-87F2-13DC1133047A",
    "13180.pdf": "7617B803-FB3D-43A8-AACA-16161FAFE54B",
    "13182.pdf": "F6BDCA41-8B0B-4BE4-A1B2-8C0CF4017893",
    "13185.pdf": "B36E0E1A-F4F8-4CE0-84CE-63118C0CD45F",
    "13187.pdf": "1A520395-B71D-4D6B-8483-475F1A564F08",
    "13188.pdf": "C3B01473-9D17-4E28-9513-2919261C70B1",
    "1319 2019 CNS poster.pdf": "3007D44E-6789-47DA-8781-20BCFC22C1DD",
    "1319_PROGRAM.PDF": "F30629E7-B671-46B0-801C-CAB86A597CF1",
    "13190.pdf": "BB33BEDA-7BEC-46E6-9396-685001FFC7B6",
    "13191.pdf": "B75FB51B-4B2A-4E42-AF2C-A4CA3DC1CD6E",
    "13193.pdf": "F053D430-DFA9-417D-A547-19D1A0F383BE",
    "13194.pdf": "DC2897DB-8E72-410B-B516-525A72892C07",
    "13196.pdf": "D7AFCAC0-7D5A-43B9-BE89-AD92CC5C409A",
    "13197.pdf": "3EF6AA58-B28A-49DF-A5B5-4E40D820A178",
    "13198.pdf": "B115C149-CDC9-4DDF-ADEC-E93399F30E5D",
    "13200.pdf": "F5649220-6486-4909-A0B9-8914C4ED3A70",
    "13201.pdf": "109BD137-230B-4981-A230-CCA60D48F904",
    "13203.pdf": "4DB192C8-28B6-4931-B5E6-8A4128EAF96E",
    "13204.pdf": "A816ED95-B45C-4FFB-A99C-35F2B55E1B4E",
    "13208.pdf": "C5AC0E02-615C-4A00-BAC3-361DC6E97A42",
    "13209.pdf": "C0AA9FD8-A516-4032-9F4C-13E7DA237096",
    "1321_PROGRAM.PDF": "CAB6419F-EE5B-4ACF-8B0D-7100373D6F59",
    "13210.pdf": "1CF9B6C6-071F-4474-A95C-5363977FC792",
    "13211.pdf": "D142C437-38E3-4040-BD51-ACC9C62D018A",
    "13213.pdf": "514367F5-3C1C-46C0-99D2-E94EE1E2AF84",
    "13215.pdf": "0CE3E922-D835-4AA1-A971-46312045EB46",
    "13216.pdf": "8B386367-9AD5-41B7-BA31-9F419C172E10",
    "13217.pdf": "1D445E19-9E1E-4B2B-B5B1-4A275EA65C4E",
    "13219.pdf": "DE17BA90-8407-43F5-8FF7-57F55F3933E4",
    "1322_PROGRAM.PDF": "85AC4C0C-42BB-43D3-B34B-04907D44CDDD",
    "13222.pdf": "BC1949F6-9385-4AFE-A21A-83875EBC90B6",
    "13223.pdf": "38FC6FFE-6D83-4B74-8706-D23CF756CCD3",
    "13228.pdf": "0B718B0F-027D-4DF2-A1ED-D46745A67F9C",
    "1323_PROGRAM.PDF": "E7F05308-E133-42B4-9436-5F5272B87685",
    "13230.pdf": "536CA302-DAF9-4B8D-B010-61B2F29E3DF3",
    "13231.pdf": "2486D558-68C0-4ADA-B8F5-958162F8B8FD",
    "13232.pdf": "C4B437C4-5691-43A8-B040-64454EC16793",
    "13234.pdf": "F92E0A5F-3FDD-4FA3-BA7C-BC47FE8B1123",
    "13235.pdf": "17A901C0-B288-43AD-A665-7D768531C408",
    "13236.pdf": "0A509515-27FD-4BD0-9D92-A3B105CB0224",
    "13239.pdf": "1F55D05F-D56D-40A6-BE36-35D0B6E98F06",
    "1324_PROGRAM.PDF": "7B27FE56-D41A-4892-9E0F-02EAFE930BDB",
    "13240.pdf": "EDD18405-4F62-482A-8073-BCD62668AE0D",
    "13241.pdf": "18013D57-C624-44DF-90EC-36E42D0BCF7C",
    "13244.pdf": "A340C9B3-A954-41F6-99F1-967D50C81D4C",
    "13246.pdf": "0C5262BE-CA2A-4C74-A8F6-22103D4BE280",
    "13248.pdf": "55B4A5C6-83F0-4FC5-80F4-F3055A8C6A7B",
    "13249.pdf": "AB52DC12-3760-45A0-8EE0-E3AD0CEF2434",
    "1325 - Elevated Human.pdf": "F996F7A0-9A7D-48A5-BE0E-946D96DCE61E",
    "1325_NEWSLETTER.PDF": "ECF9BC86-E92A-460E-9CDF-847256A012E3",
    "13250.pdf": "D53A7CA6-7B1B-4C62-A149-CC6F49EA6A4F",
    "13252.pdf": "249D0190-6200-498F-A88C-161FA66681CD",
    "13253.pdf": "BD7377C3-20DE-4773-8535-A965E8B7DAE8",
    "13254.pdf": "52A78986-58BB-4424-88F3-0321E1299CE0",
    "13256.pdf": "0C0F9020-30A4-4E34-86C2-DEFBFCC348C7",
    "13257.pdf": "B63B757C-18A5-4F01-814B-BB58A1F141A7",
    "13258.pdf": "DE1EE121-7F21-4B0F-9680-DC724AD5FF25",
    "1326_NEWSLETTER.PDF": "BFA4C5C1-057C-4C2B-A269-7760FC19CDE1",
    "13260.pdf": "5DEA84B5-060E-4BF3-AC13-40E76D8F276E",
    "13261.pdf": "0F2A53C8-E669-4A91-9946-FF149E223FA9",
    "13263.pdf": "6EB744FE-C57A-4AFA-BE53-448C391C9173",
    "13265.pdf": "DF02E46A-20FD-473D-95D0-67FFB9750316",
    "13266.pdf": "444661F0-A66F-4052-AB11-025D25FBB6BF",
    "13267.pdf": "50DC0D57-D961-4123-A473-43211AC5BBBE",
    "13268.pdf": "BE6AD7D2-28DD-4239-8105-F2E59914B3A8",
    "13269.pdf": "6419C613-A8F1-4674-A061-15CA9E4F42AA",
    "13273.pdf": "8A8F389C-F0AF-457E-9DA3-93BA000B6CC1",
    "13274.pdf": "6FF74139-EBB0-40F9-9C19-7AAA4133E572",
    "13275.pdf": "8C25613F-F232-4FFE-B25A-61BD630A2F11",
    "13277.pdf": "A9D050DC-166C-409E-9B98-C684DDA80374",
    "13278.pdf": "4E9F2FA2-FC83-42F8-9372-2494C3E5958A",
    "1328_PROGRAM.PDF": "C10E5589-3F01-45B7-9D73-EA5C13F8A2C3",
    "13280.pdf": "7AC01E84-A1CD-4FD9-A4EB-62EFD20EF9D1",
    "13281.pdf": "04C472CC-B21F-4B0B-A7C3-5BF8F9368DF2",
    "13284.pdf": "D783AC35-640E-4DCA-828B-EA9913FAF2AF",
    "13286.pdf": "05753B22-FC30-41B1-BE00-98D782E6CBE1",
    "13287.pdf": "DF3A21C7-4C1F-4A0C-8832-A5F786542C89",
    "13289.pdf": "3EA520E8-3DD5-4E7C-B72F-59925472CCA4",
    "1329_PROGRAM.PDF": "842AA9FF-6F95-484B-BED3-9EB9B8B411FE",
    "13291.pdf": "C0B2E396-12C7-4126-8247-F109DB8C0D9A",
    "13292.pdf": "541C5617-8FE7-4D07-A0DE-BDC4582E1C81",
    "13294.pdf": "A11C573A-DB30-4827-B0CE-CEEEC831B45A",
    "13295.pdf": "60C22611-91E7-490B-9913-843C716CDB4C",
    "13298.pdf": "E83172DE-047A-49A6-9B7C-9C7DDD363E5E",
    "13300.pdf": "2157C93B-AF53-4FBD-A62A-6EA3A650A765",
    "13301.pdf": "5D218E74-3F03-47DD-96FB-DCACB6CAFC1C",
    "13304.pdf": "77DFE07A-22B2-4405-8816-2FA39328DF11",
    "13305.pdf": "199807C1-7129-4151-ACA4-EAF0A5EEE354",
    "13306.pdf": "EBDF4708-FA26-4E05-84CC-2A768AC3A3A0",
    "13307.pdf": "A2EA8EFD-BAA4-4967-A33C-25FEDFFC381D",
    "13308.pdf": "612304AF-DECD-44F5-A9CF-5F85E142B183",
    "13309.pdf": "7709C334-CDCB-4A97-A9D7-18F2473A8396",
    "13310.pdf": "33B65C74-1323-48C6-9F63-2E02907DF7F6",
    "13311.pdf": "6DF824CA-E942-46FB-9332-83151EFE4F29",
    "13312.pdf": "FE164F5F-5DD2-4081-AB37-CB964035C3F4",
    "13314.pdf": "14E84224-A240-426F-AB5C-28C91D4B940C",
    "13319.pdf": "B208A5AC-EC74-4ED3-8611-662782D6D2CA",
    "13320.pdf": "C0750807-A08F-42FA-AF95-A4FC9CE757D4",
    "13323.pdf": "AEA0789F-98B1-4CFF-927D-16E4FC2F328A",
    "13324.pdf": "F41ECA23-F458-407D-BAC7-816736CA2291",
    "13326.pdf": "1E78CCA8-028F-40BC-90F5-2451B854EA25",
    "13329.pdf": "6EFB1BB9-AEC8-4488-9CA7-F3BD291AB3C1",
    "13333.pdf": "5E1F90A0-324D-48FC-BFC4-AB853EAE2574",
    "13334.pdf": "EF233C38-F88C-414F-8C63-A464FDDB076E",
    "13335.pdf": "44F061CE-6B2C-4B85-B69B-8472183057E6",
    "13336.pdf": "93BEF858-5BD4-4953-A281-97210326FB62",
    "13337.pdf": "F2142FBC-09B6-42DB-B07D-A44A95FDFADB",
    "13338.pdf": "BC56011D-AA62-48F9-BAF1-15A95C97BD41",
    "1334 Analysis of Circulating Tumor Cells Reveals that LDHB NXPH1 IMMP2L and a Metabolomic Shift are Associated with Brain Metastasis -1334.pdf": "8C8B5187-C375-4F39-9490-8797A47C0E0D",
    "13340.pdf": "8204A38A-5E27-4994-A7C8-B6D1E568EEBE",
    "13342.pdf": "BD394570-6794-459A-942A-B58C8A3A46F1",
    "13348.pdf": "C3A0C6DB-A43B-47C0-99E6-8A12D8653EA2",
    "13349.pdf": "120BFEEC-32D4-4527-B241-377F837C371B",
    "13350.pdf": "3B29BA78-1ABF-4E30-9644-1CB1B25A5609",
    "13351.pdf": "AB10869B-382A-4579-9462-882D8065D14C",
    "13352.pdf": "4BF31972-49CC-4E96-ADA8-B7FB9F8886A2",
    "13353.pdf": "83D68BC8-4B11-48CB-8DC1-5815679D7AFF",
    "13354.pdf": "C2D01218-68EB-4212-B611-492D62AE872D",
    "13356.pdf": "D4A146E1-6FA9-4C2E-81BC-D4E2C341DFF0",
    "13357.pdf": "1E323C92-2815-4960-A73C-B037D4B56872",
    "13358.pdf": "D2AD0478-DDB4-476B-B839-055BEB09C3FD",
    "13359.pdf": "A91396C4-6FAC-47FA-8D9C-CD82B5DDB7E1",
    "1336 A Proposal of Grading System for Predicting Hearing Outcomes of Stereotactic Radiosurgery for Vestibular Schwannomas-1336.pdf": "9BF12771-BC0E-47AE-AF92-627E97151071",
    "13360.pdf": "A3E7D2B0-19AB-469E-A7BF-7DBDE02CBFD5",
    "13361.pdf": "11D5CBBD-E9C1-4E86-BF9E-EDC1AD6EE7D1",
    "13363.pdf": "2C17BF10-6196-4409-A565-4275AD3AF110",
    "13364.pdf": "3B60A1F6-B531-4132-B093-F2C99B9E6338",
    "13368.pdf": "FF33AD54-54C4-4CED-A3F8-420F7483C1A1",
    "13369.pdf": "5C18F286-1C5D-4239-9784-9DC6157F43C0",
    "13370.pdf": "DAA798CD-9252-4891-BFB4-8630C89A5795",
    "13371.pdf": "281DF76B-DC5E-40CF-8852-595019EA6E06",
    "13373.pdf": "CECE1DC5-12C8-4CD0-AEBF-1309CFD9F92A",
    "13375.pdf": "1594B4FC-0A71-45FD-99D8-89E19194F464",
    "13376.pdf": "72D393FD-9527-4BC2-9D92-5118B745C4EF",
    "13377.pdf": "218AF7D7-2A46-403C-A467-002ABBD99DF0",
    "13378.pdf": "7F32E02E-F89C-4A21-ADED-2891ADFC6B58",
    "13379.pdf": "B3AEEE7A-FE83-4838-B1A1-0441B55F027C",
    "13381.pdf": "5C78CC33-FBC7-4262-8C32-1BD4620BE9FB",
    "13382.pdf": "CE8F06AE-0DEE-4EDE-A993-6B8220D0334C",
    "13383.pdf": "C4851E7B-7583-4235-A1C1-36357DF08546",
    "13384.pdf": "67A8E9BB-F919-45FB-8FEB-CAB90A2D0084",
    "13386.pdf": "B8396046-21DA-4ECE-92CF-C567BBC18C39",
    "13389.pdf": "F3D8500B-6DE6-4E16-9689-D4C175E3EE02",
    "13391.pdf": "50025D5C-0DF7-4162-A027-B5B45EFB9358",
    "13393.pdf": "2CD3B3BE-C12E-4AD4-85C4-1E5D7F0CA61E",
    "13394.pdf": "CCB97262-8A8C-4F64-B768-A3E36E0A4F5E",
    "13395.pdf": "4E18BDA5-6AF6-447F-8DE8-EAA0C8D7EB1C",
    "13399.pdf": "216B29A0-2FBF-4662-A0C4-F70BFA25E094",
    "134_2019_Article_5900.pdf": "7B6B9AA1-AB7C-4CD2-9970-9C155D78C6F4",
    "13400.pdf": "65E12F32-BC60-4BFC-AB9A-5F5023A28295",
    "13401.pdf": "EAB4B9DA-7678-4723-8E29-44EED35003E0",
    "13402.pdf": "3BB83E65-8406-41F4-A269-B1E406BCEDB5",
    "13403.pdf": "A6130638-92B2-44AC-B941-E88FAAE5C5DE",
    "13404.pdf": "3DE7BEB8-6E3F-4514-BA8F-A690C882D84C",
    "13405.pdf": "7511E168-9335-4D2E-8CFF-9475F3A7EF99",
    "13406.pdf": "23355111-48BE-4E9F-BA4A-ADD3B51A3F6A",
    "13408.pdf": "D2A70379-062D-4620-B61A-383C6F1EA630",
    "13409.pdf": "25ADA537-F5BA-4D2D-922C-F4D759CA11C5",
    "13411.pdf": "0AEF1408-1791-42D1-AAFC-B632FF4D5AA3",
    "13412.pdf": "4E31209F-DFCD-4CB9-BC98-D89E67F48B1D",
    "13413.pdf": "9FC494AE-643B-4CAA-9624-0A89B35C02E5",
    "13417.pdf": "482D3366-18C1-48D3-9C30-7DA7AD965453",
    "13418.pdf": "4122CDDE-6CBB-4CA1-8FF7-650ECB439F4A",
    "13420.pdf": "5D0394F0-3DBB-4403-A0BF-E0565456FD79",
    "13421.pdf": "776D6CE5-65DA-42F0-9E9B-BB9100EBBCE3",
    "13423.pdf": "E152AE92-680A-4029-8B84-947AF30EAB0A",
    "13424.pdf": "59574863-F20A-4540-A68D-A7D8CDBE5C3C",
    "13426.pdf": "10529FCF-4BE8-4270-8B5C-46E0A21DA582",
    "13428.pdf": "990D32EE-958F-45DC-AC0E-B41BFD245942",
    "13429.pdf": "68CA29E0-B672-4D82-A327-92977B89BB27",
    "13430.pdf": "E794506A-4B71-4EDB-9554-54DB42282E0C",
    "13431.pdf": "E06BCE5A-6538-4137-B982-E1A801AEA06B",
    "13432.pdf": "780AD24D-9F83-49FA-9943-038B4BEA41AD",
    "13435.pdf": "AF738917-E085-40F6-91CC-FABF53F00079",
    "13436.pdf": "730E6BD1-AD11-4D3B-A5B4-1445552CDD30",
    "13438.pdf": "B33821AB-EC09-48C9-9CF9-B11F49E5CE25",
    "13439.pdf": "07F42A0B-09D5-416B-BFA3-6218D5B1CD8B",
    "13441.pdf": "46B7432F-A720-47BC-B3AF-D03785B38031",
    "13442.pdf": "1E446F62-8AC4-4C6A-B848-C8845DE9BC1E",
    "13444.pdf": "3E22AC5E-FE20-4BDF-BE05-885E8A5DD905",
    "13445.pdf": "CF78942F-8524-41CA-B3A3-CDB42B89D4BC",
    "13446.pdf": "CC768465-6D7D-464C-8227-3BEB15CD557F",
    "1345_NEWSLETTER.PDF": "2927FA63-72FE-4581-84AB-81F0F753AE40",
    "13451.pdf": "B5E4E341-4E27-4C27-8284-930411F8DDA8",
    "13455.pdf": "BA744E16-F007-4A12-AEDE-CBCC1DA52C73",
    "13456.pdf": "D77A94E8-878F-419D-BAF1-321DDAAF1708",
    "13457.pdf": "26A3DD05-3348-4F5A-BE4E-CAF6D530BD54",
    "13458.pdf": "B368C2EA-E932-4C22-9049-AD6CFBEDAA38",
    "13459.pdf": "B52402F7-D6A3-49BB-BB0F-712CC3A8B14F",
    "13460.pdf": "596FB564-9EC0-43AD-B1C5-BD60A29BD1B3",
    "13461.pdf": "A7480504-60CC-4E49-BBF4-BF535EC7B747",
    "13462.pdf": "F45573E1-B44D-427F-AE2B-C9FC6918606F",
    "13463.pdf": "10733A5A-EBDB-4FD7-A91A-D1868392DD05",
    "13468.pdf": "40B3840D-7D4C-4F2E-AB1C-474113ECE1F4",
    "13469.pdf": "46F5E627-AD04-4AFA-A7CF-35F254F67E21",
    "13470.pdf": "8AA7BB48-28E9-4EE7-9CC2-C7E6C0C7C2B0",
    "13472.pdf": "2287A814-BF2C-4293-AB2C-AF0EC6E9B65E",
    "13473.pdf": "B6EB3A7A-BA67-435E-951E-4DFDE2EEFF77",
    "13474.pdf": "18FA9626-6228-4B89-954F-B7CB6729FD33",
    "13475.pdf": "A798344E-3A5E-4273-BEFE-7D51C144BE41",
    "13477.pdf": "710B6457-4245-40B7-AB08-E741ACF13541",
    "13478.pdf": "22D13FBC-5578-4B2D-806A-5E94DFF29D6F",
    "13479.pdf": "41F6337B-21A0-4253-8DDA-E50BB43FF779",
    "1348 Treatment and Survival of Primary Intracranial Germ Cell Tumors- A Population-based Study Using SEER Database.pdf": "8BC61C7C-87D8-4286-AE8C-B571DE40B816",
    "1348_NEWSLETTER.PDF": "1C5DDC59-3716-493C-BF7B-5FE76CF54E7D",
    "13480.pdf": "A5D10F94-81BA-400E-8E27-6798A5139FD3",
    "13482.pdf": "B89D1CDB-A790-410F-B001-35189F4FFFE6",
    "13483.pdf": "17CD52BB-C0DA-4503-A822-E839A856E6A4",
    "13484.pdf": "E5738E33-D5EF-4456-B8F8-8F182EBFA885",
    "13485.pdf": "73223C91-023D-4F16-8D07-0B8B563C9BFB",
    "13486.pdf": "A833AAEA-2D34-4589-BD6E-47774369AB20",
    "13487.pdf": "8DE6C041-C748-46F0-8A0E-808AFB1295AB",
    "13488.pdf": "CE9CCC1D-89EA-4785-AF85-8450C416829A",
    "1349 Preliminary Identification of Genetic Events Associated with Prognosis and Surgical Treatment Response in Glioblastoma- 1349.pdf": "6A85ACC0-9AD5-4437-8425-A9AD397624FA",
    "1349_PROGRAM.PDF": "04725FCE-AE4A-4992-B293-249D4C94600D",
    "13491.pdf": "69D4575E-BCCB-480C-876C-EAC96127B848",
    "13492.pdf": "3AED6D3A-2384-47A7-89FA-4898C91E5545",
    "13494.pdf": "EF4FB015-5E42-4195-BB36-F4CFA2193F11",
    "13495.pdf": "D352F908-0E28-48A7-9E6A-A50717AAD0EA",
    "13498.pdf": "E5157408-6763-4E95-8B89-54A0A927256F",
    "13501.pdf": "DAED831C-D9D2-40FF-B1F7-F17C838A54BB",
    "13503.pdf": "CCF16592-A0FE-437E-A826-22C1B43276A1",
    "13507.pdf": "7FB53708-1DD0-4252-97E9-C250DCF40CBE",
    "13508.pdf": "5865007B-617A-40D4-B157-DEEECE472253",
    "13509.pdf": "1430F50A-6ACB-4C0B-89E8-6A8E37F2E246",
    "13511.pdf": "18BCC054-E292-4D9D-8981-FFFE0D1F74B9",
    "13512.pdf": "9ECDD0FE-928A-4B64-A893-D16823B767DE",
    "13514.pdf": "CC3E1355-523F-4E16-B176-41BE3D58BDDD",
    "13515.pdf": "430AA152-684C-47AB-BECB-FE061A8D788F",
    "13516.pdf": "6C5948FD-C89C-4474-BAD7-855E7857D223",
    "13518.pdf": "FE619C5E-90CA-4AF1-9681-42F204CF27A2",
    "13519.pdf": "A51902BD-5E91-4DFF-AFE0-2E3E1BFC9D5F",
    "13520.pdf": "6C36FFE5-AEF7-45BA-846D-7F478DF4BFE3",
    "13522.pdf": "DB15A5A2-5903-4E89-8ED2-A64F6022FC04",
    "13523.pdf": "B8FEAE93-E439-4E4D-AF1B-D080ABCEA2C1",
    "13524.pdf": "536BBDEA-88AD-48AE-86C0-D43505D4F578",
    "13525.pdf": "242D8B1B-B76E-46F2-A248-F9A8361879FF",
    "13526.pdf": "FB01D1FC-C05B-4ABF-8BAF-D2489571CB63",
    "13527.pdf": "8484AD06-0FB9-463F-8F0F-C038D8DD078C",
    "13531.pdf": "41E47D0A-7474-4228-8A15-1B817CBA17A7",
    "13532.pdf": "4BF7E287-D322-4FEA-A98E-FF9DA7A2F437",
    "13535.pdf": "89F27C6C-9AC4-4504-A88E-E132751CF761",
    "135356.pdf": "8CE89813-28B8-47BB-95EF-2D8898301008",
    "13537.pdf": "7DA0613E-64BC-4064-885C-D3E248C6B79A",
    "13538.pdf": "17E9C56C-2C58-4660-8D61-BB71EA47BD38",
    "13539.pdf": "77AA2DE7-AA5B-47EC-B3B9-21091FD62D68",
    "13541.pdf": "E5D973EF-0A9E-4064-A141-A0EA0B7ADED6",
    "13544.pdf": "CC765F85-9B2D-4904-BAAC-D61858B1A94E",
    "13546.pdf": "792E22A5-5566-48E0-9D71-827A7EACE849",
    "13547.pdf": "65A9AE24-3A16-4995-9DD0-C6D2661E61CE",
    "13548.pdf": "1CF636A5-8986-4683-9D16-3385D30B0D10",
    "1355_NEWSLETTER.PDF": "AE42DEB6-581B-47F0-89A6-45BDE99988A8",
    "13550.pdf": "947371CE-FE31-4AD0-B40E-81045D088CF7",
    "13551.pdf": "C25B49D9-523D-4EBE-B5AD-E07084DEDD76",
    "13553.pdf": "A28C63F7-D1B0-4B52-8ED3-95D1B690FFE2",
    "13556.pdf": "75E417DF-787C-412C-968E-F5E4648EB174",
    "13557.pdf": "AEE47C95-CA8C-4915-9FDA-A052AD9F116C",
    "13558.pdf": "02A67CDB-BE7D-4DE5-AE95-3909347A770F",
    "13559.pdf": "71E36C39-A962-4C0A-8741-E5F3460E9CC6",
    "13560.pdf": "6806659E-0BBC-4EFE-B710-C2B81BADEADD",
    "13561.pdf": "5F795186-F78A-4A4A-A416-666016652EA0",
    "13563.pdf": "AD40F605-27B6-4837-A23F-BDC0EB569CED",
    "13566.pdf": "B17DDC54-E281-40FE-A14D-EDC392649DE7",
    "13568.pdf": "E56C1A86-455A-4C80-8E69-405B23668806",
    "1357 33060.pdf": "F5496EA1-90A7-4004-B438-19E09898D182",
    "13570.pdf": "F77CDC28-FCF8-4053-8A25-A2BBEFF3C1BB",
    "13572.pdf": "A3988E44-C1BE-42A0-AB65-814CB4392D9D",
    "13573.pdf": "E5034CA9-5B55-4755-93FE-90DAD4ADF326",
    "13580.pdf": "E9A5172C-6E2A-415D-AFB6-EEC116A0FCD8",
    "13581.pdf": "52C383C9-960D-4CF1-821A-7A05184A2A76",
    "13582.pdf": "0075684F-CA03-4DD5-98BA-85889C75B615",
    "13583.pdf": "957D4B06-0C69-45CC-9E9B-BC5E03953063",
    "13584.pdf": "2C270E82-F5EF-495A-B69C-D88FB846E8AC",
    "13585.pdf": "0F097F57-6636-4C5A-9D27-A2A79B7AC6FE",
    "13587.pdf": "B522A287-C5B1-4465-9635-B0717636B71E",
    "13603.pdf": "84CEA2C8-89D5-4405-9DC4-52C93F348744",
    "13604.pdf": "B114AE50-6E13-4900-9B61-62AFCAC826B4",
    "13605.pdf": "879A7648-EF86-4AAC-8790-616B19281E98",
    "13611.pdf": "3014ADB4-FB8B-452B-82A1-6B3DA71EC7A4",
    "13613.pdf": "0D5A7BEF-CE8F-49FE-A6BF-33DAD3E4879B",
    "13615.pdf": "16D9BDE4-5D27-41EC-9C02-F9CBB281EE3E",
    "13637.pdf": "81F88118-3EB0-45D7-95DF-07D01ED1CF90",
    "13639.pdf": "AAFF3D8D-D27B-4013-9A53-602256F0A1FF",
    "13641.pdf": "BDCA24AF-2744-4D0C-8ED6-373B5A94CC57",
    "13642.pdf": "F07A955A-C71A-4CA0-9451-B69D705595D6",
    "13643.pdf": "089C6F1F-4A09-44C7-A226-E8776B98CD3B",
    "13644.pdf": "BAF4BB38-5944-4001-8571-AFFF4BEAE906",
    "13645.pdf": "0DAB91A8-F3B7-4365-B55D-DC5E64FB8F33",
    "13646.pdf": "AA4E6124-0F44-4966-9A0A-D4E480129909",
    "13647.pdf": "580F71CC-C1F1-487F-B226-1853D31889C0",
    "13650.pdf": "5F818B2F-975F-48C0-BBB4-C9D8441B3EFD",
    "13651.pdf": "828FE231-09AD-458D-B534-6C2E11B804F6",
    "13652.pdf": "06992042-BE14-48FD-9127-90F1D3829725",
    "13653.pdf": "26038EF2-1EA3-49DC-AC5A-31FB5B974E84",
    "13654.pdf": "A63A85EE-401F-423D-926B-DF72C532A1A0",
    "13657.pdf": "A6370949-7A83-456E-92D5-D37536F73007",
    "13665.pdf": "2E17B46E-1586-4432-9745-72DECBA3059A",
    "13666.pdf": "D8460501-1F24-494A-BF28-7E75D1F0E6DD",
    "13672.pdf": "AAC3A1D8-8F50-464E-BBFC-E5D813DB1345",
    "13674.pdf": "DAB5EEBD-29B1-41D8-86D2-4B79BD09234A",
    "13675.pdf": "475F5124-45EC-4D5D-A7FE-20BA69220158",
    "13680.pdf": "B84A9B56-D5A7-4634-83AD-BEE76781038F",
    "13681.pdf": "73958A76-3033-4609-BDAE-3FFC5FA031BE",
    "13682.pdf": "2A9BC67E-62AF-40B8-BD06-7522A28C3701",
    "13686.pdf": "223DA703-1F2B-4A37-B8D0-4FA93FAD03A3",
    "13687.pdf": "02ECA8F1-3336-46B5-B3B1-1170E7D4BA15",
    "13688.pdf": "72E9DA95-A6E3-4E3D-8369-267A27DD2833",
    "13689.pdf": "B96362AD-4E02-4CCA-B48F-0BAFB64D4772",
    "1369_PROGRAM.PDF": "724A72E3-466B-4D85-87F9-0EB6274D0526",
    "13693.pdf": "AEFA4AD6-E1E0-45A7-A5BA-8701E35612FE",
    "13696.pdf": "540A4E09-1757-4C0A-A957-A23FCB0C4675",
    "13697.pdf": "5289D348-3E0D-45A2-9F72-ED0C7D8FECFA",
    "13698.pdf": "9D935738-4ABE-436F-A222-B21211BC3A04",
    "13699.pdf": "6EA326A8-1E3A-4796-A2E3-17D4E8148A95",
    "137.pdf": "C5EE2C7B-764A-43D6-AA4D-09BD4CAAB595",
    "1370_PROGRAM.PDF": "E3CF7E74-FB6E-45B2-A24E-F3ACBB24189E",
    "13702.pdf": "B29E3AA8-3FF1-4D4D-BFAB-0C18E47813AD",
    "13703.pdf": "1F875234-C91E-495B-A011-4405418E59DF",
    "13708.pdf": "9F04E3C8-F57F-470D-BA1B-AA6B0770F59D",
    "13709.pdf": "5EFF66CE-B0F8-4C3C-B77A-354E1A65EDE6",
    "1371_MEMO.PDF": "0C13C200-A338-4780-87BC-D1CDC88B2A29",
    "13711.pdf": "BF5B94EE-9E3B-4D7A-9FBB-4FA2E97F6E9F",
    "13715.pdf": "2EC0D21C-C2DF-4BFE-B382-48803D88C686",
    "13716.pdf": "389C5A36-8A2A-4697-9295-89F2A6E42D96",
    "13718.pdf": "FBDB2F4F-AB80-4456-938E-82B48382C257",
    "13719.pdf": "6733DBA2-1357-4B28-A53E-FC9FFA4FED81",
    "1372_MEMO.PDF": "F3B096C6-72B9-4559-A45B-FA9A2575E4C7",
    "13720.pdf": "85788ACE-3687-4668-A524-063B6F3673D6",
    "13721.pdf": "321BDFD8-DC8E-419B-BC40-7B0DF0A7788D",
    "13722.pdf": "203A99EF-7BBE-4333-97FA-59CF92D59C76",
    "13724.pdf": "A67E470B-94FA-4067-B360-BCF6633A6EF9",
    "13727.pdf": "3A1DBA90-4ACD-4F58-925D-EF0E73544DAB",
    "13728.pdf": "CC94277A-DEF8-43C9-8E08-9B88E7EA6D18",
    "13729.pdf": "F48D6ADB-5464-4663-B119-8434876989BF",
    "1373_MEMO.PDF": "13D0FAE9-59E4-49BD-B858-3332BB2A7F87",
    "13730.pdf": "5B0E8A3F-9B8F-48E4-B7C0-A645C0D1BB77",
    "13734.pdf": "70EEDB8D-8320-4AE4-88B5-C33F485F74F0",
    "13735.pdf": "E944077E-416F-4BFC-9187-FB0E70DDB989",
    "13737.pdf": "16A9ADFD-2151-43EA-B52E-706FAD91F084",
    "13738.pdf": "C988AA46-A79B-41A2-A91E-6A6CE6A972A3",
    "1374_MEMO.PDF": "433E1E4B-D7AF-4C70-8E8B-EDEE26228537",
    "13740.pdf": "3F180AC3-0107-41EE-9206-FA51AE856811",
    "13741.pdf": "2BB2FC50-4535-4C93-AD75-BEDA138B292D",
    "13744.pdf": "76AE9AF1-CF7F-4FF1-99E8-EC70E1958C76",
    "13746.pdf": "180A047B-772B-424B-A306-A7C7F53A3CDD",
    "13748.pdf": "62CAC384-241A-4B15-8298-7870FA498FC8",
    "13750.pdf": "B1139337-F920-412F-8133-05BDE93AF394",
    "13751.pdf": "9F50E704-172A-4E89-A479-4710744DDC53",
    "13752.pdf": "CE9847BA-D1FC-4587-8429-E3ACA8ECA876",
    "13753.pdf": "F97451C9-0B45-444D-94C4-10DAC2843B68",
    "13756.pdf": "88B1FA68-9230-4D37-81BA-848DF5FE96AD",
    "13757.pdf": "0D4F6451-3839-4774-B362-249773CB1A1F",
    "13762.pdf": "67FE9F97-44B6-4388-8A4B-805CFAA7D0C0",
    "13763.pdf": "01FF453D-E8EF-4447-AACE-87331A374867",
    "13768.pdf": "11BF5FE3-BB24-42C7-829D-1E46A5254BF7",
    "1377_PROGRAM_compressed.pdf": "F49DCCE3-8764-45D9-9504-54F6CBF8FF97",
    "13770.pdf": "15EBBFE8-C128-491A-BE6C-43607B05BCD5",
    "13771.pdf": "93850D65-5895-4E8A-9B91-104A4C8A3EC2",
    "13774.pdf": "6E88693D-5CF4-4D4B-92E9-F66FAF92D900",
    "13775.pdf": "2B53BFD6-6C87-4074-9E02-174E00C50BEF",
    "13776.pdf": "F30D83E6-ACB6-4455-BE1C-FEA6A0394037",
    "13784.pdf": "47F7BB40-7031-4C9A-9C19-1109EEC68089",
    "13785.pdf": "ACA3710D-DABE-48B4-A696-019B31D4CE4B",
    "13787.pdf": "96A81446-98C1-4C0B-AD35-5AD240D5EC33",
    "13790.pdf": "4DD0186C-BEBD-4457-83FA-4BF48AD442BE",
    "13794.pdf": "3136189D-A27D-4508-BEC5-BD9DC56A8D92",
    "13796.pdf": "A71A78D5-A8B0-47EA-B3CF-0D0E6CEF5FAE",
    "13798.pdf": "7909D0AA-1B99-4D20-8439-7E9FA1A03188",
    "13799.pdf": "464D2158-F688-4642-B8BF-3B2BACA88AD6",
    "13803.pdf": "2E3EAA55-95B3-4C47-88BE-55ED6716F924",
    "13807.pdf": "C1C24536-CD23-4D3A-BE1C-5FDF8C8425EA",
    "13814.pdf": "8BCC6410-668F-4651-A2C3-C921CE3433C4",
    "13815.pdf": "3AC0B695-1B38-4C6A-A773-487623BF64AE",
    "13818.pdf": "4C66BF97-23C6-4763-9058-DF0BD93D4D30",
    "13819.pdf": "A3EAEC5A-BA7C-4D37-8458-4BFDDC8239B3",
    "13820.pdf": "C6CFDC82-9B05-4613-9DF6-16087F3BECCD",
    "13822.pdf": "E819DA2D-FD66-4E3F-8564-AEE28773F135",
    "13823.pdf": "844C419E-A20C-45DA-B02B-C48FC9B1E205",
    "13824.pdf": "45BC2413-12DC-4F22-8E32-6398A9FF0B76",
    "13825.pdf": "CDEDC984-CFBB-49A3-93EF-0E669FA99A6B",
    "13826.pdf": "BB2F906B-B5A6-476B-AAA9-293414C6AAB9",
    "13827.pdf": "699FBBD7-0D05-4372-B5F9-0535C85053CD",
    "13829.pdf": "C2648E39-1307-4561-9B40-4987E5EDE260",
    "13835.pdf": "4CD43FBA-17B6-4539-A0C3-2B943F2715F3",
    "13842.pdf": "6534D775-ACD9-43E6-BB10-77A07CBAB7FC",
    "13844.pdf": "1AB0C47E-2608-4318-9077-86B4EFD88AF6",
    "13845.pdf": "A7857781-DB8F-46A2-9555-4D62C977EFB5",
    "13846.pdf": "C243E9F0-E357-4712-8691-8CDA5FB811A2",
    "13847.pdf": "B7C42CC7-EEBB-4172-A37F-B57D95D39597",
    "13848.pdf": "71A57571-15AC-4962-A607-19BE4D98B1D5",
    "13853.pdf": "1CF1C3E6-5B90-4436-B1AD-607880D28BF7",
    "13854.pdf": "5EE3080B-A8E2-4809-B837-0B77A206F587",
    "13855.pdf": "218CCA6D-8903-4525-8896-70A80887D3E7",
    "13856.pdf": "0B07D35F-1AC0-4A35-B480-6ADD72797C86",
    "13857.pdf": "C3484E62-20FE-47D5-A443-FFFB65E6D874",
    "13860.pdf": "5C0A3036-B2CF-4169-AFD3-1BB8403D9BF6",
    "13861.pdf": "5276B789-87DA-4699-B5E2-2431EA4E3511",
    "13864.pdf": "A993685A-FF11-4F66-AE30-6DBED995C31D",
    "13865.pdf": "50B174EE-42B7-4856-9CD6-CFD82EC114D2",
    "13866.pdf": "AC0732CE-451C-4318-B581-6FD52E37A096",
    "13868.pdf": "D70263A7-1430-4CFA-9AF1-AE04145E82AC",
    "13869.pdf": "9515C636-3658-417A-A34F-674E48ABF72C",
    "1387_PROGRAM.PDF": "D0DD4550-9FEB-42D4-877A-1A36B7D86447",
    "13873.pdf": "AA2DF9B5-E0F4-4A8C-B328-77825FDA38A8",
    "13875.pdf": "BB12F1E5-4ABB-4117-8C6D-386E028F83A0",
    "13876.pdf": "7F2A6922-151A-400C-B317-598F66A465D8",
    "13877.pdf": "5CCA13A8-F648-4A3A-95E7-8D574EAFE396",
    "13879.pdf": "934D18F1-047E-457B-B32A-5B73A93B5358",
    "13882.pdf": "D06F34D8-B65E-43E3-8EC5-62F6C196C538",
    "13883.pdf": "9A35B2F5-898B-4D2B-9E16-D2D90C054541",
    "13885.pdf": "D36201BB-01D2-4D48-B589-3963C067F465",
    "13887.pdf": "5EF92128-00E5-4665-AF57-43AD0286B248",
    "13889.pdf": "B0A3C96A-D9CD-48AD-B203-B37AB51FCC0C",
    "13890.pdf": "D5A445D1-EA50-4D4A-8B1B-2DD00817F101",
    "13893.pdf": "5D7B0CA1-2FD0-48C5-9885-B85554A8BADC",
    "13895.pdf": "919E0501-8C70-409A-822A-BB2FF2D6F2D9",
    "13896.pdf": "5A49C488-79EC-4AF8-8435-23C8A8EEAD38",
    "13897.pdf": "5B818185-AA47-4A5A-A317-3D417939CDE2",
    "13898.pdf": "D8FB17B1-6A21-44EF-9924-EED4D208AFE9",
    "13899.pdf": "F52E4223-440C-48FB-9C7A-E7E70CB0667B",
    "1390_PROGRAM.PDF": "88B176DB-DB91-4AE0-9D7F-3CCD9FEC5FCF",
    "13900.pdf": "4AFACFAA-C7A4-474C-849C-A225B4AFF115",
    "13901.pdf": "4A1D6886-E920-4BE4-BA28-3796EDF47475",
    "13902.pdf": "0D0CD8A2-A256-4FA0-B85C-39118FE9A3CF",
    "13905.pdf": "E39794D5-4211-42DC-9FF6-A322E1F44B4E",
    "13907.pdf": "64C27319-AD02-49F8-B677-99EE13F9A4E5",
    "13908.pdf": "4167C2E0-0912-44FA-A34A-5250F641F54B",
    "13909.pdf": "C9647875-C7F4-41DB-AC7A-A6BD13783E1A",
    "13910.pdf": "6FF96FC4-840D-44E2-BA63-CD14A220C769",
    "13911.pdf": "9DFF96BF-3534-42D6-903D-BE161F898C79",
    "13912.pdf": "42E249B3-8242-409E-B572-68E32C0B1A76",
    "13913.pdf": "433A9D21-9CCA-495C-BAFC-23407AAB3EC5",
    "13914.pdf": "9E20CBCF-4C46-4230-BA38-E9FAFF126295",
    "13917.pdf": "96BD2E62-BA36-4D72-AB15-7EA13BE2B62A",
    "13918.pdf": "6DB46981-1AD8-49AB-8CB8-54E8865883F7",
    "13920.pdf": "9E0BA830-8A06-4200-8F67-AD8B6671E4C4",
    "13921.pdf": "32840458-6298-47E1-9042-2BC14E66A151",
    "13922.pdf": "6332EA06-6FC5-4853-B8C6-63C80F842ECF",
    "13924.pdf": "E20C4CF7-3A85-40B4-8A83-45B3AC1727C3",
    "13925.pdf": "5BB47460-E645-4E0D-911D-C60625FBBCF5",
    "13926.pdf": "41BB437B-27A7-416B-B095-3A0385DBCF6D",
    "13929.pdf": "8DF6AE85-14DC-4038-924C-C53B1A94B51A",
    "13930.pdf": "577D9303-FB66-433D-BCF9-4770C9796C07",
    "13931.pdf": "95DEDF70-064B-4707-B949-4309AA9B5614",
    "13933.pdf": "BCD5DE29-6F69-4B1F-866F-20D9BDA4B856",
    "13937.pdf": "DC74F66B-52A5-4935-BB7C-9C0FDB9305C0",
    "13938.pdf": "1E9D4FD8-4E2D-43B2-983A-48A4FDC97FBD",
    "13940.pdf": "EEF33647-4E12-4BFD-B18C-3F21A83B4AC5",
    "13952.pdf": "CE967939-D678-45C1-995E-5F79C5574EAE",
    "13954.pdf": "44E34D7A-F012-44EB-A591-B174DEF9AF1B",
    "13956.pdf": "56FC2A70-1460-4574-8377-A3A352855C98",
    "13958.pdf": "1D5FF640-0155-432C-8BC7-4A20CE636BB1",
    "13959.pdf": "2DF4492D-B7FB-4657-9726-2E7411185B4C",
    "1396_PROGRAM.PDF": "8A26C714-B33D-4362-9812-8B8DABF0DF5E",
    "13961.pdf": "5CEE1616-42FA-4FDA-A946-772186CC0A25",
    "13962.pdf": "04DEE226-B808-4F49-8F2E-30319552DC80",
    "13967.pdf": "0D97581E-DB0F-4F9D-BDAB-4CD9FA163165",
    "13968.pdf": "25E62997-54FB-41EF-8E81-DF0BBAE059AC",
    "13969.pdf": "1A4D09B0-A561-48EF-84F2-EA03D192FB5E",
    "1397_PROGRAM.PDF": "92E6672B-CC03-4D36-A89D-0E277B7DB78C",
    "13970.pdf": "C0C362C0-EB63-4883-91D1-B78EA03DA519",
    "13971.pdf": "FF8F2A5B-01D9-4C98-A0F4-2E0EB8888C01",
    "13972.pdf": "7714D682-5BAA-4CB5-A5A6-07459924C05C",
    "13973.pdf": "A99BEAE4-DE7C-4FA7-BC4C-C5202E117B41",
    "13974.pdf": "1B361364-1DC5-4448-9041-6FC41FE4D572",
    "13975.pdf": "3828BB37-A0E0-4084-AFC2-ED0C58AFD8EA",
    "13978.pdf": "8289BE47-0406-4DA5-9C3B-16158BC5EA49",
    "1398_PROGRAM.PDF": "6DF1A55F-1037-4EBB-9605-CDE664277921",
    "13980.pdf": "3F0D385A-0FB8-453E-BC17-2A4B46C2C96F",
    "13983.pdf": "4FB252A0-CBC4-44E6-A474-B9E22CCF9EDE",
    "13984.pdf": "C559AB69-C752-43D9-8609-AD9AAFF588AD",
    "13985.pdf": "B19F0DAA-B60E-4EA3-B77C-3B194239F0B2",
    "13987.pdf": "E8CD847B-721C-46FF-A061-B1551B4F3CC9",
    "13988.pdf": "DAB4493C-D1CB-4131-B5E4-28CC7F4FD020",
    "1399_PROGRAM.PDF": "1B74CAC7-CD6E-409F-8B41-AE8E1509417B",
    "13993.pdf": "79B37A01-B6A6-4089-BF7B-A6AF8FBBC9D4",
    "13994.pdf": "3A22F843-BCD7-4251-AF54-8179859A1626",
    "13995.pdf": "E56CDC15-2F81-4565-80F6-B762D8522591",
    "13Historical Synopsis of the 2013 Academy Annual Meeting.pdf": "38CAB2F4-B2AE-40FE-947F-2990A2E3E800",
    "13Program.pdf": "51BACCCA-02DE-48AC-A641-2B3381E692E3",
    "14 - Brace Therapy as an Adjunct to or Substitute for Lumbar Fusion.pdf": "9DB11A3B-5913-44FF-8CA2-61DB1B102757",
    "14.pdf": "93D0F5C0-3D23-478F-91F0-C97576C007DF",
    "1400_NEWSLETTER.PDF": "037FBF28-24FF-4BC1-BF46-770FD314ED41",
    "14001.pdf": "86D54052-CA6A-497E-88E8-320EABA4D382",
    "14002.pdf": "F97940B4-B569-4913-AA7E-C70BC84865C9",
    "14003.pdf": "9799D1A8-F9FC-43A6-A1F8-710148B7F898",
    "14009.pdf": "449E0EA8-C1C9-49CB-AA84-EBBD3228EE16",
    "1401_NEWSLETTER.PDF": "5E0E20EE-4FA3-4E37-A3D5-6C1D844381CF",
    "14010.pdf": "1C23BB5A-33CA-4AFC-9EE4-5E63B25E3A32",
    "14011.pdf": "B3A7B800-D284-4E21-A8B6-CD3952348FB7",
    "14012.pdf": "15862B9F-D73B-4AAD-AB63-8F2DA79FA12C",
    "14015.pdf": "C6D202C1-0052-412D-B048-118616B1E204",
    "14016.pdf": "C2030CA4-C00A-4DBC-B66C-315F58CDEA79",
    "14018.pdf": "BDAD1B07-961D-432A-85E4-9DC304759C7C",
    "14019.pdf": "E109751A-9AAC-4838-B8A6-C2E91BC6344B",
    "1402_MEMO.PDF": "BE1C1F9C-740E-44CD-83F2-0EFB89E1163E",
    "14020.pdf": "C19E688F-CCAB-46C0-9656-1B58F8262AE0",
    "14023.pdf": "4187CD28-FE77-4169-A7C3-20D1E8094F33",
    "14024.pdf": "AF3C07DC-FFBE-42A9-AFD7-5DF92A9BCE8F",
    "14026.pdf": "073D48C7-1D9E-475C-A711-54F8F08AE75E",
    "14029.pdf": "A6178B9E-093A-4188-8F46-20364368B570",
    "1403_MEMO.PDF": "B13672CE-C991-4D1B-A76B-78CE6910A6DD",
    "14030.pdf": "37845FF7-7534-4E7D-B345-AB631A5B729E",
    "14031.pdf": "068A9FF0-C48E-4515-9BD2-0AB8836F8BA0",
    "14033.pdf": "B1228381-265F-492E-9227-8EA68E30D038",
    "14034.pdf": "E1CA98EC-FCC1-441F-9878-78668FC6699A",
    "14035.pdf": "E186D28D-ED82-45DD-B409-E1ABA9E4FEB6",
    "14037.pdf": "0AC61C47-3DBE-488A-B778-D0B781123DDD",
    "1404_MEMO.PDF": "2C51BD4F-22D5-4320-BC35-5546A769FC5F",
    "14040.pdf": "2D91A840-4171-4C7F-B4A5-4B9A5CE42F87",
    "14041.pdf": "4CB4D090-C749-4317-9440-DD32A6E02858",
    "14044.pdf": "6F4493BB-77CD-4271-BF80-CF53C2D27186",
    "14046.pdf": "17B231E0-69D3-4B08-971B-790FA2BEFE8A",
    "14047.pdf": "59097F24-5E32-47BB-9D44-6880E28EE0F2",
    "14050.pdf": "A201AB69-E460-46A2-96CC-0ADE7EF1CF8F",
    "14051.pdf": "89D86AA7-C47A-43C3-B061-BE4467DEBE18",
    "14052.pdf": "3ADE30BF-746D-42D7-B484-44FFAA1478C6",
    "14053.pdf": "D14C56CE-2C2D-40FF-B361-201730E32B5E",
    "14054.pdf": "EF9C0306-7C55-43D3-A67C-1338A2E874F5",
    "14057.pdf": "645F6C8D-74B6-47FD-8BD0-10BD23FD06ED",
    "14059.pdf": "6F14ED3B-239F-4BAE-995D-4D03028494E6",
    "14060.pdf": "76C8F15E-BC0A-4F74-8740-7BD5F3277AFA",
    "14061.pdf": "E4C5FCAF-396F-4B07-9946-3CBE3C780347",
    "14062.pdf": "252539D3-A4F0-44A9-AD60-45D883245F57",
    "14063.pdf": "186978EA-82A0-4494-8597-B92F488C9066",
    "14065.pdf": "8C2C42D9-3F81-4BED-9FF7-8A27599CB782",
    "14066.pdf": "B72C8C64-165A-4750-BCD1-1B06FA317F4C",
    "14067.pdf": "B7B12173-42D9-499C-9494-D0E8A9930284",
    "14069.pdf": "FF62AFCF-D597-416B-8EBA-F487685C391E",
    "14071.pdf": "EBFBDDC0-9ACD-4BC2-97C0-63594B1BD613",
    "14072.pdf": "0E29845D-8446-4E1F-863B-064D0236A17A",
    "14073.pdf": "2F2A7BF5-A257-4D56-91B3-2A498E149B6A",
    "14074.pdf": "BAD03D93-C375-466A-80C2-020E2D71B795",
    "14075.pdf": "A9668815-A63F-474F-99F9-E7C7F0CEC0F6",
    "14077.pdf": "27AE4250-92BC-4976-ABCC-081235346BDC",
    "14078.pdf": "E8CA0E58-E4B7-4596-8F76-1B85ABBB8EB9",
    "14079.pdf": "DA061B8D-2A8D-4633-AD35-12FD0C46A96C",
    "14080.pdf": "A5769804-A86C-4918-9ADB-34D5091F7E52",
    "14082.pdf": "F54EBA44-D85C-4538-8D5C-2479A4DF06A0",
    "14085.pdf": "48678004-73A0-4845-B018-B126C2F5002B",
    "14087.pdf": "B10799FA-E9A5-4008-8925-9E51BA2DDF98",
    "14088.pdf": "8F9817F0-63FA-46F3-9BAC-DAF20B3B29DD",
    "14089.pdf": "7CE1411F-405C-4FD9-9C05-8A9B54D3FB3E",
    "14090.pdf": "263F6F2D-05E4-4AC3-958B-C6136BC4A998",
    "14091.pdf": "98713590-8B08-4B2E-8721-FD1992AE2DAB",
    "14092.pdf": "66905C78-2F87-4AA0-ACB1-7E89C229ADD7",
    "14092021152616.pdf": "785231AF-80CF-490A-B2DB-416A0E69C13A",
    "14093.pdf": "5DF772CB-D51D-41AE-AA99-D875C36212CA",
    "14094.pdf": "3D717306-30D6-468E-AF94-3DD55D6623B8",
    "14095.pdf": "D67749E5-E2FB-4A31-9B3E-FEF79E4C7A40",
    "14097.pdf": "5A710219-75B4-410C-9E9C-FAEDC43CA2F2",
    "14098.pdf": "AF25E5D1-933D-45BA-9D62-4A7F2F348DA9",
    "14099.pdf": "A2DFACD5-A5BD-484D-A38B-77A5ECC720B1",
    "14102.pdf": "DD806ACD-0659-449B-8483-FF7C209837A3",
    "14127.pdf": "B17CFAC4-27EA-49FC-AB5D-CC3DC547694D",
    "14132.pdf": "B0CC4795-1ECF-4095-AA0C-06B0251F3808",
    "14134.pdf": "B2FE4B51-2DD3-412C-BA6A-5EEEBAE872E4",
    "14138.pdf": "687ADFE0-5748-48A3-95B2-DF2458B92D52",
    "14142.pdf": "91226EB8-D0BB-4527-A199-42B200DAACA5",
    "14143.pdf": "7A22B588-3822-4A4E-A780-66B702323F72",
    "14144.pdf": "C669C7CC-2D94-4867-9708-6A2DDAAFEE7E",
    "14145.pdf": "3FABB522-4E61-43A0-A36A-C654C5B810EC",
    "14149.pdf": "AA485F20-D1FE-448E-993D-2475A92E3E9C",
    "14150.pdf": "DF8B856A-C437-4479-B9FB-F20492F35395",
    "14156.pdf": "391EFFB4-62C9-4481-A6EC-4C42885591B7",
    "1416_MEMO.PDF": "C51388C9-546B-4478-A95C-75E01625FD59",
    "14161.pdf": "54989425-1CC0-40AC-B972-216FE6F4BAD0",
    "14162.pdf": "11F51B1A-1D0C-4E3C-BAF2-4E47B0BD9F40",
    "14163.pdf": "76252D94-8F5D-448F-AF44-5D0810B02AF6",
    "14167.pdf": "59CD24EE-2B97-4D98-847B-E8205B6CC983",
    "14168.pdf": "D0966A1D-6B99-49BB-9932-BD6D8160A1CE",
    "14169.pdf": "0C9B3C32-355B-48B5-ACED-F169E18ED4F5",
    "1417_PROGRAM.PDF": "48FD5974-E3C3-46D7-A714-53EB8BA8E418",
    "14177.pdf": "84FBE31F-51CA-4D76-84F0-E33D9098C054",
    "14178.pdf": "EEB26250-77E8-4E0B-9BBD-F9D9FB0027F0",
    "1418_PROGRAM.PDF": "F47D0241-8B0A-42E7-9407-3FFF0927DC71",
    "14180.pdf": "E5D90AEF-EAE5-470D-9C52-6514CD384719",
    "14181.pdf": "3268FFDD-6337-4FB1-BC83-B7C57B9D648D",
    "14183.pdf": "0D656E86-93D7-44CD-A6DA-ED50F8BC0F51",
    "14185.pdf": "6027679D-AF4C-4879-A472-E63F5A4C42AE",
    "14186.pdf": "01B2CD22-F4B3-4C7F-ACCC-0AFA7C9120E8",
    "14187.pdf": "24F92ED8-DE7A-445F-93D8-47EBEA79C7B4",
    "14193.pdf": "2E09B347-252A-4734-8408-C60AAC2C4DCE",
    "14196.pdf": "BBF5E3A3-5571-411D-92E3-36575C9CB7D8",
    "14199.pdf": "F6FF0C51-8A32-43EF-B2DB-71F86BF9B67C",
    "1420_PROGRAM.PDF": "AF67966B-DD21-48A4-8460-252AB65D9333",
    "14200.pdf": "24817261-8109-4A97-907E-9B45452C1AC7",
    "14201.pdf": "6FAF54F7-3F7C-4B4E-9584-E2DD85BB94E8",
    "14207.pdf": "13B904D9-3C80-4F7D-98F0-62560DB5D375",
    "1421_PROGRAM.PDF": "C13071BC-53AD-433B-8799-3345FD74B6C5",
    "14210.pdf": "76DBF506-528A-48CA-970E-3249BC3DF6D4",
    "14211.pdf": "A5CADD06-BEA9-448C-A786-3AB4546A92F8",
    "14213.pdf": "9FFC8713-A527-4BD7-B988-0CF0532D43FC",
    "14215.pdf": "BE69ADC5-B11A-43A4-8F8B-F392E4B01E3E",
    "14229.pdf": "5CB7D404-9A93-4518-A2E0-5BB49648DACA",
    "14231.pdf": "7199DE21-F708-4961-94D6-0D3FC90A4554",
    "14234.pdf": "9869B1A4-894F-4690-A452-31ED2DA8D98B",
    "14244.pdf": "FFF0CB29-65C3-46AE-A671-FD82096E3A04",
    "14245.pdf": "A8103730-962D-4D75-9632-B399716EC2CB",
    "14248.pdf": "7808F9EE-6FAB-4441-90B5-CE456461CDC8",
    "14252.pdf": "39732B9B-575E-456C-8B1B-58ABBFFABDD2",
    "14254.pdf": "12018260-75EF-403C-928D-5D69784703E8",
    "14255.pdf": "4BB38B14-64F1-44FC-90CB-DD2C468BED84",
    "14261.pdf": "9D0B37AE-A317-4510-9310-81E6B6C33C69",
    "14265.pdf": "E30908D4-EC22-4537-B3D6-47791BCB269B",
    "14272.pdf": "6805B162-172B-41A3-9AEF-ACFC9D2B4135",
    "14281.pdf": "82A77520-5E2A-47AA-8CB4-AF0A18061B9F",
    "14282.pdf": "5C5FEA44-B59E-4917-9945-ED7F4CD3A3D6",
    "14285.pdf": "6A024900-0087-4846-A785-31DB845755E0",
    "14286.pdf": "511BE837-3172-4742-904A-6317A0CC8D40",
    "14288.pdf": "C5FBE601-061E-45DB-AE7C-838AC78E7A59",
    "14291.pdf": "92A50D3F-5E19-4F03-AF1A-DA7218B46E65",
    "14295.pdf": "9E85F60E-2485-494E-AF7C-7B2C10FC0FD4",
    "14300.pdf": "1140A82E-0CF2-4C1A-8F15-03DC11FA55ED",
    "14302.pdf": "046CBE05-CEB4-46AA-9346-C5C16CE4F872",
    "14303.pdf": "564C7117-A8DE-4AC3-8DFA-3A19F5C822C2",
    "14311.pdf": "A92AC96E-D0DF-464E-91DD-2B2D4B1AC0EA",
    "14316.pdf": "4C7C9D68-7C0D-4E9D-876A-24FDD4036699",
    "14320.pdf": "B2CAAC18-2066-4A18-80D6-851BBD28C358",
    "14326.pdf": "06FA2835-99D8-4B3C-AAD4-6F388E87793F",
    "14328.pdf": "9EA9EFD7-880A-4705-A313-3487490681ED",
    "14331.pdf": "C6AF379D-1E22-43D3-8F24-C997107BC30C",
    "14334.pdf": "DB26253B-083E-432D-B8D6-5227707467FA",
    "14336.pdf": "3341AEC7-54ED-429F-B609-30D6080B4CFB",
    "14339.pdf": "F87F3900-F72A-4B73-B1E1-092DCF875BD2",
    "14345.pdf": "2A3158F2-0B38-49B9-9C56-20D063296A3F",
    "14347.pdf": "F1EEE63E-0437-4AFA-BC26-CF51CDE38A02",
    "14356.pdf": "9A4961F1-9E83-4590-B060-37E2A49B1589",
    "14358.pdf": "06628408-985E-4E40-B6DC-AF1CB4E26178",
    "14362.pdf": "446D3760-A9F3-40AA-A348-0FB00A4E5B82",
    "14364.pdf": "20C75F99-D897-48B4-BC33-CCAE7E5F9BC4",
    "1437_PROGRAM_compressed.pdf": "CACEE315-58F8-4A92-A271-A8EC88C72130",
    "14371.pdf": "1B4B0782-3428-4D38-BC32-EA2F7061D898",
    "1438_NEWSLETTER.PDF": "E84AF7F4-C688-435E-92BC-41DC67EAB9FB",
    "14382.pdf": "C4DE98F5-C176-4B65-B381-55436AEBF1C2",
    "14387.pdf": "0933FE27-CD0B-4CB4-9DC4-2F8C01BB9034",
    "14389.pdf": "2D3C5A2D-E560-43AF-9C03-7F340FB4D52D",
    "14391.pdf": "27901DFC-C1BC-49A2-9C22-C5C25F3A6FC3",
    "14392.pdf": "B253B40D-7338-43A9-911D-B6C88BC031D0",
    "14396.pdf": "76FEE847-B341-48D3-86F8-144117A670B9",
    "1441_NEWSLETTER.PDF": "9E8DD0FA-91AD-4794-9113-902BE22C0463",
    "14414.pdf": "58A8E2D3-0983-4F69-A733-FCAB974E76F4",
    "14416.pdf": "782E9165-A981-4135-BF11-B36BF0E04652",
    "14418.pdf": "8855438D-10BE-4746-B91E-426A739F14ED",
    "14419.pdf": "41A813E4-DF73-43E1-9598-022D899A1E20",
    "14420.pdf": "B102643B-92CF-4545-8C9A-2F4F516078CA",
    "14421.pdf": "9F41E4BA-81D8-443D-936B-184BB88096D0",
    "14423.pdf": "9E0FC3B5-658F-48F4-8176-A5EED561228A",
    "14424.pdf": "CA40826A-555C-4AF1-B7A0-BDDFABA6FB37",
    "14425.pdf": "C23A7FCF-4FB1-4B17-BDEF-1CEB6092B62C",
    "14429.pdf": "911C7FF1-D0BF-4163-BFAB-4EEF19DB2CB9",
    "14430.pdf": "B24937DA-335E-4D16-ACAF-F772250AA6CB",
    "14433.pdf": "8770CF23-F6CB-4C5D-A184-3CDE2A961F4E",
    "14434.pdf": "2AF2D109-40D2-406D-AF28-3336C61CA1B9",
    "14438.pdf": "28992AC8-D926-44E2-BFD8-B12266C8D5CB",
    "14439.pdf": "732341C0-D7CB-439E-9778-03DDCB0CFF24",
    "1444_PROGRAM.PDF": "CADBDBF2-6333-438D-B71E-11BC8B105AC0",
    "14442.pdf": "493764E5-D72D-48F6-BE6F-FB240FD5682A",
    "14444.pdf": "374DEFD6-61B0-47BF-A12B-B4EA87C13105",
    "14446.pdf": "3FF65E1C-3E49-42DE-845A-1C93A4D4447C",
    "14447.pdf": "2D26BD90-7B4D-488D-AD42-8C49BEBA0D2B",
    "14449.pdf": "973B9CD9-2855-43F7-9092-EAFC15B74D95",
    "14450.pdf": "6F9644A7-51EB-486A-92D1-ED6565A813B8",
    "14452.pdf": "F364A136-C619-4942-9B98-9B9E9CD69BBC",
    "14453.pdf": "36538902-BC68-41F5-B027-9A8C7CBCA11F",
    "14454.pdf": "482F29CE-A417-4AF9-8FD0-0B26B6F85BCE",
    "14455.pdf": "ED43ADAE-BB17-443E-AD50-8B9FA90AD285",
    "14457.pdf": "57F8B264-ACE7-4BF5-9E46-F0B63BA7063A",
    "14458.pdf": "1BE6F679-4665-4C1F-A81C-BB88448BD0F0",
    "14460.pdf": "1CD20B68-3B75-4139-95BE-27BB76F83D0C",
    "14462.pdf": "9AD9CBC3-469F-4DC7-9F38-DB344CD7DCB6",
    "14463.pdf": "2E9403DE-176E-4447-B720-065AC2A1BA6C",
    "14464.pdf": "C8D70A5E-B28E-49E2-B987-152B0101BA77",
    "14465.pdf": "6419CF67-93EB-4A05-B0A2-21D829EBDA52",
    "14466.pdf": "1392E473-8C51-4944-A479-86D1001D78B5",
    "14467.pdf": "2B8C6FEC-A548-458C-8DBD-04F60BD5A86C",
    "14468.pdf": "CEA97E9F-600E-4348-A870-AE5316A82F3D",
    "14469.pdf": "545566C5-EE38-4CF7-B527-3A13263A0393",
    "14470.pdf": "95517671-00A5-4379-A773-C23A9686DABB",
    "14474.pdf": "6C623EC4-0EEA-4A48-BB88-C17116893016",
    "14477.pdf": "9C479E66-9948-446B-A6C2-A4F51CF632AE",
    "14478.pdf": "5DC2679B-0B1F-4961-B177-6104E3A5CC5B",
    "14479.pdf": "BD3CE381-6FF8-48BB-A64F-49E6393D5B71",
    "14482.pdf": "543DB4E2-9F7A-44B9-8415-A219E96868F8",
    "14483.pdf": "7B195DD2-6379-426C-BBAD-E0637F2AB66A",
    "14490.pdf": "0212F8A1-52C8-4676-9F96-D49CD9EAFEAB",
    "14492.pdf": "B788A3C3-1AA6-4CFE-B31B-418E5B4C61C2",
    "14501.pdf": "52F8614E-6F79-47D4-B4E3-B9B97D1141BD",
    "14504.pdf": "B8F39A9E-C7FB-46A6-87D0-522309A1C9D8",
    "14506.pdf": "E29A7FF7-BCAF-471D-A149-569FDC12774B",
    "14507.pdf": "6241E277-9483-4784-80E1-4E6F57107B3B",
    "14509.pdf": "5DA9F4D3-A5D6-40CC-9E56-0E6083B71426",
    "14510.pdf": "5FC3DB84-072A-41A9-B376-E07651B5B920",
    "14511.pdf": "ADBD4836-2222-4A48-ACD1-7D7E14E9CC5E",
    "14515.pdf": "60521C63-3977-4800-B396-14F47FFE226D",
    "14516.pdf": "126153DA-9361-45E7-BA13-AC2F4A5130A4",
    "14518.pdf": "43C1B703-324D-4A9F-B38F-CCE2507F992E",
    "14519.pdf": "61A68E89-6A4C-4AA0-BEC1-E5AB745512A6",
    "14522.pdf": "4698784C-DCD5-4A2D-911F-439A373B4A3A",
    "14524.pdf": "E2759072-3392-46AD-BFAD-24A775FBD7EE",
    "14526.pdf": "C746567C-9B1F-4A70-BB3B-87A17E8503A7",
    "14527.pdf": "36F8A240-4AF6-4EE7-8C0A-9C69D9E5CC3E",
    "14532.pdf": "674449EF-CFE8-4872-A12F-1AB4C48E54AC",
    "14537.pdf": "7045FA78-7DC3-4F83-BC4C-99C2089A317B",
    "14539.pdf": "A115507C-8859-4439-9CD3-92719E6D6674",
    "14540.pdf": "6F50B081-AF3B-4A6A-9FDE-30206AA23F33",
    "14541.pdf": "BFC9C61F-8CBA-427E-BF92-801AE2B9189E",
    "14547.pdf": "4F2FC6DA-6945-4D7A-92AE-E1F76A968EA3",
    "14548.pdf": "6A841603-4FEE-4395-91D4-63BE0E2A1753",
    "14549.pdf": "903140BC-B9E3-4179-9900-69080BB8ADB8",
    "14550.pdf": "0D4A0078-4992-4054-991C-E823DA7BB939",
    "14551.pdf": "B2B26AFA-E45A-49F6-94B2-35E3AF7F05F4",
    "14553.pdf": "9998C5EB-9B55-4FFA-8731-CB431D094FF0",
    "14554.pdf": "D8F37BA6-01D4-478A-9C68-2687271E9018",
    "14555.pdf": "265B2808-3C6C-464A-919B-829F6A604FA0",
    "14559.pdf": "0722D185-E7FA-4F85-91C4-7FF8F9189E76",
    "1456_PROGRAM.PDF": "46F9FAB5-25E0-416F-88BC-75954A547843",
    "14562.pdf": "2A773677-4A14-4BEC-A1D3-FC2F004C679C",
    "14568.pdf": "E16A1540-DE2A-4A28-87C8-33C2EEDE9A0E",
    "14574.pdf": "2691F0D9-14FD-4CE2-92A3-C3A791712CB8",
    "14575.pdf": "1E5F14DC-D43E-4528-ABCB-A088A101D7FE",
    "14576.pdf": "6CE88C87-8D47-4145-9BA6-AAB58D179F2F",
    "14579.pdf": "899E81E1-44D2-4C0B-93A1-7F929E36B316",
    "14581.pdf": "D34E5AAA-0979-4C8D-AC20-D88A96D16B17",
    "14582.pdf": "2C89A47F-154F-4379-B658-79F435DB3288",
    "14583.pdf": "12C514B3-BAF6-497B-9151-1B3DA1E8AD60",
    "14584.pdf": "77B71308-752A-4B36-8FEE-953C1A762DC5",
    "14585.pdf": "EF19B8E8-5C38-4829-9554-88CC17F6AF5D",
    "14586.pdf": "6D7DAD55-AD90-4468-9318-811274AF728E",
    "14592.pdf": "90D7D7C4-210B-4684-AB7D-CBD468DCD124",
    "14593.pdf": "9D0F6B0D-6603-4F43-B283-DEE3B4C600AD",
    "14594.pdf": "48D4DFF6-5F74-476A-AB63-7F7EAB6FF515",
    "14595.pdf": "88CA5D56-1E55-46D9-B17F-A995947FF1B4",
    "14602.pdf": "BF9ADD69-3C54-4FB6-B64D-2FB61EC47DC4",
    "14604.pdf": "9315EC10-3EA5-4EB2-904F-478EC536899A",
    "14608.pdf": "9397B307-7A9A-4E8A-BC89-5B436105E0AB",
    "14610.pdf": "941B78AE-B143-49AF-8A9E-359172CA6546",
    "14611.pdf": "585782F7-A8A5-41EC-91C6-62658B237CD9",
    "14616.pdf": "638F884F-648C-4E02-939A-D3AB9958DDB8",
    "14619.pdf": "BF7CDCA2-927E-456F-8901-243F3A8F8339",
    "14621.pdf": "94916DDB-D739-4B86-8BB2-D53770DED5BD",
    "14623.pdf": "9987ABBD-10B7-4F95-9A7D-B89EF7F33FF0",
    "14624.pdf": "8F1B25EE-2C61-4C91-8348-1C9C8513FBE4",
    "14625.pdf": "ABD68443-56D2-4AE5-9F88-06ACA58C0DB4",
    "14626.pdf": "66EAEEC1-AB70-420C-B079-2585A30B91FC",
    "14627.pdf": "5D41788F-9BE0-4C52-9D7E-C955D67754BD",
    "14629.pdf": "E4172AD7-18DC-4C4E-A484-6328BDB3E3FE",
    "14632.pdf": "C9EC6998-311C-406D-97F7-417DAC485E0D",
    "14633.pdf": "F6BEF7B3-A312-4A01-AE98-7C8CE70A344F",
    "14637.pdf": "089E6565-D94E-49DA-AF6F-5D3651064D40",
    "14644.pdf": "E1D80B64-353E-4007-B1A8-5A09E538CBBE",
    "14645.pdf": "72A1E184-9A5A-492B-9555-C01AE6212B6A",
    "14647.pdf": "F4EC587E-77C9-45D6-9F4A-24979DF8C513",
    "14649.pdf": "4637CA82-2F65-4D56-B969-B4F28DF4C8D3",
    "14651.pdf": "BDE4C363-CB38-4F47-A336-AAEB7732238E",
    "14653.pdf": "16804481-CDA5-4BCE-84CC-5C9D681BED50",
    "14656.pdf": "472AA719-2E83-4A91-BAD5-2D5A896136F2",
    "14657.pdf": "ADDF3A5C-0A78-44EF-92FE-4220F5D7552D",
    "14662.pdf": "F3252F6A-7A91-4AA4-8A9A-568A92C46667",
    "14663.pdf": "3CDFB7D2-FF07-46A0-A9AE-6C43EC2F518C",
    "14665.pdf": "1DF603DC-D7E3-4330-B710-5AC3BAD727E8",
    "14667.pdf": "FE834947-3B70-47DA-94CA-47B4B835B3F6",
    "14668.pdf": "39184A46-48DA-4EF8-8543-34453ADBA4AA",
    "14669.pdf": "41BC35C8-911B-4E4C-A92D-6BB6392628FE",
    "14670.pdf": "01C9CD0D-24D9-4083-A3CE-E4FB368485CE",
    "14673.pdf": "D8882847-D8D8-4ECC-8882-9A6009F39BC4",
    "14674.pdf": "DC9AF100-1780-4319-9CEF-19102BD3B8A1",
    "14675.pdf": "EAE03D93-C31B-4B96-BC23-7276A7F25EA2",
    "14678.pdf": "45683AF6-C885-4143-B8DF-728C7A9975D1",
    "14679.pdf": "1B481815-FB36-434A-ABCB-81D22471F52E",
    "14680.pdf": "5AA1DFD3-B3D8-4AD6-8ACB-855725C2DB1B",
    "14681.pdf": "12CB8FE1-104C-4A90-9CA4-98CD5205B575",
    "14684.pdf": "143E3EEC-972C-44D7-B384-9B61E53D9523",
    "14685.pdf": "060D9450-C55F-4DA8-9C6D-C1EA31CAF6C4",
    "14686.pdf": "C574F2A1-E37F-4347-B1E6-1191B810C101",
    "14689.pdf": "37A74CEC-5E92-4F8E-BBCA-F19C12A57A4C",
    "14691.pdf": "E7F004C9-D017-4C54-8867-F16FC08440CA",
    "14692.pdf": "B0B2ACB6-5821-4765-B5C2-B168FD12EC4E",
    "14694.pdf": "B7030498-A18C-41D0-8B1C-C95AD3F705F2",
    "14695.pdf": "EEC4913B-256E-4672-95AB-FF828D5140FE",
    "14696.pdf": "D9DA69A4-3DEB-4638-B019-7CDF141492E7",
    "14697.pdf": "53BE7FF3-E052-4FC1-AA14-62E7B335A8A4",
    "14698.pdf": "D6F4AC1C-8DCE-41D8-AD97-800B623A35B0",
    "14700.pdf": "2947F22C-1868-4B2D-B20E-F42AEB7601AF",
    "14702.pdf": "05B4B0B2-E2B0-4E02-B59B-A64507512734",
    "14703.pdf": "B2BEC8EA-7C29-4B24-A5F0-69866871672F",
    "14704.pdf": "87B9DEDB-A0ED-446C-87E8-3C831896BDEE",
    "14707.pdf": "7584D28B-6412-4E2F-B609-B2EFE286C871",
    "14709.pdf": "9CE7D5A7-3167-42BC-988F-C01FE867D90A",
    "1471_NEWSLETTER.PDF": "EF485D78-F747-4FE7-AF3F-3067CE184E6F",
    "14711.pdf": "E383BA12-73EA-46CB-8F10-3B8574419F65",
    "14712.pdf": "316DF8C1-928C-4736-BABA-9E74A2F85641",
    "14713.pdf": "7AAF8CAB-E5CB-40C8-BEE6-BAF3832D9C17",
    "14717.pdf": "AB8AC5C3-ED25-4CDD-A18B-78D64649D75D",
    "1472_NEWSLETTER.PDF": "5BA796D0-0501-418C-831B-61CDA67324DF",
    "14721.pdf": "94046FCC-3ADA-4028-AFFB-785A7379B3A3",
    "14722.pdf": "CB80D829-6607-40A5-AFA4-97FC46EAA036",
    "14725.pdf": "27346B08-05A1-45C1-9783-49B1C453076D",
    "14727.pdf": "3666BD46-B01D-49B3-AD43-0F982D5B4591",
    "14732.pdf": "1DC6E459-4390-478F-BD64-45C323560B9B",
    "14733.pdf": "EF381904-DF24-4D21-9134-84EA7057D6DB",
    "14735.pdf": "4EA40048-327F-4A79-8625-3E91BEF80FBE",
    "14738.pdf": "DD731319-C277-4D06-BB44-CAEF2B72F68D",
    "14741.pdf": "B96F9F83-0498-4F72-9CAD-D26BBDD31E96",
    "14743.pdf": "3B39A1CF-648C-4FDE-B835-FBC83FB4327F",
    "14744.pdf": "29AD766E-088E-4FE9-9DE9-1AE1E24D342F",
    "14748.pdf": "23BE95DF-3922-455F-B06D-2BBB18D97707",
    "14750.pdf": "CAAE0DAA-A3E7-4887-81FF-130323C3FE63",
    "14757.pdf": "9A6CE1D4-9199-42D8-A66E-652EDB88FCAC",
    "14760.pdf": "8755329F-148D-4BBB-B1A5-EBBE23717A81",
    "14761.pdf": "64BB3BA7-D601-40D4-B61C-762F1888664C",
    "14762.pdf": "2AA155BB-AD26-4BBF-9D79-25F3343FC6AD",
    "14763.pdf": "87EBE884-B7A7-46AA-AF08-E154B5B36AA4",
    "14764.pdf": "412BAD2F-74E4-4BF9-B43B-469AA49916E3",
    "14765.pdf": "DC2127EE-1513-4872-A101-B364E72A8B84",
    "14768.pdf": "9C045A4F-5CD6-43EA-BD18-4735A973605D",
    "14769.pdf": "49E19869-0EF6-4E69-A99D-257FC4A38B34",
    "14770.pdf": "1B0A057B-135A-4079-893A-7F1ACDDEF73F",
    "14771.pdf": "C805F086-4E47-4895-B17C-67DAC98FC020",
    "14772.pdf": "7CC3F684-1739-4281-8641-4F1B6DDFEFC7",
    "14775.pdf": "14301112-6F1E-4A9E-B2E4-DF6508B0DF22",
    "14778.pdf": "FC703DB9-7420-4C63-B56A-9F87028E1AE0",
    "14782.pdf": "FFC7CC6B-A676-4A26-954D-E032C7FABACE",
    "14783.pdf": "3FC601AE-8454-4105-98DF-EEE38EBC7796",
    "14784.pdf": "1846F0AD-20E9-4757-83D2-CFEB43B4D7D7",
    "14786.pdf": "98326109-A01B-4698-974E-16590322EEAE",
    "14787.pdf": "7E65B5BC-F48A-4E50-864A-3ADEE6C550CE",
    "14788.pdf": "9CAEFC6E-F041-4832-814F-98E30BDBB4C2",
    "14789.pdf": "A5D602E6-4EF5-43E1-87CF-5E5304E54A23",
    "14790.pdf": "74FC404E-18A6-4EE5-85FF-43B800B8D504",
    "14791.pdf": "B8FABEDA-82B7-4117-8CF3-20BB45F53BDF",
    "14792.pdf": "8708D6EE-8BBD-47B3-9EFD-75140B35CF7E",
    "14793.pdf": "48A9DF0C-F8DF-4826-9B41-F3F723679EDB",
    "14794.pdf": "78AAF67D-9380-44D9-88FE-5588A60C40A7",
    "14796.pdf": "8EB089CD-1519-4D78-B97D-59048AFAB570",
    "14797.pdf": "6B388A10-A9AD-46F6-848E-15050CF5D0BC",
    "14798.pdf": "C0E90AE3-E01A-43C0-BF5B-B405E6786E67",
    "14799.pdf": "C9414E29-B561-4D04-87F2-D546BC6400D9",
    "14801.pdf": "540AFA63-40F9-4BF8-9902-79B64DEC96C5",
    "14803.pdf": "1FD560A5-9719-4D2F-9444-5ABB89A907E1",
    "14805.pdf": "F7A41AC5-4793-4F45-A1FC-93A1D80626D0",
    "14806.pdf": "B82D7CAB-569B-4F97-B779-88D0C6636A7E",
    "14807.pdf": "C453472C-5CB9-49A9-90D2-810BAE5D7D7D",
    "14810.pdf": "FB2AC191-A2EC-4ADE-AE63-2F691E0FC9C3",
    "14812.pdf": "7BC35668-CA84-439D-B29F-9398E535BC96",
    "14816.pdf": "F1C102C9-C34A-43F5-B298-31956E221B9C",
    "14817.pdf": "8C45F8A3-2C24-4D2A-B66C-96C02D357E94",
    "14818.pdf": "0CEA5C7E-A9B3-4A38-B38C-D1CC432F510E",
    "14819.pdf": "8DA107F1-1CE1-4444-B86A-E5B642ACF630",
    "1482_NEWSLETTER.PDF": "8BE384CB-AB3E-491D-B529-2260B68E9E76",
    "14821.pdf": "18942141-FE41-46D3-AF25-F18F5DCE2C08",
    "14822.pdf": "79BF8B21-A6B9-4853-AC4D-D78E921924FC",
    "14824.pdf": "809C5C8C-9E54-48E6-9532-7EDDF701B4BC",
    "14825.pdf": "A8C3418C-66F1-4FBB-8CE1-3BDBCC887165",
    "14827.pdf": "CCB32AD2-A5F1-4A92-BF58-48A7CAE6900E",
    "14828.pdf": "DB363952-0F80-49D8-825B-2F7657425A3D",
    "1483_PROGRAM.PDF": "DE923508-74E8-4909-850F-1A08EF73206C",
    "14832.pdf": "F341B79A-38D6-48D3-80F1-0647F500BCB1",
    "14833.pdf": "E9402888-DC3F-49A6-9D19-FCEE747E0CE9",
    "14834.pdf": "C1DAF4E8-07BE-4CA9-856E-3012D1987F76",
    "14835.pdf": "F7761DD8-0B7C-4EDA-9BAC-3F362380F725",
    "14837.pdf": "F2749ABF-1CFC-41B1-88F2-FB5A0ACCF5A7",
    "14840.pdf": "F8F39383-8846-4B91-8260-1AE5F14AC146",
    "14842.pdf": "7BB569F8-DC4E-49B9-BDA1-77C2EA1F3CED",
    "14843.pdf": "FB23F54E-CABD-4D36-A62E-B8458873B012",
    "14847.pdf": "0024A39C-1888-4D91-8A15-5F7D31B3517C",
    "14848.pdf": "96588DDA-14A4-4DC1-B2FE-299C739B7D09",
    "14850.pdf": "D22FB85F-3D8A-44B2-958F-87707588A64C",
    "14851.pdf": "3A850A8D-90AC-4F77-9EBF-C3F9304E3251",
    "14854.pdf": "B69D6D50-D6FC-4657-B53C-35B896D809B1",
    "14856.pdf": "3A6C3008-81FC-4D43-841D-7EB84B9EC43D",
    "14857.pdf": "41445228-BA12-4809-98CF-26EFDC51A270",
    "1486_PROGRAM.PDF": "931141E3-3CAE-43FF-A60D-A9FCA5B185B2",
    "14860.pdf": "122164A1-8DFF-4A7D-BC29-C8E63CABE924",
    "14861.pdf": "584A28BD-7606-4F74-AE21-4DC32F47997D",
    "14862.pdf": "A7E309E7-0987-4C52-8D2B-4C1E4D06D8EE",
    "14864.pdf": "2CD7CF2F-DA74-474E-99B4-C1089019531E",
    "14865.pdf": "BAACA0F2-4888-4D88-80EC-2E3898D0D957",
    "14866.pdf": "147CB5F0-8F54-4458-B87A-B173EF1704EB",
    "14868.pdf": "F3EC8FDE-A0AA-47F8-B207-6E2BBA3219CA",
    "14870.pdf": "331A7B34-88B7-46AA-A2BE-1E24C72F0BDA",
    "14873.pdf": "99742DA8-529D-41E3-9DA8-6B074FD0661D",
    "14874.pdf": "6A3191DB-9544-4208-B0B1-52AEC6D5FDAD",
    "14877.pdf": "8844B88B-CCB7-44FB-8BE8-94E97B66FC7D",
    "1488_PROGRAM.PDF": "2DC2740E-E216-42E2-ACC8-EB7A0E846756",
    "14883.pdf": "8FE8AFC3-87AE-4A07-AD4E-6BC6ABA70C3C",
    "14885.pdf": "C7127526-8D48-444D-984E-C87117517453",
    "14886.pdf": "EECFE0FC-AF3C-4D72-88F2-5197FEF6086D",
    "14887.pdf": "DC36A11E-2EB7-426F-A37D-B3F026058D26",
    "14889.pdf": "94DF9C8F-C751-4F21-ABA6-A247670565D9",
    "14891.pdf": "ADBBD78B-09A4-4DF9-B87C-3C91CC4F11A0",
    "14892.pdf": "353EF3C0-0091-4ECB-A78E-EE1A59F05416",
    "14895.pdf": "5C584C8F-67C4-4A22-BC9E-F4AA2AF2FE73",
    "14898.pdf": "4E085144-3DAA-46A0-949B-E71FD726CA77",
    "14900.pdf": "937AA59A-97B7-4602-A187-4461C93F66EF",
    "14903.pdf": "CE9D1EA2-80CB-44C7-B62C-003CC787CBBB",
    "14905.pdf": "D92710F2-DF2F-4130-9D5E-0F8423B91CF7",
    "14906.pdf": "AFEF34D6-535B-4E8E-9E8A-D1E8FEA48329",
    "14907.pdf": "00027C56-6352-4429-AAA6-DA726F5A7C4B",
    "14908.pdf": "78F6A129-D745-4ECE-8C96-888B3C57411D",
    "14909.pdf": "16884E6E-3071-4517-A2ED-7223B1C60462",
    "14913.pdf": "CAE3E7D1-0B0F-4DE7-B7F4-53853F7B7CF8",
    "14916.pdf": "6C2017C2-DC01-4404-A104-2DE657903B3C",
    "14917.pdf": "3FD438C7-4AC1-4C90-887F-394F06A18150",
    "14918.pdf": "091650DB-8E97-4A77-8A54-23DDAE83914B",
    "14921.pdf": "00414FA6-97B5-420E-B417-C4B8FE9336A9",
    "14924.pdf": "804E7877-B81C-485E-9B12-04A817F8C3B3",
    "14925.pdf": "2C38DFBE-0F38-40E0-BE53-06698C281BFD",
    "14926.pdf": "1C6E8F57-BB5F-4DA3-83B7-E4AFE128E6CA",
    "14928.pdf": "CF5D1807-5D05-4AC2-B0A0-2424116C5685",
    "14929.pdf": "94A84C17-CE95-4128-A6E0-93D70D555CEE",
    "14930.pdf": "13E5FF70-F4B2-4569-8846-5638ACF4A897",
    "14931.pdf": "8CED8782-34F2-4196-82C0-9DEB451B967B",
    "14932.pdf": "4AC50CE1-95FB-4994-B84F-B95CCA64964B",
    "14933.pdf": "C5FEA93C-4074-495F-A016-9A323DDBB932",
    "14935.pdf": "3B8302E1-1A57-4D43-8E26-F129C826D7F4",
    "14939.pdf": "B5CF3455-B03C-4C6B-B0A1-8F5CB318B2AC",
    "14940.pdf": "D0BA79C1-D65B-4D36-B845-95377C17FA08",
    "14941.pdf": "84C27480-9D7A-4429-86FB-6D25D1E82CAC",
    "14942.pdf": "A7534351-3968-498F-B595-F9C65DD731D8",
    "14943.pdf": "C221553F-36FE-4CED-B12E-C5DB2A3AAF22",
    "14946.pdf": "E9A32400-8A13-4A6C-9F8E-DCBC51673FC1",
    "14947.pdf": "DB6DFD41-72B8-4085-967F-B8D15B6E190C",
    "14948.pdf": "1F0DA259-930C-4E95-B8B6-EF1A509DD821",
    "14949.pdf": "B6C087FA-99D7-438A-9D62-4885DE1015EF",
    "14952.pdf": "4D96A312-6B39-4D39-9857-0FE60F0C8243",
    "14954.pdf": "CA62E8B6-8811-49C5-8979-2025D31CDED7",
    "14960.pdf": "000CB133-223F-4C92-B62A-AE57385CC61D",
    "14961.pdf": "997AAC53-DEF6-4658-87C4-202D6DAA79F6",
    "14963.pdf": "A19F3E03-8A6D-45BD-B85E-8683F06DE543",
    "14964.pdf": "E0E1AA69-A318-4DCC-9DCC-CBB37636B841",
    "14966.pdf": "F8EFECF1-801B-4A02-8905-C1EC9BE0051D",
    "14969.pdf": "87431E19-CCC0-4949-9EB4-84F40228EBBF",
    "14970.pdf": "C0D2CBE2-C336-4304-B22F-826D4E67ED6F",
    "14971.pdf": "0721A00C-F5C1-4940-A627-540055F5E013",
    "14973.pdf": "56CC0421-E720-483C-9311-3183003BA659",
    "14974.pdf": "128DA054-279E-462E-97E4-42BD82679D68",
    "14975.pdf": "773A8606-3D3C-41B4-B0AD-42354A95C014",
    "14977.pdf": "AA283895-F4ED-492C-8D4C-1E91AA045195",
    "14978.pdf": "60B01FA7-EB06-44A0-99BF-FA2618053A11",
    "14979.pdf": "AA980157-DDAB-4EF7-82A7-84493DDC3210",
    "1498_NEWSLETTER.PDF": "18EBB473-4ED6-490F-827C-767246C22DF7",
    "14980.pdf": "C4ADCD83-5583-462A-82A8-76028D107AF3",
    "14983.pdf": "25C84E60-ED6C-4CAC-9FBC-1813F31180E3",
    "14984.pdf": "47220767-1668-4632-8144-C58DAED306EE",
    "14985.pdf": "EE16232D-953E-434F-A830-774E8F97C736",
    "14989.pdf": "76CB8CAD-B494-4373-B07C-9189B83601D3",
    "1499_NEWSLETTER.PDF": "B8E2292F-1FE2-452C-8543-30BEE8E76046",
    "14990.pdf": "2D7F90AC-AC12-40EE-A57B-D916D0A0F93E",
    "14993.pdf": "F8F6C679-9A54-4303-99BE-B1C574866F61",
    "14994.pdf": "D723C229-A2E4-4763-9BC8-5E1726174BFB",
    "14996.pdf": "8C7268DF-A652-468A-97F5-9E484D3466AE",
    "14997.pdf": "B11CED49-678C-44B8-A728-FD651F9B6751",
    "14999.pdf": "6BA18384-C0E4-4FC1-A2D1-A121B6BF053F",
    "14Historical Synopsis 2014.pdf": "6EB4B442-E243-446A-9F72-C0856932FFC7",
    "15 - Electrophysiological Monitoring and Lumbar Fusion.pdf": "9FA160B6-EA04-4C63-8492-95FAF5415C85",
    "15-0547-opioid-aans-cns-ad.pdf": "737E7E08-F282-451E-9BCB-5047F7D8EFB2",
    "15-0547-opioid-aans-cns-ad_0.pdf": "10EAE7F5-E426-4AF4-995C-6B55C3641CF1",
    "15-0547_aans-cns-opioid-ad-amaandcobranded_0.pdf": "B8609115-AF96-4AB1-A3DC-A4BDA026FF6C",
    "15.pdf": "1413CABE-3FFC-4DCB-91F7-AAEE399F8DE1",
    "1500_NEWSLETTER.PDF": "671C175C-C682-4AE2-9D88-7FC71F7EA456",
    "15002.pdf": "AE002B87-E456-4DC7-9D07-95E67CC8A96F",
    "15003.pdf": "E91C5650-353A-4446-A4A3-D93F2618A91D",
    "15004.pdf": "4F01AE31-9187-421D-8895-16B16A9F5003",
    "15005.pdf": "FA13F792-F549-4176-8779-FA73AA9FAF7B",
    "15006.pdf": "EF4CBCEF-471B-4282-B2B4-1AC82B76A9C6",
    "15007.pdf": "77A86AF0-DD31-4A32-A806-125439AC2709",
    "1501_NEWSLETTER.PDF": "DA954102-58F5-412E-A48A-66BAAAF68F41",
    "15010.pdf": "77109EE3-2720-4055-B1F4-7DC3CCAD4635",
    "15014.pdf": "072EA3C4-560C-4765-B366-27350EBDD875",
    "15015.pdf": "A8DFEC90-F2B8-4DB3-B4C4-A75BC7B0BCBF",
    "15017.pdf": "FDC2A4DB-DE08-4183-A155-6899F41E9334",
    "15021.pdf": "B5FD1740-3E17-4E81-8E47-CD85D9720F81",
    "15022.pdf": "B6B0D071-BD19-43C4-9279-2C29BC266F4F",
    "15024.pdf": "970F12CF-F5BD-446F-8F77-D1B14E50D91B",
    "15025.pdf": "46255DC9-B716-4BAA-815B-745FC2A674CC",
    "15026.pdf": "520C3D30-6FDC-4B48-924A-4744357CE06E",
    "15028.pdf": "49EC7ACE-F823-46EA-B3FE-52F5307744C5",
    "15029.pdf": "0604500F-6ACD-4028-9F3C-BD35655C6DE5",
    "15031.pdf": "A3B0C11D-BC35-4E89-ABF0-1A9790B9121D",
    "15035.pdf": "8B02E192-0EB9-4638-9B4A-34E74A77F536",
    "15036.pdf": "7E63F4C3-0975-4F1C-8247-BFDF2401C13E",
    "15038.pdf": "1F771C81-C49D-4005-9604-2D476966640F",
    "15039.pdf": "BB43BFC4-0D44-4134-A35D-8AF0C11B227A",
    "15043.pdf": "B0461D8E-9492-4F96-9451-7724F38107C1",
    "15044.pdf": "37CC0EF9-9E76-4814-B9C4-F084F9A6AED9",
    "15045.pdf": "5AFA4552-1131-44AC-87C3-8A15F4C17D67",
    "15046.pdf": "91B7B307-ED43-4F84-BAC3-55E1D1C6F060",
    "15048.pdf": "EB779EA2-2034-44B6-AF4C-1CCF8BC8C923",
    "1505_BY-LAWS.PDF": "ECC8B30E-1C19-4F0C-9E99-4888EEA7ACDE",
    "15054.pdf": "53EB7554-3AD1-46C1-A2E7-E227B3BB1F50",
    "15055.pdf": "642A3E3E-356C-4970-B1EC-E4414528D595",
    "15057.pdf": "F467A69C-5AE4-4376-AB0A-9F48407C9EF1",
    "15060.pdf": "6F5E82D9-99B1-43AB-866C-338C30D4FB39",
    "15065.pdf": "07358BE4-6EE2-4679-B5E2-C3DF4C454F2F",
    "15066.pdf": "A46B2B86-98CA-4631-8AC4-04968539C4B0",
    "15067.pdf": "5E289E0B-23EA-4049-8CC9-23BCBB4C9A23",
    "15068.pdf": "A5757910-60C9-4D03-8DB0-2AD0FD70C48D",
    "15069.pdf": "BCD4B3D3-657E-4C57-97C5-B157B49B55AC",
    "15070.pdf": "C07B7073-19E7-467F-8138-494B45454F4B",
    "15071.pdf": "49B09D12-6178-4829-8B21-0C019E705CBA",
    "15080.pdf": "85E1D815-649F-4B5B-9F15-C8A7B84F0975",
    "15082.pdf": "D80B5F83-05C5-4945-8E44-51511E93646E",
    "15085.pdf": "A50AFF01-93F2-4529-859F-7F1E8DD86F83",
    "15088.pdf": "DA5E4D73-16E4-4AA4-AF93-C201F725EED1",
    "15090.pdf": "FB8DD6C4-0C6E-4340-B0AE-459F4EDDE05E",
    "15091.pdf": "82EC7EF3-F48F-4444-89C9-4CA49DC6166F",
    "15092.pdf": "B10AFF2A-13A4-4BDF-B016-7AC457AAF838",
    "15099.pdf": "F7739021-CAA0-45A6-8090-6E79138FFADA",
    "15100.pdf": "BDE52A44-B7E5-4D7A-A04B-0C4AB98FCF92",
    "15106.pdf": "49484A01-79BC-42F4-9256-F55DABEABF7A",
    "15110.pdf": "D789F95C-3370-4E70-AF45-4C18FCB07604",
    "15111.pdf": "444FEED6-708A-4C10-AE76-A643FB9E4D78",
    "15112.pdf": "8C200F62-6CC2-4027-8C83-190BBE9E250F",
    "15113.pdf": "5540AA31-DBE2-413E-A424-92077655FA43",
    "15115.pdf": "F031BEA4-D07B-4CD2-B44C-5E5558DF8869",
    "15117.pdf": "08C3C664-4085-49BD-9960-067EDF28BF6B",
    "15118.pdf": "36592DC8-9A99-42EC-B938-A2135FDFEC54",
    "15120.pdf": "ABC962D1-6508-4ED6-8B86-CEC51385A766",
    "15124.pdf": "A9287CB4-8AA0-49E4-AB94-9642A45C0CFA",
    "15125.pdf": "2056A005-37B0-4807-A785-D65379CB7EA1",
    "15127.pdf": "3A08E87D-0AA7-4F7B-8D50-95C3055ED6BA",
    "15128.pdf": "67470102-F1D1-4833-A179-451B776F9C83",
    "15129.pdf": "B6CA7969-326A-45D9-8ADA-D289CB30967F",
    "15132.pdf": "B99617CA-E6AC-4419-B7F9-E17BCA23F0D1",
    "15133.pdf": "E5C06EF9-F0BB-4352-83EF-DE4904BECB85",
    "15134.pdf": "92AEFB3F-7A79-4F32-B760-7EC8D47DBDAD",
    "15136.pdf": "B4EFFC12-07F9-44FC-B786-6FD67A2976B7",
    "15142.pdf": "17D9AF7B-6A02-4602-986B-61B047C51B02",
    "15148.pdf": "147CFB78-DCAB-4E29-9AE8-4B3A4CAE121A",
    "15150.pdf": "C41759ED-C4EE-44EC-A5FB-EB39278994F1",
    "15151.pdf": "36345456-131F-4752-AC23-F970BAEF891C",
    "15152.pdf": "4E122063-C74F-4778-9A5F-607AB948B691",
    "15153.pdf": "1CCA8BC4-4A5C-4B9E-A707-F470153F86B8",
    "15154.pdf": "325DEEDC-2BE9-4219-B899-2C1706F0AE31",
    "15157.pdf": "9EDB4CE0-C1AD-472A-9722-A34766F79233",
    "15158.pdf": "82E6819A-EFA6-4246-918E-D31879CFD609",
    "15161.pdf": "79568BDC-F267-414B-BF55-3079CC9D423C",
    "15165.pdf": "5C4B1DC0-BED8-4FCE-B375-1C6432B693FC",
    "15167.pdf": "64799EFD-C177-4D84-AA6C-33F8E56755F8",
    "15168.pdf": "0C019790-1209-4A7A-A18A-3E01EC34CB01",
    "15171.pdf": "37DC813A-84B1-4894-BE6F-316BBC63CF90",
    "15172.pdf": "6CE0CB9A-7DB7-4532-B2B2-444DE6E4E437",
    "15173.pdf": "2D616833-E02B-446C-8D83-A464D164A1A0",
    "15175.pdf": "88FCAA85-3FF8-48F2-878A-E4FD2C3BDDA6",
    "15178.pdf": "00224D98-4346-45DA-879F-53032F0E1C99",
    "15179.pdf": "E85860E5-2611-46D6-AD52-B5C0F59376C4",
    "15181.pdf": "C749FEAB-7E04-48A5-AD6D-809DF3E78FE9",
    "15182.pdf": "33F1ACB6-B8C7-46A9-ADD9-A39DF5EDAD77",
    "15184.pdf": "D18E65C1-C58E-49FB-B839-EFCA3806D044",
    "15188.pdf": "8B681F07-8C02-4FDD-9BF9-0A2647308E40",
    "15191.pdf": "208CB80D-CC7D-425A-961E-681C1B26DB74",
    "15194.pdf": "5725D652-4E32-4324-945A-F8C78475B1A4",
    "15198.pdf": "366AA359-E36E-4972-B084-C9B0C9D804B0",
    "15199.pdf": "E0E428C2-BBD9-4F89-83FD-C0183809EA13",
    "15209.pdf": "57A2D1BE-D526-49B8-A599-29AF60C703B7",
    "15211.pdf": "0CBA8A5A-F182-4A21-A537-AE56CC1C8863",
    "15212.pdf": "B365CF0A-D14A-48C6-9E83-5CD8502AB3CE",
    "15213.pdf": "01F05190-29AC-4EFF-9D4E-A39825674244",
    "15217.pdf": "7203D76D-18EF-46FF-9990-7EC321A625C3",
    "15221.pdf": "93483406-3199-4211-B836-C8138E27E143",
    "15223.pdf": "CBC5AB49-23A6-43E2-A969-503A922F5468",
    "15225.pdf": "1E38766B-6C87-438D-A25B-C35E160DDE91",
    "15229.pdf": "9F1952AE-B705-452E-9481-53A0CA381339",
    "15231.pdf": "A1BE6FA3-FF8D-4A16-A86E-F4FA9A8A71CA",
    "15234.pdf": "6701A560-2029-40CE-8A25-88155D751893",
    "15240.pdf": "E7B66A85-B77C-468C-AB88-C28011E16890",
    "15241.pdf": "2E7AE15B-59B3-4713-B266-E4E4EA3A23BC",
    "15243.pdf": "89CC0641-C063-4778-8E79-8B9EE2F12509",
    "15245.pdf": "CB554983-3D44-42F7-883D-8D6CEBA36004",
    "15246.pdf": "5E092424-DB19-40B8-92EB-9DFF934A417E",
    "15247.pdf": "66C7FFD4-2258-470F-94C5-1A79DBF9365E",
    "15248.pdf": "99AF0DA0-8822-41BF-902E-F12C83F44E95",
    "15249.pdf": "2FA3E28D-FADA-418A-A8D3-86FFFBFF4FC7",
    "15250.pdf": "ACEA7060-651B-4EDF-9B37-2DB156E3EE7D",
    "15251.pdf": "A8344461-F22E-40D2-8385-3888A0D0292B",
    "15252.pdf": "AEC10A52-4478-4B96-91FD-F864FDA50DAC",
    "15253.pdf": "FAA8DF6F-EB5B-4670-B36A-BEBD237F7427",
    "15254.pdf": "B2EF0331-43B7-4CDE-96F6-959511DE27F9",
    "15255.pdf": "F88D6590-472B-44A7-80AC-58AD9B7B3066",
    "15256.pdf": "92B822F1-2568-4546-B7BE-BB0DE4ABA380",
    "15257.pdf": "ED7016ED-E1A5-476B-9DCE-1115DF4359DE",
    "15260.pdf": "2B3BAAC5-4565-42E3-812D-6C5D1A527852",
    "15262.pdf": "8BB0FD1E-FC44-4A89-8287-5130E20BA0F6",
    "15264.pdf": "FDA1EABE-3234-4CF4-AD83-0936AE9B2B05",
    "15266.pdf": "5A04BBF0-44FC-4BAB-B8E1-1B64BA841D8C",
    "15268.pdf": "53DA0AC1-F1A0-4083-8FCF-7DB7FE878288",
    "15270.pdf": "369F881B-BA0E-4270-9641-39F701580147",
    "15271.pdf": "7CFC2781-67EE-433E-BE16-EB8C27465EB5",
    "15278.pdf": "754DA6DC-150F-488C-AFD2-2C4CDD8D0DBA",
    "15279.pdf": "96980772-AAAD-4242-9BFC-27A0BCE328BD",
    "15281.pdf": "5E4DEC2A-9E0A-4A75-A459-35B8912D3D6F",
    "15282.pdf": "1664CF91-340C-4FDC-A879-D50533375CEB",
    "15283.pdf": "6EADFCDC-75FF-4D96-9EAA-DB0BE4118275",
    "15285.pdf": "C96A9ED4-28D3-4E9C-8DED-B851C92FEC13",
    "15290.pdf": "BCF273B6-E876-45DB-9BCE-332953996E04",
    "15291.pdf": "843FD1E5-9B49-4CFA-B3CA-98A663E63332",
    "15293.pdf": "82907585-C2E6-476B-A200-5478EC6B83CE",
    "15294.pdf": "EE5C1D42-C188-40A8-B104-447560C536D2",
    "15302.pdf": "47C99E32-111C-4E05-BF1B-5892A2483448",
    "15303.pdf": "224E245D-7374-462B-ADCB-4C756C3F8E44",
    "15304.pdf": "73D83D51-998D-4AF3-81CC-354A4A3C33E1",
    "15305.pdf": "463CEE64-5F09-4B05-9A94-DE9971F2A51C",
    "15306.pdf": "E323CDC3-8DAB-4CAA-93C5-57EFFA739E71",
    "15309.pdf": "4CC813A5-51A2-41B3-A93A-4C98CC9D5A2D",
    "15313.pdf": "4D10A197-AAE9-4836-AD48-707FEADAFB28",
    "15314.pdf": "A2221A3C-F974-4CC1-9ED3-70B5E006A847",
    "15316.pdf": "2B06B0CD-5479-415E-9C53-0E4EFE8EFE61",
    "15322.pdf": "B620958C-805E-4EC7-B76F-411B10CC6F3D",
    "15324.pdf": "41C13AC1-AF52-40C6-817A-DA2D5B131296",
    "15325.pdf": "F8339B93-8522-4153-BE21-FDCDDDD0EDC6",
    "15327.pdf": "A8DFDD19-5F3A-41E6-A21F-D8BDD880216E",
    "15328.pdf": "4F17F56E-CBEB-4C2C-8989-403AB9CB6BF4",
    "15329.pdf": "09BE37D9-21AD-4066-B91C-82AFC3239A2D",
    "15330.pdf": "6B596294-CBBA-40E6-96F4-B9253070ADD2",
    "15332.pdf": "A0EDDDB9-F04C-472D-9FEC-51D2DEB9948B",
    "15333.pdf": "EA6E8872-395C-4433-88F5-C6A927EA94A7",
    "15334.pdf": "F5F84258-6900-4445-BAAE-5C7817A34186",
    "15335.pdf": "359D17C4-36E2-4975-9CC2-56B1C35278C8",
    "15336.pdf": "E0504D01-5C2C-4483-A222-A229C156882A",
    "15337.pdf": "B75CDC8F-CCE5-4298-BB35-3DCB5F9E689E",
    "15338.pdf": "8A25D28E-B797-455D-A2AE-2EFDEDB64271",
    "1533871715432631.pdf": "5EFB50A6-F0F4-4734-B906-2760137FA845",
    "15339.pdf": "4A47E66B-9B0B-4088-8EA9-A4F311D53EE4",
    "15340.pdf": "2341C15B-A96B-4916-A164-667ADDE58C58",
    "15341.pdf": "EA9B207E-EB82-4CE4-A24A-12139B53472C",
    "15342.pdf": "8EEFC774-0FBE-44C7-99B6-3E2B337CF623",
    "15344.pdf": "2A96C1D5-2861-4761-847B-297BCE6E6696",
    "15346.pdf": "9D08D607-102B-42B3-8BAB-38E4C663894C",
    "15349.pdf": "08DA4E64-E76F-4924-8F26-90C8B80113F7",
    "15350.pdf": "3874F7FD-D250-4950-A1BA-77F92D6853FD",
    "15351.pdf": "775B583D-0AAD-4BEC-A5C7-1D11A557FF0E",
    "15353.pdf": "2150DBDC-E099-4985-AC8B-7C8F015A4B78",
    "15354.pdf": "E624CB0B-D9D5-4C5A-912F-F2657F2BD985",
    "15356.pdf": "F09C06B2-8930-4AAF-A0B6-B97C10B39C74",
    "15359.pdf": "A1C36129-0422-4A9D-A756-751C02F3BAB4",
    "15360.pdf": "21DCEE3A-DD84-4330-8436-238BFB2471A1",
    "15361.pdf": "6D16AB0E-A2B1-4C00-95EE-05FC38CA09DD",
    "15363.pdf": "A2B34584-CBD6-44D6-9F85-1F40A7800354",
    "15364.pdf": "18061D3B-4D0D-4B54-BD3E-7A39283F7866",
    "15366.pdf": "904A233C-EFA3-4CD6-9140-1C1A825EFD74",
    "15367.pdf": "1E894839-04C4-4028-A4A8-E73D4BC2E1BD",
    "15370.pdf": "17B7DFE0-70AD-49A5-93DC-19D9EA04452C",
    "15372.pdf": "C5989546-917F-49B1-ADB1-2DB65A76192C",
    "15373.pdf": "46CF1E63-1057-4B26-9C71-6BB2A64D3426",
    "15376.pdf": "8C6A3E5B-5006-4634-BE60-D95F76D0D5B9",
    "15378.pdf": "EC8D30A8-0CCE-4241-85DE-B2C92BA12286",
    "15379.pdf": "B67058ED-1FEC-4387-A61E-F0E3127F6C8C",
    "15380.pdf": "D0FDF12B-40BD-4C61-BCC5-593038DEF6E4",
    "15381.pdf": "33128395-E6C7-48C3-9E31-AB48A47AE1FA",
    "15384.pdf": "B58F8E68-1B8A-4AD7-883C-BB78176A6CDA",
    "15385.pdf": "EC6D8E7C-ED63-459D-849B-943827001107",
    "15387.pdf": "3A0A5C56-F1CF-4ACC-9821-1939FB75005B",
    "15389.pdf": "33C9ADAD-F571-4328-8842-83B4311724EC",
    "1539_PROGRAM.PDF": "BF8FDEAA-DB4B-4C3F-A7E6-15F232E30401",
    "15390.pdf": "A7F0B09C-5881-4B6A-AB2B-6BE3CFF94DAB",
    "15393.pdf": "F0959946-6889-4FE9-9CF8-D1F2B2CBF4A4",
    "15394.pdf": "56E6B6B6-42AC-4B57-B777-FE5E698A630B",
    "15396.pdf": "3978D25C-89C7-4EC2-AEFB-592AF945CC2C",
    "15397.pdf": "2C51C07C-2DFF-4AFD-BC9E-27309D8D90C6",
    "1540_PROGRAM.PDF": "B1E27FC8-84A3-4518-874B-16871CDB00D1",
    "15403.pdf": "E14E922A-E016-4F35-80B5-306C655AFFD2",
    "15407.pdf": "C0879C36-3424-44C4-99C3-0DD639A5B73C",
    "15408.pdf": "AB3D214B-8FC9-433D-8878-15720C3C0080",
    "15409.pdf": "E0891388-3408-4D4A-91AD-FA1E7915411D",
    "15410.pdf": "665FA6ED-3578-4B21-BAF0-8224BC0FBA94",
    "15412.pdf": "7C87281C-E946-4F04-92F9-48945ECDF10C",
    "15415.pdf": "41A57C2B-75E1-4C00-973C-0596B41AFA02",
    "15418.pdf": "6A02B56D-85E3-4A92-851E-067FF4DFE4C2",
    "15419.pdf": "C005F087-D62E-4717-9AD9-438C33774EB8",
    "15420.pdf": "6ACCEAFA-5F7D-464B-9EA8-93B6B5EB0550",
    "15421.pdf": "D0F9F4EB-5AB1-49C2-A08A-05B4F2CF17D0",
    "15422.pdf": "32C44298-C561-4154-8658-0D672EA21E32",
    "15425.pdf": "3A70CE9B-127B-46A0-9AA9-D6D8D48B70A5",
    "15426.pdf": "43EC18B8-5430-4DCB-8E2B-E9A58E7C72B1",
    "15427.pdf": "9B8881F8-CA4E-40AC-9B76-43039E7667B5",
    "15428.pdf": "C82686D4-463D-4A8A-8D28-176FFD9801BE",
    "15429.pdf": "BB23A61B-05F8-41ED-883B-991F05230522",
    "15431.pdf": "1C457192-6FE5-4F8E-861C-B2DD687DD26A",
    "15432.pdf": "E6C41A36-DA0D-4597-9AAC-0BDF1B0E4236",
    "15433.pdf": "35AE1063-12B6-467F-91A6-9901705AA070",
    "15435.pdf": "6A2AFF77-41E0-4376-8FBA-2FEED24B88CA",
    "15436.pdf": "A1E1D636-5335-4E09-AAEB-DCAA4EB2B1C3",
    "15438.pdf": "66F6F830-3BF6-484E-A90D-B5DD810F87BD",
    "15442.pdf": "5421FF10-AB42-4BAE-A657-08CF69B8CF71",
    "15443.pdf": "AD7518F4-D167-4DF3-A306-ECC94AF814B0",
    "15444.pdf": "871C2047-89C8-4D7D-84E8-B8382FF2BED2",
    "15445.pdf": "B5E08D76-3D35-4DE2-9F90-D0E0A27CEFC3",
    "15446.pdf": "6B508306-338D-49CC-B704-FF73FDBC47B8",
    "15447.pdf": "484ED1A7-858F-4D77-A664-5265852A3043",
    "15449.pdf": "88880DAB-85F3-472B-BDA9-D8CC7A96092D",
    "15451.pdf": "2E90510B-ECF5-48C3-989C-800C4AEB16DA",
    "15452.pdf": "57C8131C-C299-4A44-9572-EE0CA30EE38F",
    "15453.pdf": "F6D40F97-0D21-4492-A2A7-D32DA3674008",
    "15455.pdf": "8C650822-9A0E-4FB2-A1EB-9FA7945362BE",
    "15456.pdf": "BCA97424-F8F4-4A6A-91F4-FA883F94AB77",
    "15467.pdf": "190CE586-F191-47FB-9BE8-1397A62037D1",
    "15468.pdf": "FAE6D186-43EA-4690-A9CC-9D850D7DEC1B",
    "15470.pdf": "88AC90DB-D35F-49BA-8167-BFF2618FC87A",
    "15471.pdf": "A3471FB5-8CB5-4105-BBEE-9BDB593E8B1A",
    "15475.pdf": "DFC346D1-7E16-418A-A734-A1AD8A23C9F2",
    "15476.pdf": "FF296512-1202-4C54-90CA-5FFC15379A32",
    "15477.pdf": "1B56ABD4-D041-4505-8796-4C151872DA0E",
    "15478.pdf": "73B311A3-F5E1-4A29-8786-FC0D504FA411",
    "15479.pdf": "B946224D-C485-42CD-8538-42A3ED9E7D14",
    "15481.pdf": "16F3429D-0EE9-45C0-B63A-BE83F50288E4",
    "15489.pdf": "D99F563D-9628-453E-8E24-640A86A0D567",
    "15494.pdf": "2B53B4A5-FF87-4372-BB88-3A6FB6F130E2",
    "15495.pdf": "2CC34C01-F754-4275-88AA-BC6DFB0C338D",
    "15496.pdf": "11FDAE0F-4AD8-4482-9335-67871A0C1610",
    "15497.pdf": "1EF94585-78D7-4534-891C-CA1F4833B398",
    "15503.pdf": "88BA36F1-B49E-4F3C-B160-7BCA192FEBDA",
    "15504.pdf": "FEFF7BC6-6ECB-41A8-9AED-F0E3F52D4BEF",
    "15505.pdf": "D0994C6F-51A1-4968-98CB-A6B39B3915C7",
    "15508.pdf": "626DD58D-D152-45DA-96D5-8628A1EFCC97",
    "15509.pdf": "86B9CE1A-0EE5-4C60-8F40-EE5AD7A1C7FA",
    "15514.pdf": "DBCD7DC7-B48E-4A87-A099-CF7F69037687",
    "15515.pdf": "90688A63-0EFD-48E0-B089-245B27D01EE2",
    "15516.pdf": "4D0B2B48-5B5A-49B9-B453-DC0A67A05CC5",
    "15517.pdf": "116A15C6-C81E-45AB-BA80-15BEF3DC79D0",
    "15519.pdf": "88E2163D-1F84-4145-B068-0BD3B71BED9C",
    "15521.pdf": "3D19F2B8-AD06-4615-A2D6-8F1AEF58FED8",
    "15522.pdf": "AF654851-A296-47E9-9DD6-1CC3812B9F39",
    "15524.pdf": "2F262471-948B-4CCE-B683-93480712F7A5",
    "15525.pdf": "96A606AE-2956-47F2-B8F8-1D5A35F5A764",
    "15526.pdf": "CF092C2E-BFA2-4A37-96D0-E3A679BEAD31",
    "15527.pdf": "60FE76F0-087F-44EA-9CBD-201AA30903BB",
    "1553_NEWSLETTER.PDF": "785CC6C8-F0B3-48A7-92EC-3C9FF4583984",
    "15530.pdf": "8C8A947D-B34B-40BE-8A6D-B519BED6AEC4",
    "15531.pdf": "A1D2DD45-D557-41B3-8F86-DFC168A7F5EA",
    "15532.pdf": "F4DA5475-6A4D-4780-9C2A-A18E0896DFD0",
    "15533.pdf": "20921116-E299-4950-8598-F3E776EBD145",
    "15534.pdf": "D0CBF957-BA6B-4715-9A92-04F21EAE3D78",
    "15535.pdf": "DD93C0CC-CB1C-43B8-B087-2132B20077CA",
    "15536.pdf": "C27C3A0E-7179-487E-A696-FEA4E2EC29E1",
    "15537.pdf": "2FC69FAA-35E5-4C05-A51F-98975F0B3FD7",
    "15539.pdf": "AF7D74A6-BB9E-49AA-BE73-B51FD219186F",
    "15544.pdf": "F28B5E16-BEDC-4D55-9F7C-1F91783E124D",
    "15545.pdf": "30B98E2F-32E5-42B0-86CA-517CB3B8DED5",
    "15546.pdf": "DE34D97A-5C42-4157-B2FE-DF3754704B62",
    "15547.pdf": "1B31725A-EC57-421E-862B-4FA509583CE6",
    "15552.pdf": "2AA7D0DB-FC38-4082-8F5E-C21C152A6897",
    "15553.pdf": "F7362339-BD81-409D-AAF2-2E58B18C3033",
    "15554.pdf": "5D1EC015-8A6E-49AA-9047-655FCCD61D20",
    "15555.pdf": "11CFABD8-701A-46C2-BECB-48CE0D9D283B",
    "15556.pdf": "FD148858-D8D9-4FB6-B99A-4D3BE3CC5273",
    "15560.pdf": "7B399470-B640-4F29-867A-60D212DA52D6",
    "15561.pdf": "0C740203-8A1A-4A21-9E90-24DE19BED25B",
    "15563.pdf": "CC4B3DF5-B48A-47F0-AAF2-36C2F35F2712",
    "15564.pdf": "722B67EE-D7FE-4F3C-A9B8-B2B173739969",
    "15565.pdf": "FD343D07-89CE-4091-B66A-8A9DF00E4D2F",
    "15568.pdf": "F9E7BF46-599A-4BC2-9CEE-BEB27D832F93",
    "15572.pdf": "8F95751B-0414-4CA8-9C8B-CBB7DEB35B23",
    "15573.pdf": "23F4910A-B801-468F-BE49-2D48190C164F",
    "15574.pdf": "66F65AD8-39FE-4B91-A3DF-90A2A1ED9C2D",
    "15576.pdf": "32DCDD79-6F3F-45B9-B06A-1EB5BD1BD0BC",
    "15577.pdf": "B189E7E5-D3A8-40F2-9EE6-CC6CC412D245",
    "15578.pdf": "DF2D8E1B-6EAB-4767-A083-8138E242D06B",
    "15579.pdf": "F39910F0-EFF0-48EF-AE9B-62D04E447705",
    "1558_MEMO.PDF": "A7EE1C5F-33D6-4FC2-8EA6-279F2ADAA6ED",
    "15582.pdf": "51F26A15-6299-463A-BA9F-8E35772363A8",
    "15585.pdf": "5BFDD7BF-61D6-4B49-B783-221A913324C4",
    "15586.pdf": "18D423C1-32A0-4030-B09B-F634D6F5091D",
    "15587.pdf": "01F7E529-B14A-4CDF-85F1-02FAFB8084E5",
    "15588.pdf": "35D0C3A0-23A9-4B1F-ACBC-1A46501372B1",
    "1559_NEWSLETTER.PDF": "DB5832C9-3735-4B79-BCE8-5C85BAD9F3AE",
    "15590.pdf": "E6359428-7191-4A60-ABF5-31DE12975491",
    "15591.pdf": "E91BE5C4-4E80-4E8A-9D74-D6FC0674991D",
    "15593.pdf": "86698F8C-5316-44EB-B823-F7422FF8E8DE",
    "15594.pdf": "A25A4FD6-6A34-4550-A579-A4675F188EB2",
    "15595.pdf": "31A7FC7D-1626-4284-8A82-D4C338B9664E",
    "15597.pdf": "84E53F4E-AD93-488F-AEFA-8443A3D433A7",
    "15600.pdf": "2F4D31A4-6525-448B-BEC1-FA3242A10958",
    "15601.pdf": "60706085-EA9C-4569-9533-A19CBF3B0C62",
    "15605.pdf": "3CE54AD8-86DC-4ABD-A80F-4AD1FCCF54A3",
    "15608.pdf": "AFBAD592-96A4-49DB-AEC9-97B7968795A9",
    "15609.pdf": "D0DC3582-5323-4052-8FA2-C8DE51A19059",
    "15610.pdf": "A0DCDC95-4F85-42FE-88A2-A8BDAE57C805",
    "15611.pdf": "59786916-8003-4AF8-AA7B-94D489222D0A",
    "15612.pdf": "AA6C87A3-611F-449F-9AB1-25D5E4BB7656",
    "15613.pdf": "3EA4AB29-DAD4-41A5-BB44-E6916BBC3CDD",
    "15614.pdf": "1A8BCBD5-57FF-4750-8B3A-833615E081FA",
    "15617.pdf": "B94C7DE5-8CB0-470A-9202-DFF85BA795B2",
    "15620.pdf": "2D60D837-6EBD-4141-B796-E1A859C12647",
    "15622.pdf": "94FC977F-F039-47D2-8A8E-6F6145F45848",
    "15631.pdf": "DB63D327-5AF2-487E-A5BF-49A176DE73E2",
    "15633.pdf": "7471CAA4-C26E-438D-890A-BBE43D5CB3B7",
    "15634.pdf": "4BE49DF6-5981-4492-9FFB-4152C2A1C551",
    "15635.pdf": "B7A28A64-8747-46C3-B899-94250267B2F5",
    "15637.pdf": "0AAA24D9-600B-4F99-898C-4735A41F5AD8",
    "15639.pdf": "69042E3E-0604-462A-8C6C-BFA0BE825137",
    "15641.pdf": "CAD079F2-7D29-4BB7-84C6-904A2E34FB26",
    "15642.pdf": "3E2F2AD2-A1BC-4747-8BD3-B15003A8BEAD",
    "15643.pdf": "1804DEF0-A75B-4997-A9D7-45075FB687E6",
    "15644.pdf": "D836DC47-289F-4686-8008-C55055288135",
    "15648.pdf": "3BD7314B-E913-44CA-AE63-21D2A9B868C0",
    "15660.pdf": "388C6A03-9DD4-47A6-9438-C7690D8171EC",
    "15661.pdf": "CF36CB58-5DA7-4222-B43F-D61CC66EA592",
    "15664.pdf": "1072C7F8-D686-4921-B1AD-9DD9F690F49B",
    "15665.pdf": "18755361-CE2D-46E7-979B-54BCE8FAACE7",
    "15666.pdf": "A3B15B0C-FF26-4F24-BB95-7E93A116646B",
    "15668.pdf": "C456FE5E-7D53-41E3-961F-326134556057",
    "15669.pdf": "C8F80CEE-E7F8-4AFD-A51B-BAB7FC9E6B5A",
    "15670.pdf": "54D9DD7B-33AB-4CDD-877D-7C572602127B",
    "15673.pdf": "A543C1C1-2115-46DF-8B16-19859AF77FB6",
    "15675.pdf": "B14307F3-5F00-4A48-9F25-547AA4FF7708",
    "15678.pdf": "381E823F-D44E-440C-97D1-95B45974A9F7",
    "15682.pdf": "BD3B9163-58B3-4042-96D6-FFCD0F3FD070",
    "15684.pdf": "E5722BB9-9020-4DE4-AF12-E0683DC5F977",
    "15685.pdf": "C5520CE3-E0F3-409A-BEBA-F2BFEC9D13CD",
    "15687.pdf": "9E06129D-C290-422C-9966-245A6AF34EA2",
    "15690.pdf": "0D8367AB-1C15-4AC3-9132-3CE556FE1E12",
    "15691.pdf": "1805E550-E7C1-401E-87ED-BCAF0E6E8000",
    "15694.pdf": "A6AEB4C6-4AFE-46CE-9500-9148E7921233",
    "15699.pdf": "BD2F6B48-C41B-4CB6-BF8E-AE7EC0381036",
    "15700.pdf": "A31F644F-EE27-4767-A601-69D70879B0BF",
    "15701.pdf": "75161A70-1F7A-4EF0-BC67-AF974072FD92",
    "15705.pdf": "23D0BB35-5B5F-4AD8-BAD3-84D96CDFA3FC",
    "15709.pdf": "345EC0E9-9E78-4B88-976A-FB62F38EE39E",
    "15713.pdf": "B00F4ABC-D73A-4509-A186-37F8C9AB1605",
    "15719.pdf": "63689F6B-0E53-4C5F-90C5-224338E9CCB1",
    "15720.pdf": "22BC72CA-9A6B-4AA6-A477-D2877BB23DF5",
    "15725.pdf": "CB8AE1A9-E349-454C-93DE-823FC633612C",
    "15727.pdf": "AB7C9208-EFED-4364-8679-250F2537553B",
    "15729.pdf": "12DC86FB-3281-421E-9ED5-C02938054297",
    "15732.pdf": "30FA796A-B57B-4E3F-8B74-9709B4EBB1F4",
    "15733.pdf": "5F039262-BF06-4355-A470-FCBA689AFA75",
    "15734.pdf": "5BA3F981-30F4-4D55-83B7-755C052920E1",
    "15735.pdf": "19BF60DF-F17F-48DE-8D7C-745FEA19050D",
    "15736.pdf": "3C4BB282-799A-440D-8BB3-CE89C8DFD88C",
    "15737.pdf": "4FA453A5-E0AB-4519-B862-B3951F33A38B",
    "15738.pdf": "E348B92A-0BCF-43A5-9566-738A09445C96",
    "15739.pdf": "AA95CC51-069B-4E54-A135-4F85794F5F5E",
    "15740.pdf": "0D115500-5BF4-40F4-8DEA-5FB69843F477",
    "15741.pdf": "BF631D39-A6CA-4F12-81BB-FF4DA23CE65D",
    "15743.pdf": "FF7A033E-7047-4525-82A5-30BA292EA6EF",
    "15744.pdf": "D7CC1A4B-151E-45FA-A352-B12247664E10",
    "15745.pdf": "418D94CA-9175-4519-B3CB-2B8262852080",
    "15747.pdf": "55554B04-7538-4732-AB2B-B170888B2EF4",
    "15748.pdf": "D789F68A-1E4B-453C-8E6C-EC09B82A7220",
    "15752.pdf": "BE52D774-215F-47E1-839D-55DA69A9EC07",
    "15754.pdf": "5871A41A-9BFE-4D43-879A-B3147BC79D89",
    "15757.pdf": "A3CF488E-8C6E-4BFF-9142-E57428014B01",
    "15759.pdf": "C53B96E3-65D4-4318-978E-A6E9866B7DA5",
    "15760.pdf": "D599C67D-26E6-4FF3-BBB4-BD3D48265037",
    "15762.pdf": "E7046E40-93D1-4D7E-A44D-344115DE610B",
    "15764.pdf": "1D5FE52C-8EFC-4F85-BD2D-04FE3541D3F7",
    "15765.pdf": "632CC84E-CAB4-4DD0-9CE5-9ED678F088DB",
    "15766.pdf": "CCFC0775-8489-417C-8041-55DC437F5C2B",
    "15767.pdf": "3F915781-BF1F-41D0-84C8-507F32B0D528",
    "15769.pdf": "C29327FF-DC3A-47E6-9C79-1162C59AFDB4",
    "15773.pdf": "F7E9AAA4-14B1-4D02-8EA8-F7F783A60756",
    "15775.pdf": "D873A714-9E28-4F55-BB9A-AA0830B51B10",
    "15776.pdf": "F5BED806-3E4D-4275-885F-8DDF8AB3BEFC",
    "15777.pdf": "3BB8DE8B-4CBD-4044-88FD-47FCE09059D0",
    "15779.pdf": "67E27B48-AB54-482B-9F48-055C2508E151",
    "15781.pdf": "741202AF-72E2-49C0-9872-9885BF285FEE",
    "15785.pdf": "EDD3A4FE-EC7C-4163-B024-0E14D796DA3F",
    "15786.pdf": "25D4CCBD-29A1-41DC-8E43-303F01071C0B",
    "15787.pdf": "4E0C089A-6F48-446A-837C-E1A2B40EC8B5",
    "15788.pdf": "A493DE05-EA52-474C-B9D5-950AF3EE3145",
    "15790.pdf": "1A0C17CF-4A52-432E-A3AE-D4AC12FDD897",
    "15794.pdf": "A49ED512-890F-4072-8250-61D66F7A8DF9",
    "15795.pdf": "C3AE7F68-E53F-4213-98F8-1BF73BEB255C",
    "15796.pdf": "376697B8-BC03-4B38-A1A2-9F9438BAEC3E",
    "15797.pdf": "DB4E0310-3368-4714-9D7B-7F40B98EF9AA",
    "15798.pdf": "59C037D9-AA82-40EC-BF0A-7F432F2C6BFF",
    "15799.pdf": "44C5E915-8E1E-47ED-97B7-C34FADD252BC",
    "15802.pdf": "4BCF7A78-FA45-48BE-B00B-E58C8B11B0AD",
    "15803.pdf": "DC347A6F-E815-46D8-97FD-912BE2CBB655",
    "15808.pdf": "A9E5C5E3-8B73-4FFC-8ED4-BCB411C82695",
    "1581_NEWSLETTER.PDF": "EDBEB8F1-5D4F-43F3-8BDC-12F25D300E9E",
    "15810.pdf": "00076850-2425-4E39-8325-287D3B8282B0",
    "15811.pdf": "5BB746D6-F7E0-4899-87AE-D990C2AA6C4E",
    "15813.pdf": "0979487C-F12C-443D-A7D4-6689B0A8077E",
    "15814.pdf": "6E8F3900-D929-45AB-B5C0-602E0709397E",
    "15815.pdf": "7B7F6A80-3D69-4E15-801B-D73A4C5207BA",
    "15816.pdf": "71EFE339-2332-41C8-818B-801404B6BCE5",
    "15817.pdf": "06F883D4-BC56-423C-AA72-D6B50D0DC129",
    "15819.pdf": "6D5A70F2-F72B-4511-B546-1D4EBC8A9505",
    "15820.pdf": "22664AF7-4380-4C47-B453-2049F55FD813",
    "15821.pdf": "294000BE-3AAC-44CD-8AA5-3D1A1ED05313",
    "15823.pdf": "FDC64781-D76E-4C0D-94C3-97D8E92D9550",
    "15824.pdf": "0C11E45D-D2DC-49AA-B9B7-72E0CF7907D0",
    "15829.pdf": "1B1FD099-0691-4535-96F9-52DBA4D021B0",
    "15831.pdf": "A85C50E3-8EF3-4DCB-A499-640AFA6AAC90",
    "15833.pdf": "9A77E6E9-FD67-45A5-98E5-5694CF2E3141",
    "15834.pdf": "808E67ED-6A59-4771-92C2-3DD134B37164",
    "15836.pdf": "6A59F9F2-5D33-4D3E-BA2D-496A545B51D1",
    "15837.pdf": "AE4DE90F-D299-4196-A987-89C06E58CD7E",
    "15838.pdf": "76C14684-802E-4D2E-82EC-448549DC5760",
    "15842.pdf": "369B6964-159A-4A44-85F5-84C7A79F236A",
    "15843.pdf": "0C8BE8EB-3E84-457B-8B02-E3889D55980B",
    "15844.pdf": "0366D0FE-0027-406F-AD5D-E575C7B65E24",
    "15845.pdf": "E902AD1E-CE14-4E89-8C42-683011FC2E8D",
    "15847.pdf": "1DF75E6A-3FD2-450D-9095-2AA135D13E09",
    "15848.pdf": "3C19FB2F-11F1-4E7A-B750-8700EEFAD17F",
    "15851.pdf": "22D9F713-8AC8-476C-94CA-AC1EEE84FCB5",
    "15852.pdf": "9602D61F-8E2F-48B3-9D06-06A42FE8B00D",
    "15853.pdf": "3215A0F8-01A6-40E5-AACF-6F89FB9626BC",
    "15854.pdf": "9B4BB918-09DE-4531-8F7C-B6E8A4851D7F",
    "15855.pdf": "9999004E-9A31-4CDE-93B5-2C7B2D451D38",
    "15859.pdf": "E5AB2474-D2F3-49FF-B83A-9CE984153B51",
    "15863.pdf": "BB48B943-D5D1-4A07-B204-47849464C5CD",
    "15864.pdf": "3BC6F5EA-71D9-488E-BBA3-34FC2F0A4218",
    "1587_PROGRAM.PDF": "F816CE6A-28C1-46B4-9CF4-44ED752E8295",
    "15917.pdf": "9F8F245A-BA30-4C18-93E8-638DBA11AFE2",
    "15918.pdf": "B52C1069-D49C-4038-84EF-88926E182AD1",
    "15933.pdf": "B0DE824B-FA0B-4DA4-AA2F-8BEF675015C5",
    "15939.pdf": "BAC8A0BD-C146-43A2-B292-0DE8B47AF2FA",
    "15949.pdf": "561D605E-24B9-4F96-A0B0-4B0CD80AD159",
    "15961.pdf": "11E52DD8-AEC0-4681-9CE1-2AD18188B374",
    "15976.pdf": "F9ED8DD2-CAB1-4D15-AC98-01D0F3519818",
    "15985.pdf": "B7AAC301-C9B3-4158-A1CD-A1ECD282F207",
    "15989.pdf": "236699FF-88C2-4F3E-ADC3-8A631145AAF5",
    "15990.pdf": "1E5C69A8-EC01-4D71-BB28-8A20D7FB152D",
    "15991.pdf": "9ED95078-D414-48E5-93A9-5C7FD5B866F0",
    "15992.pdf": "69195F12-388B-4F4B-AC49-7EA04975352C",
    "15993.pdf": "D84E9D0C-0C64-4588-9FD9-90D1D44BD8D6",
    "15994.pdf": "8D6E4285-B7D0-4F11-8E2D-3F564888963C",
    "15995.pdf": "6B8CC3D0-7A9C-4E69-B281-8CF69500C05D",
    "15996.pdf": "02DB38D7-80EB-4C2A-9A36-F87F3CC36BEB",
    "15998.pdf": "23307038-DAC4-4854-9B6C-0984120B7960",
    "15Academy Annual Meeting Synopsis.pdf": "52749938-D375-4912-AB6B-703585A79420",
    "16 - Bone Graft Extenders and Substitutes.pdf": "73702A6F-8AD2-445D-9DD6-3271B4147154",
    "16.Batjer.pdf": "E4746530-6438-4DD9-BAF6-BD4E4E2D7ACA",
    "16.pdf": "F557666D-FD21-4AF8-B6D2-6B64FDC27EF4",
    "160_Neurological_SurCase Minimums.pdf": "56CB0E67-5650-4448-AB79-5EFE3D54E428",
    "160_Neurosurgery_Case_Minimum_7_1_2019.pdf": "A402733C-926C-4AE8-A655-D06586FF86E8",
    "16000.pdf": "529E4BC4-A5C3-430E-9B82-5690C9C621F8",
    "16001.pdf": "7E1FD8EF-0F6C-44C9-A41F-3BFD3CB7D591",
    "16002.pdf": "3C8C81EF-3567-46F0-9C6B-FE4A7B215E71",
    "16003.pdf": "2082A0C1-1EB5-49DE-9904-F935213D6B92",
    "16006.pdf": "3F2B6645-9505-4EB8-9FD4-25AB007FA3CA",
    "16007.pdf": "A08ACBE8-5897-4073-9EBE-F557DC05F627",
    "16008.pdf": "F86C973F-E7EE-468C-9A59-2296D7A2FE37",
    "16010.pdf": "1AD8EB60-0CFB-472E-AC76-66D1433795FA",
    "16015.pdf": "AF9502A1-73C7-4C73-BB9D-D5490C46DD5B",
    "16017.pdf": "2EE15A60-F0D3-4308-BA46-EDB7553459A8",
    "16022.pdf": "7B308E8B-0960-4E32-84EE-D7F031093F6F",
    "16024.pdf": "2378ACB7-1913-4EAB-A170-52603F394F8D",
    "16025.pdf": "8201B90C-4C10-47E6-94B1-7E76FF76CF70",
    "16027.pdf": "030471C4-6F6E-4086-83B0-A030265FAC52",
    "16028.pdf": "FD4C8A03-C86D-4ED3-A75F-28FCB9BA7F78",
    "16029.pdf": "9E8EC129-A4B1-4295-8C63-D1516BBF2A9A",
    "16032.pdf": "C2E07CB6-C98A-4241-B804-4B2CB7E4BE43",
    "16034.pdf": "E07E655B-E9DA-4DC9-8916-3464AED78991",
    "16037.pdf": "E4385A00-4927-4143-9156-E5CB7AB9EB0B",
    "16038.pdf": "F1DE9D29-C116-4DF4-86A7-4F8F868313B9",
    "16042.pdf": "906DA77A-88C0-442D-BED1-04B39C299048",
    "16043.pdf": "80077EA5-05C5-4606-BBC9-BD095EF1910B",
    "16044.pdf": "C773C983-E570-46D3-8BBE-AD3AE4126A8F",
    "16045.pdf": "3998948D-4E58-4FB6-8DD9-FDEB2C8568E0",
    "16046.pdf": "72EAEC6D-910A-4EF8-9676-0A510AE18E68",
    "16047.pdf": "92EE3832-8DBA-44BE-A8E6-C8DEFBE1795F",
    "16048.pdf": "1A1138EA-532C-4A8E-95A9-48E0100ABD9E",
    "16049.pdf": "701C02DF-B7D0-483D-9795-B1B99990051D",
    "16051.pdf": "B728AE5D-833B-4B87-8302-19204CA37882",
    "16052.pdf": "CBEBE0CD-C30D-4DE7-9638-2DBC54247295",
    "16053.pdf": "71B70D4A-4B94-490B-9AB2-78666C9A9CD5",
    "16054.pdf": "C1459E72-BD42-4734-AD07-17538655A022",
    "16059.pdf": "9D7B1695-5856-4695-8E8E-FB0773DF6B65",
    "16061.pdf": "104CDC19-E114-46C0-846C-20F457A05484",
    "16062.pdf": "B85B86DF-9E55-481A-8C0E-4E4F1362527B",
    "16063.pdf": "3DA7462E-FF0F-4702-B38C-9FF5CC348F8F",
    "16064.pdf": "214EF7AA-4DBE-49E1-B326-E53506673946",
    "16065.pdf": "770E36E9-F7B1-44BF-9FE9-466E8AF13954",
    "16068.pdf": "84DC41CC-B71C-44CC-856A-568CD559D49C",
    "16069.pdf": "73FDDC3E-C867-46DB-9083-3DE95E695CC2",
    "16070.pdf": "29337AA0-3B15-493A-8E31-2D9854D24B67",
    "16071.pdf": "E9BB56FB-10F1-462A-859C-787648623A04",
    "16072.pdf": "D2C9E22E-D3AE-40E7-9CBE-5CE25E60E3E7",
    "16073.pdf": "C7A8FCD9-8B05-4434-8E06-0BC2E10126FC",
    "16074.pdf": "0C39ED98-40DF-43E5-AE92-A65D57308F7A",
    "16076.pdf": "EF264E75-8730-4C36-B8BF-2B04D88E630D",
    "16077.pdf": "9822308F-1B84-4B94-8A3E-764BDEF731ED",
    "16078.pdf": "32468C65-78DC-49CF-921A-9AF8751C62C3",
    "16079.pdf": "F6D06BA4-6B96-4CAE-BCAA-13185A18085D",
    "16081.pdf": "35B7431B-A647-498F-B520-281E97EB5519",
    "16084.pdf": "D17AB330-52AD-4AE1-A426-0CB730A6EB66",
    "16085.pdf": "55357A29-3154-4703-BBB2-1A69EBEB83C1",
    "16087.pdf": "6836FBB7-68AD-4A03-9D69-2260E64ECCCE",
    "16089.pdf": "A4DE4AFD-0F87-4603-9E29-D9D54AC6ACB2",
    "16090.pdf": "A38F1F80-BFF0-4850-83CD-3418C458CEFA",
    "16091.pdf": "0BA87371-A954-485A-9B2D-251E41805354",
    "16093.pdf": "A0CC64EE-7710-45F8-9FC9-52FDDB77C229",
    "16095.pdf": "E5ED39C3-63BD-4278-9E44-7984854FED2E",
    "16098.pdf": "F6656BEC-AEC1-4B0D-AF90-625BF5D037E0",
    "16100.pdf": "7D82F6CD-637B-4BB3-BEE9-A1DAA43CF540",
    "16101.pdf": "83227366-174A-4E3F-ACFA-1632188A7BB3",
    "16102.pdf": "F2ECB690-650D-4910-B503-1E9B77BDF532",
    "16103.pdf": "0B607193-10EB-4EDD-81DE-961DBD8BB054",
    "16104.pdf": "4DB23B55-159E-4616-8E1F-4BED1CA130B6",
    "16107.pdf": "0F54B341-8757-4903-81C0-69292F7006A3",
    "16108.pdf": "E7B70833-5CC6-403C-9C2C-5C3224BED6F2",
    "16109.pdf": "E6196582-29D0-4FC3-9439-D9D345D7D5A9",
    "16111.pdf": "52A0415E-AB04-4369-B85E-CD371722FCB1",
    "16112.pdf": "CABEF7D5-784F-412A-BE96-E2D84568145E",
    "16113.pdf": "3CB340F0-F294-4EB7-9DAE-D5A68D9FA6EC",
    "16114.pdf": "993F5D78-2E9E-466B-ABE3-249554328E2C",
    "16115.pdf": "B8ADEDD7-FC62-422A-B1FE-A0AE4ACB96BF",
    "16117.pdf": "50EAFBA4-D4BE-4950-9494-EB76B09F54A7",
    "16118.pdf": "28AA49F5-438B-4417-BDE3-93E43991DEBA",
    "16120.pdf": "9C3CA523-463F-486F-B599-87DE8FB08F0D",
    "16123.pdf": "38C4F882-9210-4183-9DA6-57E5F2631C87",
    "16124.pdf": "FAA50081-5D19-46B8-96E0-A569486103D1",
    "16126.pdf": "4BC38A3E-2FA0-4618-9CD0-C91F3B1155DA",
    "16127.pdf": "C17BE3D5-E73E-4171-B731-318FBFD043EB",
    "16128.pdf": "12EA7771-733F-4FB4-B8BC-34FFE1705DD4",
    "16130.pdf": "D3D422D7-0051-41CA-B674-D87032B31446",
    "16132.pdf": "4D823BF8-8095-4727-AA5A-7BFBA4938BCE",
    "16134.pdf": "C0BDACA4-ABF0-4913-8644-7D8FFD458828",
    "16138.pdf": "B07BB54E-102D-423E-8A13-228CDAFE5C3D",
    "16139.pdf": "45CAEBA1-7892-4CDC-85C1-98B6171E2FEC",
    "16140.pdf": "FD690C69-56B6-4569-813D-490497CCE622",
    "16141.pdf": "AF094908-EC05-43E7-B4F8-24E1F844FB76",
    "16145.pdf": "9A692F33-CF8A-4DDA-BB82-55388E10DD3F",
    "16146.pdf": "8BCA6FE2-0218-442C-8975-2740DCB62397",
    "16148.pdf": "9F41965C-F1A5-45B5-809E-D608FC7C2A52",
    "16149.pdf": "66FC2D94-77B1-484E-9A98-76EB812E90BB",
    "16150.pdf": "43ECB2C0-F5B9-484C-AE20-22571AF57182",
    "16154.pdf": "9EAFAD04-C171-48E3-9272-9C8A98A2E179",
    "16156.pdf": "EE717CE7-D091-4729-B58F-8B090B62D09F",
    "16157.pdf": "53F5E52E-6993-4767-961C-904532535CE5",
    "16159.pdf": "069F0C08-11CB-4B00-8D23-74932363FEF8",
    "16161.pdf": "B97471A6-EC09-468C-BC49-520C971BF032",
    "16162.pdf": "06A41492-EA8D-4C03-B9C1-1DC620463323",
    "16164.pdf": "823881C8-F59E-4569-B2B3-870DA82B5CB0",
    "16166.pdf": "036615FD-A118-43F1-BEDE-B9E17266EF0C",
    "16167.pdf": "0FBA3938-AC8C-4C51-A320-6EA23429BE72",
    "16169.pdf": "CA33940E-0014-4079-B974-FFDFE49BD980",
    "16172.pdf": "99D37D49-5201-4C42-A3FC-E876F7333083",
    "16173.pdf": "2B745957-653D-49AA-B673-9E345C836D34",
    "16175.pdf": "5706EF26-F1F1-4A69-9AFB-AD26A47FA381",
    "16176.pdf": "A45D1D0D-5930-4585-B7E6-20A6A0CE1D1C",
    "16177.pdf": "23050B18-866A-4123-9758-4A1B8D615963",
    "16179.pdf": "5F2A67BA-B364-4CE9-8412-3B6833D43E2E",
    "16180.pdf": "D065E982-5B87-4AA5-A465-9A60E591EF2A",
    "16181.pdf": "DA524977-1789-494B-B0E9-1522B72B2AC1",
    "16182.pdf": "E6905079-EF90-4E03-98C6-9BC440C4FF55",
    "16184.pdf": "92D7F111-A505-4771-A8A0-79F64168B5E9",
    "16185.pdf": "21EC2938-3055-4FAD-AFE4-13AE3B5396F8",
    "16187.pdf": "361296EE-0F38-40B3-B77F-3A0C26184A16",
    "16188.pdf": "350489B3-F92E-4327-A4AD-4ADDBA2082DF",
    "16189.pdf": "BAB85E39-0F15-4D32-8E41-7CA72C77FD38",
    "16190.pdf": "85DB90C0-E3C5-4187-9467-DF322FEB6527",
    "16191.pdf": "2A96886D-AC00-43B3-B24A-F6910E2E4FC7",
    "16192.pdf": "957977E9-4D0B-423C-89F5-B16082C793C6",
    "16195.pdf": "100D8B1C-3835-4F25-9FEC-E991C7340228",
    "16196.pdf": "BE663F6C-0420-4279-B603-D41C38D4392B",
    "16198.pdf": "B286C0C6-975D-4359-B179-2BEE57B08087",
    "16199.pdf": "FE1D284D-314C-4120-B1B0-FBFF53686D0E",
    "16201.pdf": "E5036404-1101-4DD6-A93C-0293CCE0A419",
    "16202.pdf": "52B22D63-88E8-4DDC-A279-884CAC9818CD",
    "16203.pdf": "5F442855-AAF3-4CEC-A79E-AB28A25B0B77",
    "16204.pdf": "E8D3EBC0-327B-47FD-8491-7A59260F75B1",
    "16206.pdf": "5DAED0D3-BAC1-4262-9D95-4FA3F6803366",
    "16207.pdf": "60E1A966-F4A2-4DF0-AB2A-1FF71A278D1B",
    "16208.pdf": "E897D2CC-D15D-42B7-901B-E6830371699D",
    "16209.pdf": "112CAE12-F075-48AC-88AB-BCD72A58539A",
    "16210.pdf": "62CF27E3-6F1D-461A-A2D3-23AC0910B78F",
    "16212.pdf": "24BAA5F8-C43F-4F89-AFC2-045063B534AD",
    "16214.pdf": "B4CCD5FF-466E-4BBE-B9D0-82CCECB5653B",
    "16215.pdf": "89FA1559-D5DB-47DC-8462-472285509D5C",
    "16217.pdf": "D1F743ED-DCEC-4BF5-814F-932BAB25EAFC",
    "16218.pdf": "DF8927C0-1BFE-4775-A76F-C1D6B0719C62",
    "16219.pdf": "D9426AEE-4BEE-4C65-9BFC-BF81039A3F56",
    "16221.pdf": "20D05B78-714B-4E88-B9A7-4B99196FE2BA",
    "16222.pdf": "CC63FA35-57BA-49DD-9C2C-F63E647BE06F",
    "16224.pdf": "9DC62783-2E3A-4E57-B23D-3CF239E39BF7",
    "16225.pdf": "BDC2CAF1-332B-4CA4-8FB4-6E64174E3EDA",
    "16229.pdf": "18B1E974-C0C2-4B9B-A400-B4DFB7520CBB",
    "16230.pdf": "A7C88FFA-72F9-4851-8F5D-19C0F6B0295A",
    "16231.pdf": "B20DD2A4-0913-4558-B47B-1201F7333087",
    "16232.pdf": "527D55A8-410B-4927-9B69-B92EAA2C63D8",
    "16233.pdf": "A3B839C7-A4AF-4C73-B3B5-EDF4F76F6F4B",
    "16234.pdf": "4D59C003-BC32-49EE-A3BA-ECD41922ABC5",
    "16235.pdf": "81CFC75F-1860-46DE-BF9C-4332953C3F0C",
    "16236.pdf": "B1047AE9-0154-44A3-8F9D-E4042236F9CA",
    "16237.pdf": "8EA0E10B-5A22-4AEC-A218-ABEC017FFB59",
    "16238.pdf": "CF106D04-9DA8-40F1-A9F5-91AD6E324484",
    "1623816563.pdf": "96841D85-893B-4337-84F5-67929A837A8E",
    "1623825639.pdf": "A02E4DB3-336C-4137-A101-24AF976A587E",
    "16239.pdf": "A8284EBB-96BA-433B-8746-2D6DE8110556",
    "1624_PROGRAM.PDF": "6FDAA144-F3FA-46D6-8E44-51197E2DFD84",
    "16240.pdf": "9A8DC4EE-0300-4AE2-BC2B-709858E69C5E",
    "16244.pdf": "FC9B5C84-7294-4E0D-8D8C-F100ABED0A04",
    "16245.pdf": "B7FB2796-7D35-4310-BE03-9A10469B819A",
    "16246.pdf": "7DF4A90F-70BA-4EBC-83BC-9396EA43F9E0",
    "16247.pdf": "9A57D939-1024-40B4-8A46-ED8F32F3B4FB",
    "16249.pdf": "D11070C5-EFB6-4125-9035-E34908AE8411",
    "16251.pdf": "4D5990C4-6EB2-486D-BB11-E1C7F370909C",
    "16252.pdf": "8753F602-15CE-4B95-A0BC-B1F13B8972F5",
    "16254.pdf": "95B53485-EB4D-40EF-8564-FC74AA34007B",
    "16255.pdf": "9EA527DA-ECF7-41F6-96CF-8F1D32F32405",
    "16256.pdf": "F8F45040-4BDD-4247-A0A6-0336F44F577A",
    "16257.pdf": "D495C326-45CF-44D1-9339-EA7C431855E0",
    "16258.pdf": "8EA1F6DA-7859-47A6-BA62-B3EB7ECBAB80",
    "16260.pdf": "9DC8566B-211C-41E3-9197-1F9A89073015",
    "16261.pdf": "84F38D85-FDF9-4F72-AFF7-A8800E045DB9",
    "16263.pdf": "3CFB71C0-BBA4-40C6-B13F-478E27132EF1",
    "16265.pdf": "E69788DE-B459-4DAC-B55E-800E07D7F000",
    "16266.pdf": "5FD323F7-D2D6-49B9-8583-735C7FAADEEF",
    "16267.pdf": "33AEB192-C463-49CF-AD2A-50F319209810",
    "16268.pdf": "88FD68F8-9452-4E00-99BF-447C0D4B5504",
    "16269.pdf": "62757869-A8D5-4A91-88AD-3F44B06F4FA0",
    "16270.pdf": "03733C5A-9430-4214-81B0-6877458325E6",
    "16271.pdf": "8601E2D5-FB91-4732-8B6B-2E0068CE16EF",
    "16272.pdf": "EF38F0BD-FBFA-407F-860A-512A23A9DC50",
    "16279.pdf": "FAE581E5-78A8-4A7B-AB49-929459748D72",
    "16280.pdf": "B61D8C11-AF4F-461E-ACDA-D7AFE80D7FBD",
    "16285.pdf": "51CD6E9E-151D-45DC-86EB-03F8A5A0DBD8",
    "16287.pdf": "F4ECBBAC-921E-4667-B54A-32F79C2CA713",
    "16288.pdf": "1A022E9C-4CB5-415C-AE64-6BC2DF3BB029",
    "16289.pdf": "B982CEFB-28E7-4B8A-839A-ED8CCFD66818",
    "16291.pdf": "39CA0111-BCDB-43B9-9E80-8C11E0886D8C",
    "16292.pdf": "515B1607-501F-4EA5-833B-73C81DFD8EAB",
    "16293.pdf": "3A7E9112-BABE-44BE-824A-85D1AB96B2C6",
    "16294.pdf": "D926E15B-DDE5-465D-97D3-11F94ED4951E",
    "16297.pdf": "413BF59E-E823-44A5-9336-4528BBF4BEFA",
    "16298.pdf": "7118FF38-96F8-4D2C-BD28-473AF2A3B84F",
    "16299.pdf": "2C68B6C4-F81F-45CA-B59D-5E2416221FB3",
    "16302.pdf": "30255E16-8A48-4C1A-9F96-F9584C266791",
    "16303.pdf": "770081F7-5D7D-4042-8A04-447F999BD29E",
    "16305.pdf": "D4993D82-CC9B-4B42-BB15-C4165BB7786C",
    "16307.pdf": "F736E47F-4E74-47D7-BDBC-5D9053A33D6F",
    "16308.pdf": "B4FAD0EA-705F-4487-885E-52DB444BAAE1",
    "16309.pdf": "7484BF75-5B17-4333-B699-2BB738EB2E4A",
    "16311.pdf": "0DCD965E-33A7-4A2F-A5DA-1DF4E005057C",
    "16312.pdf": "054DC425-7737-4989-83CC-24E6E0312B9B",
    "16313.pdf": "846BF37E-E63B-47AF-AE56-6E319614D32A",
    "16314.pdf": "656267C7-8641-462A-8575-8394F65DFDE3",
    "16315.pdf": "ADEA1A8E-774E-4824-A394-1DAC42B8D546",
    "16317.pdf": "93E12C95-3A2A-4616-B62C-E01B1A8A9C75",
    "16323.pdf": "6668997A-09ED-4BEC-B934-AB4B1248E44F",
    "16329.pdf": "66961910-B682-4DEB-AE06-74CA40921BF0",
    "16330.pdf": "372B8304-9C7B-4D50-A1BE-5DE24C46F49C",
    "16334.pdf": "E990CC4A-28CC-408C-BD4C-360FD3734ED6",
    "16338.pdf": "7B6B0FF3-B66D-4B89-AD67-14583D08BA4A",
    "16340.pdf": "4C433AC4-99F9-4DD9-A57C-EFA007D8707D",
    "16343.pdf": "4A92D2E2-F5F6-40BD-BEB6-BE3BC8526E49",
    "16344.pdf": "2FFC3467-DD95-44C0-91F8-BFA894309153",
    "16345.pdf": "2AFCAA19-26F4-48F4-8357-5ED6D1CB2D98",
    "16346.pdf": "0C905BEE-EC9E-4BD0-AA2A-FB6A70893E53",
    "16347.pdf": "4D17E72B-305D-431A-B70B-1EBF737965DC",
    "16349.pdf": "7B33055B-22F7-4660-8937-55D1E735D964",
    "16353.pdf": "825EAE2A-C42E-43EC-BEC9-A53B8C13C76E",
    "16355.pdf": "3A1509EA-F360-4F3D-86F0-B5B0CAC0E3B1",
    "16357.pdf": "71672FE5-A991-431A-9D2A-7EA3AC030281",
    "16359.pdf": "91B86F0E-C4A6-47BF-90D6-560C55F39FC9",
    "16362.pdf": "431B39A4-298B-4EEC-AB6D-3B83A1004D75",
    "16366.pdf": "A69BF41A-64E2-42EE-8389-BE46F19FADF8",
    "16368.pdf": "F114E7D0-E220-4FA7-8385-A19B9E3FF3DB",
    "16369.pdf": "AEAD069E-F02E-4A9F-8E51-72A3994B33C6",
    "16370.pdf": "6AF6F40D-B0CA-42D2-A8B5-0B9752F6B980",
    "16371.pdf": "36F069D4-7B31-4746-B596-0713E3102443",
    "16373.pdf": "6467AEEA-5883-4BED-B7B1-71E622531B80",
    "16374.pdf": "7864C1D0-81AC-405D-82FE-415C762FADEA",
    "16379.pdf": "1461212D-21F7-4EDB-A468-EED2D33660BF",
    "16380.pdf": "6BA39546-568D-404F-9D4E-7BDBFE162CCC",
    "16381.pdf": "472B48B5-70A4-44AC-B8C6-52C4CB41CAF5",
    "16383.pdf": "FFE62844-E014-41B8-98AE-5D073D577DF3",
    "16386.pdf": "824BE22B-14DF-4A23-95B9-CE1CEA846751",
    "16389.pdf": "82B5B515-D3CD-475D-B0E3-713358A2A7B4",
    "16390.pdf": "667A925F-2509-49F2-8098-B64C539D775E",
    "16397.pdf": "C280DDA4-6D94-449F-ADCC-83DA08B9942E",
    "16399.pdf": "DBF47D7B-4419-4BB6-99F9-BAE9A7C1C589",
    "16400.pdf": "89750B5F-D07A-4293-BE00-7B752C84D20E",
    "16401.pdf": "E170C612-EC69-4E3F-A71A-8D5075E062D2",
    "16402.pdf": "24203D09-6D98-456F-9062-DDC7CA078CDC",
    "16405.pdf": "46120071-4DA4-4B5B-ACAF-21EC57301B2D",
    "16409.pdf": "9FBFA3C4-6DAC-4530-969F-7D892083A5F7",
    "16411.pdf": "75DEF357-42CB-4954-A2B1-D6D261280C5D",
    "16412.pdf": "11AF9CB9-2810-441C-ABF0-36A6EE76FD8A",
    "16421.pdf": "32E77992-6040-46EE-90A2-92C59B888BA6",
    "16427.pdf": "C23A059C-F46B-4589-AEAF-F11C58440D80",
    "16428.pdf": "F4467249-7A72-4E04-B706-0046A1B4DF72",
    "16429.pdf": "6CEE18CA-C5FB-4CE7-A6C7-AD9F32672A01",
    "16432.pdf": "B2CE0682-D571-4C70-8420-E619F68A832A",
    "16433.pdf": "D751A99D-BF6C-4610-BC72-B3FA84C3E4FE",
    "16434.pdf": "71A1D0B4-6F1D-45AD-86CF-1D0A85675673",
    "16436.pdf": "F9D22F88-FE39-4B40-A4A0-E05D6BB5864D",
    "16439.pdf": "AFB7937B-EBEC-4617-A1E8-5B17295F50D0",
    "1644_BY-LAWS.pdf": "9F8C8782-01EB-4857-86D6-42D7B6DA97F5",
    "16440.pdf": "F5155331-75D6-4B08-A6DB-BB6BA14EE9C6",
    "16441.pdf": "75534B84-8166-4B17-9367-B1439B13D107",
    "16442.pdf": "A27B1658-664A-44EA-B094-0F376E05A550",
    "16443.pdf": "36F75737-514D-40BA-9F34-B18873DE952E",
    "16444.pdf": "ED33D74E-2A5E-4DCD-AB57-981A6E88ECEE",
    "16446.pdf": "978AE962-7049-4483-863F-89CE913DBFAD",
    "16447.pdf": "4422F614-EAA3-4F66-979E-4EF6B851A7BF",
    "16450.pdf": "71C16669-6369-4145-BCF3-68C4D534C702",
    "16452.pdf": "E3A3B9A0-75BE-4E47-B576-2E64126D2BA3",
    "16453.pdf": "FB7366C0-4804-4F0C-B435-608DC0E521F8",
    "16454.pdf": "632C0F70-AD78-494B-BAA1-113718CF4A89",
    "16456.pdf": "77E1E061-C3AE-47AC-9847-5E9BC454C90C",
    "1646_PROGRAM.pdf": "D75BC9CB-E1C5-4600-B3FA-1029CAF44D5A",
    "16460.pdf": "D4DD4071-81EB-4997-9DDF-D9CAEAC64344",
    "16462.pdf": "7C595610-FF87-4670-BF46-B8F2B5C56A53",
    "16465.pdf": "5E80DA0F-C15E-4186-80BF-C5F603AAB71D",
    "16469.pdf": "98E907FD-5863-48F9-90C8-44893533AE58",
    "1647_NEWSLETTER.pdf": "40A95802-C0E6-4C7F-931C-AC9107988E8B",
    "16471.pdf": "B034E774-EA5B-4ABF-82BE-CBC23DA2895A",
    "16474.pdf": "2F71F2A6-4171-430C-A100-5CE9BA7B70DA",
    "16477.pdf": "833D31EF-857D-4E73-AC65-E0147F4A4BCE",
    "16478.pdf": "0640941F-928B-4C30-8B6E-59233E650471",
    "16479.pdf": "AA2CB399-DDA7-436C-8F24-D7CE534233B9",
    "16481.pdf": "F1276BC1-3C5C-43EC-8ACB-16D3C6AEBB78",
    "16486.pdf": "CE1A2876-839A-464F-AD2E-24EE5D270CC9",
    "16487.pdf": "0D33A064-691A-48BA-A11B-12923021C9FA",
    "16488.pdf": "2A519E74-5910-4FE6-91A8-BC4DE899925D",
    "16490.pdf": "57304E55-5DFA-4846-840A-AD3DF189EAB6",
    "16494.pdf": "7038EEB7-7B1C-4BE5-AA4E-1C11C69AD986",
    "16496.pdf": "3377E37F-1A9A-4646-8D8F-F9895AA053BE",
    "16497.pdf": "90E05B39-8F6A-4C3F-A3D0-DF873802BF37",
    "16498.pdf": "534E7FDC-1313-458C-9D60-E8C59ACAE13F",
    "16499.pdf": "16C702FD-949C-4816-882F-8EE05617897E",
    "16500.pdf": "C7658B1C-14AC-4DFB-A95C-E965AD2C9EF5",
    "16503.pdf": "7F84D706-E7E9-4422-88F4-5BF3DEEB7620",
    "16505.pdf": "281DE8AA-3D5D-42B1-908C-4FDEA5113259",
    "16507.pdf": "25C64811-1840-46C3-A478-58DEEB52370C",
    "16511.pdf": "88261FBF-5CB9-4A02-9739-6DB6F519FAE3",
    "16512.pdf": "AD9BB36F-AC24-4AA2-980D-E94682F635B4",
    "16513.pdf": "7C734E69-2B12-4A17-A153-C30927FC7F43",
    "16514.pdf": "5B8294F7-0426-4D0A-BD9F-20622913E34B",
    "16515.pdf": "19020BD9-309B-42FA-9224-401FC85580FD",
    "16517.pdf": "4F76467E-62FA-4BB6-8AA3-CFAECC02FFE2",
    "16519.pdf": "9FEE4F33-3280-4D89-B9EF-982282264251",
    "16521.pdf": "47AAD58C-1CCE-49D0-BE25-C1D8A0D880C9",
    "16522.pdf": "B39EB9E5-C0E4-4C0C-8D65-5ACE559A53BE",
    "16523.pdf": "BD433CFB-1BF0-4618-B55A-428EBCA65BEE",
    "16527.pdf": "D4819F22-4CEF-42E8-9756-1F9303AFA456",
    "16528.pdf": "97651641-96F9-49AD-AF65-6E99965F3AB7",
    "16529.pdf": "CFEA7D8C-5EDE-426D-A43D-7D8169439583",
    "16533.pdf": "6AB9C269-385F-49F1-9422-AEED63F4643B",
    "16534.pdf": "59A40EC9-778E-48B3-A805-937941A5917A",
    "16535.pdf": "4EE5791A-D3D6-46FE-8A85-710B51BE6237",
    "16536.pdf": "CF5DD959-D2DA-4670-8E79-AD9B0D82D8DD",
    "16537.pdf": "D1A70E0A-DB01-4BFB-9191-91EFC7BA85D7",
    "16541.pdf": "3B9EF6C7-F13E-4BE3-99ED-EEB8B22F35B9",
    "16542.pdf": "76E6AA89-8D16-44E5-BE8E-ECCEA76C3792",
    "16546.pdf": "9A9A7196-3AD6-4A37-BAAD-4EDFADBF3DEB",
    "16547.pdf": "AFA42B97-C07E-44A8-B323-D7197D49BFFE",
    "16548.pdf": "87783055-7DF5-43DA-A1B3-FC8AEA8E3C05",
    "16550.pdf": "AF28EA41-9242-446B-B8DB-9C0861FD872C",
    "16551.pdf": "3A86E39D-D191-46C2-B3BF-66AEFA9FBFC9",
    "16552.pdf": "41E228DB-232D-4E9E-B8E3-FCEF6BE01314",
    "16553.pdf": "5F9041B0-AA30-41EB-845B-5BFA8671B753",
    "16555.pdf": "D35E7B8D-C09A-4792-A84C-787364B3923D",
    "16557.pdf": "6A3B1FCA-F7F5-4DD4-BCFD-021B0105E4A9",
    "16558.pdf": "9172A417-39E3-4FB2-B970-F728BCF7E1A4",
    "16560.pdf": "675D8E5F-22A0-43BE-ADA0-53F6754189EE",
    "16561.pdf": "57A8B675-F2D6-4BBC-9C60-7B2FD0CE8329",
    "16562.pdf": "B821FAFF-BA56-49C4-931B-3A8C1BFED73F",
    "16563.pdf": "C76A3682-6550-4FC5-81FA-2B08BF00955C",
    "16570.pdf": "0569326F-2495-487C-B18A-25B5EB9A711B",
    "16575.pdf": "FDF796CC-BDEA-49D1-A006-A2758CA5A451",
    "16578.pdf": "24F10D7F-30E1-450E-B6B8-66F5BA332D51",
    "16579.pdf": "DDB91C6B-B21B-4DC0-A905-1B067F2CDC7B",
    "1658_MEMO.pdf": "09F077F9-2F47-4A5D-A338-F4B57FCC8A8B",
    "16581.pdf": "E8810CBD-2B92-4179-8A76-971C61EE79DE",
    "16583.pdf": "C919BF3B-04D3-4B03-A587-2B173D01FDC4",
    "16585.pdf": "8FECA1D6-C3EE-4A1E-A7D5-4A20A78FA426",
    "16586.pdf": "81F5C6D9-9256-43C3-A8F3-49F1EF6B26FC",
    "16587.pdf": "C0F993ED-69B8-42AC-B1F1-571F315A3591",
    "16588.pdf": "16DFC81E-8172-41D0-B203-EB50AB5DA151",
    "16593.pdf": "DED642DD-DBA9-4023-BE41-AE5E706494B2",
    "16595.pdf": "050A5B51-8866-41D5-AE4D-CDAE1FEC9238",
    "16596.pdf": "93FA47BC-8BB2-4177-95A8-E7130B0E3EE0",
    "16597.pdf": "29B0DC60-6EC9-4358-A8FC-D899DEC88AE8",
    "16602.pdf": "C800D770-491C-4881-AF48-59DA096DB582",
    "16604.pdf": "7CC13422-D590-4537-B532-3F8FB314340F",
    "16608.pdf": "9D2792FD-5454-49AC-8EBD-0FF9794527F6",
    "16609.pdf": "E6483B29-EAC0-4FE2-92B4-CD3034CC5E5E",
    "16610.pdf": "2EECF018-E839-4517-AA3C-9359C94E6A64",
    "16611.pdf": "8D1C7A1D-3746-4FC7-A0EC-B9EC87B9BD9F",
    "16612.pdf": "4333C229-5131-416B-B926-9F666B87F3AB",
    "16618.pdf": "1CC77118-E808-4EA9-B65E-69D1F43EF779",
    "16620.pdf": "4BD2E90E-5E8D-4CDB-9E9E-91E1F924E5E2",
    "16621.pdf": "77ECAEFA-F3D6-4BDA-90EF-EB19214601B2",
    "16622.pdf": "E5ED383F-3705-4E8A-8F5E-F98C71360D13",
    "16623.pdf": "6E38BD2C-9B27-4876-9538-7538532A674C",
    "16625.pdf": "25E2114B-CD96-4D62-B254-ADC42B6EEF99",
    "16626.pdf": "8B0CF450-FA5B-452B-A7A8-78D27E7560C7",
    "16628.pdf": "95D508F0-C635-4461-B037-BA43A668E46A",
    "16629.pdf": "94C08FCE-3848-4747-A148-FBFD12A24658",
    "1663_MEMO.pdf": "B481F5E1-C9C3-4F47-96A9-11F34132E83D",
    "16630.pdf": "9BCE4491-195F-433F-BD9B-E0A76916D8D6",
    "16632.pdf": "6EB22665-195D-4D7B-8F72-57878DA4A231",
    "16633.pdf": "9378946C-CA3A-42F5-8FB6-744DDD6E059A",
    "16636.pdf": "799EF479-E3C8-4495-BD82-4293A8352267",
    "16637.pdf": "5200FB6E-61B0-4887-9729-BB2D61CA7C90",
    "16638.pdf": "C9EA3E70-2468-4489-96B2-C2B578B5A216",
    "16639.pdf": "A362BFBA-8F0D-409E-B44C-0192D34643C3",
    "1664_MEMO.pdf": "29AAD677-8537-4870-9443-98210DC6E017",
    "16640.pdf": "F78B7A06-E723-4B83-B961-6CDB2636F21A",
    "16642.pdf": "47859002-2230-4423-9DD9-3D017C991666",
    "16643.pdf": "77AD2665-4671-412B-B5D2-CE3047B96F4A",
    "16646.pdf": "D2E63B12-804A-42C0-88B9-458BDB442BC1",
    "16648.pdf": "66C25560-66A0-47D3-9098-D5A437F89246",
    "16651.pdf": "7488A788-97A1-490C-8CD0-CFECC353934E",
    "16653.pdf": "59806752-CCE5-4EF2-8E04-3204243EE126",
    "16657.pdf": "F177B54F-FCAF-43F3-8007-F81CF14505C3",
    "16658.pdf": "40B19B51-1E2E-46D5-A489-37027A2ACA5F",
    "16663.pdf": "4C5EB43F-9370-44B3-A551-9BD9A3483689",
    "16665.pdf": "09F0CA47-169D-4787-A627-12F8B33F982F",
    "16668.pdf": "754625AD-244E-42A5-8C62-98E9D10795B2",
    "16673.pdf": "ECAD6EA2-0532-42A2-AADC-41851137BBBB",
    "16675.pdf": "DED471BF-4D33-49CE-B410-BBE087BBAE32",
    "16679.pdf": "CB12174B-D97E-4C53-AB5F-F2A7E4817783",
    "16684.pdf": "DD24B183-080A-4DDF-A0E2-01DD025C106A",
    "16688.pdf": "260FE1B2-7378-44CE-B9EE-9FC6C4A60AE4",
    "16689.pdf": "93E7D547-3E42-4F0D-8E2A-CF86CD2A3864",
    "16690.pdf": "56AA836C-5948-4DBD-9090-A1DA47952E12",
    "16691.pdf": "3B8B1349-5577-43DD-9924-E1B549214F62",
    "16692.pdf": "7A232627-CF37-45AE-A79C-6C1D8F1BB8FB",
    "16693.pdf": "37BCE987-9DFD-4CA5-9183-C750D0B4223F",
    "16696.pdf": "EBF0B9AC-27B8-4A19-BAB6-A2D176E9353A",
    "16699.pdf": "E0967736-6348-4C5E-9F8D-1DFD109D4719",
    "16700.pdf": "5272A0A5-3BE7-4F28-9F4B-5DFB1210A316",
    "16704.pdf": "416955B1-A7CD-40C9-8B21-B56AF3382CEE",
    "16708.pdf": "513F0A30-B5C7-45D9-BEBD-551B3940FFCD",
    "16710.pdf": "AF7429FF-7D53-4F9C-AE0B-409E0599ACEB",
    "16711.pdf": "17BBA5E9-080A-4667-A2DE-5ADE7CD1A849",
    "16712.pdf": "3130B48B-F6B0-4A72-BF95-495A4313DBF2",
    "16716.pdf": "AAFD857F-C653-4B85-98D0-2FB275611FBB",
    "16723.pdf": "C2E8F228-02F3-42FD-BD37-22C55984651C",
    "16725.pdf": "56C05553-0FF8-4EAC-BACF-F05C65C058FD",
    "16727.pdf": "9581967C-C967-49EE-BC18-AD873F8A0CAA",
    "16730.pdf": "6C0EBA0F-EB4B-49FE-B1ED-C652F0807549",
    "16732.pdf": "7D062FE9-41F4-4FC1-80FA-C32B69260E18",
    "16733.pdf": "9C4B8614-4FD9-49EB-9982-548C21B00A99",
    "16738.pdf": "0CD9A147-DAA5-4ABD-BCA6-7B3D90A2E00C",
    "16739.pdf": "D1546CDC-115B-4603-8226-F87A1BE1151C",
    "16740.pdf": "10C5ADE3-EE37-44F8-98BE-E042AF061332",
    "16741.pdf": "3B860D4E-10D3-421D-85EA-705DB104DB53",
    "16742.pdf": "01400AA9-025A-490E-88B7-4EF813EFC0E0",
    "16747.pdf": "4C1CBE1D-6775-43B2-B62E-B56B41C1DDAE",
    "16748.pdf": "7069E8D2-D261-4B1F-B954-D31954CEE712",
    "16749.pdf": "941DDF51-0B2C-43A3-A077-3869C5FD8AD6",
    "16750.pdf": "694F7BD2-0DF8-47F4-B3DE-08B8092B8B30",
    "16751.pdf": "BD3722D9-2283-4BEA-A9EB-319B35C1C766",
    "16752.pdf": "5D5C2036-6436-43F9-8B8F-4ED6970C12BA",
    "16753.pdf": "401CC2C5-6310-410C-BEFB-058340A330F1",
    "16755.pdf": "9DAC06CE-BC54-4B78-AACC-12843ED4F9A0",
    "16756.pdf": "0AE6F97B-E564-4E44-B685-5095C3F90B47",
    "16757.pdf": "48E8A697-9044-451F-8AF3-BAEF070D5DAB",
    "16758.pdf": "925E1460-C626-4C3D-A753-F20B1B5AE73A",
    "16760.pdf": "B0A82F68-74CD-4436-9DAB-726409039AF8",
    "16761.pdf": "72FB0E2A-AAE4-4F0F-8090-06DAF9AD2D02",
    "16763.pdf": "6E5B347D-D61F-45FB-A281-B0A20BE7ADCD",
    "16769.pdf": "70F86DF1-AAA5-4F7B-9CFA-CAE8AFA7D3F1",
    "16770.pdf": "3FE1CCFF-C254-4288-B0D1-17161805AAF2",
    "16771.pdf": "460D9858-961F-4A0C-9116-52434B407AEF",
    "16777.pdf": "E5472A64-BBD0-4B37-A1DE-27AB479155E1",
    "16778.pdf": "C17C4411-8BEC-4C51-9FE1-E5D89172661F",
    "16779.pdf": "E6100375-3810-4272-A881-E18C32800F72",
    "16781.pdf": "C5058667-4AD6-44C5-A12E-68E284281591",
    "16785.pdf": "92321CEF-A5EB-4DA4-B451-53169E9EED92",
    "16787.pdf": "7EAD005D-2DC5-4A4E-A8A1-49D80706806C",
    "16790.pdf": "775DA867-109B-4B65-807C-3B41A8CAB905",
    "16791.pdf": "9DB305A0-BB9E-4979-9819-810E47FA03FF",
    "16792.pdf": "1B6C4399-A69D-47DA-9DFB-ECE3A96D09FE",
    "16793.pdf": "26E984D3-AE0D-45EE-8D9E-4C76805E79E7",
    "16795.pdf": "6ECA4E7D-46B9-4DBE-957E-90009A30B820",
    "16800.pdf": "400F881F-5A15-441D-AD6B-301128AA8618",
    "16801.pdf": "026DAE41-81E6-4D46-92DB-0BF6BD8BE4D6",
    "16803.pdf": "BB32C169-90EE-4AA2-95FB-BF4671A3F043",
    "16805.pdf": "2635A96F-2A5D-4143-9FE9-3028D3CE2AB3",
    "16806.pdf": "FFF6B6F5-804A-4908-92D4-CF0AD499FAE7",
    "16807.pdf": "D06ACC69-D14D-4A41-B2A4-74A38F06A9F4",
    "16808.pdf": "60E85A16-C21A-49BA-B259-C6A32079A30E",
    "16810.pdf": "AC2C8D7E-2F02-4077-A8A1-A39D606AB489",
    "16811.pdf": "071D6E7D-D4E1-4668-8187-788F45DB9270",
    "16813.pdf": "7E2C1276-C210-41D7-AC10-1F906DB76BED",
    "16815.pdf": "F0813D19-FE2B-4ED4-A849-719F7E0F9B00",
    "16816.pdf": "A111438E-41E0-4326-A78B-A887C278E385",
    "16817.pdf": "70AB156C-C938-4192-A7A6-17F758F9740A",
    "16818.pdf": "41C48613-DECA-4574-A8D0-D8B58CF05474",
    "16820.pdf": "DDB92A33-8B7E-41F4-A420-3371858161BB",
    "16825.pdf": "BFD83CE2-048C-4BBB-95F3-1C58558ADB62",
    "16826.pdf": "8CAF0A3F-6B05-471F-844D-14C8FA26A9A3",
    "16827.pdf": "07DE94A6-7DE1-458B-B98A-8634BC74AD60",
    "16828.pdf": "B0E50C44-C2C0-408D-83F4-73250ABD7B91",
    "16829.pdf": "9484710D-80E4-4F55-890F-EAC2276EC21B",
    "16830.pdf": "118E253C-8DF6-4EF2-98D3-EDF4DB1AB9B7",
    "16831.pdf": "BE2E44F9-2192-4BDE-B31D-54D4C981315B",
    "16834.pdf": "A4150B5C-330B-40A7-9924-B3C45AC7F629",
    "16835.pdf": "53B7C11B-BF8F-4290-A2F8-EDA91DD31519",
    "16836.pdf": "EBD382B3-5389-44F3-8858-2A57ACC0B579",
    "16837.pdf": "B5749AB4-CCAC-4775-8449-6138E5BAB5DB",
    "16838.pdf": "C1C06C8F-0676-4BEC-93D2-199CEDCFDAD8",
    "16839.pdf": "4FD9C997-7EEF-42BC-8112-6BD0E475E7BB",
    "16840.pdf": "070CB13B-415A-46FF-9843-CDD391D3A7EB",
    "16846.pdf": "BF163A82-92B8-40B4-A853-2805DA79735E",
    "16850.pdf": "AD2D2C6A-2F2C-476C-9A75-A910EBF7C02C",
    "16851.pdf": "BC95076D-9BE1-4600-89CB-C3703D0ECB07",
    "16852.pdf": "71EADAA0-8F28-4318-93A4-1197F0E82AF7",
    "16855.pdf": "91E7A034-4A1A-466C-93DB-BD83A2C5B840",
    "16856.pdf": "435EC844-63FE-4D56-94F4-D7B8A06D3128",
    "16858.pdf": "CA78C802-8E46-4CF5-87EF-9A5A5BE7A798",
    "16860.pdf": "262AB815-9A37-4B8C-B6B6-0DA0E5028902",
    "16861.pdf": "26E3AECD-DF6A-44AB-8CA4-10F94626CCF9",
    "16862.pdf": "F020580A-D8C8-48B0-9133-80D9648D6032",
    "16864.pdf": "20124244-19C9-42EB-B331-F63B085BC31D",
    "16865.pdf": "E0A227E0-1495-4C81-BC53-E6E4AE127F4B",
    "16868.pdf": "A1D62B30-0A33-40A5-8EC9-78881DA59E5E",
    "16872.pdf": "0B3D4AFD-7547-4A99-8E95-ED36DDD3B145",
    "16873.pdf": "70488B10-3CC2-4F0F-A06A-6F4C17CD34E5",
    "1687424272768_ElRahmany_230622_105815.pdf": "B42F89E7-C4CD-4B67-A2C9-1B6B69F7DAB0",
    "16876.pdf": "F720E131-5DD1-4621-BE88-CF9580C6680F",
    "16877.pdf": "5B80972A-CF71-4A8A-A1EF-F31D802D2D56",
    "16879.pdf": "4FE4DF7F-699F-400A-962D-4D26D106DB50",
    "16882.pdf": "179D4D51-AC2A-4290-9C66-F4A27277A1DB",
    "16885.pdf": "8652EFC4-3105-45CF-9874-F940A7E4B0E8",
    "16886.pdf": "1001375B-1B07-48F1-82A9-6CEB78CF7B73",
    "16887.pdf": "DCF03B2A-4AC1-4C19-B9E7-DFFABFD72F23",
    "16888.pdf": "0A419700-86ED-45EA-BDF2-515B6B1BFB5F",
    "16889.pdf": "B7DF115C-E265-4D1E-81AF-4592B23EF7FB",
    "16893.pdf": "04651E3A-9940-45CB-838B-8D9AE10A5D0D",
    "16895.pdf": "9812A875-C986-4DBD-AFF2-389407BCEFA0",
    "16899.pdf": "36A7675C-7865-4F99-9903-C76A874EF3CD",
    "16900.pdf": "CEEE8012-3E5E-4270-AC8A-F371B7A8924A",
    "16901.pdf": "B3BB39AA-D037-410F-A426-D98FE97E8207",
    "16902.pdf": "7D578F1B-D8A1-4071-AD2E-724C7FB87560",
    "16903.pdf": "3E1AD7B3-5045-4DF0-9BE7-05D0D34016D1",
    "16906.pdf": "FA1FA536-26B9-4930-930F-3F281543DA47",
    "16912.pdf": "74EBB680-4849-475A-9E1B-51113C49E62A",
    "16915.pdf": "36FB02DD-21AB-4D8F-AAA5-E19673C4AB1A",
    "16921.pdf": "AE5A035A-4FB5-4F6B-9F06-0E695E35A91C",
    "16922.pdf": "9425BC2D-82CE-40CD-A75C-9458A02707F7",
    "16923.pdf": "45ABA4D1-FDE4-4DAB-9AF6-55F82F7EFA46",
    "16926.pdf": "00C7FEDF-701B-40D8-81F5-51F2B7E76EF1",
    "16927.pdf": "9450604C-6F52-47B3-A4F5-B3F451716820",
    "16928.pdf": "DD378C61-5A0A-45DF-AE63-AF3ACF988915",
    "16929.pdf": "20C0BA3C-3202-4CAD-A412-B70E325F6756",
    "1693_NEWSLETTER.PDF": "7ECBCA7E-3D0A-4A9A-BFE1-24741421EBDF",
    "16930.pdf": "79B1266A-AF49-418F-B256-E5F1C07E6F50",
    "16931.pdf": "0DAFA7F9-E352-4A29-B831-9A8DF2F6255F",
    "16932.pdf": "84AEAD6A-570A-4799-B71B-91DF75694F88",
    "16934.pdf": "FAD7BF0E-90BB-4AFC-82FD-AED173B3F29C",
    "16937.pdf": "327D7D94-BE31-4176-9163-02A74E71AC3B",
    "16938.pdf": "EBD40156-CB4D-42F9-82BF-CDA7E1FA8B64",
    "16939.pdf": "94D11225-6C9E-41FC-A23A-FAAE82739AEE",
    "1694_NEWSLETTER.PDF": "147E5C2A-FDB2-48EE-81A4-22E143534FA8",
    "16942.pdf": "42D96F36-39AB-41B4-9AD4-3435C898AB8F",
    "16943.pdf": "E9FA06C5-DCC3-4095-92B5-318288E39991",
    "16945.pdf": "9239B3BF-241C-4E9F-A20A-357E749C093F",
    "16946.pdf": "BFDAC2A6-B58B-49F1-945B-26682BBC6E04",
    "1695_NEWSLETTER.PDF": "D0EA8407-6FE6-451B-A764-2BC5EFBF7247",
    "16950.pdf": "23E48E3E-6D96-4724-8919-3465E41E1FF2",
    "16951.pdf": "862193CB-5EF3-4C1F-94F2-3C6534FDF632",
    "16953.pdf": "E5070C35-24DD-4D78-B150-F055F069EB4F",
    "16954.pdf": "8B313AFC-4F5B-42F4-B644-FB5F4A955887",
    "16955.pdf": "B7CA6B45-8893-4EC6-B086-F4051AFBC083",
    "16956.pdf": "F9DBB653-88D0-4EA4-AF41-3070D2C90ED3",
    "16959.pdf": "FD4C3A92-578A-40A9-AE96-235CFAAF8DB4",
    "1696_NEWSLETTER.PDF": "84BD4722-C5A2-4621-98DD-FBC035DFC319",
    "16960.pdf": "A433EBC9-F8E6-43A4-97F7-46CB005CB365",
    "16961.pdf": "41DAF31A-E9E2-4B97-9A1E-7BEA7BC2AA71",
    "16963.pdf": "7F6724CB-11CE-467D-B913-97BF7A686511",
    "16964.pdf": "71B6AC1B-FE8D-40B6-85BC-F962718D87B7",
    "16965.pdf": "588DA0B7-8702-457C-8565-6C1FF8270FAF",
    "16966.pdf": "0631D2F8-6412-4A1B-AC45-832FBA9194EB",
    "16967.pdf": "E69A1DB5-2817-410C-A72A-2E2A1CDAA5FF",
    "16968.pdf": "5E1E2038-5A0F-4389-9BFF-01902870A238",
    "16971.pdf": "CAA652AA-CE36-4EB4-9716-2FFEA002A278",
    "16972.pdf": "57ECBCAF-8479-4973-869A-9E8BF552C141",
    "16974.pdf": "9F576A90-AE6E-4041-A128-4C07BE8010C6",
    "16975.pdf": "391095A5-1B0F-4713-9199-6D6A9ACD4210",
    "16978.pdf": "900FC850-82C5-44B9-84DB-8B1573390571",
    "16980.pdf": "CFCB45A0-F319-4244-AA0E-883510D57572",
    "16982.pdf": "22B969FD-351D-4D42-9D09-C62E1D4993D1",
    "16983.pdf": "4DD058F1-EFB4-4658-BB74-83743BBF15EF",
    "16984.pdf": "695AD413-E5D8-4CA9-AE4F-3383D0129FB1",
    "16985.pdf": "B38CE36D-3DEF-476E-991A-C97337E120AB",
    "16990.pdf": "4653BBE0-4DC2-4647-9B20-A0F7586DA1A8",
    "16991.pdf": "50893560-93D2-464A-AB15-BD1422FA1371",
    "16994.pdf": "B5A050D7-A7BB-499C-8F1D-3520064C97D2",
    "16995.pdf": "BA112AE0-74BA-47EE-9681-FD4DDA58F0E6",
    "16996.pdf": "1F4ABACD-F59B-4652-8FC3-4CCD855182E8",
    "16997.pdf": "A61CB52D-E7E2-4885-BE96-D4E912E99C14",
    "16998.pdf": "A2E6E8F2-E6B2-46EF-B0BA-F2407F51808B",
    "16999.pdf": "1958ED4A-3665-426A-817F-C249D1B08681",
    "16Historical Synopsis of 2016 Meeting.pdf": "E26FAB2E-63AE-464E-A20E-6739314162DC",
    "17 - Bone Growth Stimulators and Lumbar Fusion.pdf": "37B26468-FCF2-4EEF-B432-BC48E332BFC2",
    "17-02-2024 13_53 Microsoft Lens.pdf": "EEBBEB70-4615-4A22-855C-D54363FC0B05",
    "17-88251-Opioid-promote-AANS-CNS.pdf": "CC693E04-769C-4F32-AE43-235151B74F7C",
    "17-88251-opioid-promote-aans-cns_0.pdf": "3ECF3DD7-B7E2-4557-A938-A83C00B51CE7",
    "17.pdf": "C5EC216F-EE95-48A3-A02A-EEC9370D8DD1",
    "17001.pdf": "5D369419-6BC6-4E2D-A97C-C05A8E335EDA",
    "17003.pdf": "67AAE8C5-AF05-403B-BE3F-ABCEBDE4D121",
    "17004.pdf": "DF239B7A-E4C3-4B94-937F-A160BD0710CB",
    "17005.pdf": "329103D8-9D7F-40C7-9B0D-1326AC3E22DF",
    "17006.pdf": "A27F0226-263E-4EAD-9317-5042B584852A",
    "17010.pdf": "B0B06A92-2DF3-44FF-ADD7-FFE622B5134D",
    "17011.pdf": "4B795186-FF92-4C8A-AC0B-940843FDB686",
    "17012.pdf": "832634EB-26BF-423B-8B05-66C792C842D5",
    "17015.pdf": "5216C5DA-C1C7-4041-9636-A22F7536AA9D",
    "17016.pdf": "6FDE72CE-F66A-40A7-8CD2-05F91843D0A0",
    "17017.pdf": "C5175D68-98E4-4E42-B555-530BC5A5FB70",
    "17018.pdf": "551F11F5-C9A0-4C72-959F-34F6DBB582C6",
    "17019.pdf": "4970E6CC-22DB-4090-A5B3-A67E73F83F87",
    "1702_NEWSLETTER.PDF": "DEE9ED16-ACCE-4613-BD1E-3267AAA7AE3F",
    "17020.pdf": "05072313-3D75-460C-BD1B-D086711C3302",
    "17021.pdf": "A0831388-BED5-43FF-AB7D-E46005EB066E",
    "17022.pdf": "488042F0-6588-40EB-BAB7-84D399E3F529",
    "17023.pdf": "EC056E47-0C7D-458B-AA10-C39E483DA3C0",
    "17025.pdf": "149CA4CB-7F47-40B3-AA7C-1B686DEE7CB3",
    "17026.pdf": "BDA92C46-CCDA-4712-A18E-11A4973509E5",
    "17028.pdf": "42CB9596-9726-4FCA-8048-E1DF96F74705",
    "17030.pdf": "0CF72B67-F2CF-402D-8A9F-D8E47C3EF0B7",
    "17032.pdf": "EE272C46-FC6D-41DC-A0C8-770CF318AEDE",
    "17034.pdf": "71621894-813B-428B-B00C-70E62754A890",
    "17035.pdf": "E7F38BF5-8AAF-4833-865C-B265384134F3",
    "17036.pdf": "61B528B5-9F26-4C30-B222-C9FE011E97CB",
    "17038.pdf": "3794561D-0581-40B7-8562-9126E14A0702",
    "17039.pdf": "9785AFBF-524D-48B4-9421-C9F2F965F433",
    "17041.pdf": "0EA5798E-78BE-4DF9-BD01-4A6A98AC4C2D",
    "17043.pdf": "901F1176-458A-4DDA-B0D7-3D4A314B7E6B",
    "17045.pdf": "FDDDFFDA-ADF4-4FD6-9E64-D8CB4147C837",
    "17047.pdf": "664D153A-BD4A-40C9-B265-15DFB2976EE2",
    "17048.pdf": "4936AF09-3084-4C6F-A830-A38D1F1B9F55",
    "1705_MEMO.PDF": "E7896010-94C4-4072-9827-622BF8D5468D",
    "17050.pdf": "05A17042-AB8A-475F-BE4A-DB1BEAEB91EC",
    "17052.pdf": "7AA178AF-5529-42B1-A903-3C0B3FF10153",
    "17053.pdf": "0EA31378-773E-469C-8EA8-719BE0E94C35",
    "17054.pdf": "F9CD13A9-8874-49E3-869A-C79893A1F1D8",
    "17055.pdf": "8D6CE454-72F5-42EA-9D3F-F81756B1D50B",
    "17057.pdf": "EC093A19-FEE5-4DEE-BAB1-DF601C43E61E",
    "17058.pdf": "64D70F6A-4744-43D8-BBB5-CECBF1000F32",
    "17059.pdf": "8D47ACD3-3772-44DD-AE5D-B945B759D63A",
    "17060.pdf": "556095BE-BCB9-4C14-B335-55747944A50F",
    "17061.pdf": "99A6DF09-63CD-4102-93CA-D3A922E9C71D",
    "17062.pdf": "3D996138-094B-4548-874B-7D06127850CD",
    "17063.pdf": "99E395F8-0C1D-4AB9-A039-2B6A9AFB431D",
    "17067.pdf": "27B637C0-7492-47BF-A861-8212D2A10606",
    "17068.pdf": "5C22FC28-F79D-4A7A-A35D-9EEF7A2BF871",
    "17069.pdf": "E978F414-A392-488B-99C0-2715C4F8A002",
    "17070.pdf": "533A5063-387E-44F1-A772-4AA5C959775D",
    "17071.pdf": "3CC8F0BF-D613-4C7F-A156-92E29CF82760",
    "17072.pdf": "AE8E641C-F552-4F8B-8886-1B90F2CD6E22",
    "17074.pdf": "9CAFE913-5759-434D-8872-A64D90B588C5",
    "17075.pdf": "73513F75-D492-481A-915A-7CA47559B929",
    "17076.pdf": "94443952-D62C-46A1-92EA-EE9D98BDC350",
    "17079.pdf": "0D700206-A094-4375-A177-10DD0F5C7B75",
    "17080.pdf": "24BBDE6F-EC76-459A-8F70-188308762C2E",
    "17081.pdf": "E9AD4BF8-5FF6-46FB-8056-7E2C9F208555",
    "17082.pdf": "EAC3EF92-A42F-47F9-9E9C-A0818F57040C",
    "17083.pdf": "5791A975-9E02-4AB6-AFE3-55EE3BF10143",
    "17085.pdf": "446CF5CD-3096-4019-840A-0E3E12258EBD",
    "17086.pdf": "9781397D-5C0E-470B-A942-BF428D1861B5",
    "17087.pdf": "87D22518-5792-4BE5-8164-5EFC743DD425",
    "17088.pdf": "DCA21EEC-278C-4C96-8626-309066CF55F9",
    "17090.pdf": "20F28280-ED9D-46B6-B92D-A5AC58AE1C32",
    "17092.pdf": "94322BC4-914F-4467-A3E8-8AE4B0EFEDE5",
    "17094.pdf": "7D055B28-6240-41B9-A6B0-CDCD3F92EC83",
    "17098.pdf": "21313BFA-7FBB-4C0E-943A-B6624B7A6D64",
    "17100.pdf": "136583F4-C569-4521-A26F-4C7AE65C26FF",
    "17101.pdf": "4EF1E71D-38C2-4AC3-BD44-7147AD87F2F5",
    "17102.pdf": "708ED198-3A02-4C5E-984C-25E00671D49D",
    "17108.pdf": "A9F0D932-38D2-4EB2-9BAB-7D97424ECF95",
    "17109.pdf": "1C14F3DC-4793-4FFA-A460-17286126EC53",
    "17112.pdf": "0B90264B-36E4-4CF7-AAF8-E11BEA2CA853",
    "17117.pdf": "7CE53714-68ED-48B2-8094-030582982BB0",
    "17118.pdf": "2EFD754D-1CD5-4C2B-A50D-1FFF920E4B21",
    "17121.pdf": "6CCD3963-796D-42ED-A516-1FEC48F95427",
    "17123.pdf": "0975A33C-690A-4721-A98F-55F1A0CD126A",
    "17129.pdf": "BFDCDC61-6712-43BB-8900-E638AAD55D3C",
    "17131.pdf": "4BF4A50B-3838-47F7-98B5-731969E8895B",
    "17132.pdf": "523C6D16-5A88-481A-B243-CF5B6B621968",
    "17135.pdf": "54220C65-6F51-4DC4-978B-F78A4F1AEDDB",
    "17136.pdf": "9F47AD4C-7996-471C-8752-DF9826B68E28",
    "17137.pdf": "A7CAB161-1366-400F-ACCF-EC3EFB10CA2A",
    "17144.pdf": "F8EDE963-6F13-440C-9798-C8C3526570C4",
    "17145.pdf": "22C0DAC9-BF88-4225-BD3D-4E1311425FAD",
    "17146.pdf": "A2DD7451-F01B-4D13-8E21-32A0BC75AB8F",
    "17148.pdf": "FCE62015-49B6-48CA-A2EF-AA9DE13A6CD9",
    "17151.pdf": "3D4B521F-0F07-4D17-A3F7-51973FADF7AF",
    "17155.pdf": "15730C9E-F326-43F9-85DF-011A99E7C9D3",
    "17159.pdf": "20883554-63A9-4743-AD6F-942D3D63ADB3",
    "17160.pdf": "B7EDB9A6-7B83-46B4-B263-11E09209F6D3",
    "17164.pdf": "A15D6400-81C5-4714-A63A-5B25B1D8AD5D",
    "17165.pdf": "51BC8F2B-9321-4188-AD7C-B9546B97A93C",
    "17168.pdf": "C4979019-98F4-46F3-9B6A-0FD18EE841D1",
    "17169.pdf": "562B6533-EAC9-40A0-9A61-7FF942AA43AB",
    "17172.pdf": "67A45295-14CF-4235-8224-B040EE6FA9CF",
    "17173.pdf": "19195317-554C-453F-A09B-10A3C0606FFA",
    "17174.pdf": "BDC90CBD-2127-4E2B-B76C-82A14EC8B842",
    "17175.pdf": "811480D9-EB67-4F31-97C4-2A00111D8934",
    "17177.pdf": "E1C2AC63-EAA3-4061-BC8A-2241FCAF097F",
    "17178.pdf": "D803FDA3-AADD-4667-9B3D-FE23539C71F9",
    "17180.pdf": "7C735FA8-5284-4EA2-8762-5CF89E752FEA",
    "17181.pdf": "D4C7CD97-EBFE-4110-B46C-B5E993FD7F34",
    "17182.pdf": "3853F221-E470-418B-BF13-8F56BED309CB",
    "17185.pdf": "C98D1692-DC0B-4A6C-AB25-19A7987EF1E2",
    "17186.pdf": "CC593115-7303-4648-953E-C22B13C6A4B5",
    "17188.pdf": "D5F6D23C-6AA9-40F6-A9F5-79A42480004C",
    "17189.pdf": "93F5DBE6-4A3F-4CF0-8C73-272682B8A832",
    "17191.pdf": "E702E828-5048-4206-AABE-30CE406C82CC",
    "17193.pdf": "E7AF65BF-6D04-4B80-89D3-51E38B2C3615",
    "17194.pdf": "3FC6C899-9641-44CC-9DD1-5EE5F136C5C1",
    "17195.pdf": "203765AD-2B21-4F10-A675-7663B013AAF6",
    "17196.pdf": "D17A1DFB-F72F-45F2-9504-37C35658A5D4",
    "17197.pdf": "E773D529-7BA6-4D57-8E1D-E29EA8C2C5D4",
    "17198.pdf": "F890F829-60E4-4D22-8563-90C37C5EF5B8",
    "17199.pdf": "DE6C597C-D721-46D4-B999-95BFD7499855",
    "17200.pdf": "6CAD0E18-F749-4BE3-AB03-4869D11FE90A",
    "17201.pdf": "E551AC27-21F2-4734-A452-A10B5EAB9CC5",
    "17202.pdf": "704F3F82-ABA9-48CB-85DA-633454FAB73A",
    "17203.pdf": "62027297-C87C-4A81-996F-9AAF44710E7F",
    "17207.pdf": "0D507CD7-C3FD-4909-8CC9-05095557DC73",
    "17209.pdf": "BAA5E940-DFFC-4A39-B9E9-47477E79233B",
    "17210.pdf": "1E22DA99-796D-4D2F-AC8B-1D0FD5F87552",
    "17213.pdf": "89448BB5-6724-45EF-B5EA-084584F6928A",
    "17214.pdf": "D635C4E9-019A-481B-8FAA-94D676105F27",
    "17219.pdf": "EC18BFB3-B9F0-44E6-BFDA-CD9E63DFE3B4",
    "17220.pdf": "C7954572-D6BF-4D35-9155-844989B8BFFF",
    "17221.pdf": "B7A8425A-A4FF-4E78-8093-9E78B327556C",
    "17222.pdf": "CF82594D-4814-415E-A680-250A33EBF398",
    "17224.pdf": "6FBBAB96-3A3C-46F4-B97B-1BEB2F724AD3",
    "17226.pdf": "6308EA5B-4D85-43C4-9F39-2D8BDCC825C3",
    "17228.pdf": "BFCDF1AB-3A46-4F00-9EBD-81117154F09D",
    "17229.pdf": "78B9FECF-8A79-495E-A85F-318BA1067663",
    "17233.pdf": "3BAC5BB6-0F1B-4C35-B047-3439398C9D16",
    "17236.pdf": "A9268A31-4298-4AD6-8AF0-5B40E8625B63",
    "17237.pdf": "D773F190-846A-4D43-97F0-3D9617B01A24",
    "17238.pdf": "A5CBCBAD-9558-4449-BD07-62BB1A4CFE91",
    "17239.pdf": "5A359153-16A7-4502-8187-E64AE821D6E3",
    "17241.pdf": "C4E4CF02-0CAE-4DE6-B826-AFD25DECDAEE",
    "17245.pdf": "02A98B86-73A5-4ABE-8DE5-2DA730C273BB",
    "17246.pdf": "C4B850EF-F4A8-46E0-BCC2-90800BD350F9",
    "17249.pdf": "CCD36C36-517A-4001-AB11-C1EF0D614C89",
    "17250.pdf": "AD2A4720-21EF-4780-8410-4ED11D170F3E",
    "17251.pdf": "62DB10EA-73FB-4F90-BA55-4E786E87FA19",
    "17252.pdf": "D11BEE65-1CF1-4BEC-8D4A-5B04A4BB9975",
    "17253.pdf": "09B04EB8-4398-452D-B587-078EC56293D0",
    "17254.pdf": "EAE77D56-361A-4F85-A237-3EE4BF26325D",
    "17258.pdf": "F1C4F6B5-3766-4FB7-A635-EA9926FD33C4",
    "17259.pdf": "280FC2F9-EE43-4D81-837D-B52AA42E38C7",
    "17260.pdf": "562217BC-2A89-45D0-AE76-A8832CAA3A0F",
    "17261.pdf": "54E3BE98-B065-4CD6-9A15-367A910F7B4F",
    "17262.pdf": "2683B964-922E-47AD-BEA1-297C34F2AD46",
    "17264.pdf": "32994ED0-DC28-495E-A800-42E982CBCA3E",
    "17266.pdf": "41031B03-5DFA-4A5D-8E28-11800DF9EEDC",
    "17267.pdf": "309601DB-F7C8-42F2-9123-7A0EA762D3C3",
    "17268.pdf": "E36ED4EA-D95C-4D26-B147-B205E37F3C0D",
    "17269.pdf": "41D0F434-4895-4442-AB59-0ED6E82C175B",
    "17270.pdf": "AC6B15DE-9157-489F-82B2-77248CA2F342",
    "17271.pdf": "CB02830F-AD7A-450D-93C0-AD65F84CFB41",
    "17272.pdf": "3EB36C2D-ED0D-4F50-A5EF-F583F390111C",
    "17273.pdf": "2BB36066-F846-45A7-846E-C96225161579",
    "17275.pdf": "25DAB995-AC69-482B-8969-10EA81469376",
    "17276.pdf": "019A5030-341D-4CCD-AC99-8BCE16D380DB",
    "17277.pdf": "34EAD8F9-AE77-474F-98C7-FF40C34E11E0",
    "17278.pdf": "D21A745C-A4DE-4F87-B75F-C6D50B05C9C3",
    "17279.pdf": "4D9A0209-E38E-4814-96C9-E86EFE507B29",
    "17280.pdf": "562E4AC2-F618-4404-A3FA-167031F5A0B0",
    "17286.pdf": "5F824C33-3D74-42CD-93C6-1C89DB955F78",
    "17287.pdf": "4774A0D7-09C3-46C8-84D3-92C6657C9B9F",
    "17288.pdf": "34B19BA6-D952-4488-933C-14B8EC704580",
    "17289.pdf": "CE314CE8-AF5E-4334-A8FD-D5808A77382F",
    "17291.pdf": "15783376-3EE7-474F-9F7B-896B0CBCEEC4",
    "17292.pdf": "7D1110F1-4AD0-41F9-BE67-D13F00281077",
    "17293.pdf": "DADF12AF-DF22-427D-BCBB-87A67599317B",
    "17295.pdf": "E5A00589-9C05-462D-8C0B-E98F22F34EA8",
    "17296.pdf": "81551F6D-34AB-4DDA-B3F8-A9B37741B7F7",
    "17298.pdf": "AB632677-A363-4BD7-82BB-CB446E568F18",
    "17299.pdf": "C859B2DD-10E7-438C-9297-8E37D17E06B3",
    "17300.pdf": "D3F77EE3-DC70-480D-B495-3662C2A639D2",
    "17302.pdf": "794CAA0F-1AFB-4E81-A08E-07D2306EB349",
    "17303.pdf": "93139765-097E-4782-A1DE-EDDA6B18AB7E",
    "17304.pdf": "FFC10C89-D382-47DF-943A-293070464ECC",
    "17305.pdf": "86450752-63B6-43C7-95B1-6E6428805FB3",
    "17306.pdf": "2096ECB2-4711-4308-983B-FF37A892F764",
    "17307.pdf": "2052008F-E7D7-423D-94FD-03ABECF628EA",
    "17308.pdf": "23FCADD5-E675-49E0-B7E8-F9A1A00348BA",
    "17309.pdf": "D2F39203-C02F-44CC-85D0-8D355AE8B508",
    "17310.pdf": "B0374333-B560-420D-BFC5-F3B709CF16E2",
    "17311.pdf": "52D23DF5-BF5A-4D9A-B5FA-B3BE80954335",
    "17313.pdf": "B6881D7D-5DBD-458B-BA91-B79736520605",
    "17314.pdf": "7DBB238E-EFBA-45FA-AF3B-A46C33CEF417",
    "17315.pdf": "3261CBB4-F51D-4971-A9D9-2A8C0A555FC5",
    "17316.pdf": "9D26A78F-D181-4F16-9A73-67C93605C747",
    "17318.pdf": "50C7A309-D0EA-441C-A88D-7C8F06D4502F",
    "17319.pdf": "C46BBDCA-4F47-4129-8567-27CC18B225FF",
    "17320.pdf": "6EC52D18-5C6B-4250-A2DE-0A32D9AE64C0",
    "17321.pdf": "1830ADE7-96F6-4A17-8F99-519FB12AA8FE",
    "17327.pdf": "35703FA2-CF2A-4723-A998-491B8B829BE9",
    "17330.pdf": "63691AC1-4C1A-40B7-97AD-90DB9CE9F515",
    "17331.pdf": "47D61DEA-EC74-4EF4-A5B2-D9FF7EF9F98D",
    "17333.pdf": "25517AC0-EA1B-46C2-92F6-E036C0C8BD4E",
    "17334.pdf": "55F7A2E3-643C-428B-9936-64E2D6224C2E",
    "17338.pdf": "F9BC1463-FCB7-4C42-A02D-E910542778BC",
    "17340.pdf": "657FB5EB-57A0-4CE3-93EF-36B87B533957",
    "17342.pdf": "CF9A6708-BF4F-43B9-A0D7-3DA0B3D122D0",
    "17345.pdf": "63F066C8-D994-4B72-A2B9-175A775BC478",
    "17346.pdf": "96D92EF2-C516-4275-B9CC-EB5243182D05",
    "17347.pdf": "687A2203-0352-455D-A434-B8F25FC9CBDB",
    "17348.pdf": "7D6AD2B7-CE83-4D59-8549-02A97450BB10",
    "17350.pdf": "10D4D5D4-C209-48EE-AB6D-AA2145A3EAF9",
    "17351.pdf": "3E67DDCC-456E-4B56-B8C8-E2CE291F6B24",
    "17352.pdf": "4A7D0532-53EE-4C9D-B038-3FA157A1F0C0",
    "17354.pdf": "C227C500-7E0D-4A93-B351-B1586494BF80",
    "17355.pdf": "3AA80FD8-15D6-44ED-ABCF-C2428A5C67F7",
    "17356.pdf": "1F83F7F7-C554-48D4-91ED-B9A82115CF6E",
    "17357.pdf": "3186F510-8B18-4314-85E8-EF9DD2D033BA",
    "17366.pdf": "C378E429-4FAE-4E2B-900B-D8E7D6BADBB2",
    "17368.pdf": "9AC860A2-FE66-41CA-80ED-1F0193D1CE19",
    "17372.pdf": "68DA42CA-1ED2-4914-9C89-48697FBDAFE8",
    "17373.pdf": "3E026095-248D-46DD-9094-2FEDEC692D6A",
    "17374.pdf": "88DC1A9C-ADBD-443E-B5D9-4A2A149C0300",
    "17376.pdf": "CEAA63CF-6279-4F64-811F-E8E2FEC2D047",
    "17378.pdf": "19C1DB9B-9085-4B99-AD3F-DCD229573D82",
    "17379.pdf": "ADDC7037-5AD8-4432-9D72-B5DB01862EF4",
    "17380.pdf": "4B0B92C1-F6E2-4F79-B5D7-E083C48F4D4C",
    "17381.pdf": "38470C94-0ADD-4450-BEE8-D5C52FFB58EC",
    "17383.pdf": "A42D3AFD-9BA7-45C0-A7A4-C758ACD4D382",
    "17384.pdf": "8CF68286-7E79-47DB-A503-4B65D0C5C1B1",
    "17386.pdf": "DA2F87D8-C843-4184-BBA7-4CCE2C1AA72B",
    "17390.pdf": "44B97579-2CAB-4D40-AF05-0F237584DDC6",
    "17391.pdf": "8C3F8C76-D678-4D74-8AEA-8BE1209C686E",
    "17392.pdf": "676CC4C5-6DFD-425E-B82B-793489FEB8C6",
    "17394.pdf": "68E9F862-FCEE-4CAC-86B1-80E7EC7E02D7",
    "17395.pdf": "56054005-EA04-4C24-8BDF-67C3CCCC1CCF",
    "17396.pdf": "E0264D4C-9BD0-4478-AD16-ABF821F12208",
    "17397.pdf": "E9FBC0FF-139F-4355-9BA1-3F8901DBE098",
    "17398.pdf": "5C6C3EC4-4107-4B67-BEC8-22B9967091D6",
    "17400.pdf": "B25A9974-B356-4F76-B21C-15DF1AA97E70",
    "17401.pdf": "B91BD2DA-1DFD-439C-AAF9-4142031282B3",
    "17402.pdf": "AAD71D31-3E43-4BF8-8CCC-4705F62A8207",
    "17403.pdf": "B8972CD3-24CE-4EFB-AD44-A3F235B172B1",
    "17404.pdf": "8E562D40-008C-4B0B-B229-7F18DBCDD79D",
    "17405.pdf": "9014A366-3AFF-4D8D-BFB7-0598EA1BB0A4",
    "17406.pdf": "09EB6A1F-6D32-4D2E-87C4-B262660141B6",
    "17408.pdf": "DD0FFFBA-6281-4175-9093-59E15AB082F2",
    "17409.pdf": "0AB1C79C-14C2-4E2B-8487-FBF9F7F90993",
    "17411.pdf": "1A8B8AA1-E7EB-49BF-B939-62F8FC72EA9E",
    "17414.pdf": "371CB0AC-25F8-488F-A979-7088C5C3A158",
    "17417.pdf": "24E6D487-8F61-4453-85D4-03C66EAD890D",
    "17418.pdf": "BB4F255C-7F6D-4E54-8427-F6CD226ACC86",
    "17419.pdf": "B31EB721-FF5F-4F6A-8BF0-4A820A239B37",
    "17422.pdf": "AFC732E9-BFCE-4137-AB5C-5A38435DD62D",
    "17423.pdf": "154F41AD-9C03-4F3A-BD76-09D5F54226FD",
    "17425.pdf": "6E3A7AB3-8DDD-44CA-AA97-810BDD3A4D8C",
    "17426.pdf": "E2C8CEC9-5894-43B0-8CB1-10993B8463DA",
    "17427.pdf": "E2741BD4-B3E0-4E52-A95A-25585D52496C",
    "17429.pdf": "1C437972-BAA4-44C8-9FE0-CFFB765E0D5A",
    "17430.pdf": "3F72BC29-E012-4DF4-B061-FBCCEAFE8FBB",
    "17432.pdf": "E691AE94-F05D-4F0F-9782-C273EE27FA99",
    "17434.pdf": "27CA7B62-CA31-429E-9BF9-10DF6EAA8430",
    "17435.pdf": "C5734CE3-5949-4082-96AA-34E445AF42B6",
    "17436.pdf": "FA5AC9EC-D1D3-4BCE-9ADB-87A120ADAE94",
    "17437.pdf": "A7C7D4B8-A590-4FFD-A3B7-0761A45697BC",
    "17438.pdf": "A17AE06D-B98E-49B9-9A04-E63F136E76A8",
    "17439.pdf": "B8B42617-CA8E-4A75-98A5-FEF5FC9FAAFA",
    "17441.pdf": "2C81BA03-D17A-4D1B-B8C1-E1D90DC866D3",
    "17442.pdf": "AC1DA86F-6AC0-473A-B364-D7FFFBDB1FB3",
    "17443.pdf": "8C2279AD-1289-4CE2-8E4B-C56FA5290084",
    "17444.pdf": "C609864B-7A12-48CA-8633-3848AF4BF836",
    "17446.pdf": "B04AD616-DF1D-4AD3-9211-192F803B860B",
    "17448.pdf": "CEB89930-E17C-42E4-9F72-A386E566E403",
    "17449.pdf": "8AE013AF-00F8-4934-AE66-D646460BBC34",
    "17450.pdf": "AD6E740C-9D43-4A9A-8B79-150C251CE6A9",
    "17451.pdf": "F82F2A6D-8C17-4C61-AC0D-90594E50DFB5",
    "17452.pdf": "EC7B167E-2457-464B-B144-61A3B3022AFB",
    "17453.pdf": "2E00A811-2539-4B4E-BF63-6E41D7E02CC3",
    "17454.pdf": "90676BE9-B00C-4F19-ACFE-0752333C24AE",
    "17455.pdf": "1715C700-801E-44BC-ACED-2769A101B06D",
    "17458.pdf": "F7D676D4-EA42-4C4D-A250-DAAE65C6F1BA",
    "17460.pdf": "492FFD59-7557-43A5-A7FF-D2BB07DB2679",
    "17461.pdf": "4EC6A795-99F2-453D-870C-FAE60531F91C",
    "17466.pdf": "85E1AC52-09DB-4425-9D35-4D20829DB10C",
    "17467.pdf": "8B71152C-AE56-46F8-A050-790C9AB74918",
    "17468.pdf": "B02E6FD5-3F57-4870-A3EA-6E9AC3810381",
    "17469.pdf": "BA43A8F8-5A08-41C3-913E-C4DDE04F4A01",
    "17471.pdf": "42F4D135-FD77-4656-827F-AB3613297BB1",
    "17473.pdf": "E0B63728-26A3-4087-B040-AAFC6702A048",
    "17474.pdf": "756C25B2-C5D2-4B62-8023-0781AE5EB7E1",
    "17478.pdf": "53ECBF0B-2137-45B5-AD92-34F5C91631EA",
    "17481.pdf": "077B9C90-10D1-4F53-A68D-48C6A128FCDC",
    "17482.pdf": "BF4A70D5-7996-49E8-9818-36949A3B5FA7",
    "17486.pdf": "CC498ECD-0025-4E2B-824D-3A773CB48CBB",
    "17490.pdf": "A75FCCCA-EA00-468B-A74A-208CDE18ED2D",
    "17492.pdf": "7A94C386-9614-4EC6-9C4C-059D9B0F74B4",
    "17494.pdf": "7376FF24-396F-46C4-A072-BFFCFA4B5025",
    "17495.pdf": "AF2E9923-5DB3-4FDB-835C-96FC6EE31A29",
    "17496.pdf": "D5EAF51B-2BCC-4E64-A82A-87C53F762E51",
    "17499.pdf": "980CB436-CB88-4C05-8E48-C5A0C17AAF7A",
    "17502.pdf": "B5771396-E7F6-4D3E-BAFE-675AA5B7282D",
    "17504.pdf": "5F5C04AC-BE92-44EA-AE9C-29B1D5E2A6AD",
    "17505.pdf": "1E4E1B39-14E3-4EE6-8E9C-2365716F12FF",
    "17506.pdf": "CAA4C162-250E-4C8E-8408-A8A415347CAE",
    "17507.pdf": "11D80E93-8114-481C-9D20-1356CBA2362A",
    "17509.pdf": "B339DFE2-3B48-475F-B0F0-5DE027FF95BD",
    "17510.pdf": "36795E76-2D3D-4945-99FD-46D4B89D0A57",
    "17512.pdf": "19DC71D4-1D07-44E4-9CBA-9DCFF4FB323F",
    "17516.pdf": "4A671F5C-ED0C-4E0D-86A3-565CD8D85E18",
    "17517.pdf": "57C6804B-60A9-413D-AB17-9C677B50AE46",
    "17518.pdf": "6BDFDB4B-3CC1-42EA-A363-AF6D20FCDCBC",
    "17519.pdf": "D8990710-7FF9-43F9-B60A-D5A8463F19E9",
    "17520.pdf": "C31DFFB1-B848-4287-BC1B-29A6B311CE31",
    "17521.pdf": "EBA98710-7108-4898-80D6-939902C4ECDC",
    "17522.pdf": "DB85AB63-492C-4477-84F2-66AFDA352B1E",
    "17524.pdf": "CDE102F1-114C-40A4-BC0C-FFDC0F5BEF0E",
    "17525.pdf": "063B6E49-5BD9-458F-BF74-21321E780B80",
    "17526.pdf": "68290DB6-401E-4639-A860-46997D93D674",
    "17528.pdf": "ABBF9960-FB7F-4DA2-80A9-122A447ED4C6",
    "17530.pdf": "6606507E-0362-4B15-A83B-8B5262CD9888",
    "17532.pdf": "DE8FD817-D10B-4A2A-96FC-A3FF121E9539",
    "17533.pdf": "A6BBE781-53A5-47FF-A771-F66660C21B7F",
    "17535.pdf": "805EAD84-0FBF-490C-8278-9AA4EEEAAD1C",
    "17537.pdf": "CB64778C-D4E3-4DF5-9580-DA01415C1A7E",
    "17538.pdf": "A4ACFC3D-440E-44AC-9854-22620F4AFE86",
    "17539.pdf": "00A3CB2B-D381-4D82-A4C1-4C95338561B7",
    "17540.pdf": "286A4047-D7C6-4948-976D-A3A70289233E",
    "17542.pdf": "408A2F39-80F7-4214-8C53-E1E41DDCE06C",
    "17543.pdf": "DA39186C-215B-47F6-97FF-EE532FB9246F",
    "17545.pdf": "CFBA1007-0FF9-4D23-8A1C-1E3CF631E618",
    "17547.pdf": "2BDA96C4-EB5D-4451-AE83-0FF72BEE0CCF",
    "17549.pdf": "BC000281-B6DF-4B4F-A98D-49172165A6E5",
    "17551.pdf": "C636D998-BF77-44EB-9603-9E58D536EBBA",
    "17553.pdf": "66BA1C42-77E3-4CE8-BDB3-25CF79EB5227",
    "17554.pdf": "CFD774B7-2994-4F53-B2BB-601F2B7EDE8C",
    "17555.pdf": "B79AE8C2-E3A5-4386-B0B9-D89A5D9DB909",
    "17556.pdf": "287980FE-1E86-40A0-9065-B120EE94CF1A",
    "17557.pdf": "9CBE0E23-F377-4B88-B122-B558254F5296",
    "17558.pdf": "91D4A845-4F33-473B-8517-070C384C156A",
    "17559.pdf": "BCEBD5FB-B645-48D0-86D1-8F03A85BAB72",
    "17560.pdf": "85DA1562-B730-490D-BB04-CFDD784815FB",
    "17561.pdf": "3B1D199D-B65D-4144-9AEE-152E3D527DCE",
    "17566.pdf": "94CF9A23-9A6E-4F63-9C8D-30CDEAC1E620",
    "17569.pdf": "73CAD5B6-1907-4E76-9A2A-AE81C01BFA26",
    "17570.pdf": "18301D06-8BEC-4E0B-980F-76ED5B27125F",
    "17571.pdf": "6DE2231D-E7A4-44BC-A306-A4669B88BB01",
    "17574.pdf": "00D3CACD-F87F-4046-9BFE-B73B89F20723",
    "17575.pdf": "E7E6EAEC-ACB2-4244-B823-5C6503BB83EB",
    "17576.pdf": "9E137B9C-58FD-4A7E-AF7D-A6A8DBC32656",
    "17577.pdf": "9942A1D2-A6D5-4829-91FE-AEB3CAF975DF",
    "17578.pdf": "117589A5-2561-4786-8040-14B95F7E1795",
    "17580.pdf": "419162CB-4D33-40C7-B308-3C7177C9E65E",
    "17582.pdf": "98231141-2559-48DA-B797-1EDE6DE10A08",
    "17583.pdf": "7846E1CB-4D1C-4EF5-9620-00D2A3572735",
    "17584.pdf": "2C0F7C3D-A3FD-4E86-91AA-9AA06A08C5F0",
    "17586.pdf": "9621D8AE-A5CD-49B0-9CE2-EC66481E38DA",
    "17588.pdf": "5F92E2ED-3349-44D0-88F8-BE138B3E034B",
    "17589.pdf": "24C2DA78-206D-40A5-B218-06D7CA5976CF",
    "17590.pdf": "EAB582E2-4224-4D8C-BABA-BD8AFC1519A7",
    "17591.pdf": "69734AFE-A85E-4725-9F02-E7AB27E1F0C4",
    "17593.pdf": "EC2CED2A-9D5C-425F-8D5C-EE5CD5EDEE0D",
    "17594.pdf": "643A1288-CD19-4598-A148-1841236A68FB",
    "17595.pdf": "1FBEDCC3-F173-4352-82A3-28DFCFC4F5FD",
    "17596.pdf": "B8598159-2528-42B8-97D9-0ACE5C10E10F",
    "17598.pdf": "971194F7-2D1D-47DD-804A-852DBCE791A4",
    "17601.pdf": "9EA09049-FF00-48C3-A923-F8A39674A536",
    "17602.pdf": "A4F1BD33-D688-47A0-818C-8ECDE1E2A329",
    "17603.pdf": "72590121-1CFA-4967-B1B4-7316D90C85C5",
    "17604.pdf": "24C3E5E0-0959-41B5-B214-E7283E071CFD",
    "17605.pdf": "C477B017-5418-4927-B0E2-9531F3DE321D",
    "17606.pdf": "D8E531F3-5753-4732-B5E7-A40A481513B0",
    "17607.pdf": "27276142-4CF0-4817-BDBF-AC1F82080C72",
    "17610.pdf": "78515B3A-B985-431B-B3B6-020B944BE103",
    "17612.pdf": "AF821F2E-2BF3-4790-A308-B712F584FDCD",
    "17613.pdf": "A993A9D1-CFB4-41EA-9E2B-1E73F62F256C",
    "17614.pdf": "813B1856-1921-4E61-B25F-68D03FF9A2BF",
    "17615.pdf": "6592D064-257F-418B-8C99-7B7CE0E2D544",
    "17616.pdf": "98D935C4-6CDB-4E60-A30B-7286FC58A471",
    "17617.pdf": "6F0A3F50-E493-4A24-94B0-D024CF4F391C",
    "17618.pdf": "E285A68B-EE31-48E7-8EE7-ED656BBA1459",
    "17619.pdf": "0D4E0F05-3952-4A4E-BE80-034FBF4A7032",
    "17620.pdf": "020B789C-53AD-45F3-A804-C62EFA8CC8E9",
    "17623.pdf": "8A45BC2D-4B8F-4930-8291-A45107842A24",
    "17626.pdf": "6B093A6E-6504-4C46-9EAC-504DA329AF2B",
    "17628.pdf": "5F4A4AEF-BD1C-4729-93A7-0AF3B4900888",
    "17629.pdf": "8023CA9D-EDC9-4D1B-B34D-0BB423AC3191",
    "17630.pdf": "060049FF-0027-4D30-AFBE-76CF7EDC24D0",
    "17631.pdf": "11CEF1C7-8918-43D1-B19F-13A8166079AA",
    "17632.pdf": "B6DCCEF6-36D0-4262-86E0-5AABC71D1827",
    "17633.pdf": "C7E529D5-C26B-4F73-B7C6-A66EB9CA5CF5",
    "17634.pdf": "132F6DED-84A1-4D3B-AA78-95ED870CC9AF",
    "17635.pdf": "E5B9DB53-097D-426A-A670-46E8ECEFB182",
    "17636.pdf": "B553A276-5A64-404F-9011-B1AB6F32C805",
    "17637.pdf": "919C4BA7-A808-438B-B9B9-3F35313EB08D",
    "17638.pdf": "97A939EE-9E56-4DF9-B8B3-4CEBA9E9DDD2",
    "17640.pdf": "BBC6B0E4-0E59-4BAB-8B7A-63D18039319F",
    "17641.pdf": "30525E5C-EBCC-4188-B279-DFDE02DDF772",
    "17643.pdf": "0BAA9278-F1CF-4E43-9B1F-A5BDD2D85AC1",
    "17644.pdf": "993B0644-95B2-41E3-B5A7-E3FB2769B5B1",
    "17645.pdf": "37C36A19-830B-4CB2-AC99-C44842D60F26",
    "17646.pdf": "98ED81AB-8B22-49E2-96A9-274CA65FE211",
    "17647.pdf": "883BBED7-0008-46FC-960E-0B9C33F0A4EE",
    "17648.pdf": "2D4E05BE-C39B-4CEE-9790-52AC710A6539",
    "17649.pdf": "B3092C61-3651-4436-B75F-FB62D4991155",
    "17650.pdf": "1297B8FC-A187-4A79-BB8C-5CD9F24243EE",
    "17651.pdf": "1DC96798-2B0F-4F92-A8E1-96D63C266816",
    "17652.pdf": "B1C23F9A-B3D3-46BB-89F3-4962B15DF94C",
    "17655.pdf": "67880289-7FF6-4C47-90CD-44C92AEAA578",
    "17656.pdf": "3B8F6A87-1A62-4381-AC9A-7343A180E391",
    "17657.pdf": "F3E81528-8FB5-4E59-A966-7835993EA549",
    "17658.pdf": "47103FD8-DEAD-464A-93C6-19F1FD4A742E",
    "17659.pdf": "F1D8528E-9043-4F8A-8457-23E901FD9022",
    "17660.pdf": "2B790CE0-AD8F-4886-B776-438DCA119A09",
    "17661.pdf": "990C1B9F-ED29-451B-BDB0-FC3A2849B836",
    "17664.pdf": "80DF86BA-4BA7-49A1-85DA-DC8015E7B8DC",
    "17666.pdf": "E1485D79-BCC1-44D6-A064-5CB1EDB03782",
    "17667.pdf": "B078B00A-9DEE-43EA-8390-F6473DA879DC",
    "17669.pdf": "19A6E35F-B9CB-49C9-821D-E36188D66055",
    "17670.pdf": "17767742-2DE5-4154-83EB-B4F069235106",
    "17671.pdf": "DAA65B56-CEA4-4F6D-AE6E-48EA15171184",
    "17672.pdf": "18260EE9-D248-4F90-89BD-51F5C07209CB",
    "17673.pdf": "0251EFA9-7ADE-42C5-BE26-8091D5B54C9E",
    "17674.pdf": "2556A5F6-F117-4DE2-A7BB-7C265351D08A",
    "17675.pdf": "5067575F-7664-4A47-815C-B7F772925348",
    "17677.pdf": "97A01C59-1CA6-4DFD-926C-78D026882860",
    "17679.pdf": "27EC2816-EFEE-408B-92C4-9870C39B646A",
    "17680.pdf": "3EEF05A6-13BE-448E-8141-1C69F99607DE",
    "17681.pdf": "41D002F0-47E9-495A-8B17-4094DF8E36EE",
    "17682.pdf": "CCF38AD3-F712-4827-A44C-8B37F837CD20",
    "17684.pdf": "5AB456BF-4E1C-452C-A37C-BE9BE1E2A0A7",
    "17685.pdf": "142F6BAC-6D35-492D-8852-FD97F7CE1F9D",
    "17687.pdf": "9DDEEB17-3802-4A30-A776-0DE52F4A6493",
    "17688.pdf": "C08A1F0F-B25F-4914-B374-4E58F2D2FFF0",
    "17689.pdf": "0D76AA65-840E-45E1-91B0-1155664D2485",
    "17690.pdf": "0513C833-75E9-4CDC-8768-A79D1BC3D479",
    "17691.pdf": "E4108377-3630-4EDE-9708-A2880C9C0350",
    "17693.pdf": "85FAAF46-BC16-477F-9A0F-0D0F2BD883A7",
    "17694.pdf": "702F3799-2EB4-4547-9425-D5FDCE62E27A",
    "17696.pdf": "D146101A-58A5-4262-B9DB-5ABA11E4A9B0",
    "17697.pdf": "53E4842A-F151-4687-AD60-37E16557B681",
    "17698.pdf": "1CF8AD2A-D8A1-4614-914F-1D5B00660B08",
    "17700.pdf": "6EECBAC0-6383-465F-8220-0D65213B3009",
    "17701.pdf": "9493255C-0BD6-4F13-99C9-692E4CC2AA3F",
    "17702.pdf": "703BB48D-B341-4DE0-BFD0-C4C30DC113E6",
    "17703.pdf": "F329CA8B-69D4-49C8-91CD-27DA6DE9B802",
    "17705.pdf": "72435901-13B5-4A2B-8030-2B1E5951D736",
    "17706.pdf": "B619C3D3-2F6C-407E-A8ED-063684856729",
    "17709.pdf": "13FE93AC-7724-40B2-BB87-3EC5ACF5E1A2",
    "1771_PROGRAM.PDF": "5BC088C5-30CE-48C8-A049-B8FEB74AE2E3",
    "17711.pdf": "1BB74FF2-AE75-467F-A637-4C50F3CBF845",
    "17712.pdf": "884A3EE0-EF8D-4FCD-A482-BA9697D77E8C",
    "17714.pdf": "24278435-0EEB-4E50-B73C-B64D775183D8",
    "17715.pdf": "C47B8943-9EB3-4645-A15E-9D6DFFD6D518",
    "17716.pdf": "253CDAEF-6C70-442D-863E-C0B39DD09D0F",
    "17717.pdf": "3644551C-0F4F-4167-BC68-250450C86095",
    "17718.pdf": "C0C7315E-34B2-44E6-9E67-123D945AB71C",
    "17719.pdf": "82166856-4131-441F-B065-ECFB59AA3B3D",
    "17720.pdf": "21A85F53-6926-4E79-AF6E-4AC89248CB4F",
    "17722.pdf": "0A413A3A-3605-47BC-81C6-D636BF67D478",
    "17725.pdf": "BB3E855A-876F-4565-800E-6F21EEECE4F2",
    "17726.pdf": "C058F267-1FE6-40FE-A002-B42DF8845341",
    "17729.pdf": "1AC9FB8C-C9DD-4480-A996-73B6E01B568D",
    "17730.pdf": "A9F67842-042C-4B0C-8614-14AF86C39552",
    "17732.pdf": "A2ED7D8F-1366-45C5-AFD9-53945F475F28",
    "17733.pdf": "94475594-ED6A-49CC-817F-9AD6FFCA7809",
    "17734.pdf": "227AC5A5-8E75-4427-8E03-7073B2AE6D62",
    "17735.pdf": "B6AFF345-9A7E-43DF-876B-59AD4C53F07B",
    "17739.pdf": "51353292-C5D9-43BC-93DB-28A2CF4F56EF",
    "17740.pdf": "734FF407-FD4B-4076-AE3D-D013B452940B",
    "17742.pdf": "E23FD405-049A-4AAB-AE68-F2D2A5C9A2F5",
    "17743.pdf": "A3EDD64F-AFDD-465F-A119-D0E769FA2101",
    "17744.pdf": "BF470E5F-E939-4E34-9B88-CAD8BE5FAFD2",
    "17745.pdf": "72ACA722-0825-4367-9069-50587C3F1408",
    "17746.pdf": "0659C059-8C3E-4A58-8396-C7617158A64B",
    "17749.pdf": "E02CE4FD-EF19-4C8B-BCC0-D3A2C9513051",
    "17750.pdf": "A44EB4FF-AB50-4736-80B3-67BD46549B8C",
    "17752.pdf": "6AF93BCD-4578-4A9A-A022-099A6D1C111D",
    "17754.pdf": "FD585B3F-18FE-411A-833B-5841728512D4",
    "17755.pdf": "93BDC455-1457-43D0-9C6C-B821E44E731B",
    "17758.pdf": "AC6848FF-3AB2-4F81-91D5-34B5F02E8300",
    "17759.pdf": "4E79C4C6-5693-4C54-9A80-D69FD84A08CD",
    "17761.pdf": "B72A89E1-1ACF-4FA1-A8BE-5551BF02051D",
    "17762.pdf": "ECD3383F-42A6-4D03-B1FA-94C1D0F06CE0",
    "17765.pdf": "381E5393-3A63-4057-BB80-1E441B911A8A",
    "17766.pdf": "6F2A2214-B5AD-4EF3-9650-D976D15D197F",
    "17767.pdf": "605708B2-5351-4003-BB7B-4A48F955B57B",
    "17768.pdf": "D26CD14E-E0AE-4327-8504-109F89FFD0AF",
    "17769.pdf": "4EE36F8A-2E0D-4560-93B2-21A7D4EF7CC4",
    "17773.pdf": "F783BD7F-B21E-480C-BE25-B623F19B6547",
    "17775.pdf": "38A13796-5C83-4183-9B3E-FC6492F9C95F",
    "17776.pdf": "4F4D6F97-F49F-4875-A587-DD05504AC449",
    "17780.pdf": "DCFEC9F4-6BF8-4CF5-BD94-1925CAED6053",
    "17781.pdf": "7D35B797-F9C4-44B6-B93C-1C8B12E05997",
    "17782.pdf": "70C880A4-E744-4B6B-B08E-6859CD67A6A2",
    "17783.pdf": "0E4B3E48-8F79-4738-87BA-2BA722B612A6",
    "17784.pdf": "255DD08C-8DDA-4658-ADA3-61689891E4A8",
    "17785.pdf": "08D503AE-DDD0-4194-A849-84DB30A29C2E",
    "17790.pdf": "DF4D0213-DCD8-4CA7-8F94-177042527403",
    "17791.pdf": "D25ADCE3-3235-4EA0-B708-3C3C1F06E2FB",
    "17792.pdf": "969B120C-C65C-4170-B09F-4E78D498AA9B",
    "17793.pdf": "7348BD28-B2FE-4A7F-9EC1-F554D4FB6C5F",
    "17794.pdf": "FFB26A12-4FA2-4F2D-9B13-21A38ED0D727",
    "17796.pdf": "2D224CC8-C83D-4CCB-9AAE-2CC71AC437CE",
    "17799.pdf": "CA0C9C13-3276-4E19-8D41-65CFD86EDE5D",
    "17801.pdf": "BF6CDC31-8394-4692-B62A-A83874A9DD58",
    "17803.pdf": "B60F52F4-40E4-4C60-975C-C8098FE36182",
    "17804.pdf": "E1F7DFFD-A65E-489E-8C92-EF534C9B696B",
    "17805.pdf": "F550F17C-19E4-4688-B195-0EF2EFB04488",
    "17806.pdf": "3198285A-8408-47F3-81C6-BCBE96FEC323",
    "17808.pdf": "A68A0917-B90E-4745-BEDB-447939951B1B",
    "17809.pdf": "550FFA8F-9050-4FD5-83A4-95F3528DE70D",
    "17812.pdf": "2623E19D-DA6A-4459-AB00-C6C400EAE814",
    "17813.pdf": "155AC71F-D476-434F-8911-2C607B108FBA",
    "17815.pdf": "C397804F-0690-4E54-AFD0-74E092CC5061",
    "17816.pdf": "0896CF16-D048-4E27-9578-351DB32C0A0D",
    "17817.pdf": "B6CEE84B-F90F-4C9B-8215-F0092665F62F",
    "17818.pdf": "8EDD0351-8BDC-48D6-83B0-06411FF5A3DA",
    "17820.pdf": "4152EDA9-1A8C-403E-8544-B8B9D2EBE7B6",
    "17822.pdf": "67C862ED-05F5-4258-87F2-214FAB4AE0FD",
    "17823.pdf": "BEF85863-CDBA-4953-85E3-A51BA7877E93",
    "17825.pdf": "202BCAD1-3B6B-48C0-8744-F877CA2C3C6F",
    "17828.pdf": "4E54BE04-6941-4343-B44F-2D6EAFA054C7",
    "17830.pdf": "8A1231DE-2D96-4EB6-B96B-42BEB59429A1",
    "17832.pdf": "54BDC333-D556-420D-AF70-D09D63A46B13",
    "17833.pdf": "A7FC8295-709C-4B81-AD57-D645E75A35FD",
    "17835.pdf": "937D1D44-45F6-4142-934D-3F1932465E76",
    "17836.pdf": "0EBEF7B6-05C8-4F72-A3A9-8525FBCA8551",
    "17837.pdf": "7A1FFAD7-C8B6-4B0A-90D8-E9BABD5F3155",
    "17839.pdf": "3DB8154F-61A9-4D9A-897A-68E5ECE028E6",
    "17840.pdf": "8156A086-2B23-4E3A-9819-14F50F62A1D4",
    "17841.pdf": "0B81288C-D998-4E9F-AC38-C11CE9A16777",
    "17842.pdf": "4897A14B-EA97-4D11-9DA2-D6367A57D7DF",
    "17843.pdf": "5802629B-194E-4B71-8505-60A5A5C05BA6",
    "17844.pdf": "5FD90003-010D-47B3-AB5A-EE6AEB677A99",
    "17849.pdf": "DFDC2BB2-9385-4364-9968-34A2E12690AE",
    "17850.pdf": "49BDC97B-1F99-4C9D-9773-FE6653F0419F",
    "17851.pdf": "91172C62-362E-4CB7-8EE2-6E982D782D21",
    "17852.pdf": "71E9400F-EC6F-4273-9B3D-21FBA6F407B8",
    "17854.pdf": "E30374C8-E07F-435D-850D-6254B4226B6C",
    "17856.pdf": "C1BADDA0-C40C-4472-AC1C-27C256A07864",
    "17857.pdf": "0DCD5613-5129-4CAB-A9D3-D78AE7A8D4A8",
    "17858.pdf": "558CBEB6-DFC4-407D-B810-39CC9685C1FD",
    "17859.pdf": "D5E932B6-C4A0-417B-8ACF-1B3137C9E441",
    "1786_PROGRAM.PDF": "6E971F88-430A-4BDC-B626-3858C61FAF2E",
    "17862.pdf": "2A3A88CD-889B-43FE-A7FC-EDE58D023C64",
    "17864.pdf": "0F234448-A176-4D27-A4BB-04DACA5C7427",
    "17866.pdf": "04352778-17CC-444E-A013-E049FD1A630C",
    "17867.pdf": "B80858A3-FB2A-43DF-A1E9-BAB130EDCDA7",
    "17868.pdf": "9237AEB6-7924-427A-B7CA-3094B546D167",
    "17870.pdf": "6CD0D2CB-866E-4FF3-9C11-B7E3576C13E1",
    "17873.pdf": "674315FF-B5D0-4106-89C9-1EE8F079A17E",
    "17875.pdf": "9A29CBC4-01F4-4177-AF81-F6D363405EF6",
    "17878.pdf": "531C19EB-D180-4DB7-A192-6DB375F29953",
    "17881.pdf": "70F31671-C411-4A9D-B6D1-C2E07DBB937F",
    "17882.pdf": "924133F7-5F35-47C1-BCF9-BBBC6BD666D7",
    "17883.pdf": "9DBA8752-1327-43E7-A3ED-4D61EFBC5BED",
    "17885.pdf": "E7E6B0E8-D223-46CE-B4C2-53D8BA3A079B",
    "17889.pdf": "DBC7CA6F-33EA-4997-B8DE-53AE331C599F",
    "17890.pdf": "1C9EAF5B-FFF7-474D-963E-82AAD34CE06B",
    "17893.pdf": "DF50AAB6-DEB4-4F9D-B4C1-FF065B66635A",
    "17894.pdf": "685332E1-994B-4EC9-AAA3-553F20B213C8",
    "17896.pdf": "6CCD2D65-044E-480E-B69E-BAFAD7F849A5",
    "17899.pdf": "7625473B-7E3D-4216-8CAB-94B0DC8131A2",
    "17901.pdf": "9447E5F4-D856-44C5-B42B-DBFFDCA946E4",
    "17904.pdf": "476CBEB5-8632-47F9-86E0-4895E9B43BB3",
    "17905.pdf": "74DB52EE-4EB4-4584-B897-46D9D4790BAD",
    "17906.pdf": "F624BD82-C2F8-4E84-A647-DF132DE20CAE",
    "17908.pdf": "1165C47C-2356-436A-B31A-B256E40F65A5",
    "17910.pdf": "E8F14A14-D6B9-43E2-A38A-54F82ECCA097",
    "17912.pdf": "234E751C-3B88-4FFA-B6E1-338937AEFD3B",
    "17914.pdf": "0A05CACD-1583-4E93-9C3F-3784D22F489F",
    "17917.pdf": "58E9A197-F0BB-4D34-B81F-6CCCE854B7CA",
    "17918.pdf": "40B0FF63-02FE-48FF-B35B-3357CE95054F",
    "17919.pdf": "6689764F-C20D-4AC7-A5D9-BA81D3A47E5A",
    "17921.pdf": "5596B2F8-8296-426A-BC0C-AD6A75B3E115",
    "17922.pdf": "D63CD036-4655-4D67-95A3-9B0A7E95F026",
    "17923.pdf": "4F453EC1-E8FD-4F9C-96C5-4CDCE49FB181",
    "17924.pdf": "15CC2949-DC02-4680-BA71-57A0B9726A60",
    "17926.pdf": "8BFA603F-2869-4234-BFCA-B6CD24CCACF2",
    "17927.pdf": "364167E6-C06D-43CE-914E-9F144B052E4E",
    "17928.pdf": "2C374DAA-886E-40BC-AC94-99BE23801C0C",
    "17929.pdf": "6B6AD80F-3A34-4F60-B4B1-DD6AD4D57EB5",
    "17930.pdf": "2732A6E1-53BA-4EDA-8F0F-14E1C7918A2B",
    "17932.pdf": "6C8C1C08-BC16-4AB0-9413-03A66E2D1C35",
    "17933.pdf": "0351BF3F-5DC8-498B-9A85-8C805D6AE7D6",
    "17934.pdf": "4F846730-78CD-4FC2-8A50-3F1C856F71A6",
    "17935.pdf": "BB573826-430C-4E8B-AC81-7A34175E06E1",
    "17937.pdf": "462E81A3-38D5-44DF-B139-E93284D2CD76",
    "17938.pdf": "18518B51-A1B3-413E-9C7F-87CB82EF6532",
    "17939.pdf": "742A81E7-6C61-4728-9393-0648F8816888",
    "17941.pdf": "33338375-1970-49EE-ACD8-87F126800180",
    "17942.pdf": "B745E6DB-FAED-417E-BB82-4D91072D6117",
    "17946.pdf": "FF566029-620F-43B5-9BFD-25CCE23C3B63",
    "17948.pdf": "C942C613-FB16-47A1-8676-E453C98D8CDC",
    "17952.pdf": "7259AEE3-EE50-4317-917F-662291720B43",
    "17955.pdf": "D97ED4FA-8D9B-4825-B849-5C5EEE7B847E",
    "17958.pdf": "FB0DE09A-58BA-40F8-A570-0E5561D0864C",
    "17959.pdf": "F1297327-7BB6-4495-8C65-B8B73D398533",
    "17961.pdf": "94EC0808-0007-4F4E-BE9D-20506F6A711A",
    "17964.pdf": "5026830A-B23C-4544-B525-83763D18051C",
    "17968.pdf": "8401A3BE-6E5D-478F-8B1A-248A3DFECEC6",
    "17970.pdf": "2EBB3FD5-124B-43E8-9240-D4FB26BE8C13",
    "17972.pdf": "FC93459C-0388-4338-834A-82971BB479D5",
    "17973.pdf": "03AB022D-7C5F-4671-8E51-DA86B445A2D4",
    "17975.pdf": "13570A7A-CF4B-4688-8090-36AA6088AF0F",
    "17978.pdf": "A7552775-4323-4300-86B1-86BDA76BDD0D",
    "17979.pdf": "BCFCD7F6-49AC-4FEE-9A84-C3CB962D45A7",
    "17980.pdf": "EC872A0E-8A9D-4CDC-89EB-0D1834B7BE9C",
    "17981.pdf": "B12A3BF0-D241-4491-AF32-080218A6369A",
    "17983.pdf": "16D53341-E1F4-4BF5-BDB7-A2F4DE1DA588",
    "17984.pdf": "C2A03F48-6D90-4270-AD41-2D4FED19A831",
    "17986.pdf": "07AD6FAC-F9FF-4FD2-8897-E427A6EE140C",
    "17988.pdf": "B8975578-7DFF-4AC9-A399-142FF5251C05",
    "17990.pdf": "67BF8CFC-B64A-4896-A7B0-206F0463C681",
    "17992.pdf": "912D3BBF-0D39-4DAA-8653-5BCE8AAE34CC",
    "17993.pdf": "DAA98A28-8997-41B6-B65E-321F03188FEB",
    "17994.pdf": "404C7198-1607-4E26-B960-FAADEB3F4F07",
    "17995.pdf": "DDD44246-D056-494F-866A-9E7AE381F00A",
    "17996.pdf": "EB53DFD4-39A1-4E26-9F67-555AE4FB37D1",
    "17997.pdf": "F98085A2-6AE5-43BE-A0EF-681BF25ABEC6",
    "17998.pdf": "61247568-F882-4DC5-9CB7-643DAC357710",
    "17AM_Prelim_web_2.pdf": "2FD2D4A6-7DC7-4FD4-A087-2D5D703142EF",
    "18 Diploma miembro de SENC.pdf": "57F3720F-8A82-4736-A87E-357ED64B5DD7",
    "18.pdf": "C72DD801-5EE8-4E86-BA32-F449A063FE6B",
    "18001.pdf": "B006662F-51A2-45D9-9F3C-CC78C4FD798F",
    "18003.pdf": "C236BEED-D3A0-4976-BEAE-D84692070DF2",
    "18004.pdf": "48538BB0-D10F-4BFC-8CBB-4237BCBBBA5D",
    "18005.pdf": "0BDB56C6-46B8-4DD6-8614-7F88B8C51BEE",
    "18007.pdf": "9B674B2C-222A-4736-8643-539B111CF881",
    "18008.pdf": "D38F8438-0B81-4CFD-91FE-13F1861BD07B",
    "18010.pdf": "425E94E4-A63C-4B36-8BE8-89CA8E59D26D",
    "18012.pdf": "7E501330-F1B6-4065-A776-52AC68B211CA",
    "18013.pdf": "6D892498-23A2-43CB-A71C-8C9ADEF404B8",
    "18015.pdf": "6F18DD35-2D68-45B0-9C1B-182E72B1FAE7",
    "18016.pdf": "FD7264F8-44B1-4FA4-BDF5-B79CBB244978",
    "18018.pdf": "5FF96930-0D96-4E98-9C7D-E8A809185691",
    "1802_PROGRAM.PDF": "B3729C30-9D7B-4758-AEDB-283802D6C924",
    "18021.pdf": "624B84C7-BF2D-4D02-B679-0681B7FF4ACA",
    "18022.pdf": "AC217FB2-38BE-4ECE-B4EC-0FB3CFF81480",
    "18023.pdf": "432B31A2-1104-469D-ADF0-9D0490F9176B",
    "18024.pdf": "AC958D55-A272-4CF1-BBA8-D9A210ABD55A",
    "18025.pdf": "BCF7591B-9C09-4F1D-ADBE-9A5459A33D71",
    "18026.pdf": "CB22AA6A-A475-4EB3-AA7E-965B6AD977E9",
    "18027.pdf": "31526E9B-877B-40AA-AB30-B4256A0B35DE",
    "18029.pdf": "CBA04189-0EEC-4874-BE39-95EF22265DF9",
    "18031.pdf": "79AF844F-A27C-4956-B195-96F7AC11378C",
    "18031409_09_4323743[1].pdf": "51957E23-40ED-422A-84F5-BCC5FFAE3729",
    "18038.pdf": "A9C6D2E4-110C-4F5B-ADF0-38FEC22F55BB",
    "18039.pdf": "7C62949F-284D-4DB8-90AF-8AF956A699E2",
    "18040.pdf": "03C4B09C-3CF2-431B-989B-83686195E69D",
    "18042.pdf": "CE9060D9-212B-4611-B67A-03EC43FF9F2E",
    "18045.pdf": "9A6544AA-E474-4181-93FA-B42B146E096B",
    "1805_MEMO.PDF": "0C63D2A3-316F-424B-B4A8-0EF5371929E6",
    "180604curriculum.docx.pdf": "E662BCE7-C2A0-40BA-935E-FAD3878704BF",
    "18073.pdf": "D303DB05-A941-4DB7-BF40-27D64534E8CD",
    "18086.pdf": "47567F43-1708-42BC-8BDA-CCD53763B0F7",
    "1809_sec_forum2.pdf": "EA62B5F2-1F51-4B73-93FA-ED4159C1E25A",
    "18095.pdf": "E0F73D9F-89B6-4350-968D-3CD3AB2CB2E1",
    "18096.pdf": "241C3C76-CE36-499B-B893-EBBCAEAA4E37",
    "18098.pdf": "B06D6D0B-8EEA-4394-A88D-75792B6CAE14",
    "18126.pdf": "EA7E79DF-AE9F-45A3-943D-86D5B987716A",
    "18143.pdf": "AF9088B0-0E79-45F7-B1FC-28A3FE5EAFD4",
    "18171.pdf": "AE232632-B731-470D-B334-65CC9E82C09F",
    "18179.pdf": "0EFBE385-A454-461D-9EE3-C80B2ECF220A",
    "1819_MEMO.PDF": "D4626742-A058-4410-86AB-70E63051AE87",
    "18190.pdf": "58E496E2-B048-40A5-AF7E-5F30683F9576",
    "18218.pdf": "2678089B-54B1-40C6-9ADD-A081BD56749E",
    "18220.pdf": "D74F4B58-8F9C-4998-B2D8-86C8063182A0",
    "18232.pdf": "AA60EAE7-D29E-4270-A697-35DC39AB750A",
    "18233.pdf": "9FAD94EA-269E-4344-93DF-382C79A68F63",
    "18238.pdf": "C4DC91C2-80D6-4158-B257-C693FAADF7F5",
    "18245.pdf": "E23270F3-5CF0-40CD-912A-EB4E8D015DBC",
    "18264.pdf": "297622C1-B708-4C9F-9F3D-0D8A6ADBBE97",
    "18274.pdf": "4184F9D8-57B7-413B-9621-5254BF369CB3",
    "18285.pdf": "7989BC35-0BB0-4B47-91CD-C2753FCD4CF9",
    "18288.pdf": "95BE518A-275D-447B-A796-7E6727A58DE3",
    "18294.pdf": "46439BEA-72F3-4B11-9CF2-138C3E26DE68",
    "18296.pdf": "DEF0CE60-7199-4188-A15E-452B29B726E2",
    "18297.pdf": "78C22488-7646-46F9-B396-B736AD5F9653",
    "18307.pdf": "8E8D6793-5CFD-4F11-A805-0DEB20758662",
    "18341.pdf": "2DEE0FED-A596-4441-B73E-8105F636BFAC",
    "18343.pdf": "0CE06A6C-1F71-4293-8B67-0EE5ABC8E9C7",
    "18346.pdf": "6D23C9E0-B234-47C8-A884-E0D7EF9BF551",
    "18348.pdf": "C6B88EBC-65B2-476E-9831-51A76AA00EA2",
    "18350.pdf": "175A6772-4664-489B-845C-DC4C926AE04E",
    "18360.pdf": "A9241816-F631-4CEE-BDE6-456EFB2AB40B",
    "18365.pdf": "75794A9C-F279-400E-AEF9-D9EC74E40D3B",
    "18368.pdf": "7C81E4EA-5DEC-4737-BD4F-87E594D3D128",
    "18371.pdf": "6BF046D5-9EFC-4DE8-B230-51F34FD6E8E9",
    "18376.pdf": "EF26AE78-0656-4AB4-89E3-8E28F9F6992D",
    "18391.pdf": "C60EAB3C-560E-481B-AB94-DAAF8D540595",
    "18392.pdf": "5A015AAD-5F08-48C0-8350-033E95CB83FC",
    "18396.pdf": "3D0A8141-E6C1-4DE5-8690-9FAEBD45D297",
    "18397.pdf": "337A34D0-EA73-4FA8-B0F9-E6DCFBB3163C",
    "18402.pdf": "DACE80CE-A75B-44CA-B200-8EA9BFC4CCE9",
    "18407.pdf": "38BD2A62-E29F-4C48-AA77-75C675B6C828",
    "18419.pdf": "8B7B1E7C-2CD6-4B0D-93B1-589DA1685329",
    "18420.pdf": "1FB11363-F61B-4A8E-8BC6-417FE92A1851",
    "18422.pdf": "7B71041E-C761-428F-96FE-B7DEEB994AC9",
    "18424.pdf": "AA7214C8-894C-492B-BB08-06D56BBB53D7",
    "18426.pdf": "66AE63F4-9162-46DB-89CD-C6EB657E0BBA",
    "18428.pdf": "5B56C49A-91FA-4541-8AD0-470D4E9BA446",
    "1844_BOOK.PDF": "113A1916-55C6-43F3-BDE3-9D0100D66DFF",
    "18446.pdf": "4594BABB-6044-4A3A-8F01-64C9BEA348E5",
    "1845_NEWSLETTER.PDF": "DE4C2BC0-8752-4471-B8C4-090BC5A35FA1",
    "18450.pdf": "A5434771-30A4-42FC-A5CB-3B100BCEFF50",
    "18451.pdf": "5F25D7E2-F71B-47E0-8265-E1697A840387",
    "18452.pdf": "DD9B0C79-973A-4A09-A270-3A110B9BF5E0",
    "18455.pdf": "E422765E-79A4-431E-9C7C-3CE5DAEB4C31",
    "18457.pdf": "4445102D-505A-4C35-9611-BFCFC7A9C093",
    "1846_MEMO.PDF": "654F1A8C-CC83-4A03-8E1C-634584F51D1A",
    "18460.pdf": "3D524997-8F13-4EFF-B1DE-7225CA7FB25D",
    "18462.pdf": "14D76F31-F864-428C-8C76-E1A861AA4899",
    "18464.pdf": "4B9E61F9-D7BD-4070-B64E-E727CA147171",
    "18465.pdf": "5AADDCC6-7D8C-4197-B937-81DB5402B865",
    "18470.pdf": "441298D9-4C8B-4DBA-8F2E-1B61674647B1",
    "18474.pdf": "E94EDA90-C651-495B-B9CA-A92868103118",
    "18476.pdf": "9B72A762-6092-441D-BFA5-30E2808FA343",
    "18482.pdf": "7A767118-C354-4F5C-8BA9-7DADCCB46402",
    "18497.pdf": "AFA43E4E-6537-467C-8F66-C73C8484C932",
    "18511.pdf": "5B621F43-609B-46E0-BE36-905698096F33",
    "18518.pdf": "E64BE899-D578-4EAF-A2C7-33B8E71CF072",
    "18519.pdf": "E6898405-BF2F-46E7-A8F0-3BA032B10381",
    "18521.pdf": "3BC34946-44EB-47AE-9879-C3CEF0D58EAC",
    "18524.pdf": "518ACC31-0E2A-4586-957F-8A49294564B0",
    "18528.pdf": "68247F43-504C-4FDF-BAFC-29280F337CEA",
    "18529.pdf": "9B2DC716-B164-4CE3-B959-5661287DAF6C",
    "18530.pdf": "42AFD7AE-3818-429F-B232-404A67371BF1",
    "18540.pdf": "AA35A08B-5D10-4096-A6DF-DDF7E4293C06",
    "18542.pdf": "D69BE77A-6CA8-4FD3-887A-BCA4F9D23AA2",
    "18553.pdf": "97FA37E7-1179-49DD-AE3B-458064A3D3F6",
    "18558.pdf": "3007715A-DE21-40BD-A078-1D80A813C18E",
    "18565.pdf": "2A26CA82-6B57-4D2A-A39A-13AAE45F7366",
    "18587.pdf": "711BB02E-D6BE-440E-B31C-B7F0F093EE50",
    "18594.pdf": "9B87720D-A0AE-4412-B7C8-7D3AC43308CF",
    "18596.pdf": "B92195CC-4927-4EBE-B588-B0B269EEB23F",
    "18597.pdf": "0C87862D-AB38-467C-89F9-4ADE32F006B1",
    "18598.pdf": "E890E9C2-DB5F-4AAC-A0AD-8FD621A8C34A",
    "18599.pdf": "57C05E35-E1D6-442B-A761-C0A428736CB0",
    "18600.pdf": "7EEF8B42-CA5D-4BB2-8EC4-D3B820BD935E",
    "18601.pdf": "BEBB12C0-E749-49E2-A08F-E3FBD0F8A95C",
    "18603.pdf": "617E2A8B-8E47-4899-A8B5-5B3A2A586D38",
    "18606.pdf": "A5BC47BF-36AC-41E5-9029-CF8B9378F7DE",
    "18607.pdf": "1410E13B-777D-42E3-BCBD-D7A754D67604",
    "18608.pdf": "93380C8E-E3BE-4EB8-B4E6-21462916E63E",
    "18609.pdf": "0B47B9B5-371B-4796-8B31-155F89B70484",
    "18610.pdf": "840F6721-6F5A-4075-AEF2-0704524E4438",
    "18611.pdf": "C5B6C1D3-2240-4FE8-BF94-385064AFA9FF",
    "18612.pdf": "432760B8-B290-4337-B21B-D2576B243F0D",
    "18613.pdf": "74AA6FB6-DB9D-4D74-B9A9-D16C312F2B3B",
    "18614.pdf": "A2808804-C07D-44FA-91FE-E352FEB7CF04",
    "18616.pdf": "16FE7969-1EE8-46D6-B90F-12BF87CE7205",
    "18617.pdf": "93929891-118D-4BC9-B033-8CD239163C74",
    "18619.pdf": "A7CBD8A8-CF27-4BE6-A579-A83A98A3F36B",
    "18620.pdf": "B63E507C-20EF-435C-B02B-17594BD7F102",
    "18621.pdf": "4D04AF4F-ECDA-48C4-962E-E6993374037D",
    "18622.pdf": "9AC76620-C4D2-43B3-AD07-E4B259E9300B",
    "18623.pdf": "902E2AFF-FA17-4DB6-8DD5-CA3C2A872553",
    "18624.pdf": "1388C962-3750-46EA-9FED-7C53F374E60A",
    "18626.pdf": "EDF60618-B4F8-4A88-B813-DB9F74D7F551",
    "18627.pdf": "BDA96CB5-A747-4523-9E40-B5C5B2DC62C1",
    "18629.pdf": "A6EE42EF-E94C-4952-98FD-E30761D14222",
    "18630.pdf": "09BB277A-41EA-440F-A119-56BF542B3E07",
    "18631.pdf": "A684CD50-7E32-4528-953F-A6A7D2861E10",
    "18633.pdf": "FA9C2E2F-530E-4B99-A2E2-9446134FA034",
    "18634.pdf": "EB1941F1-82E7-41BA-8B55-43F4B95C2969",
    "18636.pdf": "CE799F03-40AD-4ACD-B6C8-FC9DECC72B3C",
    "18640.pdf": "4A81DCF6-9AEF-477F-ADB1-85EB9868997C",
    "18641.pdf": "21AACAB9-6425-483B-BE36-37159F58AD76",
    "18643.pdf": "346775D3-FBD0-401F-8F45-82443477E89A",
    "18644.pdf": "BA03B7EC-795B-47A1-9416-64B38D4CBA1F",
    "18645.pdf": "6D5CB81E-AF97-4E8A-949E-D77EAF3DEA77",
    "18646.pdf": "0D9BB688-90DF-4FCD-8150-07186DF49CE6",
    "18649.pdf": "73DF47CA-9F68-484D-8ADE-D6D8CB552D3A",
    "18650.pdf": "5B54431D-1EB5-4020-978B-2759D7AA831F",
    "18652.pdf": "2C90361C-6CCB-48AA-9E87-A8F34303F685",
    "18653.pdf": "CFF01C5D-5FFE-4C0E-8517-6B0FAFB354FA",
    "18654.pdf": "A3C2A756-9EFE-4DB9-9B20-2BA3FB6DFA69",
    "18656.pdf": "58B08335-D7CC-44D8-9274-08F471CF14A0",
    "18657.pdf": "44C02809-E100-4C09-A92E-CC753A64E149",
    "18658.pdf": "84FB299A-54C1-406E-8628-3043132F53E8",
    "18660.pdf": "1E4A6743-D554-49F2-920D-8250773E60C4",
    "18662.pdf": "85BB5EDA-49A7-4D45-BA79-237BC06429E9",
    "18663.pdf": "8524B075-735C-4779-82F1-DD05730895E2",
    "18665.pdf": "154BE7A5-ABA4-4359-B045-36B36634EEE6",
    "18666.pdf": "226ADB36-5000-45D7-9F3C-C81243092AB4",
    "18668.pdf": "CACFDE93-E5D3-4A7C-ABFD-51FD47516D14",
    "18669.pdf": "195E1598-8CD6-44E7-9A1E-297D6F20CA79",
    "18670.pdf": "3DCD304D-076A-49E1-8640-191E77766813",
    "18671.pdf": "E071548D-2B61-4599-A334-BA03EF4D1B75",
    "18672.pdf": "997651FB-369A-4E93-BAC3-251ED9137676",
    "18673.pdf": "E2ACFDD9-92AB-4271-A5E0-5D0F3A88260F",
    "18674.pdf": "27C63CB3-7E61-41EB-94B2-E8C392EE86A8",
    "18675.pdf": "EA5803E2-920B-4F48-9540-95EE5FC6CDFD",
    "18676.pdf": "979C4BAE-3EF4-4194-B156-7494FC599E7A",
    "18677.pdf": "B08B41A9-E4DD-4019-9ED0-8768F2813B45",
    "18678.pdf": "2F0DC6A5-ACCB-4FE2-ADEF-27A9AB7B9F80",
    "18679.pdf": "084729D2-81B9-4165-8DF7-86483F2F00E7",
    "18680.pdf": "63EF5D13-FE97-4745-B678-120F748005BE",
    "18681.pdf": "DC451D79-C641-47F1-B392-2DF8AC2ACA60",
    "18684.pdf": "71267294-19E4-40AE-A360-3D80AA29082A",
    "18685.pdf": "AD6CA42D-1AB5-4F0C-AC59-92D3C9CA156D",
    "18686.pdf": "3F1B1D15-559A-42FE-A223-1B7FB0E0C1D6",
    "18687.pdf": "33C08E2C-C0ED-47C9-A578-03F1EC20C6A9",
    "18688.pdf": "1295B66F-D52D-487B-9B8A-DFE8AFD182FF",
    "18690.pdf": "4DE8517A-EE54-409E-8D5E-AA6FE25A0876",
    "18691.pdf": "117E3A7B-C13B-479D-8026-379500385B5E",
    "18693.pdf": "F2F4AB0C-160D-4CF4-BEC7-1E0F0A6FADD6",
    "18694.pdf": "A8262C3A-4E28-4EB0-8FE9-E4DBD0875C07",
    "18695.pdf": "FB140406-76F3-4833-BBF1-5D483FB7256A",
    "18696.pdf": "2BEB524D-5CA3-41BF-B1FC-990B872E52F1",
    "18702.pdf": "20DCF2A7-B077-461C-87F3-61CE1B93DA31",
    "18704.pdf": "D7245131-E73F-408A-8424-8B8ADE89E217",
    "18707.pdf": "8775E9AE-BE9F-4EFF-B017-CC5A1DF9D1ED",
    "18708.pdf": "D20E4032-FBC8-4733-A1E0-A5CB730B51DB",
    "18710.pdf": "82B43B27-B859-425D-B8ED-37B38DAA1187",
    "18711.pdf": "E614F923-2B95-4AA9-BD5A-5EFE296A5B1B",
    "18712.pdf": "BA8E1BC1-46BC-457C-A68C-D7F829017F56",
    "18713.pdf": "BB18554B-96FC-4BDD-B8ED-0B0102FAFCA4",
    "18714.pdf": "6C9B35D3-21B1-4C4D-B011-DC02A8B8C6E1",
    "18716.pdf": "CBF688F4-F1C0-4DD2-8935-A2BAA8BF6344",
    "18720.pdf": "AA1E16AB-7BA3-4335-9D27-7E085B73299B",
    "18721.pdf": "4199D3FA-1108-4C4E-B8E3-5901E40B0A60",
    "18723.pdf": "9D743276-5137-4CE1-A6EB-FC17434455C3",
    "18725.pdf": "8496D0E8-9008-4726-8E86-1497C231EEF9",
    "18727.pdf": "96DBA432-41D5-4058-9998-66CE5287A0F9",
    "18728.pdf": "7BCECEA4-B3E0-4F0A-9D73-84A3A7E51E4F",
    "18729.pdf": "3C3EA75F-A09E-4AF1-BD54-EF7007484C70",
    "18730.pdf": "4406A6FC-DFF4-4C3C-8BEE-46BD59F2F214",
    "18731.pdf": "66502BE4-EB3F-4E42-BFD0-0455FBADA627",
    "18732.pdf": "EA3D07FC-C2C4-46DF-BA44-BAB2067D911D",
    "18734.pdf": "5E608252-DB1E-4317-BB33-3763D3A61EF4",
    "18736.pdf": "3E99B373-C99D-4D8F-A4E3-E18098938494",
    "18740.pdf": "957D16FE-1357-4B79-A94C-6D1DB420D3AC",
    "18742.pdf": "FB466944-DF6C-4546-A714-51D37119602D",
    "18743.pdf": "4D9D753E-2E88-4ABA-BDE6-0892B65D7540",
    "18747.pdf": "3D1EE4CC-7021-4BCE-930C-D9181EE426BC",
    "18748.pdf": "49AE7403-105A-4CF4-88D7-4353A0D8C757",
    "18749.pdf": "78C5E607-C229-4281-BE0D-9BDF707F6537",
    "18750.pdf": "C915E107-DDC9-4F66-8D69-63B4B120EE43",
    "18751.pdf": "6732B597-B8A0-4930-ACAB-9D95657A668A",
    "18752.pdf": "9C79F171-EAA4-4D7A-A8C0-3DD5FBF6237C",
    "18754.pdf": "E95501B2-D546-4E05-9DDB-92C9CC2EDA4E",
    "18755.pdf": "29FAB687-7D77-4452-B363-2F0D19BDD794",
    "18763.pdf": "9192F156-9A0B-4E74-9481-EC1FE1A94058",
    "18764.pdf": "DF8E3C3B-D2E7-4D78-8334-1ECA271CED13",
    "18765.pdf": "B83FF1D4-59FF-40B5-AF95-C19909E3DB9A",
    "18768.pdf": "0A22B002-02B3-4A07-B850-4533EC05F917",
    "18770.pdf": "8C8AEFC0-F14C-4375-87FB-24AD6C80C2B2",
    "18771.pdf": "DA0EA6F6-697B-4030-AE62-AC1936292F2D",
    "18772.pdf": "BFACC693-ECFF-4603-9622-2F69A21774F6",
    "18773.pdf": "6D0FE943-116A-4269-A2E8-8DE786D4E07E",
    "18774.pdf": "957A7385-429B-4419-AA53-65C6C5C16F33",
    "18775.pdf": "EA2789CD-49AF-4BB6-B1DB-E862B25C93EE",
    "18776.pdf": "B79F96BA-F7DD-4E5E-8D55-ADDCCCE15610",
    "18777.pdf": "B49B7FE5-A68C-4E94-B9C6-0A733BC5CAEA",
    "18778.pdf": "4C528D69-42AA-420A-ABB4-5AEB918C1192",
    "18779.pdf": "D245291C-596B-414D-9065-E29A02265F81",
    "18782.pdf": "E6332686-680B-4CF0-A931-824F228A479C",
    "18783.pdf": "50DF1436-E5AB-429E-B41D-251DD15A177A",
    "18784.pdf": "2F7752E6-CF2D-44E4-887D-2DF05DC0252C",
    "18786.pdf": "4676226E-EE5C-4973-8617-7518577A1F13",
    "18788.pdf": "C0B110D6-F348-412D-A3FB-DA027EC75A5A",
    "18791.pdf": "4A5042E7-9A34-470E-A14F-1E2065E31C6E",
    "18793.pdf": "7760B4BC-8A0C-46C0-BB18-523A37953124",
    "18794.pdf": "64CEBA44-F95F-444A-B5FF-5AFEAF7F3BE8",
    "18796.pdf": "E7DD5250-F1D0-459C-9C73-E09D21D93779",
    "18797.pdf": "05B8139A-21EF-4876-9729-D52232876B94",
    "18799.pdf": "1E7D0BEE-D225-4928-9A5E-39BFF7EEA448",
    "18800.pdf": "B3062F8A-CC06-4390-B7FE-35FF308F7AB0",
    "18801.pdf": "E32C6492-6B9F-4F46-828C-A60FF2CB0D58",
    "18802.pdf": "F9504FBE-4948-4D1F-A387-221BF5AF5A18",
    "18803.pdf": "5E4A1A8C-218B-426D-94F7-7379B88C6FDB",
    "18804.pdf": "51356A76-7EC0-42F0-B752-32AE077CCFF3",
    "18805.pdf": "1BD89BBE-E2E0-488A-88F5-1C2F0DD9881C",
    "18807.pdf": "597509DA-9AF1-4F6F-A268-4F90AD55F483",
    "18808.pdf": "04A21229-017D-45C9-8508-AB97C68C16B0",
    "18809.pdf": "5A612CB7-DF74-47DB-8520-6E9959A4D49E",
    "18810.pdf": "71F9991C-EBE4-4843-9260-2C1084463FC2",
    "18811.pdf": "B71C56CA-7F73-441C-B5E6-8BE94017B1D6",
    "18814.pdf": "0D8885BD-515B-486B-A47F-0ABD6097D5C5",
    "18815.pdf": "288BC480-63A2-41BF-9726-6A8C414D7739",
    "18816.pdf": "0C7DFC01-DCAF-425A-B0B3-0726538724B7",
    "18818.pdf": "D366BF71-AFE2-4089-81A0-AC42A38769E0",
    "18819.pdf": "7AD1C351-DE08-49FC-A4BE-FEF0149D3050",
    "18820.pdf": "3E9475D5-360B-4B01-AC08-EFCE76E2B50B",
    "18821.pdf": "2D3D276E-83D1-4C2D-8C42-DAEBAD2858A3",
    "18827.pdf": "D32B0E83-0710-4211-ABD2-34BABEA868F2",
    "18828.pdf": "179630E1-7806-4C9C-B48E-663CE5A75DB3",
    "18831.pdf": "BA6CE774-961D-4128-9FC6-9F32308039FC",
    "18834.pdf": "DA25AC3D-A68E-43D3-B812-25A2959E1143",
    "18837.pdf": "07D3241C-713B-4AAB-80DA-D798BA3B802D",
    "18843.pdf": "01DE97D5-4137-43BC-AD96-B997D2AD1426",
    "18844.pdf": "37E7948E-DFE7-491E-BDC9-C6343801A8F9",
    "18845.pdf": "70AC6020-E26E-41A3-AB8A-28770FD9181E",
    "18848.pdf": "885EA308-8BE7-4D6E-B184-F51D0926A600",
    "18850.pdf": "586BB608-9CC9-42A9-BB04-37072BCCDC9F",
    "18851.pdf": "298E84D0-0C07-41E9-B0DF-E8A77CDD1FED",
    "18852.pdf": "4DEBD51A-E5FD-4F56-B372-B9BB95863A4D",
    "18854.pdf": "C4110917-3CD7-486B-93B4-3B573B73BB5E",
    "18856.pdf": "B642B9E8-3AAE-4044-9D65-35601EF83768",
    "18858.pdf": "2E856D5A-5B96-4ECC-BF03-6742F2872695",
    "18859.pdf": "648E2A5C-87BC-4327-B675-DAE62084506E",
    "18862.pdf": "BD67E4E3-4074-4992-A2B3-F9649BE80DE1",
    "18863.pdf": "EF37E1AE-4B1A-44E7-968B-8CBB4E636956",
    "18867.pdf": "C4BAE9AA-A0F9-4778-851E-8F6BAD5921DE",
    "18868.pdf": "59A19485-EC70-42FE-ABCD-7F3ED5FE9863",
    "18872.pdf": "C078399F-545B-4908-9B21-5D6D240C69DB",
    "18875.pdf": "E8D35FC1-963A-4171-99F6-7AE872418D97",
    "18877.pdf": "DB74ED16-FA05-439B-A086-7DCAFC69B577",
    "18879.pdf": "82C717E9-30CB-40CA-B8FF-DFF227967582",
    "18880.pdf": "DAE08C13-07AC-47DA-846D-72E7D7660176",
    "18881.pdf": "6C988FEE-2A3C-425C-9679-DA739BF548E4",
    "18882.pdf": "36B08F97-3B12-4D51-BB46-9956EA999F3C",
    "18885.pdf": "AE7A68B8-635B-4103-B788-AC85FAAD0185",
    "18886.pdf": "E0FBFE2C-571F-44DC-88A9-2F18F30990FA",
    "18888.pdf": "57563484-7819-4BCD-B260-F5EC61BDBCEF",
    "18889.pdf": "8815FC61-996D-4B9D-8327-62C807F95D40",
    "18890.pdf": "025DBAEF-5BF2-4956-BCEA-5843987D3079",
    "18892.pdf": "47EEFC06-AD3B-4B54-B07D-373A66366AF5",
    "18893.pdf": "52DD0C33-C084-4F1D-ABCB-EFA85FA3C667",
    "18894.pdf": "24B48A5B-D3AA-497C-B08C-46F60615B64D",
    "18896.pdf": "E3023CE4-9449-419B-813F-BC9B08837BCA",
    "18898.pdf": "E309410F-E319-4D1A-B69D-FBC510F722F2",
    "18899.pdf": "7F740153-E0D6-451B-85A6-0E113439669E",
    "18903.pdf": "8B93C221-E4E4-422D-97FA-7A2BDE64F59F",
    "18905.pdf": "86037A1F-879A-4471-921E-DAB6AED826B4",
    "18907.pdf": "5D5C960E-8E0F-41B7-B468-574A9374AF4A",
    "18908.pdf": "21D0ECDD-E51B-4066-B6DA-82B47213958A",
    "18909.pdf": "A0C168B8-89CA-41AC-A2F8-8B5070583EA5",
    "18911.pdf": "BFB0AC05-9ED0-4156-A614-E0000427EAFC",
    "18913.pdf": "C266D7F3-547C-4EBB-B7E0-B7E8A7705DE0",
    "18915.pdf": "A32FED7E-3021-43A8-BF6E-EEBB15B6F387",
    "18920.pdf": "DBBDF8C8-337D-4000-98CB-F04DC3B1D004",
    "18921.pdf": "033B260F-92EE-4C15-B26E-529DF0274942",
    "18923.pdf": "511F535A-752F-4C37-A95C-669A2F178462",
    "18924.pdf": "939D5AED-D4F8-4230-BC6A-C7A28EBB0125",
    "18925.pdf": "4D558422-51BB-4851-BF5B-57CAA589C8E2",
    "18926.pdf": "4CBC84C6-CE41-47B6-80E0-45EB58A6C031",
    "18927.pdf": "DF249227-FA76-4B96-B234-2344C18BAD21",
    "18928.pdf": "486AD410-2BA4-4480-8A9F-7815A739E6CD",
    "18929.pdf": "2FB87DCD-9F10-47D0-B776-F95D37E8C150",
    "18930.pdf": "0689F6F2-8863-49B7-A275-5157E33CF165",
    "18931.pdf": "2994F88F-474D-4125-981C-DB199A1AE73B",
    "18933.pdf": "F37478D8-36EA-4966-94CC-A5DCE2BD1418",
    "18934.pdf": "E2771EEB-7A96-4991-A2BC-DBEE8FB067F7",
    "18937.pdf": "FA39CDBF-19A7-42C2-BD95-36615C84FA4F",
    "18938.pdf": "A7A47E82-A6E4-49F7-AFEB-F9474B8A1DEC",
    "18939.pdf": "55B09DED-A854-4DB2-8832-E67C8A4379D8",
    "18940.pdf": "D2EC9277-9A92-4A10-8B22-4AA10806B90B",
    "18942.pdf": "F850EA99-F6F8-49CD-A343-DD862AB0858E",
    "18945.pdf": "8F4F830C-50B8-4ADD-9A05-9098A3AF1FA4",
    "18947.pdf": "30451D81-3E59-4D07-9ADF-E3864AA3AB0E",
    "18948.pdf": "1977FE48-A74C-480A-8EDE-E8252201BECB",
    "18949.pdf": "4FE1A500-82F1-4F07-B954-EECFAD14CABD",
    "18951.pdf": "04A31681-D754-4EBF-866E-456F82BE443C",
    "18952.pdf": "12ABA756-3570-45D6-BA1C-06B056F6F07D",
    "18955.pdf": "6712836C-6A47-470C-AAD9-6CB955A63D1F",
    "18959.pdf": "085CD897-613C-48FD-BE5B-DFD688F526AF",
    "18964.pdf": "B18EF850-98E7-4D70-AD32-F7B3975CF0BD",
    "18965.pdf": "E7AAB088-5CD4-49EB-81C0-CDAFE0558413",
    "18966.pdf": "9168D181-9991-488C-95FE-C619FD59C248",
    "18967.pdf": "3B4BFA35-F7BC-48DD-923E-53483A582766",
    "18970.pdf": "B3461760-0468-49F9-89B0-97245C204991",
    "18971.pdf": "E3CDE385-E0AE-408C-8A76-39CBDD62A360",
    "18973.pdf": "31B9FA15-58D0-49B5-A803-55B41F870E0E",
    "18975.pdf": "0C5C19A4-A8CB-4812-A793-0F4A884C2FCB",
    "18981.pdf": "58A310A9-049E-4BDD-8EF9-A41A2059193C",
    "18982.pdf": "0E291D31-CEBB-4FBC-8D26-9C0B42376C68",
    "18983.pdf": "1FAA9371-1BC0-4B5D-813D-E07F7A0C74AD",
    "18984.pdf": "AEA1F7EF-3798-4875-83B0-EA49B0FE4817",
    "18985.pdf": "29D57816-833F-4B65-B116-00989DCC38C8",
    "18987.pdf": "DD048342-3CFC-4ADC-B29E-347AB25C6A6B",
    "18990.pdf": "1BE1B4B6-61AD-4C28-ACE7-39C8434D9D37",
    "18992.pdf": "2266BCAB-3ECF-434D-A48F-6D8297D09963",
    "18993.pdf": "6EECFA25-E34A-458E-AB13-B4E6009B6DA1",
    "18994.pdf": "C3E1DD54-7BE6-424A-B0BF-972299853444",
    "18995.pdf": "E9FAE017-FCC9-4057-9918-ACADCE7F85EC",
    "18997.pdf": "A0038554-21F8-41D5-B417-2892AE47AB5C",
    "18998.pdf": "B59A58AD-3F3A-43F7-B048-D9E953558F02",
    "18999.pdf": "7549529B-80F5-423A-A3E8-54312712CB7A",
    "19.11.18 Chordoma Spine Summit Abstract_Figures.pdf": "8C5D5E64-A908-4778-BA74-4E75F00B5587",
    "19000.pdf": "F4FAC5A4-45DC-42FB-A9B5-BB5BED9DAB62",
    "19001.pdf": "7C154F4D-2881-49D3-9677-932C7C7F55E8",
    "19003.pdf": "A1A69A23-793F-4A38-A093-C5F10EDF36A3",
    "19006.pdf": "702289D6-CF10-4694-A998-19F6C54B5099",
    "19007.pdf": "E5B9644E-2031-45D9-8F95-018536768CF0",
    "19008.pdf": "A89D462D-1B79-4490-AFF1-E9B202774D89",
    "19009.pdf": "187D6598-E513-4CD6-AB74-FE4DCB9E3CD7",
    "19010.pdf": "53A1156E-33A0-4939-B956-DE9257704357",
    "19012.pdf": "AF1DA437-9FB6-4431-8407-2D5C6075631F",
    "19013.pdf": "AF39B76B-2BC3-456A-8E5F-D6E235A01975",
    "19017.pdf": "75AEBF35-DDA2-4800-87F8-3E382417AAE1",
    "19018.pdf": "9C58E519-BA27-4445-B96A-89D5B697F071",
    "19020.pdf": "E89CD568-A9D3-4599-AA6B-231798C584E8",
    "19022.pdf": "F95592C9-0C92-4EE6-B25C-C027ECDC1848",
    "19025.pdf": "56A6B054-4F5D-48C4-9E45-D34792673D6C",
    "19026.pdf": "DB22E8E1-94C5-49F6-962E-79E62CB76C85",
    "19030.pdf": "49F1DF21-A647-436F-AC83-BFE6850D58AE",
    "19032.pdf": "C7F1AAE1-FD61-4B7B-AF27-FA638C9C68B9",
    "19034.pdf": "91624145-C265-472F-8612-8E467F4A1BFA",
    "19035.pdf": "F5C6731D-F100-4BBF-83F5-5D68BDD43104",
    "19036.pdf": "1FC25DF8-FE93-4C17-AAF7-705394DE55DD",
    "19037.pdf": "677E292D-3393-45AD-B238-C8763C4C35E9",
    "19039.pdf": "8FFCB16F-0B34-4091-A4D1-BF56398B1FB7",
    "19041.pdf": "F63CEA54-F6D2-4B46-96AD-4858F46118E4",
    "19042.pdf": "9C09C690-5BFA-47F6-BCC1-9EC54253FF0F",
    "19043.pdf": "32D72CD1-2F91-43B8-BDEB-C467D37C6AE4",
    "19045.pdf": "D67D7118-714E-4261-A088-0BF073E1E5FF",
    "19046.pdf": "1DDDC431-F64D-4DD0-990B-B088C21B7CE9",
    "19052.pdf": "B35A2DEA-AD1A-49C3-919B-E2F7873D50CC",
    "19054.pdf": "2F6EEA77-C880-4C8A-8406-7F87DD93E70A",
    "19059.pdf": "EF087119-C859-4205-BA29-42364945B963",
    "19061.pdf": "441EAB2A-5E14-4A1A-A2BF-EAC4C72E5000",
    "19064.pdf": "06617D2D-5D37-420E-B5E4-183A2ADBD48F",
    "19074.pdf": "569434C7-BA6B-4F9D-B896-89798B187510",
    "19075.pdf": "411A9021-9267-4711-A280-479338BCBD11",
    "19082.pdf": "E2E180B3-1306-4EAA-96DA-7E75BB843C70",
    "19083.pdf": "18646E03-675B-4469-B805-E42C4EC79DA3",
    "19085.pdf": "416F4AC3-70F1-4766-AFF7-19AC9B60DF16",
    "19087.pdf": "9EEFDFBF-D609-4D29-A0C2-83DF0C8E3B46",
    "19088.pdf": "F81375DE-C84F-43C2-A95B-2DEEB6BFC7E0",
    "19091.pdf": "992184F2-D299-4D58-A3D3-47E55AB9FF28",
    "19092.pdf": "0237D6FE-A0A4-4CB9-B0C5-D0EF0FA61EC5",
    "1910 CNS SSr.pdf": "FD92B5D6-C79B-4322-9068-172F1FA8C404",
    "19100.pdf": "BE91BF29-25E4-4086-8028-BCA0E7846748",
    "19104.pdf": "D25F2BE8-27D9-49A5-8ED6-ADC254DCE980",
    "19105.pdf": "B3071659-7E73-4B74-91B2-F20B5A3EA466",
    "19107.pdf": "9ABF59F6-CC52-40E3-A1B4-52402868BC0C",
    "19118.pdf": "07E376E3-2FCF-4496-950E-5D6C728F3A9D",
    "19121.pdf": "1576A622-4BC5-4292-AE85-CA149E63DDF5",
    "19129.pdf": "BDFBC66A-F4DD-4B88-96A7-96B0E79BE150",
    "19131.pdf": "6A7A5897-9E5D-4187-9636-221411487973",
    "19132.pdf": "A6225829-2D02-43EB-8599-F4F12138E622",
    "19133.pdf": "0E905239-6A38-46C2-8EFC-0F3A34287C23",
    "19134.pdf": "A190DFD7-22FA-4642-9FC6-BFFDC5673CBF",
    "19138.pdf": "8EB663B0-69AC-4290-90A6-B39AB7E94238",
    "19140.pdf": "E65FBF8A-092B-4D4C-91FB-7C62692B8C27",
    "19143.pdf": "EA5AAD6C-6EA7-4F47-A2DF-97ACF89659E8",
    "19147.pdf": "BF030342-20A0-44DE-BB01-8620DD869E7B",
    "19149.pdf": "1DBDC9CC-47C1-4372-816A-BA621A6DC5F1",
    "19153.pdf": "E8C4F074-E5F1-40B9-AEE1-26828F20765E",
    "19155.pdf": "A4CB15F8-E1D1-4FE1-9E57-0FC0FFF97F91",
    "19161.pdf": "81240EBB-70B9-49BD-83E4-4FBB51FB295E",
    "19163.pdf": "6BC286EA-7825-4F0B-AD8C-33C5D8411835",
    "19164.pdf": "40954E85-9D00-497A-9973-1E45553DF48F",
    "19165.pdf": "CED1A50C-BD88-40E3-8484-1587EB918232",
    "19166.pdf": "86292887-6A0D-42C8-9DB2-CED38B5C6819",
    "19168.pdf": "F7360E47-7DD1-494E-8876-2B0F85A41203",
    "19169.pdf": "E96819BF-39A9-4DB9-8EC0-E34990849E60",
    "19170.pdf": "5F6B2F5A-3AEC-4BBF-9045-584C637E7597",
    "19174.pdf": "503AC11E-AC14-44F4-AE1F-DA2F59174FB2",
    "19175.pdf": "7CB68D24-36E0-489D-BC5D-B2ED15CD27B2",
    "19176.pdf": "95289313-413B-4268-9A8C-5302234E9D41",
    "19178.pdf": "72D0FA7F-8BA2-4107-92FD-10B443BC61B5",
    "19181.pdf": "9BBF275F-6CAB-4512-8614-18112339A510",
    "19182.pdf": "3D6249AE-223D-4117-9C68-BDBC1ABC3307",
    "19183.pdf": "A302AE35-F86E-43AD-86BB-49A682C35971",
    "19185.pdf": "ADAC7F0E-532D-44E1-AAC9-79D3CB8D3F39",
    "19187.pdf": "524EABB5-B3E9-4E0E-B015-816B18076D63",
    "19188.pdf": "E0CCECF1-2828-491B-BD1B-926C590139BB",
    "19189.pdf": "EF674D2D-BBCF-4770-840F-E21681CDCEBA",
    "19190.pdf": "6B6DB1D2-12AD-4332-9F15-88DCA1294CF7",
    "19191.pdf": "97046F8D-C67D-4DE7-9FD4-B26E22C5EFDA",
    "19192.pdf": "7CAD8C59-50C9-4233-86EC-CEBBA2D90EFD",
    "19193.pdf": "CB9BBD83-80F3-48D1-B3AF-2D6D0B91FB39",
    "19195.pdf": "5519AFB5-F422-4675-ACAA-0087D4E8029B",
    "19196.pdf": "5999A162-528B-4F34-82A5-75484E971F88",
    "19197.pdf": "D96B925C-562C-497C-ADDB-6DEB1E41537E",
    "19199.pdf": "5A38C534-EA5E-4C22-AB42-61E561ED163C",
    "19200.pdf": "5244DDF4-8970-45AA-8764-3DC1429AB982",
    "19201.pdf": "3F1B8C06-346C-4C22-B693-5D0D08FAC4A6",
    "19203.pdf": "E0D8D489-21AF-4242-AA4E-69B39F31A7BB",
    "19204.pdf": "9ECC18BF-9BD2-4E3D-92C9-8C0BB61EE78A",
    "19208.pdf": "D3B0C0C7-9A31-4C86-8F79-C72B0F32E71C",
    "19209.pdf": "1B068D99-9AAA-467A-AE6B-5352EFC81CBC",
    "19210.pdf": "723E1B73-C6B6-447A-9089-1CA70C71DBBA",
    "19214.pdf": "F8FE43C7-BE73-4076-BE2B-60E83A0A68E9",
    "19217.pdf": "AF29716E-71AC-408A-9A72-3DA419ACB2E3",
    "19218.pdf": "87CD828C-C7E3-4E89-8AAC-ED10AEFB8CC9",
    "19221.pdf": "B203F4C3-96B3-4FC4-A522-65F5C09B2CFE",
    "19222.pdf": "3913FB0B-D18A-4DED-971D-116011B2A1D6",
    "19225.pdf": "20BD9B8D-C158-47F6-9B43-7D9431347BE9",
    "19226.pdf": "C092270F-5051-437A-B46E-1C80936CA605",
    "19228.pdf": "7DDC0796-5630-4049-B3BC-657EDDCAC99B",
    "19229.pdf": "284C2A69-6726-485A-93B3-338AB7156E1B",
    "19232.pdf": "5E25E796-BAF2-47F5-8556-CD0E5031D872",
    "19233.pdf": "2F630763-13CE-46AD-B22C-15BAE34F5749",
    "19234.pdf": "38AF0D69-70E5-48B9-869D-02279BD3B4E6",
    "19236.pdf": "11DA2B25-243A-414E-BB7F-DE926C7FFE1C",
    "19238.pdf": "26A1F964-F8EB-4195-8C9E-2436BF1E6C03",
    "19240.pdf": "39185628-8CBF-4A9A-A5E6-3B2AC2DF1F2D",
    "19241.pdf": "D8EC004B-9EEC-4E08-899A-62535498B841",
    "19242.pdf": "F93CDC7D-E68E-4BEA-B027-91E7CBE469DC",
    "19244.pdf": "0FE8FAD5-C0C9-4E6F-921D-C68BD44938BE",
    "19246.pdf": "A3B0E255-C6DC-4E00-826D-1216416B7011",
    "19247.pdf": "14E10EA2-5C49-4B6C-93C0-25C6A3FAE1E5",
    "19248.pdf": "17816F33-6955-432D-9363-DEBE947DFA9B",
    "19249.pdf": "96859AAA-A40C-4FE6-A266-FE60433F5E0D",
    "19252.pdf": "736E9D32-F193-41AE-9AB4-673F1DF7218B",
    "19255.pdf": "F2F1D200-A96C-401E-847B-5C7426150843",
    "19257.pdf": "683C9120-29C9-4F13-85D1-ACCBA9E844CB",
    "19259.pdf": "27F8EF9B-2783-4316-8B49-F458503DBC4D",
    "19261.pdf": "4337DCAB-BEAA-4588-A8DF-24112AA6F012",
    "19263.pdf": "8B25CE70-B0DD-4D0D-BDE7-6BF574B58021",
    "19269.pdf": "B938AF07-33D0-44DC-A7DB-F3C470AD3A4D",
    "19271.pdf": "5AABC45A-8220-4EA1-96AE-27120646E6CC",
    "19274.pdf": "18DB8C18-A860-47B7-B648-7F4A3CCE66C7",
    "19277.pdf": "51FDE94E-6EC8-4DC9-BA1C-4572DE8ABEA9",
    "19278.pdf": "552498AF-B05C-4E88-8F62-E6F3375E1261",
    "19279.pdf": "E8AB1515-5A9B-4722-9379-CC5B829555E3",
    "19280.pdf": "902423BB-C5C2-47A0-A5BC-D846138F2CD3",
    "19283.pdf": "A62736F3-B9AC-4C8E-B8F6-945A40192D3E",
    "19286.pdf": "0AA83D96-6863-4711-BEF6-30FDD104D8FB",
    "19290.pdf": "17B5E76D-9265-48A6-B924-4846CC5D9C85",
    "19291.pdf": "A1173B55-32CA-44F9-839D-F10ED661EB7D",
    "19293.pdf": "FBF922ED-B7AB-4191-92BC-4D23394CC78D",
    "19296.pdf": "003289D0-F684-473A-A10F-8F65A5F81422",
    "19298.pdf": "0A21D1BB-24C7-4758-92AF-5716865D60BC",
    "19302.pdf": "34B5E677-1B19-48E2-9FC2-C4E883A9513D",
    "19308.pdf": "3AEB721D-6AA7-4544-A50E-5D538C3170A0",
    "19309.pdf": "5ACDB933-72FF-4B8C-A755-1661E4962F72",
    "19310.pdf": "7740EF83-256E-4DFA-A8A1-9245D30DCD08",
    "19313.pdf": "D07D7723-7223-47E2-B704-20FAD52AE59A",
    "19315.pdf": "5F49CBCD-F903-476A-B753-1F710C7BA6FD",
    "19316.pdf": "BE7244C9-FD36-4008-8335-25677A6FDEBA",
    "19317.pdf": "ADBDAD50-D693-47F2-9A86-4B5BB524A694",
    "19318.pdf": "59F12A82-5F75-43D9-B07B-774C93CBB499",
    "19320.pdf": "839DE67A-B533-4AA8-AD36-D72E5F19D2D5",
    "19322.pdf": "91913DFE-35B7-4F85-B770-C913EEC17DA8",
    "19323.pdf": "0BA7B795-0896-4E5F-93F2-EF621871E3EA",
    "19326.pdf": "4D8DC0F0-659B-40E7-9C73-D620EBF99C6F",
    "19328.pdf": "1B369B90-9480-4789-B455-B3B03E7039FC",
    "19329.pdf": "9F8A465B-424D-4FAD-B8B1-299BEC27F689",
    "19330.pdf": "8D96E3FC-F9BE-4D3A-B39C-E0FAF27E22C2",
    "19332.pdf": "061E6FCF-B4FF-4BBC-A52A-C68813892877",
    "19333.pdf": "42ACA30A-FF2D-460B-AF54-EB95A98A7163",
    "19337.pdf": "75EAD69E-A92A-4976-9C44-65E02B002720",
    "19339.pdf": "2BF75082-B5AB-4CFF-A52F-9921F90F0C67",
    "19343.pdf": "8F85B099-E7E7-4F1E-A30C-6982E437AAFC",
    "19344.pdf": "7E25F127-9523-4CC1-ADC8-BEB4C092A29E",
    "19345.pdf": "31FFF0FE-5FFD-4004-B593-995E6EFFA9AB",
    "19346.pdf": "AE560481-A922-47EE-97B0-110793CAA358",
    "19347.pdf": "73D924EE-22C3-472A-8182-FC897F583FAF",
    "19349.pdf": "011B957F-0290-43BC-A480-7924720AB257",
    "19350.pdf": "B690D5C6-4745-4611-B9A0-C4AEEA3B9125",
    "19354.pdf": "F6460D35-9F3A-4AEA-B018-5968BCDEFA28",
    "19358.pdf": "FDDDD0E0-BE48-4FF3-A9EF-D0E5F9703B2A",
    "19359.pdf": "F9DBEC1A-6344-47B4-BD17-78E333A34F26",
    "19361.pdf": "12A36F1B-1070-4D48-BE1F-7A0BDCE7626D",
    "19362.pdf": "C1C56289-DD26-4EBF-A221-0DBC9B63DF8C",
    "19364.pdf": "DF4F0A92-53F9-4D30-983F-21BEE65AA5FE",
    "19365.pdf": "2A384B06-CA24-41CD-92FD-1C5784F255B0",
    "19368.pdf": "BDC2F844-E6F6-4102-9B39-40CD596A55F0",
    "19369.pdf": "1CF899E3-4A97-4B1A-8DB0-0C8365FA410E",
    "19370.pdf": "C159E414-BFAF-43A1-90E0-FC3FFA3B0024",
    "19372.pdf": "03851647-D5BF-4755-95AC-DBD4C3560282",
    "19373.pdf": "9EF8C80A-4DF9-48D5-B3D9-EB789325D08D",
    "19375.pdf": "3F1470CB-66F0-4A20-87CD-3E7EF2EACC4D",
    "19376.pdf": "5C172F62-3533-438C-B328-3C15695E17B5",
    "19377.pdf": "2BEE7121-1805-4AE8-BCDF-5FA3E701836D",
    "19378.pdf": "A3F3795E-E9A2-4430-9527-3505EA06F340",
    "19381.pdf": "5739EE23-4D84-4D80-9326-3CCF3F2145AD",
    "19382.pdf": "87BC1710-E458-47F1-9836-7BA914F23C3A",
    "19383.pdf": "6B9228CE-EEEE-4D77-BE6D-7ECF3CCEF074",
    "19384.pdf": "758ACA78-D3B8-4B7D-AEC9-891618B67BBA",
    "19386.pdf": "5328B2AA-C695-4E86-AAAE-B46ED7697D7A",
    "19389.pdf": "83813C14-C4DD-422E-B966-3D7ACD4D7680",
    "19391.pdf": "72865198-41F6-46B7-B615-B848738B1463",
    "19392.pdf": "CC1ADFD8-C503-44D1-BB87-8EA109B35DCE",
    "19393.pdf": "C6513DDC-9912-408A-8E46-486B43086DB4",
    "19395.pdf": "E3A0B2F7-4D10-44F8-921A-3AC72E334BD5",
    "19397.pdf": "3EBF92C9-6984-4A84-847E-03EB82FD56CF",
    "19398.pdf": "677ED61C-B351-41ED-8837-550556BC8822",
    "19401.pdf": "DBFA5976-D608-40C2-8F86-DB169EB4F17A",
    "19412.pdf": "A1C1FC5E-CD8C-46D0-8815-0A6B0889A192",
    "19415.pdf": "F6FEE909-CD96-4ACE-BCA4-7FD6C03875BC",
    "19416.pdf": "BB0B314A-0FA6-45E5-AB38-E75D253755DC",
    "19420.pdf": "745A7EBB-8810-4BDD-8C76-D33DCCF81913",
    "19422.pdf": "4C0D8188-7D36-4B9C-9514-8D0BCE5CA132",
    "19423.pdf": "E5E7BB62-EBAE-443A-A8E7-A54496715CC6",
    "19424.pdf": "1197C616-B05E-4891-BA41-961F8DE2DC38",
    "19425.pdf": "60258E64-8820-4438-A393-A0CC49848914",
    "19426.pdf": "23C8E758-F350-4452-A807-7AB5CF837588",
    "19427.pdf": "73D8CFCC-95E1-4E9D-9321-1819FF1CFAC5",
    "19429.pdf": "2796747F-FB04-4AA9-8CDB-1D375A1936A4",
    "19431.pdf": "2655107F-5CCC-4B62-AF9D-6C7BF5EA8D7D",
    "19434.pdf": "D5AD111F-80E9-4A5D-B91D-1D9CF340E8DB",
    "19437.pdf": "1184F350-4177-42B6-AE2E-48735C3F3D40",
    "19438.pdf": "93816FAC-2C46-42DD-BBC6-71A9F3F48F97",
    "19441.pdf": "45CC64EE-FF98-4F5F-9A65-464D987C666B",
    "19443.pdf": "C0EF3FA4-25BD-451F-8322-99EECBDD3277",
    "19449.pdf": "AEC912FC-E928-42B2-B22A-A3E37EA9CC5A",
    "19452.pdf": "3271A2DC-B062-4202-94AA-B59E5AA13C5D",
    "19453.pdf": "9486A98A-9930-4FAF-9AA3-08C4EC0DFB26",
    "19455.pdf": "7A162C0B-7913-4847-AE29-D3F74A865130",
    "19456.pdf": "03325341-9A9E-4E80-B54B-688D7A06D649",
    "19457.pdf": "5513B5E0-0B67-4BEC-938D-23BA6656308C",
    "19458.pdf": "BB367B4B-ED91-4A51-B8C0-5159D562576A",
    "19459.pdf": "E2F8A538-999C-4877-823F-9830DA8CFE46",
    "19460.pdf": "EF55E30E-2115-4C68-82BA-34B0B6D4A82C",
    "19463.pdf": "C62E128C-45F3-479B-8F1E-CD255CDDBAE5",
    "19464.pdf": "90AEE8FC-421F-47EF-943C-67664BD57861",
    "19467.pdf": "78535D2F-46B6-491F-ADFC-F4308DF74DA8",
    "19468.pdf": "93A5DA05-CCCD-4FE3-9B4B-D06FB385B52B",
    "19469.pdf": "8EED1B69-7A02-46CE-996B-58009E735AC7",
    "19472.pdf": "5135CF40-CA90-4655-9BE2-37C1D8D19D7B",
    "19478.pdf": "0F5E5F1B-FBC7-47C4-9237-9BAA376567DB",
    "19482.pdf": "1EF988C5-8964-4E6F-8B30-1085608F5B28",
    "19484.pdf": "C1630FBC-2F13-41C1-9241-491AB5CDEB82",
    "19485.pdf": "3C899047-BD79-49FC-A5A5-DE7B57BA1B1A",
    "19486.pdf": "E0ABE036-7928-4A46-8871-5F8FDA85FCE5",
    "19489.pdf": "BCB08C4E-EC96-4CD0-A27C-43EA40C1EB39",
    "19490.pdf": "E179F1C0-2031-4E25-B24F-9F9DB0671012",
    "19491.pdf": "D05F8864-126B-4C4F-B847-B2D43C2CD9A3",
    "19493.pdf": "FADA2F0E-6EFD-4F31-ACBB-D248FA330CC4",
    "19495.pdf": "862A56D1-1E8F-4BFF-B387-5EAE959C43D2",
    "19496.pdf": "B28A4309-38B5-4DE6-8A57-ECB976992E73",
    "19497.pdf": "0F6BCB27-9E17-4B8D-A64D-5494D1357503",
    "19498.pdf": "534C63FA-9716-435B-A33F-853290027B27",
    "19500.pdf": "09CAC093-182E-4DDF-86F5-60BAB091E10F",
    "19501.pdf": "02501C95-6474-4EA9-AA54-03738F5DCE3F",
    "19502.pdf": "03684958-256C-47FA-A462-211B755DC5A3",
    "19503.pdf": "8C0CCFE5-7FDD-48D2-8F55-6352BE21E8E1",
    "19505.pdf": "CF919832-1132-4415-B1F3-650619D27702",
    "19506.pdf": "10057035-3C45-4E0D-B3C4-388066089AEC",
    "19507.pdf": "2D92FA99-4DFE-47BF-8AC9-9FED4AB3C1D5",
    "19508.pdf": "CA4F5480-BD4F-4AB8-BC7B-61A4459A8D99",
    "19510.pdf": "7B69C538-1A40-491F-9D30-FB361A58474C",
    "19512.pdf": "B4F94420-AE31-42A0-8BB5-01AF5E532815",
    "19516.pdf": "ACDED272-6EAB-4A74-97AD-5E4952714923",
    "19517.pdf": "9831503A-1266-4F9E-8A1A-41E6B37ADCB1",
    "19518.pdf": "AAD4C075-CE56-4D6E-A66A-9D6F421A9D6B",
    "1951PROGRAM.PDF": "B3CE7E08-9116-4642-B5DB-4A3A91A4F49A",
    "19526.pdf": "8DC84234-7B6A-498C-B784-329E3E0B59A6",
    "19528.pdf": "563D0208-70A7-43D4-897D-993ADF32A985",
    "1952Newsletter.pdf": "E4163E60-6A9B-4470-9C75-4646E5A77790",
    "19530.pdf": "CDA9EA15-2FE0-4CFE-9E1B-35F30331663E",
    "19532.pdf": "92DCC48B-ADF4-4173-9749-87281FD02BAD",
    "19533.pdf": "8E11B8CB-5F55-4576-929B-67FBAFC0D944",
    "19534.pdf": "A250BAD7-B31A-4FEC-B96F-A234B701CCB7",
    "1953Newsletter.pdf": "D96B5FD1-4CF9-494F-B639-033D5405E96B",
    "19540.pdf": "A8562FFF-7999-4A57-989D-B87E83E13152",
    "19542.pdf": "0DA19381-2A7F-4C53-937B-051D0A1F1F52",
    "19544.pdf": "5887487F-D97A-4679-ACA0-1359F5AECBB2",
    "19547.pdf": "56F201E3-D4ED-496A-91E7-16FB186BEAE3",
    "19549.pdf": "76067C27-1AE9-4953-8F56-29017919F704",
    "19550.pdf": "1964ECD3-8D0C-44E6-8E98-F9CD74354C19",
    "19552.pdf": "B9F502FA-D9B3-4D8E-9137-CD7AA3640386",
    "19553.pdf": "D093E498-B8D7-4370-A991-4D842A0712AC",
    "19554.pdf": "33B51347-17F8-46CB-AD1B-4479E28B7D6B",
    "19555.pdf": "03CC4F7F-A5BD-47FD-B7E8-B1A941BF9078",
    "19556.pdf": "99CEB49D-D3A2-49F6-B73C-9864D354A43D",
    "19557.pdf": "89C71E87-FE49-4240-9AA3-CE57EFD29333",
    "19558.pdf": "C72D567C-A208-4281-B68B-2CDE34EC1098",
    "19559.pdf": "427E6DE0-2F42-4215-A083-1D7D08F25B89",
    "19563.pdf": "CE7FCB68-AFC9-4F8F-AD85-4F3D0C7C1E96",
    "19565.pdf": "93248114-3512-4347-B6C4-45DE3CD79FE9",
    "19566.pdf": "47612076-B3AF-4D3A-8250-9CA66CBC9514",
    "19567.pdf": "14267029-29AF-4ADD-898D-3957A2D4C70B",
    "19571.pdf": "C6B00BDF-8ECB-492A-B812-15A44415BEC1",
    "19572.pdf": "90846D9B-E039-437A-849D-4291A510581E",
    "19573.pdf": "7CCEDBA7-D89D-489D-8995-B6E49B8E9DDC",
    "19576.pdf": "E9FED74B-8272-4CA4-973F-8A245346BECC",
    "19578.pdf": "D981CFA6-15ED-4640-8A08-E97BCFD2EE22",
    "19579.pdf": "F3A29982-0D77-4878-9914-6A2A62586D18",
    "19580.pdf": "A3910CC6-5C86-4846-A726-1817569D818B",
    "19581.pdf": "ADE75776-F18D-403D-A34D-52321CAC925F",
    "19583.pdf": "55D59E46-449F-41BF-A16F-BCFD72BDF3E6",
    "19585.pdf": "A0AB950B-E8F9-4E8A-8366-5A45C0D2A381",
    "19586.pdf": "CB78D4B1-2472-40FD-954B-437F7538E5D5",
    "19587.pdf": "EA82D50F-396F-4B5F-B36D-5B6ADE97B527",
    "19588.pdf": "428B2166-7D04-4F00-BF66-9550A5A3A214",
    "19589.pdf": "B8461A0B-F33C-4B57-9816-94FA0F40A723",
    "19590.pdf": "D8D0AFD0-8E82-40CE-A385-E04C17FA08ED",
    "19591.pdf": "D328245A-7C82-4355-8CE4-8082FA1B3DF9",
    "19593.pdf": "AF940A66-7F89-4159-8F36-302E6790F62E",
    "19594.pdf": "6812AF2D-2192-4EA3-A1E9-A8E4FED9BC1B",
    "19596.pdf": "0773A5C9-B928-4F7E-9BF1-42CE03957DAA",
    "19599.pdf": "8419B6BC-D05C-4ED4-BDCC-C26912CF606E",
    "19602.pdf": "849CDB81-DE50-4C51-891E-54E2BF5968DA",
    "19604.pdf": "0473365F-1A93-4339-9168-9EDE95A80A04",
    "19605.pdf": "29CC06B0-6B93-4B9C-9F89-2AD8E56ABEDF",
    "19607.pdf": "70AD1407-99F5-4D98-97AC-C68079D0552B",
    "19609.pdf": "DFF2F559-1713-427E-A1D8-AD63220E18A5",
    "1961 photo_compressed.pdf": "74124580-BDD3-44EC-A25B-8C91D471D7D1",
    "19613.pdf": "BCD982F1-94B3-474A-818C-622F5C9FF8EE",
    "19614.pdf": "002A69D7-0572-40F1-8115-259993AE12A5",
    "19615.pdf": "563FC168-E84B-45AC-B9D3-0627F1CD5546",
    "19616.pdf": "B439C263-92B3-4D51-8851-1A2BF028BEF0",
    "19617.pdf": "DD797DB6-2AB1-4930-9D61-8ABBCCF4D701",
    "19618.pdf": "80005AFA-1F90-48AA-ACAE-0FA390635709",
    "19619.pdf": "7293F149-C3A0-418E-B1BA-B24C770DEB31",
    "19622.pdf": "29EF8B0D-8444-413F-A2CA-F9FF2085AD79",
    "19629.pdf": "C25BEEB3-95F6-4FFD-9E21-F3815C034B03",
    "19632.pdf": "60FB2E63-1081-43DA-A19C-858FCAEE99A8",
    "19633.pdf": "157D1FE9-4DCB-4F39-8B72-51C813228D8E",
    "19634.pdf": "9B5BC7F4-432A-435A-87A4-2C3C61A0D09E",
    "19635.pdf": "A9EDAF46-569C-46F3-B3D7-8EA3B2BA9B3E",
    "19636.pdf": "B488277B-C57E-4577-AC8C-F63E653D5839",
    "1964.pdf": "F48C7CC5-496E-49A9-B30B-3DE7E250C0E2",
    "19640.pdf": "0C077720-BC79-4B2A-B020-AD32A04C7B46",
    "19641.pdf": "3572789D-8E15-4485-986B-F3DD3F32B120",
    "19642.pdf": "4E54D4A4-3E69-4BA2-A6CD-FF2891481530",
    "19644.pdf": "071FC3E8-8154-47F3-BA1B-1F664FABD6FD",
    "19645.pdf": "1011C017-693A-45E6-925E-DB0E166F91FB",
    "19646.pdf": "CF9F5649-6B8F-4842-95F3-99608F81E709",
    "19647.pdf": "06F0A934-E6B1-45A9-AD95-DAD32AF4FFC7",
    "19648.pdf": "52ADA884-AB0F-4F23-A221-AB88AC11B433",
    "19650.pdf": "DCD24284-DDC5-4B9E-92EB-BD90D2A23C61",
    "19653.pdf": "4D8C9F54-2D55-4417-AD96-0F3FFC1A2885",
    "19654.pdf": "7499FE02-600D-4D34-B0A6-8E2016A4362E",
    "19655.pdf": "63C72B70-4850-4467-AB83-7AD7839CBDBE",
    "19656.pdf": "C385A59E-B96F-48DE-8520-CC25BB00FA38",
    "19657.pdf": "5C80022F-8E90-4175-A676-B63D59E15C4F",
    "19658.pdf": "CB6B5B90-0C7F-493A-9EE0-565FC181C48B",
    "19659.pdf": "EA9C5E0B-1D12-4C1C-9E91-1E77549D29EB",
    "1966-DrKubalaappltrs_20140102.pdf": "6EE9D4B6-EFAE-4527-B19F-E0C4A4F9E3D9",
    "19661.pdf": "2AF18C59-AEBA-4B63-B553-57F4516A59DA",
    "19662.pdf": "37ABCED3-4F25-4703-A124-DCAFD08B8AF1",
    "19664.pdf": "2C03C25B-1A72-4D8B-8C12-5BF22EEFED3A",
    "19666.pdf": "681D6839-9635-47B8-A7BF-312565B74271",
    "19668.pdf": "6D46C9A7-A740-4944-B2F9-7F7212CBF55B",
    "19669.pdf": "4A912495-E9C8-45BB-A590-6181868B02B7",
    "1967-12-XVII_20140102.pdf": "DDD3D3D0-A7A7-4F07-9A1D-E05A358CA574",
    "1967-DrCoxeAppltr-DrKubala_20140102.pdf": "66DAAA7C-C433-4744-9E8C-97B7B9A9E486",
    "1967-DrCoxeltrforDrKubala_20140102.pdf": "56092DD8-75CC-4D6D-ABD1-4E88EF5063CD",
    "1967-KubalaAcceptanceLtr_20140102.pdf": "1D33F7B5-F5BB-4805-BCD6-04ECED24BDAD",
    "1967-KubalaItialFeeLtr_20140102.pdf": "2984BAD2-308D-43DA-A80C-368EB93FDBC1",
    "19671.pdf": "C8FDAEAC-9944-4BCA-9C25-6EB5D55B1EBF",
    "19672.pdf": "DB7CAA6A-9843-4EE0-B4A1-70FCEE2C4D4B",
    "19676.pdf": "9E69561D-4837-429B-B991-24D56913CFE9",
    "19678.pdf": "9F9C6C90-A82B-4CAF-90DD-E6D9EA0D16B8",
    "19679.pdf": "846DBC11-FC89-40DD-9360-0B5D8601C77B",
    "1968 toronto guide.PDF": "D443B972-CEAD-4D86-9F92-24D401A8F226",
    "1968-2-XVII_20140102.pdf": "2E144630-05C1-451A-A76B-5068480CF81F",
    "1968-2-XVII_20140102_0.pdf": "3C5022E7-7DDF-4FD0-894E-A8EA8A153E0A",
    "19680.pdf": "ABFA7E08-BFB6-4034-80A7-673B592BFB65",
    "19682.pdf": "3F84A585-628A-40A6-BE84-122912393E81",
    "19683.pdf": "0B7CEE14-1B5F-40DA-B79B-06430DF14231",
    "19684.pdf": "8BBAC2C8-F09A-47F7-81D6-0DD69EB88B85",
    "19686.pdf": "72279B4D-280E-40B1-84BD-BCAC1061DF4A",
    "19687.pdf": "2C648A68-DE18-4307-9DE5-02F0790723C3",
    "19688.pdf": "E6A77317-CA34-43BD-A605-B965978F2980",
    "1969-2-XVIII_20140102.pdf": "A7F72F5A-F884-4F1A-8040-6B028517483B",
    "1969-2-XVIII_20140102_0.pdf": "5230E027-DB7F-4D47-AE7E-E764CF564CD1",
    "1969-6-XVIII_20140102.pdf": "18383EBB-D6EC-4C41-B33D-06A881DE0FE6",
    "1969-8-AuxnoteforAnnualMtg_20131230.pdf": "92F4F636-70A9-422B-80B9-31CCED6C229E",
    "1969-8-XVIII_20131230.pdf": "1487EDDC-88AA-412E-8F88-674F61B98189",
    "1969-AnnualMtgResponseLtr_20131230.pdf": "AF92146A-FDAE-4EAF-8757-97BC8897AC24",
    "1969-AnnualMtgSched_20131230.pdf": "6C7FEC64-5DE9-4B62-ACBD-39CDD1B9EA3A",
    "19690.pdf": "EE48A94C-8D02-4AA9-B8F7-138C3F62A6CC",
    "19693.pdf": "7754E74B-D817-4963-9894-DF8969C0AC21",
    "19694.pdf": "7086F734-A14D-423D-8BC7-B3EB253727A1",
    "19697.pdf": "26EB0315-3BFE-40F4-B57E-382EFF460882",
    "19698.pdf": "16DDF20A-C7F2-48E4-9CC6-030B578B7508",
    "19699.pdf": "948E682C-A806-4E98-919B-9A47367D875B",
    "1969PresAddress.pdf": "084A1A2B-EDEB-47DA-8967-27CC2202BDB2",
    "1970-3-XIX_20131230.pdf": "F0D67C26-1CA8-4630-B878-15AF805151D7",
    "1970-OfficialNoticeofNominations.pdf": "BA0C3AEF-6B39-4607-8095-1C020FAE6079",
    "19706.pdf": "3926C5D6-DF10-47C2-B302-9AC559825245",
    "19708.pdf": "9024CDC0-9212-46BD-BD3D-B58C3861C408",
    "1970PresAddress.pdf": "D44404AB-B42B-4E23-8FAC-A7ACAC5D26A6",
    "1971-1-XX_20131230.pdf": "03AC5922-6019-4AAF-8992-93465EAFF8D1",
    "1971-3-XX_20131230.pdf": "9B5C7B7A-031F-4428-99AF-4AC45D0F081D",
    "1971-6-XX_20131230.pdf": "5CEA46AA-E231-4C1C-BFC1-9E2457570B18",
    "1971.pdf": "83DFE1BB-C612-4FA5-A7E2-8CAD9EF8B7C7",
    "19710.pdf": "5D33AF3E-FDB5-40B1-9C19-40A28051A116",
    "19714.pdf": "5989F9AE-ADA2-4C10-BF8E-09A28D6EC7CA",
    "19715.pdf": "470E3367-49BD-4DCC-BB3F-0FC74DCD1CE6",
    "19716.pdf": "FCD16964-86B5-47A8-9C34-D283E8F2E06F",
    "19718.pdf": "E95D0DDE-39F6-47D9-8DB6-AE65D39B466C",
    "19719.pdf": "67A6A211-1DA3-4B40-B832-C174CBF14598",
    "1971PresAddress.pdf": "8697CAFB-34E2-4CE6-A8EB-20970B3F3D72",
    "19720.pdf": "85D82490-7464-4B77-91DB-98E1C36CB611",
    "19721.pdf": "D0BCB9A1-3F12-40F9-AEDE-B30E976AAD7B",
    "19722.pdf": "94959EC8-8D52-488F-913D-EA5277124063",
    "19723.pdf": "74F7AB7D-3F7D-4A99-88A1-8F3519529B76",
    "19725.pdf": "4BC5B9B4-BDBE-4438-8A73-EFB124CE69B6",
    "19726.pdf": "CEAA633C-C135-4AF1-BEF8-786E6613E590",
    "19727.pdf": "B557BC86-9109-4FB3-886C-2BA819A35B97",
    "19728.pdf": "4D116BDE-BDD7-47F5-8624-E5334E96C73E",
    "19729.pdf": "414FC6B1-7E4F-4224-A426-E22B5307B581",
    "1972PresAddress.pdf": "3D8BA776-472C-4BDC-AFBD-2D9F463B1788",
    "1973-12-XXIII_20131227.pdf": "FD95F47A-D4A3-40C7-9100-B0B9F005F6F2",
    "19730.pdf": "4E0EFB94-6FC4-448A-9237-DE31BC13DC75",
    "19731.pdf": "547C59DF-27EE-4FF4-915A-4A9C82E2D00E",
    "19732.pdf": "B0B63C98-D6F6-47E9-9F24-783401941DD0",
    "19733.pdf": "D4809941-737A-4EE1-B36C-953154A86AA2",
    "19735.pdf": "74B78997-780A-4E11-A361-9FE0836BC514",
    "19738.pdf": "3CD22B23-B481-4EC1-A634-DC55E4C3A6A0",
    "19739.pdf": "CF7EF32B-6C5C-4257-B048-4C7FCFA5C8B6",
    "1973PresAddress.pdf": "E497CA13-C012-4885-8C09-0514BDF0A56B",
    "1974-12-XXIV_20131227.pdf": "F527B0B6-6F96-42E0-87BE-4A4F3C537A96",
    "1974-6-XXIII_20131227.pdf": "6B81109A-207F-43C0-9AF2-A8BC018200B8",
    "1974-8-XXIII_20131227.pdf": "5E43E97D-F464-4F69-9CB7-DB13E9C60C91",
    "19741.pdf": "532657ED-8BB5-48F9-BDE4-41502A596F85",
    "19742.pdf": "FDACA989-A3E2-4A7A-8E4E-11527B4D60D4",
    "19744.pdf": "02F129C3-B17B-4CB2-A77D-7961BC4B28A9",
    "19747.pdf": "10872490-D564-4A30-B4E0-8B2BFB63A3BA",
    "19749.pdf": "57E1D522-EC85-408F-B071-EF78E337E804",
    "1974PresAddress.pdf": "5E1C9432-CF13-4444-ADAF-862DFEBF6D02",
    "19750.pdf": "3161F469-6FAE-4050-9264-432D39798BF7",
    "19752.pdf": "198CF34E-2CB1-4323-A313-B7A8D09D0392",
    "19753.pdf": "B383AB4A-19FF-4732-A9D0-32C1DF62EB31",
    "19754.pdf": "3ACFAC94-A89F-4841-80DE-0CA3431A64F8",
    "19755.pdf": "30D1574C-EECC-4FFB-9EA3-4172503D6594",
    "19757.pdf": "00E035DC-1E96-4358-9978-B939A73D85F2",
    "19758.pdf": "7693202E-3A8A-4CA7-8F2E-4A0A694049AB",
    "1975PresAddress.pdf": "2AAA34D3-B4AC-4C48-90C8-2A2947800D54",
    "1976-4-XXV_20131227.pdf": "0C663A25-9DEA-4479-9F53-E910CDCA2D78",
    "19760.pdf": "44381FE2-2AB5-41A4-B4BF-C8C20F51F553",
    "19762.pdf": "A5D07CF7-AADC-4183-AE10-075ECB8AE256",
    "19763.pdf": "481E7FA8-174A-46FE-86F5-E86D6BF77412",
    "19767.pdf": "5790228C-D4D9-4653-A93C-CED772143793",
    "1976PresAddress.pdf": "4E9C597A-CD72-4F25-A53B-91DAE20F6EF9",
    "1977-1-XXIVPart2_20131227.pdf": "1BA4F0AB-3C99-41C9-B19D-65DCDDE5B534",
    "1977-1-XXVIPart1_20131227.pdf": "272F750A-4E90-4799-9D5E-95064885F4FA",
    "19770.pdf": "B379B7A0-4750-419A-908A-764D74E3496B",
    "19772.pdf": "00D37059-D932-4EBC-A049-2E98811B4759",
    "19773.pdf": "D54E01DB-DD85-4E5F-A3EA-2429B0FA58A8",
    "19776.pdf": "E70B33C0-4568-4DF5-B50D-413CF7C467C1",
    "19777.pdf": "1B7A5BC0-266B-47AD-BE81-A66349D3B10A",
    "1977PresAddress.pdf": "D1768662-D5CC-4BC4-AC9B-4617A89E1ECB",
    "1978-11-XXIX_20131227.pdf": "E63DD4A2-79D7-46AD-9768-1C664EADCCB3",
    "19780.pdf": "54415FBD-D5BD-4EC9-81E3-A60E10CDDB19",
    "19781.pdf": "74472DD0-B496-4EEB-B8D9-1C8858480F6D",
    "19782.pdf": "4D0DF74E-8E94-4B02-B44B-F20FD55CD7E4",
    "19783.pdf": "71D4EBF9-D3C6-4549-96A5-EA8E8DBEC383",
    "19784.pdf": "52F0215C-EF66-4B47-B701-36A2DC481FEF",
    "19787.pdf": "3920469F-6391-479B-A933-B73731557665",
    "19788.pdf": "85E7FCA6-479E-4F77-8E52-7B0E18C4606E",
    "1978PresAddress.pdf": "5BFFF2FD-ACED-45D0-8318-1B51D1F64E41",
    "1979-12-XXX_20131227.pdf": "22446DC6-6D76-4FEF-8F99-BF57C6097D30",
    "1979-8-XXIX.pdf": "E86DF559-6E55-40E4-BB7B-D3729584946F",
    "19790.pdf": "E4E305FD-9731-467D-B7DB-C4D00F8166C2",
    "19791.pdf": "B87A173F-6F21-4367-B98D-E05970DCD8F5",
    "19792.pdf": "D56927BE-5DE6-43CB-A17B-A8F92AC407B4",
    "19793.pdf": "9603767D-7FA9-4BDA-8B43-A4AA8E595C81",
    "1979PresAddress.pdf": "20A32E13-6A38-4863-A7E6-DB88F52099AB",
    "19800.pdf": "B37E11F3-3D29-444F-842F-331B4084D0CD",
    "19802.pdf": "95C21570-6399-4BBE-A222-DF6631450433",
    "19805.pdf": "50ECA436-6DEA-4DD8-8B50-9C3069C80035",
    "19806.pdf": "D17FD057-9CE8-4012-9D2A-B79C9F07F045",
    "19807.pdf": "0B3365CD-29CB-4732-BCA6-D1EFB679D67D",
    "19808.pdf": "2F4D7B09-9413-48A3-B8F8-09082277AE2A",
    "1980PresAddress.pdf": "99D780AC-D103-4816-BC8F-146484741D97",
    "19811.pdf": "BCE91901-33CA-4A33-B444-52F97ECC79A7",
    "19812.pdf": "150D18D5-1ED8-4CA5-A60A-B90077AEC439",
    "19815.pdf": "FE5E91D7-58F1-4460-976B-75967ED049E2",
    "19816.pdf": "D775B73D-AFFE-4E94-9FBD-739ECDCB70F0",
    "19817.pdf": "134F3E0A-73EB-471B-A115-E86901EC9917",
    "19818.pdf": "E83AF3BA-CA7B-43BE-9E42-98448C66E19E",
    "19819.pdf": "6039EAE9-EFC6-4E1E-8832-99FB65A5DB8A",
    "1981PresAddress.pdf": "28443ED0-5780-4257-AC0F-31475028A21B",
    "19820.pdf": "BFE6FF4A-3A0B-463C-9E8F-BD744B008B5B",
    "19826.pdf": "CD7639DD-0920-46B7-9D84-2C2DE670EDD9",
    "19828.pdf": "32AE43E0-6D8A-4A57-A8D9-8F94798C68BA",
    "19829.pdf": "C1E59EF6-ED7F-44F1-A5A9-7CFE70B9F258",
    "1982PresAddress.pdf": "90E0B188-EA75-4FF8-85CB-022EB30CEC65",
    "19839.pdf": "C36D7C82-26C0-4019-AF98-C0FC52A4195C",
    "1983PresAddress.pdf": "B9C0612A-ADB4-4073-96BD-2E561B7E11A6",
    "19840.pdf": "3E2273C1-0EF3-47E2-849F-388C95A3AE2F",
    "19841.pdf": "9DDA51DE-2DCC-43E4-859C-039EB7066215",
    "19842.pdf": "3FCE7DFA-B8CE-407D-A490-E72AEFD78534",
    "19847.pdf": "54640A03-FE21-4896-962C-ECEA32C19914",
    "19848.pdf": "20651643-DAC5-49F2-8EFD-6A123EF892F2",
    "19849.pdf": "143F339C-4114-4ADC-8B58-F102277E464E",
    "1984PresAddress.pdf": "D54EE0B6-2C6C-4CA7-917C-00954D4179A1",
    "19850.pdf": "9351B662-0059-4DF4-9A04-A31FC0927284",
    "19852.pdf": "070152AB-B59D-4EBC-B8D4-191371165E4C",
    "19855.pdf": "D72E8391-DFA8-4D6B-A5C0-0D8893B4423A",
    "19857.pdf": "9FCCA488-27D9-437F-8D2F-D506467D276A",
    "19858.pdf": "666F9CF3-F7EC-4353-B52E-DA494E462C08",
    "1985PresAddress.pdf": "DB872C73-F30F-43D4-8313-066A2CC06161",
    "19860.pdf": "ADF8234C-331E-4367-B15F-A88F9C21853C",
    "19861.pdf": "EE5D7315-26E6-49DB-B222-2A2DAE21F405",
    "19862.pdf": "71132ECA-47AC-47B5-9933-DD190E47588B",
    "19864.pdf": "4BC797C8-DF43-4942-A484-9D043E221295",
    "19865.pdf": "8421F066-768F-463A-8931-8377589B3AC2",
    "19867.pdf": "873D128E-B6C5-4539-B134-6AD1C60270AF",
    "1986PresAddress.pdf": "79B439B9-42AC-4F1C-B2E4-7D92C893A955",
    "19870.pdf": "0FD4F50B-8A5C-4CE2-9708-04307050E243",
    "19871.pdf": "F5FEC917-C79D-4A93-91C2-0D029CD04800",
    "19872.pdf": "A9BD5ED5-BDE4-4EB7-8D14-320E0BC1F508",
    "19874.pdf": "4297D3F8-597F-4D3B-91BE-77AFA4D8F1CC",
    "19875.pdf": "25B5E740-2A96-4FAA-993B-5C528DA22354",
    "19876.pdf": "E88561C6-8AF5-4DD7-96EA-4111B08D6B98",
    "19878.pdf": "1A1888EC-6EE6-4147-9C9F-CD9910E2F147",
    "19879.pdf": "025579AB-07ED-408B-8279-8D25E2C0EF75",
    "1987PresAddress.pdf": "6F234BB6-F924-46D1-B0E4-68561CA4A6B9",
    "19880.pdf": "3ACAA95E-755C-4436-8B03-92F6D11E37CC",
    "19882.pdf": "AF7607F1-2FD9-42AC-B716-C97C95633CAA",
    "19883.pdf": "0C957387-E9B5-4EA1-8228-7C334F747FB3",
    "19884.pdf": "FDE290BE-03F3-4130-A8B1-32AB25FB01F0",
    "19886.pdf": "C57016D4-114B-4B14-A615-E721EB06304A",
    "19887.pdf": "D83D1084-5807-484C-89D4-FF5554C2B6E6",
    "19888.pdf": "E6FC5056-73EC-4F2E-BBA7-F8E42E884480",
    "1988PresAddress.pdf": "0D774491-1F10-48EA-9BC9-024941F074F6",
    "19892.pdf": "F726769B-8881-4D35-B266-546CE6825AE5",
    "19894.pdf": "6DEBED58-90FF-402C-981C-A708FB992FD4",
    "19895.pdf": "DB07B206-FF83-4924-9891-19A490746C74",
    "19897.pdf": "87A56453-E6B4-4A8F-BDCA-4EAE608E702A",
    "1989PresAddress.pdf": "0FF388CC-42D9-4202-AC52-460538D4184D",
    "19900.pdf": "47F76305-DD12-41AE-8A8F-DD1088FCE80B",
    "19901.pdf": "1E7C8048-A3E0-4B4F-8921-9549ABD4BD6A",
    "19902.pdf": "092EBA88-FC63-423B-89CE-46C3B66646B0",
    "19903.pdf": "5BCE3944-E64C-42A1-B84C-E6CAE8EBF7CA",
    "19904.pdf": "FD8A1EEE-D229-4F3A-A499-E3A4A49CD245",
    "19907.pdf": "5FF70C9C-61A3-4FD4-828C-17F73FE536BD",
    "1990PresAddress.pdf": "6CDA9FF2-15D4-48EF-B7DB-09B3A7B7193D",
    "19911.pdf": "AF86B44D-D075-463E-B109-0BDE2C493DF2",
    "19912.pdf": "F5DB886D-C078-4B6A-A562-48256DC8A268",
    "19913.pdf": "9D6B167C-B81F-426B-AA40-3545B487D305",
    "19914.pdf": "F366DF6D-EC23-4AC0-838D-73DDF840EFFE",
    "19915.pdf": "7932A485-26B7-4BDC-BD30-C7203D47C45C",
    "19916.pdf": "93A1D1F5-5331-4CF2-941B-63C19374EFFC",
    "19917.pdf": "6E97DA6D-2026-43B3-88DA-3C9F38483C63",
    "19918.pdf": "A95EFFE5-2C0D-4433-81F4-F30CF798DB38",
    "19919.pdf": "4C250A55-AA03-4D13-868F-BF45115EB07F",
    "1991PresAddress.pdf": "AE02D927-7F9C-46E6-BCF6-44BFDF9D63BD",
    "1992 Clinical Neurovascular Seminar Program Book.PDF": "95496121-00DB-4BEF-9E11-3D4E53B9ADCC",
    "19922.pdf": "8191B03B-CF96-44B6-8E4C-7D5FC2B8B14E",
    "19924.pdf": "3431DFCC-BDCB-42C9-9C8C-2B5B27632347",
    "19927.pdf": "41C97696-927B-4340-A87A-3D07BBBA9FCB",
    "19928.pdf": "924E0B64-07AA-45B3-968C-FEE8BA4A76AD",
    "1992PresAddress.pdf": "93804C0B-0F72-41BB-A2F1-18DFC101B46C",
    "19933.pdf": "54EDA67B-A64B-4F6E-B8AE-F0EC01A64F7C",
    "19935.pdf": "E6FEDACD-D754-4E00-8A99-7E3C7BCEECE2",
    "19937.pdf": "346D4C82-9192-4228-AE66-E711B59C1A3F",
    "1993PresAddress.pdf": "B5E3C09E-8369-443B-92C4-1A7E58379CA1",
    "19940.pdf": "EDFE8572-58E0-4D53-9D36-2D38DFFE9983",
    "19942.pdf": "A2B09D6D-3ED2-415C-B731-9B855ABED2E6",
    "19943.pdf": "871F8808-1649-47AA-8EF7-6B2826F35924",
    "19945.pdf": "4D9D829C-061E-4A8E-8A5D-77964F7D73F9",
    "19946.pdf": "5AFA2EA9-04CE-4C54-B895-CD19CF5CAF35",
    "19948.pdf": "B98B943D-F15D-4372-BC2A-A3EE7B40A46C",
    "1994PresAddress.pdf": "F338E65F-D0BF-43EA-A9F6-663E690C1BD4",
    "19952.pdf": "C69F4AF4-DDA9-4F03-A356-98B7DDE8347A",
    "19953.pdf": "42F82579-9A02-4EAE-A3F5-F33FE718BF88",
    "19959.pdf": "6876074B-993A-420E-8297-3721A7E48285",
    "1995PresAddress.pdf": "11E73E70-B5C4-438B-8C70-3460FC8CC299",
    "19960.pdf": "E1705B39-5947-4CEF-AB30-41E82027CD10",
    "19962.pdf": "B50C4688-4E1F-4488-9EBD-6816841AEB17",
    "19963.pdf": "861009EA-2B2C-4A4F-B100-CD8BB18EF8BC",
    "19964.pdf": "8DF91C89-B7A0-4C4C-AA95-94FFBFF9DB25",
    "19965.pdf": "3FD1A88D-1C6D-4118-BD30-776B9E7D6BBD",
    "19967.pdf": "A4DE1838-F19C-4C45-94B1-E7D443219D4A",
    "19969.pdf": "2BF862D4-E24E-471A-B95C-F8931320B5E1",
    "1996PresAddress.pdf": "7E9C91C3-9D38-4933-A6E8-0276FF1E7203",
    "1997 history of CNS.PDF": "AF4052E5-DEDE-4FA1-8D25-2B1BF1188C3F",
    "19971.pdf": "BCD22FBD-196B-45B4-B3E9-B246291A2607",
    "19973.pdf": "E1847FE8-97A6-446E-95CA-C3C17BF03E71",
    "19974.pdf": "C0980638-6473-4F5D-BB94-33AC19EF6541",
    "19975.pdf": "BC9883C7-C7E0-4F56-81C5-AA2262759F97",
    "19976.pdf": "60012A96-7CE3-4AD5-80A0-BEE4FFB8F21C",
    "19977.pdf": "0BA94FA8-2EB8-4EEB-A3A3-C01B52A3972F",
    "19978.pdf": "04FA4D43-1494-491C-AF37-B2ED5B7B4033",
    "1997PPBK.pdf": "94BE2A81-595F-4DE6-9397-6A8C4BBD9C5C",
    "1997PresAddress.pdf": "601805E2-990A-49E2-9BF8-0D23D5DB18FB",
    "1998-1999YNSDirectory.pdf": "3D9155C5-6050-474E-8271-167BEDF1CA1C",
    "19981.pdf": "81FBEE2C-F594-4116-B590-EB72E4ED7253",
    "19982.pdf": "FB930383-AAC4-4348-B657-EB81D931024E",
    "19983.pdf": "B9D63CB7-EE28-4B41-8953-ACFB9C8EF385",
    "19984.pdf": "19314B6C-1CB0-4215-985F-74B520EFA2AD",
    "19986.pdf": "F84E0EB4-B514-464C-9543-EEA1B80025A6",
    "19987.pdf": "4DE3FA6D-8D68-480A-8F91-9D2DF4E86DD4",
    "19988.pdf": "338B6868-38AB-43D1-BD21-F81B9BDBC5BB",
    "19989.pdf": "636E473C-EF2D-4DD5-B963-8ACEA2963BF8",
    "1998PPBK.pdf": "93519BC6-B977-4A7A-A8C9-4350EBB505B9",
    "1998PresAddress.pdf": "E110B53A-2549-4E7E-BD45-05E0AC97F73A",
    "19991.pdf": "4A5BF626-DBA2-4CAF-9A31-E66627540E29",
    "19992.pdf": "FCC33E64-1291-4B69-84FA-B4AE4EB2FDC4",
    "19994.pdf": "FA611F93-DC8C-4398-A6BF-B58E6172D55E",
    "19996.pdf": "69E709CE-E1A0-4CEC-AADA-E71B1751A9A9",
    "1999PPBK.pdf": "7757907E-00BE-4144-9541-764A21D2E4A9",
    "1999PresAddress.pdf": "B3EBB3D2-4D62-45A9-8D3C-44DBE27097F3",
    "1a.Previously_Published.pdf": "9A6A06A1-11B8-405B-86B7-11A8F408D43D",
    "1st International Congress of Neurosurgeons.pdf": "235E779B-1D30-4725-BB92-03A65E25E5FE",
    "1stInternationalProgram.pdf": "9B4DFDB5-E235-407B-AE56-1BE9844AA7B5",
    "1ucsfNeuroSurgBlast2022sk_horiz.pdf": "3A2860F7-7110-4CB9-AA74-32B3DD97D543",
    "1ucsfNeuroSurgBlast2024sk.pdf": "64CFB2C8-629E-48E7-A87E-D968B36A4760",
    "1ucsfSpineSympBro2020sk.pdf": "02BE20B5-40CF-470F-8015-19C15491FA47",
    "1ucsfSpineSympBro2022sk.pdf": "8185F91C-ACF1-48E2-B741-FB4C888FEC52",
    "2 CNS poster Subu.pdf": "078168DB-F357-4BA5-8DAF-D48CA7C43F2D",
    "2 CURRICULUM VITAE.pdf": "6C75E78A-FB3F-4A08-A824-F1B288C579A4",
    "2 Personal Statement_Yaghi.pdf": "90323014-D3AC-4693-9DDE-74B7A6DF679A",
    "2 surgical risk assess.pdf": "36C79E95-9A40-4B3E-A846-3C5B563D684D",
    "2 Tumor_4-21-22F.pdf": "4704627B-C41C-4778-B3BB-811CA660A821",
    "2-1 Depression_Coiling_Poster.pdf": "BB9D530C-428C-45E0-B6FA-F103EC6BFBCB",
    "2-1-2013PedSubLoanRepaymentLetterToOMB.pdf": "22386680-36CA-4991-9234-16E51C8457BE",
    "2-10CV.pdf": "1964F596-513B-44CF-9052-C7444D53BA5F",
    "2-4-21_Tables-Cervical Robotics CLEAN.pdf": "1AC4A88A-E81C-45BF-B51C-65644582C5EE",
    "2-Sieg-Trauma and CC.pdf": "22EA21B2-51CA-4CBC-9C81-AE360945932A",
    "2. Sample Wellness Curriculum.pdf": "13EFA71E-CB8E-4F78-8B7A-4F64209540BF",
    "2. TITULO APOSTILLADO.pdf": "1FA3BF13-44D1-4CA5-8FE9-E170B9B9CD6B",
    "2..pdf": "9AF71B99-8F3F-4818-856D-E24110807595",
    "2.7.17_ad_hoc_group_letter_on_fy_17_nih_funding.pdf": "C96760E1-9D25-4EFB-8B5C-F87555D066E7",
    "2.CURRICULUM VITAE UPDATED.pdf": "5CE64F42-6BCE-4780-8B18-11EA855C2298",
    "2.pdf": "4A1F8BA6-2033-48E6-B73C-8F8E4EE2DED8",
    "2_32_01_scan143141781.jpg.pdf": "A427C436-15C2-4C46-9973-0CFFE54A23A8",
    "2_cervical CEAs.pdf": "7102B482-E603-4D04-9D3B-1655512F36F3",
    "2_international_tuberculum_sellae_meningioma_study_.9.pdf": "F5124782-727A-4086-A270-9CF5EAA559F3",
    "20 Transcript.pdf": "BC2B2CE1-8736-4F54-B0A4-711D8AD372A4",
    "20.12.3 Doc Members of Congress EM Letter.pdf": "476DB555-FE6B-432D-928A-A37804844753",
    "20.4.8 Appendix A.pdf": "CAD7DA3D-2671-4834-A05F-AD50685C2D9A",
    "20.5.14 Meningioma Nutrition Tables_CNS.pdf": "325F275D-E83E-43DE-8441-2162115DD1C9",
    "20.5.14 Step 1 PD_Figures_CNS.pdf": "701E8BAE-3D0D-4A57-A751-5FC2B929D296",
    "20.5.15 Chordoma Ribavirin+XRT Figures.pdf": "2ACAA0FB-7EBA-41BF-825D-1A7A0701B3C2",
    "20.5.15 Discharge Disposition Model Figures_Final.pdf": "E889D7D0-BEAB-4EC8-A04D-559AB402C492",
    "20.5.15 Discharge Disposition Tables_Final.pdf": "09C0ADE4-DD9C-43C0-9E6F-750958171927",
    "20.5.15 GBM Malnutrition figures_CNS.pdf": "FFF4CAAD-3359-4B5A-A58A-6DD536215962",
    "20.5.15 GBM Malnutrition Tables.pdf": "AAD72BFA-C465-4580-9AD2-A260C3158E8F",
    "20.5.15 mFI-5 Brain Tumor Outcomes_Figures.pdf": "045EB6B1-7101-4427-A6F2-7C87120C9C44",
    "20.5.15 mFI-5 Brain Tumor Tables_CNS.pdf": "67836C6E-0E90-45E2-8204-3C7C29038E3C",
    "20.5.15 Ribavirin Medulloblastoma Figures.pdf": "A5A47D48-77F0-4785-B961-A9523EFEC586",
    "20.5.15 Step 1 PD Survey_Table 1_CNS.pdf": "077875D1-C083-4025-9DAB-E7041C327CD2",
    "20.5.15 TMT Figures_CNS.pdf": "F700846D-B473-44DC-9158-6E3ACA34586C",
    "20.5.15 TMT Tables_CNS.pdf": "AEDAF24E-F718-4C94-BEF1-AC1A500628D2",
    "20.pdf": "27A0BD56-9AB3-4040-BFD8-9D36C0FAC59F",
    "20002.pdf": "3282B41B-8F44-4D23-BA43-A8BC02C8D056",
    "20003.pdf": "37C722BD-8C8B-4CB2-91C2-FD67440830F5",
    "20004.pdf": "D2C9A1BA-5B45-4546-9169-46321472078F",
    "20006.pdf": "41751215-4EE4-44F0-BFBC-FD528D285912",
    "20009.pdf": "B2D3F00B-695E-45B8-8D77-DDFAD706165E",
    "2000PPBK.pdf": "0B6AAA3D-A01B-4DBB-B7F2-28FED9116E53",
    "2000PresAddress.pdf": "4F0E9B1C-CD1C-44C4-B142-9860718E5FFB",
    "2000RUNNannualreport.pdf": "B8AA80E3-E42D-4E78-83E0-11E03D38B0D9",
    "20010.pdf": "45A775D3-E967-400E-BC0C-3B5D12819A1B",
    "20011.pdf": "1900C800-8FBA-4765-A76F-F2CA2E7A3D6C",
    "20013.pdf": "3BD88602-FD1D-4B6C-B483-4F8CEFD3D188",
    "20018.pdf": "F6AE3F7C-42B9-4076-8102-91E7286F9E5A",
    "20019.pdf": "AF4B1411-EFA0-43E6-B266-5B9C6D090156",
    "2001RUNNannualreport.pdf": "6A95036A-2593-47B5-B92F-A1A9623B829C",
    "20020.pdf": "25DB7CF0-FF99-45A0-9629-5529ABB54680",
    "20022.pdf": "44DE7012-B0F2-4F44-BF24-AA4482408B29",
    "20023.pdf": "FD1C6184-4684-4E1D-9484-8485A0E9E1A8",
    "20024.pdf": "C31C0DB6-566C-40B9-B654-07CABCDAA6B5",
    "20025.pdf": "D20A1A4A-6DFD-4EE3-BC38-F63952A067D6",
    "20026.pdf": "E695F936-9701-467B-8EE3-0C6BCEFB25E6",
    "20027.pdf": "F779C9A7-07D7-41C2-8EC6-6ABCFE641207",
    "2002RUNNAnnualReport.pdf": "703899F1-C7C7-44A8-9A39-1471A4F96484",
    "20030.pdf": "3F0BBDA2-472C-45A8-9EFD-6238A55B680C",
    "20031.pdf": "BB773AAF-E878-4B6B-86CF-44FAD0722376",
    "20034.pdf": "EC7983A6-5869-4B41-9A87-366817AB4514",
    "20036.pdf": "2678627D-4DEB-47D0-A7E7-F0AFE5F9D366",
    "20039.pdf": "01EDE19B-7AE5-4A61-8353-569E56B85A9F",
    "2003PresAddress.pdf": "EB1A9EDE-3D31-424D-BDCD-8BB1BB94A4E6",
    "2003RUNNAnnualReport.pdf": "E3497EFA-D49A-453D-93F2-FA400F789513",
    "20040.pdf": "DA664A77-46E7-4971-A7E8-2739B619D77C",
    "20043.pdf": "22E9E8C3-2F90-443B-943F-0893D5C666C6",
    "20044.pdf": "7C92B983-C4F6-40B4-96E8-97C38C98FFCC",
    "20045.pdf": "3F0BEB5B-BC08-4A0D-AB63-C5C4E681A129",
    "20046.pdf": "5490A5D1-C577-4A5E-B149-A97892374F62",
    "20047.pdf": "A120E47B-07A2-4E91-82B1-1AC12D84892D",
    "20048.pdf": "39569C5A-088E-4DE2-91A4-251C33899B5A",
    "2004PresAddress.pdf": "365095EE-ED8E-48FD-ADFB-57661334CE42",
    "2004RUNNAnnualReport.pdf": "E7D2B2FB-C512-4B45-99DF-680731D26116",
    "20050.pdf": "B8484141-3697-4371-B5B2-B53775B5BD92",
    "20052.pdf": "FB7063BA-7D22-4AEE-B669-E2B67799481C",
    "20054.pdf": "B4A7755F-C96D-4CF1-B812-0FE661BEB4BE",
    "20055.pdf": "BABA9457-DFF3-422F-9DEB-476697E7F934",
    "20059.pdf": "5F5DB341-2679-4B1C-A575-4A24C239F760",
    "2005meeting.pdf": "02502129-38E9-4669-B853-6B56AEAB5927",
    "2005PresAddress.pdf": "FF43A56E-D2D8-45B2-B826-5BC767CEE1A5",
    "2005RUNNANNUALREPORT.pdf": "288D9074-2055-43FF-9C4E-B42E69E29DCD",
    "20060.pdf": "29FF692E-E6F5-4C47-AF49-26078F6D35B2",
    "200608 - ASSFN - aDBS Poster.pdf": "E798CC90-E4CF-42FD-A011-9F4C9201693C",
    "20061.pdf": "9D56CCD8-0ED0-406A-9536-2B0179FF4FFE",
    "20063.pdf": "CA6CAED3-AFED-445C-AC24-4DEAC3B820CF",
    "20065.pdf": "D48A4E1A-5368-4291-8A9A-A3B7C51409AA",
    "20066.pdf": "1DB1354C-E65B-4960-8313-1A2A1AAEAE91",
    "20067.pdf": "192288FD-4A1F-4E50-B14C-50171D6EF7AF",
    "20068.pdf": "479656A5-3BD0-4700-933B-8A000B3353F3",
    "2006ABMMinutes.pdf": "F1A9C703-8E10-4FB6-B58E-42678B8AE209",
    "2006HistoryPresentation.pdf": "91CC903D-35FE-4F4F-BF6E-698FF46E91EC",
    "2006meeting.pdf": "2D5ADC48-7F6A-43B9-8279-3BEBFA40B373",
    "2006PresAddress.pdf": "BB91DCDA-F2D2-44DF-BBA5-9EFEBB3AC8E6",
    "2006RUNNANNUALREPORT.pdf": "8353076D-0815-41E6-84C2-10D436D79602",
    "20070.pdf": "A257A7B9-158C-4010-8432-4115373FF7BB",
    "20072.pdf": "E74BB4C9-46AC-4D73-A7B1-1AD21D49F09E",
    "20076.pdf": "4D520DF5-24F7-47E6-95B6-900DB4D66EDF",
    "20077.pdf": "C4D09611-E787-452C-9749-D07C14955133",
    "20078.pdf": "E28F9D82-0D69-415C-8829-05D7D1C80184",
    "2007ABMMinutes.pdf": "344E9BF6-36C9-4079-B9E0-5B1FA03DEE61",
    "2007FinalRUNNAnnualReportApril242008.pdf": "57DCAEE0-8CC1-40FE-ABF2-F1538557B77C",
    "2007HistoryPresentation.pdf": "0678C401-E7BB-4EBE-999D-25D61CED5E2B",
    "2007meeting.pdf": "ADC68CC6-A387-43F2-B283-64C60B8104B1",
    "2007PresAddress.pdf": "4F2A755F-A982-4E40-A24D-49A4052E1318",
    "20080.pdf": "50A5F208-6287-4481-BF54-99B2B2267514",
    "20081.pdf": "D6CEEB8B-F2BB-407D-B52C-FF9B19F73554",
    "20083.pdf": "9D6FE186-33C3-4507-9C5A-47B710170E09",
    "20084.pdf": "29968627-DCE4-4525-8DC8-763775A61C68",
    "20085.pdf": "455DE2FE-1E52-4ACB-87AF-8EFFB426F62D",
    "20086.pdf": "42C950A0-9EE1-4908-A007-609CB490C3C9",
    "20088.pdf": "7D12A535-D3B5-486C-9658-269555E68EBA",
    "20089.pdf": "888D4A2B-9E35-49C1-9894-266966ED6D61",
    "2008ABMMinutes.pdf": "1D150B6D-B814-49F7-B287-4009EEC78775",
    "2008HistoryPresentation.pdf": "661E7B56-F05C-4A6F-B3B0-A1581D6C4345",
    "2008meeting.pdf": "251CBA3B-830E-4D05-8572-DB3A78C73FAB",
    "2008PPBK.pdf": "6E575068-27C5-4B07-B253-41A1FE1545EB",
    "2008PresAddress.pdf": "B5EC14DA-40B8-4037-AD5F-46D70E1C3EBC",
    "2008RUNNAnnualReport.pdf": "D5430A21-BC0D-443F-97D7-4C374B287A8B",
    "2009_MEMO.PDF": "82D6ADAE-7EF7-4BA4-8B17-8085C5125D20",
    "20090.pdf": "14F8D34F-1248-4589-89A2-E2575803CB43",
    "20091.pdf": "914B2F12-A766-4EBA-AD33-9E4C0A7D8A4C",
    "20092.pdf": "FAA56724-49FE-4225-875E-FCC726032263",
    "20093.pdf": "C5BF25AB-446F-48BB-AAF1-F4C3CF4764D9",
    "20094.pdf": "E06F9D23-7CF2-4431-8927-4079C77B0218",
    "2009445959CertGanau.pdf": "2825C811-9FF6-4F54-8B16-B8363DCC0624",
    "20095.pdf": "6CC83C4D-8054-4B6A-B8AC-ABECABDE6FDC",
    "20098.pdf": "D518E8DD-E842-4DD2-972F-082C259616A1",
    "20099.pdf": "7C2E3FA7-4E61-4A77-A85D-3D7A9217DF3B",
    "2009ABMMinutes.pdf": "9A7CECA6-5472-4F74-A844-51A4BF91A9D4",
    "2009HistoryExhibit.pdf": "D707DFA1-C718-43D4-B109-7DE1FE556A09",
    "2009meeting.pdf": "77D5C61D-B40E-49B2-8F4E-F373A1DF3D89",
    "2009PPBK.pdf": "FBBB8C3A-4FA2-4C0D-A6CD-94C2E9764526",
    "2009PresAddress.pdf": "04AE1AE2-CF2A-4735-9A9D-46E78F0E2C38",
    "2009RUNNAnnualReport.pdf": "6822A5E0-F069-401F-89A8-677890DFD2CE",
    "2010-02-DGNC Jahresbeitrag.PDF": "59E4A046-22E3-4FCF-A831-3C780CD0A2C0",
    "2010.pdf": "C07A9DA8-34E8-4328-93B2-84AF56EA747A",
    "20100.pdf": "FD71C8D4-4334-4672-90A1-BB824FDFD904",
    "20103.pdf": "5558BCF8-4F35-406F-9599-24F2BEFFF53B",
    "20105.pdf": "3FF67124-231B-4252-BA1E-690AE649E631",
    "20106.pdf": "433FDF50-DD52-4084-9D0F-2971A10E4329",
    "20107.pdf": "D32DA21A-D1BD-418F-8392-B64E82E6A2E9",
    "2010ABMMinutes.pdf": "91C30B66-1EBA-47AD-85F2-75E23C712039",
    "2010HistoryExhibit.pdf": "994E8334-544F-4E8A-B4BF-44CF433FA426",
    "2010MedicarePhysicianFeeScheduleSummary.pdf": "E30B6B57-95D5-44B9-B001-A1FFE47CC165",
    "2010meeting.pdf": "FAA05150-179A-4753-A76D-6886A8E45F78",
    "2010PresAddress.pdf": "E211E649-496C-41C6-8E30-331271644568",
    "2010RUNNAnnualReport.pdf": "6319739D-7339-4207-BCCC-6EDFB50FF799",
    "2010ScientificProgram.pdf": "8DF1F9D2-7A6E-4263-BD6F-275FF68C223B",
    "2011.pdf": "A98297CF-221A-4B6E-9916-DDBD3D49329E",
    "20110.pdf": "DBFB89FC-13A7-42F3-A1DB-4205C02A0809",
    "20110321141746043.pdf": "72649D89-32E5-4607-A402-1F6019F5280F",
    "20110609102114206.pdf": "B948256E-D03A-44BE-A2A4-03F0FE0BAC43",
    "20111.pdf": "CC692F59-6873-4AAB-B1B8-117567B9DC5C",
    "20112.pdf": "BAF63A2A-0351-4582-A8C2-B1344286A748",
    "20113.pdf": "B732C80B-F434-43D2-9C73-AA8B9D0FB012",
    "20115.pdf": "03CF1BC7-2F53-4F68-9763-51CC186CE810",
    "20117.pdf": "CAD2E116-0307-438F-B94F-EA810B13998E",
    "20119.pdf": "93413F42-A785-4343-800B-46DD71ECA479",
    "2011HistoryExhibit.pdf": "0E0ED48F-0D34-4E8B-BEBE-0978F77C40BC",
    "2011meeting.pdf": "4E412F44-3819-41D8-B2B2-0856A3FFB06C",
    "2011PresAddress.pdf": "8E894277-EF54-4B21-A908-524B3619E421",
    "2011RUNNAnnualReport.pdf": "02771FF2-681F-49CE-824A-8BC2E6CDEEF0",
    "2011ScientificProgram.pdf": "502E8893-2094-40C2-917B-48774F917973",
    "2012 Cheng CPT update_2012-03-11.pdf": "CBE56C70-41A3-40E0-B9C5-3789001941A2",
    "2012-11-13-device-tax-letter-to-senate-leadership.pdf": "AAD21EF1-C542-4517-898F-07880E3E1642",
    "2012-2013 New International Resident Welcome letter-1.pdf": "B74A6076-2073-4E64-AA5F-BE1BFA55F16B",
    "2012.pdf": "9C20F056-1972-4058-990E-A92A91005C10",
    "2012_PROGRAM.PDF": "A50359A1-5C28-4C62-8690-3ED26BBAE8FE",
    "20120.pdf": "8B683368-51B2-4756-9850-169F1EEC586B",
    "20120808145455481.pdf": "05398840-3B97-44BA-A956-FB2EE21E1B91",
    "20120911111617.pdf": "A9A9B976-6D81-45DD-9DC7-5B1A8E4C4741",
    "20122.pdf": "0DD7C6AC-46AB-4E97-8A8E-F513028001D4",
    "20123.pdf": "3C8026C9-C303-4599-B368-9D652F01905C",
    "20125.pdf": "B1D48D7D-EF07-4E89-8D16-B14066F8EC81",
    "20128.pdf": "323BCD52-8A1B-4732-9C0E-A6D77E217A7B",
    "2012meeting.pdf": "73F7CDB3-E286-4719-B5D9-9F78B643334D",
    "2012PresAddress.pdf": "83E61CCB-5485-4019-BAF7-29A3742B632C",
    "2012RUNNAnnualReport.pdf": "248A000A-72B9-4AEA-B8EA-D3EC64541AFE",
    "2013 AANS EC agenda book and minutes_2013-04-29.pdf": "FBD9DB08-3783-4BF2-A21E-EFC7502A213F",
    "2013 CNS agenda book and minutes_2013-11-06.pdf": "FE27E976-4523-46F1-A46B-5852EA767D27",
    "2013.07.09 ARCP (6148931).pdf": "CA2EEE8A-56FE-4258-BE8E-E572CB94321A",
    "2013.CNS.Milner letter.b.pdf": "110B5A64-95D0-42AD-886B-DBC45ECB259B",
    "2013.CNS.Milner letter.pdf": "EC3AF3E1-EDF9-4B2F-ADD1-920B34F604E3",
    "2013?10?16?12?45?03?.pdf": "35FF05AD-DE7B-48D8-96EB-105943DB4689",
    "20130.pdf": "D9BD87FB-3D26-4A5D-BC3F-D496E6875FEB",
    "20130823105041694.pdf": "15A7A54C-C05B-48AC-8692-44933CE582E7",
    "20131.pdf": "0C914EE0-CAC5-46FC-B463-6C546B7145C6",
    "20133.pdf": "BFD41E02-4B71-4FA4-945C-B029AAD29006",
    "20135.pdf": "85BCC38A-D709-43A4-9642-EFE4D332F1C2",
    "20136.pdf": "B55CE873-742E-4FB6-937E-724308620E27",
    "20139.pdf": "3F62A076-3EDE-4552-99FD-69401BE5DA30",
    "2013devicetaxlettertoleadership.pdf": "129E55BA-E0FA-45F7-8A63-2D8AA0FEE9F0",
    "2013EnrollmentLetter-AsiriEdiriwickrema.pdf": "62F30814-B3C2-413D-9C07-482D8EC8FE0E",
    "2013HistoryExhibit (1).pdf": "79F2D3C8-806D-4EAD-8D20-D4C30C47FA0B",
    "2013historyexhibit.pdf": "735F39A6-C64F-4549-9C11-6F9D486ADD63",
    "2013meeting.pdf": "F7442F95-ABAA-4D43-BCB3-D004BFCFAA95",
    "2013RUNNAnnualReport.pdf": "1383D318-A5B5-45E1-A528-60F76DF7CF96",
    "2014 CV RR Full.pdf": "03886DE7-1DFC-49E7-B0AA-BD2EB2F152F5",
    "2014-02-27 12-40-30.pdf": "92A1917C-331F-4D4D-B37D-A98A41D34E74",
    "2014-2021_Samples_Graduate Certificate.pdf": "2FBA2174-A041-4A40-8AFA-61A3C0CADBB4",
    "2014.pdf": "151CECF7-71D4-4EF8-8900-94EC553BF957",
    "2014_MEMO.PDF": "8C7DD7FD-BE8C-4BE7-AA3D-7C867C298E9E",
    "20140.pdf": "B4B06812-9179-4BD8-B43A-31D2DCDF8DDF",
    "20140918lettertocmsregardingphysicianfeeschedule.pdf": "5394125C-5B7A-4BB4-B3E5-F08A9327E3F6",
    "20141.pdf": "5145F239-F539-4F29-950F-2780A8F91649",
    "20142.pdf": "8C093010-3E02-467A-A7AE-83975C55B7DA",
    "20144.pdf": "2FE6708D-7AE2-4AC6-9239-0A69EDEDAA09",
    "20146.pdf": "6ED81D76-D0B9-44DC-A693-8C46BF73F11E",
    "20147.pdf": "74655F6B-8EA9-4965-A0B8-45EE4CADD540",
    "20148.pdf": "D05C2620-CCEA-42F8-92FB-179ACC2362F6",
    "20149.pdf": "3A056837-4367-42BD-8064-C1991872D952",
    "2014MedicarePhysicianFeeSchedule-QualityIssues.pdf": "65FA060A-4CE8-4E5D-9D17-3F4F29B90090",
    "2014MedicarePhysicianFeeScheduleSummary-PaymentIssues.pdf": "D046BA3E-8BE8-4990-9C93-DEBF51A2E28A",
    "2014meeting.pdf": "A03AA162-EF2B-44BC-AEA7-D258AEBE1AA0",
    "2014PresentationGuidelines.pdf": "6155A86B-CA51-465E-97E5-EA4754DB086D",
    "2014RUNNAnnualReport.pdf": "7467C808-3E52-48AF-B7CE-FAE465603287",
    "2014SQA_PublicReportingDocument.pdf": "3F9DF910-BFA5-4006-9175-C98D6515F59D",
    "2015 01 27 Panchal CV.pdf": "D3C1CDC8-D406-4F0D-BED4-0490B6661B84",
    "2015 October EC Minutes_2016-01-27.pdf": "C37E17F8-CA83-4C03-ACE4-B9181D735BF7",
    "2015-ipab-group-letter.pdf": "6AAA12CD-FCB7-4DD6-95B4-295B1468E949",
    "2015.pdf": "A6A92E93-BB26-4BDF-94B9-206A7A5771DB",
    "2015_ama_sign-on_burgess_sunshine_letter.pdf": "9691AF70-DD7D-4132-8DC1-F4231D8E32EC",
    "2015_boot_camp_registration_guidelines.pdf": "DEB3F566-2D6F-4E99-8A43-890BDC838BAE",
    "20150202_164970_Russell_Fellwoship Stage 2.pdf": "503C1A62-3F2A-4F54-9287-B7CDCB77330E",
    "20150710094640678.pdf": "4B9BBDA7-072C-41EA-B64A-78A925BA61AE",
    "201507280844.pdf": "7EF11D1D-3CD6-408B-B754-8067D31AFEE9",
    "20150824090529824 (1).pdf": "0A8582C9-D5A5-4B2A-B886-0B23B58D3B03",
    "20151.pdf": "F0CD3073-DD61-4190-BE2C-64D2AE2C0717",
    "201512151431.pdf": "7D7D15A8-9FF9-4810-B01F-60F8AEE9E7A6",
    "20152.pdf": "8DA8594A-3EFB-40EE-9661-3DF0FD1F28DE",
    "20153dcourseappguidelines.pdf": "13D4ED72-FED3-4962-AAE7-B72208F371BA",
    "20154.pdf": "6316C6C9-EA8D-4145-868E-C0E4D247D672",
    "20155.pdf": "F1369F28-2712-446B-B82B-5B31CFB9D731",
    "20157.pdf": "0D067304-C1F7-4617-A7D9-AFA2AEAB220C",
    "20158.pdf": "76664943-B6FB-4440-91CC-46877B869EE4",
    "20159.pdf": "C2E67911-7075-420A-90C9-083EA9FEF1D8",
    "2015meeting.pdf": "0BFB4425-567F-46F7-9495-E819B8A41BE3",
    "2015presentationguidelines.pdf": "33F08277-AB6F-49D4-AB71-A34793AE11F5",
    "2015residentappguidelines.pdf": "FB188107-3CDC-4C54-AF6A-6F4CAA1D3411",
    "2015residentappguidelines_0.pdf": "FFB062B3-1276-41DF-BB00-A6F9F3B26B6E",
    "2015residentappguidelines_1.pdf": "4CF1AE95-D1B1-4E34-B3C9-C914D8A6F11B",
    "2015RUNNAnnualReport.pdf": "028E9FB3-FF55-46E6-9224-2400C698464D",
    "2016 Meeting Agreement_2015-11-17.pdf": "7C3B6E45-A7E5-4E06-BFED-49C17AD6C32D",
    "2016-7-22_ama_sign_on_letter_to_shulkin_re_vha_aprn_proposed_rule.pdf": "62C4E629-68A4-4E4C-83A0-F7A3390E62EF",
    "2016-letter-with-signers1pdf.pdf": "353BF28F-5D7F-41BC-B295-7F4BB24898BF",
    "2016_boot_camp_registration_guidelines.pdf": "D7A1016A-5420-41B9-B145-1A574D921C48",
    "2016_boot_camp_registration_guidelines_0.pdf": "4829289C-039E-4207-B08B-A6C54AC14E21",
    "2016_course_application_guidelines.pdf": "4E4C492C-8FA2-4759-AF1D-F7A6AE9BAEA1",
    "2016_june_onc_rfi_-_sign-on.pdf": "5F5B8E54-1256-4A0E-99DC-E42793FB9E08",
    "2016_medicare_payment_schedules_summary.pdf": "12A6038F-C910-4767-828C-BAA1EDACDBB7",
    "2016_resident_housing_guidelines.pdf": "0DD9AF8A-10E6-4FFF-9930-4530BF4A4482",
    "20160.pdf": "D6CA8445-7DFA-46ED-9529-B8908A02999D",
    "20160509155245809.pdf": "50261D8F-975E-4EDB-AF00-603D0AC84975",
    "201609061301.pdf": "109834C5-D20D-4960-AB31-7B69AAD2F379",
    "20161.pdf": "71D5F742-DC22-409C-B193-7F52E4141D54",
    "201610191752.pdf": "08393A3A-0D0E-4C1A-9BCC-3E92CF73703C",
    "20162.pdf": "46146C08-3F32-4892-94D7-0A375567B9D7",
    "20163.pdf": "41DB8F53-6241-4EED-AC11-030144D70052",
    "20164.pdf": "9A11A8DF-A286-4AFB-8E47-C796A247F63C",
    "20165.pdf": "864AFE2D-DDCD-49EE-86A9-1606308D4B61",
    "20166.pdf": "7136F78E-9081-4FD7-9788-6EC6DAFE92F9",
    "20167.pdf": "A7D2C527-7FD2-4E5A-9D4E-EA320777D21E",
    "2016meeting.pdf": "5A87C710-65D5-4BA3-91FA-68DD3F476EF1",
    "2017 Round Robin Letters.pdf": "7C3CDC26-B3EA-49CF-8F54-2516D710975C",
    "2017-04-25-Global-Surgery-Presentation.pdf": "CD323E2E-9B11-44D0-9B28-69463FF89582",
    "2017-07800.pdf": "D711C8CD-2F86-4ABE-9941-A74DFF99BE00",
    "2017-13010.pdf": "49C5CFCD-4AD1-4143-AC9A-081142F632DB",
    "2017-14883.pdf": "62F8CB14-1185-4951-85E6-DB426D6DC084",
    "2017-2-onc_cms_2015_cert_delay.pdf": "7E68AE90-9799-4729-B20A-308A6DB90F1D",
    "2017. Intervertebral Disk Degeneration and Repair.pdf": "2E04CDD8-3F8D-4638-ADE4-A3682328D05F",
    "2017_03_28_11_07_59.pdf": "8A2DE052-7382-4142-B863-97908834C908",
    "2017_MEMO.PDF": "71732FE6-D888-4935-8A70-146AD7D7A6A3",
    "2017_poh_coalition_letter_final.pdf": "6EF10174-83C8-4758-B9EB-B34F73037976",
    "2017_resident_housing_guidelines.pdf": "1A2E7AEE-0161-447C-B6F3-73E116C5585F",
    "2017_support_opportunities_non_meeting.pdf": "D2460753-32B3-4373-8C6D-5B3D2318F05A",
    "20170.pdf": "B16D042A-5355-41BC-80DD-93C0E486951F",
    "201704180717.pdf": "3E3D06F5-7E84-42FD-BE52-E90ACE6D5E18",
    "20170721150016520.pdf": "9B7C4BB0-B1FC-4AF6-BF9C-E3F5E41CC3A0",
    "20172.pdf": "A355CE07-29B6-4BC1-A88D-A087B7AAC12A",
    "20175.pdf": "ABCEAF22-4775-41D8-9DF6-D794E1BA094A",
    "20179.pdf": "0FF2D875-208C-4814-9703-0991DA398F8C",
    "2017CPRSectionVIImplementationTable.pdf": "8212117A-A044-423F-B4D8-2E8FB3B1E443",
    "2017meeting.pdf": "651038C5-37F2-4BF3-B574-FBBA3FC10877",
    "2018 1 8 Eckardt CV1.pdf": "1384E516-82BD-4CB2-8369-A4CB2AE7479D",
    "2018 CV--Zhihua Cheng.pdf": "9BE7AB4C-D80F-4152-B49E-8237FCDCB49A",
    "2018 Med Fellows Program Information.pdf": "DE387DA2-F56D-4FA6-A81D-BDDBB6B5E49D",
    "2018 QPP Proposed RuleHart Health SummaryJune 2017 002.pdf": "5776F59D-EAAA-47EF-A0B9-3115758FC5BB",
    "2018-15958.pdf": "9AD1354E-3E88-4110-A5AF-22C3A5940E81",
    "2018-VM-IR-Quick-Ref-Guide.pdf": "A1EC6F89-B691-441A-BD6E-0F932AF0364D",
    "2018_CV_Roser.pdf": "B9DF167D-7033-4001-AD2F-944C858F6EB3",
    "2018_Dues_DGNC.pdf": "35DB86A8-2B20-4782-B398-BB1B7B24EFE5",
    "2018_iac_supporter_benefits_0.pdf": "3BE0A56C-EA7E-44EE-829A-E82644688846",
    "2018_mips_reporting_period_sign_final.pdf": "6AD449C9-58E0-40B1-95C1-459EDE646D8E",
    "2018_PA_ResourceDocument.pdf": "5D356583-B2E2-4F87-9A88-91D000E93D33",
    "2018_qpp_final_rule_sbs_final.pdf": "B8DEB1FB-E968-4431-B5C2-1CC332C8F1CF",
    "2018_qpp_proposed_rule.hart_health_summary.june_2017_0.pdf": "65FAF33B-CB31-4B12-B5E8-AEB255291A89",
    "2018_resident_housing_guidelines.pdf": "96E4C39F-F37E-4EB9-90DA-A1B2A129E05D",
    "20180222-cv1.pdf": "2E2CA590-79BE-4C03-B344-3853378C0554",
    "20180601151455949.pdf": "16A03A1C-1BA6-4670-91BA-F78AB8A907AC",
    "20182.pdf": "4464F15B-A679-41B8-8843-DE8F26ADD057",
    "20183.pdf": "7E9762C4-815B-4516-9924-1BDEBEEF20DB",
    "20185.pdf": "741620BA-48FA-47C6-9194-9490FB7B0FF3",
    "2018511141 Constancia de Inscripci?n 2024-02-23.pdf": "E4B3BF50-CC5A-46A2-B065-02448651E808",
    "20186.pdf": "FB9ACED6-463C-4E33-86A9-B3CABF954842",
    "20187.pdf": "E2EB31AE-234B-4791-849A-AC25E2355828",
    "20188.pdf": "AA7F0783-DCB9-4404-B0A6-DCA7BEC70613",
    "2018LONRoberto Heros MD.pdf": "90474ED1-3E81-46E6-A5FF-A8C3558F1365",
    "2018meeting.pdf": "65D4380B-3D59-4307-8285-B6C7D612E661",
    "2019 - WFNS Neurosurgery Diploma.pdf": "8891DBC4-BA57-4056-9F44-6585C4842B9A",
    "2019 2020 Neurosurgery Program Information And Interview Dates.pdf": "CDD7EBD4-2985-440D-BFF4-ED053A9AC6FD",
    "2019 AAcNS  Program Part Two Abstracts.pdf": "9CD6D6C8-2D89-450E-9DFC-3CC4B0BF22A6",
    "2019 Active Application.pdf": "AAC87DE6-19B0-4D11-A69C-121A2E542090",
    "2019 Affiliate Application.pdf": "C2405BF0-B376-43D4-912D-383673846998",
    "2019 Annual Report_Final.pdf": "A70890F7-C0D6-4C85-965D-11390079628A",
    "2019 Associate Application.pdf": "AFF61FD9-9FF3-4639-AE28-06D50081BCB0",
    "2019 CNS CME.pdf": "C70EBFF2-9693-4D13-A86F-988B94F31859",
    "2019 CNS Disclosure Form.pdf": "7C84CECB-A15A-457A-A372-C25E0D2019F6",
    "2019 CNS Poster V5.pdf": "2770ADA8-86A9-4A50-9DFC-C6CFC1DAE2CB",
    "2019 CNS Poster.pdf": "FA86D40D-FC63-4D71-8F3C-5CB61B1DDF7D",
    "2019 Conversion Application.pdf": "0D53917E-1F71-428C-BB3F-1828BBE28EB1",
    "2019 International Application-DR. Ronnie Baticulon.pdf": "71E134EA-1420-43E6-8EC2-1AAAE4290FC8",
    "2019 International Application.pdf": "6B6AA19D-3AA0-412B-A661-026C7768CE19",
    "2019 International Vista Resident Application.pdf": "C57BDAD0-D5D1-4B75-B307-35051D6D9DF4",
    "2019 Match participation agreement.pdf": "1DC4DAE5-F9CF-4C00-9F12-B40957B3439C",
    "2019 Medical School Application.pdf": "269BE37A-FFC2-423F-872D-050CC1C37AD5",
    "2019 MPFS QPP Rule Summary-Payment Provisions.pdf": "D981AEA8-31E5-4CFA-A56D-9116367F0602",
    "2019 MPFS QPP Rule Summary-Quality Provisions.pdf": "30870F30-E7DA-400F-BE49-B5EEC3EE65CF",
    "2019 Resident Application.pdf": "B481570A-FE39-4EBD-9C69-5A5073A60717",
    "2019 Spine Weekly Registration Report.pdf": "D1D07346-7C46-4428-BCF1-601AA7BF6C64",
    "2019 Ticket Session Tracker.pdf": "BE1628C6-E00E-45EE-B3C0-BA724612AE71",
    "2019-09-16 060423.pdf": "FC6C83E9-65D1-4B13-9D22-22627DD24878",
    "2019-5-14_physician_community_to_house_senate_leadership_letter_on_bundling_proposal.pdf": "146EF5F0-C930-4C4E-9FE8-1EE42E5B95E2",
    "2019-5-23_macra_federation_sign-on_letter_final.pdf": "C197F403-3BFE-4AC5-898E-17317051AAD1",
    "2019-7-1_ruiz_roe_letter.pdf": "CC1CA0FE-34FA-4A0D-B438-330AB7B38B4E",
    "2019-8-7_Vycor CNS Poster.pdf": "DD62AB76-A2BB-4429-BADA-F8D9D50DB2C6",
    "2019-CAST-Transition-Update-with-ADS-reminder-SNS-Letterhead-final-Sept4-2019.pdf": "850D5D9C-1DFE-4198-A79C-7F8CF5AD9B35",
    "2019.10 CNS slide final 1.pdf": "0B8C53BC-8820-4885-BF7C-1A4C596A0DDC",
    "2019_AM_HousingRegistration_form_07_16_19.pdf": "76F5329A-4626-4E1D-8703-DF54099829DE",
    "2019_AM_HousingRegistration_form_07_25_19.pdf": "CA3BA6F7-516F-42AA-A074-CC980D6EA686",
    "2019_CNS_CSMP_Poster.pdf": "CA6D0B9A-BCF2-4E81-A175-61D7B0DD5421",
    "2019_fall_oral_board_agenda_07_10_19 (1).pdf": "EC05AF68-1CF0-44AF-88A7-548F3AB82736",
    "2019_fall_oral_board_agenda_07_10_19.pdf": "9B53D7C7-DBE7-4F47-966F-F96BA4790275",
    "2019_iac_supporter_benefits_se.docx (1).pdf": "5D4FD59A-F645-481D-B555-A761D4976DFD",
    "2019_iac_supporter_benefits_se.docx.pdf": "A6CB2BE6-1949-486A-B105-369C67828595",
    "2019_mdt_repeal_coalition_letter_to_congress_final.pdf": "812B0C87-9DAC-42E7-AC85-E0E4A6CE8BA9",
    "2019_mpfs-qpp_final_rule_summary-payment_provisions.pdf": "603109EC-57D6-432A-8C6B-9A87C824CD28",
    "2019_mpfs_qpp_final_rule_summary-quality_provisions.pdf": "CA260DA3-4ABC-4FCF-880C-B3E6AEEBF0CE",
    "2019_mpfs_qpp_rule_summary-payment_provisions.pdf": "176B6F9A-61B9-4CDE-86A8-2EA31127EA9E",
    "2019_mpfs_qpp_rule_summary-quality_provisions.pdf": "6DAB3A59-C2DE-4419-8095-D3B4ECC38C80",
    "2019_resident_housing_guidelines_final.pdf": "A16D249E-7C85-4CA4-9BA6-A8788D563EC3",
    "2019_skull_base_course_agenda_03_11_19.pdf": "518A7F44-CC3A-41E0-B823-7AC98EE9940E",
    "2019_spine_complication_course_industry_support_opportunities.pdf": "11CFF194-0909-4265-9E7C-495591C67D4A",
    "2019_support_opportunities-annual_meeting_se.pdf": "77B831A8-01CC-4EAE-98B1-711ECE1312F6",
    "2019_support_opportunities_annual_meeting_05_22_19.pdf": "2EB66EC3-6ABF-4360-B16B-19C8409D65EA",
    "2019_update_-_the_complexities_of_physician_supply_and_demand_-_projections_from_2017-2032.pdf": "168CDF67-9C0B-449E-B92E-A3A300BC02F8",
    "2019_v3_a_career_guide_for_new_attending_physicians_and_fellows (1).pdf": "6CBBCA29-0C39-49A4-A0AA-A0A587018E05",
    "2019_v3_a_career_guide_for_new_attending_physicians_and_fellows (2).pdf": "D40E3A32-0535-48A7-9C85-19BAD3A4895B",
    "2019_v3_a_career_guide_for_new_attending_physicians_and_fellows.pdf": "E831D174-75D9-42BE-98DA-129A8CAEB409",
    "2019_Value_of_CNS_Membership_Presentation.pdf": "96E0A877-9F41-4217-BFA5-E387962933B0",
    "201908311106.pdf": "BE55BB92-658C-45B8-A73B-A26704B866F6",
    "20190910 CNS ???????.pdf": "8C87833C-4CBF-4705-9134-321E2259D3F6",
    "20191.pdf": "81F2C416-C7BC-405C-9047-B58EA10C3273",
    "20191020-CNS-Poster-PPLDraft1-48x36.pdf": "C1CF5FAD-BBA6-44CD-8F84-C3E6649B2DFC",
    "20191022_CNS Poster.pdf": "67A34E6F-7429-463B-BADB-E0B3CB02C2AA",
    "20191224_CNS Scholarship in Data Science_Letter in Support of David Satzer_By Edwin Ramos.pdf": "2B5434AD-857F-416C-ADCB-C4DDE6BB2B7E",
    "20192.pdf": "E2FBE455-E7B8-4E16-8EFE-FBBE786A7BC3",
    "20196.pdf": "ACF6735C-D9B2-447B-A0B9-2AE5EC0F9B97",
    "20197.pdf": "F3157783-2517-4869-9EF1-9B54423612BF",
    "20198.pdf": "9E2557C2-214B-410A-94D1-E2CADF8ADF24",
    "20199.pdf": "24C791E0-9968-497A-AB64-FC93C342615A",
    "2019meeting.pdf": "801B0D1C-8BBF-481C-8B47-7201E27C6AC8",
    "2019RUNNANNUALREPORTMARCH52020).pdf": "3132199D-1C0E-427C-AB0C-BDFD71F0E620",
    "2020 - CV  Arash Farahvar MD PhD.pdf": "DB972B33-3817-43A7-95DB-369900D7BA37",
    "2020 Academy Meeting Registration.pdf": "7F584C18-ECAB-4897-8BE6-4F2F06A5813A",
    "2020 Annual Meeting Announcement.pdf": "2B5B4B3B-EADE-43CB-8825-4D467EB0FC48",
    "2020 ASSFN Logo -Disclosure Slides- ALL.pdf": "7BE8EF69-1504-46AA-BCD3-8841EB1E3DAF",
    "2020 Biosketch - Malhotra.pdf": "793F987F-6455-4F56-901B-BA2E61EDA9B1",
    "2020 Bowers CV.pdf": "2FBFAF37-2EBE-412F-B977-266130055876",
    "2020 Bowers Updated CV.pdf": "1769748E-3802-4345-B9CD-15AC6079B90F",
    "2020 Braintree Documentation (1).pdf": "F864E29C-38C2-4DF7-A518-29870014C63D",
    "2020 CNS Annual Meeting _ Exhibitor Prospectus.pdf": "00490FCE-1795-4501-86A4-8FD7923EA638",
    "2020 CNS Disclosure Form.pdf": "F5F51F49-0E4A-487D-A7FE-28D368B5D464",
    "2020 CNS Exhibit Application.pdf": "63F9D2B1-4EEE-4986-9621-35646A77A2F6",
    "2020 CNS Exhibit Application_2_12_20.pdf": "995B2C02-9D7A-4F5A-A97A-8ECEE20A7770",
    "2020 CNS Exhibitor Meeting Suite Application.pdf": "AF84F3E1-37A6-475E-AC7E-FA911FC20374",
    "2020 February Oral Boards Agenda Draft_01_16_20.pdf": "D4C7C6EA-5C7E-426F-BBE7-7290139B5EC8",
    "2020 February Oral Boards Agenda_11_5_19.pdf": "2096E59C-62EE-4E2A-B1D7-17086534B971",
    "2020 February Oral Boards Agenda_12_19_19.pdf": "531B6276-3B6F-4BF1-A1D6-051299D06500",
    "2020 general compliance trng certificate - MENENDEZ Ingrid.pdf": "91FAF2B2-6BEE-42B4-B67F-448AD40ADA13",
    "2020 Industry Support _ CNS Benefits & Recognition.pdf": "F36CDEA7-2620-4FC5-8B4C-DD3812EE1FFD",
    "2020 Junior Resident Course Registration Guidelines.pdf": "98323F97-A9B7-4CD9-A859-ADDAB2F65E17",
    "2020 Mar.pdf": "03A847F7-EF92-4E0A-8AE1-7841CAB4757D",
    "2020 Medicare Physician Fee Schedule Final Rule Summary.pdf": "8BA96812-6AA2-4826-AADC-C9F6D7C2B6A6",
    "2020 MIC Course Support Opportunities.pdf": "1EED67CD-94D4-4ACF-8C86-190D7A13071C",
    "2020 Newsletter - Issue 0.pdf": "1253F3E6-3B64-4DCC-BD26-CB51081EF695",
    "2020 Nexus Support Opportunities.pdf": "C77ECD1D-6789-42C9-B8EC-43631D21573C",
    "2020 NSI Newsletter.pdf": "69B0DC13-2F93-4343-A929-FDFB2BAE3A42",
    "2020 park sns.pdf": "B58A8FDA-4228-4D27-A5E1-351C9A9E9299",
    "2020 Resident Housing Application_Guidelines.pdf": "56BE6BAA-B46F-49BC-9116-DD1A1D8B0460",
    "2020 September Oral Boards Agenda_Draft_052220.pdf": "8A7B1AA6-465C-40EF-AB35-C59F90FFCC61",
    "2020 September Oral Boards Agenda_Draft_052620_Approved.pdf": "191903F8-B120-4C74-B1AB-46576A188098",
    "2020 September Oral Boards Agenda_Draft_Online_08122020.pdf": "F5732ECC-0486-41C3-B675-3CA9343DD77A",
    "2020 September Oral Boards Agenda_Draft_Online_08202020.pdf": "5B53B9A8-D084-4F1A-B8E3-FDF68C96EC3D",
    "2020 September Oral Boards Agenda_Draft_Online_09102020-FINAL.pdf": "50085C2B-8C34-4254-A7AE-4FBC7451F73E",
    "2020 September Oral Boards Agenda_Online_09102020-FINAL.pdf": "586B617B-AE3A-43A7-89FD-E82BAB5949CD",
    "2020 Skull Base Course Updated Agenda.pdf": "4199ACCB-5A43-4151-BC6C-2767027DA47E",
    "2020 Spine Complications Course _ Support Opportunities.pdf": "791E541D-CB4E-4755-B258-66111F29C870",
    "2020 Spine Complications Course Support Opportunities.pdf": "6ED96DDB-B680-41E8-AA8C-5F1182FBEC90",
    "2020 Tumor Complications Course _ Support Opportunities.pdf": "964D4534-0904-458A-98A0-A326E6184827",
    "2020 Tumor Complications Course Support Opportunities.pdf": "32C2FF6C-C9B8-4FBA-8DC0-17101C903DFE",
    "2020 Tumor Satellite Symposium Preliminary Program.pdf": "EAF9C87D-E0BB-47F6-A9FA-E789429097F5",
    "2020 Written Boards Agenda Draft_01_08_20.pdf": "2A87892D-A928-4098-9FE3-A53FA7D4D27F",
    "2020 Written Boards Agenda Draft_12_19_19.pdf": "37D71D7D-4BF0-4626-8AC8-6113AD8C1FDE",
    "2020 Written Boards Agenda_11_5_19.pdf": "68531C60-4FCD-4B7B-B01C-940DEE540DEC",
    "2020 Written Boards Agenda_11_7_19.pdf": "E3158C0B-AE3B-4283-93EF-A6F1C1C8552E",
    "2020-03-05 14-56.pdf": "D1D7D021-07C8-43AE-8427-79000750C758",
    "2020-05-08 ASSFN conference poster 36 x 56 - with SK + BL edits.pdf": "9E40A7BA-A11E-4879-B56C-21D817D80298",
    "2020-09-11 - Curriculum vitae - ENG.pdf": "E927696F-9443-489C-BBC0-960442F1572D",
    "2020-3-25 Congressional Stimilus Sign On letter.pdf": "60E1CA4A-9964-4FEB-B95D-CB9BDD1A4B3F",
    "2020-ASSFN-Provenza.pdf": "F3B87D7F-59AA-4064-BBB2-95BAE0EBAC33",
    "2020-Dr. Sy CNS Resident Committee.pdf": "0B8F2383-B9EA-47D7-98CE-B0455E09AD9B",
    "2020-NS Winn Prize Instructions.pdf": "75A654F8-DFE6-4027-B40B-9FC867D6ACC5",
    "2020-tumor-section-satellite-symposium-program-at-a-glance.pdf": "1C089C8F-C573-4EA5-869B-C32C0AB5D352",
    "2020.01.24 Bhandarkar Archis Letter of Good Standing.pdf": "DD2705A3-7894-4DF9-8D76-3F544C5039AD",
    "2020.3 Raskin CV CNS nomination.pdf": "19CEC0D5-29ED-42B9-9DD3-34BFB110DFB2",
    "2020_11_09_SNS_MLim.pdf": "5548D3CD-BF0D-4F29-A8B7-FCC2922780DC",
    "2020_AM_cover.pdf": "3831401C-4E03-45CB-9071-11DAE3543F07",
    "2020_ma_call_letter_prior_auth_sign-on_2-19-2019_final.pdf": "139B2091-775C-4A56-99AC-0037B2F8F6A8",
    "2020_MPW-First Coast_LCD-PVA for VCF.pdf": "0B3B0BCF-0F1F-4887-8443-1AE6533D4F38",
    "2020_MPW-Noridian_LCD-PVA for VCF (003).pdf": "8C0D6A3F-DBC3-474F-9E79-C0E090FE7F1D",
    "2020_MPW-Novitas_LCD-PVA for VCF.pdf": "F85EB68C-AABD-4121-8A08-392FA557D1C2",
    "2020_scc_agenda_071119_1.pdf": "9B8FB4C7-A2A1-4190-BB2B-D81451FEE65D",
    "20200.pdf": "C5046919-3532-49F2-BDAD-8539389D3E01",
    "20200127 RDM CV.pdf": "FEF58FD8-C023-4F47-9FE1-1A2D0373AFF8",
    "20200306_cns_figure.pdf": "41BFEFF9-CC7B-41FC-9747-14B699B0BD77",
    "20200309084532(1).pdf": "768E0A73-6585-4C0E-AB5F-89877CC3161D",
    "20200312101545.pdf": "E7D109AE-B78F-4A5B-ABD6-FD2B64C5F56F",
    "20201.pdf": "2FE9FAEA-74C8-49AB-9ABE-C91C81075B0A",
    "20201023142011485.pdf": "B9ABED97-5993-479C-B6E0-5DD0011E6754",
    "20201124 CV.pdf": "95A95413-A2E2-48BA-8EE6-A63F1B300935",
    "20202.pdf": "524901C2-D48D-49BD-8A1F-B0FE35D426AA",
    "20204.pdf": "F0F7577C-9BC5-4BE5-9C17-D623CF2FD8AF",
    "20207.pdf": "316675E8-5309-4728-A678-8CB1DB50AF38",
    "20209.pdf": "0EBDE2D6-5971-4470-9F59-7E4BD66F0F2F",
    "2021 08 30 AR CNS Final.pdf": "427A313B-0A23-4B8F-980E-7EFBB34C6E0A",
    "2021 08 30 SEEG Final Presentation.pdf": "EE376CBA-668F-4A3D-AEB9-F0E5DEA78C56",
    "2021 183RD RECCOMENDATION LETTER - DR. TEDDY TOTIMEH.pdf": "AD8274C1-B3E4-41AF-8EC0-6063AC69215C",
    "2021 Active Duty Military Housing Form.pdf": "52B56DBE-3168-440E-B2CD-3582FACFC276",
    "2021 AGENDA_Diversity-Pathways to Neurosurgery.pdf": "1073BB25-40A2-4314-8F51-DAC0B3E57780",
    "2021 AM  Disclosure List - 10-16-2021.pdf": "42E6EC83-EDBE-431A-A333-DC288510F78B",
    "2021 AM Summary Content For Bundle.pdf": "970D8EC9-AE9D-4EB2-8EBA-88E9799021BE",
    "2021 AM Summary Content For Bundle_1.5.22.pdf": "BB4D3DE4-D720-4637-BED5-9F8929EF093E",
    "2021 Career Guide Agenda draft no faculty_10_29_20.pdf": "1FF07D5B-AD12-45E4-9AB3-C7C408F4BDCA",
    "2021 Career Guide Agenda Faculty 11.4.20.pdf": "CC78D70F-ECC0-4E0B-ACB1-16FEBFBDFEE0",
    "2021 Career Guide Agenda Faculty Clean.pdf": "E9F00F7A-B45B-4342-8329-9C423C7225C8",
    "2021 Career Guide Agenda Final 11_2_20.pdf": "F65AC138-34A5-4194-ABC8-55794B07430C",
    "2021 Career Guide Agenda Final Nov.2.20.pdf": "20A7AA07-7858-4F51-99D4-9348DA5DD701",
    "2021 Career Guide Agenda.pdf": "AAFB4FC2-0E55-4664-A781-1DCBBE34A57A",
    "2021 CNS ACTIVE MEMBERSHIP DUES.pdf": "3CEAE5F4-B326-4946-BE16-FEF9A7024375",
    "2021 CNS poster NSQIP aneurysm scale.pdf": "3EFBCCCA-5304-401F-A703-B9C8DC3D7603",
    "2021 CNS_030626_PARK.pdf": "497DFDD4-6ED8-483B-A8A1-FFC9345C6BC2",
    "2021 DSPN DISCLOSURE LIST- DISCLOSURE TO THE LEARNERS-7-22-2021.pdf": "BA7C45A5-C74E-4817-A1C4-18054115A0E7",
    "2021 Fall Oral Boards Agenda 8-25.pdf": "B0662D99-C1DD-4033-9B30-F5ADF2993B14",
    "2021 Fall Oral Boards Agenda 9-2.pdf": "810A0006-7118-4795-8899-7C930F9102C2",
    "2021 Fall Oral Boards Agenda v3.pdf": "DAA7A79B-065D-4798-950B-5F98C99A33E2",
    "2021 Fall Oral Boards Agenda.pdf": "99E1B3AB-B987-4C80-921E-04EAFC0C96C2",
    "2021 February Oral Boards Agenda_Online_021921_Final.pdf": "3CA83AD9-BF66-45C9-8C1D-4CAFBB34526D",
    "2021 February Oral Boards Agenda_Online_121020.pdf": "6FC088DC-44E1-4332-8CC7-F2E9BC451C74",
    "2021 Legislative and Regulatory Agenda.pdf": "6C6B60D8-42DC-4707-857D-48F07B840F95",
    "2021 Poster Lineup_FINAL.pdf": "A7CBF7B6-64B4-48DC-91AD-DF4C5EC5D514",
    "2021 Resident Housing Complete Application.pdf": "F53EF333-6156-4013-8A6C-0B267327499E",
    "2021 Resident Housing Complete Application_070621.pdf": "CE53DC55-0816-4CE5-B8E5-E698DA665556",
    "2021 RUNN Course Agenda v4.pdf": "86A5F0BF-EE11-4509-A7C5-27BBA45F0794",
    "2021 RUNN Research Award Application.pdf": "2C6ACA49-A7A4-47CF-B3ED-A5CB0616B975",
    "2021 Save the Date SPINE20.pdf": "8CE00389-3FCD-4A14-95E6-630CCA41FFA3",
    "2021 Show Management Rules Regulations.pdf": "6DC1EB87-D2BE-48EC-8E81-136DD1C29673",
    "2021 Skull Base Course Agenda_3_11_21.pdf": "945B558B-ABA8-49F2-9336-37D25B870210",
    "2021 Skull Base Course Agenda_5_4_21.pdf": "DA6CAFAB-DF5C-4CE0-ADCB-F8E8C26657FE",
    "2021 Skull Base Course Final Agenda_090821.pdf": "0627ACD1-8309-4E80-9379-C648FFD70A97",
    "2021 Skull Base Course Final Agenda_CME 9-13.pdf": "E3696C3D-FEAD-480A-A426-7154A33F1A3C",
    "2021 Skull Base Course Final Agenda_CME 9-14 Revised (003).pdf": "2B7D32CB-D904-40A1-B225-BAA9700378C5",
    "2021 Skull Base Course Final Agenda_CME 9-14 Revised.pdf": "DC378D81-CB3A-494B-9BB8-72F263302D3E",
    "2021 SNS Annual Meeting Agenda v3.1.pdf": "9AC5C926-6F70-498B-9F47-6DFE1328B499",
    "2021 SNS Disclosure List - 10-13-2021.pdf": "765561DC-255E-4D9F-BADE-F376D22BB647",
    "2021 Tumor   Disclosure List - 10-12-2021.pdf": "3011627C-8CDC-4E45-A8FA-9CF1C5B113B7",
    "2021 Tumor Section Symposium Program at-a-glance v2.pdf": "39BB51E3-5D9B-41D1-9827-AA176DA950FC",
    "2021 UNMC Virtual Q&A.pdf": "FF62E32E-1148-4BB9-BBD4-ADC74AB1B6EA",
    "2021 Washington Committee-AMA Fellowship Application.pdf": "27F6F20F-F5AF-4BBE-85BB-D4C819803B58",
    "2021 Written Boards Agenda_011521_Draft.pdf": "00D32D33-5FA0-4A95-8373-116D174C75C4",
    "2021 Written Boards Agenda_120120_Draft.pdf": "498F158C-3342-4FAA-98DF-4F85978A4F10",
    "2021-1-27 Table 1 Rialto final.pdf": "21420455-2475-45F7-A136-AB5F237B9D80",
    "2021-1-27 Table 2 Rialto final.pdf": "7E402D91-4BBE-4862-ACCA-95D284A4C4DF",
    "2021-1-27 Table 3 Rialto final.pdf": "248254DC-6E3E-4CB4-881B-3077BB9541D5",
    "2021-1-27 Table 4 Rialto final.pdf": "A5B4AB1B-E00C-4D7C-887E-A3E9A249B73E",
    "2021-22 Enrollment Verification - Raj Malhotra.pdf": "9F217331-6057-456A-9B37-B1553E9AD5AA",
    "2021-CNS-Poster.pdf": "BEC3CEC8-FAB4-4A2E-90D4-67D3D8E1CD1B",
    "2021-Dr. E Ramos CV.pdf": "34A74A95-8BC8-43A9-92DC-818333D7C24D",
    "2021.pdf": "296E57E6-836D-4853-8196-D3286DD3B88B",
    "2021_12_Kanter_CV.pdf": "711A043F-6934-428E-9BB3-5C7A0FF6CF7B",
    "2021_CNS_Abstract_Figure1.pdf": "5807C860-8A96-405E-BC29-D853E94EBD3F",
    "2021_CNS_Abstract_Figure2.pdf": "503771CB-7EA0-488A-ACCB-6220363F4645",
    "2021_CNS_Abstract_Figure3.pdf": "B3B5816C-9198-493C-8EE9-F2B99A69AE27",
    "2021_CNS_Abstract_References.pdf": "446BB4BC-31D8-484B-99C9-D81EBA4DB6B0",
    "2021_Kim_CNS_iNPH_DigitalPoster.pdf": "FA2D78FC-2023-4482-9274-E476E6B5459F",
    "20210217 Nduom CV (1).pdf": "28AE3D5C-FE71-4472-99B8-752475723CC1",
    "20210217 Nduom CV.pdf": "765FE908-817D-4BD4-831F-922F86D25D60",
    "20210317 Nduom CV.pdf": "FE169F16-FC1B-4B46-98DE-A90130148929",
    "20210419_Goodstanding_Beach.pdf": "B526EF7B-1929-4833-9D1C-E21411A9781F",
    "20210816.IEDposter.CNS.pdf": "DD69F4F1-416D-478F-AC9E-FB408BBE5AAF",
    "20210907110338.pdf": "7BA5BFD7-4259-4434-A4AA-7A38819D08E0",
    "20211.pdf": "5E9819FD-5188-4AF0-96CA-2A9F4F87CF39",
    "20213.pdf": "667F2FAB-0C5F-4A8F-95B6-3D8B44989178",
    "2021510163 Constancia Estudios 2024-02-27.pdf": "5F8D3CA9-6E9F-4F5D-9F53-78DD0F25F9AA",
    "2021510484 Martinez Ruth.pdf": "FC3DED8B-E17A-4F50-8F30-56707898B61E",
    "20217.pdf": "D66CDBCC-4872-4BAD-88F0-AFB2B8C2B997",
    "20218.pdf": "0E732AAA-CFB2-4361-B40D-87170146CB4B",
    "20219.pdf": "4715CD4F-F523-4E20-B06F-00A46E814084",
    "2021AM_Online_Prelim_052421.pdf": "46D12E64-A81F-4BCB-84BF-3B8BD4793A4E",
    "2021AM_Wellness_Linked.pdf": "9F0537C8-82BE-4388-9A7B-5A61AEE331E3",
    "2021CNS_Ispondy_Poster_Arnold_20210902.pdf": "B9A30957-3B7D-49E5-8316-E8E33625A07B",
    "2021CNS_P15_DigitalPoster_Arnold_20210902.pdf": "371CC65F-A941-418D-869D-E8A75075C48D",
    "2021Jan31 - Mets_Race - CNS_Tbls.pdf": "48C0FFD7-4DE0-4F00-9829-100E4BFC64E1",
    "2021Jun01 - Infx_Spine_Met - Tables.pdf": "9516F07E-522A-45A6-B5D9-E41B6AA13777",
    "2021Jun01 - Plast_Surg - CNS_Supp_Tbl_1.pdf": "3C5060EA-DDB8-4DB2-9327-AC6275B6A760",
    "2021Jun01 - Plast_Surg - CNS_Tbls.pdf": "4D1FB516-50E9-4201-82D4-EF40DC34DE4A",
    "2021Jun01 - Readmit_Spn_Met - Tables_CNS.pdf": "38212A31-13E8-4ABF-9CE6-653DB968F404",
    "2021Jun14 - Spine_Ant_Col_XRT - CNS_Tables.pdf": "75E25B39-8C0B-49AB-9EAF-194BA224A38F",
    "2022  Diffusion Tensor Imaging Y-8th ed Ch14c.pdf": "399D2776-EA50-4B1C-9610-B467AA3ECF8B",
    "2022  Pediatric Section Meeting _  Disclosure to Learners -11-11-2022.pdf": "B7C0DC42-D1A3-48E7-B962-1E5106A7418B",
    "2022 - Results of the Advanced Method (normal-to-normal) Technique in Resection of Symptomatic True Cavernous Sinus Meningioma.pdf": "B36D09B6-886D-4A6C-B2E0-B18275526A48",
    "2022 - RUNN - Program Director Approval - Wolfe - Cody.pdf": "3B80E615-DE1C-4198-9C7E-535B8D2C72FE",
    "2022 03 30 Robertson.pdf": "736C13C7-DEFE-4DAB-904B-6A3AA5D5D036",
    "2022 03 Friedman.pdf": "A5280CF8-879E-4004-A365-0D1440C6EFD3",
    "2022 08 15 MHI_CNS_Poster.pdf": "27B461C1-C7AB-4A85-B78A-C5EB33B9231F",
    "2022 08 15 Race_CNS_Poster.pdf": "575204EC-CAA5-4E97-A42C-27F6048344DF",
    "2022 0822 Poster presentation CNS sequential vs circumferential dilation.pdf": "5F5B9241-E507-44D8-A342-8ED2F60992A1",
    "2022 09 21 CNS Poster.pdf": "46C1F2EB-08DC-4D6A-8D6A-A5BC709FBE88",
    "2022 AAcNS Program Book - for website v8.pdf": "01927680-6DBF-4928-A60F-E7ABB01DEFBA",
    "2022 AANS Nesvick - ATRT SMARCB1 vars poster.pdf": "9A700619-9751-4E91-8AC4-83E226695182",
    "2022 AANS Nesvick - ATRT TFs poster.pdf": "867FEC2F-D8F9-47DB-9BC5-F697F475F8F3",
    "2022 ANNUAL MEETING DISCLOSURE TO LEARNERS _ 8-26-2022.pdf": "AF8A1115-8F11-4676-86A2-3C67940CEEF1",
    "2022 ARANS agenda_Final.pdf": "0B9224D7-C29C-4CF6-B71D-544CD1D02631",
    "2022 ASSFN Disclosure to Learners 6-7-22.pdf": "0A7D1E92-BCA5-4324-AEBD-4E67A2A7FE0B",
    "2022 ASSFN EOT RWD poster v5.pdf": "C8809F31-0388-479C-BC4F-894F375C4CC2",
    "2022 ASSFN Poster final.pdf": "EDCBECC7-8F81-49CC-8561-456FC3FC1FA1",
    "2022 Career Guide Course - 1.6.pdf": "021FCF96-F288-4C68-B789-3221AFB52FD2",
    "2022 Career Guide Course - website version (002).pdf": "25DE598C-B18A-4B23-AA3A-038D1E6C233F",
    "2022 Career Guide Course - website version.pdf": "D6D7181F-DB9C-46C8-809A-C6788A93532F",
    "2022 Career Guide Course Agenda.pdf": "3BDCC344-F261-4AF3-A5A9-DF3052FE9680",
    "2022 CNS Adepoju.pdf": "5D9BE291-94F5-4446-B6E7-A9FAC863FA3A",
    "2022 CNS LSBP Poster.pdf": "F069C118-1554-45E2-8CFB-8EEE21FBF454",
    "2022 CNS Poster Peds Readmissions - CNS template.pdf": "91957E8E-B29B-4720-9232-C9B4341841FC",
    "2022 CNS Poster.pdf": "A0884879-97A5-4E0A-9937-2D824CDECD12",
    "2022 CNS_STAMCA poster.pdf": "319230D9-4EB8-4F10-A77F-62A53C9AF38B",
    "2022 Curriculum vitae - Tarek El Ahmadieh 2022.pdf": "CAFD7C22-881C-4ED2-A388-94D984DA5097",
    "2022 CV Section-CNS Foundation Young Investigator Research Grant-Application Instructions and FAQs_v2 003 1.pdf": "7C29F6DA-268E-4740-B2AD-FE28A4F48BFE",
    "2022 CV_Giancarlo Mignucci MD MS.pdf": "0D481CDB-6526-4546-8536-45F983BD92DC",
    "2022 dean medical letter Scholarships.pdf": "53EB5C63-782A-436A-8B91-7DC96BCA6D93",
    "2022 DSPN DISCLOSURE LIST- DISCLOSURE TO THE LEARNERS-2-13-2022.pdf": "45C9133A-EE70-4779-BD7A-5A110873103B",
    "2022 Harward CNS Poster - Final.pdf": "03CCEE7D-2C8B-4737-A804-8231A018CDA7",
    "2022 IMMT Hamid-Hassan FINAL.pdf": "061089B6-184B-47A3-8523-922E20679355",
    "2022 IMMT Hamid-Hassan FINAL2.pdf": "3B142E50-EDF6-4233-95D4-4445F487E572",
    "2022 Jacky Yeung CV.pdf": "78E204A1-ECB5-474D-8506-E02FA8027C92",
    "2022 Joint Section on Pediatric Neurosurgery_Poster.pdf": "3D27D1FC-3C6D-49B3-8C21-DDD7ADF97E7C",
    "2022 JRC Agenda_Website v2.pdf": "E7E6FFE1-1752-4933-94F1-9691E5755D82",
    "2022 M4 Group Letter of Good Standing.pdf": "0A0D6AD1-E37A-4EF0-93E9-B5AD5D3EEAB7",
    "2022 Match Letter - Perdomo Pantoja.pdf": "05F54A88-35D2-4D29-AF74-73439CB884ED",
    "2022 Medicare Physician Fee Schedule Final Rule Summary.pdf": "7DA67DB7-942B-426D-9106-C116B6967175",
    "2022 NR Malhotra SNS Submission.pdf": "9C0B8297-A83E-43EA-B49F-78CEA9C38E8A",
    "2022 O'Leary Sean (Congress Neurological Surgeons).pdf": "52724571-21F9-42FD-80AE-CB0BC84A37E6",
    "2022 OPPS and ASC Payment Systems Final Rule Summary.pdf": "C052787A-D2D7-45B0-A8A3-0569788326BB",
    "2022 Outcome of Microsurgical Treatment of 198 Basilar Apex Aneurysms using the Transcavernous Approach and its Variants.pdf": "263DC909-054A-4514-8A84-0D13731A41B8",
    "2022 Pediatric Section Preliminary Agenda 9_14_2022.pdf": "15CD321C-DB23-4062-B5EE-2123E3400E06",
    "2022 PFS Proposed Rule Comments. RRC. (D0964842).pdf": "B689C5CE-B818-480C-91E5-EBDD4E127986",
    "2022 Preliminary Agenda.pdf": "FACD6196-8944-4846-A76D-3B64D6C0CB3E",
    "2022 Resident Housing Application.pdf": "2BD7CEBC-912A-4626-90D4-07945D1AD43C",
    "2022 RUNN - Program Director Approval - Daci - Rrita.pdf": "7DDD9594-8317-4F49-BA13-76C1FBB00DC8",
    "2022 RUNN - Program Director Approval - Dang - Vivien.pdf": "D3A90046-EBC8-4339-8BD9-3D5AC8692BD9",
    "2022 RUNN - Program Director Approval - Lim - Jaims.pdf": "E59AE1C5-814C-4752-96FC-85B549EB1316",
    "2022 RUNN - Program Director Approval - Whipple - Garrett .pdf": "F9CB2764-EFC9-41BC-BC1E-0DAD6B80CB8D",
    "2022 RUNN - Program Director Approval - Yan - Sandra.pdf": "AE89241A-F93B-455D-90FB-829CEF475EDF",
    "2022 RUNN - Proposed Research - Casaos - Joshua.pdf": "9006F461-A240-46F1-9031-492864E80864",
    "2022 RUNN - Proposed Research - Daci - Rrita.pdf": "1D6F6BA3-CC79-452F-A05C-72B222DED8A5",
    "2022 RUNN - Proposed Research - Florence - TJ.pdf": "10393DC5-345F-4AC0-887A-07035B501836",
    "2022 RUNN - Proposed Research - Lam - Jordan.pdf": "A42D3C9A-394F-49E8-83F1-04A3E4215ED9",
    "2022 RUNN - Proposed Research - Lim - Jaims.pdf": "2DDCE620-E51C-40AF-BEC2-8C562D3F7FED",
    "2022 RUNN -Identification of microglial gene signatures essential for the tumorigenicity of Glioblastoma-Taiwo-Rukayat.pdf": "3A63E0AD-1FBB-4DD4-8180-71A33D1804ED",
    "2022 RUNN -Program Director Approval - Ratliff - John .pdf": "6D382078-C2CA-4E3D-A7B4-5D877521FB3D",
    "2022 RUNN Course Agenda as of 10.29.2022.pdf": "BB6B5378-3188-4369-B0E4-32B268DC6F90",
    "2022 RUNN Resident Award Application - Program Director Approval - Florence - TJ.pdf": "D1FCE3F7-EF32-434C-A4CA-3E0BAAF4107A",
    "2022 RUNN Resident Award Application - Program Director Approval - Lam - Jordan.pdf": "A4ADBA8A-0364-43E2-88C6-6E3A8A35A722",
    "2022 RUNN Resident Award Application - Program Director Approval 21.pdf": "BF9355BF-53B8-407F-B90F-E59B06B8B0C4",
    "2022 RUNN Resident Award Application - Program Director Approval.pdf": "8D41A55F-29CF-4035-8568-07008B748BB2",
    "2022 RUNN Resident Award Application - Program Director Approval_signed.pdf": "64DD5F4D-FBEB-43FB-AD88-3657BEDC92DE",
    "2022 RUNN Resident Award Application - Program Director Approval55.pdf": "C7C4CCC9-D525-4CC2-A2D3-F7AD0672AAD2",
    "2022 RUNN Resident Award Application - Proposed Research.pdf": "746CCCF0-ACDE-49A5-A5C3-5C0B3FAB0E2C",
    "2022 RUNN_Program Director Approval_Asuzu_David.pdf": "B8E91EF0-699A-4A41-8697-232B8D3A6088",
    "2022 SANS Written Boards Review Course Agenda - 9-27.pdf": "B42634EE-9FDC-486B-B5A4-AB50FD0384D0",
    "2022 SANS Written Boards Review Course Agenda_122221.pdf": "143CE6D0-874D-4C6E-B5E3-E0AB449AB4F3",
    "2022 SCC Agenda 71521.pdf": "40B9C1F5-B406-44CD-81FB-1E79E2910231",
    "2022 SCC Agenda online_071421.pdf": "D7D307C2-87F7-46B8-B457-C33A7360F9A5",
    "2022 SCC_01042022FINAL.pdf": "66209444-3E8A-46D4-B5EC-FB00DB4FA807",
    "2022 SCC_102221_FINAL_2.pdf": "DC445EBF-AA8A-43E5-805D-0EDBB3FED28B",
    "2022 Senior Boot Camp Agenda - Fall.pdf": "E62E0A02-5752-47FA-9C97-B1678BA4414B",
    "2022 Senior Boot Camp Agenda.pdf": "B91164D0-E176-4566-BD3D-AAA3582407AD",
    "2022 Skull Base Course Agenda_Updated_April 2022.pdf": "0C5CED9C-806C-4E4A-B402-9E8154E05D6A",
    "2022 SNS ANNUAL MEETING -DISCLOSURE TO THE LEARNERS-5-20-22.pdf": "9EA4C494-1502-47FF-AC01-BC7F86964FCA",
    "2022 Support Levels _ Benefits of Supporting the Congress of Neurological Surgeons.pdf": "57AB3F88-65FC-4017-91A0-A547D31804CB",
    "2022 Tumor Complications Agenda.pdf": "E847F531-4A52-4067-8861-42314FD6C559",
    "2022 Tumor Complications Agenda_01062022.pdf": "F4B96F27-DDFD-431E-8A1B-A3FEE2223FD7",
    "2022 Tumor Complications Agenda_01072022.pdf": "B3F61E2F-C1E6-40A7-887C-DC4F845ABB29",
    "2022 Virtual Intern Boot Camp Course Agenda.pdf": "7E6C54DC-1C66-4A3C-A110-EDF804DF03E0",
    "2022-04-14 Patel Letter ofGood Standing CNS.pdf": "971E7CC4-5ACB-4627-971C-B1DADDFC07CC",
    "2022-12 Szymczak CV.pdf": "C9C67E56-3B9C-41D7-9231-4630922914B9",
    "2022-2023 CNS EC Resource Guide- 1-2023.pdf": "C756A328-0AB1-45DC-AE79-7B2418354AD2",
    "2022-2023 SNS Recruitment Update- Updated lbc 6.26.22.pdf": "E5DB11A5-0994-4AD4-87E1-439E335EDDFD",
    "2022-23 Assignments- Supplemental for Agenda Book.pdf": "CA7182EC-23D6-47A0-A31E-A558A9482091",
    "2022-9-22 AMA Sign On Letter to Congressional Leadership re Medicare Physician Payment.pdf": "30AD4A7C-8B79-46A9-B2C4-9E3380BE6A2C",
    "2022-CNS-lead-rotation-poster.pdf": "35B55333-A6E5-463D-9953-EF626C066CA8",
    "2022-Peds-Behavior-poster.pdf": "F80A6C2A-8041-4476-BD96-5DBEEC03AA04",
    "2022-show-management-rules-regulations.pdf": "7DB62BDC-7487-4917-9A9F-32A5FA1E2C4D",
    "2022-TumorComplicationsAgenda-10272021.pdf": "990BC341-1404-4CF2-BBD0-8726D245F9F4",
    "2022-Vorster.CNS.pdf": "A1C7D723-8FCC-47A3-BDF8-0BC49B3354AD",
    "2022.03.04 B.Laing Rec Ltr.pdf": "39E0C509-F7BD-4A56-A3C5-171BAE4A2E6F",
    "2022.03.31 Letter to HRSA Johnson re Provider Relief Funding Period 1 reopening FINAL.pdf": "EC335079-7B58-4D56-8336-A2A5265E5C45",
    "2022.07.05 - Davidov Vitaliy - Verification of Status.pdf": "738ED5FC-BE89-49A1-B8A1-A37B1EAB434F",
    "2022.10.05_Dr. Akash Patel.pdf": "4D9CE5B4-98CB-479D-9411-0E8910AF7524",
    "2022.10.05_Dr. Akash Patel_Lonser.pdf": "DBA1EB60-A79D-43F6-A953-67E7D72689D2",
    "2022.11.10 LY VNS Predictors Poster.pdf": "7AC8F5D2-9A4E-4FF0-8F5F-B086AEBAD166",
    "2022.12.15 Letter to Leadership re Medicare Cuts_FINAL.pdf": "BC41559A-16B1-4981-9AF1-DAE52BED9F29",
    "2022_0130_JosePorras_CV.pdf": "403C4882-34A1-4F6E-A254-7FBA305E814E",
    "2022_0303_JosePorras_CV.pdf": "785A4F2C-E445-4065-84CC-4F1041696DB7",
    "2022_0331_JosePorras_CV.pdf": "99695EBC-2EE7-48B5-81F5-9E25B48442DA",
    "2022_2023_SNS_Recruitment_Update_Final.pdf": "7C73D5F3-94C6-4D94-8B64-44FA5840665C",
    "2022_ABRAHAM_BENJAMIN_CNS.pdf": "67AB0681-4838-47B4-AEB9-1280F57DE3F8",
    "2022_AM_CEEH.pdf": "227DD8C5-D83B-42B1-9ED1-F03E05F33960",
    "2022_AM_CNS2022_SES SCI poster_Austin Lui.pdf": "F0602DD1-41ED-41CC-8CFC-E00233328133",
    "2022_AM_ExhibitorList_July22.pdf": "220B018C-A6D7-45F0-957F-59B68C35B873",
    "2022_AM_Poster Cervical spine robotics.pdf": "2E3C4CA0-3FE1-455A-8316-C99267D57733",
    "2022_AM_Poster GAP score.pdf": "078AECCA-D82D-4926-82FF-A1AA571B9CC2",
    "2022_AM_Poster Peds Section CNTD titanium clips.pdf": "A1811B0F-EA72-43FB-8EA0-C86947215AC3",
    "2022_AM_Poster PSI liver Dx.pdf": "338D6306-DC3F-4EFC-BAF7-27657AC8E864",
    "2022_AM_Poster Spine discitis hematologic.pdf": "ECF8C88E-FE4F-435C-BA18-AA9498736B9D",
    "2022_AM_Poster Spine Pediatrics discitis.pdf": "AF4D132E-9B9B-4F0D-A621-946140CF27F3",
    "2022_AM_Poster Thoracolumbar False Pain.pdf": "7A62CD8B-53E3-46F5-B5AC-96210DFE7290",
    "2022_AM_Poster-CNS-Missionbrain.pdf": "B3582842-FD52-4D87-912B-D77B36C75D7F",
    "2022_AM_Poster_DR.pdf": "73282B0E-AB98-47BE-80D9-3680CABA279D",
    "2022_AM_Poster_Takafumi Shimogawa.pdf": "3A465AB9-01D4-40F2-AF8C-0A6D917DC246",
    "2022_AM_PosterTemplate (1).pdf": "16D9EAFF-8399-443B-87F1-1506F4AC54E7",
    "2022_AM_PosterTemplate-BCMdata0929.pdf": "CBE91CE6-ED5C-45EF-9398-0FD791C8A943",
    "2022_AM_PosterTemplate.pdf": "8613D805-92AB-494F-9B2C-0CCD5902E7D1",
    "2022_AM_PosterTemplate.pptx.pdf": "201AC900-608B-43E1-AED0-42CEEE041810",
    "2022_CNS Update_Final poster PDF.pdf": "224BCD9D-793E-4E79-9AAB-744BDD51B589",
    "2022_CNS-TRACK-SOCIOEC_Poster.pdf": "E18C8A88-56B1-455F-A964-1C530E705F18",
    "2022_CNS.pdf": "4720D13C-CF47-4AAE-B43B-385EEC1F9F56",
    "2022_CNS_DataScience_LoR_Huang.pdf": "54C515F6-5771-49A3-8694-704972D3335D",
    "2022_CNS_Digital Poster_Joseline Haizel-Cobbina.pdf": "7F7FACDB-9AA9-415A-9794-98D8D281C926",
    "2022_CNS_Guidelines_LoR_Huang.pdf": "F0DC0BB4-ECBE-4BF2-8038-EA939F515C72",
    "2022_CNS_Hargreaves037702.pdf": "FE8BD39C-E728-48A3-BB3F-39890230657D",
    "2022_CNS_KVM.pdf": "2DBB8706-3F47-4036-8EBF-20AB943AB4C0",
    "2022_cns_meningioma_final2.pdf": "07347332-17FD-4122-A388-838E928CA737",
    "2022_CNS_MMC_Poster_Upload.pdf": "D7D8A81A-83B6-4069-B10B-693B9FD84934",
    "2022_CNS_POSTER.pdf": "DFFF5B55-8A8D-47AB-8C82-98E589340D7F",
    "2022_CNS_Poster_EEW.pdf": "CCF38277-17B5-4B41-BBDB-B1EDC4C408E1",
    "2022_CNS_Poster_Final.pdf": "3A318AFC-DDDE-4EE6-A1A5-075DF89DD49E",
    "2022_CNS_Poster_flexible endoscope.pdf": "E87C47A6-6223-4A0D-B3E0-0A04E9BE3B54",
    "2022_CNS_Poster_LRC.pdf": "6F0E90EF-D2DD-4415-9608-C34A74610F54",
    "2022_CNS_PosterFinal.pdf": "35E15510-B472-4F21-841B-3342F331731B",
    "2022_Legislative_and_Regulatory_Agenda.pdf": "C16B12A0-A84E-48C3-89C2-0BE821F15326",
    "2022_MCA.pdf": "EE05011D-5651-4EF4-AE9B-342789A84056",
    "2022_MCFAC_v5.pdf": "E1A25A06-A43F-4307-AC22-544E6C57C53E",
    "2022_Occipitocervical fusion CNS.pdf": "8FEEBF95-03F6-49E6-A782-20515E03DF1A",
    "2022_Peds Section Poster.draft.pdf": "51FA97A8-184C-44B6-8F67-86C9EEFCA70B",
    "2022_PedsMeeting_Chamberlin_Interlaminar_v1.pdf": "3F3CA6A0-04F4-4B67-A0C3-BF774C35DA11",
    "2022_PedsMeeting_Chamberlin_Occult_TCR_v1.pdf": "79815A60-D1D1-4C67-86EF-BD8046AC39BA",
    "2022_PedsMeeting_PosterTemplate_Final.pdf": "C372993E-A31C-492A-9F50-DAC715257A64",
    "2022_poster_Akshulakov.pdf": "156F9958-AB6D-4D76-819B-CC4EC989222E",
    "2022_poster_autofluorescence.pdf": "7C1CAD02-05DB-42C1-A217-457F241EE522",
    "2022_poster_Menlibayeva.pdf": "7DA4B8C9-9CC4-4340-8419-00CFDF27D0D7",
    "2022_PosterTemplate.pdf": "9FE5EFA5-652F-4957-B592-FE1D58AAF830",
    "2022_PS_Poster CNS.pdf": "427021B7-7DD3-4EBC-BB51-3BBE55CD95AA",
    "2022_SEEG.pdf": "E7D4BD1C-04D2-4F6F-B3C9-D6AB47368D48",
    "20220.pdf": "D6F02113-BE85-4FFA-9B97-F9765C1BAB52",
    "20220303 talks Nduom Edjah_CV.pdf": "910BF758-CC5B-4A0C-A16A-1B2862FA7B19",
    "20220714 PASC Sign-on Letter Final.pdf": "DD8636A8-6B64-4EA0-8E72-200A50BCF6A9",
    "20220815 - Lopez_GoodStanding.pdf": "B39B0A9B-7056-4F94-B1AE-FD2CC7032840",
    "20221.pdf": "248EF6C0-3A7E-439C-B57B-51AD218CE6F7",
    "20221019095752336.pdf": "26D3D3A4-1801-446C-9ADF-581E9409EE4F",
    "20221212_GoodStandingLetter_Standard_AllisonDana.pdf": "1D02DF8A-9110-4CE0-AD94-A8E72BAC75F2",
    "20223.pdf": "A6F160AE-32FC-43B1-BBD8-7356540D3FF6",
    "20224.pdf": "87DFFF2E-C377-470B-8E89-89A53A468668",
    "20227.pdf": "F7BE5D79-625F-4F8D-B2F7-696CDD62704D",
    "2022IHTBrainTumorPosterCNS_AK.pdf": "989ED13E-2335-4DBA-A4BB-DA83649889AE",
    "2022letterofgoodstanding.pdf": "DEB37736-E31B-44E1-91C0-D2C57684A26A",
    "2022TumorComplicationsAgenda_12222021.pdf": "FF8E2FF3-46FE-4957-8005-3A9DAB02672B",
    "2023  Pediatric Section Meeting _  Disclosure to Learners -11-17-23 4.pdf": "3CEAC642-EF1B-41DC-BCF3-D2407614D3D1",
    "2023  Pediatric Section Meeting _  Disclosure to Learners -11-17-23.pdf": "A4792763-3CD0-4EEF-A7A4-B1494DDC051F",
    "2023 Academy Scientific Program 9_26.pdf": "662A2FDA-B965-49D3-A648-6D60DD4AE757",
    "2023 Allan Friedman RUNN Research Grant Application  SERRA.pdf": "DB7DDC26-6224-4A20-8C0E-B83961FB2A3F",
    "2023 Annual Fee Disclosure.pdf": "8F0261BF-8D42-4DE5-9C6D-449F5AF4D05C",
    "2023 ANNUAL MEETING DISCLOSURE TO LEARNERS.pdf": "F0F74386-0863-40AD-9650-AFEEC5E37359",
    "2023 Ashok Asthagiri SNS sponsor letter.pdf": "B5F4B274-420D-4F88-AE05-C3F2FE69AD29",
    "2023 Ashok Asthagiri SNS sponsor letter49.pdf": "9DAE0444-10EA-4791-B8A5-E83CFA6C4E56",
    "2023 CNS Annual Meeting Poster IHG Case Report.pdf": "DB3BF6A1-7D77-4ABD-B1B6-00D1928D3CF4",
    "2023 CNS Annual Meetings Industry Support Prospectus Sponsorships Advertising Grants Branding - updated on 06.23.23.pdf": "CF21495B-1DEC-4271-88FC-474CA0916F54",
    "2023 CNS Annual Meetings Industry Support Prospectus Sponsorships Advertising Grants Branding 1.pdf": "7CFB1AF3-E728-4DAB-A521-D46A69C0DF01",
    "2023 CNS Career Guide D Hoh for Attendees.pdf": "2457327D-3D3C-4B8A-A7E7-96A1385B814F",
    "2023 CNS Digital Poster - Soummitra Anand.pdf": "DB6835C0-6F48-45A3-810B-EF93D59A0552",
    "2023 CNS Digital Poster NSRBP.pdf": "2EE4F996-291C-4A27-A2E0-4BD6C26AD106",
    "2023 CNS digital poster.pdf": "D3E72919-CBCD-4409-ABFC-97C981693247",
    "2023 CNS Epidural Abscess Final-1.pdf": "9DEDA03B-7C19-438B-8D8C-A55596F1AE4E",
    "2023 CNS Full Endoscopic SIJ Denervation Poster-1.pdf": "398F53DF-0A5E-4D6A-96DF-11760445B8FC",
    "2023 CNS Koga et al DEXA poster.pdf": "798B5DEC-6C8C-4174-B775-C5B3ADDBEE16",
    "2023 CNS Mini Oral Poster (#818).pdf": "867B8EFD-6A04-4426-8034-BCE5A03B1C51",
    "2023 CNS Patel 24M NSRBP final.pdf": "CFA46772-9562-4847-8DE9-A4509F4BE897",
    "2023 CNS Perc Embolization Poster.pdf": "5D5FE282-F352-4028-9164-F9968C691613",
    "2023 CNS Poster - TVCS.pdf": "590846DA-B92B-4A25-A834-ABE721DB7DC4",
    "2023 CNS poster(1).pdf": "220375F1-1762-4386-91B3-7681E3CAE81F",
    "2023 CNS Poster.pdf": "2CCDE41F-B513-4654-898F-365FCDDA29B8",
    "2023 CNS.pdf": "0D3A4B73-C812-454B-8C37-4FAFF3CB5D79",
    "2023 Consent to Record Form 2.14.23.pdf": "3BBE4A08-ED9E-4C08-8BF6-84E9CF7164C9",
    "2023 Consent to Record Form 2.14.23_Without signature lines.pdf": "57401EFD-9518-4E70-AA78-4A072BA2780B",
    "2023 Employee Handbook 11.pdf": "9DE3620D-EBA1-4BF7-879E-5F9B14FD8C08",
    "2023 Employee Handbook.pdf": "87B1825B-8D5D-4FE8-9062-F8401E285D88",
    "2023 enrollment verification.pdf": "000948DD-BA73-45EB-BD5C-608303320640",
    "2023 Essentails Agenda 01122023.pdf": "20033FE0-AB42-4F01-8228-6A552F860667",
    "2023 Essentails Agenda 10.17.22.pdf": "D919B7EA-5CD5-47FA-8B62-2472E4FA3FD9",
    "2023 Essentails Agenda.pdf": "E48D4F3B-C5C2-42F8-8CCA-0949FFDDA918",
    "2023 Essentials Agenda - Final 2.13.23.pdf": "91A24DEC-3C74-4782-BD42-4FA166A37A67",
    "2023 Essentials Agenda - Final 2.23.23.pdf": "819F4300-39AD-4A4D-9A59-826A584F1FD5",
    "2023 FEC I Agenda Book-Final.pdf": "4ABF15AD-C1A1-45F4-BCE9-EA2A96DC6EA5",
    "2023 HOPPS Proposed Rule comments. RRC D1015841-5.pdf": "85C55042-CFCB-4700-9F5F-A602913D96A3",
    "2023 Industry Allies Council _ Benefits  Recognition --- supporting the Congress of Neurological Surgeons CNS.pdf": "47BAA725-D4B5-4F10-9BA2-FD31CD78B2E5",
    "2023 Industry Allies Council _ Benefits  Recognition.pdf": "CAC17586-4431-4E80-81C5-0FFC9246CB5D",
    "2023 Jho CV.pdf": "8219D40B-AD49-4B91-8C42-8AF40540B3B5",
    "2023 JRC Agenda_Website.pdf": "A2B4BFF7-96F5-4D92-BCF8-524B403463F8",
    "2023 Leadership Institute Faculty List.pdf": "295F39AE-40BE-45D4-953D-45EF786F37EC",
    "2023 NSTP_Year 1 Application_Furey_Charuta.pdf": "A1A70E74-B859-4CDE-B2BA-4B2D8F61EDC4",
    "2023 NSTP_Year 1 Application_Hardigan_Andrew.pdf": "6F9E6A1A-3D13-4148-94B2-0129DB27E1C7",
    "2023 NSTP_Year 1 Application_Herring_Eric.pdf": "616AE06D-806F-4B8B-9BA3-165DB23D2044",
    "2023 NSTP_Year 1 Application_McGrath_Margaret.pdf": "20507B03-23D8-4838-8DAD-80575D72B41B",
    "2023 NSTP_Year 1 Application_Shah_Sheel.pdf": "237E9D57-9576-4A05-9E58-1DE8954C4C4F",
    "2023 NSTP_Year 1 Application_Tyrtova_Evgeniya.pdf": "6CF18195-EB4B-47DE-BA6D-2BA0ADA29DB9",
    "2023 NSTP_Year 2 Application_Asuzu_David.pdf": "8B3225ED-D8D8-449B-8248-11C80C2A6C84",
    "2023 NSTP_Year 2 Application_Aum_Diane.pdf": "70F2F942-8088-44BF-915C-42529F5395F6",
    "2023 NSTP_Year 2 Application_Carroll_Kate.pdf": "558359F8-A5CE-4580-B143-2BB5D011D019",
    "2023 NSTP_Year 2 Application_Eaton_Jessica.pdf": "87477F54-E7B1-43E6-A40F-64371451DC32",
    "2023 NSTP_Year 2 Application_Friedman_Gabriel.pdf": "D5A57349-5420-406B-93C3-602C1FE1EF7E",
    "2023 NSTP_Year 2 Application_Jamiolkowski_Ryan.pdf": "C2E102E4-C822-40E7-AA4B-4B2B0D92C9C3",
    "2023 NSTP_Year 2 Application_Jamiolkowski_Ryan_final.pdf": "697D0A11-2991-4812-B2AA-00A9DB05B6A8",
    "2023 NSTP_Year 2 Application_Lamsam_Layton.pdf": "9A0BC2F7-03D1-4F57-9BFA-159E6AF69C47",
    "2023 NSTP_Year 2 Application_Lehner_Kurt.pdf": "BACD5C24-FEC8-4DBA-93F2-6C518367F974",
    "2023 NSTP_Year 2 Application_Lim_Jaims.pdf": "6128C266-2275-4580-B35E-6E6D2D3169FD",
    "2023 NSTP_Year 2 Application_London_Dennis.pdf": "B1AF30F5-C3C3-463D-A292-7D0585EFAB2D",
    "2023 NSTP_Year 2 Application_Villa_Genaro.pdf": "2463F4D6-3593-4D10-88F6-1A0DFAFC9916",
    "2023 NSTP_Year1Application_Traylor_Jeffrey.pdf": "34310776-AF45-4B6F-8037-0592173BF1A6",
    "2023 Parker Neuro-Oncology Advances Epidemiology of sBM.pdf": "9C2AA9E7-54DF-449F-88B9-04623878387C",
    "2023 Pediatric Section Preliminary Agenda revised 05012023.pdf": "3770173C-46AA-4AA5-8385-E92C097D1D0F",
    "2023 Peds Networking session- flier 08212023.pdf": "3D983B1B-9C13-4360-B344-B412CC86E56A",
    "2023 Poster.pdf": "9ACEBBC2-BB79-43B5-B472-BEEFB8E20697",
    "2023 Prelim Faculty List - RUNN.pdf": "13FBE77C-FFC7-4231-9442-31DABA806727",
    "2023 Resident Housing Application.pdf": "8143A1A2-57EF-4FE4-86F7-52D71BBDA4D4",
    "2023 Resident Housing Guidelines.pdf": "EA8DA101-50F3-4AF9-886A-8C38C5E3AE9A",
    "2023 RUNN - A Novel Neuromodulation System for Restoration of Function after SCI - Abraham - Mickey.pdf": "80B7B691-0A15-465A-B370-AC816F1FD561",
    "2023 RUNN - Program Director Approval - Abraham - Mickey.pdf": "5BF426D2-B7C6-4C0B-A430-CC83C74E2354",
    "2023 RUNN - Program Director Approval - Castillo - Jose.pdf": "699F2B10-8C37-458A-A1AE-33073F95088B",
    "2023 RUNN - Program Director Approval - Diamond - Joshua.pdf": "D902031D-CEDD-4A9F-B6AA-25D1E30E4F2B",
    "2023 RUNN - Program Director Approval - Dietz - Nicholas.pdf": "9DDE0884-87BF-47A9-88A3-1328973E4B48",
    "2023 RUNN - Program Director Approval - Hale - Andrew.pdf": "CFC99B7C-C0C1-42EC-80EF-0F69910EB22E",
    "2023 RUNN - Program Director Approval - Katlowitz - Kalman.pdf": "BB59A47D-1E5E-4B2F-856E-3C812A895EE7",
    "2023 RUNN - Program Director Approval - Moor - Rachel.pdf": "33993AA0-CAC3-4C31-BC32-88C632BA5B27",
    "2023 RUNN - Program Director Approval - Muftuoglu - Yagmur.pdf": "CD335614-3EDE-485F-9452-EFA061013BF6",
    "2023 RUNN - Program Director Approval - Xia - Yuanxuan.pdf": "A7A27309-D8E0-4E9C-968C-CEC33EAA6F68",
    "2023 RUNN - Program Director Approval - Youngblood - Mark.pdf": "69EF7297-4D74-4E5B-81EC-7B11310E23F0",
    "2023 RUNN - Proposed Resaerch - Choi - John.pdf": "53D43D9D-3294-4855-9C84-BA1933FE54D4",
    "2023 RUNN - Proposed Research - Diamond - Joshua.pdf": "57D05417-8A29-4B1E-92B1-2F9B4BF44591",
    "2023 RUNN - Proposed Research - Hale - Andrew.pdf": "19EB5F64-1E81-4C75-9585-105DAA084A26",
    "2023 RUNN - Proposed Research - Muftuoglu - Yagmur.pdf": "33F91B12-7330-4FFE-A5B5-D141C45F4E88",
    "2023 RUNN - Proposed Research - Xia - Yuanxuan.pdf": "A1917493-641F-4133-8EB2-08A251EF5944",
    "2023 RUNN Course Preliminary Agenda.pdf": "E215F180-F868-453E-9731-024793939180",
    "2023 RUNN Resident Award Application - Program Director Approval 1 1.pdf": "69A4C04D-13FE-44AE-8882-8D6C5DC2F8BA",
    "2023 RUNN Resident Award Application - Program Director Approval 1.pdf": "8D909BBB-AEDD-422F-87AC-F4FD79B59685",
    "2023 RUNN Resident Award Application - Program Director Approval.pdf": "1985D8A4-699A-4240-AFDD-74E65052F58F",
    "2023 RUNN Resident Award Application - Proposed Research.pdf": "5CC4AEE0-E310-41C9-9D77-4F41AF1DE802",
    "2023 RUNN Resident Award Application Updated - Proposed Research.pdf": "9CEFFC46-2D0A-4696-93AB-B237CBFD4DF1",
    "2023 SANS Written Boards Review Draft Course Agenda.pdf": "A224B8F5-9A26-491E-AE23-72E36903F3AA",
    "2023 SCC - v1.pdf": "80D1125F-42B5-491E-8918-B7F3412C30F8",
    "2023 SCC - v5.pdf": "A4C4108E-84E2-48F9-81A9-AFAA92FF3F38",
    "2023 Senior Resident Course Agenda - Spring 14.pdf": "8F800F61-E658-4D5C-8907-03BFE12B92A8",
    "2023 Senior Resident Course Agenda - Spring.pdf": "D7BF6020-3B01-4FCC-8377-1C28674AEC84",
    "2023 Sept Journal Article.pdf": "2C18B5CA-4A13-4A3E-80E4-213488478D0C",
    "2023 Skull Base Course Agenda_Draft_4-28 1.pdf": "EDB8B3D0-5671-4096-9E95-E2D858AB774E",
    "2023 SNS ANNUAL MEETING -DISCLOSURE TO THE LEARNERS-5-17-23 Update.pdf": "5D4F851A-9FD6-42BD-B263-33BA6BDD98D9",
    "2023 SNS Bylaws - Finalized and Approved 5.22.2023 002.docx.pdf": "C09A108B-9CE0-4D9E-8A00-6395C2AE8B2A",
    "2023 SNS Final Program_For Website.pdf": "A1EFEC15-BF80-476F-9B72-9653C84A64D9",
    "2023 SNS Medical Student Teaching Award_CV_Fred_Meyer.pdf": "6123BF1F-939D-425C-996A-7482052E83BE",
    "2023 SNS Medical Student Teaching Award_CV_Javad_HekmatPanah.pdf": "6476242E-F55C-4EEB-844E-2516C9B44069",
    "2023 SNS Medical Student Teaching Award_CV_Michael_Scott.pdf": "CF1D8152-02A9-4656-8ABB-F26370A06554",
    "2023 SNS Medical Student Teaching Award_CV_Steven_Brem.pdf": "182F8092-AFA9-4688-994A-91B77C282592",
    "2023 SNS Medical Student Teaching Award_Javad_HekmatPanah.pdf": "06A6C8E2-E4C0-4BA2-8096-DEAC1FD366E7",
    "2023 SNS Medical Student Teaching Award_Michael_Scott.pdf": "F72E5BDA-4883-43B0-8A77-25803F899477",
    "2023 SNS Medical Student Teaching Award_Steven_Brem.pdf": "7EBB9EE1-51D2-4CF1-818C-D5A28C3D2A42",
    "2023 SNS Medical Student Teaching Award_Support 1_Fred_Meyer.pdf": "89176102-4135-475F-AF91-E6232DABA3A7",
    "2023 SNS Medical Student Teaching Award_Support 1_Javad_HekmatPanah.pdf": "43803927-1A85-4EAA-A19F-C71E725946EF",
    "2023 SNS Medical Student Teaching Award_Support 1_Steven Brem.pdf": "479E7FCB-227F-4B32-989B-1A41DB0D974E",
    "2023 SNS Medical Student Teaching Award_Support 2_Fred_Meyer.pdf": "5ECFF956-0B77-440C-BA29-219FCC827ECB",
    "2023 SNS Medical Student Teaching Award_Support 2_Javad_HekmatPanah.pdf": "25F27F30-866D-43FF-9E5E-CC0E036D0B78",
    "2023 SNS Medical Student Teaching Award_Support 2_Steven Brem.pdf": "21DC0744-6ED4-40FD-8394-9BD0059F5073",
    "2023 SNS Medical Student Teaching Award_Support 3_Fred_Meyer.pdf": "3EBA8A76-E13B-4D11-8C11-39053ABED660",
    "2023 SNS Medical Student Teaching Award_Support 3_Javad_HekmatPanah.pdf": "69ADBEDB-F2BD-4110-82A5-22395768F380",
    "2023 SNS Medical Student Teaching Award_Support 3_Michael Scott.pdf": "25EE2862-7BCD-429E-AB13-694F9625D1BE",
    "2023 SNS Medical Student Teaching Award_Support 3_Steven Brem.pdf": "3F249AB4-7895-482F-82CA-466624F5CDEE",
    "2023 SNS Medical Student Teaching Award_Support2_Michael_Scott.pdf": "7708EA35-8B55-4367-AB7C-D0B5B7747564",
    "2023 SNS Medical Student Teaching Aware_Support 1_Michael Scott.pdf": "EF56067B-22E4-4CE5-BED8-5B07370E2E5A",
    "2023 SNS Medical Studnet Teaching Award_Fred_Meyer.pdf": "2F922817-D454-448B-821B-73C35178B5DD",
    "2023 SNS Preliminary Program_Final.pdf": "0A45D7DC-1EA5-48D6-B35A-5EC4CB9FEF96",
    "2023 SNS Residency Administrator Award_Christina_Dominique.pdf": "5F5B280C-2B89-433D-BB0A-0AE32C7190AC",
    "2023 SNS Residency Administrator Award_Nominee Kathy_Guzman.pdf": "E076E20F-5DE3-466F-B764-46280579BDA8",
    "2023 SNS Residency Administrator Award_Nominee Melissa Lukehart.pdf": "68A79FFA-116F-4CF4-A518-21C3A967981F",
    "2023 SNS Residency Administrator Award_Pamela_Lane .pdf": "51C78F02-755F-4DD8-B3C9-3F70971A1656",
    "2023 SNS Residency Administrator Award_Rita_Raio.pdf": "3DEEFD2D-FAE8-48D5-9562-924FD9C3DBF0",
    "2023 SNS Residency Administrator Award_Susie_Hines.pdf": "EBF15571-4089-447F-B2A1-F5D21861B46F",
    "2023 SNS Residency Administrator Award_Tara_Anderson.pdf": "2EF6187D-2898-4F09-94D5-006E3FAF9F4B",
    "2023 SNS Residency Adminstrator Award_Kellie_Devney.pdf": "E174CBF0-887C-4EBF-87E6-C49FCBD73164",
    "2023 Summary Annual Report.pdf": "DCD33858-5B5E-4856-B7FC-7C06EB10EEF1",
    "2023 Telecommuting Work Application and Agreement.pdf": "E1BDB761-F171-4A49-9C20-7C5F4572AE7A",
    "2023 Virtual Intern Boot Camp Agenda as of 7.10.2023.pdf": "A596E313-0E7F-45B0-8796-1905984BBE54",
    "2023 Virtual Residency Fair- Program Schedule.pdf": "F62EC35D-E4C3-4AAA-B3E1-116D97EF2AC0",
    "2023 VRIP Guidelines and Expectations Form-2.pdf": "E3CDF696-3180-4C63-A0C6-7422CA9BE2B5",
    "2023-03-03 Athar N. Malik CV.pdf": "07781BC4-03D3-4217-B16F-4AD82489DFA8",
    "2023-04 DELAVARI Nader_Letter of acceptance.pdf": "2C67EA0C-0F92-4B0F-9C73-B2CCE081E258",
    "2023-04-01-Escalante-Reyna-Letter-of-Good-Standing.pdf": "689937C5-5A05-4937-8528-1EE6A4566EFA",
    "2023-06-14 Thum_Jasmine_CV_Jobs_General.pdf": "C8D4EAF7-367C-4C7A-9953-3968A621CE20",
    "2023-11-16 Signed on letter 2024 Medicare Payment Cuts.pdf": "9972061B-AFEF-42FC-ACB8-7463827B3156",
    "2023-2024 CNS EC Resource Guide.pdf": "FFB7BF4F-7614-4140-B2A2-C0C70103D75E",
    "2023-2024 SNS Recruitment Updates – Part 1.pdf": "FD5C9CA0-A09D-4D4E-9EDE-74BFD456AEB4",
    "2023-24 SNS Recruitment Guidelines.pdf": "B1F44BB1-B75F-4BBB-A67E-4D3053B8147F",
    "2023-CNS-AN schwannoma-1.pdf": "84B4E664-2B4D-4229-8CFD-EA56886495CB",
    "2023-CNS-Brand-Guidelines.pdf": "BB1370CE-92C6-4DCA-B270-ABC7F34461BE",
    "2023-CNS-digital Poster02.pdf": "13B3681A-74EE-486C-8057-37FEED64BD86",
    "2023.10.18 Dr. Dennis Maimans Letter of Recommendation for Dr. Karin Swartz-e.pdf": "C09657C6-C389-43E0-9DFC-297C66FD2134",
    "2023.10.23 Dr. Nathan Zwagerman SNS.pdf": "7E8FDF45-6809-4D4C-8B8D-84CE3E6EB275",
    "2023.11.28_McCutcheon Ian_CV.pdf": "DCC73A01-1F09-4C02-B25E-660BFFAB543A",
    "2023_AM CNS Shenoy Visual Abstract.pdf": "ECA2DAA2-1F8B-43FE-A8DC-FCE6C7B9FE77",
    "2023_AM Visual Abstract _ Homeless project _ Cole KL.pdf": "C98EC9CC-EC82-477B-B8FC-785A477E8270",
    "2023_AM Visual Abstract Template (1).pdf": "B2B8392F-3E56-4F3E-8ED5-A0A5645E6779",
    "2023_AM Visual Abstract Template 1.pdf": "CD647CDF-8BDF-4240-AC8B-A20F5D16114A",
    "2023_AM Visual Abstract Template 2.pdf": "CF38D00F-4381-4886-9995-8242B8940914",
    "2023_AM Visual Abstract Template.pdf": "9BDA3CB9-9AB2-4DB4-9700-3B45A0DD66A1",
    "2023_AM Visual Abstract(Shusaku Noro).pdf": "4A960B94-B371-413D-A47E-225B31E57654",
    "2023_AM Visual Abstract_Krishna Joshi DHC.pdf": "3444C6AA-1516-4610-BB2A-8AE0EF2ABBA5",
    "2023_AM_Andrea Cuschieri.pdf": "61BED2B3-EBED-4EA0-BC20-46046BCFEE74",
    "2023_AM_Poster 2831.pdf": "C39DF6FF-A145-41EF-9E7C-F9CED21BB8EB",
    "2023_AM_Poster CNS.pdf": "C3984ABA-F46E-48DC-BA1C-70CD96DC8293",
    "2023_AM_Poster final Rati.pdf": "6EE4A9BC-9383-4550-AE8A-EFB6DFA58737",
    "2023_AM_Poster Wiseman.pdf": "5FFF75E6-1CFC-4D03-B5A9-C95FFC57CB94",
    "2023_AM_Poster-2.pdf": "B798DABD-B1F7-4254-93B3-FB4C67596B9A",
    "2023_AM_Poster.pdf": "B7F7A55D-3E3B-41C3-B95F-23CCFE720E62",
    "2023_AM_Poster_ECoG_v2.pdf": "980E9FDA-6AB2-4A40-B8F0-A74596C2EC03",
    "2023_AM_PosterFinal.pdf": "6839DFA4-28A2-48C9-A83E-509C8A265835",
    "2023_AM_PosterTemplate-_Lumbar.pdf": "23617D0F-FCBA-47A2-9E0E-860A8159D503",
    "2023_AM_PosterTemplate-1.pdf": "D46AED5C-B6E9-4670-884A-69E848576F6D",
    "2023_AM_PosterTemplate-Awake.pdf": "49F6DFE1-ED75-43D6-8391-BCA8745A7793",
    "2023_AM_PosterTemplate.pdf": "2F7272CB-0D23-4254-8F03-23A887FD7E7C",
    "2023_AM_PosterTemplate.pptx.pdf": "74340994-DE40-4FC9-8410-48680D5BBE18",
    "2023_AM_PosterTemplate_EEW.pdf": "E0F6BFC5-362E-416E-91D4-C8DA81E27A86",
    "2023_AM_PosterTemplate_GolnazMoallem_Final.pdf": "049958F3-192E-4C69-B653-646EA3A9752E",
    "2023_AM_PosterTemplate_Karunakaran.pdf": "FE517FDC-8457-430B-984F-44B904A0B355",
    "2023_ANSAH_CNSposter.pdf": "F7462416-229A-4C6C-840F-D3B74FD4761D",
    "2023_CNS Digital Poster- Neurosurgery Residents Education in Ethiopia.pdf": "11A2280A-737A-4D67-A9D0-43DEA1D1379D",
    "2023_CNS Printed Poster-The Role of Race and SDoH on GBM Outcomes (1).pdf": "749F6841-97B2-47E1-BC1A-04B5D9C1C3E1",
    "2023_CNS_AM_Brain Mets.pdf": "979D642B-73F7-44E5-B4D6-42EAA3E9B996",
    "2023_CNS_AM_HighImpactSpinalSurgery.pdf": "38F62C0B-20A7-4818-B8B5-B2F6CB397AA4",
    "2023_CNS_cranioplasty.pdf": "C7EB37F4-D851-493D-870B-B5B2AFE9AB09",
    "2023_CNS_MedulloPoster.pdf": "71D28F12-39FF-43C0-A020-9FE8C052ABA2",
    "2023_CNS_Poster (KS).pdf": "2913D09D-6665-4F1F-B095-20BB2FBB0B1E",
    "2023_CNS_Poster (OS).pdf": "FCFC11CA-9479-4CEA-9E21-50070F169E69",
    "2023_CNS_Poster 1.pdf": "4041B4CA-6E9B-4AFE-B496-563B6C2BF790",
    "2023_CNS_Poster 2.pdf": "86473C9C-CEED-4399-B677-14FE120C217B",
    "2023_CNS_Poster.pdf": "62A79DEF-9248-4F26-8D2A-02A715E9D03F",
    "2023_CNS_Poster_AaliyahSchultz.pdf": "504FADF9-994D-4575-AF9F-F9CA8317D33C",
    "2023_CNS_Poster_AJ.pdf": "76F99D15-6897-482D-A016-C3894655129E",
    "2023_CNS_Poster_DO_TC_AD_Medicare.pdf": "D36BD654-D92A-4A9E-AED7-A344A1B71720",
    "2023_CNS_Poster_LK.pdf": "765DFEAB-F9F1-42A4-BB7E-7649699F334E",
    "2023_CNS_poster_ZAM.pdf": "A6FE7EC5-FAFB-486B-AB6D-ABFF9AE84AA9",
    "2023_CNS_SkullBaseReconstruction.pdf": "73E71F41-12C2-4846-80D6-C91DF8A9982F",
    "2023_CNS_Visual Abstract.pdf": "03031805-F936-4519-BE1D-F5219B303D35",
    "2023_CNSPoster_HybridNavigation_CS_Jul30_edits.pdf": "DD818A65-7BEA-4809-BC99-FB0AD0BD5C4A",
    "2023_CNSTumor_RRfinal.pdf": "4FB29459-3BE7-4C80-8FF4-C231D3103FEB",
    "2023_DigitalPoster_Tandem Detethering Case Series_Sagaityte.pdf": "E54D2392-7F6C-4BF4-B7FB-82D6277C16C8",
    "2023_DTI_IMSCT_CNS.pdf": "8CF8EA11-7EBD-43DE-9E0D-E86617A365AF",
    "2023_EB_CNS_Poster-final.pdf": "A0338C4E-3E42-4192-AF13-AEE9A1D10E23",
    "2023_FLNSUS_CNSPoster.pdf": "D08CB175-C2FD-48BD-B793-DDD14F541312",
    "2023_GT_orders.pdf": "54ECE507-979F-4A72-92B4-335CAA13F3E5",
    "2023_Homelessness_Peds_CNS.pdf": "E14020BC-6D43-47B4-8A2B-CB9FDF4AF5E0",
    "2023_LPC_CNS Poster.pdf": "1709CBC2-F684-49EC-AE99-12149920D752",
    "2023_MEMO.PDF": "6E56E5A8-32FC-4A64-9CA9-38310DFB06B3",
    "2023_Mini Poster 816_Match Analysis.pdf": "AB32EC2F-2CB1-403E-8E7E-4F17CA73677C",
    "2023_MStephenson.pdf": "4FB040B6-8E9A-49AC-8CB2-4BF87AF481A8",
    "2023_NSTP_Year1Application_Kerezoudis_Panagiotis.pdf": "8E23881C-7470-4A44-AA85-962C812CFD92",
    "2023_Paging Study Poster_Updated_With New Number.pdf": "B6C14F2C-E1E4-4D7C-9D18-B3F969B0B313",
    "2023_Peds_DigitalPoster.pdf": "64803EBC-E5B1-4050-8EB2-D5A5B30B266A",
    "2023_Peds_DigitalPosterAiroiCT[78].pdf": "0B606580-E077-45DB-BF52-528E73177848",
    "2023_Peds_DigitalPosterChiari.pdf": "BE6766D4-F94C-499F-9291-999CD4A41211",
    "2023_Peds_DigitalPosterShunt.pdf": "3BAA6E5F-ADD0-43B0-814F-13DC28C585B5",
    "2023_Peds_DigitalPosterTemplate.pdf": "4EAD3E77-62E9-454D-AF7C-0737568A7A24",
    "2023_Peds_OKC_Poster_Chamberlin.pdf": "A6E46EE4-3422-433E-9D97-E64023503FBF",
    "2023_Peds_Pediatric_Intracranial_Infection_DigitalPoster.pdf": "527F3526-BBE3-4A09-9F9E-2D87D21E355A",
    "2023_Peds_SEEG.pdf": "F553CBE3-D551-4E0A-AE93-C366C0549588",
    "2023_Peds_sEEG_FINAL2.pdf": "307863A4-AE48-4E7F-A362-FE9B860F8B69",
    "2023_Peds_ZPD_FINAL.pdf": "4F46D888-BE16-4186-8B05-123DD4AFCF0C",
    "2023_Poster.pdf": "39CFB265-BA6D-41F9-8210-3AA9B9877B0A",
    "2023_Poster_Antiplatelet_Mechanical_Thrombectomy.pdf": "77C12D7F-BA51-46D5-9CD6-94D6806F395C",
    "2023_Spine Frailty_CNS.pdf": "43C5F4B6-A6C7-4277-8E21-549D1B86AF7E",
    "2023_Tumor_Brain Mets.pdf": "69A3E9EA-F471-4994-907F-6320977B70EF",
    "2023_Tumor_Poster AN schwannoma.pdf": "E0172F52-ED33-4D9B-B512-3ED3B07634F8",
    "2023_Tumor_Poster-MBN SES.pdf": "6D0477B1-59FD-4881-9313-804BE5210102",
    "2023_Visual Abstract.pdf": "35B8F6FC-5A6F-4AF5-8A53-92F930F72E2C",
    "2023_Wassef_NELscopes.pdf": "9A002B4C-2119-47F5-AD80-33FB40E5F1B2",
    "20230.pdf": "4D805884-A6B6-4199-8362-F0755C897C85",
    "20230301140726.pdf": "63F92485-AF25-49CC-9D9B-82FA58AC10E0",
    "20230629170112-SFCR_TPORTELLA.pdf": "31AFE91F-1462-4724-AB53-622E8307BE78",
    "20230711-Halldór Skúlason.pdf": "F305CE33-C4D7-4FFF-8C47-E973C21F769A",
    "20230903_Levitt Society Nomination.pdf": "B56C732C-03FC-4135-BABB-44D3F89667FA",
    "20230922103712548.pdf": "F3A4C2AE-3481-4C63-9BEE-DF564A23E3A8",
    "20231027_Lee SNS LOR.pdf": "B65837F6-530C-4B9A-A663-6F8F6664208C",
    "20234.pdf": "222D1B92-10F7-4931-A203-F1D37425D235",
    "20235.pdf": "E3335183-BAEB-40D4-9705-4F32E8CFC330",
    "2023510490 Constancia de Inscripción 2024-02-14.pdf": "0B510549-5340-4CAE-849B-55582B0135B3",
    "20236.pdf": "6893471E-D9F4-467A-938A-8CB033CC5E58",
    "20237.pdf": "F9A8F91C-4874-479A-9991-120C46077A0F",
    "20238.pdf": "5E770684-F4C3-4705-BFC3-F7E3ADD7AC47",
    "2023CNS-TumorID-GliomaMet-Poster.pdf": "D917818F-1AF4-4FE9-88B4-F1781DCBBC68",
    "2023CNS.pdf": "27F03B3E-F477-400B-A26A-E922B35D17B3",
    "2023CNS_Poster.pdf": "6E23DD5D-A138-4151-AAD3-A057FF0A23A6",
    "2023CNS_TBI_TEMPLATE_DigitalPoster.pptx.pdf": "C596063E-BC5E-4FFD-88A5-30C722260549",
    "2023CNSPoster_V6.pdf": "BA8EB779-B8D2-4380-9C32-FF1C34AE6CCE",
    "2023CNSTumorComplicationsAgendaCGH_Draft 3_1.20.23.pdf": "BC788734-47E9-45FF-9EE6-B14734C5B126",
    "2023CNSTumorComplicationsAgendaCGH_Draft 3_1.5.23.pdf": "902296C5-2249-4A0C-ABFA-45C468DCB158",
    "2023MSR_Transcript_Oshotse.pdf": "D1EF79A5-25C9-430A-9A49-C452157FA84A",
    "2023NSTP_Year2Application_GiantiniLarsen_Alexandra.pdf": "DE3A5187-4A93-4D63-B8A9-AADABBEC27C2",
    "2023Peds_Development_GMK.pdf": "2E5C65DC-D4FE-4202-B38A-41825C2A07D2",
    "2024 ASSFN Bifold_web program highlights 12202023.pdf": "B6E56421-981F-4A9A-AB1E-7AB2550700B1",
    "2024 ASSFN finalfinal.pdf": "EDA0A1F8-1CC3-4145-9629-F58CC076148E",
    "2024 ASSFN LopezRamos.pdf": "F8BF8789-EF12-478F-80DA-222C73F1B6A8",
    "2024 ASSFN MEETING Disclosure to Learners.pdf": "43BDD659-59E5-4ED9-8672-98C0CC2EE0D2",
    "2024 ASSFN Poster LIFU Girgis.pdf": "DC50D251-4783-4E13-8DC8-63CE582A8C44",
    "2024 ASSFN Poster_MH.pdf": "35D1B388-15DC-469E-BD0E-CB6502C1A335",
    "2024 ASSFN Prelim Prog_PrintReady02122024.pdf": "E94A0C4A-52C6-4412-BB57-AB04D5F1C655",
    "2024 ASSFN Prelim Prog_Web 04192024.pdf": "1FADCB78-6308-44F5-A3D4-3F5D61B8C06E",
    "2024 ASSFN Prelim Prog_Web.pdf": "C7643E0B-AAD5-44FD-919E-DFAEDF106774",
    "2024 ASSFN Prelim Prog_Web02122024.pdf": "1DAC25CF-F28F-495B-A4CD-EF0C762A08CB",
    "2024 ASSFN Prelim Prog_Web02122024_2.pdf": "EEABFA2D-DDF9-4F1C-BD22-01AD76DDEDAC",
    "2024 ASSFN Program_web revised 06032024.pdf": "D7DD5BD0-5B98-4E0D-B4AF-E2800A729303",
    "2024 ASSFN Program_web.pdf": "498A4AF2-5079-4C42-818B-042A08D0A619",
    "2024 ASSFN YS V3.pdf": "824CA1C1-588A-483C-8FED-58DF9C0FBD23",
    "2024 ASSFN_Abbott_MIC0.pdf": "C26464C7-400F-4FDE-965D-B6278ACA9B76",
    "2024 ASSFN_Case Series Poster_v4-2.2.pdf": "12BCA143-460A-4139-8CB0-7E87BAE6F876",
    "2024 ASSFN_CL-SCS Motor Control.pdf": "2946A7EC-9005-43A0-9840-4D49F12D8AFE",
    "2024 ASSFN_Dystonia.pdf": "7B792CF8-703C-4285-B7C2-7A604F645A15",
    "2024 ASSFN_final.pdf": "E8CFF974-1152-4BF9-A548-FFF638B0C19C",
    "2024 ASSFN_Greydon_Gilmore (1).pdf": "94B44D83-D31B-4853-AE23-D4E8D69B9381",
    "2024 ASSFN_ID148.pdf": "293C5965-4699-4012-B93D-286F0BA3492D",
    "2024 ASSFN_MD SCS 1Apr2024.pdf": "BCA8DE4F-0400-4262-B77B-1BC3A7BC03BF",
    "2024 ASSFN_Myoclonus_v1.pdf": "70DD10FA-989E-488A-B734-12D759FA377A",
    "2024 ASSFN_Platt.pdf": "8B3319D5-FC9C-413A-9221-45EE40761705",
    "2024 ASSFN_Poster PB.pdf": "A21F269F-6153-4D36-8454-E4516A31F8DD",
    "2024 ASSFN_Poster x3.pdf": "9DBAD9D5-ACB8-4A18-9DFF-A8F485B8F057",
    "2024 ASSFN_Poster.pdf": "2D2B99FA-7A7A-43D3-A141-0FCB36780C31",
    "2024 ASSFN_Poster.pptx.pdf": "D2E63BB3-3C0A-4CF6-8A36-74189D194E03",
    "2024 ASSFN_Poster_271.pdf": "5B046DBD-8BF4-4EE4-A1EB-80E023F97F37",
    "2024 ASSFN_Poster_draft1_EMR.pdf": "846AA601-65B3-4231-8873-70A914789F00",
    "2024 ASSFN_Poster_Kang_v2.pdf": "3DCF77B5-625D-4ED1-B1BE-66E59DE00603",
    "2024 ASSFN_Poster_Mocchi.pdf": "67182283-049F-4232-8C90-0C5E73740E9C",
    "2024 ASSFN_Poster_Novel_Stim Final_with_Images_PDF.pdf": "A8AA12DB-7204-4E15-B3A6-F828FCC370A5",
    "2024 ASSFN_PosterAbdulrazeq.pdf": "2279AEF8-0953-49E1-886E-DFBEFDE9F0B3",
    "2024 ASSFN_PosterHargreaveset_al.pdf": "78D1EC4B-CC3E-4F4E-9132-31CBB4FDA9FE",
    "2024 ASSFN_PosterTemplate_bgf_Disparities_DBS_Dystonia.pdf": "76000DCC-8876-4A96-A0AC-70FE2E28F49E",
    "2024 ASSFN_PosterTemplate_Denervation.pdf": "71C8FCAA-AA4D-4238-8A9A-B4FB9B73918D",
    "2024 ASSFN_PosterTemplate_OCD.pdf": "A9BEB3D4-8BD8-4AD8-8609-0392D83D9AA4",
    "2024 ASSFN_PosterTemplate_pain in PD.pdf": "E7F32DA0-F9B5-4C64-9F63-3D404D94C4CF",
    "2024 ASSFN_PosterTemplate_ra_DJE.pdf": "15F70698-58CD-408B-A97C-D664C9193B09",
    "2024 ASSFN_PosterTemplate_TC.pdf": "E52524EA-434E-4AF8-B3A3-9D049D43A1F6",
    "2024 ASSFN_Posterv4.pdf": "D32EF9D9-59CD-4B48-840F-695E41321C88",
    "2024 ASSFN_v3.pdf": "98EFBD2B-EDDC-4A91-BF04-A1B36B088053",
    "2024 CNS Employee Handbook.pdf": "508515E1-26F8-4DE7-A03C-3F1528CEA535",
    "2024 CNS Leadership in Health care Course 1.0 Agenda.pdf": "7948491D-E5E9-4921-8BEF-1D4D77BB2B6C",
    "2024 CNS Vanguard Leadership Course Agenda 5.7.24.pdf": "669B6DE0-0FF1-4A9D-A56B-3397EFEFBA7B",
    "2024 Congress of Neurological Surgeons Cover.pdf": "14D15A39-077A-4BC0-BAAA-40F119E30CDC",
    "2024 CV Carlos Candanedo.pdf": "B695B97A-95A1-46B1-B84C-4185E9B27AF8",
    "2024 Data Science Agenda.4.16 with disclosures.pdf": "88B90E28-33C2-4F30-BC80-6E9E83E504E4",
    "2024 Data Science Agenda.4.16.pdf": "A3B9EB05-4E7A-423A-B924-673C751A0DB0",
    "2024 Data Science Agenda.pdf": "A8D7ED58-9CD3-49A5-A656-4ABF30B865B4",
    "2024 Data Science Agenda.v6.pdf": "FFCE2836-FE73-473C-9FCC-712F938B261E",
    "2024 Form W-4.pdf": "5FEEDD6C-7E7E-478A-A24C-17897A5F1E0D",
    "2024 Holiday Schedule for CNS Full-Time Employees.pdf": "DED6F2EF-7867-442A-B83A-5557F8FA9DB7",
    "2024 Intern Boot Camp Preliminary Agenda_Feb. 2024_For Website.pdf": "72DE0A5E-2AEE-42CF-85DA-24803997F3FB",
    "2024 JRC Agenda_Website.pdf": "663DAA26-3DA9-4D6A-948D-6795CE5855DF",
    "2024 NSE Course Agenda 2.pdf": "40A9E63C-F2FE-49F7-91B1-F8FE7FD74BB2",
    "2024 NSE Course Agenda.pdf": "BD3AC0CA-2199-4640-804F-8AF38DF6236A",
    "2024 NSE Course Agenda_Updated.pdf": "C25F0BC8-89B2-44BB-98D7-0B3567824632",
    "2024 NSTP_Year 1 Application_Abecassis_Zachary.pdf": "666F5A4A-849B-4168-9ACA-78DD191A368C",
    "2024 NSTP_Year 1 Application_Baisiwala_Shivani.pdf": "BDE03C4C-7F0B-4F1B-AA65-BD36122ECE09",
    "2024 NSTP_Year 1 Application_Castillo_Jose.pdf": "08A40C5E-90CD-4687-B8C7-4AF8CCF9CF31",
    "2024 NSTP_Year 1 Application_de Leeuw_Charles.pdf": "ACE56080-0576-4BFE-B6D9-9F34B5D0D7CC",
    "2024 NSTP_Year 1 Application_Goethe_eric.pdf": "01F697CB-FB7A-4C73-B84D-0ADF74CAE91D",
    "2024 NSTP_Year 1 Application_McAvoy_Malia.pdf": "E024B387-EF10-4108-AC87-3323B2E8B2AE",
    "2024 NSTP_Year 1 Application_Mott_Bryan.pdf": "B38B1A1A-D4F2-4E78-BCC0-278EE18799F8",
    "2024 NSTP_Year 1 Application_Nichols_Noah .pdf": "3EE5A35F-3AD9-4A7E-9EC9-1032C2E390C3",
    "2024 NSTP_Year 1 Application_Nistal_Dominic.pdf": "C7E2CD91-EEA0-4B63-B4DD-B3BAFA54DCF1",
    "2024 NSTP_Year 1 Application_Scheitler_Kristen.pdf": "8EE48E18-128F-49B9-8435-C5E623048376",
    "2024 NSTP_Year 2 Application_Alawieh_Ali.pdf": "A19EFB19-01B8-45A6-9AC5-5F50487C0958",
    "2024 NSTP_Year 2 Application_Diamond_Joshua.pdf": "DF3805DA-58FE-40E4-B3E5-0EFDE8EDE912",
    "2024 NSTP_Year 2 Application_Estevez-Ordonez_Dagobert.pdf": "CF7588A2-67DE-434B-8509-88E1F8E1D5A1",
    "2024 NSTP_Year 2 Application_Jahangiri_Arman.pdf": "5C199C1F-E123-4DF0-8EB6-F31CF7B80F70",
    "2024 NSTP_Year 2 Application_Kim_Lily.pdf": "F277C496-1618-4BCB-879A-93004BFC12E9",
    "2024 NSTP_Year 2 Application_Muftuoglu_Yagmur.pdf": "92B59C94-DF56-4DC4-8F65-CFB99A2F3592",
    "2024 NSTP_Year 2 Application_Youngblood_Mark.pdf": "14DB5289-5546-49BA-B795-E7EEF30839B2",
    "2024 Presentation Guidelines - ASSFN.pdf": "97716AAC-CA41-4545-AB8E-90CED19488E6",
    "2024 QDIA Notice.pdf": "C31624B8-BF7B-446D-8540-FF2D5F643BA1",
    "2024 Resume_Ref.pdf": "5D30BA63-8CAF-409E-97B6-602050A169DF",
    "2024 Safe Harbor Annual Notice.pdf": "912899E6-17FF-44EE-97D9-88FF90D1F5C8",
    "2024 SANS Written Boards Review Draft Course Agenda 01192024.pdf": "011B9B32-7F40-4424-9C6D-6C502A8D6FB2",
    "2024 SANS Written Boards Review Draft Course Agenda 12052023.pdf": "5CC069FB-529E-4D62-82B6-DD221FDC6FC0",
    "2024 SANS Written Boards Review Draft Course Agenda.pdf": "0A0F58E8-55E4-4FA7-915D-A6348CD3E5DA",
    "2024 Skull Base Course Agenda_5.17.pdf": "1349C899-7106-4D39-AD82-8BEEA8F4DBEB",
    "2024 Skull Base Course Agenda_5.9.pdf": "6FB94E1B-1E78-4C3A-AE7B-BED8585C43EE",
    "2024 Skull Base Course Agenda_Draft_2-1-24.pdf": "533C4D6B-37B9-4F88-890C-9751249A6DAA",
    "2024 SNS Annual Meeting - Program at a Glance 2.7.2024.pdf": "38BD44F6-4A7C-4535-A0F8-01B96F9F599D",
    "2024 SNS ANNUAL MEETING -DISCLOSURE TO THE LEARNERS-5-15-24.pdf": "FDE1D078-CF24-4668-A0F8-C12007F4B3C9",
    "2024 SNS Final Program.pdf": "FBBAB324-A4FE-4BF5-BE5B-CFE08F025C7F",
    "2024 SNS Medical Student Teaching Award CV Charles Prestigiacomo.pdf": "08686EE3-6817-4098-AAEC-C500B5E2930C",
    "2024 SNS Medical Student Teaching Award Nominee Charles Prestigiacomo.pdf": "23A71E52-E40A-4963-8BBD-0A75A9A30435",
    "2024 SNS Medical Student Teaching Award Nominee CV Fredric Meyer.docx.pdf": "DAFFC7B1-3809-4C41-BACA-E7C67B3EA7EF",
    "2024 SNS Medical Student Teaching Award Nominee Fredric Meyer .pdf": "B84CD78A-4A5D-4C5B-87F7-03CECB85FAD4",
    "2024 SNS Medical Student Teaching Award Nominee Fredric Meyer.pdf": "7C27E152-BD1D-4203-92F7-1FC5D8CCEE12",
    "2024 SNS Medical Student Teaching Award Support 1 Charles Prestigiacomo.pdf": "18D2CE12-3292-4DAB-B90D-441B1746E2CE",
    "2024 SNS Medical Student Teaching Award Support 2 Charles Prestigiacomo.pdf": "6290A46C-D0EF-434F-B1A6-67787AA85FA7",
    "2024 SNS Medical Student Teaching Award Support 3 Charles Prestigiacomo.pdf": "FC329F56-CEE8-4D9D-8683-0446584FA20E",
    "2024 SNS Medical Student Teaching Award Support 4 Charles Prestigiacomo.pdf": "1F794FC5-46D7-44A6-8FEA-BAAA6BBEA0E5",
    "2024 SNS Medical Student Teaching Award_CV_Alan Cohen.pdf": "56008591-A3D1-4660-A0A9-698F2D2D2569",
    "2024 SNS Medical Student Teaching Award_CV_Javad_HekmatPanah.pdf": "5856B19D-E194-4496-8A08-2D3357DDDF94",
    "2024 SNS Medical Student Teaching Award_CV_Stacey_Wolfe..pdf": "3D5E1EB5-1412-4774-BBB3-0A699B7B9BBC",
    "2024 SNS Medical Student Teaching Award_Javad_HekmatPanah.pdf": "0D01B2C1-A965-4D47-929D-0231EDE09D1C",
    "2024 SNS Medical Student Teaching Award_Stacey_Wolfe..pdf": "FAC3A885-DCD9-4C3B-99D5-37F8818239C7",
    "2024 SNS Medical Student Teaching Award_Support 1_Alan Cohen.pdf": "FAAC1FB3-1B5D-4B69-97C6-FFFA37253076",
    "2024 SNS Medical Student Teaching Award_Support 1_Javad_HekmatPanah.pdf": "89BD5B95-7D17-4358-9615-66BE12587BB4",
    "2024 SNS Medical Student Teaching Award_Support 1_Stacey_Wolfe..pdf": "071003E8-2C19-4879-9657-3FBD86C891C9",
    "2024 SNS Medical Student Teaching Award_Support 2_Alan Cohen.pdf": "C9F37462-715D-41B7-82B9-1AFE5977D4E2",
    "2024 SNS Medical Student Teaching Award_Support 2_Javad_HekmatPanah.pdf": "9BAA84FE-E1DB-42B5-90AF-EBB1A7A5481A",
    "2024 SNS Medical Student Teaching Award_Support 3_Alan Cohen.pdf": "99A0A6A1-741F-44AF-8E13-53832C52A52A",
    "2024 SNS Medical Student Teaching Award_Support 3_Javad_HekmatPanah.pdf": "A6FDD40F-EBE9-442F-AB64-55A331A52494",
    "2024 SNS Medical Student Teaching Award_Support 4_Alan Cohen.pdf": "77EBCD59-1E64-4102-A8C6-2D027F1FD033",
    "2024 SNS Preliminary Program_3.26.2024.pdf": "B19BD668-DA01-4E58-A4F7-34E43F5027F4",
    "2024 SNS Residency Administrator Award Nomination_Susie Hines.pdf": "D2016642-1E24-4749-B9D9-98F9929CB559",
    "2024 SNS residency administrator award_Christina_Dominique.pdf": "8C8FC412-81BB-4347-AE1B-0BF1C384C757",
    "2024 SNS Residency Administrator Award_Colleen_Bruton.pdf": "DEF265D8-CC13-48D1-80C1-2A4486673882",
    "2024 SNS Residency Administrator Award_Courtney_Gilligan.pdf": "106FB7B8-C4B2-4FDA-8BA6-76BC584C05C0",
    "2024 SNS Residency Administrator Award_Elizabeth_Battisti.pdf": "44BFC2AB-E56B-4C50-B6C0-7E14B173017A",
    "2024 SNS Residency Administrator Award_Mary_Gallagher.pdf": "26E1B5C7-4482-4D49-8599-E4DABF0A38FA",
    "2024 Staff Recognition - Formal Awards.pdf": "8D0F7EE4-F5DD-4C44-A29D-BCB096BB9D3D",
    "2024 Summer Hours Schedule.pdf": "BCA8CCD9-F29F-4109-ADC3-78C776CF230D",
    "2024 Translating Data Science to Neurosurgical Practice.pdf": "EE8A9E4A-CE23-4711-BDFA-5616152C91E4",
    "2024 WEC Agenda Book-Final.pdf": "4DC052CB-4980-42D3-9555-4C77F8583F69",
    "2024 Winn Prize CARTER CV BOB CARTER.pdf": "5774FC44-A211-4822-82A6-C863A99A7F50",
    "2024 Winn Prize Nomination Letter Linda Liau.pdf": "A4638395-DCFE-42EB-95F6-61FEC14C2F3A",
    "2024 Winn Prize Nominee CV Linda Liau.pdf": "C73C0652-90CC-4E06-862E-9AF3F8049FD7",
    "2024 Winn Prize Research Contributions BOB CARTER.pdf": "A18E59B8-4434-4A2F-A56B-EE8999F16C6A",
    "2024 Winn Prize Research Contributions Linda Liau.pdf": "AA80A13E-E32F-4A3C-99B6-185856024176",
    "2024 Winn Prize_CV_Starr.pdf": "9F85BAF7-EC52-4E2C-89F2-CAD57149FE10",
    "2024 Winn Prize_Hassan Kadri.pdf": "BD18AC9F-5DEC-4D05-A6DE-B3E10B806BBA",
    "2024 Winn Prize_Nomination Letter_Adam Mamelak.pdf": "4035634A-13F6-41D1-AC42-AA6145FCF30C",
    "2024 Winn Prize_Nomination Letter_BOB CARTER.pdf": "CFA4A1D6-DF5F-4278-80B2-A8FB35C45895",
    "2024 Winn Prize_Nomination Letter_Chetan Bettegowda.pdf": "14D0590D-4638-47FB-AF4D-9BAEDF8D34CD",
    "2024 Winn Prize_Nomination Letter_Christopher Shaffrey.pdf": "38E0EA51-0590-42F0-B80E-E752118BD0CD",
    "2024 Winn Prize_Nomination Letter_JafriMalinAbdullah.pdf": "0C501C9F-FE4D-4F83-90EF-EE7955FF6426",
    "2024 Winn Prize_Nomination Letter_James Markert M.D..pdf": "A577AFF1-1F11-4833-B7A3-0119592D88D8",
    "2024 Winn Prize_Nomination Letter_Linda M. Liau MD PhD MBA.pdf": "D6DCA741-042B-4206-8CF8-8EA439ECFFBF",
    "2024 Winn Prize_Nomination Letter_Michael Alexander.pdf": "AADC1BB9-7340-4FA2-9734-20AE086A2E59",
    "2024 Winn Prize_Nomination Letter_Starr.pdf": "7D9A419E-1661-49C3-9B6C-A5B5EB831124",
    "2024 Winn Prize_Nominee CV_Adam Mamelak.pdf": "C1D6BFC2-4685-4953-9CEE-A54976C30DEF",
    "2024 Winn Prize_Nominee CV_Christopher Shaffrey.pdf": "4455D524-D1ED-45FE-B19B-E5A48F98E53F",
    "2024 Winn Prize_Nominee CV_JafriMalinAbdullah.pdf": "164411E8-09E3-412A-8161-1CC1D86D0A05",
    "2024 Winn Prize_Nominee CV_James Markert M.D..pdf": "C8650B91-A901-4A99-BFB6-3ACF3D4F776C",
    "2024 Winn Prize_Nominee CV_Michael Alexander.pdf": "C741B63D-E382-4EA3-850C-090EC374EB63",
    "2024 Winn Prize_Research Contributions_Adam Mamelak.pdf": "08EC73DF-71B4-4E64-98D5-D18E782F1A13",
    "2024 Winn Prize_Research Contributions_Bob Carter.pdf": "764F2544-1E83-425C-A0D9-59CD107378C1",
    "2024 Winn Prize_Research Contributions_Christopher Shaffrey.pdf": "DE3F3C59-38CD-447B-BBC9-16A4A1309017",
    "2024 Winn Prize_Research Contributions_JafriMalinAbdullah.pdf": "79393B6A-AFEA-4E73-9439-D948C7166223",
    "2024 Winn Prize_Research Contributions_James Markert M.D..pdf": "96FDBD0F-C3F3-4B77-940F-8099F86A0FE3",
    "2024 Winn Prize_Research Contributions_Linda Liau.pdf": "6DE0E37B-B435-42E2-8A8E-814DB730973B",
    "2024 Winn Prize_Research Contributions_Michael Alexander..pdf": "49E935F9-4911-4953-A3E7-035BC8932097",
    "2024 Winn Prize_Research Contributions_Russell Lonser.pdf": "57814B11-AA2F-44E7-95ED-FA22E90674E4",
    "2024 Winn Prize_Research Contributions_Starr.pdf": "C2ACF306-B55C-4C3A-A73C-EB2B73307A78",
    "2024 Winn Prize_ResearchContributions_Chetan Bettegowda.pdf": "F8900D0C-A7D5-4EFF-9704-5280EAEA6447",
    "2024-4-10 AMA Sign on Letter_PI and CEHRT Changes for APMs FINAL.pdf": "EA630601-7C42-4027-81A4-C058050F7114",
    "2024-APPT LTR-RST-NSI WipplingerC.pdf": "40126AAC-9DBE-4F8D-B699-99459E2B2748",
    "2024-ASSFN_PosterTemplate_DanstromFINAL.pdf": "5B3A21EB-474A-4F79-B4C5-913166136302",
    "2024-Masum-Rahman-Good-Standing.pdf": "6A1ACE15-3452-4853-A333-E4F621F2AD28",
    "2024_0301_CV.pdf": "788C9F67-9FB5-4D73-A341-9522E6CF68D0",
    "2024_ASSFN_Amyg_Delayed.pdf": "F9D352FB-6A15-4635-9088-778D136D6748",
    "2024_ASSFN_WG1.pdf": "E2A7B243-FA8C-4F9A-B62A-B0928AFB9A47",
    "2024_NSTP_Year 2 Application_Huang_Yuhao.pdf": "DE96489D-6D18-4D4A-99BB-40DD2961AFF5",
    "2024_Verification Letter.pdf": "58852580-4ED2-44F7-AE11-8369BD29EE4F",
    "20240.pdf": "CD34B434-FB44-4DD6-922E-24DFED0D1532",
    "20240205_101947.pdf": "D00F505E-F884-4439-ABA2-297F33773646",
    "20240208142850627.pdf": "39FB74DA-BE6B-4367-B530-E5692F60B79F",
    "20240228 Nduom_Edjah_Neurosurgery_CV.pdf": "FAA74223-576A-444C-982F-4B50E8557F88",
    "2024052277000014.pdf": "3BC3983E-7F3B-4E3F-B847-B298AF8FB524",
    "20242.pdf": "130285B7-7467-4665-9E45-3DCE2563F066",
    "20247.pdf": "E7D8CC50-7B6B-456A-9DCB-D01B1470B632",
    "2024NSTP_Year2Application_McGinnis_JP.pdf": "190A8BBF-FD67-449C-86C7-2FD5ECF6E5ED",
    "2024SNSResidencyAdministrator Award_Melissa_Lukehart.pdf": "9E8FC79C-3214-486B-ACA0-5ACE0359D9EE",
    "2024WinnPrize_NominationLetter_LindaLiau.pdf": "921B03F3-8180-4F37-9BBA-A33CE9DBB1C2",
    "2024WinnPrize_NomineeCV_LindaLiau.pdf": "8F5C551D-3AAB-4B28-AF42-905D69E4CAE8",
    "2024WinnPrize_ResearchContributions_LindaLiau.pdf": "B610664B-76EC-4D37-B8DD-744002648A7C",
    "2025_LGS_June_2023_Jain_Jyotirmaya.pdf": "E170A06C-63C5-4B9B-955B-091CC2724134",
    "20251.pdf": "CC9A56BD-51E4-4A37-B2B0-C2269050388A",
    "20253.pdf": "EE2D05A8-C2E1-4CAD-9B88-3293D108DFAC",
    "20254.pdf": "2B50B18E-E5AA-409C-8D56-3FC33D7B0AA7",
    "20255.pdf": "2615B6AE-8741-4C42-A045-DB4027F3CF79",
    "20256.pdf": "77BFB130-7DE4-42E3-AB5C-0593B8FD8CE0",
    "20257.pdf": "93133F27-DBEE-41DC-8B4E-CCEDBEB850E6",
    "20258.pdf": "1D80423D-DC8C-48E5-9ADC-81F7A7D3DFAB",
    "20259.pdf": "97F4D3CE-4278-49D4-AC6A-D0B8DAA4F400",
    "2026 SNS Proposal_Barrow Neurological Institute.pdf": "41ADF82D-0EBF-447F-B172-7FAC82921ECC",
    "20260.pdf": "C73CB3E7-05D4-4E6B-89DE-BDCFBBE04274",
    "20262.pdf": "8AC4FF12-726C-4DEA-8520-22EF0E7DD801",
    "20263.pdf": "E97B6CD0-AECA-4DB9-8FAC-3BD2FCE1904B",
    "20266.pdf": "8E9AAB8F-DF4A-41AA-B4C3-26A8BE9735DC",
    "2027_MEMO.PDF": "E4C1A109-45B4-41CC-811E-5E81020C3632",
    "20273.pdf": "27EC4208-8405-48A8-AFFA-16B217122E31",
    "20274.pdf": "456045CD-48E4-42CC-BE3D-DD0DB2491054",
    "20275.pdf": "30576E24-4A35-48BD-AB82-2685D3CC9CBA",
    "20276.pdf": "AE6F18F6-B55B-4014-B626-234AD194F3A9",
    "20279.pdf": "4A51B8E1-0C61-4FC8-8A61-964359612668",
    "2028 SNS Proposal.pdf": "00A31159-0F92-4EF8-88D0-52A24B9CA183",
    "20281.pdf": "3A3BF58E-9D83-497A-9519-C325819E270D",
    "20282.pdf": "F4E4022D-603E-47E3-95A4-316D26454A50",
    "20283.pdf": "770C9605-3474-4A09-8D85-4971269E9831",
    "20284.pdf": "324271A9-2B5C-4DDF-B275-193C47374153",
    "20285.pdf": "38A503D9-D7E3-46E7-BAE2-4F5B657EDE5A",
    "20286.pdf": "77925FCF-AE54-4EEC-B126-3F05115B30AD",
    "20288.pdf": "727D8D42-9175-4BEA-8639-016F8D84289A",
    "20290.pdf": "46D1318E-BAF4-4253-873E-40E593DFC615",
    "20292.pdf": "72289DAD-C717-41B8-A03F-478C67E1726E",
    "20293.pdf": "0C1109E3-41FE-4AF1-86D6-E460B7721381",
    "20295.pdf": "FE97416E-791E-4F32-9283-9274C2A56A1E",
    "20296.pdf": "C882B370-25E6-4F29-9A8E-F94576748E83",
    "20298.pdf": "166390DC-B89D-48F5-8207-75E86A2482EC",
    "20299.pdf": "7F911E76-C91F-4F15-8BB7-471BC68798E3",
    "2030_MEMO.PDF": "55C83B60-14A6-460C-9896-4A449628DEA1",
    "20300.pdf": "3E521405-06D8-4553-924C-3C3FADDE30CF",
    "20301.pdf": "3500FCBF-0213-48D7-BFE9-627294E5C303",
    "20302.pdf": "D71E552B-E4BC-48B3-A543-F33DE9481ED1",
    "20303.pdf": "3EB4DD70-CC66-4F20-81CC-984A5BA9547D",
    "20305.pdf": "24B7533E-AB36-4166-9B61-5A6B418D63E0",
    "20306.pdf": "B319D37D-8FCF-4CAA-9A3C-8E551FFC683C",
    "20308.pdf": "19362628-1F81-402D-AF7B-F2BB09050F59",
    "20311.pdf": "99E2543A-2D5F-4449-BB53-047E54524AB1",
    "20313.pdf": "CC13CE32-3C1F-4837-A3D0-2D2119CCBC2C",
    "20316.pdf": "03780B58-879B-4216-BEF8-A24F949DB4E0",
    "20317.pdf": "CBAD1E3F-2405-46DD-88EC-5D39F5DD4EC6",
    "20318.pdf": "632C8F35-CB15-46F1-8ABC-5A909982E51C",
    "20320.pdf": "D0BF0E6B-1C7E-47DF-80E2-52A99FC566E0",
    "20321.pdf": "28FDA684-E99A-4037-94AC-BA0F3EC3F22E",
    "20322.pdf": "CE5491E6-AEF7-4A38-BCF9-8563227DD3C4",
    "20323.pdf": "1DCA0ADF-F7F3-4F1D-A858-AEEF4724629E",
    "20324.pdf": "FC44E7FD-19A3-4D74-AAD0-89E613B17BA2",
    "20326.pdf": "B4F67766-81A2-47D9-A2B0-17C82B5F36E3",
    "20327.pdf": "21D2485B-6550-4EB4-99CF-360FC18E0077",
    "20328.pdf": "DE553CB3-9BB2-447E-A032-959511B31E11",
    "20329.pdf": "A1318414-B0F4-4E04-9178-1F480F4DD910",
    "2033_MEMO.PDF": "A1CE7080-D473-4270-A4C7-5391367BCBD7",
    "20331.pdf": "4F0B784E-1413-42D5-8A8C-6B04C044271E",
    "20332.pdf": "C54A52A1-2DC8-469A-8558-A6A66A224EA9",
    "20333.pdf": "B3C8C3BF-8555-4E8E-B8DB-09A05F394C33",
    "20334.pdf": "D56624E8-4504-46EA-9118-04A4E9746E00",
    "20337.pdf": "3F735A34-59BD-4F2F-9016-0EDC68F55A6E",
    "20338.pdf": "9B11B71F-437C-4C9C-815A-E8A05DCCCD1A",
    "2034 - Pennington et al.pdf": "2B00D6E9-7B3B-4EB4-8B75-B138D0875F3F",
    "20344.pdf": "67FC6709-2CF1-4427-97C0-77A19DE7DD56",
    "20345.pdf": "610B8C47-9604-4218-8EE6-05A503FFDCF1",
    "20346.pdf": "1FB2558B-B933-4384-89E2-798458D08822",
    "2035 - Pennington et al.pdf": "B962946A-A392-4DC4-9FB0-F569E6D74743",
    "20350.pdf": "3C245089-884F-416C-8EE5-281BD219BDCF",
    "20351.pdf": "7005A41C-09E0-41C8-99B1-113292685A5D",
    "20352.pdf": "6505B33B-1A07-4C88-9643-C156B09C28FB",
    "20354.pdf": "E978D249-16F6-44CF-8613-3CD0F59C6045",
    "20356.pdf": "57D9395B-7FA1-4F6C-9D9A-DAE9076D1CE8",
    "20357.pdf": "D221DE6D-855B-44BB-89E3-B80AD9C01798",
    "20358.pdf": "604ADA80-B287-49EB-9DEC-34078FB453F5",
    "20359.pdf": "0E48FDC6-1A21-495A-9B70-FED60957ED27",
    "20360.pdf": "AFDBACDE-E080-4734-B47D-29A577FCECAF",
    "20361.pdf": "112E9BD6-D8E6-4C5D-B86E-28D17160E2BC",
    "20362.pdf": "CD49160B-B7D5-4EBA-B00F-7FF5739198BB",
    "20363.pdf": "2EB2A11E-F57E-41DE-B782-135F6986445F",
    "20364.pdf": "657C5445-1A9C-4625-B657-4583BF3C7620",
    "20365.pdf": "34290156-47CD-4893-B72A-6FD46DC49935",
    "20366.pdf": "254E5736-47F5-483E-8034-983F56C83572",
    "20367.pdf": "74B2F864-ADBB-428C-BA89-2992996653BB",
    "20368.pdf": "122D4789-F50C-4723-BD11-473659000D1E",
    "20369.pdf": "131500B7-9AA9-4A7C-89A8-82BDF4963345",
    "20370.pdf": "DA609B61-9042-458A-914B-5876B6690BBF",
    "20372.pdf": "08506E4F-D07C-4037-96FB-106808C57B09",
    "20374.pdf": "469A708F-AC40-4F38-A57E-ACEC0621B167",
    "20375.pdf": "9F9ACD93-7EA9-45C5-8409-F5DDA59E48EA",
    "20376.pdf": "74401D4F-AB5F-435E-8A14-375B9E72E890",
    "20377.pdf": "9F503688-D13E-44F2-AB9E-AD7FC591ED15",
    "20378.pdf": "34655921-1D7A-482B-8AE3-59FE3686667D",
    "20379.pdf": "B1DCBFB1-0EF3-41C2-91D2-50317A2D9DA4",
    "20382.pdf": "9718DD35-A9CD-46F0-AE00-3F11DC85BDD8",
    "20383.pdf": "80EFD916-56A7-4AEF-BD70-3E8170094856",
    "20385.pdf": "5F7DD967-E7EB-46B7-B4C2-9533C275B9F4",
    "20387.pdf": "7CC436BB-46EC-4B1F-A0B4-7AC70CBF9F9D",
    "20388.pdf": "95D9E0BE-95F9-4F60-AB17-D912073C56B9",
    "20389.pdf": "862669FE-BEAA-4659-9F38-C40D5B8AFE73",
    "20390.pdf": "3CDE1C73-61EA-4CFA-9624-2353953761F8",
    "20392.pdf": "38BE3F22-B46C-4735-9CE2-43CE37B16B31",
    "20393.pdf": "DDC3DED9-03CC-476C-9D6B-8357C64320F4",
    "20394.pdf": "04CD50A9-2C6D-4261-B854-D876A265EDF0",
    "20395.pdf": "7B6CE527-F68D-443F-95BB-CD3170B05574",
    "20396.pdf": "C05DDD8B-9C9D-4A03-ADF1-D1C6D80F2384",
    "20397.pdf": "34DBA74F-F742-4353-9C47-30A1AA699184",
    "2040_MEMO.PDF": "0D9085F9-441F-49C8-99E3-CD0CA297244C",
    "20400.pdf": "FCB4EDB0-B25C-4BF1-8675-1B979BC49107",
    "20401.pdf": "6A1CB75D-6628-4BB7-9613-C9F6D5B4A285",
    "20402.pdf": "7CEF32EA-2885-4E09-8BE0-48848C5C2583",
    "20403.pdf": "75ED4B94-D0FB-4FF7-A122-4363055DF425",
    "20407.pdf": "0571B522-5029-4A5C-B982-A180C1ED5586",
    "20408.pdf": "ECDCEF92-126D-4805-916D-955A6EB98A56",
    "20409.pdf": "D30EDD40-F6B9-4D35-AC76-DEF30F77B753",
    "20410.pdf": "1DF6514E-2204-4E9F-BE15-F26D7AF5E3A1",
    "20412.pdf": "0C3A3031-B951-4E58-8711-5F901690FBAF",
    "20413.pdf": "7AABC0D1-C943-46B0-9123-D62BEC9B7621",
    "20414.pdf": "3A48FBF6-FF0E-4E51-A02B-B2ADE0A927CD",
    "20415.pdf": "E3191083-3420-4FD0-860A-AF9D918B4D0E",
    "20417.pdf": "008F3033-CC7D-46CC-9412-242D838AB22F",
    "20420.pdf": "3EB72ED4-D484-47CB-8D5A-6B418C690251",
    "20421.pdf": "4EDB64BD-0A5B-42C6-8C04-F535707713CD",
    "20426.pdf": "8F2415D4-0B16-4DA5-88A0-0F428878ACEB",
    "20427.pdf": "3F7B41F7-325C-4BBA-AAA7-80086D795F69",
    "20429.pdf": "2FE847DA-55FA-4856-9954-0980A43437C2",
    "2043_PROGRAM.PDF": "C7554B23-2949-4A52-BE1B-78C7213B7B63",
    "20431.pdf": "B0EC46E9-8500-4CC3-A8B4-DC6EC0CCC60C",
    "20432.pdf": "C59A27E1-3D18-47E2-B21E-AAA916C759FA",
    "20433.pdf": "F0B1B3B0-864C-4CC3-97F0-8E3A26078402",
    "20434.pdf": "956FAC10-1DFA-4CB3-BBC1-432C376B544C",
    "20437.pdf": "59ED7DDF-3E04-46E3-990A-EAA6BD219948",
    "20442.pdf": "95400A63-73D7-4C6A-A1E0-CF1382FED9FC",
    "20443.pdf": "214F899D-C8BC-4660-BBDB-C6F2EBF2129E",
    "20444.pdf": "0AECFCA1-3932-4DCD-97B2-00B42FE79CD6",
    "20446.pdf": "46C7F4B1-8B60-48AE-9C60-9F992B5FBDF0",
    "20447.pdf": "B7E5A880-18F8-4F74-A335-15DEE0ADDA88",
    "20456.pdf": "6505ED74-5953-494A-BCC3-9D1E3F17DB41",
    "20474.pdf": "4414E9DC-4E5E-4D60-B210-6963E6B60C19",
    "204751840_ALDERETE.JACOB_M1_20220712081145.pdf": "D4EFC347-AF9A-4B4B-9A3F-45228B6F679C",
    "20480.pdf": "33C430E3-BDD7-40ED-ADE0-E62554A7132A",
    "20483.pdf": "28484703-D8BF-4389-A062-7D595C44CF2F",
    "20489.pdf": "ADA061B9-4A7D-4559-BFE9-0F0DA8C33AD2",
    "2049 - Pennington et al.pdf": "B308374E-ED66-480F-801D-01002B6312AF",
    "20492.pdf": "5DAB9FE6-D4E8-4151-94D6-2E5A2C2947E7",
    "20501.pdf": "B9730A01-256E-4928-A639-8E113B7FEF10",
    "20505.pdf": "74651B58-2CB3-4004-BA07-D877A04E52E6",
    "20509.pdf": "C4B05427-7780-49AF-A195-26DF5773CD9D",
    "20515.pdf": "4E1B828E-74ED-4FE6-8476-48C6DFEA546F",
    "20537.pdf": "EA0B8735-44F4-415B-B7C0-54F1D07B5F43",
    "20539.pdf": "71FD46F1-30E4-4545-9724-25F0FD5A02C2",
    "20540.pdf": "BBE99665-6BDB-4D91-B966-3B2EEAD90175",
    "20541.pdf": "61BDB482-F43B-48DB-B795-FB628EAA6EEE",
    "20543.pdf": "54B9BB4D-4452-40B2-B473-A15C04B9269E",
    "20544.pdf": "75FB4DDA-8158-481C-BE06-77120FFA98FE",
    "20546.pdf": "E8AC4590-D05D-462A-AE74-00D9F21C5771",
    "20547.pdf": "71E6F78B-E228-40BA-AFE0-54C40AC22478",
    "2055_PROGRAM.PDF": "81BB48A4-A1DC-42DB-8AB8-981BDC4CC366",
    "20550.pdf": "D884ED11-D8E3-4292-A056-68962587A36B",
    "20551.pdf": "83C0D4A9-854B-4A52-93A8-FC2104E45BBD",
    "20552.pdf": "BE2E50F6-4442-4B0B-B60E-D9B58E809559",
    "20554.pdf": "9030B218-704D-4EF3-94F0-061FA63C7EC1",
    "20555.pdf": "87A33446-ECD2-40D7-AED7-7001820AC3E7",
    "20558.pdf": "8ECBAE11-36CC-438B-8579-2BA95B04EC9C",
    "20559.pdf": "943F5303-5140-4061-A08B-E32FD204ACC8",
    "20561.pdf": "24824648-57AF-4A11-8CC7-BAD329C16C98",
    "20563.pdf": "A84527BA-C436-4368-8BEF-1FEFBBAC4731",
    "20565.pdf": "9352F339-FBC7-4EFE-BE1C-D3C84D287FC4",
    "20567.pdf": "0377AE13-BDA0-49F8-B229-512F94F6C569",
    "20569.pdf": "24D62E94-9C0D-4CC8-BB37-2B223AE53A19",
    "20572.pdf": "2D68582E-B0E4-4ECB-AAFE-925DC435D9D8",
    "20574.pdf": "32BF314F-1690-4202-9E18-824854F3C898",
    "20578.pdf": "F0782020-F1A6-4225-93B2-673F364DE4CD",
    "20586.pdf": "E048F8BD-1331-4707-A0FD-3C4F7F824709",
    "20588.pdf": "7F7531EB-E31D-4921-BD45-7A7EF5585CEE",
    "20589.pdf": "FF97F41B-EFAE-440A-A5E2-46C3FC1FE556",
    "20591.pdf": "293FB690-B8CE-4E55-8477-57C5CA511693",
    "20593.pdf": "33CB3824-4606-4831-933E-A44B10FACE99",
    "20594.pdf": "68798C87-C83C-4F64-A08C-D66EE1A8724E",
    "20596.pdf": "ED69FCB6-35F3-44C2-8255-21436B362779",
    "20597.pdf": "9EA2642F-B3E4-434C-9F5D-EDF03664E03C",
    "20598.pdf": "A6CA0F06-74DD-4724-9D41-F04F0C95B2B6",
    "20599.pdf": "7238160B-748E-4C76-A879-F863F4920BAA",
    "20600.pdf": "A70426F7-BB5D-4C1A-8769-F4A3473EBB59",
    "20601.pdf": "9ED21297-E27D-4AFF-A058-6344BCCF54A3",
    "20603.pdf": "80CAC673-D463-4D80-BFA3-BC5748DC61A0",
    "20604.pdf": "5EE2C329-A8F9-4BEC-8593-398839E407A2",
    "20605.pdf": "3D69421F-8B9A-43D7-A817-7486E3687015",
    "20606.pdf": "8ACD8DEE-F9EE-44F3-A5CB-747C93FB837A",
    "20608.pdf": "718A8219-B2C2-4A3A-8C66-A2EB7AFBC34C",
    "20610.pdf": "7459C1B4-C14D-4120-8993-E5BBDCFD7A69",
    "20611.pdf": "DDF4FFC0-5705-4CC4-A6AC-765052EB2FCD",
    "20614.pdf": "51DB3BBE-5D81-4200-A746-DDBA3F45D67F",
    "20618.pdf": "FCF1D593-6D8C-46B6-AEA0-A823BAB5906C",
    "20620.pdf": "B5F9C7FB-D831-461B-A7E6-71D3E4657895",
    "20622.pdf": "816A381A-FEF0-46C9-BCC1-4234ECBA6899",
    "20626.pdf": "CA21EE50-16F4-43D8-8DC9-D7D8E5AC268E",
    "20627.pdf": "C63650B3-AF46-4BB1-8DE9-7B4E4B63194B",
    "20628.pdf": "9731CD6D-C208-4CCD-9C4F-AAF88DC84F01",
    "20630.pdf": "5F7B3B13-385F-47E0-9678-69B6FE684365",
    "20634.pdf": "AF6847B6-3B61-4CE1-911D-F8D36EDCF7AE",
    "20637.pdf": "2D0C15C4-C6BC-4C5C-AAB0-367FBB234167",
    "20638.pdf": "DED50875-9D8E-4910-925E-4FC2E404B878",
    "20643.pdf": "514032A8-73E1-4AE5-9E08-EBBE057DFB66",
    "20645.pdf": "26932BBD-2BE7-4C7F-9C53-36A09ED85A82",
    "20646.pdf": "CEAEB16C-511F-4020-94A2-D2A37F5FB0A4",
    "20648.pdf": "5FA431D1-2916-45DE-B919-EAD880474E9E",
    "20649.pdf": "A3158E0F-A2EB-4691-B155-25922B51D371",
    "20650.pdf": "13A616B1-1288-4BE2-ABB0-2E55A798FC90",
    "20651.pdf": "B3D8A9E2-2CA9-4FC6-BC7C-71DB71C1C68C",
    "20654.pdf": "1C0086F8-40EE-46BE-8DDC-C13DFF1D9FC8",
    "20655.pdf": "515CF27C-C243-4209-8588-76B58498F860",
    "20656.pdf": "60C05076-A882-41E4-B69F-7EA471439F2F",
    "20657.pdf": "144A5B1B-D3D9-49BE-8438-4173F794086F",
    "20660.pdf": "03865B6F-FCC0-4A57-8F42-5CAC4FD98C57",
    "20662.pdf": "B6CD7A9E-EA7E-4F6B-AB71-BC1C7ED2F60C",
    "20663.pdf": "3B49E006-FF0C-40C7-8648-5A2ABC6F2760",
    "20665.pdf": "791CC838-715F-45E8-BD2A-90B8F5FDF3FA",
    "20666.pdf": "C8375233-5AC8-4209-9E10-D48CFBE79B11",
    "20667.pdf": "A542D792-39D9-4918-93E0-F9342D3B23A0",
    "2067_MEMO.PDF": "DC44DBA1-798A-47FE-A90A-0E86351A8522",
    "20671.pdf": "76D7EB43-601D-466E-ABA1-433C5A05FC74",
    "20675.pdf": "1BE1B1E7-19E0-42C0-9CC8-6BA39762A410",
    "20681.pdf": "1792A0CC-DB50-4993-BE14-D2DC4BA72403",
    "20684.pdf": "73E19DBF-BCB1-4982-9169-2F75DA70152F",
    "20685.pdf": "0F8FFD30-2887-4AA1-875C-97FF6F1969DE",
    "20686.pdf": "EEE09472-1177-459F-9AC7-18110DAABD2B",
    "20690.pdf": "9B6FEB21-3F06-4927-867D-B6CC8292400F",
    "20692.pdf": "D85E4307-E8DA-4308-88AA-33CFDFCFF977",
    "20693.pdf": "6AB4D548-965F-4785-8554-71F347880B9F",
    "20696.pdf": "15C0C92A-C96D-46DF-BA2D-50EAC3364D17",
    "20698.pdf": "3D5A4617-639F-4475-9EB1-DFC069601B2D",
    "20700.pdf": "F07533E7-7192-4194-90AC-18C09BB95592",
    "20701.pdf": "BC59DD00-87D4-4802-A3E9-6F148B4EC83D",
    "20706.pdf": "4EAA44BA-CC44-4363-BA7A-4212AB8B7C99",
    "20707.pdf": "7B4BDD88-6AAB-47C7-9C26-5C3834C6DA77",
    "20709.pdf": "2FA069AD-D265-4119-B2A1-FB96D6C4614B",
    "2072 Rose Du poster.pdf": "1B5A7627-0DBC-4A1E-8F9B-EF53404FA507",
    "20727.pdf": "71F54B3A-BDCC-49CC-8C94-B6180508C244",
    "20730.pdf": "9C3442B9-D491-41B9-BA67-480760336CE4",
    "20738.pdf": "69875879-F081-4A51-9E18-DF2AA1383337",
    "20742.pdf": "360D3788-DC21-4CA3-BBCD-B482A2F12B21",
    "20743.pdf": "CECEFD19-5F5B-4836-96A5-839DE38AE84C",
    "20751.pdf": "7D816527-17B1-49F2-B310-8200084E6EE2",
    "20757.pdf": "92AC5021-76C2-4A4E-9A03-E55CDB4DBEBA",
    "20773.pdf": "5EE3DE7E-C488-4D72-9975-223ED6DD5B21",
    "20776.pdf": "BC1A7D53-748F-4B08-A000-3A4B438DB633",
    "2078_MINUTES.PDF": "A12F016E-C7B1-4243-B8D3-0CDEEB5D60B9",
    "20786.pdf": "FB51B32C-97C5-45A1-9D9D-51B1850A2BD1",
    "20791.pdf": "E5AD2C84-5906-4509-9C5A-F2613FF7F402",
    "20792.pdf": "24B6A4B4-757F-456E-B573-2A39B985B17C",
    "20798.pdf": "895213B1-DFA3-4803-842E-ACF79F71AC07",
    "20799.pdf": "6291BEF2-06B5-48F2-BB4F-B9A2B4A425E6",
    "208 - Baker.pdf": "19A8F1C8-1447-4E6B-B4A7-113941FBBFEA",
    "208-Declaracao_Dr Danilo_Otavio_Silva.pdf": "D18ACA26-7B7D-41DB-B3DE-1A2D6ED88330",
    "2080_PROGRAM.PDF": "46A76715-9B88-471B-98B3-8D34B2E4E7FD",
    "20800.pdf": "FF7D8B10-146B-4AB2-935F-51EFCF64FD25",
    "20801.pdf": "69AA52B1-6913-4873-B9FE-B0138D3E7CE8",
    "20802.pdf": "A9221134-59BE-4C83-886E-82FEE45BA7C7",
    "20805.pdf": "D3B30C75-CB64-4B71-82FF-7BB91E2BFCEF",
    "20806.pdf": "2C386F73-6AE0-47D6-A09F-F376F294C11B",
    "20809.pdf": "1B1EE85E-FCBA-4E9E-A0EF-FE42B7A5682D",
    "20813.pdf": "535C3ED3-9691-45DC-8E37-B3232B444C44",
    "20814.pdf": "7F835660-BA3B-4354-8CF2-74EA68EAC42C",
    "20838.pdf": "085DBF38-F0D0-4928-91FB-B434E9B9FC6B",
    "2084_MEMO.PDF": "EF73493B-9332-42E0-B005-ABF607439FD6",
    "20844.pdf": "12C1712A-BCEF-4974-9A7C-D57C6AA4D245",
    "20847.pdf": "7BF3DF45-1835-4B8A-89F7-C6538F0FB1D3",
    "20848.pdf": "EE03135E-4192-4A38-937D-60A056955EC3",
    "2085_MEMO.PDF": "8F3DC86C-71E5-48FE-BE0C-2A6CE3EE3932",
    "20854.pdf": "ECE50AF5-1B24-4454-BF0F-572414319958",
    "20858.pdf": "ACA20930-950B-438A-B5AF-87B23980AAB0",
    "2086_MEMO.PDF": "21DEEB4E-07CE-454C-AED0-ADFCBEC50DD4",
    "2087_MEMO.PDF": "10AC604B-B3F8-4B42-9862-72DC3E957AFC",
    "20875.pdf": "05C48C75-7491-4CA2-91F6-6FE67BA67B95",
    "20880.pdf": "85F03677-A92D-404F-8BD9-118E84003DEF",
    "20882.pdf": "BA3CFC25-BC3D-4C20-B8F4-EA3D1CB3741E",
    "20889.pdf": "003B661A-D738-4FC8-9A1E-D48010AC18C0",
    "2089_PROGRAM.PDF": "76474A6D-E9AE-4F72-A81D-2C5D22760F80",
    "20892.pdf": "DB151753-C82F-4027-A621-827E1433F34A",
    "20908.pdf": "F74C07BD-1B48-4232-BD33-E778F18AF24C",
    "20916.pdf": "6F896A1F-EDDA-49D1-99E0-02C11CD966EF",
    "20922.pdf": "66546AF4-3A00-48D3-8B66-3B240F78C601",
    "20924.pdf": "B27B318E-80D6-4565-A29C-D7A7331DB451",
    "20925.pdf": "CA41A810-9F9A-43A0-BC26-283FBEE64873",
    "20940.pdf": "B79B38BB-72FE-4CEB-AAC7-5881DEBCED9C",
    "20942.pdf": "29668F20-4E85-4C17-B210-907ECBACBB2E",
    "20945.pdf": "88AA69BB-8849-4F5A-926D-3EFC8CE0BA9D",
    "20960.pdf": "8BBA2EA4-DCB3-4DB1-9072-4BBE64C68D31",
    "20961.pdf": "530ECA44-CCFB-4875-A2E0-3AC4F0B415AA",
    "20971.pdf": "7549796B-2707-4933-9940-511924B31B4C",
    "20976.pdf": "7CA46D72-D3AD-4BC6-BB1A-BC7BE32C1670",
    "20980.pdf": "840A4932-029C-4735-AA78-46CFEB167E8F",
    "20981.pdf": "06168F7B-B676-4D78-B2E3-0AC03A1901CA",
    "20987.pdf": "2E486887-AAB6-4E30-8DDA-F5B0391ADA46",
    "20997.pdf": "C2211DB8-966A-4E03-99DF-939968292143",
    "21 CV.pdf": "F2E08B5C-6C1A-46DA-BDC5-097ECFC85FAB",
    "21-08-29 CNS abstract poster.pdf": "018E21BA-BEFB-43A5-81D0-BE10B50FFE34",
    "21-54_Neuroplastic_Save-the-Date_2020_Prf-3.pdf": "46C5F96E-093B-415C-B512-504B2C53C9D9",
    "21-NEURO News_Fall_2.pdf": "CED250AB-09E9-4686-897A-E6CFA9E47B76",
    "21.pdf": "4588027A-67DE-4277-9C02-1882AF4E363E",
    "2100_PROGRAM.PDF": "EAA382E1-5D15-42B9-AF9F-6725738020CC",
    "21000.pdf": "46A8DC79-DDB6-4B79-B78B-9F65908FDF16",
    "21001.pdf": "7F43AEDD-2356-469C-BB89-4D209C1AE682",
    "21002.pdf": "228D37C0-4AC6-4504-9A96-D8C26ECB4548",
    "21003.pdf": "685AE29A-17CC-407C-B60E-4AD18856087E",
    "2101_PROGRAM.PDF": "C7F75152-D63C-4117-9428-16942A2F448D",
    "21019.pdf": "AB4522A9-60C2-4B3C-8D55-DCBF8D1A73CD",
    "2102_PROGRAM.PDF": "80DAED92-2BDA-44BD-9A69-46B053A653F0",
    "21021.pdf": "50478AE9-9C6B-491E-BD39-C1AB7BA34423",
    "21039.pdf": "7F00F2D5-5960-4816-83FA-2E4FB52677CB",
    "2105_PROGRAM.pdf": "F5C8851F-C9EF-4604-9E98-35E4D2F317BD",
    "21050.pdf": "E68F4B34-E89D-4F4B-B345-F5FB1CF678EF",
    "21083.pdf": "533B8DC4-E70A-4B76-B40D-68238EC3B58F",
    "21095.pdf": "79BFB34D-55CA-4A45-8273-DBE647D0B509",
    "21096.pdf": "284C033D-EAB8-4259-BB3E-3E92FB07BC0F",
    "21100.pdf": "7FF62102-B1F7-4EAE-95BF-A330A255D8E7",
    "2111_NEWSLETTER.pdf": "32E22385-1DA5-42EE-9012-84D0DA6CF3ED",
    "211130_FY2022_CDCCoaltion_conference.pdf": "088EB60C-F444-4BD3-BB44-890A7F503B7C",
    "21116.pdf": "760DA1A8-FF21-494E-8314-4CA0F38E1CAD",
    "2113_MEMO.PDF": "3E858527-62EE-49FB-AFB8-144DB4A9AE9F",
    "21130.pdf": "C11E8F67-2E52-44C7-86CF-BC3ACB9FB387",
    "21134.pdf": "F6A5019E-E44E-45C0-A91C-4815150C6CA7",
    "21135.pdf": "009E9009-92C9-422F-96FE-E46FD7FB03AA",
    "2114_MEMO.PDF": "55A562BA-AC54-4410-866A-235D75BCAA9C",
    "21140.pdf": "E44755CE-22E6-4A2C-BF7C-AF439916FBF0",
    "21141.pdf": "CD9A10AA-B9A7-46BD-8BB9-D02D0DA4C740",
    "2115_MEMO.PDF": "5BC3AC94-F9DE-4A39-A6FD-42490010B56D",
    "21151.pdf": "ED11615E-A58E-4F19-907F-6DA55AA3509E",
    "2116_MEMO.PDF": "CD8E0736-BBA3-48CC-8F38-D315F9A76F16",
    "21168.pdf": "D541A867-9CB9-4173-97D5-76E741508C70",
    "21183.pdf": "64B8AF3F-090B-437F-89F4-0E183A9A2618",
    "21184.pdf": "16D50D11-D605-4DE9-A897-262CFD784776",
    "2119_PROGRAM.pdf": "C0D0820E-1CE3-48DA-B699-03BF0CBCED1E",
    "21195.pdf": "BD258951-9F2D-4517-BBB1-4DA660B62D72",
    "21196.pdf": "1DE17B5A-6210-4E85-8852-064C3CF4E04C",
    "21199.pdf": "CE2A1C0D-571F-41E5-A887-CFF8548D08EF",
    "21200.pdf": "ED2E5C0C-A2F6-4293-919D-4BB9268E1D68",
    "21209.pdf": "01F2E1C5-DB57-4B22-87F8-180102E3D8C9",
    "2121_PROGRAM.PDF": "37E0EB05-F3C5-4EBF-898A-E4668B293041",
    "21211.pdf": "9EFFDFFB-8B4D-4446-9F61-ADF90FD62581",
    "21216.pdf": "52B1C031-7175-4479-8399-863C9F5A42E8",
    "21221.pdf": "222203AA-A14E-448F-9F43-1FFC1FDB5501",
    "21231.pdf": "18FB8A5C-45F0-4779-8492-F006E0067347",
    "21240.pdf": "1D8EF9E8-395A-4842-B8F2-3E8FD7ECF44D",
    "21245.pdf": "A965453C-B338-4AB7-84D0-DE6DC3B89D81",
    "21246.pdf": "BF25030D-784C-478F-8E26-BA85A799BD2D",
    "21251.pdf": "0790F4A1-5431-46D8-AB79-C2223DABCB51",
    "21252.pdf": "EC8A681B-E87E-4083-B107-7F0853B40BC3",
    "21253.pdf": "95B7454A-AD71-4E26-ABAE-3720A5776080",
    "21255.pdf": "16910A25-D104-4140-ACB9-C76CD916BD9F",
    "21257.pdf": "1E2A6EA6-7603-44D6-B631-3423EC67EE2D",
    "21259.pdf": "C177DA5F-4DA3-4202-A43D-063A68CF8AE7",
    "2126_MEMO.PDF": "71D6F79E-1EFD-4A41-B960-2173F013476D",
    "21260.pdf": "374F5FFD-C25E-42E6-B8F0-061030C0E021",
    "21261.pdf": "AF943ABC-55AD-4866-982E-B0382C9B6379",
    "21262.pdf": "D6E433AA-2949-4D2D-85BB-8A15493D944E",
    "21264.pdf": "009D80D7-7712-456A-B9C2-651FA0398BC2",
    "21265.pdf": "224B2ED8-D10C-48A2-9679-9755FAF39363",
    "21267.pdf": "9FC5E962-D8B9-4093-AB59-99BC2E133E6D",
    "21268.pdf": "B4B1F343-82A3-4D27-B34F-593DA818A7EF",
    "21269.pdf": "D9CBBFAF-4375-4DFF-BB4D-49321F7DB33B",
    "2127_MEMO.PDF": "7561D2BD-73F6-4A04-94B3-3BC49B6E2F12",
    "21271.pdf": "02D8B080-4BE1-4064-8D7B-026041575634",
    "21272.pdf": "0DAFDDC9-5FAB-4373-AD0D-D794F91A8968",
    "21275.pdf": "D17BA3A1-C061-43AD-AA32-C97CEFFD4A4C",
    "21278.pdf": "0FAF8A09-4799-467D-9B75-C7B89C4E3708",
    "21279.pdf": "FD1293A8-4A6F-40E0-BF13-806DD52061E2",
    "2128_MEMO.PDF": "6D784F8D-2EFA-4CA5-BADA-6939D5A02581",
    "21280.pdf": "93E497BF-4933-46DB-A959-0D287C554165",
    "21281.pdf": "BB075A86-C324-4ED7-BEBE-AD98A3B9BB0E",
    "21283.pdf": "5028CFFE-A849-4AC7-89FB-85621A58C6DD",
    "21286.pdf": "79DBC686-FD8A-4159-9BA2-24DB550E9A89",
    "21288.pdf": "2DFA828A-6939-4761-999E-0EB8379C822B",
    "2129_MEMO.PDF": "4CC8EADB-BACC-49F2-96F2-37C2BC6C56FC",
    "21290.pdf": "962397E0-7C88-4A3A-97B5-E898CD099F00",
    "21292.pdf": "93B8BE1B-201E-4771-AE49-B09F018E6923",
    "21294.pdf": "E6CBC837-97E3-43B7-A75A-40F74B01FDE6",
    "21295.pdf": "3EC03297-8156-469B-882C-C7B36431D285",
    "21296.pdf": "26A24B92-8548-479E-9725-06C4AD4D0849",
    "21297.pdf": "A4EFF40C-6672-4DA5-AFBD-C8DC2E6E3CD7",
    "21298.pdf": "F52F6DCC-039A-4FA9-9B1C-CF1C83B4D9C7",
    "21299.pdf": "738A1E7F-BDF1-4044-B7A8-2F6C5A49B1F2",
    "2130_MEMO.PDF": "D0C77FC6-184D-496B-AB25-048AE1DE7040",
    "21300.pdf": "CC98519B-90C9-43F2-BFDA-8391ADA94644",
    "21302.pdf": "C0AC2263-3057-43ED-9729-FD549B47B991",
    "21303.pdf": "CB451565-D942-4C2E-9BDD-250FD598B7F4",
    "21304.pdf": "CAFBDC7D-BAEB-409B-94EF-D35B45EFF8A3",
    "21306.pdf": "F19F2532-F11F-4F03-8F10-9A63F1F60D46",
    "21308.pdf": "CA1199EF-ECAD-4930-AA14-EC2927DFACFF",
    "21309.pdf": "DB561809-CE90-428D-B020-2363EE1DD127",
    "21310.pdf": "25B02C66-1496-4FE0-8AA6-D157439F5417",
    "21311.pdf": "9BC9F342-3860-4451-B7BA-CEC18BB053D7",
    "21312.pdf": "F1B1916B-0AE6-49D2-8857-B550D5E5E243",
    "21314.pdf": "9904582F-ED32-4025-BDDF-4828D3673315",
    "21315.pdf": "16ECF4F2-C65A-4A76-8186-3D799B9C841E",
    "21316.pdf": "F1DB8D4D-A39C-486C-B0A2-3C17A6A4CB29",
    "21317.pdf": "A3263238-8ADD-496D-B183-D5CA827BDB91",
    "21318.pdf": "D83BACAB-928C-41D5-920D-926B28ED75E1",
    "2132.pdf": "9742DC9C-C788-4814-BBE5-354F5E827E9C",
    "2132_BY-LAWS.PDF": "0A5E408D-3839-4266-9EAA-01A39B703A3E",
    "21323.pdf": "76C24609-BE1E-4684-907C-2B84E2AD6A29",
    "21325.pdf": "38DE5CF9-7CC4-459C-A948-CDD3F6B11063",
    "2133_MEMO.PDF": "97CFB781-1B66-48F8-99C9-4681E1B866A4",
    "21331.pdf": "AC84DD95-921C-458B-BD1B-19F345A5D51B",
    "21332.pdf": "98062FCC-7766-4EB4-952D-3453BC5FCB35",
    "21333.pdf": "3F33710A-E6A5-4519-BD2E-D9E0D086DB93",
    "21334.pdf": "105F61A2-3AA9-492A-8E99-3336BA3E6873",
    "21335.pdf": "CC4D5562-E9C2-41E7-8F62-61E553AA993F",
    "21336.pdf": "20FB88BA-5FDD-41E4-9F93-EEDF08EFAAF9",
    "21337.pdf": "14267BF4-BAB4-48FF-A65C-10D8AE671C4D",
    "21339.pdf": "089ADE27-89D3-4862-9B03-DBFA4B22D960",
    "2134 DBS Registry_CNS 2019_FINAL.pdf": "C3D77747-E997-4C9E-A437-23BBD553FDB2",
    "21340.pdf": "33354587-6DA0-40DF-96EE-19F42A42C19C",
    "21346.pdf": "195A1117-097D-42FA-9DB9-8982F3B092FE",
    "21347.pdf": "8BB9E909-881F-4880-AEC9-20A54ACCDE2E",
    "21348.pdf": "23B88D53-EA01-4D17-8E91-D0E85EDE0729",
    "21350.pdf": "AE08F45C-E72D-4D1C-9A40-BD09E282AA38",
    "21352.pdf": "8FA2348E-9A5F-495F-BB20-7242182A59EF",
    "21353.pdf": "14179B1F-C6FD-4A52-8BA5-2E74495E3D2A",
    "21354.pdf": "0B9A9A79-40CA-43F9-87B8-91B1FF3A0B10",
    "21355.pdf": "590E9D36-BF57-46CD-A45E-191F050BED8F",
    "21356.pdf": "3DD8D08C-FB48-4B90-92A2-3B52ECE5BDEF",
    "21359.pdf": "6C659BB3-7A29-4A12-906B-8FD6DF08DA0A",
    "21360.pdf": "3B0160D6-E40C-49EC-812B-757B66E24420",
    "21361.pdf": "3DE2C594-1997-4430-8E1C-2233B65B9695",
    "21362.pdf": "F561F49E-B61F-4935-AAD7-7173567B75E8",
    "21363.pdf": "15D729D5-20B7-4B4D-BD45-BF1535172F7E",
    "21364.pdf": "BFFB7789-EBAC-4E81-868E-4F5C01CD1F2F",
    "21365.pdf": "F1BD121E-995D-4BFE-BEF7-AFAAC540D8A3",
    "21366.pdf": "3C2962CF-E783-48FD-B4BA-FAA193392A4B",
    "21368.pdf": "DB30D035-87C2-4654-8B3E-AEF17743FB04",
    "2137_PROGRAM.pdf": "9B748F9E-7245-4667-98D1-564DE2582358",
    "21371.pdf": "E991A77E-B86F-479D-82C9-866897A151BF",
    "21372.pdf": "694400CF-F438-4DDF-8E45-C65104ED97E7",
    "21373.pdf": "55D0A3DF-4009-4C42-ABA5-64AF1BCFFE69",
    "21374.pdf": "0A4FF4D9-9373-4151-9FE8-D7B00E5607BC",
    "21375.pdf": "58236ADB-4A86-48D6-A371-3D65B45BA33D",
    "21376.pdf": "A2DB4970-D743-4F01-B48C-1497F377BDB0",
    "21377.pdf": "3F6CE5EA-85E2-4F9A-A723-546927CC2349",
    "21378.pdf": "861E98ED-2637-4D64-9E55-4BE0BF09494C",
    "21379.pdf": "AE84ABE5-06B1-4648-9D14-C29FAECF2A2C",
    "21380.pdf": "1B62A5BE-19A5-4A0B-92D2-560CC2A53217",
    "21381.pdf": "9205CA0F-283C-4A4F-82E9-8D17B852151C",
    "21382.pdf": "6BB1A924-4D55-4343-8368-CB84517A4A23",
    "21385.pdf": "131201DA-C27A-443A-B691-C6AD8FC0E776",
    "21386.pdf": "A981F7F6-EA0B-4DA2-8E7E-E1CF8CE5D197",
    "21387.pdf": "30EB9B85-6E6D-440E-9972-E3C2027E4C08",
    "21388.pdf": "EF7E3E8A-0199-4084-B45D-7777860628AA",
    "21390.pdf": "81269B2E-F12E-41BC-9C19-0F04324B3952",
    "21391.pdf": "A0D18DF3-6031-46D6-96E3-18236CE72177",
    "21392.pdf": "0BAFB9A7-C33F-4B80-B09C-9576B596CBC6",
    "21393.pdf": "563A0487-9500-4263-A96F-597A32B94A64",
    "21394.pdf": "ABA14668-B9C6-4A38-A517-F0EE7B8A1DFE",
    "21395.pdf": "2A58663A-0FEA-48AD-A3CF-BE8896F246B3",
    "21396.pdf": "D4424C69-B335-43C8-BB05-18F032B90093",
    "21398.pdf": "4CE0444A-55C9-4C2B-930E-8230A24F935A",
    "21399.pdf": "518C4CC4-A1B5-448F-BD21-79ED525628B7",
    "21400.pdf": "C6CB66ED-05F7-4CD4-B3B1-AB885B957DB9",
    "21401.pdf": "5BBCCED7-0687-45AB-ACF5-4B49B9C751CE",
    "21403.pdf": "75AFB221-4827-4B35-AE5F-3DE939B6E1B1",
    "21407.pdf": "BC7E590F-E27D-4288-8C79-AC7E1CD69CB7",
    "21408.pdf": "93148E20-A144-47C0-86B1-4A9CB31E7F2D",
    "21409.pdf": "89D8177E-5353-417A-9D1F-8A08E30070C4",
    "21412.pdf": "FCB76DE6-1146-47BA-85B5-B045F3235756",
    "21413.pdf": "CD3B8AFA-2922-4EF4-B9CB-5037D5759249",
    "21417.pdf": "1461C90A-3565-4C9F-B982-8A0A574F8367",
    "21418.pdf": "2E45C5A3-7DDD-4F18-8D0A-07BF414ECED5",
    "21419.pdf": "BACCB4D9-0613-4D1B-BE17-F78E7D3CB8D9",
    "2142_MEMO.PDF": "EEBEFEE9-DE85-4B21-8E23-0B30B4362644",
    "21420.pdf": "D8432AC3-682A-4540-A78A-85D91D4497B3",
    "21421.pdf": "CE55C3E4-7376-4121-B873-44B5F9BDB173",
    "21425.pdf": "489EBE3E-2B01-487C-BA38-4D129265BBBA",
    "21426.pdf": "5E123F4F-F008-4028-B52F-D2453C5EC229",
    "21427.pdf": "BD6C48D3-506D-4657-AECD-DA1396C24A7E",
    "21428.pdf": "B9B67AB4-3F91-40F4-B5DB-F853E92A271C",
    "21430.pdf": "7EC06A77-E036-4C31-9B72-80E87DD73B2D",
    "21431.pdf": "0178EE6F-0D70-4022-B7DF-E97E4A5330EB",
    "21433.pdf": "131A1D29-C60D-42C0-826F-20E13F2C913E",
    "21437.pdf": "2A96D0B1-80A0-4105-9CD7-A71611F52C6A",
    "21439.pdf": "DEC13F69-4581-4442-BCF5-F9D366590126",
    "21441.pdf": "E8EF3D0E-AB9C-4C29-A7EF-332078A2D6BE",
    "21442.pdf": "64EF2003-7D7E-4F18-B252-362034CAED37",
    "21444.pdf": "40470B08-4CF4-4DB1-86C3-718563913568",
    "21445.pdf": "94936B1F-E899-4A90-A5CB-DB77961E1A72",
    "21447.pdf": "D7E5B3FD-8820-402F-807F-4474C04D5A93",
    "21448.pdf": "5B17DC83-C913-46EC-A911-E273CBB1BDEE",
    "2145 CNS_VMS_Embotrap.pdf": "403D60D2-6F84-4EEF-BF41-208AEA35BB27",
    "21450.pdf": "B2735D9C-E4BD-4C68-928D-7B780A7CD94B",
    "21452.pdf": "8E3412B9-F37D-4C9E-A2F5-8D858DAA4233",
    "21453.pdf": "1C9298CD-41F6-4CE3-82F3-ED2429C475BD",
    "21454.pdf": "2C77891F-2BCE-4678-B07D-BD0D88D1660D",
    "21455.pdf": "8F5D0AA3-1FB1-4099-BB7B-C8159A7449FE",
    "21457.pdf": "FF792A92-E396-44C7-A21D-DD1013BD2A73",
    "21458.pdf": "E8341DFE-1A26-487F-8B8C-7202AB447A07",
    "21459.pdf": "C17CA20A-02BB-41D3-8869-90757929FC2A",
    "21460.pdf": "997A688C-47F3-4B32-A4C4-1867B23480A2",
    "21461.pdf": "B82D9BE8-C066-41CC-BC18-D7BB7C290373",
    "21462.pdf": "39143BF4-EEB9-4616-81B4-115394AC3204",
    "21463.pdf": "72AE6532-17E1-48F8-BD99-67473967F107",
    "21464.pdf": "04393E8D-EAC2-41A2-B9C0-8E9349453F4F",
    "21466.pdf": "1DB415C2-AB46-45C6-8990-CD323C1377D0",
    "21471.pdf": "DA681284-3F36-4F1E-A486-3C23FA59B8B7",
    "21473.pdf": "11B629E9-A208-48FA-BD1B-50B8B4320ED8",
    "21475.pdf": "C3B1E2B8-FD39-49E2-B33C-6E6BA5F55E84",
    "21476.pdf": "B5EE562E-04B1-4856-9E27-BE281B75AF7D",
    "21479.pdf": "AF197F8C-36FD-4D85-B723-AFE41A8C00B5",
    "21482.pdf": "663FD779-D3AC-4173-9904-FD202472DDDD",
    "21483.pdf": "2799B5E8-8A67-4AF4-BF73-D383983330CE",
    "21484.pdf": "B1B2AF3A-5C70-46C9-8716-49FF27C6FE49",
    "21488.pdf": "957E47F9-AAB3-402F-9D8E-6C0B14834705",
    "21489.pdf": "EB476DB9-73C0-419F-B0EB-F7C72FDBE15F",
    "21490.pdf": "DF062802-73F9-4990-BF86-F6063A549F6F",
    "21492.pdf": "89ECF0B6-96EE-4F6D-8B02-B1572E72C054",
    "21493.pdf": "52C2A612-56AF-473B-BF1B-4ED3B68E3070",
    "21494.pdf": "1621FE94-8097-4251-8147-4D1930008F1D",
    "21497.pdf": "045A69B9-9C74-4A8A-89F1-5198FAA11F7F",
    "21498.pdf": "09ED0596-0BEC-4D53-BC9B-7278A3D235A9",
    "21499.pdf": "3656E3AE-1EC2-4772-848E-BD00A046E17A",
    "21500.pdf": "61E19CCB-D32E-4ECD-ACE7-F78CEE2D0BA7",
    "21501.pdf": "768FF352-D760-4105-A729-CA83A60CE828",
    "21503.pdf": "85CCAE0F-42B0-4972-BCB6-5DBDFC06B254",
    "21504.pdf": "6CFB0B4F-CBDB-493D-8532-CDD50D311B04",
    "21506.pdf": "7CBA8C3E-CC0A-4F11-8231-026711707B80",
    "21508.pdf": "CA35307F-7C97-49D6-B9BB-681FD2BD983C",
    "21509.pdf": "86B19553-0BA3-4BBC-9DCA-98B900966C44",
    "21511.pdf": "F772E5F4-1E9B-4E0D-BFD6-9141650C6047",
    "21513.pdf": "EC3A976C-EF73-4419-9093-8A9089454DA9",
    "21515.pdf": "9C6C8279-A27D-4125-8600-B4E4ACC305DE",
    "21516.pdf": "34C96862-0AE6-4DAF-A12F-621E0B14BE17",
    "21519.pdf": "9194BF58-2F9F-4117-957E-6D5DEB667A44",
    "21522.pdf": "AC16E5DD-B5EE-42F6-B947-022A3E625795",
    "21526.pdf": "B854800E-B3D9-44AB-9BD0-5D415E1929CB",
    "21527.pdf": "DC7753BC-DD66-49AD-A312-D34E94DEDE21",
    "21528.pdf": "28D04159-CF47-4D22-9018-1F5825D48E69",
    "21529.pdf": "5CD3998B-EC35-4E7A-9D4C-AAFA75DBC790",
    "21530.pdf": "4BA8DCA7-40AA-4FC1-90B4-76D61F338EB8",
    "21531.pdf": "68215B6E-C16D-4A05-BC11-EC9C41FDB673",
    "21534.pdf": "10894202-FD92-4171-BF57-E797B572156B",
    "21536.pdf": "C41715A5-916A-4E41-9F62-598BD1E71C71",
    "21538.pdf": "9028AE04-156D-4EFA-B522-C9B22EC47625",
    "21540.pdf": "46057755-20F6-47E4-A030-73E8D0629BD0",
    "21544.pdf": "4CCCECF5-287A-4D38-A189-47EB7F9BA8A8",
    "21547.pdf": "6FC247AD-0CC8-4E29-893E-9A70FD819C8E",
    "21549.pdf": "4FEBEABB-FB99-4149-810A-CA1BA245890D",
    "21550.pdf": "A750E758-CFE8-4F6B-BB32-09FA58735E77",
    "21551.pdf": "B27ECBCA-5436-4160-8574-E2E4BA7E699A",
    "21553.pdf": "A88441AE-28E0-4F8E-8AEE-91085A9E6749",
    "21556.pdf": "95332BEF-BEE1-421C-8D7A-05F7EAE9F13B",
    "21557.pdf": "C5FE00B7-E54F-4D65-885B-FA5B5359877B",
    "21558.pdf": "27F6D49F-5478-4E9C-8C25-3577DD9821D3",
    "21559.pdf": "E69082D9-7398-46D4-AE5D-B3F84165183D",
    "21562.pdf": "D1B5831E-C200-49A3-A246-DBE1FD7C2113",
    "21563.pdf": "D5D2D888-38B1-469D-AA06-1A807526C777",
    "21564.pdf": "10A93838-F9B0-4E43-ACD5-958703B0E0AC",
    "21566.pdf": "8F29A244-3262-4249-8D2E-8712B6FA1DCC",
    "21567.pdf": "9554B62A-6F6A-4206-ACEC-51A6EC5B4660",
    "21568.pdf": "AF71AAF8-4E3A-4663-983D-00BD0F5949B4",
    "21569.pdf": "CC564FC7-1F7E-4607-B427-801B612D3710",
    "21570.pdf": "2DBB7084-685D-42A0-A688-D26D94B82EF8",
    "21572.pdf": "6B91D91F-CC3D-4CEC-8B21-AD36983F112D",
    "21573.pdf": "6079E719-1415-467D-A59D-D377968059C8",
    "21574.pdf": "D2058403-5424-4056-BB87-B89655479E65",
    "21575.pdf": "BDF2F5A5-2701-4CE7-8C4F-CE48DC9042A4",
    "21577.pdf": "55BBDB8A-D915-47EA-813D-FAF973BE4E8F",
    "21579.pdf": "796A01C4-2390-48A5-8E7C-E3EEBF7E8413",
    "21581.pdf": "0540D0FC-CF78-45F8-BDF5-21E90B8E900F",
    "21582.pdf": "268A51FA-2FEE-4C8C-9CA7-B4A091910AE8",
    "21583.pdf": "7CE85153-C9BD-47DA-8B33-7E1BB90291C7",
    "21584.pdf": "F6130D2D-9327-496D-8A79-BBA2215574EF",
    "21585.pdf": "486212C0-8B95-4F2F-B1A9-FF318903E021",
    "21587.pdf": "1EB1D273-F24B-47E9-963D-F0A7E59B40A5",
    "21590.pdf": "83FA3381-7667-40BF-A413-CDD9A5200E0F",
    "21591.pdf": "BBD6033A-264B-445D-B816-FC595CC4B91F",
    "21592.pdf": "A8249997-C76A-493F-AE3A-AAF1F2E25A1D",
    "21593.pdf": "87212097-7EFF-4E0A-B083-FAE28A7A7EED",
    "21594.pdf": "52876411-8E08-44EB-AD23-0660A8E301C0",
    "21595.pdf": "C558BA00-8972-482F-A3A1-0CC7ED6B311E",
    "21596.pdf": "9D90CF22-4149-4748-A928-F5258AAD55F1",
    "21597.pdf": "2C6D19D5-CB3A-44C4-A5BB-14CC3D24276F",
    "21598.pdf": "3023A037-F03E-4873-8DDF-0F78F9ED88E4",
    "21599.pdf": "775D53DE-5D08-4790-B3EA-3A124C2BD473",
    "21601.pdf": "24188B0F-604B-4ABE-99F4-C527830660E5",
    "21602.pdf": "E2EE098B-D0A5-4EDE-8445-5FD4A051D59E",
    "21609.pdf": "A41545F5-1771-4652-90B3-2403D463CF66",
    "21610.pdf": "BA041B13-551A-4EAA-A3A5-AAD67B5630B1",
    "21612.pdf": "B8DD86CF-A410-4F14-8B7F-0D2676016F03",
    "21613.pdf": "1658C8C0-E78D-4F8F-A8E1-694E731E9BB9",
    "21614.pdf": "D6DB9E84-CEB8-4B03-891B-0A99B3481C80",
    "21615.pdf": "3D821CCD-D875-4BC0-B5CD-46F1EC00F63C",
    "21616.pdf": "AC6B2F60-CBB4-43DE-B605-FD7C3853B6A7",
    "21617.pdf": "01EE51A2-E909-4820-AB3A-E8B3934D51D6",
    "2162 CNS_2019_Poster.pdf": "A6D1A4F5-2555-4F80-9AD7-9CF4E5CEC773",
    "21621.pdf": "05890E04-AF07-4B78-984C-0FE8AA1B7546",
    "21622.pdf": "4B491311-2AEA-435C-AB9B-5A29F7B6F6B7",
    "21625.pdf": "413D30F6-4AD6-4921-9A72-36CEB6948357",
    "21626.pdf": "C976B0C4-2B3D-404D-B07B-7DEA10C4F027",
    "21629.pdf": "CC82319C-78DC-4390-8863-8E0E559EEC20",
    "21638.pdf": "854AD1C8-6A1A-4108-920B-7B71915A2514",
    "21639.pdf": "F29F588D-587A-4109-B1B0-2E6B412E1937",
    "21640.pdf": "B2048D30-B64C-4C94-AF40-A4F2EEB2508C",
    "21641.pdf": "3A8183CA-6C3A-48DF-B0A5-BC63E24DA004",
    "21643.pdf": "163A9A22-CF34-418C-8165-CA6AE89A98B5",
    "21644.pdf": "16483F54-B235-4529-B362-0E6B9BD3049B",
    "21646.pdf": "AC4E16D8-5C8D-4A82-A266-D8C18AC457C8",
    "21647.pdf": "CDC50FB5-41B5-44D8-97D5-68D2C6B856D2",
    "21651.pdf": "5A36108E-EED9-4B3C-89AC-9C9B610C9AC7",
    "21652.pdf": "FE55ADFC-A469-44C6-9EE4-28D4EBDFF4B0",
    "21653.pdf": "84F35DB3-6ECC-408A-A7C8-0B3DC076D498",
    "21654.pdf": "8A436E93-20F2-45E1-A12D-541AD556A956",
    "21655.pdf": "9784561D-A193-45C1-A5A8-AB865F2027FE",
    "21656.pdf": "EC507176-3483-470F-8DA8-DD037299AD8B",
    "21657.pdf": "E099AD01-E1D4-4522-A633-AAB488A235A7",
    "21659.pdf": "AD760E12-3EC0-4A2A-B19F-59203A3EB0F4",
    "21660.pdf": "3AC2B514-CBEA-481D-95E6-C52D8E3741B1",
    "21661.pdf": "B4ECD962-CA1E-4016-9D6D-03A59D8BF30E",
    "21663.pdf": "73779656-F477-4E0E-B900-40C510C2C2AD",
    "21664.pdf": "329F09D7-205C-4EE7-B99C-AFD293BA92A4",
    "21665.pdf": "35DF0476-EA1F-4861-93EE-BAE45E50904A",
    "21666.pdf": "918965B8-9F35-4978-A00F-F14D49D0B02F",
    "21669.pdf": "55033D9D-7E25-4BB2-B313-7FCA0224D5D7",
    "21672.pdf": "4D050526-CAFF-498B-A72F-363474F24D8F",
    "21674.pdf": "0049074A-6CDE-42CB-8691-86B1EB16CEE9",
    "21675.pdf": "54515333-91EB-4468-AAA9-7557A8F73573",
    "21677.pdf": "7CEEB47A-7C61-454D-83FF-0D9094B9A0BD",
    "21678.pdf": "07BA7D6E-A3F0-4F53-BB4A-F898BE3EC737",
    "21679.pdf": "A6693870-4105-48B7-9609-B9760B006D7A",
    "21680.pdf": "9BA81CE7-973B-4D96-A79B-A794E20BBFC8",
    "21682.pdf": "BCB80067-34B0-4992-B0F0-1E1D0D0C6AAF",
    "21683.pdf": "C9D7A2EF-7DCA-40CE-BBCD-EBF4642B0A96",
    "21684.pdf": "2C0AEBC2-20E8-4F1C-86E5-6C157F1E1028",
    "21686.pdf": "5EB37059-811B-4319-9CFF-A657FF3C4052",
    "21687.pdf": "9ED128CA-F5A7-4EAC-B60D-979B1E0B6348",
    "21688.pdf": "9E2EAD87-BA98-45FB-AFBF-EB05DBAA10B8",
    "21689.pdf": "5632EA83-D47B-4B09-9BFF-7BC4F706F88E",
    "21690.pdf": "283A6E82-2F26-4488-AF69-C4A4023B7A43",
    "21691.pdf": "AB5BFF31-DB6F-46BA-85DA-01F728160783",
    "21692.pdf": "3EE90972-0810-442C-B221-E6A7E6FA6535",
    "21693.pdf": "4A479F82-D621-435C-97F3-5E82A9435442",
    "21694.pdf": "4004F536-9331-4963-91A4-5A27A7363F1C",
    "21695.pdf": "38653B64-E80F-46AD-948D-2140A46368AA",
    "21696.pdf": "2C8F9278-9A8E-4281-9FC6-0107C3306339",
    "21697.pdf": "7117A1C2-4C56-47BC-A1E2-67037754CDAD",
    "21698.pdf": "17FFB49F-91DC-4EF1-BDB8-60DFD2813C91",
    "21699.pdf": "3AFD93E7-5AAF-412D-B7CF-E87515D42235",
    "21700.pdf": "6B855DB7-4A5B-4077-9590-7C0A6B2DC1A8",
    "21703.pdf": "1DDFD3A4-F3FB-4A33-9ADC-C725164FF31C",
    "21704.pdf": "E6B5B4AE-E0B2-4A00-A3A5-AFC76A096A1E",
    "21705.pdf": "67439DFB-111D-42E3-B418-92D8945C9E9E",
    "21708.pdf": "2B002BB6-15C9-4A36-8731-1774C6F25CEC",
    "21712.pdf": "C0FC1C06-1936-402E-92E4-9ABDEFFA3F30",
    "21713.pdf": "26659BB2-EC9B-4559-AFF5-4734C11F8F1B",
    "21714.pdf": "DC8DBD4F-F445-4C2E-B2F8-8D4949D106B4",
    "21716.pdf": "E85ADE73-7166-4E8B-9DCD-6AB73F85AF43",
    "21718.pdf": "DECD6C89-6737-4C9C-9C76-E3635C785A4B",
    "21719.pdf": "64C1933F-DD34-410E-8E7E-77FF16D6CB47",
    "21720.pdf": "C4AEA097-0B8D-4526-B8E6-7946C2818915",
    "21722.pdf": "2E0B6386-5F79-4EA8-BF5D-6520B7E2A3E9",
    "21726.pdf": "B7F0F10F-4580-4100-B07C-73EF3DD73541",
    "21727.pdf": "526ED01C-9252-4881-943D-EC00AF672F8E",
    "21728.pdf": "F44EA399-22CE-4247-BC79-DA39D9F29CB3",
    "21729.pdf": "3DAA5939-702F-4144-81F1-3F9910B9F7A6",
    "21730.pdf": "00E6D57C-019B-45DA-AB86-4C2D05CD91E4",
    "21732.pdf": "D039F851-D19E-4718-AE45-6A92FC8BF524",
    "21733.pdf": "9BB6BC65-0B4F-48EA-B5A8-E463D34473FE",
    "21734.pdf": "CF5F7994-0343-4ED1-BF3B-E121228C6188",
    "21737.pdf": "673059B4-F3FA-4733-BF45-5406EB86C7E8",
    "21739.pdf": "8A1FBC03-AAC4-4AA8-B05A-A53E4830665D",
    "21741.pdf": "7AB5C072-D795-4C47-9BBD-07C244AECE14",
    "21744.pdf": "332CAA5C-8591-4F4B-A06C-975B9FFCCC29",
    "21747.pdf": "9C84AEA1-1383-4704-8278-C561839C16E7",
    "21749.pdf": "5A84593D-AE97-4E68-874E-6A9D87A8B4A1",
    "21751.pdf": "C779507C-4D1D-484B-8B35-80AEFD698491",
    "21755.pdf": "26BFAFAF-50AD-4517-B0D6-8DC44925FC6F",
    "21756.pdf": "B14FA187-9F54-474A-9133-E372D33120B0",
    "21757.pdf": "1D2894AC-79A6-40FD-8B12-88C63311FED8",
    "21758.pdf": "B8B7D68C-2ADA-4B7D-9424-1550A7483131",
    "21759.pdf": "E1155043-4A31-4B40-ACDD-A1F9899CF774",
    "21760.pdf": "BDACB2F3-2FDC-445A-AD54-3E959BE6C904",
    "21761.pdf": "CA88EA9B-5FCB-4915-BE27-7800E2876B2B",
    "21762.pdf": "9CDCC37C-D984-407E-8733-CD52F176E428",
    "21764.pdf": "752AE354-F57E-4BD5-AD4D-3B4AFEAA7244",
    "21765.pdf": "C660A74F-97B8-4032-89F3-349C3ED08878",
    "21768.pdf": "947B6721-EED1-443B-85B4-86DCAE294E4C",
    "21769.pdf": "89C461E0-85A0-4B0E-9D33-218D0C76C603",
    "21770.pdf": "71A03307-E00D-4D23-9484-326DCCD676C0",
    "21772.pdf": "EC53C72C-EDF2-4305-BB8B-A04BC5B33576",
    "21773.pdf": "F17C8EEE-85CB-4B79-B91C-5566653422C5",
    "21775.pdf": "9737ED10-4DF9-4F03-A2C9-C127AA4AB76E",
    "21776.pdf": "C41AEFDD-32AA-42BA-8A65-263A08B7A852",
    "21777.pdf": "784BCE52-9B2A-4D0C-BC2C-B649C880605F",
    "21778.pdf": "47A7F3E7-B0F8-4735-B6CB-21619493ED59",
    "21779.pdf": "05B30F27-FAE4-484B-9026-904D7C55D8B6",
    "21780.pdf": "8B8372EA-FA60-4F92-8EC2-A684381AF353",
    "21782.pdf": "3D8F9DAA-82C4-4BC1-B139-C1E59D116E5B",
    "21784.pdf": "8B189437-B020-4AF8-BD8E-71BB0BD505CB",
    "21787.pdf": "3279E330-22DF-488F-BC71-F078350A975A",
    "21789.pdf": "E22B14E3-601B-4433-A151-8A637BE756B8",
    "21792.pdf": "57817DD1-D227-431C-8ADA-BEE8A9DAF3EB",
    "21795.pdf": "451118C6-24E4-4C71-9CC4-CB415F9CDB86",
    "21796.pdf": "81FA9A26-93DC-488E-A1DF-AA20DE6FB445",
    "21797.pdf": "10EAF865-7515-4399-833E-8DE5D8494A77",
    "21799.pdf": "620F592C-1BD9-4A7E-A7E7-9B9D8342C318",
    "21800.pdf": "1ABF4689-DEE6-478A-AA89-4140710036E9",
    "21801.pdf": "A063FAA6-E2B0-4AA4-BB9D-8F54AA91BF79",
    "21802.pdf": "D4D262AF-4C4F-4476-B4B5-01D56891F462",
    "21804.pdf": "31D38296-06B1-41CB-9813-4DEA4FC516DF",
    "21808.pdf": "953A0813-F5E1-4568-AD72-20BC254328D3",
    "21810.pdf": "359B9C89-87B9-4554-BF73-6A9A046A27FC",
    "21812.pdf": "F39B4132-97D1-4232-BC08-AED248DD7859",
    "21813.pdf": "FDD2DA4C-2B87-49BD-8AA2-B762C46005FC",
    "21814.pdf": "31B2220D-A9C4-46C7-82C9-114895B9E6EF",
    "21815.pdf": "1EB1BA6A-C74C-45FB-B596-EB75188C2295",
    "21817.pdf": "B62D0D53-4AB3-4CBF-9078-42160DCA52EB",
    "21819.pdf": "0C39C418-875D-44C2-816B-A1D34DB34C00",
    "21820.pdf": "24CC1D77-F540-42F7-9B9B-32F6926D5B8D",
    "21821.pdf": "49361B45-9AEC-480E-8209-13F70E22B8B8",
    "21823.pdf": "9A220F90-4DCD-4B09-9E85-65F6DD191A6A",
    "21824.pdf": "26BFF062-89EA-4597-806D-9B9F9D5034CF",
    "21825.pdf": "50F53577-FC3E-4E65-8F6B-D5534F5FA6F6",
    "21826.pdf": "91F21BB2-02A1-437F-95A0-43F01876AEB3",
    "21827.pdf": "C07DD6C7-130F-42FC-AE9C-9717A6DABAAD",
    "21830.pdf": "A8C5CC9A-CF92-48DE-A72F-41F101228141",
    "21831.pdf": "4CB735A1-F92B-476E-A2FA-4FBC03C64674",
    "21833.pdf": "FCAFE873-B09B-48DD-A960-0B68EA5C5081",
    "21835.pdf": "E0BA7AA2-B33E-4E81-8F66-C285E31D6874",
    "21839.pdf": "9944584F-968C-4EFD-8DC8-AE27F7B17252",
    "21840.pdf": "322603E6-53F4-49D4-81EA-1906D3CE4B00",
    "21843.pdf": "AF251688-9C9A-4628-A34C-8ECACF8C7683",
    "21844.pdf": "52C0F644-BE69-4F2C-9E05-59159B6EAF06",
    "21845.pdf": "AC12DECD-697F-4511-86C9-EC412332BB2E",
    "21846.pdf": "180B32B3-D916-4757-BF37-0D4D1368DE76",
    "21848.pdf": "1DEC76C2-2590-49E9-B621-6673C874C544",
    "21849.pdf": "3C12C5B4-3397-486F-9EED-B459CBA72CEF",
    "21851.pdf": "231A9B02-73B5-4B9C-AE4F-474CE7C0BA4B",
    "21855.pdf": "317B4B78-C5D9-4700-B261-A3B23E5CC874",
    "21856.pdf": "DCCA1956-0744-43B0-9BD9-4B28862F1545",
    "21857.pdf": "D63D8FA2-6F2E-4932-98F8-31F94F2202CF",
    "21859.pdf": "7C7B53DE-8FA2-4C37-85F9-A04305B4AD4E",
    "21860.pdf": "B4D8D470-C9F2-49EB-A377-ADCFBC180E38",
    "21861.pdf": "5C25DDB0-A312-4250-AB54-7530097A8B79",
    "21863.pdf": "9E2E1C0E-73A3-47F7-A7FC-CBA872D49F01",
    "21865.pdf": "8E2D3D3B-45B5-4ABD-B6F4-12CC101CF377",
    "21866.pdf": "3C011E0E-9504-4643-8619-3A8DC5133F5D",
    "21867.pdf": "37DF45BA-4878-4443-B71A-B5D866FECBEA",
    "21868.pdf": "5C38CD2B-FE76-4781-987C-0E5337B00753",
    "21870.pdf": "F4B47C0D-BBD5-436A-9EBE-E2A5485AC652",
    "21872.pdf": "7B053FC5-2359-41DD-96EB-C9A93D012F48",
    "21873.pdf": "A30750F6-0043-44DC-BDCC-CD92B7E098E5",
    "21874.pdf": "986DF418-8AB1-4391-A744-5248B773F5C6",
    "21875.pdf": "1AD5B010-F10D-447E-A247-F047ED82E67D",
    "21876.pdf": "16488074-86AA-404E-B133-53F7149F8AC6",
    "21877.pdf": "19DAEE10-1361-4169-8469-68926B7D9926",
    "21880.pdf": "3088C15B-2CE1-4966-B904-EC1A3E4F4DEC",
    "21881.pdf": "235297E7-A7E7-4D0A-A1C8-B58393141A9F",
    "21882.pdf": "9451D1D6-ACFF-4CC9-B1CE-1F70792520F2",
    "21883.pdf": "D179CADD-F060-4E87-92F8-54D3861F7F59",
    "21885.pdf": "6F0743A8-6C44-450F-9CEB-19A1E9FBC807",
    "21886.pdf": "B0F6ABB7-2D3B-47C4-8340-73998812DFCA",
    "21890.pdf": "E25C7C80-33AF-47FB-A32D-39BE9F45D5EA",
    "21891.pdf": "BA128CCE-0912-4DCC-BC88-F346DDBC67F1",
    "21894.pdf": "FCC9182B-75F4-48E9-9C24-705A547EA7B5",
    "21895.pdf": "2D8EE417-DCCD-4724-AAB0-6FE2F5F0447A",
    "21896.pdf": "7C8FDCD8-7EAA-42BC-B504-174C01FF325F",
    "21897.pdf": "4660C9DF-65B1-4C41-BD38-BA7C37CB64CD",
    "21898.pdf": "48489940-8A08-4C8C-99FE-C58BB7A02C90",
    "21899.pdf": "BD0B27AF-E85F-4C4D-A71A-4A22E111499C",
    "21901.pdf": "9BA9CDE0-7595-400E-9CEE-216E31344CB2",
    "21903.pdf": "1C1773B4-D2CF-448E-B7FC-C7B2E0817AF3",
    "21904.pdf": "2A717D7E-36FA-47BD-9FBF-8B1D9FC7E1B6",
    "21908.pdf": "50D42110-905E-428F-848F-C7A90C12502A",
    "21909.pdf": "4CE10652-7A44-4CE7-82C1-9FC708298A0B",
    "21910.pdf": "09107412-FEEA-4D70-B248-7900AC673630",
    "21911.pdf": "0089CB5B-9C45-4650-8981-1777099365A6",
    "21914.pdf": "756A9BD2-01B5-47EA-8A30-F153F51882D4",
    "21915.pdf": "F39C286F-D865-4B51-825F-704A72400ED3",
    "21917.pdf": "9EB6A441-C06F-460C-9AA8-75E68BD7D049",
    "21918.pdf": "724C27E1-1AA5-4ABD-81DC-C13C6313C4A4",
    "21920.pdf": "C7F058B9-82C8-4743-B688-90264A5E5635",
    "21921.pdf": "A7D92CB5-BB74-403C-97D7-1DF3B72518F3",
    "21922.pdf": "6F001D26-A084-4961-8C33-F304EEAC2177",
    "21926.pdf": "9C1A2C7D-0AFB-47F1-B978-AD8BC0374077",
    "21927.pdf": "BD906B95-D6F6-4B6A-975E-E42EA4AE32BE",
    "21929.pdf": "7B1C009A-0F1E-478B-BADC-E3C146DFEBA5",
    "21930.pdf": "B1518E04-7AA7-48A9-9E82-4229392B3222",
    "21932.pdf": "E816E730-6A6D-4FA8-A753-3A4F007FB17B",
    "21933.pdf": "05B2C936-226F-41F5-99B5-6169F621E734",
    "21934.pdf": "52BE052E-C208-48ED-B24C-86E85385EEBE",
    "21935.pdf": "AF657BA3-F1B0-49AA-B094-F11A94AB631E",
    "21937.pdf": "2B37AD78-6E98-4379-A8C9-2E14CBAE6F89",
    "21938.pdf": "D0EA0BE2-D4A3-4C91-9FFE-24EDD372CA71",
    "21939.pdf": "A4724B97-A132-4741-8A7B-3BD9CBB44B2C",
    "21940.pdf": "B93A59D9-EBC4-428C-9C19-33735BF2CB59",
    "21941.pdf": "E13C10F2-E51F-4D1D-B4C8-B67E0288A000",
    "21945.pdf": "FA44D287-5ABA-4AF6-9D5D-5C37FD7A869E",
    "21946.pdf": "A13DCEFE-520C-488A-8D3C-6A44682B129B",
    "21949.pdf": "35386719-EFC2-4278-99C4-BE476C422160",
    "2195_MEMO.PDF": "ED156493-5726-48D2-989D-0BD906FC67FF",
    "21953.pdf": "AB88D173-E941-486E-BFBC-9FCC9EB29E53",
    "21954.pdf": "02462D0F-95ED-4163-80C0-D4E3573CEEB8",
    "21957.pdf": "B520BB32-0168-4463-ACB6-443224F1C88B",
    "21958.pdf": "63D0E06D-6895-43BF-B655-11061B29AB91",
    "2196_MEMO.PDF": "95D437B9-433B-4FA6-8BA5-184DC85198FA",
    "21960.pdf": "5AED2492-1C61-47E2-A6EA-DCF6ED4666F5",
    "21961.pdf": "7847F85D-49EF-4B3A-93CD-8982592022C0",
    "21962.pdf": "169FA714-38FF-48F6-9DA9-5EF02C4EFECA",
    "21964.pdf": "E33BC7A9-C297-4539-850B-A0ADC5525220",
    "21965.pdf": "707CE2B4-0232-4C2B-A3FB-01B29452A8A9",
    "21968.pdf": "8FC8E144-3A14-4BE5-A3CE-BCCEDBB12CFF",
    "21969.pdf": "BC07B29A-83DA-43DA-A982-0A822241C3AF",
    "2197_MEMO.PDF": "170FA008-E801-4406-99FF-A0102A736244",
    "21970.pdf": "9BC33D98-900B-4741-B00C-D4693E65C4FE",
    "21972.pdf": "B729E421-2CEC-47F0-99B0-23697F5EBE8A",
    "21973.pdf": "51352AED-3AC2-418A-98DF-6097BC48C625",
    "21974.pdf": "804E2393-B117-4437-9E70-A781E8ABE685",
    "21976.pdf": "C02CDDBB-4BF3-498E-B3E0-1510BF9C2D51",
    "21977.pdf": "B8A13159-9EB6-4753-B4D9-1DF61ECADA1E",
    "21978.pdf": "D2B684F0-27E1-480C-A84B-9194B2E83A61",
    "21979.pdf": "6468AF2D-28AE-4A2F-9DA1-4D13B9C00AE1",
    "2198_MEMO.PDF": "63F385C6-5B2F-4C0A-B87F-E1568471304A",
    "21980.pdf": "D17AC377-61CD-43AD-9735-C78865E21829",
    "21983.pdf": "279A124F-709E-4AAC-88E0-4849CA881E41",
    "21985.pdf": "5D4965E0-C435-4540-A2C0-073F030BA99A",
    "21988.pdf": "321DC53B-0E4C-4E9E-B1D9-0C82258956B1",
    "21989.pdf": "C1E35A7B-7ED8-4C98-8B86-55589B2BFAA7",
    "2199_MEMO.PDF": "29AED0EA-5A2A-49E8-919B-C56A2DBEC0A6",
    "21991.pdf": "4A6F27F8-C5A6-4D95-918E-5F84053C9394",
    "21992.pdf": "A48AC1EE-721E-4E15-BFCC-3BD653FDC8B0",
    "21994.pdf": "5BFB31D7-1180-496F-8ED8-C3F697D04470",
    "21995.pdf": "002F8B59-31B6-4E03-903E-D0A4DF0F9C25",
    "21997.pdf": "50FFBD85-B38E-4889-8E48-E32FADBAB263",
    "21999.pdf": "D80BABF2-D626-4024-9C47-E8B20C98AEBC",
    "22 Neuro Fall Newsletter.pdf": "A067ACBF-BB54-4A17-B15E-803CFE34C2A2",
    "22-140_Neuroplastic_postcard_Draft2.pdf": "46D871DD-AA59-40B5-9337-417F71BC8D04",
    "22-708552 Advocacy Medicare Principles Document_2.pdf": "5B86F89F-CD5A-4B4F-829B-1325DF8936EF",
    "22.Osbun SNS Nomination.Limbrick.9-15-22.final.pdf": "7D7B18E6-587E-4E3E-BF34-326DE92FDBDE",
    "2200_MEMO.PDF": "B4D8FB4B-25CD-42A6-98D8-507236F4D362",
    "22000.pdf": "BE87A5D3-8A81-4098-B2AF-C1A91D5E75F3",
    "22001.pdf": "038A5AA7-461F-4BD8-B942-5C22EDBF4519",
    "22004.pdf": "3DCF367A-3046-47A2-8463-A1AD4D42FCF1",
    "22006.pdf": "67D2139D-89D0-4378-934C-2F3AC2078060",
    "22007.pdf": "F9F02075-ABDE-4628-A767-589BEE9BE570",
    "22008.pdf": "CFF64729-B266-4F14-8363-7092BEC5FACD",
    "22009.pdf": "C1E6F5F1-A1B3-4BA5-B737-B14486966772",
    "2201_MEMO.PDF": "C0CEE66A-6A68-4C71-B5B6-9FD3D8623367",
    "22011.pdf": "89B006BB-CB36-4995-B9EB-76A6C5ACF0A4",
    "22012.pdf": "CE0B38E2-716A-4DB3-9285-2B31C8AA3147",
    "22014.pdf": "8EA423D0-A659-4F09-913E-A8B1BE426A2D",
    "22015.pdf": "3C8EABFA-0A71-4DA4-B0E1-AB14A5301136",
    "22016.pdf": "113F591E-84AA-40F3-A02C-7A20CF8B978B",
    "22017.pdf": "A507DAF5-EC31-4CC4-8C73-0B165EC32DC1",
    "22018.pdf": "0C78A4F5-4F6B-4818-9D70-38A413D0068F",
    "22019.pdf": "B219FF77-7DE4-4212-A5FD-E358DF1A4346",
    "2202_MEMO.PDF": "35439A81-13A4-4095-9CD8-7327FBF2082F",
    "22020.pdf": "91CCD423-BFD5-4AEC-88C6-31335683507D",
    "22021.pdf": "C4D7D7A9-1CCC-4302-B1EE-90DBF1E754F9",
    "22022.pdf": "FCD0A015-D247-4484-AE6B-C3E16E4CEF51",
    "22024.pdf": "0BF87C90-77A5-48E2-AB18-3330ECDB6992",
    "22025.pdf": "AE95A263-06D8-4801-873D-3119757BCFBC",
    "22026.pdf": "0343600B-8BC1-4F4E-9E9E-EB8891D72F59",
    "2203_MEMO.PDF": "58A3DD42-E5C2-4713-8904-054C43154A9E",
    "22030.pdf": "DB51F227-BE7E-4497-8EC0-30CB38C2E233",
    "22031.pdf": "20825E64-BD70-43F3-B461-1768077E4C9E",
    "22032.pdf": "52872FFF-9638-4B94-A8F0-2D507993B194",
    "22033.pdf": "99364928-68AF-4FA3-B2A6-61C45AACDD23",
    "22034.pdf": "49102297-2FB4-459F-97B9-14198C67FEEE",
    "22035.pdf": "579FF47A-A49E-4A2E-A4F9-B703116CF6D7",
    "22036.pdf": "6C791167-1D04-424D-A58E-C269B2224BC5",
    "22038.pdf": "BFC88EEF-7C63-4638-9A5A-C7BD4FDA2207",
    "2204_BY-LAWS.PDF": "864E0371-610B-48C3-A6C1-944BB6934FEF",
    "22041.pdf": "9217D29F-0D35-4ABB-B413-2A92F888B475",
    "22042.pdf": "C2FE1264-B4C5-4548-884F-4FC8D586A8DB",
    "22043.pdf": "21EE2FB8-5B50-4197-A65D-EECCD071C00B",
    "22044.pdf": "45686D7C-61C1-4FDF-8B95-DB8FDABFE37D",
    "22045.pdf": "DB2EF451-91AC-460C-9861-B20C4878747A",
    "22046.pdf": "110F435A-EF1D-485E-8B5D-67E58B31077D",
    "22047.pdf": "029CCC83-00EB-44C0-9665-39F0683081E5",
    "22048.pdf": "41D0DE82-B9CB-489E-BD0C-889A2AE811C7",
    "2205_PROGRAM.PDF": "13B3B7AE-00AD-4010-90ED-134CA961AF1F",
    "22051.pdf": "4ED2D6B2-D71A-42B2-8052-D8D239EBE5AE",
    "22053.pdf": "F001C435-DCD5-44F7-92C8-E28530327529",
    "22054.pdf": "88979C82-4D6F-4E94-8ECC-0407B1A131BC",
    "22055.pdf": "49DC5AEB-8090-47A5-A710-20377BEE82DE",
    "22056.pdf": "C4AFD53C-2082-4832-95D2-AE39FD7887F7",
    "22057.pdf": "7D1DE7B6-65BE-4331-B64F-A08F4485DAFB",
    "22058.pdf": "953CDB02-943D-419D-AFA7-00BD185FD714",
    "22059.pdf": "00D4A077-C315-451A-B069-51EB07DF5C52",
    "2205CNS.pdf": "589C3301-3B9B-4FEE-AA15-9E8F3C5EC8F9",
    "22064.pdf": "B2560B2A-6A93-43B8-8753-1C252368B9E9",
    "22065.pdf": "FFFD19D4-02AA-48C9-BCCD-0222D01845D8",
    "22066.pdf": "B0AFE892-A646-4E65-B324-D2C27FFD1889",
    "22068.pdf": "64B409BC-77B6-4CB8-91DD-AD9E29F2B339",
    "22069.pdf": "79BC4061-78C7-4AB2-A419-C6F047A6E573",
    "22074.pdf": "D8F7C702-C07E-406B-9F3D-AE2B04402C4F",
    "22075.pdf": "4035B3E2-8D3F-489A-B81A-C57B0C52DDC0",
    "22078.pdf": "B0AA72D4-255E-4959-8743-3BE0D3E8D5BF",
    "22084.pdf": "70238C89-8270-4233-B0BB-2D170D0CAA26",
    "22085.pdf": "0E03DF51-B12A-4D40-8559-D3961CB71EB5",
    "22086.pdf": "0FD021E4-2354-4545-A9FB-1071FB39D538",
    "22088.pdf": "49062689-C95E-4DC3-95E0-DBFC08010F67",
    "22091.pdf": "7B817FCA-E66C-48B4-AE70-2A0A8D70567E",
    "22093.pdf": "64E9FDCF-AD7C-4753-97DB-D76C8ECB6382",
    "22094.pdf": "C5C44BF4-D6DE-4D27-9274-209002A3C5D4",
    "22095.pdf": "6DA7BB7E-2288-4673-9617-A833D95B1FBE",
    "22097.pdf": "547E5190-D1C5-4A9C-B684-ED7B9D018728",
    "22099.pdf": "D08CEBA1-2420-4C40-B2BA-6158FF58D510",
    "22100.pdf": "0DD1739A-21ED-4397-B379-5A42C04689DA",
    "22101.pdf": "A37E7964-38A0-42C4-BA13-879735C94064",
    "22103.pdf": "AED61FE9-B639-4077-8945-F6F08C336002",
    "22105.pdf": "003BFA33-4B1A-4A51-89D7-65B1334C790C",
    "22106.pdf": "311D0686-600A-4E72-B668-AAD3CD1D894D",
    "22107.pdf": "F84E6AB6-C52F-4A67-B4A7-165EB67C64C0",
    "22109.pdf": "6D8D4D57-3FF5-4D09-8586-A0892033CA05",
    "22111.pdf": "D0ADE8E6-F377-48C8-AA52-345009D53EE3",
    "22112.pdf": "D3F446D6-BA86-4C1A-B36F-693360B01D3D",
    "22114.pdf": "E9B16D5E-50B4-4EEF-A195-CC7046D47CE3",
    "22115.pdf": "1BA75601-C68B-4083-BE17-B2DA960561A4",
    "22116.pdf": "422B9871-F933-4578-B101-17C736B4E764",
    "22117.pdf": "61660EEA-A9EB-4E85-B88C-0045D841A8A4",
    "22118.pdf": "85395B34-1547-4FF9-91DC-E1AEA38A20CA",
    "22119.pdf": "7DC22A9F-5593-44C3-84FE-D0A81E4D36A9",
    "22122.pdf": "3414E46D-1C74-4E35-8D7D-0414742314E9",
    "22123.pdf": "E7AFB4E4-4AD9-4316-962D-95DC0BD8B4E4",
    "22124.pdf": "C52843A9-386A-41E0-AEA4-1492E06D7026",
    "22126.pdf": "97542EE2-58F3-4620-A1E8-74777E1C1137",
    "22127.pdf": "C5B017FE-1DD4-4928-ADA6-66D2927B4AE1",
    "22128.pdf": "FBFB7663-2792-4CA4-A501-51FB852A0AC5",
    "2213_MEMO.PDF": "454BE61B-8FE1-4288-86A5-190EB786DDEB",
    "22130.pdf": "98FFD092-D41C-433C-A1C6-C80D5AEE7A8E",
    "22131.pdf": "24FD7D4D-F5B3-4FD7-94EE-AB304B338EFB",
    "22133.pdf": "5343D977-6B64-4FC7-8B43-791CCC62EA7D",
    "22134.pdf": "0182D106-4165-482B-8776-2297D371D17C",
    "22135.pdf": "CFE9C8AE-0726-4B3A-8581-75979001F008",
    "22137.pdf": "7A5FAD4C-3553-43C8-8791-937A29D7C324",
    "22138.pdf": "56FD7011-F06F-4899-9D6D-71759D0151BA",
    "22139.pdf": "4F0F5AD5-82F9-4F81-AF2D-5F383EA4F2F6",
    "2214_MEMO.PDF": "CFC9BFE1-0E3A-4EC4-B193-25862976FA1F",
    "22140.pdf": "BA7150FC-FBAD-401C-B7CB-9DB2CADC419C",
    "22141.pdf": "DE04333E-7F28-46F5-8219-AC374D5BC563",
    "22142.pdf": "7719B663-0170-4ED2-A0FA-DD6216C21AA7",
    "22143.pdf": "9BE56F3D-4CA3-4340-A88C-D3967CDA44A2",
    "22144.pdf": "6932C19A-BC9A-4E0E-ABDA-697929F32109",
    "22145.pdf": "293B42A5-0022-4D10-9144-80F384889B10",
    "22146.pdf": "A2A79CFB-2989-4683-A3EC-789ACD659CFD",
    "22148.pdf": "00DA0592-8C6D-4F0D-A6EF-9479AC13146D",
    "22149.pdf": "A18B2CA9-111C-4E78-9718-3DE3E9F257BD",
    "2215_MEMO.PDF": "BCD47DE3-97F8-4C1C-8342-20113F82CFB9",
    "22150.pdf": "D59A1660-D71D-44D4-82FB-A88250EAE761",
    "22151.pdf": "C2897734-13F7-4FAD-8C6E-8AA4574BAD64",
    "22152.pdf": "D295F8A2-7442-4F61-8F3A-1C6D5069FFC6",
    "22154.pdf": "514EA823-592F-4A30-8D4D-46AD0E8CFB12",
    "22156.pdf": "FC54A90E-3CFB-4AB7-A2BA-019FDEE145D9",
    "22157.pdf": "339E9885-731A-4634-90B3-CE0C27AB5AD9",
    "22159.pdf": "DBFED2EE-F3F3-44DB-BBD3-B74CC364DE1D",
    "2216_MEMO.PDF": "DFC568F8-376B-4A19-B4C7-5132A953FD33",
    "22160.pdf": "38AED383-65B5-4303-920A-84017B9B9318",
    "22161.pdf": "56535927-529D-4F34-93D6-0992E8E8F138",
    "22162.pdf": "B83667A8-5EA9-4B06-BC47-2003851911F4",
    "22163.pdf": "28430A2E-D531-44DD-B20F-0D030A3831ED",
    "22164.pdf": "9F233CA4-3A85-42F9-BCA4-F402385E572B",
    "22165.pdf": "BD36B4C9-C3DA-43D2-AADD-F02CEC9C135A",
    "22166.pdf": "A1362742-92A3-4FBC-8339-650381772634",
    "22167.pdf": "27FB3CFA-506A-4D63-988D-6932922FBD97",
    "22168.pdf": "997E840A-BAA0-4E80-B7FC-99E6784AAF09",
    "22170.pdf": "BE35CE3E-AABE-4E42-8993-DE0976546133",
    "22171.pdf": "114C4BBE-1136-416C-BFF7-BD9F0E1E9779",
    "22172.pdf": "675CF3F2-F93D-49C6-8F34-1B218AD82419",
    "22174.pdf": "B5810AA2-657D-41D8-AD5C-C134413C52A6",
    "22177.pdf": "3C50550E-BD21-4EA5-9145-39209D77A4DD",
    "22178.pdf": "45B562CB-1C88-45F6-8340-7BC0B978334A",
    "22179.pdf": "EDB63DF6-032A-4862-A7FC-A0DC98DCED87",
    "2218_MEMO.PDF": "688B72E3-B98D-43C4-9603-A2EDF729B58D",
    "22181.pdf": "AEEF42B0-EBBF-4EEF-B2C0-FB7E3DEAF5AD",
    "22182.pdf": "C717CF1D-26FC-4BE0-AE5E-D262A3EC4041",
    "22183.pdf": "11A6782F-DBAB-48A3-864D-BA57BFA2521F",
    "22184.pdf": "F30D48A2-BCE8-4ADA-A57B-CE0B4D632A47",
    "22187.pdf": "AAF80116-877B-4B41-A64F-230B56AA7506",
    "22188.pdf": "F48D9D7C-64C3-4166-9B71-3AC92B9A13A9",
    "22189.pdf": "3239AD26-B1E4-497E-8A97-58D5A276B493",
    "22190.pdf": "3F50AB25-1DF9-4D8E-A2D7-2DEAA7374B80",
    "22191.pdf": "68D3A905-2C15-46CB-A56C-0DECBA14FDD4",
    "22192.pdf": "20A32056-CD18-4829-9FA1-80E809DFD028",
    "22195.pdf": "0947A7CF-ECBD-47F7-87CA-3415B8572646",
    "22196.pdf": "126F391A-1B45-4BF8-BC5E-A10143CDB36D",
    "22197.pdf": "37E4F53D-6D86-45C5-B46B-F8A338CFDA4C",
    "22198.pdf": "61CDDABB-19B0-4950-98DD-6F4B048579AD",
    "22199.pdf": "B47338B1-E348-4B00-87E7-4775593A4946",
    "222_Letter_Rodrigo Moreira Faleiro.pdf": "7D290481-7AF0-4064-90C9-F96B0CF7CD21",
    "22201.pdf": "B90AE533-1B9A-41F7-970E-159D458DE199",
    "22205.pdf": "ED13B01D-4086-4A16-8ED1-00E8CE13DF3C",
    "22206.pdf": "1BEAA7A7-579C-47DB-BBD4-E874E27C214A",
    "22207.pdf": "5E832B0B-698F-4717-91C5-C7ECA40E417C",
    "22208.pdf": "5E41136C-3DCE-49E9-8915-742752CAB2CF",
    "22209.pdf": "7F2DD270-9681-4A2E-BD85-514B80F3058A",
    "2221 - Pennington et al.pdf": "F4B2F691-85BB-4A41-B9D6-832CEAAD7781",
    "22210.pdf": "2AB6070D-9D02-4685-B2B8-241AAE2368E0",
    "22211.pdf": "A7336A2C-3417-46A9-9EBF-CD9414667D0B",
    "22212.pdf": "0FAD3AEC-A501-4065-82E0-97181B255E77",
    "22213.pdf": "C70E0F09-70DF-481C-86B8-6D08DFFC07D9",
    "22216.pdf": "A26ABF29-EF3C-4650-A10E-58E18EBFBB8E",
    "22217.pdf": "62028DE0-C2D0-4CE2-9168-4A8681F87759",
    "22218.pdf": "7BD5379C-8DA7-4234-AEB4-0199B73635D2",
    "22219.pdf": "5FD80EA7-E3F1-47B0-AD44-D5FE59DA927C",
    "22220.pdf": "D4AADD71-3F8C-46E6-ABD9-B227CCD50866",
    "22221.pdf": "F486B7F4-C7C8-461A-AC0E-FC95E9C5CEE5",
    "22222.pdf": "8497504C-B395-42C2-9E97-F1679624AFD7",
    "22224.pdf": "02F97FBA-5C9F-4B20-B5CD-52E3889248ED",
    "22225.pdf": "92464393-5FBC-4DD9-BE23-3E89691D13EB",
    "22227.pdf": "DABBD0A2-9FE4-49AF-AF65-E8DF6931E972",
    "22228.pdf": "AA2FEA03-3B18-4643-98DA-3458E02B5D19",
    "22229.pdf": "49C45771-FA6A-4881-9F6F-0A349525D4A3",
    "22230.pdf": "97232A7F-CC23-4BE8-AB1D-1B25F69FCE06",
    "22231.pdf": "2F8CDA86-59B8-4B7D-BC80-06320BAFA5A3",
    "22233.pdf": "71E28D6D-1023-4023-B16F-E00F15B3FD09",
    "22235.pdf": "B88D76C3-CAEF-4D1B-8805-1E2261DE1B98",
    "22237.pdf": "A5BA1303-7B94-41EE-9018-62100400B2EB",
    "22238.pdf": "1103B7F5-2001-4E27-8778-D6776DA44AE9",
    "22239.pdf": "48BCFBB3-9B63-41D8-B67A-4F7A72907E1B",
    "22240.pdf": "29E280C0-1A50-4F5E-B4C2-677781077D4B",
    "22242.pdf": "BC25FEB9-C46A-49B0-988B-9068F4227897",
    "22244.pdf": "DE13CAFE-D93B-4FEB-A617-7FF1ECD8BD8C",
    "22245.pdf": "DB9518D2-0988-49AB-A72C-3E4F10F9D873",
    "22246.pdf": "55CB648F-B44D-4483-B653-A8FD8D99E79C",
    "22248.pdf": "B64C1C2A-B210-4B47-A262-8724445C805C",
    "22249.pdf": "21FC2C18-B136-4270-AB1F-64592B0B2653",
    "22250.pdf": "F26C8945-7082-4AB1-972D-073320A319CF",
    "22251.pdf": "674749EB-5468-4AB6-9E9F-4162E24F562F",
    "22252.pdf": "8C81A902-CD9F-4596-B9EE-C6EDB5C1E750",
    "22254.pdf": "637515E3-2066-42BE-B797-D6A7E7E3726C",
    "22255.pdf": "B78E047A-35AC-41EC-9824-562D1E2BA395",
    "22257.pdf": "E757ECFA-28E1-449F-8AA2-25D783601221",
    "22259.pdf": "3B6335A6-138B-4307-82F0-EB7B4AE783A0",
    "22261.pdf": "71F89510-F530-41A3-B69A-832DE8CA2B87",
    "22263.pdf": "5E243D1E-F43F-41B4-9069-B2653D233849",
    "22265.pdf": "0F709E63-BE2A-4F59-A231-D647DB3A4E25",
    "22267.pdf": "AE1E3C72-AE3C-49F9-9569-AD63D237E3C3",
    "22268.pdf": "ED2D7F81-E1C0-45F8-AFBD-12EA46703FE8",
    "22269.pdf": "64DD4775-76EA-4208-9E6F-FD43018FDAA1",
    "22270.pdf": "2772446D-9762-49F1-B71D-D26B540746EE",
    "22274.pdf": "7BE89B7F-3990-4A1B-9C2D-E826BEBE527F",
    "22276.pdf": "D7F2737A-709B-4C45-B59D-8038681FB1E5",
    "22277.pdf": "47F2605A-4DE4-45B9-AC10-15BE3D1200DB",
    "22279.pdf": "5319FF0C-E6AF-4B17-82AB-C17AF4B9356E",
    "2228_PHOTOGRAPH.PDF": "102B33D3-A853-4CB8-B69F-9E87CBA85066",
    "22280.pdf": "ADBC5D54-0B20-422B-80A1-EA9AB378527D",
    "22282.pdf": "59A0D96A-91A0-4F11-A909-190B1815890B",
    "22283.pdf": "713023DA-A0E3-4F6D-B4DC-AC19704C6F94",
    "22286.pdf": "9BE408A9-E9C6-4664-9857-0255D3D3B0A1",
    "22288.pdf": "EB83C94F-CE2B-4F4A-B363-61E39E178DBC",
    "22289.pdf": "067140DC-762E-449D-8F32-9D5DD1E07FDA",
    "2229_MEMO.PDF": "2AF217D3-F0D0-47EE-85C6-8EE36CA997B5",
    "22290.pdf": "F8DEB429-8610-4D0E-A756-EA373F83D97F",
    "22291.pdf": "D0713C2B-8100-436A-860D-5D1FF1D46C9B",
    "22293.pdf": "6B66272C-E05E-4AFA-95CD-46B1E6962734",
    "22294.pdf": "2A7125F7-5C96-47FC-A8C8-97A2E35E3B83",
    "22296.pdf": "66F8A266-778E-41F5-A12D-FDFF74897D40",
    "22297.pdf": "6F603166-2BD2-4900-A5AF-33BE54ACD398",
    "22298.pdf": "863CEF3B-CF58-4E42-AF28-F823AF13AF21",
    "22299.pdf": "B71B098C-F06D-4834-B939-8F6A133ECDD0",
    "2230_MEMO.PDF": "DE1D8867-AEF7-4EC9-9553-10BB52911BD6",
    "22302.pdf": "1D26A2DD-8EF7-4408-AB90-5A31F712AB13",
    "22303.pdf": "53BF313F-84F0-4A51-AB44-9BDBC1CAB165",
    "22304.pdf": "27CF9728-1AC4-40FE-BE9C-259B525BA929",
    "22305.pdf": "0457379B-3202-4072-9BC3-644004E072D6",
    "22306.pdf": "1200643A-DB4C-40ED-AFF3-D13EB77D34AD",
    "22307.pdf": "A99BF1D6-BA6F-457D-A63E-347A48CD5E3A",
    "22308.pdf": "D353D19C-2828-4E2C-9B91-1466BCB00412",
    "22309.pdf": "1EEAD9E9-E693-47A8-86BE-CB091208BA4C",
    "22311.pdf": "EF4A82D8-0B7E-4D84-9062-04171E5AFD6D",
    "22312.pdf": "D8A012B3-7551-4C2C-9C6E-982E40895972",
    "22313.pdf": "FD234768-942B-422A-B2F6-7BDF14492518",
    "22315.pdf": "7B83ECC3-D077-406C-AD8B-7A677DE0EEC6",
    "22316.pdf": "558927CA-6B28-48BF-9A73-801739152D06",
    "22317.pdf": "72906C7D-D500-4BA0-88B5-55E1CEFCD277",
    "22318.pdf": "FE8E7910-DAA8-4977-9891-D8B28648274C",
    "2232_MEMO.PDF": "09FD0D0B-0231-43B6-AA36-2F105D065693",
    "22321.pdf": "3E4D6221-36CD-4F42-918D-085925AAD7ED",
    "22323.pdf": "282D8192-8D56-47E7-8F20-3C32AB970F3F",
    "22324.pdf": "C3623470-1856-4290-97CD-BE79DCB75547",
    "22327.pdf": "7125B409-4CAF-4593-80C1-54788A8BA94D",
    "22328.pdf": "4ADBD372-183F-4AC5-B9D2-3DD712B2732E",
    "22329.pdf": "F78C73FA-9AF1-4C58-8D1F-3FF082FC162D",
    "2233_PHOTOGRAPH.PDF": "6218035E-B57E-4806-96A1-CCA40AA6C513",
    "22331.pdf": "AFA2C4D8-AF0F-4B98-851C-831A81E18FAB",
    "22332.pdf": "4FDC270E-A33F-446A-86E1-09D1475DAF28",
    "22334.pdf": "BF0DB4BF-2B6C-4E14-A2C7-FA1B52462892",
    "22335.pdf": "3C67D39C-9DFB-4A9A-986E-24991AC737BB",
    "22337.pdf": "2653F82D-C85F-464A-98DE-BA84093032A4",
    "22338.pdf": "84DD9167-0838-49D4-96F5-497373176082",
    "2234_MEMO.PDF": "2B490923-3481-423F-969B-943BF4FA191B",
    "22342.pdf": "1D040AFB-3944-4B87-B721-FCA3E8595E42",
    "22343.pdf": "57F5F0B9-944D-4949-851D-B4462F3C4E8F",
    "22344.pdf": "4EE6B035-858C-4FDF-97E6-88853B4C4B6D",
    "22347.pdf": "52F5598C-F0F2-4532-BF2B-2255A3AB7F8A",
    "22348.pdf": "A39C3837-7891-474B-A89D-49CD347D4AFA",
    "22349.pdf": "14F5BA03-8F59-460F-9DF8-F80E359F2829",
    "2235_BOOK.PDF": "7CBA63A3-204C-4787-B513-0154BE1F4435",
    "22351.pdf": "123A0A90-EF80-4B5B-8EA2-856CFF84BAF4",
    "22352.pdf": "03273FBF-9AEA-4B79-BEA6-5AE33DB4AD93",
    "22353.pdf": "34958731-8F06-4587-BC21-23C6EAAE0654",
    "22354.pdf": "6A4F8A99-31D9-4EDD-BCE2-EBC1E4ED2026",
    "22356.pdf": "75701774-3880-440B-8A3B-AAA87B1FC443",
    "22358.pdf": "1390AAFC-E032-4348-AD61-01FBBB846917",
    "22360.pdf": "595B5810-1A6E-4AD7-828F-39E4A1DA18DA",
    "22361.pdf": "A777557C-8290-4A6E-9B97-E689A1B03AF9",
    "22362.pdf": "B37AE3BB-AD35-47EA-9F24-833FE8BF4A45",
    "22363.pdf": "73EF43EE-CAB2-44D9-8AFE-49361D0D9A5E",
    "22364.pdf": "4CFF9886-DB50-46FA-8C5B-DB6D9B7DD105",
    "22365.pdf": "9CCC4CBC-1A58-4A8E-AC40-EA7AA1F1EB39",
    "22366.pdf": "0BCD4DFD-20F4-4BC1-9448-E89A9B44BE6A",
    "22368.pdf": "063680A1-7987-48A9-8083-EADB0A666305",
    "22370.pdf": "62D7A79D-A719-4B71-A341-8C29D3982DA1",
    "22371.pdf": "AC32F9D2-D5B1-4A32-9CE1-0DD811829BC5",
    "22372.pdf": "391F0F58-AD66-4898-B5A4-A8FD4AC525A9",
    "22373.pdf": "7B7BAB3E-4A25-4038-B2A6-D43A9B758721",
    "22374.pdf": "06620C02-E86A-4605-AA3F-800ABE9ED632",
    "22375.pdf": "3709A26F-5E71-4384-8295-4790074B5441",
    "22376.pdf": "D228F1A9-D7B9-4236-B0F2-11138CCC7C5B",
    "22377.pdf": "253FAB96-EEF8-4841-A090-E7B267CD81AE",
    "22378.pdf": "15B21F9C-AFE1-4E8C-ACEE-197E071DAC92",
    "22379.pdf": "A8245AB7-3DF7-476F-8A85-D2214E10CA30",
    "2238_MEMO.PDF": "9B3509B1-0546-41BD-BABA-3AE552FBD67D",
    "22380.pdf": "77604A0B-082D-40DA-9A8F-9866ED4548B9",
    "22382.pdf": "8C1F7FE4-9677-4446-AD1C-C76554956A11",
    "22383.pdf": "C59A50FE-87AF-4080-9787-7B329652098C",
    "22385.pdf": "8702F6BE-BDE4-4526-82B1-184A559FA413",
    "22388.pdf": "3592209F-1503-4843-B14B-5106464E9B22",
    "2239_MEMO.PDF": "4DDDE3F7-FF1F-4B94-8FDD-E879F4C20206",
    "22392.pdf": "F013B366-8A6A-430F-9245-CE0ED8202E14",
    "22393.pdf": "71C1CBAA-BAED-457F-BEF2-7AABFF8FA82C",
    "22395.pdf": "355D42F1-4443-44BC-937B-2B44A556C299",
    "22397.pdf": "8D28C148-6F75-4DDD-9BE3-A4ABC8EE0657",
    "22398.pdf": "C7C11F1B-2E13-4A7D-B67C-F5412D426FCE",
    "22402.pdf": "A9647E7D-749C-4FBE-A65C-A8C5E0B1F7BA",
    "22404.pdf": "B2FD548E-2117-4811-9E18-29352A55D060",
    "22405.pdf": "82D3DFB8-EABA-4D4F-9B69-80E59B5DF6D5",
    "22406.pdf": "4C69D298-7FC2-451D-88EB-33BD2FF00ADC",
    "22407.pdf": "A7B2A72E-3D1B-4226-8D9E-B423F1F06EEA",
    "22408.pdf": "735C0B19-E8DE-43C8-B902-56B152572940",
    "2241 CNS 2019 Presentation .pdf": "2F540CBF-93F9-4C03-9E73-3241AEE2A4BD",
    "22410.pdf": "F9BEAC2C-B906-40A6-B958-2E526878EE35",
    "22411.pdf": "9BF657D3-2BC1-4B95-A04C-6B4876919587",
    "22415.pdf": "41F8787F-F588-4D2D-87CA-4073B4602291",
    "22416.pdf": "71987376-9278-44B9-B137-94DC6026C2DD",
    "22418.pdf": "FF249FC9-E931-44D4-9AD9-F7B30DBBF5AB",
    "22419.pdf": "0219AE40-C192-4698-8A1E-368969C14040",
    "2242_PROGRAM.PDF": "A8EEB5CC-0303-40A7-B385-3ACB39AAC4DC",
    "22420.pdf": "A88D3741-A708-4BDD-A423-0B65E9E1410F",
    "22422.pdf": "58141151-6B3B-4125-A9B6-33E693EA3345",
    "22423.pdf": "A205315B-EDC0-4546-917E-6B3840A631D7",
    "22424.pdf": "1D11D7D4-55E4-48AD-9AEE-96FF6868072E",
    "22425.pdf": "C04277A0-5E27-48D2-B177-A9B43C9A8771",
    "22426.pdf": "D3A393EC-37E1-4465-B86E-708A1D418BFC",
    "22427.pdf": "735F1360-6187-4D26-ADDA-6185A52CDAB1",
    "22428.pdf": "FDAC6A9A-F086-403B-8F67-AC888812ACBB",
    "22429.pdf": "489E5406-EAE6-46BD-89C1-403B85991F63",
    "22430.pdf": "57321F9F-C8DC-4BC1-836A-383236CF3F52",
    "22432.pdf": "A30E9E1B-3DD4-4361-B21B-E915572CD37E",
    "22437.pdf": "3A3B3E77-E835-4BB9-9CDA-F170002F039C",
    "22438.pdf": "75492FAD-4809-4190-912E-0CF141206FE0",
    "22439.pdf": "02385753-C41F-4E69-B61F-361AB0B65588",
    "22440.pdf": "221DA8F1-9EE5-4D19-B146-581C57BA4A78",
    "22442.pdf": "20465047-77BA-4F28-9865-F16907545BE5",
    "22443.pdf": "02EE8620-7ECC-4C48-A2E9-7BECE9002C75",
    "22445.pdf": "6819B3DB-3757-4F59-9B79-CDC2622916B5",
    "22446.pdf": "E8AF2FE1-7AD2-4D19-A15D-49D1B951E4EF",
    "22447.pdf": "8AD2586C-9D45-4D59-8250-D72A231299E0",
    "22448.pdf": "F2BC8000-B708-434F-A9DF-9EAFBD57DA2A",
    "22449.pdf": "4BEE84CB-2395-4BD0-BC2D-A353B80625B4",
    "22451.pdf": "4EE80763-39EC-4381-9469-E335994C48C1",
    "22452.pdf": "D9C864CB-9596-4CAD-A3CE-90DF2BEA6E28",
    "22453.pdf": "A00B6113-A359-47FA-868A-E52CBAA3F2BA",
    "22455.pdf": "DB492A44-C0DA-46A7-905B-C7B926BCCA19",
    "22456.pdf": "0A308B6E-A702-4D11-AE4A-C2A07DA63420",
    "22457.pdf": "1A25397A-41D0-4B4A-BB40-48E6387D9E47",
    "2246 CNS-Poster-Dr. Santiago Valenzuela (2).pdf": "3D6FD2D3-9BC2-4EF7-8BE9-D5CDD6972755",
    "22460.pdf": "602F00F0-201C-4407-A8CD-CDEB2DE201B6",
    "22463.pdf": "65206599-7E50-4BD3-B25D-F0882F69A853",
    "22464.pdf": "4D017161-2C2E-4902-BD9E-D2701D3A66C8",
    "22466.pdf": "E521DAC5-EB44-46A3-A6BA-F6B18AA7F948",
    "22468.pdf": "55D08D1D-A09A-4489-B6E4-6953CEE37CA0",
    "22470.pdf": "AC1BAB34-6FFE-4F43-B790-826A46416E4F",
    "22471.pdf": "04FF4E59-604C-4E47-A424-30813C895E67",
    "22472.pdf": "E0E55FAE-2AF2-4D43-894D-AEB19FF7A385",
    "22475.pdf": "1BF4BD27-DEC9-4253-9DB6-B6DDE73F6FDF",
    "22476.pdf": "5A5E1B37-FAAF-425C-94B4-B74D00DC6C95",
    "22477.pdf": "117DC061-067B-415A-8672-36A7BD1EC0A8",
    "22478.pdf": "D83604B8-3B92-448B-8355-A1819C681255",
    "22479.pdf": "7063751C-FAE9-4743-8C1A-6C7ECDC190FE",
    "22481.pdf": "29272E2B-7569-4C68-A9BC-C37CA1C66D1E",
    "22482.pdf": "E319ED84-3224-41C8-88C1-20A1509BFBAB",
    "22483.pdf": "30ECDC46-52ED-429D-8DDE-1159B127B3C8",
    "22484.pdf": "AD10AE61-5DBF-4766-9D01-E85FE562AC61",
    "22485.pdf": "5AF6529F-D956-4779-8721-741E59B5D9AF",
    "22487.pdf": "83CBFF03-CC8F-4D60-BEE2-72ADFBF34EB0",
    "22488.pdf": "38A16005-15AE-4D70-BBD9-5406AB082AE7",
    "22490.pdf": "B2D6316C-9796-434E-B43A-E68DAFA39CA6",
    "22491.pdf": "1F070F87-9112-45FF-A252-5B2964FA8726",
    "22493.pdf": "7D36BE64-3C96-43A4-8418-0AB5C636CCAF",
    "22494.pdf": "17B21CB2-7D3E-413B-82F1-C760CC37788A",
    "22495.pdf": "9056AD49-3E70-412E-93E6-5612A573EBB2",
    "22497.pdf": "7476083B-500C-4948-AD78-148070B78866",
    "22498.pdf": "0FC88E5B-5251-41B4-8EA2-65B970AB0938",
    "22500.pdf": "8B528957-4437-469E-A2BB-ADE253C7D4D5",
    "22502.pdf": "3FCAD738-6201-445E-85B3-B0A0D5BAADEA",
    "22503.pdf": "D04015A0-99F2-45C6-891C-D1728E7E701A",
    "22504.pdf": "3159B619-1080-4C4B-8E2A-D44BE3D20BF2",
    "22505.pdf": "62357160-3284-40AE-A859-93479DC315B2",
    "22506.pdf": "BD46A10C-17ED-4BD2-9DEB-9E673083FDB5",
    "22507.pdf": "16299FAA-125F-4AC4-8432-268360360DE1",
    "22508.pdf": "16473A63-34FF-4456-86B6-23A182209303",
    "22509.pdf": "DE7CC4BD-F7DF-450B-B108-644434B984E2",
    "22512.pdf": "FFCECB13-A2C2-4804-A384-645292CD4C7A",
    "22514.pdf": "2263574F-54C9-44DB-893A-FF67DC7EB1A2",
    "22515.pdf": "ECF86D95-A0A5-4343-B52A-782D3A10CEC8",
    "22516.pdf": "AA45E14D-D10C-436B-BDA1-10DB3336D3A9",
    "22517.pdf": "4813194E-097D-4FD2-AFE5-169C19B7B43E",
    "22518.pdf": "03AED8A9-7020-4896-A3C9-47AD9CAB53C6",
    "22519.pdf": "6D7EA098-770C-4C2E-9DBC-83D04D35F8EA",
    "22521.pdf": "E5E919C0-B296-44F4-AAA7-701B395D441C",
    "22522.pdf": "6164115F-3F2A-4940-8EBC-B0B927E06477",
    "22525.pdf": "91F457D1-FB2D-47ED-A7A5-B68C887CFB59",
    "22526.pdf": "A7F79C34-E5CA-41E5-95E2-AF623551511C",
    "22527.pdf": "708A3DEB-B518-470E-B39D-E6695FE13BDA",
    "22528.pdf": "820F1602-260D-4A81-A36C-BA87A3C961C3",
    "22529.pdf": "26219FB0-6BC9-47C7-A2AC-E2F8F198D2DB",
    "22530.pdf": "D76FBD16-4FB0-43EA-B65B-A9EA0ACDE0E4",
    "22531.pdf": "49830424-E7E2-456E-9955-E7982FDDC962",
    "22532.pdf": "A92BE6EE-F807-4FB5-B679-F2E71BFA5DE0",
    "22533.pdf": "FC71B2F2-4D5C-47EF-BA67-72FD4425B512",
    "22534.pdf": "2E1B7BD5-79BE-4C7F-B7D0-0AEB2B85164C",
    "22536.pdf": "6F08A8F0-8498-409E-820E-2E6301143679",
    "22538.pdf": "D50F0806-FC3F-4638-94AC-AEF54DA98993",
    "22539.pdf": "E286A957-7944-436B-A01C-4923A250F173",
    "2254_BOOK.PDF": "562E7B3A-CAA3-4064-B9D4-B2FF2FED1F18",
    "22541.pdf": "D28215D1-4C82-43D3-86AC-065662FF7AC5",
    "22544.pdf": "43E0CB8B-D7FC-466D-8CE8-EB8A7E8B9F70",
    "22545.pdf": "9D4D50F1-FA7C-4059-99D2-00033965DA0C",
    "22546.pdf": "D80D7155-3DAD-4B26-B9CB-479A5969EF22",
    "22547.pdf": "01093CEC-CA7D-4929-B50F-4145E67C79A4",
    "22548.pdf": "80A20B10-7391-41A3-A543-9B92F07E25F1",
    "22550.pdf": "D02E2777-CA74-4009-A4B9-53DAA97B70F4",
    "22552.pdf": "F880CBBB-0EED-4892-8951-6D7BC814FE73",
    "22553.pdf": "7C864482-D6AA-4C48-9ADF-83A43C23BC8F",
    "22554.pdf": "1AE040B8-0289-4A8E-A74C-BCD181924CA7",
    "22555.pdf": "976C42B4-F1AD-44EC-89EA-BD55C7D7BAC0",
    "22556.pdf": "8D4A1182-AC6B-4024-AF27-7AA984AF57E5",
    "22557.pdf": "220F039E-0B74-4B2D-9378-D7D5AB9D5180",
    "22559.pdf": "FE3BDD8C-8721-4D28-951B-6DCA0C7BC04C",
    "22562.pdf": "CE1CF040-A075-47C6-8EA7-3291F078C681",
    "22563.pdf": "D8FE08F5-A07D-45B2-8F02-9941DEA3EB05",
    "22564.pdf": "3CFECDA4-530B-43C4-8B1D-A8ADD209FD0B",
    "22566.pdf": "964F8756-D715-433E-B7D2-6E73E1D13BE3",
    "22567.pdf": "92149385-9910-423E-865A-72AF67C61E7F",
    "22568.pdf": "0EA3E046-5B0A-4C78-AE71-F3767891C40D",
    "22569.pdf": "9EC4B23C-5A6B-4BF0-A140-5A15DBBA7850",
    "22570.pdf": "0FCA5D63-B34D-453E-A8E5-655A80AC67B7",
    "22572.pdf": "E3E1183D-385B-4A68-B3E9-12CAEDCCAC26",
    "22573.pdf": "9CEA93AB-85F4-4CC3-9E42-36E86D9C4347",
    "22574.pdf": "D6B1C92D-2C68-4265-8008-B65A79B1A166",
    "22575.pdf": "14D56C25-FF56-4D05-B959-47AF0BF9C2C1",
    "22577.pdf": "61E8AF94-C5DC-459B-A6E4-122D05A4148C",
    "22579.pdf": "211B9E47-BD49-4920-81A8-95DF6E974C85",
    "22580.pdf": "74CDB272-8BCC-4A11-AEF6-6BE48AEBB27F",
    "22582.pdf": "3BC4DB41-39E1-4FDF-8A96-0809AD2092AA",
    "22583.pdf": "9C704243-D15E-4D80-B514-0E8795B24B9F",
    "22584.pdf": "1456CA8A-D280-4C3F-AF9B-311EEC55D118",
    "22586.pdf": "010D0508-578F-43E0-A7E1-68BE8EAB3BCE",
    "22587.pdf": "BB45FFE0-8A50-4835-BCE1-CCFD258A6383",
    "22590.pdf": "62DC5A25-C757-4A81-B494-28F5941ABC3D",
    "22593.pdf": "D340A6F0-483F-4C4F-A0D5-E66F62A23A83",
    "22594.pdf": "2B9B79A0-9672-45F7-9C22-F5073DD0C5D7",
    "22596.pdf": "9304F196-D91C-4326-9DE4-4B14BE0A79C1",
    "22598.pdf": "465EBF22-6B63-4177-9943-92C6DA966D8F",
    "22601.pdf": "C838126E-9A89-4602-BACA-9D1F7BAC6309",
    "22602.pdf": "6120475F-4A2B-4CFF-8AE0-049677A9A788",
    "22603.pdf": "DB1DF709-9870-4F6E-9B88-D151AFCCD22D",
    "22604.pdf": "4FE62CAD-E4CF-4D1C-B759-56DB46B782C0",
    "22605.pdf": "23FA88E5-0A7D-4F4D-9BEE-A48DDDD5CCB2",
    "22606.pdf": "F8876FDD-D203-4B62-BD57-76CAF65C8F9E",
    "22607.pdf": "2557E11B-366C-40E0-8807-5FBDF5ADA3F5",
    "22608.pdf": "80EC89DD-CDCD-408D-8AA5-2E7A93AA2666",
    "22609.pdf": "A08391F5-33C3-40D5-9E27-EDB95B69640C",
    "22610.pdf": "B7B4C40F-F315-4EE8-AB55-A731841F1D3D",
    "22611.pdf": "E1D36154-EADB-47FE-837A-59E5BB402F3D",
    "22612.pdf": "04DC03CA-1FD3-4A49-8E5E-3BE717CB314E",
    "22613.pdf": "A7ABAF19-4F96-4FC2-8DA4-7E9091B5657B",
    "22614.pdf": "F34A8C35-F512-436A-95B5-26420B661936",
    "22615.pdf": "A5C66B6E-A9A2-4138-8A54-5182632946FB",
    "22616.pdf": "4284C0E6-7573-452D-B2C3-73C053415875",
    "22618.pdf": "171B7E84-0CB9-4731-A86A-0EC9F81BE3B6",
    "22619.pdf": "86B8E71F-34AB-4A73-8E08-7C2FC941A1D9",
    "22620.pdf": "559C2ACA-D993-49F3-BACF-E5C1E26D1CDE",
    "22621.pdf": "F3341B64-3FD1-4BA2-A397-982DFEB2E275",
    "22622.pdf": "654B1271-13A7-4414-9B0D-7276C4F519D5",
    "22623.pdf": "D7662F02-E276-4E5B-84C0-A9E956F8B712",
    "22624.pdf": "468E7267-6913-44B4-B67F-98E717A4630A",
    "22625.pdf": "0BDDA96B-59AC-47DD-B974-C42AC79CB191",
    "22626.pdf": "5ABAEBD7-D937-49AD-881D-C8A005374842",
    "22627.pdf": "BF6EDF73-9ECE-4BE0-95F4-CD920E466387",
    "22628.pdf": "0E082730-4D48-4183-A907-EBAF91E369D1",
    "22629.pdf": "0AEF760A-662F-4A82-AC92-DEBA752A3D12",
    "22630.pdf": "57454464-7028-4F5B-8EAD-D4BFCA09FEEC",
    "22631.pdf": "1F17659A-D166-4745-9FF7-9E8C3A8F4945",
    "22632.pdf": "C5DBB2E5-F8EF-4F0C-A3D9-727ADBC16C3E",
    "22633.pdf": "AB1698D2-F4C8-4A62-AA57-9322A94EA84F",
    "22635.pdf": "866054C0-DCAA-4A43-ACE0-DDA6B8E7644D",
    "22636.pdf": "71DA60E3-A14B-4DF9-8DAF-21CAF61C7C61",
    "22638.pdf": "C62AE25D-4E2F-4365-A9ED-2BC32E910AE7",
    "22639.pdf": "1F177739-3E01-4C6F-92F9-8CBD86465251",
    "22640.pdf": "61954BDA-33D3-4153-A722-46B7DC7B0C7C",
    "22641.pdf": "01365670-C806-4A54-B7CE-E333ECD9672A",
    "22643.pdf": "0DE6C7C7-F0D5-4BF1-BDCE-E54D5B51B486",
    "22644.pdf": "5777C8C3-3BB6-4955-A301-0CCC8DC989E9",
    "22645.pdf": "B4F6EF76-C538-42CD-B943-3222A1D9AC2F",
    "22646.pdf": "BE170726-9E0E-45CB-8A1A-E3FD214B5D41",
    "22647.pdf": "CD164B8F-7931-464C-9690-650F6E2BF7FA",
    "22648.pdf": "4ADA5EDA-24D6-46FA-82E9-051A6963F5D2",
    "22649.pdf": "3FA32993-7480-4054-A6EB-D50ED455CD34",
    "22650.pdf": "3626D3D5-5CFC-4C7F-9B01-47CC9E1BF79A",
    "22651.pdf": "CE26D49F-57B8-4E0E-B809-36E379CB8F2C",
    "22652.pdf": "F8C46F08-D594-411C-BC5A-85AB96786780",
    "22653.pdf": "0DABD27C-38F7-4FF0-932E-5DF6C07F03A9",
    "22654.pdf": "7F4DE653-8C14-4403-9E43-2937DC1A062F",
    "22655.pdf": "ACC9509B-88AC-46E5-9F63-78D380FC93A2",
    "22656.pdf": "762733C1-6A2A-4696-9B46-31B7B871303C",
    "22657.pdf": "E72CCCD4-B3A2-4624-96FC-41FEB60F3D1A",
    "22658.pdf": "072C0F60-105A-4827-9D94-EB5C8286421B",
    "22659.pdf": "1F8F1B9F-3B53-4E57-885D-E317E8BAA46A",
    "22660.pdf": "074B111F-5A92-41CE-A356-238A6CFD46EF",
    "22661.pdf": "96230B06-AE39-4303-A93D-27AB1107540B",
    "22662.pdf": "7EDE942A-68DF-437B-8A84-B3779B0A489D",
    "22663.pdf": "C8B4B742-2D3D-4262-A9F6-13DAE2B4B79D",
    "22664.pdf": "48E122EA-13D9-4366-96F1-D42530C425EB",
    "22665.pdf": "AE3DD058-8917-4C9E-BBC7-D3ACE8488437",
    "22666.pdf": "11EBA7EC-1D17-4C6F-B284-75EB7DC748CF",
    "22667.pdf": "352A941C-1527-4B78-9801-16EFC141A9AB",
    "22668.pdf": "21ABEA69-FABF-4AD5-806B-E837B28EE95D",
    "22669.pdf": "2F99DE28-546C-4A44-B520-539CD49581DF",
    "22670.pdf": "8924E98F-CBD1-4E25-AD2C-0BDDA0741B11",
    "22671.pdf": "AF3C51A4-7895-44AD-868B-81F4E77BEA65",
    "22672.pdf": "131F4FFD-0D5A-455D-91B3-2EBA0CE568D2",
    "22673.pdf": "31C37159-9CAC-48CA-A6B8-DFD9F03D5933",
    "22675.pdf": "46C2888E-C813-47CD-A100-DDD3252259A4",
    "22676.pdf": "E76218B0-5F5C-4377-BE89-8DA21CFA0DCB",
    "22677.pdf": "29A6FF9C-BC9E-4309-8309-0B8CC04C115F",
    "22678.pdf": "27AE0DB7-4D2A-4D88-A007-816058C72FD4",
    "22679.pdf": "CCDDE8FE-6CCA-43EB-B67D-424F9EF3C30B",
    "22680.pdf": "DFBDECD3-CA11-4E25-80F9-815E4BD43E33",
    "22681.pdf": "2B723302-2E73-4E1A-A49B-DC76751EF130",
    "22682.pdf": "9D2A73B9-D652-4246-BC79-1F1697B9529D",
    "22683.pdf": "C0E33733-A4AE-4BCD-B7AB-4579E74D55D7",
    "22684.pdf": "4222111D-0BBF-4F82-98D4-E0EA405BB992",
    "22685.pdf": "F8CFC73D-BAC2-4804-8511-637009DCE150",
    "22686.pdf": "E53A58C0-79BE-4EDE-A074-03A65F2AC0D7",
    "22687.pdf": "3558D039-CBB8-4467-AEB6-1FED4A155802",
    "22689.pdf": "B3E6CDAD-4554-4F27-93AB-2F9C0A383184",
    "22691.pdf": "4EF829C8-DE56-4708-A800-DA61224E095E",
    "22692.pdf": "B911A6CE-78B0-4B2F-89C2-E347EF3F1CCC",
    "22693.pdf": "C1205485-DC90-4C73-A1F2-AC638D194C3A",
    "22694.pdf": "29915C79-7639-4D38-B3EC-DCF9D0BF7409",
    "22696.pdf": "7D978362-65CE-4378-A8A4-4F420646CBD5",
    "22697.pdf": "CA685185-99B0-424D-9142-AEF8C01A27E1",
    "22698.pdf": "28A0132D-D5BD-46DF-818F-B5A20DDF1988",
    "22700.pdf": "08D7D175-AD36-4E31-8F74-C71F8E19A027",
    "22701.pdf": "E30859DA-235E-42A2-A81C-48FD0B4B17FB",
    "22704.pdf": "AE7F7935-3B5C-4D0C-9073-8228B91A0268",
    "22706.pdf": "6E6F03A7-6035-4791-8CD0-BFA2D45FF857",
    "22707.pdf": "7D7901F2-4D86-4924-B759-B4B0C4D3E44E",
    "22709.pdf": "85237AD5-27D4-4589-9C66-1A865D379A07",
    "22710.pdf": "13D4BBA7-DCF0-40B1-B770-009DD3D313C9",
    "22711.pdf": "3611EFB4-E0A5-4AE0-9FF7-125E449D83A1",
    "22712.pdf": "63FEE0CF-95E6-49BF-AFF1-93EB9F5C9EF6",
    "22713.pdf": "411FF768-CD8C-402E-903C-24D8590DEE8C",
    "22714.pdf": "B95E3A2D-E6A5-4416-A955-DFA70860B863",
    "22715.pdf": "C17C79A4-DFA7-447F-BF1D-8DC4F299DF8A",
    "22718.pdf": "A8FBD18D-0967-4899-885A-07701A487C07",
    "22721.pdf": "E2A69332-E9C8-467B-A7D3-954821C31642",
    "22722.pdf": "5D3A8FE1-8944-46B6-94C8-C5637E882BC3",
    "22723.pdf": "E2F21A38-8A06-41AB-8283-B856C11BCE36",
    "22724.pdf": "DDB32D69-0C19-4633-972A-4B1BAD93DEEB",
    "22726.pdf": "41EB4C47-8C43-422F-AB76-9E952BFADABF",
    "22727.pdf": "98F1A493-6AD1-40D2-B35C-CD62AB262247",
    "22730.pdf": "CE78DAE4-DA95-4A79-BB2B-98F0B509B90B",
    "22732.pdf": "6B7AF35F-87D4-41CD-B3A6-0F648ADA5A46",
    "22735.pdf": "DA5911B4-C348-4B37-BC6D-4E2BD2AFC37A",
    "22736.pdf": "DD6EB2A3-2388-417A-86DE-2FB39F99673B",
    "22738.pdf": "259A2756-2B60-47AB-B777-41BFEEDD5BE9",
    "22739.pdf": "A6865388-21AF-4356-ABC6-3DC037452401",
    "22741.pdf": "3C05DD63-AE3C-4892-9700-26A73466A2EF",
    "22742.pdf": "35F446CB-4E46-4627-A411-38B3D3CFC0AC",
    "22744.pdf": "97740316-4D1B-4152-B1F3-0C3598BDBAF9",
    "22745.pdf": "6C4ACBA1-78DC-4D39-A715-F1378D7B0119",
    "22746.pdf": "7D678B44-9CFC-492F-A091-7F6F8B7F6949",
    "22748.pdf": "05EE8F75-B49C-41F5-BD44-9582853CEF32",
    "22751.pdf": "BC9FA2D2-F01C-46D1-B670-A325C891B614",
    "22752.pdf": "3F890837-162F-49D8-8239-DD453A305206",
    "22753.pdf": "C51E00DD-B3BA-4899-909F-D6F4952FF343",
    "22755.pdf": "5DE2A6DC-F3BB-4250-8CA4-806AC7C127A8",
    "22756.pdf": "5C84ABBC-B615-456C-8EBE-6A7559686D0E",
    "22757.pdf": "E1F76568-DD59-4434-A64B-486BFCE3A9E3",
    "22758.pdf": "68811B54-BF7C-47E6-B2DB-6C75844E192C",
    "2276_001.pdf": "F6239317-1A67-4B9B-88FE-3777DA4A4489",
    "22760.pdf": "DF047B2B-7E24-412A-9FEF-75B993D103FE",
    "22763.pdf": "85D594E3-24E3-469B-A177-B0EF75686968",
    "22765.pdf": "AB6697AA-9008-4438-8CB6-CB8580F1B0CA",
    "22766.pdf": "10333B3B-C3DB-49D6-9143-4CE8CEF8186B",
    "22768.pdf": "173FA699-5CD6-47C3-ADF8-234BC559CCED",
    "22771.pdf": "47A787D8-6778-4ECC-AF4E-BC6750DD861F",
    "22772.pdf": "6F477723-5F5D-4594-856F-C96C6004FBEE",
    "22773.pdf": "304D1CC5-BF4B-4A82-83F2-3EF33A9705ED",
    "22774.pdf": "DB47AF16-E7DC-4E59-83B0-ADB33BF71526",
    "22777.pdf": "C6C6B0C2-211C-4EC7-9BB7-C4EAEDFFC88E",
    "22779.pdf": "F0241AC8-C1C4-4879-9025-8A30359BF45B",
    "22781.pdf": "5568DE67-2DDF-4CB5-9A0F-C287ECFEC230",
    "22782.pdf": "E0C5BF7A-8611-4814-990B-E3A34236B967",
    "22783.pdf": "4036E78D-FF5C-44F0-94D4-BFAE37217A75",
    "22784.pdf": "9465DDF1-6EA5-4F82-A480-8292A5FCACEB",
    "22785.pdf": "2AA2D852-AED8-47E0-8D59-E4DECC03272E",
    "22786.pdf": "26476DEB-60C5-4782-8714-BADC872C7D72",
    "22787.pdf": "5EEB95BD-A950-4E73-9681-9E7618A99088",
    "22788.pdf": "92E90A28-9380-4BC3-BF13-23112C6772B3",
    "22789.pdf": "F73803D9-0F97-4F1E-A148-B991B92281EB",
    "22790.pdf": "15AFD24A-4CFE-4CEE-AE04-89E919206157",
    "22791.pdf": "D59BBBD0-0A3E-436D-83EC-9AA7B84E23BA",
    "22794.pdf": "99749DCA-E8B9-4693-B1B8-A9F75547F835",
    "22795.pdf": "B0F4C497-5678-42C3-BE42-1B049148F554",
    "22796.pdf": "81C844B7-7574-485D-AECC-233F6B75298A",
    "22797.pdf": "6DE75363-B65E-42AD-BEFB-092B9A11EFE0",
    "22798.pdf": "84CDFC0B-1F5F-4C98-9CD5-C655540BFD9C",
    "22802.pdf": "DE3448C8-92D3-4A1C-BD77-D9E0D3FEFA3C",
    "22803.pdf": "70D83DE6-1589-478E-845E-3E4F4A0808EF",
    "22804.pdf": "DC3EE50F-3E73-45B9-A47F-39D5A842BDDF",
    "22805.pdf": "6CC9B509-62C9-464D-B01A-BE0FE35EE15C",
    "22807.pdf": "B671C03B-ABCB-4A51-9F46-B8E554458CBF",
    "22808.pdf": "9E811DDA-57BC-4C5C-914F-71FB34F493DF",
    "22809.pdf": "CC5F7644-DFF3-48BD-8819-57D26C467FA6",
    "22810.pdf": "0A84582D-D3C6-437B-914F-596AABC82836",
    "22815.pdf": "2F61170F-1D20-4E25-9E01-CCBCF3FB2D89",
    "22816.pdf": "3222C200-C61B-4B9F-AB4B-B7C8FAA462D9",
    "22817.pdf": "5AC6694E-BAF2-4E5B-A1A5-4C6F15D5AEC6",
    "22818.pdf": "8827EA83-79BD-4FDC-AB3D-7EAB1018522A",
    "22819.pdf": "2D098EC3-93D7-4BAB-9CDE-707EFE46E402",
    "22820.pdf": "68478B5F-335E-4286-B619-2C66B6519DBD",
    "22823.pdf": "91C02768-501B-40A2-9272-EB1C86CC0844",
    "22825.pdf": "48BAD720-0A04-475E-A2D9-E6BFF99C323E",
    "22826.pdf": "30310B19-FA2B-4435-877E-471B5232626E",
    "22827.pdf": "6709E041-E10F-4276-A95B-19A193F127F0",
    "22828.pdf": "0A551F25-F0FE-491D-BFCA-1C5EBF682C66",
    "22831.pdf": "5C8FFE8A-2103-4786-A4B5-0EB394A8635E",
    "22832.pdf": "67E4EB8F-D7F0-4E6F-87B0-6D881C26331E",
    "22833.pdf": "E24F0C8A-DC7C-4E20-B3B1-E4C201CF90F2",
    "22834.pdf": "5493D365-A143-4A47-ABDB-97327CF362F5",
    "22837.pdf": "2F764BC3-6DDD-412D-A046-7781911C6C95",
    "22843.pdf": "558F84B2-52AA-46C7-BA87-4F090FA2ED78",
    "22844.pdf": "D1952114-B669-4A08-96ED-E42CBD22BEF1",
    "22846.pdf": "F3AF3B1E-4DFC-45D2-B8F4-D90B8DE11590",
    "22847.pdf": "1695EC17-044C-4631-9CE6-B736DE07374C",
    "22848.pdf": "BD8D5360-FEB5-49F5-A426-7DF362752182",
    "22849.pdf": "75693809-1AD1-4102-A104-93E0D299F70C",
    "22850.pdf": "CC748987-CBA2-4A06-A8AC-9A158229D093",
    "22851.pdf": "435E9F83-9224-40BA-83F6-018FA783BFDB",
    "22853.pdf": "0037F496-2D3D-437D-A4A7-17B695E75BAF",
    "22855.pdf": "DABF1BD9-06B7-4295-BCDD-FE13E6E95CB1",
    "22859.pdf": "F3621A67-8719-4D7C-AEFD-725EB6B7C298",
    "22861.pdf": "C267174E-F59B-4FE1-8545-975A5E289C85",
    "22862.pdf": "0FEB050D-39C2-4A29-A899-ADC01D358D12",
    "22863.pdf": "3E61141A-17D9-4736-A918-391ADE938E00",
    "22865.pdf": "2632D3EB-7166-4B53-994A-40F7874C83F8",
    "22866.pdf": "1C783E24-5522-4011-9C1B-433D49F43D75",
    "22868.pdf": "7D7CD39B-A3FE-4E9F-ADE5-5138E47E9C79",
    "22870.pdf": "96CF3C6F-8385-4192-9B22-5BD59570C5FD",
    "22873.pdf": "29806264-E989-4649-AA4B-6B1690699314",
    "22874.pdf": "F62E920C-7DBE-49D8-B282-BC2AE0B816D7",
    "22875.pdf": "59A590DA-98C7-413B-B5AF-097187DB7229",
    "22877.pdf": "AA0713AC-CEED-43DC-A31B-256246A7C3CD",
    "22878.pdf": "76CEEA80-AD47-4A7E-8C45-7E0C4D5FA80C",
    "22879.pdf": "32A4784E-B15E-4B37-A864-4B33A8A9C79A",
    "22882.pdf": "265054E6-181E-4509-9E15-D29CDB68D8A9",
    "22883.pdf": "6B9A1FE2-6678-4066-A74B-C06A38C9C516",
    "22884.pdf": "4E32CA93-062F-4BB7-B2ED-CCCF4513DE45",
    "22885.pdf": "B9B4F8C7-7578-4528-9F5F-751B11F1568D",
    "22887.pdf": "3C4B66F3-B199-4495-919E-0FADF2FD18CC",
    "22889.pdf": "F128F25F-A3C3-40C4-B010-22B1851AB11C",
    "22890.pdf": "93DFAC11-6DF7-41C8-B87F-AF66E067DF00",
    "22891.pdf": "684B8682-D81E-4A41-8621-6F61CC1A48A7",
    "22893.pdf": "FB49DBBE-BE05-4ABA-9017-E13EE7419F7D",
    "22894.pdf": "3EDB8C3F-72C6-42B4-9560-02243FE0C8AB",
    "22895.pdf": "DD487D6B-26D0-4588-AA1E-658D4E468ACF",
    "22898.pdf": "1AF20E6F-E03E-4357-9D06-770BFD835D38",
    "22900.pdf": "6D1BBB80-298A-4F45-AE85-54CBD3452B05",
    "22904.pdf": "D59444FC-5C45-46A6-89F3-2556E1BEBA37",
    "22906.pdf": "BD7C355D-4A82-4BCD-AD28-CD15A7CEA313",
    "22908.pdf": "D8F4179B-9768-4E1F-9BB3-3C9811FF45E7",
    "22909.pdf": "E16C2F0D-D902-4357-9D43-8393DBDEF63C",
    "22911.pdf": "8FCF45DE-599E-4D8B-9045-E4D52322B2C5",
    "22913.pdf": "328FDC06-39D2-40D6-A5DC-004FF2FC401E",
    "22915.pdf": "85C16AC7-389B-4D3A-800E-954811955C21",
    "22917.pdf": "1775D3D4-3A3C-4EF0-8085-E96CDDE0E465",
    "22918.pdf": "A5FD21B2-DDB8-4841-B6A9-0DED8F6D3A00",
    "22920.pdf": "8AE3D775-A72A-4836-97BE-76E4CAB22580",
    "22922.pdf": "D6964910-FBBD-4CE0-BCD2-9319E626401C",
    "22923.pdf": "4CA3BAE6-BB66-4863-8355-B01F03E18C4A",
    "22924.pdf": "46E48E6E-6D63-4655-A013-3558228D3730",
    "22928.pdf": "B9995EB8-B321-481F-84C2-052D1FB70375",
    "22929.pdf": "CD47F826-0A31-4C61-9473-9DC0698E692F",
    "22930.pdf": "54C85C75-C057-4289-BA9D-866DA94371D7",
    "22931.pdf": "37930E95-D18D-4C31-B093-FAD1643F4A83",
    "22932.pdf": "AC9E591A-A42A-4D38-9ADC-2EC0D783EA48",
    "22934.pdf": "2C1A925C-8C48-42C1-87F4-29203F32830B",
    "22935.pdf": "00F870C4-F546-4792-AEC4-2A825650ABFB",
    "22937.pdf": "250F8349-333C-48F4-ADF6-6EB0089890A0",
    "22940.pdf": "951B5BE5-8DB3-4A56-BF59-A99C4E0AB4E0",
    "22941.pdf": "01E0F617-2521-44CA-997D-3F624733BF1B",
    "22944.pdf": "5AEF6025-0C53-42AB-BE5C-A8A7E09DAF5C",
    "22947.pdf": "2AB6F681-565D-4CC6-94AE-8486486ACA80",
    "22948.pdf": "156DC577-EA2B-4561-9E12-A2E481907622",
    "22949.pdf": "F39684A7-70DC-440B-AE51-773AB28477CE",
    "22950.pdf": "A62F9893-E4DA-4561-BDA2-08CFC62CDC91",
    "22952.pdf": "EB163294-1526-46C7-A791-7509E7231CF1",
    "22953.pdf": "38690BE9-676D-4CBC-A585-E9E90D4CB3C4",
    "22955.pdf": "5F26E428-6DF8-408A-BF21-84FB39F3B153",
    "22957.pdf": "BBE01A41-7B58-4A95-B302-5B61ECED7A91",
    "22958.pdf": "072C3B9A-CB43-446E-8B44-7213FD93A67D",
    "22959.pdf": "08A3C4B7-54D8-40D7-B303-68DC4E320D21",
    "22961.pdf": "D6444BA2-E6B0-4ABF-BFF1-B39DC4265289",
    "22962.pdf": "7B600A02-B488-4288-AEDB-C4CEB87F56AA",
    "22963.pdf": "F730D19A-0BF2-459C-BFA8-7F9A80D50169",
    "22964.pdf": "3A80484D-BFD8-4DC2-A07B-7E31E03783BF",
    "22965.pdf": "36D61520-3060-458F-91C8-0231BB49B171",
    "22966.pdf": "F6ACFD8C-8DE1-4C40-AF6D-5F1060C77FBA",
    "22969.pdf": "068BC1DE-3500-401A-9149-77DCCB45B2D8",
    "22970.pdf": "EC67BDA4-55C4-4A17-9598-19D286EEBDAF",
    "22974.pdf": "E7F32BEB-C009-4040-A77F-A1088D9BC5E9",
    "22976.pdf": "A92AE8FA-DE56-4CAE-BCBA-D231466DE4A0",
    "22977.pdf": "D5A814C9-77DE-4A86-9D3F-CD02DB6672D9",
    "22979.pdf": "DA7205A8-9CD5-4CAF-AA0A-9899759F8F57",
    "22980.pdf": "5B7BD81F-640F-40E3-ACC7-F04D28124DC5",
    "22981.pdf": "EE136B00-497F-43C1-BAC3-5F2E87956137",
    "22982.pdf": "4025B7B1-3BB8-4345-A331-208A767778F0",
    "22983.pdf": "557FFE17-3B0F-4673-BA6D-BFC1F87CE9B4",
    "22984.pdf": "4D2721C8-F4A6-46CF-B318-CFF93FCCF0FB",
    "22985.pdf": "1EE3EA58-73ED-4FB6-8B2E-6358BF745C45",
    "22989.pdf": "A3DC07EE-0BAF-49BB-9CA7-D62B137EAC21",
    "22990.pdf": "3606533B-4F9C-4D3F-B183-DA8BE56EA18E",
    "22991.pdf": "2E33F271-194C-47D1-902C-0AA1B5FD675C",
    "22993.pdf": "B3E31050-1F54-4B68-860F-77145E150919",
    "22994.pdf": "A03F9EA1-2CF3-4EA2-9532-4E06C07A6F4C",
    "22995.pdf": "82CDF6C2-E8E4-411A-8E61-1A5744EB5E4E",
    "22997.pdf": "E70121BC-8711-41C2-916B-BBAA6EC8AE16",
    "22998.pdf": "DB9CE33B-B3AC-409E-A361-8CEE1D43BE8C",
    "23.Chiocoine.NominationLetter.SNS.10-30-23.pdf": "1D6F2843-3044-46AD-A4CC-CD60D6B70C9B",
    "23.HaydenGephart.LOS.SNS.pdf": "F8966B6E-E26E-4863-8EF8-12D9D05D3C62",
    "23000.pdf": "2EDC9E5A-C5D4-4BF8-8975-5CA6DA7FF8B9",
    "23001.pdf": "8A115165-125B-4B78-82CD-347C0B28CE80",
    "23002.pdf": "73B2DB18-8F27-4FDE-918F-C4E0B07A8A33",
    "23003.pdf": "6915713F-A83D-4D25-825E-5147EA300676",
    "23004.pdf": "62CC9C78-796C-4B27-A5FC-558EB70AE559",
    "23005.pdf": "CDB3B536-DCC9-42B5-A747-471B1FCACDB5",
    "23006.pdf": "AA49DADC-C0D6-4BDE-AA2E-61FD3D3246F4",
    "23008.pdf": "C806CF6B-4C07-42CB-8EF4-8ADABD52A90B",
    "23009.pdf": "22FFB93E-043E-4B1F-A121-3FD068255B60",
    "23011.pdf": "C16B2D82-D4BE-4975-AA97-6CA0850ED3CE",
    "23012.pdf": "95EC87D0-B2C1-493C-911A-FE6A7FFCBFC9",
    "23013.pdf": "BF491CAD-43F1-46E4-9CD7-42C4481F5472",
    "23014.pdf": "B9E7E140-8046-48EA-852D-3D1429C6BA0E",
    "23015.pdf": "66D6E4B6-644F-4673-B233-31826331768A",
    "23016.pdf": "119678FE-8451-4E20-86CC-0B748490E98D",
    "23017.pdf": "11E647A7-D1E0-496F-B35E-66353B5E46F9",
    "23018.pdf": "DD3258A7-0F85-4720-BA19-475C2E390325",
    "23021.pdf": "1BC6082C-9D05-4AEC-AF04-0D7271F62807",
    "23022.pdf": "E365BA18-72A0-4D20-B0A9-0CF6CFFBF4E7",
    "23024.pdf": "FD8E466B-5FD6-4447-B950-CB07A75669AD",
    "23025.pdf": "BDE61ACA-9C67-4D3C-A947-11FF51620E16",
    "23026.pdf": "86EF6357-AF5B-4323-92DD-B62825BAF54D",
    "23027.pdf": "4AACEA6B-F896-4971-9714-4BBB53333CE9",
    "23028.pdf": "EA0E2A8C-B1BB-4193-A789-AFE07F45428D",
    "23029.pdf": "5921DC12-9CA8-4695-9B14-07F43ED718B8",
    "23031.pdf": "A6CDB9C8-3D66-461D-866B-A00922198CB5",
    "23035.pdf": "35FA58C4-A497-4076-9E40-45A94EEF965E",
    "23039.pdf": "789AA501-FE10-4C0A-AFB5-E172F95DE23F",
    "23043.pdf": "1616643D-D9BA-4CB4-9496-6D878546D4BF",
    "23045.pdf": "B5EA2425-927D-49B6-9677-2EB5622CBA21",
    "23046.pdf": "1E206E60-758F-4C63-924A-2AC3390DE27B",
    "23048.pdf": "4EEAF5C3-A900-49D7-B4CA-C4F3D28E4CB3",
    "23049.pdf": "91921223-28E0-402A-AF62-440FABFA5508",
    "23051.pdf": "C05E4235-7EEB-4DB0-B610-C32534923C15",
    "23053.pdf": "10FA506D-BA22-4E9D-ACF6-13ECA950EDA2",
    "23054.pdf": "A999B367-37BD-44A6-AF4C-E11A4CCA3551",
    "23058.pdf": "88F10AA4-8ECB-41CF-AEF1-B7864FCEAD9B",
    "23059.pdf": "953476A1-ECC5-48A0-A028-999DF45B26C6",
    "23060.pdf": "2754525A-3352-4FC5-8DA5-078AE266A988",
    "23066.pdf": "A52D62AC-0AF1-4137-BB19-0569FBB54DE8",
    "23067.pdf": "C757F89A-F725-46E8-B28C-59003C5AD586",
    "23068.pdf": "558F5FC7-022D-419A-A9F2-A9E62D79683D",
    "23071.pdf": "F790D6B8-0D2D-46D9-AC06-433922780A14",
    "230718 CNS Poster.pdf": "D0E613F1-781A-4FD7-86DC-8BD476A96821",
    "230724 Denis Routkevitch Certification Letter.pdf": "686E6F4E-9838-422C-AE89-606BC6A4B17D",
    "23073.pdf": "4E932888-23B6-4AFE-899C-879A778A6A8A",
    "23075.pdf": "68C48573-3026-4C52-A01E-BF88287B5B5B",
    "23076.pdf": "19B22023-A8F6-49CD-BAF1-A00D238198A5",
    "23077.pdf": "6EBE58A9-E4CD-41BE-B016-43DEC0FAF789",
    "23078.pdf": "C7570021-C77E-41EB-A853-4FDC5B95A0F7",
    "23080.pdf": "8BF425BE-EAB4-4577-AB04-CAF7655CE5BC",
    "23082.pdf": "41D7F800-7C9E-40E4-8286-9C70C5B405BC",
    "23084.pdf": "527F0B59-43F9-43A1-99F8-B2449C49614A",
    "23087.pdf": "A00977DE-A10C-49CE-8900-603E6FA51401",
    "23088.pdf": "B97049A8-4A29-494E-A22B-2D88B9575D6A",
    "23089.pdf": "B4360646-86DB-4833-8881-FFE7C704733F",
    "23090.pdf": "78B4D03E-8C37-4C9C-BBED-4474CA4182AF",
    "23091.pdf": "D4334228-24CF-4F19-AB0D-F2FF96628605",
    "23092.pdf": "709BA508-F1AF-46CF-9E04-93CE2D0859A9",
    "23093.pdf": "BC5B6111-02AC-43D1-8FC9-D3850BD179CE",
    "23094.pdf": "439560B1-4301-42A6-9A40-A67F0DA3C44F",
    "23095.pdf": "6CBBCE11-CDCC-4F6D-99A5-3D5A9D62AAB5",
    "23096.pdf": "C5642390-01E9-433F-B77B-42591B809537",
    "23098.pdf": "DA992062-B9BF-41DC-8906-600AA1978A0E",
    "23099.pdf": "5DD32369-A826-480A-8C7A-5CB622E6F308",
    "23100.pdf": "D24E0557-0DBF-458F-AA21-2E84E776B69B",
    "23101.pdf": "CB080338-DBBB-4FA5-B1DC-62B1B8C69530",
    "23102.pdf": "34B79B94-5A62-4237-B47F-B375A03C7E5D",
    "23103.pdf": "CB3A5EF7-6844-4430-BA11-B9274C641BBD",
    "23105.pdf": "24E431C3-BA16-4B01-BA79-A32F1DCDFAA4",
    "23106.pdf": "EA4B394E-A2A8-494B-A70D-682587749B03",
    "23109.pdf": "62883ADC-ED73-4397-BA90-8DDAA20B3D78",
    "2311_PROGRAM.PDF": "F4FC315A-517A-48F8-997B-26A20C2EDA8C",
    "23114.pdf": "758C4297-E892-43BE-B912-1329CFC3DC9A",
    "23119.pdf": "6A1F3291-030E-424D-9363-5F3F340FF181",
    "2312 CNS 2019 - Poster - MEG Project - v2.pdf": "0BC9040E-66BC-4013-91D7-238F3AB1F723",
    "23120.pdf": "53E8A54A-FBCD-4B42-900D-566DC621B539",
    "23121.pdf": "AF2A26BA-B705-4159-ABFA-347F236DEAB7",
    "23122.pdf": "93241117-263D-4B92-BA65-FAC6B0679FF1",
    "23123.pdf": "CF6C0ACC-69B5-4F9D-906E-718B28710207",
    "23124.pdf": "5F3A7F1B-7597-49C1-A6E7-28640E7B8BFD",
    "23125.pdf": "F5653D28-C191-4DC1-9D5D-2FB197AC173D",
    "23126.pdf": "49621E78-EE63-4540-BDFD-6D2F2B407C68",
    "23127.pdf": "45D31ABA-FA52-48F3-A6D5-30F46D19891B",
    "23128.pdf": "7E84BAC2-759D-432B-8217-1633402638CD",
    "2313_NEWSLETTER.PDF": "CBB6D6B1-8E04-4F44-8819-345CF77A3859",
    "23130.pdf": "DC97AF1B-1B38-4369-9347-868BA16C5411",
    "23131.pdf": "2A2BB996-484C-4097-BC42-A1F172DB8049",
    "2314_NEWSLETTER.PDF": "80C2D002-CC60-42D1-898B-665267D76D40",
    "23140.pdf": "95104343-9B2C-4DBB-8D50-846E6E5EF429",
    "23141.pdf": "C6F06B1A-63B2-4915-B3B3-6C719BFCF929",
    "23145.pdf": "A198F41C-0D5B-4515-813E-C77B7EA973C7",
    "23146.pdf": "3335372B-C454-4052-8AB0-8B16FC9CB6E5",
    "23156.pdf": "B456EE88-1E4B-4C40-9B5D-49C7189A7A45",
    "23158.pdf": "65CC60CD-6F9A-43FF-AB39-615B5A07AE0F",
    "23161.pdf": "355F0DC4-5E34-482A-85A7-F479A2B9E6A0",
    "23163.pdf": "8C613909-26B5-4692-9FD9-AA2E2261BBC9",
    "23164.pdf": "9E101BC9-CD3F-4E77-A772-5DB8EDFDA0C8",
    "23165.pdf": "24F31301-F532-41F9-981D-9E62B83B642D",
    "23167.pdf": "7F85C607-2905-4EC0-A9B4-CAF4E43E3186",
    "23171.pdf": "658D6BF0-24F8-4A8C-8772-EAC7F83E69F8",
    "23172.pdf": "2516321D-7E0F-4572-9E4A-264767C782B6",
    "23174.pdf": "D2780D5F-628D-4A1F-A881-B13B8183570B",
    "23175.pdf": "9D70FC22-831B-4576-8DC7-D898D40F68C0",
    "23176.pdf": "F4076490-1CBE-4946-9A0F-B7881339C491",
    "23178.pdf": "1844F62E-C268-42A7-9217-DB435F19D5D2",
    "23181.pdf": "7E9CF8F6-41E7-4C15-8CC1-DD49455BE745",
    "23182.pdf": "E4454EFE-DAE0-48B8-BB52-FBCCE735588E",
    "23183.pdf": "B924524F-C3F6-4B02-A4E5-9846C3F14BD6",
    "23185.pdf": "867ADE5C-553A-4526-B150-6B1246E3474A",
    "23188.pdf": "DF8A5F09-FB81-480E-A1E1-00786392CF2E",
    "23189.pdf": "B6BB1840-9495-419B-869E-D5E78EFF2AC2",
    "23190.pdf": "50CAA12C-2E4C-49C2-9FEB-89126CFD3961",
    "23191.pdf": "8089A2BA-69D1-48CE-A1F3-182A068626F8",
    "23196.pdf": "C5075761-FB0A-4ADD-A076-20C7FAD99354",
    "23198.pdf": "C60A9BF6-1CF2-483B-BC12-966BDA19086A",
    "23199.pdf": "228449CB-BF0F-49AA-B9A5-4EB5172B67F9",
    "23200.pdf": "81978D5C-296A-40A3-A022-889596A0D0CF",
    "23202.pdf": "9AD8A7DD-4FFA-4333-A59E-2A26B42692AA",
    "23204.pdf": "9BBD5EF0-FF12-451E-876C-A003D41FBD8A",
    "23205.pdf": "FF240486-8348-4B8B-9055-1B9123840A64",
    "23206.pdf": "B7041CB4-EA39-4AAE-AD40-0D2ACF82A1FE",
    "23207.pdf": "CFC16B39-4305-40BC-BCA9-7F4C43D49694",
    "23208.pdf": "82151DD2-55A6-4965-8223-88AC5DC47563",
    "23209.pdf": "8F5B26DB-C1C0-44AC-87E0-66DA9DADDDE4",
    "23212.pdf": "A4784369-1300-417A-8603-691B494E5BBF",
    "23214.pdf": "979031CD-4516-42E5-BEC7-73FC2631E52C",
    "23215.pdf": "80D3867F-5F4B-4C89-A45C-F11FDFA133D6",
    "23217.pdf": "6AB84567-53AB-4C16-ACB4-63EB83A87BBC",
    "23218.pdf": "BC1E0766-35A6-4035-9D08-FD66B9BEFD70",
    "23220.pdf": "C83898D5-D4F0-4DF0-B4C0-958871913829",
    "23223.pdf": "4FB10546-178B-4B4A-8807-943F99BCB8BF",
    "23224.pdf": "8EACA5E2-B222-411E-845D-EE2F595F9C92",
    "23225.pdf": "876C64AF-FB5E-4ED2-B41A-E64B374EAEA1",
    "23226.pdf": "8587BC6E-82DE-4957-A734-1B93CBD219CE",
    "23227.pdf": "68B3940D-BA2F-4B6F-9767-AC9D13F82162",
    "23228.pdf": "5D893031-4E72-43B0-AF54-64E733DA3D7D",
    "23229.pdf": "5DF714AB-6B63-4B32-89A4-7549219C66E3",
    "23231.pdf": "1AA1310A-0EBB-46FD-8432-BDDCD35C524A",
    "23233.pdf": "AA2FD186-6E4C-43AB-BA8F-6A30AD6076FB",
    "23235.pdf": "CD9ABE60-23C5-4E1B-A2A2-8D25DA6CB69E",
    "23236.pdf": "0747F250-855F-47A5-930D-321E68D81528",
    "23237.pdf": "AB212CFD-62F6-44E3-8C62-7B06CA8A7848",
    "23238.pdf": "CF14DC12-6CBC-4074-8040-F53CFDF20010",
    "23239.pdf": "F140848D-00CD-4114-A060-6AE626EA3365",
    "23241.pdf": "169CD7EB-A4BF-400C-AF91-81C283A469DF",
    "23246.pdf": "FB483654-9232-4B42-93AC-1F5EFFFC319C",
    "23247.pdf": "E938EA2A-FFF1-4F5E-B675-FFBAB36C9514",
    "23248.pdf": "254C78F6-45A2-4830-A154-8C6773662E0F",
    "23249.pdf": "1C2DC2B0-52EA-4200-88D3-AE79BFB862AB",
    "23250.pdf": "E8B8C2C7-D6BF-4540-BA8F-E759AE18EE57",
    "23251.pdf": "17EEE759-7991-4530-A906-64ED9798B05B",
    "23252.pdf": "E79CEF00-593E-4C79-94B6-64DCA059AA1E",
    "23253.pdf": "3491D36F-2492-4052-BA6E-F42B2D986823",
    "23254.pdf": "95BFE691-A4E5-4ED4-A9DD-84DFDD8489C7",
    "23255.pdf": "831C3734-776B-4358-9CCD-9D15639D80AC",
    "23256.pdf": "599B4B0E-CC26-4AE1-A85E-4BF2B3C88E28",
    "23257.pdf": "26F0F339-6C6E-4729-BCA8-90F9D7D8F50D",
    "23258.pdf": "2829F2B5-BD68-4DF3-B7D7-101388F942B9",
    "23259.pdf": "70F5E3EA-4D39-4E34-BD24-2ECA7C7E9F73",
    "23263.pdf": "66D0B7EA-BD68-4002-B3E5-F426FA05034D",
    "23264.pdf": "6FA18144-A50D-4A2E-9DB8-E0D12F7F7BE0",
    "23267.pdf": "CE4CD054-9BFB-4046-9249-DB2939CF3FF8",
    "23268.pdf": "9BCDE531-1B39-4DC8-A29E-FB6826119721",
    "23269.pdf": "2156B2DA-16FF-4654-9B87-24C5F421275D",
    "23270.pdf": "E647805D-62D8-4819-AD70-7BDD032C966E",
    "23271.pdf": "C64BEA22-5A7D-42EE-B055-3EB5FC1DDBF6",
    "23272.pdf": "9457B343-3757-46D1-94A3-B5E61B08254E",
    "23274.pdf": "9ACC583B-0968-4AD3-8C23-BAEE0978BA84",
    "23276.pdf": "45B8B33F-47DB-4583-A9A7-D04A14091BBE",
    "23277.pdf": "E9C1EA42-FDBC-47F8-A7B2-26BBB0A565A1",
    "23278.pdf": "F0C29F4E-1C7C-4FE9-91F9-F950594C8B35",
    "23279.pdf": "2D6488FF-B109-486A-A67E-E441F702B000",
    "23280.pdf": "1D2A7E7C-E216-4273-BFB6-7A0A4B9DF1F1",
    "23281.pdf": "7CDC7FA6-C6F2-4EAD-902E-63B835CDD420",
    "23282.pdf": "996AEF8D-A7F7-4ACA-ACFB-80DFC6F8C894",
    "23283.pdf": "D45F738C-364F-4DE1-8853-A5103D64DA26",
    "23284.pdf": "44A07E96-85D2-4B8B-8CDD-566CF1A3FF93",
    "23285.pdf": "80F602E8-F2DC-4457-A311-E4491922093C",
    "23286.pdf": "E6F37336-CEA2-461B-8FC3-31E1B0B38FDD",
    "23287.pdf": "A8A26EA0-8A9F-44B3-B9F6-2D0B997EF378",
    "23288.pdf": "B2AE7C03-DCFA-418A-99A1-7B8715C52DF4",
    "23289.pdf": "1EE7B0FF-B859-469A-9BB6-6DA588460C42",
    "23290.pdf": "BB248AD0-51AC-4DDC-8CC4-A5328CE2FD59",
    "23291.pdf": "517A730E-DD4F-4A68-B827-AE951C81D8CA",
    "23292.pdf": "A4F52D4A-9C32-4220-823F-1F1F218F33D0",
    "23293.pdf": "89A3C5AC-2769-470B-842D-E48EA8CF1411",
    "23295.pdf": "71262E70-5EAF-43BD-9BA9-DF9F37243BED",
    "23296.pdf": "9A67C26A-CA39-4701-B6D7-81F38836DFF1",
    "23297.pdf": "F929AB2E-349D-4F5D-9686-E1C5FD5C69D9",
    "23298.pdf": "CCA4C8A9-38F8-4CED-AB7D-D9DC215732D4",
    "23299.pdf": "31F5B272-6201-4F89-9967-B5883FEA3E8C",
    "23300.pdf": "A0139A3D-6B4D-47E5-B57D-DEAD492E69FB",
    "23301.pdf": "00645A95-2BBF-4991-A33A-B96379573682",
    "23302.pdf": "97D70C0E-6674-4061-9CA0-48C672A6483B",
    "23303.pdf": "F716BBA8-DC0A-4632-A2E1-BAB2B131A4AF",
    "23304.pdf": "996E49DB-6E3B-48C9-AA2D-8A7E4BBF93AD",
    "23305.pdf": "842C1AE3-8271-40F5-AB47-AA638AFEE0A6",
    "23306.pdf": "54487F16-844E-4420-82B0-65ADB64C6D26",
    "23307.pdf": "BC5B5171-0CC3-457D-8B4F-B1C504508B5B",
    "23308.pdf": "E91AAA14-A78E-41DF-9466-18898A2CB190",
    "2331 CNS-poster-2331 .pdf": "D57E5FE4-7B3C-4E2A-856E-EAA494D59195",
    "23310.pdf": "12585A48-A379-4399-AE4B-E596A14E6932",
    "23311.pdf": "A41F3BFD-36CC-4816-8F73-0B01DFFA94FB",
    "23312.pdf": "56A49DBF-AD03-430E-80AD-DB27E33BB105",
    "23313.pdf": "6A6F0022-862F-40FF-AD48-E9002389CCAE",
    "23314.pdf": "E9D540B1-8CFF-4A10-AECE-6347094AE4FF",
    "23315.pdf": "B99F8BA7-D3FC-453B-A56A-74F56540F91A",
    "23316.pdf": "182A3774-D2C7-45C6-A0A3-2FADA0741801",
    "23317.pdf": "B3811D5B-D1B2-428D-B8A9-FDB3284ED93C",
    "23318.pdf": "0906C9F3-DF80-4502-987B-B719FCD12D32",
    "23319.pdf": "D47AECD5-9F46-48E5-84A4-ADFE436E01E2",
    "23320.pdf": "888A6B50-37DB-4700-A0F6-09821C62A849",
    "23321.pdf": "8B212764-37A1-4F02-83BD-97383DF2C85F",
    "23323.pdf": "C5FF5F05-3EA3-4012-870B-60FE78295A25",
    "23324.pdf": "56318945-B125-4BD1-BD13-4C384856CB84",
    "23326.pdf": "5BCB2D21-3DDC-405E-A439-60A6E93911ED",
    "23327.pdf": "FD6AFD32-5D52-46AA-B8D1-3E38F62C1F4B",
    "23329.pdf": "DFE591F1-49EE-4149-BCC9-7227897F4FA3",
    "23330.pdf": "54F5446D-7026-45C3-980A-A23E8E776F41",
    "23331.pdf": "A3AB6628-31B1-4A0D-9A31-0862FF9FA76B",
    "23332.pdf": "E22C8B72-9822-461A-9929-A48CB505BEDF",
    "23333.pdf": "5FA2DF29-2237-49C8-A6CB-37DE33FB129E",
    "23334.pdf": "30F8FEA5-9AB4-4960-89AB-0653D101A68C",
    "23335.pdf": "9FD71B7C-8BE6-46F3-96EE-2A9E400F14F3",
    "23336.pdf": "5BB8BAD4-5889-4A34-9AE4-71CBCE9381D8",
    "23337.pdf": "BBFE2603-7966-4351-A4D2-5AACB0CAFBA0",
    "23338.pdf": "2C67383C-30E3-4339-B6E6-7B7FDD39AEBB",
    "23340.pdf": "D522A65C-3D75-4FFD-87D9-F68CD9EBC3CE",
    "23341.pdf": "5D86A89E-FBC9-480A-A796-FD617172777B",
    "23342.pdf": "89EAAC68-F46B-468E-8480-4EA110FAFF3F",
    "23343.pdf": "E030C5A5-A879-49AD-A09C-9D215871D44A",
    "23344.pdf": "0AFD7174-C071-4AE6-B9C6-9E6465E54C76",
    "23345.pdf": "4D0DBDC8-B9CA-4A09-BECA-E04425D6AED3",
    "23346.pdf": "D393F9FC-5A15-4434-ADFD-42E106D1DDFD",
    "23347.pdf": "EAFD0957-A1ED-46A7-A98E-BA0034B6E1D9",
    "23349.pdf": "06EE7DD4-C5E7-40EA-9D14-E80F59219833",
    "23350.pdf": "94B78C40-A7AC-449C-A2C7-884D9EDC93F2",
    "23351.pdf": "DB8FD0FD-AA2E-41E0-8263-01C36C82F989",
    "23352.pdf": "3857B928-2586-4B6E-B606-A10F4CC7A608",
    "23353.pdf": "6855F3D3-63F3-4F0B-B9E5-8F6BAF563E4B",
    "23356.pdf": "57D4116F-D42E-4307-A5A8-EBD869944E0F",
    "23361.pdf": "A1A34FD5-6EFB-4AAF-8E09-460201C2774D",
    "23377.pdf": "3018231C-3C04-4593-9E3E-869AC5C0575B",
    "23379.pdf": "2E0F4EA0-7029-457B-897F-71D877A99ED5",
    "2338_MEMO.PDF": "974B50AE-799B-4575-B611-5432811E4DC9",
    "23380.pdf": "A7AA99EF-A4D8-46D3-8D2B-9E5D164625DE",
    "23385.pdf": "9F972022-A82F-47FE-861D-9DA5514C9733",
    "23386.pdf": "2A8C1376-6578-4E67-9B29-CAF4F2620658",
    "2339 CJ_CNS poster_A risk score.pdf": "4F072570-1259-4D08-8707-AAFC9A988797",
    "2339_MEMO.PDF": "08CA0CC4-F860-42E6-9A67-D3DF047E1769",
    "23390.pdf": "FC9B1E65-99C6-4241-9CA9-7DBDC7FF9BEF",
    "23395.pdf": "974050E8-415C-44E6-8FEE-C18B066D5CF5",
    "23397.pdf": "36E6E422-BA0D-4694-9293-09667F93E782",
    "23401.pdf": "2E85D22B-4B74-4DC2-BE88-077240930478",
    "23402.pdf": "ADA7DDDE-D852-4628-BE43-87195FA752EC",
    "23407.pdf": "B06E1D8B-8F98-4D0E-89C0-A0CEF4DF5170",
    "2341 Miller et al.pdf": "502FDBFD-ED9D-47A5-B43B-56AA03F820BD",
    "23411.pdf": "58EFF90F-34B4-40D5-9D8D-953936A0CAA1",
    "23417.pdf": "543D4C07-A089-46B7-97B5-FBF9C903C734",
    "23438.pdf": "3534444B-4019-4628-A326-C4B81792526B",
    "23442.pdf": "7AB007CF-56EF-4916-A80E-6473BFE25C67",
    "23448.pdf": "242761D7-96E0-4225-AC26-8451FC3528AA",
    "23455.pdf": "852B9F44-D9C3-4AC8-9215-F8CAB61E92AB",
    "23461.pdf": "91D98618-FD3E-4268-88F2-8A5BFF23E786",
    "23468.pdf": "2BB80131-EF7B-45CB-A1B2-912073F70000",
    "23480.pdf": "785447B6-482F-4628-94A0-AF13F9BEAEE6",
    "23482.pdf": "A2A5D7AB-449F-4AF3-A2C1-9CBB464A587B",
    "23487.pdf": "5FC5AC82-8A19-440E-B4ED-8E39DD51F1B9",
    "23492.pdf": "C1343A25-57A6-45DB-A111-8A1414744ACB",
    "23494.pdf": "4B8844AB-4D7A-49E8-B442-15CB865F2499",
    "23495.pdf": "7E703728-84C1-42A2-9B07-119182CCE899",
    "2350_MEMO.PDF": "9D780501-A5C8-497E-8A7D-CF6DE495F07E",
    "23507.pdf": "88646709-D3CE-452B-94F9-627643AEEB2C",
    "23515.pdf": "155AD8FA-C7A6-48C7-8A6A-E6314CC5729E",
    "23521.pdf": "D968F3C7-6D1B-49F7-8658-B569707EE280",
    "23523.pdf": "B7812209-0557-43C5-BFB1-12A18F8A8C83",
    "23528.pdf": "4907AC19-E03D-4D40-B8C0-31E5FD21A4C7",
    "23533.pdf": "59305B52-AA04-43E1-9D3E-08C701BA63E8",
    "2354_MEMO.PDF": "F02A5D0B-370C-478D-86D1-194CE563670D",
    "23541.pdf": "3D793A33-4C6D-4800-A892-4860C59A0F08",
    "23547.pdf": "CC82670F-2AED-4017-9DE8-E67F506D186A",
    "23556.pdf": "D5EABA34-E06C-4E06-B291-609F351B1DC0",
    "23562.pdf": "D034355A-B8D9-4A00-8EE9-AE6152D0B90C",
    "23564.pdf": "9C25308F-4ECA-494A-8662-ABEDCD79D51D",
    "23565.pdf": "2C3AA7AF-AE9F-4138-A2CF-B9B64FD53D0B",
    "23576.pdf": "D881B5D1-EE32-4131-A8E0-70789DB44B3D",
    "23581.pdf": "B3377077-8112-450E-B4C6-01925A7F5FF7",
    "23585.pdf": "90E998E1-9BE4-43C4-A889-38EFAC871A10",
    "2359 CJ_CNS_Clinical Predictors of Tumor recurrence_.pdf": "71648766-E440-42B7-80EB-82B0A6886E7E",
    "23608.pdf": "1C470D75-F59E-4142-956A-0436B2CC7B8F",
    "23615.pdf": "86BAC9C4-1CB4-45F8-895E-6DD944860191",
    "23618.pdf": "D9418B14-FCDF-48E1-A412-B347EFF74E46",
    "23623.pdf": "A0D21B6A-326B-4844-AA97-4686DC68B96E",
    "23625.pdf": "DECB4E60-756F-4AD6-8C49-CBD012C3F4D4",
    "23636.pdf": "08A0F54E-A66C-4D20-B00C-D86D8009224B",
    "23640.pdf": "3CE70DA6-7473-486A-92D4-E2C0F7B323A9",
    "23641.pdf": "9B4FF8FE-B62C-43C7-95A1-EF54D0D0D63C",
    "23647.pdf": "033C97C0-4025-40A7-8290-7ADC3294141A",
    "23651.pdf": "66D43427-B736-4842-A3B8-3792CAF7B85F",
    "23654.pdf": "12E3AA1B-9218-471A-904D-151FF2CF3986",
    "23655.pdf": "6548C7B1-4183-43B3-8F85-EE21F12845B9",
    "23659.pdf": "1B86C453-0C4A-4AA5-A128-75A05F2D03AF",
    "23660.pdf": "8F2908C6-EDA0-4D14-B948-DDD2281C9FB9",
    "23661.pdf": "490FD256-FF4B-45AF-A923-9AB79014D6BC",
    "23662.pdf": "378B27B1-3353-4CDA-8760-2FA39E6B058B",
    "23667.pdf": "54EE5347-70FB-4ED8-BEB9-ED053758CE9A",
    "23668.pdf": "B82D5948-C4D6-4723-B3AC-1DC256B8517F",
    "2367_MEMO.PDF": "DCD44019-CAFF-4D69-8D07-46CBAE050192",
    "23683.pdf": "2BA05A1F-2F35-4D85-B9AE-E55B7168C300",
    "23684.pdf": "9211860A-E3EF-4F35-91ED-08FE764699D5",
    "23686.pdf": "6856506E-B97D-4E24-9DED-25DED4FC1956",
    "23687.pdf": "311EE5B0-2EF5-4F03-AE83-97B1887B677E",
    "23693.pdf": "6BECC867-2A17-4F87-8DF2-23C27F1E4B73",
    "23707.pdf": "A8ED4113-5CB4-4974-8DDB-F9C0670A1F22",
    "23732.pdf": "94D276E5-0210-44EB-8904-7DD03781D7BE",
    "23734.pdf": "F49D0FDB-F20A-42F6-AF1C-629D88A1EBC6",
    "23735.pdf": "6C105933-677B-4005-900A-4FB7A83B5424",
    "23741.pdf": "67E24331-10E4-4022-813D-80FF388064AB",
    "2375_MEMO.PDF": "C9525E9A-5A25-461E-9F65-195A09912F5E",
    "23752.pdf": "9D651517-7A79-4C89-BE45-7A5EDBFA76AC",
    "23756.pdf": "4400B721-70F1-4650-B707-C4AA2F0E443D",
    "23759.pdf": "FF0CCFF9-BC92-4D42-9F20-40F8469CC67B",
    "2376_MEMO.PDF": "2E4B23C8-704E-45C5-BDBD-024B4E713BF5",
    "23766.pdf": "84CFB634-2CC0-4CF4-BBD5-6F5F75C692D1",
    "2377_BY-LAWS.PDF": "C0C3214A-DE86-4AD5-BFA8-881CFA568B6A",
    "23773.pdf": "7DDD7873-EF41-41D4-9770-05C3001F71C1",
    "23774.pdf": "F16CEE10-81F2-4165-84DD-1CA7568703F9",
    "23777.pdf": "2352B01F-140D-4312-AFED-7CF7F3C01B49",
    "23779.pdf": "9FE23B80-96A1-4F30-B8B7-68282019B4C7",
    "23782.pdf": "758A73DA-A07E-4C6C-8610-C21D2CDF64EE",
    "23793.pdf": "5B09CC41-1994-447A-9C2B-E00A2EBF3E1E",
    "23799.pdf": "8B260AA2-74F1-4F32-BB50-EA14710DC818",
    "23800.pdf": "7AE3C978-CECD-42AC-B476-882FE77B8FA9",
    "23802.pdf": "09E2A508-BB2E-4DF7-9B2C-E75C36C77D5D",
    "23811.pdf": "F7453865-8FC9-4800-8751-36240A639785",
    "23812.pdf": "ADB567A1-E201-44A9-BEE6-D2E8DDAFB60D",
    "23813.pdf": "6E8D4FD8-FBA5-4113-9C6E-766EFCC85CE1",
    "2384 2019 CNS poster white matter variation DTI.pdf": "06571B27-02DB-4F1C-A54D-DA0E21E85B37",
    "23843.pdf": "DA56AA84-3ACD-4C5C-98A4-227E03D16A57",
    "23850.pdf": "1A40DD23-E273-4B55-A851-9B655BFE5770",
    "23857.pdf": "35B0CB03-EC78-4789-B31F-FB28A013180B",
    "23860.pdf": "4EA90DC6-B143-48B9-B9E9-E481BCD21E96",
    "23862.pdf": "C9C691E1-2277-45FA-AB62-3AF5B5AE3016",
    "23863.pdf": "09B17DDC-8E6D-4F76-B24B-9A20EB2DE4C4",
    "23864.pdf": "442E94BD-1ADC-4195-A305-A893843E9CD0",
    "23865.pdf": "53F6427A-321C-4283-BA44-43E01F8262BD",
    "23867.pdf": "D3D5D5BB-E40D-4A08-8F45-625247A53C31",
    "23870.pdf": "A8BF4C4D-EF2A-4AFB-AACC-8E4DCF701FB7",
    "23871.pdf": "47AAF7D9-C70A-4336-9147-8A380EB5135C",
    "23872.pdf": "960E5E10-FE03-4283-9469-08AEE86864C1",
    "23873.pdf": "7CCD3184-6348-4209-ADD1-6FE503FA0ED1",
    "23876.pdf": "EA8ADD0E-AF0D-47ED-85FE-E14DF50A57B0",
    "23877.pdf": "84E3AB0F-6EFA-4D80-847A-AC6E73BDB2EA",
    "23879.pdf": "F20EF388-E188-4F40-A5F0-CFEF2266B2DD",
    "23880.pdf": "E2DDF464-0810-4EE4-8AB9-2A6690B7E397",
    "23884.pdf": "2B168CF2-84A0-4B0C-B0C5-C3600DD5329F",
    "23885.pdf": "F06EA104-FB84-4182-9162-360B75FA50B7",
    "23886.pdf": "3F3C5E46-2E51-4643-844C-D9DA81CCA590",
    "23887.pdf": "74F4BEF6-6184-4402-AF1F-9471336EA683",
    "23888.pdf": "8F3F4D4F-3816-48CC-AE9E-4E92F97C4F5A",
    "23889.pdf": "BE22EF2D-EFFD-4F44-A29D-2D6E30972E57",
    "23890.pdf": "B9D8E981-3430-4480-937F-24F014A5C0B9",
    "23891.pdf": "8D2814E9-68F6-463E-BAC2-561BC2E02DD0",
    "23892.pdf": "B3523EEE-19D2-46E2-8E3D-C8F241406090",
    "23894.pdf": "787A8401-C093-46BD-9E25-4056D982331D",
    "23895.pdf": "DD588A23-6F44-401A-BBA3-4807230F89D1",
    "23897.pdf": "D8182AD5-75C7-48FA-9096-E94BAD77B848",
    "23898.pdf": "B69CB4BA-C19C-4CC9-BE13-E83050FC48AE",
    "23899.pdf": "5E0F8BFF-4F02-4090-9087-AF79980FE1FB",
    "23900.pdf": "2DCB5123-B9BC-4F53-8582-9303AC906632",
    "23901.pdf": "4CA01782-7E01-4338-9CB0-1D25D30552AC",
    "23902.pdf": "42D9EB82-3FE5-4709-8050-C9F5BF1286C5",
    "23903.pdf": "8E90D40B-46A1-419D-A68B-DDC9A9673236",
    "23904.pdf": "12ABBF8B-80DB-44A1-A8CC-E9DE1AC1322B",
    "23905.pdf": "9A76F0EF-B9A2-4A8A-BB45-1863EFAECA56",
    "23906.pdf": "4095CB34-D57F-4820-887F-0E1E54F31CD3",
    "23907.pdf": "CC0D7AF4-3B65-4A2D-A379-6FDC7CD40366",
    "23908.pdf": "C29937BA-82EB-4BE7-B868-C6E20A56B797",
    "23909.pdf": "68989289-1461-48D0-A43E-7C6D1542D97A",
    "23910.pdf": "228D66E0-64E4-4CBC-B20C-1B108001179E",
    "23911.pdf": "5CEA1C3A-9E12-4415-840B-F2D5B12BAFAC",
    "23912.pdf": "AACD9044-C941-4153-8342-4CDFE8B80921",
    "23913.pdf": "C304B282-299E-449B-96FC-642C549F5D46",
    "23915.pdf": "31BA4612-4DB0-4138-939F-022CD8EA5CB1",
    "23916.pdf": "B9AA1022-0FCF-4C7C-93AE-3E5AAA76E963",
    "23917.pdf": "8A5D388B-BC0A-4003-84AC-7ABD561C342A",
    "23918.pdf": "A65CC17B-4D92-4755-9B70-B6ADCD7C4DC2",
    "23919.pdf": "757BA478-CB0F-468B-B7A9-1732096BEDC3",
    "23920.pdf": "300DD558-7EB8-4A3F-AE04-7EA6EF6A97FF",
    "23921.pdf": "518740DA-3DA9-4AAE-8FB1-C3086A4D6767",
    "23923.pdf": "BE87262B-4ECC-4BCC-8C88-5841BAF3A191",
    "23924.pdf": "334C5B56-83E9-4B41-8FDF-6EC46111B6A6",
    "23925.pdf": "06F3F9A5-6322-44E2-8B4D-2BE898F3E755",
    "23926.pdf": "7C9B2913-7090-4819-901D-A14CD301EE8D",
    "23927.pdf": "75434181-6D11-44EA-A6E8-1AF3AB4D417C",
    "23928.pdf": "1581CC42-FDE4-4850-9345-C70480798829",
    "23929.pdf": "6230F7DC-3194-4133-B194-AB650239869E",
    "2393 Weiss CNS 2019 Poster 2393.pdf": "2B2021D2-972A-4F81-B69C-E30CF59A75F2",
    "23931.pdf": "D3E37FF0-327E-4425-868C-91507CC16E83",
    "23932.pdf": "8B4396CA-3392-409C-B3F4-CAD510A98CD2",
    "23933.pdf": "3A60D1BB-DF45-49B4-A0FC-828A397849A9",
    "23934.pdf": "1973A065-B66F-4560-8BA3-161264689319",
    "23935.pdf": "7236726C-9844-45EB-9A9E-BFB0B3A36157",
    "23937.pdf": "2C9E3DE1-5EF5-4143-B0C1-059765D78B96",
    "23938.pdf": "7B6DEA7A-D27C-4D88-A876-A50F569B027C",
    "23939.pdf": "3175B884-5CA5-417C-A50A-9B17250ECCF5",
    "23940.pdf": "B009A2EA-8453-450E-AAE6-49A57E43D30E",
    "23941.pdf": "B34D373E-1FDD-4DE4-B30C-AE1F5EA70A41",
    "23942.pdf": "84AA5847-586F-4B56-84FF-87330719E4FA",
    "23943.pdf": "100F094B-031D-4CF7-BCF8-97DCDA254241",
    "23944.pdf": "F3411D8C-AE70-4498-B503-80B93DB0B8EA",
    "23946.pdf": "6F6AA3B5-97B0-4E94-88ED-65BA3E5B53FE",
    "23949.pdf": "B91FB36A-65F0-4B42-934D-0FA9BCBB4C0C",
    "23950.pdf": "B6B85B7E-34AC-4606-836C-14DFDF970A80",
    "23951.pdf": "E2A72397-7BA6-405E-AB76-152805F8F170",
    "23952.pdf": "4CC1145C-6212-4D62-A2EB-5FA4244064F0",
    "23953.pdf": "6070DC4E-38CA-440F-B885-3FDFB20213A9",
    "23957.pdf": "84685D62-5022-4150-B440-3822A64ADFCD",
    "23960.pdf": "58FB413B-2E3D-4B62-9717-D4D8EAA2332A",
    "23961.pdf": "9A8878F8-9C95-4172-BEC3-504491D6033B",
    "23962.pdf": "464900A4-5985-494A-9B6E-1CDB6959B892",
    "23964.pdf": "0F555B87-4CBE-480E-8580-806C935CE941",
    "23967.pdf": "3D0421FF-93BC-454F-8CDD-966F21B88A91",
    "23968.pdf": "B48E2FAF-A479-4CDE-BA75-D295543D3B14",
    "23969.pdf": "126126CD-2CCE-48F1-8F9C-DC7EBE9FF482",
    "23971.pdf": "5F27473C-5A52-436A-8A13-4A565DEC2965",
    "23974.pdf": "3FFE54F3-777B-4437-9E30-BEF627C856FA",
    "23975.pdf": "99E221FB-C96B-4BD5-B92C-3D3890A4E025",
    "23976.pdf": "3E54B7E3-D368-4967-906F-BB9241E34FA1",
    "23977.pdf": "D8D6301B-1322-490D-965D-2F2DCEC628A2",
    "23978.pdf": "2FEE1655-C1E7-4EC5-B59E-E8CD513A29C7",
    "23979.pdf": "3C23D834-CF51-407D-B212-291F19396086",
    "23980.pdf": "90E3DBBD-39D0-4E53-9AC6-0127E7B5C31D",
    "23981.pdf": "AC01068F-3DA0-426A-9780-3017C8E9F2E7",
    "23982.pdf": "5D0FEDE4-FB45-442C-8891-6859511763DE",
    "23984.pdf": "D3F954A7-1652-428B-AC67-D91D27E46740",
    "23985.pdf": "559EFED6-78D3-4568-ABC8-1C17D4FD3BE6",
    "23986.pdf": "F6DA6258-22A1-44EF-AF7D-C2D71EB40BE7",
    "23987.pdf": "737BE6BE-0C64-48F3-8F31-A2C5953AC746",
    "23988.pdf": "5D0CF06F-A4E2-4C93-91D4-06A0B10B390A",
    "23990.pdf": "5A7A4207-35FD-46BD-88FB-A3924BA7F182",
    "23993.pdf": "3214F04A-E859-468E-82EC-FEB3014CCA0F",
    "23995.pdf": "10B00A81-823C-43D6-90B7-19CCC54DFCD9",
    "23996.pdf": "60D490D6-09C4-46F2-AE38-EEA2A1AFADFD",
    "23999.pdf": "61805C45-4CAB-466C-9090-8CE1CC6A2C50",
    "24.Leuthardt.WinnPrizeNomination.1-22-24.pdf": "239D2859-DA76-4886-8588-927D598423C8",
    "24001.pdf": "CD64A650-6E02-40C7-B89B-AC8FF4F1C244",
    "24002.pdf": "39A369CA-E39A-4374-A05B-3F2571E2A683",
    "24003.pdf": "4B6D1FBF-DD49-4593-8258-F11B57F9215F",
    "24004.pdf": "F35ED6EF-BC82-4C0F-B795-5AA73040D2AB",
    "24005.pdf": "CDF534CD-728A-404E-BC90-A77D46E943DE",
    "24006.pdf": "91A82BB4-2AE9-431F-B9E8-2901843B74B3",
    "24007.pdf": "DD4D03F5-FB2C-4EA0-86B9-D53FE74CFF51",
    "24008.pdf": "C8BC1D2B-E9D2-4FC2-A849-52D9E688AC28",
    "24009.pdf": "93B9216F-CA03-4D9E-BE37-531A595BEB5C",
    "24010.pdf": "4285DDBA-8E0A-4E21-852E-9BADB93F9E8F",
    "24011.pdf": "67999E89-A94A-4A54-B778-E06D53724670",
    "24012.pdf": "6ADAE6F0-CAA4-4776-B1CB-CB61D2BB5564",
    "24013.pdf": "9802E8FD-83A6-42FB-AA58-68E4E8467CB6",
    "24014.pdf": "ADFC7C63-CC2C-4E0D-BBF7-DD9C9A0F3EEE",
    "24015.pdf": "CB42BE80-8C83-476C-9EAA-65F63F0D1C2E",
    "24016.pdf": "B7A4C82C-C0E4-411F-B94A-DB824B9FB9A4",
    "24017.pdf": "1B70B318-4138-4596-89D0-7AACEB1A4EA8",
    "24018.pdf": "E91F5287-31D5-4D6D-AE33-E223F824082A",
    "24020.pdf": "1342B67D-7E9B-466A-AFE8-DC9223463684",
    "24021.pdf": "9256A91D-0F99-4C9C-969C-65524C17EFCE",
    "240214_LOS-SNS Teaching Award Cohen.pdf": "104A68AF-5C88-4AD6-849A-DB0D1338FD69",
    "24023.pdf": "35EB2449-CB3C-4E30-95C7-EBE01522D6BC",
    "24024.pdf": "DCC82112-1C11-4138-B91D-35FB9E25BE14",
    "24026.pdf": "DB765DFD-70ED-49E0-9E9A-F1857013D6B1",
    "24027.pdf": "A8DA4D2C-DE78-4E16-997E-DD7F5EE38F79",
    "24030.pdf": "E7EBCDDC-4317-437F-8D82-67DD10D514A2",
    "24031.pdf": "8126B649-1980-487A-A056-9DEECCD52680",
    "24034.pdf": "4CD4765E-E176-43AA-941E-BA215FCC4F98",
    "24035.pdf": "A280AEFA-EC5C-42E2-805B-C96FC3FD26B0",
    "24036.pdf": "4753F76A-865E-4340-9BA3-4AD161286AC0",
    "24037.pdf": "5EAD71A3-FA56-40A1-92B4-34923A552465",
    "24038.pdf": "6D659B9D-6394-4112-8200-CF2CA12D4D7F",
    "24039.pdf": "75E8ACD3-4A83-4DD1-A98B-C5893C7D2BBE",
    "2404 POSTER CLIPS.pdf": "2B794540-C2B7-466A-B93E-5DBBA77B5ABF",
    "24040.pdf": "F866DC48-34F7-4587-9CEB-22288455DCDA",
    "24041.pdf": "DA40D943-9B46-4B07-A1E1-782623681C44",
    "24042.pdf": "F259B584-403D-4F01-9204-4AC9DB451469",
    "24043.pdf": "58EECD0F-85C1-4422-9592-33D7C0A0DFCB",
    "24044.pdf": "36D724AA-156A-4B18-843F-B60A2225FDD8",
    "24047.pdf": "4202B23C-B1AC-4C2E-B208-6640C49632D9",
    "24049.pdf": "62C9C89B-E8B7-49DB-A86B-3AF5C101EB85",
    "24051.pdf": "180B1FDB-B79E-45C0-BF02-BC8D0E0CE7B6",
    "24057.pdf": "36AAC09E-E903-493D-ACC4-E674D5E589FF",
    "24058.pdf": "2A279C78-CEB6-40AD-B82F-81A192133148",
    "24062.pdf": "136CEAD1-13FD-46ED-B0CB-6F60FDBE3280",
    "24066.pdf": "031BBB5E-EBEB-482E-9AAA-39520FE9E558",
    "24067.pdf": "F5A5DF7B-B86A-48AE-A466-856AFEE68733",
    "24068.pdf": "7F02E8B3-3F6A-4671-866B-F2466E3725DD",
    "24069.pdf": "B7E16897-62DE-4038-9ED5-531C904C7C34",
    "24070.pdf": "05AF8B65-9FA6-4A78-910C-B9F5B19691AD",
    "24073.pdf": "AABC976B-1791-4B75-91CB-4D4872B65993",
    "24076.pdf": "36502EAF-7700-4D88-8EB9-919BA95F489D",
    "24082.pdf": "5A25B3F3-1E76-4C00-A056-79F743CF43A4",
    "24083.pdf": "6F171C0B-8868-4086-ABB1-4D258F369F42",
    "24085.pdf": "D491FB86-DF70-431C-819E-2A28BF4AEDB2",
    "24086.pdf": "906E9714-D9EE-4692-B4DC-51754480A2DA",
    "24088.pdf": "B0C24348-349F-4F93-8A33-AAECDF32B7E1",
    "24092.pdf": "6E04E0DF-F896-4D33-91AF-81E45756BDB1",
    "24096.pdf": "F06E43BA-FF28-47E7-A534-1908DB50643A",
    "24097.pdf": "831C4231-DC43-4F10-8787-19BB87305EDE",
    "24102.pdf": "AAA3F63D-47D0-4CBB-BB5A-62CD48F5D0EE",
    "24106.pdf": "DAEA5C8B-E504-4DD7-ADEA-406FFD7245FE",
    "24111.pdf": "CEC1ED96-0098-4EC0-BE15-6C74CEFEB2E2",
    "24112.pdf": "9D3FAC51-6C5C-4401-86CE-D781F4BB1AF4",
    "24113.pdf": "C6BBB69F-9431-4D21-88B8-4E730EDD0759",
    "24114.pdf": "21927C90-C706-419D-A3E8-1F8AC1D41300",
    "24115.pdf": "D2251B4C-DB68-484E-91FC-1FC31F1623C0",
    "24116.pdf": "531D326D-2654-42ED-BB84-DEFB8AE88CD4",
    "24117.pdf": "E15E787E-EB90-4169-9200-7EE3279F827F",
    "24118.pdf": "AF61B2BE-5597-4252-8BB4-76D8053E5F72",
    "24119.pdf": "E563F704-3964-42A9-9458-37909356C1A4",
    "24120.pdf": "5096E337-92DA-45C5-B7CB-C5D9B3512040",
    "24123.pdf": "109E1A8C-C8F4-4848-8C40-5CE828979990",
    "24125.pdf": "913C36AD-5AD8-4E12-ADD5-1BF74B75DB78",
    "24126.pdf": "447E53CD-C92F-4CF1-A9AB-ADEEE06F2700",
    "24132.pdf": "82055828-7EFC-4E1C-ADA6-803B3B0B4924",
    "24133.pdf": "221009F8-1DFB-4B50-B28C-53289F032E12",
    "24134.pdf": "54CD0AF7-1DA2-4A78-915B-BD5E4C2F6126",
    "24139.pdf": "3A34526A-3ED7-4854-9D75-E0374A5B24B4",
    "24142.pdf": "1C2A6BC7-7719-40AB-A62A-1B098862687D",
    "24145.pdf": "EAA874D3-C53E-4A4B-B951-BA8DFD0FB9E5",
    "24146.pdf": "7B4D6190-5E9A-43FB-A0B9-23CB00A6FBBA",
    "24147.pdf": "2F9AC729-6685-429D-8F12-D0E7CC3C6014",
    "24150.pdf": "314F72C7-2888-47C7-AE32-AE4192B24B38",
    "24154.pdf": "76412685-B930-4977-98BD-720389658C49",
    "24155.pdf": "7E0EF6FC-6D61-4A15-8D62-40712328C857",
    "24156.pdf": "FFBD7F62-09B8-4A89-A62F-DA9AA3FC34CC",
    "24164.pdf": "28A543DD-7BFE-478A-973A-51C98F3F4CA6",
    "24166.pdf": "03A7EDEB-69DB-461D-8550-179F3C163BAA",
    "24168.pdf": "B25592C0-9F76-43F2-A631-859EC8EDC4DF",
    "24171.pdf": "DD7505EB-23EC-4F6F-A736-B8D045C2A67E",
    "24173.pdf": "50673736-4BC4-439E-BA32-2A8E370DCA63",
    "24174.pdf": "7335A0D3-E669-4C9E-9087-E69AC7D9CD0E",
    "24175.pdf": "A12BF9F9-1B4E-4915-9B74-3334B05C4162",
    "24177.pdf": "12E6988A-20AD-4894-B1ED-69EE6B36AB2F",
    "24182.pdf": "A1EE31B8-5719-4F80-A886-7E77F6E013F6",
    "24188.pdf": "EC72B863-DF91-4D06-BC14-1DDD1B6EF92E",
    "24190.pdf": "3D524244-69BB-4494-8F4D-4A5AD8178351",
    "24192.pdf": "679ABDB9-F0E2-4F68-927E-7BFE34AC1806",
    "24196.pdf": "7006E444-08CD-4AD5-9778-1D9D4FEF2EBA",
    "24199.pdf": "D884E4DF-D8B3-4073-84D4-13C24A5D5AF6",
    "2420_MEMO.PDF": "88DD6063-AD9C-484F-984B-C726F1BCDFAC",
    "24202.pdf": "C8626DBA-AF66-4B89-B9E9-9E44BC081870",
    "24203.pdf": "28D8E8F7-17F3-4D1F-9B07-0344B4673250",
    "24208.pdf": "C399976A-797D-4624-98E8-F32A56F07E10",
    "24210.pdf": "EE0F09D4-0C87-4FEF-80AA-0A4837907692",
    "24211.pdf": "8E2F7C7C-F427-4FFC-A585-29C11DE1ED3D",
    "24212.pdf": "92C26D2A-4EEF-424C-BD94-C96004E14F02",
    "24213.pdf": "18A6CDAF-DAE1-4186-A619-D2E109FB577E",
    "24214.pdf": "0BCD6EFE-5131-4947-86C8-380F262A981C",
    "24215.pdf": "73731B3F-AE9E-44C4-815F-23E7C15CFD88",
    "24216.pdf": "81F22DCC-61FB-487D-AFDA-6B27447C560A",
    "24218.pdf": "D8A8F915-9A1F-486C-A6AD-7744E10D480C",
    "2422_MEMO.PDF": "CB2F871C-5B4F-49A3-9E9D-304CE9192815",
    "24221.pdf": "F08735C7-0CD9-4A7A-8024-AC3AB7F96161",
    "24224.pdf": "AE6C08C2-D893-4C17-B37E-1BF52C6F4D3E",
    "24225.pdf": "49AD6EAB-9418-40A9-9F5D-006A98976DE0",
    "24226.pdf": "E7A2215B-08A6-4DBB-B40E-520BD6F4D59D",
    "24227.pdf": "BDEA6DF0-9FCB-4AD8-A378-C8738C0E979E",
    "24228.pdf": "19399218-1780-43E9-93F2-CB586D8EAA48",
    "2423_BOOK.PDF": "7D55670A-C88E-444D-B0AB-1FED6E0CDD13",
    "24230.pdf": "5F4473EF-8400-4BE2-B7D3-5D9D092C6D73",
    "24231.pdf": "F8FA8452-D9A5-4570-A8E5-9D7429B6B0FE",
    "24232.pdf": "B117D378-0565-43FF-B7D9-74610CCC24CB",
    "24233.pdf": "38A2BE53-99A7-4445-9B0F-0FB88B6D730F",
    "24234.pdf": "08C3A124-30E8-4B2C-AEB1-CC579D753651",
    "24235.pdf": "F5045084-8179-4997-9F51-B20BC6621C8D",
    "24237.pdf": "767AD78F-52F7-43EC-A2B8-5690FDD3570D",
    "24239.pdf": "B8DBE4BC-DC07-409E-9211-07D0C5696191",
    "2424_MEMO.PDF": "5345FA9B-E002-4924-9ACA-2811E75ABC6D",
    "24242.pdf": "F6A54252-8222-41D0-9BCB-D60EC44DC001",
    "24246.pdf": "179B9DC9-4C85-4322-86A8-991EE5FA6C3B",
    "24249.pdf": "BD578100-7E59-48EA-809B-124DB3586EB6",
    "24251.pdf": "92153C85-6103-44B9-9416-C267D508E618",
    "24252.pdf": "19FE65BE-E227-4063-8FFA-E1AD4E7A8258",
    "24253.pdf": "C5257D44-1D92-4571-8710-3BA83634428F",
    "24255.pdf": "6C35E2B8-72F6-4939-8BE9-8BA30B0731A3",
    "24258.pdf": "9A252100-399A-48B3-BAC9-0F9CFD21C98A",
    "24259.pdf": "E2B01EF6-0997-452D-888F-0693B93E187A",
    "24261.pdf": "B4347781-9371-4C59-AF63-A1F6626382E0",
    "24265.pdf": "3FDF4C42-15F8-4D69-87A1-DAED093F6E9D",
    "24269.pdf": "57BA45F8-9C75-4F34-9973-CCBE1E53B5B9",
    "24278.pdf": "AC4C0B6B-CAE0-4D2A-94F5-D9913D89B3B4",
    "24279.pdf": "820B6C6A-5996-4CAB-9B7A-31B80DAE92C4",
    "2428_PROGRAM.PDF": "71F6230E-0098-49AC-87A2-D87E5F3A4673",
    "24280.pdf": "91137E1E-63EC-4472-8FAF-724BB0C3A77C",
    "24281.pdf": "1A929380-148C-467F-BA47-D52CD24A3248",
    "24282.pdf": "A3A116CF-3EEC-49FA-9CB1-27422891DAF8",
    "24283.pdf": "345993B9-92B5-4F09-8842-50675A101569",
    "24284.pdf": "BAB2027C-BC87-4D9E-BD49-133D5C56603C",
    "24288.pdf": "0FF89B89-115B-40DD-8149-F2897CAF52CC",
    "24289.pdf": "BB76A72D-3070-4729-9DB8-F583F41E3BE5",
    "24291.pdf": "DA60F208-CF02-44EF-A84B-DBE3A3CCD511",
    "24292.pdf": "B43B8926-7DAC-46DF-9E67-B73136BA817B",
    "24294.pdf": "3C8FE789-4B67-4061-A06A-929825A562F8",
    "24295.pdf": "2131DB5F-57D1-43E9-8DE9-558257CABED0",
    "24296.pdf": "0F7CE2DE-2512-44C8-84AB-0F69E2D0A306",
    "2430_MEMO.PDF": "BD2A39A8-6374-4C6D-BCC8-3A459609D93B",
    "24301.pdf": "03078720-5755-4D24-A4D2-41244BD8337F",
    "24302.pdf": "2E6056CE-8153-4E0B-A809-852131F9493B",
    "24304.pdf": "022632BE-26F5-4C7A-8EFB-1C48C556B9DC",
    "24306.pdf": "AAB27102-1BC8-4CD2-AB8B-5A75B6BE9537",
    "24308.pdf": "BFA0729B-EB7F-43E0-BE7E-42CF41F040C7",
    "24309.pdf": "18FBEC3D-2C8F-46A0-A0FC-CF1A277C40CE",
    "2431_230802153009_001.pdf": "F630814C-E8E9-4EFA-B91B-7556A827FE90",
    "24311.pdf": "705F6BC8-30EF-49E0-89E0-AC3DFFBA1E20",
    "24313.pdf": "C040F2BD-D4DE-4189-A564-1A4C7BF32A2F",
    "24316.pdf": "22AF3440-4696-41EC-A7F9-8097FB7DF9E3",
    "24319.pdf": "2EA5839C-2D04-4B2C-ABCD-F838002FFE33",
    "24321.pdf": "7AF57117-0730-4AD9-9EF4-5442554AD752",
    "24322.pdf": "4E60F02A-FDFE-4287-86A3-F0172AE6D25D",
    "24325.pdf": "032BF912-8AA0-4B00-A870-35E705D6F8F8",
    "24326.pdf": "5D2CD939-1396-4FB7-B61B-C97E578E6107",
    "24327.pdf": "AB8FF669-CACD-46EE-8E45-C37F50DD3E6B",
    "24329.pdf": "434C904D-DB5D-4D8D-A796-EF109536E494",
    "24331.pdf": "43339435-556E-431E-AA05-78321CAFBC30",
    "24332.pdf": "6F54B506-3D57-44F4-A499-ED7783209D72",
    "24333.pdf": "B2FC35CC-26F9-4E1C-A217-F2BDF8DE4816",
    "24334.pdf": "BAF2D948-4D63-4899-BADE-403AB1425B2B",
    "24337.pdf": "7DAC3549-E01E-42F7-B5EE-68402BB3F0F2",
    "24338.pdf": "2875E6A1-206C-40D6-9322-D50CE1EBC4B0",
    "24347.pdf": "DFD0978F-EB7C-46C0-A973-03A55A5B2D4E",
    "24348.pdf": "98C2628B-4126-49EC-BD5B-AF2DDB592831",
    "24350.pdf": "9FC04870-3F94-4934-8B35-6212756FD01C",
    "24351.pdf": "D91B32F4-7331-4370-9157-C78E59E681AC",
    "24353.pdf": "F9BF4959-4CBF-481E-9923-1D54F159B0AB",
    "24360.pdf": "0035E488-10E9-4CCF-AD11-1A3AEC0EB029",
    "24361.pdf": "76A171DE-6AB4-4026-A637-C07A6D5CBCA8",
    "24362.pdf": "F83D4328-E559-4653-9D0B-F51265259F05",
    "24363.pdf": "A5CB79C2-3F24-48AD-9C69-E976641E01E5",
    "24364.pdf": "F303C486-C147-48B6-94A8-BE08C8E8D3C7",
    "24365.pdf": "1101E3F9-81DB-490B-BFD0-B1A547039F02",
    "24366.pdf": "B781C0C2-73D9-41C0-8742-D8A9C8710F45",
    "24368.pdf": "825D5896-B6B1-442E-AEB1-CCA3B337CFF1",
    "24369.pdf": "232C128D-E402-4E1D-80D3-38917E44D7A5",
    "24370.pdf": "CA1D5F78-AFD9-48DF-B459-9011BD8A8C63",
    "24371.pdf": "1B7E4E90-2825-4901-A0C8-2271763CE189",
    "24372.pdf": "0299E556-2E8E-49AA-ACEA-A3922404E587",
    "24373.pdf": "39CC01BD-28EF-4F6D-94A2-F5FE37D5A5C4",
    "24375.pdf": "6301CDCE-66D2-4A06-80C1-14198FDF49CB",
    "24376.pdf": "3EFDF620-176F-4EFB-8676-02648DDD8781",
    "24377.pdf": "D73E69D2-0480-40D4-9B43-F2791B04F2BF",
    "24378.pdf": "F6130928-D6FF-472F-ACDA-235C1015C134",
    "24379.pdf": "FF8AA221-3519-4F8B-85A8-E7F9A9058F35",
    "24380.pdf": "595F007E-04C1-4EB0-9046-C05A529B3BD6",
    "24381.pdf": "A47DAC87-E4DB-46D4-A1F7-0340040602AC",
    "24385.pdf": "D3B22F58-3EE2-487D-8D29-46C28B7347B3",
    "24386.pdf": "5E80CC56-85B6-4BAA-BBD1-4DA7CDCCD044",
    "24388.pdf": "12B4B067-0E56-444A-8999-3867A210E078",
    "24390.pdf": "3AD43F26-741F-4D4E-8E68-53821B501926",
    "24391.pdf": "9C3AC4B9-0707-400A-9581-5D9369C4E5DE",
    "24392.pdf": "9A8B007B-00FF-4E92-ADE5-CC1FD3FC4AF0",
    "24393.pdf": "BFB9EBBE-A09F-4DE1-A3F6-4A0651C48A1B",
    "24396.pdf": "D156E63C-1FEF-4F9A-BFBC-5584333B509F",
    "24399.pdf": "8E3D9246-96C7-4BFF-9A3F-D791A234D95B",
    "24400.pdf": "0B3B5F54-33D3-40D6-919E-B4BC48B272F3",
    "24401.pdf": "F8FE18DB-C89B-487F-B8DB-3D0441D99587",
    "24404.pdf": "43BCF28C-E141-4004-9423-6C9588A918B9",
    "24406.pdf": "963C209E-0FDC-4929-BAAC-D44C77DB6F54",
    "24407.pdf": "6175699C-AAAA-48F3-9A39-05312EFDEC11",
    "24408.pdf": "752F2C73-B32A-4D70-833B-F961231204EE",
    "24410.pdf": "229F25B3-5651-4989-BCE4-CF2F2EDE64E4",
    "24417.pdf": "8BB9EC37-6B57-45DA-9FD3-B86731347D3F",
    "24420.pdf": "5A3C3C03-83D0-4E44-AACF-708ED6E040BA",
    "24421.pdf": "B1E10939-2DE7-46FD-801B-6CFFFDD73A45",
    "24423.pdf": "704F7C39-AF5F-418A-8C6A-93224202A359",
    "24424.pdf": "5E632386-4E0F-4AE4-9A08-7C25B958A91B",
    "24425.pdf": "369C18B8-27D9-46DB-BBAB-2CDBE8FFDFCE",
    "24426.pdf": "25AFE655-028A-4B40-8D86-1B09D3238A67",
    "24427.pdf": "9BD46864-3C34-453A-96F5-2032CE3D12EB",
    "24428.pdf": "F953C090-06F3-470F-8171-1347C29120A1",
    "24430.pdf": "1AD48BB5-6976-4E91-8A37-9EB47A82EA72",
    "24432.pdf": "EEF337CA-0D2C-47F5-A34B-ED5B4CFAFFBB",
    "24433.pdf": "77F9BF80-2F27-4515-87DD-9C22EE81EFDC",
    "24434.pdf": "4297F55F-5596-4023-A430-52375C47AC1D",
    "24435.pdf": "87DD936F-D130-4975-8AA8-6E4A5B263228",
    "24436.pdf": "09936B0C-20EE-4CDF-8805-B2032E1DA742",
    "24437.pdf": "363A100A-3FB8-47FC-BB9C-A2B086132617",
    "24438.pdf": "A2532FD4-10F1-4242-9C97-5AC5FC7EB89F",
    "24439.pdf": "E70A4E5D-2D2C-41D7-BE03-6F16E2B68825",
    "24441.pdf": "57C0AE6F-CCC5-4E05-9F61-FA9F4BEC72E2",
    "24443.pdf": "BE71D189-2036-4AB3-84C2-550B7516FCE3",
    "24444.pdf": "1B286CAE-FBC9-4B95-8FA4-021B76C58747",
    "24452.pdf": "BD015364-8D4C-4607-8060-E592DBA2A9CD",
    "24453.pdf": "E65628A0-F1D0-428B-BAF5-F1E716DC70A3",
    "24454.pdf": "D4A5426B-CD03-4FB0-B1CA-4A9FFF8BE3FC",
    "24455.pdf": "557BF628-0B21-48FF-BB0F-7DDB6CA0A954",
    "24456.pdf": "446F5390-3BA4-4BA6-9CD1-57A8242E0359",
    "24458.pdf": "2FEF73AA-9AFE-4F7A-8C81-05D9BF3EFCB0",
    "24460.pdf": "F660FD8D-F4BB-461B-BDAD-7F2E09C369C9",
    "24465.pdf": "C47486EF-CB28-4F4F-924A-262E9BC5C989",
    "24466.pdf": "438D1713-C6BA-4480-A8B4-FED7092D22F2",
    "24468.pdf": "AD809FC1-A690-4080-9CEA-BB45ECFA67D9",
    "24469.pdf": "F147594B-F18C-4EF7-8995-125BA78CEC4F",
    "24471.pdf": "6F74B8C0-22FE-4809-8A1C-1BDEE6A3D43F",
    "24472.pdf": "702D0325-0A2C-49A1-B2D0-B399242C166B",
    "24473.pdf": "56F69E99-6C37-4ACA-B9FE-A816A99D7F2D",
    "24474.pdf": "13D67C47-C2A7-4C68-8309-7FA0327E757D",
    "24476.pdf": "004D3B47-E4F3-4D82-8D8D-58408FD014A7",
    "24477.pdf": "F16450CA-11CF-4798-A348-B096EBF21C2D",
    "24478.pdf": "0CEDC622-A341-44E2-A873-A601FCC94D7D",
    "24479.pdf": "CC67D917-B7B7-41AF-A391-3995E3BB7A57",
    "24481.pdf": "6A5FA358-17B3-4703-92EC-87CE86B30E9B",
    "24482.pdf": "689335EB-80B3-40AC-B754-5F698D229D4A",
    "24483.pdf": "49CB8242-F053-4630-9905-4C1D0C317534",
    "24484.pdf": "BD00B19C-A404-47F6-872A-5C11B7367F10",
    "24485.pdf": "4327E313-63D9-4689-9CF8-AA727081DC7D",
    "24486.pdf": "C7BEF7B3-50FC-4895-85B3-ABEB258F384B",
    "24487.pdf": "399003BA-7D1F-412E-888D-19106557F647",
    "24488.pdf": "DEB79CA3-19C0-477C-A80F-0F9CCDD44192",
    "24489.pdf": "350E4957-8678-4C62-935C-5CC3C1E05C6C",
    "2449 CNS Poster_Litigation and SCI.pdf": "87D21439-7C79-4CFD-8475-3B6C673F08E4",
    "24490.pdf": "174C3C8A-3C2A-472B-A419-0089382CD8B3",
    "24491.pdf": "E455129D-1503-4681-B829-162EF2BF995C",
    "24492.pdf": "73E9AB2B-169A-4ED1-8426-C600B7E5B7B8",
    "24493.pdf": "FEB35CE1-5916-450D-B480-96E8DB5B63F0",
    "24494.pdf": "AD2FEF9B-B4FB-499B-9177-42849D612F1C",
    "24495.pdf": "DCEA9393-8ED3-45AC-94DB-4FE4F258B6A2",
    "24496.pdf": "6123F5DF-1B5E-49DD-9EAC-9E01810A8C6E",
    "24498.pdf": "7485206D-CC49-4197-979A-FEEDB1356DB9",
    "24499.pdf": "8DD0D037-501B-48BA-8BA2-E513CD21FC5D",
    "24500.pdf": "50F2B832-3514-462E-8407-63BE53406C9B",
    "24502.pdf": "C0EED297-85C4-449A-989E-09CC119F9070",
    "24503.pdf": "8F67968C-A59A-4E8A-A42F-146DCF62BC8B",
    "24504.pdf": "15FCB1CC-EECC-4982-8DC3-E06D75706119",
    "24505.pdf": "6E690F4A-54B5-47D1-A371-71F66BE9C074",
    "24506.pdf": "AFBF0845-AAC9-4CD0-9EE6-DE310B48C1EE",
    "24507.pdf": "00557AF8-1668-4758-8E18-B518790E06CB",
    "24508.pdf": "4EE9C0BB-E4E2-4F98-99D9-D3214C6B1D41",
    "24509.pdf": "19593DAE-D1F1-4294-9027-9AB3A1D08BF8",
    "24510.pdf": "E001B73D-EE0E-46A0-9485-470C39EE81CD",
    "24511.pdf": "7A0611A8-EE5D-43F3-914F-9FBA9F4B3D41",
    "24514.pdf": "E6FFD6A3-CDF6-47DC-9668-00F96DA546D2",
    "24517.pdf": "1C35B58B-DC35-4C7B-9576-AD590C5784A8",
    "2452 Wallace.pdf": "B4DB9DAF-F278-4A09-9BFE-8C48D834CE91",
    "24520.pdf": "D9D2414C-1BB1-4615-8C1B-5CB408A66B98",
    "24521.pdf": "3F78D169-0057-41C6-A1EA-9C01B22254EC",
    "24522.pdf": "0006BEC8-F0F0-4CEF-9E8A-13ED697CA02D",
    "24524.pdf": "463C38F5-3810-4647-9FA7-E0CCD41D665D",
    "24525.pdf": "1C670741-CB01-45D6-B838-37CB416A87B1",
    "24526.pdf": "63F73ACC-E8F1-436A-A2BE-B7D6E2DE5398",
    "24527.pdf": "7ECD6719-D9DA-461D-AE9F-4D5A4446D952",
    "2453 CNS Poster XLIF ASD.pdf": "D472B499-23FF-4D0B-914F-2135F7FF45A2",
    "24530.pdf": "A457BA73-04FA-47B9-B0BA-C72C24C029D5",
    "24531.pdf": "33E9DCCC-B813-4A1B-8CFC-8403621DE51D",
    "24534.pdf": "CF91A58F-5883-4EF6-862D-D90CEA9D3FF0",
    "24535.pdf": "5D68D30A-8655-426A-88DF-E44EBFA09E43",
    "24536.pdf": "07B0A918-B939-45FE-A352-AB08EE31C388",
    "24537.pdf": "1D4DC2D7-2C50-42C7-B28D-D9F0105D6604",
    "24538.pdf": "78974F4F-305F-4BEB-A26F-FBF73D1EEC5B",
    "24539.pdf": "F259CCDA-A90D-4E4C-A17F-EC38C1B38D14",
    "24540.pdf": "42758343-76A4-424D-BCC6-11E24D39E933",
    "24543.pdf": "11DBFCC9-77B7-43C2-B0BF-730047D70728",
    "24544.pdf": "8795C286-51DE-414F-8F6A-377CB2625B25",
    "24545.pdf": "73AAD916-BCEA-49BF-8132-1BD695339AFC",
    "24546.pdf": "A72D220C-5EF3-4E8B-AD71-166909DE6439",
    "24547.pdf": "CAAF2EC2-E418-4FF4-ADF2-7862C7277FD2",
    "24550.pdf": "E8D7B044-174C-457E-AC7D-553D6B1C2864",
    "24551.pdf": "565CC9DA-3A82-43C3-A681-19ED0AD5FC67",
    "24554.pdf": "1A37622C-8FDA-4809-ADC0-4384A838C469",
    "24555.pdf": "5705457C-F9D6-46E6-830F-AA4A13234E05",
    "24557.pdf": "01926FFF-08DD-4598-B64C-5605B470BB07",
    "24559.pdf": "E9A6410A-8074-4325-8C5B-5AC5F99EE644",
    "24560.pdf": "ED7DC8A6-9364-4EAF-A22D-D155E6AA2EEB",
    "24562.pdf": "9AB0D01E-DDE9-4AB0-87D1-24D421BE6FC6",
    "24564.pdf": "9F9F4098-690E-444E-B641-08DD3ED1389E",
    "24566.pdf": "CCEFFA92-EFD1-493B-92EB-F022855F0E59",
    "24567.pdf": "FA500049-DD91-43AE-8C2A-5E4270E7A997",
    "24568.pdf": "6E4175C1-D891-471C-A73D-93E8D204A9E9",
    "24569.pdf": "84B86829-4B2F-435F-8E83-C05F611E3974",
    "24570.pdf": "20A121D5-87AF-4FA1-871E-7AC5777C2E86",
    "24572.pdf": "89C7605B-721A-4E2B-8393-3985AF177343",
    "24573.pdf": "6ABECCD3-1C6B-4847-BDFC-52FD5A7CF390",
    "24574.pdf": "F5CCB31E-9073-4C22-8AD9-9AA20689A1BD",
    "24577.pdf": "E517920C-6C5A-43E6-AE71-BD196C287F6C",
    "24579.pdf": "45187A15-93A3-486C-9A7F-0963C24C4BF8",
    "24581.pdf": "475A6A97-6545-45DD-9A86-EE3330D037E8",
    "24582.pdf": "2C413A11-924F-40DB-B843-26AE9D6E5815",
    "24583.pdf": "725D13D8-DB1B-47DB-9007-7DFBD7D6CFD3",
    "24584.pdf": "56BF94C3-EA4F-4236-8618-CAD9598A5BC0",
    "24585.pdf": "FBDF910F-5166-4054-9EB3-80E448B44EF0",
    "24586.pdf": "C41145C4-E383-4152-924A-A5D1612E974C",
    "24587.pdf": "50924317-CCAF-4ED3-8E2A-DE8C268092A0",
    "24590.pdf": "4C809623-058E-4451-88B6-1AD03893E163",
    "24591.pdf": "27417069-9670-455B-91FE-BBCEC14D332D",
    "24592.pdf": "E87990F8-879B-44F9-A718-FC520BCD45C0",
    "24593.pdf": "44DC641B-0DB6-42A2-90D6-861707991D8A",
    "24596.pdf": "F834D793-5FA2-423C-ACB1-D1CCC367F84E",
    "24597.pdf": "1777B5E0-8332-41F2-9877-CC15D74A294D",
    "24598.pdf": "95C61E8E-EFC9-4E53-B2DB-E543B30D0A96",
    "24599.pdf": "F5768E2D-595A-4F74-A902-A1A8FD8E67D3",
    "24604.pdf": "F37BABB4-39BC-45D9-996A-79056E3CBDC3",
    "24605.pdf": "3A30D676-503E-4D2B-9874-21575233084D",
    "24607.pdf": "2E8C9CC1-13C3-444A-AC11-B62C216A5E84",
    "24608.pdf": "0534F2FB-1110-4665-A083-49D67777296B",
    "24609.pdf": "9B4C1770-11AF-4E27-B5AC-FB4ED6A11A6E",
    "24612.pdf": "2C9B2F31-95F2-4F44-BD7F-CA971215E48A",
    "24613.pdf": "B62A4BC5-6642-413F-87A3-A32F5BDD35A8",
    "24615.pdf": "52145103-B797-46FA-AF24-F2A7F5737B9C",
    "24616.pdf": "ADBFC292-719C-4123-A7A2-F96C4D9EAE2C",
    "24620.pdf": "27C0A46C-9063-4A73-9B42-FF815E77F66B",
    "24621.pdf": "ABC84A79-D994-4318-847F-3FE0F7075AC2",
    "24626.pdf": "7BF8BD27-4F7F-4613-B308-00156EB2D1AD",
    "24627.pdf": "23E5CB68-C2B5-485F-B511-3346D9322352",
    "24629.pdf": "3A188191-E49A-427B-8FF0-D3DDEF0DB5F9",
    "24630.pdf": "481D0380-0CAB-4005-89EF-99A8254B6046",
    "24632.pdf": "92FAC21A-0F01-4F1A-B8BB-0DB38D849C3A",
    "24633.pdf": "DC284776-9DA9-4217-ADA3-CFCE5A054F95",
    "24634.pdf": "029C8C02-3CEE-4611-AAB9-ECE6FF195654",
    "24635.pdf": "09DA5FE5-DC1F-4201-8166-30B5F4EF710B",
    "24636.pdf": "92078F90-DDA7-4AE1-AEFA-CA5980EF3DD1",
    "24637.pdf": "5106D098-F87B-4A35-835F-9219F65B1319",
    "24638.pdf": "1A73AC8B-8E9B-4AEC-A2D9-48F7E8E58815",
    "24640.pdf": "9797C9A1-7589-4A3A-8B1A-0A2342E89FD7",
    "24644.pdf": "5DFF197C-8368-46F4-A2CA-C610AAF1D139",
    "24645.pdf": "2B2084DD-C736-4EFB-9A7E-9A6A4FEBDE65",
    "24646.pdf": "7F0123C0-B291-4020-813A-4EA7D54FC3F1",
    "24647.pdf": "23A3EEB5-36DC-4393-9142-53ED57F0B14F",
    "24648.pdf": "ADB1A709-F64C-4C8F-9381-FA56A3282CAE",
    "24650.pdf": "D2EE1BF0-64EE-46DB-9BA1-42EC8515BA7E",
    "24651.pdf": "C6B5ADAD-187B-4376-B9E5-C1FF2C8C3838",
    "24652.pdf": "F4B9FD45-B843-4BC1-B99B-FD7D05E2F060",
    "24657.pdf": "FED0B3BC-6939-4FD9-ABBF-D3E675A2BB0F",
    "24658.pdf": "475FFDD0-1DC9-47FA-A1DA-8384742E1587",
    "24660.pdf": "0EA59869-2B27-4024-A259-8D5B6A455CAB",
    "24661.pdf": "6A1D8939-4D26-4E29-AC51-DFF9714DBDE2",
    "24662.pdf": "B310E20A-D985-411C-8D72-63309F472A83",
    "24664.pdf": "25A7BAF1-6D4B-4581-8D54-15C419E703C3",
    "24667.pdf": "4A8EEE0A-5D9E-4853-B7CF-0A88F052EB9C",
    "24669.pdf": "2CFD3A88-8B49-4671-A73A-DCE69D139F56",
    "24670.pdf": "3F79215E-3B2D-4491-A669-D21EB74A32F8",
    "24671.pdf": "B2AA80E2-ED87-4A70-97A5-35474F89ACE0",
    "24672.pdf": "85918E5D-B787-423B-9010-7A76EF82753E",
    "24673.pdf": "AFBAB26F-35E9-4FE4-A91D-B5EE09980726",
    "24674.pdf": "8C539F87-76A3-40E3-A44C-BFBAEA026CE2",
    "24675.pdf": "E6DEDDD5-AEDE-4EE9-A852-8D3B897BCD6F",
    "24676.pdf": "16713BE8-B04C-4073-B7EB-34E836829840",
    "24678.pdf": "D88D7BAC-6F9A-46AA-AB08-2F82E344FCB3",
    "24679.pdf": "18ADB3C1-55E2-4769-9011-444D7D43F877",
    "24680.pdf": "ADAFEEE8-DB4F-4D9B-BE6E-0863E457513E",
    "24681.pdf": "0A3E1F3C-8C3D-4B69-80B4-6980E45E14D0",
    "24682.pdf": "F3B0F272-51AB-4151-9496-A0EE6C9B2695",
    "24684.pdf": "B74501C6-5220-418D-A1ED-9CB81EC6ECCF",
    "24685.pdf": "FB6C7A7F-498F-445B-9B6D-36B8167AEE22",
    "24686.pdf": "C8405EBE-F306-4DC2-B49E-6120ABFEB462",
    "24687.pdf": "0D7FD998-3141-49E5-AB99-B72AFE12A8AA",
    "24690.pdf": "3168138E-5106-464F-B295-DCD5596FDC38",
    "24691.pdf": "58396AE7-267D-496F-927E-C2DEB4E8A9B9",
    "24697.pdf": "80F42717-96B8-4A47-95C8-E24542F2FD9D",
    "24698.pdf": "AF7AF58D-3002-445F-BFA9-C5833F332999",
    "24699.pdf": "CFC1FBFC-6220-44BE-817C-F6DC70A2AFBC",
    "24703.pdf": "198E5BEC-6392-4E1B-926B-D399BC93E144",
    "24705.pdf": "AFED1E28-1A4A-4FD0-B737-78ADAF5FC58A",
    "24708.pdf": "8D0EC883-1145-4194-B5B6-58421FF56093",
    "24710.pdf": "1D50D37F-9623-4DA1-B2C1-88DFB96E09B1",
    "24711.pdf": "4F7F6087-0520-4E87-8CAF-BC9E6C36F54F",
    "24713.pdf": "45A362DD-F2B0-4E9E-B3AF-A0335BEF167B",
    "24715.pdf": "4DE90EF0-2A7F-46F3-AD68-8DFA6DD2559F",
    "24716.pdf": "ADF74195-F47C-41AF-84BF-E71C57B3C36C",
    "24719.pdf": "8179D891-5D06-4D5C-A818-852967B6B5EF",
    "24720.pdf": "CEFDDE34-8119-4C49-81B3-FAB43AF924B5",
    "24722.pdf": "CE1135BF-9780-42C1-9387-11D35C1FC59B",
    "24723.pdf": "043EF57E-2C7F-49C2-8BE3-9F2236AD8EF9",
    "24724.pdf": "2988D219-91BE-48B7-9855-EB97E3C4049A",
    "24725.pdf": "B050468D-C678-485C-A91D-F874A32F3BD7",
    "24726.pdf": "335467B0-6531-4D5A-9431-08FCF0FF5FDE",
    "24728.pdf": "7A5ECEA3-4DA2-4C7D-8405-DC12EDCF19EB",
    "24729.pdf": "3046B92D-5583-4F7E-886D-0595E4825045",
    "24731.pdf": "690825ED-4C48-4F8E-9E56-4ED17F6A4BE5",
    "24732.pdf": "E5760E35-E422-4BC5-B6E2-ABC6FA6D2E5B",
    "24733.pdf": "A05D8F19-E4DC-4818-8517-A6CCFF3FD83E",
    "24734.pdf": "78FEB79B-3F65-4CD3-8BF2-70E45109369E",
    "24736.pdf": "7BD20FFB-E5D7-46E8-97BF-4FA3D7437554",
    "24737.pdf": "A554417D-C1F2-4D92-800B-87362B047BCF",
    "24738.pdf": "C9F3CC57-D8A6-4E11-9610-84069FC05615",
    "24739.pdf": "FA2C5A15-7E3C-4438-B85B-CE7FCA35F84A",
    "2474 Support Letter FINAL.pdf": "195092FD-77D8-44DD-A725-B54C6FC60FAC",
    "24740.pdf": "49704F04-ABF6-453F-A3B5-6B3F0B140CAA",
    "24742.pdf": "48D3A0D6-FBF5-4561-928B-F7C342F5A59A",
    "24749.pdf": "D9D31454-67A9-4598-9A6D-C2A28AED5907",
    "24751.pdf": "0F614FB6-8607-4313-A98F-5C0CB3EEBAE7",
    "24753.pdf": "D0245B27-67EF-4B3A-87AC-FB97CCECBC0A",
    "24755.pdf": "5A76D1A7-FA47-49BF-8AD6-1F0F37760637",
    "24756.pdf": "AB44C509-BD34-4E3F-9224-916FEA7635F3",
    "24757.pdf": "568CEF70-8CBD-4ED0-8AA7-02F8B582BE55",
    "24758.pdf": "58C1F555-2E2A-4924-A3F4-E1182EAC54E8",
    "24759.pdf": "FB740A25-6D4C-41F8-B450-080F505BD7A5",
    "24760.pdf": "D24E3AE9-0379-4625-8CD3-F8A83DC91BA2",
    "24761.pdf": "FF8EB10D-35F2-4C80-B916-DEC729086479",
    "24762.pdf": "10CC11CD-6139-4161-A5E5-AE2D22A0ACAA",
    "24763.pdf": "EA7F29D0-B4D9-4789-B326-A58F48A161A6",
    "24764.pdf": "7BAC8B58-1B75-4534-8BAC-2377611EB1D3",
    "24765.pdf": "A62EEFCC-3D28-4466-9E4C-B7F3E12D3D62",
    "24766.pdf": "7F2CF19C-A6B4-4AB3-BEEE-F2EA5750B7E2",
    "24767.pdf": "D6DAA691-ABEE-49F0-B24C-1AD8168D2274",
    "24769.pdf": "3B102A78-EE2D-49B7-86A4-DB2893F4E41B",
    "24770.pdf": "D2088D7E-4831-43EF-95C5-83ED6BD9BE62",
    "24771.pdf": "4B16BBB2-E872-492D-BE5F-B8A6EF8C1CD2",
    "24772.pdf": "47E1EA9D-32CC-4B1D-8CB3-87CE8B00E949",
    "24773.pdf": "87B6422C-375E-492F-8F64-113A0EFF4063",
    "24774.pdf": "BCEFA66B-D215-4946-8835-CD2C06581648",
    "24775.pdf": "32EED50F-0395-4F7E-AC73-B285C8B31540",
    "24780.pdf": "69587629-5753-4AC7-8F93-436F161781FD",
    "24781.pdf": "21BADADE-A619-402F-8F5D-A945B12097FC",
    "24785.pdf": "892D5929-CA22-4548-80EF-A8BA1501750E",
    "24786.pdf": "8CAC0488-929E-4327-8C4F-32DD16A403AA",
    "24791.pdf": "081399A6-DE45-4818-B5B5-FE22AD83BCDD",
    "24792.pdf": "95401938-A81F-47FD-AD52-A274E917B450",
    "24795.pdf": "4A5B9A5C-3E55-40E7-B9CF-699AD78FC7F1",
    "24797.pdf": "4A9D8FF1-E7E1-4038-ADEC-BCCEA5D52D65",
    "24798.pdf": "4C5601AF-C45D-497B-A2E1-0B14DC391AA1",
    "248 - Gilbert (Human Conflict).pdf": "D84F2778-DB9C-467A-9007-ABE21F5D7AA4",
    "24801.pdf": "CC6A962C-5BD7-4341-9DB5-A795A966D74B",
    "24802.pdf": "11842494-CCBC-49BB-A9BD-5B13B4A6BA16",
    "24803.pdf": "0AA1917E-5261-44E3-85F7-5FC84888FD9D",
    "24804.pdf": "1D47A25A-0346-4507-AC66-97508E6CCC10",
    "24805.pdf": "FF07D2E4-CFD2-4BBF-8EC1-C7E207EF822E",
    "24806.pdf": "14DCC0D9-DAE6-4F67-9570-BC6927342D98",
    "24807.pdf": "800CF051-6CA9-448C-A515-1BB5D07172AF",
    "24809.pdf": "96979499-6505-4920-B725-E01C8FE43384",
    "2481_BOOK.PDF": "E2493DD1-2A41-4762-9C3B-01C529E2D163",
    "24810.pdf": "5BFC373D-2EDA-4225-855C-60462306C601",
    "24811.pdf": "A0103034-CCA6-4876-88BC-C663CB45FA4D",
    "24813.pdf": "20B7542D-6E7A-4F7B-B289-907F3E64D0B9",
    "24815.pdf": "AE0CE9E1-5F25-4B78-8DDC-134E68655491",
    "2482_PROGRAM.PDF": "56C5D719-E376-48D9-9A42-392BBFD490E5",
    "24820.pdf": "2F21B5FE-16B4-4B9C-983D-EF564EFBF22A",
    "24822.pdf": "CA0DCEE8-13A0-40ED-BE29-D69295631788",
    "24823.pdf": "B2AAE542-F6BB-46DC-A152-36F596C672C1",
    "24824.pdf": "E6FC7424-C213-44C1-A274-D2FD5EFFE923",
    "24826.pdf": "11633211-78F6-48D5-9AEB-DE5F00CCECD5",
    "24828.pdf": "ABE6F861-7F73-45E0-9317-12F027CF6CBB",
    "24829.pdf": "8914CCAE-3E47-473E-97C8-44A4BB5013FD",
    "2483_MEMO.PDF": "A910C216-377F-44E6-842F-91C06EC81A88",
    "24833.pdf": "A1B70CF4-0BFF-4EF9-9D0B-F82552F790F0",
    "24836.pdf": "44B306D1-6C9F-4A38-8F57-C74ABA9E86F5",
    "24837.pdf": "55683298-61B0-4420-ADAC-4CDBB5AB17AC",
    "24838.pdf": "A75586D6-71CF-4D48-AAA0-EA14335CCFA8",
    "24839.pdf": "AA141263-E790-4349-AA43-034D047E3345",
    "2484_PROGRAM_compressed.pdf": "721E6A13-734B-4A23-896C-B391AC5AF76E",
    "24842.pdf": "239E3DE6-791F-4242-95B5-4C3B7C700A2F",
    "24843.pdf": "1DD276D8-7F46-4DB2-A60E-D7CB2987138D",
    "24844.pdf": "00588A49-2EB7-4B33-97CA-365466B11ACB",
    "24847.pdf": "0E336F3C-B4FF-473D-9D10-ECE7DFB0A4FF",
    "24848.pdf": "4EBE465D-AFB7-4922-8634-2B4A45744BFD",
    "24849.pdf": "8400F99D-BB7D-4D82-9A8A-90912EC520EB",
    "24850.pdf": "1616660E-F336-472E-90EB-824732DEAA6E",
    "24851.pdf": "B34E2FB4-0331-4B47-BDE6-CEB5F416DBE2",
    "24852.pdf": "E02119E6-0DF4-4E33-9A7F-BDA8B49CB345",
    "24853.pdf": "6B75808A-B059-451E-AA10-BE9499C589C6",
    "24854.pdf": "3190DCCC-048A-45E8-936E-7451862A8509",
    "24855.pdf": "33386B24-C486-45E2-B757-86191D93F674",
    "24861.pdf": "1395E145-BC6A-475F-883E-FE7F2098D850",
    "24863.pdf": "F20BBFF6-280A-4995-85F0-C5484F9572C9",
    "24864.pdf": "89398F30-3E7A-4188-B408-73026738EFDC",
    "24865.pdf": "0633203B-BB5C-4CF4-9F34-42919148C4A7",
    "24866.pdf": "B2946B1C-CE30-4D14-A3FE-FCD93746CE52",
    "24870.pdf": "61488303-8BE7-4298-B3C5-09946D4546FA",
    "24871.pdf": "539DEF77-5ECC-47EF-9DB5-6198951B29BA",
    "24872.pdf": "53552F19-2AC4-4D80-85A3-98EFF855E643",
    "24875.pdf": "EC2DE6A3-66E4-479A-905D-821A4CDBF0F0",
    "24876.pdf": "4299F36A-9B16-4572-93B1-2076CD1452D1",
    "24877.pdf": "974B7613-9C28-4881-8048-624836F69048",
    "24878.pdf": "2E0229D0-5B1D-49DD-8303-585048C25DAA",
    "2488_BOOK.PDF": "4D199C4C-8873-4C35-9C88-7A0AC4403CA5",
    "24880.pdf": "18EED446-17C4-426D-B489-440CE76DFCBF",
    "24881.pdf": "2C747375-0B7F-4B5A-8181-045905043B63",
    "24882.pdf": "5A1C6393-A1B6-4946-B2EB-7AA062919A5C",
    "24884.pdf": "716E18F1-7CCE-43C3-81F9-1A6A6AF5B2BB",
    "24885.pdf": "BD8063DA-6ED6-4086-A99A-131C35ACB9A7",
    "24887.pdf": "C6D98BBF-B575-4E12-854E-D3FE7BA04CF1",
    "24888.pdf": "B37E8A6D-B9D6-41D1-986B-24646CDE5F5C",
    "24889.pdf": "ED53906A-9A1C-48B0-AF54-F713FA464F7C",
    "24890.pdf": "96410F55-644E-4A7F-9E34-CA63097923CF",
    "24891.pdf": "4A10E8C9-82B4-4351-92B8-7B1145AB0368",
    "24892.pdf": "14D8CB0E-DB50-4922-92E5-34C40B24D5B5",
    "24894.pdf": "3554A041-E42C-454A-BB36-D0FE92B84B9E",
    "24896.pdf": "14ED56D6-FFC9-497F-8330-628327DDCDA5",
    "24897.pdf": "2BBE4A1A-B22F-446B-8338-146A9EA0AB7A",
    "24898.pdf": "1404398D-902B-4D8C-B92B-ADA916D291AF",
    "24900.pdf": "46EE59FA-6BFF-48F2-B98A-2AD6EFD05250",
    "24903.pdf": "D37588BB-9D18-4FAB-BF69-4FAC39F1BAD4",
    "24904.pdf": "938CB129-711A-4C23-B269-397FD70E51A6",
    "24905.pdf": "CA9D1B85-E5AB-4D7B-B844-91506B79E765",
    "24908.pdf": "54A6AD9D-76E1-4492-A040-30E047AD5C6A",
    "24910.pdf": "114EAE3C-2C43-4E95-8F8D-66AFD607B2BE",
    "24911.pdf": "DCC7850F-2EF1-480D-9ABD-64C5B61FEE19",
    "24912.pdf": "B7AFA12B-75EA-4967-B245-5783829C0581",
    "24913.pdf": "18651ACE-F0D2-4ADF-A865-442E43CC127C",
    "24914.pdf": "B3C24C52-C5E8-47EE-B04F-FDFD23B30C12",
    "24915.pdf": "1E3226E1-4FE0-46D1-A050-F71920C39A24",
    "24916.pdf": "523BFEC0-E64F-45DE-9C04-795E2672A9EA",
    "24919.pdf": "42C8244A-2FFC-4726-9D12-3666816AB086",
    "24920.pdf": "BDDFDF89-B75C-4740-8F52-301C272C6B69",
    "24921.pdf": "C961EF16-E94A-4D74-B29E-B69D51E7EBB9",
    "24923.pdf": "42046B31-BDA5-4AA5-B806-B66E91F77B0C",
    "24924.pdf": "A4B0A1EE-FD84-4CAD-98F9-05BFDBF8D33E",
    "24925.pdf": "DF50A901-8849-4A30-B0E3-F0EAA6F14A53",
    "24926.pdf": "5B24DC49-FD61-40EE-90EB-F1D5102B2AAE",
    "24930.pdf": "E4E6A7A1-F2AD-4578-8C80-CAEC696FBD65",
    "24931.pdf": "310937E9-5742-469C-A082-4318A8BBF5CE",
    "24933.pdf": "81D7BE37-70E3-40D4-8A3E-0B03A3282A38",
    "24939.pdf": "7B239FFA-BBCA-48D6-B4DC-D13DFCF70F15",
    "2494 CNS-Poster-.pdf": "A6D010C3-FB13-45BB-85C0-B46EEC51E166",
    "24940.pdf": "42B89105-254F-439F-9625-0CF353FA44C0",
    "24941.pdf": "30A45017-B6AD-4774-83F8-F0EA63FEF1B8",
    "24942.pdf": "B962810E-FE05-477A-A1F9-36921DF532BA",
    "24943.pdf": "409CDDB7-6178-4CB8-BF23-745BB596D1EA",
    "24944.pdf": "EA595F60-6D3B-45E9-BC87-88C6DBFA54E2",
    "24945.pdf": "D93EF8C2-539C-4089-8947-622668C2CD0B",
    "24950.pdf": "E3D6F2DD-D88B-4120-B322-E9162D63D764",
    "24951.pdf": "2445AAE9-3025-4939-B698-5A30A48A95A7",
    "24952.pdf": "86CD4863-D1C8-456A-B9B9-DF5A8274BD5F",
    "24953.pdf": "CD5F3DF0-296D-4114-8CDB-63CAAB4FBF79",
    "24957.pdf": "C830A9A4-FF1C-4717-97B7-AED3F800C0AA",
    "24958.pdf": "41F60377-D324-4CE2-AA80-6A5BB296F0FC",
    "24960.pdf": "D3D24D5F-F8D3-4D57-ADB1-132ED0D76131",
    "24961.pdf": "8FECF0C9-59B4-4A78-AAE7-E98CC865F98F",
    "24962.pdf": "957345FB-068E-4EA4-86A2-DBB6C3B9E79D",
    "24964.pdf": "CF72A610-B9AE-4A83-9B0E-91CC7B08922B",
    "24965.pdf": "2AD881B4-DFCC-4C7F-8B1A-4437E242CE4D",
    "24966.pdf": "72472B82-B7CA-4923-AEC8-3DFCEB363462",
    "24967.pdf": "5098A5D5-7C36-464E-81F6-4534045A07A9",
    "24969.pdf": "151ABE07-A70B-4DBC-9BC3-156447F99217",
    "24970.pdf": "71A6FB99-F69A-44EE-A6AC-ACD265271A69",
    "24971.pdf": "05443D70-53EC-4947-94F3-10A17D9F79AD",
    "24972.pdf": "43C963C4-A838-4884-BCDE-DB1C8577FCFE",
    "24973.pdf": "43845670-BE85-46AC-A24E-D7AE363D4BA7",
    "24974.pdf": "61E4AA5B-6A38-4AA7-AEA7-D06E65938647",
    "24976.pdf": "95F16B86-6D43-4D27-87C0-7AF3B8827456",
    "24977.pdf": "845B1E44-14BB-4A43-9E3E-F5E25CF9CDC5",
    "24978.pdf": "CB3C9425-1F41-483A-8721-9E224EAAB06E",
    "24979.pdf": "4E263CB4-4D63-47B2-8FD5-FCF7EC7D448A",
    "24980.pdf": "62C78960-B5D4-4B91-BBA8-377BB1D88393",
    "24981.pdf": "C26E316E-414D-4930-B49E-E1C5B055EDA4",
    "24982.pdf": "B1378B61-3BD6-4DEC-A902-7F8D989F4656",
    "24984.pdf": "FF1FFE95-4EF1-4F33-BA43-A08F2B6F0223",
    "24986.pdf": "A99DCBF3-73EE-4B01-BC9A-D7860AD1131C",
    "24988.pdf": "04E6B307-9A09-42EF-96CA-92572BBC55AD",
    "24990.pdf": "B277BA0E-70B5-4101-9CAD-3C6AADD18F40",
    "24991.pdf": "11F28821-89EB-4BC7-9CFE-E3FC32F30B99",
    "24994.pdf": "6C63094B-9EB4-46E7-9EB3-13671EF74B7F",
    "24995.pdf": "586AEB5B-FB03-4F44-BF62-FB97F1AECDEB",
    "24997.pdf": "C369BBD4-5735-41C6-8C92-1301AFACBB01",
    "24998.pdf": "E2A56B87-73E4-4B66-949A-BD32E7F52A32",
    "24999.pdf": "D4CF0610-F6F5-461C-AAB8-1FFB456CE0E6",
    "250 - Gilbert (Baseline).pdf": "B704F14A-DF7F-4C9B-8E4F-67932858BDA6",
    "25000.pdf": "18F4B4E8-DC2F-41EA-9D29-4C103FFF1372",
    "25001.pdf": "C6F223CE-2B74-43D1-933C-2FA920D3FB1F",
    "25002.pdf": "D9302C06-2D85-4F07-BEBB-BA0175114B14",
    "25004.pdf": "42CDE2D5-9FD1-49C9-B862-5C9A8A4212F2",
    "25005.pdf": "127B882A-6579-49C6-B8E0-82E17C3A025D",
    "25009.pdf": "7028CB0F-A62B-4BA8-A8D9-3FA6C7E595E8",
    "25010.pdf": "D01BD3D3-9AB4-49F4-ACCC-2169366BAE43",
    "25011.pdf": "C4BF1E7A-6E7B-4D8B-B673-1B2FACA0E611",
    "25012.pdf": "F32667BB-456C-48D4-B429-729365EE8867",
    "25013.pdf": "F663CE9E-25DC-4975-9FF8-B25A59BA670D",
    "25014.pdf": "BDD77CBA-9E93-49ED-BC3E-063CB9974BF2",
    "25015.pdf": "040AC740-5BED-43F7-AEA8-5CCBB2FCAEC1",
    "25016.pdf": "E862BD02-CFE4-4E5F-803F-91B6AFD2F0A6",
    "25017.pdf": "B472CDA8-1EE9-4679-9E55-A844A193AF69",
    "25018.pdf": "126EEC28-2282-44A5-AF2F-E34D33E1737A",
    "25019.pdf": "A303FB15-29A9-47CF-9ED5-AEA9BC269FDA",
    "25021.pdf": "9046D355-4E0B-41DB-B7A4-DA9A522DB8B8",
    "25022.pdf": "FD444FDF-C67D-4D75-9421-F61ABD954080",
    "25023.pdf": "77CA8C95-7312-4D19-A4AA-F55C9432DB8C",
    "25026.pdf": "20D85224-9E9E-4C26-9C57-620FF1AE0C8D",
    "25028.pdf": "7D8F6F15-25B2-4532-82E1-877969E6D506",
    "25029.pdf": "E6110BBE-0C8D-4F5A-943B-E02AAFCDB241",
    "25032.pdf": "566DF47A-435A-4D89-8C83-F2BAE1076BCA",
    "25033.pdf": "D60BE7BD-F224-4262-872E-577A05B5E1C7",
    "25035.pdf": "BDB5CFEA-EDB0-4EFD-9852-CEFB784670D9",
    "25036.pdf": "C8E99AA7-0E02-4467-96AD-3E4D352F381C",
    "25037.pdf": "8C73143E-4DFD-499D-BDE7-93D652CFEEB4",
    "25038.pdf": "BBA80100-A975-42EA-84C7-369F26AE1026",
    "25039.pdf": "DC77F6FB-AF22-450B-B387-BAA177666334",
    "25041.pdf": "340C3B2E-ADAA-431F-9B2D-71601ECABCAB",
    "25043.pdf": "D363D701-D232-443D-A01A-D66C43AF4532",
    "25044.pdf": "3D4A5303-A0AC-4157-93BA-5301CD0702E0",
    "25045.pdf": "4C85C2C0-19CA-4FD8-B132-7376C7AB4D88",
    "25046.pdf": "CFCAA87B-F952-4536-870B-698FACCD8807",
    "25047.pdf": "0A4D8DEF-16E0-4294-A4CC-8634CD9BA4DC",
    "25051.pdf": "B21E6E50-9807-409F-9DBA-419C8090CE83",
    "25053.pdf": "67DDBF76-CA7D-4D17-8EC5-7914CA419981",
    "25054.pdf": "CFB495BD-A38D-405B-B731-90AF14529DDD",
    "25055.pdf": "087243E2-318C-4E78-AE3A-BB7D68C6480D",
    "25057.pdf": "15747DA4-AB70-4619-851C-33A4C7C0A908",
    "25060.pdf": "77FC935C-9EA3-492F-9168-A585081DAB9F",
    "25062.pdf": "5954274A-093F-4F52-B156-766BECF3F9B5",
    "25063.pdf": "C66D1CAE-49EA-467A-8B83-55550E3C4A7B",
    "25064.pdf": "352C2756-87E0-48C3-8D82-2912C3F5A117",
    "25065.pdf": "4AE100EF-D427-4FD7-8495-7B2272A7C08D",
    "25068.pdf": "575CBB21-5FA4-472B-A037-ADCF746907B3",
    "25069.pdf": "EA571C40-6C50-4A6F-9450-9DE8CDB29383",
    "25071.pdf": "6C7185BA-01B5-42F3-9765-497CDAF5543C",
    "25072.pdf": "A9BBA3C5-9C9D-4529-9650-4273A3F0CE2C",
    "25074.pdf": "DF4ACE8C-7E61-4F51-B649-02C214B2F441",
    "25076.pdf": "85827D81-43C8-4885-A999-67E8331BD00D",
    "25078.pdf": "26768BF7-1D93-4311-9CFB-32DBFE31B794",
    "25079.pdf": "F5585249-A797-4567-995A-AA65DD786957",
    "25080.pdf": "E38A5FCE-049E-4A9D-9675-35100C65B4E9",
    "25081.pdf": "F1B75BD8-89C6-48DA-AEF9-7045335ECD12",
    "25085.pdf": "191642A0-A732-41DD-A2D4-5D22A15BE685",
    "25086.pdf": "4DD56C1E-53AA-4B9E-9826-4E71CEB9F863",
    "25089.pdf": "F8A8C5F9-7DF3-469F-911A-976A4B0840F9",
    "25091.pdf": "608CF850-A701-4025-99AC-F8B2DA87FCF1",
    "25092.pdf": "A12EA3A3-6052-482D-A596-C337BDA65E7C",
    "25093.pdf": "C4A18A16-D4D8-4479-B6CA-63E503011321",
    "25094.pdf": "BCDD4487-5228-4F6A-B677-602A590FC911",
    "25095.pdf": "25CFACB3-1742-40DC-86F9-6517E37B7FC4",
    "25096.pdf": "2738B0CE-97BF-4493-91C0-BE833EF4A29D",
    "25097.pdf": "57266CB9-4650-4F86-958E-1F3DEFA0F33A",
    "25098.pdf": "A1669D01-11C6-4ABF-91F0-B0359D244F82",
    "25101.pdf": "0A8B2FB3-4EB9-4733-A3AF-07024C082E37",
    "25102.pdf": "3552B661-EDF6-456B-870A-BBBA2CECBF0F",
    "25103.pdf": "9BE54902-197F-436A-991B-239BCB8B3EF3",
    "25104.pdf": "B7C87822-9501-48A3-8731-C33BA36BA569",
    "25105.pdf": "E8F44C19-A602-4E0A-815E-086D5E988442",
    "25106.pdf": "7A9078D3-481B-49CF-ABC7-5F500B83012B",
    "25107.pdf": "C65C384D-2A8B-4021-923E-BEA9D9AABD7C",
    "25108.pdf": "39BEB7D2-2ED5-4184-8ECA-60A46122C174",
    "25109.pdf": "7A1F47AC-9AD4-408A-85CE-61B60FC54912",
    "25110.pdf": "1672C348-0083-4276-9835-C440ABAB25E1",
    "25111.pdf": "97374545-D2B7-41F1-B9A6-4BE2AD630792",
    "25113.pdf": "61EDD610-7AF5-43E6-B946-1588B22B6995",
    "25114.pdf": "A2773D8C-F603-473F-B771-AF408C12C3E0",
    "25115.pdf": "14C39B54-BB3A-495C-888E-085272006708",
    "25117.pdf": "08EE0A4C-5BAD-4CE2-9D88-48F96DF6AC7E",
    "25118.pdf": "05F6406F-A405-49AD-9836-C418F296F5A5",
    "25119.pdf": "5918DD58-9266-4D12-AED5-3D2010E9125F",
    "25120.pdf": "3940B69D-03E3-4ED2-AC6B-6BF398E775FF",
    "25121.pdf": "BD3BDA2E-3866-472B-9A00-DD51487BFD8A",
    "25122.pdf": "223DD83F-C852-4157-904C-BEB14F29BF80",
    "25123.pdf": "45D61D2D-8706-4506-B7CE-48BCF54ABF4B",
    "25125.pdf": "4D2C0574-EB29-4D7F-8354-5FB0581A6BA8",
    "25127.pdf": "F5FB26B9-8A60-4B3F-AE81-2B12A497C0C7",
    "25129.pdf": "219BF9E6-591B-4EB9-84AF-77473F99BBCE",
    "2513 Meningioma poster - CNS.pdf": "CDAF786F-4AA1-49DE-928B-2B0743F35409",
    "25131.pdf": "0997E2B3-833F-4B69-B0E5-A2B84552C239",
    "25135.pdf": "B27CC5AA-0813-4483-881C-4239487FBB40",
    "25136.pdf": "47A63231-E8D5-4FAD-A5FA-8794B2F9F2BA",
    "25137.pdf": "702F8026-77C3-4D2F-8AA9-6659322BBE5E",
    "25138.pdf": "4E7486BF-858E-42BC-B67C-DFA0118199A3",
    "25140.pdf": "951AC640-9444-47C5-AB14-F72C9F38C16B",
    "25141.pdf": "DD909494-60B3-40A4-B656-ED082684BAA7",
    "25144.pdf": "E181575F-1D0D-4E2A-B4E7-E607DBFCC036",
    "25145.pdf": "FA5DB649-DE73-42FC-A744-F26A7E64D6C1",
    "25146.pdf": "5229442B-52C0-4427-B4FD-53AE4CC76013",
    "25147.pdf": "7EDA5536-3F53-4E2D-8CB6-9D0E0DACBF36",
    "25148.pdf": "D61E26DC-59AC-4AFA-8A38-428F82EA697E",
    "25149.pdf": "5E0B1923-058B-4C44-8839-2C0FB8B2280A",
    "25150.pdf": "6EC7959C-C3C9-40AA-9CDD-E5A0A3F9799B",
    "25151.pdf": "CE19E2A5-C105-4353-B686-8060A30A3D9C",
    "25153.pdf": "60934C92-6B83-47F8-B113-8159286BCD32",
    "25154.pdf": "F32AFE1E-0CBC-49E1-870E-2AAA24385790",
    "25155.pdf": "E15B46BB-1E54-48FD-A928-AB76807DEE2F",
    "25157.pdf": "A9108A66-B40F-4660-BD19-2EE5D704BF17",
    "25159.pdf": "04A5CFFB-3612-4D64-804D-BE1801A605F7",
    "25160.pdf": "D97055AD-698F-4F8E-AEE2-986505D64ADD",
    "25161.pdf": "89F66F99-A9A7-4B10-B647-02CB043956E7",
    "25164.pdf": "99A0EDF7-9987-4E59-AE12-6D8536245E13",
    "25165.pdf": "1534C0CA-3AEB-4F0F-985B-05C2E6F62B1D",
    "25166.pdf": "5A5C1867-9B77-4C04-B8F5-AB02FAF51687",
    "25168.pdf": "E059BFF6-E80C-4A02-96B8-0AD5288A56EE",
    "2517.pdf": "2B48E85A-E844-4375-B1FB-A03C1C19E0FA",
    "25171.pdf": "76C64D3A-7693-4E69-AE8B-97979AFE493C",
    "25172.pdf": "43AB532B-754B-42BE-9FA6-1AB2607BB0C3",
    "25173.pdf": "D1D44E23-4E10-47A6-B1BE-CA8C81A479E1",
    "25175.pdf": "51F18E42-7420-414E-8B58-28D6367CB174",
    "25176.pdf": "D4B74E71-B67A-4C23-98F2-906D929532B0",
    "25178.pdf": "22761780-E32A-414B-A691-BBA3802F4ED5",
    "25179.pdf": "860206BC-BACC-46DF-AA9D-23BC09C41186",
    "25180.pdf": "D7C9B5B0-24DD-430E-8811-336EF5D6D36A",
    "25181.pdf": "F2250440-F304-4C12-882B-85EFE6D9FEBC",
    "25188.pdf": "882013E8-EA34-4101-BBE5-9CFD1A9BF997",
    "25189.pdf": "66BAAEAF-9BCF-4CE2-8D62-066860CAEB6C",
    "25191.pdf": "F69B64FD-B51E-48CE-989E-110A4DCF359A",
    "25192.pdf": "4F3229A1-CC7A-4BD5-BDE3-BF36DBC8A5EE",
    "25193.pdf": "1E37DB11-F558-4119-957F-65C0D92AD564",
    "25194.pdf": "3098CE3A-78CD-4805-81E3-5DF608479258",
    "25195.pdf": "2FC19F20-5F8F-4348-8395-4C761AAA7461",
    "25196.pdf": "A6DE0D6E-A7E9-416F-97C6-1EE64BAC6027",
    "25197.pdf": "5EABABA9-F988-4B8A-8191-C31EB25E2D76",
    "25198.pdf": "CEED8EE2-5586-4656-9951-F6C718C0D1B6",
    "25199.pdf": "2908E315-9657-4206-9AB0-7F625880CD1A",
    "25200.pdf": "35324C84-AB0A-4D36-93D3-3A9B15796A56",
    "25201.pdf": "BF3D497D-9DFB-4D3F-AC1D-BDC0392E83F4",
    "25203.pdf": "9BAC5C00-F4CB-4837-9366-0079D2634858",
    "25204.pdf": "417A46ED-6FB2-4627-999E-625451B2458D",
    "25205.pdf": "246EA382-2842-4066-AF71-D7FE5FBF2B97",
    "25206.pdf": "A380A955-1AC5-4141-A45E-08DFF91E9D6B",
    "25207.pdf": "FD0C02CD-703E-41ED-9DF3-C1B814022A1C",
    "25208.pdf": "237076E7-4923-48C3-8919-01C9B6A9857B",
    "25211.pdf": "F555FCD3-FA20-4BD5-AA4B-8A9449D097FC",
    "25213.pdf": "A4FC277F-92B6-43DA-8EA8-C7550D3B7889",
    "25214.pdf": "B0C93292-E919-4FD7-AD0F-347210ACC4EB",
    "25215.pdf": "EF4FFA6B-F7DC-498E-8201-8CE103C5273E",
    "25216.pdf": "CCF67166-1B78-4C75-9BBA-3537A1DB0974",
    "25217.pdf": "D90ED303-D487-4882-8673-AD2C81883DEC",
    "25218.pdf": "6F2B749C-3264-4817-8134-727AA00E4B7A",
    "25219.pdf": "4CC97BCC-7A65-47F3-B9E2-4A98FF836DB2",
    "25220.pdf": "A1E47452-984A-48F1-9715-D5765EC78DB9",
    "25221.pdf": "C1581399-83E6-41DB-9A62-4C4EE6389000",
    "25223.pdf": "A0FE7A44-4BA1-4D7E-8910-7ED22CE24E89",
    "25226.pdf": "6EF6B7A8-D1B8-4D8D-BFF5-669B1152B476",
    "25228.pdf": "2D68F3DA-9022-47DD-A3A8-849EB566C8CE",
    "25229.pdf": "0EA08D56-DA67-42B0-9EC1-174AA2F103D1",
    "25230.pdf": "9DF81273-50D4-4B54-B272-B83067AA8052",
    "25231.pdf": "C21306C0-8683-4237-AF45-C2761A62FF23",
    "25234.pdf": "1CFA2F0B-92A3-43EC-B79F-20C0D2F9E65B",
    "25235.pdf": "3AD36861-B662-4E94-A984-5C4812192AD1",
    "25237.pdf": "2C5EA8B8-21E4-490A-B360-64DAC0092949",
    "25239.pdf": "BE5D37B1-794C-45B2-9DC6-952ECFCBF212",
    "25240.pdf": "19A264EC-5F05-4A5D-A58F-9B0F47596863",
    "25242.pdf": "1F7A756D-78FA-4318-A407-73DC41429D3D",
    "25243.pdf": "8BDAE5AC-A711-4850-876C-4C81645F549B",
    "25244.pdf": "6AF33E36-D0CD-465B-88E8-BFA1F64DED96",
    "25245.pdf": "FB6CA855-B0A8-4C7A-900C-17C38311CC88",
    "25246.pdf": "1FDA33A4-7489-4AB7-82A9-B68ECA875D39",
    "25248.pdf": "C43FD722-05E2-4A40-BFB4-0072B2032B22",
    "25249.pdf": "F7C97149-F782-4186-A6AF-6543D33617BB",
    "25250.pdf": "4AB362F8-39A9-4DDF-A3F4-6C5D2D80FBDF",
    "25252.pdf": "6DF945B0-C6AD-4B52-A659-B932F2311AD9",
    "25253.pdf": "0AAB3FAD-DEE0-45C9-B303-880415848A7C",
    "25254.pdf": "B7B04A67-696A-4ABB-AD6F-6516D8F64ED6",
    "25258.pdf": "C6BA4E27-740B-45B0-83A6-A950473F5AD7",
    "25259.pdf": "024C836B-E36E-49E1-9E09-26F613D1E6AA",
    "25260.pdf": "CB9A5FF6-B3C9-4461-BAD1-B87EBF368B36",
    "25261.pdf": "BB0327C5-CF84-42E7-80CA-345A9B4FD601",
    "25262.pdf": "DBFD6FC5-E2C7-48A5-8802-BE27DADC459D",
    "25264.pdf": "21A8C8C0-F9D8-4C0F-A88E-95176C2B97C5",
    "25267.pdf": "8F18E3A2-6A6E-41F6-A22F-9FA5AE530627",
    "25268.pdf": "BEAA053F-4992-4992-93E0-2D5DECFCFAE9",
    "25269.pdf": "710CD705-BD5C-46E5-8408-A8DB07999E75",
    "25270.pdf": "D6C9F83A-B532-41CF-A456-F768C7BBD66B",
    "25271.pdf": "13675CB6-F447-46E8-A5AE-F42D9AD7C4E1",
    "25272.pdf": "E2FFBE75-9848-43C2-8425-DFD4B6795C6A",
    "25273.pdf": "ABB0446B-4670-44EC-B143-54470D0DB563",
    "25274.pdf": "7DDB5614-5BB7-45B9-8C12-1397722AC25E",
    "25275.pdf": "74DFB231-3D9B-4B24-B845-9DE56BB5BB73",
    "25276.pdf": "BFF41C24-C18A-4C7E-AD74-3638270598A5",
    "25278.pdf": "AD1E1544-CB2A-4E49-803D-533D1682D519",
    "25279.pdf": "2A580A14-D531-446B-BBCE-4390128CB588",
    "25281.pdf": "61244E51-6F02-4D51-B423-8EA2D71F08E5",
    "25282.pdf": "C410FBAF-7B1A-4524-BA33-D92B4832D5A2",
    "25283.pdf": "27D06B6C-DA7C-49D6-AF85-3F40360A7F99",
    "25284.pdf": "49FE63CF-6270-4C8F-8560-E4A9CBF61233",
    "25286.pdf": "40348622-5A2B-4BCC-8685-B8E5EFE6BFDB",
    "25287.pdf": "B2C9547E-132D-47FE-9592-C54820C09137",
    "25288.pdf": "3F0E72DD-C7BD-4F70-8819-A72F92886EBA",
    "25289.pdf": "FEE209EC-5FD2-4E88-961B-C4CF8CE1E890",
    "25290.pdf": "FD98275B-7D4D-40E6-A2CE-84A7873DE592",
    "25291.pdf": "6F30255E-DE8F-4E01-8770-D592FB8A0513",
    "25293.pdf": "2506AF5A-A52C-48ED-B84D-7902A925DDA8",
    "25294.pdf": "ECB7008E-CB5B-4FA9-B05D-0995194FAE54",
    "25297.pdf": "CC930099-FC61-4519-ACDF-C3016FC63980",
    "25298.pdf": "ADE012D4-45BC-4414-81C0-8C444355B108",
    "25299.pdf": "1E5471C9-35F7-4D9F-9EFB-367AB9AC3BFB",
    "25300.pdf": "8A418C38-750C-488D-B32E-E2DB08952098",
    "25301.pdf": "9D4A20CA-AB22-4DDC-810C-A209C2E0BE60",
    "25302.pdf": "E364580E-6894-4729-91B4-9DE4732901EF",
    "25303.pdf": "5336B7EA-FBFD-45F4-9743-F4AEBC159D43",
    "25304.pdf": "48F5FEDD-D9B2-463F-BC68-94ABC995C8B1",
    "25306.pdf": "AB354000-7AED-488D-9EA3-B955F18894FE",
    "25307.pdf": "9E168737-D355-49F8-8881-F351F11A9561",
    "25308.pdf": "7CE91D86-75B1-47E3-BE2D-8A9FBE48C706",
    "25309.pdf": "1AC3D2D0-025E-4F3D-81C9-F2FE0F240828",
    "25310.pdf": "E6F029AF-8CEA-42F1-A11E-FF22A9B6B5F4",
    "25312.pdf": "9792E831-EE2B-4A0E-868B-ECAEEB3CB0B7",
    "25313.pdf": "15A91E72-9FEA-45B3-BD06-2A6336C96A00",
    "25314.pdf": "ACB08387-4282-4AAB-848E-D880E921BE0D",
    "25315.pdf": "2297B443-41A4-4222-A8C2-31B0C08A9C9E",
    "25316.pdf": "555644C1-A247-4C45-83BE-6BF709DB9240",
    "25317.pdf": "1F881A8D-800E-49A2-962D-420248FA5171",
    "25318.pdf": "260BC5A9-8F64-4DB3-940B-AFF36F1DD8EA",
    "25319.pdf": "E45DE290-962B-4470-B18F-9BEFB07C4913",
    "25320.pdf": "CE172A5E-4236-4A5D-AA0B-A1356032C54D",
    "25322.pdf": "80A21868-63B2-4A93-AFF0-6BD1853F9435",
    "25323.pdf": "5556983C-F267-4178-AC82-EEBF5D542929",
    "25324.pdf": "B5E799F4-1AC0-4716-AF05-32C219572575",
    "25325.pdf": "57AA8A20-405C-4814-8C67-CF8BCD69E49D",
    "25326.pdf": "5B071136-5112-444A-AC42-0A171B5B88FC",
    "25327.pdf": "F9B74DD7-C446-4CF9-A0BA-7C5991B7AC35",
    "25329.pdf": "2F8CB638-D968-4921-9AA8-7D7E42603255",
    "25331.pdf": "98587AB7-58C4-4196-A24D-E5754E4642EF",
    "25332.pdf": "22C043FD-0A68-4F9D-B54B-C8111A782064",
    "25333.pdf": "E5CA9564-9361-4627-9922-64E646E0BD2E",
    "25334.pdf": "F2ECD0F3-C491-45C9-BBB6-980AE661B51E",
    "25335.pdf": "AE1BDDCA-F47B-4102-959A-496DD0518112",
    "25336.pdf": "224A8D73-FE42-4B16-8109-F6F3C369471C",
    "25337.pdf": "B2F403A6-8E4C-483C-A55A-E065E61971B4",
    "25338.pdf": "FD05DD7C-39C8-4B31-8318-C987D8B9532C",
    "25340.pdf": "1E88D663-0AC5-42C7-8657-FC8C003EB545",
    "25342.pdf": "8ED1BB9C-635D-498B-AA62-C908A98A860D",
    "25343.pdf": "2106343D-FA12-4D75-ADFD-F23D780B78DD",
    "25344.pdf": "3A8CD7BB-F674-4AE0-AF8C-D19F66E034A7",
    "25345.pdf": "888C9CF1-D344-41ED-8923-67FED8FBC4F9",
    "25346.pdf": "318EDC48-DC4E-4FD7-8C1E-AE776DB353FB",
    "25347.pdf": "AB312410-22E2-407E-9683-60C65CAE88A7",
    "25348.pdf": "0154E15E-76B6-4F0F-BB8A-5E758CF40819",
    "25350.pdf": "0EE4C0E9-9118-40F7-B20F-AB6AB66F7088",
    "25351.pdf": "4AE9F2D2-9967-43B7-AE7D-3AE1AAD0988E",
    "25351630.PDF": "F7C55934-74AE-4CC2-BA38-738FC9ADE481",
    "25354.pdf": "D3A7A8E5-7F31-4E75-8564-7D9F1E30F159",
    "25356.pdf": "EB5C9A1D-80CA-400A-8FD6-4B059086BE82",
    "25357.pdf": "04E9C64E-3FB6-46B9-8187-FB17D2F78E27",
    "25358.pdf": "1544A5D5-96A8-49F3-90B8-B73501FF67B8",
    "25359.pdf": "946A9AFA-DD67-4BE6-B404-24E09F7A202E",
    "25360.pdf": "8E86ABD3-A516-4318-87EF-1B4F73C3146A",
    "25361.pdf": "AB959D6D-374F-45C5-8DC4-212C1D9EAD34",
    "25364.pdf": "DCFC4999-DD85-428E-A9EF-46DD852B754C",
    "25365.pdf": "C02C3EAE-6E9C-46A4-B5A1-5CDB7E014894",
    "25367.pdf": "234355B6-C0AC-4930-AE65-3FA6AB03918F",
    "25368.pdf": "3FEC813E-D97A-4845-8668-746FD6A3F787",
    "25369.pdf": "FDC2E214-B44D-4567-A812-90CE82689823",
    "25370.pdf": "55260677-00E8-4C46-A4A5-4C9D8C43AB9E",
    "25371.pdf": "98BDB768-8CE2-4ED4-AF0B-538D8541C04F",
    "25372.pdf": "91438DD8-3EDC-4FD9-AA6D-B359315A5AF3",
    "25373.pdf": "448E5334-8CDF-4366-9F6C-597CFBC6A7C9",
    "25374.pdf": "8CB4FE2C-AAA9-4BBF-A4DD-F05F8E44B744",
    "25375.pdf": "3D5E06DF-76B3-49CB-85EC-7A2A834F36EE",
    "25378.pdf": "96980352-C3D8-4F43-8566-2257A0D819D3",
    "25381.pdf": "983D7A13-E8E8-4C71-BCF9-865EA1B1282F",
    "25382.pdf": "41F8EBD1-9C17-446C-8406-4786A09FFDA8",
    "25383.pdf": "3D26FB53-319A-4142-8E76-1FCDBF33B3C6",
    "25384.pdf": "8BAA8A1F-D15E-4E4F-9E6D-2D241BBD0D42",
    "25385.pdf": "8C91D890-26B4-4DF1-8AFD-DC2C09835187",
    "25386.pdf": "7F61E9EC-5BD0-439F-A92A-690C36E3B2FE",
    "25387.pdf": "B702E5BF-C633-4BC6-A958-AD4DC198FDCD",
    "25388.pdf": "D0180D21-A306-435B-A9B4-A3E68DF4FAAA",
    "25389.pdf": "C24B5964-6F3C-4700-99ED-70F6D732FD80",
    "25391.pdf": "D518F90D-677A-4E69-9E9B-7A80C73AFC41",
    "25392.pdf": "7A26C254-4EA6-4115-A12C-0AF54CE54164",
    "25394.pdf": "E134760B-8EBB-47F0-BEBF-AE29EF85CA9A",
    "25395.pdf": "A9E988DF-E710-423A-8F3B-E32AEE5A4A2B",
    "25398.pdf": "CDD07189-C540-49B1-927F-8A7832569F71",
    "25400.pdf": "3533D537-7F76-48E4-981B-749B91EC121F",
    "25401.pdf": "234644F6-407A-4825-B652-7E9007C3975C",
    "25402.pdf": "8F064E69-ECC9-433B-B668-A95268764369",
    "25403.pdf": "2D8A80B9-ACE5-4F90-B1A7-AAC0A93A8A43",
    "25405.pdf": "AC0A3099-91DA-4553-BA0A-B3D698D6B89D",
    "25406.pdf": "CE3D3DA2-8D0F-45CA-8B35-2B39650FFE27",
    "25407.pdf": "EFE73DFF-D34F-4D41-BDE3-6761F5FA7B2A",
    "25408.pdf": "481D25C0-6881-4DCD-854A-EBA6B0FF7CA3",
    "25409.pdf": "9AF04D80-802C-450D-8C52-87AB9D09600E",
    "25410.pdf": "E8BE3EA4-A010-4032-84E9-70798B4E7DFB",
    "25411.pdf": "DB11F95B-FCD9-4765-ACD5-E6D702E39BE9",
    "25412.pdf": "B6950A40-4373-4885-8F86-18D727A13C25",
    "25413.pdf": "738F7878-E60C-450E-B983-77F305C614FB",
    "25415.pdf": "64C198BC-4BD8-4278-A24A-8EF06248AB54",
    "25418.pdf": "BC5B34FB-52D8-44FE-84CF-803850C02E5F",
    "25419.pdf": "C100C682-A3E5-4F86-A2DD-17C1A2B539CE",
    "25420.pdf": "585AA0B7-9561-4A56-9B05-17C8383806CE",
    "25421.pdf": "8E48FECA-F620-4BE7-B429-9623AF7DEE00",
    "25423.pdf": "56868631-2F8A-479E-9F76-0AD7BCD99330",
    "25424.pdf": "E4B462B9-FC47-478D-8558-C0FB24AB50A6",
    "25425.pdf": "9AB05601-1EF9-4664-8402-97FA2E1B5DD2",
    "25426.pdf": "7B54B28D-340F-4271-8B03-42287C86ACE7",
    "25428.pdf": "E5D62DEE-02CE-4FBC-B6CD-EB2383191BCA",
    "25429.pdf": "B79740A5-E9E2-49CC-8708-AF6ADC81E23C",
    "25430.pdf": "DA2D9F71-8BA0-400A-BDE9-DC6C6078D5DB",
    "25431.pdf": "3C819259-D193-4311-86D1-3EC59C362B97",
    "25432.pdf": "0E53B75F-D30D-47CB-913B-A6CBBF32664C",
    "25434.pdf": "8E5D2582-7B48-4385-9E30-27BFA08A26A5",
    "25436.pdf": "E42FAAC1-F2BA-4D6A-AD33-5D732DD2A1CF",
    "25437.pdf": "00C03A30-A16E-4EFE-B674-284BA78637C5",
    "25438.pdf": "0FF8C09D-2AD2-4586-8A86-FA9F33088261",
    "25440.pdf": "4F32B9D3-6E25-4833-B5C0-91BAF9A69FC4",
    "25441.pdf": "6E274A76-D5E9-4AE5-9FED-BD83C51E5243",
    "25442.pdf": "531FBA73-F5F4-4761-A8F0-D734F6091C0C",
    "25443.pdf": "74B8BE44-F41E-4E59-B4F7-801A5D4760B5",
    "25448.pdf": "BCFBB34E-7E25-4CAE-A058-F0276FF42259",
    "25449.pdf": "3A891C0B-A752-4DBD-9AD6-5C82A6E87A5C",
    "25451.pdf": "0F0A8FAF-0897-4D4D-BD94-9F129F0691CE",
    "25453.pdf": "4B4BE380-21AB-4E12-99FF-840FBCBAD0DC",
    "25454.pdf": "0762BE35-7F2B-4CA4-A211-3D14BE26FAEB",
    "25455.pdf": "2FEC6B77-4FCC-4288-8D0E-2987BE505579",
    "25456.pdf": "05C72D20-5E72-419C-A6C0-EEE65DBC8183",
    "25458.pdf": "6CB52907-1914-425B-9E2E-D6B9E75CFC73",
    "25460.pdf": "EF15FB7D-F8CA-4353-BBE7-285139A50F4C",
    "25461.pdf": "298933CE-9526-48A3-9D77-715F1032D18B",
    "25462.pdf": "435E3116-11E2-403F-9563-E90C199AC02E",
    "25464.pdf": "23B65234-AED8-495A-9859-620ED73FF451",
    "25465.pdf": "ADB0AD5D-AD24-4582-BF24-9FA43A81DEF4",
    "25467.pdf": "6F1CD46A-7C53-4492-BC94-1DC7947710B4",
    "25468.pdf": "AEA6DC4F-D06A-450F-B259-CB8EFCF2992A",
    "2547.pdf": "1185EA72-C45C-468E-A4E5-4F53FDB57ABB",
    "25471.pdf": "488CD61A-9871-4AED-8DA3-CF8029924FF6",
    "25472.pdf": "A28A2341-47FE-4549-A5C3-473C7C8703E3",
    "25473.pdf": "F9B4D6A9-92C6-40AC-B6AC-81636295BAE7",
    "25474.pdf": "899F81B9-DC17-44AA-B886-966BC7E3AE3A",
    "25475.pdf": "0532FDA9-B96B-4D0D-859B-352A9317C5E1",
    "25477.pdf": "036E191A-8D9C-420E-A4EE-DFD66C511705",
    "25478.pdf": "4BDDE1F8-FF2D-4EEB-B64D-73B97A1F6581",
    "25479.pdf": "6E2D7D0C-542E-4F61-9964-0F999419B82B",
    "25480.pdf": "2AFB5DDD-052F-4B64-9F61-308214CD7B39",
    "25481.pdf": "FEAF1633-7154-4A6C-BCC9-2E669F76D0BC",
    "25482.pdf": "5A039C42-81FD-426D-9421-77541C28C84A",
    "25483.pdf": "62007490-C522-44C5-A88C-BC768A7A19F5",
    "25485.pdf": "2B27332C-8393-45D3-8D54-BF21CEAFA260",
    "25487.pdf": "A7EF1881-0277-499D-87D6-C7319905F181",
    "25489.pdf": "0D986B2A-E496-4B7B-A7BA-AE5719B7E367",
    "25490.pdf": "79EA1351-2C0E-4B35-BA30-89E7447E53D4",
    "25491.pdf": "E59697CD-D96A-45B7-BE20-02721B41C341",
    "25492.pdf": "B850988C-1102-4436-9F6F-AA535CA3B3E0",
    "25494.pdf": "08B03511-58C1-45E5-9414-0E4E6CF99A04",
    "25496.pdf": "62E326C1-1F0A-4076-AC62-00473BFE1ED5",
    "25499.pdf": "126B8B9B-4BD0-4461-998B-D5F59F751ACA",
    "25500.pdf": "D4033881-0265-4465-A250-8731BAADAAB8",
    "25501.pdf": "0D41183B-E8E6-4B34-B1A1-D8853258BE8D",
    "25502.pdf": "495C02D2-4676-46DC-AFCF-7F5E2EA0AE2F",
    "25504.pdf": "55CE664B-90C2-4D76-BCA2-DA39CD203BEA",
    "25505.pdf": "4DDEFD78-CEE9-43D5-80ED-5D63AA82501B",
    "25506.pdf": "DAC0C759-2DA8-46C1-A395-1A79646921A1",
    "25507.pdf": "49A16CF2-91DD-4337-8CFC-E92841AE6EDA",
    "25508.pdf": "E846DE7A-32EA-4D63-9038-FB2754B529E9",
    "25509.pdf": "4F045D46-2B00-449E-B52A-8F86D5944A25",
    "25511.pdf": "79C1C098-10B6-41E0-9495-1837DB331BF6",
    "25512.pdf": "A1136A81-1916-43FF-8814-9D731686AE38",
    "25514.pdf": "EA8FD58B-5D86-4010-AF01-2BC5DDBA853D",
    "25515.pdf": "59CACE87-2694-4728-A400-5597AC70AC17",
    "25516.pdf": "BBA5DD85-399D-47EC-B9F5-01E5432E4A6F",
    "25517.pdf": "1CE6C81F-8D99-4C28-B743-3047698F7306",
    "25518.pdf": "7931B01E-4411-4AD3-9EAF-52A02346C2D6",
    "25519.pdf": "8C7F95ED-52A9-4746-A8DF-23F05ABD29FE",
    "2552000429020561048025.pdf": "E8E40FDC-1090-407F-ACAE-61A25BE7EAFB",
    "25521.pdf": "12C11B7A-3D44-47F2-824D-3ECD69BE3D71",
    "25522.pdf": "9CB60323-3300-40BD-891E-E3983C07BFC0",
    "25523.pdf": "104F3C10-FFBF-44EA-A202-6A574440802F",
    "25524.pdf": "4C9B70A2-3EE8-4BAE-BA1D-38BA2B4B4D15",
    "25525.pdf": "0BDAAADE-678C-497C-BF35-05653BA9BB46",
    "25526.pdf": "3D4591D5-F555-4E5D-BB5E-25A0525728A8",
    "25527.pdf": "2A9EC6EF-5F53-42AE-925B-67AA7B619AA2",
    "25528.pdf": "DC5F627D-47BE-45C2-BB29-1A61B819ABDE",
    "25529.pdf": "36ACD85B-2903-4560-9F15-8ACF2C20E5C3",
    "25530.pdf": "D6B7329D-8A6A-446C-AEC9-B4B225569CC0",
    "25531.pdf": "592CAFC3-A2BF-4C8E-9C78-DEFA6BCEE99B",
    "25532.pdf": "EFB72353-C0CA-426C-B663-2D1262C48201",
    "25533.pdf": "F687D489-B53E-423D-9132-1E8A8670CD97",
    "25534.pdf": "23AB4DA2-E83C-42F0-A3A4-B911895EE0BB",
    "25536.pdf": "FF8DE991-20EE-42F9-AE74-9CCF1469CC7B",
    "25537.pdf": "0412B74E-3E48-480D-9CDB-864C7ADF0197",
    "25538.pdf": "BEADDDBD-C8A6-413A-A1EA-881FE548C66B",
    "25539.pdf": "5F2FF70E-2832-4F58-BE24-66EE4DBC60F9",
    "25541.pdf": "B59AA250-6F7D-4C78-BBFD-0FF978886CF7",
    "25543.pdf": "88FE868D-22A7-4617-8847-C62F540A8887",
    "25544.pdf": "0C2042EF-63C2-4BF6-AB7F-CC46E51E83BA",
    "25546.pdf": "B0955143-48FA-42C3-BE17-D35D0E9FB082",
    "25547.pdf": "85A35EA2-E6C8-46B8-82F5-23E6ABF326ED",
    "25548.pdf": "30CD82DC-4AB4-4D6A-9F4A-FDFE29E75F06",
    "25549.pdf": "46A69A60-9E86-4E2E-9760-69352AB23CE6",
    "25550.pdf": "F036FABA-145F-46B3-BC2F-8BE093D4B2D1",
    "25551.pdf": "33FB189A-FDB6-4612-A105-711250FC757E",
    "25552.pdf": "C6AE40EC-C0DB-4ECA-9572-B2EA0F23B942",
    "25554.pdf": "3E142090-947A-46CF-B9D4-14ED84FD34CA",
    "25557.pdf": "B5A053E5-BF3F-422B-A45E-E83532E2A073",
    "25558.pdf": "7E2A0A4D-09FC-43DA-8E27-4834F8FDCC6C",
    "25559.pdf": "2F022624-3E77-4833-B710-15A02CFC36EE",
    "25560.pdf": "F494BC1C-2E5A-4923-A4CA-BE812D4CD38D",
    "25561.pdf": "84B3A2A1-3F5C-454B-ABA1-9FE0286346C7",
    "25562.pdf": "72740CE9-E6CA-4FBF-A89F-150594750E6B",
    "25563.pdf": "8D93FC27-9209-47A4-B91B-E703A64B3958",
    "25564.pdf": "8F29A45D-5C26-47A3-A2AD-F2A19FCC65AF",
    "25565.pdf": "BA4A5B78-41FD-45C8-AC5D-617A7A1CF019",
    "25566.pdf": "0D146731-719E-4B64-A2B7-E14E2C3FCB01",
    "25568.pdf": "2726D284-EEFE-4D30-8035-3BDE4C8F56C2",
    "25570.pdf": "D557D8B5-E89F-4F8A-B94F-9BC2D973EB48",
    "25571.pdf": "12D7A4F9-67D9-4D9C-ACF7-8E70BA8ADFC2",
    "25572.pdf": "9C1A9E29-77DB-427D-9816-7E2EB13CDBA6",
    "25574.pdf": "1505DB81-2E9D-483C-B995-BDD0AA494A82",
    "25575.pdf": "3EAC947E-BFC5-4D00-AE2B-80454C0586D8",
    "25576.pdf": "81CA668E-14D3-4CA2-BFB2-297F5C1FE5A1",
    "25577.pdf": "9BB5E1E4-3FD7-4A30-A64F-340686128B7E",
    "25578.pdf": "282D9107-61CB-44E5-A56B-F9495B738257",
    "25579.pdf": "08A4ABCE-AFD2-4DA4-8826-6C67AC1DECBD",
    "25580.pdf": "74EF3F0E-CEEB-4EAF-A55B-E405B126704E",
    "25581.pdf": "DCDBCA9B-BA6C-4693-8948-F28F32B02449",
    "25582.pdf": "BB3BD15A-72B1-4A7E-8715-FF7AA4566820",
    "25583.pdf": "6FB7428E-70C7-4DAC-A580-71BF848B2372",
    "25585.pdf": "9FD45D92-2350-4D08-B409-9277367EE622",
    "25586.pdf": "198B6961-D9B8-4129-AC70-5801C438A7D3",
    "25588.pdf": "98BCAF76-8E86-4510-A334-E5C348AB47A2",
    "25590.pdf": "B3A5846D-9DB2-41B4-9B8A-808041C9723F",
    "25591.pdf": "7BC97082-40F5-4F5A-B83C-7F5BF9B0FF6E",
    "25592.pdf": "344CEBE4-6A10-469B-B7B1-4FCBD78AA7E6",
    "25593.pdf": "B85C4C72-F802-4611-8B8F-A85809DF802A",
    "25595.pdf": "0173CB08-7CC6-4A9D-9CD0-C7977886CA4B",
    "25597.pdf": "755DF6D8-7BCD-4053-87E7-05E4A040E1E2",
    "25599.pdf": "0C593FF2-F78F-49F4-A398-432C91993F31",
    "25600.pdf": "3932B33E-5F34-4678-B3F2-5638178A581D",
    "25602.pdf": "B4CA797A-12FD-459B-91BC-A28099B9D778",
    "25603.pdf": "84818BB6-ED66-4D20-9F82-8CEC27573340",
    "25605.pdf": "925FAB4E-14E3-469C-9738-1198FAD84900",
    "25608.pdf": "9421C030-D9DC-43C7-938F-8FF441E5DAC8",
    "25610.pdf": "1620D630-FA3B-405E-B2D5-A9C44328F35A",
    "25611.pdf": "78885F75-4FA4-404C-A9F0-55692BAEFA8C",
    "25612.pdf": "32761E05-0456-4566-A917-52D06827D2CB",
    "25613.pdf": "84E05BA4-BA26-4DCF-8102-B3E51B3252EA",
    "25614.pdf": "2F037478-F9F1-4977-B937-583FF90BCFF5",
    "25616.pdf": "61593B0E-03C5-41C7-B276-5F0DBB97C2F1",
    "25617.pdf": "30164C6E-A004-4130-8855-9BE532856AA1",
    "25618.pdf": "ACF33EFD-F53B-4B80-B1AC-604C3469322C",
    "25619.pdf": "0DE48035-BD04-4604-991B-9797FB0BFA42",
    "25620.pdf": "5651F83E-2178-4F07-B381-1CC45885D103",
    "25621.pdf": "2EF52340-BE1A-48D2-A515-49347B04852F",
    "25622.pdf": "8B4A9F16-58E8-43E5-B61C-5BC919E53828",
    "25623.pdf": "AF6F2833-2480-4C10-B992-19139542B59D",
    "25624.pdf": "904AF0C7-4C30-4EB9-8112-8E7AA629499C",
    "25625.pdf": "4531DCC0-DA34-4DB6-B21D-73E50691AF5E",
    "25627.pdf": "70776299-78DA-4E98-AA6E-BCEDBE936F7C",
    "25628.pdf": "FB14E49F-894E-4812-96C0-543AED06D93D",
    "25629.pdf": "9848E8E5-C82D-49FF-A8BD-26BB4A5FD7C6",
    "25631.pdf": "FB2C5D2A-3784-4A68-8602-401EF6A8F442",
    "25632.pdf": "CE9F6274-8078-490E-8ABE-828A19C0C8A0",
    "25634.pdf": "8734A750-05F3-4612-BAB6-E5E0080806CF",
    "25636.pdf": "165713F7-8E0C-476B-904C-606AAEF0C2D6",
    "25637.pdf": "A8AFE595-F67A-4F4D-8751-E266B967D2EC",
    "25638.pdf": "822206BD-928B-4D7E-8E0C-2A45F19ACD66",
    "25639.pdf": "C426C970-E520-4067-B9F6-38E5EF038466",
    "25640.pdf": "78F2D0A3-CEE4-49F3-8634-977A68E6A9E2",
    "25641.pdf": "EAB3E065-4F60-4D46-AD57-CD25BF3E27D4",
    "25642.pdf": "7EA119AF-5F15-4680-8FF8-40CD0A30364E",
    "25643.pdf": "B8E30A1B-7D1B-4171-8E8F-5B37C865E0FA",
    "25644.pdf": "93C6B27A-00DA-4A87-9063-38B4294760E0",
    "25646.pdf": "8B41464C-63D8-4BB7-A999-B0F93F50F16D",
    "25648.pdf": "5044183D-E12D-4656-AD1F-7D9124818CF9",
    "25652.pdf": "50A3D89F-43F5-4EE0-9E01-04E601D7A6EF",
    "25653.pdf": "EE1E4029-D053-44C0-B1E2-2E3BE022E5F2",
    "25654.pdf": "AEB2F695-24ED-4B62-8FF1-7F34D5C482FB",
    "25657.pdf": "A282BB91-2875-4A59-BBD3-EC9DB93BB4DF",
    "25658.pdf": "F7C63228-ADD6-4E53-9F0C-8D83E0DA8623",
    "25659.pdf": "8533B206-9F12-472E-994F-92ED70745744",
    "25660.pdf": "D1F561D9-739D-47CF-88C2-0AEBD4EBDECC",
    "25661.pdf": "94E4D548-325A-41CC-85AD-FD17220C64D2",
    "25662.pdf": "58B3A301-E659-4B47-85FE-21623BF5B618",
    "25663.pdf": "569E50F0-6319-4F3F-99AE-CA4AF256BEB5",
    "25665.pdf": "4B05F5DE-527A-413B-AB5C-8ABDB5B968D7",
    "25666.pdf": "046B52FA-628C-4EA7-9224-19B7E4FEADEC",
    "25667.pdf": "DE11347B-7AEA-4209-BF96-8DD3E051DB5E",
    "2567_BOOK.PDF": "B44C8BB5-A96C-4B6E-B435-0E54EB421D11",
    "25671.pdf": "438465FE-8755-4972-B3AF-38A1D77EA1F3",
    "25672.pdf": "7681540C-9F1B-4D48-9CD4-E5E8E677D9E6",
    "25674.pdf": "C793A909-90F8-4AF2-945D-9C114A7CFDA4",
    "25675.pdf": "DCD4F607-3B49-4A33-946A-51CCE36CE116",
    "25676.pdf": "2276F0F0-7D63-45CA-86D0-44E445F173AE",
    "25677.pdf": "C38C1B64-7E49-44E9-8814-12F6D09F27D2",
    "25678.pdf": "E35ABE7F-3952-43DF-9E0E-87BA1B87DED8",
    "25680.pdf": "4C0621E6-54F4-43A7-ABD4-DB9412E321D3",
    "25681.pdf": "233D48B4-7E72-4893-B855-FF4AF8D4F3CD",
    "25682.pdf": "0CC4C47F-04FF-4ECE-A6ED-BEDFA51360BD",
    "25684.pdf": "66C0BC5F-F4F7-4B3C-AF1A-C2F6AE04C370",
    "25685.pdf": "8E6750C6-83F8-4BAE-AFD4-BCF66AF8BCAF",
    "25686.pdf": "6745D945-5B53-40BC-91DB-64C164BCC18A",
    "25687.pdf": "17F096FC-6D1A-46E4-B82E-0DEE7AF0E280",
    "25688.pdf": "3504523A-9407-40A4-8909-B30C8D74D51A",
    "25691.pdf": "36999492-4448-4C2D-9594-A61CEFF6B39F",
    "25694.pdf": "327A6D48-735A-4399-8A5E-4654260616BB",
    "25695.pdf": "A21B3AA3-8D3E-44BE-AD87-D36C0DB02173",
    "25696.pdf": "3932E8BD-6CE0-49A2-9C5C-D34E276260E8",
    "25697.pdf": "66171B4F-E546-408C-B1D8-CD5B98F208E6",
    "25698.pdf": "74ACF0E0-22F3-476E-983D-34EDE016C9EB",
    "25699.pdf": "D0279382-6569-4B7E-B36A-18F631174FC2",
    "25700.pdf": "6064E8BD-1290-44FB-ACD0-D8C5D90606EA",
    "25702.pdf": "F35A40D0-F02A-4503-BB67-D15628EA4A60",
    "25704.pdf": "71D05A5C-3F09-4FAC-8276-220DCCAADBC8",
    "25705.pdf": "04F0333C-1A5C-4D18-B11B-6AF703A436F1",
    "25706.pdf": "31ABF51D-72CB-4AB4-B2DB-FBE48055E2D5",
    "25707.pdf": "6A7A4431-247D-43A2-A337-A3BF81B102F8",
    "25708.pdf": "93438EE0-43F8-4E5C-BECF-FB99BA0B6888",
    "25710.pdf": "9C503FF3-73BD-4871-B33B-1F6226181D72",
    "25714.pdf": "861FBAB1-F422-416D-BE9B-9BC0E0BB3983",
    "25715.pdf": "975062EE-4F26-48D1-803B-52570A39B46A",
    "25716.pdf": "BF7FC379-2936-44CB-8C90-4E6A689763C1",
    "25717.pdf": "4182E00D-9382-4955-990D-398A71174B18",
    "25718.pdf": "A7D14ABB-C519-47F2-86F2-9E8777B35B85",
    "25719.pdf": "B0FC6AA1-05C6-4D15-877B-CDF61CC25F9B",
    "25720.pdf": "6D09F3F5-5C57-4423-A80F-E4E2DB27065F",
    "25724.pdf": "D09109D9-F0BF-4848-8D90-BCA484749F18",
    "25726.pdf": "D01D413F-25CE-49C0-B9CB-D0E482EA8ADC",
    "25727.pdf": "5A482E96-E8C1-4FC3-B598-284E6779C118",
    "25732.pdf": "1C23B934-073E-42C3-82D2-36E1E1C4C29A",
    "25733.pdf": "573922F9-EA94-4A8D-8A01-72CFD26074BD",
    "25736.pdf": "0EF5AF46-5838-4CC4-B1B9-4EB1CF23A69C",
    "25739.pdf": "43B06562-A9CA-4862-ACF7-312D29F18B75",
    "25740.pdf": "F83D2B6C-653F-4AF8-A0CF-F6078654404E",
    "25742.pdf": "80A8DFB4-09A2-4EC8-9EA5-4F1C04C7119F",
    "25743.pdf": "3948974F-5C2B-4D63-BAC5-A4FB4E33C1EF",
    "25744.pdf": "C71E62E4-AD37-49C2-A1C2-83E76168D287",
    "25746.pdf": "FD351F7C-A668-4B8E-98AA-49EE264E7F2D",
    "25748.pdf": "8CC122FD-4A0C-41EB-B361-411520F330B6",
    "25749.pdf": "A9B10BCD-A7E3-4C2E-8C9A-3E13965A2341",
    "25750.pdf": "8800809C-8754-4C34-AD80-38F07DBD1764",
    "25753.pdf": "4028FF32-36F2-475B-BC49-9414F8704737",
    "25755.pdf": "05484E83-D0EE-403C-B957-5612796BB5A1",
    "25757.pdf": "34866F71-E315-4648-8B2E-67AB1758C903",
    "25758.pdf": "C604975D-6249-475A-B638-5D877A5672F2",
    "25759.pdf": "B31AB321-435C-459F-BE5B-B75580A7280C",
    "25760.pdf": "24B54D10-B594-4325-BD3B-9593419168D6",
    "25761.pdf": "AD918251-10A8-48F6-9424-0B88098093A2",
    "25764.pdf": "306130A7-2E7D-4C1D-B051-6D8B27E46284",
    "25765.pdf": "8CB6FFC7-D766-4B82-9128-5AF3160ABF1A",
    "25767.pdf": "6B635197-485E-454A-AB3E-9153331B6FB3",
    "25768.pdf": "9107ECC4-8488-48DA-9298-DF325B107291",
    "25770.pdf": "00CA69E9-1BF6-4919-8302-A8ACF98E91C7",
    "25771.pdf": "4F28A46B-1571-420D-AEA7-1249336BE654",
    "25772.pdf": "B0FA99B5-9E8E-4006-960E-0258CE8021A2",
    "25776.pdf": "1AD15DF2-31AE-4EFE-BED9-8DD494D98E1E",
    "25777.pdf": "5E8C1654-091A-4CB8-B214-9C143A4D4C02",
    "25778.pdf": "5B0A782B-08B1-4CB2-B521-40874F6F5026",
    "25779.pdf": "95557FB4-1C0E-4D24-9F6B-76F50F728830",
    "25780.pdf": "A2963E23-3006-4BA1-BA23-3AE880A7EF67",
    "25787.pdf": "C8CD2675-7767-474D-9E59-4A4F90CA0B2C",
    "25788.pdf": "3159BAB0-99A1-4BE3-BCB4-6A247A2D35B3",
    "25789.pdf": "5E761F98-915E-4343-BD17-B70871648483",
    "2579_MEMO.PDF": "08C2B2C2-EC64-45C7-BBAB-030396D3E96D",
    "25790.pdf": "33DFE23A-5E4A-4B33-B839-A1631F1A8484",
    "25791.pdf": "2DC0FC4C-54BC-4B25-843B-F1387C8E2340",
    "25793.pdf": "924A5A15-9E95-4813-B334-C6CA881A2FDB",
    "25794.pdf": "A3F195AB-1ABB-471C-AEDD-7F43E77DAD9B",
    "25796.pdf": "D06676A5-16EE-42BE-BCB6-6F435620999E",
    "25797.pdf": "CB3DD9B4-60C3-47CC-AADC-AED060F13FE4",
    "25798.pdf": "6F98F5D2-A354-40D3-9C4F-27C7528B7CFF",
    "25799.pdf": "FEA9302B-CC18-4989-8786-34705C84092A",
    "2580_MEMO.PDF": "7872642A-9BB3-4074-8CAA-DF0C0E5A55AD",
    "25801.pdf": "8B210B8D-A637-42F5-8191-23CECB820F27",
    "25803.pdf": "3C4DC41A-4373-4F69-98F1-9B7DE0063A40",
    "25804.pdf": "EBC873C4-7927-4F69-9DAD-9191FD5F5F29",
    "25805.pdf": "A4157A1D-C5DB-45AB-BE2F-FEE06CD71488",
    "25806.pdf": "2329CC99-34BF-45B8-932E-90D53AF30A42",
    "25807.pdf": "BD37C389-FB57-459E-9512-FCFB560DF228",
    "25808.pdf": "A473E1A3-7E3A-4498-9203-4964109D4E88",
    "25809.pdf": "AEE11A2B-4774-4D74-81D7-C13204337472",
    "2581_MEMO.PDF": "C16D183F-61C8-48EB-AE4A-3448A56918FC",
    "25810.pdf": "1E3953AC-B659-43FC-B9A0-999EA96484D2",
    "25811.pdf": "C70FCFE5-86AB-4E4F-BF11-B8FB1B5A6861",
    "25812.pdf": "7C76FCC3-B434-4E24-883D-69F70ADD9A55",
    "25813.pdf": "D918CA45-FCAB-43C2-A84D-8FD41D8772CC",
    "25814.pdf": "E3A682B0-08BF-4529-9E37-A16BDE8CB837",
    "25818.pdf": "F981179C-A635-4AB6-BA11-0E9DCD42E620",
    "2582_BOOK.PDF": "C844B455-85BD-47E0-84B4-D54F74C38C79",
    "25820.pdf": "C5C85ECB-5660-4F58-ABE0-2198659E84A3",
    "25821.pdf": "77D2096A-12E8-49C0-A13C-0105C462493E",
    "25822.pdf": "21B2ECB1-65E9-495E-B3C7-ED537EE44928",
    "25823.pdf": "C7905CAD-EF6A-408B-9D4F-188CED0A8EE0",
    "25825.pdf": "88FD9132-C555-4D7B-8662-DE1F23F8EC05",
    "25827.pdf": "9271DA55-3B0A-4887-9317-A3643A809B64",
    "25828.pdf": "7F41F98C-7A17-4F2E-B251-7531DE2DF1D0",
    "25831.pdf": "E32FA724-11F3-41E2-BCB1-DA62C977CFAA",
    "25833.pdf": "9D3D14F6-2E49-4127-816E-05DD2B68AED5",
    "25834.pdf": "BD7C8BFD-4E0B-44E7-BE69-0561AFB86757",
    "25835.pdf": "81FDECF9-95EF-4797-B13F-1082BB7EC97C",
    "25836.pdf": "5C81BEB8-A1BD-482F-9AAE-6AF086A7961D",
    "25838.pdf": "192E8D08-9022-44B1-8976-C4BE74736832",
    "25839.pdf": "125FDDF5-04DB-422C-82CF-627F0823C233",
    "25840.pdf": "3936E2BB-FC6D-43ED-AEA1-8DE95BCDA2AC",
    "25842.pdf": "D38774A2-DCCC-4120-86C8-651806A0BA28",
    "25843.pdf": "CBFE6547-F81C-4C0E-9D5F-BA2ABE15367E",
    "25845.pdf": "83C336AF-B93C-4CE3-8CC8-908D39EC658E",
    "25847.pdf": "49F7BDE7-FFC9-43F1-8806-48E754C0F6F7",
    "25848.pdf": "CBDEC2AC-AB67-4A51-B77D-8A4BBECD3399",
    "25850.pdf": "7835F243-0BD9-44AD-A405-E62B9D8034BA",
    "25851.pdf": "534CA498-1F13-4DA8-9DBA-E691803E1598",
    "25852.pdf": "299DBC15-3D63-4441-84DC-2D0652F177AE",
    "25853.pdf": "A73E6FD1-B971-4938-92FC-DF9CC40C74A7",
    "25854.pdf": "CB5E086C-28A2-4950-84FE-3530850147B4",
    "25855.pdf": "B8669FD7-9FE6-42AD-AC7B-E327064C9ED5",
    "25859.pdf": "254987E6-5463-49EC-9D9C-3F6056924250",
    "2586_MEMO.PDF": "D4AC7F9A-8C8C-4909-A2A0-7532C07C66DB",
    "25860.pdf": "8C877113-EB33-4285-BE69-E83C1B2B4B41",
    "25864.pdf": "E5B9376D-12A3-475E-8C57-3E33B540DB9B",
    "25868.pdf": "2EFB0479-9153-4791-B05B-43B95F76B720",
    "25869.pdf": "874C6B48-2603-449D-9BCD-AC384451FB8A",
    "25871.pdf": "0D21E94B-AE23-4D1E-9A9C-D766287EA31C",
    "25872.pdf": "0DBAC367-2D35-48CB-93DA-12D56E415F56",
    "25874.pdf": "23E436CE-331D-4B53-98E6-C7DAEADB0C82",
    "25875.pdf": "3907A03E-6AA0-4E87-A8A1-D5F84BDED14B",
    "25877.pdf": "2FCDB498-2B07-4E06-BE66-BA456E7B2094",
    "25879.pdf": "D5DCA5D2-673E-4BBD-A73B-50174A4A20A4",
    "25880.pdf": "7560AD4E-7B7B-4ACA-A4FD-49C59C297EDE",
    "25881.pdf": "DE7844C1-84A2-41FC-A3DD-70E4A996EE13",
    "25882.pdf": "AC355E2F-7F8C-42AD-AC14-75F111BE4833",
    "25890.pdf": "2C494A08-97CC-4925-B081-3678EC0B6A8C",
    "25891.pdf": "B959A74F-4AD7-4572-8408-D3329C742B03",
    "25892.pdf": "A226CCAA-1E6F-4C71-B0DD-70DA68593BBD",
    "25893.pdf": "56D79587-1E9C-4636-BD80-067BF6C1E572",
    "25894.pdf": "14FBA0F9-F1B4-4D02-BAF2-631DC8F473C8",
    "25900.pdf": "EAE93522-32DB-4626-B021-4E8EDDAB96BF",
    "25903.pdf": "7C75FC11-795E-44C9-A9C5-8464471C5DC4",
    "25904.pdf": "B67EF2D3-2B27-4BB1-813B-49A251FDD104",
    "25905.pdf": "18D8F17B-54E2-4FFA-854C-B58BCD478C90",
    "25906.pdf": "0EDB5D26-78C8-42B8-880D-0541B4B38525",
    "25908.pdf": "94B0D2F7-229C-4B8F-9DD9-C1189D9FC263",
    "2591 Caras et al. Comminuted Coincident Squamous Temporal and Orbital Fractures.pdf": "D2FFEFD3-A72A-4BBF-AABB-C1BCCE4380C2",
    "25910.pdf": "59589E46-49CF-4285-8267-9074DF280D69",
    "25911.pdf": "2D2883A0-817D-47EF-9A20-1A83F49DE6F7",
    "25912.pdf": "0DAC905F-5650-4B20-9909-84D097F6E0DD",
    "25917.pdf": "0DF44FBB-8845-4966-8DD2-AA3E86D25ABF",
    "25918.pdf": "B04A624E-B85D-4768-8E76-DD74EB7F84C3",
    "25919.pdf": "3C72D513-4EB1-41E9-9D01-AB8FEC8676A4",
    "25920.pdf": "02F2E45B-DD9F-49F7-B729-88BF8961FCE7",
    "25921.pdf": "EE1E7CCA-13A3-4EBD-AA5A-0237A14CB102",
    "25923.pdf": "E2150A5E-5E5E-4414-9BD6-CA6EAB6763ED",
    "25924.pdf": "2C010E66-F0D7-49C1-9AEB-1F176FB23B4D",
    "25928.pdf": "2CC5CB47-0EAD-471F-B534-010698D01A6C",
    "25929.pdf": "433D7A65-8187-418F-B092-EB5BB0462DF6",
    "25931.pdf": "7DFAA6EB-0E3B-4DFB-BF33-C51E41F6965E",
    "25933.pdf": "830A264D-FF56-401C-87CE-5FB4420D507A",
    "25934.pdf": "4B711E3C-22D5-4E8B-9161-A4089F728970",
    "25935.pdf": "46939112-FF23-4335-832A-51E116E91DD4",
    "25936.pdf": "9F780A67-5365-473D-A25E-B14F0E3D13CD",
    "25937.pdf": "C4D1F40F-4BFE-4AD8-AC84-42F48DDF3FF9",
    "25938.pdf": "CC7DFDE8-644C-4200-9BBD-6066EA4FAF21",
    "25941.pdf": "F8984302-CD58-44EF-9942-93E9ABCD67DC",
    "25943.pdf": "07C8B312-83A7-43E9-87B6-736CF194796F",
    "25944.pdf": "40C91E1F-D449-42D6-9895-9017267D8416",
    "25946.pdf": "C075A81C-0150-48E8-B95E-E51DFA43B399",
    "25947.pdf": "F6B94AA4-6618-4D36-A305-D7051C6AC2C8",
    "25948.pdf": "85B7DFCD-BACD-4A1C-A35F-6C2DF6994B79",
    "25949.pdf": "BD87D43D-8873-46A8-A87C-390E7D973687",
    "25950.pdf": "F21C1DEB-5EB9-49C4-8673-4380BB634E19",
    "25952.pdf": "2F76F46C-6264-4717-8C95-3D3C8F9E4B32",
    "25953.pdf": "9F93579D-B481-4D07-B38A-256C59C1243C",
    "25956.pdf": "C509A799-B322-4538-9026-469D75A83D8C",
    "25957.pdf": "CD5EFAAA-A94D-4EEB-9A60-729D9D9C32D7",
    "25958.pdf": "47CC074C-3FD7-4D16-8CC4-1421DC00D12A",
    "25959.pdf": "DD20E5B0-8A24-40E8-B80C-B3A38652EF94",
    "25960.pdf": "2E2FB934-D7FF-444A-A90D-69FCDF868E36",
    "25961.pdf": "7DAC3B7D-A3D8-40C1-932B-1491DBC87D7A",
    "25962.pdf": "C3458DF4-53C3-4DD1-A591-6BC163E06C74",
    "25963.pdf": "293878F0-8250-40A9-8A14-67561C35B471",
    "25965.pdf": "5A80BE78-21F5-4A56-9E84-734B0155C329",
    "25966.pdf": "3BF7B363-5209-4523-B613-40E542EF63A4",
    "25967.pdf": "656BAA69-9B39-4177-8B1E-CCA2345D829A",
    "25968.pdf": "EC638BDD-5C77-4EFC-848C-909C18E3BC63",
    "25969.pdf": "7E94B493-A9E9-4DEF-8A05-F516CC218776",
    "25976.pdf": "B1550712-5D7B-4A1B-BE40-97F1C2E2F500",
    "25977.pdf": "C55A41BB-6DF6-483C-ACF8-20F5A7C8FD2B",
    "25978.pdf": "6EAD7828-0CC4-4FCE-A640-95DD5532C581",
    "25979.pdf": "32579F12-BF25-452D-B905-B6BE99F7E01B",
    "25981.pdf": "FD8B6FED-6129-4CA3-AB3F-B8FBE1ED856C",
    "25982.pdf": "D04FF43A-9C79-4164-BAE3-7E67F2702C91",
    "25983.pdf": "8BC8EC51-14D9-4250-A42F-EBB8C77DA189",
    "25984.pdf": "A58048AF-6DA5-455D-BDE9-95A5FC2470F3",
    "25985.pdf": "DDA6E6CD-4F82-4902-8245-0315FD4703E4",
    "25987.pdf": "DB6B8932-F48D-4A40-87C6-54BA1281D1A2",
    "25990.pdf": "1F0898C2-2400-4D22-82AC-856683BF5296",
    "25991.pdf": "83A0D5C2-76C1-4B8F-ADD0-D395C1771577",
    "25992.pdf": "EB068903-2B6E-4123-A6D9-6DB66FDC8B22",
    "25993.pdf": "CBC307ED-D715-436B-A5C0-D14F863123C4",
    "25994.pdf": "BDE809BF-BA2E-4A50-BB9F-3DD66C571A15",
    "25995.pdf": "F09205D0-BBF8-483E-A8FB-D98C2513844A",
    "25996.pdf": "F5DBD15D-680F-4EB7-979C-F7CD14355852",
    "25998.pdf": "A7E35BCB-1315-4310-B770-3D2CBFFC2F06",
    "260.09 FLight - Mohamed.pdf": "6855C789-13A2-42EA-8B4C-477156FEFBC0",
    "26004.pdf": "540DF66A-3905-4273-8CB6-8DBD017612CB",
    "26006.pdf": "646F4DC6-C8E7-4D3F-A258-D70851FF22A6",
    "26012.pdf": "D78E7401-6078-44F1-B1C9-41F41BCBC407",
    "26013.pdf": "8A77AB4B-81F8-4FD3-804A-AA91B7E58049",
    "26014.pdf": "1F687289-3ABC-49F6-BCF0-492112287856",
    "26016.pdf": "65E07D22-EB28-4634-AD6D-03CEC59DF4DC",
    "26017.pdf": "63C9A249-210D-4D41-B50B-F57B5F1349AC",
    "26021.pdf": "CF6C079A-4385-4EE0-A950-B3F891317D9D",
    "26026.pdf": "BE3FDD95-FFEA-485B-AAC8-717E6565F9AC",
    "26027.pdf": "47615FC0-3292-4563-8550-AE95F5AB1BC1",
    "26028.pdf": "61679C5F-88C0-4869-8D7E-51C42DC87BC5",
    "26030.pdf": "457F1DA6-5523-4FAC-BD73-538597E086D7",
    "26031.pdf": "8D14C93A-E67E-4324-9D95-52B2A0E7DCF2",
    "26032.pdf": "CF25BE96-5AAC-482C-9703-3093533D9E10",
    "26034.pdf": "5BD09559-9942-4336-B2E2-9BCCD5DB041E",
    "26036.pdf": "49DA4966-6CCB-4459-ADDC-E5DBB6156C55",
    "26037.pdf": "D1A4A6C1-C0D4-4D33-95BD-5B6B0B77528F",
    "26038.pdf": "6BF7ABE6-36F9-463A-AC2B-DF8E1214BDB1",
    "26042.pdf": "3BE0E4F1-4DD1-4732-9B64-531E702981D9",
    "26043.pdf": "28ED87FD-A3A5-4C9E-81B3-2B8FCCF52D09",
    "26046.pdf": "5267D025-C147-4B6E-892C-47BA67B6BEF4",
    "26047.pdf": "0AA88D1F-CD32-4CA0-B8F7-B4396AF304CF",
    "26049.pdf": "FF009490-98B4-4C20-82E2-9F745CEF2731",
    "26050.pdf": "0B961097-395D-4123-9CA6-2D77EB5C7CDA",
    "26051.pdf": "68779D50-9C51-406B-A72F-9120D462B4C2",
    "26052.pdf": "67AD6F25-E237-4191-81AC-08CCE1FD09CB",
    "26054.pdf": "19AEEFFF-5C90-4433-87EB-3DA2280D8935",
    "26056.pdf": "C90D51FB-14C9-4853-BCA4-5B48A4AC437C",
    "26057.pdf": "F6DD1A25-A4C3-428D-B066-B9874BD0C2FB",
    "26058.pdf": "62F20692-3F04-4263-9B9D-097B322B63C9",
    "26060.pdf": "3FFEDE41-F829-4540-A106-83C7D8DF2583",
    "26061.pdf": "3B345980-CE70-4272-ACFD-B49CB563A1F0",
    "26062.pdf": "3C9BB695-1D0C-4905-8472-EBF425C8B273",
    "26063.pdf": "DC62DEC4-7091-42F9-B48D-46DBAA6B4160",
    "26064.pdf": "40724E84-A005-4A5D-A467-A82A75965357",
    "26066.pdf": "DDB07EEC-2128-4B36-80D7-36E8CD7A08EA",
    "26067.pdf": "73BA6AE5-36C8-4036-A457-EC36776101C1",
    "26068.pdf": "C3799BBB-46E0-4061-A4CF-FB0B15A074FD",
    "26069.pdf": "985C0338-A146-44D2-8DC4-D9F2B023193D",
    "26072.pdf": "7B91BBDC-011E-45DE-A9EF-AF90D72FE6DC",
    "26073.pdf": "24968A9B-AEAA-4E3E-900E-6AFD6093DF88",
    "26075.pdf": "D299391F-C885-412B-A2BD-23295A9DBA72",
    "26076.pdf": "511C07D8-504C-40E5-AAF9-3399A1C9DB73",
    "26077.pdf": "443B633A-CED5-46B5-A788-D26238763357",
    "26079.pdf": "C8D6D23D-7543-4A05-B47C-D91D4307FF83",
    "26080.pdf": "BA702A4A-23E4-4E9A-B168-0EE43B794D42",
    "26081.pdf": "DCBDB662-EAA2-4810-B1FF-DD15464B84AE",
    "26082.pdf": "B425B49D-C1BB-450A-8D8A-BD8537D6E7CC",
    "26083.pdf": "08E14260-F2CC-40D7-BBD0-F7CBDC635250",
    "26084.pdf": "DED73188-5D66-415D-B3DA-6A63192FF68F",
    "26088.pdf": "2498EDD4-AAC9-4729-8611-CEEB5F2D67EB",
    "26089.pdf": "02E74AD3-3A2E-47D0-9D7E-A0A0FBCD4C0B",
    "26091.pdf": "C9E226B3-FB19-4A35-BB99-BF5D8B8A9424",
    "26092.pdf": "E814C021-689E-4B1F-AFCB-D17DDED8635D",
    "26093.pdf": "7666E8D3-6359-47EF-98A4-D79C0D00FC23",
    "26095.pdf": "B832DEF4-DDAF-499F-8326-7AA608A4685F",
    "26096.pdf": "7FCB43A1-9B40-44A8-8043-C5166C11AAC2",
    "26098.pdf": "1DAF643B-A187-44EB-AD42-497DDB07455D",
    "26099.pdf": "987C03C3-C39B-4ECB-9669-BA5BD3486857",
    "26100.pdf": "2E2D98B6-8333-4322-A8CC-2280B0F6B975",
    "26101.pdf": "C6D77352-583B-449E-AE86-51839B743B35",
    "26102.pdf": "CA1DAE38-07D9-4BCA-B923-2AB858C200F2",
    "26103.pdf": "2387A4A8-6248-4247-9A27-52900F885AE7",
    "26104.pdf": "433A2902-7728-42AB-B782-75B897276DA2",
    "26105.pdf": "B9848EED-F6F4-4BDD-8D09-91EDE36E53DF",
    "26107.pdf": "C9EFFC8A-70D8-4AF1-8C53-00DF333560F8",
    "26108.pdf": "EE0E7BFE-2E85-4763-9B0A-5FD6B00D409F",
    "26110.pdf": "3959F330-DEEF-44F0-BA5D-55571F84B605",
    "26112.pdf": "0296FAA4-D3BF-43D4-9340-093B03FAB789",
    "26113.pdf": "3496CE5B-06A1-488B-85D2-6E9ACFEDAA3E",
    "26114.pdf": "05899015-B26B-4B37-812C-9D5C849021A3",
    "26115.pdf": "3AFC5297-91C7-4BC6-8717-F00A53900C5D",
    "26116.pdf": "543C1CE2-1DA2-43CE-8361-400C7E6AB15D",
    "26117.pdf": "142A4AD9-AC65-427F-B0CE-9BDC12B1FE41",
    "26118.pdf": "66C07C4F-87FF-437E-B8DD-590D2AF9C660",
    "2612 tubular L5-S1 poster.pdf": "0C1000AE-F4BD-4B54-9875-7482D5F8B9DE",
    "26120.pdf": "D6B93C02-F602-4EC1-826A-52E1A800A449",
    "26121.pdf": "D7C2BE89-810C-47F0-8FA7-7F2DA95A74A2",
    "26124.pdf": "C3D85289-2881-4917-AEC6-F2BE89C5048C",
    "26125.pdf": "6DF971E4-E142-4C90-9DCD-3B6CEFF29676",
    "26128.pdf": "EC599EAD-098D-45B8-84C9-576B46B64652",
    "26129.pdf": "8C0FEA98-80BB-4857-8AA8-B9956AC528C9",
    "2613 PercLIF poster CNS.pdf": "62ED67E9-D568-49F5-AF9E-E2AA88A3CA9B",
    "26130.pdf": "C5DFE771-F15C-4071-8198-0C007C58F4BD",
    "26131.pdf": "CF835B93-AD79-4866-BD99-6C81425D6396",
    "26132.pdf": "DEA61FFA-E997-4BFF-8554-4A0A9AF76C34",
    "26133.pdf": "97950A63-8F80-4029-8495-6276AFC8B6C8",
    "26134.pdf": "B0FFB62C-A77A-422B-A362-822A5E150AA8",
    "26135.pdf": "97DBC3F0-F4E8-422F-85B3-DBCE6344ECA0",
    "26136.pdf": "2418477D-9875-45CB-8D16-A4C18AD45B73",
    "26138.pdf": "1C5A7835-0DA0-4829-848E-D3C650E5726B",
    "26139.pdf": "989BF42D-6FD3-41CF-AB2B-5DCD1E831BFA",
    "26140.pdf": "7F15531B-C45F-4B21-B4F5-FA34827497DE",
    "26141.pdf": "E8B11C3A-C8D6-4F6C-9129-1C02BB539B38",
    "26142.pdf": "E815BE0A-1262-4AE3-A802-B39EE8ED7630",
    "26143.pdf": "F5651834-9C0F-49B1-8EFD-5BE493E9AAB4",
    "26144.pdf": "36197F3F-B97B-4FBB-BB29-ABC45FDFAD8D",
    "26145.pdf": "05F7310E-02EB-4530-AB80-C75DD70B81DC",
    "26146.pdf": "9F084D6C-6D4D-4AC0-AD5C-655771015E44",
    "26147.pdf": "F1184D7B-0834-407C-8A93-2F9C2CDB5605",
    "26149.pdf": "63AE8723-21C7-4445-B2CD-120B66E58A4E",
    "26151.pdf": "E5292A83-134F-4AC8-8432-DB0148EB5592",
    "26152.pdf": "3B85851F-C3AD-410F-A5AC-3AEA39DFDC3E",
    "26154.pdf": "DAEE1DF5-0137-4E69-975A-2F9525772F1F",
    "26155.pdf": "2A5F55A9-E551-46E8-AEC4-8453CDEC0152",
    "26156.pdf": "6AB31616-16E5-4BE9-956D-F28F50CD000C",
    "26157.pdf": "6175AB5B-0EC0-4266-8EFE-911FF65B3B9D",
    "26158.pdf": "26CFC041-E2D3-4404-A582-D281BE0E4562",
    "26160.pdf": "80BBDB81-68FA-472C-B406-3A3884AC1C80",
    "26161.pdf": "B229D891-8E54-4767-8D08-E3DB58B9DB3F",
    "26162.pdf": "844B0AD8-9E5A-41CC-A12D-4AC3FCA97F6D",
    "26163.pdf": "AEF43330-C9D5-4384-BAAD-940FFDE1A685",
    "26164.pdf": "F9AF9AB2-52C8-459D-BE54-3003D740DF4E",
    "26165.pdf": "5C61F43E-434B-4569-BA4C-4EE9840177B0",
    "26166.pdf": "5D7F62F1-A133-4DEC-8DC7-5C23F9D7FE5D",
    "26167.pdf": "636D808E-31C2-4479-9F9B-053E3807AE99",
    "26168.pdf": "E265B613-1786-4D17-B591-A53ADF543AD0",
    "26170.pdf": "1838DA28-EAF3-4343-8F46-6BBB0BACD9EB",
    "26171.pdf": "D586B580-AE04-4B16-B547-34D96B53271A",
    "26172.pdf": "438FE75E-80E0-4DEE-A40A-13C64478F0ED",
    "26173.pdf": "07EB4814-40A7-42C9-B096-43F2B6888866",
    "26175.pdf": "09444DE9-2E70-483F-A0BE-3F24B7275A96",
    "26176.pdf": "15AEF978-0104-4F3E-A2AF-E68F9F2AE7E3",
    "26177.pdf": "0662967F-FDB7-4A31-8411-5BBECFDA8F49",
    "26178.pdf": "55D7D4DC-3145-4A91-B27A-4A39867BBE90",
    "26179.pdf": "0DCBF593-DAB1-49A0-8FB4-63C0890746E5",
    "26181.pdf": "E80AE94F-E62C-40D4-8D43-FBFF1E82334E",
    "26182.pdf": "F987C7CC-099F-416E-8297-1AC83F115F76",
    "26183.pdf": "DB8753AB-DD10-4E47-A77C-1FC94F3C2307",
    "26184.pdf": "0EE3B685-A9E9-4603-9A42-7125587699A3",
    "26185.pdf": "D99DD70F-C74E-4B88-8446-40DDD8BAEF76",
    "26186.pdf": "47D17B4A-4871-4898-9394-7BCD71B79891",
    "26187.pdf": "D26C8A85-4A6B-4AE1-98F6-B0CD1F8FA378",
    "26189.pdf": "443D3283-4CB1-42B3-95D9-66B1AF680653",
    "26214.pdf": "97F48159-6974-43C0-B6C7-0A34092B5BDC",
    "26222.pdf": "411870D1-DE0F-493C-AC66-6C1FF45F4572",
    "26232.pdf": "BA0498A0-37A2-4B66-9E92-CE9CEBE3531D",
    "26234.pdf": "F12DAB01-D2A0-40DD-942C-16C23A0ACCDE",
    "26236.pdf": "2EA9FB96-403F-4871-8511-D6601EED72B1",
    "26238.pdf": "31E4966E-A364-4340-B37B-AB190F18AE3E",
    "26241.pdf": "965CD6A0-A189-4ED1-B53C-3BA98301CEB5",
    "26253.pdf": "1EFBA46F-038A-4064-8DEF-E85B4E3E7A11",
    "26254.pdf": "7B15E143-E1CF-4589-A5AF-BED253DCB0DB",
    "26263.pdf": "38F87FE4-0F92-42D7-9962-B9A4EDD0311B",
    "2631 CARR CNS GBD NSGY Poster v2019-09-11.pdf": "F4A24AEA-9ECD-49C7-8480-D63201C714D0",
    "26316.pdf": "8066E642-3A48-45DF-8F13-9AC66F676AC5",
    "26317.pdf": "A55D21EE-F9FA-40D0-AF17-08A507FBB64D",
    "26332.pdf": "AA4C9CC6-1684-461B-8BDC-BB63B64796E0",
    "26337.pdf": "40D27FBC-D32B-455D-9411-598A34841243",
    "26348.pdf": "736B4E9C-8781-4DB2-BD52-9E4884BB8ECB",
    "2635 Differentiation of Outcomes by Treatment Regimen and Histology in CNS Primary Embryonal Tumors.pdf": "DFEBA3BF-1052-401E-A2F1-BDD93F87B431",
    "26359.pdf": "79AABF7F-FB2A-4491-8CB3-3CDE8193A1E4",
    "26373.pdf": "28463B6B-5F83-4CAA-8BF1-F28314A2DD37",
    "26376.pdf": "FF0018B9-CF33-44A8-BECD-57A8A019319E",
    "26435.pdf": "889B720D-6F52-4CB4-A24E-35F9ED80ABF6",
    "26458.pdf": "653CBA43-6189-4CEC-8306-ECD36D01A1C6",
    "26481.pdf": "1CD56179-9C37-4A50-864C-F63A226B9A1E",
    "26492.pdf": "135FADCF-A440-411C-935C-AB0AF4577F49",
    "26518.pdf": "272FE9E8-2BF0-4E9B-89CA-28E2E3E9A00A",
    "26527828088_Ogrenci-2.pdf": "BD0DED4C-D5ED-4EB3-86E6-4E625ACC3E59",
    "26544.pdf": "70810D16-EFEE-4425-9869-5E8EAE483E8B",
    "2657_PROGRAM.PDF": "D75B2947-4D18-4357-BE1A-488BCB3EB4C6",
    "2658_MEMO.PDF": "99794A61-A2D7-40F6-A731-7562330145D1",
    "2659 CNS 2019_Stroke Awareness Knowledge Gap in Women.pdf": "6B7AFB1A-6FF4-4AD0-AE95-F3441BDD0BAC",
    "26591.pdf": "CAECC717-CF8C-43B1-BAED-A45560AE0563",
    "2660_MEMO.PDF": "3EA81FB0-C562-4E37-BB9E-7601201AA908",
    "26603.pdf": "5CE901BE-5D11-453E-BC07-32E5C8FCC2BC",
    "2661 CNS-Poster FvS Aneurysms 2019 PDF (Corrected 09.08.19).pdf": "69BEB070-256B-4DB9-948F-B7FE47C96EC1",
    "2661_BY-LAWS.PDF": "D34071FA-FAC4-4112-858A-1E7DB68B46FF",
    "26615.pdf": "72901BE9-E649-4C2B-903E-398A95EF7CFE",
    "26616.pdf": "D7BEA5CD-D7E3-40EB-B4F7-15CAF74D83E0",
    "2662_BOOK.PDF": "C157BF36-F7DE-49E3-B2ED-027E65A7CCAE",
    "26621.pdf": "B720A058-2834-4BE6-A63E-D94CE99DC3E9",
    "26624.pdf": "9D61CB23-04A5-4B8C-AC1F-A3B3DD46F3FC",
    "26628.pdf": "FF73FCB6-C9E3-4C43-8329-DD66DABB539C",
    "2664_MEMO.PDF": "23D5A198-6584-4EB9-89E7-315FCC88B8D6",
    "26649.pdf": "03F8D44A-7AFA-49FE-80C6-3B371C40428B",
    "2666_MEMO.PDF": "06807A74-0994-4D9B-8BBA-66AD34EF8088",
    "26668.pdf": "704E0721-E2B8-4B24-AC58-84D40A056A66",
    "26682.pdf": "9651F53B-3B2A-4BC9-85AE-932E6C3C339C",
    "26688.pdf": "5F9DEF80-821A-4D65-A964-3151830961E4",
    "2669_PROGRAM.PDF": "B440C977-1493-4943-8193-4044DD0C1689",
    "26720.pdf": "536292D1-ED5F-476C-9AD2-7B00223A5FDF",
    "26736.pdf": "B98ED77A-A4D2-45A1-8BC3-B43B731B68AF",
    "26751.pdf": "21CEDC1A-93E2-47AF-9622-9D99C1DC42CD",
    "2676 Poster_Abstract28202.pdf": "B7CC45F4-A008-498A-9264-6692C90F770F",
    "2686 CNS-Poster-Shaken Baby Syndrome.pdf": "4316A02F-C500-4801-8845-76EB9A70651E",
    "26867.pdf": "DDD9D5C8-626B-4B80-A5CC-F911A950DFFB",
    "2693 CNS poster.pdf": "D91B0B11-A90B-4454-9268-75C9958283ED",
    "26936.pdf": "5BBFFE20-BCAD-4FC1-AA67-CFBFA37FB5B5",
    "26945.pdf": "E5161F77-1275-4642-8141-5FEC3CBAF6B1",
    "2704 PSCT Poster PDF (2).pdf": "6565F780-E0B1-44DC-9AD4-E473C6CBD4B6",
    "271 - Al-Sharshahi.pdf": "C481371D-21E8-46BE-8D1A-D10BB529E209",
    "2716_BOOK.PDF": "232FEFF5-48E9-446D-97EC-5824E02F0A4D",
    "27162 Tables.pdf": "14A77D35-EDD8-49E7-A3DB-FFD68296B3D9",
    "2717_PROGRAM.PDF": "B3E8D9D3-B262-4315-9C79-1EC876606C46",
    "2718_PROGRAM.PDF": "6AAE1DB7-881A-4421-8A65-689C7F69FED5",
    "2719_BOOK.PDF": "9592A395-F8FA-47E9-BD17-4D5096693CF2",
    "2724_MEMO.PDF": "BC8F2DC7-97FF-4D92-8A06-156BAC8BC054",
    "2725 imri5ala_cnsposter_2019_pdf.pdf": "BEC55178-FF78-453F-90D0-98B7016A678E",
    "2730_MEMO.PDF": "3E19340A-C772-4E9B-A9D8-E7F76F76056A",
    "2731 CJ CNS Digital Posters.pdf": "5146EF37-48FE-4BCC-B339-A180D99DDF07",
    "2731_MEMO.PDF": "09E69F86-A318-44DB-9D3E-03D4BC51F315",
    "2734 CNS-2019_Popov.pdf": "49A208A4-086F-493A-B71D-74536CF24409",
    "2741_BOOK.PDF": "520FF164-92DB-481C-AFA5-839AA8EBE3A7",
    "2742_MEMO.PDF": "088A4871-7A64-4F9E-AD66-8FAC60E5908D",
    "27426 Two Year QOD Spondy Study-JANE Award.pdf": "CF3AF570-C052-4F51-BC8F-E1848ABF7894",
    "2744_MEMO.PDF": "4FFB0B8A-A65D-46BF-9009-147E79D67059",
    "27442 DH PEMF Manuscript_Final Mayfield and JANE Award.pdf": "4F83E7C8-ACA9-4F4A-AD47-9B20492A14D9",
    "2745_PROGRAM.PDF": "DC26C8A0-7581-4B9D-8227-090D906E531E",
    "27556.pdf": "D1BDBAAA-BC61-4A39-9DFB-D1BE3DAC760A",
    "2767 Kern EVD Poster.pdf": "95A2F825-2B30-4A85-9C8D-80A14E251CDF",
    "2770_BOOK.PDF": "0F38C0CD-7AC3-40AA-99C7-418009C1C838",
    "2771_MEMO.PDF": "ED7BF3E2-F21E-4414-BD13-CF238FD68A86",
    "2772_PROGRAM.PDF": "E8FBD751-E91E-4778-92D7-3B92ABAE2E1C",
    "2773_MEMO.PDF": "57A1A2F3-D589-4A4C-AE65-37714861FAB7",
    "2774_PROGRAM.PDF": "E7200800-1B8C-450A-9F09-9292E1979550",
    "2781_BOOK.PDF": "F421C204-635C-46B9-B5DF-D56770F7725A",
    "2789_NEWSLETTER.PDF": "16533E43-9B5B-480D-BF3F-D28E00E8B90C",
    "2790_MEMO.PDF": "428D36A4-229D-488A-AD3C-C06D35922035",
    "2791_MEMO.PDF": "9E41B264-2B8F-44E9-9CBA-33C9CCD14066",
    "2792_MEMO.PDF": "CB11472C-1A84-4AFF-A060-A7A829FCDE99",
    "2793_PROGRAM.PDF": "68CCA9AB-8398-4337-AFED-26E2E8E34F9E",
    "27935497_eTranscript.pdf": "6764AACC-1EC1-4743-BE8C-03A78007D025",
    "2811_NEWSLETTER.PDF": "65CE61C6-ACDF-4492-8CE9-20CC3134D8A8",
    "2812_MEMO.PDF": "4220D960-ABBA-44F4-81ED-116138DD9B5E",
    "2813_NEWSLETTER.PDF": "AD525CBD-1441-4949-AFF5-D64BBFC3F120",
    "2816_PROGRAM.PDF": "F9B8FF66-FA87-4DF7-9143-63927329F3BC",
    "2817_MEMO.PDF": "7CF436C9-C9C0-48E3-A77A-61B0E655E796",
    "2818_MEMO.PDF": "DA20B074-DCD1-45F6-BE07-028B1B030599",
    "2821_MEMO.PDF": "E0F3072C-B329-4B34-A1FE-8425A49255B1",
    "2827_001.pdf": "9D890C0C-BC3F-4E8E-A1DD-0212652F0214",
    "2827_MEMO.PDF": "770986C3-670F-4E43-A6EB-8CF6B24F9EA9",
    "2884_Aguillon.pdf": "1E7D075E-BB3C-4523-9B2D-B91284776BAC",
    "28881 CNSResidentAwardSubmit - MSSIC Symptom Duration Manuscript.pdf": "52E822FD-BD56-4B35-B8DF-C5A314477C80",
    "289 - Lam.pdf": "D4F853CF-D97E-4B4D-97FC-25555519FA92",
    "29-09-2023 Serhat Aydin Good Standing.pdf": "55896872-2EF3-443A-8C46-0C3E0B3F7EA0",
    "29-09-2023_Serhat Aydin Good Standing.pdf": "3D3FC262-B812-46D0-B938-4AFB4E896FCB",
    "294.full.pdf": "DB542946-4A18-4138-8C09-2EACE1D9DA2A",
    "2963.pdf": "E19225A3-AFC2-400A-91E7-9E7218EEA4DC",
    "2988 Kern NPH Comorbidities Poster.pdf": "6A180F03-24AC-4C11-AD17-1A0D458C682D",
    "2e70afbd-6c6a-4777-a5cb-cab8d781a6fc.pdf": "E4B44E3B-1B8E-4844-BDE5-08D1C1501C17",
    "2pm_pocus_lab_schedule_and_group_assignments.pdf": "C18ED6B5-1AC7-4431-AA44-270814FB0991",
    "2ucsfNeuroSurg2023bro_sk.pdf": "7ACAA376-06FD-45C0-8EEC-9E0625BEDDF4",
    "2ucsfSpinalVegas2021Blast6x9sw.pdf": "1183AAC0-4B32-4324-9977-83077D7AA669",
    "3 Cerebrovascular_4-21-2022F.pdf": "FC7F68DD-0588-4395-B291-315AA93C1D7C",
    "3 Especialidad neurocirugía.pdf": "4727C100-839E-43FA-8591-5AE8B07BC6CC",
    "3 Green Destiny - CNS verification.pdf": "94049E85-1A15-4232-91A9-E9ED3EC5CE9B",
    "3 Letter of Recommendation Mentor_Yaghi.pdf": "48AA9E79-0A14-450F-9F2A-A6A504EB1D38",
    "3 Month CRST overall table.pdf": "2A81B6F3-D364-45F8-B849-086D8F612B8C",
    "3 Offer Letter SIGNED- Coutinho Pedro.pdf": "4EDD08BC-E4E5-45CD-A341-55109FAB3F0F",
    "3 osteoporosis assess.pdf": "1F6C7881-E254-436E-A44C-825939E7091C",
    "3-Ropper-Fridley-Spine_Optimizer.pdf": "EA6D7E7F-7321-4B9F-9F49-A29D84A72322",
    "3. Figure 1 Tumor Board Outcomes.pdf": "FF1D13D8-B31F-4954-8860-A0EC8576A2D3",
    "3. Qiu Liming_Employment Offer_1.3.23.pdf": "9648C62F-564B-4AA4-9F33-C515623A3332",
    "3. Wellness resources.pdf": "DCC8967C-6E2C-4966-B8CC-8D2B868EB5CE",
    "3.A DSPN Ad Hoc Coding and Attribution Workgroup report.pdf": "47C87E9E-AEAD-4D6F-A72E-A8E075C2D07D",
    "3.A.i Procedure by provider type_Veeravagu.pdf": "3805CD3F-6434-4527-9199-9E1E580D0492",
    "3.A.ii MS query.pdf": "D22E2B2D-C236-46D8-B1DA-587662F51634",
    "3.A.iii CPTmedicare.pdf": "B8B2356F-A7CB-4A31-A722-2763884AEA06",
    "3.Derstine.pdf": "A448FBFA-7C64-4E4C-96D1-1D33311A78B4",
    "3.NSI certificate.pdf": "E667BFB0-CCDF-40A3-BE68-96B4115724CB",
    "3.pdf": "3E85D959-1B05-48AD-9728-38C230A3E4D4",
    "3_lumbar CEAs.pdf": "778CF5A8-BFB4-4354-B195-C38347AD7E5A",
    "3_PXA_NCDB_Table_1.pdf": "41580B82-94E0-412D-9BCE-E92F3F96CB60",
    "30-Day Readmission.pdf": "FA355504-3B8B-4FB9-B7FB-EB6930B65F9D",
    "302bsignon.fy2018.withsigs.pdf": "5D0F39AC-CEB9-44BE-8602-AC5044D22E31",
    "3044_001.pdf": "8A266BA7-4FC1-443D-BB4C-C62CE6676AE3",
    "3059_PROGRAM.PDF": "4E6D9944-96F7-4371-A177-38E781352C7D",
    "3063_NEWSLETTER.PDF": "72E1DF13-9E03-4CA7-AE4D-1B20EC538C91",
    "30656.pdf": "B28A6504-0404-4C13-86F4-D204FEB61A69",
    "3066_MEMO.PDF": "9DF57A07-E9CB-4CB4-8E78-106874A532D2",
    "3067_MEMO.PDF": "9989D936-587C-4117-A65C-D4A7F3C07FF2",
    "3068_MEMO.PDF": "BDA36AB0-CD0F-4FAC-8182-960E95FEFDD9",
    "3069_MEMO.PDF": "FFBECE42-B246-4FC8-B225-7000D84FC28C",
    "3070_NEWSLETTER.PDF": "1CD95DEC-C5E6-49F9-ACB4-1557AC6DA980",
    "3071_PROGRAM.PDF": "1095690A-9FAF-4674-9ED6-43B57EF1A158",
    "3072_PROGRAM.PDF": "D97ADE05-210B-4442-B417-828A3D64B243",
    "3074_NEWSLETTER.PDF": "B59630BE-D2D4-45EF-B679-44990B178548",
    "3078_NEWSLETTER.PDF": "2B1CA5D1-BB8B-43DE-8BCB-577AB4A02477",
    "3079_PROGRAM.PDF": "397CF522-971A-4F77-9387-70E1A2595F51",
    "31. MCI MCH REGISTRATION.pdf": "CC5BD9E3-77BC-497F-BA14-0AEC47C2B853",
    "3170_PROGRAM.pdf": "E4A06A50-96FE-40E4-9BCD-3CC08336E4B7",
    "3175_NEWSLETTER.pdf": "E9673604-E5FC-4141-BA38-0C19C39EF055",
    "31754.pdf": "2CE81300-A9B1-47B6-8EB8-7B93404414D7",
    "32089.pdf": "C9D12B2C-B84C-4D20-BE58-2D19F702A840",
    "32327.pdf": "30095E99-1788-4B5D-9357-C30E95AA3539",
    "32327_Lavoie.pdf": "4F11EB43-B37D-44E7-B931-B496D0357B35",
    "3237_CNS_E-POSTER.pdf": "293C597D-405B-468F-B4C0-A1E3B39EC198",
    "32435.pdf": "EC3C07DA-0839-4A31-B673-85ABD191FC0E",
    "32489.pdf": "DD137C08-0640-4A9A-831D-80220BFCEAD1",
    "327 CNS poster DHS epilepsy v4.pdf": "6F370C4B-DBE2-475A-A16C-46DA2CF811A1",
    "329 Caras et al. Opioid Dependence Spinal Fusion.pdf": "FB74527C-963C-41CE-82FF-11342D302EBD",
    "32987.pdf": "EE70BD00-EDA9-4918-85BD-060E07100D3F",
    "32ChannelEposter.pdf": "E7215BCA-E31E-4638-A8F1-A1A0412CEC7D",
    "33-Guidelines for Service on the CNS EC.pdf": "262FBB80-DB8D-4231-A500-AC0ED689BD2C",
    "334_INSPIRE 1.0 CNS 2019 digital poster_25Aug19.pdf": "C497B27E-E054-4406-9E75-E195E7C07785",
    "3408_001.pdf": "2CF30291-8004-4EA9-A3DA-A3F81248BC60",
    "3439_001.pdf": "6325B10B-272D-4636-B467-EF670311BF95",
    "343b5b19-5c18-4472-a969-1d7efb5ed99d.pdf": "7D85C3D9-5457-4E61-955C-D22BB4F8D36F",
    "35130011_eCertification(ElectronicAcademicCertification).pdf": "03DB7434-0702-4355-AAF2-ECEF9C709AF7",
    "3561_001.pdf": "3629297B-9836-4293-993B-9A5BC093C190",
    "358 CNS2019_MMS_SCS.pdf": "42E26BE4-F8AB-4DC8-8562-46821FABAAD9",
    "360 fixation poster .pdf": "D93FD713-F21F-4329-8A10-B7D8EF8200EC",
    "361 DLead Registry CNS 2019_FINAL.pdf": "D9772D63-278C-49AA-88BD-992237ABF89F",
    "363 CNS 2019 Digital Poster.pdf": "14A05C63-1E43-4D18-9814-5DBC5E850883",
    "36x48_PNSG AANS_110722.pdf": "AE985F9D-712E-4823-A90B-6211AAA1B4B7",
    "3708_001.pdf": "D863981F-960A-46CF-ACEB-CD44EE38F125",
    "3742_001.pdf": "C08CF9D7-BEBC-466B-8318-4DEBB2696861",
    "38minutes.pdf": "F9E52C14-ED39-4447-9AEC-94ED4E5BD77B",
    "38Program.pdf": "7AC34892-8035-491E-88DC-193602074D58",
    "39-1-2-2-004219-10-2021.pdf": "F8502864-73BA-40FF-A74F-F9B3023FF8AE",
    "397_Letter_English_Roberto Cisne de Paula.pdf": "ED6FAF3D-74F8-4FFD-BDB6-228DD0D99AE3",
    "39865862_eCertificationElectronicAcademicCertification.pdf": "030D69F9-706F-4A76-9347-0AF29E4D6640",
    "39Program.pdf": "802E7C81-8EF4-4224-B17B-B3080582955E",
    "3D Printing CNS Digital Poster.pdf": "D22803A5-D53B-4C22-885C-527E70C8AC30",
    "3D referencees CNS.pdf": "5A849A8D-6849-44AD-83EE-94A2C06574A6",
    "3D spine models poster.pdf": "4E679C1E-B286-40FF-93F2-9D8CE0F7E509",
    "3E40867B.pdf": "04D5499D-2DD2-4FE9-86C1-B84264675BE3",
    "3NSI membership.pdf": "6101403B-5F87-421D-87B7-C8A7DAD0BD10",
    "3ucsfNeuroSurg2021bro_sk.pdf": "EEE75F52-0E0D-4BDB-A0A8-50DC893F68DF",
    "4 Approval letter Korea.pdf": "A8BAB995-34F2-4D91-8D8E-AB9BB18CEE96",
    "4 Letter of Support of Time Commitment Chair_Yaghi.pdf": "7987EB33-58F3-492B-B33F-1ADD9EA6B762",
    "4 NeurocritCare_4-21-2022F.pdf": "E6A09176-091A-4C67-B6E5-4B2E54F1B2BF",
    "4 nutritional assess.pdf": "C4B10256-6FF9-48CE-948A-BB8A9ABBE6F6",
    "4-Approval.pdf": "105719EB-73C9-4BCA-A42A-2DF5B6D82AF6",
    "4-Tihan-Wood-Neuropathology.pdf": "CE01B0E6-3DCC-4E4F-9DCB-9898C7B9A6A9",
    "4.29.19_physician_auc_letter_to_cms.pdf": "33D11BF9-1CF6-4AA1-8579-38BEB495425B",
    "4.a.i Career Development Programs.pdf": "9688AB4D-8F60-4222-9748-3C837DC5B34F",
    "4.b.i Proposal for Revamping Research Grants.pdf": "0D4F3355-7E27-4CED-88CF-B355474C700E",
    "4.pdf": "2C946D89-7847-4F23-9F7D-77B22E46A2AE",
    "4????????????????.pdf": "A650ABF5-1BA9-42F5-90CB-1FF4A3F9EF2C",
    "4_ccm.pdf": "0F27AD58-1C14-426A-9107-04A8808AB17A",
    "4_PXA_NCDB_Table_2.pdf": "C2C745DB-B22F-4DB2-8056-EC93CC522C07",
    "401k Safe Harbor Amendment 2020.pdf": "6CF50D57-E43B-4424-A91A-F59C2A8FEE84",
    "401k Summary Plan Description.pdf": "3B61C68B-1615-4027-BF59-C1A09A22173C",
    "4078 Poster_Siler.pdf": "ADACAD84-FA5B-4FC5-AC01-45B450BD5C7F",
    "409 031414.pdf": "833E1EE4-9EA4-4B6D-B0AC-045D50747EDC",
    "4095 CNS2019-TMC Poster.pdf": "3DBEE48F-7C81-4163-ADFE-EF81FF542D1A",
    "40Program.pdf": "D0C49F7A-14C9-4EAB-AB3D-F02F0EAF722D",
    "41050517_eCertificationElectronicAcademicCertification.pdf": "4E3D3B2A-BD0F-414E-A493-771FD2CA6377",
    "4107.pdf": "FC8420B3-A515-4CA3-BA68-5B1102591ED1",
    "4114 T.Yanagisawa CNS2019 Poster.pdf": "161CF6F5-7044-426E-B5F3-6C6C26C17733",
    "4120 MEN 1 poster (CNS).pdf": "9AD3EB0A-4AB5-4E66-B319-3834F6B1C11A",
    "4158 Digital poster cns2019 kanoke.pdf": "8CF65106-0149-48CA-8F74-D1FEDE10D86B",
    "41Program.pdf": "55FBD698-FD57-4D32-9F16-54A81583124E",
    "42_cfr_part_2_conference_letter.pdf": "DD99D5D9-0A0B-4974-94FD-940E5356007B",
    "420 30771.pdf": "66397897-11B1-42F6-BB15-98D2843AC960",
    "420.pdf": "A1738D58-0911-4217-AC01-B45ED182EB57",
    "423 32825.pdf": "1BC4D9EF-7706-4BD1-945E-3B4F8C285689",
    "42Program.pdf": "94F46253-57C4-4973-BFAE-F045A9F448F6",
    "4364_001.pdf": "7392A746-2F52-4300-AFEF-40087F507FF0",
    "439 031744.pdf": "DB3C4CCC-F29C-422E-91DF-B2020C0C18C1",
    "43Program.pdf": "B432CDB9-ACF4-425F-9930-951372865BC8",
    "440 CNS-Poster-Toshiki Endo.pdf": "B75EC2A5-0E2C-48FD-8160-B87968701C46",
    "44Program.pdf": "ECF6F957-6F1B-4082-86EF-6FD73EB3312D",
    "450 30779.pdf": "91D54529-E856-4A8B-8DD3-198F0E861063",
    "465 031789.pdf": "BB7EAFDF-B660-4771-8737-3CADA4AD6654",
    "467 031507.pdf": "AF63351B-A4B9-4941-B035-0A0B0EA690EA",
    "468 030778.pdf": "F6B81399-2EC2-42B1-BF7E-EB514F46AF52",
    "4689063 ROBINSON BOURS LEON ESTEBAN DE JESUS.pdf": "E289EF83-2620-4711-B66E-743903B50BE8",
    "46Program.pdf": "79C00A60-C624-410C-B543-03C56FAFE080",
    "477 032463.pdf": "B9E6E089-93EA-4DC7-B4D3-97D5EA1A54E4",
    "479 032463.pdf": "4529C8A8-D4ED-42E3-AA7E-98E6CE115006",
    "47Program.pdf": "8FB499EF-8F29-4DF1-8076-B973D83CE44B",
    "487 32510.pdf": "1E1ED102-36F4-4493-8A20-0B7B1F178E14",
    "48Directory.pdf": "70D5DB2C-CE51-4B30-9FB7-0C1725B47671",
    "48Minutes.pdf": "A21226E5-1BB8-408C-9BF2-E02A00E78689",
    "48Notice1.pdf": "4D0AD3BA-8E5B-458B-8915-E952ED86FA2B",
    "48Notice2.pdf": "71B7278A-A468-477C-A2AD-B2686E447BBD",
    "48Program.pdf": "AF9864AA-18AF-4D7D-B655-852FCCCE05EF",
    "494 032374.pdf": "FB364C23-8ADF-49B2-A0B3-0098629E0CA3",
    "499 32853.pdf": "590D75EA-551F-48BC-895C-C5AD3250B75B",
    "49Program.pdf": "3C438A0B-4219-4971-A7FE-3198B400C072",
    "5 Epilepsy_edited_4-21-2022F.pdf": "89894EFA-F206-4BF3-A5E5-746359ABF1E4",
    "5 pulmonary eval.pdf": "2649A158-C46E-425A-836C-19A634937973",
    "5 Sourdough Bread Checklists.pdf": "185A3480-DBD4-49FB-95CC-0E71F9C66DE0",
    "5 sourdough checklist.pdf": "7BDD86F8-FE09-4DF0-AC3C-45FB5D9A8F30",
    "5-12-year-old CNS E-poster 2023-07-18.pdf": "960C1710-EF3F-438D-9073-A4A28DEA1FC3",
    "5-15-24 Shah Khushi.pdf": "85E8F46A-0725-456D-8A5D-76998CDD83B5",
    "5-Germanwala-Tumor Skull Base.pdf": "351696FD-435A-4869-B18C-435B81E81775",
    "5. Fredrickson 1998- What good are positive emotions.pdf": "4332D573-9860-43E9-87E4-59EF4783D147",
    "5.???????????.pdf": "7A3E65B0-8959-4CF9-8CF9-B00C6D2A6925",
    "5.1 Treasurer's Report.pdf": "5DC1F766-AD11-4705-ABE8-D69A139A274D",
    "5.Harbaugh.pdf": "896FB0A8-57E2-4F76-A026-F007FBF7E416",
    "5.pdf": "B82A6E1A-4947-4733-BD8B-7A3F968E88CE",
    "501c3 Approval.pdf": "104EBEC3-BF91-4E85-BB50-A6871E9FCFE6",
    "505 031612.pdf": "92A13556-22CC-40CF-B726-2B594063EB03",
    "507.pdf": "AC175B2E-3AAA-4229-A714-4839DEF4ADBE",
    "508 032570.pdf": "0CE6605A-9B3F-4CE2-BB2C-6B49D0AC09BD",
    "509 032311.pdf": "2297BBD2-AAD5-4C67-A454-8C8597959E13",
    "50History.pdf": "730E0AD6-74B6-4F80-8E39-B71B36F158E6",
    "50Program.pdf": "95FD9005-B150-4463-A2EB-D52ED27A13E7",
    "50RoundRobin1.pdf": "3CC0FA59-3EC2-4DBE-AE9C-2B71E0766DE3",
    "50RoundRobin2.pdf": "51D4F575-E9FB-42C8-BFBE-1EDD7D3ACB17",
    "50RoundRobin3.pdf": "A59C0051-5B30-4E0A-8DB1-3EC68CD51F75",
    "51 Attendee List.PDF": "D0BCBE1C-7792-43B7-ACB9-3C94C9AC1421",
    "51 Attendee Listing.PDF": "06836D8F-9FD0-4634-BF94-286ADF752AFC",
    "51 CNS Charter.PDF": "86F37A97-B501-42F1-B2A7-ABAE84857720",
    "51 Committee Members.PDF": "D2DAE143-5EF7-4744-81CA-5CF796CA1D02",
    "51 Constitution of the Congress of Neurological Surgeons.PDF": "6105728D-5072-4486-A3E5-D3CE061BB630",
    "51 First Annual Meeting Progam Book.PDF": "3A2B43A5-815A-4FAA-9049-BED6C032EB93",
    "51 First Minutes.PDF": "F49DE63B-C5D2-463D-9313-94E518BEF4B1",
    "51 Special Thanks Card.PDF": "89C5E880-A0AB-4C6A-A16C-3F2A67ECDD73",
    "51 Suggested Members List.PDF": "D997786B-18AD-4CAB-8D0C-1A4FCF3C0CD0",
    "510.pdf": "1FC354AE-E04A-43BD-BA18-099B49EDA85D",
    "513 32678.pdf": "B6FE7311-25AE-4136-B28C-F0595C3D9306",
    "516 32300.pdf": "B484F468-332F-4003-BAD5-A3DAB94D0EB1",
    "518 032889.pdf": "E8402F90-02A1-4A2F-8586-D05AC4F28EA6",
    "51Program.pdf": "69FC67E9-6812-418D-B0AC-5116243F8D7B",
    "51RoundRobin1.pdf": "83E93C57-7706-4416-A052-9EBCF7B62CD0",
    "51RoundRobin2.pdf": "3806F885-8FF6-48EF-AB6E-23612723757B",
    "52 Congress Newsletter.pdf": "AD127155-8546-43F3-B40F-59404F115352",
    "52 Report to Congress!!.pdf": "B5DE2232-1286-4A4F-BEBC-958DDBC7F35C",
    "52Program.pdf": "3858664D-3249-4559-BA6D-433E80F63995",
    "52RoundRobin2.pdf": "CD8FFC14-D92E-46CD-85A7-BEA99BB261E4",
    "52RoundRobin3.pdf": "12AC20D7-292D-4A9F-8B41-649E805982BB",
    "52WaldorfAstoria.pdf": "1FE67D56-1522-4D29-8E4A-99347B1E63F5",
    "53 CNS Havana Trip.pdf": "CE6A396B-49F3-40C4-94F8-3CEE7A7EE9F7",
    "53 CNS Logo.PDF": "453EF60F-5841-40E2-8FDD-A155F7CBD3A9",
    "53 Meeting Minutes.PDF": "73A7EAC3-CA16-415B-A597-2E9251403248",
    "53 Scientific Program 1953.PDF": "646ED3A9-161D-463B-9A5E-0599056A014B",
    "53Abstracts.pdf": "BBD8EA16-8129-4CFD-BF24-CE5BB0A7E2B3",
    "53Program.pdf": "1E5392ED-1528-4A04-B737-0DEA2266D628",
    "53RoundRobin1.pdf": "65DB0B8D-2225-4F26-A321-54F27F11D664",
    "53RoundRobin3.pdf": "84A3F331-EEEE-49E8-A655-1D9A58DCB6F5",
    "53SantaBarbara.pdf": "028559E0-6A6C-4568-9F9A-1B0A2DE2627A",
    "54 Clinical Surgery Letter.PDF": "D4C96960-5AAA-4A00-818A-1658F7D36A16",
    "54 Final Topics for Addresses.PDF": "260FEEA7-B5D8-45D0-9DF7-BF7F7E604EF8",
    "54 Letter on.PDF": "0B4E1003-7825-4307-9143-17702AA38D46",
    "54 Memo Membership Card.PDF": "F333D000-B075-4EA0-819D-4FD3F8C3A625",
    "54 Scientific Program 1954.PDF": "092E3BD8-834A-4F3C-AFFB-158D09E8C23C",
    "54PresidentialAddress.pdf": "14AF09C3-35E4-48FA-A20E-97ECC8BE59D9",
    "54Program.pdf": "3333D440-BEB2-4795-8774-18AE7F1E6022",
    "54Roster.pdf": "C835E8C7-7717-4C67-AFC0-0771E62901AD",
    "54RoundRobin2.pdf": "894F37C7-D005-47CC-984E-7195D08BF9B9",
    "55 Scientific Program 1955.PDF": "85977FF1-1BA3-4D1D-BA66-E58EC3D6232E",
    "55Program.pdf": "9BBF0603-6BCE-44F7-9BFC-A207A31E847D",
    "55RoundRobin.pdf": "BB589282-7002-41BF-AD84-4B597C723E82",
    "56 Newsletter Volume 5 No 3.PDF": "2BD8705D-D56A-4141-90DA-B523ED90136C",
    "56 Scientific Program 1956.PDF": "F8838A8C-38BD-4603-89B6-1D25877BC61F",
    "56Program.pdf": "8782C3DF-5F09-4DAC-8F88-D5833E53CFF7",
    "56RoundRobin.pdf": "B84A3DEE-0AE2-4AE5-A2DF-F47F5D48DB06",
    "57 Directory of Neurological Surgeons 1957.pdf": "F3D53CF7-2F18-433B-B252-8959AED1D6ED",
    "57 Membership Roster.pdf": "CC2C782D-3096-4041-AD9E-9E85A83565E1",
    "57 Scientific Program 1957.pdf": "18520F1E-B56C-4ED3-85E5-EC06BD101F0C",
    "57Program.pdf": "9E1E390A-9FE6-4649-B2AA-A79865E57E2D",
    "57TheNeurosurgeon2.pdf": "58E95D6D-12FB-4A78-95AD-2EF18A180D26",
    "57TheNeurosurgeons1.pdf": "2C130E05-9C90-4E07-9AD1-401F402637E4",
    "58 CNS By-Laws 1958.PDF": "BC815FF1-E794-44FE-88EF-A07CECB3907E",
    "58 CNS Charter.PDF": "63FE853B-7AFA-4167-8188-5C326862D4E7",
    "58 Cost Summary of InterNat'l Gavel.PDF": "7A2E3B37-B300-449A-988F-54CE1D0D666A",
    "58 Financial Statements.PDF": "0DD4EEAB-C700-4B2F-8383-4481F151B73F",
    "58 in Remembrance of Honored Guests.PDF": "75A67C8D-1743-442A-BFF7-F64E589F5C89",
    "58 Memo on Incorporation.PDF": "A45DB4A7-E2E8-4641-8BDA-15DB9680A551",
    "58 Memo on Scientific Exhibits.PDF": "3512493C-4B2C-4737-B92D-0DB7C9532CEB",
    "58 Newsletter Volume 5 No 2.PDF": "2CAD9909-93E4-4F25-86C3-A667307908F3",
    "58 Notice of Society Nominations.PDF": "A3C1F312-7363-4BED-B767-A098AE3FFA40",
    "58 Photo Fee Memo.PDF": "F9A336DA-CAF8-4D4B-86C8-9302105C03EB",
    "58 Proposal for By-Law Revision 1958.PDF": "E5D164D9-164C-46B8-BC3E-663784A99D1E",
    "58 Report on InterNat'l Gavel.PDF": "4B4C16C0-6BB7-4318-96B6-8E8AE00AAF81",
    "58 Scientific Program 1958.pdf": "9ADE2F82-FAC4-4D18-896E-7DBE28B8EA27",
    "58Program.pdf": "49D4BC2F-36A2-4954-A1B7-E29625C4812F",
    "58TheNeurosurgeon.pdf": "B7C4E7D9-27DF-421C-88D3-16DEDCAD35EF",
    "59 Annual Meeting Registrant history report from 1951-1959.PDF": "A33B4BAC-5BD0-42A2-A5D3-334C284CB581",
    "59 Auxiliary Letter.PDF": "E8A75D49-4FC7-4FE3-9A72-390D2D4200F2",
    "59 Auxiliary Note to New Members.PDF": "B15090E8-1F97-4EF6-97EA-69636B032178",
    "59 Meeting Attendance Numbers and Registrant Listing.PDF": "0F468A96-1986-4C66-9A17-52A4C58506AE",
    "59 Meeting Attendence List Letter.PDF": "B36E8DD1-75AB-4AE8-88C3-310F487C786E",
    "59 Meeting Events Letter.PDF": "24B2B145-665C-4218-AA6E-E7DAC26C802E",
    "59 Newsletter Volume 8.PDF": "B3FAB712-6CC6-4FD9-B490-25B22A88197B",
    "59 Scientific Program 1959.pdf": "1C8506F3-8E96-4D4E-A303-C85619CD88F3",
    "596.pdf": "244653E6-6C8B-465A-B35A-F24723F3B5BC",
    "59PresidentialAddress.pdf": "3108F357-DFB9-4501-8F4B-B9083DFD9123",
    "59Program.pdf": "D0CAD14D-47C6-462D-B633-BCFDD7782EC4",
    "59TheNeurosurgeon1.pdf": "1F1DF7A2-834E-451D-B4F2-18715D8F9342",
    "59TheNeurosurgeon2.pdf": "773E8606-D08D-40EB-BE8E-32CD2E0682FE",
    "5ALA Tables JNS.pdf": "533967B5-ED9B-4A20-B9E9-55469007D44F",
    "5th_year_MD_nimrodkovacs.pdf": "0C3293BE-D1A7-48A7-8C03-1065468A53CA",
    "6 ????????? ???? ????? ????????_????????.pdf": "5E11C290-B060-4BA6-A0DA-EF89D793C0FB",
    "6 Pediatrics_4-21-2022F.pdf": "E4573FCE-98CE-42F7-A916-47DBFD4763BA",
    "6-Patel-Tumor Skull Base.pdf": "949E9E0D-AE3F-4F44-9498-D160C5B61A33",
    "6.A.I  SPC Committee Report.pdf": "35FCD615-FB89-427F-B155-B4F3412B083E",
    "6.A.ii Exhibits Committee Report 4 2107 - Copy.pdf": "C5F6D9A7-31F7-4D9C-84D9-E98D277B3835",
    "6.A.ii Exhibits Committee Report 4 2107.pdf": "2E93A325-17DD-4057-9BF7-A4F7E8B3824E",
    "6.B.i  CNS-DSPN 2017.pdf": "7C61CFC5-0600-47A8-A378-9D5BF2E91658",
    "6.B.i.a  CNS.practical.17.pdf": "AF3763AC-9C44-49AF-BE01-14EEAB95CBBE",
    "6.B.ii AANS 2017 Spine Section Sessions.pdf": "0B3475FE-89C9-43D7-B9AF-CE6DFD4CD779",
    "6.D  Membership Stats.pdf": "6C9C1E37-FE62-4B99-8D26-760823AE77B1",
    "6.D.i Spine Section Membership Statistics from AANS.pdf": "4288DEAF-A2C8-4852-9D6D-21CF092CB668",
    "6.D.vi  2017 DSPN Membership Spring Report.pdf": "BCE0135D-8F47-4E58-A5AB-48646ED5DE57",
    "6.E.i  CNS Nominations 2017.pdf": "5A7DD6AB-455C-431C-B8A0-C472804A93C9",
    "6.E.ii CNS Nominating Committee names from DSPN.2017.pdf": "E65B32EB-99D8-418B-A24C-BBB755FCF8DC",
    "6.E.iii AANS Nominations letter.pdf": "6E9FE943-5A20-4CF4-944F-5055DEC1942D",
    "6.G. Peripheral Nerve Task Force Report.pdf": "C23EAA2C-AC43-49D5-929B-3FD0A420884D",
    "6.H  Awards Committee Update.pdf": "3A1505F0-1B89-4BA9-986D-C52382F1BF26",
    "6.H Research & Awards  Update.pdf": "986AB5CA-C13B-4CAE-A244-AFDE39E18DD4",
    "6.HarbaughTuesday.pdf": "E4430EC3-1620-4C5C-94EA-66B01FABB224",
    "6.I Rules and Regs Report Jan 2017.pdf": "5220F12C-106E-45B9-B1C1-F0D71379B798",
    "6.pdf": "39CA999F-3326-40F1-8C6C-AED3D56BB718",
    "60 Auxiliary Guide Chicago.PDF": "8A4ADD1A-4EE2-4099-8FC9-19FD53357C64",
    "60 Auxilliary Letter.PDF": "2ADC2A88-D162-48E1-AB75-B7FC344DF3AE",
    "60 CNS By-Laws 1960.PDF": "E2441E62-F1B8-4596-A1B8-B6223CF05679",
    "60 Committee Chairmen and Members 1960-61.PDF": "35E0699E-B27C-4836-800E-F239D5C68CAC",
    "60 Directory of Neurological Surgeons 1960.PDF": "79D5A3C2-681A-45AB-AE69-615DEDC09145",
    "60 Scientific Program 1960.PDF": "DE096E88-80FD-4228-8F64-024FF089672C",
    "60PresidentialAddress.pdf": "2518A222-EF14-4AC2-8BCA-0C8107CEFC19",
    "60Program.pdf": "02549422-5431-430A-8CA7-C162D49B8300",
    "60TheNeurosurgeon1.pdf": "DAD49889-744B-4495-A57D-926E9CE02880",
    "60TheNeurosurgeon2.pdf": "2F15E702-E7B3-4525-B2D4-091A0DEE2AA3",
    "60TheNeurosurgeon3.pdf": "8ABD7BEB-DD83-4CD6-A580-D86A765BEE47",
    "61 Annual Meeting Registrant history report from 1951-1961.PDF": "BD73B3A4-14FA-4190-90D6-85F61021C0EA",
    "61 Directory of Neurological Surgeons.PDF": "3D462FBC-6351-46FD-ADA8-432557FB4644",
    "61 First New Luxury Hotel Announcement - Summit.PDF": "B438BB09-F3EE-417E-AC2E-69E3A9B97C5D",
    "61 Letter in interest in the Summit..PDF": "1D987977-39C4-4D01-89FB-7ED326AA6CAB",
    "61 Mark Hellinger Theater Tickets.PDF": "6BB1A98F-6E6C-4CBF-91A5-6976DAAA575F",
    "613 Does Positioning of Cervical Disc Arthroplasty Implant Affect Postoperative Outcome-613.pdf": "E995A2B4-163B-45FB-AA76-A6B2FF460EA6",
    "618 The Impact of NSAID Use After Lumbar Fusion Surgery on Fusion Rate and Complications  A Meta-Analysis-618.pdf": "A023D024-61EF-4D9C-8C44-6085AB100B7F",
    "6197.pdf": "5560A43E-5E7F-42FE-9DD1-939890B5A68D",
    "62 Neuro Services Tracking Shit.PDF": "BB5B5F52-9FA3-4EC5-A538-E336821D5FD8",
    "6200.pdf": "7CAFAB47-735B-4B6E-B8F0-2BEEB4BD3F61",
    "6204.pdf": "395C0C86-5AF2-4C39-B9ED-0B6F27654F56",
    "6208.pdf": "EC68027F-5267-42A1-B846-D0459F351F7E",
    "6214.pdf": "BCA88544-2027-4B0E-AB12-BDBCF4B6A758",
    "6217.pdf": "F4A689AE-B95F-47EA-8611-CA3C7BB1EF63",
    "6225.pdf": "C4EA0098-3E6A-4DAB-9FF3-E873532A1CB0",
    "6227.pdf": "E4A07177-5A0D-4BE5-A04C-1FF1E1C70CBD",
    "62344240184_Transkript-2.pdf": "E75C6017-19E0-458C-AC0E-A0576272B982",
    "6244.pdf": "74AC2792-70CE-468A-98DC-23C64E5B6996",
    "626_Accreditation_Requirements_Document_20120924.pdf": "4F2838E2-93AB-440F-90BF-CBAD92A99BAB",
    "629-Prather.pdf": "E978E25D-7319-4C40-9012-1585E39D3E04",
    "62Program.pdf": "CA28BA7F-FDD3-490E-AB83-01D9C9EF59AC",
    "62TheNeurosurgeon1.pdf": "CBFF703D-0640-4751-9474-37A8DD00B451",
    "62TheNeurosurgeon2.pdf": "DFE0639A-A52C-4CCE-AB58-9B4541E08A61",
    "63 Directory of Neurological Surgeons.PDF": "9CCC0E05-1ECD-4B4F-B50C-1B901CF4F021",
    "63 Executive Committee Meeting Minutes.PDF": "41A0517E-2F2F-42F9-B9AF-8418FC8E8EDC",
    "63 Executive Committee Meeting Notes.PDF": "17E9E159-7E69-4BCF-94CE-08BCBCF58E94",
    "63 Future Meeting Dates.PDF": "183BF8D5-039F-4C8A-B72B-EAE368D98BD6",
    "63 Letter to A. Roy Tyrer Jr. on International Recruitment.PDF": "1219A4BC-2905-4464-84DC-1C2008116A29",
    "63 Membership Growth.PDF": "66887438-9B36-48FE-9D97-A73A13175EB3",
    "63 sci pro.PDF": "CF4FB16E-8FAA-4F02-B4DD-DB4371C59684",
    "63Program.pdf": "0756CE62-1F6A-41DA-87C6-6050541E7469",
    "63TheNeurosurgeon.pdf": "F2293C0C-F8F1-4D46-A21D-CB8B99279C78",
    "64 Boat Trip Ticket.PDF": "F9972A2C-079E-4E0C-9F53-18651F2EA915",
    "64 CNS Comittee Rosters.PDF": "C20792EC-0671-4DA5-89BF-65FE6DFA9D8E",
    "64 Directory of Neurological Surgeons.PDF": "D8B9AC85-1EF4-4006-9643-2B71773AA33F",
    "64 Newsletter Volume 13 No 4.PDF": "F67D35CA-2D3C-463B-A578-D2EDD08E146E",
    "643 CNS TKI.pdf": "9E15EBAA-379B-4161-8740-4119909853C4",
    "645 SRS vs SRT.pdf": "E6B647F3-E663-437A-86A4-3B5CA0CECF28",
    "64Program.pdf": "B0FEA994-DB16-4F38-ACE8-8BD6B965F6F1",
    "65 chicago guide.PDF": "C973D0FB-F4CB-4C52-959B-7502681CC787",
    "65 sci pro.PDF": "1D3A46F1-985C-4E06-A354-CE5A922888BC",
    "65-300c297bea668720b723ee4a23f39f4f_Vikas_Munjal.pdf": "19F1E9A9-E720-436C-BBD8-C95E7DA26BC7",
    "650-1273_PHOTOGRAPH-1.pdf": "E149DEA3-20C5-483E-BA7D-BE615CB9A92E",
    "651 CNS Pineoblastoma.pdf": "3EFD68CC-6A55-4E99-93F1-BB6F9015A6CE",
    "65Program.pdf": "8FEEFF48-2DB6-4EC7-B95A-E82A3E8918EA",
    "65TheNeurosurgeon.pdf": "E00D2C0C-CCE5-4330-8CBB-4183F13CF189",
    "66 Auxiliary Activites Program.PDF": "69FEA7E7-C73B-4BD3-BA42-223F20CBDA73",
    "66 Directory of Neurosurgeons.PDF": "C63358C0-ED12-44AF-9A3B-F23FCEF5ACC1",
    "66 History and Progression Letter.PDF": "36CFAC70-FC16-4A11-BCF5-BC1343B980F3",
    "66 Neurosurgeons Identification Letter.PDF": "817985ED-1076-4A9D-9085-D67B236B23F3",
    "66 San Juan Accomodations Reserveation Card.PDF": "D7F2BB11-5953-4E9B-9978-DC8C81AA4E27",
    "66 Scientific Program.PDF": "D1F5BD22-5DAB-427A-98FA-737B10A8D471",
    "66Program.pdf": "C6162E3F-F3EA-4060-ADFF-17A894175383",
    "66TheNeurosurgeon.pdf": "745874F3-F7D0-4FDE-9733-8340F04F9735",
    "67 am sci pro.PDF": "199F1A88-913F-4EED-BEB9-E4BD6C7405F1",
    "67 Directory of Neurosurgeons.PDF": "EF8252AB-3DEA-457F-A941-E6416A0C0A8C",
    "67 Dr Kubala Acceptance Letter.pdf": "56044B3A-20BC-4544-AFA0-894CC6024E46",
    "67 Dr Kubala Application Letter.pdf": "328B32A1-EFEB-4632-B99A-4B46C4A6E1F3",
    "67 membership stats.PDF": "9B9EA3F5-4E42-4DF5-BD4D-C0F8FC9D9A6B",
    "67 Newsletter Volume 17 No 1.pdf": "8048BA7E-92C0-47E3-86D6-84157BF6662A",
    "67 San Frincisco Guide.PDF": "56D4C88D-E1F6-48D3-A9D5-1E1E492FE195",
    "67PresidentialAddress.pdf": "1FB471F5-4B08-409B-A9EE-A21CD32BDE15",
    "67Program.pdf": "97A84E6F-2B65-47A5-B70B-891B33419725",
    "67TheNeurosurgeon.pdf": "5F339A36-B40F-40C1-AD34-F8B1BC5E6818",
    "68 am sci progr.PDF": "B849CDB9-5F6B-4A2C-B697-E00250EB9BD8",
    "68 banquet program and menu.PDF": "073A4BAB-069A-460C-B58A-0A0F49D8F931",
    "68 by laws.PDF": "96E5BD0A-761C-496B-ACE2-0EB43626E4EF",
    "68 directory of Neurosrugeons.PDF": "BE6119D5-43EB-490F-AE3B-DD61A7AFD380",
    "68 nl vol 17 no 2.pdf": "58DE1AFF-05CF-423F-87D0-14F531E43A78",
    "68 Stratford Festival Tour.PDF": "2065DCA3-C686-433E-BC5C-32A5ADB099EE",
    "68547_1_table_1252322_pwlbn6 (1).pdf": "B130C98E-9A99-4FBC-A4A6-7FDA31CC262D",
    "68PresidentialAddress.pdf": "3D861502-1397-44E4-891B-42B27E0A86B6",
    "68Program.pdf": "98692C77-D982-4CC1-A292-9C98128D8EAB",
    "68TheNeurosurgeon.pdf": "3D3FED0A-46A6-45D3-A174-05C4A6B8470E",
    "69 19th annual meeting minutes exec commit.PDF": "EE6395F5-8B0B-4607-A2A7-3BD2E3F59F9F",
    "69 am event schedule.pdf": "5EAF4D27-4C00-41BE-983B-E29E5895E8E1",
    "69 AM response letter.pdf": "5BA32B3F-6C6F-44B8-98D8-2378D15C692E",
    "69 aux note.pdf": "72E3F62D-6667-49C2-978B-C47046CB1A4C",
    "69 Bulletin World Federation Neurosurgical Socieities.PDF": "EA8BEC0B-68AF-46A0-B23C-751FC29F96D5",
    "69 harvey cushing tribute.PDF": "0D7C82A7-419C-470E-9112-2326E03E13A5",
    "69 hospital utilization manual.PDF": "DE4D0945-FA18-4255-8BEF-41BD7369CBA7",
    "69 nl vol 18 annual meeting boston.pdf": "E11E0F03-4097-4C54-AAAE-58184238F659",
    "69 nl vol 18 no 3.pdf": "E5582A2A-AD94-4F71-B68E-3F84D268E588",
    "69 nl vol 18 no 4.pdf": "C045A061-381E-40C5-AC38-27E50EF6A9A7",
    "69 notice of society nominations.PDF": "0EE0BE21-550B-411A-8571-277419C59466",
    "69 Report of the CNS Representatives to the AANS.PDF": "1A8A49AA-7DEA-4223-8526-0E55A60BF705",
    "69 sci pro.PDF": "302E54A9-A69C-466A-8727-4B301D50E3C8",
    "69 sheraton invoice.PDF": "5A4B3F8E-49A5-4666-8628-822870B6AD0A",
    "69Program.pdf": "6EF48EB8-089F-4F7D-8718-C0DF9EBA7256",
    "69TheNeurosurgeon.pdf": "AAB5983D-2847-4536-A53D-0C07FEDC929A",
    "7 Neurology_4-21-2022F.pdf": "E867FCF3-67D8-47B6-9EA8-922670655204",
    "7-Polifka-Cherian-Vascular.pdf": "1D7E9F45-1C5D-4B4B-B890-F63BB30925AE",
    "7.2021 CV PDF.pdf": "DC0346F8-CE6D-4E89-9746-48D43E389982",
    "7.26 Water.pdf": "61379428-2943-4762-99C9-E5A2E6D0FBCA",
    "7.28 Water.pdf": "FAA27C63-F013-4B2C-8420-3CB6A5B5919E",
    "7.A CPT Committee Report.pdf": "3ADDD7D4-6C28-449C-8683-83A8405E8FA7",
    "7.E.i  Comments & responses.pdf": "7C3E2CF0-FCA4-4717-8346-3B77BFC4ED55",
    "7.G NPA Update 2017.pdf": "3F2DAEBB-034D-4A0B-9A5F-E126C00F50FE",
    "7.H NREF HYM Status Report - Spine Section 9.30.16.pdf": "7CE41AB8-B1C9-4DC5-B7AD-5801912F863E",
    "7.L Publications Comm. Report.pdf": "609EA307-BB5E-48BE-916C-EF7F4563C793",
    "7.N April 2017 Washington Update.pdf": "7ACC88DB-A683-4EA8-9938-6722FA006BDB",
    "7.N Washington Committee April 2017.pdf": "DD81B89D-1653-4F9E-A4E0-14F0A1047EA9",
    "7.N. WC February 2017.pdf": "77AD6211-5247-4A17-B6A7-4792C7D11914",
    "7.pdf": "C7218E8D-C32A-4AD3-ABBB-4D94B4EB9834",
    "7_17_09Release.pdf": "A7CCB94D-9EEC-4B7A-94A1-45259AF42EA9",
    "7_HDEZBALDERASUNAM.pdf": "842AE112-C755-43E5-B572-C2359562A565",
    "70 AM program book.PDF": "76D81DFA-F20B-49CA-BE67-A61A7BEF5DE7",
    "70 nl vol 19.pdf": "4A6F87C1-9189-4937-91AB-2B4A29D4039D",
    "70 Notice of Society Nominations.pdf": "9AE61E8A-1180-4944-9B8A-554BC347046B",
    "70 wrld CN program.PDF": "AF72D0D3-DCD5-45A3-BC80-C13B8AB5DCDD",
    "701_Rajpal CNS 2019.pdf": "240A32A6-279C-4AEF-AE27-CB6995140282",
    "704_poster_Oya.pdf": "472BBD9E-3521-4CB9-B98A-7220FB18C930",
    "706-20_Morgan_7272_1.6.21.pdf": "13078E0D-6C22-4CC3-AD23-081C1D6DBBD4",
    "707 digital poster opioid CNS Mezzalira.pdf": "818E4B60-4F9E-42FA-BE71-6B2AA26A35B5",
    "70PresidentialAddress.pdf": "BB469479-335F-4445-80DA-A7E57B1F6CA9",
    "70Program.pdf": "D3234888-08D1-42B5-9200-1FD401CCABF5",
    "70TheNeurosurgeon.pdf": "620FE6FC-F664-464C-AD88-9DE633013CDB",
    "71 4th international meeting prague.PDF": "1FF5B457-2C5E-448E-A632-59F4476F298F",
    "71 nl vol 20 miami meeting.pdf": "397A721A-04C7-4D2A-9A14-9650BACED469",
    "71 nl vol 20 no 1.pdf": "308A2EE3-2EFA-4D1F-AE47-897A2FE002D9",
    "71 nl vol 20 pres corner.pdf": "7D7EE4A8-CE90-4411-B6A5-A64809D910B5",
    "71 sci pro 4th inter meeting prague.PDF": "6F351F06-0F57-4DF2-89B8-682B54B0C7E1",
    "71 selective guide miami.PDF": "725189F8-B8EE-4237-AE0B-0550EB577CC3",
    "71 sights tour prague.PDF": "FD8C6A91-74F4-47C1-A39E-23178DF637CD",
    "71Program.pdf": "3FE1306D-ACF6-4AF6-A2FA-4DB1CFE1EECB",
    "71TheNeurosurgeon.pdf": "89AA87FC-BC72-4118-ADC8-69E6BF65D81F",
    "72 A Tribute to Elizabeth C. Crosby.PDF": "3F73C247-2E5F-4CFB-9F2B-00967A9E19A2",
    "72 Foundation for International Education in Neurological Surgery Award.PDF": "1EBD48D6-C2F1-43AA-8E9C-44738DB0D922",
    "72 nl vol 22.PDF": "341888D9-8C97-4389-A015-A778FA964345",
    "7226183_UnOfficial_Enrollment_Verification (1).pdf": "2601B896-D5BD-46EB-B1A5-34A3BC7E3DE6",
    "72Program.pdf": "BE64D2D7-71E0-4E5F-BF14-C3242BE3CD3E",
    "73 23rd Annual Meeting Travel Program to the 5th International Congress of Neurological Surgery..PDF": "D194FDB8-F1DA-47F0-8B02-D3A7D8085F7A",
    "73 dinner dance program.PDF": "5FFBEB46-FEF7-4966-B76B-14CBD23A00B5",
    "73 flight inquiry japan.PDF": "CADC7D87-4487-4324-9B49-C093C71549C3",
    "73 nl vol 22.PDF": "9099E05D-0474-4995-88DB-C969066CFC9D",
    "73 nl vol 23 honored guest.PDF": "A92358C6-5997-4EF6-8773-BB90D073E099",
    "73 nl vol 23.pdf": "838375DA-8D84-4D69-AC62-58B1F9F461C2",
    "73PresidentialAddress.pdf": "85C1BAA8-2795-4F77-90D4-AF421B258F53",
    "73Program.pdf": "51F78A54-AA7B-4C08-91F3-915552125079",
    "74 AM selective guide Vancouver.PDF": "D237D1CD-2C85-4546-AC04-6C821EF9E583",
    "74 aux pro.PDF": "5F2DD8D8-5BAB-4D49-9321-3EC355359EC1",
    "74 faculty club menu.PDF": "3EF21EB5-2F70-4256-8E5E-156E12C4F1D0",
    "74 nl vol 23 honored guest.pdf": "8620FFC3-892E-42C9-BFBB-A760F09B6696",
    "74 nl vol 23.pdf": "B045A598-0D74-4DD9-BC50-45E40832157A",
    "74 nl vol 24.pdf": "144B11D3-2CAB-41E0-B2ED-6E99C9A9FB5F",
    "74 reception and dinner dance program.PDF": "7424E355-0674-4060-93AF-54F3BF3F1EAC",
    "74 sci pro.PDF": "D14A9B87-9703-4A09-BD43-61B0F835EEF4",
    "74PresidentialAddress.pdf": "DAA5F6E1-32E8-4CBE-9FF1-DAD2B9694A0B",
    "74Program.pdf": "596E6C2E-7B6D-470A-838F-41D67586FE2E",
    "75Program.pdf": "7FF32193-10ED-4A97-A50D-C7519B176916",
    "76 antoines postcard.PDF": "B51A32D7-D140-46D5-ADE0-BC4862A8D2DD",
    "76 neurosurgical manpower monitoring comittee report.PDF": "D63E75FD-C23A-44DA-9D89-7488ADA2520B",
    "76 nl vol 25 honored guest.PDF": "3288DC90-9357-4A49-9111-AE721E73B805",
    "76 nl vol 25.pdf": "38D2464C-870C-4DFA-9678-BAA7481174CE",
    "76 nl vol 26.pdf": "3FA3EDA7-3444-4EEF-9CD8-6F54A53AB9CA",
    "76 NO menu and program.PDF": "2853C4C1-E50A-4FC3-ACA3-8695D58B5025",
    "76 past pres dinner menu.PDF": "6621CD32-4B36-482E-BFCA-81689A976EF6",
    "76Program.pdf": "61D2F509-6101-4378-9A9F-61C0EB90150F",
    "77 aux pro.PDF": "46EFC1F6-55FE-4400-9FD4-2384633AA221",
    "77 aux selective guide san fran.PDF": "92AFCC1D-508B-46FD-A492-2F2955FDAE2B",
    "77 call for sci resources.pdf": "C6A70ECD-51B1-4634-9E01-4BD4CAE6E52E",
    "77 neuro order form.PDF": "9E1F5FC3-384B-424F-95E8-73EA0632D00B",
    "77 neuro vol 1.PDF": "F2214459-3442-4F60-9CE3-B62B928AA077",
    "77 neurosurgery vol 1.PDF": "44164B3A-3946-4EB4-BFD4-0A5F37E0C752",
    "77 sci pro cover.PDF": "30C86385-6557-48B7-A71D-F6EBEFCA5FD7",
    "77 sci program.PDF": "85D7882E-F92F-47E1-83E2-2BF2605B06D2",
    "77Program.pdf": "ADC73002-FB0B-4ABD-B1F8-BC1B0B3A1F93",
    "77TheNeurosurgeon.pdf": "A90864D3-75A9-4159-A876-CD8F10022BC3",
    "78 aux letter.PDF": "E5666EDD-1470-4CE2-968C-7E9206123067",
    "78 bylaws.PDF": "0FB798DA-9FA6-4D3C-9EA5-1EC945920444",
    "78 DC tour program.PDF": "684D19AB-3DCA-4EE8-8288-92C0A4E72A24",
    "78 First Annual Meeting of the President of State Neurosurgical Societies.PDF": "C5663153-E808-4298-BBF6-270FFD2148DD",
    "78 nl vol 29 pres letter.pdf": "2C17A598-6C18-48CA-AD41-8DE144705FE7",
    "78 sci pro cover.PDF": "693F0F09-C86D-4E64-84ED-2F96AE5B6BCC",
    "78 sci pro.PDF": "37084D67-E8C9-4A05-A41B-F67985F1ADA5",
    "78Program.pdf": "B33BB01E-AED9-44DA-BE87-223DBE799BA9",
    "78TheNeurosurgeon.pdf": "68ACD950-AF55-4788-8A85-9A8070937B48",
    "79 aux program vegas.PDF": "D72CCBF2-0C76-4CEB-8095-316CA6F19A3B",
    "79 nl vol 29.pdf": "FBECA7F3-D376-4258-AE28-5C6E4E3AEB32",
    "79 nl vol 30 pres letter.pdf": "0B8F8E84-FE2D-4AF7-903A-3B40B78032BA",
    "79 sci pro.PDF": "50969417-6788-4FAD-9F67-2CD31E291349",
    "792.pdf": "390B6751-E245-4281-8178-4B5F012B52FB",
    "79Program.pdf": "087FC8E1-03B1-480A-BBFC-6ED97D74DC9C",
    "79TheNeurosurgeon.pdf": "C5789C69-23C0-46D1-B4FB-F92444A805B5",
    "8 Neuropathology_4-21-2022F.pdf": "FB8955B8-05BF-4F68-920F-2339D43E94AB",
    "8 x 10 in.pdf": "DD12BE84-15C3-4897-90E1-26DF6D534AA0",
    "8-Spinner-Peripheral Nerve_Optimizer.pdf": "679189FE-D5F4-49CD-802B-E6C0747AC181",
    "8.A. AANS Liaison report.pdf": "A49F9602-FC11-44D9-94A2-0D5B74F7ECE6",
    "8.pdf": "FB928C44-82FA-4E04-9509-30505D1FD928",
    "80 aux news vol 4 No 1.PDF": "BE08C556-B1F9-4C5F-AC45-46D88854FDCB",
    "80 aux pro.PDF": "2DF51330-71AD-4A64-98F1-788568427186",
    "80 nl vol 30.PDF": "A57825F5-0699-42BF-88D3-DC923C5435FC",
    "800-Prather.pdf": "FCB6636B-5F90-4EE7-A065-A24C0224E9F8",
    "800703 Dunsker to Shea.pdf": "6A35FC30-CC8E-4A8E-B23E-C152F47DE290",
    "800703 Dunsker.pdf": "E912CDBE-B1EA-4262-B4AE-1433ED617149",
    "801107 Dunsker to Kelly.pdf": "A387367C-6C18-4352-BAF4-710C5AD481D9",
    "805.pdf": "F00406A2-CCD9-44E5-A58E-30C9CAAF819A",
    "80Program.pdf": "6787450F-FD80-47F8-B528-6AB9E4B54D3B",
    "80TheNeurosurgeon.pdf": "519F04BD-77E3-4D5C-9EFA-D56A26DF2A42",
    "81 art oct 21 stroke prevention.PDF": "094D077D-B504-4E99-899A-FE5D01295B6C",
    "81 aux program.PDF": "562D0EB3-20D2-4BD4-9AE4-20F4FC779302",
    "81 nl vol 32 pres letter.PDF": "BBEAC282-BC97-4B06-BB9A-3074372D2518",
    "81 nl vol 32.PDF": "3472F119-DA01-4C90-80D6-41775D34828D",
    "81 sci pro.PDF": "7F4F13CA-23BE-4AB6-8311-234B6AEBDD8B",
    "810 Captopril As An Adjuvant To Temozolomide Prolongs Survival.pdf": "01F41948-C7FE-4F12-AF3D-739F607F86AA",
    "810427 Clark to Robertson.pdf": "18A38A04-C2DD-4FF5-9BFF-A8D7D2CD390D",
    "810601 Clark to Larson.pdf": "0571EEAA-757C-4ABA-8656-D8E053CDE81B",
    "812 - The Luciferase.pdf": "68FF87E2-EAF2-4FD0-B6D6-DE1A0688FFC0",
    "817 AC_CNS-Poster.pdf": "CE779A55-7193-427D-A90E-5551E184C58F",
    "81Program.pdf": "E46CF7BD-0690-4DF2-AB1D-1C798D6142B0",
    "81TheNeurosurgeon1.pdf": "F4D1E7DE-1345-43E0-9D98-41E45AF666A2",
    "81TheNeurosurgeon2.pdf": "601DEBB5-9C28-47D9-8183-23C31A514529",
    "81TheNeurosurgeon3.pdf": "394434C3-7DA5-430B-BF78-F01E2CF3CBBE",
    "82 AM invitation.PDF": "C94DD426-F829-4C8E-A071-E1228DA12991",
    "82 AM newsletter.PDF": "864C4F6B-4F31-454B-B910-1DBB95F52F5E",
    "82 AM registration form.PDF": "36F82A57-B54B-4460-9CA5-6410A5D63E7C",
    "82 article oct 5 brain research.PDF": "63D77797-BC68-4085-9C1D-50D6B279E461",
    "82 article oct 6 brain imaging.PDF": "478D022C-4D5A-4582-8FC3-24CEDD795236",
    "82 article oct 7 brain glue.PDF": "32AEC724-0ABF-401F-AFBB-B9F5CB95524B",
    "82 Article oct 7 civil suits.PDF": "D72AB395-D529-4559-8174-AB8389C9DB1F",
    "82 aux letter.PDF": "C993C08C-139E-4BB3-B3B8-FF7677B4FA57",
    "82 by laws.PDF": "B9CE11BC-9924-4D95-B356-1543FE533209",
    "82 cns aux news vol 6 no 1.PDF": "4C884930-C59A-4DEE-9982-06B117445F61",
    "82 Past pres dinner invit.PDF": "3D847500-2DF2-41AA-96CF-A9FBDB693D85",
    "82 pro ata  glance.PDF": "C5FB1ECB-BE59-4D61-8F44-4A6D7F6AA267",
    "82 sci pro.PDF": "DB3CD92E-AF9E-40C5-B061-BAB40049A6AA",
    "8282023 enrollment confirmation-Jack Mordeson.pdf": "AF52DB5B-5880-4056-9E4F-BE986FEFCDE9",
    "82Program.pdf": "560B6341-CD25-4223-AAF7-05FE2CB98C7A",
    "82TheNeurosurgeon1.pdf": "6975FBBE-8894-47B4-A162-1DE88F3D04FF",
    "82TheNeurosurgeon2.pdf": "A49905C0-93F6-4185-AB7E-EAAC70BE2A05",
    "830503 Mahaley to Hardy.pdf": "B4B00E5F-FD72-4EE5-BEBB-0E88F5904763",
    "830609 Larson to Wrenn.pdf": "A6AE411C-14E2-48FA-B3A7-805753B9CAE0",
    "830622 Tator to Pevehouse.pdf": "73497ADC-7018-42CA-AA3F-F585BC6FC008",
    "830630 Seljeskog to Hardy.pdf": "CC0D2FB4-BA59-4346-A1D6-165055383803",
    "830803 Dunsker.pdf": "BA5FA2B8-1EB1-4F38-AD8B-A7C3A3CC5F87",
    "830906 Hardy to Mahaley.pdf": "8ADE9907-50E0-4A5A-BB19-6514862C10E3",
    "83Program.pdf": "06718EE9-343B-458F-9B88-0F11F9AFE238",
    "83TheNeurosurgeon1.pdf": "436C5EC2-BF9F-4A01-B34B-0D5347107C47",
    "83TheNeurosurgeon2.pdf": "EDDB095E-20CB-4FC3-AF48-561514303C85",
    "84 nl vol 36.PDF": "91A49A09-4D68-48A5-993D-74C2331D19C7",
    "84 Past Presidents Dinner Invitation.PDF": "34F09333-10E2-4AB8-B7D6-817F69569046",
    "84 Presidential welcome.PDF": "E1D0FAF4-8063-4075-9EBC-C133E741929A",
    "840206 Hardy to Mahaley.pdf": "45603E41-5528-4422-B6E7-0E70D3C43B9A",
    "840217 Berger to Boyer.pdf": "9A78CF28-2511-4BBF-82C6-83B3371C23A2",
    "840227 Hardy to Seljeskog.pdf": "98D0278E-08C8-4615-B24D-26FCE400923A",
    "840305 Boyer to Sypert.pdf": "E5A6EF03-2934-48EC-8820-CF6D5211C74E",
    "840305 Grenelefe Resort to Green.pdf": "62199C2B-17AC-4851-AC39-541BC81545AC",
    "840508 Grenelefe Resort Contract.pdf": "CBF17D22-B20E-4B15-8CB0-C921AD42A1C9",
    "840516 Grenelefe Resort to Campbell.pdf": "BF1CC14C-C449-446B-A045-88B20E6BE5C5",
    "840523 Sypert to Hardy.pdf": "C21CAACE-E2A0-475F-9E67-7B07C2AFDD8B",
    "840529 Hauber to Boyer.pdf": "5D732F90-9045-4B89-ACE1-BC054FABF988",
    "840529 Hauber to Campbell and Podboy.pdf": "C6569FBA-CFD2-4C7E-B5C6-47989AABA7AB",
    "840619 Engelhardt to Berger.pdf": "18A0AE90-5D0D-4917-9F4E-7120A17ADB50",
    "840625 Podboy to Grenelefe Resort.pdf": "719E5712-0C72-40D4-AEB4-CEF054EE76DB",
    "840627 Sypert to Giannotta.pdf": "865D2A50-0D9B-4D1D-8DD2-6FFD9C3C5973",
    "8407 Newsletter - Vol 1 No 1.pdf": "ED35BFBE-EA56-44B2-87F6-879F6E23E3C2",
    "840703 Hauber to Laws.pdf": "7CFA14AB-FFB4-4341-AD8C-BDA6ED326CEC",
    "840705 Dawson to Hauber.pdf": "51C85F3B-B9E7-42F3-A931-B8D38743FE76",
    "840705 Podboy to Green.pdf": "83E7B2F4-AF75-44C8-BC96-E87BF17D7E8E",
    "840710 Hauber to Dawson.pdf": "4D128875-23DE-499E-A10F-B01B9A058A37",
    "840712 12th CSRS Meeting call for abstracts.pdf": "E77082D6-9D80-48C6-BDD2-57DFA9841488",
    "840717 Engelhardt to Connolly.pdf": "50A3464D-56A9-48E9-83A4-BB17F69B901C",
    "840724 Ratcheson to Laws.pdf": "3D2C9D6E-8C92-45A1-BB87-7CBD4628577D",
    "840731 Boyer to Hardy.pdf": "93F2DB46-0B39-49ED-BCE8-FEA1C40D8A5D",
    "840801 1st Annual Meeting Announcement.pdf": "C3611DB3-BA3D-4E33-A9DF-79A55765E4CF",
    "840801 Boyer to Bucy.pdf": "A380DA8E-0C7A-4BBD-BA1B-73D2E5DE3D32",
    "840801 Engelhardt to Kranzler.pdf": "F5A25E26-B5E6-4315-AB04-21B77F402BC1",
    "840819 Boyer to Featherstone.pdf": "08CE3D28-FE76-45E8-9032-4460F54A8366",
    "840910 Dawson to Featherstone.pdf": "0765EDCF-ACBB-4773-A9C3-A2771CC6AFD4",
    "841008 Hardy to Goldring.pdf": "E16E9EC3-D4D8-46EE-AE41-67E2D7361B1E",
    "841009 Hardy to Berger.pdf": "8446A2F2-2A2A-423E-92B8-6A0DC3D7453F",
    "841017 Hauber to Hardy.pdf": "53DEE546-75F5-4B8F-AB38-A3FA3A75D39D",
    "841114 Korogi to Dawson.pdf": "3404E3B8-CB80-4746-BC8F-A5452A496547",
    "84PresidentialAddress.pdf": "AF7FFFE7-580E-4572-B764-8CB73FF8477E",
    "84Program.pdf": "6F48A394-E670-4A66-9250-FA76DC57CB82",
    "84TheNeurosurgeon1.pdf": "7466890C-1A6A-41BB-A97F-F916D48D1CC8",
    "84TheNeurosurgeon2.pdf": "7844799F-0374-454B-A2EE-43680C09BA28",
    "85 AM invite.PDF": "E275F31C-9EFC-4E8D-9B09-D4AD3E9CAD2B",
    "85 First Meeting Announcement plus Abstract.pdf": "65D24F83-65C0-4FBE-BE82-55418C6C8A92",
    "85 program.PDF": "6F55B30A-A103-44ED-B0BC-CDE6003C724A",
    "8502 Spine Meeting - Planning Calendar.pdf": "08BF137A-CEBF-4ACC-8A78-C646E928BB0A",
    "8504 Meeting Announcement.pdf": "8F1B4C72-7C28-44CD-9D55-E06654EFCB46",
    "8506 Bylaws and Ballot.pdf": "2831C637-AE1A-45B5-A615-96C48A55BD01",
    "850621 Boyer to Long.pdf": "96A8AD0B-E4CF-4924-B62B-B77BA1FE28E4",
    "8508 Newsletter.pdf": "FE2C36EE-EE23-415E-B2A7-0AAFE4602CEB",
    "850913 Joint Officers to Sypert.pdf": "1E528F57-8AD1-445D-9809-DE5088F17F82",
    "850924 Sypert to Joint Officers.pdf": "E611168A-CF3A-48D5-807C-2950487FF7A7",
    "851022 Harcy to Slade.pdf": "E786F8B9-A92C-4199-94E8-AE03DD79BEE4",
    "851108 Garretson to Robertson.pdf": "F77F6213-564F-4637-AC5F-A2A442FEF7C8",
    "851218 Hauber to Patterson.pdf": "8D58290D-81AD-4C29-97F7-2A9BE56DA6EC",
    "851219 Sypert to Garretson.pdf": "6CE76DF2-A305-454A-A7CE-68064DF777FE",
    "85Program.pdf": "ECF83B88-4A72-4134-ACE2-8AE5B811335F",
    "85TheNeurosurgeon1.pdf": "70F89409-49EE-49D7-B45D-07F9CA2C2D76",
    "85TheNeurosurgeon2.pdf": "FF95DB0F-8695-4C51-B1FF-4CF671E636F5",
    "86 aux letter.PDF": "E843C1A5-3AB4-4513-972E-4D33F093FAE3",
    "86 Essentials of Accredited Residencies.pdf": "E853E0C2-765A-4D6D-8873-A933801886C8",
    "86 member stats.PDF": "8C24C19E-FFF0-4D92-80B9-8187B906E54A",
    "86 nl vol 41.PDF": "D82FC4C1-9D9C-4135-87DA-44EEBC2677B7",
    "86 nl vol 42.PDF": "3DB17F4D-D3AD-4A8A-A94D-A786C38B3F69",
    "86 sci pro.PDF": "35252F00-1182-4018-9259-E20F3315AAC7",
    "86 US and Canadian Training Programs.pdf": "60FB1391-7407-493A-A030-4AD9AEE87AAC",
    "860114 Maroon to Sypert.pdf": "F4AE0E93-DA72-40D9-B31E-903BB2446CC0",
    "860116 Garretson to Sypert.pdf": "786CF19A-0449-4D98-BB9E-390AFB7E2740",
    "860121 Garretson to Sypert.pdf": "1C62ADE6-1C85-49F0-8C77-B33E6A0140D0",
    "8602 Pevehouse.pdf": "B725FE13-59E8-499E-BBE6-F398FC1E93F0",
    "860215 Podboy to Connolly.pdf": "6FCA2130-EEF6-4424-BEA9-AD0E49595164",
    "860416 Minutes.pdf": "94CB3DE0-2209-4E20-B05E-27A691D4A459",
    "8606 Newsletter.pdf": "2AEE090E-E317-4E42-B8C3-766C6BD8C54F",
    "860602 Kelly to Sypert.pdf": "7014C3A3-9174-46B7-93BF-83DB992E6F8F",
    "860609 Sypert to Podboy.pdf": "11E7A754-DA3A-4974-B0B6-5FC0FEDDCF0F",
    "860804 Waldman to Harrington.pdf": "3AF3ABBE-C3CF-4544-BA52-14CE90F6CCB2",
    "860819 Pitts to Sypert.pdf": "68345731-928E-457E-9737-5704A3B328EA",
    "861117 Dunsker to Pevehouse.pdf": "B13A0350-49C9-4713-AE05-9AFE0E6A19AF",
    "861120 Zeiss to Hauber.pdf": "B1FB68AA-550C-4CB7-9995-607888D1476A",
    "861211 Hauber to Zeiss.pdf": "85AA92AA-995B-4482-9767-842CC665558E",
    "861218 AAOS Meeting Notice.pdf": "F65A34C9-C40C-48E1-B9D2-793CB05ACCBC",
    "864758_Manuscript.PDF": "75E274CC-04D1-4CFB-ADF6-97C38EBEA700",
    "86Program.pdf": "F9DD1DE8-906A-46BB-A4F8-B3C0A12CCE2B",
    "86TheNeurosurgeon.pdf": "7797A620-F165-41A7-BE78-4BCACF56E4C6",
    "87 aux progr.PDF": "11BAB67E-164B-44D7-B1F6-87EE0C79080F",
    "87 cns aux news vol 11 No 1.PDF": "B77BB33C-4609-4CCF-BAC2-43201BB55400",
    "87 Past President's Dinner Invitation.PDF": "F1504AFD-3DEA-447D-A81A-103358822B62",
    "87 Presidential Reception Dinner Invitation.PDF": "F921FE15-B9D2-4ACF-A3C5-3D17C6E9AFE9",
    "87 sci prgorg.PDF": "E8304690-A38B-4A25-8C06-64B810C2E3CD",
    "8701 Bill for Spine Meeting.pdf": "6A14D9A9-5551-4600-932B-DCF046CC4607",
    "870105 Hauber to Kap Graphics.pdf": "0403AA29-A7A9-4862-A2C4-3D851693FC53",
    "870115 Kelly to Garretson and Mahaley.pdf": "6B162782-BC6E-472D-8423-6EE368B75205",
    "870115 Kelly to Ojemann.pdf": "BADA31F5-AFA9-4E94-AAF4-F98AB258CB48",
    "870115 Tindall to Miller.pdf": "237DC0A6-ED45-4062-A5AA-9C0498AB8ABA",
    "870119 Hauber to Caravelle Travel.pdf": "422EE099-E5B0-45A4-BB55-A12511176699",
    "870122 Brezinski to Dunsker.pdf": "337A4E75-0A34-4EFD-845D-990245887210",
    "870124 Dunsker to Hauber 1.pdf": "01327FEF-BB84-464F-BB13-3D94462BB294",
    "870124 Dunsker to Hauber 2.pdf": "2C6A8788-1C85-4501-8855-5B9BBA4AB3A5",
    "870124 Dunsker to Hauber 3.pdf": "A68555EB-D50A-4AEF-A0F5-CA09CF0909B4",
    "870124 Dunsker to Hauber 4.pdf": "B0EBC186-C69B-4236-8CDD-E40BE5B784DE",
    "870126 Dunsker to Kelly.pdf": "FB812A9D-1A83-4FF5-96BD-896F22CFDC21",
    "870204 AANS Invoice.pdf": "F58ABF4C-52FC-4B68-874B-1281264E28AA",
    "870204 Payment.pdf": "2C11382D-4D3B-432F-B79A-259C35A3911C",
    "870206 Hauber to Tarlov.pdf": "3BC71E1E-B9FB-4673-A9FA-ADCF4FBEA640",
    "870209 Mahaley to Kelly.pdf": "E290E219-CFC7-4231-BB9A-02E3019A2938",
    "870213 AANS cover memo to Dunsker.pdf": "90D35E07-D195-44BF-9CEC-0654E8A694E0",
    "870213 Joint Officers to Leblanc.pdf": "0459B7C4-605C-4427-8882-450A2E015AB3",
    "870622 Venator to Hopkins.pdf": "B544E1C7-773A-4149-9941-723D885C326E",
    "870731 Garretson and Quest to Dunsker.pdf": "A1482913-24C6-4D64-95C6-7EBC5FE939F4",
    "870828 Garretson to Dunsker.pdf": "388EB7FB-4037-46E0-8D71-6C8159360112",
    "870921 Venitor to Tarlov.pdf": "711148DF-1065-44EE-B2B8-9B62B0B3BCAD",
    "871012 Dunsker to Correll.pdf": "3B2F08C8-9BFF-430D-A76E-176722CD20ED",
    "871012 Dunsker to Venator.pdf": "2A7C648F-8206-4D74-995E-120F1BC8728A",
    "871207 Kelly to Dunsker.pdf": "A43CF811-96EB-45BD-BCE2-023CF0C7F3E3",
    "8771_ ????_20230425_Certificate of Board Certified Neurosurgeon Member.pdf": "FA73DBCC-4E15-45E5-8AB4-72ECE8DAB62D",
    "87Program.pdf": "2BA479E0-F439-48F0-B3A6-715A570F7F4E",
    "87TheNeurosurgeon.pdf": "9BCC1DE3-1466-4CF8-9DA4-9DE74FEA4320",
    "88 NL vol 47.PDF": "973E7B41-A698-40CF-9E7D-A7AB1B9D34A9",
    "88 NL vol 48.PDF": "E3B60F46-17DD-4EA3-BB6E-DF960AE10476",
    "88 NL vol 50.PDF": "F7746590-98DA-413D-A493-7D5C0EB5675D",
    "88 program.PDF": "CBF30578-4BC9-4DCC-8AD2-5C22B642643A",
    "88 Report Spinal Surgery Procedures.pdf": "64874C2F-8DD7-42FA-B2E1-9934162134BB",
    "880111 Tindall to Connolly.pdf": "F95D5B4F-4DF4-4044-AA8A-160D7AB816CA",
    "880112 Mayer to Lippe.pdf": "F65ABF86-697A-45A2-8703-DAC0D530149F",
    "880205 Hauber to Sundaresan.pdf": "CBC186C1-6533-4C67-A288-9298F640EDCA",
    "880208 Hauber to Black.pdf": "9086F1FE-8F65-4755-B656-FA06C21C9CFF",
    "880211 Tindall to Garretson.pdf": "6E52CD26-7B51-4D4C-AF6B-C9C59333F5B9",
    "880301 Dunsker to Hauber.pdf": "43B20847-981A-4015-BB00-A8E9796B2F67",
    "880304 Hauber to Connolly.pdf": "B3AE961D-E726-4F60-A6C2-2FA61647D57B",
    "880304 Hauber to Dunsker.pdf": "08AA5E0E-879D-430F-9C40-30F60A32CA34",
    "880304 Hauber to Miller.pdf": "2D1B8680-9E79-4D7E-BB1D-964AF748ADFB",
    "880304 Hauber to Tarlov.pdf": "ACC72228-79E7-48D8-A43F-85E2A499CC8C",
    "880307 Lippe to Dunsker.pdf": "9AC7A4B9-D54D-41E1-AB68-0C1ED2F08615",
    "880325 Hauber to Dunsker.pdf": "66EEFF94-0D5B-4975-A838-DFE84A2A28D7",
    "880504 Dunsker to Tindall and Shields.pdf": "BEC6E3C6-A1F0-43C5-A61C-74C61BF57D4C",
    "880516 Tindall to Connolly.pdf": "45FA50FA-E549-4B8E-9A14-E0F252A4534C",
    "880516 Tindall to Dunsker.pdf": "46C0B833-BD38-4FF0-BBD7-5582BD75D430",
    "880530 Kelly to Many.pdf": "F59F5650-A137-4FF8-83F3-C971CAF1ED92",
    "880607 Hauber to Sundaresan.pdf": "AC4A9330-87FA-4D50-B334-1BF5C24C8B0A",
    "880609 Stone to Hauber.pdf": "1E14BD09-3FCE-4C01-8625-402B8C069AF8",
    "880630 unknown to Brockman - 1st page only.pdf": "D59B6428-21E7-42EB-98B6-66BB17E45720",
    "8807 Rules and Regs.pdf": "71410EA1-A6E2-4594-8F41-ED7BEDE2E32E",
    "880701 Hauber to Stone.pdf": "2511C583-782B-4B2B-9A0B-0A78BA08DC43",
    "880810 Hauber to Tindall.pdf": "28AA7958-ADE7-468E-AD02-B1FD7A5D359A",
    "880913 Hauber to Miller.pdf": "E9BE09BA-0F57-446A-A3EA-8D62409ED79F",
    "880916 Tindall to Watts.pdf": "5D153BC4-C61A-46DA-83FA-1EBA63B6A7EE",
    "880923 Hauber to Martin.pdf": "7D3D817D-9A57-471D-A5C5-DDCD2DF98C98",
    "881004 Tindall to Larson.pdf": "BE173105-9097-4D25-9BF5-B64F7186A403",
    "88Program.pdf": "78FF0A4D-0BB3-433E-949E-5BEC8CBAED2E",
    "88TheNeurosurgeon.pdf": "6BE73CA9-B558-4A5A-ABC1-1171C38BABAD",
    "88TheNeurosurgeonAnniversary.pdf": "440B6561-C9E1-48F5-B8ED-91477C8D2BB0",
    "89 program.PDF": "1896E1A0-39B8-49CD-9BA5-251D53995F26",
    "8902 Meeting Announcement.pdf": "F7000289-0812-42F2-B26F-7FB3A9118404",
    "890314 Tolchin to Connolly.pdf": "284802EA-523A-4A1C-88D2-6F4B1FB5C131",
    "8906 Newsletter.pdf": "C4A2B717-7555-489F-AF4D-A32845133FF6",
    "8910 Newsletter.pdf": "1AF02846-D3EB-448F-A1B0-192C901CA8DC",
    "89Program.pdf": "4E23B32F-3768-4715-8045-897ECE6D1FF1",
    "89TheNeurosurgeon.pdf": "8E0752B3-7B9A-4C55-9E70-E27FFC7F16D7",
    "9 Competencies_4-21-22F.pdf": "116466CB-AF74-4F75-B736-2BECA9D12CF6",
    "9-Bragg-Pediatric.pdf": "E2391F70-5E52-4CFA-9BEF-72BD770951E2",
    "9. CNS2023_IVGBM Poster 7.29.23.pdf": "188E0C40-52BD-4EED-90CA-19E1EE02B118",
    "9.A.  Appendix A  Monitoring Guidelines Final.pdf": "B22DE266-159A-4D30-87A3-13DB2D6664A8",
    "9.B.  Walmart Letter.pdf": "21AD8F0D-8A67-4F9B-8FA1-87FE413D6BFF",
    "9.pdf": "060B8D9E-3CD5-48EB-9730-FB68716B3D9F",
    "90 Day Readmission table.pdf": "F6E5343A-C8D5-43B2-AA32-D98821347F10",
    "90 newsletter vol 52 pres letter.PDF": "034D5EF5-505D-4ACA-B8EA-388D48A1E80B",
    "90 NL vol 52.PDF": "A0DCDAC2-BE35-4DDC-A3F1-98A94F24753F",
    "90 NL vol 53.PDF": "3977A679-93FD-4DA2-8E62-1F53098A8D47",
    "90 Program.pdf": "DD52C7B4-D4DA-4898-B9A9-708FB3872D1D",
    "90 proposed bylaws.PDF": "F8B2DED8-DB05-47EE-8D5A-443864D94D67",
    "90 scientific program.PDF": "0A77952E-093A-4D5E-B10F-E70DCB89D826",
    "9001 Newsletter.pdf": "2619F55E-10FD-4E4E-9E83-1614996C9512",
    "9002 Meeting Announcement.pdf": "A59D1D31-E44F-46A2-9D3A-57108EABEA4C",
    "908 Digital poster for mini beam radiation treatment-New Kid on the block CNS 2019.pdf": "C3E83006-3EE1-4A13-B0CA-A10089B83153",
    "90Program.pdf": "48EEDEEF-7F17-4CA1-B024-715D7F725CA9",
    "90TheNeurosurgeon.pdf": "3FFD8DF8-CDFD-4712-9E45-118986DE0E83",
    "91 Program.pdf": "E0B1E288-F8C1-4206-81CD-575279EEC0FA",
    "91Program.pdf": "28F63941-3A7E-4BC4-85B5-B13C57443252",
    "91TheNeurosurgeon.pdf": "100821E0-3B09-46BF-8A76-D2364796C2CA",
    "92 memo DC merger.PDF": "A455F914-DDB7-4526-A605-CEB5455D94B1",
    "92 newsletter vol 51.PDF": "699E0B3C-8DFB-480A-86CD-93E1EDFC8C49",
    "92 scientific progrm.PDF": "CA57A7B3-78EE-492A-811B-B0021846B5EA",
    "9211 Proposed Rules Revisions.pdf": "0C1D2AAA-B941-4A3E-AF79-0D2244F6100D",
    "92Program.pdf": "5864342F-E3F6-4F4B-BFE7-09113D38052A",
    "92TheNeurosurgeon.pdf": "A52E0FFF-DC0C-4B29-9DDE-E1EC309A21F1",
    "93 Program.pdf": "370F3EDE-A657-40AC-8E10-2890D2F6FED2",
    "93 progrm.PDF": "8A8431DB-752A-42FF-884C-40B852309BEC",
    "93Program.pdf": "9D7C12C8-61BC-4FFB-A627-2BC98B257B27",
    "93TheNeurosurgeon.pdf": "D7E6B6DB-64C3-4225-BD3A-B5A95E870F08",
    "94 Program.pdf": "D2ACED38-0DB8-42A4-BB0F-17C284B74E66",
    "9407 Newsletter.pdf": "DA48AD65-28E7-4E94-B278-F3D0EA1C46ED",
    "94TheNeurosurgeon.pdf": "BFA3CA57-AF05-4F28-9BEF-47E83A12C84F",
    "95 by-laws.pdf": "8B86E134-3B7D-47C1-9277-6A64DC6B7633",
    "95 CD rom memo.pdf": "5DC051A5-6DDE-4707-BB34-1C2D1A8851FA",
    "95 newsletter 62.pdf": "D767692B-9A8E-4B0A-9BD0-62A519B1C9D4",
    "95 program.pdf": "051163EE-3822-4AEA-876B-2F409CAFA1D7",
    "9501 Newsletter.pdf": "D75521EA-D0D8-4067-935E-A0C2957DB78A",
    "9508 Newsletter.pdf": "DE2DC14D-9B56-4499-B1F8-71091BF01468",
    "95Program.pdf": "EB66F239-85ED-40D7-B56E-8AF8DE828B3A",
    "95TheNeurosurgeon.pdf": "FC571DC1-1292-4FEB-A6A3-EB04573B9BFA",
    "96 Herniated Disc.pdf": "A07C287A-82C3-42E3-964D-B3D057CAEE64",
    "96 Prelim Program.pdf": "284BF7E6-2773-418F-8ACC-73F5FCA026CB",
    "96 Program.pdf": "FDB07F26-DEFA-4841-9389-55D74B13E9A8",
    "96 scientific program.pdf": "2F1DABA6-0C3E-4434-8E40-CF75C8CB966A",
    "96 Spinal Stenosis Brochure.pdf": "F4A1304C-0E16-4FB3-A81E-6D24D49C78C9",
    "96 Sprain and Strain.pdf": "9D1DB710-6A04-4337-8C1B-59CB6B7184D0",
    "9612 Newsletter.pdf": "FCBE7E75-849F-47C5-A506-59EDD768157E",
    "9612 Rules and Regs with Proposed Changes.pdf": "D310C695-37BD-4D1C-ADD5-70A6A4B0F9FC",
    "96PresidentialAddress.pdf": "E8725A6C-9D70-42DE-AA30-8848D6CCDDF6",
    "96Program.pdf": "E45483C1-666D-4488-81B0-440EF0B30213",
    "97 july newsletter vol 65.PDF": "7D5BCA38-37E6-46D3-A0AE-960E3D25A8EB",
    "97 newsletter vol 63.PDF": "BD6600D6-C0BF-4008-8AC4-D6CB14465FB5",
    "97 newsletter vol 65.PDF": "D6D7D730-83B3-45AC-94FC-B4AB5570651E",
    "97 newsletter vol 66.PDF": "A26160B0-36A8-45F3-90A9-DB9F55C178C2",
    "97 Program.pdf": "E25E7C4E-88FA-4B49-9842-032C1C9AA692",
    "9794.pdf": "B651C737-F54D-4B4D-85ED-37186E141C63",
    "9795.pdf": "8F8C0400-DDF8-4661-8698-6BD17E337141",
    "9796.pdf": "5515C4AA-4A5B-4966-9F52-684A7F920981",
    "9797.pdf": "24514536-28C5-408D-A7F0-00852B7553A1",
    "9799.pdf": "70ED24FC-E11D-4010-A738-40EABDF7FBE3",
    "97TheNeurosurgeon.pdf": "440E6B6B-DAD6-4FBB-A777-9477170021DA",
    "98 Program.pdf": "CC23C3DF-0589-473E-A2A2-A00644F9D8A7",
    "9804.pdf": "2FEED739-F339-4CCA-A6A3-821C678773E7",
    "9805.pdf": "3B85360A-61DB-46BC-8998-4A6804EA87E1",
    "9806.pdf": "41A32D6B-2E52-4AB3-BAA4-B19B1845997C",
    "9807.pdf": "E7F53190-8042-4E59-82B9-28DD371225DF",
    "9808 Application.pdf": "0A9D18FE-F051-474F-A979-976DE4B93739",
    "9808 Newsletter.pdf": "5DF06898-6437-4A66-944A-3790915E6044",
    "9808.pdf": "BD84E15C-81E0-433B-95AD-CE3637C405DF",
    "9810.pdf": "F3C54B24-717D-46AD-AD24-320090968A38",
    "9815.pdf": "66B32044-483B-4EFE-81EF-E8EBF014532C",
    "9818.pdf": "159934D4-F9FC-4A7F-9861-82025B5E22C4",
    "9821.pdf": "D47EE90B-210A-47AF-9923-FCCEFE0338F3",
    "9825.pdf": "44B3706A-53EA-4A2F-9035-45E9ECE19A91",
    "9827.pdf": "A171B67A-255E-4E11-BEA4-28F4BEDE0A34",
    "9830.pdf": "0393874E-97F5-4DB6-BBC2-43F87AC53512",
    "9831.pdf": "A41DACA1-8C1E-42CA-AB98-F9CD02CBBFE8",
    "9832.pdf": "BBB26583-4531-451E-87DE-19FF767A2F2F",
    "9833.pdf": "A01FA72F-990D-4D9B-AC12-DA261BF13344",
    "9835.pdf": "E697F770-118B-40A3-9ED2-C2E6DF85112A",
    "9836.pdf": "F2D7E423-9EEE-4F19-88E0-99852E30A8E9",
    "9837.pdf": "748DC729-D144-4E96-99CA-20C739CDE8F7",
    "9840.pdf": "1970F361-682F-4FD6-BF63-E3A1050E9DC4",
    "9844.pdf": "FD1A1745-1149-4640-B9E7-AB56CB36AD6F",
    "9846.pdf": "5F891F5A-E841-42FE-BDCD-664AD1B4F6D0",
    "9847.pdf": "0A9AB6AD-9D35-4BE9-873C-2C39DE340A2E",
    "9848.pdf": "6167D629-9F79-4A82-A1FA-A43C61F8A7F3",
    "9849.pdf": "198D4EA2-E0EF-44AA-9AAA-52CF935F5B25",
    "9851.pdf": "D7053961-3106-43D6-8BA6-856146F6B844",
    "9852.pdf": "768C34FF-0CFF-463C-A47D-148E3E703CD6",
    "9853.pdf": "4B9547EC-057A-4903-A9B0-81431E05891F",
    "9857.pdf": "B8A5BF40-28FC-4129-A825-0C52B3A599AE",
    "9859.pdf": "BCBF07AC-A840-43F5-8A6E-56E0EEFE872C",
    "9861.pdf": "1DB4F158-0741-420D-B871-F60FF864FB58",
    "9862.pdf": "432C3EFC-C119-4A22-B1B0-92694ABE3045",
    "9865.pdf": "BD7049FD-6DBF-40B0-8F80-C1AD52F1A30C",
    "9867.pdf": "098EE084-563A-4636-9DAF-A690F88BCEC6",
    "9868.pdf": "C78FE952-6E0A-4684-B3C0-9A1DF020DC22",
    "9870.pdf": "78CB9502-EBC5-4634-A76D-CB75E92600B8",
    "9872.pdf": "8082A899-CBEF-4E19-B06A-119F458F4A3A",
    "9873.pdf": "22BC49DC-0C3B-41BF-90EA-3EB672CBC439",
    "9874.pdf": "F0A42CAA-EA99-4D88-A635-5B285412541C",
    "9877.pdf": "55C0D66F-D422-4855-A7C6-BEE1F2343CC9",
    "9879.pdf": "A8D48B13-945F-4A0E-8ABF-719529996A48",
    "9883.pdf": "8C09E423-F50E-4EE4-87DF-4FA16F48FCD5",
    "9887.pdf": "3D1DB843-BE56-47FF-B931-08BF60A37570",
    "9888.pdf": "F1FE2479-3761-4D86-8A12-78332FE01281",
    "9890.pdf": "D69F91B4-A1F2-46D5-AC2A-A59085BB1AB9",
    "9891.pdf": "EC80925D-E999-436A-A672-1A0DBB3ECE7F",
    "9892.pdf": "7B18DFED-F93D-40B3-8D68-F9DD74BCA157",
    "9893.pdf": "BACE98AB-F0E8-4131-90E1-1E898185741A",
    "9896.pdf": "741174BB-6755-4BAC-8BCA-72E6757E5A22",
    "9897.pdf": "412F6243-A342-44E1-BA05-DCC832976E99",
    "98Program.pdf": "E3AB71EA-D89F-49D3-B39B-886385944CCF",
    "9903.pdf": "76BAC5AE-B060-4812-A180-1A5DA72D5150",
    "9905.pdf": "0CBC5249-623C-4E8E-8759-C23EDBDA4B7A",
    "9906.pdf": "BE38E253-FC52-444A-9124-40D495A07364",
    "9907 Newsletter.pdf": "D2CFA942-32CF-4AC3-AC88-740E1D88ADD3",
    "9909.pdf": "9EE7629F-0ADE-414B-BD40-AD67FC55D9C2",
    "9910.pdf": "1F3F4325-E763-48AF-B272-7B8CF821AB0E",
    "9911.pdf": "A763D33A-F6C9-43EB-8E7D-1A7B25AC81F2",
    "9912.pdf": "403DD9D0-5A34-447B-BD02-7EF3FF066247",
    "9913.pdf": "1B20CA04-305C-4CE3-853E-A9B47C2817E3",
    "9916.pdf": "C0F7CD35-FF97-45D5-88AC-1FE4990E97B0",
    "9917.pdf": "67B75E20-DC71-4900-BB5A-23CB2E766F4B",
    "9919.pdf": "AD2B6F10-05AB-4D13-A095-AE05CA95A0C1",
    "9920.pdf": "40E4A91C-FDCB-41D8-8208-B50DCE50C768",
    "9926.pdf": "F7141D55-E54D-4157-9DFD-45A6497C0DD2",
    "9929.pdf": "97140E40-69EE-442C-B1FC-0047378B657B",
    "9930.pdf": "894DA834-939E-47AF-B4BD-CD107822DD17",
    "9932.pdf": "489345E5-9EE3-4319-AAAF-94C38DC7502B",
    "9933.pdf": "4688C1A7-B3EF-48D7-984F-8D77D96AA633",
    "9934.pdf": "7878E192-5313-4CB2-BFF4-7A95D4BD9502",
    "9936.pdf": "2F953879-A3A4-41AA-9235-41C988CEB4A2",
    "9937.pdf": "EBEF813E-F18C-4E33-90D1-BB1A0371A670",
    "9940.pdf": "6897AE20-FA27-4198-88E0-C3A858894993",
    "9943.pdf": "369AFD7A-FE70-4DF3-AD56-58ADCD01AF02",
    "9944.pdf": "5100B52C-A756-44BE-A679-607F946D208C",
    "9945.pdf": "929175E9-ADEB-47D8-9863-BF555A7DD59D",
    "9946.pdf": "273DB199-A008-43AD-B9B3-7146E98D365C",
    "9947.pdf": "587EF7A2-EA14-4730-8854-3F019D3B7937",
    "9948.pdf": "0085AC83-218F-42C7-9CCE-BA2FB8EE95B4",
    "9951.pdf": "9C70501A-0D95-4374-8B7E-1EA864377711",
    "9952.pdf": "E0DF7523-5946-48F0-90EA-A8E2A69C9760",
    "9953.pdf": "D8D33588-B681-4260-BA04-6ED493135BDF",
    "9955.pdf": "E764B772-98A3-410A-9764-3FCD232EB6ED",
    "9957.pdf": "641E4AE2-9793-4080-B7FA-DCE87313792F",
    "9958.pdf": "E0A7907E-1CF2-423D-88B4-CD925CBA2D19",
    "9959.pdf": "3EF61C0B-F6BD-4F64-A58F-CEDC9B6BC3B8",
    "9962.pdf": "98745A7D-B748-4A76-ABA7-CD488624EEC1",
    "9963.pdf": "7C78CCFE-50EB-4085-9670-A1BFB8F4DFB8",
    "9964.pdf": "28ECCE89-918B-43B1-951E-0DA97B2EE33D",
    "9965.pdf": "6FEB672B-8AE0-40C7-BA14-67EDEA35BB6A",
    "9969.pdf": "0751B2DA-723A-47B6-B8B3-177F44B0A6C5",
    "9970.pdf": "F145083C-7C8E-40DB-8021-9F7D19D4FD58",
    "9971.pdf": "0E3A4F0B-9216-4312-A289-BF4E2BE70849",
    "9972.pdf": "DA3813AF-0D43-4410-BE07-F62287BAB87A",
    "9973.pdf": "21D621CA-57E0-4A10-A2FE-D74550914DC6",
    "9974.pdf": "99F62CE0-5A4E-4D29-BABB-A4A7F75B5127",
    "9980.pdf": "26C11013-501A-4149-958C-7206E0B4730F",
    "9981.pdf": "EB780B55-3581-4220-A696-8A51BE5053B6",
    "9982.pdf": "FBB9ED8D-CF79-4570-9712-6EF8EBF822F8",
    "9987.pdf": "FB73C3EE-C3B1-40EA-AD8C-8F934B30E964",
    "9988.pdf": "8934222E-8135-40B7-AD5D-B09605C70E19",
    "9989.pdf": "3749B448-913E-46F4-B423-A5D49C932E75",
    "9990.pdf": "58D20DF1-3768-4E95-AD22-EE5E89DCE0D7",
    "9992.pdf": "4EA634FA-8C4C-4FBC-BF71-868446C7EBDC",
    "99Nov. 3, 1999.pdf": "76992AFC-F317-4E79-A9D2-F30EF6778361",
    "99Oct. 14, 1999.pdf": "93A6DBA3-6059-4C4A-8EC3-8E2EB792B3E7",
    "99Oct. 21, 1999.pdf": "0F2A9AB0-1B48-4581-AC16-27211590308A",
    "99Program.pdf": "8CAFF517-C9A7-408B-8099-EA76FD2AAAD0",
    "99TheNeurosurgeon.pdf": "6A1E33BA-DF48-4CE1-A93B-392BC0A96B6C",
    "A blinded study using laser induced endogenous fluorescence spectroscopy to differentiate spine tumor.pdf": "F1E466EA-0E33-4B59-895E-0C9B97612AAA",
    "a bouras.pdf": "77408E21-46D1-492B-A2AD-3ECBF8D45991",
    "A Boylan Verification.pdf": "88D81305-4AA0-410E-B11B-B2751CD6EF24",
    "A Charles CNS-CSNS Goodwin.pdf": "B925FF05-D824-4FB2-8C46-89E26BD87507",
    "A Feasible Benefit Of The Renin-Angiotensin System Blockage, Looking For Clues In Cervical Spondylotic Myelopathy.pdf": "D34B473E-2E30-4F38-86E1-518D7F841FA6",
    "A Minimally Invasive Technique for Decompression of the Lumbar Spine.pdf": "D598A6EC-8E36-414A-9FE1-9D4CA30B61D8",
    "A Multicenter, Prospective, Randomized Trial Evaluating the X STOP Interspinous Process.pdf": "FB96C4B8-2095-4437-ABB9-18F092594405",
    "A Systematic Review on the Radiomics Classification of Chordoma using Machine Learning.pdf": "B9F89791-94BB-4928-BE20-D9D725DB9917",
    "A Thomas SNS recommendation mwg.pdf": "F71ECF1C-8683-40A2-B2FE-21713A68DE94",
    "A Veeravagu T Johnstone LOS Apr 14 23.pdf": "4F964849-7976-45BE-B47C-668FCCF681B5",
    "A. Aguirre CV Resume.pdf": "92FB3F1F-D9FA-44DF-B2B8-06DE877CEF10",
    "A. Ahmed CV.pdf": "ED5DD8C7-AB24-4699-95FB-EE97759312CC",
    "A. Carson CV.pdf": "20A6862C-2724-4008-9EEB-BBB7D81529DD",
    "A. D. CNS.pdf": "18C446E7-81AA-4366-8E0E-53425AF86652",
    "A. Jessey Chugh-RESverif.pdf": "AF17F7F9-80F6-43C4-AD7E-831D8F7493F6",
    "A. Khan LOA 8-9-22.pdf": "5EBA8C7C-B35E-4B52-A5C2-993D94A1F750",
    "A. Zemmar - Verification.pdf": "9A4D2B16-0607-47D7-A4B0-F244E15DA1D3",
    "A.Althagafi.verification.email.pdf": "975E0732-F8ED-41EC-A4A2-EFB38B1A5F8B",
    "A.Lim.pdf": "85C43A62-8192-4DBF-9EA4-99E513D675AF",
    "A.Miller_Good Standing Letter w.Rotation Completion Dates.pdf": "CAF549D0-43BC-42CB-9A45-F87B78C9D3EB",
    "a_direct_comparison_of_targeted_muscle.762.pdf": "F3238577-6544-47C3-896A-90E4046797A9",
    "A_Gomez_RESverif.pdf": "CB65B30B-5B0D-4ED2-B030-3E6EFB8DE99A",
    "A_Novel_Bypass_Technique_to_Prevent_Vexing_Spinal.9.pdf": "80556FEB-758A-44B4-8C77-EA8047667D3F",
    "a_writers_toolkit_pories_and_borus_3.pdf": "42CE2183-2BF1-4317-868F-90218AF849AE",
    "a2e36a55-6d7a-4700-964d-ce7010923897.pdf": "ABF675E3-DDBB-48E2-BB2D-10A5D4C53997",
    "a456f86a-24a2-4337-a292-d093ff9ced4f-converted.pdf": "A5E53965-5DA2-45EC-A135-E475FE437E5B",
    "AA Fig1.pdf": "FDE36AED-A931-4D23-9321-3C591964455C",
    "AA Fig2.pdf": "F5B030B2-86D0-492D-88AF-12334DBA6939",
    "AA Fig3.pdf": "AFB3A96D-0356-4A6F-8ED7-590982759117",
    "AA_SNS_nomination_2023Oct.pdf": "BD266C9E-25FB-4E64-A129-224FDA7BD720",
    "AAA craniometrics_peds section poster.pdf": "1D116598-54F7-4EAB-9BAA-78D17432C24A",
    "AAcNS 2021 Preliminary Agenda_08.09.2021.pdf": "7BA13F70-6F72-47A5-A2B4-91080544BFF0",
    "AACNS Abstract 2020.pdf": "5203AF92-3DAF-4C8E-9480-8AA2F9690668",
    "AAcNS Newsletter June 2022.pdf": "73B609CB-0F5F-4FAF-AF9C-39DE2A2599D1",
    "AAcNS Nomination Elizabeth Claus.pdf": "C2528247-B509-4CEB-9CF6-063DF27EF2AB",
    "Aadit Mehta 10.12.21.pdf": "6D3CF87A-54A0-4EEE-B593-F948DB7F321B",
    "AAFNS 11 58.pdf": "162D48FF-D256-4008-AEE0-7120551E5FD6",
    "AAK LoR Wali for CNS.pdf": "2A6F4B97-1703-4945-A15F-290C8B532CB0",
    "AAlmojuela-email.verification.pdf": "1106F005-3BE7-4C31-A7A9-132748DD4B57",
    "AAlvarado.emailverification.pdf": "93668269-4644-4CB9-A775-3C7AEB6AD027",
    "AAmmar-emailverification.pdf": "5431AB44-466E-499F-B8DF-E2CB403828D3",
    "AAmmar.email.verification.pdf": "36F1917B-0B2F-46D5-9512-56CAE3CE6173",
    "AANCHR.pdf": "DB42C98D-8AE3-401A-AEE6-0E7A69E6EE7D",
    "AANS 2017 RFP_2015-11-17.pdf": "8A285774-C887-400B-A61C-92B1F2169294",
    "AANS academy letter Penn.pdf": "B51E674B-2363-41F7-919B-A31C69892484",
    "AANS Achondroplasia Poster Presentation - Version 1.pdf": "D3CFD521-C8DB-4F6E-867E-2E76CC6C0EF6",
    "AANS and CNS Position Statement Cervical Spine Guidelines FINAL.pdf": "8B77A5ED-8830-4ED4-9F57-8E51EFC1A83A",
    "AANS and CNS Position Statement on Arthrodesis of the Spine FINAL-APPROVED 082121.pdf": "8925FC4A-DA79-4FD0-A08F-87236361ED5E",
    "AANS and CNS Release 2021 Legislative Agenda.pdf": "06846746-13F1-4AEE-BF3E-E8A210C006A9",
    "AANS and CNS Release Prior Authorization Press Release.pdf": "C0C3556B-DBE6-483F-8D0D-B87D9492BB9F",
    "AANS AVM ICH References.pdf": "C7DF1F8E-34D5-4F47-9245-10E54F27C365",
    "AANS certificate.pdf": "8770C747-7B11-47A1-87C7-7F45656CD588",
    "AANS Chiari 1_Scoli Outcomes.pdf": "A0637FF2-F803-4E27-89EB-FC95FE68A011",
    "AANS Chiari 1_Scoli Radiographics.pdf": "B3D85A73-9393-44DB-AF75-8326738F7C6D",
    "AANS CNS ASSFN Letter to First Coast on MRgFUS Draft LCD 020820.pdf": "AB62F0DB-B384-4C48-B853-C67FBA4B1CFF",
    "AANS CNS ASSFN Letter to Novitas on MRgFUS Draft LCD 020820.pdf": "46FBF08E-7B3B-405C-BF6B-76C84BE55415",
    "AANS CNS ASSFN WSANS Letter to WA HCA HTA on VNS Evidence Report 033020.pdf": "E040931E-20F5-42BF-AFA7-DD987F4D9D32",
    "AANS CNS Letter Regarding First Coast Neurostimulator LCD 39406 09242022.pdf": "F42AEC59-9D79-4407-AA9C-E7A441485540",
    "AANS CNS Letter to Aetna on Lumbar Interbody Fusions with Expandable Cages.pdf": "1FDEC2BF-596E-4622-AF74-56C3CF7DAC10",
    "AANS CNS Letter to CDC Regarding Pain Management Guideline Update 061620.pdf": "C4DD7ED7-9243-49EC-8E08-D6643100B879",
    "AANS CNS Letter to CMS on 2020 2021 MA and Part D Opioid Prescribing 040620.pdf": "927A473C-1E40-4229-AECC-911AB566EF07",
    "AANS International Membership Letter (Home Address).pdf": "643544BE-75A0-4F8E-BC58-A73D74E02C71",
    "AANS Meeting Services Proposal_2016-01-27.pdf": "50FC28E3-1F7F-442F-801F-A2FFD5C8E662",
    "AANS Membership Certificate.pdf": "3A97863B-499A-45A8-97AC-5E634B4618E6",
    "AANS Membership letter Gephart_LOR lonser.pdf": "76550357-4E9A-441E-B5B7-DB4566399202",
    "AANS membership recommendation letter.pdf": "3011C4BF-2049-47F4-B5BF-FE91C4F60CEC",
    "AANS membership verfic form NK 1-14-22.pdf": "2CD22DA5-E1CF-4872-AE9C-08D5C164FBC9",
    "AANS membership.pdf": "5F32F68C-FC43-4202-946E-73DBC8AFD47D",
    "AANS peds GMH psel poster_MAA DSH.pdf": "20B1BE00-F56E-4AC1-BD9F-B460C67FC359",
    "AANS peds neuropen poster.pptx.pdf": "49E29B4D-9F41-43BC-BB6C-58B1223B7785",
    "AANS PHIS+ Poster Presentation - Version 1.pdf": "EB0FD952-E931-4E62-A32C-3E72575642C6",
    "AANS Poster final final.pdf": "B24AA42D-3919-41D3-8910-FC2AEFEC2766",
    "AANS student membership form.pdf": "B5198F46-15F0-43E4-901E-DC7276CDB168",
    "AANS-CNS 2020 Legislative-Regulatory Agenda.pdf": "3457F670-B499-4759-AFA7-F0F0C7CAAD24",
    "AANS-CNS 2021 Proposed Medicare PFS-Payment Comments 100120.pdf": "AA6DF3BA-7EFE-4827-BF95-14544A5A731A",
    "AANS-CNS 2021 Proposed Medicare PFS-QPP Comments 092920.pdf": "A49C73A2-CA4F-4B9C-B895-FD3F0760C8AD",
    "AANS-CNS 2022 Medicare OPPS ASC Comments 091721.pdf": "4559336E-9FF7-47B9-8F7F-F094B4E04C15",
    "AANS-CNS 2022 Medicare Physician Fee Schedule Proposed Rule Comments 091321.pdf": "EC704949-1998-4795-87A6-1D6BF97EDD75",
    "AANS-CNS 2023 Advocacy Agenda Press Release.pdf": "2A15DC2A-34C0-4613-929F-2FB1DDB6E96F",
    "AANS-CNS 2023 Legislative and Regulatory Agenda.pdf": "BAB9A6E4-DCD0-4CE1-BAD8-1C4A3D3AF8A9",
    "AANS-CNS 2025 Medicare Hospital IPPS Proposed Rule Comments 061024.pdf": "6E784B20-A689-4880-94BC-8B55D68F2102",
    "AANS-CNS COI Policy Introduction.pdf": "F45B9DA7-9ABB-4F77-A6C0-47C938104A0E",
    "AANS-CNS COI Policy.pdf": "1F09B5EB-CAF0-43C9-8202-15CFD9043B45",
    "AANS-CNS Comment Letter.Wave 6 Movement Disorders Cost Measure Field Testing 022824.pdf": "9E0ABD44-7158-4C62-96C7-E93E6F997234",
    "AANS-CNS Comments Medicare Hospital Inpatient Prospective Payment System Proposed Rule 061422.pdf": "FA857281-A5F0-4E1B-8805-9254850B18FE",
    "AANS-CNS Comments Medicare OPPS-ASC Proposed Rule 100520.pdf": "D06B4C3F-AE6F-44E1-9F45-36A2FD71D419",
    "AANS-CNS Comments on CDC Opioids Guidelines 040422.pdf": "23159534-F20F-46E5-8A80-722A8B3CF41B",
    "AANS-CNS Comments on Cervical Laminectomy and Laminoplasty 5-26-23.pdf": "437DF553-54D5-4011-8D93-D84F5CA331AB",
    "AANS-CNS Comments on CY 2023 Medicare Hospital OPPS and ASC Proposed Rule 091222.pdf": "5A19DCAC-A109-45EF-A948-0AA30A0DFC31",
    "AANS-CNS Comments on CY 2024 Medicare OPPS ASC Proposed Rule 091123.pdf": "DBBCA088-ED77-4856-A153-703B84D64752",
    "AANS-CNS Comments on FTC Proposed Rule Banning on Non-competes 041923.pdf": "40857E70-EEBF-4F9F-A5F3-8075E63F88F2",
    "AANS-CNS Comments on MACRA RFI 103122.pdf": "B781E580-1D68-40AD-8C6F-1FB0D839B0F8",
    "AANS-CNS Comments on Medicare Coverage for Innovative Technologies 1102120.pdf": "1CBDFEB9-D2F7-42B5-A22C-4F86B4B96FE4",
    "AANS-CNS Comments on NASS Intraoperative Neurophysiological Monitoring Policy 092423.pdf": "46AD51DF-8D6C-4219-80CB-B97906967A50",
    "AANS-CNS Comments on TPCC Refinement 051724.pdf": "77C86F50-79CF-479E-825E-14A6C6895402",
    "AANS-CNS Comments re Proposed 2023 Medicare Physician Fee Schedule Rule 083122.pdf": "0F0511C6-4A20-4DBD-BF1F-8D7B6692A85C",
    "AANS-CNS Comments Regarding NASS Basivertebral Nerve Ablation Coverage Policy 060122.pdf": "4A826EB0-9B75-4B84-B14B-781046329AE5",
    "AANS-CNS CommentsPCORIResearchAgenda31312.pdf": "5AB8BDC6-0A96-44E9-A751-569E8465059B",
    "AANS-CNS Endorsement of CONNECT for Health Care Act 042721.pdf": "10018205-8041-4ADE-9833-E177154D7421",
    "AANS-CNS Endorsement of H.R. 6283.pdf": "6AF936D3-0017-4B49-910F-E14ED1BAA5A9",
    "AANS-CNS Endorsement of H.R. 7059 the Coronavirus Provider Protection Act 052920.pdf": "CCC9798B-B589-4496-A5D4-25DBC37141B2",
    "AANS-CNS Endorsement of Improving Seniors' Timely Access to Care Act 051221.pdf": "B283E355-0E73-4508-9062-EA460BB50107",
    "AANS-CNS Episode Based Payment Model RFI Comments 081523.pdf": "79392896-5421-4907-B69C-0834DFBE5FDD",
    "AANS-CNS Firearms Position Statement FINAL UPDATED 5.pdf": "13FEAFB5-6308-4EB3-9626-3F2A9978250B",
    "AANS-CNS Firearms Position Statement FINAL UPDATED.pdf": "EC128CF0-9963-4F33-A44A-FF7A1DDA9145",
    "AANS-CNS Firearms Position Statement FINAL.pdf": "1F3E65E3-2D41-40AD-A062-D04AC23C907C",
    "AANS-CNS FY21 MISSION Zero Appropriations 11202020.pdf": "27AB4AC5-8B3F-403E-B618-0BDC796ECF20",
    "AANS-CNS Legislative Accomplishments 2022.pdf": "C9FA15FC-3DBD-4460-A929-BC40B9E60E5B",
    "AANS-CNS Legislative Accomplishments.pdf": "487AEB6C-C9C5-43C6-AD9C-02628F15E97F",
    "AANS-CNS Letter in Support of PAHPA Reauthorization House 032123.pdf": "DB84C3DB-B493-4C5D-974B-7F94D2192C1C",
    "AANS-CNS Letter in Support of PAHPA Reauthorization Senate 032123.pdf": "91B35A75-4F74-48B5-AF9D-A7E321D4819B",
    "AANS-CNS Letter of Support  for S.Res. 329 100919.pdf": "02330624-1E8D-4B25-827E-92DB0A5641F8",
    "AANS-CNS Letter of Support for BLEEDing Act S 1653 053023.pdf": "56F02226-E915-466E-B606-97666AB2CB3F",
    "AANS-CNS Letter of Support for BRAIN Act HR 5435 110121.pdf": "182AF43A-8BF4-426C-8A1B-5936D9A3C349",
    "AANS-CNS Letter of Support for Ellie's Law HR 1553 030421.pdf": "5E491EF7-C304-4C49-AFDA-C75F457356B8",
    "AANS-CNS Letter of Support for Ellies Law HR 902 032223 FINAL.pdf": "C625764F-ADE4-49E0-AE3D-E95030E5F9D9",
    "AANS-CNS Letter of Support for Ellies Law S. 895 032923.pdf": "AB102818-3452-441C-809F-811F21359F49",
    "AANS-CNS Letter of Support for Good Sam Legislation 041823.pdf": "26421A3F-62DD-4371-8C2C-09D747D94B5D",
    "AANS-CNS Letter of Support for H.R. 5239 091521.pdf": "F8AC8B14-3BD6-42D1-B43B-46B8554244F1",
    "AANS-CNS Letter of Support for H.R. 623 051121.pdf": "68BAE1AD-2496-40EB-AB5A-6554E5D70E7B",
    "AANS-CNS Letter of Support for HR 315 020221.pdf": "2C9ECF9A-BF54-49D8-8247-F89C3C159F25",
    "AANS-CNS Letter of Support for HR 3391 052623.pdf": "D646DF77-1E17-4090-902F-D8A1F620C659",
    "AANS-CNS Letter of Support for HR 66 021021.pdf": "2FA16F94-80F1-4EC4-AE2A-32D92510588B",
    "AANS-CNS Letter of Support for HR 825 021621.pdf": "F809C6BB-5598-4493-A47C-8CF4C3F20BE0",
    "AANS-CNS Letter of Support for King-Cassidy Good Sam 100621.pdf": "6C6F7B51-BA33-46EC-9B54-CF2868F2D0CD",
    "AANS-CNS Letter of Support for MISSION Zero Funding House 021121.pdf": "B8A12D83-898D-4EF5-815B-E03035760C94",
    "AANS-CNS Letter of Support for MISSION Zero Funding Senate 021121.pdf": "925953FE-4B19-44A8-85B9-D4700612EAD5",
    "AANS-CNS Letter of Support for National Concussion Surveillance System 020221.pdf": "5F6B2744-9B32-4B96-A915-ACA5C9A8EA67",
    "AANS-CNS Letter of Support for National Concussion Surveillance System House 020221.pdf": "9AE54716-F855-4B6E-BFF2-581F4417B6D6",
    "AANS-CNS Letter of Support for S 1624 052623.pdf": "F771CA83-9FA3-4363-BB2B-C7CD1BF32297",
    "AANS-CNS Letter of Support for S 281 021621.pdf": "89D0D27C-7822-426F-BA16-05071C6B2EFD",
    "AANS-CNS Letter of Support for S. 1521 051121.pdf": "693C55B3-DA22-467A-8C15-D5362D31B460",
    "AANS-CNS Letter of Support of H.R. 2256 032921.pdf": "FE20653A-6098-4AF7-A411-F854BCCD664D",
    "AANS-CNS Letter of Support of S. 834 032421.pdf": "8AABD9F5-2F18-48F5-B498-58EEA5BD2179",
    "AANS-CNS Letter Re LITT MS DRG Resignment 081921.pdf": "9447F73D-296D-4F39-8E1A-8D18F80F5C03",
    "AANS-CNS Letter re Proposed 2024 Medicare Physician Fee Schedule 090723.pdf": "90D9EE3D-C304-485C-8553-DACF73D1D635",
    "AANS-CNS Letter RE RFI For CMS Action Plan on Pain Management 101119.pdf": "6178A128-E87C-41F3-AFB1-CE80FA6885FA",
    "AANS-CNS Letter Regarding Novitas neurostimulator LCD 39404 09242022.pdf": "6677E459-A25A-40DB-ADFB-00F23D8CD46E",
    "AANS-CNS Letter Support Bera and Bucshon Medicare Payment Legislation 103020.pdf": "D2234955-306D-4472-84C9-1DFC7350132C",
    "AANS-CNS Letter Supporting H.R. 3259 NOPAIN Act 051721.pdf": "BEB29774-93C0-4F0C-9C49-E38712053BD6",
    "AANS-CNS Letter Supporting Protecting and Continuing Physician Education and Patient Care Act 053023.pdf": "619AD371-992E-444B-BE57-BEFE5B410DE7",
    "AANS-CNS Letter Supporting S. 586 NOPAIN Act 032321.pdf": "A8121F56-59E3-409E-B401-290E708B1DF3",
    "AANS-CNS Letter Supporting Sunshine Act 050523.pdf": "65082394-7663-47A6-8958-984BE8D1B668",
    "AANS-CNS Letter to Aetna re 22853 083023.pdf": "6E4D95F4-97E8-400B-909A-F3311514DD33",
    "AANS-CNS Letter to CMS on TCET 08282023.pdf": "191B86A0-E1B5-47C5-9546-4B329EFB6F50",
    "AANS-CNS Letter to CMS Re MS-DRG Assignment for LITT 10202022 submitted via Mearis.pdf": "93E68092-3144-4658-A222-45D151EDFB9D",
    "AANS-CNS Letter to Congress Regarding Cures 2.0 Legislation 121619.pdf": "BDC3FCED-7B4A-4046-A675-E666D9BFD23E",
    "AANS-CNS Letter to HHS Regarding Primary Care 080122.pdf": "44FD8AFD-78BA-459B-A976-0CB81D2E62D8",
    "AANS-CNS Letter to NASS on Cervical Fusion Policy 10242022.pdf": "416FE102-7126-411E-9F0A-EA1D3E607487",
    "AANS-CNS Letter to NGS Medicare regarding SRS SBRT Coverage 110919.pdf": "337EAD25-1ED2-4F22-BA0B-B6D572FBF85E",
    "AANS-CNS MVP Letter to CMS 012924.pdf": "B2C45346-06F8-4D4F-A219-3D3F2E53E6AB",
    "AANS-CNS Position Statement on Telehealth 2023.pdf": "436536EB-A98D-41B7-8EA0-395EC544BA2E",
    "AANS-CNS Position Statement Paper LITT Tumor-Oncology 090721.pdf": "FC86A2CA-4E15-47B4-895C-A7B59F388AA9",
    "AANS-CNS Press Release Annoucing Surgical Care Coalition Launch 061820.pdf": "931CC367-1AAE-4ADD-92F9-43AF4A6EA586",
    "AANS-CNS Press Release Denouncing Proposed Medicare Fee Schedule 080420.pdf": "91FD04A3-387F-400D-9B30-EB655E23E567",
    "AANS-CNS Press Release Endorsing HR 7059 052920.pdf": "E3A2C0DC-EF98-413D-A4C9-969FBFDBF813",
    "AANS-CNS Press Release re Congressional Prior Auth Letter 062123.pdf": "E00EF569-0722-4DEE-9EE0-5C774045F25D",
    "AANS-CNS Press Release re House Passage on Prior Auth 091422.pdf": "A141C9A7-AD2D-4315-97C7-7070020142FD",
    "AANS-CNS Press Release re NSA Amicus Brief TMA II Lawsuit 102022.pdf": "3F45AB4A-6877-4C72-A559-EF949601BEC4",
    "AANS-CNS Press Release re Orrico CEO Appointment 122023.pdf": "8A2514BA-DFE3-4EEC-B12C-59DB16A3C6A2",
    "AANS-CNS Press Release re Prior Auth Reintroduction 061224.pdf": "5FCC0277-08A4-4895-9C55-E8D488B1B09A",
    "AANS-CNS Press Release re Ways and Means Committee Action on Prior Auth 072722.pdf": "CA30E4C8-FD0C-4A2F-AA0E-E819EB4DC81F",
    "AANS-CNS Quality Comments on Proposed 2020 Medicare Fee Schedule 090919.pdf": "55BA89DD-D681-48EA-A2BE-3509D4A3B9DF",
    "AANS-CNS Release House Letter re No Surprises Act IFR 110821.pdf": "529D7F3E-016A-4C07-9849-D76FE7F649E9",
    "AANS-CNS Release Medicare Payment Reform Principles 053122.pdf": "2D937732-3163-44BF-A0DD-6C44395F2EDF",
    "AANS-CNS Release NSA Amicus Brief 01322.pdf": "8BBAF00E-0A59-49AF-8B9E-C0000B19180E",
    "AANS-CNS Release on Passage of Gun Legislation 062422.pdf": "6BF51E16-ADBE-416C-9886-0890B3F498C6",
    "AANS-CNS Release Prior Authorization Large Group Letter 091019 (004).pdf": "F859A091-AF02-4AA7-91CA-4A58F1715742",
    "AANS-CNS Release Prior Authorization Legislation 051321.pdf": "4A2A6C4A-13C0-466D-912D-D8F3AB9AE805",
    "AANS-CNS Release Prior Authorization Legislation 100821.pdf": "14064991-0502-4777-BAF4-70D651920DCE",
    "AANS-CNS Release Prior Authorization Legislation 102021.pdf": "BFD74636-C2F3-4ACD-8D88-56389CD53DBF",
    "AANS-CNS Release Prior Authorization Senate Letter 102821.pdf": "573FCECB-16BE-4844-9D98-A901BE1DF33F",
    "AANS-CNS Release re Alexander Pallone Walden Surprise 121119.pdf": "949CD904-7B16-4064-8518-713DF0FBEB30",
    "AANS-CNS Release Strengthening Medicare for Patients and Providers Act  040623.pdf": "CF03A760-F711-44D9-83A4-E50FFE16F963",
    "AANS-CNS Request to Include Good Sam Liability Protections in PAHPA 070523.pdf": "626AF295-C947-4339-BA36-4627E3896D72",
    "AANS-CNS Statement for FY 2022 House L-HHS Approps Subcommittee.pdf": "EB63E72E-FC3B-41CD-9B60-DC19B405D214",
    "AANS-CNS Statement for FY 2022 Senate L-HHS Approps Subcommittee.pdf": "D804A476-833D-42FC-B2F7-156563A60763",
    "AANS-CNS Statement for FY 2024 House L-HHS Approps Subcommittee 032323.pdf": "E271F33F-1BA7-4750-B8EA-8CBDA767B40F",
    "AANS-CNS Statement for FY 2024 Senate L-HHS Approps Subcommittee 5.18.23.pdf": "8CB799E7-5D29-4DEF-AF30-2716D0C83D6F",
    "AANS-CNS Support of Medicare Patient Empowerment Act 051921.pdf": "960F4FDA-93EA-4C04-9CE7-DC7408546C0B",
    "AANS-CNS Support of Medicare Patient Empowerment Act House 051921.pdf": "B6DC3631-821D-4466-B645-1E34E224F94E",
    "AANS-CNS Support of Medicare Patient Empowerment Act-Senate 051921.pdf": "3531CD29-A3B0-4D03-BDC1-D0430B9A2D62",
    "AANS-CNS Ways and Means Surprise Medical Bills 021120.pdf": "2264C86B-4A41-44FC-B7F2-3133CB57BECA",
    "AANS-CNS Ways and Means Surprise Medical Bills 121219.pdf": "B2B18283-4C14-45B4-B409-7FEE3F5C3259",
    "aans-cns-assfn_letter_to_cms_re_nca_for_vns_for_trd_062918.pdf": "B4DD44AC-92B2-4D56-BDA5-94704B22EB40",
    "aans-cns-assfn_letter_to_first_coast_on_mrgfus_for_et_073019.pdf": "3DAC0E51-BD44-4F1C-B184-7A918059CA6D",
    "AANS-CNS-CV Section Letter CMS CAS NCD 020723.pdf": "C366562D-58A1-4E77-AB52-CC47019CD381",
    "AANS-CNS-CV Section Letter to CMS re Coverage for CAS 080423.pdf": "4A11B6B0-12E2-45EC-BFF2-635D14833819",
    "AANS-CNS-DSPN Letter to Cigna Regarding Cervical IOM Coverage Policy 032923.pdf": "C71900C0-BA44-4C57-B18D-8181FFB89A5D",
    "AANS-CNS-DSPN Letter to Horizion BCBSNJ 121123.pdf": "A4856657-4F95-41F0-A301-B6466884500D",
    "AANS-CNS-DSPN Letter to Independence Blue Cross re Physiatrist Referral Requirement 110220.pdf": "E815037E-D979-478C-86AD-E019F9B21F14",
    "AANS-CNS-DSPN Letter to NASS Regarding MIS Model Coverage 061121.pdf": "AE4FC0F6-9B51-428B-992B-51AF841F3FCB",
    "AANS-CNS-DSPN Letter to UHC re Imaging for Prior Authorization 062320.pdf": "86CDF26B-2FF4-40D7-97D7-4271CCB90938",
    "aans-cns-dspn-wsans_letter_re_wa_state_health_care_hca_health_technology_assessment_hta_draft_key_questions_on_si_joint_fusion_070518.pdf": "31320CBE-007B-4389-848D-8535BE74778F",
    "aans-cns-spine_section-wsans_response_to_key_questions_for_washington_state_re-review_of_lumbar_fusion_coverage_policy_052015_final.pdf": "0C05EFC5-BF31-45A5-9011-CA99EE6CB9A8",
    "aans-cns-wsans-isass-nass_comment_letter_re_adr_lumbar_coverage_030117_final.pdf": "9A9789A3-4060-4F9C-BCF1-8D6CB480C4B0",
    "aans-cns-wsans_comment_letter_re_spine_fusion_coverage_121815_final.pdf": "6FD270A9-1283-4B7A-92AC-20C5F18C33E2",
    "aans-cns-wsans_letter_to_washington_state_hca_hta_novocure_and_pharmacogenetics_1_20_15.pdf": "2DB71D4F-8579-4B7C-A002-E24D9BF9A7B5",
    "aans-cns_2015_legislative_agenda.pdf": "D6F4513D-75E3-40F4-AC15-9376B64C31CB",
    "aans-cns_2015_legislative_agenda_0.pdf": "79BC3790-0E08-4ED3-AD8B-2135B696F634",
    "aans-cns_2017_legislative_regulatory_agenda.pdf": "8AA6FB4D-3856-4BD6-AC71-0D4358A6822B",
    "aans-cns_2017_medicare_fee_schedule_comments-global_surgery_090216.pdf": "C4601109-89F6-40CF-9E4A-D4BCD0E05FA1",
    "aans-cns_2017_medicare_fee_schedule_comments_090516.pdf": "200C18E4-176A-43B7-94CC-0DD2E63A19A3",
    "aans-cns_2017_medicare_hospital_ipps_comments_final_061616.pdf": "B561DCF3-05DE-491F-A88D-C3BFC26D26E4",
    "aans-cns_2018_legislative_regulatory_agenda.pdf": "CB5394EE-AFA0-4D90-87E7-3F6F247058FE",
    "aans-cns_2018_medicare_physician_fee_schedule_comments_091117.pdf": "BF8EC015-A28C-46A2-8EA8-0DE9E945004C",
    "aans-cns_2019_legislative-regulatory_agenda.pdf": "86CF776E-EE7F-4B45-B3FE-98B5866E67D9",
    "aans-cns_2019_legislative_regulatory_agenda_3.pdf": "743A72CF-36EC-4099-BE6C-8FD252093CA7",
    "aans-cns_2019_medicare_hospital_ipps_proposed_rule_comments_062518.pdf": "E0FDA27D-1151-4C6A-94E3-7F5BCF5CC979",
    "aans-cns_acute_care_surgeon_position_statement.pdf": "727C3E7F-C390-48FA-93F7-0A5630825818",
    "aans-cns_advocacy_toolkit.pdf": "00C1B1B4-1FD5-4034-94CC-89C9177D5AB6",
    "aans-cns_advocacy_toolkit_2018.pdf": "1DFFB119-36CD-4E30-AF03-015BE6D5FA09",
    "aans-cns_alexander-murray_bill_release_10.24.17.pdf": "699E3EB3-480B-4F63-8A96-4FBB128BA0F3",
    "aans-cns_appropriations_letter_9.12.18.pdf": "836193A3-D97A-4984-A120-CAF8B2F85DA5",
    "aans-cns_comments_house_ec_no_surprises_act.pdf": "B404B69F-0B53-4A87-98F4-2B8C099D229C",
    "aans-cns_comments_on_2018_medicare_hosptial_ipps_proposed_rule_061317.pdf": "6DF21C64-C16B-42CE-8AFF-FA0294153688",
    "aans-cns_comments_on_cassidy_surprise_billing_legislation_101018.pdf": "ACEA3158-B347-4F0D-8D60-113FB7293C23",
    "aans-cns_comments_on_cms_innovation_center_rfi_112017.pdf": "E592CC19-2D8C-4697-BFE8-4FA0F29945EE",
    "aans-cns_comments_on_cms_patients_over_paperwork_rfi_081219.pdf": "B7B97FB1-8529-4B44-B754-1FD28052CEE0",
    "aans-cns_comments_on_proposed_2016_medicare_physician_fee_schedule-payment_090815.pdf": "AD366C9C-631B-44D3-AE0D-3B2BA8F783ED",
    "aans-cns_comments_on_proposed_2016_medicare_physician_fee_schedule-quality_090815.pdf": "FC564983-1B28-45D5-8A74-3B3E24E15714",
    "aans-cns_comments_on_proposed_ccjr_bundled_payment_program_090815.pdf": "D28955CB-4038-4ACF-B956-DC8BA8BC8DFD",
    "aans-cns_comments_on_proposed_ehr_2015-2017_rule_061515.pdf": "90AEC529-A58B-4D35-B6D4-A35FDCA84AD4",
    "aans-cns_comments_on_proposed_ehr_stage_3_052915.pdf": "5CE0FA02-2FEE-4EC5-A954-EE5899BC807A",
    "aans-cns_comments_on_proposed_ipps_rule_061615.pdf": "3C621F61-42EB-43C2-B160-9D2941D62A0D",
    "aans-cns_comments_senate_help_lower_health_care_costs_act.pdf": "B074A84D-3021-465A-8150-9DA87521A46C",
    "aans-cns_comments_to_cms_re_cancellation_of_bundled_payment_program_101617.pdf": "8601DF3F-894E-429D-B6FC-1D40ECB440A8",
    "aans-cns_comments_to_palmetto_mrgfus_stereotactic_lesion_ablation_080917.pdf": "5C1E241B-0F99-42E3-A9ED-29474F51F5C0",
    "aans-cns_cures_bill_letter_of_support_to_house_11.29.16.pdf": "CB93369D-CC74-4A89-B34E-0FB2BE24B17D",
    "aans-cns_cv_section_lte_new_york_times_mr._clean_121914.pdf": "5C13A5DD-A583-4CD3-AF17-47FA6AEF4B36",
    "aans-cns_dspn_comments_on_nass_draft_lumbar_interspinous_device_without_fusion_and_decompression_policy_031018_final.pdf": "D117DEBE-5A1A-42D2-841B-44D78D420E4C",
    "aans-cns_endorsement_of_hr_1763_resident_physician_shortage_reduction_act_032019_002.pdf": "76B38C24-A129-4A57-BFD8-25B47EA5D919",
    "aans-cns_endorsement_of_improving_seniors_timely_access_to_care_act-bera_060319_udpated.pdf": "2A712FCC-2720-4FA8-96A1-939FB369FCEE",
    "aans-cns_endorsement_of_improving_seniors_timely_access_to_care_act-delbene_060319_updated.pdf": "6861AE47-90CC-442D-97CE-B4CD6BDC8458",
    "aans-cns_endorsement_of_improving_seniors_timely_access_to_care_act-kelly_060319_udpated.pdf": "BCDD3E71-B15E-4E0A-8789-E583D535FED9",
    "aans-cns_endorsement_of_improving_seniors_timely_access_to_care_act-marshall_060319_updated.pdf": "020445D7-5A84-4F56-81EC-A22452F8C37E",
    "aans-cns_endorsement_of_removing_barriers_to_clinical_research_act.pdf": "BECAC9AB-7BBB-4A52-804D-44EB980BDC5D",
    "aans-cns_endorsement_of_s._348_resident_physician_shortage_reduction_act_030419.pdf": "60AF395D-DFF3-4E27-A839-4D0D95A079DF",
    "aans-cns_endorsment_h.r._2207_the_protect_medical_innovation_act_061719.pdf": "9581194C-B891-40D8-8359-DB8E249DF43C",
    "aans-cns_endorsment_s._692_the_protect_medical_innovation_act_061719.pdf": "FFECFAF7-DFAB-4570-837D-094DAB4BD446",
    "aans-cns_fda_hearing_release_1.30.18.pdf": "3FC2DC0F-DD9C-4FF6-AC4F-9A0D29B4D65C",
    "aans-cns_finance_committee_release_on_device_tax_4.23.15.pdf": "39645C7B-04F0-4AF7-A2AE-5312782827E6",
    "aans-cns_global_surgery_code_alert.pdf": "1986D3AE-AB95-478D-B21B-A6F86D6E4AB9",
    "aans-cns_global_surgery_comments_release_9.6.16.pdf": "6533FE6B-FDFE-44CE-A25E-7CBACC63E252",
    "aans-cns_graduate_medical_education.pdf": "B8EA2F57-6A80-4461-837D-1457F0999DC2",
    "aans-cns_graduate_medical_education_6-19.pdf": "3D58065E-8C8F-41D8-8607-2F2F82169DC7",
    "aans-cns_ipab_repeal_release_10.5.17.pdf": "CA357AE2-72FE-4B10-BB2E-618B3EE80B96",
    "aans-cns_letter_of_support_for_affordable_health_insurance_for_the_middle_class_act_052517.pdf": "834946E1-CF8D-46AF-AB47-D740B8F82FB8",
    "aans-cns_letter_of_support_for_ellies_law_hr_594_013019.pdf": "A07BDBF6-E795-4B3A-A877-A8A3567C8A02",
    "aans-cns_letter_of_support_for_ellies_law_s_864_3.26.19.pdf": "9C771354-408C-49B2-A91F-01C2426253E4",
    "aans-cns_letter_of_support_for_good_sam_bill_hr_1876_041117.pdf": "D40A20CE-2138-489B-A8E6-CDF275DABC94",
    "aans-cns_letter_of_support_for_good_sam_bill_s._1350_final_5.8.19.pdf": "3A926EDF-2F15-4AE3-A852-A10E9873D3DB",
    "aans-cns_letter_of_support_for_good_sam_bill_s._781_041117.pdf": "D073598A-B3DD-445F-B689-002388991CFD",
    "aans-cns_letter_of_support_for_h.r._280_6.17.19.pdf": "A9FDC8F9-6B88-4C6A-818D-5502CED59FCD",
    "aans-cns_letter_of_support_for_h.r._3573_the_ccm-care_act_070619.pdf": "77668952-9A9E-489E-81AF-61D6BC840E62",
    "aans-cns_letter_of_support_for_h.res_._366_052219.pdf": "23652836-71CE-4216-AB8D-9DCE639A3462",
    "aans-cns_letter_of_support_for_house_trauma_reauthorization_hr_648_2_3_15_final.pdf": "A151F8B7-EB18-4F2A-94F6-3CE069B8145B",
    "aans-cns_letter_of_support_for_hr_1255_032017.pdf": "14559373-B982-4D48-BA87-7577A3972EF5",
    "aans-cns_letter_of_support_for_hr_3984_072919.pdf": "F782BD07-D70F-468E-8465-E682CCB63BEF",
    "aans-cns_letter_of_support_for_hr_548_2.3.17.pdf": "389C5AD2-26C5-426E-BF90-DE9C15F71699",
    "aans-cns_letter_of_support_for_hr_836_draft_2_12_15.pdf": "15578A6D-B12E-4F1F-8892-4220D546474A",
    "aans-cns_letter_of_support_for_hr_865_draft_2_12_15.pdf": "A26ADFF2-2747-44B1-9AC1-B8F1E8C93EED",
    "aans-cns_letter_of_support_for_keeping_physicians_serving_patients_act_061719.pdf": "DF9C9839-C72E-4A66-B9A2-81A6FEDEF2C7",
    "aans-cns_letter_of_support_for_s._2010_the_ccm-care_act_070619.pdf": "FCFFB888-DE3D-416B-9EE3-34CC0D1326AC",
    "aans-cns_letter_of_support_for_s._475_032017.pdf": "30CAEBF3-27AE-4EB7-941A-69B66364DBA2",
    "aans-cns_letter_of_support_for_s.res_._245_061919.pdf": "6A44ACED-0461-4725-9263-AB52F00B6506",
    "aans-cns_letter_of_support_for_s_3101_final_7.5.16.pdf": "6BB8F57F-7252-4EFF-BBEB-46304ABA4D64",
    "aans-cns_letter_of_support_for_s_527_041117.pdf": "D138B88B-876A-40FC-A7BC-327234155360",
    "aans-cns_letter_on_ahca_030917.pdf": "F27DA396-872C-4A0D-8431-AE83A3569B10",
    "aans-cns_letter_on_cy_2020_mpfs_proposed_rule_091319.pdf": "49D1472F-E89B-49B3-BDC4-F1949DA56AFF",
    "aans-cns_letter_on_draft_inter-agency_task_force_report_on_pain_magement_040119.pdf": "2B07908B-0B14-42F1-AA32-39874F1FD83C",
    "aans-cns_letter_on_h.r._1215.pdf": "CD03CF36-A180-45E6-8943-AAC7F2C236BC",
    "aans-cns_letter_on_h.r._1215_060917.pdf": "9B7E0189-1694-4194-BDA3-12EBF948A66A",
    "aans-cns_letter_on_h.r._1704.pdf": "5571F3F9-01AB-438A-A450-256359754554",
    "aans-cns_letter_on_h.r._2267_resident_physician_shortage_reduction_act_071217.pdf": "1DEA8F3F-879B-4A09-849E-B6C136A39D72",
    "aans-cns_letter_on_h.r._2373_071217.pdf": "AB185FEE-7EBC-4F75-A288-1264E1CCEBDE",
    "aans-cns_letter_on_hr_1565_saving_costs_saving_lives_act_050417.pdf": "A2C4E95C-335E-417E-8C9F-5EE15888BCB7",
    "aans-cns_letter_on_s._1301_resident_physician_shortage_reduction_act_071217.pdf": "EEF80F04-A16B-4FB1-9A0C-4A7775577C8E",
    "aans-cns_letter_re_blackburn_cara_2.0_0.pdf": "40BDE0E4-6F80-4802-BE68-3F7B7852C8BF",
    "aans-cns_letter_re_cara_2.0_0.pdf": "FE19FED0-4F6C-4657-BA39-19A6935D40B8",
    "aans-cns_letter_re_connect_act_s._1016_060117.pdf": "40AA0CB4-2442-4EED-9206-618C733C6E3C",
    "aans-cns_letter_re_cy_2020_medicare_opps_asc_proposed_rule_092719.pdf": "49CA9432-77EB-4ABC-A1D3-73C62459A36B",
    "aans-cns_letter_re_hr_4482_meadows-renacci_opioids.pdf": "6AB347B1-0B0B-4F6D-9FF5-A4C70A533835",
    "aans-cns_letter_re_medicare_opps_asc_proposed_rule_092418.pdf": "BCCC76A6-4104-41D9-B4DE-A905D6F46495",
    "aans-cns_letter_re_opioid_addiction_prevention_act_041817.pdf": "7AA66387-5E92-409B-A2D4-648EA7AF428A",
    "AANS-CNS_Letter_to_Aetna_re_22853_083023.pdf": "F0DADBE6-7E41-4020-B307-61F80CC31DF1",
    "aans-cns_letter_to_cassidy_bwg_on_surprise_bills_052019.pdf": "8791D1E2-FFC7-48F5-94BE-5D565741DC14",
    "aans-cns_letter_to_cdc_re_tbi_surveillance_080316.pdf": "553325E5-9FBC-404D-92C1-8D816F732F9C",
    "aans-cns_letter_to_cigna_on_medical_coverage_policy_0527_for_cervical_fusion_051518.pdf": "64206F71-AE0F-4B96-A2BC-38462F6D0AD2",
    "aans-cns_letter_to_cms_on_pild_procedure_051316.pdf": "141E3477-2328-436F-AEF7-1D9498B1792A",
    "aans-cns_letter_to_cms_on_pild_procedure_100616.pdf": "76510947-5952-44D3-9811-303F135AE7F8",
    "aans-cns_letter_to_cms_regarding_part_d_opioid_proposals_030518.pdf": "D8A0DDD0-CA36-4D86-8446-D9D77F1AF970",
    "aans-cns_letter_to_dr_rosen_re_spine_cci_edits_020315.pdf": "E662EBB5-B870-46AE-AF75-E9FBC5748548",
    "aans-cns_letter_to_fda_on_classification_of_posterior_cervical_screws_060816.pdf": "EA584150-6722-45AC-B9B4-6345E09E663D",
    "aans-cns_letter_to_fda_on_guidance_to_industry_on_ide_studies_060616.pdf": "2A31EFAB-C564-4A12-8FFC-B0B8C84A6A56",
    "aans-cns_letter_to_fda_re_de_novo_reg_030719.pdf": "A0E6983C-45A5-4768-BBEF-0EA05685732D",
    "aans-cns_letter_to_fda_re_medical_device_action_plan_081618.pdf": "5ED56063-70BA-4964-9984-E92CCD0405A8",
    "aans-cns_letter_to_fda_re_regulatory_reform_020518.pdf": "74D3D815-B634-4B06-9C86-FBFF4ED0B8F4",
    "aans-cns_letter_to_health_tax_taskforce_061419.pdf": "86560A8E-544C-444B-A8F0-3C63F1DFFEB9",
    "aans-cns_letter_to_icer_042817.pdf": "64D60D1B-C135-466D-91D2-7BB22A1CD325",
    "aans-cns_letter_to_nass_regarding_interspinous_devices_040819.pdf": "93DA91D5-5CA7-4D41-B132-4F29698FA242",
    "aans-cns_letter_to_ngs_medicare_for_mrgfus_for_et_111617.pdf": "8B2E8A45-DB91-4FFF-8A3E-0C9FBDC7005E",
    "aans-cns_letter_to_sen._hatch_052217.pdf": "5B645E1A-6C0B-4310-BE3D-3C5DC98E672E",
    "aans-cns_letter_to_sen._rubio_re_spinal_cord_injury_awareness_month_092717.pdf": "E1D55B53-C68E-4494-AD49-6F9BE2829F61",
    "aans-cns_letter_to_sens._alexander_and_murray_102317.pdf": "AAB21A2F-BD18-4899-927F-E7C3ECB5539F",
    "aans-cns_letter_to_sens._hatch_and_wyden_re_opioid_use_021618.pdf": "3C9EAAE1-A105-42E1-89C9-0791B32C62BA",
    "aans-cns_letter_to_wm_re_medical_device_tax_060115.pdf": "C48A1E28-8D8A-4442-A15D-1FEF75B81529",
    "aans-cns_macra_final_rule_comment_letter.12.19.16.pdf": "76A16851-36D5-4426-98E7-053F41F64E6B",
    "aans-cns_macra_proposed_rule_comments_final_062716.pdf": "3B622DB4-D0EE-48A5-B216-C7301FFA0977",
    "AANS-CNS_MFSComments9412.pdf": "7C236F98-4F20-42AA-9BD8-745A801EF6E2",
    "aans-cns_opps_comments_090516.pdf": "29B57E16-29C3-46E3-A983-6D1F4E2877F7",
    "aans-cns_position_statement_on_hydrocodone.100113.pdf": "8AA32BD0-8CA8-46EB-805F-5E9FDD3E022C",
    "aans-cns_position_statement_on_motorcycle_helmets_final_050717.pdf": "225CA132-CD34-4FB3-83F9-12B86CB1FEAB",
    "aans-cns_position_statement_on_motorcycle_helmets_final_050717_0.pdf": "62739A78-218F-42DF-930E-06B807A2C384",
    "aans-cns_position_statement_on_stroke_center_volumes_6-19.pdf": "814DE91D-9D08-4DD3-A4B0-4CE3B0018FA6",
    "aans-cns_position_statement_on_team_based_care_in_neurosurgery_final.pdf": "6D9E004A-35F4-496B-AE8F-81D22F7307D4",
    "aans-cns_position_statement_on_team_based_care_in_neurosurgery_final_0.pdf": "E7FEB4E5-1D59-4B6D-AB1E-0D2D964587BC",
    "aans-cns_protecting_access_to_care_act_release_final_062817.pdf": "14F5A3D5-85B5-44EC-BB6E-ADE7E26FD49A",
    "aans-cns_qpp_comment_letter_082117.pdf": "84A47AB9-0F2D-4055-BF68-FB323B4AC1F3",
    "aans-cns_qpp_comments_01.01.18.pdf": "6711C177-C198-4BB6-B76B-21F2A34DB776",
    "aans-cns_release_prior_authorization.pdf": "A56F1A5C-8B0E-419B-8296-CA761D2662A8",
    "aans-cns_release_prior_authorization_large_group_letter_091019_004.pdf": "F770EA63-4407-4746-8FB3-49A96111A1D3",
    "aans-cns_release_re_lower_health_care_costs_act_062019 (1).pdf": "93B9A1C4-8B79-49C9-A282-1CDEACAFE382",
    "aans-cns_release_re_lower_health_care_costs_act_062019.pdf": "8D37DB73-8D40-453B-957C-FA051EBD6FC9",
    "aans-cns_release_re_medicare_auc_for_diagnostic_imaging_program_090718.pdf": "CD42EB7E-B498-4D2F-A1BB-11B7F5FA2E29",
    "aans-cns_release_re_ruiz-roe_surprise_medical_bills_legislation_052319.pdf": "BA3F4BA4-DEF0-41A2-B05F-165BA693FD12",
    "aans-cns_request_for_brain_aneurysm_funding_house_011117.pdf": "640E6A8F-971B-4A93-BF39-CC375C3D56FD",
    "aans-cns_request_for_brain_aneurysm_funding_senate_011117.pdf": "94BC063E-16D7-41D3-9E5D-A4D02EE31216",
    "AANS-CNS_SpineNwsltr_2019_Fall_vB.pdf": "C25A6835-E3B4-4A8A-8B13-7C8A4117BD7E",
    "AANS-CNS_SpineNwsltr_2019_Spring_vB.pdf": "97503BDA-8616-4B19-8915-5DDAE8F11020",
    "AANS-CNS_SpineNwsltr_2020_Fall.pdf": "ADFF22F8-E0E4-406C-9216-57015174C024",
    "AANS-CNS_SpineNwsltr_2020_Spring.pdf": "FC00919E-0228-44D6-86DB-839F7B2B6428",
    "AANS-CNS_SpineNwsltr_2021_Fall_vB_PROOF.pdf": "16367890-CCC9-4B50-A733-1CBE21270281",
    "AANS-CNS_SpineNwsltr_2021_Spring_vC.pdf": "FF13E96D-6BC2-4D3B-A091-DB58E9AC093A",
    "aans-cns_statement_for_the_record_protecting_patients_from_surprise_medical_bills_060419.pdf": "7062A917-965D-4CF7-99D5-6C179FFFAA79",
    "aans-cns_statement_for_the_record_small_business_committee_utilization_review_hearing_091119.pdf": "CF653440-FC8F-420B-B38B-8F1E66AFA443",
    "aans-cns_statement_for_trauma_appropriations_04.29.15.pdf": "75BE1295-6D4F-413E-A690-CBD611951FC4",
    "aans-cns_statement_on_firearm_safety.5.13.pdf": "EF03A19A-3FAE-49E2-8C39-EE198EBCD099",
    "aans-cns_support_of_medicare_patient_empowerment_act_110117.pdf": "6288D18B-C883-4F4B-A099-7BAFEEA4B523",
    "aans-cns_telemedicine_position_statement-2017_final.pdf": "11C17475-513C-4A8E-92B7-937A7CB192DD",
    "aans-cns_telemedicine_position_statement-2017_final_0.pdf": "232A1268-438F-4187-87BD-9E76293AE564",
    "aans-cns_testimony_on_good_samaritan_health_professionals_act_w-appedix_5.17.17.pdf": "690B8F02-4B38-47D4-A1E9-7DF05F2DE917",
    "aans-cns_trauma_briefing_release.pdf": "4000A4FB-B0A3-4E8A-BAE2-20139820CDBB",
    "aans-cns_ways_and_means_committee_release_on_device_tax_6.2.15.pdf": "87500D23-121A-4C31-A6F9-C40E26E10FC3",
    "aans-cns_wm_provider_feedback_submission_form-auc_for_imaging.pdf": "0BB528A1-8571-4D6D-94C2-FBB80A48F0C3",
    "aans-cns_wm_provider_feedback_submission_form-global_surgery_data_collection.pdf": "4B5C13A3-3414-4045-AD5F-2F926043A273",
    "aans-cns_wm_provider_feedback_submission_form-macra.pdf": "FC9FC441-E1E5-42D4-BFF9-69E72D2E1DCF",
    "aans-cns_wsans_spine_section_wa_hca_hta_letter_re_selection_of_artificial_disc_for_re-review_051916.pdf": "E87E2E0B-0D3E-4E0D-AA4A-58FBE5F47AC3",
    "AANS-CNS2013LegislativeAgenda_4.pdf": "7BD0B2E8-61AF-440F-AFA5-818686EE02EC",
    "AANS-CNS2014LegislativeAgenda_4.pdf": "87DB817C-035F-4395-9217-2B3B3CB071F6",
    "AANS-CNS2014MedicareFeeScheduleCommentsQualityPrograms9613.pdf": "EF37D772-C9F5-4162-9592-7B41497F0284",
    "AANS-CNS2014MedicareFeeScheduleCommentsRegistryProgram9613.pdf": "96D20ECB-FF8B-4B05-95D9-B9F29EE05EBD",
    "AANS-CNS2014MedicareFeeScheduleCommentsReimbursementIssues9613.pdf": "E998E753-3FBB-46CA-A84D-E5408766ACC1",
    "AANS-CNS2014MedicareHospitalOutpatientComments9613.pdf": "7B57406E-0D3B-4035-9763-7A60514FD712",
    "AANS-CNS2015MedicareHospitalIPPSProposedRuleCommentLetter63014FINAL.pdf": "7CDAE24B-DA89-466A-8EF2-3B296B623E83",
    "aans-cns2015medicareoppsasccomments090214.pdf": "AE075132-2A40-46B6-9A19-5DB6CA8A3DAB",
    "aans-cns2015medicarephysicianfeeschedule-openpaymentcomments0902.pdf": "39096233-4E0F-43A1-B53A-FA64F03D7779",
    "aans-cns2015medicarephysicianfeeschedulecommentspayment082814.pdf": "DF3B4462-F7D3-43BC-887B-AAAA277137DE",
    "aans-cns2015medicarephysicianfeeschedulecommentspayment082814_0.pdf": "7548CA56-FA2B-46EA-9DC9-7AC11454EEA6",
    "aans-cns2015medicarephysicianfeeschedulecommentsquality082614.pdf": "1378B700-E474-4E38-B25E-F10238C57F4B",
    "aans-cns2015senatefinancetransparencyletter080814.pdf": "EF5E3053-FFC0-4D54-8315-7DD2EED8B48F",
    "AANS-CNSCCIEditLetter22630-22633with63042FINAL.pdf": "6006B88D-6861-426E-90C7-97CAB78CB02E",
    "AANS-CNSCommentsMichiganBCBSSpineFusion5-1-12-FINAL.pdf": "37D82E8B-6905-4E09-8E33-62244F09C564",
    "AANS-CNSCommentsonOIGProposal071114.pdf": "64E77BF2-12D0-4CF5-94EE-5D042E205D21",
    "AANS-CNSCommentsToHCAreCAS121112.pdf": "865960FC-6ED2-45E3-B791-0941DBCF5BE7",
    "AANS-CNSCommentsToHITPConStage3MU11413.pdf": "0996AC71-23BF-4663-B39F-9AFA1DF09AFC",
    "AANS-CNSCommentsWashStateHCABMP5-1-12.pdf": "45CFE140-114B-468C-A86C-C884E7AB1539",
    "AANS-CNSEndorsementofS961052213.pdf": "F85F31F7-F74A-468D-AE55-06945F89210D",
    "AANS-CNSEndorsementofS961052213_0.pdf": "86D49B6F-DE3F-496C-B5B0-C17499D07F4F",
    "AANS-CNSFDACervicalScrewComments82812FINAL.pdf": "2AEA55CC-6464-418D-B17A-E82135D67A16",
    "AANS-CNSFiguresforIMSIIIMRRESCUEandSYNTHESISResponseMarch2013.pdf": "544F6029-33F3-4981-A929-F030E50C2F49",
    "AANS-CNSHealthcareReformGrassrootsAlert10-09.pdf": "0909518E-B384-4AB6-801B-D256EAFBB7EC",
    "AANS-CNSHealthReformPressRelease-HR32007-17-09.pdf": "F0B98279-EFD9-4F84-838F-3AACC6C849CD",
    "AANS-CNSHealthReformPressRelease-HR34008-4-09.pdf": "3F701E29-6F60-492D-8B1A-C53C26E9FB47",
    "AANS-CNSIPPSCommentLetter62513.pdf": "A3456C36-AEF5-49F2-8666-1C10C0643BC3",
    "AANS-CNSIPPSCommentLetter62513_0.pdf": "A92B4368-812F-4305-B0F5-83ADB89C36C2",
    "AANS-CNSLetterFDAReReclassificationofPedicleScrewSystems052213.pdf": "74BA44BD-D53C-4FA4-8BD6-5177872B53B7",
    "AANS-CNSLetterofSupportforBrainAneurysmAwarenessMonth071714-Senate.pdf": "EDBB6DE0-C8F8-405C-B662-9C5D9B6A3AE5",
    "AANS-CNSLetterofSupportforBrainAneurysmAwarenessMonth071714.pdf": "A296B267-AC03-418F-98E2-FC5F1D6EBE51",
    "AANS-CNSLetterofSupportforHouseTraumaReauthorization022414.pdf": "3D962A33-C862-4498-97DA-A090E3A8D7A5",
    "AANS-CNSLetterofSupportforHR1733FINAL5613.pdf": "491837CB-9645-408C-8B85-E27A820C6F90",
    "AANS-CNSletterofsupportforHR36FINAL3513.pdf": "BCF56F89-984B-4D7A-BB58-24C7B1ADF654",
    "AANS-CNSletterofsupportforHR36FINAL3513_0.pdf": "6A7865E6-6150-48A2-BE4D-9515B9FAB8A8",
    "AANS-CNSLetterofSupportforSenateTraumaReauthorization.pdf": "2C81B619-54EB-4F48-B554-1727AE46EB60",
    "AANS-CNSLetterOfSupportHR3144112612.pdf": "532FE9E8-597E-4B8D-B2A1-45DBD10AF353",
    "AANS-CNSLettertoACSreHealthcareReform9-09.pdf": "F81778DC-9E1C-43FC-A471-C3E35E06FB96",
    "AANS-CNSLettertoAMAreHealthcareReform9-09.pdf": "70A1FB6E-969F-47FE-8513-3A18BBAA52CF",
    "AANS-CNSLettertoAmericanCollegeofSurgeonsreHealthcareReform8-7-09.pdf": "41F5B03A-5C27-471B-A427-7B0F626C4337",
    "AANS-CNSLettertoAmericanMedicalAssociationreHealthcareReform8-7-09.pdf": "F0310CF3-3E0C-4B92-968A-ABC2B1D13533",
    "AANS-CNSLettertoCMSonPILDProcedureFINAL111213.pdf": "ECA35497-6837-4E5D-9E91-F0304B1E6571",
    "AANS-CNSLettertoCMSRegardingSunshineAct060214.pdf": "076BD0B8-4C05-4353-AD68-F1F5FBB0E878",
    "AANS-CNSLettertoCongressreStereotacticRadiosurgery3413.pdf": "B9DD8772-A25F-4A8B-94E7-51A54F4ABF5D",
    "AANS-CNSLettertoDEAreHydrocodonePrescribingPolicy042314.pdf": "B5652E1E-CBFE-4C87-9CED-A9E02322BD9D",
    "AANS-CNSLettertoNoridianonSRSSBRT6252014.pdf": "0D16B274-E7B5-41B5-A115-A204CCA331AF",
    "AANS-CNSLettertoNoridianreCervicalSpineArthroplasty062014.pdf": "C27032F9-C9AE-4940-BD43-116A40798043",
    "AANS-CNSLetterToSenCoburnReSeniorsChoiceAct6412.pdf": "277A1C7D-AC49-462F-9430-5FDF3008664D",
    "AANS-CNSLetterToSenCoburnReSeniorsChoiceAct6412_0.pdf": "C1FF72F3-9D54-4141-9072-E5BD872DBBEF",
    "AANS-CNSLettertoWashingtonStateHCAHTARegardingLumbarSpinalFusi.pdf": "871A3D79-8708-459D-A232-D1D8B15E4926",
    "AANS-CNSLetterToWashStateOnBMPForSpineFusion13012.pdf": "7A36B408-E164-4CFD-BE04-E8ED8F3B4C56",
    "AANS-CNSMeaningfulUseComments5712.pdf": "65F13599-8C6F-466F-8AEB-929181DFFFE6",
    "AANS-CNSMedicarePhysicianFeeScheduleComments123112.pdf": "38BB51E5-608D-4A99-AF2F-0272403E3B46",
    "AANS-CNSPositionStatementonHydrocodone100113.pdf": "91B75FF0-FD6C-4EF1-AD47-E2902DC55979",
    "AANS-CNSResponsetoCMSRFIonRegistriesandQualityReporting4813.pdf": "44285F78-1BC6-453A-BCFC-0BFD3766435E",
    "AANS-CNSResponsetoIMSIIIMRRESCUEandSYNTHESISThrombectomyTrialsMarch2013.pdf": "0E6B2106-01FA-4669-B7D7-08941FF2A51D",
    "AANS-CNSSGRRepealEndorsementLetter021814.pdf": "9B6E41DC-3AC5-4AF2-8C7A-7CCF2A70EE98",
    "AANS-CNSStatementtoFDANeurologicalDevicesPanel21313.pdf": "CB0DF5F2-AEC5-477D-BA23-F1CF1317BC11",
    "AANS-CNSUDICommentletter11712.pdf": "A1B366C5-D0BC-4706-8661-2EE5F94C7083",
    "AANS-CNSWaysAndMeansSGRComments52512.pdf": "1C10FDF4-A153-43CE-99F2-F435BD072330",
    "AANS-CNSWaysAndMeansSGRComments52512_0.pdf": "3D94C95D-5C21-461B-B44C-2792394C0607",
    "aans.cns_comments_on_21cca_ehr_reporting_program_rfi.10.17.18.pdf": "B7104D42-27BD-4849-85F1-1239F16D3EBF",
    "aans.cns_comments_on_onc_draft_ehr_burden_reduction_strategy.01.28.19.pdf": "6F43F444-506A-427D-A224-4D5096A29809",
    "aans_and_cns_release_2015_legislative_agenda_2.0.pdf": "BDDCC269-9A51-4224-9AC2-4B72011340F6",
    "aans_and_cns_release_2017_legislative_agenda_2.14.17.pdf": "B37CA3CC-26EA-46AF-91F4-3643EF14BB07",
    "aans_and_cns_release_2018_legislative_agenda_1.23.18.pdf": "1C4A4B79-3B77-4054-A297-B317DBEA0EA0",
    "aans_and_cns_release_2019_legislative_agenda.pdf": "DF191A3D-4A13-463A-8948-CE35E7601617",
    "AANS_CNS Joint Guidelines Committee COI.pdf": "F337DCD5-A28E-4397-9DF1-7A8334B52D3F",
    "aans_cns_2019_medicare_physician_fee_schedule_proposed_rule_comments.pdf": "8377D7DB-FEE3-4C22-A476-0C4C7236E360",
    "AANS_CNS_2020_Figure.pdf": "BC8593A8-32E4-4F8F-9A34-D443511EDDC2",
    "aans_cns_2020_medicare_hospital_ipps_proposed_rule_comments_062419.pdf": "40D3936B-3FE8-4687-91F4-2290FB6A181D",
    "aans_cns_aes_letter_to_total_health_care_in_michigan_072716.pdf": "AF790E2B-8DC1-4C31-AB9D-1A0C408D815F",
    "aans_cns_assfn_letter_to_cms_re_nca_for_vns_for_trd_121918.pdf": "89F2A254-ACE8-4229-9E0D-9F6003E0B0DC",
    "aans_cns_assfn_letter_to_novitas_on_mrgfus_with_position_statement_6-3-19.pdf": "B68D69E0-511B-4EE4-8020-95892F113B0D",
    "aans_cns_assfn_wsans_letter_to_wa_hca_hta_on_vns_re-review_03272019.pdf": "80545FF7-8806-4047-ADCB-748DDF035EDD",
    "aans_cns_dspn_letter_on_nass_draft_coverage_policy_for_lumbar_discectomy_final_04022019.pdf": "55EF9E88-A9A0-48AE-907B-81B189908116",
    "aans_cns_dspn_letter_to_cgs_on_vertebral_augmentation_lcd_38201_072519.pdf": "0031DFFC-5FF8-474B-AA86-18A0712295AD",
    "aans_cns_dspn_letter_to_nordian_on_vertebral_augmentation_lcd_071919.pdf": "D5A24DE3-A30A-48FA-8964-4CD20276F446",
    "aans_cns_dspn_nass_aaos_wsans_letter_to_wa_hca_hta_on_si_joint_fusion_evidence_report_november_9_2018.pdf": "3C50135D-66B2-43D2-A733-66E08ABF0679",
    "aans_cns_dspn_nass_aaos_wsans_letter_to_wa_hca_hta_on_si_joint_fusion_evidence_report_november_9_2018_0.pdf": "8F3C60DB-493A-4836-94B0-2C23FAB4F435",
    "aans_cns_dspn_pain_section_letter_to_cms_on_acupuncture_08.14.2019.pdf": "5144F4C2-1E8B-4B54-8D05-AD71C787F54A",
    "aans_cns_dspn_wsans_letter_to_wa_hca_hta_on_si_joint_fusion_noncoverage_02202019.pdf": "D3E04D52-EBAE-491E-B5ED-A6394BA26A87",
    "aans_cns_letter_to_aetna_on_lumbar_interbody_fusions_with_expandable_cages.pdf": "6057A225-DDD8-4D82-A914-DF81E5BBF6E7",
    "aans_cns_letter_to_fda_on_guidance_for_brain_metastases.pdf": "E6335060-26E4-43C2-A593-FFE1EFCED1FA",
    "aans_cns_letter_to_nass_on_endoscopic_spinal_surgery_090618.pdf": "80209BC1-17D7-4E93-807C-3F50091C7A82",
    "aans_cns_letter_to_nass_on_lumbar_artificial_disc_replacement_090618.pdf": "1BFEBF96-A3D2-4622-911C-A111FAD5EC86",
    "aans_cns_neurosurgeons_critical_care_policy_final.pdf": "7348940C-66BB-4E13-B19D-3A66A277CA0F",
    "AANS_CNS_Peds Poster_FINAL.pdf": "DC383F03-7D04-41C1-8E4C-57EE70BE71CE",
    "aans_cns_wsans_letter_supporting_drs._leveque_and_chowdhary_for_htcc_060116.pdf": "A5B203E7-FE3C-4680-8D0B-D6847D52E426",
    "AANS_CNS2019_Morisaki.pdf": "B3BF75F4-A07F-4210-9DD0-193BFE9EBA47",
    "aans_lte_nyt_re_spine_surgery_080816.pdf": "9816F551-C958-4228-87C8-533711906582",
    "AANS2015.pdf": "1CC6013E-D8D0-40BC-9E42-507ADAE18516",
    "AANSCNS Appropriations Letter 91218.pdf": "64827ED1-F853-4055-B8FF-45E2A518A73F",
    "AANSCNS Letter of Support  for HR 280 61719.pdf": "129E99F7-2985-4565-8120-CE11D9D9FDBC",
    "aanscns0215_codingpolicyman.pdf": "B0FCBE37-7D42-435C-9320-FDE8A599928F",
    "AANSCNSLettertoCMSonPhysicianCompare011212.pdf": "D6B5ED7E-1EF7-4868-B22E-7580CF58C83E",
    "AANSCNSWashingtonStateHTACervicalFusionKeyQuestions10512.pdf": "D0971804-9B9D-47F5-A4C4-E77CE370D3A5",
    "AANSPDF.pdf": "3DFA0DC3-10F3-4C1A-B884-0072FE6E6D6E",
    "AANSPeds2023_Animal Models Ictal Fingerprints_v1.pdf": "D9ACABF7-2FB1-4697-8E21-EDE99F824B56",
    "aaos-aans-cns-dspn-wsans-isass-nass_cover_letter_re_key_questions_112717.pdf": "58C66C06-7D54-4A31-A86C-499162D5CC0D",
    "AAP Poster.pdf": "72C15D8E-669F-490D-85F3-61800D112050",
    "aarabi2013.pdf": "4CFF931B-7830-4686-B43A-6CE7C71DDFCA",
    "Aaron Gustin-Fellowship.pdf": "580ADF16-9438-4F0F-A8E4-73A38F1BB72E",
    "Aaron R. Burket .pdf": "236CFCC8-3008-41D4-B3F2-5A45A7811E2F",
    "Aaron Wessell (Neurosurgery) CV Mar 2022 (Mo Yr format) AANS & CNS.pdf": "08D65364-0FFD-410D-8A84-D3119FC4542D",
    "Aaron Wessell Residency Diploma.pdf": "E30CE2B3-CCB7-4BFF-986F-0711767A6435",
    "Aaron Yengo-Kahn-FellowVerif.pdf": "DFE1BAFF-5B16-40D9-A3ED-A7981C4F5B12",
    "Aaronson - good standing.PDF": "FE359C78-E214-4F9C-9793-83BC32DE27C7",
    "AAtik.RES_Fellow.Verifi.pdf": "B7A504FE-4559-441A-94A3-22A1AC9DFD5B",
    "Abamoussa.pdf": "7056E48D-DE48-4599-BB5A-55AE154F0E75",
    "ABarlett.pdf": "A354769C-55E9-42FA-BD17-26FAF167F5C3",
    "ABartlett_CV_4.13.23.pdf": "A342DE50-E591-4E17-A6DF-80A2FC28E3C7",
    "Abbasifard.pdf": "6121BC7B-3F74-4DD4-B173-F4E4A1040F7A",
    "AbdelgadirJ 3-2021.pdf": "50AE3C8A-17B7-4338-B15D-46735A93B76F",
    "Abdelmageed_Sunny_GS-letter.pdf": "B8A9972D-E503-4756-93BA-2B63DA03956C",
    "Abdi Letter of Good Standing 1.pdf": "B120FF3A-12B5-4B81-9E18-34F7BB326795",
    "Abdul Kader Tabbara, MD.pdf": "716ACE43-9473-44EE-8463-E0D004235F67",
    "Abdul Kassab-FellowshipVerif.pdf": "56A4EC4A-89CC-4AE8-9A50-447526E6C4FA",
    "Abdul Presentation Poster.pdf": "F9BDC646-2D12-40F0-A476-E843C0C5FD74",
    "Abdul-Kareem Ahmed_CV2022.pdf": "6890D12A-5EA0-4D35-B7EC-2C5F0D5C36B8",
    "Abdul-Rahman Toufik0001.PDF": "D444C558-263E-4314-8EAD-4CB1198D7917",
    "AbdulAhmed_LOR.pdf": "A31ED40C-A402-422F-A7C6-2584D3575CEC",
    "Abdulaziz Abobotain-emailVerif.pdf": "4193A103-4408-474B-ACA2-15F3737C8598",
    "Abdulaziz Abobotain-FellowVerif.pdf": "9BEEFB44-F8E0-4CFF-A656-B6BE3F6B1B27",
    "Abdulghani Al-Aswadi MBBS certificate degree.pdf": "9C6099E5-0817-47C4-B495-597B4D5B3EA2",
    "Abdulhakeem Alghamdi.pdf": "D5440CDA-2BA0-480E-8981-36825F639E84",
    "Abdullah alsaib lebenslauf 2022.pdf": "95C5C509-4A03-4B09-986B-7BCCC79F22EC",
    "Abdullah Bin Zahid CV.pdf": "06E34F21-D20B-4DE6-83E7-8B504B1422A7",
    "Abdullah Feroze-2ndFellowship.pdf": "CA7AF330-CE1D-4753-AAF2-9C69964261E4",
    "Abdulrhman_Poster.pdf": "58EC43DB-7616-404F-A229-66E7269A319E",
    "Abecassis NSTP FINAL.pdf": "A7C1D249-DF0F-4DD3-84B1-42C8D418A730",
    "Abecassis%2c Zachary.pdf": "E9DD08E6-5D72-4DE5-85FD-EB34BB2F5651",
    "Abel Gizaw last Nov 2023 CV.pdf": "26BBCEDA-EFDD-441A-80E4-18CEF21C322B",
    "Abhi Jain.pdf": "A5AC23A7-4857-4465-8617-7C2C0058982D",
    "Abhidha Shah CV -current.pdf": "0CE61191-F8AF-4B64-B4C0-DBAD7EDC0D1D",
    "Abhijeet S. 2024 ASSFN Poster Final.pdf": "E01372BC-6CC4-4894-A2E1-0C96AA36A49A",
    "Abhyudaya Mehta_CV_CNS student subcommittee.pdf": "EFEA31D3-A60D-48DA-AF2D-7B5D4585041A",
    "Abigail Awode A00759049 Promotion Letter.pdf": "F0B54C7E-BE3A-438D-98B7-ED5C9AE9D065",
    "ABKCV.pdf": "DE90E91D-6218-43EA-B7C0-08C7ED922E82",
    "ABNC Member.pdf": "07AABEAA-3DD7-44AD-8452-C23F98597FA1",
    "ABNc.pdf": "132B3801-3EC0-4D4C-A523-99F9894AC556",
    "Abnormal Curves and Pitfalls.pdf": "CFF1E183-5A19-4B92-8FAA-0A627D90AC8F",
    "ABNS Certificate  ex. 12-2031.pdf": "496EDE07-8741-439C-8900-F20E49609164",
    "ABNS letter.pdf": "A815BF3E-ABDD-4F5B-9A01-D0B0E1FDDF2C",
    "ABNS written exam policy.pdf": "D3AAB49A-230C-487B-8A59-0E75C1FAFC95",
    "abns_letter_JFM.pdf": "B29EED29-988A-4F8E-B38B-4DCE59DA952E",
    "Abraham Schlauderaff-RESverif.pdf": "270D5578-77C7-46D5-8A7D-39E471B9F61D",
    "ABruzek.verif.pdf": "59404485-A2CA-4217-9466-C065F65BB67A",
    "Abstract 030178 Figures.pdf": "CF1037B2-0BB5-47F3-917E-3388805D9649",
    "Abstract 038044 PDF.pdf": "FA99ED95-3871-4510-896F-9316580DF157",
    "Abstract 2160_Deniz Kiliç.pdf": "F4FE0D9E-CF25-4589-81CA-F510CAD6F823",
    "Abstract 492_Timucin Avsar.pdf": "5789684C-78F2-4FF9-B561-26C7149F88A6",
    "Abstract 594 Ackerman Ash Burroughs Excess Postoperative Opioid Prescribing in Pediatric Neurosurgery and Follow-Up Prescribing Intervention.pdf": "2ECFC099-BC5C-4EFA-A615-B0A09353189C",
    "ABSTRACT AAcNS2021.pdf": "1B6F7DBC-F8CB-4202-BE9C-905FB5EF139D",
    "abstract figure.pdf": "9EAC687C-C647-4D3E-BDBE-6ED44FEAAF7C",
    "Abstract ID 037669_Drapeau_Decrease blood transfusion poster.pdf": "A9296D1B-2746-4935-9F77-FC31D09A247E",
    "Abstract Imaging.pdf": "357EB028-C8AD-4EFC-B995-C66EA83D5CCB",
    "Abstract Number 2322.pdf": "91CE84D6-1D13-4C2D-81E2-2F138FBA3E8A",
    "Abstract Presentation.pdf": "F0134BA0-8AB6-4888-9B12-6F94FC77C85C",
    "Abstract Submission Test.pdf": "A31B76AD-7B76-42C3-82AE-FC9B8A6BAB24",
    "Abstract Table.pdf": "D2B217F3-02A3-4ADE-916D-90CB2523E32E",
    "Abstract.pdf": "D37EBCFD-F9AD-43AA-B124-EFC7CD4ADD37",
    "Abstract_036917_CPT_EMBO_Final_Draft.pdf": "5CA32232-9BA7-4985-9354-EDAC11B27459",
    "Abstract_Digital_Poster_Final.pdf": "16FC4EFD-32DA-4B47-80F2-F39A4A9A9D9F",
    "Abstract_fig.pdf": "FBB4A4B5-5058-4EF0-8864-8E47E6BDA404",
    "abstract_figure1.pdf": "7E8AACAE-2702-471E-8D5E-4860549C1182",
    "abstract_figure2.pdf": "4005FBE8-2C7C-422D-B451-A28BEDBFC529",
    "Abstract_SRS Outcomes for Resected Brain Mets_ARS2019.pdf": "4299E227-C2AD-40C3-8802-780467636AAE",
    "AbstractFigure.pdf": "A35E7779-EFF2-450E-ABCA-0134FD9C0C69",
    "AbstractReviewScores (1).pdf": "BACBC158-3D11-4761-AF2A-9EAE12D63A9B",
    "AbstractShuipingDaiMD2021.pdf": "00968FEA-AEE5-4573-B94D-B1D625AD4E26",
    "ABTA Support Letter.pdf": "8614055A-69E0-4329-BC7B-5D7B5C0DE120",
    "ABugarini_GoodStandingForm.pdf": "9AB0727C-2963-4127-8750-064857FE2CDB",
    "AC and ICH.pdf": "49F11F8E-D059-48F9-9F36-C5EE6A403550",
    "AC CV.pdf": "7F2A944B-95F6-425D-AD9A-9CA1D2B6D460",
    "AC_06_Surgery_Service letter_ Dr RMGK Rathnayake.pdf": "998114CC-4EE0-4B0B-BB69-E99641175F11",
    "aca_scotus_release_6_25_15_4.pdf": "43BAC9DA-A938-4D15-9B5F-9D2C4223F834",
    "Academic CURRICULUM VITAE of Jeremy Kam Sept 2022.pdf": "A1DE30CA-0ED2-4C12-801B-39D61264DE90",
    "Academic Standing.pdf": "9F43F4EC-B214-4532-B1ED-394B571DDFB7",
    "Academic Transcipt.pdf": "E6DB0BA7-3BA8-4E98-94CB-7D17E462E542",
    "Academic Transcript unofficial .pdf": "4B5288F2-49D1-4318-A17B-B9CE5C744AC5",
    "Academic Transcript.pdf": "2BAC65A1-1EFC-4252-AD6E-FD71C6D74B50",
    "academic-transcript-97367-5902493.pdf": "EEDB06A2-C0D6-482A-80E4-B21E950E7341",
    "academic_verification.pdf": "2DEFA088-3551-4331-A861-BF9E1CC5CDD1",
    "AcademicCV_Coulthard.pdf": "9BFA35D6-345D-4733-BCFF-BF9FF4841DE7",
    "Academy AB AES 0820_Fig 3.pdf": "288DB3F6-A612-439A-A1FA-E5E171FB878D",
    "Academy Abstract 2020 - Ventricular Entry.pdf": "B3ECCC50-DD70-4662-966B-3279D083F40B",
    "Academy abstract 2021.pdf": "C34F3B4C-D862-491A-B312-6306F37D38DC",
    "Academy Abstract.pdf": "9DFA0FCB-4217-4CF8-B25D-C583C786600E",
    "Academy Activities.pdf": "8DD296B8-A5FC-4375-86EB-67B75328FCA7",
    "Academy Meeting Registration Form 2019.pdf": "D83A6980-2B95-445A-AE77-46C4BA041A7D",
    "Academy Neurosurgeon June 2019.pdf": "5217AAFB-70BA-43B4-8BF1-40C55CAD0E4D",
    "Academy Neurosurgeon September 2019.pdf": "B4EF5F11-A2B8-45FC-92D2-1D11ADB8FCD4",
    "Academy Program Book 2023 FINAL V3.pdf": "EF1B3499-AA73-490A-995D-CF94996478C2",
    "Academy.pdf": "50E44519-9F04-4C74-8662-443A6A6DA3F5",
    "Academy_M032_Paper_Estevez.pdf": "1FA163C6-CCA9-49CB-997B-B8E443CD6351",
    "AcademyTumorMeasurementsPDT.pdf": "FF4AF8EA-D273-4EE0-A723-1FD3DA751A65",
    "ACarrillo.verificationletter.pdf": "1A04A0F6-1D7D-4C03-8582-FD21056BC7FA",
    "ACC .pdf": "990B44D2-0630-4FDD-BCEF-07C84130726F",
    "Acceptance Letter - Chimaobi Ochulo.pdf": "FD33A92F-747A-49E2-A3B5-A32CC245D629",
    "Acceptance Letter LSOM .pdf": "5E229ADE-70B6-4C17-BC15-A53E31BBD662",
    "Acceptance letter.pdf": "1844E58B-B5C0-460E-AC8A-008CDF67073A",
    "Acceptance Packet-1.pdf": "D2750E6D-B0DB-4F37-8461-1797BE9A58BC",
    "AcceptanceNotice copy.pdf": "6C43A344-29AF-4A53-91A7-41D7141CC281",
    "Accepted_Letter_Dugan.pdf": "58DBEE23-48DB-4207-9D18-BF778317CCBF",
    "Access to MMC ePoster Peds Sxn 2023.pdf": "0803C6F6-905A-404F-9803-EB655D682A34",
    "access_support_to_hudson.pdf": "CADF27D4-FA37-40D6-9F98-58FC952AC63C",
    "Accommodations_poster_final.pdf": "33772BD3-B45D-4B8F-822A-503065159DD2",
    "AccuGyd.pdf": "854B264A-D127-4F08-8111-358FEE6F13A6",
    "ACDF Cost Predictors CNS 2019 Poster.pdf": "AE5C073C-6C5C-416E-9431-5BA180795234",
    "ACDF LOS Readmit CNS 2019 Poster.pdf": "B1534FCE-8369-41F2-8F22-12CE9390B818",
    "ACDF Time of Surg CNS 2019 Poster.pdf": "8DCB5BDC-659A-4EC1-A7A8-2113F14C7F1B",
    "ACDF+CPM results table.pdf": "F829E404-644C-445E-ACB0-AC8082D6846B",
    "ACDF_ACD_Readmission_AE_Final.pdf": "DFE9FD17-707D-421F-ACFC-66701A7A2547",
    "ACDF_ACD_Readmission_CNS_ID_029136.pdf": "9EBB7B9E-3F1A-4383-A792-48F0CEEE14FE",
    "ACDF_CMS letter_05.21.24.final.pdf": "A7B14773-A2A6-4A74-8EAC-5091F6463F16",
    "ACDFbreakdown.pdf": "6CA48D2F-2B2C-4382-967C-BAAB6E893CE9",
    "ACDFTables.pdf": "DF5CCD9C-970C-40BB-81D1-CBF777EB1682",
    "ace_kids_act_release_7.9.16.pdf": "A5CE390D-16BE-4477-ABEC-1FFCC8320A2A",
    "ACGMEClinicalCompetencyCommitteeGuidebook 9.17.pdf": "65833436-9AE0-4C5E-A03A-0D68149DFE76",
    "AChan-emailverification.pdf": "94CD2F70-6CE1-4665-B6F1-B0FB6D06C84C",
    "Achey_Rebecca_CNS Membership.pdf": "CA11289D-7F47-48C8-8B85-D5B883C3D4E3",
    "Achon CNS 2022 poster 2022-09-25.pdf": "9151081B-3E27-463A-AA12-1EC6F9358492",
    "Achondroplasia CNS poster.pdf": "EDCF3045-DF00-4ACD-8E56-E4B4E7AC678F",
    "Acknowledgements.pdf": "85BAED82-64C3-4AF7-9F56-415577F5F6E7",
    "ACNS Certificate_Dr. Raed Hassan Abu Jarir.pdf": "A72CD15E-D0A1-4E40-815A-BB439ED80590",
    "ACOM Enrollment Verification Letter.pdf": "60C0B90B-3EC9-4E4F-A28C-09B44AC1A574",
    "Acoustics NIS poster.pdf": "9EB522F5-099D-4D11-8A34-0609CFD1C483",
    "Acromeg Digital Abstract.pdf": "F634E87B-4A63-46B6-B67A-E93E5C139CF3",
    "ACS Consultation Article.pdf": "7CF914B4-A833-4FF1-8DF7-9D72454801D5",
    "ACTIVE DUTY MILITARY HOUSING FORM.pdf": "C33B68DB-CD7B-4B66-9AB6-F4651F702BAE",
    "Active Duty Orders - Puffer.pdf": "F6F0152C-E5CB-40E3-866A-CDF2741C2298",
    "Active Duty orders - Robbins.pdf": "B079ED2E-BA21-4F52-B533-2072D8819223",
    "Active Duty Orders for D. Thoms.pdf": "17E9B71A-5811-4DC0-AA96-4374AC06276B",
    "Active Duty Orders Jason Hall.pdf": "5C587423-8089-4B38-88E4-95F664CD9FCF",
    "Active Duty Service Obligation 2018.07.12 SIGNED.pdf": "612B6123-1F6A-46D4-824C-18F1335D0402",
    "Active Duty VER for B. Ruble.pdf": "539F46A1-FA9A-49F0-BD9F-CE1253340344",
    "Active Duty VER for Milton temporary until official VER in June.pdf": "D1288B50-E981-461E-A000-E2B979D20604",
    "Activites on own.pdf": "ADF859E2-0F60-407C-9A76-9BAFFBB4CEBA",
    "Acute Ischemic Stroke Transient Ischemic Attack in Early- vs. Late-Stage Cancer Risk factors Quality of Life Outcomes and Survival.pdf": "2F42B516-AD23-47DD-B3AD-C729343C1B72",
    "Acute PD DBS Poster v1.pdf": "BE1F9369-D243-4FBB-966F-717FE0044112",
    "ACW 2020 CNS abstract.pdf": "716DA2A6-EF47-4976-A35A-4712C6365D54",
    "ACW CV.pdf": "69CC13D1-F2A3-4550-AEDE-742C5CEBF94D",
    "ACWest_CNS Poster_2022.pdf": "93EF83AE-C5E1-4E64-AF76-2835C1E3CD7D",
    "Ad Hoc Group for Medical Research FY2024 Recommendation 3.13.23.pdf": "1FFB6823-35A6-4907-810F-A9BBD841E4D9",
    "Ad Hoc Group Infrastructure and Economic Recovery Letter_05.19.21_FINAL.pdf": "6EAD858D-DC03-40C3-BD59-14488EA3BA6A",
    "Ad Hoc Group Letter Urging Swift Passage of FY22 Appropriations_Final.pdf": "696C0D82-7AAA-49A8-B5EB-D7979ED76DC4",
    "AD orders.pdf": "04EC5B6B-4845-41C5-994B-AB9F60CA4A39",
    "Adalpe_Ver.pdf": "2D20E8D9-D1B1-4818-9D5C-39901FC7D1A7",
    "Adam Kundishora-FellowVerif.pdf": "A4A01AD9-3B0E-46EF-8679-386FA13DB702",
    "Adam Levy-Medschoo.gradform.pdf": "1FF3E263-7CDC-43AE-AF95-EDD48357F045",
    "Adam Lipson-Fellowship.pdf": "E1D51440-7601-421B-B44F-06B4B0D26B82",
    "Adam Olszewski-RESVerif.pdf": "4B02B88C-89BA-4133-A029-227D0BE58E94",
    "Adam Podet-RESverif.pdf": "837ACF4D-805E-4A2F-9F12-3720993ECF6D",
    "Adamczak_Biosketch July  2022 PDF-CV.pdf": "AEB93390-18A0-4DFF-B728-B9B6D3D57378",
    "Adamson Cory - CV 2020.pdf": "CB013A0D-9EFF-48E5-A14F-CBEE4A84C797",
    "Adamson2022.pdf": "7D1B4EFF-3105-4599-BB98-C4A5602E87B1",
    "ADB_ REF.pdf": "A2FF5FE2-1EAB-4568-9CF8-6CF9029FEBA5",
    "ADC_poster_Peds_section_2023.pdf": "87F780CF-0D8B-4917-9273-5BD5BE6211DA",
    "ADCDF_RE_CNS2023.pdf": "25050BBC-C92E-4141-A059-EB6EBF204C29",
    "addiction poster 20200604.pdf": "B0877995-6011-4D01-BA6B-DF3014C16BBE",
    "Addiction poster ASSFN v5.pdf": "9B327D0B-E66E-40A1-87FA-662CD7F1BFD2",
    "Adebayo-harvard.pdf": "578D369B-9B67-405D-8E10-C1E6C50472BD",
    "Adebayo.pdf": "E8FEEAC1-82FF-4B25-9E18-28E0B445E36B",
    "Adeel Ilyas-Fellowship.pdf": "71D2E0A4-7E29-4DA0-8C83-0F49FAAE4093",
    "Adeesya Gausper CNS Letter.pdf": "EAAC68A4-CBE7-4612-9974-494BC2D0FECC",
    "Adela Wu CNS Guidelines Letter.pdf": "E43657BD-2A69-4F5D-A6D6-03C15212D816",
    "AdelaWuCV 010522.pdf": "94848E8F-3881-4137-A199-ADF1F6B2EFBE",
    "AdelaWuCV 032322.pdf": "A3EE3CC1-CA09-46B7-B574-45F1228560CE",
    "AdelaWuCV.pdf": "6902A1C3-8B41-49E4-8993-AE27F8B9854D",
    "ADesloges CV.pdf": "40FA475D-758D-42A9-B22E-91EA6FEC1F92",
    "ADETUNMBI B. CV ACS.pdf": "F0DD1CE8-262B-4DAF-8A36-F3A13FC5B7BD",
    "Aditya Mittal Letter of Good Standing.pdf": "EB37E5CE-1DEB-43BC-A5C0-2A1A42E5DA05",
    "AdityaVedantam_CV.pdf": "A9D36F38-3281-4964-A421-ACE0411B634E",
    "adj.pdf": "C53EECAD-1484-4334-8003-658C7C2CEF22",
    "Adkinson Poster.pdf": "4EB0CD02-F953-471B-9D68-EEF32DE1EF07",
    "admission letter abdifatah (1).pdf": "76599AEC-C9DE-447D-BE5A-E0DAD241D8D2",
    "Admission Letter.pdf": "D0B581E9-4478-4AEF-8CD6-10F98AA48620",
    "admission-letter.pdf": "5AE46AE2-F4CC-4C01-9796-2D0E307BEC90",
    "Admissions Letter.pdf": "0540DAD4-893C-4568-A935-A2BB60789D3C",
    "Admissions Update-Nicholas Cassimatis.pdf": "F40425CB-DADB-4567-A51D-154F860CDFA3",
    "Admissions_and_Student_Affairs_WC7530@tulane.edu.pdf": "6315F212-E534-489B-98B0-51889E2EF848",
    "Admit Status in Spine Tumor - Table 1.pdf": "9C6B5683-F57A-4734-A12E-15C7DA4F1C8D",
    "Admit Status in Spine Tumor - Table 2.pdf": "AAA398AC-4500-4546-9254-12A5A5EA0104",
    "Admit Status in Spine Tumor - Table 3.pdf": "C6CE33DD-A6A4-44AE-A88F-48154E4CC058",
    "adnan CV feb 2022.pdf": "C20C1DC3-D6F8-4FC7-BF37-5EA75FF14DF8",
    "adnan CV.pdf": "C92E4AD2-CF41-419D-A161-F56D801D17A5",
    "Adnan Qasim CV.pdf": "1E6A8671-DBC5-4175-ACE3-4D7EEE4CB4A4",
    "Adobe Scan 08-Feb-2024.pdf": "F2F6FB18-DB13-4080-83D3-8A2FBE0BE51B",
    "Adobe Scan 16-Aug-2023.pdf": "0BBD239D-DB86-4DAE-97DA-131CC7413FBB",
    "Adobe Scan 17 Apr 2023.pdf": "8E02888B-2041-4D5A-A663-F451ABB3BA68",
    "Adobe Scan 2022?12?4?.pdf": "7CF1A09F-054E-4AA4-A208-2A5A5DD0E731",
    "Adobe Scan 21-Apr-2022 1.pdf": "D2B4F3F7-87CE-4B1C-9C17-218862C262E9",
    "Adobe Scan 7 Mar 2022.pdf": "5DEA9BE6-B7BC-4725-B38F-B4060213FB22",
    "Adobe Scan Jun 21 2021 (6).pdf": "EC111780-EED7-4DB0-BC85-683310E27B6F",
    "Adomas Bunevicius's Employment Verification Letter 2019.pdf": "A563AAE2-03D1-40D6-B9BF-9C74CDCB811A",
    "ADP Direct Deposit Form.pdf": "EC31C702-2C86-4181-A895-D5545A5FC20A",
    "ADP Employee Reference Guide.pdf": "639C56E7-650E-47C4-94B2-30CBB15B4885",
    "Adriana Navarro.pdf": "18B32D25-5FA7-4590-ACA2-563012B7E82F",
    "Adriel Barrios-Anderson_Letter of Good Standing.pdf": "25F9588E-7216-4EAF-9AAE-19549D160CC7",
    "Adrish Anand- Enrollment Verification.pdf": "2E252980-6A91-4D2D-99F8-BA84D656D618",
    "ADru.RESverification.pdf": "88E032CF-A06A-4E66-B13D-ECA128688128",
    "Adu-Kyei Diandra.pdf": "D3C1BED2-0159-400F-937D-FA8ACD121747",
    "Advamed_CheckList_v10.pdf": "4FAFEB95-40EC-471E-ACF2-EC175699176D",
    "AdvaMed_DeviceTax_PrintAd_Politico_032714_v2.pdf": "B019C31C-25E7-4E54-ABD2-6D739AF56F2E",
    "Advance Beneficiary Notice (ABN) with Medically Unlikely Edits (MUE) Denials.pdf": "64303301-9D22-4A8F-B0E2-7EE313B9442B",
    "Advance2020Part2.pdf": "A30F5CAE-F9F3-4A39-A545-AA589A937154",
    "advising transcript UTRGV SOM.pdf": "ECD9301C-AAFA-472E-BF74-97B08C3DC63C",
    "Advith CV .pdf": "70897B5E-7E12-4CA7-BDF7-3726EA5CEE03",
    "Advocacy Guide - Capitol Hill Toolkit May 2023.pdf": "BE277292-5D6A-471F-8987-91AC148A1814",
    "AE_Signed.pdf": "58B3636F-339A-4E48-9059-6A84D128523E",
    "aeadiyaman_cv.pdf": "73A4FA0E-E1F9-4B07-B039-5F546E9DABAA",
    "AEB_LOR_FINAL.pdf": "23559C63-4866-4F28-89F4-7115611F5AF7",
    "AEpstein_CNS_letter.pdf": "69D8A1B3-10DE-4C86-A18A-B485750358AA",
    "AetnaLetterSpineFusion.pdf": "716EBE31-CDBD-410D-B21E-B35BA2C795B0",
    "AF - CNS Abstract - Digital Poster.pdf": "29B7F99F-985F-4880-8261-EABC419EA45F",
    "AF LOR.pdf": "CE686F25-9CF0-4D07-A152-A95C213E25F5",
    "AFeroze.emailverification.pdf": "04A12FA6-6D34-41A8-8BDF-9E41A6FEE252",
    "Affiliation Dr. Rubiano.pdf": "0A680239-1C46-4673-AB3A-B001023F695C",
    "AFN Fellow.pdf": "57ABD69E-D362-4C08-B97E-30404A85DAE2",
    "AFS.pdf": "016DF617-7221-4DC6-9293-EA836AF374EC",
    "Afuwape_ProofOfEnrollment.pdf": "8085A31F-4EC7-413B-B266-785E935CD6AD",
    "Agarwal_Prateek_CV.pdf": "D7A98648-B384-4CE1-95AC-03A913098134",
    "Agarwal_Spine_clearance_25Mar24.pdf": "B31A69BE-7C52-4AC6-8631-BFCE17E470A2",
    "Age Distribution.pdf": "0E6756E4-1AB4-4454-8FC0-A25630F3D152",
    "Agenda & Minutes May 2015_2015-06-09.pdf": "DD7A5233-A899-42BC-A282-3F260291B3DA",
    "Agenda (Part 1)_2009-03-11.pdf": "ECA6516A-E726-43A7-A215-E6118F672122",
    "Agenda (Part 2)_2009-03-11.pdf": "5837644F-79A2-4B94-BFDD-0E9634838EE7",
    "Agenda - Data Science and Image Analysis for the Neurosurgeon.pdf": "8F272A28-D668-46AF-AD0F-2B3342130A5B",
    "Agenda Book Feb 2010_2010-02-28.pdf": "E2B4D080-47F0-477F-8ECC-EE775D23F7A2",
    "Agenda Book Mar 2011_2011-03-23.pdf": "25F6ECF7-1058-4571-8F04-4382675C5798",
    "Agenda Book Oct 2010_2010-10-16.pdf": "B89E09B6-16D0-405E-B0F5-A8542563A0A5",
    "Agenda May 2009_2009-04-21.pdf": "35115ED0-0859-47FD-97FA-0D24168418F1",
    "Agenda Oct 2009_2009-10-25.pdf": "1FBC854F-4B7E-4572-A909-CEF97B8AD497",
    "Agenda Pages 1-101_2006-03-15.pdf": "0FBD2445-DEAD-464E-B10F-E97DA191A103",
    "Agenda Pages 102-193_2006-03-15.pdf": "3107D4F9-4910-4398-9D92-FDBF8A31253C",
    "Agenda Pages 194-301_2006-03-15.pdf": "9179D28D-22A5-4B1A-8C45-EDC7E310B512",
    "Agenda Pages 302-400_2006-03-15.pdf": "AD8DE7AC-23FE-420B-AFC7-8A1C518E441B",
    "Agenda_1996-02-28.pdf": "B19AA2A1-15C6-4FCE-8E6D-CEB4ABDCF658",
    "Agenda_1996-04-30.pdf": "00284A31-0C2F-4736-822E-76D3BC0211C1",
    "Agenda_1996-10-01.pdf": "47EB51CC-5C7E-498E-831B-02D9624F3FEB",
    "Agenda_1998-10-05.pdf": "99518971-1641-41AD-BAA2-DE78AFAB1EBA",
    "Agenda_2000-02-22.pdf": "E228DC19-38F2-48CD-9DA2-C0CA160D2A0C",
    "Agenda_2000-04-10.pdf": "9046BB4C-49B1-47E1-97D8-41ECB33FDBF8",
    "Agenda_2000-09-25.pdf": "43D677B4-D979-4843-B010-D139F0DE25DF",
    "Agenda_2001-10-01.pdf": "50DDFE0C-4C8F-49CF-AB00-CB30DA7433C0",
    "Agenda_2002-02-27.pdf": "AD43E744-72A6-429E-BED0-8757E12C9326",
    "Agenda_2002-04-08.pdf": "79DCD1F9-5491-415F-A63E-59694FB1983D",
    "Agenda_2002-09-23.pdf": "B2D8034D-99B4-4BB5-ABA8-852AF8A3B8CC",
    "Agenda_2003-03-05.pdf": "FE3053AA-5244-49DE-A3B3-6D8F608A663D",
    "Agenda_2003-04-28.pdf": "5E88C53C-501A-4FA9-82FB-8976E0B66DB9",
    "Agenda_2003-10-20.pdf": "370BB4BE-F08B-4BB1-9C60-F3092A931D36",
    "Agenda_2004-10-18.pdf": "7789829C-49C2-4347-BFB4-99B30ECF2CF8",
    "Agenda_2005-04-18.pdf": "2FA184F9-2CDC-4CDE-98F9-8ADC18AAE2E4",
    "Agenda_2005-10-09.pdf": "183B056C-4643-45A8-B2A7-1A6950B401E4",
    "Agenda_2006-04-24.pdf": "A6E16312-5ADD-46AD-AB70-A01B4B5F3A85",
    "Agenda_2006-10-09.pdf": "4FE17686-1478-424F-A17D-4171FC3847CA",
    "Agenda_2007-09-17.pdf": "7F3F737E-1788-43AB-8239-71FC7CAFF4B6",
    "Agenda_2008-02-27.pdf": "F7D15005-52F1-4AE1-90F0-F0EE6D638274",
    "Agenda_2008-09-01.pdf": "CA89EF93-BB86-46D1-A18A-62137D081B71",
    "Aghi CV October 2022.pdf": "F2571C8A-9385-4E03-ABEB-B7D9F01577D4",
    "Agitation vs Commands (boxplot) (1).pdf": "66585248-67F6-47F9-9A79-EEF3974070F5",
    "Agitation vs Commands_2.pdf": "B25BC309-108C-4A33-89A6-FEC43D6A677F",
    "Aguilar_Tania_GS.pdf": "A4E22E76-C43B-41A4-AC99-B6B80C2A9A30",
    "Aguirre Alexander- LOGs  Enrollment Verification.pdf": "3E38DE6D-98E9-4219-B3EF-784BBD85F775",
    "AGummadavelli-RESVerif.pdf": "31F162D8-25AC-470C-A4DD-CA295B9BD0A5",
    "AGummadavelli.emailverification.pdf": "42265279-D63E-488B-9CA6-3577A6221EF2",
    "agummadavelli_CV.pdf": "54F4EB4E-4530-413F-84F2-5A8593D48BF3",
    "AH CV CNS Application .pdf": "E4574548-1468-428C-9D16-0C1410144C1D",
    "AHatcher2017_CV.pdf": "1E6AEB5A-B062-4895-ACC0-0D6013E21EFE",
    "AHFS2007.pdf.pdf": "E2336C73-E247-4EC8-8010-D1BD694A8F00",
    "Ahluwalia Letter of Good Standing.pdf": "C57EF33A-F55F-49BB-800A-A0D447416630",
    "Ahmad Alhourani-Fellowship.pdf": "83E25354-013F-48A2-B036-4CB6B3725D11",
    "Ahmad Alsayegh-2ndFellowship.pdf": "53A1D0FF-0460-433E-9F4C-20EED042261E",
    "Ahmad Hasan (Ltr. for CNS).pdf": "DE467DAA-CC68-4850-81A3-314C5B64CAA6",
    "Ahmad M. Alsayegh-FellowVerif.pdf": "B2E9FDC5-55A9-4E38-A6C6-A76D3BF7CBE4",
    "Ahmad Ozair_March 2022_Proof of Good Standing.pdf": "38534B45-2DB3-4B1D-A439-690391BF4C60",
    "Ahmad Ozair_Proof of Academic Status & Enrolment_January 2021.pdf": "47BA358F-F8FB-4DF0-AA57-BF5973BEC61B",
    "Ahmad Sanan.pdf": "B5E1AA90-F9BA-4EE0-8652-A8F3B522F456",
    "ahmadlatefiresumepdf.pdf": "390BA16F-2229-403D-80D7-024F3B947782",
    "Ahmed A Cheema CV.pdf": "3985074C-1265-4122-BE54-CF87645EA14F",
    "Ahmed Alnemari-Fellowship.pdf": "62FCC67D-7B84-47E8-80F0-3AC04E3A53FB",
    "Ahmed Ashour CV.pdf": "15FC23A9-349A-4FD7-B172-A59D331DF698",
    "Ahmed Awad-Fellowship.pdf": "8FD68620-DF62-4362-8985-7C87905AFC9E",
    "Ahmed balfaqih.pdf": "FC3AEB28-21DF-4343-B392-7A0541FC79C6",
    "Ahmed Elghity.pdf": "89009732-4E30-4641-9748-66D0A9057AF1",
    "Ahmed Mahad  - Good Standing Enroll.pdf": "0D16E706-2CBB-434A-8B65-00A89FBA94B1",
    "Ahmed R. Maaty.pdf": "C62E62D5-8838-4C55-98CE-17F1CC85171D",
    "Ahmed Raslan SNS Membership - Burchiel.pdf": "294B6ACE-26B2-4BA5-8FAB-307EAEBBE64A",
    "Ahmed Raslan SNS Membership - Miller.pdf": "07523203-4DAD-4B7E-BF78-2518DA7B9BF7",
    "Ahmed Raslan SNS Membership - Selden.pdf": "3C8CBCAF-F3EB-4D29-BBF5-AA68EE3547D1",
    "Ahmed Sallam CV.pdf": "4C40B749-908B-4C46-A997-46709D4A940E",
    "Ahmed Samy Sheta – JCF Neurosurgery · Resume.io.pdf": "1769888B-17A6-4173-80A5-E78BAD5D25D7",
    "ahmed.pdf": "A08434B7-05D6-4C2A-BAF5-7E6A785614DC",
    "Ahmed_CNS Letter.pdf": "4F950950-2FC8-40A9-AF72-0DBA35A26E38",
    "AHNF.pdf": "7991CEB7-6EE5-4964-9C78-C963D451411B",
    "AHNT.pdf": "C8D25D91-2221-47A9-B159-5672CDC22A1D",
    "AHo-emailverification.pdf": "30866BFE-6F80-454D-91BD-CDF6AFDEE164",
    "AHS-Confirmation-Huang.pdf": "A0982804-A119-4C91-9AD4-634B9F5C57C1",
    "AI Course Dataset Explanation.pdf": "B93BA2FE-3BE7-4BF3-BF54-D897AAA9B69A",
    "AI Course Orange Download Guide.pdf": "AC8580E8-44DF-4968-BCF6-E5558F3747A0",
    "AI_GoodStanding_2023.pdf": "CD00E9D0-1A4A-4D10-A732-F4768ED3D8E7",
    "Aibaidula Abudumijiti - enrollment verif.pdf": "9C446C08-9A19-45A2-A1F1-327B2B6D5AA1",
    "AICA Bypass _ CV Section Poster.pdf": "A0B50D6D-7FB0-42B4-8999-0F132AA61537",
    "Aidin_Table_1.pdf": "C1A74B94-03DC-4354-A5DB-6AAFCB6A6C64",
    "Aidin_Table_2.pdf": "6582A56F-2DED-4477-825C-0DD15F065CB9",
    "Aidin_Table_3.pdf": "B80DFF54-D487-4D22-88CF-8570BD8B2344",
    "aifd.pdf": "F2432F6C-C54F-43E7-B844-0D1E4C8582C9",
    "Air Brief CV.pdf": "F28BAB65-C24A-4F05-A4CA-FD3D0559E334",
    "Air_CV.pdf": "8D7B12DF-986F-40CB-B594-62FCF5BC9104",
    "AIS_CNS_2023.pdf": "872E1491-FCA0-42BE-A5CF-8C2C291B4AE7",
    "AIsaacs.emailverification.pdf": "2A1F57A5-C2FA-4513-8E43-BD5A83F7E87F",
    "aisha alkubaisicns.pdf": "E92FC9DE-DF2B-4E38-93A7-A66F8A6CAFC9",
    "AJ CB CNS SES LOR 041522.pdf": "ED9CBB0B-D699-4A08-AE87-6CB4806BDCDB",
    "AJ DM CNS SES LOR 041522.pdf": "0999EE11-C836-4755-B280-906D0C9EE39E",
    "AJ DM CNS SES Sponsor Letter of Support 041522.pdf": "58641C30-5D2C-4E08-8D59-D59B64037DC6",
    "AJ DM CNS SES Sponsor LOR 041522.pdf": "BA7CC5CD-5755-4B93-953A-51AD7FD507B6",
    "AJardon-Pizano-RESVerif.pdf": "13D61016-2FE7-4346-BB23-5769536352EF",
    "Ajay Nair Letter of Good Standing Verification.pdf": "C2BF9492-AFED-46AC-841C-DBC24A31F84F",
    "Ajay NJMS Academic Transcipt.pdf": "BC013DB3-1877-4257-971A-784D55CBC3D3",
    "AJG CV april 9-2023.pdf": "5AFFB5E3-5064-4983-9FF9-D7ECBB63774C",
    "Ajith Thomas Nomination Lette.pdf": "8A3C9FA5-9441-49D8-93B8-7E96B5F42F59",
    "Ajith Thomas Sponsor Letter.pdf": "B367BFEB-6D66-48F7-A84F-2BA08A254CE3",
    "AK.CVApril2024.docx.pdf": "710335B4-F2CF-43DC-A380-221D40411C9C",
    "AK_CNS1.pdf": "DEFBFC19-7F4E-437D-9B38-A4826A0FBDC0",
    "AK_CNS2.pdf": "D602B19C-1F84-4E33-A0D7-DCFE35C8BF21",
    "Akbari CNS Leadership Institute CV.pdf": "1348EAE3-714F-4009-ABBC-7C6FB0252521",
    "Akbari CV 12.12.22.pdf": "69CD0DAA-1833-4780-B245-D20327C62317",
    "Akbarian-Tefaghi CV Medical Student Welcome Ltr 2015[1].pdf": "F3A2697D-B0F3-40E7-B445-AE3C1684547C",
    "AKC_071622-CV.pdf": "0E0D243C-B903-406A-A379-E878F1A338E6",
    "AKhan-RESverif..pdf": "8378C895-6480-40FD-B229-CD8B047A5096",
    "Akhil DMS admission.pdf": "8262513C-4AD7-4232-A5F0-956E3E14C01C",
    "Akiro Duey- Student Enrollment Verification.pdf": "0A4FBF8F-8A00-4373-8C87-64230D608113",
    "akpede cns.pdf": "C1AAA254-06B3-426B-87F4-345DF874F3AF",
    "AKsendzovsky CV.pdf": "6D22938A-F847-44E1-A195-9B96D1701E8C",
    "Al Drous CV.pdf": "2E4A477E-7B33-42C4-9A6F-FA58728C9D2F",
    "Al Marzooq, Osamah_Certificate_28.11.2013.pdf": "D43A0C50-2409-4EA8-BB8E-DDFA73C0AA7D",
    "Al Tamini.pdf": "5159059B-2CD8-4D9A-A6F6-5D5AD86A6EC0",
    "Al-Hadidi CV.pdf": "A85528C0-6112-4817-8D03-D4E6F706E80D",
    "Al-Rumaihi,G Eligibility_ CPSO.pdf": "514BCF43-51FD-4EF6-82AB-9263045184D3",
    "Al-Sharshahi-RES.verf.pdf": "7F98DFC5-38BC-4874-B194-BC2BACA8E360",
    "Alabi Verification.pdf": "03A10316-9F91-40D1-B645-8543C2B96A8A",
    "Alam, Yasaman.pdf": "A8C231AF-7C52-4D4B-97C4-E306B9B2F647",
    "Alan Balu Letter of Good Standing.pdf": "DF07CC71-0F9C-4DC4-BC79-3D6483BF33DC",
    "Alan Chalil-FellowVerif.pdf": "03805D51-D533-42CE-BB58-98C5B994469C",
    "Alan Tang Enrollment Verification.pdf": "779571F2-2915-4E75-B92B-C41FDAEE7883",
    "Alarifi ver 2020.pdf": "2A97C873-D663-4531-A8B7-CF6CFF397EB8",
    "Alawieh Ali Neurosurgery Publications Fellowship LOR-DLB.pdf": "EA8A616E-4C58-4F71-B38C-C5A3702CA9CF",
    "Alawieh Ali.pdf": "72E5EEB6-3CD5-45D5-ADE4-3312AE467257",
    "Alawieh LOR Publication Fellowship_Grossberg.pdf": "596ECE94-5784-4C93-AF84-B35D95C89B5D",
    "Alawieh_CV_2023.pdf": "CC21DA84-111C-4EB6-A2F2-E1110BDE5C8F",
    "Alawieh_PF_PS_2023.pdf": "CBAFFC48-6914-41DF-BE82-11B13BAF0DC6",
    "Alayli Adam-Letter for medical Society.pdf": "9A633F24-363C-415C-9CB6-06251D49FF4B",
    "Albanese Dystonia Reg CNS 2023_poster.pdf": "2A609906-75B6-4223-80CE-760080A455B7",
    "Albanese_Dystonia_2024 ASSFN_Poster draft_FINAL.pdf": "89ADDE88-5B30-479B-BBFC-A090789890E0",
    "alber_enrolment_cns.pdf": "851C8C36-FAAD-49D5-8A88-3557F5667C3E",
    "AlbericoLOR-TDinsmore.pdf": "15C52DC3-D083-4A05-8977-402FA7202321",
    "Albert Antar Certification Letter.pdf": "29E94974-8A1E-4129-84F8-BA6C9BA04C41",
    "Albert Isaacs-RESverif.pdf": "AC44FD17-C071-414E-9EA5-6074A2278BB7",
    "ALCOCER-BURDETT Carlos_Good Standing.pdf": "227A22E7-BAD8-4208-8422-8FCAA16231C0",
    "Aldred GXT outcomes US registry CNS 2021 eposter #031971.pdf": "A824D0FF-1E2F-4286-AE77-AA645F93E6A3",
    "Aldred IGP Registry CNS 2022.pdf": "14914623-AF61-4FD0-9BA1-5FAF35FAFDDE",
    "Aldred IGP US DBS Outcomes Study ASSFN 2022 eposter.pdf": "504F1DBA-B1AC-4F87-9284-FD9CA2FE472A",
    "Aldred_IGP_ 2024 ASSFN_Poster draft_FINAL.pdf": "25BCF7EB-921D-46B9-ADB5-DF01A8E2BDCC",
    "Alejandro Lopez-Fellow.pdf": "910B2754-4C9E-4333-B87F-E5113003B129",
    "Aleka Scoco-RESverif.pdf": "FDF179CF-D285-4169-A9D2-C59C87B31FDD",
    "Alessandra Cathel-Fellowship.pdf": "38F8D0B5-D478-40A8-8494-20917B5584BA",
    "Alessandra Histórico.pdf": "1E38C978-A351-4196-AC17-CDC6B87A84BE",
    "Alex Landry-RESverif.pdf": "B74B9CC3-E5E2-41CF-A505-2C2E49F0372D",
    "Alex Lu-Fellowship.pdf": "40646B99-3DBE-4621-B2E5-9AF42894A69D",
    "Alex_Enrollment_Verification.pdf": "CF11EE86-01B8-4647-A31F-2B909D7C3570",
    "Alexa Bramall_RESVErif.pdf": "26D9573D-80E9-44BB-9548-A579F7744E83",
    "Alexander - CV 2018.pdf": "AAA5EE6F-09BC-4F9F-B9F8-619C73C39793",
    "Alexander Braley-Fellowship.pdf": "C7C5DCFB-4E8E-41A9-9B2E-ABD5635C3300",
    "Alexander Evans.pdf": "EEE404FE-E41A-4E5D-AF6B-CB08E6A176DF",
    "Alexander III, Eben.pdf": "EDA2E194-FDE6-46D7-8A1B-FDABB803FDB5",
    "Alexander Lopez LET.pdf": "D64A95DD-8C13-4F6B-8EB7-60ED82EAEF5B",
    "Alexander Ramos-RESVerif.pdf": "B466C2F6-7A49-485A-99A2-C74A8549930A",
    "Alexander TaiRES-Verif.pdf": "399D6FA0-D83F-41B5-8929-2DB7D78FCD4E",
    "Alexander Tucker - CV 2022.pdf": "EAC013D4-B544-435B-BBC9-007865651884",
    "Alexander Winkler-Schwartz-2ndFellowship.pdf": "8C6744AB-B474-4D2F-AA59-ABE2D6E77440",
    "Alexander, Eben, III.pdf": "302F3260-15DF-40FA-86E9-7ECBB261960A",
    "Alexander, Eben.pdf": "AF013DFA-9506-489B-9846-04F51B57FAEF",
    "Alexander_CNS_2022.pdf": "3F72C865-2C99-46F0-920B-61BF6D854618",
    "AlexanderKYuCV2023-11-1.pdf": "C898D524-29F1-4FE7-BC95-8758CC2967C1",
    "Alexandra De Sequeira CV.pdf": "12732B02-028C-4D57-AB47-C6C86A5898A0",
    "Alexandra De Sequeira.pdf": "2B78F6A9-404B-43A6-9B78-B2EBB9A629D6",
    "Alexandria Mesropyan CV.pdf": "378ECE71-9199-4F6E-A8E0-481C1B3F7100",
    "Alexis Maya (Ltr. for CNS).pdf": "BA814079-CBE9-40B0-B9EE-14ECE9A4993B",
    "Alexis Morell-2ndFellowship.pdf": "68544246-736D-4F16-B3F4-65D6D9854931",
    "Alexis Sireci  - Enrollment verification Clinicals.pdf": "B9D3EECD-855F-40DF-A9DA-278902D84579",
    "AlexSchupperLOSNSurgeryPublicationsResidentFellowship1-15-2023.pdf": "B5BC1CDA-3D08-42F8-9225-E1840F617884",
    "Algorithm.pdf": "4349A882-F4A7-4681-823A-82ABF7F4D630",
    "Alhadidi letter of rec.pdf": "8C054A0E-0A58-4C7D-A8B9-3CCE18A452EC",
    "Ali Alattar Letter of Good Standing.pdf": "86014C07-C693-4C95-BD7B-FC37A08289D8",
    "Ali Amine CV.pdf": "65C497D5-C6AB-4E4A-B006-A15E6B49260E",
    "ALI CV  new update.pdf": "B590771B-F237-4C8C-9669-93B313F9B0CC",
    "Ali CV.pdf": "E2AAB9B5-BC2D-442D-91A9-70E5A365036A",
    "Ali Ebada CNS Letter of Good Standing.pdf": "13729E71-CD44-4DA7-9B28-864A9380E458",
    "Ali Farooqi letter of Ver.pdf": "B60DE55C-9387-455C-9786-A99717FB0C68",
    "Ali Haider.pdf": "629980EA-7EE6-48D0-93B9-EB2C70A148B2",
    "Ali Mohamed-ltr.pdf": "5715C856-528E-4AE3-B4D5-B435DA407041",
    "Ali Palejwala-FellowVerif.pdf": "B2C0F71B-3DE5-4296-93D2-711A6CA13BB4",
    "Ali Tafreshi's Letter of Good Academic Standing-School Enrollment Verification.pdf": "8FADDF5A-9C21-46FE-8600-5F4AB134488C",
    "Alia Hdeib CV .pdf": "1FEDD271-AA7B-4D0C-A3E0-9BDA9C285646",
    "Aliaga, Leonardo Letter of Enrollment, CNS.pdf": "F958D3B7-8823-474A-8A6F-3C24D08E9808",
    "Alice Wang OMS Letter of Good Standing.pdf": "E872D4E5-1129-498B-9722-EC4ACC264BFE",
    "AliceWoolard_BCB Training Status Letter.pdf": "B5BD0CF6-5C62-4F54-BC40-266DB9A7D717",
    "Alicia Agyemang-Sarpong.pdf": "E1FDDDB1-943A-4EF7-AFDD-86697E8ABEE8",
    "ALICIA TEST- REMOVE THIS.pdf": "BF4B7BBC-0AA3-4381-88DC-6E1E31630FAB",
    "Alick Wang CV May 2023.pdf": "70FF11E2-CC85-436A-83B2-8820354E6B5D",
    "ALIF Income CNS 2019 Poster.pdf": "AF5EE9CB-DDF6-467E-9948-23DF80336362",
    "ALIF LOS Readmit CNS 2019 Poster.pdf": "6FEDA9E4-D6F2-48A5-B1DA-6F9C89EA2B64",
    "ALIF_ECI-CCI_table1.pdf": "8A5E6955-C839-4168-8FD9-B5AFF8F4D800",
    "ALIF_ECI-CCI_table2.pdf": "0B7A80B5-0CE5-43A9-B946-F19CE036FC37",
    "ALIF_ECI-CCI_table3.pdf": "86ACE72E-F3BB-44AE-9B87-54A79351411B",
    "ALIF_ECI-CCI_table4.pdf": "F900A0F7-26C1-499B-9936-8EFC76B060DF",
    "ALIF_ECI-CCI_table5.pdf": "5D5C9516-DF0A-4955-ACEA-83BE162786F9",
    "ALIF_Figure1.pdf": "2145EB68-A2EE-42FC-8A00-535C38567EED",
    "ALIF_Figure2.pdf": "0C1C1031-048F-44B3-8B4A-F89A03242813",
    "ALIF_Figure3.pdf": "B7290D8C-781F-40C3-8628-8B743925592F",
    "ALIF_ms_JFB.pdf": "9CFA9D3D-D329-4FF5-8BEC-946F8818A06D",
    "AliHazamaCV2022.PDF": "E37C0747-6814-4EA3-8C29-9DAC29AEFABA",
    "AliKhedrCV3-15-2015.pdf": "617AAE2F-7AF0-4A40-AA9A-7BE12731D50D",
    "Alina  Mohanty .pdf": "3C23E832-7BD5-4238-9058-A9264D8EFC81",
    "Alireza Mansouri CV March 2019.pdf": "581D045A-2BE6-470C-8D29-A17FD3A56973",
    "Alireza Mansouri Dec2019 CV.pdf": "37B20CCA-F361-4C60-A655-2ED381C84515",
    "Alisa_Pugacheva_Letter of Enrollment & GS Verification.pdf": "47D8ADCF-F907-4C4C-ABBC-C9BB7A650D93",
    "Alison Westrup LET.pdf": "32582C4A-00BE-46F5-BEE5-7C877C8C1F01",
    "Alix BEX Proof of residency.pdf": "AD962025-A69B-4A5B-B3FB-515703C83B80",
    "Alkhars Hussain .pdf": "D5487E69-D9DC-4796-9C99-6817B510D373",
    "Alkherayf Letter CNS Scholarship 2023.pdf": "D5174904-2B23-4886-BCE5-5EACA3B7B541",
    "Alkherayf Letter CNS Scholarship.pdf": "041634C4-0CB3-479D-8AC2-1F6E81DC0660",
    "All CNS Abstract Figures.pdf": "4CB259A3-D29D-4ED9-A4F3-729E624B9145",
    "All leads.pdf": "2B153628-03FA-4EA9-9863-6F7C07666649",
    "All tables (1) copy.pdf": "FAF28F25-72F8-41C4-AFE7-758D536F9407",
    "All_figures.pdf": "A71B9978-1D9C-47C1-B69D-152175C7D75C",
    "All_Tables_LITT_CNS_Abstract.pdf": "52574723-4D98-45BB-8962-75D2C5C8AC91",
    "Allen Austin - Letter of Good Standing.pdf": "A1E73F72-8948-4906-BCC0-7B275F2AC7D6",
    "Allen Fu- Accepted Student Verification.pdf": "C86FD38C-2CBA-4443-8E47-9DC2506077C3",
    "Allen Jackson Enrollment Certification.pdf": "38506881-EF37-4FF3-B8B0-EE96CB26DE88",
    "AllenFu_CV_May2024.pdf": "144A4249-21E1-4ED1-B0C9-87D3016C4D54",
    "Alliance of Specialty Medicine - UHC ED Claims Review.pdf": "4618C8AD-30D5-40B6-91C4-6092F50431ED",
    "Alliance of Specialty Medicine COVID IV Letter 04.17.20.pdf": "6A765274-FBDC-41F7-A37A-B8FB6A11EB58",
    "Alliance of Specialty Medicine MACRA Letter to Health Committee Leadership Oct 2019.pdf": "CCACE8F7-8F0E-4CF4-95D0-34D2C7B44B17",
    "Alliance of Specialty Medicine Participates in CMS Roundtable on Prior Authorization Reform FINAL.pdf": "41691E9A-44E6-43E3-89BB-FA5DF4847A5E",
    "Alliance of Specialty Medicine Primary Care RFI comments 08.01.2022.pdf": "8A25B74A-CC62-48FC-BF83-0FD1F706334B",
    "Alliance of Specialty Medicine response to House Budget RFI 10.15.2023.pdf": "7594AC4C-6E29-4CC7-A65C-7E9C7A70E2E5",
    "Alliance of Specialty Medicine response to House Ed and Workforce ERISA RFI 03.15.2024.pdf": "802BFACD-751D-4852-AC89-5116047A606E",
    "Alliance of Specialty Medicine Statement for the Record SFC Bolstering Chronic Care Thru Medicare Physician Payment 04.11.2024.pdf": "E38F4DEF-4132-4F33-A1CA-8D3FC16FC093",
    "Alliance of Specialty Medicine- Comment on VA GME Proposed Rule.pdf": "C270A247-8EF3-4E16-B97F-2CE06AA6F9DB",
    "Alliance of SpecialtyMedicineMeaningfulUseComments.pdf": "57A3B924-C437-425E-9599-275A7CB15B20",
    "Alliance Prior Auth Comments 010421.pdf": "33597C38-63A8-49CD-82A7-8795F7225634",
    "Alliance Spec Med -- FTC RFI May 2022.pdf": "00BDA350-7768-4A3E-A2EA-60C818B0AD48",
    "Alliance_ Response to PTAC RFI on Specialty Integration FINAL.pdf": "2A6CE698-3A67-4837-B77D-562587782603",
    "alliance_2017_mpfs_comment_letter_final.pdf": "52DBD3E0-374B-452A-A24F-FEE5A1500376",
    "alliance_2018_pfs_comments_final.pdf": "03D31723-EA4C-491F-AE4D-F662B0C68739",
    "alliance_2018_qpp_fc_final.pdf": "C7963A06-C40D-49CF-90DD-97C7FB22E4B9",
    "alliance_2018_qpp_final.pdf": "3F6F575E-3002-4ADA-A352-5D0956F88219",
    "alliance_2019_mpfs_qpp_nprm_final.pdf": "B18A9DD6-C2F3-4A2E-AF14-70733E6D8F80",
    "Alliance_2020 MPFS QPP Final Rule_FINAL.pdf": "337054AD-A35C-44DC-89D2-0FB1121E44CF",
    "alliance_2020_mpfs_qpp_proposed_rule_final.pdf": "669F8CCE-51BB-42D2-962D-2F74823BAA21",
    "Alliance_2021 and 2022 MAPD Proposed Rule_FINAL submitted.pdf": "68298392-25BB-41AC-BA18-8D1847C36605",
    "Alliance_2023 Payment Notice_FINAL.pdf": "8FB2044E-FE78-4AB3-9C0D-679D36D8E88D",
    "Alliance_2024 MAPD Proposed Rule Comments_FINAL.pdf": "A68BB158-2A22-4594-B062-E00E30884158",
    "Alliance_2024 NBPP Proposed Rule Comments_FINAL.pdf": "8D8DAD4A-DDD3-42DA-9AF7-8B3D032A7736",
    "Alliance_2025 NBPP_FINAL.pdf": "EB366EC3-7A32-4ED5-854E-1818FAD2B7A4",
    "Alliance_Advancing Interoperabiity and Improving Prior Authorization Proposed Rule Comments.03132023.pdf": "90B86ECF-4192-46FF-989B-09833570C0FA",
    "alliance_cmmi_rfi.pdf": "3677303F-2B27-4913-872C-6289BAF7EB07",
    "Alliance_COVID-19 IFC_FINAL.pdf": "DB3B4EDB-BF54-468C-81E4-B4AD5F3BF901",
    "Alliance_CY 2022 PFS NPRM_FINAL.pdf": "F23B5E86-41BE-470C-97F5-13E0E8425434",
    "Alliance_CY 2023 PFS NPRM_FINAL SUBMITTED.pdf": "B990B530-E4FA-4718-ABCC-C91A4EF8C395",
    "Alliance_CY 2024 PFS NPRM_FINAL.pdf": "A514135B-654B-4383-B445-EFA459FB8D3A",
    "Alliance_Disclosure of Affiliations Comment Letter - FINAL.pdf": "8AE0E98D-5E9F-4DAE-9CB4-78C38ABB85AF",
    "Alliance_FDA Off-Label Comments and Position_FINAL COMBINED.pdf": "FF3F4C94-13C3-4561-9EBF-E6BCF280357C",
    "Alliance_FDALetter.pdf": "B849D81D-0E03-4E7B-B2FD-B002D27F2B5C",
    "alliance_fdaregreduction_final.pdf": "636B9F5B-2FE0-4AB2-9431-4795215FE68F",
    "Alliance_HHS letter on Trump EO on Medicare_FINAL.pdf": "BC58D1A4-6CC6-4E7B-A01F-DAEA1ABAA7AD",
    "Alliance_HTI-1 proposed rule comments.06.20.23.pdf": "2F7984FF-B094-4E5E-A0D2-265D8ED45544",
    "alliance_icd10_black_letter.pdf": "433615AC-D791-4C06-9EC3-7EF19C5CC53A",
    "alliance_icd10_palmer_letter.pdf": "6AD20CC5-F993-4224-BC90-C819FE5972A2",
    "alliance_icd10_poe_letter.pdf": "690ACE76-AC6E-4300-B880-E5E9210514C9",
    "Alliance_Improving Telehealth Access_FINAL.pdf": "73585C91-CDC0-43DF-BFD8-717D4C35F61F",
    "alliance_legislative_priorities_feb_21_2017_final.pdf": "E6FE9ECD-048C-4521-9930-028BCFB2532E",
    "alliance_letter_on_reboot-2.pdf": "1DEF3AC0-5327-4165-A0C0-A89D8DDEBE7C",
    "alliance_ltr_to_naic_on_network_issues_final.pdf": "29F0B21F-1F07-47CE-A0ED-C883CF590743",
    "Alliance_MA RFI_May 2024_FINAL.pdf": "657B7373-9918-42F9-B339-6092ABBACBC5",
    "alliance_macra_qpp_nprm_comments_final.pdf": "675A1DE9-BE94-4D5B-9952-5EF577927AD4",
    "alliance_mapd_nprm_comments_final.pdf": "79C9A1FB-D756-468E-8790-68AFDEDEEE04",
    "Alliance_MedPAC Improving PCP Payment_FINAL.pdf": "A90327BA-2415-483A-B5AC-F257DD3B030B",
    "Alliance_MedPAC Physician Pay Recommendation_FINAL.pdf": "2DCDCA40-4BBD-4425-98FB-B2968D32A26F",
    "alliance_medpac_letter_release_10.25.17.pdf": "8997E051-E75F-46B9-955D-A0347C3DD071",
    "alliance_medpac_mips_vpp_recommendation_final.pdf": "EF069A5A-0797-4818-AD70-3109E083793C",
    "alliance_medpac_vvp_final.pdf": "4300E7A5-6DED-4391-B4B9-7DB7F52BEDCF",
    "alliance_modernizing_part_d_final.pdf": "46344F36-B12C-44F4-89A9-2B583351E3DC",
    "alliance_mssp_aco_pathways_to_success_final.pdf": "907DF938-B5E7-4C9D-9DBC-170F14F37FDE",
    "alliance_of_specialty_medicine_-_judiciary_cte_subcte_constitution_civil_justice_-_attorney_ads_hearing_june_23_2017.pdf": "E894A386-2E1E-476A-AF64-5E26F43F6A15",
    "alliance_of_specialty_medicine_-_statement_for_the_record_on_medicare_fwa_july_2017.pdf": "AD06F888-1C17-428C-82AC-1A580353D36F",
    "alliance_of_specialty_medicine_21st_century_cures_act_support_letter_070.pdf": "AB719C1A-FCF7-4168-884D-60AE1D731D5C",
    "alliance_of_specialty_medicine_21st_century_cures_act_support_letter_120116.pdf": "2806D094-D58C-48D4-8877-0035E3D6C2FC",
    "alliance_of_specialty_medicine_access_act_letter_050417.pdf": "1CE4A030-15EC-477F-8D69-23D23C254393",
    "alliance_of_specialty_medicine_championing_healthy_kids_act_letter_nov_1_2017.pdf": "F05B593C-E386-4652-B098-41A35829788D",
    "alliance_of_specialty_medicine_chip_letter_011818.pdf": "AFA21CB9-3894-42EF-BF64-398107F0659A",
    "alliance_of_specialty_medicine_commissioner_gottlieb_congratulatory_letter_050917.pdf": "E89CF52E-7A3E-451C-986F-27EEEC2E82F2",
    "alliance_of_specialty_medicine_good_samaritan_letter_050417.pdf": "8280B071-F89E-4B56-80FF-FF4646276E9F",
    "alliance_of_specialty_medicine_health_act_letter_to_house_judiciary_0321.pdf": "BD938519-65E0-453E-8BD7-A1C4F8089A9C",
    "alliance_of_specialty_medicine_health_reform_comments_to_cassidy_collins_052617.pdf": "617ECDFE-7CD6-4B7D-813A-527961874178",
    "alliance_of_specialty_medicine_health_reform_comments_to_sfc_052317.pdf": "C9E6BFA1-70F6-453A-9B13-C1AA75BD5A1A",
    "alliance_of_specialty_medicine_hit_ehr_letter_to_senate_help_070115.pdf": "FD3FCE21-4622-4ADB-AB3A-71F109F1D4B5",
    "alliance_of_specialty_medicine_house_ec_healthy_kids_act_letter_oct_3_2017.pdf": "790643C0-3A03-4166-9623-9D21A71AEDD6",
    "alliance_of_specialty_medicine_house_ec_ipab_repeal_letter_oct_3_2017.pdf": "F4C6FD40-4485-463A-8CB7-0EFD78A259CA",
    "alliance_of_specialty_medicine_house_leadership_ipab_repeal_letter_oct_19_2017.pdf": "07C8C478-562F-49F7-8664-DAE0EDCB140A",
    "alliance_of_specialty_medicine_house_wm_health_subc_macra_statement_for_the_record_march_2018.pdf": "F59AB25F-684A-4507-87ED-5B72F7821B9A",
    "alliance_of_specialty_medicine_house_wms_ipab_repeal_letter_oct_3_2017.pdf": "520070B4-B269-4C68-9E05-5E709D4CEE51",
    "alliance_of_specialty_medicine_hr_2_sgr_support_letter_032515_final.pdf": "5AC92776-7D37-4AD8-B49E-DE71D7A84FB6",
    "alliance_of_specialty_medicine_ipab_joint_resolution_support_letter_to_reps_roe_and_ruiz_020217.pdf": "29BBD9AD-D6DD-4ABD-AAA3-D5B00D6BFB64",
    "alliance_of_specialty_medicine_ipab_joint_resolution_support_letter_to_sen_cornyn_020217.pdf": "F95B1317-E4E3-47C5-BE62-06DF1E2C55D6",
    "alliance_of_specialty_medicine_ipab_joint_resolution_support_letter_to_sen_wyden_020217.pdf": "923AEAD1-CB7D-45BE-B7D3-51495A681262",
    "alliance_of_specialty_medicine_ipab_repeal_letter_020218.pdf": "5C7A7CA0-A91B-4F52-8136-2A6B43C3565E",
    "alliance_of_specialty_medicine_ipab_repeal_support_letter_to_reps_roe_and_ruiz_020317.pdf": "64883B1E-39C2-412B-9084-F9F3163EF35C",
    "alliance_of_specialty_medicine_ipab_repeal_support_letter_to_sen_cornyn_020317.pdf": "8BF0DEE3-C459-43F9-8B48-38BFA8082163",
    "alliance_of_specialty_medicine_ipab_repeal_support_letter_to_sen_wyden_020317.pdf": "E945EC44-DD80-4924-A3E1-08791F47976D",
    "alliance_of_specialty_medicine_ipab_support_letter_to_reps_roe_and_sanchez_031615.pdf": "B673F299-7743-40CE-8F47-D141D67F975F",
    "alliance_of_specialty_medicine_ipab_support_letter_to_sen_cornyn_031615.pdf": "F0123F4C-F84B-473E-96F8-AFF25902BFD5",
    "alliance_of_specialty_medicine_letter_to_rep._griffith_re_hr_1703_0502171.pdf": "220E6C31-AB88-4FD3-8A9D-C191569A8022",
    "alliance_of_specialty_medicine_letter_to_rep_price_re_provisions_in_hr_2300_062615_2.pdf": "CD7D8CD2-7B79-40C9-A784-B44221FA2B28",
    "alliance_of_specialty_medicine_letter_to_rep_roe_072015.pdf": "6F3E971E-8B80-4E58-A7CC-A247A3B8E3B1",
    "alliance_of_specialty_medicine_macra_letter_100417.pdf": "0660E043-6C19-4477-AFDE-97048B061139",
    "alliance_of_specialty_medicine_macra_statement_for_april_19_2016_house_ec_hearing.pdf": "5D7EE680-451D-414B-931A-4AE29DF042A2",
    "alliance_of_specialty_medicine_macra_testimony_final_july_12_2016.pdf": "FDA264CB-15C0-4E0A-9C64-D2AA95F66A46",
    "alliance_of_specialty_medicine_medical_practice_freedom_act_letter_050417.pdf": "DFC0565B-BD6F-4695-BF97-574EE577887A",
    "alliance_of_specialty_medicine_medicare_extenders_letter_113017.pdf": "97CC751D-5A60-4AD9-9700-838137F4A7CD",
    "alliance_of_specialty_medicine_medicare_extenders_sfc_letter_120617.pdf": "C0B93591-B541-4FCB-A39D-A95822670808",
    "alliance_of_specialty_medicine_paca_letter_061217.pdf": "25CC188D-9405-44E4-A495-03B1EB63AE42",
    "alliance_of_specialty_medicine_rep_castor_gme_bill_hr_4774.pdf": "E00A5413-1332-4062-B04D-CD1ADB819E1E",
    "alliance_of_specialty_medicine_response_to_wm_rfi_opioids_march_15_2018.pdf": "5C64057B-FAEC-410E-AD89-CD77BE78F1F9",
    "alliance_of_specialty_medicine_saving_lives_saving_costs_letter_050417.pdf": "52006DA2-26DB-4B81-897E-4259447C210F",
    "alliance_of_specialty_medicine_scott_gottlieb_support_letter_033117.pdf": "23BE1142-65D5-46E2-9101-573AE86FF322",
    "alliance_of_specialty_medicine_sec_price_congrats_letter_022817.pdf": "F7E6036E-8285-4F81-9122-D64104181B50",
    "alliance_of_specialty_medicine_sgr_letter_to_leadership_final_09.26.14.pdf": "6A9B8F37-C988-434B-B3BF-C3332BAB1512",
    "alliance_of_specialty_medicine_support_letter_crowley_boustany_gme_bill.pdf": "8E773792-30B7-4551-9DE9-BC46C47B9D26",
    "alliance_of_specialty_medicine_support_letter_hr_3940_meaningful_use_hardship_relief_act_120415_0.pdf": "2B8139C4-7753-4253-9935-6BF2C636366D",
    "alliance_of_specialty_medicine_support_letter_hr_4133_private_contracting_110117.pdf": "F1C04F56-C108-47DE-A8FD-244D30E27D6F",
    "alliance_of_specialty_medicine_support_letter_nelson_schumer_gme_bill.pdf": "F2D1B105-7B36-4FAB-87D7-24436488A74E",
    "Alliance_Off-labelStatement.pdf": "1263C33C-84DF-46FD-B38B-E8973968899A",
    "Alliance_Payment Notice Network Adequacy_FINAL.pdf": "0AFC3E68-BA86-474A-BC90-6870751017F4",
    "Alliance_PFS CY 2021 NPRM_FINAL.pdf": "478C0F28-AFBE-4ED5-8DB1-E10EC36F0C92",
    "alliance_qpp_comments_final.pdf": "935AC3FC-F02C-4D05-8653-C2C2CFB7F5A5",
    "alliance_stark_rfi_final.pdf": "D313613C-836E-4C33-B944-97C9B3C372CD",
    "AlliancecommentsonWaysMeansFinanceSGRproposal.pdf": "9968F763-7EC1-4571-AF88-831FE6A73D6A",
    "AlliancecommentsonWaysMeansFinanceSGRproposal_0.pdf": "861FBCA1-A3DF-4191-97E3-BEF1F502797F",
    "AllianceImagingletter-RepRangel7-22-09.pdf": "6467E55F-5027-4C4D-9684-21BAB60A798A",
    "AllianceImagingletter-RepWaxman7-22-09.pdf": "CF97DFFC-4F6A-4A86-9660-0013F2790900",
    "AllianceImagingletterRepSpeier7-22-09.pdf": "40A07C68-CF47-4898-BD36-E5044FC88C5B",
    "AllianceletterHouseMOCs031010FINAL.pdf": "EFDE45DC-8265-4872-9E5F-56EED43B2FD5",
    "AllianceLetterSupportingRepGordonMedicalLiabilityReform-7-10-09.pdf": "0C5B249A-58D1-4F64-95B4-F814C4E5A2DC",
    "AllianceLettertoCongressonSGR012114FINAL.pdf": "713E8922-7837-4F02-8293-1C766A4B062E",
    "AllianceLettertoCongressonSGR012114FINAL_0.pdf": "D59C782A-0226-4CA3-8E48-B550B6EF7576",
    "AllianceLettertoCooperOpposingMedPAC--7-06-09.pdf": "363BA2FD-9D59-47B5-A423-D86653A0F140",
    "AllianceLettertoFinanceOpposingMedPAC--7-06-09.pdf": "9B4BC69F-D50F-4657-BFA2-23F83B2EC586",
    "AllianceLettertoFinancereHealthcareReformOptionsPaper1--5-15-09.pdf": "1C48A3B7-F0F2-4F8A-92B2-B7AC19A8F583",
    "AllianceLettertoFinancereHealthcareReformOptionsPapers2and3--5-29-09.pdf": "6310B87D-9A3B-4C41-95DE-2AB84A2A0AF2",
    "AllianceLettertoHouseOpposingMedPAC--7-06-09.pdf": "F04863D5-6D78-40F1-9ECB-B6665B0E8BDF",
    "AllianceLettertoHouseTri-Committees--HealthcareReformFeedback-7-08-09.pdf": "0C8CE2E9-95C6-474E-ACA3-67A8B2532858",
    "AllianceLettertoRockefellerOpposingMedPAC--7-06-09.pdf": "F2FD7464-C102-437D-910E-65C538C79F25",
    "AllianceofSpecialtyMedicinecommentstoECSGR070913FINAL.pdf": "E5595106-5087-48D3-A696-589454B6125A",
    "AllianceofSpecialtyMedicinecommentstoECSGRCommitteePrintFINAL072213.pdf": "07EDD642-8E8F-4306-BAC2-4DA23492D9ED",
    "AllianceofSpecialtyMedicineECSGRtestimonysubmittedfortherecordFINAL060413.pdf": "62AF401F-33BF-4C4D-A82D-C82D35B1F81D",
    "AllianceofSpecialtyMedicineFINALDRAFTSGRLetter091813.pdf": "FFFD1DA2-613D-4261-A3CC-11E35F1DA53B",
    "AllianceofSpecialtyMedicineHealthCareReformAdvertisement7-14-09.pdf": "24C137BD-E9D3-420D-833C-470945280A9B",
    "AllianceofSpecialtyMedicineIPABlettertoSenCornyn021513.pdf": "65725833-D60F-4BB5-9879-6C9BDED2A895",
    "AllianceofSpecialtyMedicineSGRLeadershipletter032614.pdf": "8FC89A60-3803-47F6-82EB-18D929322630",
    "AllianceofSpecialtyMedicineSGRlettertoCongress050914.pdf": "367287F0-A10E-464F-B5C3-3C2A499E6399",
    "AllianceofSpecialtyMedicineSGRsupportletterFINAL.pdf": "53480882-722E-4103-8BFC-7F493FB77ABC",
    "AllianceofSpecialtyMedicineSupportLetterS1152SenatorsReedBluntWorkforcebill.pdf": "6308BD26-88CB-4609-BC81-1BFA380F554C",
    "AllianceOfSpecialtyMedicineSupportLetterS236privatecontracting020813.pdf": "8066816F-430C-432F-BDFF-0DBFDF181F56",
    "allianceopenpaymentsfinal.pdf": "A0D58C0B-89C9-4F72-B2AF-2D72FEB64DA6",
    "AllianceSGRLetter_Dec2012_FINAL.pdf": "C89B65FE-2FCE-42C1-BBA9-D9DB6B4E51FB",
    "Allison Kuzniarowicz Resume.pdf": "8E4D3A8C-82B9-4C3B-AB14-BC06812BD0C3",
    "AllisonD_LetterofGoodStanding.pdf": "0E83D42F-63EB-4C1A-AAE3-7CD8D9A7B783",
    "Almgren-Bell et al., 037747.pdf": "F66409D9-5221-4AF4-9133-40B9D5C2EE05",
    "Alngar.pdf": "EDACA253-18AE-4397-98DA-5384849047BC",
    "Alotaibi_Verfication.pdf": "95D3CBE6-CDB8-40D9-92EF-B61092F0C79A",
    "Alqurashi.pdf": "52AAFF25-2181-40E2-BDE8-BC23FC0CE8FF",
    "Alrashidi.pdf": "625BC0AE-910C-44F8-AE5A-88A54DCF7FF1",
    "Alsalek_Samir CNS 2023.04.04.pdf": "21AB9831-22FE-48B1-907A-243C7E429E76",
    "Alshareef, Mohammed-LOV CNS 4-8-13.pdf": "432F5B03-C2A6-43C0-B498-C1E00341BADA",
    "Alshareef_CV.pdf": "5665BD97-5F16-4F4B-A4B2-4969945A6349",
    "Alterman LOS Stippler SNS.pdf": "6F110BEB-94AA-4C22-8985-2FF720B95015",
    "alternative approaches to sural nerve biopsy.pdf": "E2D83A1B-BB4D-4947-A293-89523FE80F81",
    "Altshuler Invoice_RUNN.pdf": "40C740FD-EB56-49E3-8E29-016131D005D5",
    "Altshuler, Marcelle.pdf": "B08BE324-572B-49D2-992F-5711971FB767",
    "Alvi.pdf": "B095969D-8D77-4E85-A338-C95B2425AF08",
    "Alvi_thesis_1_tables.pdf": "A81B98C5-AC43-4723-9DF6-FE6340E8428A",
    "Alvi_Thesis_2_tables.pdf": "E212B73E-128D-4DCE-ACCA-4747EBC72527",
    "Alvi_Thesis_3_Table.pdf": "359B0C30-6312-4F57-9B47-776E88FE11BE",
    "Alwyn Gomez-Residency.pdf": "2B83CDA3-C6CE-4B72-80C9-960786322845",
    "Alwyn Titus Gomez CV 2024 03.pdf": "CEA901A1-8C8A-4C08-8772-BF42689833C1",
    "Alyakin Anton.pdf": "EB21D11D-5509-4DF3-A2FA-760E78EC2D4B",
    "Alysa Almojuela CV 2023.pdf": "77B1F4B2-9F58-4248-8C43-A99AACC1EFA5",
    "AlyssaBartlett_CNS Letter_Hertz 1.pdf": "72C4C7FE-9914-42B3-8148-BD103F9BE4B2",
    "AM_LITTICB_083123.pdf": "36200EF1-CD55-4333-9749-62DAEDBB1E15",
    "AM_LITTModel_083123.pdf": "993CA049-6E5F-414C-8F70-1B04B3308B1B",
    "AM_LITTSE_083123.pdf": "258A23D8-CF9B-4C42-8714-60A8BAD17E28",
    "AM_SSR_TSRPT.pdf": "E9EBE091-1217-48CD-A4B3-8BE7CFF23402",
    "AM_VOD_083123.pdf": "A32F3EC4-1C2C-483C-B816-BDE52EDEF2FF",
    "AMA - Physician Community Surprise Billing Sign-on Letter - House .pdf": "598BF823-66D1-4560-97EB-3D6632F3A1F0",
    "AMA - Physician Community Surprise Billing Sign-on Letter - Senate.pdf": "97F1889E-560B-4C1E-8433-E3DD6AAC1C83",
    "AMA I CAN Act Opposition Letter .pdf": "EB476421-5DEB-45DC-90AF-1D8B56B8CACF",
    "AMA NSA Toolkit - January 2022 FINAL.pdf": "6C23A2D9-58D1-4F48-967B-03A3FF8615EA",
    "AMA Substance Use and Pain Care Task Force Recommendations 092321.pdf": "7EA96101-3431-4E7C-AC3B-558A7B922016",
    "ama_harm_reduction_and_nsc_urge_nga_to_focus_on_treatment_feb_2015_sign_on_with_sigs_final.pdf": "C0BB0632-CE6B-42A9-B327-578C21166CF3",
    "ama_refinement_panel_sign-on_letter.pdf": "9739C535-6E3C-49C5-8DA5-F6DAADAD17D5",
    "ama_task_force_one-pager_final_aug_2018.pdf": "27C163A2-38C0-4BC7-9A2C-DC1748B81895",
    "Amadi Iheanyi - Letter of Good Standing.pdf": "A7281826-535F-4720-BACB-6110BBEDC671",
    "Amalia Parra.pdf": "BA494AA0-A34D-485F-926D-5D267D0ACA83",
    "AMalik.RESverif.pdf": "94B3FBD0-FF08-4C44-802D-3D797FE61987",
    "Amanda Carpenter-RESverif.pdf": "041CE86B-3348-4793-A5BA-6864B2B694FE",
    "Amanda Kwasnicki CV.pdf": "BC07B196-09B4-412A-86FA-1B00CD1B5295",
    "Amanda Kwasnicki-emailverification.pdf": "C930B34A-0176-434C-8DC5-5458480B7FB9",
    "Amanda Kwasnicki-RESverif.pdf": "0F7C42CE-8EB6-4F83-AF65-A5EF241164AA",
    "Amanda Zakeri-Fellowship.pdf": "E5323567-8747-42BD-9A58-C48F4D0E1297",
    "Amanda_Jenson.pdf": "96F5415A-4B06-4801-9D2E-C9A180FE69F9",
    "Amani Carson_Fellowship Budget.pdf": "18DCB517-BD94-4A7E-8776-6BB1B7C8798F",
    "amasignon-medicalstaffcops07-01-14.pdf": "E55830C4-BAD2-45E1-9C98-C240C2CA7E92",
    "Amazon 3299.pdf": "F06BD728-52D5-4B94-902D-265092D0A285",
    "amb 2023 ncr.pdf": "322D9FBE-775D-4C19-9817-919067961A31",
    "AMB.pdf": "F423EA16-FCC7-42F1-9CEB-0B621D607C26",
    "AMD_LOR.pdf": "3CD661C4-2221-42F9-95C5-104228FD31F9",
    "ameen .pdf": "28AADD94-30D0-4B62-8AAC-56C6D016A6FE",
    "Amelia Haynes CV .pdf": "FDD38A45-73CB-4967-9A99-9C933D56B432",
    "American Association of Neurological Surgeons Medical Student M.pdf": "A8FD219E-ED67-4962-8AD8-D15E8E5D43DD",
    "amerihealth_modifier_25_letter_05-24-18.pdf": "3B89D8CE-48B5-4E5B-A48E-27F2C3282B39",
    "Amey Savardekar Curriculum Vitae -faculty.pdf": "912AA53C-DE43-43E1-B4A8-E2491A693CCD",
    "Amey Savardekar.RES.verif.pdf": "11A47B9F-650F-4107-95A6-E135BFCC1B15",
    "Amicus Brief of PAI 16 State Med Assn. and 7 Specialty Medical Soc. TMA II 5th Cir. Appeal.pdf": "21B1ABA2-439C-4DDA-868B-62A8AC1E714E",
    "Amicus Brief of PAI State Associations and Specialty Societies TMA II litigation challenging NSA Final Rule.pdf": "CA7A370E-AD92-448C-A84D-2814F818B633",
    "Amin Devin CV 2023.pdf": "DB38284F-4BD8-4606-B696-0412D42A3AFD",
    "Amin Mahmoodi – UCSD Medical Student.pdf": "483CEC03-1878-4E5B-A266-5784B0BAE8AB",
    "Amir Dehdashti MD.pdf": "826ADD72-594B-4F14-B05F-B9F052182FD7",
    "AMIreperfusion.pdf": "316799BF-92DE-428C-9CE2-B620548A5ED2",
    "amirfaraji.pdf": "AA896BD9-57EC-400D-BAB6-334E46358360",
    "Amirmahdi Mojtahedzadeh_s2114394_tuition fee.pdf": "C5DC74E8-93AB-4AF5-814F-1BB8048A2118",
    "Amit Persad-2ndFellwoship.pdf": "011EDE2F-C08E-4F4E-A4EC-925ACF49E38D",
    "Amit Persad.FellowVERIF.pdf": "DCE7E5B0-C56F-423E-9AD1-41F5D53FD599",
    "Amith Thomas for SNS Jensen.pdf": "EBEE2AFC-8D2D-4AD9-BF2E-AA627C7ADF28",
    "Ammad BGC_082222.pdf": "DA51C8B5-881D-4D7E-92C6-E633F2331972",
    "Amman Haider.pdf": "CD368B55-14A3-46A9-B7AB-785ECE01B3EB",
    "AMontoure.emailverification.pdf": "D29B0173-C3A4-4DEC-83AD-E30D3E8C3515",
    "AMorsi.emailverification.pdf": "329FD10C-7E92-41A3-B666-327D5EBCE253",
    "Amponsah Precious 2024 6-6 Class of 2027 Current Student Verification.pdf": "30CFEDB4-6A4A-4739-9C7D-E227C2AF287E",
    "AMS98313LBL Rev 1 CNS  Digital Poster.pdf": "B74F87B7-23FA-497B-851F-B5A895C75DFC",
    "Amy Bruzek CV.pdf": "E7B7E93C-4FB9-421F-864A-92A6A90BF785",
    "Amy Li Verification Letter.pdf": "8883BA22-0D5D-4CE7-A1F3-041FD7A56178",
    "AmyBaohan-FellowVerif.pdf": "C3F18D5A-87A9-4EB5-813F-E4CA78E77298",
    "An Extreme Lateral Access for the Surgery of Lumbar Disc Herniations Inside the Spinal Canal Using the Full-Endoscopic Uniportal Transforaminal Approach.pdf": "018C1141-F8A5-4EC1-AAE1-D8F5E6555C10",
    "An Nicholas - Letter of Good Standing.pdf": "2B4D2DEF-61E0-485C-8574-C2D9836DE380",
    "AN rupture treatment outcomes GOS over time.pdf": "FDCFC860-0440-4681-AC43-362C3747C1E1",
    "Anahita_Fathi_Kazerooni_CBTN_Poster_36x48in.pdf": "E5F28840-AC8F-40E8-8BA1-5E24EE8F4C36",
    "Anand Germanwala Slides.pdf": "6A63E19D-5026-4730-B180-0255AD0B4213",
    "Anand Kaul-2ndFellowship.pdf": "9D61701B-2207-410D-8F61-720FC799CFD4",
    "Anand Rughani.pdf": "A846F38A-3C05-475E-8BB4-A8059832F59E",
    "Anand Veeravagu CV   02_2020.pdf": "67CE1C1D-0B05-4186-A5BB-0DB172F3E382",
    "Anand Veeravagu CV   2_2023.pdf": "4A69F0AF-D9E8-4760-BB74-81E557FB57B1",
    "Anand Veeravagu CV   2_2024.pdf": "B3592A47-CC9B-45D2-81CD-2531384F9441",
    "Anand Veeravagu CV   3_2021.pdf": "D8136987-79D2-41C6-A741-9AE36F3DB870",
    "Anas Bardeesi-2ndFellowship.pdf": "4EEB8FFC-85AF-4D60-959D-69FB3D8C00BF",
    "Anastasia Kiprya-LOGS.pdf": "C7F1A740-DF43-474D-B596-E1D334C856B9",
    "Anatoli Dimitrov CV (Eng).pdf": "A6B7AD43-E618-496F-BD80-B000563BB1C6",
    "ANDERSON J Neurosurg Spine2006.pdf": "ED37A717-4B99-42ED-B6E5-A5B3B9B2459D",
    "Anderson LOR.pdf": "52A0D563-0A1F-48F8-9125-5DFD572616DA",
    "Anderson_SNS Receipt.pdf": "6CB7316B-BD78-42EE-BCA8-1B1678F17634",
    "Andre Boyke_Letter of Good Standing copy.pdf": "34593504-9FAA-4BAF-8CEC-0138FE9ABC17",
    "Andrea Alonso - letter of good standing.pdf": "CF03D6FD-AE9D-449D-B098-680B5164AE74",
    "Andrea Griffin Resume 3.2018.pdf": "09082EBC-D5EC-4F22-8673-8D5DAB629C74",
    "Andrea Kassay LoGS 20Aug2021.pdf": "681F8203-1700-4FA0-A7A1-D7136ECCC5A9",
    "Andrea Parish CV 2022.pdf": "39057482-B6FC-4993-BAF7-4ED2194FB5B4",
    "AndreaBrzeczkowski_EffectsofRemi.pdf": "AC50651E-F89D-4514-96C3-165237892C3E",
    "Andreoli_Mia_GSLetter.pdf": "2985376B-2778-4128-8201-3306014716BA",
    "Andres Alvarez Pinzon CV - NEURO CV_Dec2021_bio.pdf": "26B25D14-9779-4764-B98C-2BBEE2B820F6",
    "Andrew Cutler CV.pdf": "24C43C04-D365-49C6-B7BA-F59FE3002C62",
    "Andrew Egladyous.pdf": "6A1F91BA-B11F-4877-9418-75680CD38C03",
    "Andrew Erwood verification2.24.22.pdf": "DDC05E36-65FF-40DD-A2C6-CBC343EABD72",
    "Andrew Fanous CV.pdf": "E36BF803-170C-4FFD-B94F-0345B5397342",
    "andrew G. Bouras - Letter of Good Academic Standing..signed.pdf": "4994E6D1-C512-4495-A549-616CD901C832",
    "Andrew J. Kobets CV and Bibliography 2022.pdf": "8D8737A6-AF14-4417-823B-023637A85AB9",
    "Andrew James Witten letter of good standing.pdf": "38E11702-4EE4-458F-B99F-DB5DF3759E7D",
    "Andrew Ku Good Standing.pdf": "8DBD8145-C153-4B74-9F5D-22359E04C49C",
    "Andrew Montoure_RESverif.pdf": "DA06EC41-1FD7-4BC0-9210-2EA0841AB6F3",
    "Andrew Powers CV 3.7.22.pdf": "E08C803D-613C-4621-8258-607C1090CE1C",
    "Andrew Powers Letter from Program Director.pdf": "F9291042-7572-43E2-9AE9-7977F0C2E172",
    "Andrew_Wong_CV.pdf": "1B76483D-C609-49B4-8638-7A9596C6FB0E",
    "Andrews_John_GS Letter.pdf": "9BA60237-75AB-4C2F-BA0D-DAC42A9FB22A",
    "Andy Stewart Resume CV 2021.pdf": "15943CEC-3B3A-42F7-9EF5-B012C1AA845D",
    "Ang Ya Lyn Samantha (Letter of Certification).pdf": "0BD58A4D-2154-4018-ADCA-1FA8649A05DD",
    "ANG Ya Lyn Samantha - CV (2019) copy.pdf": "4840DF76-4C6F-4962-AD1B-042BDF9ABCE1",
    "Angela Chang Enrollment Verification.pdf": "344D64EB-09E7-4458-8CD3-C9B0B8BF019B",
    "Angela Downes CV 1.13.23 doc.pdf": "636A04BD-B1A7-402E-9241-43F1FA63B3BA",
    "Angelica Alvarez - Letter of Good Standing .pdf": "FD63E18C-B9A8-474F-902C-4CC9C1973077",
    "Angelov L LoS SNS Memb MPS 09-27-21.pdf": "509D1B6E-EA03-4ED7-BBF0-9CB18B42EFD6",
    "Angelov Lily SNS LoS ECB ltrhd 10-07-21.pdf": "A0354E9F-B4DB-4FAD-B9FC-145927084698",
    "ANI CV.pdf": "983B4340-36C4-468E-81E1-7D759E8EEDFD",
    "Anirudh Penumaka-Fellowship.pdf": "F344F850-4BAA-43E0-A8DC-A3B146A1063B",
    "anish man singh cv pdf.pdf": "B7218360-075A-4C34-836C-85F1B12F57DD",
    "Anitej Palur UKM Student Confirmation Letter.pdf": "28EF3506-42DA-4BB2-8AEE-8D4DD94FC22C",
    "Ankitha Iyer CNS 2022 Poster FINAL.pdf": "BA381DA0-D395-4007-B604-E1588597BA2E",
    "Ankur bonafied.pdf": "22756020-0857-4890-8E9C-637C5CD5217C",
    "Ankur_Bajaj CV Dec 2021.pdf": "D5DB8B30-41B8-4FA3-9A59-CB566D0DCBE6",
    "Ann Liu CV.pdf": "D668B4BB-D633-4CD1-9714-DE75DDECF9C3",
    "Ann Liu LOR.pdf": "94DC07C3-08C1-4FEA-9B77-DF1E2A6510F1",
    "Ann Liu Personal Statement.pdf": "F0834C6F-D235-437D-81D3-28915F4C4A7C",
    "Ann Liu Support of Time.pdf": "C0577E18-47D5-4175-94E7-F905E035C959",
    "Anna Huguenard-FellowVerif.pdf": "A717333D-37E7-49A7-82B0-67D5CD28BF5B",
    "Anna Kimata.pdf": "F299D4A3-3EB3-452A-8EDD-9BA3B938DD5F",
    "Annual Meeting Attendance Cancellation.pdf": "F36DAB22-3FBD-4B06-AA6B-D62D6BC6387E",
    "Annual Meeting invitation (1986).PDF": "C389AEAC-3A70-4D69-9E4D-A921DD747386",
    "annual meeting invitation.PDF": "7986EF58-BB6A-4235-A369-9532DB99F2E7",
    "Annual Meeting Local Host Faculty Attendance.pdf": "C8DA2A79-720F-4D56-8802-49389F5CA282",
    "ANSAH_CNSPoster.pdf": "78F932BB-7121-4582-8425-92F75830AA70",
    "ant cerv surg treat CDM.pdf": "D063985A-5C1F-48DC-BF75-D330137DA560",
    "Anterior Spinal Reconstruction.pdf": "F2449A72-4BDE-415B-8A92-338FB80EEF0F",
    "AnthemMechanicalEmbolectomyLetter6712.pdf": "79E69857-D225-41DE-B859-3459303447E5",
    "Anthony Le.pdf": "A5ACFC8E-7C4A-4368-A1D2-BAD4979A0162",
    "Anthony Mikula-Fellowship.pdf": "A22C32EF-0B5B-4C3B-8652-4F4F91FE9259",
    "Anthony Mikula-RES.VERIF.pdf": "C7B2FBD5-91A6-4E34-9FEC-73B334B9B917",
    "Anthony Nwankwo_Good Standing.pdf": "337F4D27-5B23-49C7-807D-FF5675F964FD",
    "Anthony Nwankwo_Good Standing_.pdf": "8DB188A2-F5CA-41A0-A466-E90A26EBA3E2",
    "Anthony Piscopo  - Good Standing Letter -  2023.pdf": "F8678B18-DD02-4BAF-A90B-440C51132935",
    "Anthony Sin CV 11.2.2023.pdf": "C3275B24-55D3-4E04-B70F-54C1C127972D",
    "Anthony Sin LOR_9.11.22 from Dr. Madison Michael.pdf": "B08BB5EE-4604-4B9E-9658-4E4E6C04187D",
    "Antoinette Charles_Good Standing.pdf": "3DDF057D-9A8B-40BE-96D9-228C9AF527C7",
    "Anton SI Incidence 2021 (Table 1- Patient Demograhics).pdf": "CFCF4270-0923-436B-B09E-B5400A405247",
    "Anton SI Incidence 2021 (Table 2).pdf": "DB1CDDB6-4930-4937-A500-9896A5DF1FBA",
    "Anton SI Incidence 2021 (Table 3).pdf": "9F137612-6404-4812-9CF4-5534276B7563",
    "Anton, Gustavo Ltr. Good Standing 4-21-15.pdf": "A96708EF-9CA1-49FB-9CCC-A5546B14EEA4",
    "Antonella Mangraviti-CV-2015.pdf": "D207EB28-9473-44C7-BC8E-D66AEA0EBB49",
    "Antonios CV 042324.pdf": "C23EA289-78B4-4877-BD99-5F522A3CE964",
    "Antonios_NSTP Application 2024.pdf": "451FDBC0-5FB5-4573-B0B3-30F925104281",
    "Antron-Williams.pdf": "5ED8E86A-0151-44F3-A0E9-DDFCD03435B2",
    "Anushka Praveen - Foothill High School - Resume 2024 2.pdf": "A14B1AA2-0A8F-47BB-8990-B9097732A315",
    "AnvithaSathyagoodstanding.pdf": "BDB32CDF-F383-4CB4-A75D-2B31C95F9737",
    "AOlszewski.emailverification.pdf": "5C66E8A8-C643-427F-AD33-5DFFEA465DB4",
    "AOSpine member.pdf": "5813DB25-FCE2-4042-86F6-47CF957C4651",
    "AOSpine_Certificate.pdf": "7C723888-B2D7-42B0-88CA-E113E9857D62",
    "AP_GSL.pdf": "E926FE76-9CC5-4FA4-9011-2E07575FD90E",
    "APlatt.emailverification.pdf": "71A5A931-0501-4E5E-AFA0-CD1CAB45C925",
    "APlitt-verification.email.pdf": "9F028FE1-B189-406A-8530-5091B509D949",
    "APM_CV_2024.pdf": "D984369F-2FB4-487B-BF1A-D89A9C1178FC",
    "APOLLO ADMISSION LETTER DrNB KARI 2023.pdf": "140E9B15-D626-4A08-AED2-E110B3AF4B57",
    "appendicitis0110.pdf": "08C2FEEA-D879-44ED-8D6C-35D509C06C3C",
    "Applicant CV.pdf": "160E3542-A208-4553-9895-210311FF7DF9",
    "Applicant Video Submission FAQ v2.pdf": "3AA76350-DC65-484C-8B82-FE28BA173E19",
    "Applicant Video Submission Instructions v2.pdf": "187853C1-40B7-481C-9C2B-9FB5268669DC",
    "Application from old database - Jason Chu.pdf": "8ED296C9-28F3-4667-A992-08256CCFAF0A",
    "Application-2016-2017-PCPA.pdf": "26B59FE9-054C-4D82-BE2A-7430E308615F",
    "Appointment Letter.pdf": "AC071FD6-B873-42B0-81F1-16282BAB96A8",
    "ApprAIseye_Deck_Final_RDS_PDF.pdf": "6DB1A2D6-B19E-463D-BFB7-3BF55EB66FC9",
    "approbation+FANC.pdf": "0485A586-2145-4641-B8DF-60477EB7662C",
    "Approbation.pdf": "B5B81BE1-8E80-4C22-9150-E2CF31CD7171",
    "Appropriate Use Criteria Physician Letter 3.20.10.pdf": "DA0FB4C1-C667-48EE-B1CC-875F872A3FED",
    "Approving email.pdf": "F4E96FC4-8DFE-403D-B679-6E2FD98C1A81",
    "Apr 24Doc7771714014501.pdf": "98D2A778-61F1-4EBA-BE20-27234BBD551E",
    "April 2.pdf": "7A71635F-0871-41AA-8817-D06B41ECD6B0",
    "April 2011 EC Minutes_2011-04-12.pdf": "040AFD88-FCE2-4EBA-BB7A-979DBF89EDCF",
    "April 2014 EC Agenda Book_2014-04-06.pdf": "7C8E6FB7-E2BF-47D0-ACCC-049A590DAFCE",
    "April 2021 journal article_Final.pdf": "8FDD9ACB-399E-43AB-9553-6BC19738C1B1",
    "April 2022 journal article.pdf": "9F34AF10-18A4-4D52-B24C-B57032058D30",
    "April 2022 journal article_updated.pdf": "2E43E780-9930-45BF-9727-0C4D45098DF6",
    "April 2024 Journal Article.pdf": "EB491477-F925-4FE4-85D3-CF42484C10E5",
    "april_enrollment_sign-on.pdf": "CF8EE4A9-070D-4352-AC4E-1D5AF51652D1",
    "Aptamer Fingerprinting Poster.pdf": "7AADE641-5AAA-499D-BF38-C96C66540297",
    "Aqib Zehri-FellowVerif.pdf": "AD2F7899-3692-4003-A356-CBD6F239375E",
    "AR poster.pdf": "41CF17A9-3ED2-4EF6-9280-AC73B7B92389",
    "Arani Pronounced_ Ar-uh-knee Kulamurugan - Letter of Good Standing 1.pdf": "5F37F358-5EE2-49FB-AD6F-7A1006C50222",
    "ARANS Interim Meeting Oct 2021.pdf": "B04B09F2-05A6-4D6F-9F45-CBEA06332397",
    "ARANS Meeting Agenda - 2020 DRAFT 1-6-20.pdf": "DC6057C1-5663-46E5-8820-325212CAF30C",
    "ARANS Training Program Administrators List Jan 2021.pdf": "FA4284D6-C23B-4170-9900-2FC9975FD5DA",
    "aravind.pdf": "5079C49F-2083-4324-89AA-48799DF7C54C",
    "Archit Baskaran CNS letter.pdf": "10CB5B0E-F86B-45F0-86EF-5E731C08B33D",
    "ARDS ALI Fx and SCI.pdf": "9FAAC7EB-1DC9-4395-A8C5-64818C40BC7F",
    "Are there Gender Disparities in Outcomes and Healthcare Resource Utilization after Posterior Spinal Fusion for AIS - Peds Joint Section 2022 Poster.pdf": "EA32A323-E074-419C-99D8-4A0709ABFF43",
    "Arhin Martin-Certification of Enrollment for CURRENT student.pdf": "239A8B91-F94E-46AA-8D44-A4E92B293402",
    "aria's (dragged).pdf": "0F8E4125-54A5-4840-97D1-2A4024409C11",
    "Arian_Kolahi_Sohrabi_2022_CNS_Poster.pdf": "790A75CF-8BC5-4FAE-A445-40B55650AD6A",
    "Arif Good Standing Ltr.pdf": "7D7A245C-4B8E-403E-B07A-908E38FDF77C",
    "Aripiprazole Poster_Final.pdf": "585F6AF3-A318-43DC-89DA-DA046611D522",
    "Aristotelis Filippidis-fellowVerif.pdf": "01F90A09-2664-486E-8A61-516D9AD6D369",
    "Aristotelis Kalyvas CV CNS.pdf": "B7658C2E-73C2-4369-80D3-50DAFD2FA0A5",
    "Ariwodo-enrollment-and-good-standing-verification-pcom-registrar 2.pdf": "480B9305-9DC2-4F5B-81C5-0AA40889E42E",
    "Arizona Letter of Good Standing.pdf": "B7CC724E-26F0-4E22-A9C0-8129F501E175",
    "Arizona.pdf": "8309D4BE-4891-4DA1-AD4D-295E8CDAC8F6",
    "Arjang Ahmadpour-Fellowship.pdf": "D450B448-5262-459D-8F8E-D4D6726DEB54",
    "Arjun Adapa GS letter.pdf": "FF896970-9F21-4CC2-9A9E-99226ADFC293",
    "Arjun Khanna-FellowVerif.pdf": "F9D5F9A4-0BD8-4560-A00F-032FA85289A8",
    "Armstrong Stephanie- Enrollment Verification .pdf": "34EC3750-0638-43D7-B8DD-070364758A4F",
    "Arnett_Klugh_III_MD.pdf": "C98A7EAE-47AF-438B-B4CA-706A5015D1A0",
    "ArnoldZenzeleBhebheResume 1.pdf": "347C6371-91F0-41ED-A81F-002DAF2F5E4C",
    "Aroosa_Zamarud_CV_08022023.pdf": "1EAA8F4B-F8E3-4524-A07E-5FEE31C60BB5",
    "Arrighi-Allisan, Annie_Enrollment Verification MD.pdf": "E5B8C03C-789A-4F37-BB74-3F3F84BD20A4",
    "Arsalaan_Salehani_Letter of Good Standing.pdf": "0A9ED3FA-2B1C-4089-BFA7-A1EE1EE9260E",
    "ART CNS.pdf": "D6CF3A4E-61C1-4879-BD2D-954B673120E8",
    "Article The Risk Assessment and Prediction Tool (RAPT) for Discharge Planning in a Posterior Lumbar Fusion Population.pdf": "6B9F25EC-C2CE-47FE-BFAC-AE8FD4D53EC4",
    "Article_A Retrospective Review of the Outcomes and Utility of Percutaneous Radiofrequency Rhizotomy for Trigeminal Neuralgia Using Anatomic Landmark Guidance in Asleep Patients.pdf": "FC8B9F6D-9250-4DDC-B1E1-C815D991AEA5",
    "Article_Mechanical Thrombectomy in Acute Ischemic Stroke_ A Meta-Analysis of Stent Retrievers vs Direct Aspiration vs a Combined Approach.pdf": "41BBD86D-65ED-4D83-8DE1-D6CC9FBAEEA4",
    "Article_Outcome After Decompressive Craniectomy for Middle Cerebral Artery Infarction_ Timing of the Intervention.pdf": "23C6C994-EF2D-41C3-83BC-D7C16C1A1129",
    "ArtificialDiscCoverageDecision8-07.pdf": "5316EBC2-9F9A-4BB1-9BB0-D96C708C7E76",
    "ARTURO ESPÍNDOLA-RODRÍGUEZ.pdf": "B50A4FD6-5645-452C-B3B7-95B595712E87",
    "Arturo Robles Godínez-RESVerif.pdf": "87D7E33D-D521-47F9-8249-9F49157A03A7",
    "Arturo Toro Cert of Good Standing.pdf": "81200207-836C-4497-B1A2-27C96EBE56FE",
    "Arturo-Carillo.pdf": "FF0D5D3B-F418-404E-BE9E-E672CCA56D9B",
    "Arun resume March 2023.pdf": "77A0AF7F-EF27-4D65-B033-C43BA71068E3",
    "ARUN RESUME.pdf": "6FB70195-C2B2-4B81-9B37-C1840E2013FC",
    "Aryan - Percutaneous axial lumbar interbody fusion AxiaLIF of the L5-S1 segment_2008.pdf": "47E86D12-882C-4AEB-BE51-1EDCFB70F12A",
    "Aryan Wadhwa LOGS.pdf": "5D05BAE9-E4CF-4885-AED5-7FA4AA5B726C",
    "AS Pandey CV 11.23.pdf": "62EDBD94-F587-47E3-8CE0-D1197C5580BA",
    "ASA_deformity_table1.pdf": "12246518-8842-460B-B215-FEB6FA958D1D",
    "ASA_deformity_table2.pdf": "D612E241-8387-48F8-B550-7D487C9F0C39",
    "ASA_deformity_table3.pdf": "AA62C47F-9129-432E-8779-D35498340CAD",
    "ASAA as cause of SAH- CNS 2019 - poster pdf.pdf": "91BAB0F3-297F-4E87-87B1-4A2A2121888D",
    "ASacino.RESverif.pdf": "1BE24654-E1BA-4508-8EED-BFFFE108CE77",
    "ASAH_CNS_Poster_Gulek_9.7.2021.pdf": "578324AE-A08D-4A7A-91D8-692F6A5EC0D0",
    "aSAH_CNSPoster.pdf": "DDDA305B-42E1-4936-8FE2-B0582EB6DEEA",
    "Asfaw_Zerubabbel Enrollment Letter 1-4-21.pdf": "79173DF9-6154-4A14-8B8B-71F593C20EDB",
    "Asha Krishnakumar LOGS.pdf": "2BB00621-864D-45B8-8F8B-878BA918B997",
    "Asher LOR.pdf": "305497B3-BECC-4114-9130-C54CAD3E6F3C",
    "Asher, Tony.pdf": "308B7A99-C87A-4663-AB29-B9BA6FCD18E5",
    "AshishRamesh_ProofOfEnrollmentGoodStanding_202324.pdf": "457EDF26-D450-4272-8F05-CD7707E50BB2",
    "Ashley Barks CNS verification.pdf": "E72137E5-7F06-481A-BA4D-0C35259FFE3C",
    "Ashley Phoenix MD student Photo.pdf": "1DC6695F-7D6F-496E-A2EC-75EC785E6191",
    "Ashok Arjun- COMPLETE as of 29-AUG-2023.pdf": "DB5AF92A-932B-4EE5-8EC7-36063870CF53",
    "Ashok Asthagiri Letter of Recommendation.pdf": "5EF70112-48A4-4D45-939B-EF15EB7AA02A",
    "Ashok SNS letter 2023.pdf": "7E8A55A7-21DC-49E4-B99C-5F8D14128F94",
    "Asif Kabani Letter Of Good Standing.pdf": "89DE0D5F-CDE0-40C7-85E1-12AD8D0950D6",
    "Asil Sbeih CV October 2023.pdf": "BE1DA09F-B778-41C3-8F5B-CF48956930C2",
    "Asil Sbeih CV.pdf": "24461907-A7F4-42C6-BFC9-060FCDBCF65A",
    "ASivaganesan.verification.pdf": "0151503F-105D-4A86-8BBF-072F7F668A82",
    "Askari_enrollment_20180610_0001.pdf": "BD26CA1B-134C-4BC4-9EA8-80B7EC5E895C",
    "Asleep vrs Awake Final Poster 2.pdf": "0C925F19-77C0-4CFB-B028-CBA95BABA5A9",
    "ASM Coronavirus Provider Protecton Act HR 3021.pdf": "B0AFEBCB-FF98-40D2-B802-198930294170",
    "ASM COVID19 Relief Letter.pdf": "5932A0F3-CA40-4539-AA13-EF9DDEF60823",
    "ASM GOLD CARD Act support letter July 2022.pdf": "F9371A5A-9697-4311-981B-AF08632161D5",
    "ASM Health Workforce Shortage HELP RFI comments 03.20.2023.pdf": "FBB8BF0E-00D7-4C64-944F-F2719D15B9A9",
    "ASM HFTF Modernization RFI_FINAL 03.06.2022.pdf": "32FB3738-F94F-43CB-93F1-53F661F60CB9",
    "ASM HR 3322 Medicare Patient Empowerment Act June 2021.pdf": "41774D4B-4BFD-43D5-94A2-409F53FFB72A",
    "ASM Letter on 2020-2020 Federal HIT Strategic Plan.March 2020.pdf": "A855F83C-5F21-48AC-9C0A-24DB37DBC6A8",
    "ASM Letter on Payment Updates_FINAL.pdf": "12675F18-9395-4656-ABCF-A7A7A13EA3E7",
    "ASM letter to CMS on MACRA implementation.06.08.2021.pdf": "4A1DC4AD-24D1-4229-B1AF-0927F9336DB1",
    "ASM letter to HSGAC - Medicare Advantage delays and denials May 2023.pdf": "AA1FBC96-5B04-4177-B585-D78E29E7605B",
    "ASM MA Prior Authorization letter to EC 06.27.2022.pdf": "512B4C3E-7B1A-4BE7-A374-4561583B4DB8",
    "ASM MCIT Comment Letter - Final Nov 2 2020.pdf": "6A85AFD5-3F1A-490F-8686-09DFF0C3E50C",
    "ASM Medicare Cuts letter to leadership 12.21.2022.pdf": "635E60A0-A82B-4297-97A3-A32940E5D9C1",
    "ASM Medicare physician payment letter 09 24 2020.pdf": "F4B6D11A-357A-41D5-B67A-6126AC244396",
    "ASM National Provider Directory RFI Response_12.06.22.pdf": "34E44D98-5BE3-42E1-B520-F6586D07C634",
    "ASM physician payment letter to congressional leadership 12.03.2020.pdf": "B0259A57-B9DA-4C83-97D3-C241C7911CEA",
    "ASM physician payment letter to congressional leadership 2021.pdf": "D2A6215B-59E2-4A76-B4E4-03ECD8242E01",
    "ASM Prior Authorization HR 3173 Support letter May 2021.pdf": "E5324D37-7F59-4CA5-ABCC-4748674CCEF6",
    "ASM Prior Authorization S 3018 Support letter Senate 10.20.2021.pdf": "69B91BA3-F79D-4F13-BB5B-3FCA15C50360",
    "ASM Prior Authorization S 5044 support letter 12.17.2020.pdf": "D147FC53-2024-4B4E-B0ED-187664733CC6",
    "ASM Request for Congressional MACRA Hearings_FINAL.pdf": "4CAFB2E1-19E8-4278-B89B-C540769632FB",
    "ASM request to WM for MACRA oversight hearing 03.30.2021.pdf": "B6B085F7-3B27-4EDF-9697-57C09521DADD",
    "ASM Response to PAHPA HELP RFI 03.29.2023.pdf": "6703F976-5F0D-4D91-B69A-4AFCF773C69C",
    "ASM RFI on Elec PA_FINAL.pdf": "CCCB4B96-1418-465D-BC00-74D26C1D7056",
    "ASM S 826 Medicare Patient Empowerment Act June 2021.pdf": "29E0C121-41FA-4238-8195-A64E67F845B7",
    "ASM Safe Step Act HR 2163 Support letter April 2021.pdf": "0C665B5C-3F8F-46A0-AA0D-6FC751E8CAA3",
    "ASM Safe Step Act HR 2630 Support letter 05.03.2023.pdf": "830167CF-F4D7-4961-AC7E-43EE9B861094",
    "ASM Safe Step Act S 2546 Support letter Oct 2019.pdf": "F15B8E2C-319E-4BB0-BB1A-34C6B520EF54",
    "ASM Safe Step Act S 464 Support letter March 2021.pdf": "2EBCEC3F-6BF7-44ED-8235-0144E50ABB48",
    "ASM Safe Step Act S 652 Support letter 05.03.2023.pdf": "CE9BCC2C-22E3-4447-BB46-940DC9E1B538",
    "ASM Sequestration Letter Nov 2020.pdf": "FC1AA894-3A97-46ED-B73E-889C8F10BA16",
    "ASM Support HR 2474 Strengthening Medicare for Patients and Providers Act 05.03.2023.pdf": "65287D74-9513-46D8-A6D4-A2A1C0D77C39",
    "ASM Support HR 8800 Supporting Medicare Providers Act 09.29.2022.pdf": "EF70A430-2C2B-489A-AA05-8EEA9C4E9364",
    "ASM support letter for HR 1868 - Medicare sequester 03.12.2021.pdf": "B3E0490B-0F5B-47F3-AB6A-CB5E6CF11A85",
    "ASM support letter HR 2256 Resident Physician Shortage Reducation Act 04.21.2021.pdf": "CCBF59D5-E9E2-4DA7-BF1B-11657E2F3FA7",
    "ASM support letter HR 2389 Resident Physician Shortage Reducation 05.03.2023.pdf": "8E3C07F8-8489-4BA0-B1F1-B9465F25C079",
    "ASM Support Letter HR 315 Feb 2021.pdf": "3707A237-3672-4AFB-8C6E-041330A00F06",
    "ASM Support Letter HR 944 rural access to specialists Feb 2021.pdf": "E2ED445E-C099-4FA6-BE5B-2EC1144F03DB",
    "ASM support letter S 1302 Resident Physician Shortage Reducation Act 05.03.2023.pdf": "B539FEAE-58E8-4A7C-9CFC-7ED99EB8F58E",
    "ASM support letter S 834 Resident Physician Shortage Reducation Act 04.21.2021.pdf": "FC5B8866-65DF-482A-970D-7D946F65A757",
    "ASM Support S 5194 Protecting Medicare Patients and Physicians Act  12.16.2022.pdf": "9C853059-A14A-4BDA-B4D6-B8983FBFFCFC",
    "ASM Support Senate Medicare Sequester Relief Act March 2021.pdf": "45667FA4-3895-4BB7-A56D-53E880FDE5E0",
    "ASM-Medicare-Cuts-letter-to-leadership-01.16.2024.pdf": "D57ECD7F-DB71-4106-8992-6196D1C38504",
    "asm_2016_mpfs_nprm_comments_final.pdf": "C56EF23D-C287-468C-894F-F665DD2CE913",
    "asm_aco_proposed_rule_comments_finalsubmitted.pdf": "A5DE32EF-1E02-4692-9209-5A7887968E7F",
    "asm_letter_to_medpac_on_acos.pdf": "CEFDBE68-CDCF-415D-A33D-D0D731ED13FB",
    "asm_macra_rfi_comments_final.pdf": "AB45C2F8-B8B3-4BC5-BF4A-26EC02184327",
    "asm_network_adequacy_model_act_final.pdf": "9BBF8B73-5662-45EB-95CA-966C044F1BBB",
    "asm_physician_directed_applications_position_statement_update_march_2017.pdf": "BD78A194-2651-4D00-ACF7-7C689DED85D2",
    "asm_physician_directed_applications_position_statement_update_march_2017_0.pdf": "056369C5-E6D1-4CF6-B73C-A7C68214D02E",
    "asm_sfc_macra_testimony_final_052019.pdf": "C05E1B79-5538-4105-B02B-240FAE6B2438",
    "asm_strategy_on_reducing_hit_burden_final.pdf": "B1D34795-5AA8-4557-B646-0A855837CA14",
    "Asmaro CV 05-23-2021.pdf": "FCBC9A97-8073-47B1-A7FF-A4C61B4C82CE",
    "Asmaro, Karam -LR2.doc.pdf": "B99E48D5-5FDC-4A93-99AF-14C9FB9F426C",
    "ASmith.RESverif.pdf": "3BD38578-629E-43D1-B985-31B26792F45A",
    "Aspirin WEB.pdf": "C1EEB087-A4AF-41F9-8A5E-7AF8B8768DD1",
    "Assaf Berger-CV July 2021 ref.pdf": "E85D79FA-170A-4733-8EBF-6C5A913BFC91",
    "ASSFN 2020 Cordeiro Poster.pdf": "70A1CFF6-69F5-4646-A522-54B90CBDA7B8",
    "ASSFN 2020 Fernandez Poster v4.pdf": "307E6B92-4F20-45BB-BE69-14F6146CA94D",
    "ASSFN 2020 Poster ZC-FINAL.pdf": "85119087-65AB-4269-B226-E3B32C84BF2E",
    "ASSFN 2020 QOL vs Seizure Frequency.pdf": "03E85147-6878-439E-BE70-61210F401CE2",
    "ASSFN 2022 meeting .pdf": "2E651EBD-E4C6-4F95-8855-C52476C369ED",
    "ASSFN 2022 Poster.pdf": "B804C2DD-4287-4601-9841-BB66A65139A3",
    "ASSFN 2022 Wnt Poster.pdf": "6CC47117-3233-4F68-BFB8-ECE1E771DEF1",
    "ASSFN 2024 Poster NeuroOne ex vivo chicken 1.pdf": "834337D8-916F-4FA0-8439-677F0AB5F905",
    "ASSFN 2024 Poster NeuroOne in vivo swine Dr Gross.pdf": "B80A1084-53E1-43E4-A275-A692BC302B96",
    "ASSFN 2024 POSTER.pdf": "46F22E07-B7E4-490D-9F60-87A71DE112B0",
    "ASSFN Exhibitor  Industry Supporter Prospectus as of 03.19.24_ASSFN 2024 Biennial Meeting.pdf": "5DFFEC80-9AC3-47E0-8E94-1A396E55A105",
    "ASSFN Exhibitor  Industry Supporter Prospectus_2024 ASSFN Biennial Meeting 02162024.pdf": "E353D995-F4CF-4443-9454-BA19D1EDCBE9",
    "ASSFN Exhibitor  Industry Supporter Prospectus_2024 ASSFN Biennial Meeting 03122024.pdf": "CCCC79CD-CEF9-4ACA-9455-29D20C388F0B",
    "ASSFN Exhibitor Application_2024 ASSFN Biennial Meeting 02162024.pdf": "DB8757A6-22E9-4C0E-90BD-C707E4E4B010",
    "ASSFN Final.pdf": "2BFB5B44-C29A-475A-9FAF-44879EB248FB",
    "ASSFN OCD Clinic Poster (1).pdf": "1C5B9F2F-A5ED-448B-9B59-CAD9B2B013C1",
    "ASSFN Oura Poster.pdf": "5780A348-D8E3-4695-A07C-D759313D4728",
    "ASSFN Position Statement on LITT for the Treatment of Drug Resistant Epilepsy 091321.pdf": "895E049D-3CFA-48C8-8143-70A41A694819",
    "ASSFN Poster - Meditation.pdf": "39ADEE03-7E74-4D01-9AE3-5EC97E865553",
    "ASSFN Poster - Volumetrics.pdf": "1CECEC9D-1231-48F8-86D3-30CF7503DAE0",
    "ASSFN Poster 2024_42709.pdf": "D13DF635-953D-4F19-98D1-86733D879623",
    "ASSFN Poster 2024_42710.pdf": "5EBA0682-3A5D-41D2-A59E-F42B3AD580D9",
    "ASSFN Poster RNS.pdf": "1059D645-08D0-4BCF-8258-A6B4C8592AD8",
    "ASSFN poster v2 final.pdf": "FE94615B-6438-4E8A-A259-4259D8EF87C0",
    "ASSFN Poster.pdf": "41D2E993-3824-4B64-9B45-064695705841",
    "ASSFN Poster_HQ_VUMC.pdf": "F4D02AF5-9696-42C7-9971-0EC4E1CFCDE3",
    "ASSFN Poster_Nisha Giridharan.pdf": "A01C1F4A-B70C-45A9-BBAC-C04B88ADAB41",
    "ASSFN Poster_v3.0.pdf": "7EE19A64-C597-48BA-875A-372A67E97B51",
    "ASSFN Prelim PDF.pdf": "F29CC7C1-54CA-47C9-9B37-3280D00DDC22",
    "ASSFN prelim program 19-web.pdf": "30AAE5F6-18D1-4E10-BB76-52603FCDFF14",
    "ASSFN result table.pdf": "0BEE77F8-ED7C-419A-9907-9EE9A391487E",
    "ASSFN tRNS Poster UofL - MV AW JN PPT 4.21.22[100].pdf": "8A83ACCA-08C4-4E8A-9BB9-9B4701DF31EC",
    "ASSFN Virtual Program (2).pdf": "9D34F132-3A0A-41BA-AFB0-7DFA604B1E05",
    "ASSFN Virtual Program Final-4.pdf": "C6A1058F-CB0F-4A87-8E8C-9525BF5EAB7D",
    "ASSFN Virtual Program Final.pdf": "FEEA6306-3C7C-4A64-91D5-B3476A682D8A",
    "ASSFN Virtual Program_6_16.pdf": "D6438AAB-BA6E-4BA3-B0F6-8700BD03865F",
    "ASSFN Virtual Program_6_17.pdf": "20F8E4EF-E1DE-4464-8A83-9541451ACA50",
    "ASSFN Virtual Program_6_23-5.pdf": "0B86FAE6-C6FE-482D-B1E6-98BE0E91AC65",
    "ASSFN-104.pdf": "38DD561F-58AD-459E-87AD-861FC9F484CC",
    "ASSFN-205.pdf": "13E24AEB-02A7-4238-B745-134CB042913F",
    "ASSFN-Amirreza.Alijanpourotaghsara.pdf": "AAB1A3CA-223F-489B-9B60-4D2FAC57BB9B",
    "ASSFN-CNS Poster 2022.pdf": "EFE5D8B1-3733-4A8D-B6B8-98A04461C0D7",
    "ASSFN-TownHallAgendaMarch2022.pdf": "5933837C-CA99-417D-B179-2EABF12E87AE",
    "ASSFN.pdf": "5C10BB5A-90BF-4CD4-ACC9-5FE52DD71B79",
    "ASSFN_027865.pdf": "C8B6881E-8743-4544-9790-073A9ED79DCC",
    "Assfn_2020.pdf": "759BC934-A29C-4E5A-BBEB-07980CE0286E",
    "ASSFN_2020_ad.pdf": "EB8E441B-5585-41CA-B1B1-8D29E87D959A",
    "ASSFN_2022_0422.pdf": "BCC8A2E0-6076-41A3-8B8D-B6A1D8576DA1",
    "assfn_2022_draft2.pdf": "06B3253A-8DC7-4C2F-B487-1667F8C3BCD6",
    "ASSFN_2024_Bechtold.pdf": "84C3F8D1-884B-4BF2-AD8C-7A665073F353",
    "ASSFN_2024_poster_deepLearning_DJD_v4.pdf": "7DB76040-8438-4969-9713-79575349B305",
    "ASSFN_2024_poster_DJD_v4.pdf": "F42F0146-48DB-4C9C-8B2D-2C8AA0900AB2",
    "ASSFN_2024_Telkes_Phase-Amplitude Coupling in DBS.pdf": "2BF19BCE-B1E5-4F55-9157-FDCD37DCDB26",
    "ASSFN_ClearPoint Outcomes.pdf": "E9667745-20C6-46F4-B073-3AC3DFA536B7",
    "ASSFN_CM-targeting_poster.pdf": "464D0EF9-DBBE-47E2-8C07-4A3F95085A61",
    "ASSFN_Fig1.pdf": "079EE3A9-21E5-46DD-ABBF-120816950998",
    "ASSFN_Fig2.pdf": "4F923D36-F1F0-4F45-833C-53918B1C0B2C",
    "ASSFN_Figure.pdf": "79703C35-2950-4581-BFD0-59B752CEBD62",
    "ASSFN_JRyu_final.pdf": "548F1FC4-DDFB-42F9-89FC-5828DBA2FA22",
    "ASSFN_Kons_2022.04.20_v1.pdf": "B583A682-CBD5-4655-8BD0-310D33087711",
    "ASSFN_memory_optimization.pdf": "63AE9B71-72A4-40E5-8268-0C9BE3D1015D",
    "ASSFN_MinJaeKim.pdf": "52F82173-3EC3-43E7-BE45-9199B4E37B9D",
    "ASSFN_nT_V2.pdf": "2F99F1B1-42D5-4258-930C-4BA2EF1CA513",
    "ASSFN_OFC_DR.pdf": "3DB9E19F-4C73-44F5-B5C5-6B7FC082013F",
    "ASSFN_Poster.pdf": "7267CD08-53AC-4EEE-AB69-42FCE2E45647",
    "ASSFN_poster_2024_2.pdf": "76E78DB5-42D7-4D5B-81BE-6FB4B9C17B9E",
    "ASSFN_Poster_Connectome.pdf": "64866174-FA4B-4E2C-9CF7-CF2E2F09E36D",
    "ASSFN_poster_final.pdf": "969D281A-8398-4747-A21D-34C5108A304D",
    "ASSFN_POSTER_HFOs_20240415AB.pdf": "A4CD0B99-6E01-488D-B872-92FBB104B436",
    "ASSFN_Poster_MLM.pdf": "D1FE6E02-4A0E-43E6-B392-15C5129380B1",
    "ASSfN_poster_SeyyedBahramBorgheai _final.pdf": "8C8CD6DF-B793-42A0-B956-5B66E707954E",
    "ASSFN_Poster_v1.0.pdf": "EDB38117-7438-4A57-9DB5-BC33B65B8C0C",
    "ASSFN_Poster_v2.pdf": "F7D50860-A6D5-4C85-9E84-A5D498EE095E",
    "ASSFN_poster_ver2.pdf": "A23FF357-D309-4BA8-BF7E-6F2446F86070",
    "ASSFN_poster1.pdf": "A578EECE-98AA-43AD-80C3-CD244CEFE9A7",
    "ASSFN_poster2.pdf": "7AB45D00-A1B2-4AEF-9C87-DB7D490C73E5",
    "ASSFN_Table1.pdf": "9BF9EBBF-3125-4AA5-8549-AFA5C2D3BDC6",
    "ASSFN_Table2.pdf": "C0CCCA59-1BF9-42E4-B2EE-52991D9482F4",
    "ASSFN_Table3.pdf": "6CC368BD-AF1D-4CDF-93EA-65D18C58316A",
    "ASSFN2020 LITT Poster - FINAL TongAP UPDATED.pdf": "27BE176A-34B5-41ED-AE5E-1FF3040BC5B1",
    "ASSFN2020.pdf": "7045E287-DE70-4D3F-8E8C-C1F1F76C65AD",
    "ASSFN2020_kundu.pdf": "637E769F-36E1-4246-9AEA-79CB7C4D15E4",
    "ASSFN2020_NCvW.pdf": "CC1BC3FA-EFCF-4E21-94AF-68F710B97C7C",
    "ASSFN2020_Poster_GrahamJohnson.pdf": "4757A8DD-0657-4864-972C-8ACD36BD6EA1",
    "ASSFN2020_V2_pdf.pdf": "BA75C7D2-0029-4042-BE39-DEEE3A6D41ED",
    "ASSFN2022.pdf": "EF091BE7-9540-461D-AFED-0BA865D01A1C",
    "ASSFN2022_DLP_1.pdf": "8D718214-F098-48BD-8027-CFFB97B1F7E0",
    "ASSFN2022_HighVelocityDecodes_Poster.pdf": "3F4D05E4-89A7-42CA-AD7A-D8C6593BFEAF",
    "ASSFN2022_poster_ClaytonBingham.pdf": "25B44246-CEF8-4499-AAAF-7A3BA548B1F8",
    "ASSFN2022_Poster_DJD_v4_final.pdf": "E31096AC-8640-4AEF-A62A-A8A81413127F",
    "ASSFN2022_V2_pdf.pdf": "C08338CA-419C-4C8D-8F0E-0E066BDD3D74",
    "ASSFN2024.pdf": "1B992DA7-6E08-4082-8D42-4DB8902C163D",
    "assfn2024_poster_ls_final.pdf": "4AD6BBF0-40F3-4F51-B5DD-3E96E9F65582",
    "ASSFN2024_poster_presentation_hp.pdf": "2076AB42-2112-4B92-89E1-9BC78800C943",
    "ASSFN24 aperiodic poster v3.pdf": "4C93A05D-63C1-4F4E-9B15-2E1DDE401F61",
    "ASSFN24 Poster.pdf": "9DBB5405-8423-4013-B735-361BABED6ABC",
    "ASSFNposter.pdf": "BB866AD6-DEB6-488C-9E3A-6FAF410CE411",
    "ASSFNPoster_NCvW.pdf": "0BF61EA5-D2DC-4EF1-9CD1-32C8223E99BF",
    "Assign access to resident subscription.pdf": "62C35F03-D677-404B-B479-8ED1E9611D20",
    "Assignment_O_O.pdf": "4248875C-73A4-4C28-9EDC-59F936BC1EE4",
    "Assn Mgt Exec RFP 2017_2015-11-17.pdf": "6E137250-AF74-43F3-A880-C4F7CFC505CD",
    "Associate Director of Education.pdf": "0B98AB4D-134F-4629-8BE2-DD1585148809",
    "AssociateMember NSI.pdf": "DF145DED-439A-4062-9FEB-1B1F3BD86BDF",
    "Association Between IV to Oral Opioid Transition Time and Length of Hospital Stay After PSF for AIS - Peds Joint Section 2022 Poster.pdf": "77434023-AE10-43DD-B942-A3463A19352F",
    "Association of Spinal Degeneration Diagnosis by Sport Type in Adolescent Athletes.pdf": "621972CF-31D7-4C12-8018-A73C6D4EED00",
    "AStaudingerKnoll UA Acceptance Letter.pdf": "FE3E63F1-5130-4E68-ABAC-9907326925F9",
    "Asthagiri CV August2020.pdf": "BAD97DB2-1F78-4FC8-B972-5194B1CC66BA",
    "Asympt hypert2 Final BOD approved 2013.pdf": "41BD6E8A-7B5A-492F-865B-301AE41155B8",
    "ATai-emailverification.pdf": "468BE06B-9848-4378-B93D-FC589275BDCA",
    "Atalay verification letter.pdf": "80DBC00C-76BA-42FC-B54D-3F3988ACBF8D",
    "ATCs_Anaesthetic_Parameters_CNS_Poster_2021_20-09_right_justified.pdf": "A6CA770A-FEFF-41AD-8F91-0385053D75BB",
    "atestad0 2.pdf": "9ECC9D64-A99F-4126-999B-20292309F347",
    "ATESTADO DE MATRICULA 3 PERIODO.pdf": "BDD9DC01-CE60-4F26-B8E4-EDFA1ED820CF",
    "Atkins Verification.pdf": "06589F79-940F-44CB-A87C-E650B8C27287",
    "Atli letter.pdf": "AE4678E4-B134-48EB-807C-F942F21B1A1E",
    "ATRT_MB_CNS_Fig1.pdf": "56BAF28A-534E-4F35-8E1D-4F40626AD082",
    "ATRT_MB_CNS_Fig2.pdf": "D16740CD-03AD-48AB-BBB8-B23C17A0D37E",
    "Att Ait Benali SMN Member.pdf": "FDBA610A-A411-4857-90BD-2D6CAAA302B0",
    "Att1 2012 Neurosurgery Residency Certificate.pdf": "3542AF1A-B8F6-433E-9085-CA2792DB6958",
    "ATT42400.pdf": "159E8669-62A6-4EF1-B8BE-3A95C88DC97A",
    "Atta Boateng Jr Dean Letter 1.pdf": "3A9A8EBB-AA86-490F-882A-12B53909C208",
    "Attest Alex NCH.pdf": "5E0570FA-87EC-4E1F-A9E5-E448B96838C5",
    "Attest Ben Heskestad_20130829.pdf": "0276E2BF-2410-4898-8DFE-532CAFC83D8D",
    "attest neurochirurgie 2.pdf": "02C39489-5458-4449-A42A-4D6D753A705D",
    "Attestation Letter_Devon ODonnell.pdf": "ACF70398-B1BA-445B-8E83-1DF716D8AE9F",
    "Attestation.pdf": "8940D95A-B330-48D8-8CB1-602B27375A67",
    "Attestato Iscr. SINch, EN- Giordano.pdf": "BCD8B7CD-AAB0-4492-9645-724D855DC142",
    "Attestion Letter.pdf": "0216A188-E329-46B2-B3F6-45AF949C6679",
    "Attiah, Mark .pdf": "2B93BAF6-F7A1-487E-9B64-0976F69ED03A",
    "AttorneyTaxDeductionLetter.pdf": "CB95DBEE-004F-4294-9729-EA294F0EBE8A",
    "AttyGen re intrathecal pain pump Oct 2021 (Final).pdf": "43A0265B-69D4-4A3F-B2D1-20163D571B48",
    "ATV Poster (002).pdf": "C6BA1841-7DA5-4412-A119-88F8F324E71E",
    "AUC Program OMB Control #0938-1288.pdf": "832F6AA5-5419-4F93-A7C0-2ED80B14E1E4",
    "AUC Repeal Statement .pdf": "6C122C7C-B1AC-4315-9635-BFA2A557D9E0",
    "auc_sign_on_letter_final_090718.pdf": "E5C26385-706F-4C36-A139-959A987CB581",
    "Aug 2023 JMS CCF CV PDF.pdf": "3D36ACD8-F8DF-40E0-8E4C-BFB121B87AA2",
    "Aug 30 Doc 1.pdf": "049E66A8-38CE-4A17-80AC-B595826C0096",
    "Aug_Journal Article.pdf": "26715941-9249-4451-B42D-8C8D350B186B",
    "Augmented_reality_CNS.pdf": "7ACF6F5C-2F07-435F-AE31-141B7050CD80",
    "August 2022 article.pdf": "68DC15FD-460A-4392-AA06-7EB203A4BA22",
    "August 2023 Journal Club Article.pdf": "0DCA8212-4EA6-423D-9048-2F1F205A5344",
    "August journal article.pdf": "CF044F23-1674-476E-B9A2-8F5F74CBAF69",
    "Aurora Cruz-FellowVerif.pdf": "6209651A-3F99-410A-9686-C37748A8643B",
    "Ausman, James.pdf": "87A422FE-C283-4864-B810-1954F612D1E1",
    "Austin Bowering Enrolment Letter June 22 2022.pdf": "C3525BBE-8A7A-41F5-AC3B-CDD10D8D3DC5",
    "Austin Wyant_Letter Request Form.pdf": "0F2CC4A4-9821-47DA-BBBD-30DD390D9EB6",
    "AuthorIndex.59.pdf": "8686A795-E1ED-4A48-A90B-887837B82C5F",
    "authorindex_53.pdf": "23395161-0C2D-4907-85C4-4BA24A31E8B7",
    "authorindex_54.pdf": "51F0A3C9-C4AB-46C6-BDB4-16A7D693E850",
    "authorindex_55.pdf": "AE106BF7-BC7F-41E2-93B7-FBC6A4873DB3",
    "authorindex_56.pdf": "77A6AB6E-80A6-412C-AB2F-354A52A19382",
    "AuthorIndex_57.pdf": "BD09F8BF-69B2-424C-96A6-74A967C38434",
    "AuthorIndex_58.pdf": "1EC9D1E5-F6AB-44DF-93DA-680868D46589",
    "Autodichiarazione Anni Iscrizione.pdf": "7BD0C79B-62EF-48CE-8817-527FB25DBCB4",
    "Autoguide poster.pdf": "B6573B65-A735-40A3-8CA8-2F6331FA71E9",
    "aux prgrm 93.PDF": "E3692D43-F5B7-4842-9DE9-127E0DA7A737",
    "aux program 1999.PDF": "B602D2A5-C698-4082-9487-ACAF043D153C",
    "AV Quote  Sunday_3252022153624.pdf": "5A346ED5-6B10-4A80-A49D-0DFF277D71B5",
    "AV Symptoms in Shunted iNPH.pdf": "30E7BE8C-1E84-4042-8E33-3BBAB4419AFF",
    "AValeri.emailverification.pdf": "EB616F87-0D35-459D-B096-1182B841CC53",
    "AVellimana.RESVerif..pdf": "C6F4D037-3E8B-4586-B6C3-80281CBE7D8B",
    "average_rnfl_tumor_group.pdf": "3AD483C0-DFB1-4AE2-BA11-7C4A5F3E3872",
    "Avery Verification.pdf": "539F77E0-D0F3-4C61-B562-62DB84FD5B78",
    "AVF  for CNS.pdf": "68C02B37-0B5C-40F5-A2F5-D4DBE5BC7C1D",
    "Avila-CV July 2023.pdf": "8913B241-E202-4A22-90E5-7B37B89C9F75",
    "AVM highgrade natural history tables and figures.pdf": "E88A40A0-A01C-4BB1-9944-38C2767822E3",
    "AVM NSQIP Table 1.pdf": "3177B15A-66B9-4F88-9907-DC41AD49DBC8",
    "AVM poster.pdf": "E5D4E649-3CB2-47A3-B4F6-19E88D05EE51",
    "AVM RESECTION THROUGH A TUBULAR RETRACTOR-1.pdf": "509C9E77-151C-4443-BC61-3FB0D203B5D7",
    "AVM RESECTION THROUGH A TUBULAR RETRACTOR-2.pdf": "2C366C82-7BCE-49C0-85EE-3D43848763CA",
    "AVM RESECTION THROUGH A TUBULAR RETRACTOR.pdf": "5F9EECAF-92F7-420D-8E85-3ED48752F2E5",
    "avm.pdf": "A1994EC7-0159-45F8-A08C-AB6A03DF4FF5",
    "Avril Betances Letter of Good Standing.pdf": "60D1AA9A-9E95-477A-8496-9ED4DE899CBA",
    "Awad LoGS.pdf": "829B44C2-F49E-47BD-8C32-B88E507D8769",
    "Awad Verification.pdf": "6082C4AD-E54F-4D2E-9732-834ED25095AF",
    "Awad, Issam A.pdf": "F7716D00-537B-480D-AFEA-D983F3B54E23",
    "Awad, Issam.pdf": "676C8FE8-DA53-4860-B3AD-025B11895384",
    "Awad_AJ_CV_0224.pdf": "43F0622C-5590-4F82-ABD5-88107D550CA1",
    "Awad_AJ_CV_1123.pdf": "B87E2617-8614-4BC7-94ED-554B6C50D274",
    "Awais-2.pdf": "3CCCF9CE-F630-4845-9050-349A0958CFFF",
    "Awaiting examination.pdf": "54BAF30F-3C98-4A28-98AA-6567151318D1",
    "Awake Manuscript - Spine Summit.pdf": "16910D65-428F-468E-A217-55FE27687052",
    "Award Notification.pdf": "873CA8A5-4B5F-40A6-8B4A-769D602340E9",
    "Ayala Brown Acceptance Letter.pdf": "CC6228D6-56FD-44C7-B36F-0A0959593EEC",
    "Ayala Gonazalez.pdf": "1983FE3A-3FA1-4250-B0B4-92ECD09BEABB",
    "AYang.emailverification.pdf": "4D3420E0-5CC9-4B66-B117-8B4F9525C223",
    "AYC CV.pdf": "C0C503BB-0B63-4D60-A35E-70DAB3F756EA",
    "Aydin Sinan Apaydin.pdf": "EEC31598-98D5-4929-99CF-3682673CC9F4",
    "Ayer Amit Certificate Residency Training 06.23.2013-06.29.2020.pdf": "88055178-8346-47C5-B92E-3ADD39F75CCD",
    "AYu_Constantinos Hadjipanayis_SNS Nomination Letter.pdf": "B41F00C7-4AF2-4481-B049-3B25370B1666",
    "AYu_Joseph S. Cheng_SNS Nomination Letter.pdf": "B09F410C-E0D7-481B-8E19-C9DC3C7F72E8",
    "AYu_William Couldwell_SNS Nomination Letter.pdf": "959327EB-8BF7-40E4-AD5D-76530B2DCA9F",
    "Azad CNS LOS_Huang.pdf": "2E310168-4FEE-473F-89BE-5B0386C0B0E1",
    "Azad Tej manuscript.pdf": "1B268515-E18D-4CD3-BD4D-7E24BE9C301B",
    "Azad_CV.pdf": "3E7A058D-2AD3-4CD0-A7A6-B6645AA381E2",
    "Azam Basheer CV 2020.pdf": "A6460D20-8C0B-47B6-9867-B880225ADADD",
    "azeem CV updated 2021.pdf": "9EABF521-C5E2-4DAB-8E03-57EBE86F72B2",
    "Azeem Rehman-FellowVerif.pdf": "930CB15D-5E15-4677-B447-D238DB94EEA7",
    "Azghadi Adel_Letter of good standing.pdf": "0C27F802-7426-4F2D-A25A-4908AE6332AB",
    "Azim Laiwalla-FellowVerif.pdf": "70C4F7C5-BE4D-487A-8A50-B74FEC62611C",
    "AZMI LOR.pdf": "95FFB75F-D2AC-47C2-BDEC-B11FA6367337",
    "B Zeiban Dec 2014.pdf": "B6BC7EBD-5864-416E-8DDD-5FD71443E87F",
    "B. Cooper  Enrollment Letter.pdf": "D9C0C4B2-484B-4758-9CF0-9CF9ED158752",
    "B.Hoh Dodd CNS LOS 3.8.21.pdf": "8807963B-C1F6-4331-91E0-E5075A09C83F",
    "B.pdf": "875EF705-6188-4BE5-9E6C-EC6B5FE51944",
    "B.Vieira Nogueira-verifLetter.pdf": "E7A638A9-3678-4963-BFAD-1C7C079397A2",
    "Babadjouni_ver.pdf": "D3E72A63-FE02-4CEA-BE74-4D3E0C5A4F72",
    "Bachelor Certificate.pdf": "FE4A9286-B1B1-4DD7-9340-F69A54D753B9",
    "Background Check .pdf": "60EF44D4-7F95-4378-AD7C-475A9AB99895",
    "Background.pdf": "7385FF4B-6C5D-4878-8462-D11BAC0E65CD",
    "BackgroundInformation_Mehdi Zreik.pdf": "E70DB3BC-8F99-46A9-A43E-EC147957A2F8",
    "BADEJO_FWLNSchol_2022 .pdf": "38B30020-1AD8-496C-A58E-156DDAEAAD3F",
    "Badih Junior Daou-FellowVerif.pdf": "788ECB87-957D-4307-89A2-BD7AA339EA69",
    "Baedessi Verification Letter 92023 1.pdf": "AF18D747-6664-4BBE-AF76-E87469EBD157",
    "Bagley CV 10-2022.pdf": "E8A7513C-BD37-4A8E-9EDF-9850DFB9EFD8",
    "Bagley LOR CNS leadership Institute .pdf": "DEE3F387-BFC2-4E68-9420-05EBD9CA5432",
    "Bah Momodou Letter of Good Academic Standing 11.17.20 - aj.pdf": "682586A9-B74F-4D98-A8F5-7182CD3C4D03",
    "Bahie Ezzat  MD Education Verification Letter.pdf": "30A8D9F7-5F81-47D2-AA51-D8B93A319A80",
    "Bai_Halbert.pdf": "550DB9BC-CF04-4638-A763-D250E9718FC1",
    "Bailes CV 2019.pdf": "69D64D17-66BB-4BCA-AA10-3E4DDAC6F944",
    "Bailes_MYO_SNSmembership_LoR.pdf": "FB5249EC-801B-41D2-9C43-CDB75F54DE4C",
    "Baisiwala Verification.pdf": "1B97F784-95D1-4422-853B-35F524F28893",
    "BajouriZabiullah_good standing.pdf": "B2AB666B-12FD-4E48-A0C6-6DFAC845774E",
    "Bakare.pdf": "370ED44F-D4A9-45C5-85F8-1019E18FA2E4",
    "Bakhsheshian chief diploma.PDF": "157525F8-26FD-4502-9207-F1D724209EB5",
    "Bakhsheshian_CV_.pdf": "FF7D32EF-110B-496F-81FC-61A1F6B65994",
    "BAKWA_NENKIMUN_DIRTING_CURRICULUM VITAE_2023.pdf": "D3D8812A-8A23-4BB1-A708-056B1E5A10DC",
    "Balasubramanian Kishore.pdf": "325A7ED3-038E-49E6-9CF8-2EF6E8278066",
    "Balgees Ajlan Dr Sabbagh.pdf": "D5956D43-2A49-4FE7-8C93-F69EEF4E36C4",
    "Balgeess Resume Feb 2023.pdf": "80A0E5BE-54BC-433D-942D-39E996D73C23",
    "Balik V., Curriculum Vitae 6.9.2016 .pdf": "0F2CFBFD-2CE9-4629-A4CA-ECADBED8F3F1",
    "Balloon Figure 1 CNS 2019.pdf": "848CEBB1-B5C8-49E9-87AE-BB320C7FF710",
    "Balvindar Singh_Certification of ID NBME.pdf": "9AA29DE6-7912-4EFA-9E1F-217D330A65D1",
    "Bambikidis LOS Stippler SNS.pdf": "2B697C50-360B-4A93-8BE7-5C74A6D03D95",
    "Ban resident fellowship CNS 2019 letter.pdf": "48C9F556-C1CF-45E0-8D9D-5827A5129AB8",
    "banipalSletter0001.pdf": "0918204B-40F0-4595-BCD9-292659D6D7E1",
    "Bank-of-America-Roval-400-Live-Stream.pdf": "B97914E3-6E08-4513-95CF-81388E18C680",
    "Banu Matei .pdf": "2D42061E-9D5E-41A8-89DD-6197B6537A03",
    "Baqai CV.pdf": "635D05A3-9204-4C6A-8EED-0A4C70E042B4",
    "Barajas Llanes-Emailverif.pdf": "E494B0D8-6028-4262-B6F3-DCF01D8C6E04",
    "Barbarite- Medical Student Verification.pdf": "BB424E00-E552-4FCA-BAE2-82968421503A",
    "Barker William - Verification .pdf": "9464E174-2B4B-401C-829A-3981D7D18CB8",
    "Barkhoudarian CV.pdf": "8913F2A9-4961-421C-A38C-212FECB10A4A",
    "Barkhoudarian Letter Leadership.pdf": "CCC80349-C59F-4939-B261-1D037EBC1CC4",
    "Barkhoudarian Slides.pdf": "0D3AE747-055F-4D69-9810-7DAE24DF2963",
    "Barlett Alyssa LOGS.pdf": "80A300FF-0692-4186-8D27-D08ECE23EB67",
    "Barnett for SNS from J White Sept 2018.pdf": "3F555CC3-BE58-4208-90FC-AA23738B6D22",
    "Barnett Letter for Angelov.pdf": "BBE26467-FF68-43D3-A0E3-37A0F9483CF3",
    "Barnett Sam LOR SNS membership.pdf": "B59360A6-0E9E-4741-A010-73CF5A7D361E",
    "Barnett_SNS.pdf": "0B7A2E67-626F-4572-9D5F-8EE6F6A8FCD2",
    "Barpujari Awinita Congress of Neurological Surgeons.pdf": "FB479CB8-1E5B-4799-AE37-0C07CE6C3418",
    "Barrett Kaylyn. Ltr of Support.pdf": "09609647-AF92-478F-AED2-179F8ADC4C86",
    "Barrett, Daniel.pdf": "3EA5C903-B4C0-4BB3-92E2-4C7AD67DCBF2",
    "Barretto Verification.pdf": "951C2983-C56C-45D0-9627-5008A70F47C8",
    "Barrington_2180.pdf": "5910B956-E5B3-4ED4-8217-ED40A8731B5E",
    "Barrow Cadaver Scale CNS 2022.pdf": "98E86E60-C0B1-457C-B237-A2A7861145D5",
    "Barrow CNS Leadership LOS 2024-1.pdf": "2BBC9D2C-70AD-4684-9748-5A2A61F8C08F",
    "Barrow, Dan.pdf": "F4CEFA58-6140-4736-88D2-307AD260061A",
    "Barrow, Daniel L.pdf": "04C470CC-8B62-45A3-B5E7-5A6AE5B4D0E4",
    "Barrow, Daniel.pdf": "8152D999-6752-49D2-B558-2547AD79E471",
    "Barthelemy%2c%20Ernest%20Enrollment%20Verification.pdf": "9C8D38B5-AD3E-4842-BD2F-0E2E657E3F1A",
    "Bartlett_LetterofRef1.pdf": "103896C1-3835-48E3-B5E7-38C762074723",
    "BartoliE_ASSFN2024.pdf": "49C9B048-D7C4-4A52-950E-81F308D2F86B",
    "BartThaci-certificate-UCD-NeurosurgeryResidency.pdf": "F68AFAB9-68F6-40AD-A060-C003B6FEC5E5",
    "Baryseika_FWLNSchol_2022.pdf": "F43CD554-3212-443A-A965-03376C0E1770",
    "Baschera Verification.pdf": "C4E3F766-A747-4EC4-ABDF-39245BD53443",
    "Base_xml.pdf": "8A9EFA47-42B1-4FB3-8188-4FC1BC06E2D7",
    "Baseline Characteristics.pdf": "AED67135-CB17-4D7C-9195-41A8E823310F",
    "Baseline Patient Characteristics Table.pdf": "A39F843B-A43D-4992-BF23-6FE847FFBA98",
    "Basem Jade-2025-LOG 6 12 23.pdf": "1A245BB7-749F-4320-BD72-2B0ACD483FFC",
    "Basheer.pdf": "F698DD8D-7B80-48A4-AE76-0DB91697EBA9",
    "Basic Science Acceptance Package - Abhiram Koduru Reddy.pdf": "EA1A3B57-6E91-4073-BFB9-6443939E85D7",
    "Basim Mohammed A..pdf": "7B8B4B07-2312-471D-BC40-9AF13FA83586",
    "Basim Noor Elahi.pdf": "96C557A5-5013-4351-84B5-1DCF435408BC",
    "Basit CNS Data Science Scholar Letter.pdf": "26BDFCEA-8D40-4A66-B598-545D0A362B65",
    "Baskin 032667.pdf": "52E662BF-EF49-455C-8F6D-57A1974C7F8A",
    "Baskin 032676.pdf": "E042E9AA-A104-495F-BB01-84DAB8861EC1",
    "Baticulon RE - LOR.pdf": "B15BCF8D-0DF6-40E9-9CA0-ACDACD2D3651",
    "Baticulon RE - Research Projects.pdf": "A036A37A-0537-40FD-A6E2-657097E86C48",
    "BATS II Figure 1.pdf": "0FEFC247-DD2A-46D2-A2F5-FDFF87D5AF27",
    "BATS II Figure 2.pdf": "D93D3609-60C6-46EF-806C-8F81E27BA748",
    "BATS II Figures 3, 4, 5.pdf": "6C927353-ED4B-4180-AF56-318762973623",
    "BATS II Figures 6, 7.pdf": "8CF7C05C-F1B3-48C8-8BB0-C0211AF31F5D",
    "Batton_Aiyana.pdf": "4BA26A03-8D73-46DB-B165-750F41780916",
    "BaucusBillNewsRelease92109FINALVERSION.pdf": "03F70A00-4A9C-4D7F-BBD5-936668CD923E",
    "Bauer S 8-16-2022.pdf": "7A84E693-7D57-4D97-BAFB-6B6ED458D243",
    "Bauer_Andrew_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "97340692-14F2-426C-8972-2A23BF605E1A",
    "Bauer_Andrew_2024PositionalMembershipApplication_CVandBiography.pdf": "4509A8D5-1AC2-45F9-9D74-9A169DB3F7F6",
    "Bauer_LOGS.pdf": "34134592-8A69-4F14-B82A-7459D48F9713",
    "Bauerle Luke - Official Letter of Good Standing.pdf": "2FDCFB33-1CD6-4300-BD3B-F94AAB660FCB",
    "Bauman Megan M - enrollment verification.pdf": "774D7D94-E5C7-47A0-B806-08FBE5E205BF",
    "Baumann.pdf": "CB600B4B-4E50-4D85-8208-6FB66AD9C04D",
    "Baxter_Curriculum Vitae_October_2016.pdf": "A2E4AB3B-2CD6-4CAB-BA9B-9BF1234CF7A5",
    "Bayard Wilson-Fellowship.pdf": "1706107A-9CBF-4BF8-B985-CDC10EAF03D2",
    "Bayley CV 11.15.23.pdf": "E3AA3660-1F48-4367-80A5-CAFCC9715368",
    "Bayley_LOGS.pdf": "5903E53D-687E-47F0-A8C0-17BB4C9E2F04",
    "Baylor College of Medicine - Effendi Cherian and Sen.pdf": "DC00EC59-04C8-4740-ACD0-3E7FB6D86A2D",
    "Baylor College of Medicine.pdf": "DA98ED1F-2FB4-4A15-895C-977BE8A19690",
    "BBrahimaj.emailverification..pdf": "BF44AAA0-7A1C-40C5-BEF4-1059BD596DAC",
    "BBuster.verification.email..pdf": "5894CBA5-CDD5-4612-A356-A08574B7C312",
    "BBuster.verificationemail.pdf": "2272A7CC-8496-4A66-89F8-713E8B0CA557",
    "BCBSIL Enrollment Form.pdf": "004E5381-1F45-4DD5-A412-29F7B845B4FB",
    "bcbsmichigantranspsoascosssresponse08-07-2014final.pdf": "7753A12D-F914-4DCD-85E8-DEF3D955ACBA",
    "BCBSNCLumbarFusionResponseFINAL12-15-10.pdf": "ADF9E464-D022-492C-8EB5-94210EEACA3B",
    "BCiavarra Enrollment Verification CNS.pdf": "1B333CA4-1D18-4431-9257-94F8A7A9DF82",
    "BDawes.pdf": "FAF7837E-757D-4B52-A298-C54131682FB6",
    "BebokRefLetter.pdf": "6E6BB7F9-2CA0-49C6-9253-C3F241E32CD4",
    "Bechtle P. et al. CRANIS POSTER CNS.pdf": "7A0114DA-FB39-4E79-94A5-DFDF9258BA5B",
    "Becker Kathryn.pdf": "ED1AD036-CC27-47D7-810E-05100A5091B7",
    "Beckett.pdf": "9350E6CE-0D6D-4354-A285-FC59ECC1F3F2",
    "Behbahani_Rec.pdf": "31A9E8BF-DE73-4EDF-8A12-D8E95E2B9D50",
    "Behymer - Letter in good standing for Congress of Neurological Surgeons.pdf": "13BBFD4E-0181-421B-A309-17F2BACB276F",
    "Beige and White Contemporary Editorial Landscape University Research Poster (1).pdf": "D5BB1815-2641-4E6C-B5EF-3C512F8963B2",
    "Belcher_CV_May15.HMS.pdf": "8C76E26D-CE27-48B0-8E61-E9A27F44FFBE",
    "Belda GÜLSUYU (1).pdf": "86BFB2D2-BD43-4B1C-99E5-C42BC6124236",
    "Belgium-vs-Estonia-live-stream.pdf": "81D6BDD6-46B3-4D66-9899-D8AEB0C01252",
    "Belkhir Raouf Letter of Good Standing.pdf": "8AA5EFF2-123C-4D30-BFD5-3F48DE7ED1A1",
    "Ben Prout Letter CNS.PDF": "D981792A-D2CA-40AD-8E09-8A5ABAC64461",
    "Ben Strickland-RESVerif.pdf": "DAE03137-F43B-4094-8BD9-42DED18F8F47",
    "Ben-Haim_CV_July_2016.pdf": "3090E143-B185-4852-9E74-E0A62EA255AB",
    "ben_carson_hud_confirmation_release_030317.pdf": "64D4ED15-25E6-44F0-8363-C15330B5CB8F",
    "Benamin M - Verification.pdf": "89BDCD36-D5F5-4174-9792-C0429F230DBD",
    "Beneath the Blue.pdf": "D4D2166A-2207-4322-9F13-04CE1D0FE52D",
    "Benedito Amorim CV.pdf": "42E30996-2031-4421-AA6B-63074C3BA799",
    "Benefits Comparison 2024.pdf": "BB30731F-662B-4C80-A249-00DC45F9452A",
    "Benjamin Carroll-Fellowship.pdf": "A82A06BA-5493-41B0-851B-FD93F30C2D50",
    "Benjamin Himes Good Standing Letter.pdf": "F2507B67-3313-4F76-AD3C-D69809BF02B6",
    "Benjamin Letter.pdf": "C8F58790-1D34-428F-BAB4-38E87BE80340",
    "Benjamin M - Verification.pdf": "AF0D4F73-E581-4584-B865-F86DF71BBFE1",
    "Benjamin Motley-RESverif.pdf": "B13FDCF0-B985-4476-A396-B8554DD7B904",
    "Benjamin Motley_Verification.pdf": "153A4643-C738-424F-9206-17B089913504",
    "Benjamin Sherman.pdf": "2BF2E242-3310-478A-A55B-30D3B8868E48",
    "Benko Orders -SSN.pdf": "DFAC552E-9E27-4A08-A12B-E1030B88548D",
    "Benko professional CV updated 1-1-2022.pdf": "1D8B2FFF-EA24-4628-9EB2-20F5972DE768",
    "Benko signed residency training verification and evaluation.pdf": "5143F927-0B1B-47B6-BB16-DFE1E71F91CE",
    "BenShofty-CV August22.pdf": "98779D09-7E0B-4A05-8983-3C4B709776D4",
    "Bensu transcript English included_.pdf": "0B0D886D-0BC0-4BD3-9D1A-7125E1B64B29",
    "BentleyLoS-SNS Signed.pdf": "852DFF4F-AA6C-4B6B-90A1-32B56AF56747",
    "Benton CNS Verification Letter.pdf": "A16A242D-C26E-47B5-AB5B-E514357CA3A1",
    "Bera Bucshon Final House Letter to CMS - Global Codes 2022.pdf": "6A301725-54F2-411A-B4E3-D1A3C7EA9860",
    "Berdinov Farhad CV.pdf": "828D74F3-878B-4400-A593-B81269852F1F",
    "Bereuter Caroline.pdf": "F3C1142C-66EC-4C0D-9224-3C368E2CF20C",
    "Berger, Mitchel.pdf": "A4B3D7F6-5110-453D-8513-B3D669DB2121",
    "BerginS LOGS.pdf": "206BDAEA-4F24-4D36-AF6E-7BCEFBE89FEC",
    "Bergquist Julia_good standing letter.pdf": "DDD3B23C-6394-4900-BD72-F1B2B7F48BA3",
    "Berje Shammassian-RESverif.pdf": "E71CCD65-A7C9-4F1C-901B-7B40CBB86BCA",
    "Berkay Paker Poster_ 41814.pdf": "6AD7A551-0D80-4B88-B0F3-F69CFF935664",
    "Bernabei John Ltr. for CNS.pdf": "4802C2F5-DE18-4C04-ABBF-D3DBD071A71F",
    "Bernstock SNS NSTP.pdf": "B45A9874-7079-4FE1-9C79-9D5C9F4B970B",
    "Bescheinigung engl..pdf": "CF011100-07A3-4451-A6F8-7D95AD748EAE",
    "Bescheinigung_dr  buchalla.pdf": "ECA3CA5F-DC38-4931-9B05-2421087402A7",
    "betagngamygdala_poster_CNS2022_RC.pdf": "227531CD-F6E0-4C86-B29F-E59DA44013EF",
    "Beth Israel Deaconess-Boston Medical Center.pdf": "0DFB35C9-96C2-41A6-A053-27E3491EB9C2",
    "Bettegowda SNS 2002 BREM.pdf": "D07A6FA7-9542-43EB-8201-BC46B1649122",
    "Bettegowda SNS 2022 HUANG.pdf": "C1E08D42-7404-44E8-8FA1-589D8AA091C6",
    "Bettegowda_Support Letter Gokaslan.PDF": "9149C497-B341-49AD-A1BC-4B73BC0C114C",
    "Bettegowda_Support Letter.PDF": "53509D87-8C61-4DB5-A7AC-43C578C22082",
    "Beutler CV .pdf": "929D267E-AFCE-426F-A9AC-20AB5620E296",
    "BFiani.verificationemail.pdf": "9D69DB9B-926D-4EAE-A04E-4D9F0D743684",
    "BFS_Letter_0918.pdf": "24A6956A-7E90-406A-BF88-25069EBD4573",
    "BGrannan-UW_cv.pdf": "137E47BA-A00B-4769-AD94-0DF890D800C1",
    "BGrannan.ResVerif.pdf": "176094B2-4C30-4F4B-8D52-84B714B84745",
    "Bhalla_Tarun_2022_ver.2 CV.pdf": "63525219-C043-4EA6-B7EB-DA5302E39EE1",
    "Bhamidipati 220410 CV.pdf": "CD5C7AE9-A560-48BE-AD0A-1AA33DA66E1C",
    "Bhamidipati Akshay 4.pdf": "34A700D7-25C8-4E4E-A256-084071BC4EDD",
    "Bhamidipati from Chambless 2022.pdf": "EE560F99-FA4B-4607-A86E-1B077AD3569F",
    "bhamidipati LoR Ratliff.pdf": "FB2EEDE0-CE7C-44FB-947C-372D6A238EF1",
    "Bhamidipati Mentor Biosketch John Ratliff.pdf": "0A133EB3-F943-4602-8A9A-8CE251E98E8E",
    "Bhamidipati sponsorship letter.pdf": "63E3BF46-031F-44C3-902E-34D7E6120566",
    "Bharath CV- April 2021.pdf": "1FF013AB-5C79-4FC6-8843-1966A42A0E39",
    "BhargavDesaiCV_2024.pdf": "D7C308A7-2EE6-4E62-A9DE-6E479FE0030B",
    "Bhargavi Budihal Poster 1 NSC vs EVC.pdf": "B8B6D497-3BD8-45C9-B574-5CDA2491E43B",
    "Bhargavi Budihal Poster 2 TBI outcomes.pdf": "F06B5C39-8395-4C9A-A197-39D1F5FA0FD9",
    "Bhargavi Budihal Poster 3 Hydrocephalus.pdf": "5490A1B3-9868-4247-91CB-10D3ADF772C2",
    "BHartley.emailverification.pdf": "8D842365-72C8-458C-A82C-F4784F991829",
    "Bhattachan maya cv 2023 .pdf": "1742301D-726D-4C0D-A022-37812C81353B",
    "Bhebhe_Arnold_CV_2023.pdf": "E7859B1F-B1DA-454A-81F5-8D8AF9942869",
    "Bhenderu Lokeshwar - Research Approval.pdf": "BA85023F-9EEE-4E3D-BEF9-8BFCD2BB3452",
    "BHimes.Residency certificate_.pdf": "9BE0AA49-3EDC-4F2C-8277-9C9E8C897883",
    "BhimreddyM.CNS.pdf": "D9450092-CD1D-4F7C-81F3-61ADC337BC8B",
    "BHoh LOS.pdf": "D0254B79-8D23-4EDC-9737-F5545337650B",
    "BHoh Moor  LOS 12.12.23.pdf": "57CA6ECC-C4D8-4975-9CF4-6B7695A94E21",
    "Bi CNSCSNS Fellowship LoR.pdf": "4FFD68F2-9271-49C7-B8CC-4DD38D65F2C3",
    "Bi Letter of Support.pdf": "7E51FB52-BDC6-424A-95F5-1B6AC688CA80",
    "BiblioPoster.pdf": "D1F952C8-723A-4DF0-BD04-DB13C835206A",
    "Biden COVID Response.pdf": "6B4F3803-0E18-4E8F-A2AF-F82D8AEA90E5",
    "Bidirectional Electrical Neuromodulation with BCI-AI - USPTO - US10780264-B2 PDF.pdf": "4C86795C-AFB0-4ED4-8AE2-D79B83BF4959",
    "Bielinski - Letter of Good Standing.pdf": "9DF05E76-65DA-4EE5-8439-0BFFE334214E",
    "Bikash College Recommendation.pdf": "7AA48123-2D12-4D56-BAB3-B8EE73C1D72F",
    "Bilateral Directional Deep Brain Stimulation of the Globus Pallidus Internus for Spinocerebellar Atrophy Type 8.pdf": "C3338334-63E3-49FE-9F88-A616380C3AF9",
    "Bild2CNS.pdf": "FE808DCD-C3E5-4B14-AB3D-4AEAE32CCB2F",
    "BildCNS.pdf": "832E149D-CB7F-413E-949A-4090281C8DB6",
    "Bill Roy Ferrufino.pdf": "E58242BE-B8AE-4B0E-8E2C-975A40A652BF",
    "BILLS-111hr3590enr.pdf": "C1DB42AF-9214-4929-ABC7-93280B9A02F0",
    "BILLS-114hr34enr.pdf": "ED7E45E2-0B0E-4EF5-B739-AD4375B48890",
    "BILLS-115hr1892enr (1).pdf": "1194A9CD-4F90-4864-B107-5E7F73975431",
    "BILLS-115hr1892enr.pdf": "CFADB405-2537-4896-B2E1-6C49E9175DD2",
    "BILLS-115hr195enr (1).pdf": "4BD5439A-3867-42D1-B600-FEA419F82905",
    "BILLS-115hr195enr.pdf": "2711FFA9-706D-4B99-A6FE-1701F3A3113D",
    "Binder1.pdf": "708C5C2E-64DA-4664-BC44-3BF2E5AE2A49",
    "Binyamin_CV.pdf": "A0931F1D-6309-4457-B848-0BCB6ECBF0EC",
    "Bio CV JCT eng 8-2022.docx.pdf": "035FD929-B9B5-4C82-9EF9-629D69B7543E",
    "BIO Dr Asif Bashir PK 2023.pdf": "E0FEDFB6-9325-44B9-A5F1-80F57CB48BF1",
    "Bio Kullmann April 2021.pdf": "811083AA-5645-4159-A08E-BD6734F03900",
    "Biobank table.pdf": "B29EB477-BCD1-43AC-B45D-78453F8055D5",
    "BIOE564-AI_Policy-class4-2022-02-07 1.pdf": "7309D29A-6F3B-43F1-95EB-31F994C9D21C",
    "Biosketch - Mansouri.pdf": "48713CE6-4BC4-4975-85BC-06D52CB247D4",
    "Biosketch. Ahmed Mohyeldin MD PhD. 2022.pdf": "BCCE06D2-5981-4C4E-A853-E21E4EC2EFD6",
    "Biosketch_Fallah for Carson.pdf": "52DF93AD-3A25-4C88-95C3-FB1BA346C748",
    "Birra Taha CNS Data Science.pdf": "7EC37B9D-DE2C-48C9-A3CD-32297FD6D643",
    "BKafka.CV 2022.pdf": "6189DFB0-72A7-4103-91E3-F440AA766FF5",
    "BKafka.Residency Diploma_.pdf": "133A2902-04E5-4529-AFD6-7D3BE8320A88",
    "BKessler.emailverification.pdf": "99395111-B1B9-4AD8-8B99-F24600D57637",
    "BKK CNS abstract (002).pdf": "B4D8D643-5C0A-4467-9E63-0A482616F150",
    "BKundu_email.verification.pdf": "51A81845-8828-4BF8-95BE-75AF17FB50C9",
    "Black Lives in Neurosurgery Sponsorship Opportunities.pdf": "2CB87C8C-1468-4920-AD52-8993FC8084BF",
    "Black, Peter.PDF": "C95121AF-66D4-47E1-8AFA-F042340F47E3",
    "Black_Jordan_Good Standing.pdf": "9DF4152A-01E1-493F-9804-694BD59225DC",
    "Blackburn_Spiros_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "BB4E29FE-357F-4D22-BA0A-B54043911E99",
    "Blackburn_Spiros_2024PositionalMembershipApplication_CVandBiography.pdf": "E6D15CB8-A895-4A1F-BE61-107785345CE0",
    "bladder recovery.pdf": "A45A2D82-BFD6-42E9-B4A8-7A71DA931488",
    "Blake Taylor-RES Verif.pdf": "2F5C7869-19E3-4410-B93D-E7E9C2366957",
    "BLALOCK JONATHAN - LOGS.pdf": "E3A082B0-4BB8-4755-9E25-4A0A9584525B",
    "blance male perspective.pdf": "C0C58A39-B12F-45BC-BD2C-974585249D6B",
    "Blank 39.pdf": "522A241F-B96C-4111-8DFB-56B22AB813E7",
    "Blank Document.pdf": "7D82A5E2-03D2-46C5-86CE-1D4E5B602B13",
    "Blank Fitness Center Waiver.pdf": "E9174422-57D0-4F94-8BA0-2C8D393A9DD6",
    "Blank.pdf": "D15D7C6E-F2CF-40FA-A516-47C2713B85EA",
    "Blast TBI Digital Poster v2.pdf": "6B06A2B0-771D-472F-B5E6-0D21AA24086A",
    "Blatt, Daniel - Letter of Enrollment and Good Standing - 2.1.19.pdf": "C8528012-BE20-485C-A208-22AEC7F05998",
    "Bledi Brahimaj-RESverif.pdf": "43F1A33C-87CF-4778-8581-FCBD50C6E694",
    "BLESSING Melissa Good Std 3-12-10-1.pdf": "3739917A-C69F-4C68-9396-17166C8CE3EC",
    "BLiang_emailverification.pdf": "A919A652-2F8D-4AF0-9997-09935C846115",
    "BLieber.RESverif.pdf": "3B94EC1A-CA4E-4E03-822E-740BD4F7D24F",
    "Bloch CV.pdf": "C8B5AD50-40CF-4717-9EB7-AD51A0DFA0E0",
    "Blood Loss Table 1.pdf": "9D951A5A-4ED0-4493-863B-128D8259A43F",
    "Blue_Rachel.pdf": "388EC65D-99E5-43DE-AEC0-686DF5B1B2D6",
    "BMP Table 1.pdf": "8272EF0F-44E7-4BA5-9D21-DEFEB40F74D5",
    "BMP Table 2.pdf": "AD95B584-8922-4BC7-97CE-52AA29DC2B5C",
    "BMT poster .pdf": "26839D71-5783-473A-8677-F5BC0DF65ECF",
    "Boakye LOR for SNS Membership Nauta.pdf": "AF2DF828-25F6-4F40-B0C1-AC3FE44E9E58",
    "Boakye_CV10822.pdf": "9A71CD67-4017-485E-AA8D-29DD9CC7C049",
    "Board Certificate.pdf": "62D05EED-FB13-4BC9-AA86-83967D6E1F20",
    "Board certification .pdf": "9503FF1D-C843-4663-BCA2-8796692E8198",
    "Board certification translated - Ferraresi.pdf": "1FCB5325-5974-4395-ABAC-0866DFE5D56F",
    "BOARD CERTIFICATION_1.PDF": "653E0850-3196-47ED-9130-8463A79D8F9A",
    "Board Certified.pdf": "57B7B144-DDE4-4074-8ECC-477C6919191C",
    "Board Exam.pptx.pdf": "E3777382-A386-4CD5-A195-2796222065CB",
    "board neurosurgery licence Wei Lun Lo.pdf": "C7449C0C-2ED7-46F6-9002-78191850F67A",
    "Board of Neurological Surgery Letter exp 12.31.29.pdf": "3C4030E8-29E9-4B57-A731-169097F5B728",
    "boca resolute reliability combined.pdf": "7C114CC7-FD0D-4737-B1A3-72C4AD8E635C",
    "Bodden CV.pdf": "AA5938AA-085F-4E25-AC14-E32388600F27",
    "Bodden UVM.pdf": "7191E2B3-2796-4E6A-94C3-0E7B500FBE8E",
    "boehner-pelosi_ipab_repeal_legislation_letter_061515.pdf": "CE107E24-AC74-4A0F-B60A-F06EBDE3B026",
    "boehner-pelosi_medical_device_tax_0601615.pdf": "EA9249C7-9B3C-4793-8582-2F36E8F40262",
    "Bogdan-Marius%20certificate.pdf": "A0225471-15EE-40AF-9381-4751BF3AB23B",
    "Bojan Jelaca - CV.pdf": "C59D60B5-BC9C-42F3-AEEE-64ECBF1D89EB",
    "bonafide certificate.pdf": "FB3D8D90-3FB5-43DA-97B8-1419AFBE8AA4",
    "bonafide for cns.pdf": "FA51BA12-A4AF-4595-B7E7-9FF1E2D6A9D5",
    "Bonafide Letter.pdf": "C94E1370-3A06-47E1-AA6A-520B6045FB8F",
    "Bonafide.pdf": "647C6C9B-411F-4FCB-A058-0BF38809500D",
    "Bond_Evalina_MD21_CNS.pdf": "E36A1DF6-4CB4-4C55-81E7-9A72B9AB4BA7",
    "bone flap 1.pdf": "85CD42DB-67CF-4584-927D-6E9FD620E69A",
    "bone flap 2.pdf": "A65732B8-B1C2-4A7D-B4D8-19D0DEE85948",
    "Boney_Met_Spine and Frailty_CNS_Manuscript.pdf": "361DE4EE-B8FB-4666-9984-0E539A473E8B",
    "Book Preview.pdf": "F973F081-3300-41A3-B2B7-6073C51CD03B",
    "Bootcamp 2022_CNS Update_Final poster.pdf": "4B7AE64E-F6FC-4FFA-8DC0-32268674376A",
    "BootCamp.pdf": "79D3BE66-B374-4988-B9E9-012F24D1E215",
    "Borges, Larry.PDF": "25EDE74D-9890-496B-9B10-CC8E266D0F1A",
    "Borges, Lawrence.PDF": "F8A743E4-C6B1-41FF-B9B4-4C26185688BF",
    "bornali_kundu_CV_2024_num.pdf": "578F9EF1-E76F-44EC-A9D8-4618C2630685",
    "Borowsky_ver.pdf": "1AE174C9-07F8-404D-8879-F86ECBF30B8A",
    "BORR - 2021 BIO.pdf": "B0C01027-E17C-4BCE-BE32-24118647D962",
    "Botros - CNS Leadership LOS 11.6.23.pdf": "4D2A6B54-24D0-49E4-9A55-A9A4784505C6",
    "Botterbush Kathleen_LoGS_4-14-23.pdf": "79E119B2-A2F5-429D-B15A-90893850F1FE",
    "Bouchal Samantha M - MD-PhD enrollment verif.pdf": "72800A21-A8D7-4AD0-95F3-AECB5FF59437",
    "boundarytables.pdf": "47845EFC-5C45-4287-857E-2161B9840C0A",
    "Bourdages Verificaiton.pdf": "62A1BCF4-8A57-4995-B6A6-AFC52AAFF6C2",
    "Bowen Proof of enrollment CNS.pdf": "EFED7DD2-753A-4B4B-969B-47DED7006BC0",
    "Bowen Su.pdf": "0EB4B432-37F5-4D2E-B083-29D6C03088A1",
    "Bowers LoS from W. Couldwell MD.pdf": "9D4C1FC0-856E-4B53-A9FC-D28666E137B2",
    "Boyd 4.7.22.pdf": "1461CBDA-0977-4913-944C-34725AF7255C",
    "Boyi_Li.pdf": "31081379-2B8B-4617-B8FE-E455C641ECC8",
    "BPatel.emailverification.pdf": "20C976A8-3BBE-42F7-9FB0-2881FFA323B1",
    "BPNST_DSPN_Fig1.pdf": "522C74CD-276A-405F-AB6B-33124F39EAD2",
    "BPNST_DSPN_Fig2.pdf": "4807F25B-7B29-40C5-B6EF-1D3B11F34AB4",
    "BPNST_DSPN_Table1.pdf": "831B9A22-3A74-4CE2-B579-780980A25C2D",
    "BPNST_DSPN_Table2.pdf": "01FCCECC-7F88-4B96-84E9-54D915CE3694",
    "BPs-T0-Tokyo-Hiroshima.pdf": "2B124BF8-8521-4414-8E15-289934BB3A6F",
    "BRACIAK RESUME.pdf": "E91AAC21-D39E-4E02-AEA6-43A5CEF183E5",
    "Bradley Schmidt-RESverif.pdf": "D99A70EA-0539-40BD-AB54-B47E927EA994",
    "Braileanu Letter of Good Standing.pdf": "3AC5EA64-09CF-47C4-A833-D4C7EAFCA598",
    "Brain Mets CNS 2023 ePoster.pdf": "A4156E9B-3624-4D66-AABB-D3120211DDAF",
    "Brain MRI - neuroacanthocytosis.pdf": "5BE8B12E-F24A-4245-B600-31EF02053C98",
    "BRAIN POSTER.pdf": "37EFC4B3-C9DB-4FAC-BCFB-0C54EF07ACDA",
    "Brain Smear.pdf": "2E4717CB-7DD4-4DBA-8506-B5385A50BF9C",
    "Brain temp CNS 2023.pdf": "917ED403-EE42-4D2C-ACC2-DECF535E499D",
    "Brain Tumors Social Disparities poster.pdf": "32A1C9A5-B4AE-44FB-ADA2-63D4A367665A",
    "brain_mets_anti-epileptics.pdf": "766A09BE-AEAC-4A1A-BE11-5A35C464DB99",
    "brain_mets_chemotherapy.pdf": "53282774-D83B-4659-A87B-883D27208C32",
    "brain_mets_emerging_therapy.pdf": "BB5A1C6A-FC59-4B33-B591-0586180F3848",
    "brain_mets_intro_methods.pdf": "72012F24-0977-4F9A-BEF4-D86910D85BB9",
    "brain_mets_mult_mets.pdf": "37CAD5CF-7970-4814-9482-2A0B05B1D5B4",
    "brain_mets_srs.pdf": "52DF0B9C-C963-4F0A-870F-DA5291A080EE",
    "brain_mets_steroids.pdf": "9A924075-BDFF-4EF4-A91E-1451C0525A8F",
    "brain_mets_surgical_resection.pdf": "1CFA92A1-7A13-40E3-95DE-2B93F4B20D81",
    "brain_mets_wbrt.pdf": "6ECBFD9A-16A5-48E6-BD95-A4EBED1375E4",
    "BrainMet Primary Seizure Poster_PZ0831.pdf": "B1FB8E40-C3BF-4991-A0FA-7946F20D23D3",
    "BrainMetastasesTrtGuidelinesNewsRelease102609FINAL.pdf": "67F9DCF4-2672-4966-9680-31D400397BDD",
    "Brainstem_2023_AM_PosterTemplate.pdf": "CC0F9B94-5EF0-4143-A220-26CB6302A7BA",
    "Bramall Alexa - CV.pdf": "BBD3017C-A9CE-46FD-ACFA-42B6B0BF1995",
    "Bramall, A - Dec 11.pdf": "257C26BE-10C3-4452-B8E5-C3E6763C2726",
    "Branch Award Checklist.pdf": "6EEA35A7-A98E-4F74-88F6-B56DA07170A1",
    "Branch Award Cover Sheet .pdf": "F1F6550D-09E3-461C-A565-1A2EBEDC908A",
    "Branch, Charles L.pdf": "FBC35FEC-420C-4051-A9F2-C23E215FB692",
    "Brandon Edelbach - Enrollment Verification Letter 09.12.23 PDF.pdf": "14D0A258-0395-4558-9A63-EF86B9D18A19",
    "Brandon Pagan.PDF": "94AD47CC-E1B6-4D7D-B07F-4F36C164951D",
    "Brandyn Castro CV.pdf": "1888F775-7F72-4596-9C32-026E1E3FF9DB",
    "Brandyn Castro-RESVerif.pdf": "34E2ECB2-ADBE-4B87-8BED-816EADF5BBE1",
    "Branko Skovrlj.pdf": "1DA31D47-F41D-4FDF-B338-345B766A506C",
    "Brazilian Society of Neurosurgery certificate.pdf": "F486B79B-261E-4747-8E22-50E056C399C2",
    "Breakout Room Instructions.pdf": "DA89240B-8FB7-4612-BFFF-A7214C06C882",
    "BreckJones-RESVerif..pdf": "881D5765-739A-4CE8-A0DD-EC893E87AE09",
    "Bree Burks CV 8.31.2012.pdf": "192195A8-1E43-490F-B08E-46118137AFDF",
    "Breen Coalition Support Letter (FINAL) 03-25-21.pdf": "AD72FF90-EB31-4EE6-8094-04DBB1BE8BF8",
    "brekke.aans.pdf": "C3303694-E175-452F-B3ED-F81B3BA2002E",
    "Brem Letter - CNS.pdf": "B75AE7FA-D100-4FED-8E29-FC2FEE7123B3",
    "Brem, Henry.pdf": "033E1B56-F30C-4D0B-A576-4193EEA30571",
    "Brenda Enelis 3.pdf": "250F08C1-17BC-486B-AF0E-9CD0CA61D0E2",
    "Brendan Huang Letter of Good Standing .pdf": "74484B5E-9E43-49CC-A8FD-D6A331C3394A",
    "Brendan Klein-FellowVerif.pdf": "A8C63F06-C165-468A-BD35-FC04C0616D54",
    "Brendan Maloney Resume.pdf": "F15DEAF8-B194-4951-A012-9BF6C52E1473",
    "Brennan Matthew - Letter of good standing.pdf": "A9674A08-FDD4-4A4F-9E1C-D9EDE103E1AF",
    "Brenner.pdf": "498A9D0B-B514-4092-A1C1-424CD934E63C",
    "Brian Hoh.Murad.SNS nomination.pdf": "5FF30340-3580-4FB7-AE54-BC4E28029EC9",
    "Brian Lien - Letter of Good Standing.pdf": "BD5129A8-F241-4D58-A5BB-C1B9645DBFA1",
    "Brian Nahed SNS Letter.pdf": "A266D017-8660-4388-A878-E693DF011E6C",
    "Brian Park-Fellowship.pdf": "580C0F09-1EC2-4450-9A48-60024BE93C16",
    "Brian Westerhuis Letter of Good Standing-1-9-12.pdf": "01EA4BAF-3FB2-487C-B2D1-7093F6A2558D",
    "Brianna Donnelly CV CNS Foundation.pdf": "1B9BF2E5-2BDA-4E05-AC43-37170E10FF03",
    "Brianna Grant CV 2021.pdf": "F513FF44-A85D-4ABD-846A-9BCABABC9035",
    "Brianna Theriault - LOGS.pdf": "7270D3CE-31BC-44F8-BD9B-9E57ABFF8060",
    "Bridget MacDonald Letter of Good Standing.pdf": "C673769C-5EBA-4006-92EA-85930E4939E1",
    "Brief Bio.pdf": "FB498CBA-6754-496E-BE4A-C1C596DAB31A",
    "Brief description on clinical research.pdf": "083071F5-963E-4AE4-8ABD-BEB692F432B1",
    "Brief literature review.pdf": "2DE4A8E5-4578-4084-9C58-13D91FE64C91",
    "Brimley.emailverification.pdf": "0A4C1044-CA5F-442D-AE39-C5AFD0FD4331",
    "Brittany Staarmann-RESVerif.pdf": "D1176EDA-E8C4-4861-AD28-EB8862096FDA",
    "BRNB4220013E677_006086.pdf": "AFFCCE93-0FA1-48AD-9C39-E81D8F30CEDC",
    "Broadwater Meeting Services Proposal_2016-01-27.pdf": "EE31C2D9-0D91-472A-A252-F2DC3B979526",
    "Broadwater RFP 2017_2015-11-17.pdf": "C7D40FFE-0CA6-453B-8712-4F0DB6CD3E8F",
    "Brochure copy CVJ 2020.pdf": "D738441A-50B3-4176-9F14-42DB23E8A034",
    "Brochure copy CVJ 2020_2.pdf": "9A224B64-C737-47DD-80AE-B122BC561835",
    "Brock CV 1.27.23 ABNS application.pdf": "C5B0477E-AD48-479B-99E9-617B739582B7",
    "Brockmeyer Ltr of Recomm for Sayama SNS 09.28.22.pdf": "ABFCEC70-ACF3-4E1C-99DA-8C0B14DBFCCC",
    "Brogna.pdf": "A8F99FFB-9049-4CDD-8300-3B4690F15151",
    "BRomero CV CNS.pdf": "EBEF140A-B8C1-4C1C-9A51-0E024316D443",
    "Bronson Ciavarra CV .pdf": "B2E9354E-92CC-4624-8350-9BAC14D5FF0D",
    "Brotchi, Jacques.pdf": "AB061518-5EF8-45F5-ABB1-1ED087635076",
    "Brown & Black Professional Experience Creative Resume (4).pdf": "ABB66249-AA51-44CC-A1FF-BC5F4912F062",
    "Brown CSNS Fellowship LOR 04323.pdf": "2CC40E9B-610A-4A4F-BF1D-4375665FDD4F",
    "Brown Patrick -  Letter of Good Standing - 12.5.22.pdf": "A2A5731B-4808-4385-B91C-B7BB527445EE",
    "Brown S.pdf": "50E8048D-8ADF-460F-8696-6FA5B27367D5",
    "Brown,Desmond_Enrollment-Letter_04-08-13.pdf": "F26B85C0-A9C0-49E4-98CB-36FD2F0D42F0",
    "Brown_Nolan.pdf": "63278B3B-C55A-4319-9FA0-61816AA676CB",
    "Bruce Murgueitio Cover Letter.pdf": "37C471F0-10C0-4C7B-8E3F-FB5D6B2D80C0",
    "Bruce Murgueitio Resume.pdf": "2E144098-B8BB-4AEA-8103-A7464BE7F101",
    "Bruce, Jeffrey.pdf": "4585F2DB-2821-44E1-92C2-25855417B7B8",
    "Brunwasser Gillham Kim (2009) Meta-analysis of PRP.pdf": "72CF17AD-F311-4731-8FCF-2F5D8F6DFD04",
    "Bryan Ladd-FellowVerif.pdf": "BFCF6E60-D33E-44C5-AC43-210ADB43ACAC",
    "Bryan Torres Letter of Good Standing 2022.pdf": "3AECE772-C408-4CD5-8D9C-03753CD0BCC8",
    "Bryan_Zheng_MD23_GD_STDG_VERIF.pdf": "BF0B1B72-278C-4DB6-BB57-71FA5C031C97",
    "Bryce Bonin Letter CNS.pdf": "DD71728F-2393-4B0C-ADED-9C8E0CC7435B",
    "Bryce Bonin-RESVerif.pdf": "5EEEEAF7-55B2-4E9A-98CA-C666432DAF64",
    "BSchmidt.emailverification.pdf": "8D1F408A-628B-4727-B66C-FE7BD26EE099",
    "BSCMtables.pdf": "3EF5E899-F18A-451A-995A-8B754B6809C2",
    "BSN.pdf": "031788C6-2587-40EF-95DB-A4813EEB500B",
    "BStevens SP24 LoGS.pdf": "07AF7F51-DB05-491D-9508-A1CE46B26355",
    "BStrickland.emailverification.pdf": "7DA6F5D0-D076-4E01-92FB-89335FEDFC8E",
    "BTFC__2018__RFP.pdf": "8702A7D5-BBD8-48C6-8BD9-8D753793392C",
    "BTO abstract.pdf": "4DDB0ED3-7606-4833-94D1-4C76CEF7719D",
    "BTX-A CNS Poster.pdf": "15196167-2433-4A01-9E0A-E0159B95B9E9",
    "BUCKLEY ROBERT T PCS ORDERS(MC).pdf": "F858EA40-76B7-4D8A-AF65-5379D87227C4",
    "Buckner-Wolfson Emery - Letter of Good Standing.pdf": "E5A5133F-50D0-48D9-B2BB-7606A63AC534",
    "Budd Lucas.pdf": "75F1B01E-26DA-4266-B6B8-743C7FD20770",
    "Buddy Program 2024.pdf": "19E5A002-E5A8-4CD9-A6C1-64F1AC426650",
    "budget justification ABTA_final_2020.pdf": "02C4C422-C496-4C9B-850B-4C7EDA67EC0C",
    "budget justification CNS_1_15_2020.pdf": "AD6E29C8-3ACC-42CC-81DA-09CB881D14E5",
    "budget justification CNS_final.pdf": "97B350DB-0A0D-49D2-9253-3CD147BD7491",
    "Budihal verification letter.pdf": "03856B51-50EE-4E55-8C3A-0F25B09E7D4F",
    "Bui Letter of Good Standing83.pdf": "9702C509-9F3D-49B5-AED4-9866179C64E2",
    "Bui_CJ_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "77796CEC-E9F6-4063-85BC-763924F5CB44",
    "Bui_CJ_2024PositionalMembershipApplication_CVandBiography.pdf": "EDF32765-B38B-4FA6-97BD-07F9D171A481",
    "building resilience in neurosurgical residents.pdf": "4F239810-8FFC-4F8C-B403-67B6362737F9",
    "bulk-RNA data.pdf": "94A3F0FD-6653-4685-8557-E417F61AF8D2",
    "Bulmaro Morales Fuentes_certificate.pdf": "9FEFF1A8-4066-48EC-B88B-A7B5003344AE",
    "BUPERS 0369 Stamped ORIGINAL.pdf": "35CAEAC3-ADBE-491E-BEDC-4FFD4409B143",
    "Burch_Taylor_Good Standing Letter for CNS.pdf": "77D759AC-52A5-484C-B069-7B9C0CF03650",
    "Burchiel on Orina LOR SNS.pdf": "FA47AC96-B36A-4130-BBCE-13ADA87511EC",
    "Burchiel on Sayama LOR SNS.pdf": "90317885-BF1D-48E1-ABDC-5885F980F09C",
    "Burnout and Resillience - J. MacDonald.pdf": "C6BCA388-40E3-40BA-B72B-36CB1756759B",
    "burnout as buissnes case.pdf": "B17FBC16-7DA9-48C6-94FA-325F8FB70C9A",
    "Burnout for CNS.pdf": "932E17FA-A084-4C92-8147-76D8A8C0066A",
    "burnout malpract.pdf": "C5031A67-0797-4905-8013-B68F5D341915",
    "burnout mentorship.pdf": "5CCC31DA-F861-42B0-A6FE-D20968BC88B3",
    "burnout NES.pdf": "B4108665-F330-46B2-97F9-8B1F4F56E03A",
    "burnout neurosurgeons nationwide survey.pdf": "7129270F-07ED-4B2E-B32D-3B70649CDF1C",
    "burnout prevalence residents.pdf": "B6CD6EC9-4B1E-41B1-B39F-4DBCB405D7CB",
    "burnout survey US residents.pdf": "69AD7CD0-AE2E-459D-BBBE-DFE8CF95636B",
    "Burns gsl.pdf": "2B510368-DCCA-40AF-A9D5-09111317F35C",
    "Burroughs_R_Med_Student_App__Request-1.pdf": "830AAB37-8AAC-430F-86E3-B98EA0AC2E3E",
    "Burwell Letter of Good Standing.pdf": "BFB5292C-6301-4A43-865E-3FEDF6A203A2",
    "burwell_mu_sign-on_ltr_9-15.pdf": "68753140-5E70-4CD0-A53C-A3613AA4CC7B",
    "Busch Nisha Letter of Good Standing.pdf": "9187FB9C-1314-4FCE-96F5-2B0FF8BDFFC4",
    "Busche, Eve-Christa.PDF": "1DCE239A-D75B-44C7-8456-4ADD50E8C2CB",
    "BushchikEANSweb.pdf": "DB052C90-994D-4A86-A1A7-589D125E0B3D",
    "Bushe, Eva-Christa.PDF": "18D9D299-935C-4E4D-9B42-69BF42196BBE",
    "BUSMEnrollmentCertificate .pdf": "4047B7EC-4777-48EF-8CAF-818C16E0929E",
    "Buss_CNS Pall Care in ED 4.17.pdf": "B7E48B58-74A9-402C-A8B6-BAB6E0770783",
    "Busse_poster.pdf": "C45EE9FC-0A64-44DC-88AB-1E62DDC6EB66",
    "Butrico Casey_CV1.pdf": "FC7D000A-23EB-4FFA-8E94-D232A5657C4B",
    "Butterfield Jack 3.18.21.pdf": "03597BC5-B79D-4B71-ADFC-DE4CB74C6980",
    "Buvala .pdf": "23DE5215-0569-4DB1-A9A4-1F1679E0FA37",
    "BWALYA HAANGALA Verification.pdf": "F313DF81-DB89-49AF-A452-FC6CCB13695D",
    "BWHcert.pdf": "6FD8C3DE-4A69-4A59-96B4-9F077FEADC62",
    "BWS CV for CNS.pdf": "40BFFC98-74B8-4120-85F6-23AE8261ACC8",
    "Bydon Mohamad SNS LOS 10-30-20.pdf": "173A5204-0DD8-42F4-8E22-B66D85927129",
    "Bylaws Master 2019 FINAL (8).pdf": "C25B8108-02D6-4A0C-9DB5-FF74F600C4DC",
    "Bylaws Master 2019 FINAL.pdf": "B2B367F7-76A4-4AE6-B460-7383551F7855",
    "BYLAWS.pdf": "342AD17A-07EE-4859-9064-75F077A0C6D4",
    "Bypass database poster.pdf": "2C7393D1-9AD4-49CA-BA86-A80C6BBA6681",
    "Bypass_CNS Poster.pdf": "3D6BE516-10E6-4C5E-A80A-0B337DF33997",
    "ByrdAdatto & EMA Brochure-2020.pdf": "670C4DCB-27F4-4304-9700-39192FEC748C",
    "Byrne_Kayla_GS-letter.pdf": "AE061B2C-C3BE-46ED-81EC-AB0DC117FC9E",
    "Byron Cone Pevehouse Obituary.pdf": "C791E4B9-DC13-484F-A5A5-2B9F75205AF6",
    "Byron Hontiveros LOR MGH IOP 2021.pdf": "F0EB8374-EEA5-4627-AC9E-C9B3CA3DB2D2",
    "Byron Hontiveros NS CV 2021.pdf": "2FE4C216-1514-4CD1-964C-5DA677D3C409",
    "C Alvarez registration.pdf": "2F14CDF1-272D-4BF1-BB44-149650C6FF7B",
    "C Graffeo-RESverif.pdf": "4C805DC3-8DD4-4825-B4CB-55657153E51C",
    "C Mason.pdf": "D6A2773A-E759-4BA0-9267-A4B9D4B68498",
    "C Muh CNS LOR from CG.pdf": "56395B18-2EA7-40C6-9514-AC26EF5423EA",
    "C Spine Clearacne Poster CNS_V2.pdf": "ED20604C-3F5A-4E59-94B9-D4D54D400B8D",
    "C Young CV 2014 update.pdf": "2C89A4B5-F164-430A-884F-2911C22BE396",
    "C-Spine Trends CNS 2019 Poster.pdf": "629C908B-2F56-43D2-8425-8799D56E41F9",
    "C-Spine_Poster_Final draft.pdf": "2445E143-11DC-4EA5-A414-1B056A66A205",
    "C. Andrews - CNS Guidelines Committee LOR.pdf": "94836156-AFC4-4D13-9DB9-833AC46D62C7",
    "C. Bowers CV .docx.pdf": "102559CA-87FD-48A6-9105-C34D13311515",
    "C. Bowers MD CV.pdf": "49496CAC-1856-41E5-8AB1-D10E1B8E72F8",
    "C. Stuebe Letter.pdf": "45B128EA-C78F-4BB4-B1F1-4724EB275DDB",
    "C.V - Radwan Takroni July 2022.pdf": "8AE400CD-150B-423F-9483-C5EE8E329E95",
    "C.V. D.Feng MD.pdf": "8746F03D-42C7-478A-968E-728B0C49416D",
    "C.V. English.pdf": "D5812CA1-C747-48EF-A726-2BA7C68CCE69",
    "C.V1852556.pdf": "7C92ACB5-D01D-45F3-966D-83C3736C9662",
    "C.Wilcocks_CV_2021.pdf": "0B2E6D33-2ACC-4D62-A9E3-22327528EB5A",
    "c00d2c41-61b6-4cf8-aef1-b0c131ce8521.pdf": "C09EBE0D-A761-4B38-9B55-9B2EA224158B",
    "c06b6ec2a9b316ea0707715836dd4342.pdf": "F1A96BE0-E04A-43FA-AACF-51147E290B2C",
    "C1 QCT BMD Location Summary.pdf": "F6FB5D34-037B-4076-BC86-9A602DBD737F",
    "C2 Fractures Eldery CNS 2023.pdf": "1094E993-C72C-4B5F-90C3-FD7DA2EB30C7",
    "c2010br-01.pdf": "2D25D260-A75B-47E7-8009-4CB5094B23FF",
    "C3-C6 Table 1. Patient Demographics.pdf": "65CE5B11-0FAD-41C4-A9DD-AB2C1AE91EFE",
    "C3-C6 Table 2. Operative Characteristics.pdf": "E4F4786C-DC25-4994-8BF9-7DD18B44157B",
    "C3-C6 Table 3. Radiographic Characteristics.pdf": "DAB1ED8E-19FE-45D9-8D67-4BC29995DD94",
    "c320b213-63a4-4319-8e80-ca0d3e2ddabc.pdf": "5D58CDC7-6FC3-4F72-B996-8037AF86F91F",
    "c4611_sample_explain.pdf": "22A73404-8E8F-4BBC-A4CE-5703E1901AB0",
    "C5 stretch tables.pdf": "51D1B091-D26C-4A1D-9654-459897F26CDB",
    "Cage Type - 2021 CNS Poster.pdf": "711AFC95-1AC2-4DE9-B8C5-41AF63379878",
    "Caitlin Ritz.pdf": "14458B35-373D-46EC-B988-2CBB1E982AB5",
    "Calc WEB.pdf": "DAF5D75F-B9D5-4D8E-82A3-2B5120538F84",
    "caldwell_poster_high_density.pdf": "EA5E3CD4-3E18-4EEA-A526-B5EAD7FF883E",
    "caldwell_poster_rcs_cns.pdf": "DA4CE094-3A6D-4836-B2AB-30FFC54B9142",
    "Call for Nominations 2024.pdf": "20BD7CF4-08FC-4FDA-B8C6-B249C4659743",
    "Call_for_Nominations_2025.pdf": "42F29CC1-D8AD-49F6-BD77-A3736F840E38",
    "Callahan.pdf": "8BC11C3D-609F-4083-9B02-AAE6B588E9E9",
    "CalnanCNS_April2020.pdf": "235B5729-7F93-468D-A005-8E4E7BFACDD3",
    "Cam Sur Medical Society.pdf": "C70A863E-83C3-44E6-A310-2FB5E88B68E2",
    "CAM00543.pdf": "519FE7F3-2AE3-4F23-92C3-F06C720BA407",
    "Cameron Waites - HMS Enrollment.pdf": "2D61F7EF-C113-4F27-98C5-3B6AC08125C5",
    "Cameron Waites_HMS Acceptance Letter.pdf": "C95944C6-CEE0-412C-85D6-0166513A6FD7",
    "Camins, Martin.PDF": "02EB5469-CDB8-4044-AE99-B2C20FA99F4C",
    "Campinas.pdf": "0334AE3E-4FA7-4A0F-AB4A-E458341929AE",
    "Campos_Jessica_CV.pdf": "34D319F3-35E6-422D-AB40-E34802F56E13",
    "Campos_Jessica_Letter of Good Standing_2016-2017.pdf": "92C483B3-013D-4632-8F78-C3D473592064",
    "campus verification letter.pdf": "CAF29D93-E64B-46B7-8E7D-9477165A1955",
    "CamScanner 01-04-2023 14.58 1.pdf": "D7A48BDA-D8FB-4EC7-B062-209AC270A936",
    "CamScanner 01-09-2023 11.46.pdf": "C77390DE-A3D3-45A6-AB17-0ED53C02826A",
    "CamScanner 06-07-2024 16.43.pdf": "B614737B-9371-4743-B15C-9EF5BEA2F2CC",
    "CamScanner 07-20-2022 11.58.pdf": "4CC78414-F65C-4F0D-BA26-8AF21FD48CDB",
    "CamScanner 08-06-2024 22.13.pdf": "20EC4527-8B14-4024-8F6A-5F98BBE2AADA",
    "can_sarica_cv.pdf": "AECE38F9-FBF3-401B-A77C-52344D361EFF",
    "CANC Certificate.pdf": "B61D2F4E-64A7-4012-A285-417EC52F4257",
    "CANC.pdf": "8E6734F3-AC21-47AE-ABE3-F34485107DED",
    "Cancer Res.Liau.pdf": "9221C4F8-0D97-4D8D-A2ED-60F8263CE65A",
    "CANDY Nick _Letter of Recomendation for CNS.pdf": "58522216-B8CC-4DF3-B2B1-4FF79F5000F0",
    "CANI.pdf": "2CA42A2B-D6B4-4A9F-BD5F-BAB715C313E0",
    "Cantu Luke Enrollment Letter.pdf": "8922F82A-0E08-4F19-8104-F3207E7CB1C6",
    "Capek - CV.pdf": "083C8BA0-0EA9-4CA6-8422-23BB45670762",
    "capek EANS.pdf": "34628F46-683B-4BF7-B70F-8484A9F708AC",
    "Capek_ICAD_poster.pdf": "45DE7840-85EF-4792-9E5D-D4E2F9E8CCF0",
    "Captopril and MMP2 in gliomas CNS2019.pdf": "2ECBF8B4-3148-487A-802E-55DF74B0690D",
    "Caras, Andrew.pdf": "CC5D420B-ADD6-42D1-A0C2-D7141A39482F",
    "cardiac figure CNS.pdf": "A1456FC2-6B46-44F1-93A1-7BADA36984F0",
    "Cardinal Verification.pdf": "53C7B8FC-0ABE-46DF-9F6C-66EAD9C3A2AE",
    "Cardona CNS NTS 2023.pdf": "F1BB12DD-1F9A-42AC-911C-8618C3E80B0E",
    "Cardona CNS PAM 2023 -.pdf": "05C3FDD7-C590-48FD-9300-F222F44E5261",
    "Cardona CNS TOA 2023.pdf": "66D978A2-74D6-41D4-8F0D-5328C91B1E2E",
    "Cardona CNS Trolard 2023 -.pdf": "561108F1-89FD-4DDF-89D7-F1CB99FA9F7D",
    "Carey Preston - SKMC 2025.pdf": "E8FBDE6E-528E-4C96-AC26-2775ED18A688",
    "Carilion diploma.pdf": "AA67D52B-B021-41EC-9AC6-305535943352",
    "Carillo-Marhx.pdf": "9F25A145-1CEB-433E-8399-39892F192E98",
    "Carl Youssef-FellowVerif.pdf": "9A3E61DF-306D-4177-8F0F-3D4A382FA5EB",
    "Carlin Chuck.pdf": "33E3F691-674B-42E2-AE78-87E5D7C5A391",
    "CARLOS AGUILLON.pdf": "A0E286A0-FDD0-4E54-AC6C-AB568723D8B8",
    "Carlos Alarcon Alba EANS Certificate.pdf": "3DC31D93-7388-4230-8F7E-00771D9D383B",
    "Carlos David SNS Letter Heilman.pdf": "C93AE323-59C4-4FAB-AAF5-65854E5F7499",
    "carlosbaccin_md_cv_july_2022_i.pdf": "9052A58E-2286-4AA9-BF80-2FCF9C9C16F1",
    "Carly Farr_Good standing letter.pdf": "DD885FBD-6C31-4F0C-BD4C-B3B2529248C0",
    "Carly Weber-Levine Certification Letter.pdf": "8D06E57A-F37B-4D09-909B-0887E13136FB",
    "Carmel, Peter.pdf": "A75FF6D2-CF25-485E-9D3E-3AD589001EAE",
    "Carmelo Venero_Letter of Good Standing.pdf": "00DDCD18-C91C-4510-8F6C-ACAB615E7EC5",
    "Carmelo.pdf": "8759ED9A-8761-498E-BC4C-A994359FD0A7",
    "Carmichael Marc - Good Standing Enrollment.pdf": "892A9295-69AC-40A6-823F-94B8FE8B7EB6",
    "carnwath_959.pdf": "88573D30-842A-4AC9-9444-5EF8E7A76066",
    "Caroline Davidson Personal Statement.pdf": "92DD5CD3-ECD7-48BC-A806-F6E00E38420A",
    "Caroline Davidson Publication Fellowship CNS .pdf": "979B21AA-0DC4-4B1D-82E7-FDD4AEEB42AF",
    "Caroline Davidson Publication Fellowship.pdf": "011A379F-6F1F-4CCC-8117-C1B3B86FC1D0",
    "Caroline Elton Helps Doctors Heal Themselves - The New York Times.pdf": "BE679454-B390-4A8B-A0B3-ADB69E957705",
    "Caroline Hadley-FellowVerif.pdf": "2364C0AD-34EF-4149-93C1-BA630C3D8C43",
    "CARPENTIER- brief CV.pdf": "7D80A54C-5260-4741-A525-338B847EDCC8",
    "Carr, Matthew GS.pdf": "335B09F0-9A94-4FE7-BDB1-13225498BB4E",
    "Carr.pdf": "B35DE2BE-306A-40EA-A1D6-FACE09D6286C",
    "Carrie Andrews CV.pdf": "C8908854-C7F3-430F-A3A1-09017F2AF1AE",
    "Carson Amani 2022 Expected Enrollment 2.pdf": "0D3C4077-6EF2-480D-ACD8-6BA2C02D3E5F",
    "Carta Acep Dr. Osorio.pdf": "40D2E1B1-50F6-4800-A5F6-06E6CB5AAC17",
    "carta aceptacion medtronic.pdf": "A7E4717E-8876-4D8F-8E3C-5729887AEC8A",
    "carta Adriana.pdf": "36158AB4-6E7A-4468-BBAB-CED8D15B5C98",
    "Carta al Director CNS.pdf": "92387F08-68D8-4EAC-8F13-A8F3490CE417",
    "carta alejandro .pdf": "8E1F27EA-5947-4466-A695-2C578F97E651",
    "carta Amir.pdf": "3980A877-7A07-4167-A0FE-71B1A1D6B12B",
    "Carta Antonio Malaspina CNS Miami.pdf": "B9249C0F-7C1E-4C8F-A427-B2A11A9EC386",
    "carta Bruno.pdf": "26699EAF-6075-4A2A-AD0F-6687F2643022",
    "carta cns.pdf": "2CA0FA85-8E23-4EB9-B618-D6D23FEFDE24",
    "Carta de aprobación .pdf": "E06D72A0-7930-476E-A629-FC68BDE5E168",
    "CARTA DE PRESENTACION.PDF": "8BEE16B8-640F-44F2-964F-907816F3EFC7",
    "CARTA DE RECOMENDACION CNS FOUNDATION.pdf": "82C3C8FB-F90A-49F6-B58F-A6061E045A01",
    "CARTA DR RICARDO BERMUDEZ - MEMBRESIA DE SPNN.pdf": "D1BE52D2-D1DB-4013-8BCE-D8EBD7287EB1",
    "Carta Juan Sebastian Solis.pdf": "A663C0E0-8740-4786-AC3F-27DC69D502F6",
    "CARTA LEONEL RAMIREZ-emailVERIF.pdf": "A668A454-522E-406C-964E-C0A9C1FCED73",
    "Carta Monique.pdf": "EEF26DA7-6AA4-44BF-95E8-1449B74BCC6F",
    "Carta recomendacio´n dr. Toledo .pdf": "E9D04EFA-2F21-4787-894F-F7368EEBFBB0",
    "Carta Título SBN.pdf": "492A82B7-9C4F-42DB-BEE2-A27D91842E4B",
    "Carta.pdf": "0ABBC4B3-E23F-45BA-A55A-D3D8B2769454",
    "Cartas recomendación.pdf": "A7C69F00-6C8B-471F-9B14-333648332BB4",
    "Carte de membre.pdf": "CA54ECC5-E877-4D3E-ADD3-8AFD38258B48",
    "carte residenci.pdf": "50BDD0FA-9E3D-4087-8621-4D50ABDEB08C",
    "Cartel cocci.pdf": "CBF944D3-C951-495B-9FCF-7B37556C9A82",
    "Carter, Bob.pdf": "19C5CAD7-2B7F-4A01-8434-9586B32C23BB",
    "Carter_Ashley_ LOGS_9_23_2021.pdf": "A75627D6-6BB0-416D-805E-512C1C28CE73",
    "Carter_Gerard.pdf": "7461B983-7398-4111-8AA2-37AD8214C12C",
    "Cartmell%2c Samuel - Good Standing - CBC.pdf": "F89C8B54-3980-4AA3-B035-E9BB94B29B18",
    "Caruso_CV.pdf": "55C5156E-52F1-4EE1-8D2F-A145863E5FC4",
    "CASE 1 CONT.pdf": "672772C7-D2B7-4BE3-856F-0037F2522EA1",
    "CASE 1.pdf": "55EA636A-D843-4150-88AA-A4D7D1999046",
    "case 2.pdf": "AF2A9621-2FB8-4F1C-B878-3FCDD5CD04F4",
    "Case 3.pdf": "EE86AEA4-AB58-475D-B35C-95187C38DD90",
    "CASE 4 CONT.pdf": "875FBD48-C3C8-4374-AB42-33EB39B1C22E",
    "CASE 4.pdf": "A5A4EF59-C0A2-46D1-AFE7-918B14C0C6B7",
    "Case 47-year old disc surgery .pdf": "EDF1563E-BF32-40CA-8796-BE5CB0F41156",
    "Case Report nasogastric landscape table 2020.pdf": "39FB7E90-4343-45FD-A025-1E3DD6D3CD92",
    "Case session 3-TO.pdf": "7EEBC82F-85A8-4057-9AD5-F9975340BDB5",
    "Case Western Reserve University - University Hospitals Cleveland Medical Center.pdf": "D5867045-625D-42CC-88E3-0E0F374E0AAF",
    "Case Western Reserve University-University Hospitals Cleveland Medical Center - Alonso Cox Smith.pdf": "95F84F48-EA23-400D-A9BB-710CC17BB55C",
    "Case_Log_Guidelines 4.16.pdf": "C0181A68-6EFE-4EA1-927F-6DEB84CE6C5F",
    "Case_Log_Guidelines 6.19.pdf": "AC64FF70-0D01-49E5-A793-05101689F823",
    "Casillo Stephanie_CNS-Poster-Template-48x36 (2) (1).pdf": "A48B5506-DECD-4AEE-874D-443E4C86C1F0",
    "Casillo_Stephanie_Good_Standing_Letter.pdf": "8DDF8186-357C-4F4B-A4F7-D69686720710",
    "Caskey.pdf": "70D88473-4E0A-4CEF-94BE-A75AD59DA0AB",
    "Caskey_CNS poster_2022.pdf": "0B3321C7-C11B-4A6E-9CC0-FEDCC5B42347",
    "CAST Financial Reporting Timeline.pdf": "6F218128-DBC5-49F7-8D9C-5E31EBE14EFF",
    "CAST-Charter-FINAL-8-2015.pdf": "D4F5DD18-D67C-4F88-BA93-C5F187DBD5D3",
    "Castelluccio membership.pdf": "CE3CB7F3-9BB0-45F3-AB37-2A646A9D81E9",
    "Castioni_Enrollment_Letter.pdf": "08ACABC7-103C-4055-A16A-38DE2B6FD2AF",
    "Caston Rose_enrollement.pdf": "8E89255F-23E3-4B19-9B0C-BCC0A3B7FAB8",
    "Catalino MP_CV Feb 2023.pdf": "BB8E1756-B060-4666-9855-D1C1D6E4A72F",
    "Catherine Peterson-FellowVerif.pdf": "51764F35-90F3-4FA4-857B-2FD8399CFAE9",
    "Catherine Pierce CV 2020.pdf": "7EEB29D7-F2DD-462F-92F0-4E9B65F0EE00",
    "Cause_wo.pdf": "54533DCA-566B-4520-A08C-2976802D1C76",
    "Cavalcanti DD CV July.pdf": "4C4CE4FC-D1AF-4066-8035-46B0DED2298A",
    "Cavaleri_Amygdala_Beta_DR_ASSFN_2024.pdf": "9DB39769-8AC4-45CC-B662-3DFAC48F194F",
    "Cavaleri_Insula_Beta_GNG_ASSFN_2024.pdf": "545AB622-4C88-4BC7-8D49-7A81B6DEA039",
    "Cavaleri_Optic_ASSFN_2024.pdf": "A113B4D5-4BD1-4DF9-9915-F1D29F4E0D29",
    "CavMal Journals Tab;e.pdf": "4CEEFD78-CDD6-486D-BA43-D1D32F42C3E7",
    "CavMal Table 1 top 10.pdf": "424966DC-A476-4C91-AD69-AD89F864784B",
    "CBanerjee.emailverification.pdf": "FBC2B675-4C92-4EF2-98AD-50BD6AFB0C65",
    "CBT embo _ CNS 2023 v2.pdf": "29EE4AF7-33E3-4FDA-815D-476336BDBB39",
    "CC accuracy figure.pdf": "6333350A-410F-4CA5-AFF6-46C098188D4C",
    "CC_Table1.pdf": "1D0CDA7D-18D0-4B02-8961-A09CCE6C20E3",
    "CC_Table2.pdf": "EA42F85F-DFF6-4DDB-BEAE-C3D0224814F2",
    "CCE_000357.pdf": "AD9EB753-8750-454C-841F-16DEDD4D3DA7",
    "CCF03242013_0000.pdf": "AE22F3A2-2442-4335-9355-B2999431BE87",
    "CCF08242016.pdf": "83A2B15A-F3DC-491F-86F2-28D093F0A156",
    "CCF10232015_00000.pdf": "7EA84681-91D4-4822-86D2-833412813342",
    "CCIC Stark AKS Letter 8.7.20.pdf": "D8D7CBB2-D74C-4FD3-96F5-927F942CF3D3",
    "CConner.emailverification.pdf": "2102E8CC-0CCC-40EE-BC67-B4B517C33DFA",
    "CCR2-5 CNS Poster.pdf": "4A24FB15-440E-4F2A-8843-9594ABBE274A",
    "CCscore_manuscript1.pdf": "A6E061DC-4428-480D-A9B0-20AEACE1D459",
    "CCT BARONE-RESverif.pdf": "1A723756-D486-4DE4-8579-51350594774B",
    "CCT letter.pdf": "1EF89387-2C19-4119-BE50-A510B2ABAE4A",
    "CCV-GeorgeIbrahim-CIHR_Academic - August 2022.pdf": "827AD32C-1EC9-4A9E-9B2C-D5FAF8B78E4D",
    "CD SNS award nomination 2023-24.pdf": "0B0D4916-80D9-4F8D-B3BA-B4D2B570CA8B",
    "CDC GVP Research Funding Letter House_03_30_2020.pdf": "E8E7D86F-1317-43B6-BE93-3CBA6B7C72A2",
    "CDC GVP Research Funding Letter Senate_03_30_2020.pdf": "DB3887E7-3538-405F-B9EA-40D6F98003EA",
    "cdc_opioids_guidelines_comments_011316.pdf": "AF41F91E-0637-48C5-82B7-DB8C1F5AE55C",
    "CDibble.emailverification.pdf": "561F7BC1-8BB6-471C-AFED-45516855BED2",
    "CDL CNS 2023 Poster.pdf": "5727385D-ED18-428A-93AB-D748FA168A34",
    "CDUEdVerify_ANguyenV2.pdf": "CC5EE9F1-4C1E-4EB3-B564-CFB359AA3557",
    "CE MRgLITT v Crani digital poster.pdf": "E4312C0E-19D1-430B-B8FE-D232056E630C",
    "CEAvsCAS.pdf": "D5CCE3CB-E66D-4FC1-8CE9-736CB07A8629",
    "CED Poster for CNS22 v2.pdf": "4D9FD1EA-5700-4ED5-A129-E4ADAF80CACC",
    "CEDULA_NEUROCIRUGIA.pdf": "A1CEDE60-B796-4276-9A63-4FF17B1CEB54",
    "cell-type enrichment.pdf": "53420655-E41B-4A73-9033-1359C2A83727",
    "Central Cord Octogenarians CNS 2023.pdf": "6F0F6294-779C-44C3-AA1E-BA1B5134809B",
    "CER.DI ZHU.pdf": "90BFEDC3-C6DF-499E-972C-645D3217E317",
    "CERADMISPREGMEDIC00020304510-9479273_S.pdf_S2.pdf": "4817D3C6-38F8-4469-B421-AD2D2CF299C1",
    "cerebellar DBS in dystonia_poster 0519.pdf": "D84D5545-AE31-4C19-B28F-35816FDBCAE3",
    "Cerebellar_Infarct_CXR.pdf": "4BB5268D-914A-4FBC-9827-4DE00D0EC906",
    "Cerebellitis Table 1 EG.pdf": "CEE56549-3993-45CD-9E20-22480FE0D9F3",
    "Cerebellum ASSFN.pdf": "5B105C28-4A62-4CA8-AAB3-5E1FC8DF6B6B",
    "Cerebellum Chloride Transport Protein Expression Plasticity in Response to Experimental Ischemic Stroke.pdf": "6887E74C-A46F-4209-A9E1-687782D0C921",
    "Cerebral Angiographies in the Pediatric Population Single-Center Experience with Indications, Safety, Feasibility and Technical Nuances.pdf": "14289989-ABDC-43AA-A7FD-BBAC90C82A03",
    "Cerebrovascular_5-4-2022F (1).pdf": "4701548C-C639-4CD9-A8B6-4891393721E9",
    "Cerebrovascular_Darkwah Oppong.pdf": "652D6D2A-4C08-4444-9BD8-29DBB3A09F2E",
    "cerf 1.pdf": "8A1857AC-B0F1-47CB-ACF2-29961F639868",
    "cerficiate.pdf": "B1D9F3AC-32EF-46CD-947D-7825BA6138E8",
    "CERT KAROLINA PELLOT ORTIZ.pdf": "D9A19CB5-BDA1-4539-817B-C1EB6ECE9984",
    "Cert Letter - Duncan.pdf": "5C8B7F8A-1E56-4C0A-A4E5-1361B4E9682C",
    "Cert Letter - Jalal.pdf": "31F1DB67-48A7-4488-A0C2-D3A85F05680B",
    "Cert Letter - Maloney.pdf": "98780501-0B0B-48F9-9326-69CE0DA6A723",
    "Cert Letter - Nixon.pdf": "78BCFAAE-6C06-4837-92E4-20F227EDB8C4",
    "Cert Letter - Onwumere.pdf": "5B26165C-F613-4606-9F6A-973DF6F53AA7",
    "Cert Letter - Polavarapu.pdf": "6D6BE5CD-1D56-41F7-90EE-9C8E4D2394CA",
    "cert university 2022 felix montenegro.pdf": "86979CC8-4867-4B93-81ED-A2B8CF41B2C1",
    "cert. dott. ANGELO TAVANI (2).pdf": "6D4EC2AB-B752-47D9-955E-05554E9124E1",
    "Cert. Juan Pablo Sardi.pdf": "390B7077-E39F-4393-8DB7-F3544003D514",
    "Cert. miembro de número 2022. ALEJANDRO VARGAS MORENO.pdf": "4233AE61-3E64-47B4-BC05-2261F882BB2C",
    "Cert.pdf": "5769D468-8500-45E7-90A0-A96B90D66A4D",
    "Certeficate of Chief Neurosurgical Resident.pdf": "50000F5F-558F-45F3-A5AD-CCC05CD56E6D",
    "Certicado de Pertenencia SENEC Inglés y Español.pdf": "D6A86202-E9C8-4BA0-A0A0-0805984385C0",
    "certicadopdf.pdf": "937C7C22-A04D-4715-A26E-ADCAB8A83113",
    "Certif Pedro Perez del Rosario Adherido.pdf": "454A4138-388C-4A1B-B825-3EAE448062AC",
    "certif-2.pdf": "71380CC4-B289-4387-9AF4-38FCAE453245",
    "Certificacion  Esteban Rivera.pdf": "4BC81D79-4DF5-446B-AE30-7143D758D124",
    "CERTIFICACION CONSEJO 2020 2025.pdf": "E4DC7F03-0841-4C90-85E3-628ADF3FC9A2",
    "Certificacion de membresia dr Hellwag Flores.pdf": "A604A835-64F9-480D-9EBC-872E7DE4083F",
    "Certificación de Miembro de la Asociación-2023.pdf": "EB768CD6-E5DE-4ECA-98A1-C1D66516C4C9",
    "Certificacion Graduacion NSX.pdf": "4DAE57C2-0379-4F61-BBA5-6D94F5A9156D",
    "CERTIFICACION MIGUEL MAYOL JAN 2013.pdf": "A3643DE7-04F8-4225-9650-DD78EE12179F",
    "Certificado .pdf": "7244ED37-DE5D-41E8-90A1-3163B0B87D26",
    "Certificado academia.pdf": "7D554109-43F8-4972-83AC-71E7684F14BF",
    "Certificado ACNCx  Colombia.pdf": "58298425-C1B5-4DBF-A4A3-39E6A65F00C0",
    "CERTIFICADO ALUMNO MARZO.pdf": "B18A384A-0D52-45AC-BFD4-678952C4EE9A",
    "Certificado CMCN.pdf": "5208351C-BC38-48AB-B8EF-40DEEE1B886F",
    "certificado de alumno regular .pdf": "8C67A2EB-9FF0-4F5A-BBAF-EED2C06E201F",
    "CERTIFICADO DE CONSEJO DOCUMENTO OFICIAL.PDF": "E33C4A05-F1C2-4BF1-8055-4BED23111FFC",
    "Certificado de Neurocirujano Colegio de Medicos.pdf": "706F3032-5A8F-42F5-8BEA-520EC455E4E5",
    "Certificado de residente.pdf": "C0DEED80-CD0B-44B7-9DC4-5C4DBB045C3F",
    "CERTIFICADO Dr GonzaloFortuño2009.pdf": "4B32B58E-AFE1-46AE-99E3-555F385B8906",
    "Certificado Dr[1]. Miranda.pdf": "20418143-8D76-43E4-955D-D50270224857",
    "CERTIFICADO SOCIO Dr. Luis Contreras Seitz.pdf": "244DFA0E-61AD-438E-BF4F-BCDE968371CE",
    "CERTIFICADO SOCIO SONCAM.pdf": "D522C4E7-FB5A-4776-B796-5723F10ADC64",
    "certificado título de especialista SBN.pdf": "9F6AC39B-16F7-4110-BC35-0155D982C2C3",
    "Certificado.pdf": "EFAFF92B-0811-4C65-BA69-214EB022CD14",
    "Certificado_Neurocirurgia_moyses.pdf": "1568FABE-ED23-473A-B6A6-03D58AB53100",
    "CertificadoEFerreira.pdf": "0ACFB21F-BA61-4303-BED3-045371F3EEF7",
    "CERTIFICADOS NCX (1).pdf": "F5E04C2A-F746-40CD-BE34-2AC55BFB2CC9",
    "certificat CNS.pdf": "705D7BBF-0EAF-4C5F-8C5D-F9222A4FEB66",
    "Certificat d'interne.pdf": "0B6C375D-6355-42E7-9BA5-FE1898D1D071",
    "Certificat of residency Pascal.pdf": "11C56144-D750-4018-B9BE-EC304E9DFD4F",
    "Certificat scolarité Cyrus LATIFI 2022-2023.pdf": "8997AD0D-1AA8-4F72-B068-0B58F89744FF",
    "Certificat scolarite´ Cyrus LATIFI 2022-2023.pdf": "5A4FF0EC-B45B-4C25-8E17-12B87E5337D5",
    "certificat spécialisationNCH2012.pdf": "C64442B3-593E-44FD-8DC3-DBAC957F9BFC",
    "certificat-2.pdf": "F067B076-71F3-4F4F-8AA0-D76830EE2610",
    "certificat-inscription 2 1.pdf": "3C705B2F-B3DA-4E5B-8772-06810B569C87",
    "Certificat.pdf": "D6E6506F-BC29-41BF-BC86-844FEFCE6570",
    "certificate - Jongwon Choi.pdf": "47878433-9651-4614-BCB7-A4589849003E",
    "Certificate .pdf": "CBA9EF75-A47E-4560-BDDE-1627AB21E348",
    "Certificate AANC-Guido Caffaratti.pdf": "9F616F22-9AED-436F-B29A-9F081C7338D9",
    "Certificate adn ChenX_CV(7-25-2015) .pdf": "C9E325FC-CEB5-4A41-9ABC-397B5377058F",
    "Certificate Dr Mahboob khan.pdf": "25A61561-A077-4E2C-AA04-DD8C07EB55CD",
    "Certificate Grin.pdf": "E39C470F-ACCB-4710-8FA9-D0D81CA21FA6",
    "Certificate Membership for Koyanagi 20211105.pdf": "1AB305F7-4D00-4587-BF9A-C72C866B9505",
    "Certificate of Admissions .pdf": "1CE84EE9-0B66-46F2-BA61-C8D25AFA3463",
    "Certificate of Board Certified Neurosurgeon Member -Ren Fujiwara.pdf": "E2F63173-AB98-475C-82AF-F8E1A8F0445E",
    "Certificate of Board Certified Neurosurgeon Member .pdf": "F81BF4F5-4534-4CA5-9399-937853BAD940",
    "certificate of CCNS.pdf": "3FB4D0E1-7658-4184-BC4C-A5ACB84A6280",
    "Certificate of Chinese CNS Member.pdf": "DB198FF4-92E1-4A85-B020-3BF1AA731C87",
    "certificate of employment Bill Roy Ferrufino Mejia.pdf": "EEDC1519-C105-4576-8F74-B2F3E3A3F898",
    "Certificate of Enrollment.pdf": "C431A4FE-5CEA-4262-AEBB-A456EFF50627",
    "Certificate of enrolment.pdf": "D2A67C8F-4CE3-4D2E-984D-E4FA32FDBCB9",
    "Certificate of good standing scan.pdf": "17EB0F29-55FF-4472-B908-D247C09EB1BC",
    "Certificate of Good Standing_Dr Abdulaziz Almubarak.pdf": "E3D2F8CF-D103-488D-975C-8108FF15E224",
    "certificate of goodstanding residency program.pdf": "D326B057-EA24-4207-AFFA-78F17CD385CE",
    "Certificate of Matriculation 2122-2223.pdf": "F6D5583B-0957-4C16-BECF-B58A32037089",
    "certificate of membership (Keisuke Miyake).pdf": "77CB671C-FC03-441F-88D0-CE5F0478F182",
    "Certificate of Membership in the Venezuelan Society of Neurosurgery.pdf": "47E0E184-6B4F-449C-99AE-0159678449FA",
    "Certificate of membership.pdf": "D9A0C893-B2B2-4278-8BF1-60E3BC1F4965",
    "certificate of Membership_20180111.pdf": "FCAA2221-995B-455E-97E0-06C7AE03293A",
    "Certificate of Neurosurgeon Specialist.pdf": "17C60D8F-B2A8-43CC-8F0E-6DFFC40AD2E8",
    "Certificate of Registration for CNS.pdf": "ED93428D-80FD-4C98-AA3F-C8CFDEE54D03",
    "Certificate of Residency.pdf": "EF4F4EA1-CDAE-47D3-A990-BE1F21D2BB95",
    "Certificate of Speciality - Ido Strauss.pdf": "7C5B859E-C8A2-4B4F-89F3-AFEC93E81796",
    "Certificate Oscar Jimenez.pdf": "45E0608B-2B3F-4D6E-B4BE-04678006FA71",
    "CERTIFICATE Polyzoidis S..pdf": "58BC9A82-8D5C-464D-B99B-A1A860549DB5",
    "Certificate residency.pdf": "28D6ECF9-53C3-446A-BD50-CBE8E9BA0092",
    "Certificate Resident.pdf": "4578634C-5D84-45B3-8E70-E6F521639A13",
    "certificate VNS.pdf": "C07659CB-033D-4D2F-8B3D-0D65446C3CBC",
    "certificate-1.pdf": "92172E63-D1B0-45F8-B451-315E7082B773",
    "Certificate-2.pdf": "D6F451F4-5FD2-4233-A7AE-559FA79E4390",
    "certificate-NVvN[1].pdf": "7065BE75-7549-4B6E-869D-1E063E745398",
    "certificate.pdf": "E1359885-9DDA-4EBC-A088-0B56E536AD10",
    "Certificate_Boeun Lee.pdf": "A946CF4E-7743-48EB-8C19-DC3CC6E611A6",
    "certificate_JNS_joda.pdf": "14575995-D4C1-4633-835B-EE600994B79F",
    "Certificate_Junhyung Kim (1).pdf": "B2F186D0-7EBA-4B6D-8105-90CA67A15F91",
    "Certificate_MossoRamosTaíse.pdf": "92C60404-E3C5-4D8E-AE1D-FB33C56181B0",
    "certificate_residency_foit.pdf": "E6DFED80-E96A-4B42-8866-BB3F8A44CB0A",
    "Certification CNS.pdf": "EA163FC8-46C8-443B-98E5-4727AD0387D4",
    "Certification Form.pdf": "4F10ABCD-0089-49AD-A773-B1835DCEFE7A",
    "Certification from Dr. Lui.pdf": "A2513F3B-F8C2-40E3-9E08-1390B83C9334",
    "certification letter.pdf": "150465EC-9CCD-4182-9798-03499EC71ABA",
    "Certification Letter_Huq Sakibul.pdf": "E3FAC4F3-F55A-4E03-AA3F-37113941F313",
    "certification magro.pdf": "3BF0D37B-E7D6-44BB-81F3-0B2181420CD2",
    "certification neurosurgery .pdf": "23668C86-EF74-4AE2-B64B-2D3283C7ED50",
    "certification of CCNS.pdf": "8A10B4A0-F104-4B10-B8A3-C0299165A88C",
    "Certification.pdf": "A779F62F-FAAC-4485-B17F-1253151C5081",
    "certificato piccolo.pdf": "6A32B3C8-B2C5-4093-A6AD-6EAC94CA0DA6",
    "Certificato_specializzazione.pdf": "3DEE4CCE-98C7-45E6-82BD-D341707FA6A0",
    "certificato1.pdf": "6194D239-9B4C-4197-B5DD-3F918DCAD20B",
    "CertificatodiIscrizioneconEsamiinlinguainglese.pdf": "99F1FC74-9A7A-4E0D-A4D8-304B3EF6CC51",
    "Certificazione iscrizione durata legale.pdf": "6C6FB0F9-AF41-4DA0-BA05-27DF74EA81C3",
    "CERTIFICAZIONE03-Iscrizioneconduratalegaleperusoborsadistudiosenzafirmaautografa.pdf": "CEFCBFB9-BB0E-427D-A319-D71429BFACE9",
    "certifico.pdf": "748551FF-B15B-4C90-81F0-85CF126A127E",
    "CErtified GMC documents-BDawes.pdf": "947233C9-8B93-4B2D-A284-539D16414038",
    "Certified letter.pdf": "265C933D-98D8-4F41-ADC5-A13278A7C71B",
    "certified_AANC.jpg.pdf": "C6B9CE1D-D4E9-4CA3-8E4B-C0CB8E20CE69",
    "certified_CANC.jpg.pdf": "ABDD2DF8-0326-4529-BA09-569EA0A0C7A8",
    "Certifying Letter - Szin Hang Brendan Wu.pdf": "ED0A1398-D00D-4EB3-9A1B-F1DB3F692A2E",
    "CERTMATPREGMEDIC00020354987-10237314_S.pdf": "2C62E93E-4B53-4C23-960E-CCD86F67CEB0",
    "certs.pdf": "6918C07F-04D3-4125-9D01-150342A17DC7",
    "cerv laminectomy treat CDM.pdf": "709043EB-3134-41EB-9CCA-12FDDE518481",
    "cerv laminoforam treat CDR.pdf": "BBAA6472-8AD2-4204-9870-2D10A84094D6",
    "cerv laminoplasty treat CDM.pdf": "AF3E241F-436E-4ED0-A74A-E0420CE70D25",
    "cervical degen demos.pdf": "1F1DC177-55BE-4268-AFB0-73039A77BE87",
    "Cervical Foraminotomy.pdf": "CAC2EAD2-B418-4FD4-B0EF-07AC002E1DA3",
    "Cervical ROM Table update.pdf": "182E8C3D-278E-4A71-AF0E-4032E2DD86FE",
    "Cervical Stiffness Disability Index.pdf": "DDE26CC9-5508-419C-93F3-6C99830A5604",
    "CervicalDEfrom.pdf": "FE802F6B-D3FF-4D4D-A241-A39CC8348A70",
    "CervicalSCI_mFI_AE_CNS.pdf": "D400C6C4-FBE8-4564-AAB9-AB7803E763A6",
    "Cervicothoracic DISH_HD_Table1.pdf": "60FAF70E-2CFD-4ACC-AB74-B56A7DCC1D3F",
    "Cervicothoracic DISH_HD_Table2.pdf": "4949FCD8-0E5C-4FCB-ABB0-D6468C199F05",
    "Cervicothoracic DISH_HD_Table3.pdf": "FEADB0D8-1D49-4B93-B288-A0FA2D23A324",
    "Cervicothoracic DISH_HD_Table4.pdf": "D4465105-97BF-4053-B324-B104FEAFF389",
    "CES.pdf": "C7445CEB-5529-4E5F-B1AB-C7DEC5F5BC29",
    "Cetif of Attendance Angheluta.PDF": "6E1D4BD7-A256-47E2-B499-517FD1855C47",
    "Cezayirli_CV_20230828.pdf": "6AFE4F37-07D1-4453-AC0C-9415753E0D1A",
    "Cezayirli_NeurosurgeryResidency_Diploma.pdf": "8A6A95DC-F18D-4BAC-AA30-0E3F2CB3CF5D",
    "CGReddy062017.pdf": "5BAEAEB5-459F-427C-91B7-A6956CDACA7F",
    "Chad F Claus CV .pdf": "6B2B9432-3539-466E-B89A-3F1D515AB906",
    "Chad Tuchek-FellowVerif.pdf": "8A7188C6-03B2-49F4-AB35-AE923DCD1B0E",
    "Chad_Washington_CV.pdf": "C37EDB2A-C869-40C1-BD5D-985156DEC3C1",
    "Chad_Washington_CV_Current.pdf": "0BE6F58F-10D2-4C7E-8C2B-27E4917D1065",
    "Chae et al CHIARI Poster.pdf": "BD73AB4C-1D4B-4AFB-BA9C-2A872B36F66C",
    "Chae_MD FT letter.pdf": "23BF4063-C821-4194-ACF1-5B796F8B9489",
    "Chair Letter for Brad Bohnstedt CNS Leadership Institute 1-10-22.pdf": "41F9DFF8-1EF2-4A13-A733-AA0B91232998",
    "Chairman Letter of Support.CNSLeadershipProgram_Munich.Jan2023.pdf": "51324A12-B079-4730-AFB7-34FF31BC5D9C",
    "ChairmanLetter.pdf": "4DAA05F3-ACFF-474C-A88D-CD69E5264778",
    "Chairperson_AOSIR.pdf": "1215F4A0-EB77-48F7-B9C8-588CD8E0A523",
    "chak cv.pdf": "F5FD1324-1D87-4C80-A054-F42159D3ED02",
    "Chakraborty-Figure1 copy.pdf": "56D9CED6-3B64-43F1-AA3B-6F1CAA4CBDAC",
    "Chakraborty-Figure2 copy.pdf": "E5024675-9CB8-4729-818C-633878697E2A",
    "Chalhoub Reda - good standing ltr.pdf": "E8B99850-ABE4-4B6D-9139-8907F69C2D4A",
    "Chambless CSNS Rec Letter 2022.pdf": "E94ACA86-618A-4A18-8A15-34F44250DBDF",
    "Chambless for  Koester.pdf": "3F98BE45-9C9C-4F28-9B3A-534760C599C0",
    "CHammer.RESVerif.pdf": "8BCBB306-79FF-4E6A-B24B-9597D6452D5B",
    "Chan, Alvin Letter of Good Standing.pdf": "F2990BE0-D1AB-4553-AED4-45326FF097C1",
    "Chan_Andrew_CurriculumVitae_031222.pdf": "C446E36E-C970-48F4-A1D7-1056C0B40E69",
    "Chan_Andrew_CurriculumVitae_2.01.20.pdf": "6C39D3BE-11DD-4EC2-83FB-E0F9DBA1AEA6",
    "Chan_CNS Emergencies 2024.pdf": "58BE942C-D2F9-4C7C-A94B-244CE7847D02",
    "Chan_Dean.pdf": "A89FA57B-2B5A-446C-9B84-D61534B148E5",
    "Chan_PS_Letterhead_2020_signed.pdf": "54E89851-A56F-480B-9BBE-67E661C6638C",
    "Chandana Buddhala_CV_070418.pdf": "97BA8C21-7C37-41BD-AEDF-8232F4730A36",
    "Chandela CV.pdf": "74AE9C26-A898-4F7D-8A04-0D70C114C219",
    "Chandler Berke Letter of Enrollment[11].pdf": "62DB998B-D13D-4309-9F65-A95D585E0AF9",
    "Chandler, Bill.PDF": "2DD15C60-383E-4DFD-B777-4552EE4DB5D2",
    "Chandler, William F.pdf": "7244AB20-4659-4AF5-9F99-0ECE4748D244",
    "Chandler, William.pdf": "554BA345-3049-4ECB-BFA6-57973700AE10",
    "Chandler_EnrollmentVerification.pdf": "84722747-8783-4E34-AE1A-025790A99259",
    "Chandrabhatla_Letter of Enrollment and Good Standing .pdf": "7D7D016D-BA8D-48ED-95F0-542D53CA07E8",
    "chang cv nov 2010.pdf": "59009B0C-B9E1-40C9-9DFE-3E064DC03AA2",
    "Chang SNS 2020.pdf": "B07F0AC3-2FB8-4820-8315-147DA514E579",
    "Chang_bio_2021_.pdf": "4D0DCA39-26D1-4CE9-93F7-475312D8C494",
    "CHANJL_CV_2021-02-01 Neurosurgery.pdf": "83B05704-4878-436A-83F0-FC2B559179E8",
    "CHANJL_CV_2024-02-29.pdf": "FB146315-1B5E-4059-89D6-25C9EBDABB25",
    "Chao Li Good Standing_001.pdf": "DAEC42FC-EAE9-4738-92E1-C82828488A32",
    "ChaoLi-FellowVerif.pdf": "D554ED58-C1FE-4079-869B-BC804BB40C9E",
    "Chapter 1.52.pdf": "422EBCED-EF5C-469E-A380-0CC20834174E",
    "chapter 1.53.pdf": "DA34A09D-C1F0-41BB-9F64-2A794D38579E",
    "Chapter 10.52.pdf": "4D6AF495-35CB-494D-BACA-C632E05738E2",
    "Chapter 11.52.pdf": "E166B270-33BD-4CB2-BA80-93C497699DD9",
    "Chapter 12.52.pdf": "61B355FC-1099-4F28-8144-CEFA2871B67C",
    "Chapter 13.52.pdf": "D7B156A2-8613-4904-8577-8125EA1DA365",
    "Chapter 14A.52.pdf": "263CD409-73A7-4B34-B266-3B756EF52511",
    "Chapter 15.52.pdf": "1C5A624B-96DD-4678-A391-27FAC5763E6B",
    "Chapter 16.52.pdf": "070F7EEF-DD15-44AD-88DC-2D58701FB7A5",
    "Chapter 17.52.pdf": "9CC4A71F-2C45-41C5-AD6B-D20DCAE8C441",
    "Chapter 18.52.pdf": "EC9823C1-1605-40B3-A00B-5FE7A337DB51",
    "Chapter 19.52.pdf": "6B2682FA-A1F3-4B45-B2F4-F07BDFF8F76C",
    "Chapter 2.52.pdf": "44C99DF9-67D3-47D9-88B3-4BED42BF1A96",
    "Chapter 20.52.pdf": "68A039A9-8A57-400C-A16D-7A3BE3869090",
    "Chapter 21.52.pdf": "C653E4FB-740C-4A21-ABDB-047E4D740AA9",
    "Chapter 22.52.pdf": "1D28569B-46FE-4405-B996-0D0C890F9F4B",
    "Chapter 23.52.pdf": "9A224F30-D7BD-40E3-88BC-548B6AD2B089",
    "Chapter 24.52.pdf": "06670329-D6B0-4E8D-9B35-51E2F005AD4C",
    "Chapter 25.52.pdf": "6128213F-24D5-4E87-B306-3D895D823BE7",
    "Chapter 26.52.pdf": "DAD5A7EE-E60B-4F7C-8CDC-6A83A584D5AF",
    "Chapter 27.52.pdf": "FDD200FA-EA2A-4AAD-B3FA-EF1861638594",
    "Chapter 28.52.pdf": "491D0B7B-EB88-4C4F-81C5-967F00D63D7B",
    "Chapter 29.52.pdf": "4FB662A1-BAF5-4156-9893-7801D4FA95DF",
    "Chapter 3.52.pdf": "80D0357A-C51C-423C-823E-5455666068A0",
    "Chapter 30.52.pdf": "5F0B40AD-DEB9-4ED1-8570-B0B3E65E6CDE",
    "Chapter 31.52.pdf": "9BED73BC-71BC-47E2-969E-C922C8D6937F",
    "Chapter 32.52.pdf": "9FFFD973-5EE3-4A34-ABBA-A76A0856D73C",
    "Chapter 33.52.pdf": "E99C4791-5972-4ACB-BE5F-E3DA2BD6A862",
    "Chapter 34.52.pdf": "B12E17BD-C049-44CD-85AF-3882C675E501",
    "Chapter 35.52.pdf": "61CC1DD4-97F4-4351-9E03-8FB2D84D38EE",
    "Chapter 36.52.pdf": "9E7804E1-411C-4288-BFDC-AB954D8850C3",
    "Chapter 37.52.pdf": "0177D7DA-EDD9-43F0-81AB-B87590EFDB72",
    "Chapter 38.52.pdf": "E317F4C3-3C8E-4E36-85E2-B82053B44D9E",
    "Chapter 39.52.pdf": "E89E2AFE-C4B4-44F6-BE42-8EF493FA94F8",
    "Chapter 4.52.pdf": "296EE070-A729-45EE-A3FA-7590FA9F7EFD",
    "Chapter 40.52.pdf": "3FC00614-50B6-4E97-B316-F5A7145F1372",
    "Chapter 41.52.pdf": "74DE45A4-9AE2-49B4-825C-97B4DAE46D1B",
    "Chapter 42.52.pdf": "C3B615AB-5094-49E9-BCCE-2BB680D068DB",
    "Chapter 43.52.pdf": "A3B6274B-4D48-41C0-9232-AC6C3D4B8E21",
    "Chapter 44.52.pdf": "452DFCAC-777C-4312-9F2D-07A57CCF325D",
    "Chapter 45.52.pdf": "C9462A0C-A112-49B2-BD54-01D2A1519449",
    "Chapter 46.52.pdf": "A93F9079-2A37-45E8-ABF6-B09B970C8B2A",
    "Chapter 47.52.pdf": "1C0845E6-2867-438D-BCE7-D8A46B113D9F",
    "Chapter 48.52.pdf": "652C6048-DBC7-400F-94E6-224C584D02C3",
    "Chapter 49.52.pdf": "769C6217-2E44-4CCD-90CD-40BF9FAE5A52",
    "Chapter 5.52.pdf": "EDC3F68B-0098-4458-BD98-6BA2B9586CE6",
    "Chapter 50.52.pdf": "7A9D7D6E-D0C5-4177-A7B2-8B679409A537",
    "Chapter 51.52.pdf": "D9F5EE72-743A-4A7A-839F-7A6C06BCD3F6",
    "Chapter 52.52.pdf": "751FF3C8-956E-4DB4-B999-4DCFBF2CC0F1",
    "Chapter 6.52.pdf": "84370FBA-7377-41A7-BE2A-1CF252B4F1F6",
    "Chapter 7.52.pdf": "328C0DF5-35D9-4688-B908-E756C3D2C0C7",
    "Chapter 8.52.pdf": "1DD981CF-9BD1-47BD-97D3-3777BB824ECE",
    "Chapter 9.52.pdf": "752B49AB-E857-4E2F-9A9F-61B75A2AD369",
    "chapter1.56.pdf": "468D19F1-D29C-4F3C-A0CA-7CC144B1A6B3",
    "Chapter1.59.pdf": "61E1A275-B98D-41D1-9E71-D08D0DBC5A36",
    "chapter1_0.55.pdf": "A06F3ECD-633E-4D99-AB7C-EFF9A4A4E0B8",
    "Chapter1_0.58.pdf": "CB214F3B-1D48-4F46-BAF2-3A47982663F5",
    "chapter1_1.54.pdf": "D746DB12-EE78-47AE-B05D-E56945CEF1F5",
    "Chapter1_1.57.pdf": "C004DBBB-79E4-4547-BBFD-77B0EAE35298",
    "chapter10.56.pdf": "C9058E65-E1D4-4080-9AD6-6E220A378710",
    "Chapter10.59.pdf": "9606E16A-3198-4ED2-AEBF-43DCC8B97D79",
    "chapter10_0.55.pdf": "E991B904-479D-4DA6-B175-6CA5BBEB3747",
    "Chapter10_0.58.pdf": "8014A8E3-D82B-483C-AB77-7362F0AD9BCF",
    "chapter10_1.54.pdf": "1212C7D2-638E-4ED9-9C10-A5569F6E6DEA",
    "Chapter10_1.57.pdf": "A483C2C9-4861-412E-974F-3C526AEEDFFB",
    "chapter10_2.53.pdf": "95AE9633-A3FF-4175-972A-70E8FD0CE6BD",
    "chapter11.56.pdf": "BB3B76E3-3081-4783-8C4D-230683F069CD",
    "Chapter11.59.pdf": "9255CD6E-032D-4F94-AF18-8365A90A4CB7",
    "chapter11_0.55.pdf": "3D530838-A078-45C3-8343-65907D3FAC5C",
    "Chapter11_0.58.pdf": "48EB357A-D696-44FF-A0BC-572DB90A81E3",
    "chapter11_1.54.pdf": "229E4E4B-A94F-405C-AC3E-E1A32427C6FB",
    "Chapter11_1.57.pdf": "630A0AB2-6351-4E0C-87BA-1597D554D252",
    "chapter11_2.53.pdf": "80A68987-385B-4021-91C3-02610B1DEA4A",
    "chapter12.56.pdf": "F4B44A20-3B3E-472E-96C3-DEF8226D3197",
    "Chapter12.59.pdf": "E51FE338-D5C4-4E86-997D-95CC1F642385",
    "chapter12_0.55.pdf": "615BCD49-E49E-4AF1-9954-30EF8404141C",
    "Chapter12_0.58.pdf": "582C0C91-3CCE-4B8D-A657-1A043EB36D02",
    "chapter12_1.54.pdf": "0C51104F-5BEA-4B63-80F2-9F506F8B2CAA",
    "Chapter12_1.57.pdf": "05289215-E090-4F16-82C5-9878316674CE",
    "chapter12_2.53.pdf": "01B74A73-243E-4F4F-B5AF-E33D55B82123",
    "chapter13.56.pdf": "232490D2-7177-453B-B8E5-F33BB2169321",
    "Chapter13.59.pdf": "11C4801A-C482-4C92-9486-BA7E6FCFA842",
    "chapter13_0.55.pdf": "DCA73595-78F8-4B43-AD95-2B77D67783D1",
    "Chapter13_0.58.pdf": "32DFF0A5-1DE5-48BF-B766-9140C866CCC4",
    "chapter13_1.54.pdf": "51AB205C-6664-4669-A610-630213659EED",
    "Chapter13_1.57.pdf": "4717787C-0E8A-4037-B9D1-4F7F7FB2CC75",
    "chapter13_2.53.pdf": "68E85C2E-41B2-4403-B48B-C9B1C9FC6C1E",
    "chapter14.56.pdf": "8DE0FE03-3905-4564-BCA4-08DDD00701B7",
    "Chapter14.59.pdf": "6A983CC9-D1AE-497C-86B5-67D6D557980A",
    "chapter14_0.55.pdf": "BFA8BD4B-9213-4DC6-B581-54264EEC1491",
    "Chapter14_0.58.pdf": "9361D762-0C3A-4A16-9C30-4C1376D3A7B9",
    "chapter14_1.54.pdf": "60F53A7A-9B43-4C51-B1F0-C380CFCA62B1",
    "Chapter14_1.57.pdf": "66C14E56-BFD2-440B-8103-6E6E5EEFA398",
    "chapter14_2.53.pdf": "35FC4FD6-E12F-4670-9E3D-AB07DAEBDFC5",
    "chapter15.56.pdf": "E6123A9E-D006-4499-A9E5-DD59AD2C605B",
    "Chapter15.59.pdf": "AF11E1AD-27A3-4372-B18F-B6194EBF9D7F",
    "chapter15_0.55.pdf": "D10DB91D-27FC-4C81-BA6D-7BD061A8BA23",
    "Chapter15_0.58.pdf": "85E93238-DB8D-4361-839A-7C4BDCE09549",
    "chapter15_1.54.pdf": "555FFC3E-631F-4794-BAEB-8E9057472A73",
    "Chapter15_1.57.pdf": "1B308A60-A9B3-48E4-94C1-7152A44D8BA0",
    "chapter15_2.53.pdf": "9645A071-3650-441B-8592-AB2BCAFE3BC9",
    "chapter16.56.pdf": "85EBA4EA-F505-4D32-A19E-0952B3AC0833",
    "Chapter16.59.pdf": "C7AB60C4-CB7C-4BD3-9278-CF5184C78784",
    "chapter16_0.55.pdf": "B0D4529D-97FC-4353-AF20-680A77856D9B",
    "Chapter16_0.58.pdf": "E7A2DE25-BD55-4747-B6E4-6136524F51F5",
    "chapter16_1.54.pdf": "406D2618-A50A-4216-83E0-07ED4621A938",
    "Chapter16_1.57.pdf": "100C70A3-1B99-4F6B-BEB2-301473FD2E29",
    "chapter16_2.53.pdf": "64482A4E-A6C0-450C-ABCC-85A0F4DB441F",
    "chapter17.56.pdf": "D09A2DB7-0882-4FE5-9B61-EADE1599B0D6",
    "Chapter17.59.pdf": "EA158D95-E23C-42AD-BDE6-7166DFD762D3",
    "chapter17_0.55.pdf": "EF6E623C-44E4-4D4A-86A1-D93F10491F6F",
    "Chapter17_0.58.pdf": "EC9C3105-C4CC-4BB5-9AE0-4384FDB4A36E",
    "chapter17_1.54.pdf": "22EF4F79-0A39-422F-A1E9-274D709E0A84",
    "Chapter17_1.57.pdf": "12D794F4-317E-4A04-90A5-46D81B6AEFB0",
    "chapter17_2.53.pdf": "2BBAEC33-FEDD-4888-B37C-37C96437F487",
    "chapter18.56.pdf": "E142EB46-8DF6-4DE0-885E-E0EF3B006DBF",
    "Chapter18.59.pdf": "36FC792B-A00A-4D0C-A9DB-22AC9D909C85",
    "chapter18_0.55.pdf": "E1FF9D74-1A20-4ECB-86A9-AC940132711F",
    "Chapter18_0.58.pdf": "5B77AD09-60ED-40BB-8D51-3457A588717A",
    "chapter18_1.54.pdf": "6C21343E-8067-450A-8A9F-ABD2151A6769",
    "Chapter18_1.57.pdf": "9483CE32-30C2-435F-A03D-4D3AA3811475",
    "chapter18_2.53.pdf": "FD075D47-E204-4D3D-BB12-323D75BA9FA5",
    "chapter19.56.pdf": "F8BDEA33-6306-4676-819B-307104F30AA7",
    "Chapter19.58.pdf": "CDECD720-3D20-468B-8B6F-778E3F92CEF8",
    "chapter19_0.55.pdf": "A166DC0D-103E-45CF-82D9-F1C11EED65BE",
    "Chapter19_0.57.pdf": "48E82998-1C72-4597-8D11-1B5BE7A682FC",
    "chapter19_1.54.pdf": "41158295-BBFC-41A7-B8DC-53A63B854E1B",
    "chapter19_2.53.pdf": "9FC9F4C0-E5DD-4858-BD48-CBF97784594C",
    "chapter2.56.pdf": "B2FD6E5D-D034-4994-BC4D-6FF4C8380AAD",
    "Chapter2.59.pdf": "87A7C5A4-8534-46E3-A2D8-A3C5F472B57E",
    "chapter2_0.55.pdf": "77FED096-9EAF-4755-A5FB-14718A304EDD",
    "Chapter2_0.58.pdf": "174848DB-58F8-4D18-BA5D-A1A91F4399F7",
    "chapter2_1.54.pdf": "39F752FA-F0BE-48D1-8C31-D56062761BF2",
    "Chapter2_1.57.pdf": "B56E6F75-98E1-439A-BA68-EA7BC30A24F7",
    "chapter2_2.53.pdf": "F63B4233-4341-41D9-886F-42F1C1BA0FEC",
    "chapter20.56.pdf": "6B69C541-D186-45E5-B038-0374A32CC5F3",
    "Chapter20.58.pdf": "C80A5198-1898-4F8E-9A95-868C9D106FDC",
    "chapter20_0.55.pdf": "F20A2A38-E8BB-47FC-90C6-56A4EFA12C1B",
    "Chapter20_0.57.pdf": "D4A319E8-F5CB-4424-82ED-CEF15CEBC3F1",
    "chapter20_1.54.pdf": "55ECEBA2-E849-45B5-A8B5-4937635129EC",
    "chapter20_2.53.pdf": "1316BAED-33D7-4D00-97BF-A544619E6605",
    "chapter21.56.pdf": "ED69E2C5-F8E7-4B25-9650-43DABF038227",
    "Chapter21.58.pdf": "396E8F82-B11F-4360-AA2C-FDB9F72F7954",
    "chapter21_0.55.pdf": "2FF9B9D5-DE10-4AFC-8A4E-FA6147712C02",
    "Chapter21_0.57.pdf": "FFCF2FDB-A37B-43FF-9748-16B7FA080B52",
    "chapter21_1.54.pdf": "BFFB9A37-E552-47F5-B748-A4578222F3EF",
    "chapter21_2.53.pdf": "0EFF102C-17BF-4ED4-B56E-E0E4F0F1C790",
    "chapter22.55.pdf": "0B87DDEA-CBF2-499D-8D3D-CFA500216A8C",
    "Chapter22.58.pdf": "0A57B236-29F1-426B-A1C5-826ADF9F6702",
    "chapter22_0.54.pdf": "36779F09-2450-4E08-A9A2-AEE8472FE421",
    "Chapter22_0.57.pdf": "67A1D844-58DC-4771-9EE6-996186F0D167",
    "chapter22_1.53.pdf": "163CD341-C651-4C69-8E0F-F8F41330D314",
    "chapter23.55.pdf": "72B9EC6E-A449-4081-B8C0-0035AE0D0DDA",
    "Chapter23.57.pdf": "7CE5752B-1DC0-4A00-92CB-1E70536109C6",
    "chapter23_0.54.pdf": "84F5E466-784E-4F9B-8B8D-7EFC1C03519B",
    "chapter23_1.53.pdf": "AC7D079F-0BB8-4CBD-B61F-8AA49230B922",
    "chapter24.54.pdf": "3313B8E0-373A-4576-A86A-666367E0EF6F",
    "Chapter24.57.pdf": "35866E12-76FF-4700-B673-53F55E625055",
    "chapter24_0.53.pdf": "8AF6D7E9-B2F5-4C5A-8DFC-8D52F140EBDA",
    "chapter25.54.pdf": "F46BECB3-67D8-4C4B-9179-B3F75E527A74",
    "chapter25_0.53.pdf": "DEF29AAD-6715-43A6-8B34-7D4CB0BC9A54",
    "chapter26.54.pdf": "EA139EB9-5380-46E0-998F-0DCB1FF6B8E2",
    "chapter26_0.53.pdf": "FC48DFC4-E63B-4B7C-BF55-C6CF1D2AFB23",
    "chapter27.54.pdf": "5EB022CC-A0CD-4F6F-8CFD-656DB6529910",
    "chapter27_0.53.pdf": "9173E39A-5740-4DEF-A4A7-0D9F48F59C9A",
    "chapter28.54.pdf": "ADB7964C-0260-4735-99DB-69CC48C38EBE",
    "chapter28_0.53.pdf": "F3C204CE-5DB7-4B18-B213-EE1EED499BBE",
    "chapter29.54.pdf": "15322534-ACB6-4276-8C69-E8DBD2055AB7",
    "chapter29_0.53.pdf": "E5C8ED06-4C6D-4352-82B2-6D7BD9BCE45D",
    "chapter3.56.pdf": "1406969D-8FE2-40C0-80A8-657F4BD2B6A3",
    "Chapter3.59.pdf": "E9195EE2-C770-49A7-8351-8CC73DF49C28",
    "chapter3_0.55.pdf": "44AE3371-576A-4BD8-B104-2610F2B2AC2E",
    "Chapter3_0.58.pdf": "9F006246-DCCE-4381-8836-913B19D764F1",
    "chapter3_1.54.pdf": "B1B5C834-DA25-4AB8-A005-A3D3F3AC1A50",
    "Chapter3_1.57.pdf": "4D172093-FD9E-4A44-A17F-C5F0D8C6EEBD",
    "chapter3_2.53.pdf": "AFF9C19E-B98C-48F8-B30F-91147B8A9724",
    "chapter30.54.pdf": "E953DCC4-37D1-4E12-BFA4-8A8123DEC42A",
    "chapter30_0.53.pdf": "893A8DCF-5B07-46BC-A063-C4270A814299",
    "chapter31.54.pdf": "25F2779E-FA9A-4C0B-A992-04A6769E5446",
    "chapter31_0.53.pdf": "0D3CF247-E37C-4737-B77A-10306DF19585",
    "chapter32.54.pdf": "02760E67-E467-4604-92F2-CF9B0372FA83",
    "chapter32_0.53.pdf": "A15475E6-EC80-43F4-A648-677F54AC222B",
    "chapter33.54.pdf": "B1EF3336-EB43-4941-A379-7BB393BFD939",
    "chapter33_0.53.pdf": "0C162DA4-785F-41B1-BA19-6D8E57DB29C1",
    "chapter34.54.pdf": "EDFC152F-CF75-422F-BBE3-2173622C733F",
    "chapter34_0.53.pdf": "6E8A1BDA-FCE3-4416-B786-8D5DC3D165CC",
    "chapter35.54.pdf": "A3D18E48-2C4C-476C-9B43-2662BD4E0D58",
    "chapter35_0.53.pdf": "05176610-C0AC-4DE1-9CD1-4BCE71E96C92",
    "chapter36.54.pdf": "4EAE48E4-A2E0-4FDF-AC7F-269BC8758CB2",
    "chapter36_0.53.pdf": "4D49E751-9C0C-439A-8040-C03D6FD81E15",
    "chapter37.54.pdf": "70AE63A8-C036-4562-A3E7-7B6D2B1B5C68",
    "chapter37_0.53.pdf": "F2524755-54D2-4B7A-AFFF-ED14B766F79D",
    "chapter38.54.pdf": "808EDFF2-B942-4747-A719-D6641E014B9C",
    "chapter38_0.53.pdf": "AA5420CC-0A09-4D0C-BE4F-A08C1B6E4BFC",
    "chapter39.54.pdf": "0992D882-6F3C-4F41-B3A1-64E9CCC64007",
    "chapter39_0.53.pdf": "F8258435-C028-4C15-A2FA-9411DE2F1F42",
    "chapter4.56.pdf": "A84FFD1B-1DA1-40B0-9246-6BEF8933BC38",
    "Chapter4.59.pdf": "A33816EA-8C03-4040-8D3C-CD030BF38BAE",
    "chapter4_0.55.pdf": "7F597649-0681-4091-8F60-78D0078EA8EA",
    "Chapter4_0.58.pdf": "857272D1-A7A4-449E-A540-73BE1F623F97",
    "chapter4_1.54.pdf": "4EA6C8AC-8952-49E8-AB79-2C48AA86BEBD",
    "Chapter4_1.57.pdf": "7C987618-7156-4731-BA59-374949986505",
    "chapter4_2.53.pdf": "04FC1C1E-6AD3-450C-BEF7-AC50C4DC3B74",
    "chapter40.53.pdf": "A62666B9-E4BB-499D-B52C-30AE7D117F46",
    "chapter41.53.pdf": "29987F71-51DC-40DA-B85A-7B051516A2C4",
    "chapter5.56.pdf": "3AF7564D-0701-4F26-89B8-2F5D7945094F",
    "Chapter5.59.pdf": "A1C041A4-01EA-4F2B-A186-7CA352DE5D93",
    "chapter5_0.55.pdf": "6A5D953D-EF34-4E7D-920D-04B138342952",
    "Chapter5_0.58.pdf": "97012C1C-E891-4222-A562-E391FD5FB2BA",
    "chapter5_1.54.pdf": "D56E5FE9-EB5C-4D63-9ADF-A9C064CBBF0C",
    "Chapter5_1.57.pdf": "B9527EB0-9402-45B9-ABBC-9FDAE5C58E0D",
    "chapter5_2.53.pdf": "15BE4B5E-ABDC-47AA-82ED-E53C5BC4D848",
    "chapter6.56.pdf": "98590F2E-B257-4D0A-A534-C6258F5193A6",
    "Chapter6.59.pdf": "154A056A-58C9-4D30-9B2A-007E352E692E",
    "chapter6_0.55.pdf": "F6277EDA-AA9A-4F46-A11C-CACC4B3715E4",
    "Chapter6_0.58.pdf": "D10CC317-7EC1-47AF-BE6B-18E82B9D1C23",
    "chapter6_1.54.pdf": "B378E10F-CE59-4D7E-AAC8-70D91D49A0C5",
    "Chapter6_1.57.pdf": "E9D06387-870A-4336-87F9-022D2710537C",
    "chapter6_2.53.pdf": "38BCFCCD-A18B-48A7-A14E-92F9C2F2D0F4",
    "chapter7.56.pdf": "A17F89BC-3BD3-472B-BA20-A3DEF63BBC76",
    "Chapter7.59.pdf": "CA9E8C41-1139-41AA-92FF-BB32020D697C",
    "chapter7_0.55.pdf": "BEF297ED-8730-4B34-A21F-859EB1EF5842",
    "Chapter7_0.58.pdf": "6CFE3ADE-6EB6-44A4-BD16-72C76E437AFD",
    "chapter7_1.54.pdf": "D13E0020-7AAC-4533-AC68-76CDEF75B9CA",
    "Chapter7_1.57.pdf": "43CF3B34-F37C-4C53-A52B-DA6A47289B51",
    "chapter7_2.53.pdf": "FC999FE9-8BA5-49C9-BDB8-224EF24CDC9A",
    "chapter8.56.pdf": "E8532079-CA63-4F4F-B724-EF4EDB5758CC",
    "Chapter8.59.pdf": "44E9861A-505F-4405-8389-031911A03144",
    "chapter8_0.55.pdf": "BAD2090C-8D3E-4304-AA91-13F9935D8498",
    "Chapter8_0.58.pdf": "E03901CF-C11A-417D-A79B-2D3EBAA95AF8",
    "chapter8_1.54.pdf": "FBC34556-CDC7-4D65-A628-EA8E73E7C101",
    "Chapter8_1.57.pdf": "3A7AB190-78C8-4A84-9CCB-AB1A3867B723",
    "chapter8_2.53.pdf": "9EE6ECED-0763-4A69-9CC2-D0C4DEF2E612",
    "chapter9.56.pdf": "637604BE-363F-4AE1-B7A4-E24A2D1358DA",
    "Chapter9.59.pdf": "14D545B2-11E7-4726-A3BF-B35025A1F4A9",
    "chapter9_0.55.pdf": "A598D871-3390-4D71-A733-F53462B21DEF",
    "Chapter9_0.58.pdf": "EEA8ED45-DA6B-4C10-8975-8A0F13AFB2DD",
    "chapter9_1.54.pdf": "8D6534DA-A0D1-4FBA-89B2-C362499AF8D0",
    "Chapter9_1.57.pdf": "EF6C20F1-8677-4CD8-BA3C-928623330E1D",
    "chapter9_2.53.pdf": "B6B0319E-6F9D-4D2E-A5B9-639D92F53B43",
    "Charafeddine, F.htm.pdf": "E1258DF4-426B-40DF-8A45-6936F89A6F0C",
    "CHARBEL MOUSSALEM's CV (1).pdf": "397511DA-F063-4781-90BE-5DEEFDD65968",
    "CHARBEL MOUSSALEM's CV-2 (1).pdf": "9A375D39-4443-413B-BF81-D778750B7571",
    "Charbel.pdf": "C0410E67-5240-4B2C-BB51-E447FBE0A367",
    "Charcot Figure 1.pdf": "731A5654-5134-40B9-BE0C-EAE1F3257E14",
    "Charcot Figure 2.pdf": "586EB163-E02C-4CE9-A0B7-4E36C511ACC2",
    "Charcot Figure 3.pdf": "23EC3FE5-68A7-495B-9BE8-F3BF1EEF5921",
    "Charcot Figure 4.pdf": "6D7FDD7F-1559-4F94-9124-3E93FC9E9D24",
    "Charcot_ Tables.pdf": "E7B3A0DE-B6D2-4367-A2A8-F947F27B68C6",
    "Charles Kuntz Scholar Award - Surgical Decompression for CSM-associated hypertension.pdf": "01388466-FADC-4815-AD13-794B6F2E2723",
    "Charles Mikell Letter of Support for Justin Passman.pdf": "58A7F95B-71BC-429D-BB07-A5C606FE5D59",
    "Charles Withington_Good Standing & Reference_.pdf": "17D54BBE-01F0-4851-8E0D-00D5C6755E24",
    "Charles.Ronkon-RESverif.pdf": "2CA65B05-75F3-43A6-A5FE-E35FD7C5F66B",
    "Charlie Kuntz Scholar Award - Radiation-induced VCF Model.pdf": "0A303F37-638C-4CDE-95C6-D8D0BF972A1A",
    "CharlieNelson_CNS2019.pdf": "1BB171DE-358F-40B7-BB2E-855AE8FB36ED",
    "CHarper CV_01.31.20_CNS_CSNS.pdf": "236F14DD-7FE6-4EB4-9358-77C24796CDB1",
    "CHarper CV_02.21.20.pdf": "E3D55F6B-56A5-41C0-A075-7237AF529FD0",
    "ChartofHouseHealthCareReformBillforProviders9222009.pdf": "2A8C2E77-7E0A-4CDA-8E07-75309C1ABA4B",
    "Chartrain, Alexander_EnrollmentVerif.pdf": "CB8AF2D1-9F0D-479F-9FF3-7A572FF2E3AC",
    "Chat GPT Coding.pptx.pdf": "055FB5C4-6E6C-49D5-86E8-3D43744C2D26",
    "ChatGPT Med Student.pptx.pdf": "B46E36EC-3191-4D16-8467-FBEDF9BA957E",
    "ChatGTP GBM Poster.pdf": "09BE4F5F-0721-45E4-9A45-BFFF0FB0425A",
    "Chauhan_Daksh_Sem1_Transcript.pdf": "A48927A6-A94B-420B-A577-1E1248F14B0E",
    "Chavakula.pdf": "33A36A26-5294-4A28-B4D3-E128E9FBBFBB",
    "Chavarro Velina 45460575.pdf": "43C2FD1A-DDCA-41CF-9D37-BA954904EB4B",
    "Chavez verification.pdf": "2A76C967-9113-48DB-A594-D24F962FD469",
    "CHD and Hydro CNS poster for submission.pdf": "28A0B48B-E5A2-4910-918E-BCC8D401B434",
    "CHD Aneurysm Poster CNS 2021.pdf": "B65A1E4A-99B6-4EFC-A894-793BCD4CDF3E",
    "CHD8 humans.pdf": "339D1FA6-08F7-454C-ACAD-1EA5CC928045",
    "CHD8 Zebrafish.pdf": "79DA1AD3-79CA-4213-9216-351A488B8637",
    "Checklist.pdf": "39D52AD3-699D-4E8E-AE89-AF66F1606CC2",
    "Chee Keanu 109640414 Academic Attendance Verification.pdf": "BA664379-88CE-4C18-9D24-0305364CD611",
    "Cheema.pdf": "B2AB48D9-8345-448F-9E36-72C03800B5C1",
    "Cheetham-West Arokoruba - Good Standing Enroll.pdf": "CFCA464C-17D0-4BCB-8FCA-E759E409CDFB",
    "Chef statement for AANSCNS.pdf": "48421A91-125D-431D-95BC-2F9A1BD9EDB1",
    "Chelsey_Guzman CNS Award Essay .pdf": "D6C5FA1A-5FF2-49AB-9084-4B0073F2FFA1",
    "Chen and Shah STM 2023.pdf": "262453C2-4A4A-4199-92BA-EC17EF222B0B",
    "Chen Jeffrey.pdf": "64BF89B8-0770-4028-B4D6-37341249104B",
    "Chen Justin-LoGS Proof of Enrollment.pdf": "7D9F85E0-2E43-4CA6-9E9A-8B8C11BA5C73",
    "Chen Shi-RESVerif.pdf": "6C804467-27B6-4AEB-A440-914055F3349F",
    "Chen Shi.emailverification.pdf": "3BF68E42-90E9-4EAA-93DE-7394E0ECD931",
    "Chen Verification.pdf": "FD5542A6-521C-44A0-9D82-4B7FEF0D41D5",
    "Chen.Adrian.Letter of Good Standing.6.2.23.pdf": "F2A61B26-25DD-4D7D-8DD2-A61043C21821",
    "Chencheng Zhang CV_201912.pdf": "6EA5D50E-3E25-44CC-9F58-BA733EB724E7",
    "Cheng_Letter for Forbes SNS-2022 1001.pdf": "AF1E00A7-11CB-433E-8F5A-80159B9C1F4A",
    "Cheng_Letter for Krishnamurthy SNS-2022 0909.pdf": "1AE5D536-3C3E-4144-81C9-7129B15FBB20",
    "Cheng_MYO_SNSmembership_LoR.pdf": "E5310C39-0882-47CA-86F6-2BC5D87BE9C8",
    "Chenlong Liao.pdf": "F0D4C86F-1912-4753-8909-14A2480AE960",
    "ChenS CV 7.3.23.pdf": "16A227B3-F6CA-46BD-B8AB-6169D30E7603",
    "ChenX_CV(7-25-2015) .pdf": "A47E014D-9472-4A2C-AAD7-316A33E10F60",
    "ChenX_CV7-25-2015.pdf": "744E9FA0-CB4D-4921-B6D5-45F6340642CF",
    "Cherie Roberts - Letter of Enrollment.pdf": "62481D1A-4E67-4569-9860-853A6DF84B4E",
    "Cheserem_FWLNSchol_2021.pdf": "20EA062C-835D-4EFD-A35F-814D88E85A0E",
    "Chesney Oravec Letter of School Standing.pdf": "2E40D8D2-B8FA-4FA2-A8ED-EDCC4DD7CC39",
    "Chetan Bettegowda Nomination Letter.pdf": "8A4B036E-AA6A-4E03-9C5B-E047DD1448B3",
    "Chetan Bettegowda Recommendation Letter.pdf": "C5973FE6-851F-4982-B014-9CCD2FF6B207",
    "Chetan Bettegowda Sponsor Letter.pdf": "96F51ED1-CD08-40F2-B538-0749D8AA149C",
    "Cheung Alexander-Enrollment Letter.pdf": "84EB50F0-66A9-4EE8-A0B5-8C5AA93181CC",
    "CHEYUO CV 1-14-2020.pdf": "65D30F19-8F3A-44AE-8E8C-134E6482E5DA",
    "CHEYUO CV 10-15-2023.pdf": "83764A04-9554-478A-A142-D53C5FD0D712",
    "Cheyuo Neurosurgery Diploma 1.pdf": "40DAA2BC-CF3D-483E-B336-48DE16C81DB7",
    "CHHEDA RUSHABH CV 2023.pdf": "DF3B4788-4E37-484D-843C-89E2F83AF3DE",
    "Chiari and Pregnancy CNS Poster.pdf": "CCB93A54-5977-4E2D-814C-0A85A2156C2B",
    "Chiari and TCS Peds Section Poster.pdf": "FEE296F0-7C01-4C52-B05E-1F9691B719B6",
    "Chiari CTD Poster CNS 2021.pdf": "EE23AD40-7B5D-47D8-81DA-DF9C9F9DF09F",
    "Chiari Poster - 20220929.pdf": "B9BBFAC6-D5FF-464E-A17E-01345953C698",
    "Chiari Poster_CNS_2023.pdf": "72AC83C9-FB3B-4093-AA82-CE6C54D5A6A5",
    "Chiari Table 1 (0318)JB final.pdf": "F20F38EA-56CE-4905-AA09-E6A70C2CB3A3",
    "Chiari Table 2 (0318)JB final.pdf": "CA40ADFC-26FB-45B9-B1F1-108982B0FD17",
    "Chiari Table 3 (0318)JB final.pdf": "12AE201B-D33F-4B30-8426-9541174A12D8",
    "Chiari_LOS_CNS_ID_029139.pdf": "2BA08C25-DDC9-45C4-8C7D-BF3A43E2EDAF",
    "Chiari_Poster_v4_09.26.2022.pdf": "88C5D9B0-1AE4-47D4-B088-56F91D0581CC",
    "Chiari_v1.pdf": "271B847B-09EB-45BF-AA86-2B3F1165ACDF",
    "Chiba Ene CV 06 11 2022.pdf": "F3715DD3-9457-4352-853C-97D83E7F6CF2",
    "Chibawanye Isidore Ene letter.pdf": "00DB9385-6D87-4371-9D51-80B98635F931",
    "Chibueze.pdf": "5C6AB855-E227-4B12-A7B2-3DDBD623F232",
    "chicken_suture_Smerin.pdf": "237FAEA3-E40C-46B7-B555-A3C6750F8361",
    "Chief Residency Diploma.pdf": "C2D59877-12C9-4DED-98C3-14D842DEFE9B",
    "Chilean Society membership-Nov.07.1981.pdf": "573A05C8-F1C1-4D2E-ABF7-CFF38442AFCE",
    "Chiluwal Verification.pdf": "2CA310C3-622A-4BCE-821A-56055B72737C",
    "Chimaobi Ochulo- Letter of Good Standing.pdf": "A064CD36-8A55-4982-830D-33D807BC29E5",
    "Ching-Jen Chen.pdf": "FA2365E4-607E-420F-8FD6-F34E3D4EF574",
    "Chiocca LoR CNS_CSNS .pdf": "FF4CA21C-1839-4134-B674-4BB039C10FAE",
    "Chiu Yuen To CV revised 12_12_2018.pdf": "9C74E564-4BC5-48B9-A553-0BA5142493A4",
    "CHLA Saban Research Institution Poster Template  Portrait 3-3.pdf": "05443947-260F-4E3F-A9F5-2E6B6F176A7A",
    "Chloe Gui-RESverif.pdf": "D442F75A-482C-4818-8371-63214CEFC520",
    "Cho Poster 633 .pdf": "B44A5A37-FC91-4405-8CA3-0D141BEA3C98",
    "Cho%2c Steve (Ltr. for CNS).pdf": "D37A8A0E-70B3-4A84-8E4D-A8670980DEDC",
    "Choi Bryan.pdf": "2C25A10B-FC69-4463-AFA0-E26834967FE1",
    "Choi CV 2023.pdf": "E23BFF64-DF1D-40EB-A551-9B966CE92D4C",
    "Choi John.pdf": "C30FD3BB-80F7-405A-82CF-E6164E9E3B0C",
    "Choi_Verification.pdf": "815FEB5A-A3A0-4C23-9E13-B3688CFC88E6",
    "chondrosarcoma images.pdf": "D3CF7C94-7CBC-4CBC-BEA8-8D608EAE2C67",
    "chord diagram spasm vessels.pdf": "9CDBB7C9-67C2-43B8-8F2D-8FF5B8434399",
    "Choroid Plexus Poster.pdf": "1B29FD2D-A04A-44B7-AEBF-1CEB93DFA4F7",
    "Chotai LOR from Chambless  Letterhead.pdf": "29B11DEB-F97C-4C12-892F-51ABFD12046A",
    "Chou CV_November 2023.pdf": "24B19AC8-C8F1-4155-AB8E-BBDBD1E74482",
    "Choudhary Nikita Enrollment Certification.pdf": "33AC96B0-101E-468A-B1BD-FE3FAAD21D16",
    "ChouProofofEnrollment.pdf": "8104A58D-A779-4E8D-9421-D46D179C2179",
    "Chow, Carrie.pdf": "3F3886D3-0B1C-403E-8595-549935ECB88F",
    "chris zajner-letterofVerif.pdf": "DE0C8D11-9416-4405-BC49-15E409E1B317",
    "Christen O'Neal_Letter of Good Standing.pdf": "2A11501D-78EF-44B0-8316-CC1B1DC0BA51",
    "Christian J. Sandoval Ramirez-FELLOWVerif.pdf": "99525025-55F3-4F2D-AE3E-85B35FA34835",
    "Christian Linares academic standing.pdf": "0A4C73D0-9B67-4905-A06B-DF3A38004B83",
    "Christian Martinez LET.pdf": "2E4E5C4F-59CF-41AD-BF85-53B785AD909F",
    "Christina%20Huang%20Letter.pdf": "22FB62B7-E574-4CAB-8EDF-2363C946E3C4",
    "ChristinaJackson.CV060322.pdf": "E4212D0D-F1E2-40B0-A912-01F26FD2A5DE",
    "Christine Lin.pdf": "E99247F1-687E-4F03-AA1A-13F74C46F685",
    "Christine Mau-RESverif.pdf": "B74646B4-8393-473C-B620-65A9A47977C3",
    "Christine Mau.pdf": "48CEB9F9-9127-46ED-9D92-B1E0430607B8",
    "Christopher Dibble-RESVerif.pdf": "0869DDC5-C800-49B3-8816-8420E267F33E",
    "Christopher File LOGS.pdf": "22EE4F02-980E-405D-A130-747E0AEA123C",
    "Christopher Hong-FellowVErif.pdf": "8CA9EB99-BB56-441A-A5A8-C6686135AE30",
    "Christopher McGinley letter.pdf": "8C797C39-EE0D-4270-B267-EBEB28FA18C7",
    "Christopher McGinley LOGS.pdf": "28B1F17B-F238-43BC-A471-86597D96331B",
    "Christopher Pasarikovski-VerifFELLOW.pdf": "60904B10-7297-4195-91B1-98D395CF222B",
    "Christopher Stapleton-RESverif.pdf": "5BCBAEC3-A11A-432F-8CA3-BA8AD3B2B363",
    "Christopher Sy-RESverif.pdf": "3F346C1C-439A-49B7-84B2-2250705C957D",
    "Christopher Young-RESverif.pdf": "B915BC89-EED2-4562-BBF7-459D5106D691",
    "CHRONOLOGY AND DESCRIPTION OF CTP PROTOCOL ADJUSTMENTS.pdf": "7E8BEDB8-1EAF-4903-937A-912BFA477C53",
    "chung good standing.pdf": "94A0D885-818C-432B-B1C3-AFA72094C8E4",
    "CI NF2_CNS Poster2.pdf": "F9E70E30-B3F9-48D2-BCF4-B104642BF63E",
    "Ciacci SNS nomination_AK_FINAL.pdf": "6F5409E3-3210-44F0-AB83-1A0D55DC8CEB",
    "Cierra CNS LOR.pdf": "7164C325-5921-4E62-B6E5-CD2E95A450A5",
    "Cierra Harper - CNS MSS FRS.pdf": "D7085379-35EB-4BDC-8D8F-1F4E3FF9628E",
    "Cierra Harper - Letter of Good Standing 4.30.20.pdf": "850090C0-3E7C-4A1C-B22C-534B4DFD2492",
    "Cigna modifier 25 sign on 041823.pdf": "F4ED5D47-DC5B-41E5-B65E-73907809ADED",
    "CIM Guideline Chap 1_Final.pdf": "79E80A22-8F7A-4EB3-85B7-A1D1E46B5C10",
    "CIM Guideline Chap 2_Final.pdf": "1D2C0390-050C-4B3E-A066-5D094C509F58",
    "CIM Guideline Chap 3_Final.pdf": "5067FA36-3AF0-4909-9FC4-77ABA32FF6AB",
    "Circulation-2011--e54-e130.pdf": "44DC7EEE-0342-4F7A-A321-066004A3B715",
    "CIRCULATIONAHA.111.032268.pdf": "2D1B3AB9-5A1B-4966-B48F-0B8161580637",
    "Cirriculum_Vitae_James_Bayley.pdf": "60F25786-D6B3-49F2-A632-2403EB52E589",
    "Citations_DSH.pdf": "A4A715E0-0FA1-4581-BC48-0BF31FC0BABF",
    "ciw861.pdf": "D99E0EBF-7C62-4225-BC8B-83FA1CC53EDD",
    "CJA CV.pdf": "85DF9C6B-5FD7-4994-B53E-44B24E49325E",
    "ck5933686.pdf": "4DD5748C-047E-4341-8675-0F1958658F04",
    "CLAE 2022 Poster_AGW.pdf": "FF72D0FE-C3C4-454F-A4B8-10F20A96C353",
    "Clark%2c Andrew.pdf": "A2A01341-BD94-405A-A777-FBA8040D6752",
    "Clark_letter.pdf": "39056CAA-37FB-4A5A-BA94-2EEBF6F6D388",
    "CLARYS_SPELLE.pdf": "D93F2AAE-C80E-4BA7-AA60-F823DFB0DCA6",
    "Claudia Fernandez Perez - Good Standing.pdf": "2BC69981-EBF8-4135-BC05-5E8840CAE8AF",
    "CLAUDIA PISCITELLI CV-.pdf": "96C68078-45A2-43BD-8C0F-5B3F833AEC7E",
    "Claus nomination letter 2021.pdf": "84EE92FA-1E9A-410C-AF8B-C2EE8D2F1EED",
    "CLee.emailverification.pdf": "3B0D6AEA-BB1D-450A-BC5A-33E657964958",
    "Clemens M Schirmer CV 1-1-2022.pdf": "7DA34D0F-8A37-4B98-8AA2-72EAE4281506",
    "Clemens M Schirmer CV 12-27-2023.pdf": "95F150A8-EB77-40A2-A8C3-56F5F1C5CE44",
    "Clemens M Schirmer CV 2-29-2020.pdf": "E5189228-319A-4C81-BE02-C54C1F63A376",
    "Clemens M Schirmer CV 3-5-2021.pdf": "D71203E8-DA38-455D-A17B-377E67BD48C0",
    "Clemens M Schirmer CV 6-11-2022.pdf": "9BFDBC0D-35DC-47EE-8FD9-61B7F85C8832",
    "Cleveland Clinic Foundation.pdf": "DA3FD852-DED3-4906-BF7A-A34E50D68E04",
    "CLewis.emailverifications.pdf": "D0BB92C4-6573-43C5-A421-6216994643B6",
    "Clifford Pierre-FellowVerif.pdf": "C514642F-9233-48B7-8310-8C7F42DC89A5",
    "Clinical Policy Critical Issues in the Evaluation.pdf": "56A7CBA7-9AD9-400E-8F58-2F4AF4916C57",
    "Clinical Policy Procedural Sedation and Analgesia in the Emergency Department.pdf": "BE88E879-F977-4661-8F9B-463696CE28D0",
    "Clinical project.pdf": "33675986-8283-47CA-B81D-7ACA89B8A425",
    "Clinical research plan.pdf": "81C824F0-746C-4284-9220-50D21A9DF0FB",
    "Clinical research project.pdf": "6C399BE3-5960-42D2-91D7-210790C8DBCE",
    "Clinical Trials - I. Germano.pdf": "D6B1E280-8E93-4FFE-B8BE-FC2DC5C505D6",
    "Clinical_Assessment_Following_Acute_Cervical.8.pdf": "D9FDE46A-02A5-45A7-BD63-9066C30EB8B5",
    "Clint Badger-Fellowship.pdf": "E74D6EBE-0860-481B-AD30-256CE4F247AB",
    "Clint Badger-RESverification.pdf": "D9F949B5-D52D-4644-B2E9-F861D410400F",
    "Clint_Morgan.pdf": "E4247F7A-C557-4A22-BE9D-806D218B061E",
    "Clipping of Recurrent AcoA.pdf": "424B35A7-461B-44F4-B1FF-766948197C7A",
    "Cloughesy et al. Nature Med 2019.pdf": "CB2AE744-1A1D-474E-BF37-24E7AA3BDE06",
    "CLOVES Figure 1 w description.pdf": "2F01B600-4A39-4EC3-85F0-3E21911A2BFD",
    "CLOVES Figure 2 w description.pdf": "5E4FAE24-6E03-4C70-A250-273FCA5309DE",
    "cm-converted.pdf": "9294585A-C591-4508-B9B2-7619959AF7AA",
    "CMarcellino.emailverification.pdf": "1BBA8BF1-B62F-4A83-9377-F7EED24D10CA",
    "CMau.emailverification.pdf": "51FF0457-5410-4B90-A373-383BBE63AA3B",
    "CME Program Compliance Manager.pdf": "5532B299-179A-48E9-93C3-5D8B50907490",
    "CMHolland_CV2016.pdf": "BDFB5B66-1ADC-4081-9AAF-F84324CA8683",
    "CMHolland_CV2021.pdf": "476A16B5-A0F7-4054-AC02-590361A3B7C0",
    "CMitoko1_ CV  April 2022.pdf": "4D7D1E5A-CDE9-4381-8FC0-FD0B1C9798B3",
    "CMMI letter re APM recommendations 5-25.pdf": "163583B9-EABC-49C5-9066-FFC70FF0DA06",
    "CMMI Safeguardsd Letter 031220.pdf": "2DE30452-5B5D-4816-B239-B9948890D306",
    "CMMI-Multi-Stakeholder-Letter-to-the-Administration-2021.pdf": "8DA7ED09-6CB4-4FBB-AF6B-0303888CE87E",
    "cmmi_principles_final.pdf": "EC70D427-4A47-4CAD-A791-4F0E6683462D",
    "CMO Horn letter.pdf": "E641F60B-7530-4421-B055-3D8BBC21A2AC",
    "CMS Globals Meeting Follow Up Letter Oct 2021 FINAL.pdf": "FFD799A5-3D6C-4810-98B9-7C812A6CE6C4",
    "CMS IRF PPS proposed rule sign-on Letter 6-11-20.pdf": "AFD97D14-9C49-4895-B3AF-7A7C23600CEC",
    "CMS Letter - Response to Stakeholder Prior Auth Concerns.pdf": "B36D0C5F-1CB1-49D4-80D6-9812E1F07CC1",
    "CMS Letter061810.pdf": "978BA1A1-9173-42DD-A17F-B5C0792B1B36",
    "CMS.G2211.19SurgicalOrgs.7.26.23.PR.FINAL.pdf": "BCD4FDBF-1AD4-463F-B451-368DA53BCB71",
    "cms_letter_on_edit_aanscns090115_22630_22633w63042_63047.pdf": "569D83B0-085F-44D3-8A5B-55A2FD86B0D1",
    "cms_patient_access_and_interoperability_comment_letter.alliance_of_specialty_medicine.060319.pdf": "A34496CA-68C3-4067-A9FF-FD89EA4E611A",
    "CMSAllianceLetterOnOverpayment41612FINAL.pdf": "A7401F0E-6EC9-4268-8A8E-DA618D8A66EE",
    "CMSLetterFinal6-10.pdf": "119DC6C9-91FC-40B2-A1BC-6F789F7DA195",
    "cmss_macra_comments_final062616.pdf": "3CF48895-644D-474B-AD2C-44EE75882743",
    "cmssunshineletter.pdf": "469896DE-7A59-4941-BF9C-4F503B06D841",
    "CMU.pdf": "F08D9155-128E-4A1B-A3C3-36072EC6842D",
    "CN Certificado ACSS Grau Especialista.pdf": "428F0519-7ED6-4980-9588-FF7E9A14E440",
    "CN CNS 2019.pdf": "4582D3F2-462D-4B8E-894F-0AE2FE21FD6A",
    "CNS  - Alopecia - Poster final .pdf": "24C11F71-3C11-4CA5-AAED-551AA36804FD",
    "CNS  2021 E-Poster-Adnan.pdf": "03DC214A-623C-442E-9469-FA05AD560580",
    "CNS  E Poster presentation-2.pdf": "984B5846-588B-4E29-A4C7-A6FE9CAE679F",
    "CNS - Allied Health Global Nsurg Fig 2.pdf": "4FB1754A-F609-4845-8263-1693E2D95DEC",
    "CNS - Allied Health Global Nsurg Fig 3.pdf": "A9B09A5F-4D88-4E97-85E3-3B13ACA6958C",
    "CNS - Allied Health Global Nsurg Table 1.pdf": "2ADC69E0-EF8F-46E5-B341-D6A81112BED9",
    "CNS - Allied Health Global Nsurg Table 2.pdf": "55C49F8F-BF21-4D97-9B62-33F7124E1B2A",
    "CNS - Amirreza ALIJANPOUROTAGHSARA M.D..pdf": "2883A421-9B2D-482E-9EEB-140D0F0EFCD6",
    "CNS - Anesthesia - Poster final .pdf": "7823B951-BC50-432B-A915-AB9FCC4721F3",
    "CNS - Clopidegrel.pdf": "41E4326D-2E6A-4374-9A92-F879678EAF88",
    "CNS - Dev Valid - Poster final .pdf": "F09A9612-5658-4491-8B81-5BAA9B5E31D4",
    "CNS - Global Nsurg Allied Health Figure 1.pdf": "F69BCE17-50EF-4F9A-A4B2-24EB5CAEC9FB",
    "CNS - International Tumor observership project.pdf": "BF5654FB-0E6C-48B0-B7BD-9084C89FF603",
    "CNS - October, 2019.pdf": "FA3C46B2-978C-48A1-8E8F-2BF5C1DF7892",
    "CNS - Resume - Tiffany Ross.pdf": "A646B90B-BA65-4F1B-B352-02764A074F03",
    "CNS - Sam Daly.pdf": "439386EC-F928-40B0-BD5D-7C59E03591AF",
    "CNS - Shunt - References.pdf": "4AB75129-A9F3-4867-9234-BAF464A5B66A",
    "CNS - Shunt - Table 1.pdf": "2BACB756-01C7-4F54-B0D2-5EA48DA41018",
    "CNS - Shunt - Table 2.pdf": "2F6C9407-CAC9-40AA-B4B7-9DFE78A4D6BB",
    "CNS - Verification Letter.pdf": "A298BEFE-53CF-40FC-809E-792432DB49F9",
    "CNS .PDF": "4D056E3F-1278-46E4-A3DC-0FD900A0D7D5",
    "CNS _SectiononPain_Figure.pdf": "78BDBAA5-E892-4E72-B86A-CC6BA375F96B",
    "CNS 032232 Poster_Kevin Kim.pdf": "D2F06973-850B-4E2B-BB39-AAD8AE9EE706",
    "CNS 1.pdf": "C1B63B40-C4FC-4103-A208-E88BB1BDFB76",
    "CNS 1332.pdf": "774585FB-8F99-49D0-B3B6-774C2311E770",
    "CNS 2.pdf": "FA139881-A7B5-4B0A-B490-07E58DB9ED53",
    "CNS 2001 - Annual Meeting San Diego.pdf": "A40DD21B-2805-4138-A818-E828615E6E9C",
    "CNS 2006 Chicago program.pdf": "8515D9F6-D121-4286-8F7F-4CD143AFF136",
    "CNS 2017 RFP_2015-11-17.pdf": "8BC2BD94-8A67-4E19-9B65-E826CB0347A0",
    "CNS 2019  Poster Ependymoma resection.pdf": "43F80E01-C3E2-47DD-9BB2-32318908A7FB",
    "CNS 2019 - Poster - Meta TLE Project.pdf": "788E3A95-402D-47B3-9A78-68A48C4C34A2",
    "CNS 2019 - Poster - MS-TN Project.pdf": "9E287C17-11A9-4F45-9C21-A82B40DEB847",
    "CNS 2019 - Sacrectomy.pdf": "3E978977-DE5F-4351-AB24-19D88345E9B3",
    "CNS 2019 Abstract 2672.pdf": "EF8A60B6-9862-4440-B815-546177A50738",
    "CNS 2019 Carotid blowout poster v2.pdf": "7B23D464-C42D-4078-86EE-F36C235EA530",
    "CNS 2019 MIS for TDH .pdf": "34DE993C-B48B-4D23-A5B2-59CA1AF21005",
    "CNS 2019 PDF poster upload - Families at the Bedside Natalia Romano Spica.pdf": "61A281A8-1A60-40AB-BB68-A6D3EEF98F3E",
    "CNS 2019 PDF poster upload - Stroke SoMe footprint Natalia Romano Spica.pdf": "281E3E06-5026-441E-9CB7-0C93E1F65DF7",
    "CNS 2019 Ped AVM 2.pdf": "EB02F2F6-5399-49C5-869B-C540D5707431",
    "CNS 2019 Ped AVM.pdf": "5200CE6C-E175-4490-B912-5A9BA7EE3CAE",
    "CNS 2019 Poster Presentation- Bilateral PED.pdf": "AF090D5D-787A-40D1-84A9-BE7ABD132A6F",
    "CNS 2019 Poster Template - Predictor of Cost.pdf": "64F2AF84-D1FA-4C57-8E61-DE0947A06581",
    "CNS 2019 Poster Template - Time Of Surgery.pdf": "A63E587D-A97E-4C0E-B26B-0404DE333399",
    "CNS 2019 Poster.pdf": "E2591BF7-9A09-49E5-9EFF-36A4EED670B9",
    "CNS 2019 PRG Poster.pdf": "3848C038-C110-4B12-97A0-6FCA854C7C57",
    "CNS 2019 Resident Wellness Poster_FINALv2.pdf": "1C15638E-0722-4DD6-A87D-CB68ACF738C1",
    "CNS 2019 sPNET Poster 2.pdf": "185BB1EF-5A9F-4E9B-B460-CA4A3724E668",
    "CNS 2019 TCR_Scoli Poster 2.pdf": "4E315B64-82AD-406A-B0B2-3FDCC7132943",
    "CNS 2019.pdf": "A96B8BF4-EFEB-4DF1-BAE4-CB99B662BFEB",
    "CNS 2019_RaDical_Kunwar Poster_Draft 07Aug2019.pdf": "2996F62A-9A17-4F7D-91DB-3A798270053E",
    "CNS 2019_Stroke Awareness Knowledge Gap in Women.pdf": "92A24A5B-DFFD-4A9A-9D70-3F3170C13B1C",
    "CNS 2020 Tables.pdf": "D907A5E4-34F6-4A7E-A149-CEE4BF744837",
    "CNS 2020 TN Abstract, Figure.pdf": "F90AC9BD-0C89-4E1A-B659-2631E7C04190",
    "CNS 2020 Visual Abstract.pdf": "BF965DB0-757C-4C10-BF1E-5201358ABA19",
    "CNS 2020- image.pdf": "DC59F4E8-A9C1-4D70-BF2A-5F9414627160",
    "CNS 2021 - Active CSF Exchange System is Effective for IVH Treatment-1.pdf": "81FEED31-E386-4003-8F0F-3C22BB8BA880",
    "CNS 2021 - Anticoagulation Project.pdf": "3E3DE897-A6BB-414B-AC56-13D5FE43D713",
    "CNS 2021 Acute Ischemic Strokes, PAVMs - AD, KY.pdf": "73A4D4BF-CC39-46ED-8E4A-6EF1FE510F4B",
    "CNS 2021 adjacent segment disease after MI-TLIF.pdf": "B6C86183-02DC-4F4C-A36E-8641E07D53CE",
    "CNS 2021 Annual Meeting_Abstract ID_033029.pdf": "6B347551-5AC7-4550-AB7A-1F093502F7AC",
    "CNS 2021 CPS poster.pdf": "7809AD47-73B8-4997-A397-A56BA1297EF3",
    "CNS 2021 Depression Proximity Table 1.pdf": "BD96B3A4-66D6-4F6E-8707-8F04B858AFCA",
    "CNS 2021 Dysphagia.pdf": "26F1758B-A00F-4ABE-B389-3532EF907FD3",
    "CNS 2021 LivingConditions Table 1.pdf": "3D5E1D22-CDF8-4440-A80A-059F10626A17",
    "CNS 2021 Mantovani.pdf": "71A3FA5F-9FB0-49D0-ADAA-F063917AA725",
    "CNS 2021 meningioma Embolization poster.pdf": "0C523BA9-CD12-4075-B9FB-DD89276A4287",
    "CNS 2021 multi level lam poster copy.pdf": "F0A29D1B-1F4B-47C3-BBE0-3B3CD5C26523",
    "CNS 2021 Poster Final PDF.pdf": "BDC5B685-400D-4468-B167-88AAF4B76535",
    "CNS 2021 poster v2.pdf": "08549948-D1B7-40C6-AFAD-8BC67F73C923",
    "CNS 2021 Poster.pdf": "454E0510-A0F7-4B2F-AFD4-24299E7E742E",
    "CNS 2021 SocialSupport Table 1.pdf": "53221168-499C-434E-9EF0-5DF4F210127F",
    "CNS 2021 Substance Abuse Table 1.pdf": "653E15DE-242F-4AC5-AC9F-0C80353F25DE",
    "CNS 2021.pdf": "87831550-E103-4D72-933A-1A83AF589442",
    "CNS 2021_PMiller.pdf": "F25C234D-895D-449E-B448-608D0A84AC3E",
    "CNS 2022 - Bedside SDD.pdf": "EE338252-D6DE-4DDB-B62E-31317B664D3B",
    "CNS 2022 - Numbers 2.pdf": "2E630FA3-98DE-45DE-BD53-51C371E6192E",
    "CNS 2022 - Spinal Cord AVM Recurrences.pdf": "B88F379F-EE41-434D-8D6B-B292CE427262",
    "CNS 2022 - Targeting T cells in lupus.pdf": "E37BBCD0-E04E-4705-A0BC-9276D94255F5",
    "CNS 2022 Abstract Gender vs SIJ motion.pdf": "8F2C073F-0FF5-4AF6-818D-BF036C043DC5",
    "CNS 2022 ALS CSF Filtration.pdf": "0D1935D2-E441-477C-81EA-5EB81EAA1135",
    "CNS 2022 Embolectomy Poster.pdf": "871CD7BA-CF1E-452E-A049-1DAB28028A56",
    "CNS 2022 Garber EPoster.pdf": "B97C95C6-366F-4B35-915B-046A189538F3",
    "CNS 2022 Meningioma Embolization- [Faulkner] .pdf": "09F82A25-D214-4631-86B3-DA4CBDE43286",
    "CNS 2022 MMD Methylome Poster.pdf": "DC57D935-4D73-4513-B4DA-1CA5521B00FB",
    "CNS 2022 Muscle Relaxant Delirium poster pdf.pdf": "D2519363-199C-42A0-98C6-754D05FA729A",
    "CNS 2022 poster (Jae Ho Han).pdf": "6637F396-185E-45A8-B450-002347FC3DB3",
    "CNS 2022 poster - Fibromyalgia ACDF.pdf": "A33F7ABE-0D90-4EC2-A363-420825515912",
    "CNS 2022 poster - IIH stent.pdf": "82AA24D7-84EF-4F89-810D-835765ECA6AD",
    "CNS 2022 Poster 1.pdf": "811BE48D-B1CE-4335-952B-2C70902EAA4E",
    "cns 2022 Poster awake dbs pd.pdf": "9B091A11-4195-4A05-BFFB-CA5CC030B4B0",
    "CNS 2022 poster CBA allograft.pdf": "FAF30DC6-1C5D-423A-9F62-FEAC77A571A1",
    "CNS 2022 Poster PDF.pdf": "F9088163-C350-486D-A448-13F2452E9FB3",
    "CNS 2022 Poster, AL.pdf": "4A39E175-0F55-4ACF-93A9-C267FB1BDDCF",
    "CNS 2022 Poster.pdf": "27C2062A-7EFA-4869-AA45-380448408B68",
    "CNS 2022 Poster_xAI_082222.pdf": "C9F7D897-476B-4FF9-9852-6BAB78436732",
    "CNS 2022 SSF Poster.pdf": "E16C3161-FF9A-4C8C-8D67-E8123C752F51",
    "CNS 2022 Vasospasm Poster.pdf": "6494EF40-4946-4309-B08E-051E8D0C7056",
    "CNS 2022!.pdf": "44FA1465-8E27-49B7-9488-6D4F86EBBDC1",
    "CNS 2022.pdf": "2A6CA541-51B9-4E18-B9AC-BEBEE3E043DA",
    "CNS 2022_ACDF Cost.pdf": "14A1230D-3B73-467F-8484-FF0F11C0CFAF",
    "CNS 2022_PCDF Cost.pdf": "B35F7ABF-3374-4960-B24F-44FE7EAD41FF",
    "CNS 2022_PRSRC Syrinx Location Duraplasty_Poster_100722.pdf": "98F00873-94EE-4ACB-8BE0-3E07AC2FD575",
    "CNS 2022_TLIF Cost.pdf": "7F55A503-59C0-48ED-A6A9-D8030B725890",
    "CNS 2022_TLIF Outcomes.pdf": "49EBA3D3-D101-4EA7-8379-9F45E8F37CB2",
    "CNS 2023 684 TLIF case.pdf": "C1B0CFF8-0963-4153-ACC9-27B22A91642C",
    "CNS 2023 Annual_Report_High Resolution.pdf": "6DCACA05-98AA-41FF-909F-AB87DEEDB79E",
    "CNS 2023 Annual_Report_Supplement High ResolutionV2.pdf": "E295AABE-430C-43C6-A255-97717ACE9D2E",
    "CNS 2023 Autoguide poster.pdf": "E51DE42E-35A6-4D44-8384-388A0937F18A",
    "CNS 2023 CM abstract 3223 RP.pdf": "CC514E90-AF6F-4E49-9DB3-E8B07C8FAB32",
    "CNS 2023 CPRS Poster.pdf": "CCD093FD-BBF1-4571-BC77-F8777AC12D98",
    "CNS 2023 Digital Abstract Romero.pdf": "72975B5F-8894-4690-A244-9890644BF782",
    "CNS 2023 Digital Poster.pdf": "D0B706A0-1E58-4CC2-A2B8-1F065F5B789E",
    "CNS 2023 Digital Poster.pptx.pdf": "8F49947D-F1F9-45D1-B4D2-BD20C80C1538",
    "CNS 2023 drill autograft cost saving.pdf": "B670DBBF-5EE9-4392-90C3-C2E4B3F63A36",
    "CNS 2023 DSA Poster.pdf": "C05C47F6-AFC8-41EE-B871-69D47A50AA20",
    "CNS 2023 final avm GKRS .pdf": "6B40C763-8F57-45FA-91B5-57A7057A3AB9",
    "CNS 2023 Hardware revisions poster.pdf": "2A1CD55B-22D9-4FD9-B9B1-0C4A9EA86DB7",
    "CNS 2023 Koga et al FWC Tractography poster.pdf": "12CAA991-95A0-4440-9DC0-BFF6AC68F02A",
    "CNS 2023 MCAO Poster 8.8.23.pdf": "CBCDF072-5BCD-4913-9AD4-DFF6CC4C3F1D",
    "CNS 2023 multi lam poster.pdf": "BBFB8130-42DA-4D6D-8F8A-ACA7DF16FAF5",
    "CNS 2023 Patient lift poster.pdf": "2564F901-C2C4-48FC-B28F-1F81E9213847",
    "CNS 2023 Peds Quick Planner_Final online copy 11082023.pdf": "5F072300-0A49-4B96-BBA8-1D64456EE872",
    "CNS 2023 poster - subclinical sx of SRC.pdf": "8BAD852E-77EF-4634-9B46-16B015221E9A",
    "CNS 2023 Poster NeuroOne.pdf": "46F71471-7FF6-4B6F-B6B8-06CD26D15EE5",
    "CNS 2023 poster Precaruncular.pdf": "8EEF5898-8E8F-46AD-99A5-0320120996CB",
    "CNS 2023 poster SMIF.pdf": "E6B23B27-06B9-49E1-85AD-464F480939F4",
    "CNS 2023 poster sorrentino.pdf": "2837D858-9299-49F8-B092-374C1CDAC550",
    "CNS 2023 Poster.final.pdf": "704BE423-919F-49D5-B08C-6AF70BA0C3F1",
    "CNS 2023 Poster.pdf": "DF46BC9B-7F6A-4A47-8D5D-09EB0CC0DE25",
    "CNS 2023 Preliminary Program Online Final 6_22.pdf": "CF4C40C5-EA1C-48A8-B1C1-58204160B718",
    "CNS 2023 RESET RA Poster 22AUG2023 FINAL (2).pdf": "EC537EF9-EA4F-493A-9E7C-640A7AA657B9",
    "CNS 2023 RTM poster Alan edits.pdf": "5142CAC7-3750-419A-AA09-7E748C688AE3",
    "CNS 2023 S2AI.pdf": "F85FF666-5ABC-4C65-A0F3-DDEB998A48D4",
    "CNS 2023 SAFDAR Cage Subsidence.pdf": "6E7DD534-9CE2-4435-A3F0-63349FAC6131",
    "CNS 2023 SITE Score Poster.pdf": "316381AE-D446-4FF4-A2F1-1CF0C7769277",
    "CNS 2023 SOA LTOA.pdf": "80EFB791-B920-4886-A197-44ECC6F82568",
    "CNS 2023 stem cells.pdf": "FE6A6E54-FACF-4568-9554-BB25EDBAA54A",
    "CNS 2023 SubE Illustrative Case.pdf": "F96426DF-F82D-4E22-8F3F-27B97A9A8FB2",
    "CNS 2023 TLIF cellular allograph.pdf": "750538DA-90A1-4179-9870-0989AB52723C",
    "CNS 2023 Tumor Section symposium prelim program Web Version 05052023.pdf": "1390421C-C55B-4FEF-9237-B35C61E156F5",
    "CNS 2023 Tumor Section symposium prelim program Web Version 07172023.pdf": "2E993E8E-4439-4290-85A8-2C8079E84035",
    "CNS 2023 warfarin DOAC poster.pdf": "E4DF7101-C59E-475E-8C8D-B0ED5E6AE1DD",
    "CNS 2023 WEB Follow-Up.pdf": "428388AE-0CFA-4FDA-88AC-F6B9D9AFC7CF",
    "CNS 2023. LMD and VE..pdf": "C1E3A8BC-FAF6-45CB-BB31-5065D6A2B911",
    "CNS 2023. Survival and VE. .pdf": "6AE249D8-ECD7-4E83-83CC-CC2FE9951EA5",
    "CNS 2023.pdf": "0F3D5565-F036-4F54-8B01-A319DC99810A",
    "CNS 2023_Digital Poster_DJones.pdf": "A5763FD3-B122-47DD-BCAB-A5131B3C1D19",
    "CNS 2023_Digital Poster_NMoore.pdf": "837B4BFB-B988-44D6-B4FE-F8FFEE6F2B31",
    "CNS 2024 Membership Dues.pdf": "80B5F119-DA1C-48A7-8E9F-CFE7434DCD67",
    "CNS 2024 Preliminary Program Web 6_6_24.pdf": "9E72F024-F254-45DB-9437-CF7005349511",
    "CNS 22 Hba1C poster .pdf": "DED85644-57F1-4C07-97A5-38D778BE0449",
    "CNS 59th Annual Meeting Minutes 2009.pdf": "6FE7BE0F-747C-4EF1-BF4A-412198ABDF4E",
    "CNS AANS VPS Poster.pdf": "A17B8C72-B958-4F32-BDBC-31C6C9A1814C",
    "CNS Abstract - Hh AST Figure 1.pdf": "529752CB-41BA-4608-BC8C-1E66E0D3A016",
    "CNS Abstract - Hh AST Figure 2.pdf": "9798CEB5-33C5-46C3-A986-E3E08AFFEA29",
    "CNS Abstract - Hh AST Figure 3.pdf": "965CBD2F-3DC6-4C8A-913E-933D9EB09552",
    "CNS Abstract - Hh AST Figure 4.pdf": "1F2FB6D3-792B-477D-8EB4-AAB9C747D904",
    "CNS Abstract – Simulation Elective_JSK.pdf": "55F0F401-9850-4F24-9849-7105225C3E9F",
    "CNS Abstract Avatar.pdf": "AB6801A1-B3B5-40CE-854A-C29F764DF719",
    "CNS Abstract Figure 1 .pdf": "4E96D2F3-A8FC-408F-8C21-1B21CD6778FC",
    "CNS Abstract Figure 1.pdf": "C2206BBA-4BCB-4890-8BDD-C78231A1DE4F",
    "CNS Abstract Figure 2.pdf": "76A9FB98-E3D1-4B2F-B69D-1199000800E3",
    "CNS Abstract figure.pdf": "474CB997-9873-4710-892B-ECD53CE8B297",
    "CNS abstract July 17.pdf": "178F0E90-13A9-4886-AD35-A43638732230",
    "CNS Abstract Pit Adenoma_tja1.pdf": "68B5FA5C-4FD6-4501-A1C0-A016A77F5247",
    "CNS Abstract Table 1.pdf": "4F912D43-D0B9-4D09-918B-731D5BFB63A3",
    "CNS Abstract Table 2.pdf": "5A671FDC-AA4A-482D-ABFB-E236FB14585C",
    "CNS Abstract with Figure 1 and Table 1.pdf": "A4FF5712-D376-4966-ABE8-6C18A33547FC",
    "CNS abstract.pdf": "BC43815F-28C7-4C6F-87B5-00CB7E693F8F",
    "CNS ACDF Poster Final.pdf": "1DD08C49-5B42-4AE0-ABD5-16836CFB538A",
    "CNS ACM.pdf": "5772E752-146C-4E1D-804B-17488A7A6C85",
    "CNS ADI POSTER.pdf": "2616B034-5375-4B0C-B12E-EB4E63C2C9F6",
    "cns ajit.pdf": "E879226D-6D87-4A1E-BBE8-B11D51C3B241",
    "CNS Aneurysm AVM Table 1.pdf": "ADD8E983-013F-4596-92DB-293A9B8DE5C9",
    "CNS Annual 2021 EJS Poster.pdf": "061533F5-58E5-408F-90A6-B2391D6DF1A4",
    "CNS Apology Letter.pdf": "4D4A3332-06B0-4F71-B5E2-A55DD0C230FE",
    "CNS app.pdf": "F006C834-2FE0-4CA3-A7B9-14D7BC08E99E",
    "CNS Appl.pdf": "55CCA49A-1BD5-43C4-B615-BCA0331A8BBF",
    "CNS application Alexis Sireci.pdf": "6298B9B4-8101-44A6-A956-1A672A25C5CF",
    "cns application letter bragg.pdf": "F589B1DD-2AD9-486B-8BF0-5DB3BC00BD32",
    "CNS application.pdf": "27273E0F-0337-418F-9CC9-F0D4CB43FD8E",
    "CNS Arachnoid Cyst Figure 1.pdf": "6FCA928A-98C4-4A62-BF07-07A55ADFBA37",
    "CNS Assessing the impact of Epidural Analgesia.pdf": "28281627-B4F8-4F47-BC36-9CD9CB722FA3",
    "CNS Atopy.pdf": "C0F441C9-98F4-4ECF-870C-3DB77116DD9F",
    "cns aux news vol 10 no 1.PDF": "5D242EF2-3115-4C90-9EDA-D659A335CA03",
    "CNS Auxiliary News - Volume 13 No. 1 - August 1990.PDF": "D06C1CD0-5961-4228-B0DC-26E9F6153F0F",
    "CNS Awake AVM Poster 8-21-22.pdf": "9472A6B9-C153-4B5F-A841-6A3AA293EAD4",
    "CNS biosketch.pdf": "F477F821-C571-400C-A331-05E74B7C3615",
    "CNS bonafide .pdf": "E17E4F7E-67C9-4A67-9552-3A212A9A0C81",
    "CNS Bracket.pdf": "AEA40205-FCB4-4224-86C9-85A721864AD2",
    "CNS Brain Atlas Poster 092922.pdf": "39309E23-50BD-4DA1-9A74-35BAC8F2AC8E",
    "CNS By-Laws 1964.PDF": "E0436FEB-CBEA-4C04-AAEE-F4BD54D9A111",
    "cns cauda equina.pdf": "34DFA649-4A29-4F26-8204-FB02A97551A1",
    "CNS cert.pdf": "892A1ACD-EE97-4E60-84A5-8CA97D0985D5",
    "CNS Conference Discectomy Poster.pdf": "33AE3391-EDFC-49D8-8632-D1F207C0CFC6",
    "CNS Conference Poster - PSD Concurrent Complications.pdf": "120DEB85-B5FB-45F1-8A4C-4D076F8488BF",
    "CNS conference poster_TRA2B ASO.pdf": "75B946D3-45CC-44B7-BDD2-66C37503883B",
    "CNS congress.pdf": "494A83D4-77C2-4574-9888-9291E287811B",
    "CNS Cortical incision 8.8.2023.pdf": "D1A0D305-E1AB-4349-802D-B2BA087F710D",
    "CNS CSDH Poster.pdf": "63D1BA4B-D46A-4BF6-8355-4BE0721E0C77",
    "CNS CSM Poster.pdf": "FF9A0EE2-AD36-46F8-BC4E-2DE94AC71188",
    "CNS CSNS Fellowship LOR Letter.pdf": "CA8C9BCE-5DCF-4821-A9CD-89AA4B5FE057",
    "CNS CTB Poster.pdf": "CBC03725-5F80-4E75-A5E0-493602A65D1B",
    "CNS CV 4.14.22.pdf": "F8E2DA26-879C-4926-9A34-A2905ABABEF6",
    "CNS CV.pdf": "75ECDEF5-3A2C-491D-9ED4-3E58F3C8FEAB",
    "CNS CVJ POSTER Final.pdf": "5A876D81-E0A8-49D7-9908-70283F17A4E6",
    "CNS Data Science Letter of Support Lai Grace.pdf": "9D9A8DFA-2937-4955-B226-D9FF924BC76A",
    "CNS data science scholarship_LOR_012323.pdf": "67CCCAF3-1140-4AD8-BDDC-3AA68F515E72",
    "CNS DAVM.pdf": "BC12CDFD-73DC-4509-BC0D-2176BFFEF153",
    "CNS DBS.pdf": "448A509C-6363-4AC8-8274-532FEC415212",
    "CNS DCHC Poster.pdf": "85DA1634-74C3-4E58-9E77-F999C76993B3",
    "CNS Dean Letter.pdf": "503C9156-AAFF-4F07-8738-1B11D2BA4A36",
    "CNS Dean Verfication Letter_PA.pdf": "461599CF-2E66-4C53-9B28-148D87C7DC18",
    "CNS DIAZ DANIELA.pdf": "C2AAFEDB-F0C4-4C43-95E6-BA66D2EDB307",
    "CNS Digital Poster 2022.pdf": "5BAAB0BB-0164-465B-83D1-4B29781C6C00",
    "CNS Digital Poster Only_vFinal.pdf": "A4B91F54-4DF6-47B6-9E83-BA2D7F9F62F9",
    "CNS digital poster pdf .pdf": "E1C3119B-2CCC-482C-988A-BFC2A9A79416",
    "CNS Digital Poster.pdf": "5949286D-EDFE-47B9-9578-49B3E51A972E",
    "CNS Digital Poster_Ankita Jain.pdf": "7E2B0A32-D019-4DF2-AED9-682821ECA379",
    "CNS Diversity in Feeder Schools Poster v2.pdf": "9F7A4AA7-ED18-40B7-B1A4-8637FF4248DC",
    "CNS DR OSCAR FEO LEE.pdf": "600D8FB1-6D47-4B2A-97B8-B1406859C0C9",
    "CNS EDAS poster final.pdf": "8FE354B0-A49B-4F74-888D-5BA05C135EF2",
    "CNS Election PDF- Final.pdf": "E2CFE7D6-0FE1-4F45-9220-CF12FC932747",
    "CNS Elective Table 1.pdf": "BF0B84D1-F838-4211-9A63-D6EFFD1406B5",
    "CNS Elective Table 2 and #.pdf": "4D636DC1-7568-4D13-AAAB-E352857C1883",
    "CNS Elective Table 4.pdf": "75077BB3-891F-47CB-A6A6-002EBF39C314",
    "CNS Elective Table 5.pdf": "7FED2D74-CFEE-4AA7-8D4A-B777E4CE7E03",
    "CNS Elective Table 6.pdf": "BC46E856-7556-4202-AF06-3269660C5360",
    "CNS Embo Poster.pdf": "CC675785-5AA4-4C51-8A5E-F7EE3F80AC88",
    "CNS ENMCC_D-FX Ex-Reg.pdf": "ABEA0A9D-0A59-4281-9448-8572E40E2BA6",
    "CNS enrollment.pdf": "1E3B9CCA-E364-4178-86A4-8E6C761A3625",
    "CNS EPI VS PCA Poster_Final Edit.pdf": "D91605A2-B6D5-47AE-B33B-6E3E541F93DD",
    "CNS ERAS Implementation .pdf": "CAB004BA-C3BD-4CB9-8127-06E648961E6A",
    "CNS error.pdf": "8E826690-8543-4BCC-B796-15908F93F5A1",
    "CNS ETV Poster.pdf": "ECC2F678-4D5F-460B-B16E-A7EC18FC6066",
    "CNS ETV Table.pdf": "39EB5894-6F49-48B5-9915-2372502B8F1F",
    "CNS Event Code of Conduct 6-26-21.pdf": "5C8F1954-C208-4C79-9CEB-C30A571E7F19",
    "CNS EVEREST abstract v1.0 27May2021 Table 1 for submission.pdf": "AA58ED1F-ABB0-417F-8E0F-6A8F8B34911A",
    "CNS EVEREST abstract v1.0 27May2021 Table 2 for submission.pdf": "8C4118D0-2B2E-420C-8F50-933C53B94BE0",
    "CNS EVEREST abstract v1.0 27May2021 Table 3 for submission.pdf": "922F86D3-2341-4D49-A604-69E06E18468F",
    "cns exec com report 1999.PDF": "C9A1763A-7314-4323-86D5-7D21EE55A598",
    "CNS Exhibit Space Application _ 2024 CNS Annual Meeting.pdf": "942F86D3-7361-42B7-8BC4-FF4F23085CC9",
    "CNS Exhibitor Prospectus _ 2024 CNS Annual Meeting.pdf": "2D1D257F-099B-4C61-8A50-903F04ED3B46",
    "CNS Faculty Waivers.pdf": "E66D0450-A29D-4692-B81D-E4B650D16330",
    "CNS FAQ_ CNSF Fitness Challenge_FINAL.pdf": "410FBA82-5DBA-4F11-971F-13BACE105312",
    "CNS Fellowship Letter 1.22.20.pdf": "B36C61B6-2D32-41A3-876E-BC9089CD8BB0",
    "CNS fellowship letter-Maher 2-2020.pdf": "F682195F-32EC-40D0-A922-2C8A9AAF392B",
    "CNS Fellowship Verification 07_2015.pdf": "2C07ADFF-5445-427C-89C9-142211C21BA1",
    "CNS figure (1).pdf": "0262BB9A-11DC-4301-943E-F9ECD0862B7F",
    "CNS figure 03162020.pdf": "9DA757E1-985D-4A55-BB8E-A8C48B4A7CC7",
    "CNS Figures Abstract.pdf": "F8D6C8B1-A875-40E0-B2D4-07436EB7FCEC",
    "CNS Final NB.pdf": "7689AA7A-1148-4995-8F42-14963CB4086F",
    "CNS Final Poster.pdf": "B584D649-337E-45FF-ACB6-B6FA055D74BF",
    "CNS Future Leaders Award Application_Nardin Samuel.pdf": "0BF4E38D-BEBA-478A-B026-ED3A2049138B",
    "CNS GBM Digital Poster.pdf": "AC293BB1-4FF2-4AFF-966F-9949BC237332",
    "CNS GBM MHD Poster 093022.pdf": "715067A5-B92E-448F-BF22-51D33926529D",
    "CNS Gender.pdf": "DAE1FB47-1CCB-401E-AB5B-CCEECE0FE13E",
    "CNS Gliomas.pdf": "B5B0DCD9-CF91-4886-BD73-308FE3EBBFDD",
    "CNS GSW Poster.pdf": "14CE7488-FCAB-4A9B-A684-5AD1B7AE762E",
    "CNS Guidelines Fellowship CV.pdf": "1B3E5344-1168-45E7-AC91-D2979D8D1990",
    "CNS guidelines fellowship signed LOR.pdf": "46B3B99B-8FB5-4851-A8B1-96A395671DF9",
    "CNS Guidelines LoR Sweet for Labak.pdf": "E153AD11-DF66-4DB2-8F0C-098E94F2E3CB",
    "CNS GUIDELINES_Neuroablative Procedures for Patients with Cancer Pain.pdf": "0DDFAA5C-FC04-4D7A-9145-293650631A03",
    "CNS H Adams.pdf": "054C0861-208F-4797-B834-32B166DF6588",
    "CNS H3 proteomic poster_FINAL.pdf": "40270D6F-5961-477F-A769-8B6B757C0225",
    "CNS Headache Poster 2023.pdf": "4EF795CC-26E3-43C9-BC3F-B869F3C103A0",
    "CNS Hemispherectomy.pdf": "F9163298-2D7D-4A8C-BDD8-D5EE7D4841CE",
    "CNS HGG VAD poster_FINAL.pdf": "073DA39E-E5EE-4625-B4FA-8F96C711D7D9",
    "CNS Holwerda.pdf": "BE17610B-95FC-40D6-8B99-B728D5209783",
    "CNS hypokyphosis figure 1.pdf": "3210D2F3-3578-4D2E-B1FF-C3A0A06D03CC",
    "CNS hypokyphosis figure 2.pdf": "BFD0AB34-710A-44EF-A24D-F5442DE1C28E",
    "CNS ICH Mortality Poster_final.pdf": "A5FEA786-6100-4295-8228-5C7C632D5D7D",
    "CNS Industry Allies Council _ Benefits  Recognition.pdf": "E73ECCE3-779E-49DE-AE6C-775C2152127D",
    "CNS Industry Support Prospectus_2024 CNS Annual Meeting.pdf": "5C09BBAE-23CB-458D-A7CF-01D3B84D1C8D",
    "CNS interactive poster 2019 ML-HG.pdf": "CCEEC4CE-9681-4EE7-B165-ED990CB2A167",
    "CNS Interactive Poster Edwina Tran 08-21-2022.pdf": "D2781226-A523-4395-A664-1ABDE67B449B",
    "CNS IntResLetter CCowie.pdf": "0EF1B5D5-B06C-4775-A96E-7C7E0C0BA6A9",
    "CNS ITP Poster.pdf": "6973250E-3706-4929-8744-1A2DFC8B9D2B",
    "CNS Leadership Chair's Letter_HVL for KV_1.10.22.pdf": "ADA8DB78-3C44-42AE-A0A4-3C41F7100458",
    "CNS leadership course letter.pdf": "CE990EC0-4191-4381-AA2B-01246573586C",
    "CNS Leadership IInstitute LOS.pdf": "AA04C9B1-2298-41AA-A3E5-84F9367EAF5C",
    "CNS Leadership Institute Letter-Dr. Kaufman.pdf": "13523536-C71A-4748-8F94-158D293704FF",
    "cns leadership institute LOR.pdf": "DFB8C39C-BCBB-45E9-8CF8-65BDEE202998",
    "CNS leadership letter.pdf": "2027EE17-1DD9-44A1-9AF4-B39C78E3DE54",
    "CNS Leadership LOR.pdf": "C61D6644-8C65-4A00-A148-0CDE1A35891F",
    "CNS Leadership.pdf": "DC1930EB-3AE8-4FA2-95BB-8F3CD4DECE7B",
    "cns letter (2).pdf": "1E83B853-E209-4ECE-8E34-2DD6DEDD71F2",
    "CNS Letter - Alejandro Benites.pdf": "ADBC9648-B9DF-4281-894A-9D4D251AD2FB",
    "CNS Letter -Rafael Aponte.pdf": "11C18C2D-7CA1-4D7A-B21D-DA233CF3486C",
    "CNS Letter .pdf": "1731C5CF-4C04-4CAB-B218-6F8AE338DEF0",
    "CNS LETTER colour .pdf": "31078291-05F3-4E3A-BC1D-9C82AC6A86EA",
    "CNS Letter Derek O Pipolo.pdf": "81E85B8E-F8AF-4D7E-B4CF-6DD99813C78C",
    "CNS Letter of Good Standing - James Boddu.pdf": "DF377A1F-949D-45D6-A4F8-ABC72A48B70B",
    "CNS Letter of Good Standing - MeyerR.pdf": "F2B632EB-44C5-4979-A495-5E3641BA7F1E",
    "CNS Letter of Good Standing.pdf": "6BA7734F-5106-42E3-B136-4A54D033FC05",
    "CNS Letter of Recommendation.pdf": "FDC04AC2-181B-4241-9DD3-FC8B2C27F63E",
    "CNS Letter of Verification .pdf": "C5861AC3-CD88-4547-B6A3-48FB88D84AC9",
    "CNS letter Scizar Samuels.pdf": "2A49E308-AF47-477F-9C0A-34258EFB39CF",
    "CNS letter signed.pdf": "9202FA91-4787-49FB-BD67-CC9E215C6A5E",
    "CNS Letter- Jacob Wasserman.pdf": "00AFB332-C0A7-4D68-A88A-EC6F381CF213",
    "CNS letter.pdf": "CCAF5909-6ECB-4DBA-B60C-023C12E0FF8D",
    "CNS LGS - Tessa Breeding.pdf": "A08828D8-8030-411C-A1D7-E4D2AA732AA5",
    "CNS liquid biopsy chordoma MPNST.pdf": "0A674F91-70CB-4244-83DA-F690F93BD360",
    "CNS Live In Person Events Disclaimer.pdf": "900CF282-B71B-4B62-BD88-F3D69C978559",
    "CNS LOR - GZ_CC.pdf": "A1FB08FB-58CF-4A6A-8C51-E465A78E417C",
    "CNS LOR_Sarkozy.pdf": "211FFE52-3CAA-47BB-9C94-B555086788B9",
    "CNS LUIS OCANDO.pdf": "BF26CB74-F6AB-4CE8-82B3-396C5A6D7F6D",
    "CNS Maintenance - REPORT Nov 2019.pdf": "F6FAECA8-713E-495A-A292-9B400D788E3B",
    "CNS Manuscript_Chan.pdf": "134BAB9E-DA6F-4401-AC5B-87ABEEC2A390",
    "CNS MBA Digital Poster.pdf": "DA43D14B-8A3E-4CA7-837B-B78D5F35952D",
    "CNS med stu.pdf": "3D9D5E8B-94A3-43AE-B6C5-3DFA96AA80AE",
    "CNS med student application.pdf": "BEBE3ED4-CB74-4DE2-ABF4-5832A4954F45",
    "CNS med student applicatoin verification letter- Justin K Scheer.pdf": "23F3995D-1BC8-4E22-97B4-5E75EBA4F569",
    "CNS med students.pdf": "9AEDD21E-798E-4CEF-9633-5D000D346C56",
    "CNS Medical Student Membership for Douglas Chung 8.4.2020.pdf": "F826FE30-2BA6-4CCB-B9A4-19CEFB53134F",
    "CNS Medical Student Research.pptx.pdf": "BAF51423-9B89-4C61-81FB-ABC81D1D9DE0",
    "CNS Meeting 2023 Artist Submission_ Jonathan Anderson.pdf": "B106BD1A-B78C-4D2E-983D-7273FE061831",
    "CNS Meeting Services Proposal_2016-01-27.pdf": "2BBF8C8E-5143-423F-81E2-D1F642EA664B",
    "CNS meeting.pdf": "6DE910F2-2876-4EFC-864E-5ABCAE75DFD5",
    "CNS member application_complete.pdf": "6AB8F14E-BBB5-4096-B7B8-BC0B26260AB4",
    "CNS Member Database.pdf": "03A48182-9984-4173-B4A1-9EE7C3ED4ED9",
    "CNS Membership - Javier Figueroa.pdf": "199AB6D6-D2D3-4154-9544-F26B6EF51467",
    "CNS membership - Residency Verification - Ket Verma.pdf": "C425341E-FF2C-4614-AC52-9A2BB9805282",
    "CNS membership application_Bulsara.pdf": "A0ACF5C2-20AA-4ED0-A289-46A65B277198",
    "CNS membership Naomi Ochieng.pdf": "572161EA-BFFE-4203-AC24-05E6EBE8D13A",
    "CNS membership- letter Seretis.pdf": "5609B3AB-C7A4-451E-9EAB-041AE7E69044",
    "CNS membership.pdf": "C8D16DA0-FAFF-45BA-9B75-545B92A897E5",
    "CNS MIPS Poster.pdf": "4F9E3BBB-3FBA-4436-8E66-7B972853D3DD",
    "CNS MIS-ICH Surgical Failure.pdf": "27695FE2-586F-4EB6-9B28-CC4C17E8879F",
    "CNS MMA Embo Mailing list request.pdf": "07DF23F5-5D4E-4AEF-97B0-C6D5D5610F78",
    "CNS MMAe Poster.pdf": "BE8CDD86-08F5-4FED-97DF-13A9EE2C09D9",
    "CNS MMC Poster.pdf": "546D913C-B9CE-49DA-BC5B-AA8932D9D302",
    "CNS MMG Poster.pdf": "585CAA2F-C16E-4947-8E41-5FACB84FBD05",
    "CNS Music Figure 1.pdf": "DD46E2B8-4067-43E2-B11E-BE4624CB852E",
    "CNS Music Table 1.pdf": "F33E8C5D-544A-4ADD-9062-5D03C5AC10FF",
    "CNS Music Table 2.pdf": "D1EE2C1D-E347-487F-A730-B9FE35B05DA9",
    "CNS MyChart Poster.pdf": "7455E7F8-9CD6-4969-9D59-43DCA98350F0",
    "CNS NCM Poster 8-21-22.pdf": "179B2967-578B-4D5B-B82D-5A18ACB14BE4",
    "CNS Neurosurgery Education Abstract.pdf": "76C6282F-B2F2-4E80-BDE7-945848FEF067",
    "CNS Neurosurgery Residency Fair 2021.pdf": "C1F33368-4B91-415A-8628-8F264909AB7A",
    "CNS Neurosurgery Resident Fellowship Recommendation.pdf": "CA6C0B59-A209-4FA1-875F-2132FBA4442E",
    "CNS Neurosurgical Emergencies Agenda.pdf": "805A4A6C-D7F3-49DD-98F4-54DCB75EA0EF",
    "CNS Neurosurgical Emergencies Agenda_2.7.pdf": "13244AF1-E06B-47EA-81B8-BB8311E05FAC",
    "CNS Neurosurgical Emergencies Agenda_4.9.pdf": "424CEAA9-228E-4AE7-9419-ABF05C79A5FC",
    "CNS Neurotrauma Clinic Abstract ejg_.pdf": "8513C93F-7000-499B-A979-7C2119B3146D",
    "CNS Nexus Article Submission - Authors.pdf": "350ABD1C-2318-4A92-8C21-0452BAB0A5DC",
    "CNS Nexus Image Access from Journal Articles (2).pdf": "D80DE57B-9491-4D4C-B85F-58277E9DB37E",
    "CNS Nomogram Poster.pdf": "8A0BD445-0681-443F-A7FA-91891C493AD4",
    "CNS NSCLC Genetic Mutations BM Poster 093022.pdf": "C64ECEA2-7A5D-4424-B779-6BF01935659A",
    "CNS OC2 poster.pdf": "758669CE-4349-4572-9DD7-BED9A912D7BF",
    "CNS OLIF Poster_AHZ080619.pdf": "717E5ABD-A684-42D0-88D6-E1F3DC34C531",
    "CNS OP Analysis Poster Nassim Stegamat AHN.pdf": "0BDA6F90-B3FD-4813-B410-9DB9B3C037C0",
    "CNS OR Table 1.pdf": "42AD96ED-B305-43F2-B2DE-E022D3DDBCEC",
    "CNS Orders.pdf": "26EF354E-F890-44CC-8DDD-8CD656F0F9B6",
    "CNS Osteoplug poster.pdf": "0FA2628F-516E-427B-996C-5E15837C729B",
    "CNS PD letter.pdf": "F8A4C5A9-A6DB-4E06-AC96-F6BA97CACF3E",
    "CNS PEDIA Poster.pdf": "AF59C1E3-06D8-40D9-84E0-FE68911E016A",
    "CNS PEDS_Tri-Fold_mechanical_online.pdf": "AFF1F799-16EE-475A-9DD8-83B6A0CC07E0",
    "CNS PEDS_Tri-Fold_mechanical_online_V2 revised 07202023.pdf": "26FD1DC7-DA14-426E-B6D5-07CEC581DA98",
    "CNS Personal statement.pdf": "AA84F648-E7A2-4B3A-9A84-1306E9F9546A",
    "CNS Pig Glioma Poster.pdf": "B6C13E4F-2E38-47BB-AAC8-249131CBE1E7",
    "CNS Pit Cyst Poster.pdf": "A0648A76-2A15-469E-90D4-4DF6A0232340",
    "CNS Poster - Abstract ID 032085.pdf": "3EB19AF5-68A6-4F68-9236-C24919B55C6C",
    "CNS Poster - Bradley Schmidt.pdf": "01827A9D-4F6E-4462-9E27-0714AE168357",
    "CNS Poster - Deep transvenous.pdf": "33D11B00-F8CF-4752-9D84-E261D74142CF",
    "CNS Poster - Disparities in Central Cord.pdf": "1366E007-C3F4-4EB4-8E5E-94D2966B92D9",
    "CNS Poster - Final.pdf": "8A4C97EB-CDFC-499A-9ED1-B1CF2B87097E",
    "CNS Poster - LCBM.pdf": "53243A14-8117-4026-B835-95A6240FCF78",
    "CNS Poster -Avery Jackson - 821.pdf": "75E70E5D-500F-41E6-B768-52FAFA284234",
    "CNS Poster .pdf": "2118E2F1-5DB7-4D5A-8E44-7365AAD396EA",
    "CNS Poster – ACDF.pdf": "52350659-9D1D-4E09-9308-DE2238107426",
    "CNS Poster 12-2022.pdf": "F9771E2F-2E62-45A5-BFC1-158247520F02",
    "CNS Poster 2019 SNH Tumor Readmission PDF 8-7-19 NHN.pdf": "02C979B1-44CA-4809-906C-026F45E48D37",
    "CNS Poster 2019.pdf": "CDFA4862-8B66-414D-A8F1-5BADDC3B94D1",
    "CNS poster 2019_SKM.pdf": "A036379F-6932-4EA3-8169-ACCF0730802D",
    "CNS Poster 2021 - Bohan.pdf": "757EF382-F18E-418D-96FC-B513537002F8",
    "CNS Poster 2021 AED Tapering.pdf": "7569C72F-7B34-4D8F-BF6A-832EF6B721F3",
    "CNS Poster 2021 SBM Sz by Histology, SRS fx, Size.pdf": "61E65B8E-99E9-4835-82D5-5961C9F2C9C4",
    "CNS poster 2021- Zimering.pdf": "033247FD-601D-4443-A07A-CA953D3991B4",
    "CNS Poster 2023 - Awake AVM.pdf": "0F290224-027E-4561-A36A-E002AF4A273D",
    "CNS Poster 2023 - Diastematomyelia.pdf": "BF16ABE7-0BCC-4046-B3B3-FBA9063998D8",
    "CNS Poster 2023 - Epilepsy Surgery.pdf": "953ED68B-1D5B-4323-9FB5-86BE9C0F7056",
    "CNS Poster 2023 Final.pdf": "55627B41-2CCB-4920-AEB0-C515093E44D6",
    "CNS poster 2023_760.pdf": "EDCFC363-2E4D-4D25-BF5A-C284BD6F7C71",
    "CNS Poster 2023_MIS vs Open_TRM.pdf": "5CFE6272-1D0F-47B3-9C53-8CF471E66A55",
    "CNS Poster AK.pdf": "97E29E7C-6EB9-457A-9114-D126A0BDF335",
    "CNS poster APO E.pdf": "401059D4-ACC2-4702-9216-834DEDCBEFEB",
    "CNS Poster Beyond Pedicle.pdf": "6E7B6CE5-4087-4364-A701-24562AC9C9F2",
    "CNS poster Core Outcomes.pdf": "251480C0-7E41-4B48-8099-57A731F53C3B",
    "CNS poster Covid spine.pdf": "08A42D32-A97E-443B-A5E1-F180D9D582F9",
    "CNS Poster cranioplasty v2.pdf": "D2BEAE3F-C80F-4F98-A0EA-61A141F8EA0F",
    "CNS poster EVT .pdf": "8EF5FA6E-9DFC-4D3B-9B95-CEAD99569ABC",
    "CNS poster final.pdf": "8E3FA6A8-854E-43B8-9C92-459FE3595ACB",
    "CNS Poster for Dual Stent .pdf": "3E1318BC-1AC6-46F3-822B-9F3F47E128CF",
    "CNS Poster Fulminant IIH_AHZ080419PDF.pdf": "3F216A6C-1E6C-46B9-B719-3FE992D67371",
    "CNS poster GSW.pdf": "AAE1B649-8342-4E6B-B9C6-78ED1378281C",
    "CNS Poster HCUP-MM BB 09.30.22.pdf": "8E3722DC-4025-40A0-9EC3-F65CD7237B4F",
    "CNS Poster Headholder.pdf": "9030D4B0-D05C-48E6-A28F-FDC9769170D2",
    "CNS poster Hydro.pdf": "C7BED412-41B9-40D8-B5C8-E1BC00DF668B",
    "CNS Poster Laser Callosotomy BB 09.30.22.pdf": "5FC5072F-36C7-4736-B8D1-FD6986F2CDB1",
    "CNS poster Lumbar Surgeries.pdf": "C54F3DF0-3BE4-44A5-9D13-C391BF6EE12C",
    "CNS poster non-contrast ultrasound perfusion dynamics spinal cord injury.pdf": "71EA6F8F-B578-4CCA-84CA-582B59B00391",
    "CNS Poster Omar Sorour COVID Encephalopathy 082021.pdf": "9DFF6031-A4E1-45EA-85A7-4F03A18A2055",
    "CNS Poster Omar Sorour Telehealth 082021.pdf": "FAC1F099-3D67-49CA-B7E3-E597B89BA882",
    "CNS Poster Outpatient 1.pdf": "7D141A7D-4CDA-416C-8490-DB362C82DADC",
    "CNS Poster PA Adjuvant RT.pptx.pdf": "74CA7608-0D5A-4AD6-B72F-42C2D93F7152",
    "CNS Poster PDF Transsphenoidal Surgery in Seniors .pdf": "78E11366-4A73-4D51-95F1-A8E3B2C18E81",
    "CNS Poster PDF v2.pdf": "D86982C2-4CC6-4C66-BC92-A169BFC8F956",
    "CNS Poster PDF.pdf": "85754241-A274-4757-B51E-566ED335400D",
    "CNS Poster POD1 Posterior Fossa Craniotomy.pdf": "59455FAC-5711-4ECA-958E-7FAB75399225",
    "CNS Poster Presentation.pdf": "A49E9872-CA73-4F75-9256-D8C1F93C07A9",
    "CNS Poster Presentation_Gliadel.pdf": "DE5A414F-D525-4609-AD61-56161D35F918",
    "CNS Poster Presentation_SE.pdf": "EDB437DD-7247-4052-AC28-CBF5CD2CEF10",
    "CNS poster template - JOO SP.pdf": "219D7251-D863-4133-BBC8-FED45767FAC9",
    "CNS Poster Template 48x36.pdf": "0F0FADBC-72AC-4A6B-87C4-7894CE9EEBAE",
    "CNS Poster Template 48x36_NPedits.pdf": "DE87BA9C-D855-43FD-98AC-0A1008E8AF9D",
    "CNS Poster Template.pdf": "38CED607-CC60-4C44-9824-0042829A0615",
    "CNS Poster Wk Pseudotumor.pdf": "442E9E1C-4BF6-482D-9EFA-61ECEC3A3A03",
    "CNS poster- Amirreza Alijanpourotaghsara.pdf": "99196AB5-C496-4695-8D86-CB6B913FE2FE",
    "CNS Poster- APP.pdf": "CBE7C0D0-4D7B-4D53-8C6C-25B434676D63",
    "CNS poster- Dayyani.pdf": "BB51107B-D593-4898-B1BE-00A9F433BB22",
    "CNS Poster-032898_Mekonnen.pdf": "1810D936-01FA-4763-9545-6B012EBDE3E6",
    "CNS poster-9.16.21-JRB.pdf": "52B50F1D-93DA-46F6-A146-C450BF040AFC",
    "CNS Poster-Final 08.07.19.pdf": "637B22FF-09B0-4661-80B7-4260C74BD8D0",
    "CNS poster-ML to predict bleeds.pdf": "F91BFD7B-80A0-4435-A9CB-9C1F67624746",
    "CNS poster-sETV-2.pdf": "216896BD-2016-48BD-BB60-5FA58AF82823",
    "CNS Poster.pdf": "A02A77B5-3B69-49A5-9084-07941CDC34CB",
    "CNS poster.pptx.pdf": "F071DBD8-8C83-4ED1-ABE3-9046274D83CD",
    "CNS Poster_ CMBRFA and opioid use_2021 0823.pdf": "1B950555-9405-47DC-9CE7-0BB3AB80D862",
    "CNS Poster_07-20-2023.pdf": "A4F42C56-8F4E-4C75-BEE4-6F317169BD24",
    "CNS Poster_20Jul2023.pdf": "437FCA6A-5003-4DD6-9662-9B803BCCD710",
    "CNS Poster_Chandrabhatla_Final.pdf": "C1B1E3BF-F733-4133-9437-4094F22B76AF",
    "CNS Poster_Establishing Criteria.pdf": "4ADC19EC-BE71-476F-B330-65468DFC3CA3",
    "CNS Poster_Ghodrati.pdf": "DE83570C-CDA7-45D7-B5E9-34BE93360877",
    "CNS Poster_Grace_Futch.pdf": "AD3C53D7-9747-4546-B868-34917B4D963C",
    "CNS POSTER_HAMILTON_1.pdf": "3BC1F8F6-B492-497A-9738-325898998C98",
    "CNS Poster_Machine Learning.pdf": "F958CA81-1BB1-4828-A133-378B387B2B12",
    "CNS Poster_MTR.pdf": "E7171D05-67DD-48C4-86A3-1F74F942BFCD",
    "CNS Poster_NIH Toolbox.pdf": "B3A83FAE-ECD8-4586-A9BC-A8681FBBD013",
    "CNS Poster_RyanGlynn.pdf": "8FDB607F-CE23-4833-8D69-8D98B5CD7B19",
    "CNS poster_submission (002).pdf": "BC5FF44A-599B-4F20-B553-0DA7639CA928",
    "CNS Poster_V2 - Abstract ID 032085.pdf": "6970C4C5-203F-4790-B4FF-7EA4FDB7FB30",
    "CNS Posters - MP 1.01.pdf": "EC2CFE25-7F35-4306-9A6D-F70362A0DD3D",
    "CNS Posters MRI and CT Fusion.pdf": "F69C5E82-802A-4A20-9A4C-2A97CB4CCE48",
    "CNS Presentation.pdf": "ABD8BEA8-F580-4A65-AA7D-51A23423CC5A",
    "CNS Proposal.pdf": "10149E5F-9BFC-4338-A9E2-C86CA7D4F09A",
    "CNS prospective awake poster.pdf": "820D71B9-431E-4AB1-9BA9-9DCCD7464A5E",
    "CNS Publications Fellow Recommendation Letter.pdf": "E8A1BAA5-6FD5-4061-B9CA-A41CE02A1273",
    "CNS Publications Fellowship Personal Statement.pdf": "20013B1D-194A-45CA-AF0C-16D1AF0C2FFF",
    "CNS publications resident application 2022.pdf": "47258843-3408-4C42-A3F5-F2B0BAA28A2A",
    "CNS Pupils - Single surgeon study.pptx (3).pdf": "9E1A118E-8C1D-45D3-B053-060970EF2573",
    "CNS Questions Suggestions.pdf": "F46E6515-606A-45BB-BCCD-3D3B52673DF4",
    "CNS Racial Disparities Poster.pdf": "A729390D-78A1-44AB-AD4C-41FA5DEA6952",
    "CNS recommandation letter 1.pdf": "CEDDD05C-50E1-4F12-AA17-5F075D4BD7E6",
    "CNS recommendation.pdf": "1E33BFC6-B7F0-420F-97AF-C8FACB4A4724",
    "CNS References.pdf": "1B94C8E9-27FE-43F7-8CC7-31304FFF1AFE",
    "CNS Registration Letter.pdf": "A40C1A4E-246A-4E93-A13A-776A7A669EDE",
    "CNS Registry Poster 2019.pdf": "B42DD390-F2B1-4CD9-95CF-B8BCFFB40B1B",
    "CNS Residency Program Info 1.pdf": "2CA18D1A-0E07-4AA3-8321-FE83C97DE93D",
    "CNS Residency Program Info.pdf": "E67A54A6-4E19-4290-A3F2-8DAB8E6244A4",
    "CNS Resident Award Final_ CALNAN.pdf": "C7F42FC8-87DC-4C5C-A604-A19939DEA3D6",
    "CNS Resident Commitee LOS Nabiha Quadri.pdf": "B4492CB9-2018-4201-AE77-DF8D3AF7A996",
    "CNS Resident Committee LOR.pdf": "4D305BAA-18E5-430A-A651-010B1EB4AE93",
    "CNS resident committee-Maher-9-2020.pdf": "7B87D756-B517-44FF-8775-0B5725BAAAA4",
    "CNS Resident Fellowship Form Dr. Haglund.pdf": "2DD01489-EE08-446B-BDCB-AD2359CD566C",
    "CNS Restated Bylaws CNS - updated 9-2023.pdf": "C14934CA-0DA0-40DF-B4BE-DA8FE98BA586",
    "CNS RICM Table 1.pdf": "C42933D1-F4BF-4B73-AF5F-123C19CB908C",
    "CNS RICM Table 2.pdf": "10BB2DD7-C74F-4C8D-8E19-B4115F5177AE",
    "CNS roster 22-23.pdf": "D297A578-0497-4CC2-8493-98369F0EF1A9",
    "CNS SANS question order.pdf": "09BF01AE-2948-4E44-9593-A4409183CCC6",
    "CNS Scepter Poster.pdf": "54F65C9E-251E-452D-A91B-752D24E01EBE",
    "CNS Scholarship Data Science-Maher.pdf": "5310D82B-DD80-42F0-BEE3-70E8EAA88B13",
    "CNS SDOH poster -SLedit.pdf": "4676D02E-3CC6-4CAD-8DB5-6B5BC3E6EEA5",
    "CNS Sepsis Poster Final.pdf": "C0BA0F68-B921-4F18-9334-3E8C28FD8B04",
    "CNS SOYNEL MARCIANY.pdf": "008E5B2A-ABC8-4FEC-BE59-20FD4A703B5A",
    "CNS Spina Bifida.pdf": "EE4E7C46-6810-4B2F-875A-753BCC0E314A",
    "CNS SSI e-Poster 2020-21.pdf": "E7BE3D60-8BEE-4D7C-92E5-BC536815ED9B",
    "CNS SSI Poster 2020-21.pdf": "E0C67980-2E0E-47F2-8BE2-F023ACDDBD17",
    "CNS student application.pdf": "4C0546C2-8BA1-48AE-AF79-32016E93170A",
    "CNS Student Confirmation Letter.pdf": "6513075E-AA15-41CC-9772-881B4039B1AB",
    "CNS Student Letter.pdf": "CDB81261-14BE-4E7A-ACC1-DADE7BCD3982",
    "CNS Submission Figure 1.pdf": "3CB04480-213A-4202-9A3E-887D496E7286",
    "CNS Submission Figure 2.pdf": "12A80EA1-642A-4C42-8B95-CCFB42504063",
    "CNS Submission Figure 3.pdf": "765BA4FA-4617-4F24-BE1D-B4B673DC0EC5",
    "CNS Submission Figure 5.pdf": "2BCB354F-4835-419B-95C6-B37E2A221851",
    "CNS Submission Tables.pdf": "2383CCA4-B2FC-4EA8-B3A3-068282C4D3A7",
    "CNS Summer Research Fellowship Detailed Budget.pdf": "F10D53CC-E806-4889-96A1-E19F72C6026D",
    "CNS support letter RXE.pdf": "AAD9553A-F2EC-4D01-9136-EB96D2338E1F",
    "CNS Support letter.pdf": "2753D075-E0A8-41B2-BDA5-AACF557FA9B7",
    "CNS table 1 Mittal 6.13.21.pdf": "D65FCA8B-3C55-4423-9DE9-B5E19ACA5C48",
    "CNS table 1.pdf": "599AE598-AB7C-4A77-A4C2-1107FA8BAD39",
    "CNS table. 1.pdf": "679431A5-AD65-48FA-B32B-F111E6EFC099",
    "CNS table. 2.pdf": "F3BB1FB7-EAF3-40AA-A79B-401F4C2AEA9E",
    "CNS table. 3.pdf": "5D41E610-47B0-4644-8A63-C8F3459134B2",
    "CNS table. 4.pdf": "380110E1-DC8C-4EC7-AFFC-155FECC91466",
    "cns table.pdf": "7122FF21-CB65-4FE2-88F6-B1114911DECF",
    "CNS tables.pdf": "CA94B59D-955A-4084-BD5F-3720E4CB25B9",
    "CNS Tandem Stenosis.pdf": "09D471D1-EA89-4933-B1DC-6F4D6FAD8D93",
    "CNS Tandem Stenosis_ED.pdf": "68DA5731-E211-4681-B040-4BC334CD54F1",
    "CNS TBI Draft 2.pdf": "5B71BBD6-24CD-470F-B587-0B9D376E77C4",
    "CNS TBI KR 2023.pdf": "446B2D4C-141F-4CC0-9729-A61E3D35C25B",
    "CNS TLIF Poster- KH.pdf": "DA33475A-C335-4591-B0E7-DF74CF8082C5",
    "CNS TPNI 2022.pdf": "E90BF8A3-F00D-4882-9088-0EFDB9B52DF5",
    "CNS Transcript.pdf": "8A9F26C5-C690-40D8-B2D9-B31C80C49390",
    "CNS Tumor 2023 MAITC Poster Hannah Wilding.pdf": "80B98C11-652B-499D-934F-5C89602FFE13",
    "CNS Tumor Section 2023 pocket pal onsite guide_14-web.pdf": "D61CDDEC-3A07-49B4-AD49-E05571C50637",
    "CNS Udawatta Letter of Verification.pdf": "83123240-B8E1-423C-9526-C90C569D9F08",
    "CNS Ulnar Nerve Poster.pdf": "5443EAB3-FF05-4BD2-BE0C-CF40EA93B49B",
    "CNS Vanguard recommendation.pdf": "3E6AA613-D074-447B-9C0D-7B385D99DFF4",
    "CNS Vasospasm.pdf": "73574B7C-0B77-4258-A86B-9EF4A8E63797",
    "CNS Vasospasm_ED.pdf": "448351D7-DD15-4593-87BF-67D8CE5F0013",
    "CNS VATER.pdf": "04C189C6-50B4-45F9-B361-60AD30C6685F",
    "CNS VER.pdf": "145C36B4-7B45-467C-A4BA-193157339B23",
    "CNS Verification Letter for COrillac.PDF": "F0001304-4695-425E-8C50-41653C8B1092",
    "CNS Verification Letter.pdf": "B5ADE2FD-4418-4E2B-A25A-106D88905362",
    "CNS Verification Letter001.pdf": "D1A581DC-D72A-45E1-A26F-271974E33735",
    "CNS Verification.pdf": "965CEA27-F799-48D3-B99A-3362EB700992",
    "CNS VGAM poster.pdf": "9B8499A0-FCB0-41D5-BDE4-E05C5E48D61E",
    "CNS Vista.pdf": "84BA80CF-97CE-4948-AB04-8EF912EB6B6A",
    "CNS VS.pdf": "53D77A55-808C-41A3-8E88-8475B220DEE4",
    "CNS Wilms Poster .pdf": "BB156D58-B831-40E5-9355-DFA3CEA356EA",
    "CNS- ATV TBI Helmet - final.pdf": "F7F22E16-4B38-410B-A0B2-E52DCE4A26DE",
    "CNS- Education Curriculum.pdf": "3341134E-18A5-4239-88B2-A70BB3520831",
    "CNS-1051_Texakalidis.pdf": "C3B950E9-6ED1-4E31-8469-D158EA7BBF53",
    "CNS-1085_Texakalidis.pdf": "C3A4AE17-6AFE-46C4-BD3D-DCE0A6EF5967",
    "CNS-167_Texakalidis.pdf": "B6BF4965-F1BD-40A1-A5B9-8FFBEACA8C5E",
    "cns-2016-preliminary-program-for-web-compressed.pdf": "76D6CD13-9C94-44DF-A08B-71D368824314",
    "CNS-2020_tumor_month.pdf": "C040DE2F-67F8-40E1-A260-09155B704EF0",
    "CNS-2021_SAH.HA.pdf": "8248096F-953B-4B71-81BF-C0E224D6801E",
    "CNS-2023-Poster.pdf": "14F13F3B-DE98-4454-9797-08BC2642FD84",
    "cns-aans-opioid_task_force_naloxone_one-pager.pdf": "D1913907-AAFA-47AB-B65B-5C4DE161F420",
    "CNS-Abstract-Figure1.pdf": "AFAF856E-518C-42D5-90A0-28DC36775F0D",
    "CNS-Abstract-Figure2.pdf": "E6BD1869-CF01-496A-A575-734C89F99B39",
    "CNS-app-Letterof Standing.pdf": "3BEAD092-E6B2-407A-96C0-F514AB2F71CE",
    "CNS-Bestätigung001.pdf": "CB58C43F-A966-48DD-BB9E-2B8AE722BE4C",
    "CNS-CDAFEM-2021.pdf": "D09DBAA8-B2D3-4577-84A2-F2824CB15D10",
    "CNS-Digital Poster-#2533.pdf": "67856B11-E308-4AA8-8013-6944B5A7B397",
    "CNS-Dural injury treatment with a full-endoscopic approach.pdf": "30B41893-CE07-4FA5-B6A5-DA09E348F75E",
    "CNS-ePoster-SDG NeuroOne final.pdf": "9575F652-C02B-4D6C-8792-11399B32203A",
    "CNS-Extraforaminal extra-articular full-endoscopic access with foraminoplasty for the treatment of lateral compressive diseases of the lumbar spine .pdf": "EE52DDF2-23E0-4EAE-81DC-62E8CF7FDBD2",
    "CNS-Gliofibroma.pdf": "EDBA7263-7F33-4FF8-AB90-6052BCCA9A83",
    "CNS-LOR-Maher.pdf": "8C256D1D-FA24-4677-BC37-DC31111B2DA7",
    "CNS-LOR-Szerlip.pdf": "671405CC-6002-48E6-B9A4-7B567E70868A",
    "cns-medical-student-application.pdf": "B3E11980-5847-4D63-B701-10B2054D0F9B",
    "CNS-Meningioma.pdf": "1597DDEA-6B1A-4F91-9361-5011BD474CB5",
    "CNS-MobiC-Poster-1-2level.pdf": "D9ED796D-7C44-453D-B9A3-780A08F1760E",
    "CNS-MobiC-Poster-Hybrid.pdf": "D39D42F9-EF17-416B-BA42-D17616B0AEB2",
    "CNS-MobiC-Poster-Overstuffing.pdf": "DD865632-2461-4F23-9FAB-AE3D56BCF489",
    "cns-oral-boards-review-postcard_Feb2029.pdf": "86BAA67B-9D3B-4342-A35E-1797B437976D",
    "CNS-Ped-2022 Models Poster V3 FINAL VERSION.pdf": "B01D9959-5F05-4F9E-9C3C-B49CA836566D",
    "CNS-Poster - Cavernous Sinus Hemangioma.pdf": "517EDD31-3495-4829-AD11-72B52B455076",
    "CNS-Poster 1308-2019M.pdf": "311C9C08-EB33-487C-BAF4-D765A97A94BE",
    "CNS-Poster aSAH Senegal DALY.pdf": "E5C96E7E-63A0-415B-8E52-F5D50C998D0C",
    "CNS-Poster- Acromegaly.pdf": "81D99011-0609-432C-9A6A-89260C521C6A",
    "CNS-Poster- ADAPT.pdf": "04536B3A-0D92-4D21-A23C-6ED012A16069",
    "CNS-Poster- Aneurysm.pdf": "2D008CF0-10A5-4348-9685-C70C57506F61",
    "CNS-Poster- Dandy.pdf": "22462241-5E45-4252-8E45-57DAEA7F6367",
    "CNS-Poster- Rabbit Glioma.pdf": "871EF7B3-E17F-4735-AEC5-28D0DBFBCF13",
    "CNS-Poster-# 2646.pdf": "1CA1D57E-FFC3-4B1C-B017-2C25F87A24F1",
    "CNS-Poster-# 2663.pdf": "BE30B250-9051-401D-AD12-61BD59E58AAC",
    "CNS-Poster-#2422.pdf": "95941414-3813-4C18-8ACC-1840FD47F962",
    "CNS-Poster-_SN.pdf": "79BE3325-45B1-4F5E-A739-22B9EE9AD8D6",
    "CNS-Poster-033487.pdf": "3DD748B6-E547-47B2-B936-8F8588414041",
    "CNS-Poster-1301.pdf": "5C881AAD-64F5-49E2-8B69-5844FB171D36",
    "CNS-Poster-1304.pdf": "9B03229B-1880-4FEF-A7F7-3903277403FC",
    "CNS-Poster-1307.pdf": "27F1CC59-6F47-4448-BC0D-29C6C79FEA04",
    "CNS-Poster-2019 FINAL.pdf": "E94ACB9F-EED7-4A94-B817-8693D3B5D6E3",
    "CNS-Poster-2019 v2.pdf": "23C4B40E-8750-4864-B6E8-8A0DC93C9E12",
    "CNS-Poster-2019 Wen 2 .pdf": "58C67061-2B8D-4C68-8BF2-6D430C75FAA8",
    "CNS-Poster-2019 Wen.pdf": "4F945C5B-E1D4-4C31-89A6-1581FA96A9C5",
    "CNS-Poster-2019M.pdf": "B7FCD72B-6142-4AF3-AC33-234555A21798",
    "CNS-Poster-2021- 0830.pdf": "3360117D-98D3-4603-9EBC-9B5FA845A964",
    "CNS-Poster-2021.pdf": "49B96DEC-1DEC-454C-A307-4F6FA4A6002C",
    "CNS-Poster-2115.pdf": "D4CBF4BB-9752-445D-9DA8-4DC0D0803F8B",
    "CNS-Poster-48x36_Final.pdf": "80DED9E1-9463-41EB-A6EC-0CF064E5913C",
    "CNS-Poster-A novel surface-based registration system for neurosurgical robotics.pdf": "CD2C8045-C234-4794-AE58-1F6D4868F286",
    "CNS-Poster-Abstract 1036 Final. pptx.pdf": "1A74F2F0-83A8-4949-A37C-9C166EFE2272",
    "CNS-Poster-All pupils large study.pptx (2).pdf": "A54BE05C-5719-4655-B4AB-4FE54F3D35CF",
    "CNS-Poster-CortexV2.pdf": "B70A363A-209A-43C8-B0FC-8964715AB156",
    "CNS-Poster-Crabb.pdf": "BACEC42E-B44E-480B-B851-DC8C87CDA5B7",
    "CNS-Poster-ECSMapping.pdf": "6903D52E-DABA-42E0-8CA6-B1230FB15340",
    "CNS-Poster-Ehlers 2021.pdf": "CC5096C4-3D19-4A2E-9F9F-B6BE1D9174F8",
    "CNS-poster-FAS-PDF.pdf": "7AD84E7A-F562-4387-AC15-90B64483A96A",
    "CNS-Poster-Fistula.pdf": "868FC167-76C9-4588-AC35-30EDB390457C",
    "CNS-Poster-FumioYAMAGUCHI_Final.pdf": "4B650313-7FB3-4958-82D8-EED515CDB204",
    "CNS-Poster-imri 48x36 (2).pdf": "07110743-62C6-4799-9AD1-39E99A6A6B71",
    "CNS-Poster-In the Eyes of Herophilus.pdf": "A6FEEE28-6DAC-4480-BD84-52FE83FA3AF7",
    "CNS-Poster-Kashanian.pdf": "859D511D-5F14-41D4-8570-18168331D78A",
    "CNS-Poster-KIM IS.pdf": "B8D69E2D-72E4-468A-AC0B-E5E856D2DDA4",
    "CNS-Poster-Limoges-2019.pdf": "ECAACAE3-10CF-44A6-A8DE-BC86194E6B5E",
    "CNS-Poster-MA-3.pdf": "CB368D5D-A464-4B3A-803B-6AA70B79F768",
    "CNS-Poster-McCormack.pdf": "C84BDD16-80FA-43D0-A818-377262657BD8",
    "CNS-Poster-Mechanical Thrombectomy.pdf": "4996C38C-0393-4B85-8E60-56228B8633FC",
    "CNS-Poster-Mingze Wang.pdf": "24CAE975-4595-4024-9885-BA14DA3537EC",
    "CNS-Poster-Mueller_SSI Risk Score modelFINAL.pdf": "40FDB1AA-DF7A-4FDE-9ECD-4126A75C440D",
    "CNS-Poster-NSCLCBM - Armocida.pdf": "AAED97FD-4D63-48EB-8FB4-5923DA002F5D",
    "CNS-Poster-Precaruncular Transorbital-Transellar Approach to the contralateral parasellar region .pdf": "A48A7067-54AC-43C3-AE13-CC06DB2CB84B",
    "CNS-Poster-rise1yr.pdf": "207B7F6C-EB4F-40BB-ACD3-30BF8B8243B8",
    "CNS-Poster-riseL2year.pdf": "80B0CAA4-4D1C-4B60-ACEA-D5D3582690A1",
    "CNS-Poster-Rynda A. Immunotherapy with autologous dendritic cells as part of the complex treatment of patients with HGG..pdf": "C6A03163-3F62-45BC-A59F-CE446402BD8E",
    "CNS-Poster-Rynda A. Results of PDT use in patients with malignant gliomas depending on MGMT status and IDH mutation.pdf": "7DC892E5-A51E-4A2E-83E6-4CA36B357116",
    "CNS-Poster-Shapiro,Stephen.pdf": "75C89829-BE8A-4CF9-9D41-0B6F69B8A0F8",
    "CNS-Poster-SLee.pdf": "0012D294-DDC9-468E-9CF1-4BCCA0A9457E",
    "CNS-Poster-STN-VIM-DBS-Fayed.pdf": "80BAFB6D-B673-470F-9A76-A9F17158210E",
    "CNS-Poster-TBI.pdf": "F1D18C31-58ED-47C6-B418-F5C5389DC1BA",
    "CNS-Poster-Template for PEEK talk2019.pdf": "550263C7-FDEA-4775-A7E5-ED554748B472",
    "CNS-Poster-Template-48x36 (2).pdf": "B0240AD9-0F70-4AAB-9EB7-5F1881544CB4",
    "CNS-Poster-Template-48x36 (ML Testing 5).pdf": "7D9151A5-556D-41D2-AA41-3D1DD94B5BA5",
    "CNS-Poster-Template-48x36-chordotomy.pdf": "C4B03EB7-B434-47FE-8216-157FC5B662C6",
    "CNS-Poster-Template-48x36.pdf": "6A45EE0D-D880-40F7-9676-756A2DE3E09A",
    "CNS-Poster-Template-48x36_CAS NeuroOphtho.pdf": "72101BB4-5E76-41D6-BAC1-B7890B7066BA",
    "CNS-Poster-Template-48x36_Neuroinflammatory.pdf": "0443D1D2-FB90-48ED-AA1C-2801908A9543",
    "CNS-Poster-Template-48x36_PCS_FINAL_2021.pdf": "DF11783A-F1EA-4A14-ADA8-638F05F6F2E8",
    "CNS-Poster-Template-Trauma AKI.pdf": "3ADAB7B7-238E-487D-A949-D5227C266729",
    "CNS-Poster-Template_Abstract2519_final.pdf": "6A196F44-6373-43C9-92A8-5C8267ED81A7",
    "CNS-Poster-Toshiki Endo.pdf": "82B17DD1-1286-41DC-8CDF-67B9DF519E19",
    "CNS-Poster-TRIUMPH 6m_Final.pdf": "791C263B-6E11-4731-A059-568B1FE1E4DA",
    "CNS-Poster-TSC-sEEG-48x36.pdf": "849D4654-3C93-48CC-B44F-87D8573F3461",
    "CNS-Poster-TXA.pdf": "46532185-5A02-42BE-94E5-8C6587B5A64E",
    "CNS-Poster-Umehara.pdf": "AFC29B6A-DBB7-4EE2-A439-8D0A4F3383F0",
    "CNS-Poster-Wei Ni 1.pdf": "4850A581-465F-413D-9780-76F29D6760E5",
    "CNS-Poster.pdf": "3D5BC356-4CD1-4C19-90B3-0471FDF01C62",
    "CNS-Poster_032179.pdf": "EA9C8861-6553-4995-BDF4-523A9EA865E3",
    "CNS-Poster_032644.pdf": "3D2A0917-7CCA-4043-93A2-C63202082B02",
    "CNS-Poster_Abstract 032946.pdf": "BE0F255A-6F01-48DF-B872-B216903A3AF5",
    "CNS-Poster_AF.pdf": "3EC8A354-A284-49EE-A384-3BCC57ACB8A1",
    "CNS-Poster_Deng.pdf": "35A3BBAD-0466-4146-8ABE-81D6C0C5DCD1",
    "CNS-Poster_GSW_LilyKim.pdf": "1AC5607D-5E2B-49F0-A989-FE6BF8E293BB",
    "CNS-PosterriseLvsTranC1yr_CNS.pdf": "B686952F-FA12-447D-8366-E67DD2348466",
    "CNS-Rindler-Poster (2).pdf": "49230836-301E-4981-9489-46F85EBCFF31",
    "CNS-SBC_BCBS_G533HSA 01-01-2024-Gold.pdf": "E63A156C-F90F-4C5F-A5F2-158A1FBC3381",
    "CNS-SBC_BCBS_P503PPO 01-01-2024-Platinum.pdf": "3059320C-DC8F-48BA-AE6F-862A32152C4A",
    "CNS-Sentiment Analysis.pdf": "5FD3227A-3759-43B3-A851-9E6AB4A12E35",
    "CNS-sibelh.pdf": "83572478-8D2F-4B07-B6D2-B1A3BBA1681A",
    "CNS-transorbital pericranial flap.pdf": "2D55B6A9-2A7A-4D6C-B206-D135E4BBC1D5",
    "CNS-Wonhyoung park.pdf": "73A959BA-7FD7-4FC4-B538-4841102B0BCA",
    "cns-written-boards-review-postcard-2020-12-6-19-v2.pdf": "78A33D4E-0E3A-4AAA-81CC-0095ADFE6A5D",
    "CNS.AI.Cranial.pdf": "F31138C2-EE99-4FC3-B2EB-9AAD0E439C9F",
    "CNS.AI.Spine.pdf": "B4C5EF92-782A-426D-8EE3-F6F0E6840804",
    "CNS.enroll.pdf": "7B8305EA-173B-4365-9F6A-FAC4E76F553D",
    "CNS.MRgFUS.Poster.NK.Final.pdf": "C6EEAD6C-DB32-4BC0-A728-8E77891D7D69",
    "CNS.pdf": "F65287EB-94C1-4D67-B7EF-1ABCE5896F52",
    "CNS.pdfjoiner.pdf": "EA2A1E41-57F9-488B-9ED2-96258E49B1A6",
    "CNS_031825_DL.pdf": "AF6D0738-CD46-415F-B0D5-8F54595BF430",
    "CNS_031827.pdf": "D5F33777-79EF-4620-B081-A3D4A44BCB8F",
    "CNS_031830_DL.pdf": "7CCF0729-1FAC-43DA-85A9-AC0077C4E639",
    "CNS_031836_DL.pdf": "BBB1B4B9-B7A5-4663-8040-EB0E78A93DC6",
    "CNS_2019_UGDH.pdf": "5AF9CF17-5839-4982-9BB7-7A01E4A60FA4",
    "CNS_2021_Butterfield.pdf": "EEE9EBE0-A01D-4C2E-8D69-5868D71ABB36",
    "CNS_2021_HEP_Seymour.pdf": "7858BD79-1A3B-45C8-8E37-5DD732D2B40F",
    "CNS_2021_LGG_poster (6).pdf": "4DBAD89E-AC66-4EF8-A686-0342B1737CF3",
    "CNS_2021TumorSymp_PrelimProgram_mech_Online2.pdf": "BAF4C225-5D94-43C7-A1A4-A21EEEEBC266",
    "CNS_2022_Central_Lobe_Epilepsy.pdf": "BC35C482-7B92-4C43-9AC5-A4E6CFD3EF65",
    "CNS_2022_Cingulate_Epilepsy.pdf": "EE6AC1D8-0CF9-4D0C-8DAE-FE2C35CB87E2",
    "CNS_2022_Epilepsy_Elderly.pdf": "CB2F5139-EAD4-4EDB-B06F-645EB7C34F02",
    "CNS_2022_Insular_Epilepsy.pdf": "8046F6B1-DF03-4CBA-A0F6-F5DE5E0158D1",
    "CNS_2022_LITT.pdf": "96EE208B-0151-4461-9239-2FF622AC8DF3",
    "CNS_2022_Mekonnen.pdf": "64073B60-24A2-4092-9DCE-AF5A6491F544",
    "CNS_2022_MI.pdf": "F7887FE8-4249-405C-8FFE-1DB852C4FA59",
    "CNS_2022_Poster.pdf": "F7CE9264-FD99-411A-AAD6-1F89C24F31E7",
    "CNS_2022_Poster1.pdf": "53F89B2A-888B-451E-857D-0D75C878C362",
    "CNS_2022_RNS_Peds.pdf": "36F77184-C399-45A7-A68B-656F9CACD28C",
    "CNS_2022_Rush_Template.pdf": "2C9DACC8-6B09-4B6A-9BBF-2FA8D152D1EE",
    "CNS_2022_SDH.pdf": "F4D730E3-32A8-4895-B951-2D13C2381CED",
    "CNS_2022_Zhang.pdf": "980BCAFD-1773-4DE1-80AD-9D7B78E20F43",
    "CNS_2023_Digital_Poster.pdf": "BE19709F-D40D-480B-8D0C-9B0E6133A38F",
    "CNS_2023_PDNeuronalFiring_5.pdf": "0AECEC7B-03F4-454C-AFEC-B671330E7D6C",
    "CNS_2023_Poster.pdf": "58AA2B81-1ECD-4DF0-8570-C3AE00DC41EB",
    "cns_aans_2016_opps_asc_comments_payment_083115.pdf": "F13921AC-1813-4D68-95C5-A7CB9ECDAB43",
    "CNS_Abstract ID 034810_JFTW_2022.pdf": "31D20EA4-9ECD-443B-986F-4475AC198288",
    "CNS_abstract_2021_NEJM_model_graph.pdf": "56BB264A-3A95-4880-9E3C-42D51ABF88E0",
    "CNS_Abstract_2021_our_model_coxph.pdf": "463515BB-A4A1-4B03-B4AA-F99039E94797",
    "CNS_Academic_Letter_Karolina_Kata.pdf": "85314140-2DAF-4CDE-9C97-8AF1CBC72271",
    "CNS_ACDA2023_FINAL_PDF.pdf": "04A0C016-D35E-4C4E-96C3-3425030EB113",
    "CNS_ACDF.pdf": "772C0FF6-2EC5-4ECB-A5E8-04C3ECE53C8B",
    "CNS_ADC_Poster.pdf": "C9AF12F7-9E12-4466-B730-85AC01DE605A",
    "CNS_Application_letter.pdf": "1B993657-BE29-43A3-9C62-5A8EB6ABFFEF",
    "CNS_application_recommandation_Wen(CNS??????).pdf": "030B5C73-F4B0-4534-B7A2-35B42E639B1A",
    "CNS_AR 18_mech.pdf": "486D407E-C777-4604-B80A-65EA1882B46D",
    "CNS_AR 21_Final.pdf": "5F23138F-8E70-487F-99DC-865C29BB526D",
    "CNS_AR 21_mech.pdf": "1B5B8585-ADE0-4622-94A2-5BFB6964E58F",
    "CNS_AR 23_Final.pdf": "1AAD6C6C-E70E-49A8-8DDC-709FB2FF7099",
    "cns_ar_19_mech_3.pdf": "71A93BED-164A-4355-A35E-365A12D14DDF",
    "CNS_AVM_POSTER.pdf": "E07AB847-542A-4CD3-AF00-9977AAEF308F",
    "CNS_Awake_Poster.pdf": "C5F2F5BB-3B09-4E16-B17C-3B12EA57C188",
    "CNS_CES_2023_Poster.pdf": "F237795F-51D1-4DDB-A108-A9B5C4FF99F2",
    "CNS_Chungeun Lee Letter of Good Standing.pdf": "9065F98C-C181-436A-82F4-658339EC9D05",
    "cns_cnsq_fall_19_mech_web.pdf": "D21278F6-08C4-4469-90D5-1D3BE3159BBB",
    "CNS_CNSQ_fall20_mech2_web.pdf": "3951713D-D2D2-44BF-B78A-9B0CE85B65AA",
    "CNS_CNSQ_Fall21_mech_online.pdf": "0E98FB5A-1A21-446E-B052-71558BBB4827",
    "CNS_CNSQ_Fall22_mech_onlineFINAL.pdf": "EFDF032E-7356-40A4-9A6C-905CDEF05597",
    "CNS_CNSQ_FALL23_mech Online.pdf": "11A6E910-C91E-4990-804D-38E1E1287364",
    "CNS_CNSQ_Spring2021_CNSFPgs.pdf": "C760EED9-E62F-481A-BC8F-88C6B856F3AC",
    "CNS_CNSQ_Spring2021_Final Web.pdf": "F152823F-B200-4D63-B7AC-FEE6A1F9DF3C",
    "CNS_CNSQ_Spring2021_Finalonline_low.pdf": "9255F714-4134-4CC5-AA4B-56BFAA96E7BA",
    "CNS_CNSQ_Spring22_Final for web.pdf": "A3FFB6EA-33E8-4EE4-940B-7CAC6581C8D9",
    "CNS_CNSQ_Spring24_mech Online.pdf": "1AD84611-556A-42D3-B4CC-7EF9CB65304B",
    "CNS_CNSQ_Summer21_mech_online.pdf": "E5412BA8-8D5F-4890-9DE3-4A2653269F2F",
    "CNS_CNSQ_Summer22_Final mech for web.pdf": "D0ACEFCE-7C92-4871-A016-50B93DDE2ACF",
    "CNS_CNSQ_Summer22_mech_Low.pdf": "03C70545-6440-4FBC-8AD8-C2F373C08BFA",
    "CNS_CNSQ_winter21_mech_web.pdf": "2A51ED30-EE66-4DF1-9034-D2F2157C7FDE",
    "CNS_CNSQ_Winter22_mech_online (1).pdf": "D22654BC-8337-439A-B910-757CF38A9602",
    "CNS_CNSQ_Winter23_OnlineFINAL.pdf": "66F13DA4-FA16-461A-8EFA-15200CC9FC2A",
    "CNS_CNSQ_WINTER24_MechOnline Final.pdf": "DCC762CD-A240-4F0E-9E0A-E15FA438CFA2",
    "CNS_Code_of_Conduct.pdf": "C8552BF3-90F2-40C2-8672-C536A11F4455",
    "CNS_ColtonBarr_V4.pdf": "1D8F361A-0FAC-4D43-B7E3-522F3C828741",
    "CNS_Comorbidities_Poster.pdf": "848217E5-040F-4FBE-8BEE-21B867C84293",
    "CNS_Complexity_poster.pdf": "F31A8A82-9B56-4AA9-91D9-8CBFFEFC8DAD",
    "CNS_ComplicationsFINAL.pdf": "D4DB30AC-6C37-43F1-BD4D-9163036010A1",
    "CNS_conduit_template_final_upload.pdf": "18D85D1A-A16D-4CC0-84DE-A794D4150E23",
    "CNS_confirmation of enrollment_Irene Harmsen.pdf": "C496545A-2D04-4611-8339-BDD027E6F8EC",
    "CNS_Contract Offer.pdf": "F25C0FF6-092F-41C0-8332-D417DE51F282",
    "CNS_CostEstimator_v2.pdf": "94D7BD0F-DF0D-4DA8-A455-44FB2158BAB1",
    "CNS_CSNS Fellowship_Fossett LOR_Rush.pdf": "0FF9540B-01E4-4C2F-B850-7127A27A024B",
    "CNS_CSNS Fellowship_Parry LOR.pdf": "A06D53EB-BE77-486B-A4C7-F70F565541E6",
    "CNS_CSNS Fellowship_Parry LOR_Rush.pdf": "DA15E39A-BEBF-4846-A9ED-EF7670C1048C",
    "CNS_CV_Tutino.pdf": "58A4EC51-3720-4048-8A79-DF6FC29C2123",
    "CNS_DBSTrends_Poster.pdf": "A105C6E1-289C-4D3C-90AE-EE727398C0D2",
    "CNS_Deng_Table1.pdf": "F553AFD1-1D79-4F23-AE83-353034A85A62",
    "CNS_Digital Poster_Lee_CK edit.pdf": "15C787BC-F62C-4838-9847-441F2976D297",
    "CNS_DIGITAL_POSTER_2023.pdf": "3896354E-A51A-4E65-9B97-613AFF809016",
    "cns_digitalPoseter_2021_final.pdf": "F38AE884-4CC2-4E14-8167-2BA172EDBFC2",
    "CNS_DigitalPoster_SDAVFSAH_BN.pdf": "8130273A-BAF3-4162-9A45-1BEBD293BC4E",
    "CNS_DigitalPoster_SPvBPAngio_BN.pdf": "D7C90EF7-8C45-4CF5-9ED6-75391BB47B96",
    "CNS_Durability-Final.pdf": "1B92832D-7166-4981-A1BA-CA830F8346EF",
    "cns_ec_concussion_panel_release_031416_final.pdf": "369031DC-EEA9-4E84-B913-2BD4D4F9E9ED",
    "CNS_Edger_SET Letter of Verification.pdf": "30A85307-3CCC-4525-926E-B6D3AAB702F1",
    "CNS_EducationFlyer_05222023.pdf": "92F56A91-3F33-44A6-8607-35C9838B7246",
    "CNS_EducationFlyer_072623FINAL.pdf": "90C308A4-05C1-4936-BE06-9061F17A5945",
    "CNS_EthomicsPoster.pdf": "295AC478-C03E-4E77-9D32-A7AFC906DC1E",
    "cns_fall-2017-quarterly.pdf": "5AB60080-4C45-43FE-948A-99036FDD38DE",
    "cns_fall_16_mechlow_0.pdf": "6D351075-A26B-4363-9F40-56FDED86CD4A",
    "cns_fall_18_mechprint_0.pdf": "2AC3F05A-36CA-4EE9-8C9E-4556543430B2",
    "cns_fall_2015_mech_0.pdf": "D17A8074-2F8E-40D6-BD81-312C596F8A3D",
    "CNS_Figure_1.pdf": "5E23C67D-D589-4BE0-B21F-C6CAE213E64C",
    "cns_figure1.pdf": "925E2055-2CAC-423B-A413-E5031547B0BE",
    "cns_figure2.pdf": "7678DAA8-D9FB-4D9D-AE65-1EBA3B85D55C",
    "cns_figure3.pdf": "B32284EC-992D-409E-8110-F45212DFA25C",
    "CNS_Foundation_Bylaws_as_amended_10-18-19.pdf": "B1BEC336-BDDD-4DFF-8C32-20176C5BCCD4",
    "CNS_GPT_POSTER.pdf": "4357BBC3-E749-45AC-9018-AF0825168420",
    "CNS_Gulek_Eposter_Figure2_2021.pdf": "D65D8066-4E0D-4BC0-8938-EE67C951FF2B",
    "CNS_Gulek_Figure 1_Giorgi-Analysis_2021.pdf": "0257DAD2-C8AD-49B6-9494-DBC8A345BBC1",
    "CNS_Gulek_Table1_Demographis_2021.pdf": "A1E29490-50DB-413F-A2B7-387DED0BA6D9",
    "CNS_Gulek_Table2_Clinical_2021.pdf": "79B2DF8C-0CC1-455E-9373-FC28F29478DC",
    "CNS_Gulek_Table3_Descriptive_2021.pdf": "337A79F7-9FE0-42B7-929C-0FC797FC1135",
    "CNS_hematomascore_124 final..pdf": "A5D93EF9-620A-4890-AB26-1F1562EA3811",
    "CNS_Hemorrhagic_Mets_EPoster_final.pdf": "44CD6133-7EFB-473A-812B-1F1024431761",
    "CNS_HGGSurveillance_08042019.pdf": "DC613AAF-7596-470A-BA7A-1952206FC191",
    "CNS_IVH_40Weeks_8.5_11.pdf": "111DBF8B-5896-418B-A3F9-099336E62A29",
    "CNS_IvySign_2023.pdf": "C7F6E3D1-A475-4091-BE1F-6FA4A4136641",
    "CNS_JR.pdf": "DAF9B4AF-2383-472B-9244-94E21CC3BC77",
    "CNS_Lansford_4009.pdf": "155834DE-8101-4B49-B3F8-4B8610D33008",
    "CNS_Lansford_4010 PDF.pdf": "25F94AAA-4C38-43F0-8221-27B327A76258",
    "cns_leadership_institute_overview.pdf": "4A74580B-A2DA-4466-A3A8-233B50CCF042",
    "CNS_LeadershipLetter_Ellis.pdf": "2665E2B5-96F7-4563-AB60-E97D43E8078E",
    "CNS_Letter from Registrar.pdf": "1F9AF9C4-5B10-47EA-B720-8241EB7512D6",
    "CNS_Letter.pdf": "F99D6B1C-968F-4452-B257-59C163329F2D",
    "CNS_letter_combined.pdf": "4744C3F3-2766-48E7-988D-A5E52CE2779D",
    "CNS_LOR.pdf": "A6D707E4-85BB-4118-BEC2-1C9FD3265719",
    "CNS_LOWDOSE_POSTER.pdf": "E24FDD38-D37B-426F-892C-B16639319C9E",
    "CNS_Medical Student.pdf": "B2D108FE-9567-41B2-AC46-0F3F9E953A75",
    "CNS_Medical_Student_Verification_OPL_ZLG.pdf": "30C355B3-96A1-4660-AABD-0DAA2AFDBFE2",
    "CNS_Mendonca.pdf": "3532CA66-D9D2-49A4-9F22-B210511303CD",
    "CNS_meningioma_poster.pdf": "1A507AFD-8EF1-4708-987E-8D56CE83786A",
    "CNS_MIS_032799.pdf": "EBAE7608-CF42-47D8-801A-0CD9A8931E0D",
    "cns_mmc_poster.pdf": "BF112477-87BA-4CB0-A1E8-7A668025C92D",
    "CNS_N Bui.pdf": "636BD411-A19F-4F02-B658-8A791FD6F7B0",
    "CNS_NAMCS_PLS_Poster_2019.pdf": "E2B1A14C-95CF-4669-AD16-C352CCD9C139",
    "CNS_NeurosurgeryPubFellowship_PersonalStatement.pdf": "BFCF3CFE-5CD6-4BB5-A995-23256D245433",
    "CNS_NF1_2023.pdf": "6B90BA02-0B9A-451F-8144-E99DA701AB31",
    "CNS_nsgy bioethics_FINAL09.26.pdf": "84FC16F9-8FBC-46D9-8266-D95E41C44459",
    "CNS_Omniscient poster.pdf": "2931E503-CB62-4516-B0F2-34AF1081C39D",
    "CNS_OPENS_032737.pdf": "E750C307-9AB6-4C8F-9BC0-3398BA21B57C",
    "CNS_ORAL_POSTER_2022.pdf": "6F0D67F0-E88A-43CC-BA64-DB341AF7AEC4",
    "CNS_Patient characteristics associated with success and failure in substance use disorder treatment at a single rehabilitation facility   .pdf": "969E0A17-054D-44C3-8AB5-B46425E98088",
    "CNS_PECARN POSTER.pdf": "F999C7FD-07F2-40A3-8579-72FA35DE15E8",
    "CNS_Pediatric_Bacterial_Meningitis_Poster.pdf": "989D6767-FDB5-4279-BE5C-DC6A14B61FEB",
    "CNS_Pedsection_Poster_11.10.2022.pdf": "A9947E22-FD4E-44B6-A2D6-2FDFD7AE817F",
    "CNS_Pelvic_Instrumentation_Manuscript_Final.pdf": "25AE10C1-CE66-4D32-920B-DA0E6F074714",
    "CNS_Pitt.pdf": "DFBEB06E-2B23-42CB-B536-EAEEE1171A2B",
    "CNS_poster.pdf": "1C1CE5E8-048E-4E2D-8001-04301E7F2051",
    "CNS_Poster_2021.pdf": "BEF72316-6A03-4EC4-AD24-CA45CAE0FB34",
    "CNS_poster_2022.pdf": "7562F6B0-79A4-4417-814C-11939181F8DE",
    "CNS_Poster_2022_pdf.pdf": "69E09247-B822-4317-AF54-19CAAF04C24C",
    "CNS_Poster_8_29_21.pdf": "073F57BE-8BB4-44A3-9B83-5BC9D884BA74",
    "CNS_Poster_Abstract_ID_4067.pdf": "07EC0266-BD82-4869-988C-B602B99D4F40",
    "CNS_Poster_AMR083021Fin.pdf": "99678B91-C4AA-4917-8838-9437EE3955FC",
    "CNS_Poster_aSAHInfections.pdf": "70A4F27B-FF4F-4A22-828F-4E4474EE0747",
    "CNS_POSTER_AVS.pdf": "930242CD-CFE4-44E6-AA1C-8389E7F4EDAE",
    "CNS_Poster_BS_HGG.pdf": "BFCE66EC-5858-4619-98DA-087F17A7505D",
    "CNS_Poster_Cervical.pdf": "874C27FF-9344-4F66-83BB-5331D47952F4",
    "CNS_poster_CM.pdf": "F4BC32B6-81EA-47F9-A59D-AB39974B9FB5",
    "CNS_Poster_Coccy.pdf": "BC64FF5C-EA91-486B-9ED5-3D0D45394ECC",
    "CNS_poster_EAM_2021.pdf": "E61B18B8-E099-4363-8AEC-05B72B7F2625",
    "CNS_Poster_FINAL.pdf": "376C7C89-BD56-4CC9-833F-5F9FFD57256B",
    "CNS_Poster_FINAL_SN.pdf": "EC9055FA-8CA5-4F4E-B4F3-52EBEE988E20",
    "CNS_Poster_Gonzalez.pdf": "32E9C97B-8A3E-49C9-AF15-BF5517BD154A",
    "CNS_Poster_huang.pdf": "369AFDFB-FD35-4FDC-8025-DA9A90615F27",
    "CNS_Poster_ICH.pdf": "CB665E55-9730-47E1-8B1F-B672F48955EE",
    "CNS_poster_impedance.pdf": "DCB9CD04-8F14-4098-8EE2-177AB500B68F",
    "CNS_Poster_JRyu_Final_v2.pdf": "473E372E-1DA6-4D20-8622-F66C09BAB9F5",
    "CNS_Poster_Lumbar.pdf": "B6E31D68-2BB6-4581-958E-D2EDC268BCB3",
    "CNS_poster_meningioma.pdf": "0830B7AC-BBB5-4E52-B013-894974E725FF",
    "CNS_Poster_MJude_Zic Project.pdf": "0283F425-3D58-40C6-90FD-D8FEF97FF4A7",
    "CNS_Poster_PDF_Final.pdf": "2E722988-FFF6-40FE-97DE-AC46B8B4E4BB",
    "CNS_poster_QI_ROR.pdf": "7235A290-1175-4E93-8745-A5003751C181",
    "CNS_Poster_Rania Iman Virjee.pdf": "1091D6F8-741F-446F-AC99-26A3E9A8BE7B",
    "CNS_Poster_Resize.pdf": "9F5D1705-DF1B-4B59-AE0E-A77265EAE58A",
    "CNS_Poster_SAH_Fever.pdf": "18EBCA4F-2321-440A-8442-60FFCB45AB49",
    "CNS_Poster_SCT_Disparities.pdf": "E8C959D8-1889-4B5C-A534-03D49CD9DBF0",
    "CNS_Poster_shay.pdf": "40F469E7-E54E-440D-82B6-6EAC77B14DBA",
    "CNS_Poster_VarunBhave_08_2023.pdf": "2639866B-105C-4797-AC36-C4995C7DE264",
    "CNS_poster2022.pdf": "6F4C19BD-B720-4466-8BDB-0B24439B67C0",
    "CNS_Poster2022_Final.pdf": "18D6474C-B941-410B-B256-22F888C4C709",
    "CNS_Postmortem.pdf": "62DB81C3-9D9E-45AD-96F3-7199193E4126",
    "CNS_Presentation.pdf": "EA848D7C-DD7E-4EEC-9EE7-3E76C06E0F41",
    "CNS_Printed Poster_vFinal.pdf": "D4FA4BFB-EBDC-47F7-87DB-AB29E4B4BA52",
    "CNS_Rec_Elsamadicy.pdf": "7BBFE50A-7555-4A1A-B805-09A84E58BBE6",
    "CNS_Recommendation_Northwell.pdf": "34695822-524C-40C0-A57A-CAA79586D691",
    "CNS_Residency_Preference_8.5_11.pdf": "7D2C57D5-1CCB-49FD-A746-2A5DB7D97435",
    "CNS_RF_Poster.pdf": "2CC05389-99FC-4DC8-AD10-A07668B18744",
    "CNS_RNS+VNS_POSTER_FINAL.pdf": "4FB91FD6-FC86-4B82-802D-CB78F36BA786",
    "cns_sangwon_e-poster.pdf": "AB80AC9A-FDF2-4F9A-AA2A-3851A2BE0A03",
    "CNS_Skull_032799.pdf": "AA14361C-134E-498D-877B-D9081DF9669C",
    "CNS_Spine_Section_Figs1-3.pdf": "7E7E8FFF-E498-43A8-9DE6-38CE171B2E57",
    "CNS_SpineSummit2022_PocketGuide_mech_V2low.pdf": "5E4BFD05-9EC6-467E-8669-3CC3777C9FA5",
    "cns_spring_16_mech_small.pdf": "3E2F6A75-D872-47A7-9A58-9F0711E7C30D",
    "cns_spring_17_mech_low_res.pdf": "17B2B76B-A9F0-40C6-88DA-A6E4317921CD",
    "cns_spring_18_mech_print_small2_0.pdf": "A44C3253-7A84-4814-B068-2FE9F6BDECF3",
    "cns_srs_cacnes.pdf": "9823D49F-792E-4582-8E1C-1DFBAF95D1C9",
    "CNS_SS_2697 final..pdf": "F7C550EE-F72B-4C43-8943-B82195507D1D",
    "cns_summer_16_mech_web.pdf": "C98A1FC9-FC16-4333-BDD2-5616C4C6F45B",
    "cns_summer_17_r5.pdf": "34CDC7BD-4707-490B-97E7-882EDE84BCFC",
    "cns_summer_18_online.pdf": "21195414-1184-4972-991B-9BF84B61F707",
    "cns_summer_19_mech2_2.pdf": "24BF1995-5C4E-46EC-B530-BF7CFE26A2EA",
    "cns_summer_2015_mech_low_final.pdf": "1A4E4759-9706-43BA-BFB3-A48D9D06DA98",
    "CNS_SurveyInvitation.pdf": "D5A7C297-72EF-4A64-9DB6-3F2043FF73B6",
    "CNS_Tables.pdf": "54249DE3-063D-47C3-B95D-C83D6000DFC2",
    "CNS_TBI.pdf": "4BB3F5C9-D0CE-4928-9953-4B6B359669F3",
    "CNS_template_TNTS_hydrocephalus.pdf": "DE15B0CB-EE0B-427F-A1FD-22187A3A3C74",
    "CNS_TJONES.pdf": "562EB0DB-87F6-44D6-B488-C9AA2FC6A186",
    "CNS_transfusion.pdf": "0EDA35EE-32FD-4CDF-91B6-270AE6D4DF71",
    "CNS_Tumor_Symposium_Bowers.pdf": "AAE23580-770E-4478-B773-88C1FAFD09E9",
    "cns_winter_16_mech_low_resolution.pdf": "71196D42-3EBD-4D5C-86BE-9E2D35A41599",
    "cns_winter_17_mech_sm.pdf": "3C962D1F-DC99-48D0-84AA-6A9E2BC2EAE7",
    "cns_winter_19_online.pdf": "E201664D-AEB9-4660-8059-CA66F213311F",
    "cns_winter16_3.pdf": "940E5267-EE15-4B40-A0F0-850E9C91586D",
    "CNS_woundcalc.pdf": "F7EB3FD2-B0A2-4129-A437-6C8388B3193D",
    "CNS0001.pdf": "2A6587CF-735E-4747-8BA3-C5C00159A159",
    "cns036961.pdf": "12068B50-5A57-4ACE-98EC-9DD4AB4C86D1",
    "CNS12345_12AM_Prelim_Interior_(F11).pdf": "F9D6820F-844B-40E7-A78B-2B606B9093C3",
    "cns18670_18am_prelim_rev_082418_1-compressed.pdf": "472FE8E1-D6C1-4DA7-B446-E6EE06D3E086",
    "CNS19 CP Table 1.pdf": "1717C68C-30E3-44C1-A50C-B33E7C7684B2",
    "CNS19 CP Table 2.pdf": "FF87C399-8155-4B11-BA0C-C80CD479B6C4",
    "CNS19 CP Table 3.pdf": "FC404309-B8AE-4299-9C2A-4D2A34E2D056",
    "cns19_spineprelimprog_mech_0.pdf": "09D4896A-CC5A-40C5-9891-C20106358D84",
    "CNS19748_Flyer_2020EducationCourses_updated 1_30_20-2_Final has Career Guide.pdf": "195B00B2-7483-4D8C-BE4B-FFCBD792919E",
    "CNS19753_PainPrelim_7_HRF_No crop marks.pdf": "895DF71C-1C51-4E62-8D9B-B07F2F768BB8",
    "CNS19753_PainPrelim_8_Online.pdf": "A300C8E0-DAAD-4F3A-828B-7D04F55D59AB",
    "CNS2.pdf": "BF027571-C4B5-4336-B9F8-195478167007",
    "CNS2019 Poster - Axial facet angle.pdf": "9E71D60E-6883-490A-918E-BC66333E451E",
    "CNS2019 Poster - fluoro vs nav.pdf": "5AE1C1F5-16D5-476F-90C0-A8E1BA0DE967",
    "CNS2019????2.pdf": "A010D84B-D2D8-449F-A49C-E7CEF53B4B74",
    "CNS2019_Honolulu.pdf": "64EEEA48-0B1C-46FE-9588-635E8A37AF08",
    "CNS2019_ICH HCRU_LTC-SA-LY_FINAL.pdf": "7638DF5C-818C-4F20-AC95-2F963D73611E",
    "CNS2019_MMS_HD_LD.pdf": "D95EC808-BA79-4E58-9BB6-9A14B4589D2E",
    "CNS2019_MMS_Spine.pdf": "B653C8B1-2784-4B1F-B777-A4D19379CDD6",
    "CNS2019_SF_Dr_KEEM.pdf": "6D42B063-1F50-4AB7-9DCF-B1DE850E9E95",
    "CNS2019Amygdalectomy.pdf": "11F5A6E5-E0E5-4A1F-A868-41AF43BC0815",
    "CNS2020_SpinePrelimProg_V10.pdf": "763F5D32-1CC0-4DD2-8DBF-B6FE86BA4B17",
    "CNS2021 slide.pdf": "DA4A973E-E94C-4031-BB9B-E704FA234155",
    "CNS2021 Spine Summit PrelimProgram_online.pdf": "2F85922B-A388-496A-A893-D1F21E6F0026",
    "CNS2021-Poster.pdf": "5D6FE19D-45BB-4DE0-B172-EF0F8D884D90",
    "CNS2021.pdf": "B1ECEDD9-7600-47F6-8BFE-18C45CA5220B",
    "CNS2021_poster032693.pdf": "61469352-00BC-4B63-B9A9-69D9C78AD294",
    "CNS2022 Cranioplasty Poster_KC.pdf": "BA3E1567-7368-4BC6-AFA3-A1BF4B7F4579",
    "cns2022 poster.pdf": "0F67E381-65FB-4B49-BC25-E05E5E9FEF79",
    "CNS2022 Spine Summit PrelimProgram_FINAL.pdf": "E12A65C3-63B1-42D0-9823-164FEA66CE6A",
    "CNS2022.pdf": "09D72999-74E7-4886-BDD2-9D82FE6C94B9",
    "CNS2022_BBTBI.pdf": "E4777DB8-C2DB-4DEC-889B-E9534CA9B2C0",
    "CNS2022_Chordoma SRS.pdf": "802AB9D1-3820-4E0B-84E0-D36CF83EC3D6",
    "CNS2022_digital_poster_v0_JT (1).pdf": "59D34182-9046-40C1-9777-A04BF3CA79CA",
    "CNS2022_glioma_postopseizures_poster_v2.pdf": "E835FCC5-65AB-4332-A7EF-07BBFEC2FC42",
    "CNS2022_ITH_poster.pdf": "42EC2ED6-2EB9-4F23-8C35-6808DCCA8A55",
    "CNS2022_Poster_DJD_v2.pdf": "57E7643E-075B-4594-9039-847B84D7535F",
    "CNS2022_Poster_TAKAGI.pdf": "09C4F208-8006-48C4-8058-3B6753F7057B",
    "CNS2022_SS- VS. MS-SRS poster.pdf": "47DE7C09-70F4-4261-9E19-528B04CDA7AD",
    "CNS2023 dural calcifications poster.pdf": "501F6E7E-1D2E-4426-87F8-78325D164815",
    "CNS2023 Pit Hyp Poster.pdf": "5871BF95-AE37-440A-8D79-019E1350D72F",
    "CNS2023 Prl Poster.pdf": "5FC9B481-31F4-4E17-9771-FE8470E7EE2E",
    "CNS2023_AP_Poster_Updated.pdf": "5C402B74-F06E-4757-B1BC-9C714C067993",
    "CNS2023_DigitalPoster_TRW.pdf": "CAF4961A-3530-454E-96B0-72F5E5364CFC",
    "CNS2023_iTNcTNPGR.pdf": "4EC9B077-870A-48CC-94A8-DA816C444952",
    "CNS2023_MINIORALPOSTER_AMANDA.pdf": "5F4A92B2-646D-465B-81F0-0219A889A296",
    "CNS2023_Poster - SC Disparities.pdf": "C7310963-D85F-4D6C-A2BA-469D6808076D",
    "CNS2023_Poster_Cervical.pdf": "102DDC08-F6D2-4659-A96E-C14F169DFF61",
    "CNS2023_Poster_Draft 5 Perioperative Spinal Cord Perfusion Pressure Monitoring Via Lumbar Drain in tSCI_FCOU.pdf": "54552D9F-DD6F-4B1A-9FE6-E9D307851581",
    "CNS2023_Poster_SKS.pdf": "E27BF4DE-B7D2-45B8-9957-4C0BB073D953",
    "CNS2023_Public Domain Poster.pdf": "F7353418-4484-41BC-8843-5CAC6FAF8298",
    "CNS2023HargreavesDanish.pdf": "BF095D4A-C461-48CC-A322-0484FD0A211B",
    "CNS2023Poster.pdf": "E6973BEC-482D-4A03-8FE7-1FEC66BBCAA0",
    "CNS2023Poster_CaseNumbers.pdf": "CBA994C6-EA08-453E-A93D-A5D4A98BD238",
    "CNS2023TrisomyAbstractDigitalPoster (3).pdf": "43B08914-7C04-455F-88DF-69A9B522F235",
    "CNS20758_Update_CNS19746_SCC_2020_OnlineVersion_1_14_20.pdf": "0E575454-1D18-43A5-BC6A-CBCC98CD4977",
    "CNS20779_AM21_AD_JoinUsInAustin_CQ.pdf": "A032EDFF-3054-4E94-83A7-649957BE4504",
    "CNS21787_21AM_Prelim_05242021_copy_with 4 new GSS pages_080421_3 pm.pdf": "EDCCC356-3F88-4C09-BA3F-B101E0A28585",
    "CNS21787_21AM_Prelim_05242021_copy_with 4 new GSS pages_091721.pdf": "A55A9DB7-DCDD-485D-A9F3-C411A061F73F",
    "CNS22 awake assessment poster.pdf": "AE925E2D-05A2-4F2E-83A3-144AF34B8298",
    "CNS22 lecture series poster.pdf": "504F4AC3-562B-4427-BCDD-449314E69222",
    "CNS22817_SNS_Prelim_Final v2.pdf": "01DDB7A6-39DC-4DA1-A29A-BDFD67FAD764",
    "CNS22cSAH.pdf": "6C707568-9741-4ECB-B872-F1CBA059DB85",
    "CNS22ENBCaseSeries.pdf": "879E8C69-87E7-4432-AA34-15172D8EB37B",
    "CNS22ENBSystematicReview.pdf": "F947B4D4-0A82-45EE-9F3C-1FA6B3E487C6",
    "CNS22Escooter.pdf": "26A92CC5-DF25-4E0B-AD9D-6FB99DBB98BE",
    "CNS22Suture.pdf": "590C699F-7063-4D8E-BBA1-F3150825DB6B",
    "CNS23_Poster1.pdf": "D1B2A4E2-1F03-42B5-915B-5802A5B71B00",
    "CNS23_Poster2.pdf": "EE3841AF-956F-4D1F-A407-9CF7D1D0FB95",
    "CNS23_Poster3.pdf": "B75C3535-E405-442A-85F8-B1408AE59775",
    "CNS3-merged.pdf": "62989036-DED0-401B-86BE-EBE7755E02D6",
    "CNSAANS_Poster_FINAL.pdf": "B7202F76-FC46-4A69-882E-8F8D679C9BC3",
    "CNSapplicationLetterofGoodStanding.pdf": "EB1B8158-FC8B-4FC9-AE77-3C5AA138F394",
    "CNSasc.pdf": "54CA456C-FDEF-40F7-96F9-5A1CD4B3695B",
    "CNSclipping.pdf": "A654253D-D13F-495D-904B-DC99962BFA1E",
    "CNSCMECertificate102939CNS19.pdf": "B5A98A74-BC15-442B-A540-8F8C9B20D6DB",
    "CNSCSNS Fellowship Budget.pdf": "376ED88C-CCFE-44AA-BFB0-30C6C8EA8846",
    "CNSdefinitiva.pdf": "721A55D4-FFA3-4C53-B614-0846D5B22C75",
    "CNSF 9.2.2020 Minutes_CNSF Board.pdf": "4EC1A085-5382-4FA3-926C-2723951DDAE2",
    "CNSF Bd Mtg_Sept. 2020_Appendices_FINAL.pdf": "F3388D83-A51C-4E48-BB33-01896AF04538",
    "CNSF Terms and ConditionsAwards_ 2023.pdf": "9F19D066-69AB-4E55-8B4A-9975850A5082",
    "CNSF_Impact Report 2020.pdf": "0EF69201-A132-4A68-B97E-C62F7DE4B1B9",
    "CNSFerozeVerification.pdf": "94B0C13C-ED97-49AF-8A7D-848E665A8AE5",
    "CNSFoundation Winter 2019 Impact Report.pdf": "B6A6A69A-ED37-49DE-A8A2-D86B5EC50F7A",
    "CNSFoundation_CNSQ_Future Women Leaders in Neurosurgery Scholarship.pdf": "47EB53C1-8FF0-4D6B-936E-7AD1568617C8",
    "CNSFoundation_Fall 2020 Impact Report.pdf": "1550FE11-2949-4BDC-8F8D-383780854DA8",
    "CNSIatrogenicDeficits.pdf": "701CDB6A-398E-4D36-9C16-E931E4CDCF7F",
    "CNSletter.pdf": "996A9531-8147-4B47-8E36-274B4FCA4308",
    "CNSMembershipLetter_UCSDNeurosurgeryOutcomesRoster-1.pdf": "D1C8BE08-DA6F-462C-A357-729F1F3104B8",
    "CNSMembershipLetter_UCSDNeurosurgeryOutcomesRoster-2.pdf": "4C6274E1-3B4B-4FE8-8141-AF79FD8533A8",
    "cnsmsm.pdf": "230216A5-3EA4-4EC1-993D-C67811DAACC9",
    "CNSND.pdf": "EED86562-34D0-49DB-8145-9630979CE4C6",
    "CNSPoster.pdf": "21294BD4-5EC3-434D-A108-86EFCECEC5C7",
    "CNSPoster_2385ID_Final.pdf": "557CB93A-AB7E-4440-BDD9-0638CA20161F",
    "CNSposter_interactive_MMCAVOY_08.04.19.pdf": "47DE4232-691E-474D-B047-EA7F9B5A613D",
    "CNSposter_lowSDR.pdf": "D241E6F0-FEE3-4168-91CB-EEA8D0068828",
    "CNSPoster_MMD .pdf": "7272244A-21E5-4C15-9ADA-3588C6B79D63",
    "CNSposter_NF2SWN.pdf": "EEEC04A1-5A60-40FE-BE02-2506F9034857",
    "CNSposter_PcomInf.pdf": "9A291F76-A7A4-46EF-B0A4-64816114E2C6",
    "CNSPoster_Peskov_032313.pdf": "427B0634-A217-40F8-8B19-A93B1E9DE660",
    "CNSposter_SH.pdf": "9409D48F-C772-419F-8962-A9ADC2686694",
    "CNSposter_stroke.pdf": "C785ED3E-EBDB-466C-969B-C1FCF7089BE2",
    "CNSPoster_VesselDL _updated.pdf": "034CD50D-24FA-476B-8D45-32550B2304F8",
    "cnsposterfinal.pdf": "63838A6C-7BF6-45CD-994A-F40E0E5660D8",
    "CNSPosterKalagara.pdf": "75472E86-B092-4AE2-A4CD-64525A13132D",
    "cnsposterupdayedpdf.pdf": "1C923041-C393-42AC-BE71-701FE4B0FE7F",
    "CNSQ_06winter_0.pdf": "4B387793-AF34-49FD-9CFE-BF5A75F64371",
    "CNSQ_07fall (1).pdf": "163E34E7-1097-40F9-AB38-692B6C777E91",
    "CNSQ_07fall.pdf": "7C1552EA-9B5A-4E6A-B95F-F9C4C234AC66",
    "CNSQ_07spring_0.pdf": "54C3E4F4-0788-480D-B5B6-87FC2FC49BC0",
    "CNSQ_07summer_0.pdf": "B5ED724E-D987-4A95-8F22-7B4B76F53D84",
    "CNSQ_08fall_0 (1).pdf": "4C48A976-47B3-4020-95D3-64ED78A18610",
    "CNSQ_08fall_0.pdf": "C1CD0F3C-9C27-4D71-9B39-4273B9482536",
    "CNSQ_08spring_0 (1).pdf": "2CE16DB9-5B51-4A8D-A425-D3CEA1598F21",
    "CNSQ_08spring_0.pdf": "65EF7D3E-52D3-4A4D-9CEF-9CD2390430EA",
    "CNSQ_08summer_0 (1).pdf": "BD882F80-5768-47F9-AE26-0B5B31A84613",
    "CNSQ_08summer_0.pdf": "DBD2C27B-BABB-41A5-A5BD-51D952BEB222",
    "CNSQ_08winter_0 (1).pdf": "126AC90D-8554-4085-98D6-C31403C26307",
    "CNSQ_08winter_0.pdf": "79F1DDA3-19B7-4EF8-A0DB-BFA207C0C657",
    "CNSQ_09fall_0.pdf": "D9E86BDB-029B-4993-B05C-3DC92497E8BA",
    "CNSQ_09spring_0.pdf": "CF02E140-CE55-42B6-A7C3-0040BEED0EBA",
    "CNSQ_09summer_0.pdf": "EF6B897C-471C-4BE2-A8F2-4B803BBEBFD0",
    "CNSQ_09winter_0.pdf": "F4E7D64C-70CA-46FC-AAE2-0A82682A5A1B",
    "CNSQ_10fall_0.pdf": "03C0A4B0-0658-45DD-A898-E395173505D3",
    "CNSQ_10spring_0.pdf": "0E4E0173-8492-423D-A8E4-43FA4D9E1EC5",
    "CNSQ_10winter_0.pdf": "29DBB3D1-07A7-47B8-B31A-513F7ADD954B",
    "CNSQ_11fall_0.pdf": "CB8FB054-3974-44EC-A785-53290CD57836",
    "CNSQ_11spring_0.pdf": "C077ACAF-6D41-45B2-BB0A-07DBAEEDC29F",
    "CNSQ_11summer_0.pdf": "4E76EBB5-E661-4988-8C47-7D2E931E37C7",
    "CNSQ_11winter_0.pdf": "8DCCF9C5-E83A-4CF4-9D92-0114C5673AC7",
    "CNSQ_12fall.pdf": "9B955ACA-7D0E-462F-9810-433E19C13209",
    "CNSQ_12spring_0 (1).pdf": "EA04DF8A-E497-47FF-9E6C-12C300E2EADF",
    "CNSQ_12spring_0.pdf": "AE7F66EC-70C9-49FE-8FD4-F33504AA8555",
    "CNSQ_12summer_0.pdf": "D6F0D495-24FA-43A3-BCAC-081BD22A5DB6",
    "CNSQ_12winter_0.pdf": "EFC01258-27C6-49DA-AA05-480252CCDDB5",
    "CNSQ_13fall_1.pdf": "B6DB916F-D567-4860-82EB-0A584565CAAC",
    "CNSQ_13spring_0.pdf": "B99682EC-A190-4A97-9AC1-7788791FF60A",
    "CNSQ_13summer_0.pdf": "762BAB2F-ACA8-48FC-B549-77455D979634",
    "CNSQ_13winter_0.pdf": "C4F2246B-A001-4133-B1EE-B894FB2AA1C0",
    "cnsq_14fall_0.pdf": "151C9C29-FA0E-44CB-8DC8-6E9F81CC35C7",
    "CNSQ_14spring.pdf": "864EE4AB-4C81-4FE9-9CD8-E421C5AA3C22",
    "cnsq_14summer (1).pdf": "2D0F75A3-2175-4402-AB8F-6E6FD6F6D0D3",
    "cnsq_14summer.pdf": "20D052EC-E5F6-49EE-8CC5-45EB84C61DB9",
    "cnsq_14summer_0.pdf": "4DB9F325-2971-4405-A973-49B86015C00E",
    "CNSQ_14winter_0.pdf": "B84690ED-F4CF-42F8-A05F-89BD9104BDD1",
    "cnsq_15spring_0.pdf": "756F0214-2E92-4CEE-AAAF-348F8E3CFC51",
    "cnsq_15winter.pdf": "CD178544-CC94-4A1E-AEA2-645DF61BF2C7",
    "CNSQ_Spring20 mech.pdf": "0D1D06B7-8AE7-4D16-A0D0-88AB466C8B98",
    "CNSQ_SUMMER23_Final Online.pdf": "98C0F684-2A75-46D2-AA55-D602EEF19691",
    "CNSRASHTBI_johnson_08.22.22.pdf": "A1A1F42D-34D7-4AA7-B740-E2DB80368F70",
    "CNSSubepPoster.pdf": "0FA7EF98-9DDB-49AD-957F-8609F0D0E8A5",
    "CNSSVR.pdf": "FD3C1217-E480-4C60-A22E-2376B9215719",
    "CNSTonometryFigure1.pdf": "631AEB08-8120-447C-BEF9-8C6F009D1EB6",
    "CNSTonometryFigure3.pdf": "ED7C2A12-AF95-41E1-B1B2-C0AE5D112951",
    "CNSTonometryFigure4.pdf": "D8866DA2-F7B9-4E2C-8D21-C7D051533923",
    "CNSTonometryFigures2.pdf": "738537F8-892A-4AFC-9759-7E0690F4009D",
    "CO-Poisoning-Feb-2008.pdf": "92256F2E-FF6F-45A9-B8EA-0D6041F379AF",
    "co-surgeon plif medicare fig 1.pdf": "161CA801-D7E1-41CF-A33B-B889DA06774B",
    "co-surgeon plif medicare fig 2.pdf": "7DBAD425-09DF-4733-AECB-3A309119BF64",
    "Coalition letter to 117 Congress_House_Feb 22 2021.pdf": "362B71EE-03FD-42F6-B37E-3A515530B2DF",
    "Coalition letter to 117 Congress_Senate_Feb 22 2021.pdf": "49CDD706-854E-4B0F-85C6-C65F5B20250A",
    "Coalition Letter to Stop Medicare Pay Cuts.pdf": "A1260F1B-5252-4B99-A15F-D02088CE88DB",
    "Coalition letter.Infrastructure.Final.4-27-21.pdf": "830E843C-DDEE-423B-9456-CA2BBCFA09BA",
    "Coalition Letter.Proposed Rule July 2021.Sign-on.FINAL.pdf": "995CA416-8772-4FD2-90A5-C5CEF78F3DD7",
    "Coalition Support Letter HR2079.pdf": "94A021EB-AAAB-4D3C-936A-8E7AF3327F4F",
    "coalition_comment_letter_on_cy_2019_pfs_proposed_rule_september_10_2018_final.pdf": "A021559A-2AD6-4290-B631-32F69F09EE5E",
    "coalition_letter_to_congress_on_cms_em_proposal_september_10_2018_final.pdf": "1DDC932F-5CF1-46E3-80FD-02A97B20B997",
    "CoalitionLetterOpposingWeinerImagingAmendment7-20-09.pdf": "09E879B5-AD3F-46CE-B636-97A5DFDB6AE4",
    "CoalitionLetterReid.pdf": "93057DB4-86B1-48FD-AF47-0FB1068A9E5C",
    "CoalitionLetterSupportingMedicalLiabilityReforminHealthReform7-10-09.pdf": "5FDF3E20-ED88-431D-AE88-260D99C59F40",
    "CoalitionLettertoEducationandLaborOpposingGAOGMEStudy7-14-09.pdf": "9858D1FB-EC35-4A32-8527-5CD34B6AFA4A",
    "CoalitionLettertoEnergyandCommerceOpposingGAOGMEStudy7-14-09.pdf": "DE22D4C0-A37C-47E3-98B5-4A28B43614D1",
    "CoalitionLettertoHouseandSenateOpposingRBM--6-15-09.pdf": "B4289E30-E0F9-47A5-AF27-E192D6809772",
    "CoalitionLettertoHouseLeaders3-10-2010FINAL.pdf": "F8898D23-21D0-4326-BC44-64ABA81D73B4",
    "CoalitionLettertoWaxmanOpposingWeinerImagingAmendment7-20-09.pdf": "E1210864-8C07-4A49-95C3-87087F0CAEE2",
    "CoalitionLettertoWaysandMeansOpposingGAOGMEStudy7-14-09.pdf": "698AFF41-EA6A-43E1-98B4-683A6CA9D712",
    "CoalitionSGRLetter3-4-10HOUSEFinal.pdf": "34122753-6215-4A10-B3CB-F1556D3D28B6",
    "CoalitionSGRLetter3-4-10SENATEFinal.pdf": "C6FF3604-24D9-4D44-B31D-2E27788C88E9",
    "Cobourn_FWLN Schol_2022.pdf": "4EC275BB-B662-4DB8-8422-3ACB65FBB285",
    "Code of Ethics.pdf": "34216564-6BF4-4FFB-A762-4B24FB5261C8",
    "Cody Heskett.pdf": "0B54431A-6273-4EFA-833B-5CD233CBDBB7",
    "Cody Nesvick-FellowVerif.pdf": "B3D51762-6244-43E0-8B0F-386ACAD2DB85",
    "Cody Nesvick.FellowVeri.pdf": "D3DC8787-989F-43C2-9199-5B7E1D8532AF",
    "coe.pdf": "D6C169FE-E395-443D-802B-A358BE228B49",
    "Cognard.pdf": "150A2107-73A9-4845-8EDA-A4D0482ADA87",
    "Cohen David - LOR - 2021.02.pdf": "20CFEEE1-734B-4687-9989-B82A914442E0",
    "Cohen, Al.pdf": "17F5F985-12D5-4A7A-8ABE-0257BCF361C8",
    "Cohen, Alan R.pdf": "B8A75AFC-475C-4EB5-A4D3-4A505E92FFC3",
    "Cohen, Alan.PDF": "3908925A-0C9F-49B0-AA0A-204034D777CD",
    "Cohen, Alen.PDF": "74CA1454-960F-453F-9E67-99FF0E8A8F9D",
    "Cohen_Michael.pdf": "FC803CD7-5996-4209-9D18-DFE4C10930A8",
    "cohen2-24-23.pdf": "45227F42-2175-4A5E-B3A7-42BDA3D4E0BA",
    "Colasurdo CV Jul2022.pdf": "F72DD214-F65B-4C1E-9F9A-1066D80CB886",
    "Cole Kyril - Letter.pdf": "99B6851F-4DED-49B7-9ADA-26F18BEBF65B",
    "ColeCV 2017.pdf": "39D820EB-412D-441A-96F4-A851C52714BF",
    "Colin Letter - JJJ.pdf": "0C2822A1-08DF-4CA6-9843-DAB90B3AE2D4",
    "Colin_Son_-_Neurosurgeon(7).pdf": "DD87F146-2F9F-4838-BD55-6E4E1FF8E4FF",
    "Collin Brooks Letter of Good Standing.pdf": "DBF2D9B9-D39C-4458-A0A2-4B6B0249AECA",
    "Collin English.pdf": "ECB5D998-9423-43A8-9F25-3C38DC19A1A6",
    "Collin Kilgore Certification Letter.pdf": "ABB25CF0-FC5F-4EC2-8225-62B32DBAF066",
    "Collin Liu-Fellow.Verif.pdf": "75B973A2-E64E-49DA-BAAD-0FE106782634",
    "Collin Liu_good standing-immunizations-background check.pdf": "831EB238-9E37-4D4A-9079-CB30A1BC3E29",
    "Collins Mokua Admission Letter.pdf": "664A402F-44EF-4A81-9928-08CDB54ACD28",
    "Collins Reagan Letter of Good Standing.pdf": "988E152A-6D10-4E33-A42A-27070DBFB93A",
    "CollinsM_StrokeDisparities.pdf": "1362144C-1660-41BA-AF33-3B9EF348029E",
    "Colon, Nathan enrollment verification.pdf": "32657E29-FCA3-455B-B098-79BD7177482A",
    "COMadropinZahra.pdf": "D93A2147-BAA3-48D3-A193-1111F22A79EB",
    "Combined Call Schedule OCTOBER 2020.pdf": "6B1D1886-EC13-4BF4-A89D-262892223BD2",
    "Combined Letter 2022.pdf": "27ACBA1D-8882-407E-A55D-C5965C69B1AE",
    "Combined PDF.pdf": "DE35AA6E-B573-406B-B48D-B77123B7D383",
    "Combined Round Robin letters 2021.pdf": "D06B4344-2B38-4553-A7D5-CF37EF9F2861",
    "Combined Round Robin letters. 2020.pdf": "94156044-329C-4BDE-8E4E-FB00C8B07CFB",
    "Combined_figures.pdf": "08D7C5C2-E1A3-4C01-88A6-ED06590F1469",
    "combinepdf.pdf": "25CA59E3-6D4E-44D5-BBC1-A4E1268531F6",
    "comment_letter_release_9_8_14_final.pdf": "6BDF4708-086E-4777-8914-3A0528535489",
    "commentletterrelease9814final.pdf": "AE9CE962-FED5-490A-B6D1-055E4695628D",
    "CommentLetterToDEAJune2010.pdf": "8FFF7567-6337-4EBE-9E14-DBA31014BB7F",
    "CommentsonBCBSEndovascularCoveragePolicy070114.pdf": "A2A70CE1-AC70-477C-8597-792343B61182",
    "Committee Operating Guide- Nominating Committee.pdf": "D38C70CE-2022-4AE3-AD99-D243C90C6998",
    "Common Program Requirement 7.1.17.pdf": "4A7B059B-D812-45EB-B37B-EA6F5E78A47A",
    "Communication.pdf": "05CAC47F-335B-4E64-97CC-AE0613D00883",
    "communiction .pdf": "0DCF25D2-DCC6-4864-9639-EA34F4554927",
    "Community hospital Experience with Middle Meningeal Artery Embolization for Chronic Subdural Hematoma - CNS Poster.pdf": "CEA9CD52-F1C4-48F5-86A7-F4F03E129ACC",
    "Comparative Analysis of Early Postoperative Neck Pain and Dysphagia After Standalone vs Cage and Plate Constructs 2023_AM_PosterTemplate.pdf": "801E2330-87BB-454E-B788-136B6B4ADA35",
    "Comparison of intraoperative indocyanine green videoangiography versus postoperative catheter angiography to confirm microsurgical occlusion of cranial dural arteriovenous fistulas.pdf": "77408399-5549-432A-A4D6-D25D780233F2",
    "Comparison of Multifidus Muscle Atrophy and Trunk Extension Muscle Strength-Percutaneous Versus Open Pedicle Screw Fixation.pdf": "09D61476-9363-4FFC-B5DF-CB7817D31BB8",
    "Comparison of Neuromodulation Procedures for Epilepsy Control A Single Center Experience.pdf": "0AA8143B-7C0D-46B6-9C2B-7B40FF0D0644",
    "comparison wellness programs.pdf": "9B557A08-6840-4923-94F1-4E45B1A55937",
    "Competence certificate.pdf": "89707A8E-9D12-4F5A-B6B5-A3204CA7E348",
    "complete_medpac_letter.pdf": "FAAEE83F-0AA0-44E4-9993-5B61ADC0BEB3",
    "completed verification for Cisse.pdf": "AF360070-E212-42A5-9250-D4E53162490C",
    "Complex Spine Fellowship Diploma Davide Croci University of Utah.pdf": "5C30EFAC-45D2-4092-B88E-D163A9465548",
    "CompositeV4_Labels.pdf": "F44351D5-AE40-418E-B562-D589003836C1",
    "COMPROBANTE_ALEJANDRE.pdf": "32E25022-DA5D-4659-AB07-DC4BB8552452",
    "comprovante RM neurocirurgia.pdf": "080A1EB3-C356-4107-8B83-FC5C85069181",
    "CON22 Prelim program_08.pdf": "7E4234EF-4CBE-4046-ADA8-F7EA4095E78C",
    "Concepcion 4.9.21.pdf": "A90D7295-C78E-4052-8E5B-94FB409D05C1",
    "Concurrent Brain Neoplasm & Sz_Syst Review_AANS-CNS Section on Pediatric NUS 2023_Poster.pdf": "2C6F51F3-5AD2-4B49-AD64-F7CF7D7E7241",
    "Conditional Survival_CNS.pdf": "E11E019B-3718-4BB2-834E-26AE33D7C2D7",
    "Conditionating ADSCP2 with DMOG CNS2019.pdf": "4BBDF7DE-B5FE-424D-8CC9-A6D83F60AAF3",
    "CONDOR Recurrence v1 1.27.19 JA.pdf": "A5A0004A-5414-459C-ADC3-05EA2EF8B616",
    "Conference confirmation.pdf": "7AF949DA-70F2-474B-B458-04582BAB0084",
    "Conferences Policy.pdf": "92FF5CF1-319C-46F7-BA39-ACF2625FCEE9",
    "Confirm MND.pdf": "922A633F-6FD3-40B5-9AB9-0DD7AE878F48",
    "confirmation letter for cns.pdf": "21529A8D-8076-4795-A980-10EEE7914F3A",
    "Confirmation letter.pdf": "AB422F28-52CE-4A48-B0A1-B5D4570D3515",
    "Confirmation of Academic appointment.pdf": "76D715CB-C048-4B0D-A815-932AFC082C73",
    "Confirmation of Enrolement (Alwyn Gomez).pdf": "05D06AB9-C830-4237-B19E-6295090BF0DF",
    "Confirmation of Enrollment at UCSD SOM.pdf": "7FBF7C99-11FF-47F2-9324-D08AE564C113",
    "Confirmation of Enrolment Jan 2015.pdf": "F30BFCF0-91BE-4F64-A81C-FD56825BFB5F",
    "Confirmation of Residency - Dr. Grossman.pdf": "03E87D1C-FAF7-4716-86DA-40424A38533D",
    "Confirmation of Training MC.pdf": "BFF582FA-8FDF-4A12-B342-CF6C53DEF00A",
    "confirmation of training.pdf": "275697DC-F022-4009-8481-2B7C74CB82FB",
    "Confirmation Witt.pdf": "17A97CCC-1EE5-431F-916E-C51F63572137",
    "Confirmation.pdf": "D998FB33-9BBE-430F-B993-20194D830BFF",
    "confirmation_of_fellowship.pdf": "B2DC6B3C-990E-4708-9B96-11051A39BD31",
    "Confirmation_Residency_Wiedmann.pdf": "88954D3B-BA1C-4B26-BCDD-9694B691DBD9",
    "Cong. Neuro Surgeons 04.26.13.pdf": "7A930198-B910-409C-8105-F2812EE1E46A",
    "Congenital Dislocation poster  Final.pdf": "3754D2F5-7F5E-493F-8BFB-ABB24434897D",
    "congress legend script.PDF": "AE876061-1C5F-45ED-9033-0C173E50612A",
    "congress letter.pdf": "1A5AF2D3-0537-4611-A3DE-0D6867DD43D1",
    "Congress of Neurological Surgeons - Jordan Rimes 2017.pdf": "F30798DA-A95E-4A01-914E-231F61C9FEE2",
    "Congress of Neurological Surgeons - Murray Petersen 2016.pdf": "A9235A68-2930-4146-8DC8-DE0E558036E9",
    "Congress of Neurological Surgeons BoardEffect®.pdf": "28598E06-C5EB-4E79-B9FC-CE8DD3FE7118",
    "Congress of Neurological Surgeons COM Letter.pdf": "14E05C4F-56F6-448A-ACE6-0E17DF504E5F",
    "Congress of Neurological Surgeons.pdf": "1C3C0E52-7BF4-4ABF-A66D-31822251447D",
    "Congress of Neurological Surgeons_2020 Abstract ET_AG.pdf": "F844E943-55BB-4375-9164-7837ED1E7D51",
    "congress.pdf": "81BF3B63-9E66-4F0F-8D32-A63599EED60F",
    "CongressQuarterly_Winter2020_FinalMechanical.pdf": "D4BDA80F-28FD-4FF5-AC85-10D2FA2BF69C",
    "Connectivity_2021.pdf": "20184CAD-A8D1-4491-A8FC-DB24ADB2E405",
    "Conner_CV_CNS.pdf": "E35F0A47-28FD-428A-A336-4C5C00FDB105",
    "Conner_NIHCV_2023Aug.pdf": "29FAD403-C9AC-4E11-9D2D-3D8F1E235FB4",
    "Connolly Letter CNS2324.pdf": "AF45D093-91C0-47AD-ABA8-F02CBC48C9E2",
    "Connolly, Sander.PDF": "C1EA2653-D270-48AC-ABC2-1A3AA7787048",
    "Conor Cunningham.pdf": "44DF6E68-506C-4F96-B03E-3FE32B07422D",
    "Consolidated Appropriations Act 2021 Summary.pdf": "66EDB99B-C73B-4B55-9DC2-81371E34113F",
    "constancia FELIX MONTENEGRO MAYO 2023.pdf": "2BA9DCB9-27C2-4A38-8CC5-3EBA0C39FAC0",
    "Constancia residencia.pdf": "45065BCE-59FF-461C-A520-C5C96B51895D",
    "Constancia SPNC Dr Marco Mejia.pdf": "C47C2C0C-4CC7-4808-9A21-688DCD9DFB23",
    "Constancia-Jesús Enrique Ortiz-LOGS.pdf": "A0F3695B-C683-4BFD-BB2C-6853798A562C",
    "Constancia.pdf": "F80D9B31-8CDF-410D-B540-6DEB5663BBF2",
    "Constantine Karras-Fellowship.pdf": "13B8A23D-7E40-479A-B1D2-B119D2093568",
    "Constantinos Avraam CV.pdf": "4B210C19-6269-46A2-881F-D7181752E02F",
    "Constantinos Letter.pdf": "A342C640-B37D-4BF5-B678-7D54BBAAE54C",
    "Contact Information Sheet for Website.pdf": "A8C8322C-C2CC-4641-97DD-374DA45190BD",
    "Contact List.pdf": "E6382F7B-3F2C-41EE-AB87-9F5D63733B09",
    "Contact Our Team _ 2024 CNS Annual Meeting.pdf": "6CE89BE9-779A-49F9-8527-90A1185A6EE8",
    "Contact Our Team Page_2021 CNS Annual Meeting.pdf": "A047ADF7-66E6-4580-9E7D-2339706F2305",
    "Contact Out Team_2022 CNS Annual Meeting.pdf": "A1FE5B61-E68E-42C8-A156-704DA76335A4",
    "Contact Us _ 2023 CNS Annual Meeting.pdf": "E112583D-8C58-4212-9D3F-DFB6036B2A65",
    "Contact_Our_Team_09_10_19.pdf": "CC94324F-49FD-475A-BBE0-E37C65F4F270",
    "ContactTeam.pdf": "786BF4E1-AA39-471E-AFFB-D99F08EDCFCC",
    "Contract.pdf": "1A19E083-0672-4419-A27B-4A073A471832",
    "Controller.pdf": "7222EA85-D919-4FFC-9EAC-9AA7E9CB7D3C",
    "convert-jpg-to-pdf.net_2015-12-03_17-27-05.pdf": "6C37FD44-5793-4370-A635-2E9F0C88B55F",
    "Cook_CNS Leadereship_Chair LOS.pdf": "3C050AE2-4467-4791-B922-D17120D3CA0C",
    "Cook_Steven_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "BD928A40-37EF-4926-A5BA-0AB2D3605E9E",
    "Cook_Steven_2024PositionalMembershipApplication_CVandBiography.pdf": "4456E5F3-AB58-41F9-80BE-2B413A47D36E",
    "COpalak.REStrainingverification.pdf": "00FDE381-AE05-4C32-8FC9-87758E4A5849",
    "Copy of Beige and White Contemporary Editorial Landscape University Research Poster (2).pdf": "2DC4C4AE-4B98-4196-A9DC-C7885FE9BF96",
    "Copy of Current Medical Registration.pdf": "98C8DA3D-30E4-4D95-BAB4-0FF4D70FF53B",
    "Copy of Poster Rahme redo 3.pptx (1).pdf": "36F80D6E-0023-408B-B912-665702B1649B",
    "Copy of Tumor Subtype Percentages_CNS Abstract Data (003).pdf": "3A54FC82-9DED-491F-A8AA-99251C7ADBF9",
    "CopyofResume-Standard.pdf": "6B6A4450-75A9-44A3-AFD8-C899E26AB044",
    "cor_plot.pdf": "4ECC212F-0E8C-4C5A-A2B4-91FB321DA652",
    "coravec2023_PedsDigitalPoster.pdf": "DCFC60CC-246C-429C-8343-6A01A9AE947E",
    "Corbin Edmondson LET.pdf": "E3DDD29E-EAA5-4056-B608-284F456E2728",
    "Cord compression Abstract Figures.pdf": "15997547-C44E-4263-A391-3868AC737432",
    "Cordeiro Poster.pdf": "5CB88447-65E1-427F-9D33-58C0857BBDD2",
    "Cordeiro_Kevin_MD24_CNS.pdf": "C3F980D8-3523-4E8B-B1D9-29BEF7BE818C",
    "cordotomy.pdf": "424FDB2D-03C7-4D7E-90C9-9A7E91DEC7B0",
    "Core Values.pdf": "3FCBF856-550B-4ED9-ABB4-DD288609D5AF",
    "CoricHollandRec.pdf": "34FC4905-E1A8-4B28-98D2-3F2A153C4ED7",
    "Corliss CV and Photo.pdf": "F3D8E88E-B867-4686-923F-4D7CDD0A0113",
    "Corliss Verification.pdf": "A1BB58A6-FBE6-4073-8207-9E823BF482B5",
    "Cormier Prone_Lateral ePoster CNS 2023.pdf": "E9B998F4-17CB-44BD-8AFD-E539C66AC7C3",
    "Cornea Christiana- Letter of Enrollment.pdf": "E8DDB53F-99BD-4E06-A49D-9EE2AF6BF871",
    "Cornum Matthews and Seligman. Building Resilience in a Challenging Institutional Context.pdf": "188B7FAD-2940-44FA-BA02-CCC383472C6C",
    "cornyn_ipab_repeal_bill_endorsement_031617.pdf": "FCCDAD94-F3B6-4338-958B-8A1167F696F9",
    "cornyn_ipab_repeal_legislation_letter_012215.pdf": "874BBEE1-3FDE-48B8-B380-EA7C83496954",
    "Corporate Support Opportunities _ 2021 Tumor Section Satellite Symposium.pdf": "81B964BB-E655-4A55-81E0-C51052D897EF",
    "Cortical stim _ Figure 1 06082021.pdf": "A8142CE9-214C-4E24-8DAC-4441648E11CC",
    "Cortical stim _ Figure 2 06082021.docx.pdf": "16F861FA-C19A-404E-A480-7B51EBE7CBFA",
    "Cortical stim _ Figure 3 06082021.pdf": "D6E6E081-DA3D-4D8B-96D0-751A954765A7",
    "Corticotroph histology_modified.pdf": "D768E0D3-6711-41D1-A1F3-43237FFB71C4",
    "CoS-C2G5U08107B-Sheta.pdf": "8084BF33-EDAD-4928-BF19-42EFDADE7267",
    "COSECSA FELLOW.pdf": "0D22288C-144E-4B31-B6F5-BD15C187E2AE",
    "cosecsa letter VP.pdf": "BEF44C34-78D1-4A6F-B158-D4702CB12E15",
    "Cosgrove, Rees.PDF": "D3036535-B314-4CF5-8937-01DDAC62D520",
    "COSSSAetnaResponse12-2013.pdf": "37CAEA2E-57D3-418B-8694-B2C81236DF88",
    "Cost Analysis.pdf": "513F47CA-5654-4205-B27C-3776E01F97C5",
    "cost and spine tumor_FV.pdf": "CA9905F8-EC22-4A2A-97DF-C8CEB6EB0D47",
    "Cost Comparison PLIF LLIF - Fig 1 Patient Characteristics .pdf": "CEC4DA65-9F1F-4D92-91C7-D16A73953B0B",
    "Cost Effectiveness of Forgoing Postoperative Catheter Angiography Following Microsurgical Occlusion of Spinal Dorsal Intradural Arteriovenous Fistulas.pdf": "A1ECA56C-B3A9-4117-BD91-F3C54B344E38",
    "Cost-Effectiveness Scatter Plot.pdf": "E5B628D1-A29B-458B-BB08-4D7BEEAC3B40",
    "Costas Picolas certificate.pdf": "CEB38C2D-8748-4FEC-BA31-5C2714F53056",
    "Cote CV.pdf": "779F7A40-67B8-441D-87B1-D666B2658C83",
    "Cote et al. Cancer 2019.pdf": "A4181DAD-1663-42D7-8F6E-E35202D3EBEE",
    "COUGHLIN MADIGAN W0Q153.pdf": "393D66DC-81D2-4992-AF7C-CD45BC5E2501",
    "Couldwell, William.PDF": "C681E330-D000-41DA-A6CA-97AB47AFBECB",
    "Council certificate.pdf": "236008AE-5BAF-4600-AD41-122B0CD7B413",
    "Courtney Shepard - Congress of Neurological Surgeons.pdf": "6FADB0A5-D098-4E9A-9AB3-52E377132140",
    "Cover Image CNS 2023 Annual_Report.pdf": "13DEFB41-9C29-4EA8-93CF-FA05663F9B2E",
    "Cover letter WINN award.pdf": "DCC93B9F-1B51-40CA-92ED-4DF27F7F87B3",
    "Cover letter.pdf": "BD899292-D49E-4B1B-9F03-EC1E6CF356AF",
    "Cover Letter__.pdf": "FC10565C-6C9E-4E52-B7D2-622660C6A8F1",
    "Cover Letter_Smith_061121.pdf": "44187C4D-E96C-409D-9201-BAF2B67BCA23",
    "Cover Letter_Sonia Bakke.pdf": "386FB019-69FB-4584-98D6-6D3B27BEF2B9",
    "cover.pdf": "40D53F87-8230-4964-9590-3E77E3EFF70D",
    "CoverSheet.pdf": "658E09CC-8444-4259-85C4-D26A969F5ED4",
    "COVID and Vaccines.pdf": "7533623C-8C65-49CA-8EC8-C470135C04F3",
    "COVID federal relief.pdf": "4F28492A-071D-47AE-BDA7-DDA30641B554",
    "COVID Figure 1 CNS.pdf": "FB2A7C8B-C01C-4C17-85F0-B7FB6AC2AACB",
    "COVID Figure 2 CNS.pdf": "466BB5B9-D8BB-4416-836A-B74CCE0CF00F",
    "COVID Figure 3 CNS.pdf": "1711D19E-4503-4244-B766-31FC608CB741",
    "COVID Vaccine.pdf": "A0CF3711-6A37-4850-9BCD-90073DC460D4",
    "COVID-19 HCLA Letter to Congress 4.9.20.pdf": "E726075A-E870-4884-B3D8-2455DC98D5C8",
    "COVID19 Case Series.pdf": "1FDB3DA9-D764-45F1-B016-85E49CA6978C",
    "COVID19 ICU Tutorial JSNTCC.pdf": "22AD1A45-BA83-47D3-9EFC-EC0F33C238E2",
    "COVID19 Testing.pdf": "8EA9F2F9-C454-45E4-B347-D536A465F3A3",
    "COVID2FormattedFINAL.pdf": "67BFB65D-65E6-4908-8E6B-ED5F6EC8AE21",
    "covidgliomaposter.pdf": "C4277F18-07A6-411F-A268-8546DC5146F4",
    "Cox Makayla - CME.pdf": "134CE1FF-F7EE-49ED-AAE6-50AA13713576",
    "Cox regression CNS.pdf": "7FD74ED9-B00F-4580-9CE7-86A54860395B",
    "Cozzens for Amin 10-21-2023.pdf": "AC9F84FE-667C-44FF-AB36-21B798588116",
    "Cozzens for Frankel 10-21-2023.pdf": "56F34EE7-5170-41FF-8B71-0F55EBB1DE66",
    "cp-Pneumonia.pdf": "7868516F-464A-4057-A90B-164CAAA92515",
    "cpci_comments_on_stark_modernization_final.pdf": "BFE1087B-CF7A-4D9C-8478-539F9BB7B800",
    "CPCifarelliCV_combined_Feb_23.pdf": "B9FAD2E2-BB60-4614-A889-F6823FBAA02B",
    "CPHeadache.pdf.pdf": "8F117CD4-3136-4CF5-A2DE-93F2B9DA6846",
    "CPS References.pdf": "C38E9147-C282-49CF-B9B5-20FB94117E19",
    "CPSO_Membership_Diploma.pdf": "19B4E29C-9852-4885-8B22-C757ED132BE6",
    "cpsoregistration-2.pdf": "A71A3795-E0C6-4639-B1C5-05433ED8D286",
    "CR_CNS_digital_poster_pdf.pdf": "536FDB3D-9F74-407D-9221-8895D91FB300",
    "Crabb Brendan_UofU_registrar.pdf": "7BCA56CD-90E7-4E2E-96D7-01C8FB6B07D0",
    "CrabtreeWilson CV  CNS.pdf": "062BB4F1-6023-4EC3-B357-BA6534AF704E",
    "Craft_Samuel_Good Standing Letter.pdf": "1AE25015-8880-4FC6-9EC6-805DC108D1DB",
    "Craig Kilburg CV.pdf": "BA89CC35-DE53-428C-8DFD-0B14602EC24A",
    "Craig Schreiber-FellowVerif.pdf": "49C2E370-1AEF-4C26-8CD0-20FB30A0D8D6",
    "Craig.Kilburg-RESverif.pdf": "1A34EAB3-72DF-4908-8742-7BF929B4109B",
    "Crani vs TNTS CNS 2023.pdf": "A3B5289E-896A-45AF-B67C-89256A2595B4",
    "crani_cSDH_mfi5_cns_table1.pdf": "3F4C5705-9343-4E66-B5F1-D423D87D32F8",
    "crani_cSDH_mfi5_cns_table2.pdf": "53899710-EEF5-47F8-971A-A9170EB82B3B",
    "crani_cSDH_mfi5_cns_table3.pdf": "390D3C35-C896-4483-B9C9-6C404A8407CC",
    "crani_cSDH_mfi5_cns_table4.pdf": "B342B9EB-3007-4F03-A234-81A0CC8D1262",
    "crani_cSDH_mfi5_cns_table5.pdf": "75ADBC16-9ECF-4C7C-80AC-E92773E7266B",
    "crani_tumor_mfi5_cns_table1.pdf": "3AC698D5-08B9-4E5E-9069-49070D486424",
    "crani_tumor_mfi5_cns_table2.pdf": "B6361FB6-9AE5-4556-AA3B-0438132D0317",
    "Cranio Poster Section.pdf": "977D2173-9DC4-4FBF-8B5A-52F2440D6553",
    "Craniofacial Pain SCS Poster.pdf": "36016383-D50F-41C5-BE60-8EE4B4031396",
    "Craniopharyngioma Outcomes Poster.pdf": "09C1318B-A1CC-4B6A-BBBF-9DAD67883713",
    "Craniopharyngioma Poster.pptx.pdf": "9DC75C52-5D52-42E0-98CC-1491181F724F",
    "Cranmer_Mia_enrollment_letter_08-23-2023.pdf": "405B4B89-3327-42E2-ACD1-07133C76750C",
    "CraNovel_INTRACRAN_Slides.pdf": "FEF45A6F-45BE-4FF6-8E5A-6A0DAF743B62",
    "CREDECIAL UPAEP IRVING.pdf": "F297686E-437E-428B-BC0B-F808EDD96ABB",
    "credencial CNS.pdf": "33D5B6C6-2E41-4C1B-9863-935060E20D57",
    "Creighton Verification of Attendance.pdf": "292DAD34-10FC-4ECD-B489-4D2CC4B30D95",
    "CreveCoeur%2c Travis_CNS_KNC_013018.pdf": "5727E007-B114-4F24-9C0D-A08EAD7929EA",
    "Cristian Luciano - CV 1.pdf": "53D76611-A0F6-482B-BD79-702309D6E5A5",
    "Cristian Luciano - CV.pdf": "D1F57DF3-CD4C-4EA1-83B8-6D7FFF140AD5",
    "CRISTIAN PEREZ CARRILLO-Verifletter.pdf": "B2EF9B6A-F400-4799-8AF6-D235FD69F73B",
    "Critical Issues in the Diagnosis and Management of the Adult Psychiatric Patient in the Emergency Department.pdf": "E7C01572-AD83-4479-826B-B9F09C929F24",
    "CRM NEUROCIRURGIA.pdf": "73B372FD-1F5B-4A0F-970C-4564371BE23F",
    "Crockard, Alan.pdf": "11AC3755-74AF-4F9E-8AE6-B0102EED1107",
    "Crowell.pdf": "6892F5DC-0DF9-44C7-A43C-740FDCFE32E6",
    "CRPS tables.pdf": "176C106A-00AF-4FD7-8DF7-0F58D3CB8275",
    "Cruickshank. RR CV.pdf": "B0BCC4D4-A864-4B2B-9785-17E382E7E1BD",
    "Cruickshank.RR CV and Verification Letter.pdf": "8D0162FB-C906-48D9-BE61-9332AE155D49",
    "Cruickshank.RR Verification Letter.pdf": "F0E834B2-96D2-42A2-BB44-088B790E181E",
    "CRViewer.aspx-2.pdf": "97BB9BCF-4220-4655-A79D-EDF0F02BBC5E",
    "CRViewer.aspx.pdf": "19C28DA0-C076-44FE-8424-B53785B10D45",
    "CSadegh-emailverification.pdf": "CA1EDB92-F221-4382-95C0-6C3ED8C95D64",
    "CSarris_email.verification.pdf": "A2A2601F-99B3-4C13-8523-ABB9CB7E2710",
    "cSDH complications_readmit_figure 1.pdf": "3CA382D7-28C3-427C-9A3C-6DBC16335474",
    "CSDH model CNS digital poster.pdf": "C072C42E-A7FB-4184-8B6A-B72E80094126",
    "CSDH+AC poster (5).pdf": "254F8994-0C4C-4D0C-A337-72E061470BFC",
    "cSDH_CNS (1).pdf": "738C6940-BB73-4203-8C02-39C434EFCC29",
    "CSM_Driving_Poster_CNS.pdf": "3A9BF4E3-F1FD-4BA5-A8FA-96C03DFC914A",
    "CSM_Education_Poster_CNS.pdf": "58487798-2266-419C-BF6B-504B5B85D82E",
    "CSNS - Biosketch - Ryu.pdf": "F7855B1F-4859-4E40-8226-81B120B9C4F0",
    "CSNS - LOR - Kelly.pdf": "9FF28FC5-CA40-4BD4-87D2-61807965761D",
    "CSNS - LOR - Ryu.pdf": "F640BFC6-71D3-4F4F-B177-F2332EEBCBAE",
    "CSNS - LOR - Wright.pdf": "3E85E138-D4A1-4DEF-A91D-6C7768D6D702",
    "CSNS CNS Letter.pdf": "1BDA4809-DE73-4139-8414-81A128A8343A",
    "CSNS LOR Johnstone 1.pdf": "33B631C0-B35E-4515-AE29-1A15136185E3",
    "CSNS LOR Johnstone.pdf": "5DD59B74-CBB1-4107-ACA4-FEC7400ABFF7",
    "CSNS P1.pdf": "527EA6AB-5EDD-4DDA-962D-461224609D28",
    "CSNS_VB.pdf": "C074E9D3-EFD7-4383-8CA2-F2E7CA232FC0",
    "csnsRulesandRegs.pdf": "46E377FF-7D95-496E-AA1C-118E59A500B9",
    "CSRS Feasibility of Robot CPS_BK1 Tables.pdf": "A3210FF4-C8EB-4FFC-9457-4A1FCC64CB64",
    "CSRS2020_Fig1.pdf": "3F24D1C7-DA7A-4DF9-AB03-463BB83860FA",
    "CSRS2020_Fig2.pdf": "45404BC9-DD3F-41A1-BD7C-4ADE9E5F3F36",
    "CSRS2020_Fig3.pdf": "A93BEBF1-BE5B-48EF-8BBA-3158ED69DDEB",
    "CSy-emailverification.pdf": "28A472EB-5109-4595-88FA-E9FAFA88BDAE",
    "CT Dexa CNS 2019 Poster Awais Vance .pdf": "8084B20B-C772-4B8A-B2A7-B844C58C69A6",
    "CT head 1.pdf": "C252E5F2-88D5-4B75-B656-3DD071A51589",
    "CTHead2.pdf": "347CDDE4-C0F8-4D79-863F-1B8DEB9E23E9",
    "CTSTreatmentGuideline.pdf": "C3614F9B-84EE-438E-A6EA-839FBE8C75B9",
    "CTSTreatmentGuideline_0.pdf": "A8A1F4A2-2F6B-4033-A40A-8577C9C9E788",
    "Cua_Santino_LOGS_M3.pdf": "CB9ABF4C-4AF6-420C-BC85-B66FC17C33D0",
    "Culjat_Letter of Good Standing 1.pdf": "C7643778-94EE-4E93-ACF3-B1FBAD9EF6A9",
    "CulverLaurenG[24].pdf": "9D1A416D-1858-4C11-B253-C01A01D90936",
    "Cummock, Matthew LOGS.pdf": "C7AE853A-024D-489A-B34C-A170F4902A00",
    "cur.vit.pdf": "05F209C5-8B47-4426-AA17-C768C9707C1C",
    "CUREPoster.pdf": "0E0DF56B-AF3B-4E96-BF36-ADA43BD40623",
    "cures_house_passage_press_release_11.30.16.pdf": "0D1C55FA-5207-4158-91D1-7E8545FCBD94",
    "Curley Kara_AAPC CV_01-20-21.pdf": "3B6AC5C3-D3ED-4FCD-ACA3-58C60ACF6BAE",
    "Curley_LOGS_Conference.pdf": "3D304456-E663-47C2-9066-3B08680EB3C1",
    "Current MD Enrollment Verification Samantha Lahav.pdf": "993FD40C-D45A-475F-BECF-F6876448B984",
    "Current Subcategories of AI Application in Neurosurgery.pdf": "7E2951F7-3AF0-470D-A1D5-01F19515F9B4",
    "Curri´culo do Sistema de Curri´culos Lattes (Yuri Estevam Bandeira).pdf": "CFB8FA7C-A7CD-4705-9E45-BF24869BFF89",
    "Curri´culo Lattes.pdf": "8627CC65-18C8-45D3-AA8D-07E6D2ACE757",
    "Curriculo - YURI.pdf": "07C831BC-0299-487A-AE5D-4A8D21EC4532",
    "Currículo do Sistema de Currículos Lattes (Lara Raissa Cavalcante Malveira).pdf": "AFD35BD2-F2E8-46B8-8C36-38478BE73B74",
    "Currículo do Sistema de Currículos Lattes (Luiz Roberto Aguiar).pdf": "67925881-6592-4BFE-A0B1-A34371193715",
    "Currículo do Sistema de Currículos Lattes Moysés Isaac Cohen.pdf": "7BAFE3D5-06CA-40D4-BB6B-E622DB65A155",
    "Currículo Lattes _ José Lopes_ Neuosurgery .pdf": "D83EA819-1222-4277-AC33-22844982214C",
    "Currículo Lattes.pdf": "1132BF13-D2A2-4D34-A348-846EAFB8D152",
    "CURRICULO-2019.pdf": "E94948CD-D81D-429C-BB74-D614EECC557B",
    "curriculo.pdf": "DCF6F8EC-0494-4570-B54E-FC5490F32CFF",
    "curriculo_ISCAL.pdf": "4902D7C3-6F93-4B30-8466-677773C2EDFD",
    "Curriculum  I Mena 2 (002).pdf": "DB9A876F-414C-4910-B097-062E508EA70E",
    "CURRICULUM (2).pdf": "D96375E4-3985-4981-B03A-933D483F747A",
    "CURRICULUM - MAURICIO MANDEL - ENGLISH - CNS.pdf": "ABAD652D-F53A-43E0-8C5C-CDE6798463DF",
    "CURRICULUM 2.pdf": "322FF5A1-2183-4029-84D4-EA2572677527",
    "CURRICULUM 2022.pdf": "C0BE81A2-5E74-4089-B3D9-98F8136DE70A",
    "CURRICULUM AEMDP AGO2015.pdf": "D7D5C25C-199C-4DA1-99FB-10873797B9CB",
    "Curriculum CV Dr. Alejandro Villasen~or  .pdf": "FDD9516E-900D-4E7A-B872-61C38B92DE59",
    "Curriculum English Dec 14.pdf": "7C2F1A73-DB4A-443E-B542-601FDEE2C56A",
    "curriculum gustavo -cns.pdf": "7A120564-631B-46BB-8169-7197023C3B70",
    "Curriculum Ingles Luis Contreras.pdf": "FC33DC89-26A7-48D1-A353-3A22475EA344",
    "CURRICULUM ingles23.pdf": "406402DC-28A9-4A3B-AB05-1E90AD41C594",
    "Curriculum JD.pdf": "BB399628-E70E-4346-A3B2-5CA583F2568C",
    "Curriculum System of Curriculum Lattes (Rodrigo Antonio Rocha da Cruz Adry).pdf": "9A6B7C68-9D08-4E3C-B38E-7CFBED214D11",
    "Curriculum Vita ghaya 8 2015 CA.pdf": "45C33DB7-317A-4DF9-9B12-22A471F2C1F4",
    "Curriculum Vita_Hoang_08012022.pdf": "207AA592-3237-44C7-A805-6D6BCC7948FE",
    "Curriculum vitae   Xin Yu V2012.6.17.pdf": "9D4ED89B-DE93-4D5E-92CB-FD9754E5FAA9",
    "Curriculum Vitae  2.22.21.pdf": "1D698702-495F-4E83-96B8-F6C64DF68110",
    "Curriculum Vitae  2.8.2020.pdf": "FF3FB7CE-7218-46F9-A69F-88E11B7006B6",
    "CURRICULUM VITAE (1).pdf": "4D0CAFEB-96D2-4BF1-9845-24DA6668854B",
    "CURRICULUM VITAE (4).pdf": "540676C5-BF46-4CFB-AF5A-1628157C9C61",
    "Curriculum Vitae (edited 16th Jul 2020).pdf": "E0D6F9AA-CFC0-4074-9CF9-F5CDAB217C21",
    "Curriculum Vitae (edited 1st Feb 2021).pdf": "92D1CF75-80CE-4B4D-A7F8-9799DF474B07",
    "Curriculum vitae (HFA).pdf": "B0B0E53F-275C-4318-8806-92D4656109C9",
    "Curriculum Vitae - 2.pdf": "B7650DE6-CA53-46F8-82F2-F647552FEF8F",
    "Curriculum vitae - Alexandra Santos.pdf": "55356912-BF4B-4857-869E-59C073F0EABE",
    "Curriculum Vitae - Ariel Agur.pdf": "10C592DA-BAC4-4E82-8207-548599307F75",
    "Curriculum Vitae - CNS.pdf": "B7B0FFE3-C90E-4167-9B26-3621F5B97F9F",
    "Curriculum Vitae - EL Guilliams.pdf": "A2563D99-1C6E-4C30-B8C7-BEB68BE6B6E1",
    "CURRICULUM VITAE - ENG.pdf": "356A8FDC-555D-4424-85F4-6E7C157E1A1F",
    "Curriculum Vitae - Felbaum- 05-2023.pdf": "89119C24-112F-48C4-A7E1-22A268FE2EEF",
    "Curriculum vitae - July 2023.pdf": "A19E0FCA-1565-4D9B-8C93-8CC5C110D05C",
    "Curriculum vitae - new.pdf": "84EB9FC7-35D4-4BCC-B5CC-54FF754A65ED",
    "Curriculum Vitae - Nimer Adeeb .pdf": "91C626AC-B492-41A4-B188-370FC91CA83E",
    "Curriculum Vitae - Nimer Adeeb.pdf": "162850D2-DFCB-4708-9B74-34EBA82926E9",
    "Curriculum Vitae - Nitin Agarwal.pdf": "964D64A2-F384-4DB1-8EF7-45C70941570B",
    "Curriculum Vitae - Roberto Garcia-Navarrete.pdf": "C6168972-23B7-46F1-BDA5-C8A4D8BAA444",
    "Curriculum Vitae - Tony Anene-Maidoh May 2020.pdf": "6B56D8AA-6212-4084-9664-FADA51A2070E",
    "Curriculum vitae .pdf": "A31361C7-9648-44FD-A69B-109F802438AD",
    "curriculum vitae 17.05.2024.pdf": "746BD321-CBF5-4AD5-8645-B3D4671AFED3",
    "Curriculum Vitae 2.pdf": "79A02777-7780-4F91-9770-D2D5FFF72A8B",
    "Curriculum Vitae 2011.10.pdf": "483EE6AC-8D2B-42D8-BB79-EA893E258086",
    "CURRICULUM VITAE 2018.pdf": "12F30A7E-A40E-47F7-9236-ED064F58D475",
    "Curriculum Vitae 2020.pdf": "616CA62E-970F-4AD7-84A6-DA04AAF9FF19",
    "Curriculum Vitae 2021 CNS_Agarwal.pdf": "2A619EEA-7E56-40D0-B7D3-35945591A5A3",
    "Curriculum Vitae 2022.pdf": "4608ABC0-4102-4A10-9740-36E810D9BFA9",
    "Curriculum Vitae 8-25-16 Personal.pdf": "830200D2-700F-4673-990E-9F74FFB06259",
    "CURRICULUM VITAE AM.pdf": "0D08AABE-D826-4E3A-A52F-DFE522CC12EF",
    "CURRICULUM VITAE AND VERIFICATION LETTER OF DR. ANKUR B. PACHANI.pdf": "3C2B9977-8664-48D9-8307-1DA9D24CD219",
    "CURRICULUM VITAE ashish sonig july 2022.pdf": "0C786178-BD45-47C9-A847-5903A8CAC5A4",
    "Curriculum vitae Aug 2022.pdf": "CFA3A015-4B39-40FD-8A1B-09B44F108231",
    "CURRICULUM VITAE Benjamin Thomas.pdf": "27A965A8-660B-474D-9E4B-25F6BFCA1A7B",
    "Curriculum Vitae CNS..pdf": "E80DF066-5C2F-4CA4-8192-2C19022458E4",
    "Curriculum Vitae Dimogerontas George.pdf": "15889CEC-775A-4CC1-A94E-8B817C8604B3",
    "CURRICULUM VITAE dpaul 2022.pdf": "4E869DDD-F8B8-4B4A-B69D-6B9C8B5B142E",
    "Curriculum Vitae DR EMEREOLE.pdf": "54517D68-7D6F-4CA4-BC05-D88B57C0D41C",
    "CURRICULUM VITAE dr.sneha chitra.pdf": "CA0C18A3-8686-47B6-A03F-7547C0B0EAA4",
    "Curriculum Vitae ENGLISH 2010.pdf": "E85C5F70-76E3-419B-B80F-120E20A562A7",
    "Curriculum Vitae English 2019-2020.pdf": "A7457C20-3212-4228-8507-6A9EB0C010E9",
    "Curriculum Vitae English.pdf": "354F24E4-FFAD-4372-B352-4215CB1A6F25",
    "Curriculum Vitae Felipe    Wainer.pdf": "85B79ECD-6869-4EF7-BEC4-B5E395CA05D1",
    "Curriculum vitae fellowship.final pdf- Copy.pdf": "7F3898C7-BB36-4644-B9F0-ED6F03BAA1E5",
    "Curriculum Vitae for Dr. Henry Obanife Olayere.pdf": "6E82CAFC-7F64-4FA2-ACAC-E3FF7D28E8A4",
    "CURRICULUM VITAE für CNS.pdf": "141B4D05-E31F-4147-912B-703554451A40",
    "Curriculum Vitae G. René Alvarez Berástegui - English M.D.pdf": "3C677367-F3AB-4771-8DA1-2508CF758FB2",
    "CURRICULUM VITAE ingles July 2018.pdf": "1B262BFD-C680-4274-9844-8C6207FC71CE",
    "Curriculum Vitae Julian Lin.pdf": "45CA5C8F-C75E-4AAC-A77E-73789D6A82DC",
    "CURRICULUM VITAE julio 2011 simplificado.pdf": "618EFE17-B17B-4D93-9567-D45983380483",
    "CURRICULUM VITAE Kuhn.pdf": "A6C63708-6ABE-45BE-A2C3-49C00443598E",
    "CURRICULUM VITAE LOUIS VALERY.pdf": "34179F8E-9752-4F9E-B119-2827D0C234FE",
    "Curriculum Vitae Mani Sandhu.pdf": "2CFBF740-17B4-4A7A-8328-00248549E906",
    "Curriculum Vitae Mnguni - Latest.pdf": "265E5F8A-F121-416E-9293-CBB94ADF268C",
    "Curriculum Vitae mychean.pdf": "024B602C-8C1F-496D-9464-B05FD2915160",
    "CURRICULUM VITAE OF DR. ANKUR B. PACHANI.pdf": "7165FF75-32DE-4928-A19E-08A53CB95EC0",
    "CURRICULUM VITAE Owoicho Adogwa 1.13.2021docx.pdf": "56A9A4D6-AE47-45D3-BF8A-C20C3A3912A2",
    "CURRICULUM VITAE Owoicho Adogwa 10.27.21 (3).pdf": "FD243CCF-CCD8-4CD7-B7E2-08B4F674404B",
    "CURRICULUM VITAE Owoicho Adogwa 12.6.19.pdf": "355355EA-D3C5-4A20-B975-3DAEF87ED55C",
    "CURRICULUM VITAE PDF.pdf": "C954A705-360C-4A8E-86FC-1B979FB2905E",
    "Curriculum Vitae Raad Omar.pdf": "D71C762E-4369-48D1-B146-0A348DC16D15",
    "CURRICULUM VITAE short english CNS.pdf": "97F58994-1760-47BD-8C09-D3E23934E586",
    "Curriculum Vitae Stefano M. Priola .pdf": "BCE8AEC2-4491-4488-ABEE-12C8F2269AA4",
    "Curriculum Vitae tarek mohamed.pdf": "74F41CFF-4080-470B-ABDC-5C9A805D4A91",
    "CURRICULUM VITAE VERIFICATION LETTER OF DR. ANKUR B. PACHANI.pdf": "577A5574-9B12-4CDC-AEEF-361B8BFF587A",
    "curriculum vitae W  Stummer engl 2024.pdf": "062EE8D1-4008-4030-ACD0-9858681365F2",
    "CURRICULUM VITAE Wayne Paez 2.pdf": "D353A7FF-0696-4F91-8918-EDBF75A49166",
    "Curriculum Vitae Y. Temel 2010.pdf": "A8747E5C-CC39-4A78-9EE4-F5190D8163B3",
    "CURRICULUM VITAE YaghiNK.pdf": "D1E64F96-7D48-4586-AC5C-BC3B750C5D90",
    "Curriculum Vitae Yuki Shinya updated on 20230403.pdf": "979CD929-B8A0-40BF-9EF8-DBAA6C7099A7",
    "CURRICULUM VITAE--Jeevan Sharma.pdf": "2379B821-4973-4FB5-8DBB-1DCCBABEC544",
    "CURRICULUM VITAE-2020.pdf": "80586D92-64B0-4247-A91A-069A2DCF06F4",
    "Curriculum Vitae-Daniel Ikeda-01-29-2022.pdf": "34252477-78FE-4333-8497-3BF6E3BB2847",
    "Curriculum Vitae-Mekdes Awano.pdf": "EFA8E7E3-A217-4957-8796-628BDE89B62C",
    "curriculum vitae-Mohamed Badran.pdf": "446D0CB7-14E8-45A6-907A-F7B3F00A0F6A",
    "Curriculum Vitae-Ricardo J. Ferna´ndez-de Thomas_Updated.pdf": "232FEE18-11F6-4785-A5CB-679B31F01355",
    "Curriculum Vitae.docx (1).pdf": "96F3AE05-2ADC-4942-B5A8-66EDF2830AFF",
    "Curriculum Vitae.pdf": "94CDC69F-CE30-44F1-94FE-11C8C0918E36",
    "CURRICULUM VITÆ.pdf": "8182BCB2-F0E8-49B4-827C-695D7BA45A54",
    "Curriculum Vitae_Agarwal.pdf": "D878BBB2-8E30-4B08-8588-505C64470F13",
    "CURRICULUM VITAE_FEBRUARY 2011.pdf": "BDAE4BE5-04C5-424D-A3CB-F61C73535F9C",
    "Curriculum Vitae_Nwachuku_Enyinna.pdf": "DCD1A942-82E7-41D2-8CEB-1489896DCF6A",
    "Curriculum Vitae_Orina.pdf": "ABE7F80A-AAFA-49E1-A006-BBE641C4B03C",
    "CURRICULUM VITAE_Sept 2023.pdf": "DA45FD95-8486-49A6-9DBE-D0B1FE03B0DD",
    "CURRICULUM VITAE2.pdf": "00FB0C3C-533F-4EBD-928C-D5E31FBBE5E0",
    "CURRICULUM VITAE2012Larry G.wpd.pdf": "E7058BE9-BC49-4799-970B-C6AC0CBFDDF2",
    "Curriculum Vitae20170122_CNSmembership.pdf": "C182CAC9-3014-43E6-BB2D-7E572691DE2E",
    "Curriculum-Vitae-Sanusi.pdf": "25B9BF1F-1A25-4934-9171-10E4CAE9F303",
    "Curriculum.pdf": "3D3AF266-8628-420B-8871-1A48D669CEC2",
    "Curriculum_Vitae_Joda.pdf": "E719A3E0-2037-4C26-80F2-5BC7116C9F2A",
    "Curriculum_Vitae_Sotirios_Apostolakis.pdf": "0778D10F-3B45-4687-82FA-F9070B96410B",
    "CURRICULUM_VITAE11.pdf": "65034758-D103-4814-A588-746CEAB6C5C3",
    "curriculum3.pdf": "58D79946-5FAC-42AE-8863-47AF592911A6",
    "CurriculumVitae.pdf": "E51BDE1C-471C-4C03-A259-31CD7A96CC5A",
    "CurriculumVitae_2023.08-Jennifer Sokolowski.pdf": "6FF0C485-29BD-43B7-860E-A415C640C10E",
    "CurriculumVitae_KiritSingh_Jan_2021.pdf": "53BBE714-8D20-495C-AF96-2CF5D954EF11",
    "CURRICULUMVITAECesarCimonarideAlmeida PTBR 17-07-13.docx.pdf": "DF3E789D-D275-46E1-9059-D35F76EDC758",
    "CurriculuVitaeLHRS IMSS neu.pdf": "AAEEA069-C599-4C59-B2C7-A35D1A599B6B",
    "CurriculuVitaeLHRS IMSS.pdf": "7A2DA1BA-AD41-468D-B1DE-F1D3B1A657CE",
    "curruculum Vitae luis Felipe Checa CNS[3.pdf": "1E0A56FE-9E3C-4F8F-86DF-AAA04AC6C28C",
    "CURRY_1261.pdf": "EEC3CE1C-E7F3-4FDF-95CB-B220053C300F",
    "Curry_Brian_CV_current 2.pdf": "6A490809-8D99-44A0-8CD0-2BCE0459B745",
    "Curtis-residency.pdf": "FA9F3419-45A4-43F8-BB0C-BC1732B46B20",
    "CUSA New Neuro Indication News Release_FINAL_072220.pdf": "70C49A14-5F3D-482E-AC60-CD9ACBF8B273",
    "Cushing abstract.pdf": "AB459DC4-F20E-46F5-B6B6-41B0968EE856",
    "cusmdegreeaudit.pdf": "50C1F948-98C4-4756-8CC5-1B09F09A13C1",
    "cut and send for biopsy.pdf": "6A485566-F087-4222-92BE-260FE3B2BA51",
    "Cutler Christopher CNS.pdf": "E176869B-7069-4E7F-9817-9CFA1AF758A6",
    "CV  - JANJUA.pdf": "317A2F0C-D837-487E-A931-B48A150A765D",
    "CV  Daniel Vega Moreno.pdf": "FA4C9E6C-9C73-4DA2-8D60-1F548BF753EC",
    "CV  Dr Salah Akkad NeuroSpine Surgeon.pdf": "327AC31C-C619-4ACD-9C39-2950E2F4DAAC",
    "CV  SM english.pdf": "C3EA6976-7B59-41A5-A2D6-234D9580A5E8",
    "CV (2011).pdf": "3D273E07-EEDF-41AF-A50F-11E274614F83",
    "CV (Dr Nitin Garg) -- October 2021.pdf": "BA991F0F-D273-45C0-B2D8-1C0FF8BE2E34",
    "CV - A. Wright.pdf": "F2CF1DE2-59BF-4E58-872A-B1266E4FD2F6",
    "CV - Ajlan Ali.pdf": "23C38A6B-2F54-4DBA-83F7-68F475F6CE6B",
    "CV - Ali Hammed.pdf": "9DD0B31E-0312-4A55-BF47-A81EB8A3E806",
    "CV - Anthony DiGiorgio.pdf": "29271457-1D48-4DE5-9430-F37F9F511DF8",
    "CV - Bhasin.pdf": "53B884A3-E933-4870-B0A7-3DD2A5DA1D81",
    "CV - Bradley Schmidt 7.2023.pdf": "5F6FA309-A501-42D4-AADC-2E4D6B452714",
    "CV - Chalfant .pdf": "DA6888B5-D06D-4393-AB93-08A1EC3A8B06",
    "CV - Dr Kodali Sandhya M.Ch (1).pdf": "D14A1B45-3AB4-46C2-983C-FA9A33DBCF4F",
    "CV - Dr. Brown.pdf": "F1653DCF-69D7-4641-BE31-598FDD603DB6",
    "CV - Dr. Hassan Bin Ajmal.pdf": "7A37E8D6-3E37-4AB0-BC5A-2458D52F77AE",
    "CV - EKOERMANN NYU.pdf": "351B7AD0-8423-4174-ABFF-092A216EA739",
    "CV - Ferraresi.pdf": "840090A1-B1D5-476C-8B05-AD39C58353BA",
    "CV - Geoffrey Colby.pdf": "7478369A-7DF6-46D7-A36E-DFB929F5305A",
    "CV - Gil Kimchi 2020.pdf": "F9049854-591F-4BA0-8750-80DFE308CFC2",
    "CV - Gillick.pdf": "BDE53301-0AB2-4B4B-8FE9-4113E2AC5DA3",
    "CV - Hael Abdulrazeq.pdf": "B90D66BC-005C-47F7-9B56-C420B8A7079C",
    "CV - Hamid B..pdf": "5FB700D5-F66C-4D60-8A91-5480E1353DE9",
    "CV - Hengenius.pdf": "B2E7190A-B9C9-4161-9FED-C747461EB1ED",
    "CV - HicksWH - 2024-05-01.pdf": "C858E875-6BA4-4E47-9A00-37EDE2D7738B",
    "CV - Hilliard - 2021.pdf": "4D263462-F9B6-4DCF-97BD-14C6B6D9C9E5",
    "CV - Jordan Petitt - 4-13-23.pdf": "B6DA2EA1-23CF-4DD7-A358-2F7B3BA03D3E",
    "CV - Kyle Mueller.pdf": "6E3F4CF9-C3BF-45BB-8F35-67A2F511F40D",
    "CV - Leonardo Tariciotti-2.pdf": "D2279E20-CC8F-4DDC-A14C-BF91B6E724CE",
    "CV - M Acocella.pdf": "D43D519D-C2C4-45CC-B6E4-01079938EE7F",
    "CV - M. Eppinger - 08.24.2015.pdf": "BA3479EE-09D4-4514-8CBE-73ECEB8D349B",
    "CV - March 2024.pdf": "F15EC76C-2AD0-4595-BBCD-BF29E984BA10",
    "CV - Markus Holling - 09-2023 .pdf": "90BD733A-CD4B-4B8A-8E3C-F4368B4D6C90",
    "CV - MCloney.pdf": "2614ADC2-74C2-4849-896E-AA77B0713FA9",
    "CV - Michael Condell.pdf": "9666919B-D0CA-4CAD-97EF-FC75C31955BE",
    "CV - Mohamed Salem.pdf": "193EFD59-ECD7-43A8-B1B8-0B9C2B6FC98A",
    "CV - Nicolo` Norri.pdf": "8CD0484F-EE98-4A44-BF9D-B7571498561B",
    "CV - Nouri.pdf": "2233E439-9750-4C00-81C1-E15CF41C08FC",
    "CV - Opoku-Darko Michael.pdf": "FC261381-570A-4992-93FB-AE493E46C6AA",
    "CV - Or Cohen-Inbar 300717.pdf": "288FF8BF-704B-402D-A0E3-052623739622",
    "CV - Patrick Kelly - 2023-07-20.pdf": "1D456FCD-66A7-4105-8922-F376986068AF",
    "CV - Rafael Vega MD PhD - 2019 BIDMC.pdf": "65F40484-4FED-4DAB-8F1A-CCCB5B500CD2",
    "CV - Rafael Vega MD PhD - 2023 BIDMC.docx.pdf": "C62F7E15-4268-49E4-9922-156C33F4D85C",
    "CV - Rajendra Rao.pdf": "58447B48-D60A-438B-ADC0-8BD965B40013",
    "CV - Richard V. Buonocore M.D.pdf": "9C414F43-DC4E-4557-94FC-2DDEB1C21243",
    "CV - Rodrigo Manuel Bra´s Batata 1.pdf": "32D97CB1-9EF3-49B0-BB64-94A53481566A",
    "CV - Rodrigo Uribe Apr 2024 English .pdf": "B945CC46-CF5B-4001-9E1D-6E8CC9037DCC",
    "CV - Rodrigo Uribe Nov 2023 English .pdf": "3C33AFEF-2B7B-49BD-89D6-D27FD4F5D436",
    "CV - Ronnie E. Baticulon MD.pdf": "BAA56C7F-F97E-4474-8951-3E3B52BAF680",
    "CV - Sebastian Pavel (002).pdf": "22437FEA-2A1C-4CAB-B61C-40525CB26DA0",
    "CV - September 7 2020.pdf": "753DD721-0666-4BF9-B3D6-9C458CE9A376",
    "CV - Shayan Moosa July 2022.pdf": "96D29127-87DF-4E86-AAB8-06B28E4CB89E",
    "CV - Steven Kiss.pdf": "6E3EF593-0649-49F1-8BCF-6534C8D11219",
    "CV - Tharun Krishna - Jan 2022.pdf": "95EA3080-81FA-4175-A5B0-3F3411CB68D9",
    "CV - Tobias Mattei.pdf": "2A275890-A56B-4D47-8261-2A4E93E47188",
    "CV - UM 041422.pdf": "F9631876-0C55-4D1D-A125-3F30D5FF35EB",
    "CV -- Shahlaie -- March 2022_Mor.pdf": "30E32FCF-296F-4552-AA19-4966F8F127AF",
    "CV -english 2015.pdf": "44D3F740-E3B8-4929-B0FB-AD440E261430",
    "CV -Turkmani.pdf": "63728B7B-8115-4C7C-8A18-CEE89234491F",
    "CV .pdf": "B72FEEE4-43EC-43A8-8FE9-44C8783791B4",
    "CV 002-AdamAmmar.pdf": "E36D8757-F426-49F7-9083-A130E9081999",
    "CV 03_2019_CNS.pdf": "5B763802-F67C-4F53-977A-8EA770AF8C53",
    "CV 06282022.pdf": "7F6BE4E4-111A-4115-9CE9-9B707F2E96D4",
    "CV 1.pdf": "C245141E-6D79-49F4-8549-0C728C69377C",
    "CV 100523.pdf": "B024A11F-B1F1-493E-B7B5-45B8FF612AC3",
    "CV 11.04.2016.pdf": "1B711C07-A991-4F5D-9C50-184E7AED6C05",
    "CV 12-15-2019.pdf": "CC298708-4F7E-42DF-AD26-3991BAEF7CA0",
    "CV 2014.pdf": "F54F6CD1-A673-40A9-85BB-386E19ACDA66",
    "CV 2015.pdf": "7941FAFE-9FAA-4D7B-8235-A7CE2B2A8A5F",
    "CV 20150821.pdf": "3CE41A32-BAEC-4209-B488-7F683C27F0CE",
    "CV 2017 07.pdf": "16790B08-78D4-473C-A2AE-42A6E04548C6",
    "CV 2017.pdf": "D3CB762F-DB7F-43EB-B567-980DA723C81F",
    "CV 2018 .pdf": "85EBDD1C-5A54-4812-A4EE-63366D6CF18A",
    "CV 2019 - Almeida.pdf": "E683BFA8-5988-4657-BA1C-DD1BFF4E0F3B",
    "CV 2019.pdf": "7D1E403D-978F-442B-9692-1AFE85349908",
    "CV 2020 copy.pdf": "CB521F66-770A-4FFF-801D-151B1E97FEF7",
    "CV 2020 Derek Orlando Pipolo English.pdf": "192ACDFF-7EB5-4B15-8214-5F0113E1B895",
    "CV 2020 GSMC pdf.pdf": "F673D26A-756E-44F3-9126-7BE1879E7092",
    "CV 2020.pdf": "E1D41FEC-9A28-40C0-B6D3-226CDF15FB16",
    "CV 2021.pdf": "DEC7D8D2-A6CE-4F06-B798-9C7523D110CE",
    "CV 2022 09.pdf": "2016D073-89F1-4757-8428-20E4BCE90782",
    "CV 2022-2023 AH.pdf": "08D29F7F-3B74-4858-9773-426A653F7181",
    "CV 2022-John Hawkins.pdf": "BE382BB8-6682-448A-86C9-7FB8DEB9F3AF",
    "CV 2023 - Ross Green.pdf": "41AF878A-889A-409F-8CFE-2992C0CD3A6E",
    "CV 2023 IN-Rafael Aponte.pdf": "B00A225E-920F-4F9B-B88D-13176FD175C4",
    "CV 2023.docx.pdf": "0B68159D-1AC5-4759-B2FB-56EFFBF3845A",
    "CV 2023.pdf": "13D949A9-4FF5-4FFB-8F70-421BE7AD767A",
    "CV 2024  Matassa Patrone.pdf": "9C3C273B-A2CE-4E07-A914-D50565A09087",
    "CV 2024 Beladi Roxana.pdf": "7942A7BA-D795-4D46-A7CE-2A247DD2DD30",
    "CV 2024.pdf": "ECB46BD4-00F0-407F-8583-4450D26D3B5E",
    "CV 24.pdf": "62E59EAC-F3EB-414F-A47C-7A0831825974",
    "CV 240317.pdf": "D46E8E86-984F-4B07-81FC-C3333A5FEAC5",
    "cv 3  Bangash.pdf": "B704973F-5161-4B02-9DDE-60E40FBBDCF8",
    "CV 3-31-2019.pdf": "130FB5DD-211D-4F5C-9B43-921F9829F73A",
    "CV 3.30.23.pdf": "A2006E73-59B9-4B46-B0DB-3230AB51E3F0",
    "CV 424.pdf": "10E9F1F4-4742-421A-A988-3B9900215A58",
    "CV 5-6-2023-Ryan C Turner.pdf": "2A90D0B3-A59B-4FC0-9D43-0793453DAD6E",
    "cv 8_2020 6.pdf": "1C20A23A-9B8C-4CA0-98A2-5641AB62FCA0",
    "CV active_.pdf": "417F9DFB-5351-4EBD-AC73-5B27DC338DEF",
    "CV Acton.pdf": "5C1F0581-B159-451C-A99F-90D8361221C5",
    "CV AEM.pdf": "1438BB9C-8D79-47B9-85B9-115A019F9AC1",
    "CV Ahmed Belal.pdf": "EA9AF2F8-2F0E-47B2-96C0-3C502020E556",
    "CV Ahmed Helal 2024.pdf": "AB5F6940-C3AE-40EF-8C34-DBDE15F59DEA",
    "CV AI.pdf": "8EA3835C-B86F-4266-B8E5-F8EEB588A7CB",
    "CV Ait benali Morocco.pdf": "51B042B0-B726-4CE6-8603-2C92D8F2EB69",
    "CV Aldakkan 2022 .pdf": "0E51EC08-3452-4A90-A9C5-EA8EC59EAD56",
    "CV Alexander J. Gamble, DO.pdf": "E0FD04E8-A81F-48DF-9F5F-238B18189E68",
    "CV Alexandra Gomes dos Santos dez_2022.pdf": "94A8CE64-CC80-46E4-BE1F-02ECDBA1057E",
    "CV Ali Alaraj. December 2019.pdf": "52D1EE72-B442-48D3-B48E-DC86101D8A75",
    "cv almaz kurbanov.pdf": "60AFE21C-A04D-49AA-97BB-30EAA67848C6",
    "CV Alvino De Leon-1 copy-2.pdf": "3FB270E2-E226-4141-BA1D-808BE4E8465F",
    "CV AM.pdf": "F073C220-8DC5-432B-AB03-31DD450FD1D5",
    "CV Ammar Hamami Hawasli MD PhD_July2017.pdf": "E1E8BC23-A9BC-4A20-976F-0AD1483AF95C",
    "CV Ana Sofia Alvarez.pdf": "4C2EAD55-4C40-4458-A68A-667A30804855",
    "CV Ananth Vellimana 10242022.pdf": "1EB28A82-7C0E-4875-A06F-BACFCE1ECC19",
    "CV and Letter.pdf": "59FDE483-F9E3-4F1C-89CA-CBAF80DE43CB",
    "CV and Verification Letter.pdf": "EACCF76E-397A-4344-B984-7EDE647424D3",
    "CV ANDRE SIMIS 2017.pdf": "AE8F347D-F634-4D2B-9144-C00C7BAB43D2",
    "CV angelo tavani.pdf": "BC8C03BB-9178-442A-920B-244B641FA9E3",
    "CV Angheluta.PDF": "7D991780-C6EE-4370-BD1B-1E84DF5D2509",
    "Cv anglais apres.pdf": "308F6E8D-48C5-4262-BE84-DA07C62ADE61",
    "CV Anton Konovalov 2021.pdf": "80D2303C-6FE0-4F5A-B044-F3F34A00BFD9",
    "CV August 2020.pdf": "32F3A253-62B0-4646-A89E-331D71E7BE2D",
    "CV Benjamin Okanga.pdf": "AA7ABE38-6268-4DC6-9DA5-EDF9BFF838E7",
    "CV Bettegowda October 2022.pdf": "1C7C5190-AF41-449D-84D4-2CB11BE15ED9",
    "CV Boucher- March 2021.pdf": "197A6A24-1126-4F04-AF13-13E7AF48A52C",
    "CV Brandon Ruble.pdf": "D4F1FBF7-8DDC-4C8D-BEFB-D0AEA005E391",
    "CV BRJ August 2020.pdf": "93FD4440-801C-4C72-BD01-7803613EDA3B",
    "CV Byron Salazar english jan 2024.pdf": "467C8B57-910E-43BA-9BE6-6579B2B12D1A",
    "CV Byron Salazar english march 2022.pdf": "E8151972-A28F-4AE8-B0B3-7C9739EFF9F4",
    "CV C Son.pdf": "53C041B5-5B9B-46C5-98B8-EE8ECA78F143",
    "cv C. Dumot.pdf": "D59D33B4-38F9-49AB-9F80-919F25D3700B",
    "CV CARLOS EMILIO RESTREPO RUBIO-1-1.pdf": "9CF6345F-48E5-42F5-94E0-3639A94386ED",
    "CV Carlos Perez-Vega.pdf": "9A4B6E7C-31C5-4E2D-BCDC-357C322B4BC9",
    "CV Castelluccio 2021.pdf": "9C861D60-3A4E-4269-AABC-375526F0C940",
    "CV cav mal section.pdf": "FE2EAB1E-4A27-42FE-A156-EA6C1DF3F62C",
    "CV Chad Tuchek.pdf": "30730B8D-DF78-449F-8F67-39E26BED96FA",
    "CV Ching-Jen Chen.pdf": "7343D111-3432-4D23-B317-139851418280",
    "CV Chloe DUMOT english .pdf": "8EDDD8E2-B94B-459F-A0F9-C97ACC7BBA66",
    "CV Christie Bernard .pdf": "9983CDF8-3C2B-4811-9720-600A43CC1333",
    "CV Christine L Hammer 2022.pdf": "77F9B877-9939-4BFD-874C-A3185C460E5F",
    "CV Christopher Wilkerson 2022.pdf": "8C5BC505-31E1-4EC2-A876-FC3938467C67",
    "CV CMSRU 3-4-22.pdf": "1A1327D2-E2EA-4F35-B480-7801FDE3E56D",
    "CV CNS 2017.pdf": "C28E9496-770D-4F25-9879-ECEB809CF6C9",
    "CV CNS.pdf": "4A4ECB10-FA79-4AA4-8A70-2063B5425D0B",
    "CV copy.pdf": "31C6250D-6F71-42E7-92B9-6C3889A13EAA",
    "CV Córdova.pdf": "416990B8-4E80-4ACE-B215-B81781741AC7",
    "CV Crandall (10-08).pdf": "B1DC8EB0-3941-4B29-BFCE-59A9FC49823E",
    "CV Curriculum Vitae.pdf": "07E9B252-5C98-436D-9F7B-513399053AF0",
    "CV Dana Peso.pdf": "5E0F6F65-DF36-41BC-8D42-2500A494D3D0",
    "CV de HUNG 20122023.pdf": "3DBFE8EE-C1C9-4D25-ABFC-286265EE179A",
    "CV de los Reyes.pdf": "E11EF54A-80E2-4337-B46F-23229C685725",
    "CV debbie kantor.pdf": "2E46EAF5-9686-4870-BE90-BE45987BA9F8",
    "CV Dede.pdf": "2D02ABCE-9366-45FE-8FB1-051DBF3D40F9",
    "CV dfbauer 1-9-2022.pdf": "DA1BA58E-CDB1-4E71-BCAB-5333BC45B711",
    "CV dfbauer External 2-14-2021.pdf": "572F590C-8A99-4F11-9F56-89E192172FB0",
    "CV Diana Kraemer MD 2021.pdf": "14EF5D27-7ECA-42D7-9B6C-23A57FFF32FC",
    "cv diego tonathiu soto rubio m.d. september 22.pdf": "BBEFD1A1-F778-4FAB-AFA3-01D7141938F5",
    "CV Diego.pdf": "30C54DDB-FE0C-4F1F-941A-09ECD78C5743",
    "CV Dr Ari Sami Hussain Nadhim may 2012.pdf": "8D8E4412-A74A-42E3-B896-9128AC4B168A",
    "CV Dr Elera Julio23.pdf": "C1DDDE9B-52D9-4A75-9C66-CF1BD290B41D",
    "CV Dr Gaston  OUMAROU.pdf": "4E301921-1A10-4FD6-8F76-08279BB09C56",
    "CV Dr Rajan.pdf": "7D72CA17-FB58-498C-A3F5-5D4342FC1F77",
    "CV dr Venansius Ratno Kurniawan.pdf": "F531CF02-1EB0-45E2-A978-B3030BD971D1",
    "CV Dr. Cristobal Salgado Dec 2015.pdf": "C7675D68-1997-4FF3-9D3A-899E148DDEB6",
    "CV Dr. Rati - New pdf.pdf": "FA2B60DC-2199-42BA-A575-78811B4B8E6F",
    "CV Dr.Jonathan Thailand.pdf": "F38757E5-E8EF-4458-BCBC-500AFB3D5623",
    "CV Dr.Mirna (1).pdf": "F39E462F-9F5C-4B1C-BA32-1E0F6533FCD4",
    "CV DrJennifer Hodurek-Diez.pdf": "0049143B-7304-42F7-8DE4-81A773D4EF7A",
    "CV DrJonathan 2.0.pdf": "3AF1CC94-D1AD-45BE-81F9-BB3986560AB3",
    "CV DSK Jan 17 2024.pdf": "761E1AF1-6C84-47C8-9A8B-0AF40A7A677B",
    "CV DUANE PATTEN.pdf": "DADF5447-C802-4999-A4A9-0F69C3010E44",
    "CV Edison Valle 8 16.pdf": "298D0050-1B73-49F2-8D5C-0E4D6823184F",
    "CV Eduardo Trejo.pdf": "247EB72E-83BC-4AE7-A474-8112ECCFD129",
    "CV EEffio (Eng 1).pdf": "18EAA896-5103-4CBB-86ED-83707545BFB1",
    "CV ELA 2023.pdf": "505AFDD7-96AB-4AC4-93D4-95BD811E1F42",
    "CV ELA 2024.pdf": "F594D940-327E-4D3C-89EC-B45D933D394D",
    "CV Elad.pdf": "D5EEE97C-1EA2-4673-8843-6609E69445DB",
    "CV Elder 1-2023.pdf": "37C7E671-FCA2-4907-B351-2CEA3468BA94",
    "CV Elizabeth Duquette 04.28.24.pdf": "E803149A-A9FD-4D05-BDBD-C8E74351927C",
    "CV Elliane dos Santos Rubio - Dieben 1.pdf": "30723978-4562-4ADF-A4FD-FE033BF4B11E",
    "CV EN Roberto Segura.pdf": "FE08FA5F-FB93-4514-9949-B4AB35E27B0A",
    "CV engl 202306 CNS-Stefan Lieber.pdf": "ACD7839C-EF15-4309-8AE1-A52E8D7BDFAD",
    "CV English - version 09-20.pdf": "1EA04808-A8F3-4050-B178-1CC5689365B9",
    "CV ENGLISH 2022 1.pdf": "325CEA41-598B-4E88-AB9B-CD0405EE52C8",
    "CV English August april 2022.pdf": "88A50CDE-B993-49B9-9C0F-A10AF739027D",
    "CV ENGLISH PHILIPP HENDRIX 07 2023.pdf": "A0FFBFB2-0B50-467D-976C-822EEA495168",
    "CV English-2.pdf": "3E324D12-B203-4745-8047-A5F1E8278F3B",
    "CV English.pdf": "5793FC88-6745-4855-931D-805B54B7DA62",
    "CV Eric Sankey_6.8.23.pdf": "2272BF78-65EC-4A83-A55A-29B6901A7B69",
    "CV Euro format.pdf": "D6B43295-7702-42E4-A98D-774455287EE3",
    "CV Europass - ian 2021 - EN.pdf": "3160A2C1-67A9-4FC4-94DA-D21A0A5D3A2C",
    "CV Farhan Siddiq.pdf": "E28312E4-D15D-4656-BF42-5C8B0EA0D6F1",
    "CV february 2012 (1).pdf": "6942DFF0-3846-4686-B221-80CFC231597B",
    "CV Federica Penner Eng.pdf": "2B76D697-C548-440A-B8D3-4E74E6713982",
    "CV Felipe Constanzo English.pdf": "E97ADF92-8B8B-4D98-BAFD-6FDD53A24692",
    "CV fellowship june 2019.pdf": "494E8361-468A-4424-AAB9-493B43A8CFE6",
    "CV FINAL KACIMI.pdf": "B1E8E56D-1413-4B04-A287-990E0E701883",
    "Cv FMP 2021 .pdf": "AA28F3B6-9921-49E7-97E4-79EFE9E8FB5B",
    "CV FMP 2021.pdf": "DC7BE146-437F-4A8B-B56F-0ADE879CBE48",
    "CV for rotation.pdf": "9F59F332-941E-4B01-9BE1-E96F008D41E8",
    "CV Galih I. Permana 2.pdf": "99CA2590-C392-4201-8F0D-634BC3D197BC",
    "CV GIANNETTI ALEXANDRE english version.pdf": "E0A83185-6C46-474A-B978-6DC9DCE89F67",
    "CV GOLIDTUM .pdf": "4F8D0E88-DBBC-4D65-9DDD-3A5847D49418",
    "CV Griffin 2021.pdf": "4DD4725E-01EF-4F01-B1E5-030334AC82FA",
    "CV Guido Caffaratti.docx - Documentos de Google.pdf": "B3E70575-C6E7-40C1-8942-4B0B0F55DBCD",
    "CV HARGETT.pdf": "7779EA9B-7C55-4DD5-895A-A296B1B8DDA9",
    "CV Harpring.pdf": "53B51F03-CC17-4184-AD9C-73BC5A451CD6",
    "CV Hernan Rey 2021.pdf": "FD1D7D8E-23B8-4B91-A11D-D50B19AF4930",
    "CV Humberto Madrinan 12 2021.pdf": "C50458D2-9844-4404-8BAB-2203B51CB0A7",
    "CV Ingles PDF.pdf": "6087BEE5-F63F-4B48-9196-4D384BEE597E",
    "cv Isai.pdf": "32021E07-0614-4220-A1F1-ECEE94185746",
    "CV Jamie Toms .pdf": "E359EE79-DF83-4ACA-BF0C-43BC787D85B1",
    "CV Jamie Toms.pdf": "2B3AE7EA-05EC-4D99-81BA-408D50E623A3",
    "CV Jan 2022.pdf": "1FE44E92-A1B6-4406-9072-13150EABC2F1",
    "CV Jan21.pdf": "B6E7400E-F1DF-43FE-80A6-BE0BCE00275C",
    "CV JANJUA - 122720.pdf": "D97B14C2-91CA-46E0-9015-B0913FA1B191",
    "CV JANJUA - 821.pdf": "90883D30-0A2B-4207-955E-417D926C9A0B",
    "CV Jb pascual dec 2020.pdf": "49C14779-BA5B-4D73-A7EE-BCD5547E4E84",
    "CV JCR.pdf": "C4FD64A0-3A56-42CE-9382-DE62DA6DA1B2",
    "CV Jesabelle Domínguez.pdf": "68AF6D7E-7115-48F9-96D1-D4CFD64A58D4",
    "CV JHU format 092022.pdf": "BB0D1EC7-4F98-442C-B4EA-5AE9B4DFA0E3",
    "CV JK.pdf": "5979A225-1DD3-4F34-87B6-1FDF663B121F",
    "CV JMRB.pdf": "89B16C5B-215B-4665-A317-F29E3E314965",
    "CV JMRB_.pdf": "F14B9123-87DA-4C2F-A48A-73A4EE835C93",
    "CV Joaquín Godoy López (ingles).pdf": "F39218FA-F81A-4EC7-B8DF-173E96B6DCE5",
    "CV Joel Beckett 2021_1.pdf": "780AE1BC-F13B-4C76-BC2B-7D144D8C2330",
    "CV Joel S Katz 2-2021.pdf": "C868AE79-2A98-4BDD-A0F8-F125544AE81E",
    "CV JUN 2020.pdf": "8DDDF25E-9068-486F-A7D3-127551C060FC",
    "CV June 2015- pdf.pdf": "578AE50D-6B0E-4B25-932E-BB0CD5EBC841",
    "CV June 2023.pdf": "0EEDB75E-E002-42BE-8156-C6F6A703AD16",
    "CV Justin Mascitelli.pdf": "83867A75-9983-4ABF-8D10-4E39E3B3EC96",
    "CV Jusue-Torres.pdf": "F0B9FED6-4C7E-425C-86E1-F726B6384644",
    "CV Kanner edited by Sapir updated 160914.pdf": "750409E8-2581-4D8B-8A30-F1EE7FC2B867",
    "CV KARISHMA HEMANATHAN.pdf": "81B0423F-0B97-4FB9-A48A-62EE31D6E1A2",
    "CV KCJ Dec 21.pdf": "E433C9B4-3AEE-490D-A5CA-F0E92E485C61",
    "CV Kelly Yoo 2022.pdf": "A13C9DEF-A0A7-46D0-9883-0508042AEF50",
    "CV kenechukwu.pdf": "E68108C2-7E6C-445E-8BDB-2F0AAFA5D073",
    "CV khaled altahiny.pdf": "711F4BCA-B2CD-4F36-8A21-A5CCE604B290",
    "CV Kim Miri 2022 .pdf": "CE5BA6FA-CF57-4E6B-8A62-E9249AF8B54D",
    "CV Klinge-04_2013.pdf": "398549D2-ECC0-45F9-9CA3-7B165D69FA79",
    "CV Krishnamurthy September 2022.pdf": "653DDE58-FA7B-480B-BF8F-CE44DE47ECCA",
    "CV Kristin Huntoon February 2024.pdf": "33F5A438-F753-4291-8C9B-BC7CBE4273CF",
    "CV Kristin Huntoon MDACC February 2023.pdf": "2A610199-FF84-4D14-8CF4-119456EB5A41",
    "CV Kristine Ravina  2.pdf": "268DE8E4-A32E-4268-831A-25E909889151",
    "CV L.Requena.pdf": "B2E5EFA3-1E15-4E6A-BCB8-CC5755A0A7C6",
    "CV Laura A Snyder 2.16.2021.pdf": "D3664B68-8DD5-42AA-8F05-D08AEA5D7DBC",
    "CV Laura A Snyder 2.3.2023.pdf": "346530F9-96EF-44F4-AF67-2B34FC725EF4",
    "CV Laura A Snyder 2.5.2024.pdf": "8BC51C9C-F806-47E7-BA83-59566EB5F5FC",
    "CV Laura A Snyder 3.6.2019.pdf": "F0A74CCB-BA8E-4ACA-85E2-4B02561AF52B",
    "CV LDEhlers 1.pdf": "3755BA2B-67C4-4078-A571-DEE62C0E9B56",
    "CV Leonardo Brasiliense 08.23.pdf": "61A87254-0099-4DC7-BFCF-3901B9A81634",
    "CV Liounakos.pdf": "51DE78EA-6968-4FAC-8A97-51985D69EADB",
    "CV LONCHARIC 2024.pdf": "5FAE9470-8C7E-4D6F-AF9D-28235E97C411",
    "CV Luis Ocando.pdf": "E5716274-580B-411E-81D2-09A0B6C6B34B",
    "CV Mantovani June 2023.pdf": "A7DB541B-8C75-47E4-8067-DEDF01C83BE2",
    "CV march 2012 and certificate goodstanding residency program.pdf": "BC12A503-6AC5-48F6-A24C-AD7DBC79C9E6",
    "CV march 2022.pdf": "F5B8B9C5-BC72-4ED8-A012-E547D8BCD2EA",
    "CV Martinez Mauricio Apr 2020.pdf": "0BBF7D41-3994-4F8C-BDE1-C9B19541DF3F",
    "CV Matias updated 10_2023.pdf": "7C0A818F-A530-49F9-8684-A5C14A6CF67D",
    "CV Melissa.pdf": "5A4F13AA-A5E6-4F8B-93D4-E38BD36D5549",
    "CV merged.pdf": "FF61BFF0-9A44-4167-9795-AFB0572A39D8",
    "CV Michael Lawless 7.18.23.pdf": "93551AC5-DAED-4553-8751-7A12C5FB8F6E",
    "CV Michael Veldeman.pdf": "56AA8243-6B63-4680-85E2-61B3278D7A0F",
    "CV Michelle Ariana Wedemeyer 4-2023.pdf": "AB3820C5-3742-41BD-ABB7-A1A802537AE1",
    "CV Miguel Quintero-Consuegra.pdf": "B6CEB45E-0AC8-4DA3-9181-95BFFE66E414",
    "CV Miner Ross 2020.pdf": "29D53B35-8440-458D-9776-3F5611BD6684",
    "CV Mizuho Inoue 092819.pdf": "B85DF770-8BC0-4676-BA39-2088097299D8",
    "CV moi .pdf": "E71FF753-18D7-4E2A-A0C6-CE38B6DED731",
    "CV Morell 03-16.pdf": "88EF8E86-B718-4850-8E4F-0A774DAD88F8",
    "CV Nahed.pdf": "EAEEFBFE-0E63-4B41-86E8-9B3071858571",
    "CV Nicolas Khattar 22.pdf": "FE6D3F54-F0E3-463A-89C2-285C76A89075",
    "CV Nicolas Khattar 24.pdf": "75C5781C-B3DD-41FE-B013-1B628FD8932C",
    "CV Nir Shimony 2018.pdf": "8464BE30-D4DD-469C-B887-8212FC2769DA",
    "CV Nobutaka Horie.pdf": "F5721B96-1BFA-4B1D-81BA-4296990CFD50",
    "CV Nouri M.pdf": "E5C93DD0-43FB-4E94-B902-3D70EDBDC1C5",
    "CV Nov 11, 2014.pdf": "10CB99A6-27AD-40E6-B719-72ED7241A64F",
    "CV Nov 2021.pdf": "C0F426D0-68A3-41E9-9143-0C787E9BCE2C",
    "CV Nsgy.pdf": "68DDE8E4-021F-4DFB-9013-0280FFAB577B",
    "CV Nuevo.pdf": "532CE480-6386-4CCA-B817-9583943C91C5",
    "CV Oct22.pdf": "82481172-96D3-4497-9BCE-E29BA86C7F47",
    "CV of Dr Kamal uddin.pdf": "838602FA-C429-4C8F-85DF-EE42FCAE7A2D",
    "CV OF DR VIJAY-SPINE.pdf": "CD8B4E04-C0E6-4356-B14D-52F1A12414BB",
    "CV of Kevin Chan.pdf": "0FE3F7E0-D5DD-453D-996C-52CBDEA10732",
    "CV of Ronnie Baticulon - For Harvard MGH.pdf": "896A9A7F-F61C-4D44-9DC2-CCAD30909026",
    "CV oficial.pdf": "6248344B-695B-47B1-8B20-30D633B1B354",
    "CV Oscar Jiménez Palma.pdf": "2982CE6E-FE03-4222-8F34-217371C81DA6",
    "CV Oudrhiri 2021.pdf": "282F09EE-F4FC-4B0A-B8A0-0B26B68797D6",
    "CV P.pdf": "27E23119-3795-47E4-B748-1EDC7752AE7E",
    "CV Patrick Belton December 2022.pdf": "7B9E3837-39C9-481D-B502-9A51F00267A4",
    "CV PAULO FRANCESCHINI JULY  2018.pdf": "093F36C2-8866-4D82-81B7-CFAA473B1057",
    "CV Pedro Coutinho.pdf": "3911DB1D-2543-46F1-B88A-625710832470",
    "CV peds (1).pdf": "B039973F-D97B-4EF9-9985-7976862BD3E1",
    "CV PGY3.pdf": "3CFED7EB-2CBE-4127-A757-2CB41D101A16",
    "CV PGY5.pdf": "4B921397-819D-46C2-AA01-B7C10A763A6D",
    "CV Philippe Magown AO 2020.pdf": "C534345A-F8F2-4504-BA30-8BFDF6C2073C",
    "CV PMantilla.pdf": "4664CA29-A811-4290-90D5-C0FC14C59851",
    "CV Pooya Daemi Attaran.pdf": "C3CD24A1-316D-455B-A3DE-328E75ED2EDE",
    "CV Porto.pdf": "791E46DB-A037-4A49-BC01-05A5C93A7D1D",
    "CV Reem Yousef AlMarshad.pdf": "4732FDB9-B873-4015-B596-E023CD2BF7CA",
    "CV Reza ENGLISH.pdf": "9C610ECF-6C03-4500-B0A9-F1567701C9D3",
    "CV Rizki Meizikri 1.pdf": "E7C1BC36-E862-4F39-B455-F8809DB13140",
    "cv Roger Josefsen.pdf": "E1765039-E9D6-42BD-A938-67BB13016F6C",
    "CV Ross August 2022.pdf": "67126F9A-9B48-498E-B683-30B02F28AA16",
    "CV Sachdeva.pdf": "D7D0ABE9-9413-4B62-81BA-D221F714703E",
    "CV Sara Nunes de Sequeira.pdf": "105DEC11-57DE-44A5-AD15-B471E19DC850",
    "CV Sayyid Abdil Hakam P MD.pdf": "42B89344-59D5-4544-98D4-7A4F3FF2F140",
    "CV Schmalz 7 Sept 2021.pdf": "37485CD4-5C61-46E7-91AA-989D62618A7C",
    "CV Section ICH Ventriculostomy 2019.pdf": "69094CAF-C7CF-438B-9CA4-6C61D7FEC5AF",
    "CV Section STICH Poster 2019.pdf": "7B459EED-6957-49A6-A22D-C8C2F91F8058",
    "CV Seltmann.pdf": "63477633-8C69-48A3-9F92-382368D1D835",
    "cv sep 2020.pdf": "0B97B3CC-9CFB-4FE9-A42D-BBCE955CD3C8",
    "CV Sergio Arizona.pdf": "868A852E-7979-44ED-92AE-3FB2AE56E974",
    "CV Shenai 11.2021.pdf": "D682B679-15EA-4AF1-901E-4B42083E4624",
    "CV SHIGEMATSU FEB2023.pdf": "DD7DCA03-6327-40F2-B713-D2C7BE190754",
    "cv short mahesh ramola 30 april 24.pdf": "9B38A306-28E4-444B-818E-AADECBC5D926",
    "CV Siddiqi short.pdf": "16816B06-A908-47D6-AB66-3631BD6D765B",
    "CV Stark_02_2016 - Google Docs.pdf": "2368410B-E1FB-4203-B069-8E0DD151A849",
    "CV Stepan Capek 2014.pdf": "59950678-0368-4331-8644-9F455D46390B",
    "CV Still M.pdf": "8DB33C05-26EC-4D97-8122-0295606658BD",
    "CV Still MEH.pdf": "E9FBFEEE-A373-4A2D-BECC-EBBC59C188B2",
    "CV SV 2023-Sasha Vaziri.pdf": "7FBFDAD8-43E2-4F4A-89C1-C04807D94125",
    "CV Taimur Hassan.docx 4.pdf": "F73B0F42-9EEC-42FB-B220-383835F59A94",
    "CV Tal Shahar Jul 2018.pdf": "F0290074-57AF-4F55-BE44-4B8F4128D281",
    "CV TCalloni eng def 3.21.pdf": "78048F54-E214-413A-B244-6FD8FDB7C56D",
    "CV thomas  2020-1.PDF": "0F2178CF-AAEF-404E-A1D2-B8A2163F6F64",
    "CV Tomas Garzon  august 2010.pdf": "B9063801-180E-4447-A8F4-2151F8417770",
    "CV Tomás Moncada English April 2024.docx.pdf": "7EF526E8-3AEC-423F-8A9B-2B264BE38186",
    "CV Tomás Moncada English April 2024.pdf": "F36076F2-6D85-49B2-AD44-24B2E6C3432F",
    "CV Tommy A Nazwar 2014.pdf": "B6D3B3DF-CAE3-4130-BB8D-AC971E310A67",
    "CV Tracie Thornton.pdf": "288D4B60-016F-4B65-90AB-C2556411753B",
    "CV Ujjwal A.pdf": "9C9A9FFF-8210-46C9-AB57-2DF7BF801873",
    "CV updated 12_04_2019.pdf": "AC5D1427-5C26-4831-9C5A-5637AA61D984",
    "CV updated Sundar_Swetha.pdf": "63CC822C-0145-4174-84C7-A22825FBA1BE",
    "CV updated-converted.pdf": "39147BA6-72B1-42A9-AB82-D3BE4B8A1D54",
    "CV Uzair Ahmed 2023 July.pdf": "9638CCD6-1742-4A6B-B89A-B4EF078DC4FB",
    "CV Velodi.pdf": "C9AEB23D-B3A5-453C-813D-0834FE4CA919",
    "CV Vera Vigo.pdf": "BD5140C8-F933-4828-A4A9-A8E6CEB3EAFB",
    "CV Vibhor Krishna.pdf": "59ABFF74-68E7-4237-84C7-4102C08FB52C",
    "CV Viola Bartoletti uptaded 0722.pdf": "A6D886A9-BCAB-40C0-B20A-5634B26DCABD",
    "CV Waldau February 2023.pdf": "13AA1C91-E2CE-416A-A2B3-516DB6CC7D18",
    "CV Wishart_Danielle.pdf": "25F22F0F-194F-4CAA-9FA7-7E4293B190A4",
    "CV Wojcik.pdf": "28317FEB-4A85-4383-B962-3DD03EA249E7",
    "CV Yasmeen Elsawaf.pdf": "6C69B088-4193-4DDA-A730-E6A17DCF5768",
    "Cv Yasuda 2021.pdf": "253BB469-00AB-429D-A239-AAE1546E2872",
    "Cv Yasuda.pdf": "566908A6-15E5-4F63-9EDD-65747B375D8F",
    "CV Yehia El-Bendary.pdf": "F2E0E33E-1E69-4A16-B34A-12783B61A7B9",
    "CV YKA.pdf": "685A8A81-9DB9-4746-9A85-751327C38AD6",
    "CV zachary sorrentino CNS 5_5_24.pdf": "4DE64AF0-9DFF-48E5-AD98-4245113936FB",
    "CV Zaeem S 1.pdf": "85361583-5E1C-4B51-983E-6BB19D688F5D",
    "CV Zainab.pdf": "946BD092-8B3D-43EC-A7EC-3571411BDC40",
    "CV Zuniga Rivera Julio Cesar MD.pdf": "A7001410-45C5-45F1-A5F5-57404EF61A02",
    "CV Zyck July 2022 PDF.pdf": "1932E5F6-3AC9-462E-9872-1DF80C5FC980",
    "CV Zyck.pdf": "63C0AAEB-4846-4E64-B9B9-9F8BF1BAB15E",
    "CV%20DURSO_-20.pdf": "904A9A94-9C81-46D6-8D96-1F13D8476C79",
    "CV'20.pdf": "0DD53106-4564-46E0-9E89-2C83FD158893",
    "cv(1).pdf": "451062CC-5FBF-4B97-9C18-4C5F20245973",
    "CV(times).pdf": "1E5D76DA-799A-4F07-A2F4-E9F8D0510AE4",
    "CV, Verification letter, Awaiting examination Dr. A Thomas.pdf": "E3A01BBA-36C6-42E8-921D-5AA4378DA4EC",
    "CV- Ajmal Zemmar.pdf": "0F3499BD-BEFB-46E0-B0FE-8BCDFDF08051",
    "CV- Dr. Vinayak Narayan 2.pdf": "CDBB54B0-9EE3-41A3-9A18-175096566E73",
    "CV- Duy Phan.pdf": "B37ECFE0-2BE0-4537-932C-EE77C0204863",
    "CV- Hall.pdf": "02A0BBD0-D4CF-43DC-BBD4-9BB2D8E491BA",
    "CV- Kaisorn Chaichana.pdf": "02283EAD-2113-4903-BA3D-94142EB9CD11",
    "CV- Kyle Mueller.pdf": "3100AC66-81DA-486F-B2EA-507A4635F48D",
    "CV- March 2020 Michael Rogers.pdf": "79DA696F-FAD9-4F93-A356-9B22CE1EA134",
    "CV- Peng Jin.pdf": "5E5125A9-CB6B-4ABB-AAFB-7046731FBC3E",
    "CV- SENOL.pdf": "67820FEC-6887-491B-96DB-D93B248EAEFC",
    "CV- Waksdahl.pdf": "10F3D93A-B025-4992-98C5-BB74755630D7",
    "CV-1810.pdf": "92D56EBC-746C-4646-B9B6-C262B3C9099E",
    "CV-2009-anglais.pdf": "4ECE605A-9CCB-4781-B977-C53257D4A7A0",
    "CV-2015-8.pdf": "DCAEE3E2-7B43-433E-8B9F-5A2555828C5A",
    "CV-2022kesarwani.pdf": "69D517FE-7E49-494F-8F5B-4FF764D96F8A",
    "CV-ABDALLA MANSOUR.pdf": "384CDF6B-44B4-4ECF-BD02-5613629E0745",
    "CV-Abdulrahman Almansouri.pdf": "F85A365F-245A-437F-80F4-37703CE6407C",
    "CV-AMANDADIPIAZZA_July_2023.pdf": "4D11D0E6-4EE4-478F-807C-F69788595B56",
    "CV-april19.pdf": "9A0E4A4B-971E-4BE9-A9A9-4E422AFB522D",
    "CV-Barnett[1].pdf": "3F2D0CF1-FA59-4E7B-820A-60BBEB6B8913",
    "CV-Chen Shi.pdf": "50AE7657-45F0-496A-BF0A-15C0CFAAB755",
    "CV-Chine Logan Updated 012020.pdf": "BDDA136C-A7A4-4C89-BC9A-B24769F18D0D",
    "CV-COHEN.pdf": "4FB8593F-B551-438D-8771-31C74705A931",
    "CV-Dahdaleh_10_2020.pdf": "AF29A133-2E49-4690-98DF-8E2D98BCF14F",
    "CV-Dr. Kunal Patel.pdf": "286D0157-775D-4914-954D-E0B0A44D3AE0",
    "CV-Elder 1-2024.pdf": "F38E6D09-BAC5-4065-857B-00A7703F117D",
    "CV-eng.pdf": "51CFE951-FF8D-478C-8246-ABF6235457BD",
    "CV-Europass-20180622-EN.pdf": "F9ECA902-957C-48D9-8CDB-FD1AF9148542",
    "CV-FALSAIEGH.pdf": "F11373DB-73B8-447A-B792-09D371C52424",
    "CV-Fellowship.pdf": "489E60D9-DEDD-4A0C-8DC2-D47DB6EE81F0",
    "CV-GM2022.pdf": "5AF06E1A-FF0A-4D58-99A0-2272D5D7A4C8",
    "CV-Goktug Akyoldas, MD.pdf": "BB9CCED1-05BE-4C08-9991-E59A1B1E5129",
    "CV-Imad Khan.pdf": "6AB4A712-D494-471F-BB5C-722DE9BC128F",
    "CV-JFM .pdf": "7CCF5E32-5D3C-4616-87FB-A47A5F4FE1BD",
    "CV-Jorge Miranda Barrera.pdf": "0EC2DAF1-124E-4293-88E9-B68F27079087",
    "CV-Keith Patten.pdf": "6AE1A9EA-ED80-4B3C-9135-94D32A250F33",
    "CV-Laarakker-2017-colorado.pdf": "686DAEA4-F268-47E7-B44C-B24C4C256A41",
    "CV-Mangraviti_Jan2023_.doc.pdf": "96932596-2214-4981-ABCA-92546086B0B2",
    "CV-Nicholas E.R. Burrowes .pdf": "BC674DD0-0C90-4F60-B2D2-3B577F96A34E",
    "CV-Quentin Francois.pdf": "0F3F1BDF-652E-4E9F-B5B6-6B48841D6E6D",
    "CV-Shahriar.pdf": "06916E09-7BAE-4EB0-AB5D-5281D077C024",
    "cv-sitthisak phupungtamakoon.pdf": "12B70DC8-F19B-4DA0-952B-7605FA7CB721",
    "CV-SunghoLee-LSUHS.pdf": "F5FE2D5F-28D5-4C6E-8165-1405C29019CD",
    "CV-Vale JAN2020 - Copy.pdf": "478D5C02-F085-4FB6-B138-3953004EC329",
    "CV-Xin.pdf": "33D9C47B-6A80-439C-BEF3-BCD7FE29F68F",
    "CV-YCS- FEB 2024.pdf": "8FA53697-2148-4A25-AE0C-9183CF3B36C9",
    "CV-Yongjian Tang - 20220202.pdf": "8C499711-8912-4149-A714-067B55520100",
    "CV..pdf": "9232C86A-15D9-4BD6-B341-1F6F7CACBBF2",
    "CV.22.09.2023.pdf": "2F62FE11-5288-4459-BB8C-92C0121407E3",
    "CV.Dewan.10.2022.pdf": "E282DEB2-C53F-4293-A6E5-3B2EA64625B9",
    "CV.english.november2020.pdf": "13199DD1-696C-4B90-8BEF-2F191915DDEC",
    "CV.pdf": "B5BCF618-F067-47D4-BA61-028EBE9F36AF",
    "CV.Turkmani.pdf": "63C1FF2B-6AAB-4BA2-8937-E3500FFD59A3",
    "cv[1].pdf": "9744392B-8778-4661-B061-E99C825D99E8",
    "CV_ Fitra.pdf": "4DB0D1F9-D0D6-4E58-B91D-F0E4ABCE4545",
    "CV_ Kalakoti_01122017.pdf": "9A441B40-063B-469F-B5D9-237F765EC5B1",
    "CV_ Sohail_Syed_2023_10_20.pdf": "25CF0743-3419-46E8-8765-8DB9E35CB33C",
    "CV__Petrescu_en.pdf": "6A2BB31B-93BA-4A54-B8F7-840EE267CE02",
    "CV_03-14-2021.pdf": "86D0EB18-3E83-4028-91A6-D2DC3F0054BD",
    "CV_052024.pdf": "6D559097-98A2-4A6A-8B31-A7E0FE05AC4F",
    "CV_1.21.pdf": "5B8DC3D0-AB91-4F66-AEB7-04DE72D35A50",
    "CV_10-16-2020-132520_7fdf5320-8681-4206-8fa3-72ae97ad35c4.pdf": "76A09189-BDD1-4A68-A9CE-1483FF1A9E13",
    "CV_1222.pdf": "68AAD974-6857-4799-B771-58D2FEE65FE3",
    "cv_2.pdf": "79869994-3E18-48C4-9A6D-9AED37B57B28",
    "CV_2_17 amend.pdf": "A9D9B54C-562C-4F88-827E-DC81CBD32F6B",
    "CV_2011 and Verification letter_Toma_Spiriev_CNS.pdf": "25434E83-82DB-4AEA-A793-75EEE1C2F22C",
    "CV_2011_Toma_Spiriev_ October.pdf": "0397AA51-F9F5-4255-A02F-D6DDF1CCAB32",
    "cv_2014_09_25_19_30_16_340.pdf": "C3E5E5BB-E8FB-4DDA-94EA-C27A7B7560A2",
    "CV_2020-H.pdf": "98230F6E-F94A-46FF-B7B3-12D37FFB037B",
    "CV_2020.pdf": "D814EC9A-7041-4C64-B704-E4D3593B7AE3",
    "CV_2020_Fernando_Novo.pdf": "2F8ADBBF-285E-4399-B85E-AB50412E72E0",
    "CV_2021_CNS.pdf": "BC68A0B6-86B9-4D44-93C8-14ED7DFA36BA",
    "CV_2022_0331.pdf": "B72A1937-4AE0-4D1A-83E7-622AF8076531",
    "CV_2023 final version.pdf": "27F26DD3-05F2-4729-858A-5CF6365C786C",
    "CV_2023.pdf": "9B591AB5-78E5-44FF-94DA-0FEE725C3CD2",
    "cv_2024.pdf": "BFF5093F-CE22-4365-BFA8-8B77184594A1",
    "CV_21.pdf": "C0454211-A585-4FAF-BADD-A114ECD6FB01",
    "CV_22.pdf": "B476034C-A58B-4B95-A324-85E1DE9BBF48",
    "CV_4.2020_AW.pdf": "110053B1-F8DF-4F22-B256-213AEDAF5593",
    "CV_6_12_18.pdf": "A7C5E204-C150-4D89-ADD3-F7306FDAB8E3",
    "CV_7-29-2022-ERNEST_JOSEPH_BARTHELEMY-PUBLIC VERSION.pdf": "C58E81E4-779E-4D49-BC22-A6158BC915FC",
    "CV_ACY.pdf": "F952E4F0-A3D0-4540-9DDF-F9E5BF25A678",
    "CV_Alberto_Malucelli_2013_new.pdf": "5BA46B9F-0179-4F93-A14E-BD964C19C70F",
    "CV_AlejandroMusso CNS.pdf": "7CBEC9C7-8418-4B09-BB4B-626C260EB5E9",
    "CV_Amankulor_Jan 2021.pdf": "FA2C67B7-47FC-4EA8-A421-3DFFC8905C87",
    "CV_Ana Rodríguez-Hernández_Dec 2016.pdf": "7467B937-203D-43E7-B3FD-35FC48F6E904",
    "CV_Andrew Yang10_bni_curr.pdf": "E3F976FB-298D-425E-8DBE-A58B482B434F",
    "cv_ankurpatel.pdf": "95B7E093-1056-463D-9CE7-DF5268B77E41",
    "CV_AO_10_26_2021_version2.pdf": "7E55855C-13E8-42F7-82E4-313BC8CC7F53",
    "CV_AP.jpg.pdf": "32A34B39-2306-40C2-A74A-B59569BC0DD4",
    "CV_Arka Mallela.pdf": "8BE22D97-E37F-41B3-86E1-2AA0ACCDC950",
    "CV_AshishRamesh.pdf": "4A0BBA97-62CF-46FB-8A4B-B9649B01103C",
    "CV_AVECILLAS-CHASIN.pdf": "A8EF9024-5397-4BDA-8336-74F55B88B1B2",
    "CV_B-1.pdf": "3A234C9E-19EB-4593-A9C9-D48DB61687CF",
    "CV_BARTOLI_2021.pdf": "FF35E6CE-7846-48DC-A3D5-D8B8771C836A",
    "CV_Baumgarten_2018_09_19_E.pdf": "E6A4B5BB-55B0-45FF-92C7-3E43B2A292B8",
    "CV_bsherrod_12-15-21.pdf": "66182A88-3B4F-496B-B4D2-072A1AFF543C",
    "CV_Christian Theodotou.pdf": "EA755318-5AEF-49E2-9ED4-5FF51BC12E43",
    "CV_Christopher_C.docx.pdf": "52F0F489-C9F4-4A56-B6A5-A48F473BE5FC",
    "CV_CNS.pdf": "129BC0AF-861F-49B3-80D1-432413B0A8FC",
    "CV_CNS_PdosSantosNeto.pdf": "3462518D-627E-4408-95DA-A2F78442BBAA",
    "CV_CNSLeadership.pdf": "FEE4FD24-5FC6-4954-B712-A74B5242455B",
    "CV_DanielCleary_7_18_23.pdf": "8DF83789-B747-4AB4-BC70-19CB3E3A14F4",
    "CV_DaSilva.pdf": "5E152DB0-2090-4C33-822B-9BFB3B55F104",
    "CV_David Park_08102020.pdf": "EF543E74-9660-4841-B756-90523F5097D7",
    "CV_DFC.pdf": "2463EB92-0803-447E-BE69-0A8C4B823CFC",
    "CV_echang_2015.pdf": "EB9E39D7-E1A9-488D-9739-78EF53F49980",
    "cv_Elias Opio 2021.pdf": "1F9D34C2-EECC-4E40-80F8-B4DCF7701D6F",
    "CV_EN_AgneAndrius.pdf": "0FF61A29-ABB2-4FB5-A262-A22FEFE9F4DB",
    "CV_EOR.pdf": "727DE63C-2A23-4414-8080-1382DF9C4C37",
    "CV_Franz_Ricklefs.pdf": "38067937-9B04-41B2-A399-AD30341F7A27",
    "CV_full.pdf": "8FFAD940-784F-4006-B000-2A7277895623",
    "CV_Gabriel_Friedman.pdf": "B0FD8951-2AF1-48F7-8E56-0F6BC359B07B",
    "CV_George_Marcus_Galvan_2020.pdf": "68BCCD83-A41E-4008-8FAE-E08457EFCC8A",
    "CV_Giwuk_Jang.pdf": "01B0548F-6A5F-4AE9-AC1B-EA5708112EF1",
    "CV_H_Arnts_English_april2018.pdf": "7840CDC2-B6C1-471D-B3AE-6554AADC6CB5",
    "CV_Hecht_2023_CNS.pdf": "93A18029-9AFC-4E4B-AF5A-3F8792512BA8",
    "CV_Hui Tzung Luh_20190429.pdf": "F7BFC5A8-26E3-4D90-A041-ABBCF22B1AF6",
    "CV_Immaculate_Christie.pdf": "230FADBE-5425-4850-B4CE-A5D7ECD3BB27",
    "cv_inc_1220.pdf": "9B40F256-86C5-4830-930B-E8B8BC936846",
    "CV_J Dye_Feb 2024.pdf": "16CC64BD-170B-4074-86B5-C952CEBFD62F",
    "CV_Jayson_Nelson_May_2024.pdf": "876138E1-AA0F-44BB-8F03-330A2BB9BEE0",
    "CV_JeffreyTraylor.pdf": "0796791B-F31B-45EC-91BA-7BE7303EC503",
    "CV_JK Burkhardt.pdf": "1FA64164-F925-4E48-89CB-595801D1DAB0",
    "CV_JK Burkhardt_1_30_2023_CNS.pdf": "A782EBD8-8D2F-4D96-925A-564094E75826",
    "CV_JK Burkhardt_2_3_2024_CNS.pdf": "4AC974B2-DB58-49B3-8393-8B642E9849F4",
    "CV_JoseAFernandez.pdf": "A6B1050B-C43C-4F1A-9D31-70B9C9E3305A",
    "CV_Joseph H Miller_2017.pdf": "B2B69878-9151-4F60-A71A-DFB4D9BEADB2",
    "CV_JW.pdf": "68612DD8-8A21-41FE-AE2C-8226DFA34CA5",
    "cv_Kecheng Lei_2024 Feb pdf.pdf": "14B34708-684A-4EBA-9368-22DAAD48EE05",
    "CV_Khoo_202108.pdf": "331B5B75-36C8-45DE-9CEA-1EBDFD96C0D4",
    "CV_Koivuniemi_3-23-2022 1.pdf": "C3C2ED43-529C-4C57-B7FA-727F3AFFC23D",
    "CV_kongkham.pdf": "8E3DCDB8-7617-4C4B-B848-4F96EB2345F4",
    "CV_KPG_160826.pdf": "CEE30D7A-4D55-41FE-89D6-C4FB555CD605",
    "CV_Krafft_2022.pdf": "CF897BD3-9B94-4D1F-8925-C8898FF2A1BA",
    "CV_L.Erin Miller_April24.pdf": "88050D9D-9F37-419F-A6AB-6D47E2C042E6",
    "CV_Loewenstein.pdf": "B4A7EC40-6751-4C78-A97F-02AA72DD5D42",
    "CV_LR.pdf": "90E3382C-0F2B-4F56-A7AB-C4B72BE16EE4",
    "CV_Luis Marques.pdf": "0400EFE2-93A8-4991-9A53-2501D4DB3792",
    "CV_MAHorowtiz_CNS.pdf": "CD1B78A4-02F8-4D8A-94FA-433F7CE28E4C",
    "CV_Mendes_George.pdf": "8C51B3BC-2878-42E9-8176-EA3222B591B8",
    "CV_Mervyn Lim.pdf": "660BD2E2-6612-46E6-B0B4-4E57C0889179",
    "CV_MF_2022.pdf": "21ED2B9C-3EA8-4AA6-9D99-5A685610D709",
    "CV_Michael Yang_Oct 2022.pdf": "B70D1B18-2BCD-4744-BEAE-991A1638AB75",
    "CV_MMorais.pdf": "92962727-B26B-4E7B-B339-0FDC3941041C",
    "CV_MOHAMED ZAAZOUE.pdf": "D0E197AF-C1B8-4349-8A86-C9A06844219C",
    "CV_Mohammad Laila.pdf": "071F66F2-A505-4F06-96AB-8660BE2C9614",
    "CV_MossoRamos.pdf": "F1A973A9-2E0E-4E6A-B206-50B5A5B37893",
    "CV_MStrong.pdf": "FF8FC267-E507-44D8-A4AD-D2F4B0FB1B3B",
    "CV_Muluberhan D.pdf": "87AFAD21-FDD2-4198-AB1C-46944A21E89C",
    "CV_Muluberhan Desta.pdf": "0E8F9B85-8465-4BF3-8008-6210E60E9EB0",
    "cv_munibe_busra_erdem.pdf": "3DF92006-7012-4120-8E5F-7CD0743FA9C4",
    "CV_Mustroph2023.pdf": "736364E7-9269-43CB-AA7B-C424FCDDC3CB",
    "CV_Nahed.pdf": "AD936729-1460-44EF-914F-D2B2A6075F80",
    "CV_NeurosurgeryFinal.pdf": "14EA93F4-42EE-415B-8A83-1218C14957BC",
    "CV_Nitesh V Patel_June2022.pdf": "2E73D7E0-8CD7-437F-AD3D-4C2AA31D4F7B",
    "CV_Okonkwo-2021-08.pdf": "514F082C-3030-4E3F-B0BE-F8F716CC0451",
    "CV_Osorio_2-9-22.pdf": "71FBE031-6B34-4D67-95ED-7516F89E13B1",
    "CV_Patra.pdf": "10887A4B-0206-4041-A10B-2DE1820FB605",
    "CV_Patra_2020_Dec.pdf": "5F917475-8911-4AA5-8611-FBF780D095C0",
    "CV_Petersen_Nov2023.pdf": "E1CA5AE8-0344-43E5-AD32-B86C246E871C",
    "CV_Plinio Duarte Mendes (0000-0003-0645-5420) - ORCID _.pdf": "E95C6B56-4A45-45BA-BEE2-92D4A85EE7C4",
    "CV_Plinio Duarte Mendes (0000-0003-0645-5420) - ORCID.pdf": "65F83485-2EC9-4E39-95BF-6F397E03ABE3",
    "CV_Price_final_CNS2020.pdf": "0E405E22-7F4D-4309-84BC-1BAC52FEC215",
    "CV_PSV_05.15.2024.docx 1.pdf": "DCB39401-6350-4478-BC86-41D4D22E0BAC",
    "CV_Rabia_Deniz_2024.pdf": "02C7C5DD-0273-46DB-A835-9BDDF0BFD1BB",
    "CV_Ramos.pdf": "D5E2D35F-BE81-4562-9B90-C7523F9C0DFB",
    "CV_ResidentReviewBoard.pdf": "A1BF01C5-E102-41AD-9C5F-FCE4D63D0479",
    "CV_RGR modif.pdf": "58FD8CF3-387A-4A22-8DE0-6AB6C261FB7D",
    "CV_Sam_Shamsnia_-_Neurosurgeon.pdf": "CC85C08F-B46D-4AB0-828C-C827EC36BB4C",
    "CV_Samuel Nardin_Feb 2021.pdf": "5AB53BC7-442C-4DBE-A761-A2CB7A103131",
    "cv_section_attendees_follow_up_comments_to_october_6_2015_fda_stroke_devices_workshop_breakout_session_questions_110315.pdf": "69BE848F-61B9-479B-9EFA-B8DB15F92B35",
    "CV_Sedrak.v11.30.2023.v2.docx.pdf": "89F3DBAF-3658-4674-A497-6B6D94AAD399",
    "CV_SEJIN CHOI_3.3.pdf": "5E358B70-61F9-494F-B5D0-C41C9ABC7390",
    "CV_Shervin.pdf": "27EBB592-6156-492D-85A1-004D74144E09",
    "CV_Sigler 2018.pdf": "09C9225B-B1CB-47FA-AF46-3057770ABBBA",
    "CV_Simpson_May2023_Updated.pdf": "FAB6905B-02D3-45CC-A375-9D9A2C4D764F",
    "CV_Stavros Polyzoidis_SEP_2023.pdf": "F02A16F2-7B0B-4623-994C-F65A05D0C63C",
    "CV_SVR.pdf": "5EBA2223-B8FF-48AF-BB8B-EDFBFBD7643D",
    "CV_Sy_Christopher_2020.pdf": "C0A058F9-ECA8-49A8-8444-3D19F792E4E9",
    "CV_Sy_Christopher_2024.pdf": "C4124F65-A9C8-4329-9438-F6E2A7744109",
    "CV_Tabibian.pdf": "C54BF078-2B40-4D9A-8CF5-61E22153A3F1",
    "CV_Tai.pdf": "732D61D4-9CF5-48D3-B901-3789DD83477C",
    "CV_Updated_4.1.pdf": "A175FB7F-4A3E-4D11-87C9-43136980C723",
    "CV_Updated_4.11.pdf": "D927654D-00F2-43C8-8CE3-495B6045C235",
    "CV_Visva_Feb2019.pdf": "F352FAE7-F9CF-4504-A93A-357E3AE5D87A",
    "CV_Yolcu.pdf": "E1379509-A3AF-4268-97BD-FEA9B5D98000",
    "CV_YS_May 2024.pdf": "EF571EDF-C29B-45BE-BD4B-DE3BA704936D",
    "cv_yuri.pdf": "D399A3DF-388C-4136-807A-9A07B06A8EC9",
    "CV_Zaazoue_Apr2024.pdf": "A5AE00E2-7896-4E93-B694-8CCB12DD1BBA",
    "CV_Zaazoue_Feb2021.pdf": "CA556D73-EE53-4F3D-BC05-2ADB0767D032",
    "CV_Zaazoue_March2021.pdf": "A49354BB-0C96-40F0-9ADB-331513474451",
    "CV_Zaazoue_Oct2021.pdf": "AFB99F98-121D-4ADD-98F0-2F426FF29D4B",
    "CV_ZADA_2020.pdf": "BCA47080-F6DD-42C8-A7F8-73083F9CE1A1",
    "CV_ZADA_2021.pdf": "6B35586D-028E-445C-8490-3C221D9ACFDB",
    "CV_ZADA_2023.pdf": "04BCBE10-3C5D-4DA7-B834-175072B0D794",
    "CV03.pdf": "0E4798D5-24F5-4004-8499-B40170B0BA94",
    "CV062022.pdf": "F44CE5B3-6AD2-48F8-AA42-8F5EE0922051",
    "CV07.2012.pdf": "90787538-6DCE-417F-AD73-0594FF40B71B",
    "cv1.pdf": "05D76E9A-BD35-43D3-90BF-52E627319CF0",
    "cv10_22.pdf": "9AC61E63-F239-4070-A5DF-E4EAEDC3FE75",
    "cv2008aans.pdf": "84A7EED0-B8B0-4AAD-9F02-2ED144FAA1AC",
    "CV2013 new.pdf": "D380ABF4-E481-4293-8C36-618BAA763863",
    "CV2014.pdf": "D5374820-2085-44AF-9A8F-47AC240B1F99",
    "CV2015.pdf": "A56C9CCA-9B65-41E5-8EBE-FED2BA670BA9",
    "CV2018.pdf": "DF72B7E2-0DE7-4FB5-B833-EBF1B2E332F3",
    "CV2020 (1).pdf": "A6C4422B-F356-47F5-9CF8-2A018B8D7EDA",
    "CV2020.pdf": "DF30BB8B-317D-47E7-92D3-CE99724DF96E",
    "CV2021.pdf": "074AA225-A736-421A-81CB-2C12C67C5953",
    "CV2022.pdf": "3FD3FC04-6488-4A41-B559-2E73580DF1C6",
    "CV2023.pdf": "D5B972B2-2AA5-4AC0-BBBB-588431F8B734",
    "CV2024 Gold Medalist Finance  1 6 5 2.pdf": "65AC7A6C-6E97-4D30-A1ED-01AE0C11CAF3",
    "CVanapardilho.pdf": "B7DE74C4-C773-4159-A923-0B01342BDA62",
    "CVDerekSamplesSept2022.pdf": "55736B44-C894-4E7B-9AC3-28A47F577FE3",
    "CVDI - 00000 - AxiaLIF (2).pdf": "AF77BD9B-FD08-40BF-B094-FC0DACA40CB5",
    "CVDI - 701107 - Spcrs (2).pdf": "26ADCD7B-3926-4440-AE1F-DA457047CB98",
    "CVDI - 701107 - Spcrs (2)b.pdf": "7D3CB968-A932-4B78-8C3C-E44C811EB906",
    "CVDI - 80140.pdf": "48B802EF-025E-40E6-8DBD-92B9F8B02A26",
    "cvDrHaitham.pdf": "37E446C2-EAA4-4163-9D9F-BD22BCC00930",
    "CVen 20130202.pdf": "2D58B7D8-B59D-42C9-9E8D-0DB64AAF5FC2",
    "CVH Leadership LOS.pdf": "44D75EA2-CF82-4C82-9983-B58D38A60870",
    "CVIntercambio_FernandaCastillo.pdf": "F969DE67-304E-412D-97E1-5C476BE3A150",
    "CVJ Brochure  2023 rev.pdf": "8590F4C1-BBB8-4529-B4AF-3FFC70354887",
    "CVJ Brochure 2021.pdf": "E6A01935-F4ED-4D9F-8204-C581C08F9A00",
    "CVJ Brochure 2022 Updated.pdf": "CFFFAFE4-3E68-42D1-BD87-63F3531E8542",
    "CVJ Brochure_ 2022_Updated 7.17.2022.pdf": "787D5020-D04B-408D-AD5D-75F93EB6D8B6",
    "CVJ course 2024 agenda.registration.pdf": "05EEA1AB-2ABE-4B93-9F31-6898D84F8D2A",
    "CVLFSF231010.pdf": "66CC3ED9-8E0B-4D63-A8F4-892A588FF579",
    "CVOAR_45580064 1.pdf": "44873DDA-2FCB-45F1-9E0B-DF1FC79576DD",
    "CVOAR_99022183.pdf": "E9D8ED39-B746-455E-8D1C-0BECCC53FC6E",
    "CVOehme250712.pdf": "7486E052-6727-4465-AA45-2295FF346F1C",
    "CVOFFICIALMARKSEDRAK-12.pdf": "E5D8BBAE-8CBE-4B23-87C1-8A213131CC4E",
    "CVPSGadjradj.pdf": "03D13C94-02E6-4A0E-8545-CCF2F93B353B",
    "CVPubsnjm.pdf": "54544627-7209-4E20-8E0F-36232BBDB761",
    "CVPubsnjm_1.pdf": "5D4953B7-DCFD-4419-B435-616F0F782D4D",
    "CVResume  - Jack Xiao 03242024.pdf": "47EF10B5-9C7B-4E68-877D-3F9CA86CE3FD",
    "CVSacino.pdf": "14281A40-8BF4-41CD-A2D7-9F4929E110C1",
    "CVSection-Viz NTAP Letter to CMS 010320.pdf": "618C6792-5CA0-46AC-9F15-9269C13B0709",
    "CVSteinsiepe.pdf": "F99EC4CF-2D32-434F-8CF9-0919D4578673",
    "CW Manuscript Final.pdf": "452AABE6-5FBD-498B-9C7D-975B21F29597",
    "CWRU SOM Olive Jung.pdf": "8AED13A6-75AA-467B-8568-CE0291177348",
    "CWRU SOM Unofficial Transcript.pdf": "E8853F05-6EB2-4248-97C3-274CD71CEAFC",
    "cy_2018_mpfs_proposed_rule_-_hart_health_strategies_summary.pdf": "89465F2D-E41B-4182-91B0-D1B8060A85E8",
    "cy_2019_pfs_proposed_rule.hhs_summary.pdf": "2E22F786-AA99-4FFB-8F26-87A3252540FF",
    "CY2020 Proposed CMS EM Code Changes.pdf": "530026C6-1C20-4ED5-BF5C-7526E432815C",
    "Cylaina Bird.pdf": "FF2A4176-41E2-4624-A65A-6BD37DDD1E72",
    "CYoung.emailverification.pdf": "5D8F2769-1B30-48D3-BDAB-5E0EE4EEDCF6",
    "CYoungfellowshipandcv.pdf": "E0CB9450-77D3-46D7-A48B-348F9BC64320",
    "Cystic Meningioma_CNS.pdf": "9BF409CF-2ED2-4434-BE1D-C301529ECE97",
    "Czerniecka-Foxx_CV.pdf": "F105296B-EEBC-4B19-91D6-0DA5E1BA5716",
    "czlonostwoIMG_20150602_0001_NEW.pdf": "874F29CC-2B79-458A-AEDA-5B1D938DE74F",
    "D Hayward CV.pdf": "B0330A44-167A-49C2-98AA-D80EBA477DA4",
    "D Lubelski.residencyverification.pdf": "0D0840FA-7779-42AB-92B4-E64EA57FA5D4",
    "D Sciubba SNS Sponsor Letter.pdf": "BD678A1F-D896-4D32-A26F-87594933DDFD",
    "D Sciubba SNS support letter.docx.pdf": "59F8A661-DA5C-4949-9AAB-FF5D4D3D8D5D",
    "D'AntuonoPOE.pdf": "86804862-93C0-4A76-B7AD-11A68D0B428A",
    "D'Souza Preston Letter of Good Standing.pdf": "5A94B3DC-7151-4D00-A11E-AD0C283680E0",
    "D. Hirt - VER.pdf": "1FE6222A-962F-4DBD-AEF9-67E8193596A9",
    "D. Marotta LOR 1-2020-2.pdf": "38A2A6C9-141D-4E59-8BAE-193692A5D3A3",
    "D. Patel Letter of Support.pdf": "9A3C1DEE-B64B-4926-9CF3-821B9D31F5E4",
    "D876A6B08D98432ABB3FE516EB759FC7.pdf": "C0E8DF56-6CAD-4F87-8A75-94776D2AC184",
    "da Costa_CV June 09.pdf": "95A6FB22-58E0-440C-A9F7-305A1DC4EE87",
    "DAB-CV-NIH.pdf": "EBEEC2EB-7A9D-41FC-BCBC-2630CC5CA90E",
    "DAB_ResidencyDiploma.pdf": "FE8E945A-275C-4969-8DBE-618F46CCDA6A",
    "DACA Poster for CNS 2022.pdf": "1BFF6954-BEFB-4320-A57C-986B732AE5D0",
    "Dacey, Ralph.pdf": "AFC57120-6DAD-4AD5-BCC3-B4F9FABE1B04",
    "Dada_Abraham_biosketch-HerveyJumper_4.12.2023_SES_fellowship.pdf": "6B358F19-FB25-4A19-934C-822B4FBD7311",
    "Dada_Abraham_CV2023___.pdf": "89158D21-50CC-48EE-BD5D-84611B9EDE09",
    "Dada_Abraham_Neuroethics_Book_Chapter.pdf": "D9949B30-1856-42F5-9A02-8F405915A307",
    "Dada_Abraham_PM_LOR_SES.pdf": "FCED8D72-2405-4E52-85E0-DEFC5100A92B",
    "Dada_Abraham_SHJ_LOR_SES.pdf": "33047135-24A6-4C33-AC8E-AF2E678D7D13",
    "Dages_CV_Reduced.pdf": "CFFED5B5-8D06-4CF5-AA3B-0B5BC701430F",
    "Daggubati_CNS PD Letter-032021.pdf": "AE975927-3CCE-43D0-85C4-7287E20B938D",
    "Dagra Abeer - LoGS.pdf": "9D5B837A-8D21-47B9-AB32-E05C1AF63C68",
    "Dahdaleh SNS.pdf": "042AEC4D-797A-4DDC-8C21-4932411DD3B9",
    "Dahdaleh.pdf": "855374F3-13BC-4879-8D87-AD18221AA3E3",
    "Dahiya.MSPE.pdf": "2F672000-799C-44CD-B583-879416076B5B",
    "Dalgo SNS PA nomination summary packet Feb 3 2023.pdf": "F2CC619C-04A3-4D20-91DA-0922CF8935B9",
    "Dallas DRAFT Agenda_v7.pdf": "1E1C827A-AE17-4849-9968-947057D06153",
    "DallasSoyland -RESVerif.pdf": "745F5F78-E944-4DFA-A603-CDEDC9290BD1",
    "Dalton - Enrollment Verification_Goodstanding.pdf": "4C24144D-6CF5-4273-A6A4-CCC17746C9A2",
    "DaltonCV_April_2021.pdf": "172E802F-030D-4866-B28E-7D7DE8E91BFA",
    "Dambrino from CHAMBLESS 2022.pdf": "2361A40E-1522-40FB-93DE-BADAB13D35F6",
    "Dan, Noel G.pdf": "E7CF3061-DBF3-4791-B8BB-BB71AE799C4E",
    "Dan, Noel.pdf": "CB52E96E-894D-4DEC-8D6D-4F86246AF131",
    "DanBranchveredu.pdf": "F16E9499-1520-4456-B4CD-594E9C224774",
    "Dandy pdf.PDF": "8C07720E-FD50-48F7-B58D-F966F647743E",
    "Danehower Sarah Congress of Neurological Surgeons.pdf": "531E8A5E-04F7-495A-91A8-94FE23FE2584",
    "Dang - RUNN Resident Award PD.pdf": "D835B662-EF3A-4B9C-8C52-AF931378BCDC",
    "Dang.Nature 2009.pdf": "36756026-46E1-46B5-9BCE-43E34EA93E2B",
    "Daniel Cleary-RESverif.pdf": "22E0A270-EE88-43CC-99CE-96F7AD7EA061",
    "Daniel Eichberg CV 2019.pdf": "4EC02467-66C9-45C3-86C9-AD0A1A1B0B1E",
    "Daniel Eichberg CV 2022.pdf": "CBBFE07A-C778-4616-9338-6546849A2141",
    "Daniel Hoh - SNS Nomination Dr. Ghogawala 2022_Updated.pdf": "EB537CB4-3414-4A63-BBB2-EC0F1CA024A2",
    "Daniel Hoh nomination letter SNS 10.2022.pdf": "B0289D22-3A61-4AA9-BB8C-4FDFC5D4E9E1",
    "DANIEL JEREMIAH  OLUWATOMI ADMISSION LETTER-1.pdf": "093A3C38-9924-4869-B49E-3A89C68DAF53",
    "Daniel Juarez Rebollar-emailVerif.pdf": "A5495B41-D112-4973-9B8D-6DB10B5D0AD0",
    "Daniel Lee Student Status Letter.pdf": "99484A07-3A64-421A-AB26-7EBDC1274254",
    "Daniel Nagasawa.pdf": "D1A0FEFC-D767-477C-A94D-3DB2C4D0F29B",
    "Daniel Prevedello Nomination Letter.pdf": "224C62CA-4B99-4FEF-BEDC-0A5AEEBA2B71",
    "Daniel Prevedello Recommendation Letter 2.pdf": "966CA963-AF0F-4782-A485-2028266E9163",
    "Daniel Prevedello Recommendation Letter 4.pdf": "2E703456-44FB-4DD8-AC8B-DF95B6B9CD15",
    "Daniel Prevedello Recommendation Letter.pdf": "C3A072FA-74E8-4AE1-9DBC-13CB92F3C329",
    "Daniel Prevedello Sponsor Letter.pdf": "D52441D8-615E-4670-A8A9-1740C4DB8BBD",
    "Daniel Toscano LoGS.pdf": "BDD3BC2D-F1CC-4F84-AEA0-FB00C81B2CC3",
    "Daniel Umansky Curriculum Vitae May 2023.pdf": "7F3B2115-5933-412A-BB97-E9ECBDE61B2C",
    "Daniel Vollhaber-RESVerif.pdf": "726A22FA-5F21-4B29-B5ED-9B8CD3658C1F",
    "Daniel Wolfson_2019_ltr.pdf": "D2658689-7468-43A4-B9CC-937D66DBA907",
    "Daniel Yoshor_LOR for SCHUSTER SNS Membership_102320.pdf": "5C1255FA-97A1-40DF-AE74-17B0FB7734C5",
    "Daniel Yoshor_Sponsor_SNS MEmbership Isaac Chen_10.6.23.pdf": "69B71383-B092-4106-8E41-41CB7975B499",
    "Daniel Zeitouni-RESverif.pdf": "75061082-9400-4CC3-9DBE-6ED530260F6C",
    "Daniel_Raper_-_CV_10-20.pdf": "A858F8E4-2BCB-4808-A65A-8F83287544FF",
    "Daniela A. Abrams CV.pdf": "8B3254B4-70D7-4C59-8FD3-B354ACE2E1F8",
    "Daniela A. Abrams MD FAANS CV.pdf": "B321FCD3-2041-4D7B-85A2-A4C60E71B2E5",
    "Daniela A. Abrams MD.pdf": "489FDF53-DE91-4C02-8278-399E4512FB0F",
    "Daniela Abrams CV CNS.pdf": "4C21A3A4-E2D0-455D-89B2-5DFB3729553F",
    "Daniela.pdf": "9E5AAEC7-9BC3-43C7-BC4E-E4B06E3A5311",
    "Daniels David SNS LOS 8-24-21.pdf": "8D9E4C0A-BC33-4C9B-9566-562294168435",
    "Daniels SNS LOS 20210921.pdf": "C57C614F-3519-4A3F-9B48-A7B7563C5E77",
    "DanielsDavid LOR 8-23-21.pdf": "DF848ECF-CF1C-4944-AB99-6BBF306337B0",
    "Danika L. Paulo-Fellowship.pdf": "13EEE574-1C81-45A6-BACF-540C300D78FD",
    "DanikaFig3.pdf": "16A2DE2C-666E-4401-A04F-6449CAB9D441",
    "Dantas_Franc¸ois_CV.pdf": "64DEF901-DFC7-493F-A042-6C2425523050",
    "Danyal Ahmed TWIC 15-08-23.pdf": "DEF32D18-AC07-4858-93B7-17ADA7834A98",
    "Danyal Quraishi Letter of Good Standing.pdf68.pdf": "4209218A-FAA5-4802-84F4-57293D56B02F",
    "Darbi Leighton Invergo Resume update.docx.pdf": "4DDF3228-B1CF-489A-A2D3-B822C095C849",
    "Dardick Joseph - Letter of Good Standing.pdf": "F53817D7-D01C-4B6B-BDB0-1594A33B6EF4",
    "DarioEnglot_CV_022021.pdf": "D44ED23D-8C4E-4CC6-9840-ED7BC4BAF191",
    "DarioEnglot_CV_032020.pdf": "C753147A-2A1D-495D-8F41-7FE59306BC9C",
    "Darryl Lau CV.pdf": "3C60688A-A3B8-41A7-A74B-830467E85D24",
    "Dartmouth Neurological Surgery Residency Program Promotional Material for CNS Virtual Residency Fair.pdf": "CA698D1E-4029-44E7-BCDD-328FBB9B360B",
    "Dartmouth Neurological Surgery Residency Program.pdf": "A46D9791-D19E-4DF5-B88A-372886ACAE71",
    "Daryn Cass_CV.pdf": "84188345-A53A-40AB-A89C-505541A33E5C",
    "Das gsl.pdf": "E0B1CC5A-8CC6-45B7-A3EC-FDF567C0AF06",
    "Dashti_EVDModel_082521.pdf": "AA04FD62-A512-4AAE-B1BB-7F8431F67D71",
    "Data Science Agenda 5-9.pdf": "50164C9D-40EF-4845-8956-F5B9DE0CD94D",
    "Data Science Agenda- 6-10-2022.pdf": "1240A910-D359-404E-A37A-5C521F7046AA",
    "Data Science Agenda.pdf": "F41A22D4-047B-46B3-9C32-F3FB2EDA40E7",
    "DATA science scholarship.pdf": "0D1667F3-C157-4DE5-BB89-AC231A5E4741",
    "Data tables to include for CNS.pdf": "C94F62FD-737A-495B-9FD0-7AB051C2D30A",
    "Data Tables.pdf": "C60CB2D5-3E28-4CAD-B043-134E42CF33C7",
    "Data.pdf": "6C5651F3-36FE-4FFE-B759-60E5DB7AB57D",
    "DataScienceAgenda-04042022.pdf": "EE764E16-2CFA-4541-B8BD-5E33E1D28BCA",
    "Davachi Verification.pdf": "6A47DC19-876F-4228-AD27-2F15BFE92FDE",
    "Davena letter.pdf": "C9A5F6FB-FA5B-42A4-8CB0-135FF2746EC5",
    "dAVF_poster_10_26_22.pdf": "609E4B61-4830-4090-84D0-3E0F6B09CE29",
    "David A. Zarrin Curriculum Vitae.pdf": "1A10CD55-B721-4B24-82F7-17EBE97EA749",
    "David Agyapong letter.pdf": "180F1BF5-83F7-4407-A6A7-16319E115AAC",
    "David Bass-FellowVerif.pdf": "ACAEE7C2-C062-469D-B1B1-BD462A256176",
    "David Bonda-RESverif.pdf": "56C230A3-80F7-4370-BA1F-C140B32604C9",
    "David Bray LOGS.pdf": "BF68D14B-7A98-4732-8EFA-4E842FD04D40",
    "David Bray-FellowshipVERIF.pdf": "4236507A-8A96-4870-9B64-9DC233C2D659",
    "David Carlos MD - Letter of Support.pdf": "940BA138-47E0-4AAF-97E5-2E078D3C957A",
    "David Cedeño Reyes-FellowshipVerif.pdf": "E96BE8ED-C044-47D6-9F62-051C5A847E48",
    "David Dornbos III_CV.pdf": "C2F02D77-555C-471A-8DFC-8F4A980CE97E",
    "David Eke   Letter of good standing.pdf": "2B836294-E06E-4EB8-BB6E-054384F5D0B7",
    "David Krieger CV 2015.pdf": "467B943A-3326-4D64-B77B-04CC9227C6E8",
    "David Loftus_Letter of Good Standing.pdf": "06B56E5C-A059-4C9A-8FFD-8626595D3401",
    "David Maxfield.pdf": "1FBB0872-9F6E-452D-A96F-08EEDBECE639",
    "David Mazur-Hart-FellowVerif.pdf": "23C297F3-0720-4927-8077-A2B8C39399BA",
    "DAVID MERCADO RUBIO-RESidencyVerif.pdf": "7991D46F-8011-454A-B3FF-FD965CF50861",
    "David Paul CNS Leadership Program LOR - WHP1.pdf": "3F3C5488-5C89-4CAC-9F82-271E0E68C6DB",
    "David Paul-FellowVerif.pdf": "7209C4EA-4EBE-411B-A1F4-96807074EEA0",
    "David Peters-Fellowship.pdf": "F4636F08-469B-401A-920B-665FD8B2C06A",
    "David Satzer - Current Student Certification.pdf": "2ED97448-9072-41FF-BD7E-8A098C293DD7",
    "David Satzer CV.pdf": "F38BAB44-C821-49DD-87C8-5914044938E9",
    "David Satzer-FellowVerif.pdf": "8294BD79-EAC4-454F-BEA9-9EF3D612F7BD",
    "David Smolar-RESverif.pdf": "BABDF09C-F098-4AA1-9C01-DA895D3CFB98",
    "David Zhao-Fellowhsip.pdf": "1EC86B25-C2E8-44EA-89EE-3555865796F8",
    "David_Samuel_Oreoluwa_Admission_Letter.PDF": "26C3CF75-5D7F-459B-B364-1876674CE9E6",
    "Davide Croci CV June 2022.pdf": "CE8A5049-B340-4AD3-B3FF-DE19E4114F47",
    "Davidson Active Duty Verification.pdf": "AB070A4C-E7DA-417B-9D16-CC9B278843D4",
    "Davies Verification.pdf": "E3A6EA61-6009-4F19-A909-14282840E67C",
    "DavinDevara_Letter of Good Academic Standing _Ellis_8AUGUST2022_Final 1.pdf": "2CEA75B2-0A70-4D12-83AD-074C49CC63B3",
    "Dawley Residency Diploma.pdf": "D2477903-01B8-48E9-86CB-395F193E578C",
    "Dawley-Frame Based.pdf": "81644967-63E2-4BAB-A25A-EBABA38B03D7",
    "Dawoud Reem_28Apr2020_Good Standing.pdf": "79524E00-588E-43CE-AF5D-7A9E85028D37",
    "DB 06 06 2017.pdf": "3ACF6422-39FA-4C37-95A5-95E4644A5C5D",
    "DBen-Israel-emailverification.pdf": "8F23012D-A575-4C42-87D6-B60D92708E26",
    "DBI_Digital_Poster_ 2061.pdf": "0AC60CD7-3BE7-498F-804A-621D4C760786",
    "DBonda.emailverification.pdf": "579EBF50-A39D-4EF9-8EB0-562BAB258C4E",
    "DBS & TRD CNS Poster.pdf": "1E85937E-7F39-4A15-B00C-06D48701F378",
    "DBS Seizure Tables_CNS.pdf": "9003F5B2-C61C-4E7E-ABE0-2820A3B256C2",
    "DBS_ASSFN_2022.pdf": "B1161E5D-18D6-46BB-9FA7-DEBED23A784C",
    "DBS_bilat_unilat.pdf": "011D58FE-EBB5-4FD7-A046-3C9AA4873061",
    "DBS_hardware-related_complication.pdf": "44053384-8767-43CB-9115-DE1C346C3113",
    "DC Hotel tax class action from Dec 2016 to present.pdf": "E499E9D9-F683-4F19-9DE5-464D0A2C3563",
    "DC in stroke.pdf": "AF8BE807-FC52-476E-85E7-FDECFDCB44ED",
    "DC in Trauma.pdf": "19155AEE-5D23-4CCB-8CA4-B1D552D61CD1",
    "DCleary.email.verification.pdf": "CBDEF379-D589-4327-921E-0042B3B7AAF1",
    "DCM abstract.pdf": "CCB012DC-88AB-421C-A873-D4272CB3D263",
    "DCM Frailty Table 1.pdf": "9A60A5BE-9972-4B7E-8A60-DA805E070BA8",
    "DCM Frailty Table 2.pdf": "AFD0F5F9-D22B-4CC7-AEF4-6D3062C58FDF",
    "DCM Frailty Table 3.pdf": "BBEB0505-28FF-498C-8C97-DF17F55F00B9",
    "DDawkins-RES Verif.pdf": "9BEF2C4C-4E06-476B-B15A-E73E64D9B479",
    "DDOPedsSectionPosterFinal.pdf": "F59EC2F2-871F-48D1-A872-389D689150FD",
    "De Andr?s Guijarro Pablo - certificado de pertenencia SENEC.pdf": "06CA9221-2F34-4DCA-A768-424ECEB3DDB1",
    "De la Rosa Llergo.pdf": "27508E9A-9DEA-40F3-AB84-1BEEF688AE38",
    "de Leeuw - 2022 RUNN Research Award.pdf": "075593B2-16E6-413B-96C1-797966E9AA18",
    "De Rojas, Joaquin.pdf": "BE2AD142-B15C-4D33-BB23-9BBFCEE9632C",
    "Dean letter for cns Momin.pdf": "3E59D83A-305D-475A-BF5A-E468FC8CFDBB",
    "Dean letter.pdf": "C0FE47EC-0803-44AB-A1BE-58B0464CCC8D",
    "DEAN SUBSTITUTE LETTER PDF.pdf": "9810CDA6-C251-407D-9847-93E5708BE686",
    "Dean verification_Griepp.pdf": "787B8FBE-5B9B-402C-8BB8-3E0A6399400B",
    "Dean Zeiger Letter.pdf": "68FFDD70-08AF-4683-BF28-020CF4216B61",
    "Dean's letter - Member.pdf": "143ADE73-B8BD-4D71-A07F-69D0CA07C9F1",
    "Dean's Letter - Ziya Karimov.pdf": "0F8DE8F3-6255-45F5-890E-BCBB4C4C8C4C",
    "Dean's Letter of Good Standing-KK (12.11.20).pdf": "D4F97FF9-9D97-4E03-ADB7-12822C1CD34A",
    "Dean's Letter Sufyan Ibrahim.pdf": "81863FD3-10D2-4B3A-B9CF-B82B834B7FFF",
    "Dean's letter- Maaty.pdf": "B9A08D4B-C73C-4A9E-A03A-E69FB95FAE0A",
    "Dean's Letter.pdf": "DA4E6DDB-AE36-429A-B4A1-A7E403C910E5",
    "Dean's Letter_CNS.pdf": "C6A5C2F3-D5E9-4767-8A33-DE71027DC8BB",
    "Dean's LGS.pdf": "5995CF22-5AC6-4AF5-9069-21EA4359FB53",
    "Dean's Office Letter Good Standing.pdf": "5FA2C43F-B545-4B5D-8593-74015D0794B2",
    "Dean_Beutel_Tim.pdf": "86541E05-32B8-4ED1-9239-49D5A02427DB",
    "Dean`s LOR-signed.pdf": "B656529A-9B8F-4AF1-9116-B72253BC0739",
    "Dean’s Letter Rigel Jaquish.pdf": "73755FBD-70CF-4907-842D-C1F96BA15069",
    "DeanLetterCNS.pdf": "3E1BDF4C-B2FD-45AB-BBEA-464EF99EE7E8",
    "DeanRobertsRequest.pdf": "C03D945F-0D86-4D36-8EE2-83C4F0491BDE",
    "Deans letter.pdf": "F426943B-2993-45C0-8E9C-2520D9D2AFE6",
    "Deans LOR.pdf": "43B44408-3933-489D-AA3F-5551AF35656D",
    "DeanTunkel_Letter.pdf": "35290F67-5440-4DA7-AAD8-C64F06AE449E",
    "Dear Senator Cassidy.pdf": "189F09F9-8FF5-424A-8F40-9DCFD42A35CB",
    "Deaver Courtney_LOGS.pdf": "EF3E856A-BF5B-416A-8E4E-6EF4CF5C39EC",
    "Deb, Sayantan good standing.pdf": "E9002CFC-9F94-42CC-AB96-E0A02FB6EC74",
    "Debora Ghosh Enrollment Verification- UC Berkeley.pdf": "79DE33D7-1463-4C61-8A3B-B9116EC0A0D1",
    "Dec 2022 journal article.pdf": "41B389A9-F2B0-4E32-9352-D3A0FD7E87F9",
    "December 1957 Newsletter.pdf": "767EB9A9-92C0-471A-925C-5754E70B4C4D",
    "December 2020 Podcast Article.pdf": "6423BB29-794B-4FE3-99A0-AF379FE4C829",
    "December 2021 journal article.pdf": "83594D26-3F46-4711-B179-982F92B52C87",
    "December 2021 journal article_updated.pdf": "740797B5-19A8-49CC-8DE5-D7457901EE8F",
    "decl.pdf": "24A6F9D5-A9AE-488A-BE7B-6FF0DBF315B7",
    "DECLARAA~_A~_O DE SITUAA~_A~_O ACADEMICA.pdf": "58EECAD5-BE4C-4A5E-95D9-1BB244A1631D",
    "Declarac¸a~o.pdf": "29ED7E95-7111-4191-86F9-91CAD1516451",
    "declaração de matricula 2019.pdf": "340A80C1-21F1-426F-AE3A-4E9CF6B5B97F",
    "DECLARAÇÃO DE SITUAÇÃO ACADEMICA.pdf": "C1088AE5-FEDD-43E0-B1A1-81D36E138EA1",
    "Declaração Marcelo Ortolani Fogaroli_R2 em JAN 2022.pdf": "093A5634-7C76-49ED-975C-C7531A58A219",
    "Declaração matrícula -Neurocirurgia . Dr. Aramis Kessler Agostini.pdf": "C17A94B6-61ED-4C56-BB58-52E0F318E649",
    "Declaração para CNS-1.pdf": "15B85F13-F34E-4726-B442-0302A60B7486",
    "declaracao.pdf": "556EB6DB-BAE0-4438-B6A2-4BA5C18E5061",
    "declaracao_Gabriel Pieri.pdf": "54DB3C31-12FE-4F24-A98C-938D157BC1E4",
    "declaracao_matricula_DEISY BRIGID DE ZORZI DALKE.pdf": "6BE550D9-73C5-4697-A959-DE557F231952",
    "declaration letter CNS.pdf": "9CB0D09B-A577-4E3B-A41F-139F3E161EED",
    "Declaration of Residency - Sara Sequeira.pdf": "B1141ECE-55B5-4BD9-8656-59F49C9FFCD5",
    "Declaration PGY-3.pdf": "58DCF4D7-13C8-485F-A6BB-EC433DBEE062",
    "Decompressive Craniectomy in Aneurysmal Subarachnoid Hemorrage.pdf": "204662C8-80C9-4088-B70A-8806511C094D",
    "Decompressive Hemicraniectomy After Aneurysmal Subarachnoid Hemorrhage.pdf": "4FDE18B8-67AC-4013-91DC-AA77166F6B9F",
    "Deema Nezar Sehli Jan 2011.pdf": "4B84ACB5-2E2E-4B18-B447-2BB9477CF8C3",
    "Deema Nezar Sehli May 2011.pdf": "AF28FC2B-4ABF-421B-9778-5FBAB08986A9",
    "deep-brain-stimulation-parkinsons-disease.pdf": "CBC52F17-FF3D-4937-BFA3-6242A6CDD6F4",
    "deep_brain_stimulation_for_obsessive_compulsive.11.pdf": "E1E181C4-4DB8-43E1-92B6-DFCDA697859D",
    "Deep_Venous_Thrombosis_and_Thromboembolism_in.25.pdf": "E739030F-8EF9-4839-AA92-7BDA194DA9D2",
    "DEEPU BANERJI NSI MEMBERSHIP.pdf": "93AE1395-D585-4B0B-B401-22B11651DA36",
    "def8dbaf-5371-4de8-a274-879668c03dce.pdf": "10E9F4D2-2979-4947-A615-F1948C0F8430",
    "Defmority correction.pdf": "D55485C4-9E02-4B89-B207-7049CF2FC0E8",
    "Deformity LOS Readmit CNS 2019 Poster.pdf": "A871C807-742F-4B39-BB38-CAC1E07A40FF",
    "Deformity Prolonged Intub CNS 2019 Poster.pdf": "542C4CF2-8A8A-4D50-A26F-567538F78EFE",
    "DeformityBMP Data_Final.pdf": "82F4FEBE-2707-42DA-93F6-AF1047C15E31",
    "Defying The Laws Of Cerebral Reva.pdf": "03DA63E8-600F-424E-A692-03E1DD4CB2F2",
    "Degree FCPS.pdf": "CAFFE893-61A4-494F-A511-9080E438E41B",
    "Dehdashti SNS LOR Ullman.pdf": "EC5B415B-BB55-4EFC-98C7-F9F8E9586DB7",
    "Deisy_De_Zorzi_-_Neurosurgeon 1.pdf": "C5FB1AC3-F193-415A-97C3-0985DEE30AD8",
    "DejanSlavnic.RESverif..pdf": "A6FAE425-8227-4FE8-A68C-A587D2508075",
    "delavari cv 2022 July.pdf": "C11DB81E-0D92-4A12-A7F3-692737ACDBC4",
    "delben_025_xml.pdf": "FCB85922-885D-42F2-9576-034E17669016",
    "Delivering Bad News Article.pdf": "8A94983B-958A-42CD-985F-192293D91C2D",
    "Demetriou Alexandra GS 5-5-2023.pdf": "F9897917-B028-44A4-A02B-2227E664A42F",
    "DeMonte, Franco.pdf": "C41CEDD3-BC64-4FE8-AA35-F494669D1883",
    "Dempsey, Robert J.pdf": "A1C93114-F99A-401F-87A2-5E515B96BF59",
    "Dempsey, Robert.pdf": "C76FEAA2-3A70-4943-9A04-0A62D98EC713",
    "Deng_Hansen_CNSPoster.pdf": "8456C874-C11A-405B-A13E-3FC88CCCC7DE",
    "Dengxiaofeng.pdf": "80847E79-F9C3-4502-A1FD-3029327E1EEE",
    "Dennis Lee Enrollment Verification Letter.pdf": "786ABA69-4FAC-4C15-8A88-C4A0DE502293",
    "Deographic Poster 2142.pdf": "ED97195A-EC63-4BBC-ACEC-E1D822D476C7",
    "Department letter.pdf": "5BAAB340-2069-4964-B471-81F533C6F521",
    "Depression.pdf": "A7FCAE50-1BFA-4DA0-AC12-B3E67EFBBE7F",
    "Deputy ED  Staff VP Strategy.pdf": "E93FA7AE-DDED-49D9-8E8F-CF2051FDE6A0",
    "Derek Barnett letter.pdf": "4BC76212-AFAC-479B-B431-5598CEAB40FD",
    "Derek Southwell-RESverif.pdf": "EC1204DF-4760-4E04-BE78-339321F25E99",
    "Desai Shivani - Letter of Good Standing.pdf": "5906E02B-F4F5-44AA-8CB2-948762516EC7",
    "Description of project on Basic or Clinical Research.pdf": "838EFED8-8233-4011-A964-70927D2EC467",
    "Description of project on clinical research for IOP 2022.pdf": "A204FE60-D692-4B65-9301-B3E341AD61E1",
    "description of project on clinical research.pdf": "B8D278DF-C192-4397-8720-2C02BCF9D2D3",
    "Deshpande Nachiket Letter of Good Academic Standing 1.6.21 - aj.pdf": "E2EA9B33-B2C5-428A-85F5-B3AB389589F3",
    "Desir Defying The Laws Of Cerebral Reva. 032390.pdf": "621686FA-383D-48FB-9BB6-9F25F09B9604",
    "DeSpenza_Tyrone_GS Letter.pdf": "4772F383-1F71-430D-A118-4C8998F863EC",
    "Destiny GreenCV_2022.pdf": "24C88FA0-576B-455B-A802-28EC348F0DAC",
    "Destiny GreenCV_2023.pdf": "DDD2B1C5-13F8-4425-90F7-155A24BF8823",
    "Detection of metabolic pattern following decompressive craniectomy in severe traumatic brain injury A microdialysis study.pdf": "17C4F87F-3244-4552-ACA8-E27AB08F8132",
    "Deuschl ET registry CNS 2023_poster.pdf": "0B973BC9-1DD6-48F4-86D1-CD89C9286598",
    "Deuschl_ET_Registry_2024 ASSFN_Poster draft_FINAL.pdf": "113C5501-07F0-49BF-B95C-A75D8C2B3991",
    "Dev Patel letter 201821122018 (3).pdf": "D9334851-CF15-4365-9714-5C1959F8A8B1",
    "Devan Patel - CNS 2019 Poster Final.pdf": "8E454D8A-2AEC-4BCA-A5E4-6D2D077CA06E",
    "Development of Machine Learning Models to Design Target-specific Ligands.pdf": "19595194-9D03-44A4-845C-C963BE65F011",
    "Developmental Stages.pdf": "54A7FCB1-56A8-429F-9353-92CF51216ACE",
    "device_tax_repeal_coalition_letter_114th_congress_1.13.15.pdf": "64A902C0-6297-4976-9780-2FA05E8B4983",
    "Devulapalli Rishi Acceptance.pdf": "4095571D-0054-41FC-ACE4-5972D5001E7F",
    "Dewan.CV.pdf": "92308BB5-1921-4F2E-8893-FA345131163E",
    "Dex Abstract.pdf": "95B0B8CD-D8A0-4FB4-9A4B-A5D84E879B7A",
    "dex tbi poster.pdf": "FE7995DD-82E2-4FA4-8C4E-DBBDB4B3BF86",
    "Deya Abu Reesh-Residency.pdf": "0B2917DF-FA2D-431C-AF1D-418813C5342B",
    "Deya Abu-Reesh Neurosurgical Oncology and Radiosurgery Fellowship Acceptance Letter.pdf": "456EBE8D-F8A5-4DF3-9990-641196E2E685",
    "Deyaaldeen Abu Reesh _Resume_CNS.pdf": "BFB4B7BC-9507-4EA0-AC53-02C21A359071",
    "DeYoung Chloe - Letter of enrollment and good standing.pdf": "AEA90F76-039C-4910-933B-0DCEC6088C95",
    "DFossett_Biosketch_Final.pdf": "81996D78-7795-491E-A9B7-204D9A601607",
    "DFreeman.emailverification.pdf": "4A6942E6-B41D-490C-BC66-6B0BD9C13456",
    "DGD FIGURE 1.pdf": "3B1F6962-2AD9-4254-BF15-7CE5376A1E7E",
    "DGD FIGURE 2.pdf": "6486ECDF-A981-4C1D-84E4-261F7FB85915",
    "DGD FIGURE 3.pdf": "6463D983-C4FF-4311-8CD6-02E6D3ABA036",
    "DGD FIGURE 4.pdf": "4D0A0687-4982-4831-94A0-5B1007B3668D",
    "DGME Cap Extension Letter to CMS 081320.pdf": "9AF46F94-5F6E-4890-B37D-6C8F05D7CADC",
    "DGNC Certificate.pdf": "DABCC84A-DDF7-4498-B871-5D857D352815",
    "DGNC-Mitglied.pdf": "B41ED40C-CA19-4A41-92D7-2389CCB097DF",
    "DGNC.pdf": "96391F76-B12B-46D7-BEC2-1FBA82EBA42B",
    "DGNC_Certificate of membership.pdf": "5837B2C0-80AE-477F-A5B3-A0F8E7B4F8BE",
    "DGNC_Membership.pdf": "AEC6EE4E-AB95-45B3-9ED6-E84955FC66F4",
    "DGNC_membership_confirmation_Ringel_14-06-10.pdf": "30F5DB92-59D5-4E94-B43C-CB1C305B5921",
    "dgsom enrollment letter.pdf": "37EA1445-4CE4-4A86-9B55-B3DC5DF53AE6",
    "DGSOM_Enrollment_WangAndrew.pdf": "8A6982A8-4791-4176-A4D9-CCF634B4D1C2",
    "DH CV 2020 for CNS.pdf": "CBD71809-19B7-4893-BC70-51F773078836",
    "Dharnipragada letter emailed to student 11.18.21.pdf": "F8D5E564-FDF7-4004-98C5-9C5CC83404CA",
    "DHarris SP24 LoGS.pdf": "8501225B-98A0-407D-9FA0-069E32AE443F",
    "DHarris.emailverification.pdf": "03C19FDF-F631-46E0-AFD2-F8D0C1877161",
    "Dhawan_verification.pdf": "3B7138FA-413A-48EA-9A6A-87EE935D9BEB",
    "DHC CNS poster.pdf": "9698DE8D-5EDD-447A-B95A-88C8BB7174FF",
    "DHC Table 1.pdf": "A57C62DF-2B8A-4539-8766-98A41FB52275",
    "DHC Table 2.pdf": "5F3A28F6-603C-4217-B55C-DC32DF2F367D",
    "Dheeraj CV 05_2024.pdf": "CD9CF1E4-9A1F-4265-82E9-526FA14B32A1",
    "DHeiferman_CV.pdf": "975E2D7C-9012-4FDB-8BEE-DCFB0BEEA99D",
    "DHiggins-Residency diploma.pdf": "787E30AD-F45C-4968-A8BE-51817CBB6DF3",
    "Dhillon Nanak LGS 4-30-24.pdf": "348B538B-5D73-4A5E-8135-D4D2B7A6AB8C",
    "Dhiraj J. Pangal's School Enrollment Verification-Letter of Good Academic Standing.pdf": "BFC9D66E-8059-4763-9831-831FBB464D8B",
    "Diabetes Fx and SCI.pdf": "782A2244-6C6F-4F7B-B8B1-B5499B0627EC",
    "Diabetes TBI.pdf": "279DFECC-2C74-4D7D-A41A-3082440B3C2F",
    "Diagnosis  TAB.pdf": "C44D0DB0-5D1C-4DD7-B6E9-BE3B9A08324A",
    "Diagnosis.pdf": "E4B03775-13C4-445A-8260-9913B2600494",
    "diagnostic_imaging_position_statement.pdf": "FAA05E94-8B86-4A56-ABF1-5DBC0CB0E51E",
    "Diagram 1.pdf": "DF3D8F0B-1C13-4F38-B9CE-B859BD7B202F",
    "Diamond Joshua.pdf": "1538EA39-E6C9-48F5-A900-B078D11BCC26",
    "Diane Aum - Good Standing Letter.pdf": "C4F8626F-622C-4DF9-A3BC-7F027D639FB6",
    "Dibble CV.pdf": "16098D43-D26B-4665-A0AE-552DBDDA17EF",
    "dichiarazione.pdf": "26A7BA3B-2AE8-4C21-B320-9EA9E484611D",
    "DIEGO ALVIS PEÑA-RESverif.pdf": "B2AD92C5-3F22-4096-B9CB-FD2AA0856665",
    "Diego Soto Rubio - Email Verif.pdf": "D999D121-F257-4F28-9328-0CF58B7243E0",
    "Diemath, Hans Erich.pdf": "8259A378-21FA-47BF-AEAD-7AD62BB1F666",
    "Differentiation of radiation necrosis.pdf": "CC94A098-33D6-4063-B9BD-CF07A4DFB192",
    "DiGiorgio.pdf": "509298B3-F586-4B63-A559-3D0321B26559",
    "DiGiorgio_CV.pdf": "13E0430C-5165-4D56-8E35-7D791644E009",
    "DiGiorgio_LOR.pdf": "D0876CB5-DB1D-4AA4-86E9-BF35BA6999F7",
    "Digital Poster - Rajiv Swarup Final Draft Maria.pdf": "0CCF768D-D507-4653-ADA2-D7551849FEF0",
    "Digital Poster San.F.ML.pdf": "3839BF72-0E62-4893-8392-ADCD718E56BE",
    "Digital poster_037731.pdf": "13522929-9951-4635-B9F9-790302ABDA56",
    "Digital Poster_AVM.pdf": "87DEBB60-92E8-40F6-8749-ED38C90458F5",
    "Digital Poster_CNS 2023.pdf": "F4923A93-45EF-4625-8F4F-575EF66B7C42",
    "Digital Poster_EVD.pdf": "08DFAB94-C03D-46A1-AFE6-53A351ADFCEE",
    "Digitalização 21 de out de 2018.pdf": "5EB20AF2-F7B8-449F-813B-BD7E9136E27E",
    "DigitalPoster168(OralPresentation).pdf": "FF37960E-9CA7-4331-8118-A75E9B52364B",
    "DigitalPoster2041.pdf": "354A5E80-6CC6-4BC1-88D7-34B3CA24EEFB",
    "DigitalPoster2053.pdf": "FA95F958-9FF8-4246-9118-C47E532CD049",
    "DigitalPoster2236.pdf": "863EC826-E189-4F2E-AA5D-F7CDEE127F97",
    "DigitalPoster2396.pdf": "5CD6F816-021E-4D4F-A5F2-463D5717D7D5",
    "DigitalPoster2485.pdf": "6580306E-F5EA-4BDC-BF53-A9A6F2240697",
    "DigitalPoster675(OralPresentation).pdf": "64D8828C-2F15-4359-A333-15D5797CB3BA",
    "Digitial Advertising.pdf": "CAB31EF6-AE16-437C-B108-92F60A3A895A",
    "Dilli - Curriculum Vitae.pdf": "8C03045A-B9A5-4EC4-895A-2E9953AB3597",
    "Dilli - Verification Letter.pdf": "06C99D08-4F9B-4EC0-87D9-75F63EAB0C89",
    "DilmarethNatera-Resume.Jan2024.pdf": "26E2875E-2784-40F7-8E0F-AC78E3BBC002",
    "DIMENSIONS AND INDEXES IN 4V ADULT.pdf": "B708EDE4-CFDC-459F-97EF-E031F434CB8F",
    "DIMITRI LAURENT-FellowVerif.pdf": "AF5DD2A0-8349-4C5D-BF83-7240F6072539",
    "Dimitrios Mathios CV June 2023.pdf": "24F282C6-C87D-4160-886F-45A7CC716858",
    "Din Verification.pdf": "F09EFDEB-C8D4-4525-8A52-72DF7C95C19E",
    "Dining Guide Grady_Restaurant list_011620.pdf": "9AE6EF5C-D79A-4ABD-8E0A-66EE8806957A",
    "Dioso Emma - CNS - 2022.06.06.pdf": "1AB1352E-AEFC-4834-90A7-89ED26199A9C",
    "Diploma Felipe F SBN.pdf": "485DB099-3C62-4A15-9692-45C0E58B4E72",
    "Diploma first portion of res..pdf": "8FDD4CD7-1BB1-4D1F-8D32-DEB94C959743",
    "DIPLOMA Medical Residency in Neurological Surgery.pdf": "A1A85619-2B5A-4056-B0FF-F60DCEA02AB9",
    "Diploma Medicine specialist in neurosurgery.pdf": "966A2DBE-623C-4083-A289-5894E6906803",
    "Diploma N-DBT.pdf": "E518B698-AC8F-44CE-A77A-E44BBCB1E6A5",
    "Diploma neurosurgery---Fabio-Grassia-.pdf": "0032B7B3-1D72-4828-8CFD-27B50FF232B5",
    "Diploma of Neurosurgery.pdf": "DB799F15-8D35-4196-9F8D-066375A02923",
    "Diploma SBN.pdf": "47DA2153-0F38-40BB-A05C-56365C20797E",
    "diploma.PDF": "429AAF80-548A-4AEF-87C0-1DD524FA7257",
    "diplomaOSUhoang.pdf": "44CC6C81-91CF-413C-B231-D61A5108704D",
    "Diplomas for Greg Imbarrato application.pdf": "489C767A-66DF-49D1-9A90-F22E4BA046E5",
    "Diplome DES.pdf": "BF22678B-0A7C-438C-A168-C3DEFF9103C0",
    "Diplome DFMS.pdf": "A6282783-544E-45EF-B430-6D8ACF62EA48",
    "direct to OR poster.pdf": "C8E74CC8-798D-44BB-8D8F-3DFD7A25AEED",
    "Directions to Woods Hole.pdf": "4974F9F2-D073-40B8-B783-4377D6AA6046",
    "Director Human Resources.pdf": "912C83D0-63EB-4AA4-A4DA-246CC5522F7E",
    "Director Marketing and Communications.pdf": "3C669548-8955-4BBB-8650-6C132E1C3124",
    "Director of Development.pdf": "A4FB4D1C-DDA2-46B1-B14D-1C37D52529A9",
    "Director of Evidence Based Practice Initatives.pdf": "BABA6E56-8FDC-4532-8EA3-BEBDEA8FC9AF",
    "Director of Meetings and Programs.pdf": "C2651D6B-2DB0-4FE3-BD16-3C7649899CFE",
    "Director of Membership  Foundation Operations.pdf": "0B7B446C-069A-4864-8D45-00BE0FE50088",
    "Director of Publications.pdf": "0E435D07-3952-41F8-866F-C6F4C84606BC",
    "Director of Research  Business Development.pdf": "57A4665C-0F68-4293-9318-185C35BED500",
    "Director of Resident Program.pdf": "40724EEE-2113-4DC8-B021-0EEC2C78AE54",
    "Directory of Neurological Surgeons 1962.PDF": "415F6773-6DA2-4D28-B14E-C34959DA5783",
    "Directory.pdf": "83BC7942-F4BD-4FE6-BD82-49F3D659DA4E",
    "Dirks LRMC PCS Order.pdf": "58E8D4B5-27D2-4704-9C29-777DD7C6B99E",
    "Disaster Primer.pdf": "D4C3A1A9-6073-4575-9EBE-C5CD3A70CB42",
    "Discharge_wo.pdf": "2C5C9EBE-C61F-4ED6-869A-3F02E9EE9828",
    "Disclosure slide.pdf": "55059976-1FF1-4DEC-9120-5F14B0EB992A",
    "Disparities CNS Poster Template.pdf": "EFA274DA-9842-4C5E-BCDC-D758098DD384",
    "Disparity_in_Pediatric_SCT_Clinical_Trials_BCH.pdf": "B903682A-21DB-4073-A57F-2297DCF03C6C",
    "Disparityv2.pdf": "AC4BBC26-F759-4F1B-B29E-300B4B6A6893",
    "Display Internal Academic Record.pdf": "568DCD8A-3058-42ED-84EC-4D159435BF2F",
    "Disse letter 5.19.pdf": "3C420F37-3332-4199-A5EE-22560C564904",
    "DISTAL NERVE TRANSFERS CNS 2023.pdf": "F70A3BA9-FD0D-4A31-B616-E056CBAF650E",
    "Distal ROM.pdf": "8215EFD9-AB28-43F2-ABEF-6298764B176E",
    "DISTINCT POSTER_CNS 2023.pdf": "98C85661-CFEF-4D4E-BC08-4D1D403C75CA",
    "Disulfiram-Copper reduces stem cell-ness and prolongs survival in pediatric Medulloblastoma through inhibition of NPL4 .pdf": "58C9FE96-9556-4209-82A1-BFD485F5FA92",
    "DIV Contents.pdf": "8247E9BB-5615-4A38-9F26-9F8E0920E9A9",
    "DLB.pdf": "01ACAA70-5179-44C2-BD33-AE31F9262963",
    "DLCFA poster.pdf": "16AE88C5-446A-45E9-8DBE-2D0C3132C5DF",
    "DLewis_enrollment_verification2021.pdf": "13DFACC2-3FDA-43D1-9509-59F358EB565B",
    "DLM_LOR_CNS.pdf": "5B3993D6-4E3A-42EB-9BB0-4C026FF0BB58",
    "Dlouhy K SNS Letter.pdf": "F735856D-496E-4BA2-AB98-6C8A663E55E6",
    "DMann-RESverif.pdf": "AA12EE33-0064-4D5A-A3B6-23ABA252E24E",
    "DMG CNS Poster.pdf": "680738C6-73A7-4DC3-B234-719F230676C8",
    "DMS CV 11-2021.pdf": "42D52E82-1EE7-48AB-8F73-258A40FDCE80",
    "DMW_Massie_012023.pdf": "1582702F-FEF8-4516-A195-999F1613B826",
    "DMW_MYO_SNSMembership2021.pdf": "D6B87513-2A10-4221-9CCA-47BFAED2FE1B",
    "DMW_MYO_SNSMembershipNomination.pdf": "584033D2-FD55-4204-A359-5D9B832A00D6",
    "Do Lim CV.pdf": "A23F47E8-D4C3-4D76-B46E-510F5B522167",
    "Doc - 6-13-16, 2-48 AM.pdf": "D73C12E3-8E45-41ED-A17C-226D9A7738C7",
    "Doc - Mar 11, 2015, 3-14 PM.pdf": "7DC14F61-5313-4766-B125-57EF670EB89C",
    "Doc - Mar 23 2018 - 10-20 AM.pdf": "509AAEAC-7978-4F31-A803-907E5FA03A80",
    "Doc Caucus SMB Letter to WH.pdf": "E8B46D37-17BC-4612-BA12-E21A09A596FA",
    "Doc Mar 14, 2017, 16_23.pdf": "79858A28-60E8-4825-8FF6-BE66C0787EBC",
    "Doc Mar 22 2020.pdf": "2C87E506-45F3-4951-942B-6C19D6AD98AA",
    "DOC-20221011-WA0016. 1 2.pdf": "F1944678-E1CD-4A5D-822A-999A614A824C",
    "DOC-20230302-WA0001..pdf": "6343D498-45E5-4978-B4CB-3C38FA6BD41C",
    "DOC.pdf": "8ED90A65-4136-4AEF-BE40-02C4CAA640AC",
    "DOC_100223_NSA_Trainee_Invoice_-_L_Lai.pdf": "B40C290E-71DD-407D-9D46-07FC57F6CD17",
    "doc_caucus_letter_to_wh_re_surprise_billing-arbitration_final_-_signatures_05.22.19.pdf": "F1FB0C16-0793-4AFC-9CF7-ADE10C691E0E",
    "DOC001 (1).PDF": "424B3DFD-C2A2-4485-AECD-E01A49A02BD1",
    "doc00155520210217170331.pdf": "510A7797-AC91-487F-841E-8FE9F1E73C67",
    "DOC013121.pdf": "829BFD17-4B9A-428F-A861-DEE17662F110",
    "doc01956820170525115547.pdf": "FF0ED171-706B-472E-9BFF-7014B153432D",
    "doc02795320210209092344.pdf": "5D55C709-773E-401F-BAC1-9D7EF0472C8D",
    "DOC032023-03202023132214.pdf": "3951CEBD-44F3-40FF-9F97-DB4F2ACF16EA",
    "DOC041217-04122017162440.pdf": "969E7646-8352-4C0E-8370-0C86B3D1C970",
    "DOC062912.pdf": "CF72D829-999F-40C9-8AB3-4AD2E08AE3CD",
    "Doc1.pdf": "5F56B5E3-C7C0-4DEE-B6C8-43D1CA427B82",
    "Doc2.pdf": "000FC483-57AC-4F90-8F0D-CDECF99B7513",
    "doc20141029115604.pdf": "922D79B1-5058-4B54-88C9-8BE512051A7C",
    "Document CV new.pdf": "C6D0556C-6753-4D50-95B8-90A17FAB4FD4",
    "Document.pdf": "1E833FCD-B210-4281-B813-C42A8FE31BE3",
    "Document_164.pdf": "304F2838-B442-4BF3-8480-058CA63FE120",
    "Document1.pdf": "164FFF3B-0254-48F5-9459-54F4C545BE6D",
    "document2008.pdf": "815438DD-0BDD-4CE2-91D5-411EC7CA5010",
    "document20100901.pdf": "D2155EE8-CE10-4A34-ABDA-DDBD386841AF",
    "document5.pdf": "D7EC002F-E6B8-4EC1-A80A-1036012191EF",
    "documento_1356.pdf": "36EB8B97-2C85-4D38-9FCC-3A3AB140B7C3",
    "Documentos_Estacio.pdf": "E186F0C7-00F9-45DA-8755-6EAB8B697892",
    "DocuSign_SOM_UME_Request_for_Letter_of_Good_S.pdf": "C78E1D74-D58F-44C5-8E94-47BBB7C5ECE9",
    "Dokumentation member Danish Neurosurgical Society 2013.pdf": "709CAA67-CCE4-4E45-BC4F-06643117D496",
    "Domino Pub Fellowship LOR.pdf": "A6BAD398-8A1F-4AE1-9447-4A042E4883F7",
    "Domino Pub Fellowship personal statement.pdf": "26CC160E-4C49-4ADF-918E-00E216376B7A",
    "Domino Pub Fellowship Time Committment.pdf": "950F6860-DED0-4B36-96D2-C87371EBB92C",
    "Donnelly verification.pdf": "2FB9E1ED-07FF-416C-919E-172C36D71B0B",
    "Donnelly_Standing.pdf": "12133F23-E9A8-4A25-85CC-8766F40D5230",
    "Dono Verification.pdf": "C72FC959-5AC8-4AF4-9F85-09DC08512862",
    "DoorenboschCV2019.pdf": "DEFB683C-9943-4357-BA19-737BE84ADEB7",
    "Dorian Kusyk-Fellowship.pdf": "5B351531-114B-4038-9F2D-E8A4802A89CC",
    "Dorilio J-CV.pdf": "04E829C2-905E-4AAF-9142-790BB856C382",
    "Doris Wang CV 042019.pdf": "2BD288F3-FA5D-4E61-93F2-00E205D3EED2",
    "Dornbos.pdf": "81262B14-C8E6-4616-85AC-BE9CC810E858",
    "DOs in NSGY_CNS_2023_GMK.pdf": "BCA6D4E4-869D-4DFF-B2AB-F7B5AED3EBAB",
    "Doss_good_standing_20220311.pdf": "005FAE6E-E8BC-410B-A60D-78FE613C3595",
    "Dossani Verification.pdf": "7031DE41-846C-4D23-B407-3A20FB4AA87E",
    "Dow SNS packet.pdf": "1EEF7A31-BEF5-4989-988E-00BC01606A2C",
    "Dowell.pdf": "41DF3F8B-BE4C-4F56-A2D1-EB9375D7B0D3",
    "Dowlati_CV_07-20_.pdf": "F602DF92-15EF-4EC8-A71C-004DD5D2A580",
    "Dowlati_CV_10-22.pdf": "7A9236CD-6E39-4C53-ADA7-9F3709F4AB24",
    "Downtown Charleston Map.pdf": "7C95D628-8800-4BA6-A0EE-486B7E453BB8",
    "DPeters CV.pdf": "60DB44DC-8A78-46AC-913E-81849E392D50",
    "Dr  Anup  Parameswaran  Nair 1-1-2 pdf.pdf": "44373E65-0851-4FAE-8320-DC41F77570A0",
    "Dr  Anup  Parameswaran  Nair cv pdf .pdf": "9FBBE3CF-78B6-4C0F-A1FA-89262CF37587",
    "Dr  Winkler.pdf": "DAEAB566-196C-4424-B04F-9BBEAA15A972",
    "Dr Afzal Sheikh.pdf": "9A8522C4-A8CF-4AA0-B927-2674253B7825",
    "DR AHMAD.pdf": "A54DE3F5-DA16-49EC-B86C-125B1B7B92A6",
    "Dr Ajay Bajaj Biodata-2.pdf": "4AB78068-3E2A-4FB8-89F2-159C1CDB299E",
    "Dr Akash Rambhau Dangat. Neurosurgeon.pdf": "0DA9B886-3DC1-465B-8D90-7C90A0B64B02",
    "Dr Amit Agrawal.pdf": "AEB4B711-CE24-4E1F-987B-FC5BAADE36F7",
    "DR AMIT THAPA CV Nov 12 2023.pdf": "E659F4F0-590D-4D68-AE7C-9B206621222F",
    "Dr Amjad Qureshi Invoice.pdf": "AA56875C-49CC-42A3-B593-4101999C9E53",
    "Dr Amr CV.pdf": "1E0785C9-3A2D-4468-B417-036C232FC176",
    "Dr Aneel Kumar Puligopu.pdf": "0EAC888A-D5CE-4731-B125-09D0274F9821",
    "Dr Antony Thomas 2014 CV (1).pdf": "A6C1414B-6412-41AA-B094-245729C6837E",
    "Dr Arvind Malhotra MNS-30.pdf": "AE4C14B0-A7A8-4EEA-BBB4-82EC0717E4A8",
    "Dr Ashis Patnaik.pdf": "804CA5C8-7C79-4447-BCEE-9156F96CEAF8",
    "Dr Barajas Llanes Welcome Letter.pdf": "37B962E1-D55F-4EA4-89CD-A3B3F10AB721",
    "Dr Chou CV.pdf": "6DA99538-9E66-4B11-AE16-506600CEC844",
    "Dr Glasser CV.pdf": "15B3D98B-8202-46E0-B288-734ED32CFEC3",
    "dr harsh deora (1).pdf": "7DFF615E-9FC1-4F5F-8481-3E50B1D3FDDA",
    "dr hayward cv.pdf": "A14B947B-C0B3-45D3-8910-A58465BE28BE",
    "Dr Jagdish Chattnalli (CNS-52).pdf": "399E57E5-5254-452B-A866-88F067770A85",
    "Dr James Chandler for Dr Sanusi .pdf": "6686DC44-E6D8-4DB2-9FBE-AD6CCA5AA0A3",
    "Dr James T Robertson retires.pdf": "E73BC394-3673-4CC2-88ED-0B7CC7DF7142",
    "Dr Jawad CV-1.pdf": "8D951CCF-FCA5-47EC-9934-B733A8C54931",
    "Dr Kunj Bihari Saraswat CV.pdf": "D3E2E977-5109-4B88-8880-51EE128517FA",
    "Dr Lara Moraly.pdf": "0B829538-4048-4040-B901-15B87CD24175",
    "Dr Lin reference - SNS 9-6-2022.pdf": "D9C86013-EDB8-4EED-B671-34D60C6F07C5",
    "Dr Lingaraju Neurosurgery Resident CV.pdf": "FB9E36DF-D238-4F55-B231-013900427C4A",
    "Dr Lynch CV - 2014 Revised 1.pdf": "FE356F6B-182C-48E4-8E2A-A18F6EA278FD",
    "Dr Mark Proctor Nomination Letter for Dr. Edward Smith_Signed MP.pdf": "B707E7B7-02E0-43BD-BDC8-8CF54DD182B8",
    "Dr Marlon Reid CNS.pdf": "3165C01E-F73F-4B7F-8DBB-355259637167",
    "Dr Nagm CV October 2021.pdf": "80F9D51A-843C-4C6F-B313-9C9F2A1297CD",
    "Dr Nelesha Darby CNS letter.pdf": "741A38C4-C489-49E5-8D24-4D21ECD5C1FF",
    "Dr Nicholas Robinson CNS.pdf": "1858EDDA-7ECB-4114-AF36-FF4D1C788728",
    "Dr Nwaribe's AANS Affirmation.pdf": "8816E094-CD27-44EC-855D-4D9664729041",
    "Dr Padilla's CV.pdf": "9F8E8FB8-E2E3-44AD-8930-2993985B472A",
    "Dr Ramesh C V (RNS-129).pdf": "32731E65-9B3E-4BC5-82B3-8476AD9C0E5C",
    "Dr Ramesh Chandra V V  (CNS-45).pdf": "24A5D73C-86C3-4398-888E-FEF85BA0A3DA",
    "Dr Riaz Documents.pdf": "B3A901C6-C198-4563-A671-27B4FABBFFBD",
    "Dr Rida YasinResume_211105_141322.pdf": "D12B6B18-D7CB-42DA-9DCF-8A4AE08AACF5",
    "Dr Roy CV.pdf": "A899FC34-D0A3-48CE-A195-4E92E595FBBF",
    "Dr Rupesh Raut CV.pdf": "7C464D30-A6B6-4D67-A9FE-00C3559160C7",
    "Dr Sander Connolly Nomination Letter for Ed Smith.pdf": "DB82BEAC-B92D-4C21-9C7E-D16D8547A87E",
    "Dr Seraj lor .pdf": "30C284BE-6B8B-40B7-AB19-1D77EABD011E",
    "Dr Sharad Thanvi (TNS-35)mail.pdf": "FCB3A4CD-BA5A-450A-823F-1F6AC23D027B",
    "Dr Shikhil Uppal MCh Neurosurgery CV PDF.pdf": "05175B6A-A854-494D-9F79-FB15E453FFB2",
    "DR SHITSAMA confirmation member EANS.pdf": "D7DF8432-F293-4145-AA66-28C3A437FD3E",
    "DR SHITSAMA CV.pdf": "E4036071-87CD-4A76-93F3-AED25F83B4D3",
    "Dr Venne.pdf": "B60031C2-A59A-46F9-90ED-F59F273677E4",
    "Dr Waqas Baqai _20211006_0001.pdf": "BC3AB839-A182-476E-B2B0-50DFC1077486",
    "DR WAQAS CV 2017 (1) PDF (2).pdf": "73FBA91D-71C8-4898-9C99-0F8A854628D8",
    "Dr Yagmurlu CV April2020.pdf": "6E69899C-5370-4D2F-8355-EE4A9003D7F4",
    "Dr. Abiodun Azeez Fellowship Verification Letter.pdf": "8CCD1C45-AC66-4B40-821A-62627A61208C",
    "DR. ADETUNMBI CONFIRMATION LETTER.pdf": "81693FE7-2E05-4466-9B5D-9F02FCCF88F1",
    "Dr. Adnan Khan -Letter of Good Standing.pdf": "8AF40AFB-B505-4397-AB10-7D3433C24231",
    "Dr. Ahmed Sallam SANS membership.pdf": "638746C3-0DC4-4E12-9432-9B573F84CB5B",
    "Dr. Aisha Al-Kubaisi - Good Standing Certificate-1.pdf": "33EE649B-4305-43E2-B0B0-C55B1B29D944",
    "Dr. Algattas CNS LOR.pdf": "A73988E4-D037-4037-976C-39A44C5839C9",
    "Dr. Amanda Jenson - Verification Letter.pdf": "ABFB2102-9F7B-4A1F-99BA-E91B0047005E",
    "Dr. Amin-Hanjani.pdf": "6905154B-4476-4B48-859F-0E8C85AD1DA1",
    "Dr. Ammar Bin Ahsan.pdf": "18520F94-0911-4BBC-8A2C-0DF58C7351D4",
    "Dr. anju CV.pdf": "7F82BE00-429B-45B6-92A2-2F72EA4CA3C1",
    "Dr. Anna Lo Presti_CV_ en.pdf": "4D738B38-EFB7-45C2-9F2F-015908BADCFB",
    "Dr. Ari George Chacko (CNS-17).pdf": "1EC8A5DA-68C4-4A85-9F61-16B1451516B1",
    "Dr. Arthur Day- SNS Nomination for Dr. Nitin Tandon.pdf": "86CC25B4-8504-4779-83B6-1C026BA426AA",
    "Dr. Benzil.pdf": "A118E86E-6E19-4912-83EB-12B1777EA867",
    "Dr. Boakye LOR_01092021.pdf": "78F51192-5AED-468B-B7C5-8DE0B7E7A11F",
    "Dr. Boakye LOR_CNS leadership.pdf": "76228334-EDCF-42C4-B9CB-B659937C6B7C",
    "Dr. Boakye LOR_NEUROSURGERY Publications fellowship.pdf": "BCE7AF54-1686-4754-970E-4EECDB3889E6",
    "Dr. Bou Nassif Verification letter .pdf": "64F27A66-8C57-4211-B647-D20BF73CF94C",
    "Dr. Bowers LOR.pdf": "115D3BE9-3F09-4FA0-8474-5ECC7134E92E",
    "Dr. Burchiel.pdf": "135937B4-0BBF-4E05-A5EB-23B9B7B5DF3F",
    "Dr. Byrne.pdf": "506DA0CF-14D0-4AB4-940F-2FAFF17B7C08",
    "Dr. Charles Frederick Opalak.pdf": "EE0BB935-29A9-4EE7-854E-24ECEFEE3420",
    "Dr. Cohen -LOS Final- SNS Teaching Award.pdf": "BB9051E2-4E18-4FEF-9763-D289ED05CBA5",
    "Dr. Colin Teo CV caa 16 Jan 2024 - Short Version.pdf": "AFFF52B4-93C2-485C-BB92-84D480F68F4E",
    "Dr. CPierre.emailverification.pdf": "3AE21FA9-D83D-4339-A577-9A883A9A1F01",
    "Dr. Da SilvaNeurologicalSurgery_ResidencyCertificate_Translation.pdf": "551DDBC8-B3F0-4754-87E4-7FE87F4D2D4A",
    "Dr. Dadufalza Certification as Fellow.pdf": "6584AEAB-CBD7-4CFC-A210-CC85358F29F3",
    "Dr. Daljit Singh (SNS-82)..pdf": "926548F5-C093-4F00-B002-81B1E4CECB67",
    "Dr. David Kojo Hamilton recommendation pdf89.pdf": "A8426B7B-EED0-4E97-92B2-31FCE66FEA7F",
    "Dr. Eboli - CNS Leadership Insititute 2.pdf": "B055847F-67F7-495D-8EB8-1AECF53E9CAA",
    "Dr. Edelman Letter of Reference for Justin Passman.pdf": "CB2D186E-AE84-4F35-8A91-DA8CC3F6D22C",
    "Dr. Edelman Letter of Reference.pdf": "4D179CD7-3F1C-43A8-8AC3-154B7A36C17A",
    "Dr. Eden recommendation.pdf": "8DDDB98B-BD98-4CCD-8282-5F28530641EB",
    "Dr. Erica Bisson - WTC.pdf": "A6AE8593-2260-4AD1-81EC-D65A7FE5A7C4",
    "Dr. Erica Bisson Letter - Marjorie Wang.pdf": "F495B380-A29E-4E6F-ACE1-173EABD5407B",
    "Dr. Erica Bisson Letter - Praveen Mummaneni.pdf": "DCA6C052-E6A6-4358-A852-2793FA8FD165",
    "Dr. Erica Bisson Letter - RLJ.pdf": "DF703330-ABAD-4B01-B978-339725392492",
    "Dr. Erica Bisson Letter - WTC.pdf": "DAE3F417-00C3-4BBC-9679-AD9CB7F8B414",
    "Dr. Faheem Ahmad Usmani.pdf": "DAD6F383-00BC-404D-BA98-E06776D43BE6",
    "Dr. Farooq C1V.pdf": "1F18316D-E3D7-4509-A256-08B28CA9C8A8",
    "Dr. Fatima A. Fakhroo - CV CNS.pdf": "C2A797BF-9630-4C85-87C9-FF16CDE07CC9",
    "Dr. Fayez Mohammed Alelyani.pdf": "2F45DFDC-1B53-4CF7-AE3F-35BF15325CEB",
    "Dr. Gachiani - Recommendation Letter(1).pdf": "C406FDAA-3B12-4349-AECF-AD027D4E7EFD",
    "Dr. Gaston Oumarou-RESVerif.pdf": "66D58AB3-5694-438D-8185-9A5AAC21A475",
    "Dr. George Ibrahim JTR.pdf": "EABD7559-4F92-41ED-89B4-1B6708F86EE8",
    "Dr. George Yang-RES.Verif.pdf": "E1F1ED24-12D4-41D2-BCBA-DE31B073EF48",
    "Dr. Ghansham Das.pdf": "7FE1218A-EEEB-40A1-84FF-E56452114208",
    "Dr. Grady.pdf": "8015C191-E244-4864-86A2-BD8A469DE790",
    "Dr. Hameed board certificate.pdf": "163C8735-506A-427C-B500-EA80E3A61129",
    "Dr. Hemant Bhartiya1.pdf": "197100BC-C0CD-48E7-B388-36BF80B9BD16",
    "Dr. Henok Teshome Molla CV.pdf": "2949A574-546E-4DB9-82BD-4DF0ABDB7A5F",
    "Dr. Howards SNS nomination- Dr. Tandon.pdf": "A1C61F3E-A666-40F4-A8ED-85E10512F234",
    "Dr. Jacob E Mathew (2).pdf": "9DDD5F82-1E22-4B38-92B6-1AFE8FE2652E",
    "Dr. Jacob Richard Lepard- verification email.pdf": "135C4FDD-B224-483A-B770-F0B106407727",
    "Dr. JaimeMora.EmailVerif.pdf": "F5B36445-EB94-4A1E-B626-33CFB9384361",
    "Dr. James Liu - Letter of Recommendation 2-2021.pdf": "76B01190-A49E-41E6-B853-3608C903F898",
    "Dr. Jayun Shah CV NEW. 1.pdf": "4E3AF922-EA36-49F7-990A-347BEA54696D",
    "Dr. Jensen.pdf": "5DE6AA29-C41E-4EBE-B3F0-A41032DA4863",
    "Dr. Jonathan Wilson 2021 CNS Leadership Institute letter.pdf": "EC962A2D-F595-46E2-AA15-9DF84069ED10",
    "Dr. Jorge Alberto Miranda Barrera- verification letter.pdf": "071233C9-7CFF-43B5-8AE2-C58C35591F3E",
    "Dr. Kalkanis.pdf": "3288E350-2742-412C-8900-0F74014396F7",
    "DR. KEN MWANGI - CONGRESS OF NEUROLOGICAL SURGEONS 1.pdf": "8733A8EA-9142-44E8-AF92-D8E6AA87D376",
    "Dr. Kiran nsi .pdf": "03D7879E-3BEA-4018-935E-98EA59BF80DA",
    "Dr. Liau.pdf": "4ED80EC8-B1B9-49E5-902A-871982AD38EB",
    "Dr. Linda Bi LOS SNS Signed MP.pdf": "8C2C2BD2-166E-4979-A1A2-2997B580A91D",
    "Dr. Lozano letter for Nir Lipsman-SNS nomination_28Sep2022.pdf": "730586D3-34A4-4768-972B-CE1B75494A00",
    "Dr. Lunsford's LOR.pdf": "A5DB6458-54C0-429C-A18D-B8281FC8A7B5",
    "Dr. Mahendra Singh Chouhan (CNS-50).pdf": "8AE93BB8-77BE-4212-80DE-86CA30B5B021",
    "Dr. Mansur CV.pdf": "E90965A1-4F3C-461C-AE85-97503ACD99C0",
    "Dr. Mestet Letter of Good standing.pdf": "F8881191-F455-4461-966C-397529B2AB87",
    "Dr. MHS CV.pdf": "9279545A-DA6F-46D6-A975-E865406C8B7C",
    "Dr. Mohab Abozed-Letter of Good Standing.pdf": "055E50D3-379F-4EDD-ADF8-2BA5020736CF",
    "Dr. Mostafa reference letter 9.26.2022.pdf": "7BB2035D-AECF-43D2-B223-35E6A2CD9CAF",
    "Dr. Muraszko.pdf": "2D24272A-E9FD-4893-892B-4290859F72EB",
    "Dr. Nair Biosketch .pdf": "829EA574-9FDE-4F84-9B45-711E533E4B3D",
    "Dr. Nair letter of support .pdf": "44ECC70B-9552-42B0-8C91-2B0FA02A2E2F",
    "Dr. Natasha Ironsides PD Letter 2023.pdf": "ADAF111F-4803-4415-8486-8BCE12A40D5C",
    "Dr. Nir Lipsman nomination letter JTR.pdf": "49453B81-FB2F-467B-AED9-0F0F06E12B40",
    "Dr. PB.pdf": "F71394C3-D060-4168-AAD7-5C39D5BD13D3",
    "Dr. PBB.pdf": "2A67C112-B6C4-430E-8909-5BA7E9BA9B81",
    "Dr. Preci Hamilton CV.pdf": "65873FC6-F64E-479C-8B85-E575D86137B8",
    "Dr. Puya Alikhani CV 2023.pdf": "444F883B-F2D5-4C4C-93B2-B2DD347A5656",
    "Dr. Rajendra Prasad (PNS-63)(2).pdf": "C32BF1AA-BB0E-4625-8BF6-39158EA2D837",
    "Dr. Rashim Kataria (KNS-88).pdf": "ADD1D986-003B-43FE-B363-17E715E8CB36",
    "Dr. Riesenburger SNS Letter Heilman.pdf": "AC4D6953-4340-49AB-8871-5CBFCAAA8530",
    "Dr. Riesenburger SNS letter Wu.pdf": "FF4E1F2A-9FEA-4B53-8B09-405725CDE112",
    "Dr. Saeed Sam Sadrameli - Verification Letter.pdf": "60B83577-EF21-4453-B557-66915576454B",
    "Dr. Sagher.pdf": "72519CEA-5A65-44F2-A7E8-419336948608",
    "Dr. Sami Sulaiman Almuhaimeed.pdf": "63253E7C-4C37-427B-A778-D2138E7484F5",
    "Dr. Sana Ullah CV-2.pdf": "4F3B07BB-EEF3-443E-8CD4-C5EDECD84FF8",
    "Dr. Saqib Kamran Bakhshi 1.pdf": "4F4A3908-43C2-4915-968C-2A311DB8566E",
    "Dr. Sarmad.pdf": "147B4AEB-F574-453A-8B6D-806F3B5B4468",
    "Dr. Schmidt_Spine summit abstract_Tables_1-5_Fig_1_032021.pdf": "16E9EB16-94A0-4F04-938D-7FB1D955E3A7",
    "Dr. Scott Wait's Recommendation-Asher.pdf": "8EEA4131-1BCA-46FB-8999-F77FF59657BA",
    "Dr. Sekula's LOR.pdf": "235EE18B-1341-44FA-9582-CC2A8E367F59",
    "Dr. Selman.pdf": "D5F8E09E-DF3F-4AB2-B262-88C152D94DDC",
    "Dr. Shahid Nimjee Nomination Letter.pdf": "8F8D174E-3582-4B6F-A946-16CC08148604",
    "Dr. Shankar A.pdf": "11D9B803-431A-42AA-9174-3286A035017C",
    "DR. SHIVANI CHAUDHARY RESUME 2024.pdf": "EDE82CDE-3667-4011-BAD9-411E1CFCED7B",
    "Dr. Siddharth CV.pdf": "A33014BE-190D-4132-B772-B8DFDDE9BC3C",
    "Dr. Sigounas CSNS Letter of Support AC 4-2021.pdf": "33F6C87E-209B-46D8-95DC-2309B5840F1F",
    "Dr. SP.pdf": "0E7C69DB-983C-455F-B5C8-6339DA7B6398",
    "Dr. Szymczak CNS Ref letter.pdf": "7554A075-5DFC-4D4A-84F3-4AA9B8E3C0AD",
    "Dr. Tejus CV.pdf": "5DCDD95B-3891-4843-BABD-01ABE4FEEFDA",
    "Dr. Thorell Nomination Letter Society of Neurological Surgeons.pdf": "5747F643-C75A-4C21-AD69-03AEA862B72B",
    "Dr. Tovar-Spinoza  CV up-peds.pdf": "843EF7EB-4FB0-4111-8202-693BFCCD7F91",
    "Dr. Urakov- CV012323.pdf": "10A5ED89-25DF-4DCD-B22D-134D3AED7D1B",
    "Dr. Venkatesh T. Yeddula.pdf": "5B44D67F-8E7B-4050-9AB1-8C84992DEA87",
    "Dr. Vijay Kr Gupta.pdf": "DE4DC03F-DC2F-47A1-A9EF-464F8F503881",
    "Dr. Vikas Gupta.pdf": "F0442F56-7C8A-4219-87BD-0C2368F06484",
    "Dr. Vivek RL.pdf": "90340EE8-0C0C-403F-A25A-BF336C266F1A",
    "Dr. Williams LOR_NEUROSURGERY Publications Fellowship.pdf": "91889758-1671-4071-93B0-0546F1242648",
    "Dr. Wolfe.pdf": "A18327F9-48DB-4BDC-A9BC-C2698FE4B689",
    "Dr. Yag?murlu Membership proof.pdf": "52F83539-F606-409D-B98E-71A966A32786",
    "Dr. Yuki Shinya_Certificate of Board Certified Neurosurgeon Member.pdf": "618C6F75-97BA-4B63-A310-3ECC0D67E528",
    "Dr. Zipfel.pdf": "37E6E355-D2E9-43D9-8109-9ABF2E9445E1",
    "Dr.?? ??????.pdf": "54380BC1-138D-4792-8754-C7291DB69424",
    "Dr.Abel Neuro0001.pdf": "2045522D-CCA7-4189-AE04-F320C2432B38",
    "dr.Adi S.pdf": "A58C6EE6-B102-456A-BDBF-D94ED39CA079",
    "Dr.Al-Wala Awad-emailverification.pdf": "FCEB18D7-BCD2-449F-86A1-747E1B258BE8",
    "Dr.amin mansoor.pdf": "A4CB192E-D767-4B8A-B451-D7CC7EB9272C",
    "Dr.AMoghaddamjou-verification.pdf": "A00686D7-A908-4E42-8AE3-D4763724CE93",
    "Dr.C. Couturier-emailverification.pdf": "428665DF-E583-46AD-A9F3-28FC5F78B4BF",
    "Dr.Cadieux-Fellow-EmailVerif.pdf": "5A2CC991-4B96-4375-BEB4-2F33A87C8090",
    "Dr.Chad Claus-FellowVerif.pdf": "0A52D7A8-0DD3-4954-AE53-65232824D391",
    "Dr.David Segar-FellowVerif.pdf": "E5B43C9C-AE8E-4CAF-A594-523740FA437E",
    "Dr.de Lotbinière-Bassett-Fellowship.pdf": "20984BE7-9C5C-43FD-B724-BC4D9A7DCA36",
    "Dr.E.Fomchenko.email verification.pdf": "8900C1C7-3BBE-4376-AFA1-36D2DF91BF58",
    "Dr.Fuentes Virgen - RES Verification.pdf": "8EDC36CC-53F1-4235-9532-07BDDA160DDE",
    "Dr.George Yang-RESverif.pdf": "BDE36F5C-9F55-4A39-8E50-81DC2EB2E446",
    "Dr.Itay Melamed CV.pdf": "5D7188F1-27CE-4C8D-BEA5-479F77F8C76E",
    "Dr.Jacob Bagley_emailverification.pdf": "4D4F86F9-46CB-42BA-AA7F-A4E5BCD703CE",
    "Dr.JAmburgy_emailverification.pdf": "84BD5C1D-EAE1-402D-9A9A-29395DB13397",
    "Dr.JArcher-RESverf.pdf": "318C6BA3-47CA-4045-AA90-39056D6E4E31",
    "Dr.Jasmine DiCesare-FellowVerif.pdf": "684CC164-89FC-4258-9FE0-6FDD95DEFC4D",
    "Dr.Jonathan Chainey-Fellow-emailVERIF.pdf": "02B58853-8858-42C1-AEC2-801067B1A6EF",
    "Dr.Juan Mejia Munne-RESverif.pdf": "E82B6DC9-714F-41E1-BE91-9B466C0A6D0F",
    "Dr.Katherine ZerebiecRESverif.pdf": "C6180E60-1B86-4E77-9CAA-B0E3FFFD5068",
    "Dr.Miri Kim-FellowVerif.pdf": "89D85463-9704-4A41-8ABB-E7C42DFB5A18",
    "Dr.Mizuho Inoue.verification letter.pdf": "2569B022-2CA9-4982-AC6C-FB6813275CDD",
    "Dr.Mohamed Fekry - LOR.pdf": "2768A39E-840F-4BC0-B4BF-3CB1DF1B762C",
    "Dr.Molot-TokerRESverif.pdf": "5FDAE61B-33ED-4435-A4A6-47A222170E34",
    "Dr.Musharbash LOR.pdf": "39426DFE-AE9B-4579-AFB3-03E21F3710D5",
    "Dr.Ramos Delgado-verification.pdf": "B5FB65AC-19AD-464C-B7B6-D5295AA580E5",
    "Dr.Robert Rennert-verification.pdf": "4E85622B-C69F-4DA0-BB8A-AD19DF5AAF2E",
    "Dr.Ross -Fellowship.pdf": "4F77FB05-CA52-435C-B7B8-32342AA2E247",
    "Dr.Ross_RES.Verif.pdf": "6018E16E-EC35-4441-B6D7-F7F0B5E78F3B",
    "Dr.rstTromblypdf.pdf": "5ADCF9CE-380E-4197-8413-9199128D1751",
    "Dr.Sizdahkhani-Fellowship.pdf": "24B4B25B-A9FD-407A-81B8-5D188DB5FACF",
    "Dr.Tyler Scullen-FellowVerif.pdf": "7250529C-035D-4959-9D5B-C379F5DBBD83",
    "Dr.Vivkram Sudhir Karmarkar.pdf": "E8AADBB0-4DC8-470E-BF70-BF86734687BC",
    "Dr.Waleed recommendation letter.pdf": "A2355C39-280D-4E52-8EFD-08BE2406A883",
    "Dr.Winkler-Schwartz.Fellowship-EmailVerif.pdf": "F74FD087-885A-473D-90A4-68094D3F1F9F",
    "Dr.Xiaofei Sophie Zhou-2023 Active membership Transitional promo._Invoice.pdf": "27A049AF-3BE2-4D72-BCD6-C062D6A1A5E4",
    "Dr.Zayed Almadidy- Fellowship.pdf": "805D2DBE-1106-4B06-843F-C52A5BE5A36E",
    "Dr[1]. Francisco Rojas MEMBER.pdf": "60A696C3-BA5C-422A-9D35-60F68AD61EFF",
    "Dr[1]. Shailesh Kelkar.pdf": "34AC9CD9-09E2-4836-9467-1CEDD5736E15",
    "Dr[1].Manish Vaish.pdf": "5F6BEC07-7B85-426F-A694-10AADFE86BB2",
    "Dr[1].Vijay Singh Parihar.pdf": "F8783532-68D4-49DA-BD8A-C83055F2E5E0",
    "Dr_Edisher_Maghalashvili.pdf": "55E257ED-5045-459C-AA01-9A11652BC497",
    "Dr_J_Entwistle_CV_1_3_19.pdf": "5A59219C-BD8F-49E4-A5F9-B0BCE90CF74B",
    "Dr_P.R._Rajkumar_(RNS-85).pdf": "D4AC21FE-EBB3-4AE5-94EF-43D4ACA88570",
    "Dra_ Pay 22 julio 2014.pdf": "982B8A7B-71C7-41B8-A65A-9DE365AEF9BD",
    "DRAFT 2024 Data Science Agenda.pdf": "BF6D480F-ADC8-4B1C-9EB2-2F8023B2805D",
    "DRAFT 2024 Data Science Agenda.v3.pdf": "5D029C7D-E2DE-4272-A3F6-FA8EA612A271",
    "Draft 2024 Essentials Agenda  with COI 1-11-2024 1.pdf": "F4E9163D-B7AC-4A07-8CB3-475818BD4966",
    "Draft 2024 Essentials Agenda 1-2.pdf": "7F3F633B-BF55-4665-8C2E-9DF696DA0179",
    "Draft 2024 Essentials Agenda 11-1.pdf": "F108B8E1-5666-4691-B864-1DA4532C2FA3",
    "Draft 2024 Essentials Agenda 12-15.pdf": "95EB6C43-D177-4207-9D96-F6E8B539334F",
    "DRAFT 2024 Trauma Course Agenda.pdf": "720DBF14-E623-4036-BCA1-EDEA545384D4",
    "DRAFT pic 1.pdf": "C3ED7847-3C00-485A-9A90-45BF76F23861",
    "DRAFT pic 2.pdf": "6150794E-47DC-49B1-9987-3D4A64F0F83C",
    "DrCristianValdésMemberCNS2014.pdf": "BA87F9BD-7D42-4DB6-AC62-1B8B63467B81",
    "DREZ.pdf": "C2F0D024-6694-4773-B58E-EC69A3CA5CF2",
    "Drill bit poster CNS.pdf": "E3BDF893-31E7-4C3B-B815-21C781AC52B5",
    "Drivers of Operating Room Time Utilization for Endoscopic Endonasal Resection of Pituitary Macroadenomas_Export.pdf": "696E874B-3590-4DAD-B15A-8A9A63536CEA",
    "Driving Simulation Poster for CNS 2022.pptx.pdf": "F2FF6EEB-677F-4C36-A499-AC2A8331D4C4",
    "DrJimenez_SavetheDate Final.pdf": "47037358-4D1E-4317-8A3C-1A2C63F31457",
    "drkunaldholakia.docx.pdf": "790D59BB-04B7-4E74-B3F7-E8BB7F0D415D",
    "DrMichelle Kameda-Smith.emailverification.pdf": "BBB41878-7112-40EE-B7C5-B11B5E01CAE5",
    "Droke Katlyn Letter of Good Standing 121621.pdf": "EE971E53-4E26-47AE-8D00-DC56EF90388C",
    "Drossopoulos Peter - Letter of Good Standing.pdf": "125DD0D5-AE51-44F8-AA41-838D8224A731",
    "DruCV.pdf": "81E97CDB-AEDE-4EE9-8B3D-26481ED2BD92",
    "Drue Lorenz CV.pdf": "063730D0-5C86-46C7-BDFF-9C1DE5B51B1F",
    "DrYamLetter.pdf": "64616C4C-3888-461B-A6CF-EF2D47F7370A",
    "DSarathy_LOS.pdf": "E73ECD2D-8341-4489-A19E-4F5B639CEEBF",
    "DSPN 20-04-26 Mtg Minutes.pdf": "F3969B71-05C1-40B6-9ADE-3E0A11C49CD4",
    "DSPN 2020 Pocket Guide_final.pdf": "8058E742-A06F-4020-92F5-B4BF26483823",
    "DSPN 2021_03_13 Minutes.pdf": "2CAFDA89-DD21-4CA5-91AE-1E767BFD2718",
    "DSPN 21-04-17 Business Meeting.pdf": "649FC985-500F-4052-82F6-6A84AE862DF5",
    "DSPN 21.7.28 Minutes.pdf": "934EF3E9-D47D-4759-BC95-5170F7965F05",
    "DSPN CNS Minutes 21.10.17 LMT FINAL.pdf": "BBF5A39C-EBAD-4F0C-8771-2C1AB4ADD3B0",
    "DSPN CNS Zoom 20-09-23 Mtg Minutes.pdf": "BC180F6C-19F4-4655-9CF8-9931FC7C4A76",
    "DSPN EC Minutes, March 2015_2015-03-24.pdf": "D08F799D-3F4B-4ACD-BE3E-53B11CF47AA7",
    "DSPN Slides - Overview.pdf": "32C3B280-40B5-4047-9112-EEB1CF5714F5",
    "Dubey_CNS2021_Comparison of C5 Palsy Incidence in Laminectomy and Fusion Versus Laminoplasty.pdf": "6334359C-1628-4956-883C-4D1926A56E1E",
    "DUCOM Unofficial Transcript 1.5.24.pdf": "D5858052-9B43-4638-B87B-BF8770929004",
    "Duda_Verification.pdf": "A9AAFFF7-BC0F-4F5F-B0DB-79454513EEE7",
    "Duehr_Good_Standing_Letter.pdf": "9B2B532D-2F5D-4BF7-8380-656519818DE9",
    "Duke Letter of Rec CSNS Price Goodwin_final.pdf": "52652D0A-0C7E-4A6C-A8D1-7648C9EC216E",
    "Duke Preceptor Statement and Trainees Goodwin_CNS.pdf": "6A2DB8DA-4063-4259-85ED-77ECD419F4E8",
    "Duke-Neuro_Residency_2020_V5[1].pdf": "ED106610-5162-4833-9AA5-3EC11E1E89D9",
    "Duke-Neurosurgery Residency Program.pdf": "093974C2-5386-415F-9069-E22BC33D13E5",
    "Duke_2023_AR_V2.pdf": "CB5A33B4-2E89-4EC1-8D6F-63D3775ECD72",
    "Dullea Jonathan - Verification - Letter of Good Standing.pdf": "0453B0F9-F420-4BAB-BBD0-020EB6E88B11",
    "Dunn Letter2.pdf": "4C940CE3-C50B-42A1-B909-110ACCB8A69C",
    "Dunn pic.pdf": "38E71E69-720D-4F25-A8E4-8CA9C5AAB1CB",
    "Duschanov-CV-IBNC2016-Turkey.pdf": "0B2335FB-6601-4E7F-80B9-E0F1D736BC7C",
    "Duvall Student Status Letter.pdf": "4280D946-C986-4F8E-98F4-4A255C133F62",
    "Duvuru CV.pdf": "F7DD990E-5082-4447-92EF-F7B27B16BDD8",
    "Duvuru NSSI Membership.pdf": "77CBB755-E277-404D-8D42-EDD66012339B",
    "DuyPhan_VNS membership.pdf": "7A2237FD-0B8C-402B-AE06-DA694EE86F52",
    "DVoce-FellowVerif.pdf": "21F4C826-9E78-4F0D-885E-56DF6729E60D",
    "DVollhaber.emailverification.pdf": "6C236189-652A-4779-BFA3-C823FEBC9001",
    "DVT_Crani_Tumor_Table1.pdf": "E1A51D55-5EA1-4F86-BDA0-A4EA950525C4",
    "DVT_Crani_Tumor_Table2.pdf": "62131899-C4E0-4853-BFEF-E96DE8051460",
    "DVT_Crani_Tumor_Table3.pdf": "FA6849A0-745E-4DDE-8B9E-0DB19324B96A",
    "DWI.pdf": "4D863C70-6DDF-40D5-9FF0-6DA3F53E7ABE",
    "Dynamic Interspinous Process Technology.pdf": "F82DF4BD-9E80-498D-ABA5-D9C48C1AA7AE",
    "Dysphagia_ACDF_AE_FV.pdf": "00ED7688-8800-426D-8BE1-1E4A5881D5F4",
    "Dysphagia_ACDF_AE_FV_1.pdf": "F171F458-B618-4AF1-B3F2-4BBB9D341E92",
    "E  M Sign-on press release to HHS budget neutrality final draft 080420.pdf": "7C05765B-A445-42A1-941D-0ECC6B59A47C",
    "E & M Sign-on letter to HHS Budget Neutrality.pdf": "3A04184A-D3F4-44BE-BC41-B99D834C7E5E",
    "E Elias CV.pdf": "5E079850-34CD-4A44-8BC5-1F4209591E4B",
    "E Hudgins Verification.pdf": "32A5C9FB-45DA-42AD-9FF4-3EEB3F9B591C",
    "e-EPIC_IPG0659367_10022024_121932.pdf": "959DFFBC-822E-49B0-BC77-42DB61FE5B4C",
    "E. Spinazzi.pdf": "2BDBE001-F6F6-4238-B3A3-9B2F9CBDF0FA",
    "E.Bisson CV 10-2020.pdf": "8571AF60-22D7-4A01-80EE-539B269CAD5B",
    "E.M Budget Neutrality Letter_Provider Groups_June 2020.FINAL.pdf": "C8A58B4F-4FC0-44C7-B492-EAA0888A5C29",
    "E.M Coalition Letter_HR 8505.FINAL.pdf": "34F4725C-7385-4138-B2CC-8E8F62752808",
    "E.M Coalition Letter_HR 8702.BeraBucshon.FINAL.pdf": "62899DBA-2A3D-4352-AD58-68E01E50BBBC",
    "E.M Coalition Letter_S.5007.pdf": "87D29474-330E-486D-A208-39458DD21CC7",
    "e_m_sign-on_letter.pdf": "5190A23F-872F-46DA-8223-0F44C69FF6FA",
    "E12.full_.pdf": "2D9BF3CE-7AB3-43F1-8E02-59816D08DC4B",
    "EAH CV2022.pdf": "C1AA7D57-A866-4E10-B690-2B9386BA8311",
    "Eakins Rachel-Letter for Medical Society.pdf": "07E3B87C-0D6F-4BCA-B1A3-7B825FEBB1BF",
    "EANO membership.pdf": "43711D12-2F1E-4FD7-A243-96D62FB6DE06",
    "EANS 2018 Trauma n functional.pdf": "324BDDD4-0845-4913-998B-B914AB345769",
    "EANS a NASBS Memebership-Jennifer Hodurek-Diez.pdf": "8C3D4F79-C11E-49E6-AE0E-7D3B8F31BDBF",
    "EANS cert.pdf": "D80B62D7-A978-4B22-A4C2-11146DFD6E2C",
    "EANS certificate.pdf": "4E2B65CA-18F3-4283-9DC1-1AE68231B279",
    "EANS DIPLOMA.pdf": "76BDF420-48F1-4C90-9060-CCF9AF5FC7C1",
    "EANS membership.pdf": "CDA218A9-D735-4D7C-AD01-F81D98D00DF5",
    "EANS RECEIPT.pdf": "2BE2CCE4-C4AF-4E9B-AC47-4A6AA9B39E03",
    "EANS.pdf": "2670FC2E-57EE-4C1D-A93D-030F26786D12",
    "EANS_membership.pdf": "2A223940-EF52-4F88-9B36-1324E5D5827B",
    "EAP Flyer Uprise Health.pdf": "2716B93E-AD69-4F05-8374-8526BC964EFF",
    "Earl Jones-Fellowship.pdf": "43CD9F1B-527A-41D4-B22B-A9133AEC7031",
    "Early outcome of endoscopic third ventriculostomy with or without choroid plexus cauterization and ventriculoperitoneal shunt as primary treatment of hydrocephalus in patients with myelomeningocele.pdf": "B1AF5542-1ECD-4216-ABA8-1A3E995F402E",
    "Early Pregnancy 2012.pdf": "AF8C99A9-1D42-4BE3-AB1A-23C4BA86A931",
    "Early PTS.pdf": "73AA310D-BC7B-40E0-8911-E6B94458E516",
    "Earned Partner Introduction - for your association 1.pdf": "D6ABAE62-D424-4C05-8F98-A2D7CB13F81E",
    "Eastwood, K - March 26.pdf": "5EBE2579-A607-4DB4-A1AE-C102F5B8337F",
    "eATN_ASSFN_poster.pdf": "F97D6B5C-F800-491F-9E75-166282BC8CF5",
    "EBL graph.pdf": "118FB704-911A-468E-822A-4DF428D091BC",
    "EBrown.emailverification.pdf": "F9784156-EDF4-459D-A8D9-DA4DBC6826B7",
    "EBurgos-Sosa-verification letter.pdf": "4E381409-1983-48BD-A0F0-099239FAEECA",
    "EC Agenda book AANS 2012_2012-04-16.pdf": "D3E2D5BC-C949-43B3-8F98-C061591DE29B",
    "EC Agenda book CNS 2012_2012-10-06.pdf": "2001A56E-AF9F-423C-BA1B-7111816ECD79",
    "EC minutes Joint Section Mtg 3-2012_2012-03-22.pdf": "6717C722-BE3D-49AE-978C-5E145D4DB183",
    "EC Minutes Spine Section Meeting March 2011_2011-03-09.pdf": "5392699F-6996-4466-8005-2588BB07C350",
    "EC Nominee Responses- Final.pdf": "ECA13B1B-0096-453F-BEE0-5C67399F3990",
    "Ece Uysal.pdf": "5FCD22D6-2851-46DD-B895-635F203E9595",
    "EChen-Residency Completion Certificates.pdf": "B3FD1466-8CDF-4C54-A467-81E18F02CAD2",
    "eCV - McGinity 090921.pdf": "3AFB2B9C-BA48-46CD-B96D-6692101021A3",
    "Ed and Labor SMB Letter 012120.pdf": "2BFD9E05-4F18-4191-BD0B-7A54D4D20793",
    "Eddie mentor letter of support for shawn SNS 2022.pdf": "EC17BDE9-F4F7-4E72-9812-69DA48405A90",
    "Edinson Najera A _ CV.pdf": "65E6C133-4254-4B8C-B385-46419B69577E",
    "Edinson Najera.pdf": "15715B7D-975C-46D7-BFE9-E311EEAE0603",
    "Editorial Associate.pdf": "55566B2A-69DE-4091-83D1-24BF1ADB9EFD",
    "Editorial Fellow_Personal Statement.pdf": "78A32ABF-026B-46D5-8D54-EFFA05730038",
    "EDS digital poster - CNS 2019.pdf": "C7ED8D67-0DA7-4C56-8610-F9AB31DDC4AF",
    "Eduardo Martinez-del-Campo-FellowshipVerif.pdf": "272DC4FA-599E-4A57-AACC-B0CD980321F0",
    "Eduardo Valladares-FellowVerif.pdf": "40FFE9CE-5FB3-4185-BF65-5D512647E0B7",
    "Eduardo Valladares.pdf": "1BCC5ED0-E6A3-4088-AA6B-91E4D1EF7287",
    "Education.pdf": "6B9A69D4-C749-4BFF-9835-6B0A5E65443B",
    "Edward Andrews_RESverif.pdf": "2530C232-D47B-47BE-8914-1DE974A44DC2",
    "Edward Hsu CV 2021.pdf": "F577A3BD-58C8-44BB-A577-972F5A4E1B49",
    "Edward_Dyson_CV.pdf": "20900C8E-B976-4C16-99C1-89D23CED4EE9",
    "Edwin Kulubya-FellowVerif.pdf": "B0A06F27-AEE2-42BD-BF99-D3062B765E73",
    "Edwin_RodriguezReyes_Resume.pdf": "C8BA4D07-BA69-4117-9B1C-66319BEDDB61",
    "Edwina Tran UCSF Enrollment Verification.pdf": "0E246DC9-FB09-4736-BBF0-1D381DB4993D",
    "EEG.pdf": "E6F35912-384C-47C4-8889-2A7D79EBA86F",
    "EEOpioid_Table1.pdf": "2681AF27-2EA2-4B42-91F6-DA14A3BBA113",
    "EEOpioid_Table2.pdf": "A43CFE3C-2E1E-42BB-A7D5-5DC7E928A79C",
    "EEOpioid_Table3.pdf": "0B9B3E19-ED62-4116-9AAA-DB9BDEFCE385",
    "EERT_Peds.pdf": "0D8B8C9D-652D-43E3-88D0-9D11F6514625",
    "EF32 pt set up.pdf": "8B3A96FB-79AF-4D3B-AE2F-E8932070E9AF",
    "EF32 Schema.pdf": "9C02A25B-7461-42C1-99B2-3C78EEB3450B",
    "EF32 Trial Design.pdf": "60D179E9-5509-4F55-B84B-64EB19774293",
    "Effect of Endoscopic Endonasal Surgery Duration on Sinonasal Outcomes_Export.pdf": "ADFFABA8-9B9D-4A95-89A1-9A1D7724BD53",
    "Effectiveness and Safety of Therapeutic Hypothermia in Neurosurgical Patients- a Systematic Review.pdf": "7757F6AC-8430-4B6C-9603-6E7B7835227E",
    "Efficacy and Safety of Transcranial.pdf": "89FDD306-7531-4998-8660-7C107180EF1C",
    "EFontana-RESverf.pdf": "1D13314C-0351-4C19-8A8D-1308159CC0E0",
    "EGNOR - SNS Ltr..pdf": "7017386B-49F1-4A5A-928C-99BF4831C938",
    "EHarlandGoodStanding.pdf": "C2C1B832-B013-4A87-827A-B0D99568AB6F",
    "EHogan.emailverification.pdf": "0126E17C-2F27-4690-81FD-7F0B2F9CE137",
    "ehr_certification_letter.pdf": "38F4F8DB-8EB7-4ADC-A6B5-A1099B3AC2AB",
    "Ehresman Jeffrey.pdf": "614127B8-C8DE-4B2A-AC20-285BF0CE9030",
    "ehsan CV 2021August.pdf": "C764D897-8DE5-4FDF-BDF4-917695585E91",
    "ehsan CV 2022 June.pdf": "9C757B3D-48F0-4E29-84D5-91E4A3989FA8",
    "Ehsan Dowlati-FellowVerif.pdf": "EBCF7E16-2424-494A-9D84-F19825F90764",
    "Einstein CV 2021-ns.pdf": "7FEFF65D-1083-4D03-9AF0-04F732E7D875",
    "EJB_Residency Diploma.pdf": "E583CC69-3B8D-45EA-A559-38D2D4CC92B6",
    "Ekanem UduakObong good standing letter.pdf": "6E0FC255-812C-440B-A82F-E3CBD7BDCA73",
    "Eke- CV.pdf": "855781FA-DBC5-46EA-A261-BD2EC9156EEB",
    "EKERT Justyna Letter of Recommendation and Good Standing.pdf": "2408BE71-27A9-447E-9ED9-797609CA61BB",
    "Ekert_Object_Naming_FOM_CNS_Poster_2021.pdf": "56C0277C-16BE-4016-8F1B-E93531907FEA",
    "Elad Mashiach Letter of Enrollment.pdf": "377172AE-FB76-43FC-9A3B-6AC1D25E444E",
    "Elaina Wang_Letter of Good Standing.pdf": "3A29D975-63AE-4A07-9701-948945488C75",
    "Elarjani_CV.pdf": "49BCBF8C-31F2-4795-81B8-11914E617D47",
    "Elder Benjamin Lanzino LOS 9-6-22.pdf": "AFFEE698-8842-4496-A824-5FA1C116575C",
    "Elder Benjamin_ LOS_CNS Leadership Institute 1-11-23.pdf": "E69DAE89-4820-46DA-966E-24A1A4C727A0",
    "Elder CV 9-2022.pdf": "5432CA2E-0F88-4A4E-A655-5FCB06D04252",
    "Elder SNS LOS Aug 2022.pdf": "C972ABBA-0D3A-4EF7-9A5D-5AC94E39CD80",
    "Elderly Abstract Tables.pdf": "DF15DCDA-4E65-4624-B458-A0984A442166",
    "Elderly Meningioma Tables Final PDF.pdf": "F71CEFC4-0097-41B0-A2DC-2B67DB48365E",
    "Elective Letter for Payton Tayler - Drug and Background Test 2.pdf": "BDA48253-D74B-4273-87B0-1AE1516149F5",
    "Elective Lumbar Readmissions Short_AE_Final.pdf": "CAAC4399-6EB2-4B9D-B996-321BE8E8CE8E",
    "Electric Brain Stimulation with Brain Computer Interface - USPTO - US10780264-B2.pdf": "11AD379E-28B3-43C8-8D14-FE8FA4B60FED",
    "Electrical Wada poster PDF 11.11.22.pdf": "10980515-9F38-4381-95D3-79B2086C23F2",
    "electro mon during surg for cerv degen M and R.pdf": "7B8B0B21-D888-4D4A-B245-A3794182E4F9",
    "electronic_frailty.pdf": "A337B3AF-DD16-46C6-B43A-CBAFC119E52E",
    "Elevation of depressed skull fracture utilizing breast pump_digital poster.pdf": "2F231DD5-7D1C-459D-90AC-37C175C0E01E",
    "Elhassan CV pdf.pdf": "7B3FB1B4-4CD5-4076-8752-230201BA3308",
    "Elias Elias Neurosurgery residency degree.pdf": "07B366A7-59B1-465B-9080-29CEF941D06E",
    "Elias Perli 6.12.20.pdf": "8E3EEC8C-D5F8-417D-BD74-5103C4D47D9A",
    "Elias Shaaya-Fellowship.pdf": "C2A75E6E-D5C0-44F7-8664-09C2C1A9359B",
    "EliasOpio-RES.verification.CamScanner 04-11-2023 12.50.pdf": "A643FC14-21F8-411B-BA2B-488E24C48300",
    "Elidio Cruz.pdf": "0118851F-FF66-4BF9-A0B7-C1F17374E82E",
    "Elina DeFranceschi CV.pdf": "703E111C-BD02-492F-8F6C-3E567FF0DB07",
    "Elizabeth Ginalis - CNS.pdf": "87132FB7-DF99-43E8-94F6-A0B362BAA367",
    "Elizabeth Hogan-RESverif.pdf": "ADBB4D0F-1DE5-40DB-8649-5E97BB251EF6",
    "Elizabeth Ledbetter - Enrollment Verification30.pdf": "50D4C102-F591-487C-98E5-80CE097A0B91",
    "Ellen Air final SNS.pdf": "D0EC1A3F-3F54-4D47-A7EB-82C0383203EA",
    "Ellen Air Recommendation Letter 1.pdf": "E22F3733-1124-4AA3-8B69-3045771CCF9C",
    "Ellen Air Recommendation Letter 2.pdf": "C0C5A0FF-E916-45F0-91F3-DDA280B26D99",
    "ellie_gabriel_upenn_acceptance.pdf": "8E76BB23-D931-4473-A8F5-460036D5730E",
    "Ellina Hattar-FellowVerif.pdf": "7BA75CDB-3CEE-499F-B911-8718958B3110",
    "Elliot Min CV.pdf": "26A0E017-258B-4652-BDBC-E0D8793C4173",
    "Elliot Pressman CV.pdf": "9A6C0121-A5FE-4F7E-9625-A1015C60DC92",
    "Elliott Letter of Recommendation CNS Guidelins 2022.pdf": "EBDBB950-0C71-4D4D-8B89-709A43A2C302",
    "Elsamadicy_CV.pdf": "EF918A71-27CA-4145-8D76-2B6E568B7515",
    "Elsamadicy_CV_2021_JANE Award.pdf": "E4DB2D87-641D-4E2B-B19F-5AF401513B37",
    "Elsamadicy_CV_2024.pdf": "B556D6C3-A3FC-4A4F-9ECF-414AD12C72B1",
    "Elsamadicy_CV_CNS Resident Comittee.pdf": "1B6212A4-434C-4AA9-A43D-D320CA81D1DF",
    "Elsamadicy_CV_Feb_2021.pdf": "7D25356D-F4BD-4AA3-AAD5-09B538C3B43C",
    "Elsamadicy_CV_Fellowship .pdf": "95C4ADFD-6118-4C5B-83E9-58148F159430",
    "Elsamadicy_CV_JANE Award.pdf": "2124043C-2E69-47B5-A088-2705E2D8B01B",
    "Elsamadicy_JANE_Award.pdf": "6A02005F-8756-4ADD-A48A-2A0AC7866DAC",
    "Elsamadicy_LOR.pdf": "DB382F87-339F-4087-A883-1E3C9F26FD40",
    "Elsamadicy_Time Commitment Letter.pdf": "F9778FD4-DB89-4C8C-B79A-34A2925C92E3",
    "Eltahawy CV Jan 2020.pdf": "366F149C-50A3-4A8D-9BBB-FB7DFBE28904",
    "ELucar Figueroa.emailverification.pdf": "EE060D50-DE34-4515-91FA-D124F3536150",
    "Elvis Vidal verification letter.pdf": "EA776ED3-AFB1-41C5-A390-333265816A8E",
    "Elwy_Reem_ CV.pdf": "E739230E-7C0A-4EF1-AE45-A39092867DE4",
    "Elwy_Reem_ CV_2024.pdf": "6AC7D37A-6990-4C3D-98C2-7CA476A7B9F1",
    "Elwy_Reem_LOR.pdf": "DDB63C35-9858-4A07-B231-35DE106977CD",
    "ELytle.CV-120522.pdf": "C2FFA340-5A1D-4DC2-951B-25368A7DA4A2",
    "ELytle.RESverif.pdf": "788F97EB-7311-443A-B391-4E69BE6D3B2C",
    "EM Coalition comment letter to CMS on EM code proposal.Oct 5 2020_FINAL (002).pdf": "2F4DCD2B-7D4B-4333-BBF5-0889984ED7AD",
    "EM Coalition comment letter to CMS on EM code proposal.Oct 5 2020_FINAL.pdf": "0C7F7314-83FA-4DE7-82C6-A2DA985DCAF9",
    "em_specialty_society_letter_to_cms.pdf": "EDF3F937-F798-4C0E-AC01-7FBC526AA3FC",
    "ema_frailty.pdf": "B91A40A4-3A2A-465A-B7A4-22136B290EF3",
    "ema_reliability_digital_poster.pdf": "35FDFC64-5604-48D3-8EB8-FF05BBF29E1D",
    "Emade Jaman.pdf": "F1D154AD-EE9C-4D16-9A6F-64C4773F6550",
    "Emara 2023 CNS.pdf": "475A91D1-2805-4489-948A-DDF863664AA4",
    "emergency_care_hpp_support_house_letter_fin.pdf": "5E19BABE-B64F-49E8-8BBD-268F1FC1A71E",
    "EMILIO GÓMEZ.pdf": "C827623A-13ED-49D7-93B5-C97EB59477DD",
    "Emily K Chapman.pdf": "07FD9961-193E-45C4-873F-A936D56612DE",
    "Emily Viles-Monari CAE - Resume.pdf": "50AFCC52-C82A-4232-9031-9F413824FF99",
    "EmilyEstes_Letter of Good Academic Standing _Ellis_8AUGUST2022_Final.pdf": "E6F17BF9-498F-4CE7-A353-1B4AA62075EC",
    "Emirates Neurosciences Society.pdf": "055E11AC-9395-4431-986F-E5F8503A4D32",
    "EML CV-3.pdf": "B4DA4DE4-AD38-4526-99AF-7711B6047441",
    "Emma Celano-Fellowship.pdf": "B992608B-5584-4124-9DAE-C70909573A49",
    "Emmanuel Moreno-Ortiz-RESverif.pdf": "3C23069D-FEBC-44BC-9C0C-9459F870B950",
    "Emmanuel Omosor verification.pdf": "C9AAFFFA-52C2-4580-AB48-87627331F03F",
    "emmanuel thas 0918.pdf": "2AA2F3F5-4AD9-4C14-9FE2-97952399DAE8",
    "EmmertAS_UCCOM_StudentProof.pdf": "08EE5042-9E7C-4F70-8487-6DD588509C14",
    "Emmett Glass ASSFN POSTER 2020.pdf": "BC497803-11F1-4C18-B70C-A483999FF7EF",
    "EmmezMembership.pdf": "D0BC33F2-502F-4E65-9F8A-BD98D762C973",
    "Emory University.pdf": "CBEB6C4B-A1C0-457C-9771-C3C5D8C20D48",
    "Emory_transcript2020.pdf": "87F2F5DF-8DEE-4FD1-B9CD-533E4B6FC35A",
    "EmployeeProfile.pdf": "157575D1-E782-449A-9113-71282F6F75CD",
    "Employment letter.pdf": "5CCD1AA7-D121-41BE-BA58-D03FD79BFF64",
    "Employment Letter_Signed.pdf": "F3B86389-A043-47A3-8DB1-FEA8E1A70B39",
    "empower_for_health_act_section_4_support_letter_july_2019_final_v2.pdf": "DC06CBCA-F096-4956-89C7-5E9C7FB8B359",
    "en reg partial plots.pdf": "8FF0F976-3F63-4698-B429-8050CAA4A934",
    "eN5-Neurovascular-A Mansour .pdf": "C924A8EC-89E3-401C-B8DD-350C1E89DA5A",
    "Enbloc CEA_Poster.pdf": "1C0EBE8D-6DC9-4B66-BB35-442C6068E38F",
    "encephalocele abstract figure.pdf": "4F87E3A4-F999-446B-B67D-1AB56B9009CE",
    "EnCompass_CNS_Poster_FINAL.pdf": "B889BFD7-4B90-43A7-9942-A2302C2DEA54",
    "End of Year GME Coalition Letter_FINAL_Leadership.pdf": "81A5D6B1-798A-4E29-81D8-1955DCA50986",
    "endo_VA_poster.pdf": "DC98356B-D2FA-4426-9FF9-3C8B96CC34D0",
    "Endolymphatic sac-2023 CNS Abstract Center Poster.pdf": "76A8FC01-13F7-4393-9FA2-6F27E26AB948",
    "Endorsement letter CNS.pdf": "E5C4BF05-B648-44D1-9FF1-A3795C62B2DD",
    "Endorsement letter.pdf": "9E136B2F-6920-4C23-9639-470BCD89DC32",
    "endorsement_of_removing_barriers_to_clinical_research_act.pdf": "2C837596-89A7-4D7C-8DBA-20E96980C557",
    "Endoscopic Assessment of Ventricular Anomalies Diagnosed by MRI in Hydrocephalus with Myelomeningocele.pdf": "3F3F13D9-5570-47EB-BDAB-DFB80871235C",
    "Endoscopic Lateral Transpsoas Approach to the Lumbar Spine.pdf": "23C3DC8E-BAC1-4D79-8884-4D1FB85DB0DF",
    "EndoSNAPCNS2022.pdf": "06903F2F-3A07-4068-98EB-5E3960E83DBC",
    "Endrit_Ziu-verif.pdf": "61D901FA-6F4C-4129-AF0C-545C125E3430",
    "Engel Score.pdf": "53057002-63D0-4AB2-B892-FC95692BE1F1",
    "Englander__Zachary_Letter_Academic_Standing.pdf": "084C9617-769A-4902-A474-3DB5A037CCE7",
    "English certificate 2024.pdf": "BFA7DBB2-680F-412A-908D-9C6009CE5BCD",
    "English Translation.pdf": "68819CA9-7E32-4195-BB82-3CB107CD203C",
    "Enguidanos MoRe Rod Fracture ePoster CNS 2023 Final.pdf": "4AE44B82-D64B-4EF4-AF3E-305327F99CC8",
    "Enrique González Gallardo-2nd Fellowship.pdf": "9A2593C3-586F-4141-9F76-6EB3CB2A932C",
    "Enriqueta Lucar Figueroa-2ndFellowship.pdf": "0C687DFA-A4AB-48BF-BB57-46D256F7C26A",
    "enrol 1.pdf": "31F7F034-D41A-4454-846F-DB33F392AE28",
    "enrol 9.pdf": "2CC3EFFC-234F-4B0E-980C-05D8370F2B3F",
    "ENROL_LTR-2.pdf": "C50E5704-C3A1-4309-9D55-C6B4D4FD0353",
    "ENROL_LTR_WINTER.pdf": "D88E7626-47BB-4B91-BA68-A1816E1F4387",
    "Enroll_0000792.pdf": "D9FC588D-1752-4E18-A694-156FBC04DCF3",
    "Enroll_0010957.pdf": "16E305B5-BAC5-48B4-B3D2-035675B03938",
    "Enroll_0021965.pdf": "407CC3C8-0A89-4B8A-9631-F76EB403E3C5",
    "Enroll_0062487.pdf": "FC6B8572-FD9E-4102-BAF2-91D6AADCBE24",
    "Enroll_0070241.pdf": "9343B3B8-DF4D-4F3F-9B85-FB281F0B2A31",
    "Enroll_0075093.pdf": "13BD2466-4580-4653-A676-79AEBC743A50",
    "Enroll_0084114.pdf": "E2FAEC91-0312-42D3-9684-5CE33B39EFFB",
    "Enroll_0087098.pdf": "7B65D130-D8FF-4F96-93CF-F28FC7CA7A54",
    "Enroll_0091002.pdf": "65D7CE75-B809-468C-999A-ECE4A1D937EE",
    "Enroll_0097751.pdf": "5E419C97-903E-4925-9F6F-FB99DB177ABB",
    "Enroll_20210913_Good-Standing_SoM.pdf": "F64A04EF-A33F-473A-AFAC-C5FF1B612D41",
    "Enrolled_Student_Denyer_Steven_CNS.pdf": "31BB8BD3-5BF6-4A81-ABE8-2EA9C105E203",
    "Enrollement Verification.pdf": "83799706-D857-445B-A729-F86DF95E0BCE",
    "Enrollemnt confirmation.pdf": "CFC4B668-6564-4EFB-86CF-7338B2CB180F",
    "Enrollment 2022.pdf": "8D97819D-BF4B-4B3B-9062-23C035BD2F24",
    "Enrollment and Degree Certificate - Pablo Luengo Marti´n.pdf": "76598884-A2E3-493C-875F-5D9747DAB2CB",
    "Enrollment Certificate.pdf": "2BF86E22-76D2-42DD-9D11-789BEBAEAFAB",
    "Enrollment Certification - Bamimore 04.27.2021.pdf": "BA4BAF1D-DB89-4F23-AB7C-183FDAF8AC71",
    "Enrollment Certification Letter - Pennington, Zachary A.pdf": "8787ED19-1F1B-4E4C-8ABB-DD84CAA5C89E",
    "Enrollment certification.pdf": "E5E3497B-25C2-4191-A080-D56C551F5813",
    "Enrollment Kit.pdf": "8F8C131E-81DC-4129-BEC0-2E2A31702278",
    "Enrollment Letter - Elsawaf.pdf": "45F4E8A7-A220-4C31-81E8-E1AE31619088",
    "Enrollment letter-Ranjith Kumar.pdf": "C434CD91-66A0-444B-BAF7-1AA354173A75",
    "Enrollment letter-RES Verif.pdf": "6921F036-346A-490E-B3C7-347CE7B7D975",
    "Enrollment Letter.pdf": "DFF48D7D-2A83-47B8-AF00-15E3371A71E9",
    "Enrollment Verification  Good standing_Josef Williams.pdf": "A11D5B8C-21DE-401A-9601-6282163D4759",
    "Enrollment Verification  Letter of Good Standing _MD Class of 2025_Ueno Arisa5025.pdf": "5D04C47C-B39A-4871-97DD-808D0CBE7D32",
    "Enrollment Verification - A. Zheng.pdf": "032C2262-C03A-4894-8458-3E12D81F90A8",
    "Enrollment Verification - C. Mustroph.pdf": "EEFDEC65-C5D4-472E-9CBD-649F2836E5BD",
    "Enrollment Verification - M2 - Bolufer.pdf": "12C109EE-C3F4-4DC1-A129-C25EDBB5A764",
    "Enrollment Verification - M3 - Busse.pdf": "3B3A9E32-B8D5-44E1-B4E6-A46B46DB611D",
    "Enrollment Verification - M3 - McClanahan.pdf": "013AFB5C-80B5-435F-8F36-A3FB5AA9E759",
    "Enrollment Verification - M4 - Clark.pdf": "BF0D8963-C1E1-4455-B1B7-6D58733EBA99",
    "Enrollment Verification - M4 - Zelmanovich.pdf": "19D5C988-5F6A-4537-A4BB-1473F38E5ED3",
    "Enrollment Verification - Welter.pdf": "801C0C33-93C0-4A29-8AA3-082801EF4685",
    "Enrollment Verification .pdf": "03828450-4119-4910-B0B0-5BBAE01B91F1",
    "Enrollment Verification 2018.pdf": "65F55E00-5295-45B8-A085-19E2E2E58734",
    "Enrollment Verification 5-7-2013.pdf": "2276AB9E-1B51-4EBB-9EF8-A872B91E5A82",
    "Enrollment Verification 5.2.23.pdf": "0577ADAA-B1CA-46F7-8C77-103C9A016516",
    "Enrollment Verification Certificate.pdf": "B8530D23-CDC6-4FC2-921A-87CB316A0B80",
    "Enrollment Verification Form.pdf": "A19935B7-91AA-48AF-8504-ABEEBF4D3AE7",
    "Enrollment Verification Kramer72.pdf": "30672BD9-16A2-43F2-B930-913479F29E71",
    "Enrollment Verification Letter - Akshay Chandora.pdf": "5771F58F-6691-46B0-A46F-5E5749436A20",
    "enrollment verification letter for CNS.pdf": "AAB7C9F8-2DE3-40E1-A70D-2972D6063383",
    "Enrollment Verification Letter.pdf": "0C17C5CC-C1CF-419A-A2A5-3DA43FA5579B",
    "Enrollment Verification Ltr - Erin Burke.pdf": "323ED2C3-BE94-4FD8-AA48-296F19681106",
    "Enrollment verification Medical school.pdf": "54428D4C-D176-46EB-9A07-4DB15F8F2BE3",
    "Enrollment Verification Middleton BM.pdf": "E57F96B4-2673-47DA-AF1C-E021C3E84CFA",
    "Enrollment Verification Mvoula_Lord_2018.pdf": "5D6A5CE5-BC21-4387-837A-7764EFA8F6FB",
    "Enrollment Verification OS.pdf": "6DF6C357-6BC8-4938-9A5F-69DE250B38E0",
    "Enrollment Verification Report Anna Nia.pdf": "9AA1CE76-50B6-4699-9B66-35CE6486EA4B",
    "Enrollment Verification ShalinShah.pdf": "9D79106B-C52C-4EBB-A204-64485861E362",
    "Enrollment Verification Transcript.pdf": "3BE239CE-DAEA-41AF-9CD3-0D322307B9F6",
    "Enrollment Verification UTMB.pdf": "C03F59CE-EEF7-427C-BF6B-9FE7F17AF7BC",
    "Enrollment Verification ValenzuelaFabiola_CL2021.pdf": "5E3E447F-D57F-4EAE-8B99-9E713F2331E1",
    "Enrollment Verification.pdf": "3F38037A-0EA8-4950-8369-0504F18325EB",
    "enrollment verification_Akbota Kurmangaliyeva.pdf": "5E923682-6935-4BD6-A345-E3004B52024E",
    "Enrollment Verification_Joseph Villarreal.pdf": "4A0E51B6-CCFC-410A-BD71-1F0F830F9E50",
    "Enrollment Verification_NitiDharwadkar_CL2023_CNS.pdf": "C6C63467-BDC6-487C-ACD1-F9CF8A67AB47",
    "Enrollment Verification_UPSOM_24Jul2023.pdf": "7292E279-B8C3-48B4-9774-D022B85E9EBB",
    "Enrollment Verification0001.pdf": "41D66048-4D48-41CD-BBC0-FC4C208F7769",
    "enrollment-verification-2021-09862-20211126175151.pdf": "94409581-9E93-41A8-9E58-3C55FA98FE41",
    "enrollment-verification-2022-14713-20220809191031.pdf": "CEF6656C-298E-4AA2-B594-3FFE26205B20",
    "enrollment-verification-2022-14723-20220810120455.pdf": "87B2C201-17B7-4545-8653-CB1A2551F44B",
    "enrollment-verification-2022-14762-20220812104606.pdf": "8A74D0E9-97B1-47FF-9BBD-7FCBD021DCA1",
    "enrollment-verification-2022-15281-20220909083423.pdf": "39D1F6D7-4C57-4E91-9724-CFD38540FADE",
    "enrollment-verification-2022-15783-20221004151357.pdf": "E026E2D6-8309-42AA-B703-8622B8B51F57",
    "enrollment-verification-2023-20964-20230713161014.pdf": "EDD93D38-5524-4098-921A-141B6877DE11",
    "enrollment-verification-2023-21193-20230823190256.pdf": "032EC1D0-6AFC-489A-9D66-2352ED959D78",
    "enrollment.pdf": "FBDE9CAE-F698-4843-8A10-0A6B2B6BAD5B",
    "Enrollment_Good Standing_Verification_Murphy.pdf": "AB8284E6-F288-4170-9119-79C5197398E8",
    "Enrollment_letter.pdf": "09C9AA8B-1A32-4FD9-94A6-E65CC699C526",
    "Enrollment_MUSC.pdf": "F3E5D720-7CE7-49ED-9E1B-BD7752898227",
    "enrollment_verification.pdf": "3D11964F-D675-43D7-B641-D4849E8FB8DD",
    "Enrollment_Verification_Report (4).pdf": "5F63E0DD-DBC0-4046-8BF6-CA2F717A2A77",
    "Enrollment_Verification_Report 2.pdf": "201DBFFA-722F-497F-8613-1F5434E31C9B",
    "Enrollment_Verification_Report.pdf": "B8E84DF5-6045-4DD8-9B64-196EF2F9F038",
    "Enrollment2023Ashby.pdf": "689EED0E-828E-4C2E-A089-AE2D80048CC2",
    "EnrollmentAndTranscript_JonathanTowne.pdf": "D9EC8D13-C64C-4970-9475-588B1CD4886E",
    "EnrollmentCertificate (1).pdf": "EA51EF68-4F9A-47CF-A940-456FFCBD63EB",
    "EnrollmentCertificate (2).pdf": "7C619D55-119E-4A31-9C7D-A1BBB6299FA4",
    "EnrollmentCertificate 1 1.pdf": "77198E61-0B71-4615-888F-E0B1872C44F2",
    "EnrollmentCertificate 1.pdf": "E95C7DDF-38EF-412F-91FE-201CD99DB86F",
    "EnrollmentCertificate 15.pdf": "18504C0B-5B06-4883-A8B5-9C5382BE1BE1",
    "EnrollmentCertificate 2.pdf": "32C95193-C6C0-45A6-B126-44E547A8BC48",
    "EnrollmentCertificate 3.pdf": "2BFD6347-E2AD-4050-B889-29F0FB6B4FE6",
    "EnrollmentCertificate 4.pdf": "96A27D86-B3B1-4308-A08D-70EE862FF992",
    "EnrollmentCertificate-1.pdf": "2B19A10F-8E9C-42EF-84AB-5A235BB0BA7A",
    "EnrollmentCertificate-2.pdf": "8703ED26-6B1B-4CC7-8D28-49DB95A3392B",
    "EnrollmentCertificate-SarahBelay.pdf": "59C39097-9427-4CF5-A3D8-ECD8E8EC6433",
    "EnrollmentCertificate.pdf": "D3744265-4326-4A89-B97C-01D09591C144",
    "EnrollmentCertificate_FullWinter2021.pdf": "EA7234BD-6021-44C5-BF2A-48250F3A9D56",
    "EnrollmentCertificate_Krisna Maddy.pdf": "26CBF24B-7D31-4DED-8FF0-BB649399EE29",
    "EnrollmentCertificate2.pdf": "9B1BBEFC-B2B9-4BF2-AB53-4B95937F6051",
    "EnrollmentCNS.pdf": "F200AD80-640D-4DC7-B7B2-6278E88DD0DB",
    "EnrollmentCourtney 2.pdf": "3A20F4CA-8D95-464C-BD36-F911813813C8",
    "EnrollmentLetter.pdf": "A80035D9-F73C-4366-BF0C-8E46218A5101",
    "EnrollmentLetter_SongRyan.pdf": "46E52246-D741-4B85-BEDC-1442939C7C76",
    "EnrollmentLetterForConf.pdf": "366CBEA5-37B6-4401-B57D-6EF42DD08CC3",
    "EnrollmentProof.pdf": "78AAD44E-EBD3-44C2-B846-83855010B252",
    "enrollmentVerification.pdf": "13EAB907-31A1-40C4-BDEE-8EC05740CA4C",
    "EnrollmentVerification_Griggs Whitney 2025 LOA.pdf": "82AF7E78-8B33-4305-9A1F-1709ACA5F41B",
    "EnrollmentVerificationJHUSOM.pdf": "C2E8FBFA-F6F2-4B55-9A66-41DC85F69B0E",
    "EnrollmentVerificationSchmid.pdf": "E7AAC676-5C69-4E81-AA83-1F9D7131B128",
    "Enrolment and Good Standing Letter for Congress of Neurological Surgeons for Ajibola Odesanya.pdf": "6E2EA274-6800-4104-BEE8-1E8711FF6A46",
    "Enrolment letter_Ghodrati_Farinaz.pdf": "375D975D-FAB6-4C27-8F56-B807B1949DC8",
    "Enrolment proof and Degree Program duration - Viviana Cortiana.pdf": "57A08C7F-5D61-4A52-8ED4-2FE784138311",
    "Enrolment Verification Letter.pdf": "D445C4A2-4BB6-43F6-ADF2-DC655662385F",
    "ensuring_childrens_access_to_specialty_care_endorsement_letter_2017_final.pdf": "1CCB38E7-1AE9-4086-AF2B-E702BA612688",
    "Entezami Residency Diploma.pdf": "AD3EB9DD-7E1E-4EFD-9E14-9D5EDB93C92F",
    "ENTRUST CNS Digital Poster Presentation_7Aug2019.pdf": "18A4DDD1-365E-4C53-900D-8650E9275557",
    "EO Letter of Support.pdf": "11921A9B-81BD-4951-9372-69D2E69DB67C",
    "EONSD Poster .pdf": "5F9967E2-D5E1-4599-895F-819411A5FA46",
    "EOY CF Coalition Letter FINAL.pdf": "010B3F11-7B11-429A-ADB4-736374BF472F",
    "Epilepsia - 2023 - Krishna.pdf": "99553E3F-93C3-49CE-9451-BB21EA579887",
    "Epilepsy Trends POster.pdf": "A6FBF770-E306-4F67-B9BC-A62FA5CCD000",
    "epilepsy_DBS.pdf": "B8175E74-21B6-4BE0-9069-61E6C80D20A4",
    "Epistaxis.pdf": "B27D6061-D80D-45DA-B89B-6611BB3A47C4",
    "EPSON029.PDF": "464D7BE8-A465-4F61-9DE6-0AEF3497D495",
    "EQuach-emailverification.pdf": "4A5C10B8-41A7-44AA-841E-25383B5DC7CA",
    "Equity in medical education Addressing microaggressions and discrimination on the wards.pdf": "B3498100-6645-4D04-A995-BEBB01909193",
    "ERAS Manuscript.pdf": "AEBA021C-39D0-4868-8401-87ABB576F694",
    "Eric Chen CV Nov 2022.pdf": "11954E5A-5BC8-4009-9377-EBD206C43E54",
    "Eric Sankey_RESverif.pdf": "1ECE2C99-024B-4EA9-96FE-B45A6EFB9D5B",
    "ErickaAceves.pdf": "73088F9B-E44E-4033-8FC3-8116E3435260",
    "Erik Brown-RESverif.pdf": "B9BF5BDD-39B5-4367-AC46-59FD70C97A3A",
    "Erik Daniel Rosas Molina-RESverif.pdf": "A3B6C1EC-9A09-48A5-8734-8D6463F38B0D",
    "Erik Larson-RESVerif.pdf": "D4B9756F-EFDF-47D9-828E-5D3C4E91EAF4",
    "Erika Leck-2yrFellowship.pdf": "84048F87-F0F8-4D93-A8A4-6705323409B7",
    "ErikCurtisMD.pdf": "3E9E3B0A-C363-420E-AA8A-BCE46068AC6B",
    "ERNA_ASSFN24_POSTER.pdf": "34D326B4-509A-47A8-A142-E8B7C5ECCED9",
    "Erwin Lazo MD-verfiLetter.pdf": "8054666A-1AF7-4A35-988B-2016B70946FB",
    "ESC LOR GZ SNS 2022 .pdf": "C32D7A5A-0E98-49D4-AF8D-8E03621B3FFA",
    "ESC LOR JM SNS 2022 .pdf": "F55E967C-EA70-4869-B839-C802E7DB5DC2",
    "Escalante_Reyna_CV.pdf": "FD6A6DFA-4FFF-49DE-8B7C-333F4F49C33E",
    "Escanear 28-04-2013 19_26.pdf": "E1FDEF74-20AC-4D58-9B2D-D2BEDC39AF76",
    "Eshraghi - Resident Diploma.pdf": "D10FCA86-787D-4244-89BE-291CDD800864",
    "ESNS.pdf": "6BAB67C5-CD63-4127-BCDB-C1D70530B09E",
    "Espino Rodriguez_Cesar.pdf": "D2B9A7DB-6962-46F3-8F0A-2826EB0D9023",
    "Esposito_CV_Nov_2010.pdf": "BD040079-9462-4F6E-9E96-EE635C2DD3A8",
    "ESRD_SDH_CNSposter.pdf": "967924EB-0029-4703-A22B-54858392E3A5",
    "EsSalud certificado termino residencia.pdf": "28C0F0B6-35DF-468B-9700-2669CBC25F85",
    "Essential Workforce Parity Act 070920.pdf": "0F30F8C4-2388-4438-885D-F08C5B5F576E",
    "Essentials Draft 1-16.pdf": "5B1D1842-80FC-4171-9A53-61429C066C78",
    "Essentials Draft 1-19.pdf": "03FFCC95-6E1D-4FC8-A7AF-F1A3A573A1E0",
    "ESSO- Digital Poster .pdf": "184642BF-AB46-4DA5-A25F-6D21572ACAF9",
    "Esswein Shannon (2023) LOA Approved.pdf": "BB3EC72E-12ED-496F-BFED-0FE2D13AC6EB",
    "Establishing your practice - G. Barkhoudarian.pdf": "8728C31B-72A3-4389-9DD9-9BCBCBCD11E0",
    "estefany mendoza verdin-RESverif.pdf": "F0E00A51-FE2D-4DAC-AA41-AEC966B9CDE0",
    "Estes LoGS.pdf": "F18EA0CE-9024-4DC5-AC96-4ABB25CAD96A",
    "Estevez-Ordonez Dagoberto.pdf": "77B80BA7-C948-45F9-89D3-EFF0D9D92CE2",
    "Estudios.pdf": "E1ECD8AD-1F57-49CE-AD6E-1B9870A0D12A",
    "ET - CogBBS Poster.pdf": "73F74263-24A5-48E0-A265-54CA5D247670",
    "Etaiwi Membership certificate.pdf": "15966B47-1BCF-4030-A72C-B7AB094A6692",
    "Ethan Devara Verification.pdf": "E35B4AE4-B380-4188-90B4-65584E23AEF2",
    "Ethan Srinivasan- Enrollment Verification.pdf": "EED2DBC2-FE5B-4F33-8D91-0A96C79BFA36",
    "Ethan Winkler-RESverif.pdf": "A0855E6D-7BCC-4727-9DF8-9121EFE72F5C",
    "ETV Poster.pdf": "2BCAA674-E020-4253-8301-3EFB25592BDC",
    "ETV to Shunt Poster.pptx.pdf": "2A0B255E-8236-458C-8381-E575D04C6ABB",
    "ETV_rate of accel_peds section_MNS_final.pdf": "6622C9D4-DF11-4FBE-88D9-718F84A9DECB",
    "Europass-CV-120423-Abusultan.pdf": "67F76DBA-8F11-416D-AB9C-86149A786903",
    "European Association of Neurosurgical Societies - SocialLink.pdf": "6CEB2D35-8E3D-4FA6-819F-2D1776AFDC38",
    "eurospine membership.pdf": "BEEBE833-03D6-4733-AC9E-263B93165453",
    "EV_Mo.pdf": "B9D8EECF-747B-47E8-BEB2-96C31231FFC5",
    "EVALUATION OF BRAIN FETAL DEVELOPMENT .pdf": "A97B88C9-4C46-428E-8495-F91C164DFE07",
    "Evan Bander CV.pdf": "9ADD3C09-0B7A-401B-BD7E-9292A032C50F",
    "Evan Bander letter for CNS resident committee.pdf": "2CB97BAE-4A72-4879-9FF3-B36ADB2791B5",
    "Evan Bander-FellowVerif.pdf": "12F1835E-AA4B-4726-AD59-31F132ED4B5C",
    "Evan Joiner CV.pdf": "71AEE7B5-4128-43FE-A257-BEE3FA46F507",
    "Evan Joyce-RESverif.pdf": "AEA2DDDE-16A1-448D-A6AF-128BFB61C174",
    "Evan Letter of Good Standing.pdf": "BFE09846-7C07-4363-9719-CFCD3B9EC065",
    "Evans Madison_Letter of Good Standing.pdf": "81CABF61-13BF-4926-9E72-5B8B157715EE",
    "Evans_CV.pdf": "93D1CD68-E680-43C5-8F4F-99C283F25E96",
    "EVD CNS 2023 poster.pptx.pdf": "0099EF7D-7D2D-4E86-A94B-6F844A964201",
    "EVD Neuronavigation Poster-CNS-0720.pdf": "D5810B09-899D-4E17-8D85-BDC4CCBE3D45",
    "EVD Poster.pdf": "BB7229BC-2E99-4D43-A707-1BD8B2B0D121",
    "EVERARDO GARCIA-ESTRADA-CV.pdf": "B9C68211-9BFA-4849-AAA8-E0104B601EEA",
    "Everardo Garcia-Estrada.pdf": "41472850-5978-4648-BE72-5A4E18B6688D",
    "Everette Martin Verification Letter .pdf": "2417D1CC-31AA-49E4-9ED7-8E3007C5C1CF",
    "Evgeniya Tyrtova_CNS Verification  letter.pdf": "144A0A8B-92B2-47CC-8076-CE02DF44A494",
    "Evin Guilliams-Fellowship.pdf": "B848B91C-5C02-42A6-A56D-669B9713A61D",
    "EVSI CNS Poster 2019.pdf": "5BE288EE-FD6A-4188-89A8-6C54844A9DC5",
    "Ex. A - Amicus Curiae Brief of PAI Natl Med Specialty Societies and State Med Ass'ns.pdf": "1BE2FC49-101E-455C-BD67-82B6D60E51FC",
    "Exact Logistic Regression Analysis.pdf": "946E298E-CC8C-43BA-9272-3C6C891EE5F4",
    "exc criteria.pdf": "ECE8DFD2-5922-47CD-889D-15BAFFEA450F",
    "Exhibit Application_2022 CNS Annual Meeting.pdf": "6CB40CAA-9F0B-467C-9FC1-008DBE48F972",
    "Exhibitor  Industry Support Prospectus _ 2023 Tumor Section Symposium as of 03_03_2023.pdf": "17D04E24-6E41-4539-A57D-BDC380481587",
    "Exhibitor  Industry Support Prospectus_ 2023 Tumor Section Symposium _ 051723.pdf": "60780DC9-E799-4F08-B46B-385B504A94CF",
    "Exhibitor  Industry Support Prospectus_ as of 6.23.23 _ 2023 Tumor Section Symposium.pdf": "BBBBD5B0-E3E0-4DFE-A1F4-42F103A22270",
    "Exhibitor  Industry Support Prospectus_ as of 8.3.23 _ 2023 Tumor Section Symposium.pdf": "A29686E1-6534-49C3-BF68-3A0583DF3BEF",
    "Exhibitor  Industry Support Prospectus_2023 Tumor Section Symposium 003.pdf": "60D5F875-E13C-4479-A69B-D6BC5C8ABA52",
    "Exhibitor  Industry Support Prospectus_2023 Tumor Section Symposium 02242023.pdf": "4E44C998-6590-4B96-B0CD-7A461D51DF42",
    "Exhibitor  Industry Support Prospectus_2023 Tumor Section Symposium 02272023.pdf": "F527BAB2-0A4F-4E73-AF10-04E77D0F3F57",
    "Exhibitor  Industry Supporter Prospectus _ 2024 ASSFN Biennial Meeting 01162024.pdf": "D12D1BA4-AAB9-4C31-95D2-63C031F0B895",
    "Exhibitor  Industry Supporter Prospectus _ ASSFN 2024 Biennial Meeting 09222023.pdf": "D64A6EF6-E124-46A3-BD90-14A36DBB1E06",
    "Exhibitor  Industry Supporter Prospectus as of 10.16.23 _ ASSFN 2024 Biennial Meeting.pdf": "D27C6C39-7581-433E-A7CA-6EC6F15B4228",
    "Exhibitor Application _ 2022 ASSFN Meeting.pdf": "BEBD376B-D2B7-4357-8220-E461861A5A01",
    "Exhibitor Application _ 2022 Spine Summit.pdf": "02475D91-4C98-4B06-B59F-45DA2F46C358",
    "Exhibitor Application _ 2023 Joint Pediatric Section Annual Meeting.pdf": "835AFF0E-CABB-4BC5-B65D-128B6465DEF0",
    "Exhibitor Application _ 2023 Tumor Section Symposium 03012023.pdf": "CA905DA1-B78D-4F02-8C30-CB1B3A1DEFD9",
    "Exhibitor Application _ 2023 Tumor Section Symposium.pdf": "55052B44-979F-46B2-9868-0C16E378C8A8",
    "Exhibitor Application _ 2023 Tumor Section Symposium02242023.pdf": "6C94259E-4BD7-43EF-9DC7-CF7C2AC72CC9",
    "Exhibitor Application updated as of 10.16.23 for 8x10 booths_ ASSFN 2024 Biennial Meeting.pdf": "087A4C8B-682C-47F1-8211-0BDF5BC00E0A",
    "Exhibitor Application.pdf": "971D74FE-1643-4DF2-8E4F-143E5C5DD816",
    "Exhibitor Application_ ASSFN 2024 Biennial Meeting 09222023.pdf": "304B39C9-BD21-4157-A6E9-D8DBDD76B2FA",
    "Exhibitor Application_2021 CNS Annual Meeting.pdf": "4D7EF684-80AA-46CC-B6D2-BBD0C91D892F",
    "Exhibitor Application_2023 CNS Annual Meeting.pdf": "DB9E830A-47A3-477F-9041-2E05C5E0FA17",
    "Exhibitor Meeting Suites _ 2021 CNS Annual Meeting.pdf": "E50A4D11-BDB0-47FD-8780-7E5DCA640F2A",
    "Exhibitor Meeting Suites Application_ 2023 CNS Annual Meeting.pdf": "E8136F06-26CA-42C9-92E8-17D54CEB5A88",
    "Exhibitor Prospectus & Support Opportunities _ 2020 ASSFN Meeting .pdf": "65DC876B-8F24-46E9-87B6-795EEC3961F8",
    "Exhibitor Prospectus & Support Opportunities _ 2020 ASSFN Meeting.pdf": "490ACD8F-C057-4742-98B7-2F116F72B759",
    "Exhibitor Prospectus _ 2020 Spine Summit __ as of 01_13_20.pdf": "EAF9DD9C-E6F9-46DF-A644-325A43C9487F",
    "Exhibitor Prospectus _ 2020 Spine Summit.pdf": "3E3E7AC1-7AD7-4BC7-808B-3E85CDA138FC",
    "Exhibitor Prospectus _ 2021 Spine Summit _ latest as of 5_5_2021.pdf": "FE630865-8589-4CE7-BC06-60B82319B3A4",
    "Exhibitor Prospectus _ 2023 CNS Annual Meeting.pdf": "4F4CEDE9-2171-464E-83BA-97F1CCDDE508",
    "Exhibitor Prospectus _ 2023 Pediatric Section Meeting 09222023.pdf": "7A3FAE22-2680-4A42-B0D2-C9B65AC1A0F0",
    "Exhibitor Prospectus _ as of 12_1_2021 _ Spine Summit 2022.pdf": "B2FFD4EE-4B07-4631-B787-F14E9BE4305C",
    "exhibitor prospectus.pdf": "924BF1E1-1E5F-4948-BFDD-0ECA55359E66",
    "Exhibitor Prospectus_2021 CNS Annual Meeting.pdf": "E672D248-C4B8-4024-BAA9-F9BA42FEEC8F",
    "Exhibitor Prospectus_2022 CNS Annual Meeting.pdf": "034785D9-BF14-4F3A-BE69-08F18A79753F",
    "Exhibitor Prospectus_2022 CNS Annual Meeting_v3.pdf": "47FF6CE5-E038-4D40-97D5-DB9B314E49A1",
    "Exhibitor Prospectus_2022 Pediatric Neurological Surgery Section Meeting 002.pdf": "3C1C6A29-1554-4AFE-B23E-7F9E9990B716",
    "Exhibitor Prospectus_2022 Spine Summit.pdf": "08C3D0E3-255F-4C56-8205-5C972692B4EA",
    "Exhibitor Prospectus_as of 03.11.2022_ASSFN 2022 Biennial Meeting.pdf": "EF1FC3B0-F619-4E01-87AD-AB92F95AE195",
    "ExorbitantCost_ACDF.pdf": "A244282A-3898-4A79-94F2-ADFF665B602C",
    "ExorbitantCostACDF_AE.pdf": "49F367AA-CC0F-47E4-80AE-9FC0EC554B8B",
    "expierence certficate LRH .pdf": "28E9DC34-73F4-4EDA-9162-7DE117E34789",
    "Extent of Pre-Op Suprasellar Extension.pdf": "3BABDEB3-A5A6-4E70-9C33-E613805314C4",
    "Extent of resection.pdf": "25708C1B-3035-47D0-8416-EEE8AE799E3F",
    "Ezadeen Sharaf Ali Saeed Al-Selwi.pdf": "CBCA5491-330B-418B-ABF5-900EE80896A4",
    "Ezeudu_app and letter.pdf": "F92E9D97-301E-4E24-B884-4ABEB5473179",
    "F Alotiabi.pdf": "AFD623B3-8CD5-4F41-94CB-AF21A4325FE8",
    "F Khursheed Resume.pdf": "0AAA3113-1A88-41F2-B10D-BDD64E3E8E1E",
    "F.ALSAIEGH-SHARAN.pdf": "0865393D-3D04-4671-AC50-69A4AB73312C",
    "F18AV1451 CNS poster 2019.pdf": "3C7CD09F-5BB1-466C-8D0E-E8DF8589C4BC",
    "Fabio Grassia CV 2023 last 1.pdf": "B5D75027-4723-40F1-A83D-2CDE95B5424B",
    "Fabrega_LOGS.pdf": "5343695C-D1DE-4C47-A8B9-B7A76E5C87BD",
    "Facharzt Urkunde Deutschland.pdf": "EADE17A1-C757-443C-989B-98F02A8B599C",
    "Factors .pdf": "1002C4F4-E8F4-47A1-9374-F69EC94D2AAB",
    "Facts 2016.pdf": "32AC5DB5-632D-4243-BD7B-7B416F57B623",
    "Faculty Reimbursement Policy.pdf": "5A7A1C7E-7992-4C2D-95CF-803A5713D7BF",
    "FaithRobertson CV 090421.pdf": "9CEDC224-5957-4EAC-9C0D-ADBC1F5D5842",
    "Faizan Khan GENERAL Good Standing Letter.pdf": "A4B0B3F3-E8ED-4144-811C-F0E65AD8C6D2",
    "Fall 2017 Letter of Good Standing.pdf": "3AFF8412-8E7C-47C4-9665-D75735493537",
    "Fall 2020 IOP_Tumor Section_Descrip.English version.pdf": "8F7AC925-D7E1-4458-BD78-5C4A40FCCC1D",
    "Fall Oral Board Course Agenda Draft 05162024.pdf": "BA2277B3-9DDD-4576-A7D1-C9378462497D",
    "Fall Oral Boards Agenda .pdf": "694AD414-3C45-410E-B1E6-161680AEAF86",
    "Fall Oral Boards Agenda 09162022.pdf": "F3802FAF-C6D0-4DFC-ABBC-732668C7976B",
    "Fall Oral Boards Agenda V3.pdf": "4693140E-E945-4C5A-8614-E54628AB41C6",
    "Fall Oral Boards Draft Agenda 7-12 002.pdf": "23FD05DB-2280-4754-AFDC-91A0DC081D3A",
    "Fall Oral Boards Draft Agenda 8-23.pdf": "07A1F09E-16F8-4DE3-9FE9-D1F5869C598F",
    "Fall Oral Boards Draft Agenda 8-9.pdf": "F3858A3C-E042-4D43-A584-FAB629BD732E",
    "Fall Oral Boards Final Agenda 6-2 (002).pdf": "602207A3-EA71-47A4-B14E-AB2DDBBCDE26",
    "FAQs on SNS External Medical Student Policy During COVID-19 pandemic (final v3).pdf": "E868D24E-FA39-430F-A327-310632E38EB1",
    "FAQs_ICD10CM_Final_20170109.pdf": "91C91549-94FE-4A1F-BC9E-2BB1E26CC8B5",
    "Faraz Arkam Deans LOR.pdf": "F92B92D0-1303-454F-B55B-7E20F65AC450",
    "Farber Letter 20150717.pdf": "64D5CEBB-7296-4FBE-8828-35D0C34FBE67",
    "Farmer_Matthew_LOGS_08_09_2022.pdf": "D2DC56D0-4466-4443-B0F2-5730F5FEFE9C",
    "Farooq Jeffrey-Letter of Enrollment.pdf": "00AAAAD7-4F28-412E-AF25-D82D3D764298",
    "FathiKazerooni_NIMG-102-Auto-segmentation of pediatric brain tumors.pdf": "F24190E1-49FA-4552-BCF0-2E17A93BA8DE",
    "Faust A- Enrollment Verification.pdf": "B255C846-CEEF-4A7D-8D10-8FF2BA5DD83F",
    "Fawaz Alotaibi - CV July 24.pdf": "F53C4FF8-3972-47FD-93ED-7D9223D07C37",
    "Fay_CV_20130815.pdf": "059C0410-A127-4E28-B8F2-C1849577C78D",
    "FBerry.emailverification.pdf": "9BC04096-9BF6-4906-BAA4-AEDD6221C058",
    "fc71e055-15b3-4d6a-ac0e-b8db2753b635 1.pdf": "E7AB895E-7E97-4CD0-A3BF-CF83F8350AD2",
    "fcps neurosurgery programmme.pdf": "D2A17A3F-976A-484F-BAD2-3777721907CA",
    "FDA Off-Label Hearing Testimony William Welch.pdf": "2D65423D-19F8-41F7-A041-896571B9D843",
    "FDA Presentation 022317 Adnan Siddiqui MD Embolic Protection Device.pdf": "C6624A73-54F2-4024-85F3-4FD589171598",
    "fda_off-label_hearing_testimony_william_welch.pdf": "2EBB3AFC-46A6-42EC-9C83-94102F43030E",
    "fda_off-label_hearing_testimony_william_welch_0.pdf": "2A32DB81-AD69-456B-AE80-E5C0A5EDCDFF",
    "fda_presentation_022317_adnan_siddiqui_md_embolic_protection_device.pdf": "0581C0E2-2651-4D4D-A63F-C8EA3FB2458A",
    "feasibility_study_poster.pdf": "851B3CAB-BBDF-4492-9B3C-1F18191841A7",
    "Featherall_Joseph_Congress Neuro Surg.pdf": "E3385EE7-F093-457D-896A-FF41B1D8E8A6",
    "Feb 2021 article.pdf": "D4D91919-C833-4EA3-9F04-70BE32433C41",
    "Feb 2021.pdf": "2F3C7865-308D-4979-B164-82E62BE7ECC1",
    "Feb 2022 journal article.pdf": "A04F1AEA-F551-4564-A0EA-1FFF081740D4",
    "Feb 2023 Journal Article.pdf": "300D4ACC-1CE4-4EB6-BA13-F4A5915FD97A",
    "Feb 2024 Article Final.pdf": "191BFD07-9CA3-4B94-ABF3-FE56A732AE26",
    "Feb19 AACNS newsletter.pdf": "B76A39A5-0C6D-405D-9D45-4BBDC7BB9108",
    "February 2022 journal article_update.pdf": "D4707A60-CF32-4BDD-B25F-57793CFEDDF9",
    "Fecci  Peter.pdf": "12B49DEB-8D8D-49CB-A771-D58A2AD5B6CC",
    "FeckerA_Letter of good standing.pdf": "BE78220B-651A-4FAC-AF6F-8FA494BEAB71",
    "Federal.pdf": "6EF7BF2D-2E51-499A-9FA2-9EBA46B77D03",
    "Federation Sign-on Letter Liability Protections 6-9-20.pdf": "9AFA25CF-940A-445F-980B-253FA11D4A66",
    "federation_sign_on_letter_surprise_billing_7-16-19.pdf": "1E55E93A-FB4D-4C50-8495-C37B33CD9E30",
    "FederationGracePeriodltrtoCMS.pdf": "F1ABC9AF-645D-481C-8B4F-5944AA17C2B1",
    "Fedor Panov Slides.pdf": "CFB98999-5912-4411-8977-26607E778BFF",
    "Fehnel KP CV 030124.pdf": "75E0A63F-C16C-492E-BD64-09D6AD06C73F",
    "Fehnel KP HMS CV 31521CNS application.pdf": "20869260-2A4C-4733-AEF0-71AFACA9C2FC",
    "Fehnel_ICU_care_ICH_2024.pdf": "3FB0E645-99C7-4F89-882A-F61D4EA47824",
    "Fehnel_Vasospasm_101_2024.pdf": "077B7FCF-E3EB-43C8-BE70-D24D85E3BE6E",
    "Feindel, Bill.pdf": "BEA7CE12-00C1-46A3-8E5E-4F7AEF0F1A2C",
    "Feindel, William.PDF": "22487297-3A1B-4092-B42B-5630B710B967",
    "Feldman CSNS Rec Letter 2022.pdf": "BEEA167C-A8F7-49EC-8FF9-4CB7152F5EA9",
    "Feldstein Letter CNS2324.pdf": "ECF7BA92-F8F0-43EF-BAE2-E63A7FC13183",
    "Feldstein_Neil_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "E63E31E2-4B38-4E77-A8CD-37FD0FF33BE8",
    "Feldstein_Neil_2024PositionalMembershipApplication_CVandBiography.pdf": "DB66C668-B240-4CAD-AE00-F317F5CA0880",
    "Feler_Josh_Good Standing Letter.pdf": "D408FD0B-BB5D-4661-A3B9-D1BD0DCA2D6F",
    "Felissi CV.pdf": "236789AF-045C-4688-A6D8-AC61CA393D17",
    "Felissi Verification.pdf": "08CAC521-0B9D-4243-BD67-261D4A37BEEF",
    "Feller Christina Letter of Good Standing.pdf": "4F2E9F0D-1FA2-4FB2-BDAC-04E1BED292B6",
    "fellow letter.pdf": "1A22DE71-2A50-4AB4-B55B-5D8AB629EB3E",
    "FELLOWSHIP ADMISSION.pdf": "7A8F5CF3-B806-4E2C-8F05-0D4DEDAEA149",
    "Fellowship Certificate - PGY8.pdf": "B3C29E08-EF2A-4C8F-A091-10CD8E55C688",
    "Fellowship doc-Serdar Rahmanov.pdf": "790F845B-E126-4141-AA81-285A8D3EB4CD",
    "Fellowship Extension.pdf": "964D40C1-55C0-4F04-ADC6-874FCC724890",
    "Fellowship Offer Letter.pdf": "7F201EC8-66E4-489B-A082-F6788FC0CEDB",
    "Fellowship Recommendation 1.pdf": "3EBD1715-C304-435C-A022-1F9CFBF48271",
    "Fellowship.- Dr.Fernandez-de Thomas.pdf": "21BAC778-7D4D-4058-A0C5-CF2E8D2C970A",
    "Fellowship_ membership-.pdf": "3C1BF912-415C-4A18-99C4-025C1AFFA9CB",
    "Female Subspecialty Poster.pdf": "2B428C88-713F-43C2-97DC-3DCBFEA98311",
    "Feng Austin - Good Standing Enrollment.pdf": "F1FAA8A0-554E-4FE6-9C87-4303D5214408",
    "Feng_J_Enrollment Verification.pdf": "C405FDCC-7542-4C6B-B7FC-5E4ACF85475E",
    "Fernandez Abinader-Neurosurgery Residency Diploma.pdf": "0446E247-B1E8-492F-ACDF-50C931BE2D7C",
    "Ferreira SNS letter - Barker.pdf": "9D63A395-C485-4848-A15B-41E0072A5538",
    "Ferreira SNS Nomination LOR 2022 - Ellenbogen.pdf": "9B4A5F36-F526-47B0-BA74-B0543D591F30",
    "Ferreira Society Doc.pdf": "50211018-4B3D-4457-9348-549A245467B3",
    "Ferreira_CV9.26.2022.pdf": "B8A90325-7D8C-49DE-B4A5-DA061D0D0964",
    "Fessler E-mail 2009 0205.pdf": "3522CBC0-74ED-4F08-BBAE-5A1E2F1021BA",
    "Fezaan Amer Kazi.pdf": "36EE910C-3C00-458B-AC1A-91E09337B848",
    "Fezan Kazi CNS CSNS letterhead 031023 1.pdf": "CB698F16-EBE9-445B-B471-1694C575BB64",
    "FGA CNS Poster.pdf": "AFF7195A-5141-4FB2-BA27-AB7B7652D3F8",
    "FIBER TRACT ANATOMY OF THE HYPOTALAMUS2.pdf": "8B813354-37C8-402D-8AFC-69AEBBAE3739",
    "Fibro Myeloid Poster.pdf": "B945E747-A3E5-46E0-8307-0E034DB6B901",
    "Fieggen, Graham.PDF": "8A71DA60-9FDE-4200-BAC0-A3C3C069074B",
    "Field good standing.pdf": "ED81BB18-AE53-479E-9717-7B6E4B72846D",
    "FIENS Fellowship Ulrick.pdf": "572FD5E6-DA1A-487B-A132-CA5253B2E99D",
    "Fig 1  2.pdf": "657666BF-B02A-4421-B694-5912EA6F32DC",
    "Fig 1 Cell Culture Studies.pdf": "0548307D-537F-4058-ACA5-71D31DE47F6E",
    "Fig 1 psoas MRI.pdf": "E4BF9012-E3BA-44D6-89DE-2775FC79A378",
    "Fig 1.pdf": "C5845876-2F07-407C-8ADE-153981BFFA8C",
    "Fig 1A EOR vGBM .pdf": "E34AA3E8-73C5-4824-995A-91696CEC2056",
    "Fig 2 Animal Studies.pdf": "0FBA4681-6009-469A-A561-24E029750757",
    "Fig 2 psoas morphometrics.pdf": "3402F827-5A03-483D-A3FF-176487F399C7",
    "Fig 2. Standing vs Intraop Stats .pdf": "B4F3D403-CD88-4DCD-8748-3C97E21E1621",
    "Fig 2.pdf": "BD642081-8E6F-42E8-AD21-0B49C49E1532",
    "Fig 3 Human Study.pdf": "A1DC4C6B-8137-4C39-89AC-40289B37C2C0",
    "Fig 3.pdf": "01F41E64-48B2-4000-BDE6-6DBD289C5BC3",
    "Fig 4.pdf": "343F9EF7-5520-4FDD-9481-410A86DD6825",
    "Fig 4_R3 copy.pdf": "FD841328-CCB4-4224-A04F-84B29593327D",
    "Fig. 1 Intraop.pdf": "9CB4087F-CD71-4393-9E30-3D6870D3439D",
    "Fig. 1.pdf": "898C5F90-D713-4FF6-AD61-98BC776AA052",
    "Fig. 2 schemes.pdf": "F5CA2FCD-0FA9-40E2-8ED9-73C18581E69A",
    "Fig. 2.pdf": "1DB9C837-6165-444B-8FF5-0C40BE258E53",
    "Fig. 3 CasesAge.pdf": "57C73F3C-2B04-46F3-A741-CB0EDF32323F",
    "Fig. 3 CT vs Intraop stats.pdf": "CB34E8A8-3124-4933-9002-1FA1147DC6B7",
    "Fig. 3.pdf": "C0748903-8EEE-4111-A93E-B9273BCE243A",
    "Fig_1_ictal.pdf": "6DFDACFB-0737-40E9-92E9-C130F6BEBF69",
    "fig_2_interictal.pdf": "46A9DEA0-3AC5-4A49-B911-719F7AFC95CB",
    "Fig_2_read_reop_nonrout.pdf": "C0831AC7-1435-462B-A61C-C1DD1E85F6CD",
    "Fig_3.pdf": "246F5DD7-6144-416F-B353-AB29C2A03A8A",
    "Fig_3_maj_mincompl.pdf": "74CC455F-10E2-4130-9741-557A49470401",
    "Fig1.pdf": "461D5F8F-D23A-4A07-86ED-109B51A06FC8",
    "Fig1B EOR vGBM.pdf": "CE606C92-32CF-417A-9874-03B76A9AF7B6",
    "Fig2.pdf": "7EC6DF81-9957-4A4D-93B7-0ADA8D2F7874",
    "Fig2_H_oblique-01.pdf": "4F1A5B15-68B2-418C-B7B3-379254DC9D85",
    "Fig2A Percent Survival VSVZ versus non VSVZ.pdf": "98124338-CC2A-4068-91C0-7ADF42B019AE",
    "Fig2b Progression Free Survival VSVZ GBM.pdf": "04D984EF-D6F4-4784-8AE7-E81EB9089641",
    "FIG2B.pdf": "59210E70-FD1E-44D2-AE52-60B6310B1391",
    "fig3.pdf": "2AEB8991-E50A-4124-9A44-770717A7C540",
    "fig4.pdf": "8B67DED2-4D35-41F3-8DB1-465F49718FFB",
    "fig5.pdf": "BB430E5A-44A0-41B6-B612-3F5ED1410E54",
    "Figrue 2.pdf": "EA5B86C5-4816-4DE9-AE61-BE049572A4A7",
    "FIGURE 1  cg series SUBMIT.pdf": "0F8C3BD6-C9C2-45AF-B1A8-B36864468054",
    "Figure 1 (1) (1).pdf": "B8E3B9CF-BF87-4AB6-88F1-24802C63D56F",
    "Figure 1 (1).pdf": "06A5FD8B-A5CE-4B0C-8547-055CFCE8989D",
    "Figure 1 (2).pdf": "6BA53B07-12A7-428A-A53C-C3A474CC805B",
    "Figure 1 (consort)_CraniTrauma.pdf": "6AFBF6B2-4FB3-4FD7-AE19-D8583CEFD8E4",
    "Figure 1 (Final).pdf": "D73D6246-6211-4AF1-B14F-74852F087DF7",
    "Figure 1 - Blood Loss.pdf": "EA627B92-3A70-47C2-A086-C6574FC783FC",
    "Figure 1 - C5-6, 12 months, lateral neutral.pdf": "EE263FEA-F134-48B9-A15A-4B7A2EBE2ACA",
    "Figure 1 - Chiari .pdf": "E9142F0C-384A-4AAC-9669-2B6823310665",
    "Figure 1 - Dual Stent Retriever EVT .pdf": "90172A5A-47FC-48AC-A1D2-BA7336551437",
    "Figure 1 - Elevated CRP predicts positive culture.pdf": "6E8FF078-3D28-495D-BA76-1187C5CE1CDA",
    "Figure 1 - Final.pdf": "F70355C8-5B51-4BCF-A283-B21C577E8A84",
    "FIGURE 1 - forest - AA plot.pdf": "DA465A0D-4A87-42D6-9E62-583F75B8D656",
    "Figure 1 - KM plot resection versus biopsy.pdf": "9525A3F0-658A-48DE-8019-9461A6A778ED",
    "Figure 1 - patient flowchart.pdf": "5B3D1084-BE75-4C12-A35F-40ADCA478225",
    "Figure 1 - Pre op cervical spine x-ray with kyphotic deformity.pdf": "D8D73B1D-8309-421F-9EE3-ADFC1727CBFD",
    "Figure 1 - PRISMA flow diagram - BSAMVs.pdf": "8DDE6564-038E-4C78-B2F9-9CD9A5BF8B44",
    "Figure 1 - PRISMA Flowsheet.docx.pdf": "7CE31040-87F7-49DF-8F44-EE69A25D7FCC",
    "Figure 1 - PSAP Data.pdf": "DFEB0DF6-4BDD-4350-8A9B-CBAC9860D964",
    "Figure 1 - table.pdf": "B2BD8495-47FA-4DA3-A580-BD5806F5CB4B",
    "Figure 1 - Total Services Billed.pdf": "1563B000-32D0-49A8-8536-B177397EDB20",
    "Figure 1 .pdf": "05083ED7-E7A2-40E8-852E-ED65FD2FBA60",
    "Figure 1 A _ Pre-op Angio copy.pdf": "E06F2BA8-3883-4997-94DB-EE826BDE496F",
    "figure 1 and table 1.pdf": "1C16BC41-C817-43D9-8850-2CFEBFBECC03",
    "Figure 1 B _ Intra-op pic copy.pdf": "51383BD3-09ED-4D33-A4D8-109E9EF4C151",
    "Figure 1 BMP.pdf": "CAA5D3F0-7D0E-4C1C-8810-C349E7B8503E",
    "Figure 1 CNS 2020.pdf": "D4BE2439-A61C-4474-AE1E-EB0D4725CBF0",
    "Figure 1 CNS abstract.pdf": "2D3B128F-81FA-454A-9439-41CC62989768",
    "Figure 1 Comparison of FGATIR and T1.pdf": "F131EBFB-7F8C-4734-9D78-36D73CE73B32",
    "Figure 1 D _ post-op Angio copy.pdf": "88C1FE52-CA89-4331-B6B8-FE3AA10EA958",
    "Figure 1 Final.pdf": "620A8D2D-BF91-4702-972D-3D5A007B6A4C",
    "Figure 1 for CNS 2020.pdf": "2E9AFF96-50FB-4761-BC5A-F8BB8FDF053A",
    "Figure 1 insurance study.pdf": "8D982EC2-A0EE-426E-B4A6-18280FEA8C2C",
    "Figure 1 JS.pdf": "A1EF4B54-0F57-4D71-88CA-D62C0F06EA9B",
    "Figure 1 LC AMY parcellation composite.pdf": "A863F630-08E3-4E42-9E4F-8E955241381B",
    "Figure 1 Markov Model.pdf": "B4F95C89-671C-4DCB-8DA8-064154ECCEFD",
    "figure 1 multivariate-converted.pdf": "DCB13916-8DB2-42C4-BE6C-E0F5B5F860EB",
    "Figure 1 Pancoast tumor.pdf": "C1F4F047-166A-4368-AA16-A29887041E92",
    "Figure 1 Patient Selection Flowchart.pdf": "CB6B03CB-E699-4996-AA0D-739BD6D2DA16",
    "Figure 1 PCA.pdf": "F802DCB3-8676-43B6-8863-BA35E3413224",
    "Figure 1 pig abstract.pdf": "FE87FFC2-661E-40C9-A59B-48B87FB7187C",
    "Figure 1 Revised.pdf": "C1AA356D-6654-48DA-A4EA-49E9E0842790",
    "Figure 1 SAH.pdf": "C62C8B42-C192-4FA0-8ECE-F8464A713EF2",
    "Figure 1 Segmental lordosis.pdf": "8309E894-C8FE-4C44-B52B-53D216EE747F",
    "figure 1 submax angioplasty.pdf": "242AA140-8A7A-429B-BE1B-F8DC5288FB73",
    "Figure 1 T2 and FGATIR Volume Comparison.pdf": "F28C7A89-E2AD-4E4B-9902-23301337B053",
    "Figure 1 Thalamic Width Comparison .pdf": "E2B05DEC-31E9-42C0-864B-F81D9054890E",
    "FIgure 1 TSJ.pdf": "93FE0BBF-D388-446A-AB31-549549E4DB6B",
    "figure 1 vol_plot.pdf": "501A73CD-365A-4FF9-A94F-7E0A97A3A680",
    "Figure 1- Pre-operative CT showing fusion of Dens to T3.pdf": "82C34894-3E48-4A38-A80C-9AC285385013",
    "Figure 1- PRISMA.pdf": "5999A8EA-F238-4ABE-A506-FAC376361C1E",
    "Figure 1-ASD.pdf": "A66842D4-9D91-4587-8E9F-CEE5DCB17781",
    "Figure 1-Flowchart of systematic review and network meta-analysis following PRISMA guidelines.pdf": "C598D16A-5B30-424D-BEDC-2BBABC7E17BF",
    "Figure 1-Summary of Surgical Repair.pdf": "D3A36F32-96F3-4D5E-B2FF-D53D4547103B",
    "Figure 1. Covariate balance before and after matching (1).pdf": "70926641-481C-4662-9C28-4065E4F452FB",
    "Figure 1. Distribution of operative duration by cohort.png.pdf": "D07259BA-2778-49E4-864A-AE1C1F8CDBE5",
    "Figure 1. Flow Diagram.pdf": "519346C1-A6D1-4F67-B2B1-B51F28F46D36",
    "Figure 1. Patients with medically intractable epilepsy demonstrate similar inter-breath intervals when compared to the control group. .pdf": "92BC9197-B9E8-41D4-A82A-AF102454B5F2",
    "Figure 1. PRISMA.pdf": "CD5F5F51-C158-440F-A69E-6F4CE80F3DC8",
    "Figure 1.pdf": "232B8BC7-F218-4EBD-9542-029C0B9F8F15",
    "Figure 1_All-Cause[1].pdf": "ABDC84C2-2B19-4870-9ADF-A4D951C0DD92",
    "Figure 1_CNS abstract.pdf": "CA2676E4-1091-493E-9E17-8AE2F056C1EB",
    "Figure 1_CNSAbstract.pdf": "01B7823B-879F-48BA-BA04-16DF602A421E",
    "figure 1_gsw.pdf": "675D997E-4E6B-424F-A8B2-145D718379F2",
    "Figure 1_KM.pdf": "4D0CA6A2-CA5C-423F-A19F-3DBC0DB5FA2F",
    "Figure 1_MEA.pdf": "F9A68084-212A-45D2-B7D7-7A71C7439D75",
    "Figure 1_Spine.pdf": "15FC835A-BF14-493E-808C-FE6C64CC16DA",
    "Figure 1_SRC Flow.pdf": "A7000BCA-C650-4346-9801-85F482AEB314",
    "Figure 1_TCGA Data.pdf": "3D11086E-563C-4C51-AAF0-1F48B161F01A",
    "Figure 1A 1B.pdf": "34B2A5D5-0336-4693-9842-22D44A7BC373",
    "Figure 1A-C.pdf": "178F0391-01D9-4AD0-8AC0-0D9A8E8203B6",
    "Figure 1A.pdf": "EABB6690-19FD-4932-958C-0EB0852614CF",
    "Figure 1ABCD - Radiographic outcomes.pdf": "6033F584-24F5-47FF-9653-A000EA27C92A",
    "Figure 1B.pdf": "6F3AA9FA-0AAB-44BB-B4EF-21C214E0DC66",
    "Figure 1C.pdf": "51C09214-181C-4BED-A87C-40ECF5B8E9C1",
    "Figure 1cns.pdf": "C68AD80B-75F3-4F9F-AB8F-43079AD0EAB6",
    "Figure 2 (1).pdf": "63C2B2AE-6DD4-456D-9801-3BD251CD015A",
    "Figure 2 (Final) .pdf": "E46DF4EE-C99E-4A12-A1F9-E6227948F051",
    "Figure 2 - A two factor model predicts positive culture.pdf": "B333A514-A938-4729-9DD3-B7C50A0B7F4A",
    "Figure 2 - Blood Loss.pdf": "70C0B42F-0AF5-48C5-A746-724C9C14E4FF",
    "Figure 2 - C5-6, 12 months, lateral flexion.pdf": "07414F9C-8482-4060-B90B-FF01F1E4616D",
    "Figure 2 - Deformity correction with pre-operative traction.pdf": "0AF9918F-7F8B-4494-A98C-7398D27CC225",
    "Figure 2 - Dual Stent Retriever EVT.pdf": "2C0E7B3D-A6A4-4714-B093-D52950720B93",
    "Figure 2 - electrode coverage - v2.pdf": "75231069-860F-410E-99D4-5CAF48ED6C8C",
    "Figure 2 - emerson final 2 (1).pdf": "12A3B806-6696-4502-B22A-0FEDB1D53A40",
    "FIGURE 2 - forest - GBM plot.pdf": "CAFFDF27-E11E-433D-88C5-18243E612B80",
    "Figure 2 - Proportion of Services Billed.pdf": "5A2A6D4F-3229-405C-A023-ABCEDF69F3CF",
    "Figure 2 - PSAP Data 2.pdf": "560E90CD-96FB-403D-977C-6DEF2480B199",
    "Figure 2 - Revised.pdf": "145680C2-A2C2-4B95-B388-594990A0EE6F",
    "Figure 2 - status post C2-T4 decompression and fusion with vascularized scapular graft .pdf": "6C6C94F8-84E8-47BF-8E5B-0F2D59466EA2",
    "Figure 2 – Forest Plot for CNS ORR, 10 studies.pdf": "5209E79B-1719-43A4-9FD2-74A0FB6BF330",
    "Figure 2 A _ Pre-op Angio copy.pdf": "6E41150A-CD14-49DD-A0DB-E68035A6E266",
    "Figure 2 B _ Intra-op pic copy.pdf": "2BF9D0EA-7259-467C-9682-5ECCF8290730",
    "Figure 2 BMP.pdf": "C2BFA0D8-808B-4407-943A-3ABD8FA3D2BA",
    "Figure 2 cg series SUBMIT.pdf": "DE5B75F7-2991-4F53-920C-FB5B211D7E69",
    "Figure 2 CNS 2020.pdf": "49C36CC0-743D-40C9-BAD8-3CE731F8FF79",
    "Figure 2 Disc Height.pdf": "60E35D93-4482-4081-A61D-FF0D3E70764C",
    "Figure 2 for CNS 2020.pdf": "D55D05DB-A5DA-488F-924F-C93EC0CCEEAF",
    "Figure 2 JS .pdf": "68CE22EC-9BCC-4FCC-8EEB-F3C6B1F372D0",
    "Figure 2 PCA.pdf": "55E3891B-2458-4B90-8E44-400CB83B7DE2",
    "figure 2 plot_socal.pdf": "2D7C7369-13A9-4140-8EE3-3EFB89D6C020",
    "Figure 2 Revised.pdf": "1DD7D7C4-3503-4FCD-9E87-4D9A36BCB9B2",
    "figure 2 submax angioplasty v2.pdf": "0F94C032-7803-4280-A06E-3632D73D0DB4",
    "Figure 2- MRI showing spinal cord compression with myelomalacia from caniocervical junction anomalies.pdf": "558C8918-F67E-4C7B-9A3B-3C1AA153E3AC",
    "Figure 2-Network geometry of consistency model between surgical approaches.pdf": "FA9AA94F-9A7B-4B31-96CB-D761637CEF3B",
    "Figure 2.pdf": "DE5246A5-30FF-4089-9E97-054E2B4B9F5B",
    "Figure 2_ADCNSabstract.pdf": "1AA7EB7E-AB78-42A5-A0FA-D938B473DDE4",
    "Figure 2_ASD.pdf": "3B0133B9-CA29-4FFD-8D6D-8BBBC55B2E30",
    "Figure 2_CNS abstract.pdf": "67FD333C-C45F-454C-A8A6-02A2DED28E5D",
    "Figure 2_CNSAbstract.pdf": "C653F6B0-2BBF-44A2-9A66-2CE9F9566F2E",
    "Figure 2_CraniTrauma.pdf": "69D6DD79-0E83-4EA9-B77F-49DD910A3829",
    "figure 2_gsw.pdf": "E876EF04-4D80-4608-9931-B51AE1F83150",
    "Figure 2_HistogramRDW.pdf": "38BF2AB0-7745-4222-A536-E3E66EC6FC09",
    "Figure 2_LOPES Characteristics of Included Studies_CNS.pdf": "65C67442-E8CB-402B-9451-77E91E193DE7",
    "Figure 2_Survival Curves.pdf": "B97444CC-FD95-4C21-A81D-6E6A8786647A",
    "Figure 2_sz lead LDHA replicates_final.pdf": "649C14AF-21C8-4E50-BB9F-4F906711509D",
    "Figure 2a Pancoast tumor.pdf": "157B2470-ED51-4507-A8FB-A55E641D62FD",
    "Figure 2A-B.pdf": "4A9D6234-8BD6-409D-96A2-36B7F18F2385",
    "Figure 2b Pancoast tumor.pdf": "C4DB46A6-D766-4042-98B2-072EA13270E6",
    "Figure 2B.pdf": "1B2ED615-4F53-4E6A-9614-7807AFE4C096",
    "Figure 2cns.pdf": "26E02A7E-4186-4BD6-8CA5-5CC88F8C6923",
    "Figure 3 (1).pdf": "59070222-FF14-48D5-A038-DF332CBFCE23",
    "Figure 3 - area plot.pdf": "A60E0C8D-1824-4A0C-B993-B56BFBA5CF69",
    "Figure 3 - Average Submitted Charge.pdf": "5D729E57-1008-4E00-81DF-D7D94D52273F",
    "Figure 3 - C5-6, 12 months, lateral extension.pdf": "C2094054-4333-45B8-99BF-BEB01542EF6E",
    "Figure 3 - Calbindin GFAP.pdf": "A7E3A3CE-7BA7-4214-80C3-1B3ECCC01002",
    "Figure 3 - Correction of deformity with Pedicle Subtraction Osteotomy.pdf": "E873521B-6814-4616-A03B-9034E10F860C",
    "FIGURE 3 - genealogy benefit.pdf": "EE539D4D-8149-400C-99A8-C5367EA3297C",
    "Figure 3 - Harvest and Rotation of Scapular graft.pdf": "5960DA90-50B4-4F36-B9E0-D6AEDD99F9C8",
    "Figure 3 - qPCR data (1).pdf": "656162C2-53A8-4EF7-9D27-2ABF7508B974",
    "Figure 3 – Forest Plot for CNS DCR, 9 studies.pdf": "82B75C82-B02C-4F5D-BC41-3D6B8132BA38",
    "Figure 3 – Status post sub-occipital craniectomy, C1 laminectomy and Occiput to T4 posterior fixation and fusion.pdf": "0213E91B-8A6F-421D-A661-3F46E610A6A2",
    "Figure 3 cg series SUBMIT.pdf": "7367F6E9-748E-4F32-B290-99AFCD3E2BEA",
    "Figure 3 CNS 2020.pdf": "B4EB7A63-69AA-4D5E-9426-EB638C284245",
    "Figure 3 combined.pdf": "C7B5715F-FB79-436A-B0F2-A71E23E4E294",
    "Figure 3 Final.pdf": "DEFA26ED-F7D9-4B4F-B529-EE486909DE69",
    "Figure 3 for CNS 2020.pdf": "AD857BA1-D43E-465C-806D-CAAC5584F8B7",
    "Figure 3 for CNS.pdf": "794CA71A-F55A-4FB8-B8AB-B4FB1598FAA5",
    "Figure 3 Lumbar Lordosis.pdf": "9D86E85B-3104-4951-99A8-B5338A479F0B",
    "figure 3 pay_plot.pdf": "0922A318-BF02-4F44-ADF6-A1089F2D9170",
    "Figure 3 PCA.pdf": "9E904732-CBD7-463F-84D4-A7DF167F9D28",
    "Figure 3 Revised.pdf": "593ED0BF-65D9-41B7-BA7F-98759D708A8A",
    "Figure 3-Forest plots of head-to-head, pooled within, and pooled overall designs.pdf": "E85A95AB-1D06-4E53-B74A-8CFA1617CDE9",
    "Figure 3.pdf": "7F207D0E-66AB-49EB-B3F1-06CFEDAC4EE8",
    "Figure 3_CraniTrauma.pdf": "27B9D7CB-B91E-421A-8FAC-C62BED8A0349",
    "figure 3_gsw.pdf": "5E7D5905-FAC5-4A76-BE11-128B66E71A54",
    "Figure 3_Lymphoid Flow.pdf": "6E946B67-AB7C-4B45-9558-09EDAF397EF6",
    "Figure 3_Mechanism.pdf": "B3605CB8-63E7-4B28-AE76-E09C5E555B2E",
    "Figure 3_Spaghetti.pdf": "FD8C9B22-EC9C-4658-872E-A3476E81F957",
    "Figure 3_telemedicine.pdf": "2CA1F869-D058-4C8B-BE40-3221E1197CEB",
    "Figure 3A-B.pdf": "06C6551C-E1B1-4FE6-B675-26DC6D3453D4",
    "Figure 3B.pdf": "3FC66A68-FEE4-4809-9639-7DAEC3EACFB7",
    "Figure 3cns.pdf": "54DDA822-36BB-43BB-84AA-96E0E5B88324",
    "Figure 4 (1).pdf": "F99BD22E-4B11-4EBE-8B9B-7F663482C4AB",
    "Figure 4 - Average Reimbursement.pdf": "F3EC1184-7AEA-4784-BC7D-797F219A093B",
    "Figure 4 - CT showing adequate decompression and bone graft placement at the cranio.pdf": "F2BDF15F-39C0-4DB2-8DBE-59DE58746CDA",
    "FIGURE 4 - forest - grouped non-seer.pdf": "C772EC3E-47CF-44AF-9D69-E86E76748FE4",
    "Figure 4 - Vascularized scapular graft harvested and rotated.pdf": "6E7D0A74-B19A-469A-82AB-725041631260",
    "Figure 4 CNS 2020.pdf": "D1C2189E-C4AE-4B9A-A3D0-243B7809907A",
    "Figure 4 Revised.pdf": "D7EC4182-FFC0-44AE-834F-9F0FA06C5CF5",
    "Figure 4 VAS Back.pdf": "60B8A1D3-A5EC-4F88-8F65-ABD71387A22E",
    "Figure 4-01.pdf": "C91E2FFC-E9C9-4B52-AD98-A5E83D170D5E",
    "Figure 4. Increased resting respiratory rate variability in patients with medically intractable epilepsy..pdf": "60B42AB8-42A8-4F1A-9FE0-9645EF7C4952",
    "Figure 4.pdf": "38B231C7-D4A2-4815-BC7E-09285CE8F6B1",
    "Figure 4_CraniTrauma.pdf": "2B076324-7AA8-49DD-90EA-D77DD5072B12",
    "figure 4_gsw.pdf": "FFC08A48-A2B7-4907-85DA-80EA4A7AB93B",
    "Figure 4_Myeloid Flow.pdf": "B0B27BCF-CDA7-4D62-AC81-E20A58B39A61",
    "Figure 4_Reverse_Spaghetti.pdf": "E18371C6-BE6C-40AE-967A-195798A4574C",
    "Figure 5 20200513.pdf": "BEDC9A42-8D09-48AD-8939-8887C02273B8",
    "Figure 5 Revised.pdf": "7A196BA3-D88E-43D9-93FB-969B49251F06",
    "Figure 5.pdf": "66F233BA-36F2-4A06-BCD0-03464923F2E5",
    "Figure 6.pdf": "FD893021-DA76-4939-84C4-FF6FD92588C2",
    "Figure 7.pdf": "37C60C6B-5F82-4FFB-80B6-18538998D224",
    "Figure A.pdf": "4E8DB02D-CBA0-4C55-AB29-174AED3A50BB",
    "Figure B.pdf": "854AD7EA-5496-4C0D-B7C4-8978901ACB37",
    "Figure C.pdf": "79D64C28-7C7B-4380-AB61-21E82CE0E92E",
    "Figure D.pdf": "59AF5482-D08B-4BEB-B433-932415444618",
    "Figure E.pdf": "46015DE2-297C-46DE-A956-6071489AE87D",
    "Figure for CNS.pdf": "F36BFE90-619A-4094-8AC7-28A5D40C49EE",
    "Figure I - Observed Survival in Spinal Astrocytoma.pdf": "4384E6EA-3B5A-4D7B-B1EE-780EA50EAD79",
    "Figure II OP+CPM.pdf": "2EC6A3EB-9B44-4965-93FD-F93B9D3E6108",
    "Figure radiologic and pathologic findings.pdf": "CE3EE6E6-6E6D-474D-ACE2-7A5B2D70A6A3",
    "Figure Spine Summit 2020.pdf": "2BEB7BC3-9958-4AA9-8C50-26640D92FEEA",
    "Figure.pdf": "2C921254-866F-4685-8D5E-24320AE6378D",
    "Figure.Table.JZM.KM_ABA_MMSB_KM_RAR_KM_JM.pdf": "9596A640-08F7-4B8C-AF72-190D5B2847E4",
    "Figure_1.pdf": "6B1CE4F5-F14B-4AFE-80FC-3787F87B8B5D",
    "Figure_2.pdf": "3621C65B-53B4-41B9-B1E4-A4B89B9B5872",
    "Figure_3.pdf": "F42D138C-323E-494F-86B0-48F0AA35AC59",
    "Figure_4.pdf": "0FC1130A-A734-49B4-8A03-53CA4C5E03AC",
    "Figure_5.pdf": "E5A9DE7D-5473-424D-9AE0-1D5EBF537AF9",
    "Figure1 (1).pdf": "BA0C4B1F-7491-4584-87AF-2CF56D7412B6",
    "Figure1-D.pdf": "01450EC6-F78A-4E73-9DA7-844DFCDBB95F",
    "Figure1.legend.pdf": "F61105B7-D212-49E0-B5E9-B8F78D666531",
    "Figure1.pdf": "70935C7E-D048-407B-ACFF-24941F6520B1",
    "Figure1_ADCNSabstract.pdf": "9C88CEEB-23DA-47CC-A3C9-B79B23BA615F",
    "Figure1_bar.pdf": "1D208323-B6C2-4E45-A59C-E489DD9D5BFD",
    "Figure1_CoccyAbstract.pdf": "7F3B577D-B102-489D-9AD7-93CE496CD317",
    "Figure1_DCLN_CNS.pdf": "31BDA0B5-C016-4040-B577-4296068C39BC",
    "Figure1_Flow.pdf": "144240F4-B8A9-4A35-BF60-BB107D32C307",
    "Figure1_RFA.pdf": "31377025-4E53-4DE5-8908-34B6A4458E3C",
    "Figure1_SEERquery.pdf": "FF039A30-F85E-4A5F-8C34-A66296A17D27",
    "Figure1B.pdf": "9E59A779-AB1A-46E3-B32E-861001378923",
    "Figure1CNSabstract60921.pdf": "CB6EB92E-6692-4C11-A53B-A6C1411F4E59",
    "Figure2 (1).pdf": "D2567BFB-5074-4214-BE63-7B4664C16827",
    "Figure2.pdf": "AA2E23B8-10C0-4508-A3F1-02E67D0F4178",
    "Figure2_CoccyAbstract.pdf": "F5623FEB-CF22-46EC-A5E3-96D517B99934",
    "Figure2_DCLN_CNS.pdf": "C18D5D71-60DA-45B8-9175-02A07CA57AC4",
    "Figure2CNSabstract60921.pdf": "747890EA-22DA-4A86-99B9-8FEC513B7502",
    "Figure3 (1).pdf": "6BCCE653-4306-4E53-BA5F-D90B38FD606F",
    "Figure3.pdf": "3693FE85-17BB-4576-AA22-03F47F27FF48",
    "Figure3_DCLN_CNS.pdf": "651E6F4B-65CE-4F31-810D-998DB1865961",
    "Figure3CNSabstract60921.pdf": "FC2816B6-C6AA-41FB-B161-66FA254BD063",
    "Figure4 (1).pdf": "DA9C16EC-4131-4B96-9B88-723750B0BE6F",
    "Figure4.pdf": "F6036955-5A76-46AD-8D85-3599F0BFD4F5",
    "Figure4CNSabstract60921.pdf": "28D06DF2-6714-4AAD-9B26-F5E240920BD6",
    "Figure5.pdf": "BAC603D4-513A-437A-93A4-1FC5F0F84284",
    "Figure5a.pdf": "97E5452F-94B3-4B36-84F6-D9344CA9C13E",
    "Figure5CNSabstract60921 (2) (2).pdf": "F5C28C76-0090-464A-869D-91BFCFED72A2",
    "Figure6.pdf": "0701199A-EAF3-4758-B113-CAA6BDFB75F2",
    "Figures (1) (2).pdf": "AF0D052B-9538-4A24-A6E9-82B5D2014555",
    "FIGURES 02.05 2019 sadece figüre.pdf": "50B505C4-5428-4AC8-ADDC-A0A9FBB991B3",
    "Figures 1. Octogenarians _ Lumbopelvic Fixation.pdf": "DD319AD3-5D66-42A0-A106-DEDA1BE07799",
    "Figures 5-6-20.pdf": "9B9D86D6-B41B-4C1B-A14F-65B125D60659",
    "figures abstract aans.pdf": "C12B9C7F-2F57-4B68-AD4D-1B5023E703AE",
    "Figures Abstract TCT Mapping_To upload.pdf": "4F11D282-8C19-466C-A552-BE34962D9E07",
    "Figures and Tables Final.pdf": "136A2FA2-2F17-471F-80A6-4CE28FC0B539",
    "Figures and tables.pdf": "7E290C08-E2C6-4BD5-BE56-F110894A8650",
    "Figures CNS.pdf": "D8B24FEA-16BC-48E7-9253-C35A0C474515",
    "Figures for CNS MIP Abstract.pdf": "03280BAB-FF89-4218-AF6B-7207F97FF52A",
    "Figures for CNS WHO grade III abstract.pdf": "369A60E1-CD69-42D6-B725-2005868909A3",
    "Figures.pdf": "3609A11A-1E6D-4669-957D-0A7F4A4E58CC",
    "Figures_CNS innovation.pdf": "7EE1A224-0809-4AED-A14D-3760257104D3",
    "figures_DBSreadmissions6april2020pdf.pdf": "55F4D1F5-9BCF-44A3-B82C-368D64479C5E",
    "figuresofmanuscript.pdf": "03FDAB51-739B-4BB7-97F6-2A2195625A10",
    "File - 5 Best Papers.pdf": "2EB57914-11B6-4DBE-9C05-E217FF717EE0",
    "file.pdf": "16A2BD2B-81C1-4705-AD82-1E139670602A",
    "FILE_6456.pdf": "132AE86F-7A26-43A7-8A01-FCAA626867C6",
    "Filippidis CNS 2023 Sept poster - Octogenarian Meningioma 2.pdf": "C634BCF4-A503-4CE5-9349-7F6E426D1A7D",
    "FINAL - Letter to Congress to Act on Telehealth Earlier This Year - 22 Feb 2024.pdf": "C63641AB-043E-4953-9CE8-487C71F0DA2F",
    "FINAL - Telehealth Expansion Act Stakeholder Support Letter - 6.6.23.pdf": "C5C9A62E-3EEA-4102-B40C-9D81DDC52FCD",
    "Final 2021 Career Guide Agenda 1.23.pdf": "0FC0A451-B920-4315-9172-E9CAD52725A3",
    "Final 2022_AM_PosterTemplate.v2.pdf": "1A82FA13-AEB6-486A-9D9B-84E2ECBC45C5",
    "FINAL 2023 Vetting Protocol.pdf": "B1FFACB5-1FC5-42E4-916C-E85E885F6EC8",
    "FINAL AAcNS Newsletter July 2023.pdf": "8F50E54E-E5E8-4802-9ED7-820B13F24EBA",
    "FINAL AAcNS Newsletter March 2024.pdf": "06404226-1DE6-45DD-87F9-FE392E6011C5",
    "FINAL Author Agreement_Form.pdf": "9F2380CB-3E45-4796-9405-41123E3FC0A2",
    "Final Brochure.pdf": "AD2D4D0C-EDAD-462B-A75E-98691E8323DA",
    "Final CDC GVP Research Funding Letter Senate_2019.pdf": "61840EFF-93F7-4B11-9AE3-4F07939A49EA",
    "Final CNS Poster.pdf": "D8491618-D0E4-4115-B516-3DA452C9C1F2",
    "Final CNS Vertebral Artery Figure 04092020.pdf": "BF3EB8C3-8AA3-490B-9D02-03F3DBDFEDEF",
    "FINAL Collins PFS Letter to Leadership.pdf": "680D3BEA-E140-4C85-B8C0-41003B37C5A8",
    "final CV CNS.pdf": "09DDAC3C-E709-4C86-A3E4-4CB6A0C02D2A",
    "Final CV4 Federation Sign On Letter.pdf": "456109F7-7252-48C8-B450-BD3D8B4246FC",
    "Final Federation Letter on Extending Medicare Sequester.pdf": "9068E89B-34C0-4523-8246-35F54060064B",
    "Final Figures Academic Productivity .pdf": "AC33D7B8-61EB-4023-A7BC-FF711CCA9C03",
    "FINAL FROM PRINTER_ScientificProgram_no_crops_091819.pdf": "9235D5D7-CCE9-4835-B501-EFADA03C3499",
    "FINAL FROM PRINTER_ScientificProgram_no_crops_10_18_2019.pdf": "078EE2AB-784E-4E16-BC8C-594158959B1E",
    "FINAL FROM PRINTER_ScientificProgram_no_crops_10_18_2019_2.pdf": "A84E067C-1C36-4C59-AA7C-637CC970F12B",
    "FINAL FROM PRINTER_ScientificProgram_no_crops_101819.pdf": "48BA026D-33E9-46A5-B353-798CE22E02BE",
    "FINAL History of Pediatric Neurosurgery in the Military (CNS Presentation).pptx.pdf": "3C38A646-DBFF-450D-B499-619493824F92",
    "final labeled.pdf": "46BF81B8-DA4C-428A-9B28-877DD14DFF95",
    "FINAL letter-RRC to E C O I D1008008.pdf": "219E3949-9EFB-44EE-9400-3267F88CBF8D",
    "FINAL nashia_organ_sign_on_lttr_cdc_national_surveillance2020_.pdf": "FF4CCC2B-2BDC-42C0-B253-F597555BB40E",
    "Final Poster TVNS.pdf": "5BA6B466-4462-4254-900D-097F3C482B1A",
    "final poster.pdf": "4DCBB950-93FE-498B-995F-EE0D061EA10B",
    "Final Preemie Poster Submission.pdf": "5B9403B9-B08A-4F7F-9E13-77E16A32D6F8",
    "Final Program_Online.pdf": "F7B33746-291B-45B3-9834-30E98D6BBF1E",
    "final residency certificate-completed training-Mantovani 1.pdf": "ADA89D54-88C0-4349-AF67-EDDE4735E079",
    "Final RRC Comments on 2025 MA Proposed Rule D1095942.PDF": "5F3CC1FD-C4BB-4951-BE85-CB70394B9D01",
    "Final RRC Comments on Medicare Advantage RFI 8.31.2022 D1018478.PDF": "BE68DC72-17BA-4E64-878E-F810BD117311",
    "FINAL RRC LETTER TO WAYS AND MEANS D1069008.pdf": "3931E2E3-C70D-42CC-A001-8388CEDB6C4B",
    "FINAL RRC PRESS RELEASE ON E-PA RULE.pdf": "62FA53B7-5A9B-4F65-88A0-069EFC3814D1",
    "Final Sign-on letter to CMS re AUC Report.pdf": "51E589BD-3941-4CF9-BC4E-5FB2C28BB433",
    "Final Sign-on re 10-3 Executive Order.pdf": "08E20ADB-D5E7-4EAB-A0FE-3B0BE1591DCC",
    "FINAL SIGNED Letter to Leadership on Medicare Physician Payments.pdf": "D8426507-BA77-42F6-B1F0-A969E28F0703",
    "Final Signed Paul MACRA Letter 9.30.16.pdf": "97CD1C8E-7DBC-461A-B96F-B08FEC658C19",
    "Final Strategic Plan Milestones and assignments - November 2023.pdf": "57A670A0-3427-43BC-B9CB-E8FEF86BEA44",
    "FINAL Surgical Coalition HHS COVID Letter Regulatory.pdf": "ADFA75A5-00BD-4CAE-9079-5AADFF3A4AF5",
    "Final TABLE 2.pdf": "A673D61E-2E62-4E9B-9FCB-B9A1FE309613",
    "Final Tables Academic Productivity.pdf": "E707F296-12BD-4A0F-A6CD-4A21B0F772AE",
    "FINAL Trauma Coalition FY23 Senate letter.pdf": "C91E9B86-B4C4-4E1B-AEA6-5F99995EEB82",
    "Final Wild-Miller-Meeks Medicare letter.pdf": "3498CCF9-CFDC-4837-BDBE-B21E2FB8180E",
    "FINAL WJOakes LON SNS CJRozzelle 2021.pdf": "A625554B-DC6C-4735-BA8F-AC313BA3A5EE",
    "final_-_registry_-_aci_credit_sign_on_letter_6-15-17.pdf": "41CC28C8-77C7-48DD-BEA2-9F43B86380EC",
    "FINAL_ASSFN_costs_poster_BAT_4_20_20.pdf": "074D094D-78CB-4E64-BAF2-CE76B9B7F224",
    "final_cdc_gvp_research_funding_letter_house_2019.pdf": "9290FC56-F085-4419-8397-35D7F9F79B15",
    "final_cdc_gvp_research_funding_letter_senate_2019.pdf": "055575A8-A293-4E58-A4F3-3B5745728ACF",
    "FINAL_CNS AM Registration Form_for_on-site_10_7_19.pdf": "898E0D38-0572-4098-A116-6E3B1C18183C",
    "final_coalition_medicare_part_c_reporting_requirements_comments._d07789._002.pdf": "6D44E390-626F-4545-A2B4-EA64D0D79860",
    "FINAL_CV.pdf": "5B309FB2-FF23-4F5A-B50B-6DF9A5C960CC",
    "FINAL_Guidelines NDA_for task force members.pdf": "831612B5-B675-4AB3-855A-76093671E8BA",
    "FINAL_LOR.pdf": "8D22A95C-405E-4274-8E0B-E8990AF47D87",
    "Final_Predictors.pdf": "CC2E5BCD-7083-47FD-B58D-D3B52FE62700",
    "final_prior_auth_sign-on_letter.pdf": "939982BB-1D1C-4D0A-992B-5768A0B74FB6",
    "final_sign_on_letter_opposing_transition_of_10_and_90_day_global_codes.pdf": "EB6679DB-02D1-4F30-A9B6-78A5AFCA99FF",
    "final_sign_on_resident_physician_shortage_reduction_act_gme_coalition.pdf": "22071DC3-CE72-4F7C-BE62-22B4B9980647",
    "final_ssnri_sign-on_letter_may_2017.pdf": "377B75FE-95B2-4D2F-83A7-9D97865110DC",
    "final_stakeholder_sign_on_hr_3107_090919_2.pdf": "0F8666D4-9844-47E3-BB0E-6A410D35437C",
    "final_summary_of_2015_-2017_modifications.pdf": "0063C1FC-10A9-4D1F-9041-4216743B7BE3",
    "final_surgical_coalition_hr3309_support_letter.pdf": "42F82D37-3526-4900-8C45-23B6895C6B03",
    "FINAL_time_support.pdf": "8A8B49E6-CFB2-4101-86C7-A8B48651826A",
    "final_version_of_the_sgr_sign_on_letter.pdf": "A1D3DB37-B9C5-406E-B0AE-C49DD6630533",
    "FINAL_W Disc_2023CNSTumorComplicationsAgendaCGH_Draft 3_1.20.23.doc- DLC EDITS.pdf": "5815EE29-C227-4D95-841A-1166947D57C3",
    "FINALANNUALREPORTTOSNSAPRIL92019.pdf": "94489263-1409-4B55-A62D-EF01429153B1",
    "FinalDraft.HRPC.Poster.PedsSectionAANS.pdf": "05E4DB32-389F-46AD-A7A4-3640700A5593",
    "finalltrcmsoncdr9262014.pdf": "04A502B8-6757-412E-8F40-9EB39F51390E",
    "FingerprintingPosterPDF.pdf": "34A956C2-F03E-493D-9FBB-2FC00DC94BCF",
    "First 2022 RUNN - Program Director Approval - Lopez- Alexander.pdf": "1C35F43A-B46E-4693-9E96-D0556735F257",
    "First NSI Membership.pdf": "68DE82C1-51B6-4A5D-BF62-689B35F3167D",
    "Fis Loperena Marielle.pdf": "AA11CE34-1F60-4D6A-8337-F40527972E22",
    "Fischer, David Luke Letter of  Good Academic Standing 7.13.15gb.pdf": "3C87E330-24F8-4F49-BC8A-65966867AEDD",
    "Fischer, Victoria_Letter of Good Standing.pdf": "5D49B5AE-0A03-43E5-8F2B-5333273391B3",
    "Fisher, Jonathan Letter of Good Standing.pdf": "0F4625F9-D807-4BA5-A230-9331B9FB7476",
    "Fisher2021_Article_LetSTalkAboutWhatJustHappenedA.pdf": "27CC17EA-98D8-423E-AAAE-0976E3DCD06E",
    "Fitchett, Evan_LOGS.pdf": "5423AC1B-7840-449B-A855-56FFF80D95AA",
    "Fitzwater letter.pdf": "4E588C40-C77A-47AE-8D09-C5D01FE3D4FD",
    "FLam.UofA Neurosurgery Cert.pdf": "DC4099F3-CD09-4561-A68D-064CE0676E12",
    "Flamm, Eugene.pdf": "2E32FA9F-2D1E-4F55-8AFC-4BC72D105030",
    "Fleeting Chance - letter of good standing.pdf": "5C90ED1F-9AF1-4288-B917-7BF71D4935C5",
    "Fleury Volcy - Enrollment verification MD May 2022.pdf": "6CAF4994-00F7-45A1-9CE6-332F88491949",
    "FLIm 1.pdf": "BA7A3BB4-5FEE-410C-9C10-959B9CF441E2",
    "Flim Setup.pdf": "568C134C-4C1B-4015-ADA2-ECB83A3B70C3",
    "FLNSUS CNS 2022 Poster.pdf": "B1D78999-D35F-4D64-B15A-F5512AB69D3D",
    "Flow chart methods.pdf": "D918FAB2-2998-45A5-9ACD-3512D4A3B106",
    "Flow Diversion Antiplatelet - CNS 2020 Award Consideration.pdf": "20880CF3-9229-42E1-A670-262A8371B672",
    "Flowchart.pdf": "C5FD10B6-7E33-477E-B24F-6E60B37ADAF1",
    "Flowsheet - Figure 1.pdf": "A39F1B1A-E51A-4485-B82E-65934C3C0BA2",
    "Flyer  Find out more about UofL Neurosurgery  8.11.21.pdf": "D28EEA3B-5021-40EC-AD2A-2C7315A4CF75",
    "FNassiri-emailverification.pdf": "C8CB3F2D-0116-4603-9B0E-D2531C6C28FB",
    "Folding_manuscript_CNS.pdf": "519B930C-9060-4CE1-A0BC-EA29A157A6E8",
    "Follow Up Care.pdf": "00133536-B621-4886-86D6-64DC57E37DCB",
    "folrida neurosurgical membership.pdf": "7F399800-83C3-4D8C-BBCA-E3A51DE7C30D",
    "FOM_Event2020_Register Here_CC.pdf": "837DE76A-F8BB-42D8-88AE-F86A6B60A726",
    "Fon_MembershipCertificate.pdf": "6A6362EB-35BE-4E19-90CB-6AF50EC51551",
    "Fontaine Briana Dominique - Letter of Good Standing.pdf": "E15835F5-ABB7-4789-A88E-60C8A521FD1B",
    "Fontenot Cameron Letter of Good Standing56.pdf": "006C4DB8-B606-4C1B-A639-D270F6ACB590",
    "Fontes_Krishna Joshi Recommendation Letter.pdf": "5007C849-C4C0-4C0E-B8E3-89C410E3C767",
    "fopr_house_letter_final.pdf": "B0C29D16-29DC-48E2-9C41-457BD465032A",
    "fopr_senate_letter_final.pdf": "911E5456-3534-4DB2-BDE3-68CBC043976E",
    "for CNS.pdf": "C0B0F749-65C0-4B14-97FE-4FDCE07E6771",
    "for Geoff Colby.SNS.pdf": "08F7082D-A8F1-4D8C-8398-C3621DE33E76",
    "For Jasmine.pdf": "EEB67D8C-F11A-4375-9210-80885E62D743",
    "For Vendors_52.204-24 Representation Regarding Certain Telecommunications and Video Surveillance Services or Equipment.pdf": "2313522A-9742-465E-B178-5DA429564279",
    "Foresi Brian LGS 7-13-23.pdf": "4B18245C-BF99-45E3-B5EE-3F6D6CCFEE6B",
    "Forest  Plot 2.pdf": "213F9444-B870-4B4C-96E4-C0EB07E1E445",
    "Forest Plot – CNS DCR – RCTs.pdf": "A87C1DF6-8EAA-49E1-9898-1F0739B7EB35",
    "Forest Plot – CNS ORR – RCTs.pdf": "D1A2860C-2EA4-45C2-B6DB-99C80AE16F19",
    "Forest Plot 1.pdf": "8A42CDBC-7509-4353-AAB8-94F5A7CBD873",
    "Forest plot.pdf": "98182E69-90A8-48FD-8751-F68E1A61C740",
    "Fossett CV.pdf": "689C5BF9-36A2-4A2E-AFA2-9E42EDB1F698",
    "Fote Rec 2022.pdf": "69E87D9D-3D8F-4CCB-9562-B70B6895BB6C",
    "foto interviste.pdf": "A34D1F32-F568-4899-BDD9-C1EB7D1D4362",
    "Foucalt Historia e Anarquismo - Margareth Rago.pdf": "18995151-6BAB-435D-B6BD-227941AD8E26",
    "foundation_donation_pdf_form_05_23_19 (7).pdf": "8A81A5E4-B51D-4568-B27A-68A606793347",
    "foundation_donation_pdf_form_05_23_19.pdf": "B7F7C221-AE58-49C2-9346-218FF7897D31",
    "Fox Cambri.pdf": "9A7D6C50-DA19-4540-8FB9-9E7B82B2C424",
    "FOXM1-3 overexpression correlates to staging of astrocytic glioma.pdf": "F0A88D22-1051-4626-9964-9F6E15F3ECA3",
    "FRACS 2004.pdf": "D6EEC126-A472-4851-A323-936397F01298",
    "FRACS.pdf": "FAD153C6-8988-4E66-A86C-EC50701A5362",
    "frailty SCI poster_CNS 2023.pdf": "A99558B3-27BF-4958-B224-2C11576EE57F",
    "Frailty Spine - Spine Section Manuscript.pdf": "E544878C-71D7-4BDA-8848-14D58DC52AA8",
    "Frances Rodriguez Lara Cert of Registration.pdf": "9B13E1F8-ECA9-4332-8F48-2D53622C8C67",
    "Francisco Alfonso Rodriguez Elvir - CV. Englishdocx.pdf": "C73F8FE1-A11C-4041-B9C8-D709CAB6ACE8",
    "Francisco Ponce Nomination Letter.pdf": "1C1AD221-E6D4-49C9-BCD4-2B1F724F7791",
    "Francisco Ponce Recommendation Letter.pdf": "7BA04EA0-C2F5-4398-AA84-0978856529C9",
    "Francisco Ponce Sponsor Letter.pdf": "F1E7F5DE-69DD-4BFB-AD8B-76D07FEDA2DB",
    "francisjohn_cns_geritbi.pdf": "7C5D6257-7A19-4C8D-B87B-385C7030A789",
    "francisjohn_cns_rct.pdf": "93B8D8E5-13BF-48E0-B770-9D5DC0CBBB2E",
    "Francois Hendrick - LOGs  Enrollment Verification .pdf": "70574B20-416E-4D7D-BD4D-071231C626CD",
    "Francois Mathieu-RESVerif.pdf": "D7D853D9-3A18-4242-A4DE-DAE5A9F4B609",
    "Frank Yuk-FellowVerif.pdf": "EDD6A938-3410-4CD9-8C15-91D7EE72C389",
    "Franklin Deveney Letter of Good Standing.pdf": "CFCAA905-E02D-4E90-89A1-979579ED70A2",
    "FrankWalchCV072922.pdf": "B1FA14FA-87B6-4042-A20C-2A919BB7F755",
    "Frantz Rom Poulsen.pdf": "05505445-F157-4DBB-87D9-6C0F1AFDE8C7",
    "frcs cert.pdf": "15C3E339-8DEC-48D0-8CA2-66866F259EF1",
    "FRCS Neurosurgery England UK.pdf": "28ABFC54-7EBC-4215-A4C4-AD476120956C",
    "FRCSC1.pdf": "2C3B0008-F676-407F-B7C8-878EEB615FCA",
    "FRCSNeuroSurg 1.pdf": "D00B3F50-7740-43E1-BAE4-FF984439686F",
    "FRCSNeuroSurg.pdf": "4FD1C817-DD8F-4D7D-81C0-85AC0957F77D",
    "FRCSSN2.pdf": "A0F63AF3-BF6C-4A7D-BFB7-FA8A7E023401",
    "Fred Lam_CV_2022_CNS.pdf": "8F650416-68F2-4C02-B90F-CDE4210BB44E",
    "FRED vs PED.pdf": "09D2B1E7-D347-478E-8F40-61ACED8C93B5",
    "FRED.pdf": "C72BF7E4-667F-4CB3-84A0-89B5C76A9D3A",
    "Frederick.Hitti.CV.pdf": "76CCA643-2ADB-4C8E-A973-F347C1521ED8",
    "French, Gene.PDF": "5172917A-DB47-4199-AC88-88D7EF400CB1",
    "Frequency Distribution.pdf": "217B2782-ECDA-490C-B877-5F6BBEB7270F",
    "Frere Hospital HOD letter.pdf": "2087B9EC-315E-407C-8E3F-057227675C46",
    "FRERICH CV NOV 2022.pdf": "E6F449EF-2BB8-405F-825B-0BF353B7C870",
    "FrerichJM Letter.pdf": "1548ADF3-56E7-422B-AC3B-83073DA568E8",
    "Fresh ADSC and BMSC CNS2019.pdf": "642ED9CC-42C3-4B63-8710-7923A06627AF",
    "Frid Letter.pdf": "A0E7F457-715E-4D33-A8C2-5037883E16DE",
    "Fridley Letter.pdf": "43AC9847-038A-408B-ABC7-634CA8B9F107",
    "Fridley Slides.pdf": "3AB40A4E-64AB-402B-9771-510567C2A1C2",
    "Friedlander, Robert.pdf": "1255A441-296B-414D-911E-4123C48F0AC8",
    "Friedman Adam CMSRU enrollment verification good standing letter 4-28-23.pdf": "112F4450-B2D8-4943-9E4E-82C972173E61",
    "Frisch Digital Poster CNS.pdf": "B2EA3CB3-7897-465B-BB59-79ECE175C275",
    "FSA Enrollment Form.pdf": "67E9CEC5-CFDB-4BE1-8B6B-9D85E864DE2C",
    "FSM_GoodStandingVerification_GovindSachin_09072023.pdf": "47275C48-0B38-42E9-B700-350A9B25FF74",
    "FSMB FCVS profile.pdf": "C08285C8-3A3F-4F3F-867B-76C9B8B00C88",
    "fSRSforTN_CNS.pdf": "ACB8C1B0-4FBA-40BE-93A9-5823DAF31EB5",
    "FSRT_CNS.pdf": "A81F8255-5707-4C1F-91FE-65FEF2E3292C",
    "FSU_ENRLVERF.pdf": "54827A24-D4AE-40C5-9514-C95FDBFDA3E3",
    "Fuchs verification_1.pdf": "9F126D8B-2CBD-49A7-9775-35B697F5710E",
    "Fuleihan Antony - SKMC 2027 - logs.pdf": "8A73CFE6-9087-4C4F-ADE9-28556005134D",
    "Full time student.pdf": "A1D5E161-39C6-47A5-97AD-2191AB0991EB",
    "FullMember.pdf": "860B37C3-62A4-424F-8359-6FB30B45FBEE",
    "FullSizeRender.jpg.pdf": "6F1D54AD-B7B2-45CF-B4B5-DBB7499CB89E",
    "Fults, Dan.pdf": "795F5878-5576-4AD0-B0C9-E2209C0FB9B7",
    "Fults, Daniel.pdf": "55D6C4F7-5685-4BF1-8E17-FF7D72E335C3",
    "func outcomes ass for cerv degen disease.pdf": "70F692E5-9EF3-4736-A940-B314A719618C",
    "Functional Critical appraisal.pdf": "9E954F7C-BFC7-4E7F-91BB-5A3112B210F7",
    "Funding_UniMI_Tariciotti Leonardo.pdf": "D393FBAB-6BCD-458F-8400-10915B0E402F",
    "furkan karakas.pdf": "64271B4E-00B3-47C0-B191-22265A5CF21E",
    "FUS CL Thalamotomy.pdf": "2FDE7EC8-7973-46F8-BB86-5A6838607842",
    "FUS Frailty - 2024 ASSFN - Pertsch.pdf": "A8CD665F-C79B-44F2-869B-73E357C8F732",
    "Fuse.pdf": "A4839EF2-AD5C-405F-905E-EC155F954B25",
    "fUSI Human CNS 2023 Poster.pdf": "EBBB5BB2-C1FA-41F5-AB91-5503EACC13E5",
    "Futch.pdf": "11795776-77DF-4C5C-BE5F-BA1C72122A09",
    "Futile Surgery Poster.pdf": "0F65EAD1-E402-4375-A2FA-6118A9CA220C",
    "Future Sites Procedure - Approved 12-2-17.pdf": "161276B3-7F2E-439A-904E-94B63218ED7A",
    "FW EANS Receipt.pdf": "B6711F47-C8F0-439A-A1E1-065E2B2AD623",
    "FW_ Forms Admin Email.pdf": "94F9B637-A81F-4B50-AE28-0DC0D7B6E59E",
    "FW_ SANS modules for residents (Gil Kimchi reference).pdf": "D453570E-DD9C-4003-AEC3-007A88EB9333",
    "FWACS Neurosurgery.pdf": "D9AFE31D-CF2C-4F33-AFB6-F94E9E35DAC9",
    "FWACS.pdf": "2F77E034-1A9F-4ADB-B823-2D9D67B3FE93",
    "Fwd_ Forms Admin Email.pdf": "C9FE7C38-096B-4D20-B386-1C682EC0794F",
    "Fwd_ Jacobs School of Medicine and Biomedical S-Ali Muhammad Khan.pdf": "F076A3C0-F9CF-4003-8C0A-5B8DA8C0089E",
    "FY 2023 Ad Hoc Group Recommendation_5.10.22_Final.pdf": "1B0D8578-37ED-426C-9390-C8B31B40B49D",
    "fy_18_group_ty_letter_november_2017_-_final.pdf": "BA1635D1-DDE6-4397-A6AE-1B6DD7A9E571",
    "FY2020 LHHS_Report.pdf": "E952C84A-6C15-4CC9-90E6-85DF7B88D194",
    "fy2020_ad_hoc_group_recommendation_statement_final.pdf": "AA53C657-2DB4-453B-97AE-0B0EB0655E52",
    "FY2021 PSLRP Appropriations Letter House.pdf": "F9231A96-BFF8-488F-A74C-7F43420C12C2",
    "FY2021 PSLRP Appropriations Letter Senate.pdf": "E2C867E0-88ED-45A9-A802-340F276D25BE",
    "FY2021 PSLRP Funding in Final Omnibus_Senate.pdf": "12EF749B-1147-4CCB-826C-BC54DFF8C468",
    "FY2022 PSLRP Appropriations Letter House_Final_3.2.21.pdf": "6CB6F4AB-9786-4C99-81E6-37C450942348",
    "FY2022 PSLRP Appropriations Letter Senate_Final_3.2.21.pdf": "6500B69B-509F-47A4-AEF9-3899590C15E0",
    "FY2022 PSLRP Conference Letter_November 2021_Final.pdf": "DF4CCA23-27B5-434D-A7A2-1D99CF7381F9",
    "FY2023 PSLRP Appropriations Letter 051722.pdf": "FD414F1C-D325-4078-90E0-27EF1585C226",
    "FY2024 PSLRP Appropriations Letter House_Final.pdf": "87CD1AB6-497D-4021-9066-9D03E99DA4E8",
    "FY2024 PSLRP Appropriations Letter Senate_Final.pdf": "ACD13EAD-0AEA-45CF-A0B7-E46FCA021D17",
    "FY2025 PSLRP Appropriations Letter House_Final.pdf": "EC1ADF39-0A33-4C75-8963-D727CF1FA1F4",
    "FY2025 PSLRP Appropriations Letter Senate_Final.pdf": "F4C9B03E-3B75-41D3-883D-82F11CDF2629",
    "FY22 Labor HHS MISSION ZERO Letter_FINAL.pdf": "F9D5A7B1-B689-46A1-871A-55DC4776902C",
    "FY23 LHHS MZ Coalition House Conference letter 10.27.22.pdf": "906F13C1-AE36-4BA5-B808-B276F6134669",
    "FY23 LHHS MZ Coalition Senate Conference letter 10.27.22.pdf": "622AE5F5-C4F4-41DC-BBCA-DEEBAE8056A7",
    "FY24 JHU appt letter 1.pdf": "62FB7684-DB9F-429B-B118-7D992956161C",
    "FY24 PDC Letter.pdf": "19A88F10-81D6-4685-9FD0-29FF9139D155",
    "FY25 Pediatric Device Consortia Appropriations Letter.pdf": "E66577E2-77A3-4AA6-9E9A-EC4A5947B89B",
    "G Code Coalition Letter House Final.pdf": "01F6DFE4-4262-4A1D-9397-93970BF52AEF",
    "G Code Coalition Letter Senate Final.pdf": "4F068462-B290-4D72-A5C1-2218003A535B",
    "G. Abbas - CV.pdf": "3F787F9B-AC72-4D18-A9CA-5AB54445C986",
    "G. Mackinnon-LoG-1-22-20.pdf": "9A46BF9B-7638-423A-92C7-BD47A06E3B56",
    "G. Murad SNS LOS - Zipfel.pdf": "C3B61A6A-ADFC-4414-B40A-6A683969A812",
    "G. Skandalakis MD PhD .pdf": "BE8BE137-7677-427E-9BC8-4AA705DF7BBD",
    "G. Zenosos - 50% off first dues.pdf": "3181046D-7AAD-4742-A734-D401061C5CA0",
    "G_Kaur_Verificaton_Letter.pdf": "42B70637-A3EF-46DC-9194-0652AFA51CD3",
    "Gaal-Paavola_resident-status-confirmation001.pdf": "5E611C0A-F487-4086-8425-161F47324B72",
    "Gabat_resume.pdf": "273CBF48-D3FE-4D35-98E7-756D94DF0EDD",
    "Gabriel Ferreira Neves .pdf": "0D8130FB-BA6B-4049-A4A3-E9856B8165C2",
    "Gabriel Flores-FellowVerif.pdf": "C3EE8DF4-A6DC-4DB9-B54F-10987A19BFD7",
    "Gabriel LeBeau.pdf": "107CB403-5DF9-4E0D-9E39-6F947FE12BBA",
    "Gabriel Pham - Letter of Good Standing 2-25-2017.pdf": "2944B0FD-4CB8-46F1-9931-F2E5DCA923DD",
    "Gabriel Zada Recommendation Letter 1.pdf": "A2EAFED6-12AD-4C8A-AAA6-A21C5678C0B3",
    "Gabriel Zada Recommendation Letter 2.pdf": "9A7A6316-2AAF-43A8-812C-BC35362D4E05",
    "Gabriel Zada Sponsor Letter.pdf": "A11F2572-0D36-4B57-B736-30F3DEFB8C8E",
    "Gabrielle Johnson Letter of Good Standing 5-24-24.pdf": "D3D7CB0E-0B13-4671-8613-A94B2FF28CB2",
    "GabriellePriceCNSStudentMembership9-16-2021.pdf": "360D9860-B474-433D-A660-6D182FAE4D17",
    "Gajjar AMC Letter.pdf": "488B834C-405E-45A5-9B28-94B7C310D4EB",
    "Gal Student Status Letter.pdf": "44CC6CC9-CC9E-4B4F-88CA-F325992FB1C8",
    "Galal Elsayed - CV.pdf": "750F8DE1-FDB3-4D47-8AEB-3DC970B8D240",
    "Galang Christine Letter of Good Standing 7.25.23.pdf": "75F2244E-95D4-4673-A882-BED87961B905",
    "Gallagher Ryan Ltr. for CNS.pdf": "6C171B66-B1BD-4E3F-8D97-3B65E9BEB368",
    "Gallaher.pdf": "2C0BBC82-7899-4D89-8069-20048EFA2A0D",
    "Gallitto,M. Letter.pdf": "0C8439B3-DA83-4AB2-9168-B8A5C39D3D26",
    "Gamaliel Velasquez Jimenez-RESverif.pdf": "64999805-EB9F-4176-8AFA-6B3FCCD398CC",
    "Gandhi Verification.pdf": "6AC905A2-03DA-4A38-A22E-4872A5AFEEAE",
    "Ganesh Phayal Letter.pdf": "0CE34149-8A4E-4D2A-AC6A-DA9CFB39CF1C",
    "Ganga.Arjun AMS Acceptance Ltr 2021.pdf": "716FF840-A5F2-4306-988C-A62FF27B4A9F",
    "Ganglio Poster.pdf": "60FAEC56-6924-4A65-A103-18D600CB80C9",
    "GANI-24-Course Mailer - Live Microneurosurgery.pdf": "76C78765-E696-4964-AA13-5A440606F46F",
    "Ganju SNS nomination 2020  Rosner.pdf": "86266D92-1B3D-44CC-8FF4-1C84F1144847",
    "Ganju SNS Nomination Benzil.pdf": "2D3003A2-3A41-449E-8A6C-C8F2405B8D3A",
    "GAOletter11712emergencypreparedness.pdf": "BD169E5C-E01E-483D-8B53-1C096EA2F470",
    "GarcesJ_2019_CNS.pdf": "EF6D4DFF-E4BD-4455-8A08-BC83624A8057",
    "Garcia Cesar - Good Standing Enroll.pdf": "7F505538-F465-43B9-9C48-A4FC29584758",
    "Garcia_Cesar CNS 2019 Poster Presentation for Final Submission.pdf": "891E13DA-DA13-4D7E-8CEB-9C94ABF95A0F",
    "GardenCV Final.pdf": "B2A81237-ECDE-4BF4-8B26-53A94E8CE8FC",
    "Gardner_LetterOfGoodStanding.pdf": "85E17266-71FC-4C44-A45A-33BDF8635286",
    "Garling CNS 2019 poster.pdf": "67A82608-64B0-4B6C-97F5-E59D2887E6BE",
    "Garling Verification.pdf": "A9EAA430-FC59-4222-844E-BD7793BCFF11",
    "Garmo Lucas Letter of Good Standing 122221.pdf": "E7AC5198-A858-4154-A687-92E3F1B1DB2D",
    "Garni Barkhoudarian Slides.pdf": "B622837A-86C0-435B-999F-D7C30657C344",
    "Garrett Venable-RESVerif.pdf": "0DA2B7C4-09D5-4FD6-85F9-8E5A66528CB1",
    "Garrett_Banks_CV_Jan_2024.pdf": "9B92D612-6F5D-4ED5-9896-CD38D0623417",
    "GASTON OUMAROU certificate-1.pdf": "E137497A-FE16-4928-9C1C-A3FC59FDA0CE",
    "GATDULA_Extraction of a Metal Arrow.pdf": "95EAA318-8AD0-4AD1-8BC9-672A8E32800F",
    "Gattas Sandra_Gd_Stg.pdf": "ACE82874-E476-4C05-97DA-832665A56997",
    "Gaulden MCDI Poster V3.pdf": "C80FDE86-8473-48EA-A542-0F72791DDA82",
    "Gaulden MCDI PP Peds Section Meeting 2023 V2.pptx.pdf": "12A0651D-2261-49BA-967A-6268B840EC52",
    "GAULT_2024 ASSFN_PosterTemplate.pdf": "185C6BA7-72BE-4648-9DC5-4CAE15386CFA",
    "Gautam Diwas - Verification Letter.pdf": "E2E6B0FA-3C23-4312-AC2F-7640513C6375",
    "Gayan cv.pdf": "8D9F89C6-CBD8-42EF-952A-6234DD29FF17",
    "GBanks.RESverification.pdf": "B77E9BFD-EDDB-495D-9ED6-A427563FD339",
    "GBanks.verificationemail.pdf": "4F5DA022-B0F3-4A94-8AC0-0B677DCE271D",
    "GBM tumor radiomics CNS 2023.pptx.pdf": "0369FA8F-68F7-4F9E-9C1F-39A4858493EA",
    "GCannarsa.emailverification.pdf": "A9F5B9BF-40B0-4DC8-AD87-98941BAC7AFE",
    "GD_CV_2022_1.pdf": "E9D26097-C520-4471-91B9-BA3BBAC49BF8",
    "Geisinger Neurosurgery Residency Certificate.pdf": "19EDD139-F0EB-4054-B59B-24B283011549",
    "GELDRES.pdf": "6EA36215-FF48-42AB-8394-8E4B7FA2B8B1",
    "GElsayed.email.verification.pdf": "02091728-EBEA-437D-909A-CD63B7F4EE1D",
    "Gender Differences ASD--Tables.pdf": "0ABD2009-3257-4F2E-BDA6-715971ACFD02",
    "Gender differences tPA.pdf": "229FACA6-A256-469D-8CA4-45FD243BC699",
    "GENDER GAP POSTER .pdf": "ECA96D41-336B-49A2-B0A5-B19AC020422E",
    "Gender Med Student CNS PosterV4.pdf": "271C93C9-EE82-4CA4-84B8-7F9F15FA7993",
    "GENERAL CV.pdf": "5AE9694C-18B0-4067-BCED-D76531CF0A80",
    "General Didactic Slides - Germanwala.pdf": "9D8DB32E-E4C5-4AC3-8EBD-6BB153CE1B59",
    "General Notice of COBRA Contiuation Coverage Rights.pdf": "32866C09-4C91-4976-B7FF-598044868FD5",
    "General Small Group Cases - Gantwerker.pdf": "FEE71A45-7724-48B4-B309-A1E2B0BEA366",
    "General Surgery Standardized Letter of Recommendation 2.0 v2.pdf": "D198EAF5-214C-4988-A383-7D070ED2C4AF",
    "Generational Project Poster.pdf": "73D34F54-9FB4-415F-8C34-6CFD655EDEAB",
    "Genius MFP Document.pdf": "0D77870B-6EF8-4860-8913-9364A4BDC8EA",
    "Geoffrey Peitz-RESverif.pdf": "11DB590A-62E5-4D85-90B4-8B1B895F6953",
    "GeographicVariations_ACDF.pdf": "51A16A20-5C6E-4F06-A513-2077FC23EB2D",
    "George bush letter.PDF": "5E286320-30F2-48D7-93A4-5E714171480B",
    "George Letter of Good Standing.pdf": "A6A316FB-3C97-4959-A6DC-12F951441929",
    "George Washington University - Young Feinberg.pdf": "2828267B-1D04-45E1-B301-47B129C06BF7",
    "George Yang CV.pdf": "87E42FD4-591B-45A2-9537-D9422D6C0CA0",
    "George Zeegan-Letter for Congress of Neurosurgeons.pdf": "6167A4F5-61A6-471F-B2C3-1CD432F4DE6C",
    "George.Andrew.LOGS.M220.pdf": "A9B97ED4-7AC0-439C-8F9B-B0AD8A51899C",
    "Georgetown University Hospital - Felbaum Jha McGowan.pdf": "E7E2CF6A-EB75-4A00-AF6F-171B06183E0D",
    "Georgia.pdf": "BD456F9A-65B8-45D6-9911-952AD870F4FC",
    "Georgios Klironomos CV July 2022.pdf": "8BE1F002-A6FB-47C9-98D8-5ACB4755FAD8",
    "Gerace Peter-2026- LOG 8 25 23.pdf": "28DC2EE7-5467-44E6-9193-84C5D244CC29",
    "Gerald Oh VER.pdf": "8DFAAB0D-9E85-497F-B6C7-5CAB7E2DFDC5",
    "Geraldine Nabeta-Enrollment letter.pdf": "47E7F600-BBDD-484D-86CC-B352429A8643",
    "Gerges letter of good standing.pdf": "A9C1AEF6-BE42-4CB9-8A70-FA23AF3C0C1C",
    "Germano SNS Letter Heilman.pdf": "7BB28A5F-A671-401C-B34B-3FFBC51BEA8C",
    "Germano_IM_CV.8.19.2021.AANS. FINAL.short.pdf": "C2AD6CFB-C697-4F3C-A5BD-E82DC780074F",
    "Germano_IM_CV.Augst2023.short.pdf": "BAFAC7BB-D88E-4B9A-AB3A-55E00BE5D68C",
    "Germanwala for Amin.pdf": "46ABE2BD-7D88-437F-8AF9-A7142C3F3435",
    "Germanwala Slides.pdf": "2F08EBF9-67B0-43DB-826D-B2F6BD71BD13",
    "Gerrit Alexander Schubert certificate.pdf": "D666957C-B8DE-4895-BB3B-FD44C5B93B4D",
    "Gersey- Medical Student Verification.pdf": "0D371EB5-0196-4847-A440-510517D8362E",
    "Gg KC- 082421-Cervical pseudartrosis posters_v2.pdf": "07EC24C0-D1C7-4E92-8D69-83A98225AD56",
    "Gg KC-082421-Lumbar- pseudartrosis poster_v2.pdf": "A20FE69B-6C60-4CFD-8EC7-91FF83140B25",
    "Ghabussi Giselle.pdf": "7836C348-0A84-4534-9BEF-F2192D209A2B",
    "Ghadiyaram  Ashwin - Letter of Good Standing.pdf": "FE5C24EA-205C-4BD5-A418-E08ECC56BE57",
    "Ghaith_Saleh_R._Aljboor_-_M._D._-6.pdf": "92DEF723-7556-43DE-AD30-17D7BDC30BD9",
    "Ghare 2014-08-18.pdf": "E4E32083-D6EE-4DD0-9682-1DDDBEAF92BE",
    "Gharfeh.pdf": "AACDD1AC-E577-486B-94CF-1752E49DD16B",
    "Ghayur Abbas - Letter of VER.pdf": "57A8188D-690F-4661-931B-126524243CEE",
    "ghazwan cv update Feb 2019.pdf": "8D15A0D4-B3CC-4F71-A67E-063C0E59DBEB",
    "GHOUL CV.pdf": "EAE882AE-BBB8-4F2C-B557-6E0760EDB662",
    "Giancarlo Mignucci-Jiménez-RESverif.pdf": "EA7D6CA7-0CC7-4D08-9804-5DD25921A4F7",
    "GIANINA HERNANDEZ GS COPY.pdf": "85C8E48C-C6E0-434F-8BA1-092BCE366E07",
    "Giannaris Recomendation.pdf": "2980C89D-68A2-4BFE-AD16-057FFEA2F20D",
    "Giannotta, Steve.pdf": "8BD2A78C-4BD7-4670-AE20-64C38EC1C3DE",
    "Giannotta, Steven.pdf": "62BDA80F-B0E4-488A-91DF-014DF15D32A3",
    "Giant CPA tumor surgery.pdf": "7EC6A0B0-0F01-4BB5-867A-9D20A1948874",
    "Gibbs.David.M1.LOGS.pdf": "19A4FBCE-01E6-478B-86B9-A53073243B30",
    "GIbrahim_2023_10_SNS.pdf": "308846FD-068F-4651-B482-C04CBB713679",
    "Gifford Connor S.  Letter of Good Standing.pdf": "2541FDB7-8AF4-4D67-A158-476C126F4589",
    "Gil Kimchi-2ndFellowship.pdf": "9F9D1B4C-541A-4E0F-BBBA-BF0467ED345D",
    "Gil Nuno Castro Fernandes M.D..pdf": "A62B3243-645B-48D0-9476-6D781AEA770A",
    "Gilbert Olivia Letter of Good Standing.pdf": "A7FBC760-CA40-4AD4-B52E-7507B3A00AC7",
    "Giles Tyler Xavier - Letter of Good Standing.pdf": "C9E58877-215A-446B-8AD1-33F45387ACB1",
    "Gill Letter_Hannon Signed.pdf": "CE64AE2C-7A43-4330-AA56-890BEF06E470",
    "Gillian HarrisonRESverif.pdf": "4FF7A574-EB0F-4C9F-B7FC-34C4D71D3A6F",
    "Gillick_John_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "510FCFD9-2DB2-4192-BF01-24113EA10D3B",
    "Gillick_John_2024PositionalMembershipApplication_CVandBiography.pdf": "6CE3C0D1-FE5A-4A60-A6B5-00A5E092C7C3",
    "Gilmore Brittany 2023 - attendance verification letter[4211].pdf": "5CE1B1E5-2408-4F51-AD13-16BF18AFC2BE",
    "Gilotra Kevin-2025-LOG 7 24 23.pdf": "512BFBF4-8394-4EC4-BEAA-F42942599A4F",
    "GINALIS CV.pdf": "196A2659-CD76-43C4-8C1E-337C2C542080",
    "GIORDANO FLAVIO CV ENGLISH 07.7.2019.pdf": "A7A34224-630E-4494-A2F5-372E3AFDB1E8",
    "Girgis.pdf": "1BF2000C-F70E-4F64-85E2-E2D9A0744BA8",
    "Giridharan Poster ASSFN2020.pdf": "FE427902-969F-48F8-BC26-91C96BEBA8EE",
    "Gisela Gonzalez Enrollment Verification Letter.pdf": "BB9D5934-8AC4-4E9D-AB41-0E0AA9975D3B",
    "Gittleman et al. J Neurooncol 2017.pdf": "652F5567-C8E3-4168-A193-4370F249207B",
    "Giuseppe_Rojas_Resume-2-merged.pdf": "6F999BD6-35C7-433E-845B-C3C71EBB6F54",
    "Giyarpuram Prasad VOE.pdf": "11DC7ECA-DA82-40EF-A79C-CE77F50A6666",
    "GK LoR- November 2 2022.pdf": "18B84A48-22F1-4DF0-B35F-9216239D24B8",
    "GK LoR-CNS Scholarship 3.28.22.pdf": "23B8C7D9-259E-4928-AA5A-06BC8C4B05B7",
    "GLai.emailverification.pdf": "40B5E4DD-9043-4D5B-8171-9A450FAB6016",
    "Glaser Verification.pdf": "BAA7F6C4-C0C6-4913-9919-277B65692CED",
    "Glaucia_Jong_A_Liem_-_Applying_for_a_neurosurgery_internship_at_INI_Hannover 1.pdf": "89CEDA33-1740-46AB-8954-9D6911577622",
    "GlickmanCV 8-2021.pdf": "ABAF6BE6-08DE-443B-9025-63D19687C605",
    "Glioma Prefs_Succop_CNS2023Poster.pdf": "734FEA76-22AE-4C60-9646-AF623ECACDCC",
    "Glioma_Poster_CNS.pdf": "26DA831A-F356-4235-8833-11F125CEB0BA",
    "GliomaGenetics_Poster_CNS2023.pdf": "64FC9BE3-AD7E-41AB-A640-1A620169D57E",
    "GliomaStatinFigure3.pdf": "4B3D8E8D-CB2A-4AEA-9ABB-62A9CB475553",
    "GliomaStatinFigure4.pdf": "E3DE9928-BE92-4C8F-8D40-AAE15B3BA1C3",
    "GliomaStatinTables.pdf": "CDF7FDC6-DE68-4537-9DBB-80D36DFD472D",
    "GliomaSurvival_Poster_CNS2023.pdf": "AD785B1D-54A6-4ADB-9D49-E3DEC327A308",
    "Global Bibliometric ePoster Peds Sxn 10.2023.pdf": "632084B1-B135-4E74-BB22-372BCC1FC2FA",
    "Global Flexibility - 2021 CNS Poster.pdf": "160569A5-4700-4175-8C44-0EF26D6573FE",
    "global_codes_letter_to_secretary_price_final.pdf": "7A1CCB81-7DA9-4252-A251-698BA6B92CE0",
    "global_surgery_codes_2019.09.27.pdf": "A4E52703-CA00-458C-9063-3482672B7F53",
    "global_surgery_survey_report_final_090216.pdf": "9682B422-A469-4F13-BFAD-7C1C5820E67F",
    "global_surgery_talking_points.pdf": "5CFA1C50-BA83-4411-9764-E36A9AB4BA50",
    "global_surgical_payments_letter.pdf": "510E992A-474B-48F0-89BF-BA54746120EA",
    "Gluski 033096.pdf": "805E84A7-70A4-4108-945B-72A801FF83E7",
    "Gluski Verification.pdf": "D97CB8E5-685A-4987-B07C-2857557D9157",
    "Gmail - Membership Application - Neurological Society of India-Krushna Virda.pdf": "7660BD00-6DA5-4C77-A126-4699B8A10022",
    "Gmail - Membership Application - Neurological Society of India.pdf": "A84C4D71-5CD3-4BCC-8DFF-BD0078EAE09F",
    "Gmail - Your Google Play Order Receipt from Jun 12 2019.pdf": "61277DA8-224C-4672-B4AC-06687CECB776",
    "GMC Raenette David Letter of Good Standing.pdf": "FAE763F1-3EFA-48D7-A3F0-0ACCF190561E",
    "GMC_registration.pdf": "B82A5B88-025D-44DA-83E7-6BD84DF82E59",
    "GME Advocacy Coalition Comments on CMS-1752-P_6-28-2021.pdf": "E4C35197-E08B-4FE9-A00B-1A65AEAE3AD5",
    "GME Advocacy Coalition Sign On_COVID-RPSRA_FINAL with Signers.pdf": "E4602478-EE08-40A1-9A50-0286B9B654F1",
    "GMEAC Infrastructure Letter_FINAL.pdf": "28AB9676-E066-4F20-B2BF-F086ED496EF7",
    "GMEAC Reconciliation Sign on_FINAL.pdf": "FDD1AFC7-7258-44E7-B467-6FCE04E94AA7",
    "GMEAC SUDWA Support Letter_March 13 2024_FINAL.pdf": "42F4FDC7-32F3-4FE3-9EB8-7C65B79173C6",
    "GMEAC Summer 2022 Reconciliation Sign On_FINAL.pdf": "045293A8-BF5D-40D0-8E9E-AE58879BF830",
    "GNS.pdf": "B990ED5A-38A0-4780-BBFB-4CCAF00359EE",
    "Godil CV.pdf": "924E64CE-15BC-4FC5-826D-8828CC72E7B4",
    "Godil Jamila - Letter of Enrollment and Good Standing - 8.3.22.pdf": "8EC6EEFF-D539-4622-8719-8CF3997F2DEC",
    "Godil SS_CV_Sept 2021.pdf": "C4FC0479-E04A-43D4-9BAD-47240E69DFA8",
    "GOERTZ MIRENGE DUNIA CV.pdf": "ABC71E4F-8E49-4D3D-B959-5B7C98767284",
    "Gogia.pdf": "A5ED066F-F7B6-4F09-AB62-77DE8E7BC167",
    "Goh Jo Ling Neurosurgeon CV 11.12.21.pdf": "F75FEDFD-F6B0-4BBF-B604-C512C1E90E0C",
    "Gohar Majeed-RESverf.pdf": "2748C078-509E-4605-87B4-C39735EF8339",
    "Goktug_Akyoldas.pdf": "EBF2AB1B-0AA2-498D-A0A1-234B34B72665",
    "Golbahar Yazdanifar-LOGS.pdf": "FB06A144-D411-4B92-8C63-B2563FC1E0C2",
    "Goldman-Daleo Hannah-Letter for Externship 2022.pdf": "AA43D3F0-0BAB-4689-A079-8C8AAD2DF794",
    "GolubDanielle_CV_MAY2024.pdf": "7522FAA1-53CD-4DA7-B8F2-A95AC131060D",
    "Gonzalez Aneysis - Good Standing Enroll.pdf": "B02907B7-FA87-4BDC-B869-308507DD23C2",
    "Gonzalez Gallarod_RES-Verification.pdf": "E869D9E1-E8D5-43AA-984B-E32F2A0650C1",
    "Gonzalez Gallarod_Verification.pdf": "F1967668-C0AB-4CFF-A90F-2AC21072958F",
    "Gonzalez Gisela updated.pdf": "A2BE843A-40F4-4638-8F64-E700AFAD5870",
    "Gonzalez SNS 2022 BREM.pdf": "747845E6-85E7-4A50-BE93-A1EBAA0A136C",
    "Gonzalez_ASSFN_2022_v2aa.pdf": "FA4523EB-F367-4E64-9FB3-ADCC7F39D8C1",
    "Good academic standing letter head.pdf": "E1AB6013-440D-42A8-9447-97D37DED7595",
    "Good Standing - Bandlamuri.pdf": "51F9A2B9-F882-42C1-908A-4E2584D8DE03",
    "Good Standing - Bao.pdf": "0D1601FD-C7FC-4DE2-9DAA-AE1E9EA3FB41",
    "Good Standing - Dugan.pdf": "4448505C-99CB-46C2-97E9-4B039B689DDB",
    "Good Standing - Heydari (1).pdf": "434F22B3-13AC-4694-8AF4-3CB8B2BF76AB",
    "good standing - Tanenbaum.pdf": "3E722F32-6116-4D01-B96B-1493B8EF177D",
    "Good Standing - Williams M.pdf": "8D71885F-A584-40A5-98F3-A266BE901F6C",
    "Good Standing Document.pdf": "8C2E16AA-F972-4374-8D6F-672BDD210FD6",
    "good standing dual Alawieh.pdf": "9712506A-5C51-4155-9A6B-6FE2E3A15160",
    "Good standing Haris Khan.pdf": "0ED7F80C-49EC-43E6-AFB8-0EFF7DE00835",
    "Good Standing John Patrick McGinnis_Letter.pdf": "CBA2B5A7-A620-44C3-A139-914D2112C582",
    "good standing letter - Alentado.pdf": "F22595AC-6168-4EFB-87FB-DC2A650BA68D",
    "Good Standing Letter - Alexander Arash Aabedi.pdf": "6AB81EC1-16D8-4CB3-8005-8E882614FDD3",
    "Good Standing Letter - Ankita Das.pdf": "A529CA75-AF0B-4A9C-8B58-B8209C34DB22",
    "Good Standing Letter - Arjun Syal 2-21-23.pdf": "B26E794A-27D6-467C-ADE1-EB51EE80D1FF",
    "Good Standing Letter - Away Rotation Sravani Kondapavulur.pdf": "D903D23D-F6AE-4E26-8DAA-355743C0427B",
    "Good Standing Letter - Bridget Nolan.pdf": "CA538510-46B8-4502-9FA4-D70AECBF1137",
    "Good Standing Letter - Cameron Beaudreault.pdf": "CDBD3A87-37B4-4681-B753-C48DD954949D",
    "Good Standing Letter - Christian Rajkovic.pdf": "47C08FE9-DF8F-4FB6-8218-09B5583F19C7",
    "Good Standing Letter - Christina Ng 2021.pdf": "6F66806B-2B4F-458F-A65C-0160A71FF4F9",
    "Good Standing Letter - DaggubatiV 1.pdf": "B4D6C02B-70BE-4EDA-ACBA-7B894D062B71",
    "Good Standing Letter - Enrique Vargas.pdf": "1A3D6967-26A3-490D-B5BC-553ED56E836F",
    "Good Standing Letter - Erick D. Martinez.pdf": "F61220A8-6E3B-4CF7-BD6E-3E370BC46F11",
    "Good Standing Letter - Gillian Graifman (1).pdf": "F0E9E197-A7C0-4329-B7B9-AE3BD3FEF12C",
    "Good Standing Letter - Jessica Dorilio.pdf": "A29E5F97-9D94-4081-B03A-0830A30C283B",
    "good standing letter - Kaul.pdf - for CNS.pdf": "11CCFC86-433C-4CF1-8237-4A85AB01D6FE",
    "Good Standing Letter - Kiss - 2.27.24.pdf": "7D5EABBB-0E2E-4DF3-8171-49A186385F7B",
    "Good Standing Letter - Lee - 2.23.24.pdf": "75900112-78EB-41C8-A46A-DE569D053943",
    "Good Standing Letter - LeeA.PDF": "396578A0-86AC-4F74-A326-ED70E510117B",
    "Good Standing Letter - MusterR.pdf": "E8B638AF-1FAD-489B-A732-8B7D92A5DE00",
    "good standing letter - Perlmutter.pdf": "3C69C610-29FD-4F03-826B-10CDC8BE3194",
    "Good Standing Letter - Richard Wang.pdf": "50D2B1A7-D763-4F41-AAA6-00342B44EC12",
    "Good Standing Letter - Ryan Radford Llewellyn Phelps.pdf": "BCBBE077-35CF-4415-8752-328D75544770",
    "Good Standing Letter - Valliani.pdf": "DF9077EF-D0FC-415B-AB0F-1A80A14817CF",
    "Good Standing Letter - Vellek_John.pdf": "0CEE32CF-0BA5-4A49-BD0E-0DD8441164DD",
    "Good Standing Letter _Greisman_Jacob.pdf": "74B046A6-58F9-4086-801F-0F129694E9B0",
    "Good Standing Letter _Spirollari_Eris.pdf": "FDEAF066-963E-4848-976E-5ADC02F33439",
    "Good Standing Letter 2024_DebaratiBhanja.pdf": "FBA0C530-9D90-421D-9380-9F3AFBCA69A6",
    "Good standing letter ANGELA MEI SEILER TORRES.pdf": "64BF5782-C744-4DEF-B9C1-36B3C59BDD22",
    "Good standing letter Baker.pdf": "1492FECF-4594-4C24-A3FE-6F829EE2E4A5",
    "Good Standing Letter CNS.pdf": "C8A07A62-F7DA-4079-BF99-815A4EDCF1ED",
    "Good Standing Letter for David Bailey.pdf": "20889A0F-3077-45DD-9E15-DAE7D1F0D249",
    "Good Standing Letter for Hsieh, Jason.pdf": "B5604A5D-B417-463E-B405-4721C6EAC60D",
    "Good Standing Letter for J Domino.pdf": "2494B7F5-BF19-4771-AAC8-6DF7BD84950F",
    "Good Standing Letter for Societies.pdf": "F5084FA0-FC0C-492D-ADA8-32286ED5EEBC",
    "Good standing letter from Dr. Wagner.pdf": "9721A88F-8AD9-4F66-AB35-DB0F890BF289",
    "Good Standing Letter Nikhil.pdf": "7B5432B7-80F2-448C-8965-AEF54C670CD9",
    "Good Standing Letter on letterhead Yamamoto Erin 2019.pdf": "4BF2EDF5-4796-4A7B-AC6D-EE44E2D28F4C",
    "Good Standing Letter Serrato.pdf": "6C2629E5-A3A4-430B-A3E9-342CEC13072D",
    "Good standing letter Sharath Kumar Anand.pdf": "59E9221D-FC15-4064-A153-EFF844ECF0AA",
    "Good Standing Letter Terilyn Stephen.pdf": "2B014932-D89C-497D-BD3D-D4327016FDBA",
    "Good Standing Letter Volovetz Josephine 2019 6-11-18.pdf": "C4BACD69-CA43-4A94-9F19-1ED3D6F67DA1",
    "Good Standing letter-Chase McKevitt-072122.pdf": "8206F23E-8C7C-442B-99A7-AB618D956A11",
    "Good Standing Letter-Neurological Surgeons.pdf": "F9C32075-E85B-40A4-A8CD-031F5C03CADC",
    "Good Standing letter.pdf": "208B1A85-FCE1-433B-A734-156FB9BD9B6A",
    "Good Standing Letter_Ammanuel_Simon.pdf": "F934C887-E71E-4CE4-ADA9-24E856CA6AD3",
    "Good Standing Letter_Durcanova_Beata.pdf": "312A23EA-C7A7-4905-9C7B-76795232EEAF",
    "Good Standing Letterhead.pdf": "0BA3824D-61BC-4D36-931B-06FADDC4E7BA",
    "good standing ltr-Kathryn Tringale - CNS.pdf": "91D62AF4-0909-4ADC-8CE3-74D8387A3D13",
    "good standing ltr-Michael Brandel -CNS.pdf": "591385B9-27F3-4408-B9FA-89168CBDAAF0",
    "good standing ltr-Mike Heffner-CNS.pdf": "B43F0616-F7B9-46E6-BAE1-FCCB2ACD2D85",
    "good standing mohammad bilal alsavaf.pdf": "C6689F32-C42B-4BF4-BE0F-242717CF4CB9",
    "Good Standing Verification.pdf": "E7FEBDA2-8A3D-4185-99F0-09CEA3EB7041",
    "Good Standing.pdf": "3B8D61D3-F0EE-4244-8745-1A871EDBEFB7",
    "good standing_0001.pdf": "8E50C285-5BDB-4B5E-9702-99D0DE34D170",
    "Good Standing_Mitchell Rock.pdf": "734E2DCB-00F4-4B78-8272-A9F953C73B5C",
    "Good_Standing.pdf": "E82A99FD-DCB4-4DD4-A358-BB85BE2ECB15",
    "Good_Standing_-Behbahaninia_2014.pdf": "371E2671-E6F8-456E-BBB5-67D40DBFCF79",
    "good_standing_ohsu.pdf": "F8E3FC87-E525-4F4F-AF53-C25F90E74AC5",
    "Goodstanding Letter.pdf": "B2CC075F-3174-45DA-AE94-5B37CB7EFFEA",
    "GoodStanding.Bailey.J.pdf": "F5E71ED6-B0D3-418E-A636-31DE0D82348E",
    "GoodStanding.pdf": "CC0AA24C-A73E-44CF-89BD-4257854A743E",
    "GoodStanding_Dusseau_032024.pdf": "CD5DF3F8-500B-4BDA-BFAE-292A32CEC290",
    "GoodStandingLetter_CallahanK.pdf": "B888BAD9-2743-4795-B530-9556CBD48088",
    "GoodStandingLetter_SarrieraValentinGabriela.pdf": "0140A655-D584-49E3-BB5C-21EA997C1806",
    "GoodStandingLtr2_GHAFFARI-RAFI_Arash.pdf": "B9F0DA39-4C70-4037-91A9-2509EBE58EC5",
    "Gopakumar CNS Poster.pdf": "3C71EE93-D2CA-4188-9868-854A41686667",
    "Gopakumar, Sricharan Enrollment Verification.pdf": "7A2824FA-7FED-4CF1-A6A6-3A4243A7587A",
    "Gordillo_Alan.pdf": "7698734C-7930-4D9D-9989-91E3D0DB78C4",
    "Gordillo_Alan_CV_april_9_2023.pdf": "C741CF20-01E9-4F44-9BB1-E0171169DD2E",
    "Gordillo_YepesRiosLetter.pdf": "82883E6E-AA12-4841-AF52-CD52180DA2E8",
    "Gordon Jonah-Letter for Membership.pdf": "0B4A872B-A6D7-4714-8590-1CB9030502C3",
    "Gordon Li CV.pdf": "D423EF4F-9661-405D-B4B7-1C41C86B558F",
    "Gordon Li SNS Letter 9-21-2021.pdf": "8D95B38D-1C98-4D4E-8F41-D3241EDF9557",
    "Gorton, Lauren_Good Standing_7-17-15.pdf": "44081E66-B4BE-4CBE-A335-3FA99B9475EA",
    "GOS GCS Imputation Poster Submission.pdf": "1BB0449E-F5D9-4CCE-AC69-1F4B16E153D0",
    "Goulart Chief Certificate.pdf": "E8C5CD5C-7885-44EF-93EC-E52B2FC7FF56",
    "Goulart CV_.pdf": "AFDF65FC-CA3D-4E1A-BDCD-00AE7A2FC62F",
    "Goutnik Michael - LoGS.pdf": "C6F7D702-D00D-46B6-BA5E-62CC1CEC6AEF",
    "Gowda Nishanth.pdf": "953B67A4-04C5-44B9-AB88-5F1E73D4907C",
    "GP_CV.pdf": "8D6FB976-E047-4B68-BEA4-14116303822E",
    "Grabowski, Matthew LoR CNS Med Stu Grnt 08-02-12.pdf": "05758D08-98B6-4AC8-8384-D2218887E515",
    "Grabowski_Figure1.pdf": "20918E2B-4B2F-41FA-956F-B95CAAFFDB03",
    "Grabowski_Figure2.pdf": "9A67311B-B2DE-43B6-AABC-CE7B7C08A312",
    "Grace LaisignedPostGradTrainingVerification.pdf": "76D92A0F-6D04-4433-99CD-DAC293DECD6A",
    "GraceLaiCV2023.pdf": "6B514CAF-174A-49D8-9147-7500F507FF2C",
    "Gracie Palmer_Good Standing Letter.pdf": "E7ED961B-4F58-4C3D-AF80-5CE7A4A0F5A5",
    "Grades_poster_final.pdf": "534BFCB9-F7D5-416B-9E18-B33BDC158C4E",
    "gradesbusm.pdf": "740CA3F0-FD9C-4E2F-8D00-0F59AC6DB9CE",
    "Grading Examples.pdf": "3B955210-11AC-49C6-8E83-593E4D5D3503",
    "Grading Scales.pdf": "3F229782-6E2B-4C82-850F-4355BC9BFC12",
    "Graduation BWH Neurosurgery Diploma PValdes.pdf": "626481B3-62E3-4631-8E2F-5525E0C6F94A",
    "Graduation Certificate - Basil-2022.pdf": "8E4E2C81-967D-4A15-97FB-2DA64B64AED8",
    "Graduation Status Soumya Pahari.pdf": "553E1F52-6C5A-4760-AADC-672D78D0A949",
    "Grady CV.pdf": "8C03F48A-FD92-47CA-B38E-671E73411759",
    "Grady Farooqi Letter.pdf": "F9B10C2E-9F75-45F1-B9F6-AD6782D775D0",
    "Grady_Neil Malhotra_LOR for SNS Membership_093022.pdf": "EF392993-E52A-4AC0-BDCF-6B85D453515D",
    "Grady_Shabbar Danish LOR for SNS Membership_Round 2_102820 (002).pdf": "F8373E58-5F14-46F7-9CD6-D0C8110999CA",
    "Graffeo - Niznik LoR v1.pdf": "C7F7C466-844D-4243-A1B5-BC459628BE90",
    "Grandhi Slides.pdf": "5D22035E-A532-472E-9FE1-2CC36D9BB21C",
    "Grant_LOR_Final.pdf": "0DAE5F09-1486-4C8A-B46F-639C4083F447",
    "Graph 1.pdf": "032D5332-992D-4B1C-82FF-F74CEBF6F4CC",
    "Graph CN.pdf": "C3198262-62E8-481B-8467-1DAA597E309C",
    "Graph LMD.pdf": "BB44E01F-4881-4F87-824C-D3B1AEB8F113",
    "grb001.pdf": "2FF02D13-BEB1-4D9A-8C66-422A9B781AA7",
    "GRC POSTER SHANDRA March 2019.pdf": "6F010AA4-ADCA-4820-9B84-FD1628CAEDBC",
    "greatestFoci.pdf": "A78BF17B-D4A5-4BFF-8701-68F425CC0DB4",
    "Green and Brown Minimal Geometry Landscape University Research Poster (1).pdf": "1CB7D18A-CE6F-437B-99DF-29EE3F19919E",
    "Green_ Letter of Support.pdf": "B72443CE-9CA4-4953-A648-B1B7E391D485",
    "Green_CNSCSNS Fellowship Budget.pdf": "C1403273-35D9-419B-A6D2-AA90454B9A44",
    "Green_Reference 1.pdf": "16DB7BEF-B33A-494E-9562-6F3281EE3EEB",
    "Green_Reference 2.pdf": "0432DBE8-A932-44A0-AE5B-170BE2843504",
    "Greenan.pdf": "76A499AD-821F-44AB-8745-2BE3FD6E4536",
    "Greenberg Sally_05 17 2023.pdf": "38380221-4DE8-4146-BC16-38961DD7C11E",
    "Greenberg_Ross_CNS 2022 LVAD Poster.pdf": "BF9E35FB-97E2-4FFB-9BEB-B602184A2104",
    "Gregory Cannarsa-RESverif.pdf": "DD611FAA-9789-4278-A46F-255784C0EF11",
    "Gregory, Stephanie_GS.pdf": "CBE2B5B5-4F49-4155-92CF-174379FF54D9",
    "Gregory_Basil_Resume_07252022.pdf": "EDB70246-C069-4074-920A-7F149BDB3A98",
    "GREILGoodStanding.pdf": "413ABD44-775A-40F9-8C4F-A4A90BD4C7F4",
    "Greven Alex_20Feb2020_Good Standing.pdf": "6F32C2B3-615D-4FE9-A1E7-EB828A9AA3C4",
    "Grisham Candace.pdf": "3A24025F-5D87-4507-8194-272FCE85853A",
    "Grossbach Leonard.pdf": "13D0B421-3D32-438B-8F8B-0AAE5B92AF16",
    "Grossberg_Jonathan_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "DBCBA5EC-3F29-4032-A4CB-F513CB371D85",
    "Grossberg_Jonathan_2024PositionalMembershipApplication_CVandBiography.pdf": "16F91CB0-2CAD-40C8-BF25-DB701C0D1CEA",
    "Grossman, Bob.pdf": "FF0E62FB-87A9-4EF1-9EC3-38CE070EC845",
    "Grossman, Robert G.pdf": "1DB42A34-A2F8-40C3-90EA-10911872BD38",
    "Grossman, Robert.pdf": "70DAE102-40C3-43E7-9E60-F72DFF36045A",
    "Grote, Ernst.pdf": "70D93C2C-549B-44FB-8C9E-3B6EB6530440",
    "Grubb, Julia.PDF": "EC0CA2EC-E453-4BA7-86B9-9EAD9D177D1E",
    "Grubb, Robert L.PDF": "002EDB0E-E488-4099-A7D9-A75C46340121",
    "Grubb, Robert.PDF": "E0C3A8B9-10FD-45E8-AEE6-0C5790D813D8",
    "Grubb_Robert.pdf": "B8070FB7-8775-4908-837C-C82CA7F1359C",
    "GS_CNS_Poster.pdf": "803355DB-F53D-43C2-B5A8-C575C5C0E3A4",
    "GS_LetterofGoodStanding.pdf": "5CE4DD57-636E-4A62-89E3-DCAD308074D9",
    "GSHPA support ltr to Bucshon-Ruiz 2023_HCLA.pdf": "63E8E254-8C6F-4D74-B552-DB4CF1AC974B",
    "GSTTP Poster Presentation 2021_updated for CNS.pdf": "1B367B37-1888-4E49-A237-A298541AB169",
    "GSW _ CNS 2023 v2 .pdf": "AAD55314-BFFC-4971-8E79-843E61303DF6",
    "GSW Resume Short.pdf": "1FFCF360-07CF-4433-9C09-F30963FD27DA",
    "GSW Resume.pdf": "E48716D6-4672-40D2-9BE5-3627E9F15C4E",
    "GSWH Abstract Figure.pdf": "492AFE76-E8B8-47A6-93E9-39B2A4567387",
    "Guadix_MD FT letter.pdf": "79910A46-90B8-4BEA-92AF-F74A79986B44",
    "Gualano_Letter_of_Good_Standing.pdf": "60198832-640D-4DAE-948A-4B6F3A39DCA2",
    "Guerrero-Cazares AAPC CV July 2019.pdf": "2532917F-234C-4397-AFE7-393469B8A48C",
    "Guide to Pulmonary Critical Care for COVID-19 FINAL.pdf": "E1FCB559-0D06-4D01-853F-50DCA6AAA3AE",
    "Guidelines for Service on the CNS EC.pdf": "C51D1C96-69B0-46DD-8255-C1EA8C2E558F",
    "Guidelines_Evaluation and management of blunt cerebrovascular.pdf": "B00D0E8D-EB2D-430C-AECB-B96F3235BA82",
    "Guidelines_for_Management_of_Severe_TBI_4th_Edition.pdf": "E617C487-707D-410F-BD28-FB45ECE233D2",
    "Guidelines_Ischemic Stroke due to Blunt Traumatic Cerebrovasc.pdf": "F1A77E7D-0D86-421D-AEA8-A9A9CEF6D841",
    "guidelines_pediatric2_0.pdf": "B3ADDE29-16B7-48CC-ADFC-0CD135FA082A",
    "GuidelinesForTheManagementOfTBI3rdEdition2007.pdf": "E7A96DCF-9E81-4773-AA3C-3D3EAEA5AEF7",
    "GuidelinesInformationAnalyst.pdf": "7B93ED67-9E8A-4409-9F6E-BB7C419BD780",
    "guidelinesspecialist.pdf": "0E9D97AC-1F2C-4B39-822B-1F21BFCD0A66",
    "Guidry Bradley.pdf": "667168C7-0181-48C5-8AF4-08E27CDE62AA",
    "Guilherme Barros-Fellowship.pdf": "D5CBE537-C864-421A-8CC9-1B37AAA2F6EE",
    "Guilherme Porto-FellowVerif.pdf": "8FFA5E9C-A512-4E97-A289-5EBE931D9504",
    "GuillotteAndrew Letter of Enrollment.pdf": "72051B9B-D314-4F0C-8593-A99D0D198B6D",
    "Gulsuna_Beste_CV_2023.pdf": "F29A8483-B922-4E92-AC8A-CE222EB9739F",
    "Gulsuna_Beste_LOR.pdf": "DE5231DE-FEF4-46FA-83AC-0D5E9994CC64",
    "Gunel_Claus_2021.pdf": "EBCA6CA8-57CE-499F-8905-D3A250DA8A40",
    "GuptaMihirCV.pdf": "A242C52B-60D9-4FA6-BA25-0B59D49745FE",
    "Gurses_Three Dimensional.pdf": "56EFCBB1-1042-48F8-B18A-963CB8C790D4",
    "Gurvinder_Kaur_CV_updated_3.pdf": "59B36960-CC91-417B-8B82-0A62417A06CE",
    "GUST USERNAME CHANGE.pdf": "69530BB9-BA03-43C6-9803-A35C04812175",
    "Gustavo Chagoya CV Post Graduation.pdf": "EFB639DF-9CCC-454D-AE65-F16601E0B5D5",
    "Gustavo Parra-Romero-FellowVerif.pdf": "4F806F78-C45D-4726-AFEE-E06AA1471881",
    "Gustavo Parra-Romero-FellowVerification.pdf": "9C5D960D-9B88-438A-B7DF-20056141FAB6",
    "Guthikonda LOR Chris Newman CNS Leadership Institute 1.4.22.pdf": "9D0673CE-FCAD-4B8F-8E51-7FC8E79E2867",
    "Guthikonda LOR Jamie Toms CNS Leadership Institute 1.4.22.pdf": "8D04FF21-2C9B-41D5-A43A-3FAF11BE9C05",
    "Guy Dunetz.pdf": "42912FAC-D697-4A21-872A-A905FC757089",
    "Guy McKhann CV.pdf": "8433961E-0E9A-4449-8936-94721E1502AC",
    "Guy McKhann Nomination Letter.pdf": "C32FD7FD-DDD8-4F21-87A0-AB9DB656D022",
    "Guy McKhann Recommendation Letter.pdf": "713ABB59-2003-4AB4-9C18-F549D7C5A86C",
    "Guy McKhann Sponsor Letter.pdf": "B37AA250-8638-4D5B-A943-B2C3AFBE1C3E",
    "GVenable_CV.pdf": "CA230713-1E95-4D2E-8208-677BC457D75C",
    "GVP Research Funding Letter FY2023 Final.pdf": "9F0EFC4D-0423-41C6-818E-90AF66058676",
    "GVP Research Funding Letter FY2024 Final.pdf": "057BD606-5787-491A-906F-A380BEE357D1",
    "GVP Research Funding Letter House 2021.pdf": "B4539A71-804C-486E-870B-57D4FADF31AE",
    "GVP Research Funding Letter Senate 2021.pdf": "C6EC6765-BD77-4E4D-B295-6D4DFD1FAF17",
    "gw_diploma.pdf": "273B53C5-8D22-410C-BEF7-D263AFC34732",
    "GZadeh_CV.pdf": "B8FE727B-0D76-4FBA-B1D9-831F92D7A2F3",
    "H - NSI.pdf": "67E35FDF-2F3C-4436-8041-03BAB2D974A3",
    "H. Westley Phillips_Verification.pdf": "B772F41D-2556-4F2E-89B5-2781910DA348",
    "Ha,Christopher T.pdf": "2AA4AFC1-ECB5-4F09-88D0-B18BBC4B4E38",
    "Haas student cert 9.12.pdf": "2B9EF78F-2D11-429C-BEB2-5D2F5FF03A9F",
    "Hace constar Manuela Aramburu 220705.pdf": "4E825A47-9666-48B0-8B80-D930DD8CF57F",
    "Hacettepe_NeurosurgeryResidency_Letter.pdf": "70D7444D-FAF2-498A-BDBA-229005389968",
    "Hachem_Laureen.pdf": "E03527BE-731D-41F4-83D8-30E0763093CB",
    "Hachmann_CV.pdf": "09F5A12E-001D-453A-A2DA-6D5246D3479E",
    "Hachmann_CV_2022_Neurosurgery.pdf": "2A0CDA92-DBCA-4E2E-8AE4-988543E1A4E2",
    "Hadjipanayis_Subramaniam_CNS Rec 2022.pdf": "23B288C9-EEA5-4FF4-BD9B-F8F0D6496743",
    "HadjipanayisUofPittsburgh_SNSProposalPDF_FNL.pdf": "958AFE65-43F0-433C-B880-2480DCC44683",
    "Hadley, Mark N.pdf": "0F84FDA3-959A-4A9E-8E79-5C7C848584BB",
    "Hadley, Mark.PDF": "16A6DBCD-CB3D-4CCD-B0C4-0B05EB0D54B1",
    "Hafizka Verification.pdf": "1D1FE5CE-530E-4FFF-A2AC-F27993A570D9",
    "Haft_Mark OMS III Good Standing Letter.pdf": "B91F2E02-6F76-4D13-BB3A-F3D51B6608B1",
    "Hage Stephanie.pdf": "B316B141-C345-4165-90A2-66E5B7D66718",
    "Haglund_LOS_Romaric LOR.pdf": "0BFA2418-3C4B-4E68-8C32-232E6031699F",
    "Haider, Ali.pdf": "31D68511-2AE8-4E3A-893C-AF4CED495423",
    "Haider_Sam_MD_CV_2024.pdf": "3F4CD5C6-B188-42AD-B3C5-473CBA68E833",
    "Haines, Stephen.pdf": "A68C6E79-ED73-4A09-8FB9-54CCD1AE6049",
    "Hale Andrew.pdf": "39F6B1C9-0E66-4A64-B560-59530B262F0D",
    "Hall John - Letter of enrollment and good standing.pdf": "F469163E-8CD7-48BA-BE29-9CF46AA16251",
    "Hamid_FekriAzgomi_ASSFN_2024_poster.pdf": "2E94BEF3-004A-42AD-8F61-54E2A51B65D7",
    "Hamilton Kojo - LOR - SNS - Shaffrey 9-28-2022.pdf": "93DEFBA7-2A6C-4607-B930-EFAC3A9E3AB2",
    "Hamilton Kojo - LOR - SNS C. Hadjipanayis  9-27-2022.pdf": "669D3EE4-EF1B-4F20-A068-9FAE25F430E2",
    "Hamilton Kojo - LOR - SNS Friedlander.pdf": "085270B1-2069-4199-AD0D-A9558C3A41A4",
    "HAMISH ALEXANDER CV.pdf": "1605C3CA-DD0A-447E-9B22-534F5DE9F9F1",
    "Hammad Ghanchi.emailverification.pdf": "8C9A6286-87BC-464A-BED9-A63D96A5AA57",
    "Hammer Academic Standing 2010.pdf": "CA9D3584-7587-458B-8517-917F623818A6",
    "Hammermeister et al (2009) MST in Military Healthcare.pdf": "4F38D0ED-C6B5-4EF4-9D04-1196670B1A53",
    "Hampton Andres CNS Membership letter 8_2_12.pdf": "F1901769-4C58-4CE3-B010-0FBE5E2A5D86",
    "Han Rachael_MD Letter_Standing.pdf": "59883A53-1A75-4FB7-B377-EC9A13BE5F82",
    "Han, Dae Hee.pdf": "255F7DA7-234A-41EF-A3D2-BBCA9A531818",
    "Han, Rowland_CNS_052714.pdf": "012D51B9-FE10-42F5-9A74-E28EE011ABC3",
    "Hana Hallak CV.pdf": "31E36D80-36DB-4CD8-86BD-EC5ED19EEA60",
    "HANAN ABSTRACT PDF2 .pdf": "9F6BD780-8261-4329-977B-871697F2937D",
    "Hani Tariq.pdf": "8455BB01-EF36-4E35-92D1-02E1C898F780",
    "Hanjani LOS for H. Woo 10.30.20.pdf": "F0D22A7C-90C3-480E-90C9-7729AA52B65D",
    "Hanna Algattas-REsverif.pdf": "1B60DC70-2AD2-46C9-A26D-36BC13E55B49",
    "Hanna Neurosurgery Residency Certificates 1.pdf": "B3F77151-6169-4AAB-8B84-60FD738D1051",
    "Hanna_Aliashkevich_-_Neurosurgeon1.pdf": "1C7791BF-CBA0-4144-8303-975B95430A4E",
    "Hanna_Aliashkevich_CV_2023.pdf": "54DA0423-3C4D-447A-BCF9-4EBD45A6BA49",
    "HannahChoi-RESverif.pdf": "AE8C51B6-CC27-425F-A34D-8F6069C6E81A",
    "HanRowland_CV.pdf": "83D8B726-2C6E-49FF-9AA2-B9FA6239466A",
    "Hans Baertsch Proof of enrollment.pdf": "C96B91C9-F9AD-4CE2-B2B3-4F83B7191286",
    "Hans Baertsch Registration.pdf": "84C5190A-2644-4658-A8CF-F1E9CD4F3D1F",
    "Hans Baertsch's Transcript.pdf": "50DF3A77-0103-4779-A628-AAB9D69ECF96",
    "Hanson Jacob - EV CNS.pdf": "1858CFE1-BC39-4986-A7A0-9FCB60E17F84",
    "Hanson Keith_Letter of Good Standing.pdf": "0130EFE9-A8AE-456C-B22F-9A6DF7F6FA1A",
    "Harake Edward - Letter of Good Standing 080823.pdf": "F20CA608-5BE9-4748-858C-373EDBBB1BF3",
    "Haranhalli Verification.pdf": "B3F8516D-A49A-490B-8687-CF57D5D2EB68",
    "Harbaugh, Robert E.pdf": "E1C68DE0-E32E-4DBD-A0C8-5EC02D58B582",
    "Harbaugh, Robert.PDF": "23A9EB58-012C-414B-A5C3-B05B1FB707F8",
    "Harbaugh.pdf": "B4BD49B6-7475-4D40-9D3C-3DFA161CA41B",
    "Harbaugh_MYO_SNSmembership_LoR.pdf": "75FC039F-9366-40B3-B63B-04D0A6F9C439",
    "Harbough, Robert.pdf": "1E564E02-CD18-4057-9062-5CA5CA7A9BAE",
    "hardship_exemption_fact_sheet.pdf": "2B90BF7D-8395-4C20-975A-E1D566A4610F",
    "Harhangi.pdf": "58D6CC34-4AEF-4117-BE84-9F14D712C11B",
    "Harjyot Toor-2ndFellowship.pdf": "8BCD82C0-C953-4A82-B3A9-FEA1A81BC329",
    "Harjyot Toor-FellowshipVerif.pdf": "84560A81-890C-45E0-BD7F-9EEC11C4AA0D",
    "Harkey, Louis.pdf": "78184ACE-4614-437B-A6B9-776408C66A89",
    "Harlyjoy_FWLNSchol_2021.pdf": "B0DB95AE-6C5F-45EB-8CB9-11CC92E99B2A",
    "Harneel Singh Saini Good Standing Letter 1 (1).pdf": "BC7497A6-7D1E-46D2-A75A-BE66F4524DAB",
    "Harsh, Griff.pdf": "DF881216-06FF-415B-8A93-87410C054239",
    "Harsh, Griffith R.pdf": "6354CAEC-C716-480C-BC01-CC48F17A2D3A",
    "Hart Collins verfi.pdf": "A4F65517-CD4D-4F82-9471-32B8EF7A68B0",
    "Hart Health Strategies Side-by-Side Comparison of the CY 2021 MPFS_FINAL.pdf": "EAEBEEB2-1381-44C6-97BA-247FEE9DACDC",
    "hart_health_strategies_2018_pfs_final_rule_side-by-side.pdf": "625AC348-5AF1-4C18-8B67-19EFAF5C7B91",
    "hart_health_strategies_2019_pfs_final_rule_side_by_side.final_.pdf": "A842C2F1-6358-4CFF-AEDD-23B40BB1F3AE",
    "hart_health_strategies_cy_2018_opps_asc_proposed_rule_summary.pdf": "ED8A0136-564A-48FE-A709-E38BF6418B12",
    "hart_health_strategies_cy_2019_opps-asc_final_and_proposed_rule_side-by-.pdf": "D5070369-5619-4DDE-80DB-CA72A4386B0A",
    "hart_health_strategies_cy_2019_opps_asc_proposed_rule_summary.pdf": "B609CBF4-ECFE-412D-B4B2-4941CED9085B",
    "hart_health_strategies_fy_2018_ipps_proposed_and_final_rule_comparison_c.pdf": "A76EA039-6D08-4345-9716-0E9A283DCB7F",
    "hart_health_strategies_fy_2019_ipps_proposed_and_final_rule_comparison_c.pdf": "B65FF45C-F99E-40C7-8C46-A8600985D1CF",
    "hart_health_strategies_macra_timeline_and_status_update_2.22.2017.pdf": "60859F18-D633-4064-B7FD-6633FD59CB44",
    "hart_health_strategies_macra_timeline_and_status_update_2.22.2017_0 2.pdf": "E3016640-47C3-4396-B675-9DC5B86E0463",
    "hart_health_strategies_macra_timeline_and_status_update_2.22.2017_0.pdf": "CDA90007-E390-49D1-9070-270E38D6B296",
    "hart_health_strategies_summary_of_ehr_rules.pdf": "5B357B01-BFD5-460A-915B-0756E2FA7B8D",
    "hart_health_strategies_summary_of_medicare_provider_enrollment_final_rule_december_2014.pdf": "EDB32894-64C8-4A9B-A6F3-40D58B88B608",
    "Hartke_FWLNSchol_2022.pdf": "4722F9E7-9413-450C-AF13-079C99F83ABF",
    "Hartnett CV_8.21.22.pdf": "1E410C6E-4DC9-4A76-B9AD-779E829FCB9D",
    "harvard ID.pdf": "5AD2C30D-604D-426E-B938-036807EF833A",
    "Hasan Syed Ashraf YALE Recommendation Letter.pdf": "D8E57DBD-1897-494C-B0AB-12B59966820E",
    "Hasen (Serletis letter).pdf": "8119D041-1D2A-4106-B367-B2E8348EB8F9",
    "Haskell-Mendoza Aden - Good Standing CNS.pdf": "C8B9A101-E39B-4A1E-BC02-D36E05C47B0E",
    "Hassam-Malani, Congress of Neuro Surg. 09.20.11.pdf": "B6D3563A-B9B6-45BC-8928-BDDFF79E3D26",
    "hassan saad 12_22_21 CV.pdf": "4966873D-811D-4F98-BD57-08565C1D5BD6",
    "Hassan, Omron DO.pdf": "FD28B7F4-0B3B-4046-ADB8-16BE0915BF7D",
    "Hassanzadeh_Letter of Good Standing.pdf": "F9E6191D-31C7-42FB-A72C-D6DE73C2BE94",
    "Hasseb Ahmad.pdf": "2AA101DE-C2B9-4472-9B83-6CB1062BE7EF",
    "Hatefi_Residency_Graduation_Certificate.pdf": "A52CB05C-00E7-4167-AFC5-DE7EA1C7F30C",
    "Hauser_1254.pdf": "45268C8C-77CC-46EB-8740-F66B2621ED5F",
    "Hauser_1274.pdf": "E715561E-AF1A-492E-B0D9-12F4BF299879",
    "Hauser_2425.pdf": "C8A8FAD7-1D04-49C3-BB54-CD153E2E49B3",
    "Hauser_340.pdf": "3BCB78EF-CCBF-41F6-BBCB-2778761B09FF",
    "Hauser_346.pdf": "6974FE0A-77EB-4581-931A-8D811A4D26B9",
    "HavanaTripNewsletter.pdf": "5F5FE894-5194-472A-9EAD-485997BF7554",
    "Havard-Elie-CV-10-23-2021.pdf": "0ADC0F6E-E129-44E1-B104-A05D6EA94078",
    "Hayden Gephart CV_070321.pdf": "1FDA33CF-8AC0-443B-9B0D-0A181FB9BB5B",
    "Hayden Gephart CV_100923.pdf": "F109786B-9688-4632-9CDD-A9DD82EB2F6B",
    "Hayden Scott Ltr goodstanding.pdf": "32CC4DDC-143D-4484-91F3-C3546A556B0F",
    "Haydn Hoffman CNS LoR 3.7.22.pdf": "34A1470C-EAE0-4ED3-98B9-769180DC93F1",
    "Haydn Hoffman LoR 1.26.22.pdf": "B6F37B9E-6448-422F-A028-AB75D1A80309",
    "Haydn Hoffman Recommendation Letter Publications resident fellowship 2020.pdf": "BCA873A8-F45F-43E5-B1F8-4547159548C5",
    "Haydn Hoffman-FellowVerif.pdf": "24BCFF26-31F7-4E2C-BDB2-03FB081E1CF6",
    "Hayley Cestafe PA-C Resume.pdf": "21DE5B2A-E169-4D37-9B1B-36ECB524E2C1",
    "Hayley Donaldson Letter of Enrollment 2021-22.pdf": "735C8318-5662-46C8-AF6F-3B32C2E05B97",
    "Hazama.pdf": "86DC09A9-9F29-4CCC-8634-470E856E5505",
    "Hazem Negm CNS.pdf": "BBE633DC-6090-41C0-BA0F-BB8E75FEEAA3",
    "HB-One-Pager-2020.pdf": "ACC3A240-90D9-4250-8E7F-F7973459AE63",
    "HB_CNS_poster_Nov2022.pdf": "31DFF7DC-9CDB-4B65-82F5-3B5E2B36C040",
    "HCIClinicalTrial_Poster.pdf": "A89E4B84-C83C-4553-B3AB-BD8C26F15BA2",
    "HCK.pdf": "A6F420BF-2AAB-405A-9769-106AFC7A3B47",
    "HCLA HELP Committee Statement Final 07.01.20.pdf": "A62B6A21-08CA-4C64-8B5B-5DC0D5AB38EA",
    "HCLA Letter - A3397 opp.pdf": "53DD879D-29DA-462A-82AF-B04CE133AE4D",
    "HCLA Letter - CPPA endorsement final.pdf": "C4E7B0F4-3A5E-4565-BDF8-E5D5DFEDACD8",
    "HCLA Letter - HR 7059.pdf": "19758C85-229E-4DE7-ABE2-4D3FEA48827E",
    "HCLA Letter re PAHPA Discussion Draft 2023.pdf": "FF7D5AD4-660B-4129-8078-098F718AAEC9",
    "HCLA Letter to House O&I Subcommittee on Future Pandemic Response 3-22 - final.pdf": "CA2ACF46-8667-45BD-9B62-B8DA1CB09845",
    "HCLA Letter to Hudson-Eshoo PAHPA Draft 2023.pdf": "8CEDF5C0-E004-41DD-AF52-451ADCC6AC54",
    "HCLA Letter to Senate HELP Committee on PREVENT Pandemics Act.pdf": "45223B33-FD29-4A01-BE0F-9BA25699D30E",
    "HCLA Letter to WSJ Editor 042214.pdf": "3ADBA9E6-411B-4A4F-AE34-45066B29BA4E",
    "HCLA opposition to Sec 138518 - final.pdf": "55E6AFC6-2BC3-4710-8571-921735C5F882",
    "HCLA RELEASE ACCESS ACT FINAL 2.pdf": "79B0DFDF-9456-415D-BDF2-AFA5EFFF0942",
    "HCLA Senate Judiciary Committee Statement May 2020-FINAL.pdf": "F10E3788-B5C8-4F4C-8C71-2BDA128AC6E9",
    "HCLA_117th Senate Good Sam Support Letter.pdf": "D82C91F8-E7D5-4279-953E-4967731BACBF",
    "hcla_good_samaritan_subcommittee_support_letter.pdf": "9F0D7117-AB62-47F8-A76D-D67B7E732DB4",
    "hcla_good_samaritan_support_letter_to_ec_re_pahpa.pdf": "6E0DE532-AD61-4210-B03A-90AEBE8CA179",
    "hcla_letter_to_rep._blackburn_gshpa_115th.pdf": "F7B037F1-1152-4127-90B0-1585FAF69A20",
    "hcla_letter_to_rep._scott_gshpa_115th.pdf": "FF249E5B-FF2F-450A-A07C-B49A72638C55",
    "hcla_letter_to_sen._cassidy_gshpa_115th.pdf": "CB17452E-C7D8-4C36-8736-9A1CB9F8ED7B",
    "hcla_letter_to_sen._king_gshpa_115th.pdf": "0B894A07-D9EB-4576-8397-837CEDB0D3A6",
    "hcla_letter_to_senate_help_committee_on_pahpa_draft.pdf": "9AC2173B-B1D3-48E9-B134-E814B0315F95",
    "hcla_letter_to_wsj_editor_042214.pdf": "D4C9AD60-8354-4940-8162-9357363B1CD6",
    "hcla_release_-_protecting_access_bill_final.pdf": "B37E5E22-F1DE-4395-A833-5A6AB4BA6CC3",
    "HCLALetterEndorsingStandardsofCareProtectionAct.PDF": "6D72BE69-974B-477A-95E3-2619DFDFDC31",
    "HCRTimeline30110Final.pdf": "9FBACCA6-FD2A-489B-B8D2-F692F36B4942",
    "HCRTimeline30110Final_0.pdf": "ADE82423-60AD-4377-B94D-54941178C031",
    "HCSAE Certificate.pdf": "91A2F1A6-51B6-4565-B1AC-48F84552054D",
    "HcxCNSPoster2.pdf": "354B1CB8-8310-4A61-9139-6D0A99AB8C06",
    "hdang_enrollment.pdf": "19652538-C9C6-456A-8C0E-53F27C19CDD5",
    "Head of Department Lor.pdf": "F4AE09D9-E65A-46DF-B163-F7B38B026C5F",
    "Headed Letter Holsgrove.pdf": "8346FC69-EFE7-4290-B0EA-F5C89D5313C3",
    "Heads Up.pdf": "A491049F-9AF8-40DD-95B8-8C12D98D280D",
    "Headshot - MJK.pdf": "7AF0A38E-EDA4-45F0-912A-46EFCFCCF99B",
    "HeadshotCNS.pdf": "943C2BB1-5B21-47DC-9142-4B0C8E9ACA8A",
    "Health Care Coalition Letter. 2% Sequester & PAYGO. March 12 2021.FINAL.pdf": "628D7E25-21E3-44B2-804B-C53AF2055564",
    "Health Care Policy - K. Orrico.pdf": "8B51FD76-6F7C-49C6-AAB4-29A798945FD8",
    "Health Care Workers on the Front Lines 032020.pdf": "343AFBA5-9420-498D-880A-35681442FC6B",
    "Health Disparities DRE Peds Section Poster 2022.pdf": "4D2211A4-5069-468A-8AE6-FE0F9E63F2E2",
    "health_act_support_to_goodlatte.pdf": "C72A4CFA-3BC9-416B-BEE0-9C8112B78290",
    "health_care_reform_survey_of_neurosurgical_leaders.pdf": "D0B9837F-F356-42B0-A0BD-3EEF1EA6D6DA",
    "Healthcare Coalition Support Letter for HR7819 S4525 .pdf": "9DBA9A1E-60D9-4E32-A935-3116B66D80CC",
    "Healthcare Stakeholders Coalition Ltr to CMS re Prior Authorization.pdf": "A0A09DAA-A384-438A-84AA-D1B2FB996ADB",
    "HealthcareReformNeurosurgerysPerspective.pdf": "76A1A350-29FC-4119-82B3-C940916E65F0",
    "HealthcareReformOverview.pdf": "701AD845-CF40-46EE-96C3-28348EB79D51",
    "HealthReformGrassrootsAlert3-16-10.pdf": "2EF6BE1A-2D6C-4998-B531-EC4032494696",
    "HealthReformNewsRelease91009FINAL.pdf": "2E7317FE-4B91-4F99-ABF1-DB5736A53424",
    "heat map shankar.pdf": "1426F930-CB2B-4D53-912F-D39CC87FD307",
    "Heather Grimaudo-Fellowship.pdf": "61CAC002-ED93-43E4-BE56-9AF0C533B0A3",
    "HeatherCitroCV_2018.pdf": "8416310D-EA82-42D6-9FBD-00DBB7F9A926",
    "Heba Mohamed Azouz (1).pdf": "A2D35469-7733-46C6-A499-2EC9914FC784",
    "Hebert Dana APP - NSGY.pdf": "C9730FF3-84C3-4335-B014-A7257EA4EAE3",
    "hect_cv.pdf": "5746229B-00AE-45C9-BEE0-8D51F1F6281C",
    "Hedaya Poster Final.pdf": "D26FBA02-AF85-4D6E-AA3D-17AEEB639939",
    "Heilman, Carl.pdf": "7F991473-E92E-42C8-9788-8E0D40C648C8",
    "Heinerman Allie- Anticipated Good Standing Letter.pdf": "0684E165-6E0D-4F37-BAA2-836B1EE932A2",
    "Heiss_John_2024PositionalMembershipApplication_CVandBiography.pdf": "10341855-0F22-4B4E-B770-2E9648AA368E",
    "Helen Shi Letter.pdf": "C2D28BF9-1523-4582-8FFB-0EC1AE386731",
    "Heller Verification.pdf": "334407DF-804C-4FC1-8E06-298C1DA4C102",
    "HelmetSafetyFigure1.pdf": "7F8A0C99-DFC2-4C13-B5AB-57C8FC55EB6D",
    "HelmetSafetyFigure2.pdf": "3CF71D3D-D82A-4ACF-BD34-A696893E8001",
    "HelmetSafetyFigure3.pdf": "0BA910CD-B86B-458B-9D5D-35164B568254",
    "HelmetSafetyFigure4.pdf": "732C8B86-632A-4A71-8BDC-5562540336E8",
    "Helmuth_CNS2023_Poster.pdf": "1FBA9488-7523-4220-93D5-549529192F49",
    "Helmuth_Enrollment_Verification_Report-combined.pdf": "C8BF8E97-23E0-4454-9B69-4B913C6703CA",
    "Helton_CNS_Hooker_Manuscript.pdf": "A2E945F5-597C-4405-8291-994FF6C2E51E",
    "Heman-Ackah Sabrina - CV May 20 2024.pdf": "F9DA8E0B-BBE8-4A2D-9523-9FA54CE0EB03",
    "Heman-Ackah Sabrina - CV May 2023.pdf": "14E5D4C4-FB6D-40DF-BF1E-CC22AF5292EE",
    "Hemostasis Poster.pdf": "DA79D484-AA45-4093-B937-92D54F80C677",
    "Henderson Residency Diploma.pdf": "32B8CD89-9DA7-4C78-A4AE-A22FFDA93831",
    "Hendrix board certification neurosurgery - translated.pdf": "EA21D1C7-DBC7-404A-8865-04B13511A96F",
    "Hengartner Astrid_Good Standing Letter.pdf": "2015B183-BF85-4A46-816F-2843E85C3E02",
    "Henry Ford Health.pdf": "CB7C03C9-5BF8-4EAA-899D-E4B6E67181E8",
    "Henry Ruiz CV.pdf": "59B3CFB8-C446-42F0-9BF8-829AB9E4C92E",
    "Henry Ruiz-Garcia CV_042024.pdf": "82A48DF6-F992-45D5-923A-5747B7A88B4B",
    "henrybrem v104.pdf": "09EC501E-CDB6-4865-9EF7-A5D58A94B706",
    "Henson Verification.pdf": "D885C681-BE96-430E-AD05-55265CA067A3",
    "Herman_Li_Letter of Good Standing 01112024.pdf": "9F0D9835-2244-47BB-B603-E19514FBA9CD",
    "Hermanto_Yulius_CV_2023.pdf": "75263F59-8DC9-4EC2-BFE6-095017E6D1A3",
    "Hernandez_ Mary Angeline Luz_CV_2023.pdf": "5CD6CD10-650B-49D2-87EB-60175E14F7FC",
    "Hernandez_verification letter.pdf": "A9B189FB-913E-4DC0-812C-86C96B701620",
    "Heros, Reberto C.pdf": "18C9F1D5-7700-45A3-BA4D-4C6F5AF8B8E3",
    "Heros, Roberto C.pdf": "5A118839-7BF5-4E46-A018-2D9E554C2FD7",
    "Heros, Roberto.PDF": "FD5F4362-CEEC-46DC-893F-A678FF6BA2F7",
    "Herrera Cano Genaro_Enrollment Verification.pdf": "B10858E6-D313-45F1-B613-5B00CB00CAEF",
    "Hersh_Letter_standing.pdf": "B7AEEACE-4405-49EE-87F3-BCEF5B21DD2F",
    "Hervey-Jumper Nomination Letter_Weinstein.pdf": "7B7B9F9D-D160-450C-9BB1-AC49509CFCAE",
    "Heth J CV 20231031.pdf": "C0DCA6CF-DE35-4D3E-BC59-BEC565A1DB60",
    "HF.PDF": "ECC28D80-A506-49E2-A99A-8C9D61AEA164",
    "HFRS GBM CNS Poster.pdf": "2E24247A-6325-4624-BED6-F453017C6D91",
    "HFRS Meningioma CNS Poster.pdf": "E4E0AADF-F6FB-494C-A4E5-8D84ACB0527F",
    "HFRS.pdf": "19D84A61-5A4D-4598-B6A3-8972698553C4",
    "HFRS_hemorrhage_table1.pdf": "64E11B22-4916-479B-9806-A9CEAB810079",
    "HFRS_hemorrhage_table2.pdf": "7402662F-D07B-459B-890C-2665EF0C82B0",
    "HFS QOL table 1.pdf": "DC4C957A-6977-4CB0-AC2E-A7D2C810EC58",
    "HFS QOL table 2 .pdf": "9B10DA8B-A32D-493C-B9D8-B6C7B130CB1A",
    "Hgb Poster.pptx (1).pdf": "13A4F856-950A-4EC7-B5E2-B3074D46A0A1",
    "HGoldstein.pdf": "4A52D218-6EDB-4475-BA36-F2DC066A302E",
    "HGSAH Rugters_CNS 2021.pdf": "25E3B3CA-137A-4866-B03A-520406216DCE",
    "HH SR.pdf": "3D83753B-1531-44E0-8A48-C78CC77FD903",
    "hh.pdf": "6E29BB09-7711-488E-AF8E-FCC2D936F66D",
    "HHS letter CARES Act emergency fund v4 (002).pdf": "CB587393-BA8D-4AB3-9D76-C86ABEA4B63F",
    "Hidden Markov Models during Deep Brain Stimulation Surgery for Localization of the Subthalamic Nucleus An Artificial Intelligence Systematic Review.pdf": "80E780A5-BA65-4D84-86C7-5F70943F8DE5",
    "HIFUThal_Poster.pdf": "D55BD3D0-3F30-414B-999C-15A458A73B99",
    "highlight_slides_from_fda_aans_cns_drugs_and_devices_committee_seminar_042617.pdf": "9DFF86FE-DE05-471D-B012-A47E37BE2E83",
    "Higuchi#CNS member recommend - ???.pdf": "05EE5EAF-12B1-464D-972C-51B154A96033",
    "Hill Student Status Letter.pdf": "264F0417-A364-421F-B42B-434CE9093412",
    "Hilliard.BHoh LOS 2.9.21.pdf": "93F02F9D-CF82-4941-AA6C-B94ACF40BE15",
    "Himel, Sean CV.pdf": "2E1B6B22-B2F3-477C-BBC2-B99FC03B4994",
    "Himes CV 12-23.pdf": "4EAE425A-ACF9-4D32-A384-F2A1EBAC9497",
    "Hines 2024 ASSFN_ACP DBS.pdf": "2A31E47E-E236-447E-93A5-CB2B04F6419D",
    "Hippocampal Dominance-Table 1.pdf": "BA16B1D5-4A7A-4C30-9022-D89D0EA41B74",
    "Hippocampal Sparing during Whole Brain Radiotherapy and Preservation of Neurocognitive Function for Brain Metastasis A Systematic Review.pdf": "9EDBE23F-D206-4CA2-9278-C575042194F5",
    "Histological Analysis of Intracranial Cerebral Arteries for Elastin Thickness_NZM_CVSection2018.pdf": "8596C158-EA79-4544-B020-20934095BE4A",
    "Historial Academico - Portal de Alumnos - UABC.pdf": "E0F52405-4DC0-4A3D-82B0-DD29704CA701",
    "Historial para impresión - Portal de Alumnos - UABC.pdf": "DA4C795F-17B5-453F-9BE6-AC5974FC0AD2",
    "Histórico Escolar-JonathanLIma-LOGS.pdf": "31D063D4-9406-45FA-97A1-6B524A8E5294",
    "History of DBS figures.pdf": "21F83460-CFDB-4F3F-8324-16B38B31315F",
    "Hitti Residency Diplomas.pdf": "1A9C57A5-6C49-4216-93E9-69656040934F",
    "HKemeny 09122018.pdf": "33084622-824A-4AB6-9CF9-740E9192C9BA",
    "HKNS  society letter.pdf": "0C7A5FEA-52F9-4D3E-BBF5-5A1F0B0F4841",
    "HKNS Cert.pdf": "16A691AC-F802-4BA6-80C5-20EA0BF665FC",
    "HKNS_-_Membership_Proof_Letter.pdf": "BEFD5478-84B4-4049-92D0-97A7D4B85336",
    "HLB 2 page CV.pdf": "FFFFB938-B782-4B33-8D5F-C010A6892145",
    "HM Research Project.pdf": "BFA20F57-867E-4BE6-A386-270C7D8DD246",
    "HMA Letter of good standing.pdf": "42A1B5A5-0FF2-4E77-87AC-D32594B71812",
    "HMEMA_AANSPeds_Poster.pdf": "4AFC4B8A-73E4-4EAC-9EFA-0B2364A83DA2",
    "HMS CV Martina Stippler February 2024 .pdf": "7BB4863D-ECDF-4F05-A90E-7AC2C2EF8208",
    "HMS CV Martina Stippler November 2023_1 .pdf": "CABD46DE-1F9F-4EFD-9A31-322606BF0AE8",
    "HMS Enrollment.pdf": "B16E2D1C-D0C5-44E5-BB21-DB18AF6127BB",
    "hms unofficial transcript.pdf": "ECB6E395-B963-4066-A9F3-A495BCB402BB",
    "HMS_Transcript.pdf": "BC615646-1B4C-4A3D-B1D0-491E7D7BF56D",
    "HNP.pdf": "5B3A71A8-4122-4CD3-8AEA-5F17003BD238",
    "HNSS Membership Certicate.pdf": "BF77CB89-530F-424E-9E28-4058CA524CEC",
    "HNSS Membership Certificate.pdf": "E7DEBA01-32C0-49E6-A123-A7A2F6D8058D",
    "Hoang, Kimberly - CNS.pdf": "720A57FA-C447-4EE1-B510-1F8F575A309A",
    "Hochberg-MGH-CV-May2023.pdf": "FDA8F5CA-CA23-4F84-9371-24CDC5C44594",
    "Hockman Jakob_Good Standing Ltr_9-03-20.pdf": "ECA3F207-C278-4955-9976-E497D80C9264",
    "HOD letter head for CNS.pdf": "D17708E2-AAEE-4BC3-9370-E07C65BEA9B2",
    "HOD Reference Letter for Harvard Observership- Dr Uche Enoch.pdf": "FA5D7CF8-FE08-4BE4-9FE0-25611388E856",
    "HOD Reference Letter for Harvard Observership- Prof. Uche Enoch.pdf": "4AA2F3ED-C541-44EB-B65C-38D1BBCD35D9",
    "Hodaie SNS Nomination Letter_Fehlings.pdf": "833DF5C6-34E0-4A80-BD02-1C57B77A2472",
    "Hoffer_CNSAnticoagulation2024.pdf": "FFCE8A58-9B98-4497-8F1D-ECFD2BB4D8BA",
    "Hoffman Alexander FCD development poster Peds section 2022 copy reduced.pdf": "C1F89F13-217D-4033-BF49-D5300071DC7B",
    "Hoffman CV 2.0.pdf": "99EAC499-6983-4646-8830-9AE90E2DBE1D",
    "Hoffman CV.pdf": "A4108AEA-D816-4C82-9DEF-0219BD925B69",
    "Hoffman NS publications resident fellowship recommendation 2.3.2022.pdf": "171B35FC-FD85-453E-918D-3371D51A542A",
    "Hofler CV.pdf": "BD66E5CD-92CE-4F0D-9467-590D6E203008",
    "Hofler.pdf": "E92553CD-1EFC-47A7-8EB8-D7FD45746AC5",
    "Hoh Dan LoS SNS membership MPS 10-07-22.pdf": "CE68664E-31A7-46E8-9BFD-5D0E26C82359",
    "Hoh Daniel SNS LOR SK 2022.10.04.pdf": "98DF19D5-1171-419C-AB18-7D4938841092",
    "Hoh Letter CNS Publications Fellow.pdf": "DC9A5845-8E05-4F4A-B2FB-95F605CD3DF8",
    "Hoja de vida dr jara.pdf": "B3934112-BBD4-4A8B-AEA6-2C4013D34FEE",
    "hojaDeInscripción.pdf": "F047BEA1-5063-42D7-B4F3-D2FA70E5C316",
    "Holder Resume1.pdf": "F8B2B7FF-E4DE-449F-9C13-7883858D9A59",
    "Holland CV 1.24.22.pdf": "C5F550AC-40D4-4E12-B6B5-08D1C5CFB2F0",
    "Holmes Tremor Poster 2024.pdf": "0F44AE58-7EA6-4FBB-87E9-0A241B26FBB7",
    "Holsapple_SNSLOR.pdf": "7ACE89F7-D600-45F8-ABE6-963EAF16684A",
    "Holste Katherine ATA and IVH.pdf": "A9B63CA0-1692-4436-AB6C-E936C4974D27",
    "Holste Peds Section Poster.pdf": "FF95DF27-0469-4ECF-A59A-500F37E4AC4F",
    "Holste-NPRFA LOR-January 2022.pdf": "3C7ACB90-D6E0-4FF3-8026-EF8FE6F17C27",
    "Hong CV.pdf": "8E27984B-772B-402A-A2F2-5975162D1710",
    "Hongo, Kazuhiro.pdf": "70612426-FB69-4D3A-8E59-33E47FCAB49E",
    "Hongsermeier_Graves_Natasha_1.pdf": "4F547AB8-1765-4887-958C-940DC357411B",
    "Honorary contract .pdf": "802B0D81-483B-4375-9F34-4BD3BCF50F1C",
    "Honzel Brooke  LoG - Congress of Neurological Surgeons - 10-18-2324.pdf": "8D208D4E-030B-439D-BB03-CBE1F3F457CB",
    "Hoon Choi 2018 Dues Invoice.pdf": "F7F0B82F-6ED2-4F8B-B029-2203DC43BF03",
    "Hopkins Lauzier letter of good standing.pdf": "1E7872C8-8990-4AF2-8998-2AEAD6E1EF96",
    "Hopkins, Nelson.pdf": "26395CCC-7917-4FD1-A1FC-DB5682E02BD2",
    "Hopkins, Nick.pdf": "1E4C622B-5260-47CF-A54E-3777F5A112A4",
    "Horario CNS.pdf": "A6A6B858-96DF-4BAF-A2CA-3E16C78E6E0A",
    "Horn Alexander.pdf": "388E1C2C-C68C-4FD1-AA54-04FBA7CAC266",
    "Horowitz CSNS LOR_ DM44.pdf": "A5C60C2A-18F5-448E-99D4-13D6672B5F0D",
    "Horowitz CSNS LOR_DM.pdf": "C181035C-D0F8-495B-8E22-552B229A3E1A",
    "Horowitz_CNS LOR_HB.pdf": "2FF40060-F4FD-4651-A98A-8A7CAF0C2889",
    "Horowitz_CNSLOR_MR.pdf": "4E8B2C54-A7F6-4B17-BEDF-08FC53D4278F",
    "Horowitz_Jneuroonc1.pdf": "4E340515-5D6A-4FD5-B4C5-5C61EF5915AA",
    "Horowitz_Neurosurgery1.pdf": "DA8D223D-1A79-46E1-94C8-9553C1E3B790",
    "Horowitz_World1.pdf": "6104DAC7-5BFA-4F0E-A7AB-4F7790A759B2",
    "Hosein_Khadil.pdf": "6AFDF05B-9098-4D22-BC80-BEC7436C736A",
    "Hosp_ICU_Vent_wo.pdf": "4FC0FE09-4A05-40B3-A6AA-D00CAB31F413",
    "Hospital Bed Size and Healthcare Resource Utilization following Spine Surgery for AIS- Peds Joint Section 2022 Poster.pdf": "E1B318AC-54DF-4655-92E6-A76BDB2B6DF4",
    "Hospital Transfer in TLF_CNS Poster KMT 072023[23]  -  Read-Only.pdf": "0A3F68CF-1BAB-4DE8-A040-102783065ADF",
    "Houri_verfication.pdf": "5D04B829-5F94-4504-857E-D2A283BA2C6D",
    "House GMAC Sign on_Resident Physician Shortage Reduction Act of 2021_FINAL.pdf": "CE2AD425-3D91-4195-85E8-99418188A446",
    "House GMEAC Sign on_Resident Physician Shortage Reduction Act of 2023_FINAL.pdf": "CB0AFD93-0B5F-4C0B-A352-01F4DE58D6A2",
    "House Prior Auth Letter 06.21.23.pdf": "3FE8ACEF-42F5-416C-804B-15E89501FA1E",
    "house_mu_sign_on.pdf": "B2D04A3F-EDAD-4FE2-86CB-369231774D7E",
    "house_passes_ipab_11.2.17.pdf": "B22583D9-B191-4D30-B298-477C097BAC45",
    "Housepian, Ed.PDF": "3A116614-5CDD-4C75-9447-4AC1367F02C2",
    "Housepian, Edgar.pdf": "0FC40809-45D9-4683-AA16-20799B053BE0",
    "how to improve performance wellness.pdf": "CA0ED53D-B141-4AB6-8980-7E85A77FB2E8",
    "How to Purchase a SANS Subscription.v2.pdf": "B0DBDEBC-01B5-4F98-8063-83438AD87B19",
    "How to Purchase a SANS Subscription.v3.pdf": "E321E0BF-5C1A-4A50-84B2-C464841C359E",
    "How to Purchase a SANS Subscription.v4.pdf": "A70C1AFC-E741-41C7-A2AA-3A95EE4454CE",
    "How to Purchase a SANS Subscription.v5.pdf": "7E472D8A-8FD4-468D-A15A-45A151C393A1",
    "How to Purchase a SANS Subscription.v6.pdf": "16269054-97C0-484B-A48A-E630A85D2B17",
    "How to Purchase a SANS Subscription.v7.pdf": "436B9175-4C92-42C4-AC12-95991559A3EE",
    "How to Purchase a SANS Subscription.v8.pdf": "60D0C8C9-5A99-4845-943B-50A81AEC1494",
    "How to Purchase the Course on behalf of Your Resident.pdf": "5D8508E4-B9D7-4472-AF45-A968757A96F7",
    "How to Register on Behalf of a Resident for the Data Science Course.pdf": "63D4C646-1E59-4818-BE8A-C8B0CAA95946",
    "How to Register on Behalf of a Resident for the SANS Written Board Review 07252023.pdf": "444E2625-C7A6-42DE-BA9D-AA6F4A8AC670",
    "How to Register on Behalf of a Resident for the Written Boards Course 2023.pdf": "D03FA2C5-F1EF-4DD3-875E-379E31F627E4",
    "How to Register on Behalf of a Resident for the Written Boards Course.pdf": "5657432D-AC21-4B27-81CE-4E9C14FC11B5",
    "How to Register on Behalf of a Resident for the Written Boards Course_011122.pdf": "A745611E-F936-40FE-BAD6-5960A8E011F6",
    "Howshar.pdf": "099D81B7-D661-475F-BB5E-775FD38D3FBA",
    "HPC CNS Poster.pdf": "BF16364F-9CB3-42FA-AFBE-8CBEA79D0770",
    "HPC POSTER CNS 2023.pdf": "2FC314C7-35B1-41DD-9895-649317B0A121",
    "HPI OSU Effects of the Nurse Athlete Program on the Healthy Lifestyle Behaviors Physical Health and Mental Well-being of New Graduate Nurses.pdf": "70273D3A-1782-49F7-9C9C-F0B9C183E1E7",
    "HPI Power of Resilience White Paper.pdf": "C9CFABF0-8F21-4FBF-9B9B-AACCD5202AB4",
    "HPI-Life Benefits of Managing Energy White Paper.pdf": "092432D1-D75A-4A1F-86CF-D59DD9AF9577",
    "HPI-Performance Randomized Control Study.pdf": "32F2794A-0717-4AF4-ACE9-0ECBF30E06FE",
    "HPO clustering figure.pdf": "4300BB8D-687F-48BC-9301-4184D325745A",
    "HR 2474 AMA Federation Letter of Support 4.19.23.pdf": "252E9B2F-5E0F-464E-87F7-8BCCF7CC4E71",
    "HR 8800 Coalition Letter FINAL.pdf": "A9EEE97E-12A6-4138-89BC-0DC279E13018",
    "HR letter.pdf": "EFF27D19-0177-4EE1-BA54-1397D69CCB1A",
    "HR trainee confirmation.pdf": "50E430E7-4BDE-4921-9BED-727D07B78F95",
    "hr_1859_-_s_2782_coalition_endorsement_letter.pdf": "1AA3427A-2990-49D2-9791-9D3729782CD9",
    "hr_548_support_letter_ryan.pdf": "EEF0725E-0A20-4D30-B05F-A89BD31CCD01",
    "hr_6082_final_package_letter_updated.pdf": "A1890363-A6DB-4B18-A542-44C55D30E346",
    "HR3962NewsRelease110509.pdf": "D9C5E8C3-6BFF-4A61-8D1B-AFCCF0841A9D",
    "Hradec for CNS.pdf": "D33FF309-B0FA-4FC9-AD4B-6771FF97351B",
    "HRs.pdf": "D8EB54B3-EC68-494D-A363-87B64EE36447",
    "hrs_principles_-_final.pdf": "891F213E-6E60-4466-B18F-0A19B7288167",
    "HS_LoGS.pdf": "C5F9C709-0A87-4C95-8F9C-DF0FFF22E1FB",
    "HST_Appointment Ltr_NS_Jul 2018_TMH.pdf": "20FBDB04-366A-4C8A-A637-84BD8CE8D964",
    "Hsu A Letter .pdf": "7ED954F1-85C2-432E-B128-189D914ECAD3",
    "Hsueh.7.16.19 (1).pdf": "BB6FBFCB-B406-4770-99BA-337AD958BAB6",
    "HTML Tag Reference.pdf": "41187062-2D7B-4A7F-AAB5-CDCC8554CB9A",
    "HTN encephalopathy poster.pdf": "330E15AC-F7A7-4BA3-B2AC-8BF92BC4BA86",
    "HU and PJK_PJF Tables.pdf": "943A64DD-A2B5-48B5-BB5B-23A2627B5C72",
    "Hu James_Enrollment Form.pdf": "6DE1F1F3-1E45-46C0-B41D-E9F30288B8DD",
    "HU&LLIF tables.pdf": "A89BA5D6-2355-4B4F-A7D1-E2534951106E",
    "Huang Academy 2020 Theodore.pdf": "D3AFAF44-78DD-49B6-B440-407E7E57435F",
    "Huang Academy LOR Tamargo.pdf": "5BAACC66-C9BE-4E36-B626-3633B32CC4BC",
    "Huang CNS Data Science Fellowship LOR.pdf": "C66ACB11-BE9E-476B-A4B8-DC01A8D9FD2E",
    "Huang CV Feb 5 2020.pdf": "1094E1D2-8CC6-4ABD-BAE0-E5F3E5CF3482",
    "Huang CV Jan 21 2021.pdf": "A87F0E49-76B4-4DB7-B797-8066DF924EB0",
    "Huang.pdf": "13D49D03-D0EF-45D3-977B-695F645B7870",
    "Huang_AANS sponsorship_Brem.pdf": "C1CB9460-8ED8-465B-82F9-3144BB6F91B2",
    "Huang_CV_CNS.pdf": "28517019-C36B-49CE-9FD8-C8DD77C013D9",
    "HUCM decison status letter.pdf": "CE65942E-DDE6-496E-8B36-7683BB14E285",
    "Hudson CV 2021.pdf": "18C37922-04D1-42EF-B4D7-418E74D89D91",
    "Hudson, Alan.pdf": "EB1D7E0E-F774-4CB7-84CA-25675406A263",
    "Hugh Bartlett Letter of Enrollment 2021-22.pdf": "DE45E340-7DED-4B27-96D6-67D56CF79E39",
    "Hughes LOGS Neuro R16.pdf": "667897D7-895C-4195-B1AE-2319C645ABC9",
    "Hughes Tyler  - Enrollment Verification (1).pdf": "5FEB116C-6CB3-42F4-B5D4-B5547F124A5C",
    "Huguenard 32154.pdf": "DCF4AA41-3C35-4E01-A29D-5D274FFF8BB2",
    "Huie, David LGS AANS.pdf": "063FF766-7A16-43D8-947D-C09C3411081D",
    "Hunsaker Joshua.pdf": "76362C60-7F6D-44C1-9972-42B04D181803",
    "Hussain CV.pdf": "25397FF5-20EE-47FF-A942-575612ABDA98",
    "Hussain, Ibrahim-CV1.pdf": "472EA347-6353-4F0D-9E74-0BD5C76ECB7C",
    "HV Juan Carlos Gomez Vega.pdf": "F91C8C13-D02D-4E3A-AFC2-0C029611FB00",
    "HW CNS Verification.pdf": "2775B444-96C8-4498-9B72-389D10443CFD",
    "HWP2024CV.pdf": "3710E3ED-7FFE-40E8-A0ED-2D3D16C87ABE",
    "hybrid_corp_CNS_fig1.pdf": "791D6F68-72CE-4444-AFF7-10857B9F67CC",
    "hybrid_corp_CNS_table1.pdf": "C3F96A08-E49E-43A8-A36B-8D1AF1ECC8A0",
    "hybrid_corp_CNS_table2.pdf": "DB49B41E-0397-4AB6-9CD3-FDB28A7FA390",
    "hybrid_corp_CNS_table3.pdf": "D252FD03-45F0-4044-93B8-73D0F1DB2114",
    "Hypernatremia TBI.pdf": "B2126FEA-00C7-402B-805C-90DE587B1EFB",
    "Hyperoxia CNS_JF Edits_JT.pdf": "43173B9D-AAE6-4945-894E-43B821E01A9C",
    "Hyperplasia cases table.pdf": "B4AFB0B7-348B-4A84-B198-810331D3691C",
    "Hyperplasia-adenoma figure 2.pdf": "23EF7E46-1E24-4239-9327-3A15B4229A25",
    "Hyperplasia-adenoma figure 3.pdf": "ABC15BE0-CF89-42C7-859D-B7A7DF842ED6",
    "Hypothermia Position Statement Oct 2013.pdf": "02F8C86F-CC8E-498C-9E32-F7A0C670353F",
    "Hypothyroid in Deformity Abstract_CNS_Tables 1-5.pdf": "BD14A04A-8150-4721-88A7-8721DE93A122",
    "HYUN JOO PARK- Residency_certificate.pdf": "61F9D28A-8049-4BB8-9DCB-5FB860F8A57B",
    "I CAN ACT 118th Congress Opposition Letter 6 1 23 v2.pdf": "7874BFE3-5E66-4CA9-9937-63D2A17EDA42",
    "I.Jusue Torres.email.verification.pdf": "01026B99-233C-48F7-8E61-A5344C37AF65",
    "IABNewAdPortfolio_FINAL_2017.pdf": "48405D2C-5817-4DA9-9483-E3CEB4716E99",
    "Iacoangeli_Alessio_CV.pdf": "F0FF230A-A8C7-4ED4-95DA-59A7F6C8FCAD",
    "Iahn Cajigas CV_2022_04_09.pdf": "D91C028B-C0A1-4B44-8915-5D0895EE9836",
    "Ian Kainoa White MD CV  (1).pdf": "2CB167C0-AAC2-422C-AC97-01E7CF417054",
    "Ian Lee CV 20200303.pdf": "F15B0901-BCB2-452C-BDEA-1F0812F0F4A8",
    "Ian Lee CV 20200316.pdf": "A539F118-3A84-47EA-AC6A-F47A6AD7A6C4",
    "Ian Mandybur_AttestationLetter.pdf": "2B13148E-B601-48D4-B93E-46143D95F0B5",
    "Ian McCutcheon Nomination Letter.pdf": "E81927C0-8E33-4F6A-A4CA-BD39027F968B",
    "Ian McCutcheon Recommendation Letter.pdf": "B07A3DD4-5A2C-4FF5-BB74-DECDD84AD53E",
    "Ian McCutcheon Sponsor Letter.pdf": "2EBA9D99-4A1F-430B-9850-7A48E197D571",
    "Ian T. McNeill CV_221208.pdf": "3D09CD00-2270-42F4-AFA2-F549E3C74BC2",
    "IanFDunn12.23CV .pdf": "B12F4078-1FAC-4452-A1DC-ECF6D897DD6C",
    "iApply CWRU School of Medicine Message.pdf": "78A62049-AB43-4F09-9151-5A84AF36F21F",
    "Icahn School of Medicine at Mount Sinai - Mascitelli Sarkiss Steinberger.pdf": "C16A288F-8A32-4C85-B65C-04CDE3EE5901",
    "Icahn SOM Matriculation - J P Hall.pdf": "108EE3A0-D781-4BE4-BD80-93788986A0FC",
    "IcahnSOMAcceptance.pdf": "9FD06100-2E64-4CEF-B130-553F3AF9BFEF",
    "icd-10-sign-on-letter.pdf": "70B1D643-8956-4B6E-87CD-8E8EED246824",
    "ICD-10Sign-onLetterFINAL.pdf": "EB431D6F-9AD3-44EB-A770-38AB204731AD",
    "ICG Poster.pdf": "9A194F73-A200-4E52-A1EA-72ABF8552CE6",
    "ICG proposal dec 2021.pdf": "22EFEBC0-9F8D-4DC1-8492-3BFCF505671E",
    "ICH Poster for CNS.pdf": "DF3D6E48-5671-4D37-82F2-993A870D1695",
    "ICH.pdf": "B451C5E0-43FE-4858-A42F-2D4EDBA54B30",
    "IcI_Poster_2.pdf": "4136DA03-F471-4EDB-B87B-DA53C14C1BB6",
    "ICM 2 PBL 1.pdf": "76B24A0F-E042-44DD-9446-A8E8AEBBB3D3",
    "ICP management paper_session 7.pdf": "610A655C-A587-45EC-B897-9C90E4026F62",
    "ICU Management.pdf": "ED17BA2F-15C8-4FFB-BDFF-289547F46933",
    "id card.pdf": "0561AE61-D52C-4A1B-8768-36EB725EF6B7",
    "ID Carriage in Spine Surgery - Table 1.pdf": "7AF66AA7-14CC-44FE-85DD-BB373764D576",
    "ID Carriage in Spine Surgery - Table 2.pdf": "8EB039CF-BF7A-4244-8CAE-2C24AD4A7EDE",
    "ID Carriage in Spine Surgery - Table 3.pdf": "53AEFE44-EA46-4514-979B-15C63E8B7A44",
    "ID_1279_Boaro.pdf": "D1E77537-B153-4E0A-8740-B52ED7F7AE34",
    "Identification of heterogeneous subtypes and a prognostic model for gliomas based on mitochondrial dysfunction and oxidative stress-related genes.pdf": "31F0E3EB-8F2B-4A20-8BF3-393039A447CF",
    "Identify sural nerve..pdf": "0E19E705-8158-4DC2-9711-305354AECD2E",
    "Idlett-Ali Shaquia 109797333 Academci Attendance Verification.pdf": "09C0CBFF-10C1-4952-89CA-0A21BC71F4E3",
    "Ido Strauss CV.pdf": "FF70D18F-745A-4F50-9076-540539079AD5",
    "IFAANS letter.pdf": "E80D58F3-F919-45C1-B951-695323AAD142",
    "IFayed_Verification.email.pdf": "9692892B-3376-4413-8F1C-6F3B97AE419D",
    "IGM Verification Letter.pdf": "4D3A13ED-25F3-438E-B1B6-7D7306E15168",
    "Ignacio Jusue Torres-RESverif.pdf": "9180E40D-C243-4CBA-88DE-4158B4AEDF69",
    "IHEANYI J Amadi CV_2.pdf": "1E4DF8F8-E729-4871-BCAC-FBBEBAC43ED3",
    "Ihezie_ Stephanie Dean's letter.pdf": "C63A8A2B-636F-4204-A150-DD675CEDD4B9",
    "Ihika Rampalli - Enrollment Verification Letter.pdf": "B68A4C70-91E9-4AC3-BB83-1BCF86FDFDD1",
    "IkaasaSuri_GoodAcademicStanding 1.pdf": "1C059911-9F22-48FB-A4F1-1CC47E870AEA",
    "IKhan.emailverifcation.pdf": "91333AEB-AA0B-4C19-AE5B-99DDA488148C",
    "IL-W-4.pdf": "7CAA8A56-D35F-48EF-93EB-77FEA545C3EB",
    "Illinois.pdf": "28170333-3F52-47A1-96A9-DF8C6CB4A1DB",
    "ilovepdf_merged.pdf": "3BFFC381-756C-48DE-B4CB-DEDBF21E85F6",
    "Ilse Fuentes Virgen-RES.Verif.pdf": "3E573A5F-A1A9-4D70-AD33-BC11A7947F27",
    "Im gsl.pdf": "BAFE5AF3-279D-48FA-A2C3-A30E7FA1FB68",
    "IMA Priyank.pdf": "70F0EFC7-2365-4C5E-A979-DA6F7345FC48",
    "IMA Residancy Shofty.pdf": "FFEB6DDB-7A5F-4815-BA03-8CAF88957788",
    "Imad Khan-RES.verif.pdf": "B14ED998-1759-4A51-8F8F-CB555783C243",
    "image (3 files merged).pdf": "7842C551-17DA-4C5F-AFA6-FD121AB39387",
    "Image 1 CNS 2020.pdf": "28FD28E9-2405-46AE-8D2E-ED74B564AA1D",
    "image 1.pdf": "1C67B8FF-0BCA-4A58-B8A0-2409343C4C3F",
    "Image 2 CNS 2020.pdf": "1A22AFCC-1219-492E-B05C-464BE144FA96",
    "Image 2.pdf": "6A99AFA8-A869-400D-8192-03E767CF3C5A",
    "Image 3 CNS 2020.pdf": "E6DE9314-BE9B-47F3-91BE-B72A86FB70F8",
    "Image 3.pdf": "5788EB15-6A8F-44B2-B6FF-07C630B1FB19",
    "Image 4 CNS 2020.pdf": "57DD7B1A-0850-43DD-B806-1A33617E646E",
    "Image 4.pdf": "A6916791-604F-48AE-954B-15E0EAEDB1B8",
    "Image 5 CNS 2020.pdf": "75A1D0D9-DABB-4336-ACDF-2FD75958F7DC",
    "Image 5.pdf": "03004F2B-2B06-41DD-8516-5A13183B3AC1",
    "Image B-A.pdf": "6E93D033-5542-4666-9C8E-D1B22457ECDF",
    "image_55415491-converted.pdf": "7A22373B-F2A9-4EF4-84C2-04362BA20DF4",
    "image_for_website_ad.pdf": "B3B774E2-A374-4420-9C72-B02B0BD9BBE6",
    "IMAGE0062.pdf": "7C092373-E2B5-49DB-9BD9-2052332BD4A7",
    "Image1.pdf": "9FED2D38-D605-40A6-BD87-0D5E6FB70E9C",
    "Image2.pdf": "363698F6-2F38-4354-8595-774316D34222",
    "image3.pdf": "FF847EC7-89D6-4B0F-8C45-2A09BE767DF1",
    "Images for abstract.pdf": "AF4C7137-BD50-45A7-B475-938BB435569F",
    "images.pdf": "F36D2B26-D605-42F4-BC9A-EFFB43251EDC",
    "Img 1.pdf": "D6F4EBAC-477F-422F-B936-75F51FEFF553",
    "Img 2.pdf": "3DB3D4D4-906E-48CD-B04F-96BDD533B641",
    "Img 3.pdf": "3063091F-2EFC-4E49-88BD-F06F15A1AD0D",
    "Img 4.pdf": "653D011B-AC2D-43D4-A1FC-10F723C46538",
    "IMG-20151117-WA0015.pdf": "6A2D0611-B367-46F0-A79D-3449336A8872",
    "IMG-2337.pdf": "52DCA544-1E22-4477-9B03-8672F95C62BA",
    "img-801162618-0001.pdf": "E3274012-ADE1-4D8D-900D-4545D265FFB8",
    "IMG.pdf": "39EA7A3D-B160-4CFA-AC6B-7FA6A0C1A335",
    "IMG_0001.pdf": "ABA7412B-C105-4417-9BF9-1C5DF3181043",
    "IMG_0137.pdf": "4C4A6A4D-FF6C-443C-9CDD-56B789B9D1A9",
    "IMG_1100.pdf": "533D3A2A-6CE6-4AC0-8769-5608AD508092",
    "IMG_1347-compressed.pdf": "950AF9A0-0CB5-43BE-B88B-726867962FFF",
    "IMG_1347.pdf": "1C778B90-58C2-4C6A-81AA-357532569E50",
    "IMG_1574.pdf": "26ABEF75-660B-42B1-9C9A-1E97AC2EC76F",
    "IMG_1956.pdf": "EE9A976E-2DC1-4963-AF75-A1C7BA1A37D6",
    "IMG_20140108_0001.pdf": "1C205EE6-8CC2-4BAE-A843-E3E0A1F99E88",
    "IMG_20170622_0001.pdf": "1A4974A2-5986-446D-B49C-F53D048EB4AB",
    "IMG_20211124_103343-converted.pdf": "3C1299AF-7AA5-4CC4-9FC4-FEFF770B52C8",
    "IMG_2371.pdf": "4808446B-2049-4891-B9AA-683967B9EA39",
    "IMG_2694.pdf": "50C806BE-2079-4D0B-9160-01EA3B8E85F0",
    "IMG_4140.HEIC.pdf": "F72F2F37-06FA-4140-9F84-9B117962AA66",
    "IMG_4631.pdf": "D24ED32A-2309-4A49-BE8E-038587F0294D",
    "IMG_6793.pdf": "DEE9F7CB-4633-4B56-AF44-21EBD768829A",
    "IMG_8760 (1).pdf": "4314E8C3-C571-4C69-B49E-69CC2DDA2B1E",
    "IMG_9313.pdf": "C55E945E-D54C-453D-92E5-F7338B46D1C0",
    "img002.pdf": "1A6A3160-8B3E-4A74-868B-1729778B471C",
    "img003.pdf": "C05EF7AA-DE2B-4455-9DE0-E15B4A0C3E74",
    "img02897[1].pdf": "7DA1B7AB-815D-4AC2-B681-7D16F419241D",
    "img02898[1].pdf": "2ADB2CF4-E744-4F7A-8F75-AB3673E3E952",
    "img03325.pdf": "0F07297C-251C-47DF-99F5-3F26AAAF1F90",
    "Immatrikulation_englisch_18.08.2022.pdf": "EF26B9C8-6FA3-433E-9A45-DF7AD78A1A2A",
    "Immatrikulationsbescheinigung Certificate of Enrollment.pdf": "5BFE59D1-5A9E-4842-88B1-802EE567ADD0",
    "Immatrikulationsbescheinigung englisch PDF-1.pdf": "8CEFBB58-4967-45FF-AE2D-F22A29466B55",
    "ImmunoMen_Poster.pdf": "EA33F45C-3053-4136-9707-B9446DC394D2",
    "IMNC_Bestätigung_Miedgl_Bijlenga.pdf": "8A39954C-168A-4816-91BC-889ED421B8E8",
    "impact wellness program on residency.pdf": "515E5D0B-A08B-4B88-8325-92408CF6FAC8",
    "Imported File.pdf": "D9E79A66-D9ED-4380-95BC-03BD99954B3E",
    "imprimir.pdf": "31DC7E7F-5946-4419-9A19-FB42B7233227",
    "IMR Certificate - Dr. Jaspreet Singh Dil.pdf": "759B1BF9-CC57-4B39-9002-FDC9CAB03FB4",
    "IMRI5ALA_CNSPoster_2019_pdf.pdf": "79B1F7AC-1691-40BC-8738-2081041E235A",
    "In-person events disclaimer.pdf": "B5965DCC-753F-4E8F-AF3C-E0C7C2236DDF",
    "IN_CNS2023.pdf": "29F1B0A5-DA06-4305-9DC9-3E466E1CF84C",
    "inbound1692877895207992632.pdf": "2367A639-0056-41A1-9422-D47A95D52284",
    "inbound1839342107830579428.pdf": "95403C7F-EBAA-41FE-9ABB-EBE56835AE8C",
    "inbound2643269448770389128.pdf": "028D64D4-48F6-41C9-819A-9E9EC883B3EE",
    "inbound374152683698124063.pdf": "E4561813-F48D-441D-ABBD-0FB29B186CAC",
    "inbound3781964198865556162.pdf": "6B0D8910-1AB1-4EB7-875E-B0106A1ADC08",
    "inbound5328711853137608323.pdf": "1AAE461C-44F7-4E24-A847-AA9B888EA6C4",
    "inbound5832003257740547310.pdf": "0E1CB61D-ECDD-47D9-90B8-9069E7BE3767",
    "inbound7437695762242986098.pdf": "19B2C49D-65A6-4B6B-8557-B1FC5A8E7438",
    "Incidence and Outcomes of Spinal Cord Injury in the Elderly_edited.pdf": "6690918E-EBFE-4DE6-A317-2A52BC2344ED",
    "Incidence and predictors of ophtalmic artery occlusion in IAC for retinoblastoma.pdf": "7F00C557-CB3C-471B-99EE-5D7FBBA7B2FB",
    "Incidence of Complications of SCI.pdf": "276C5915-5C5A-4057-9BD7-4AA037702154",
    "incision drape prep.pdf": "FC4712CD-9107-4515-8DD9-188FFC0144AC",
    "Income--Table1.pdf": "D1935AE8-905F-4064-ACAE-87A47E34B595",
    "Income--Table2_opioid.pdf": "654F6086-F5B8-4260-AEDD-74DF36F4D401",
    "Income--Table3_comorbidities.pdf": "AE09C488-7F8C-4247-8058-ACCA6D03456B",
    "Income--Table4_opioidcomp.pdf": "398418EA-EC1A-4477-9309-9F7922DF06FF",
    "Increasing Cost Efficiency.pdf": "DC7CB2F0-02D7-443A-AB27-C44ECB6BEEF1",
    "Independent Radiologic and Surgical Predictors__Maala.pdf": "A47329B4-76CC-47EC-9CF6-E587735EF50E",
    "Indiana University Neurosurgery videos August 2021.pdf": "00A57753-A073-419B-855B-2976C5D80506",
    "Indiana University.pdf": "A9C6505F-BAF5-4E54-BCD5-820A142D688C",
    "indic 4 ant cerv decom treat CDR.pdf": "BCF7F8E0-57BD-49AB-81DC-67E14270FEBF",
    "Indications Conference Figure.pdf": "74B27375-5E47-4F50-A6EF-C96D47E426C8",
    "Indonesia Ns Soc.pdf": "68C1CD38-F484-45DB-BE51-9FBE8A753958",
    "Industry Council.pdf": "11ACAF9F-7131-4771-8316-3F5D13361D7F",
    "Industry Relations and Sales Representative.pdf": "3B0CBA1C-C92A-493F-9BBA-E685B99136EB",
    "Industry Support Brochure _ 2023 CNS Essentials for a Rewarding Neurosurgical Career Course.pdf": "159996BB-E39E-4473-A817-F3B9DE62EE56",
    "Industry Support Brochure _ 2023 CNS Tumor Complications Course.pdf": "66A2FF68-6C4C-4406-9354-E377901B680B",
    "Industry Support Brochure _ 2023 Spine Complications Course.pdf": "5BCC083B-1EEE-49ED-BDDC-AE4D18BE85A9",
    "Industry Support Brochure _ 2024 CNS Essentials for a Rewarding Neurosurgical Career.pdf": "5EAFC8EC-8C44-44C3-94C2-436D80DCC705",
    "Industry Support Brochure _ 2024 CNS Neurosurgical Complications Course.pdf": "F538F24A-87D4-457B-BAC6-1B3D4BDAED55",
    "Industry Support Brochure Sponsorships Grants Advertising _ 2023 CNS Skull Base Fellows Course 1.pdf": "31B35D3F-7A85-4192-8CD9-178968D75AC5",
    "Industry Support Brochure Sponsorships Grants Advertising Tabletop _ 2023 CNS Skull Base Fellows Course.pdf": "3C27B241-F625-47F0-B36E-FE7151CC1236",
    "Industry Support Brochure_2024 CNS Data Science Virtual Course.pdf": "4CF0F8E0-70E9-4626-8984-2237364C1C76",
    "Industry Support Brochure_2024 CNS Neurosurgical Emergencies Virtual Course.pdf": "04BC62D1-E366-4DA4-9263-4474EFA7A30A",
    "Industry Support Brochure_2024 CNS Oral Board Review Virtual Course 1112023.pdf": "9E54055A-A608-47CC-9853-92C8EE680528",
    "Industry Support Brochure_2024 CNS Oral Board Review Virtual Course.pdf": "6498A172-AA35-4922-8CDA-768655FF7DA3",
    "Industry Support Opportunity Educational Grant _ 2023 CNS Oral Board Review Virtual Course 1.pdf": "5FAF078B-BCDB-4AF0-958D-1FAE15F149D9",
    "Industry Support Opportunity Sponsorship _2023 CNS Data Science Models for Neurosurgeons Virtual Course.pdf": "8E6102D6-4558-4EB5-ABA6-5489387B9545",
    "Industry Support Opportunity Sponsorship _2023 CNS Neurosurgical Emergencies Virtual Course.pdf": "E10C78F3-E6CC-43CE-BC68-0F9AFC265705",
    "Industry Support Opportunity Sponsorship_2023 CNS ‘Nexus’.pdf": "2CA48DE5-E3BE-46B5-9595-B06FCB334EDB",
    "Industry Support Opportunity Sponsorship_2023 CNS ‘Webinars’.pdf": "604816CA-DCE8-418B-A4A5-634CC309DFB0",
    "Industry Support Opportunity Sponsorship_2023 CNS ‘YouTube Series’.pdf": "3B32C3A0-DF98-41C1-890A-735C854F512B",
    "Industry Support Prospectus _ 2022 CNS Annual Meeting.pdf": "503FB31A-D212-4785-92EF-23C5A4038C37",
    "INDUSTRY SUPPORT PROSPECTUS _ as of 08.29.22 _ 2022 CNS Annual Meeting.pdf": "44AD2B16-1BE8-433F-9E2E-1108FC3CBB74",
    "INDUSTRY SUPPORT PROSPECTUS _as of 09.20.22 _ 2022 CNS Annual Meeting.pdf": "D3531431-FD85-4446-9EEE-3C97CC3010A2",
    "Industry Support Prospectus as of 07-12-22 _ 2022 CNS Annual Meeting.pdf": "4D3B2886-9F17-478D-AEF5-AC8529EC7C52",
    "Industry Support Prospectus as of 07-14-22 _ 2022 CNS Annual Meeting.pdf": "39DAC9AE-B9D9-4AD0-8A5A-A8277BF9EED4",
    "Industry Support Prospectus_2021 CNS Annual Meeting.pdf": "D8307B49-1A45-4EE8-A4AB-7BCE671AF0F6",
    "Industry Support Prospectus_2022 CNS Annual Meeting.pdf": "9907BDCB-43BA-43CB-A291-FC50A3A81C2B",
    "Industry Supporter Prospectus_2022 CNS Annual Meeting.pdf": "66D1BAAD-D5A0-47EE-AB19-24CE9C9909CB",
    "Industry Supporters Flyer _ updated as of 03.23.22 _ CNS 2022 Neurosurgical Emergencies Virtual Course.pdf": "84218AC3-34A5-49F8-921E-856F3972D2D4",
    "Infinger_Libby_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "8864DBFB-51E0-47A1-8C79-8692F3DFE343",
    "Infinger_Libby_2024PositionalMembershipApplication_CVandBiography.pdf": "CC0185FF-EFC7-4818-9EEA-66281E2AE89A",
    "Influence of X Stop on Neural Foramina and Spinal Canal Area in Spinal Stenosis.pdf": "825FA9F8-014A-4F82-8C04-950D4EAB2EA5",
    "Infrastructure.pdf": "372161FA-2660-4B2C-92F9-F088BBD3FC58",
    "InfxnHRQOL.pdf": "3CC7C2B4-2977-4E10-85FC-C43B8382623E",
    "Initial_Closed_Reduction_of_Cervical_Spinal.10.pdf": "ECED92D8-CEB0-492C-8846-46EB7F041249",
    "Injam certificate.pdf": "06888590-5DCE-4342-B737-D758712E204A",
    "Injury Comb_wo.pdf": "3A7E84FF-9271-4654-BBEE-8B1089ED3194",
    "injury_prevention_funding_letter.pdf": "7B76DD81-673B-47C4-9481-AA28E4D59775",
    "InMemoriamCCGetch.pdf": "1035F9F7-E5DD-4AEE-AD58-0D1F4757004E",
    "INNC Sponsorship Pack.pdf": "CE6A4006-4033-4E86-8A5C-7BCC1AD7AF8E",
    "Institutional Subscription Purchase Instructions.pdf": "AAE87E74-0043-4A61-8D49-A78AC9AFD34D",
    "Insulinotropic influence of cocoa on wound healing in rabbits may be independent of blood glucose levels.pdf": "2AB311F8-5BF8-4DF8-A350-7C67E970C816",
    "Insurance Disparities on Healthcare Resource Utilization and Outcomes in Adolescents with Spinal Cord Injury - Peds Joint Section 2022 Poster.pdf": "A09BA108-CBF5-4970-8137-7098B8C00949",
    "Insurance Status and Preoperative Factors Associated with 90-day Emergency Department Visit following Spinal Disc Herniation Surgery.pdf": "69E18B02-710E-4BCB-BAFB-317B7B0A5642",
    "Int'l Invitation_Bourabah.pdf": "A347E438-4E99-44BE-BA76-5709BCDD50F9",
    "Interim Final with Comment for Review.pdf": "A75D6BD0-831A-4153-B4F2-40C70C6A4D18",
    "Intermediatetables.pdf": "E2C565BC-30E0-4C8D-840B-F311D26829DC",
    "International Collaborative Section Virtual Meeting Agenda.pdf": "640782A0-0474-40BB-A6D3-D62B67FD0AE0",
    "international membership app.pdf": "D08D66AB-FD4E-4278-B4A4-0E5AD61E900E",
    "INTERNATIONAL VISTA RESIDENT MEMBERSHIP APPLICATION.pdf": "C8CE45FA-4CD9-4738-B862-24FBBBB8C7E1",
    "Internship2.pdf": "EA8537F2-2CA0-425F-8C8A-7065E941AE4F",
    "IntlResLozano_Tangua.pdf": "BC736E22-DE2B-416D-8A97-37DB5C2A69C0",
    "Intra-axial Lesions within the Pediatric Population?.pdf": "F2B23CE2-1CD0-43FC-A91B-D259CB00292B",
    "Intracerebral hematoma from aneurysm rupture.pdf": "0A27A6D8-D46C-4918-B958-22BAFD04AF46",
    "Intradural_Path_Table.pdf": "DBEB1CFA-7DEA-4953-954D-C102F0DED301",
    "Intraoperative Intradural Findings During Posterior Fossa Decompression with Duraplasty for Chiari Malformation.pdf": "DFC88FA7-E81D-4284-A643-189665DF40BB",
    "INTREPID_3yr ASSFN 2019 poster_.pdf": "82D87F60-0B00-4106-BAA2-F208DCFFDB77",
    "intro and methodology cerv degen.pdf": "59B1F760-7501-4A6F-94C2-FF42A0B06F1F",
    "IntroAndMethods.pdf": "001120FC-4130-47F5-B386-BC8391AC4B6F",
    "Introduction - ICH.pdf": "CF5637C9-B841-4DF9-8620-79B356A59B90",
    "Introduction letter.pdf": "200BE666-F936-4F0F-8391-185346C1F606",
    "Introduction to Neurosurgery.pdf": "8D126EA1-9F43-4A3B-B2F4-28F12EEFFFB2",
    "Introduction to Neurosurgical Subspecialties Functional Neurosurgery.pdf": "FCFFAFA6-CF89-46B4-ABEE-9251FBC9D4BB",
    "Introduction to Neurosurgical Subspecialties Pediatric Neurosurgery.pdf": "B6DA954D-5B57-4218-BD67-131F50F5CFF6",
    "Introduction to Neurosurgical Subspecialties Spine Neurosurgery.pdf": "E2F718F6-2C45-4633-B401-DA21C795C77F",
    "Introduction to Neurosurgical Subspecialties Trauma and Critical Care Neurosurgery.pdf": "CB0A28AE-8D37-46AB-8BBE-23FBD3B43085",
    "Introduction to Neurosurgical Subspecialties Tumor and Skull Base Neurosurgery.pdf": "83065384-5AF4-4EE9-8258-C4F1EC331CE8",
    "Introduction to Neurosurgical Subspecialties Vascular and Endovascular Neurosurgery.pdf": "A3D48FE3-6F63-4F54-9102-13F551B628A4",
    "Introduction.59.pdf": "2578B17E-224B-4F6A-9223-6867FBCA8203",
    "Introduction_1.53.pdf": "4AC63B7D-61D4-4638-9C5D-18A3C1280BB2",
    "Introduction54.pdf": "852AE234-6B99-4014-9AE9-B65809C6BCAD",
    "Introduction55.pdf": "D316616C-6DD1-47C4-B4E1-2AC881360CBD",
    "Introduction56.pdf": "12296B80-063B-455E-BAEF-F4D69068F754",
    "Introduction57.pdf": "9E50A711-E97A-4DBF-A985-13D0E0925CA3",
    "Introduction58.pdf": "A316BB35-E0C4-4535-9C95-154B19E1E3C0",
    "introFigure.pdf": "8523CA52-7D56-4673-BB55-F80976674DBE",
    "Invitation - Brochure.pdf": "CBB958E3-CAAE-4E24-A2B2-EC67261A0728",
    "Invitation Letter to Dr. Chu Thanh Hung Tulane 2023.pdf": "1A8E9C5D-5460-4734-9B6E-991495F9D874",
    "Invitation Letter.pdf": "26266F39-3311-4B25-948C-168144B622D3",
    "IOM spine summit demo.pdf": "EFBDF057-F8EB-477A-98AC-0B52D796C0AB",
    "IOM spine summit graph.pdf": "96C963B1-6C4F-4C30-A616-DA67271A94AF",
    "IOM spine summit table 4.pdf": "6882C88D-B54C-4E8E-87C1-CFC26CBC00EF",
    "IOM spine summit.pdf": "63A0B72B-24F7-4953-A730-5FCA2D592CD6",
    "iom_comments_final_021615.pdf": "B61E6678-D60B-495A-843F-2A1AD5741C06",
    "IOMPressReleaseFinal.pdf": "CDCD00CA-4767-4BD9-8295-CB5C89BDD152",
    "IONM figures.pdf": "7A629014-A701-4A91-B11A-D431C43D7921",
    "IONM_IMSCT_BCH.pdf": "A56911E5-3F2C-455B-BADB-DD0FACFCF8C1",
    "IOP.pdf": "3841EE9C-C7AB-420E-88CD-09A0EB38177D",
    "Iorio-Morin.pdf": "81353EEB-D5F9-47E1-9EA3-73EFF2BA9D53",
    "ipab_coalition_roe_sanchez_release.pdf": "9463540E-00C7-4B1F-8970-4AB23A156FB9",
    "ipab_coalition_wm_ipab_repeal_release.pdf": "91F2A367-B9A1-4EF2-8D2F-4EA5626E8091",
    "ipab_release_11.1.17.pdf": "2A2DBE3D-4699-4648-A357-AF73391EE2E1",
    "ipab_release_11_29_16_002.pdf": "9C97E13F-B486-4F37-B536-515BA52A68AC",
    "IPABAllianceSpecMedNewsRelease011510FINALAANSCNS.pdf": "C58A6B38-868F-4803-AB2F-17FE3E649151",
    "IPABAllianceSpecMedNewsRelease011510FINALAANSCNS_0.pdf": "BB5D07CD-A2C9-462A-AF74-87B2B038C840",
    "IPABHR4985SupportLetter040610FINAL.pdf": "D3A4F455-E340-4255-9352-41FED5979D38",
    "IPABHR4985SupportLetter040610FINAL_0.pdf": "C77F00D1-1304-4170-8064-6AD7443A5DF3",
    "IPABLettertoCongress1-15-10.pdf": "3E4E9DAC-C623-45A9-B03C-315670B49005",
    "IPABLettertoCongress1-15-10_0.pdf": "63604430-FC41-4D5F-96A6-97E1B77E0AEB",
    "IPABLTE.pdf": "38EF10C6-4089-4CA0-A06E-A65057EAE76F",
    "IPAX1_CNS.pdf": "1562805A-DC77-4552-9049-9F0663F3D1E3",
    "ipshita cv.pdf": "CFB6ADC4-41A6-4085-BAFE-0058E45B5799",
    "Ira Bowen-FEllowVErif.pdf": "0D27AB11-917A-4543-A4A1-DB83BEFE2D99",
    "Iracema_Estevão_CV.pdf": "953AB094-09F1-4D2D-B5E3-83B815B4B0DB",
    "IRB NOT YET SUBMITTED.pdf": "81D8126C-0FAF-4B4B-8464-09AD678D73A1",
    "Irfan eans.pdf": "F6B4F57E-EE99-45FC-98E5-ED952104AC3F",
    "IRRAflow.pdf": "AF5750B2-45B8-46ED-B322-695665EA8B69",
    "Irrigating catheter paper_Table1 copy.pdf": "02A2F3AF-21F9-4498-BC4F-EA14D320F2B0",
    "Isaac Jonathan Pomeraniec-FellowVerif.pdf": "DC7C56FD-B05A-41DA-A283-D43FA67120F9",
    "Isabel Martin del Campo-logs.pdf": "99899A39-0FF6-4A11-B64A-10CC4DDDB72B",
    "Isabelle Germano Recommendation Letter 1.pdf": "944A211C-B167-45B0-BE32-2135AB70E212",
    "Isai García López-RESverif..pdf": "1DEF3700-5A2E-4F1B-A0D2-822039AEA70D",
    "Iscrizione SINch.pdf": "915BE0A4-181F-4209-B70B-D2491DF8E552",
    "iscrizione.pdf": "4124BA9E-C881-4277-97DC-147145E8CB6B",
    "isha poster cns 2023 dc.pdf": "BE8CFD5E-8300-4389-A9FE-BAAB588C61D9",
    "Ishida_ver.pdf": "1D60E468-D661-4B7E-AEF1-DC108254A830",
    "Iskandar, Benny.pdf": "836D8771-6A20-414F-8C57-615784DC9F56",
    "Islam Fayed MD CV.pdf": "D625BE80-125E-43BB-A43F-08C7B7291B5D",
    "isolate and mark.pdf": "7BEDCB91-C51B-49DC-9C8B-BEEE03230F3F",
    "Israel Medical Society.pdf": "B751A048-8925-4254-A3D5-D6D31E6072BB",
    "IT Nicardipine Poster_CNS_Final.pdf": "232C0D7E-F452-4D6D-A828-81F20D509913",
    "IT nicardipine, Table 1, Table 2, Figure 1, 060921.pdf": "34E50009-2EFD-4E8D-BB8B-728F20595ECF",
    "Italian society certificate.pdf": "079EAE19-AA36-4929-A9C8-2D6EC7496529",
    "Item 2 b CSNS Resolution III-2011S.pdf": "EEDF54C7-FF19-4769-8A04-AE9556E0EA95",
    "Item 2a CSNS Resolution II-2011S (2).pdf": "E49E7621-DFA9-41F1-B53C-A75D3FEC230C",
    "Item 2c CSNS Resolution IV-2011S (2).pdf": "C5B3862B-74AB-4E46-8B44-4DA469492971",
    "Item 2d CSNS Resolution X-2011S (2).pdf": "91A55E96-B8C7-427F-A5A4-608B26D80C13",
    "Item 3a 1 Pre-Sacral Complications-SAS_Journal-Mar2011 (1).pdf": "A7E73ACF-D2F8-481E-A128-04F920144461",
    "Item 3a 2 PreSacral_Interbody_Fusion SPINE.pdf": "0094DD74-09AC-4C44-BD96-EE318C9BEEA6",
    "Item 5g AANS 2011-04-01 V8 LJ jd dm bb FINAL.pdf": "721B4A7C-E4BA-4C26-9412-8AEDEEBC9FC4",
    "Item 5h 1 BCBSNC Lumbar Fusion Response FINAL- 12-15-10.pdf": "812556EA-86F1-45BD-89EE-C018B4AF691F",
    "Item 5h 2 Updated BCBC NC Lumbar Fusion Policy 120 11.pdf": "5F399C11-5A3D-4D0E-8469-84B2898F2936",
    "Item 5j Humana Computer Assisted Surgical Navigation Bundlin.pdf": "7BF97177-0413-42FC-98E1-93929E9F327E",
    "Item 5k Washington State Vertebroplasty-Kyphoplasty Coverage.pdf": "8BBA97A0-970A-4177-A80A-29CA5D58941D",
    "Itoh.pdf": "40BF2AC3-EF5B-4168-B3C0-E48014675D08",
    "IUSM Conor Cunningham Diploma.pdf": "95335747-201A-412F-ACF5-3666023CDF8C",
    "IVG degrees.pdf": "2DF2B39B-4784-41F7-B7E8-0FDADCC87087",
    "IVH x Weather.pdf": "C94A71C7-D33E-4257-8365-7F3776ABE127",
    "IVORY POSTER PHOTOS.pdf": "AFF303BE-09C4-4DDF-A98A-FE01CC8597F2",
    "Iyer Ankitha-EVL MD 8.8.2022.pdf": "5C299567-D4B6-429B-A185-99A6925608EC",
    "Iyer Verification.pdf": "E3ADAD8F-B89B-453F-91ED-FDB783BEC788",
    "J Bakhsheshian.verificationemail.pdf": "6FD6847F-5D77-4ABC-AA7C-F5F0E3ED8013",
    "J Burke.emailVerif.pdf": "FA0F8B74-9AA0-4F9D-9680-577865847C7C",
    "J C Saleme - Original.pdf": "4EBA3E2D-3B2B-4736-AF66-1D2E64CBB69C",
    "J CV ROMAN.pdf": "B1C8DE0D-8002-43EA-B0F9-2C1EFE21C0AC",
    "J Harper Verification Letter.pdf": "E185F4C5-9A8B-4B0C-922B-CA9ECF64C97F",
    "J Lillard Verification.pdf": "9710B7C2-7D22-481C-9529-D809EEE07CBC",
    "J Silva - Letter of Good Standing 2023.pdf": "72E0A698-1815-4667-96B8-2F4230286515",
    "J. Birch Letter of Good Standing for CNS.pdf": "684DD4D8-B3F9-485A-9FC3-0022DEA6CB16",
    "J. Braca - VER.pdf": "ACFD1172-2871-4554-A269-DB42AC97C3B0",
    "J. Breton Enrollment Letter.pdf": "1E4E8939-EE59-4E69-97D1-BD2C9CC7FA7F",
    "J. Chu - VER.pdf": "80C7CDAD-D131-4389-AD6D-17570B0A7D2D",
    "J. Hong Deans one-time Scholarship Notifcation - Signed.pdf": "8F7273B7-6081-467A-B82A-96AFD8197DD9",
    "J. Martinez CV 06.22.2023 -2.pdf": "174B42B5-3E6C-4656-87A3-11A16D0C2C3F",
    "J. Nicole Bentley_CV_2023.2.pdf": "5379962D-BF78-43E2-8AB5-505309F95C0A",
    "J. Nicole Bentley_CV_Current.pdf": "9A02E5BD-2777-4CA1-82FF-927FEC997ACE",
    "J. Ratliff- Michael Jin Letter.pdf": "2DA517FA-7120-45E7-BC93-501AEC874232",
    "J.A.N.E. award Manuscript.pdf": "A719E146-B35D-4883-B26D-C2B47094C880",
    "J_Brown UofU Enrollment Status.pdf": "C9A5F5B6-5E54-4F60-9401-7CBDFDF6140A",
    "Jabarkheel, Rashad - Good Standing Enroll copy.pdf": "447C86EF-4177-436D-A1C5-EEDE16A53E9C",
    "Jack Rock LOR.pdf": "CD21FF1D-1FEE-4C7C-A5CC-A7BB1027FA06",
    "Jackson Memorial Hospital-University of Miami - Ferraro Gernsback Snelling.pdf": "0F8F6F2C-6D52-4411-8994-FC75919864A5",
    "Jackson_Anterior Myelomeningocele .pdf": "9E334DA0-19AB-49E7-BDBC-52DBE914816D",
    "Jacob Alderete ASSFN Poster.pdf": "C91F67FB-BFE8-4CB2-B423-91A81DFD528B",
    "Jacob Bagley-RESverif.pdf": "777F3DB9-9AA1-454A-BD5D-F2F01E6BC36A",
    "Jacob Bernstein CV .pdf": "BC872D57-DDE6-4A0D-9B87-03594396D260",
    "Jacob Bethel Transcript.pdf": "2DBC9ED6-D6F5-4501-B2BB-532C4B978D5A",
    "Jacob E-Mail-2008 0207.pdf": "3C5900C9-3810-4DEA-91EC-C0276D327F7C",
    "Jacob Gerzenshtein_Letter_Of_Standing.pdf": "96E286B7-56DD-4114-9980-12316DBDF818",
    "Jacob Goldberg-RESverif.pdf": "EC048C06-44E3-44E2-8E54-D79FB2C0E6B0",
    "Jacob Greenberg-RESVerif.pdf": "2006ACFF-A059-4883-B381-519FC51D0C91",
    "Jacob Razzouk-letterofgoodstanding.pdf": "23FEFF6B-12D8-4D74-A193-FF58F6BCF128",
    "Jacob.Rosenbaum_ResumePeds.pdf": "677CD6DA-0702-4974-9D93-BC920D0FBBB8",
    "Jacquelyn Corley-RES.Verif.pdf": "1B5913E0-9C23-4EA9-82AD-1A6347D40D7D",
    "Jade confirmation letter.pdf": "911B69B1-3211-4AA1-8CF1-1416BD260FBB",
    "Jae Eun Lee - Enrollment Verification (1).pdf": "D96B6D58-6299-4EEA-A002-C548A26B6946",
    "Jae Ho Han_ver.pdf": "A1FD9D14-5BC2-4ADA-8642-A7126F6CFDAA",
    "Jaeger Andrew_letter of good standing.pdf": "A391615C-D196-481F-BD53-AB0716EE86C2",
    "Jagathpassport Page1.pdf": "9811A418-DEAB-4D58-836C-6EC60753EFFC",
    "Jai Shetake Resume_May 2016.pdf": "95FF35F0-71C7-46BF-AC92-56250B846122",
    "Jaime Martinez Santos-RESverif.pdf": "D3338003-9482-475E-BFC1-B2EFAC1BE849",
    "Jaimin Patel (1).pdf": "12CDFBBA-B65C-4A59-AE09-740FB695C44E",
    "Jake Haver CNS .pdf": "A78D0226-1EF7-442F-BC50-E465CCF00CF2",
    "Jake Jasinski-Fellowship.pdf": "74341D6C-72B3-4750-A737-4567D711505C",
    "Jake Letter.pdf": "0C23D3B4-F0BE-4065-BC17-62959C186108",
    "jake residency diploma.pdf": "6EAF1D8C-0B3E-41F8-A2FD-BEF57DA27EFA",
    "Jakov Tiefenbach Diploma.pdf": "578D7386-4208-4457-8D93-B93062EC72EA",
    "Jalal_FS.pdf": "AEF73589-C395-47D9-B59B-AA6CB7F1DA5B",
    "Jalal_SCS.pdf": "E579638A-89D0-45D7-AF12-6FB04185154F",
    "Jalali_CV.pdf": "8A11954E-15EE-4E0E-B1C0-39EE21894A9E",
    "Jallow Ousman 2-2-23.pdf": "814DB478-899B-4385-82B9-463BFB608FED",
    "JAMA23-2487_Merged_PDF.pdf": "83DE506B-A860-4026-9714-D0C9B0ED5D9E",
    "Jamal Abdullah.pdf": "11F41765-F014-4518-8D0F-C8705A01255C",
    "jamaoncology_liau_2022_oi_220066_1668698380.80695.pdf": "E50EBEBB-FA87-4F13-A02B-1757ECBA3D71",
    "James Barry CV.pdf": "5B0A52B6-961C-4C93-9C6B-3064526BD876",
    "James Bayley-RESverification.pdf": "6712E8FC-6D40-40C7-86A7-DC9CE691E64B",
    "James Berry-Fellow-emailVERiF.pdf": "F425854D-A97F-41A6-B90D-3216E12D0096",
    "James Botros - CV 10.09.19.pdf": "0AEB86BF-AC28-4A82-A6B7-69B74877CDEC",
    "James Castiglione Letter of Good Standing.pdf": "FCE9AC62-E78C-4B62-82BD-0228FDC60E30",
    "James Fowler-FellowVerif.pdf": "4866ADE7-0EAF-49EC-B8FC-FC01254714F3",
    "James Lee ID 1243828-RESVerif.pdf": "12E2C07A-D57C-41B9-91F2-0DFAEB9E1892",
    "James Lin-Letter-CNS.pdf": "5C84070A-ABB1-464C-9A7E-1E31DAB99AA6",
    "James Liu CV 12.11.2019.pdf": "8F8DB86B-2A04-4E7F-ABAD-21C0142E7DE2",
    "James Mooney-Fellowship.pdf": "86AB1E0E-75C2-4B12-8173-1162335F6834",
    "James W  Holsapple MD BU CV 11 8 2023.pdf": "D3C3B6FA-5A60-45B0-B865-56DD98A8D1FA",
    "jamiemedschoolvarification.pdf": "E8C8F7AE-E126-4906-B687-3DF79B6DD7AA",
    "Jamiolkowski Ryan et al Nature Medicine 2024.pdf": "60469E27-5316-4A1C-AF7E-78FC8100A93A",
    "Jan 2022 journal article.pdf": "23AB8EF0-20D1-4F4F-AB42-1BC482D956B4",
    "Jan 2023 article.pdf": "1483EA92-E7BD-4A42-8D76-8AC125F75FFB",
    "Jan 2024 Journal Article.pdf": "528C9E9E-1F1C-4CBC-A678-6AF828015D36",
    "Jandial CV Nov 2011.pdf": "761B434C-709B-4D65-949E-37A245B854B4",
    "JANE Award - Full manuscript.pdf": "FE3B7AD3-C2B2-427F-8DD2-24105FD756CF",
    "JANE Award.edited_Final.pdf": "8171BBED-2F3E-4663-906B-36DD20E0EFB6",
    "JANE Award.pdf": "47B0AC2E-5F83-4988-919C-C99C5F1AD7FB",
    "Jane, John A, Sr.pdf": "C8D0F106-60AC-4BD9-9091-686C1AEC607A",
    "Jane, John.PDF": "6D645182-C49D-4F64-9B66-8E808395DBD8",
    "Janet-wdf-edits-MC8100-117BRO.pdf": "7ABEEB9A-D409-4C46-8BD4-D6D37A3E5398",
    "Jani, R_Letter of Goodstanding.pdf": "2478ABD1-F9D9-40A6-8747-C9CC4C691FF3",
    "Janine Hsu-Fellowship.pdf": "F4F658B1-2FA6-498A-87CA-A7B70DB9844D",
    "Jankowitz_aneurysm rupture the first hour 2024.pdf": "CCEFB9C7-53A1-45CF-B6C9-1BD101DDE30B",
    "Jankowski_CV.pdf": "B95B1019-06A0-4FA9-9D7E-8F1DF0BAC834",
    "Jannetta, Peter.pdf": "44EFCCA5-CC86-4E32-B947-9046A5093272",
    "JANNSEN-NP2021.docx.pdf": "0730BD46-E91A-4042-9B31-082555E2ED27",
    "January 2021 podcast article.pdf": "BAE8C085-8129-4F7E-9A56-F570AB2A7AF8",
    "January 2022 Journal Article_updated.pdf": "2DDF8A7B-B72B-4AB7-958F-E70190E31CF1",
    "January 2023 PLME AAC Letter-Jay Gopal.pdf": "AB840937-8E95-410A-8E3C-97801E023D02",
    "January 2024 Medicare Sign-On Letter.pdf": "3FBE51BC-2AD4-4C48-B2CF-53133515C4D4",
    "Jareczek_CNS_2022_DSA_Trends.pdf": "E67E5C51-4CB6-4260-9544-0DD2B7851B4E",
    "Jareczek_CV.pdf": "87116ED5-10DD-4432-98A7-CAEE38B9F3BE",
    "Jareczek_PD_Recommendation.pdf": "0D2BC76F-030D-43FD-AD5F-B9464CEAEEE0",
    "Jared Brougham-FellowVerif.pdf": "23344A16-B9B9-4872-B626-ACDB8AB5EEEE",
    "Jared CIHR 2013.pdf": "22206805-07BA-4A74-90F6-A8CDFC10CAAC",
    "Jared D. Ament CV 7-2020.pdf": "98E64FDE-2088-4700-84DF-B6A46D6F8724",
    "Jared Fridley Slides.pdf": "33BED0A7-5BEA-49AD-9DB4-6EADE37A57AF",
    "Jared Wilcox.FellowVerif.pdf": "DF9ED26C-D314-45F5-AB44-C61A428390AC",
    "Jarmula_LetterofEnrollment.pdf": "4B7E7FB1-706F-4196-8BD9-555D6F16C261",
    "Jarmula_LetterofEnrollmentVerification.pdf": "A8FF7CA5-21D5-417A-A07C-4E51ADF1B7ED",
    "Jason Boulter-Fellowship.pdf": "7D49CCF1-7591-4E61-B81E-FF89F10ADD59",
    "Jason Choi-RESverif.pdf": "1E88999C-475B-48F3-BBF0-65CC8E93A2E7",
    "Jason DeBoard Letter of Good Standing.pdf": "1B788AEA-0EF4-4013-A5EA-E302523716B1",
    "Jason Hsieh CV 2023.9.pdf": "B0129D39-FA8F-424A-929E-439E6ED4DA08",
    "Jason Hsieh-FellowVerif.pdf": "0FECAC75-C95C-41D0-BC1F-ECAABBDCA786",
    "Jason Kim Laminoplasty.pdf": "4F2E5087-48A3-4E3B-A456-D5AAD14E2591",
    "Jason Kim Stroke Trends.pdf": "5A82B14A-FB16-481C-9F87-F3E795A9813D",
    "Jason Lee Choi MD CV 20231005.pdf": "5AB35A41-C435-473A-9F29-2092B59F7C6C",
    "Jason Michael Reese CV.pdf": "80F8781C-E39B-4087-83E6-FC4E5EFED405",
    "Jason Schwalb Recommendation Letter.pdf": "5412C018-CBCE-4212-B50F-D12BEAFD556F",
    "Jason Schwalb Sponsor Letter.pdf": "073D8079-7402-421A-BE55-B0ABCB617BC1",
    "Jason Vadhan Letter of Good Standing.pdf": "EE9FFB45-88BF-47EA-9116-0D30C8ECC542",
    "Jason Wang MS2.pdf": "58012F00-BEA2-4445-8913-8907B1C70C82",
    "JasonEllis_2021_v14.pdf": "39968411-A859-4B90-9B86-E6685610CD07",
    "JasonEllis_2023_v1.pdf": "4131EB97-A667-4AE8-8DC9-EDC51B7B7F47",
    "JASPREET CV.pdf": "EF87F3BC-7FF6-4431-B49B-FFDEE6DA39C8",
    "Javed Siddiqi CV.pdf": "B5C1C58F-C3AC-4A5B-A7B3-AB32B3D4B814",
    "Javed Siddiqi Recommendation Letter 1.pdf": "037FCD62-AA1F-4497-9D37-4C2DA77F7CCE",
    "Javed Siddiqi Recommendation Letter 2.pdf": "7A1E4F11-8F2E-4DCA-A82A-9051270EBBBC",
    "Javed Siddiqi Sponsor Letter.pdf": "2F16DBCB-A564-4BBE-B228-A56A9BF008F7",
    "Jawad Fares CV- May 2024.pdf": "B4CEB10F-2A32-4D54-9BCD-91752CEEC8C6",
    "Jawad-CNS.pdf": "F531B97F-C0C5-4F42-AAD2-E8FD199EB495",
    "Jay D. Turner- Signed CV.pdf": "406DB5BE-CC9D-453A-9C9F-2441BEC56752",
    "JBaranoski-verification email.pdf": "E0FC0C60-6EDF-48B1-9303-BE089C9D752E",
    "JBarry.RES.Dpl.pdf": "8DD1A507-F32A-4EA8-A694-00E46002B8D9",
    "jBeecher.RESverif.pdf": "33EB12A0-E44E-4FF0-B86D-842716137D2B",
    "JBerry.verification.email.pdf": "C5C7D83C-1D56-4C24-9EB1-EEFDE6C8FAE9",
    "JBresearchletter.pdf": "C2C38229-A6E4-42E0-BB3F-071DFFC8129B",
    "JBS Letter.pdf": "E5201EC1-54DB-47FA-9C41-17F2F10C9525",
    "jcappuzzo_cv_updated.pdf": "B90BFF74-A68C-424E-961E-0AF5CF0B3499",
    "JCatapano-FellowVerif.pdf": "8F941198-30F6-4F95-BE1A-661392C226AE",
    "JChen.UVA Neurosurgery Diploma.pdf": "C61CBAEA-0E78-42FC-8E55-4336A7DF9210",
    "jchu-KSOM-CV.pdf": "F6AD459B-6E7B-41D8-ACE4-162AB3559356",
    "jchu_LOS.pdf": "0AF2F852-2E5C-4028-A3C2-0A20B4E4ABF4",
    "JCYang - CV - v7-19-22.pdf": "34F2ADC0-0660-4859-AF1F-246F6D77D751",
    "JD.Residency Certficate.pdf": "919D7F6C-FAF4-447D-A463-BB7D07D0E85D",
    "JDM Good Standing Letter 01-23-17.pdf": "7DD805FB-F9E2-4A7E-A4FB-028CAB6DB23E",
    "JDomino CV.pdf": "69337C25-4B7E-4CBA-96D5-04EFDA010DC2",
    "Jea - Niznik LoR.pdf": "B8FEA30C-7A4C-4547-BB11-E6ECAF2FE91B",
    "Jea Letter.pdf": "0892D342-F18D-4418-9026-446CE96E93FB",
    "Jean-Paul Wolinsky Nomination Letter.pdf": "0C19D83E-4DB7-41BF-91D3-1374D2847A65",
    "Jean-Paul Wolinsky Recommendation Letter.pdf": "0DC060D8-A2B7-4B61-9D2C-081FCCB6B028",
    "Jean-Paul Wolinsky Sponsor Letter.pdf": "B60336A9-C0A8-465A-BF43-63AAC19768DF",
    "Jean_SNS.pdf": "D7C298D4-0787-4D91-BD89-58EEBD9A779A",
    "Jeanfreau Alissa Letter of Good Standing 2.pdf": "0C8224C7-8B8E-4D37-A9FB-5D5C094A390D",
    "Jeff Nadel good standingdocx.pdf": "8A6286B9-C4D3-4865-8690-C2033BFA6F1D",
    "Jeff Nie Letter of Good Standing.pdf": "F7E7522D-9D98-49A8-B2F7-D56113BA6E8F",
    "Jefferson-ProofOfEnrollment-DegreeMedicine.pdf": "3300D010-ED9F-4324-AEC5-413DD1842103",
    "Jeffrey Gilligan-RESVerf.pdf": "C2CD5259-DC83-4874-BF0C-81D7DC8E9F7A",
    "Jeffrey Greenfield Nomination Letter.pdf": "D9AE1A0A-680E-4778-A074-ADB636003FFA",
    "Jeffrey Greenfield Recommendation Letter 1.pdf": "3D682518-8F94-412B-A5FF-6E4DC925844B",
    "Jeffrey Greenfield Recommendation Letter 2.pdf": "AD1161D7-D216-415C-8D30-CF5AF8F4DDC4",
    "Jeffrey Greenfield Recommendation Letter 3.pdf": "DCF44B52-DACA-4ABE-9543-900178CD2606",
    "Jeffrey Mullin_Jen letter.pdf": "7C19BD14-A6DF-48CA-BF28-25B6267EE1CA",
    "Jeffrey Nelson-RESverif.pdf": "1F49A09E-1AC9-4524-B00F-CB71AA8C1881",
    "Jeffrey Oliver-Fellow.Verif.pdf": "09F66D46-3446-4CBF-BC5D-D7552723120C",
    "Jeffrey Treiber-Fellowship.pdf": "BA501F38-DF51-4130-A775-6AFD86772FB3",
    "Jeffrey Zimering-Fellowship.pdf": "56629458-95A1-499F-B10F-1FC5AFFEB3FE",
    "Jeffrey Zuccato-Fellowship.pdf": "E7358036-A7D9-46D5-81F6-CD0A574C2C7F",
    "JeffreyBretonCV.pdf": "95C87299-7399-415F-8C60-1D363BC41E26",
    "Jehad Zakaria-RESverf.pdf": "3BE835BF-1404-4117-B6E0-C34A0C866181",
    "Jen_Niznik LoR59.pdf": "0407DF70-4547-46DA-BAC7-71C38F261A21",
    "Jenkins, Phillip - Good Stand 9.8.16.pdf": "58C7DBF4-F55B-4EEB-A653-085A7EEDB502",
    "JENNER_Student_Affairs_3_East_0839_001.pdf": "FCA2A04E-C42C-4ACD-A1F8-6DB1BC40D469",
    "Jennifer Farley CV.pdf": "B0A47B5B-8272-42FE-B8A6-72C19C977C90",
    "Jennifer Kim-FellowVerif.pdf": "F00F0509-A8F3-4EB0-8732-84044203FD42",
    "Jennifer Molinero for SNS Jensen .pdf": "77E953C4-1129-4D5C-B143-AF7B46A59546",
    "Jennifer Moliterno _ SNS 9.30.22.pdf": "FD3624ED-6117-4209-966A-038299676C70",
    "Jennifer Quon-RESverif.pdf": "E5E37A4B-BE42-4B48-BC90-C6E5897924A4",
    "Jennifer Sokolowski-RESverif.pdf": "3DD3FF75-4980-41AE-8F70-937AEE9F218A",
    "Jennifer_Battiato_PA-C_CV.pdf": "D0635D87-D305-4413-A41D-1505A934C028",
    "JenniferFarresResume.pdf": "990D467C-B8CF-427F-AE47-BFDC235E7F48",
    "Jennyfer Paulla Galdino Chaves CV.pdf": "78DBB7FD-067C-4B40-AEF3-4CC5C7D3DB2E",
    "Jensen Katherine . Ltr of Support.pdf": "A0644F58-9C2D-4AD5-B901-150714690F1D",
    "Jensen_Michael_Rolandic_Motor_Association_Area.pdf": "43DAE819-7059-4C1C-B2B7-82001FB09A6A",
    "Jeong Woo Choi_CV_Aug_2023.pdf": "D7E34000-D2A1-4454-AF0A-1CE4322092A7",
    "Jeremy Stone Letter of Medical Student Verification.pdf": "96218B14-6BAC-49D4-88A0-FE6500712EBC",
    "JerniganCV-Academic_2021.pdf": "2131D352-B870-4EC7-847D-49092655AD47",
    "Jessica Shields-RESverif.pdf": "F189C5AF-24BC-44AE-BA9E-AB9AF1B828F4",
    "Jesús Ibarra email VERIF.pdf": "F8CEF0FD-32DD-412A-B8B1-81AC1ADC7B92",
    "Jesus Ibarra-Fellowship.pdf": "74D496D4-95A8-4328-B0AC-881A5BD9BD4C",
    "Jeswani_CV_1.pdf": "1A352642-8C6F-4E2B-9004-3FA316C01D89",
    "Jetan Badhiwala JANE Award Research Efforts.pdf": "2F16641C-CFE4-48E2-A229-21E2680B41C0",
    "Jeyan Kumar CV Update 09_2022 .pdf": "106F30FB-3136-4072-85BD-4EA7AE010EF5",
    "Jeyan Kumar-FellowVerif.pdf": "1D7C9188-D044-4900-B069-3C8E1FC235B4",
    "JFrerich.RESverf.pdf": "06FF05C8-DB57-497E-8B43-13C50582B083",
    "JG - CMSRU Acceptance letter.pdf": "89CCEA6C-D7BB-43BB-B6BC-75357DFABF1D",
    "jgcguidelinedevelopmentmethodology.pdf": "7877B6BF-1EC3-45DE-B524-6543CE7FD6DF",
    "jgrc_coi_policy_final_110218.pdf": "1ADCB55A-1539-45D3-8A8F-F8DBC9FC1BA2",
    "jgrc_governance_rules_final_110218.pdf": "61CE573D-38C9-4144-BF70-C8CCE5DA0977",
    "JGreenberg.emailverification.pdf": "F2315B95-C61D-43A6-B71B-F5162BBEFBDF",
    "Jha Student Status Letter.pdf": "0AD031D8-84D7-4EDD-85D1-6C55774D1978",
    "JHM CV 10-30-23.pdf": "D8FC5DA4-9193-4890-A87C-DC80E4560F7B",
    "Jho CV 2021.pdf": "B23F53C7-BDCC-41F8-8949-8202CD14F226",
    "JHo LoGS.pdf": "E1E2674B-A149-4826-AC9C-707F2594FA85",
    "Jho_LOR_CNSLI_2023.pdf": "3A0BF88C-5FAE-4A96-82E2-238D4D4A8A3F",
    "JHU SOM Decision Notification - kjiang21sas.upenn.edu - School of Arts  Sciences Mail.pdf": "FD6D07B2-3A7F-40D2-B7C0-8961677CEBAB",
    "Jiang_Sam_GSL 1.PDF": "D9AC295B-E9A3-4CD6-A340-0B91A36FCB41",
    "Jillian Plonsker CV.pdf": "6F7D2C9B-ECB7-414D-8BCE-D5E93CA75812",
    "Jillian Plonsker-Fellowship.pdf": "307996A1-2196-4319-BD30-9D95926D982C",
    "Jin Jung letter of good standing.pdf": "C3A3C9E0-4829-472E-97F1-AC7388A38840",
    "JinMC_CV.pdf": "168D20AD-E835-4E98-A47B-6971D8DBC663",
    "JLane.emailverification.pdf": "47830252-314C-41D5-910E-8933AB886E4A",
    "JLara-Reyna-RESverifi.pdf": "C8775DB0-C40A-4E1D-B88D-820D5170BBDA",
    "JLockwood-FellowVerif.pdf": "C516B13E-ED9A-40AD-8105-7D8D3DAF05AF",
    "JLQ- CV 2023.pdf": "361009E9-1309-4210-8C08-E125B8FCED83",
    "JLS_CNS.pdf": "14D3FC50-5043-47AD-A0BD-B1882F2289CD",
    "JMA Neurosurgery  permenant license.pdf": "F46793C6-CDEB-4841-8E20-6EC9EAD3B5D9",
    "jma.pdf": "3F553802-69E0-4F2F-9A74-85111C68AAD0",
    "JMartin.RESverification.pdf": "97480F67-DE69-49CC-BE72-73677BF772BE",
    "Jmates CV 2020_01.pdf": "EF898438-6B1C-47FD-A696-AE84CC5F1043",
    "JMcMordie.emailverification.pdf": "4C88FAE1-674D-4C44-BA9A-C25C328FD972",
    "JMFrerich CV.pdf": "A42DDE6D-FA36-4D33-98B2-5EE089274089",
    "JNeira-RES.Verif.pdf": "A456D704-043B-4DC9-A6FB-79F9C1C52041",
    "JNH_4_2022 CV.pdf": "F8FE7412-5136-4601-AC70-6EED242A4D4B",
    "JNS_Cert_emu.pdf": "519394B0-B556-4009-9CDB-D0CCECF9ED01",
    "joagyei.RESverific.pdf": "02410CA2-83B3-4E7F-B737-89EE97510479",
    "Joanna Gernsback 1-7-2021 CNS Leadership Institute.pdf": "BF0BF40C-0829-4281-8443-68E02D1EB0EB",
    "Joanna Tabor Letter.pdf": "EB4167EB-DADD-4C3F-90C2-0C79B53D9322",
    "Joaquin Alvarez Tirado-RES verif.pdf": "CA07F306-B5E2-4E10-86BD-707A9E518591",
    "Job CV Sam Asante 032023.pdf": "0C53627A-CF23-4B53-ADBE-B3659AF1394D",
    "Joel Stary Neurological Surgery.pdf": "B1B31C3D-29EF-4DF9-A4D1-8BB5E95B4CF0",
    "Joel Thorson Neurosurgery APP Resume (1).pdf": "A9494454-735E-4A5C-9926-C0BB417B33EC",
    "joel_martin_cv.pdf": "31B367B0-8CE1-4725-A75F-FF69B7D3CFB6",
    "JoGoh.RESemailverif.pdf": "4F3188D7-5270-49E6-B43F-BE10D0CC0D0E",
    "JOgunlade-RESverification.pdf": "5921C53A-91C0-443A-B425-14E7EBB57950",
    "Johan Lee LOR km-combined.pdf": "C6C8F5DA-90A0-4C19-AE46-1A0AC98B37D2",
    "Johan Lee LOR km.pdf": "ACD677C3-D215-4188-9CAB-1DA2FA5F19FA",
    "Johansen Phillip Letter of Good Standing 08222212.pdf": "4D12882E-E0D1-482F-AC0A-D4D283B2E6DF",
    "John Berry.CV.pdf": "3B9DDCB5-1D89-42F8-ACF4-063FC7722B39",
    "John Hawkins-RESverif.pdf": "79C9431B-B704-455E-82D7-82CDD13D8E1E",
    "John J. Fromen LOGO with slogan.pdf": "49A6A9E7-2424-424D-AD37-1644DA96D748",
    "John Kanter-2ndFellowship.pdf": "E5283FF4-2BD8-4D3E-B2E5-73E0490EA3A0",
    "John Kanter-FellowVerif.pdf": "542A252C-5B00-4022-9877-CAFA23076AFD",
    "John Kiessling-Fellowship.pdf": "B64131DB-1C44-4366-A049-73078CF1E136",
    "John Kiessling.pdf": "28C912C0-F6BC-49DB-BD77-B1FB74C6255D",
    "John Kuo CV.pdf": "CD6097F5-F32C-47D4-B5CB-F35C157B4C96",
    "John Kuo Nomination Letter.pdf": "75322241-AF3A-48A3-A0E8-BD2275950273",
    "John Kuo Recommendation Letter 1.pdf": "EDFA4D7F-6EEF-417F-8F28-0B8107FD815A",
    "John Kuo Recommendation Letter 2.pdf": "D0B17D28-431A-4917-B070-C32068D8A519",
    "John Lynes Resume July 2022.pdf": "563A2B56-83C4-4A82-9DAA-AED157CF8BC2",
    "John Muse-FellowVerif.pdf": "1D09A7DD-C0C5-4461-A183-8245D0C1E498",
    "John R Williams_CV_4_1_24.pdf": "0206277A-3E08-48A2-85E0-4D4838812064",
    "John S. Kuo MD  2019.09.18 CV.pdf": "E409FBE7-73DE-448C-AEE8-B51A0272680A",
    "John V Wainwright CV June 21 2022.pdf": "800604BE-B96D-42C5-A24C-9B5302A310A3",
    "John V Wainwright CV Sep 12 2022.pdf": "CB7918F7-70D1-4A8A-954D-CD6DAFC1A778",
    "John Williams-RESverif.pdf": "CCE592EC-5E68-43CD-83CC-DF9CBC0E04CA",
    "John Wilson.pdf": "F60C9C68-4BF9-435D-A050-8DD3DD4AEB48",
    "John WMC Residency Diploma.pdf": "1430EA32-D40C-4B21-A814-DE8571C44C3D",
    "John YK Lee 2021-06-21.pdf": "D78D5EDD-6E8D-4C89-B39F-7F2A6BBF35F0",
    "John Yue CV 2022.pdf": "3437BAA8-6688-48D4-ABBC-5AFCF7226E83",
    "John.GachianiResume2022.pdf": "51B21F2C-3474-4765-8B9E-20D6A17BAE8A",
    "John_Kuo_SNS_JHH.pdf": "3BFCC51D-1E65-43FB-8CAC-3030A95CF7BE",
    "Johnathan Wasserberg 10.1.2022.pdf": "301E2E0E-3E6E-4DA6-87B1-71032B864A55",
    "JohnNaUCCOMEnrollmentLetter.pdf": "B1C47232-6B36-4974-A7D2-1A66A94301D8",
    "johnny efendy cv 2017.pdf": "897B3398-2DB3-4ED5-96DD-303BDED18F02",
    "Johns Hopkins University School of Medicine.pdf": "358B9783-528B-440B-B4F1-9C00DA40420F",
    "Johns Hopkins University.pdf": "340D2AC3-98A2-4853-9423-22E9A1A21EC2",
    "JOHNS HOPKINS UNIVERSITY_enrollment_certificate-Morgan Beckett.pdf": "838E8E44-359D-4E44-8AA7-8991200F7981",
    "Johns Hopkins VER.pdf": "552BC787-67A7-46E9-9F0A-022B4234CC4F",
    "Johnson Reid 2.12.21.pdf": "CE5BBD65-CE48-4A6E-B013-60CAD6D54B58",
    "Johnson Status Confirmation 08 29 18.pdf": "D77B3609-3474-4643-A89B-5198E9D0A5B1",
    "Johnson_ASSFN_2024.pdf": "DBD29F02-2D34-4556-A2D1-8D432904871E",
    "Johnson_FWLNSchol_2021.pdf": "879ECA6E-2DBB-4355-8FE8-A3D05E79928C",
    "Johnson513_CNS_2022.pdf": "B5BA8878-B827-4EAD-9D7C-CE74B59328B0",
    "Johnstone MS Exemption.pdf": "CFD3735D-4C84-4A07-B220-68BB9AD7058E",
    "Johnstone Thomas - Good Standing 1st Year Student.pdf": "81DAE07B-BF54-44E8-BFF1-88B21257DF12",
    "Johnstone_CV_28Feb23.pdf": "3CB5D594-E531-4200-9730-805E05D18D94",
    "Joiner_E_letter of good standing.pdf": "87A91E19-D4BA-40D7-A544-DEBD9AAAB4F0",
    "joining.pdf": "EE2BD2D0-941D-4DFF-A62C-856A95E8ADF2",
    "JOINT ADMISSIONS AND MATRICULATION BOARD.pdf": "9FD42379-E7DA-4ECF-8466-884E2A29E00C",
    "Joint CBV Section 2019 Poster RAPID overview.pdf": "D0515B3B-A35A-423A-80B6-1BBA439271AE",
    "Joint Section on Tumors Newsletter - September 1992.PDF": "9BBF5D79-44BB-4ED6-A62D-34E759A253E2",
    "JointSurgeryMeaningfulUse031510.pdf": "ACEAF814-FE7F-45C1-823B-F900904683DF",
    "Jokonya CV.pdf": "48347366-2BAD-4B29-9178-ADC4F3DB5D3F",
    "Jokonya VER letter.pdf": "9BC21F09-7771-499B-8C7C-FE2EBD48FB49",
    "JOmar Santellan.emailverification.pdf": "03B4C2A8-A97F-4171-8A96-B1A6CD3DCBCE",
    "Jonas_Margaret_GoodStanding061020.pdf": "C0DB7A15-1017-426A-B94A-8356485B7AF3",
    "Jonason letter emailed to student 9.12.22.pdf": "D3CF5016-3461-4F46-83B6-DB9C66655570",
    "Jonason updated verification letter CNS.pdf": "9299AE27-B2AB-403C-BFF7-C7AA57B6DFD9",
    "Jonathan  H. Sherman MD CV[2022-update].pdf": "A4F2B3C6-7991-4A20-BA3D-3E211E7F949A",
    "Jonathan  H. Sherman MD CV2022-update.pdf": "3AE2D177-01B8-4B8B-8DA5-6C2F1F61E8B7",
    "Jonathan Chainey.emailverification.pdf": "29CC6EE8-E2F6-47DF-94A1-E3126596F716",
    "Jonathan Collard de Beaufort.pdf": "C1906E6E-FB6C-48D8-8BED-51EDEECF87EC",
    "Jonathan Couch CV.pdf": "880C2BF1-9241-47C2-8182-0790997A4F56",
    "Jonathan David Anderson  - SGU Proof of Enrollment for Immigration.pdf": "7BADB01C-48DF-4D09-AF12-E2BE1A9F6411",
    "Jonathan Eaton CV.pdf": "6C40107E-FA7B-4434-9372-0F76133EE6B1",
    "Jonathan Garst-Fellowship.pdf": "A829AED1-23DE-45DF-8512-C8D0A41E1CF8",
    "Jonathan L. Wilson - CV.pdf": "65991BCD-153C-482B-9908-5E75C12BE3EF",
    "Jonathan Lee-FellowVerif.pdf": "AD38F3D2-26B1-42F3-ABA5-62F5D9AEA4E3",
    "Jonathan Poggi-FellowVerif.pdf": "3FD8A60B-3050-42AE-9708-4FAD010875CE",
    "Jonathan Sherman CNS leadership course_Letter of Support.pdf": "63A5855B-8151-41AF-AFE5-79B4668B2604",
    "Jonathan Weyhenmeyer-RESverif.pdf": "5AA23B7B-F9EB-4761-9750-EFCC38DD3B2A",
    "Jonathan_Howe_Resume.pdf": "6ED81834-199E-4E4E-A0BC-FEAFCEBBF107",
    "jones cv.pdf": "B34E50E3-947C-4B34-97A4-CBCBF9EF3611",
    "Jonzzon, Soren Letter of Good Standing.pdf": "BB27AF1A-3A23-4EAE-B49D-9398EE231BF9",
    "Jordan G Roberts CV.pdf": "B15F4F00-4CB6-47D7-8B6C-EC6D36AA1DF6",
    "Jordan Iordanou-Fellowship.pdf": "72075C70-6935-49EC-9783-6751959A42B5",
    "JORDAN Microdialysis Innovations.pdf": "B54690E8-0DD3-44DF-9601-347AAB416226",
    "Jordan ShuntCheck NPH.pdf": "1DE1CB17-8E0E-45D6-9CB4-DD6719EC170E",
    "Jordan Xu NEUROSURGERY Publications 2021.pdf": "627AF65C-29EE-428A-9E49-09F6945B3F94",
    "Jordan2.pdf": "41397FCE-A930-4C1C-A26D-803DF0CAD655",
    "Jordina Rincon-Torroella-RESverif.pdf": "BD394E07-B1D4-4556-ACB3-860141781DE2",
    "Jorge Angel Arguen.pdf": "E28F7B4A-DDAC-4D36-8FFF-5F0363BF91F9",
    "Jorge Brumatti - Diploma Neurocirurgia AMB.pdf": "D5F3C263-B6F3-463A-B8B2-4CAE458841CA",
    "Jorge Letter CNS.pdf": "E566DA15-0BF9-4583-8E32-13EF234E6639",
    "Jorie Residency CV.docx-2.pdf": "DF7B1C0F-B218-45E9-BECB-8133C720A560",
    "JOSÉ ALFONSO ALVAREZ CASTRO 1.pdf": "09DF71AD-795B-4DB3-9300-FCD2AC974CD2",
    "José Carlos Rocha Villegas.- verification of enrollment.pdf": "41459BF2-C454-473B-A2B8-96A79C866D17",
    "JOSE CHANG CV AND LETTER.pdf": "5A2DB91B-C173-4C1A-B6F4-C650864567FC",
    "JOSE CHANG CV.pdf": "1EB9CCC3-D936-4D0C-A2B1-245B19D79387",
    "José Manuel González Martínez_CdMx_INNeurologia.pdf": "0A3CAC17-A9B4-442D-B670-37A0105C2E12",
    "Jose TorrestPacheo Resume 2021.pdf": "14E9A7BB-8EA0-4208-8B18-16F07305A5D8",
    "Josefina_Gandica Chacón.pdf": "CE6DA8BD-F529-443A-824D-D2EE2A3F708D",
    "JoseFranciscoSánchez Sánchez-RESverif.pdf": "B42853F4-D8BF-4185-8BA0-9F7CBE25EC61",
    "Joseph Bell IV- Fellowship.pdf": "5D527B85-E54B-4FDA-8389-A4C59788080E",
    "Joseph Domino-FellowVerif.pdf": "65A3F1B3-CF34-4668-B0D6-3682ED0EF1F9",
    "Joseph Driver-FellowVerif.pdf": "65C0355A-F934-4A2F-B1A2-06CA789BE70F",
    "Joseph Falcone-FellowVerif.pdf": "2353C26B-8E3B-4E67-B534-BC8E64E8976A",
    "Joseph Georges-RESverif..pdf": "DF21B7F6-3CE8-4A60-AC75-80D0E39B20E8",
    "Joseph Herbert-FellowVerif.pdf": "983376EE-8187-4059-992F-5438DCF59889",
    "Joseph Juliano's School Enrollment-Good Academic Standing Letter.pdf": "F3412C8F-1456-4076-84CC-83B442553AAC",
    "Joseph K Sullivan - Unofficial Academic Transcript.pdf": "8E75A3D0-F24A-4D2F-9F7E-75DC0D442CB1",
    "Joseph Menousek-Fellowship.pdf": "7DDE5C73-B340-4349-9A53-150DDFF7320D",
    "Joseph Menousek-RESverific.pdf": "46702A4B-AD0D-464E-AA0C-1F8E41C1E560",
    "Joseph Schaefer Complete As of 9.13.21.pdf": "540FB0F6-9EF2-4CA9-9F0A-A7C1BA2452B9",
    "JosephKim_MedicalStudent_EnrollmentVerification.pdf": "57AF8E40-A8E6-4F32-8FC0-4D80B9D46FB4",
    "JosePorras_CV.pdf": "FA39ED95-04F9-405B-832F-E2EF8015D72A",
    "Josh Bandopadhay VOA.pdf": "A82D0AB4-29F7-46F7-AA71-D8FFE46B9A9A",
    "Josh Loewenstein.pdf": "AD36A6BF-7866-4B8C-BA15-C272E09F9AE9",
    "Joshi%2c Hetshree - Aug 3.pdf": "9689AC73-57D0-4A24-8D1B-7A4782840B1B",
    "Joshi_verificatin.pdf": "3EBD948A-4276-4B5E-BC8E-6C304CE95770",
    "Joshua Burks-Fellowship.pdf": "0BB0A7E5-BFE3-4985-A21F-08CF81D64B93",
    "JoshWoodward.FellowVerif.pdf": "4359C455-48E6-4C2C-9EA3-5818797DB81D",
    "JOsorioMedSchooldegree.pdf": "283971F4-E360-476E-8CAF-7970EC41C448",
    "Journal Editor Operative Neurosurgery.pdf": "7430DA9E-46B2-4EF6-AEA9-FCA36B560188",
    "Jowdy Residency.pdf": "6880FD31-2A1C-4EA7-8C72-DD39F2084AB2",
    "joy resume 2020.pdf": "36404FC6-E6A1-4E59-9607-F9DF90992706",
    "JP Kolcun LOR 10.28.2022.pdf": "04CCB504-E1FB-43B5-9E1F-7464A912E06C",
    "JP_DadufalzaCV.pdf": "EE7E9A6A-4AC2-4F8D-B4E5-2975E87603E0",
    "JParker.RESverf.pdf": "65A11A45-D673-41AC-89B7-500E54EAA137",
    "JPellot-Cestero.emailverification.pdf": "AD5945A3-5C53-4621-AEC8-4C1349E45154",
    "JPGK CV.pdf": "84CD4A39-EA1C-4DDB-AA52-3131C8594998",
    "Jpn Neurosurg certificate.PDF": "8BA79639-CBEE-45E5-A72C-C6FBD6B7DD34",
    "JQuon.emailverification.pdf": "95827F9A-5051-4C7F-BE07-024229327FFA",
    "JR NSA letter.pdf": "F02F2F45-CD50-44F9-9ED9-0E485F1B29C9",
    "JRCWMC-ResidencyVerif.pdf": "AE844098-872D-4D0F-A3FC-4FA9C438A55D",
    "JRoach Lette of Reference_Boop.pdf": "154C2150-CD6C-4473-B0CD-A06047C52D69",
    "JRP CNS 2023.pdf": "599BB455-BEE9-4E9C-B9BD-64B85DAE323F",
    "JRT 2023 CV copy.pdf": "1A6A696E-16D6-49E8-AE97-3F8947D5C967",
    "JRT 2023 CV.pdf": "C3FDAD41-7568-449B-BA80-1795745DCC25",
    "JRW CV April 2020.pdf": "E3FB54DB-7173-46E7-A186-B1BC4258F859",
    "JS Heat Map w_ Graphs 6.6.21.pdf": "90BD4B6C-72B9-44B1-B9A7-E0B24F5081C3",
    "JSarmiento.email.verification.pdf": "1FAB6048-AD0F-4458-B81E-041FFB2CAFA1",
    "jscvs19_cf_abstract_guidelines.pdf": "72DBE986-F5A9-4A4A-9D45-F837A19932E8",
    "JSokolowski.emailverification.pdf": "A1C14D54-E635-4E94-BC91-676E3597F582",
    "JSonig.Residency Certificate 2016-2021.pdf": "14C55FF5-74DB-4910-BC5F-A3E174058F8B",
    "JSweet CV 2-2022.pdf": "999D2291-2FDF-4960-8C12-56A58283013D",
    "JT_NeurosurgPubFellowship_ChairStatement.pdf": "137514E4-0CC2-4B4D-99F9-98903DBCA846",
    "JT_NeurosurgPubFellowship_LOR.pdf": "55C60578-2A65-49C9-B11B-F70B35B12C4F",
    "Juan M Valdivia-Valdivia CV FEB2021 PDF.pdf": "2BDD2D50-9368-44D0-A44D-270D0F30B17F",
    "Juan M Valdivia-Valdivia CV July 2022 PDF.pdf": "61ED5570-D629-4E3E-B1F8-5AA6D5A59475",
    "JUAN MORALES RESUME 2015.pdf": "68440712-6F01-4B0E-B53A-2CCE722E5B2C",
    "Juan Osorio Cardona.pdf": "0CB4A502-BE31-48F9-A440-951645BFCAAA",
    "Juan Ruiz Letter of Good Standing.pdf": "B931A766-08F9-438B-A70B-DF1682E25125",
    "Juanmarco Gutierrez-RESVerif.pdf": "683C1126-AC0C-459B-B00A-CA0FE89E7B33",
    "Juarez_D.pdf": "753C12E4-B0CC-4D38-8A9E-457C35100C02",
    "Jules Nazzaro Recommendation Letter.pdf": "3CD5F3A8-290F-4550-9BE9-8096CA1C85EB",
    "Jules Nazzaro Sponsor Letter.pdf": "2C743D20-15DE-40D9-9147-485EEA85EF96",
    "Julia Sharma CV.pdf": "9F963EE4-1C03-4780-89AC-AFCAB9366266",
    "Julie .pptx.pdf": "3F52614C-DC66-4EF1-B2FF-CDCE7DFB1F9C",
    "Julie Chan LOR.pdf": "3B3AD4E8-54A3-4EC4-BAA2-A600B3BF3026",
    "Julie Chan MD PhD Recommendation Letter.pdf": "FB326D59-4320-4F4A-9594-21F2CC49A6D6",
    "Julie Chan-FellowVerif.pdf": "DC2404EB-D2B5-4C32-989C-34D2503C1B90",
    "Julie Faulkner CNS Letter.pdf": "180CC3F7-1B26-4D6D-B3AF-3168349DD155",
    "July 10.pdf": "53AE8222-66F7-44AE-9FC1-009BB36DBBAB",
    "July 2021 article.pdf": "BD4EC295-0E74-411B-8371-598B88E6881C",
    "July 2022 journal article.pdf": "1AC047B0-15FD-428D-849A-73C7EC31AEA5",
    "July 2023 journal article.pdf": "C0E610EA-B66D-45B2-9858-66BD8F30A958",
    "July Podcast Article.pdf": "B465E89F-15BF-4655-B9EC-977D8479768D",
    "June 2021 journal article.pdf": "2B5E099B-3762-42A3-BA00-9486ABE68679",
    "June 2022 Journal Article.pdf": "1D5A6A9F-B554-45F5-8A28-43DE706B3255",
    "June 2022 journal club podcast article.pdf": "694B21BA-14E5-4385-B0D3-BB83900251E1",
    "June 2023 article.pdf": "FFD5FBF2-0447-4905-A63C-C8C4CB12594D",
    "June 2024 Journal Article.pdf": "499AD62D-D4D3-4B80-8D9A-42F92DF86E06",
    "June Guillet.pdf": "3B8AAD4E-89D5-4C71-B160-8CAE1A6304B0",
    "June podcast article.pdf": "6450B408-2985-448C-B85B-C4E39A07EE05",
    "june23.pdf": "A5868012-2BA4-4425-9AEA-2F14963382DA",
    "Justice Agyei.pdf": "E501F6D1-B93D-405C-A35B-7F92D8C1D305",
    "Justin Cohen-RESverif.pdf": "E2203627-8CF2-4C19-AD1C-AADB8D8EC5F0",
    "Justin D. Cohen CV.pdf": "32AA9D7D-9346-4E4E-9743-B386F570E41B",
    "Justin Lee-RESverif.pdf": "6C86C5A0-3693-4F4A-9647-49B7B313662A",
    "Justin Passman CNS Research Fellowship CV.pdf": "55FBE694-5664-4CDD-8D55-448B2630C732",
    "Justin Passman CNS Summer Research Fellowship CNS.pdf": "0FE70D92-F3D1-4C2E-AC17-86B9FACE840B",
    "Justin Passman CNS Summer Research Fellowship Detailed Budget.pdf": "03777F92-CCA2-4136-8B12-220B00FD8889",
    "Justin Scheer-FellowVerif.pdf": "CA23FCC7-50F3-41FC-843B-DD18EE604999",
    "Justin Verification.pdf": "74A98287-83AE-4E3B-8DDA-16FBBFF20194",
    "Justin Williams.pdf": "68D1DF27-5858-414F-B397-CFE08CA0FD75",
    "Justin_Lee_CV.pdf": "3E563AC9-F8D2-45C7-9181-ACA22ABDC1AA",
    "JustinSinger_CV2019 2.pdf": "C3179737-0A3B-47E3-BF16-CBDCE59A711C",
    "Justus Boever.pdf": "C9563D48-DE9B-4452-95C1-9FD646C0CAF4",
    "JVP pix2.pdf": "40E4465E-A5C0-4DF5-95E3-10BCEEFF6B74",
    "JW Residency Certificate.pdf": "064D251B-E501-4C0C-905F-CA5DEF2FAB94",
    "JWestmorelandProofOfEnrollment.pdf": "116C4DAD-0E18-4544-BEBF-BEC273493630",
    "JWilcox Enrollment Letter.pdf": "21B93C34-C5C4-478F-8A8A-637D48C44D56",
    "JWilliams.emailverification.pdf": "E6D192BA-A9AE-488F-9434-03A880ECF46C",
    "JWoodard_Letter of Academic Standing.pdf": "34900D20-377D-4571-B92E-361B0BB9BC82",
    "JX resume 2021.pdf": "17FB0D76-4BAC-4B36-B62E-050AED378CED",
    "JYeung.Residency diploma.pdf": "DF5A7E18-1F64-4840-8A36-8A6D27676738",
    "JYun_2023_CNS.pdf": "467CF52C-2F7E-4859-B08E-1B66E250F015",
    "JZervos.emailverification.pdf": "7A3D66A9-F2E7-4F49-9217-97ADB5E40DF0",
    "K Zimmerman.pdf": "22CE86A6-61C2-4240-B9B9-6B92F28FD5AE",
    "K. George M'21 Enrollment Letter.pdf": "7B5EA4AB-8810-40F7-ACE4-78133F9BCAD6",
    "K. Hewitt.pdf": "2F871DA5-F486-4786-A199-BD409FC3C23F",
    "K. Porche CNS Letter.pdf": "9C565932-59D4-4320-8BAE-D47A85BFD971",
    "K.Wu.emailverification.pdf": "61A39102-8594-4209-9117-98F0005E7E12",
    "K050965.pdf": "F77644B7-8614-4DE2-98E3-BBA2F8383FEF",
    "Kacheris Enrollment Ltr.pdf": "F7F90F9E-DE1D-4DD6-A361-2D2D08AF6D0D",
    "Kaculini Christian Good Standing Ltr.pdf": "EF29D8FB-92A9-453E-8B2D-2E0BAE86E191",
    "Kagithala.pdf": "D7C53E1E-A374-427D-B458-DF36424B9532",
    "Kahn.pdf": "FDBFD015-BBDF-43CA-85F7-F5FCA94DF2D7",
    "Kai Miller Verification.pdf": "3B68005F-EDD1-4CD5-A0A2-2F74A84C4F51",
    "Kaiser gsl.pdf": "8C71E5A2-165E-4AA6-9918-DE3CAE964B0C",
    "Kalistratova Good Standing Letter.pdf": "2EB11A21-3FD7-4929-AB4F-9DAC965D1938",
    "Kalyvas CV 2017.pdf": "CB44D490-B257-4520-B846-F1FB055F07E5",
    "Kamal R Woods MD CV.pdf": "EC8A9892-E357-4E12-AB3B-6105AC62080B",
    "Kamara_Allieu_CV.pdf": "CF657917-2D40-4BFA-8DD6-0870C3960030",
    "Kameda-Smith-2ndFellowship.pdf": "D62424D2-944C-4C89-869C-AEB0D8AB70E2",
    "Kamen Scott CMSRU enrollment verification good standing Ltr 2-10-23.pdf": "DA5DD66A-6EF4-41B9-A410-5A20F014E88F",
    "Kamil Nowicki-Fellowship.pdf": "6790278B-24A7-4AD0-87D1-BFA741B6C266",
    "Kandregula Letter of Verification - CNS 8.10.2022.pdf": "42F5D006-5064-402A-9BC8-0E97EFFAA0D4",
    "Kang_Keiko NEUROSURG 030920 Harvard.docx.pdf": "87A6C479-95F7-4076-A661-3D582250056F",
    "Kantak Letter of Good Standing.pdf": "A1770D78-C752-4934-BD96-B6C6696BC2E1",
    "Kanter Matthew.pdf": "D28E4EAD-9696-4FB9-A57B-E4E069B98F96",
    "Kaplan Meier octo sep surg.pdf": "42D84398-97EC-4249-97F5-BBA17597D230",
    "Kappel,Ari-2017-LOG.pdf": "2BB74013-1E3E-4A70-9ED9-A0CB9305E6A2",
    "Kappel,Ari-2019-LOG.pdf": "9030E800-C291-4B4C-BF8B-93582FAF952C",
    "Kara CV.pdf": "0AA88DEE-9923-4606-8400-11E429D50FB5",
    "Kara Parikh_CNS letter.pdf": "43743E2A-5630-4BD2-9209-E7EF5FA12874",
    "Karas Verification.pdf": "479C55C2-2A58-451B-A833-9ED1DCBB85D0",
    "Karenna Groff.pdf": "0BA46CA4-895D-4759-8255-A724DA2469A5",
    "Kari Andersen CV.pdf": "E8F33B64-9559-40AF-ADE4-D57370986614",
    "Karim_ReFaey_C.V._March2019.pdf": "95B6F7C7-F490-4F71-8248-6E3A25FFC089",
    "Karimi_H Letter of Good Standing.pdf": "D3DAAC56-CCB3-458B-947F-22BDBD8C677B",
    "Karin Swartz SNS 2023.pdf": "C01733AA-A2ED-41DE-8F93-B05661941CDF",
    "Karina Moreno CV.pdf": "0936FEF1-4F02-481D-B128-8C7563DA10EE",
    "Karl Schaller Nomination Letter.pdf": "EACF78FE-45F8-4CE2-B903-8FFE31882820",
    "Karl Schaller Recommendation Letter 1.pdf": "DB2E3C2B-7E9D-4526-83E5-85FB02BED27E",
    "Karl Schaller Recommendation Letter 2.pdf": "E619CAF8-4B30-4DFD-AF3B-41C940657899",
    "Karl Schaller Sponsor Letter.pdf": "59EF3CD2-18E4-4A01-B520-70F7BF33E7B1",
    "Karras, Constantine LoGS.pdf": "0A1B4815-5B8E-4F7A-9C48-3C3A55C28EB6",
    "Karthikeyan Gokul Letter of Good Standing.pdf": "6817E979-BB3E-43EE-929E-BCCB1313DA0D",
    "kashkoush-letter of verification.pdf": "31866E2A-050F-4CA5-87CF-55934858BF28",
    "kashuba_resume_2021_JUL.pdf": "27E5BC36-E919-4C13-A9C8-C0F5DF9A82EA",
    "KAsmaro.Residency certificate.pdf": "F6EA57A8-3208-4BF7-BEEA-E44A95589CA6",
    "KassiciehA_LetterofGoodStanding.pdf": "41A1B22C-C418-43F5-AB29-11AC7B304672",
    "Kasztowski.pdf": "787F497D-4FCB-404F-BD3A-172C9EB57AF4",
    "Kate Gelman CV.pdf": "BC9CE765-6704-4512-BF26-F092D9BB29BD",
    "Katharine Bills Woods PA CV.pdf": "F5AF20D9-5F15-424E-8A00-DA6D4A66B27C",
    "Katherine Kabotyanski.pdf": "FC33068A-0B0F-457C-85A4-E7D3924A1B1D",
    "Katherine Ott Dean's Letter.pdf": "5A65BA13-D398-42FD-A826-B9CDBFD6C16B",
    "Kathleen Kelly LOGS.pdf": "10EC4F7B-506C-4E86-AB8F-FC899969DAB5",
    "Kathleen Ran Certification Letter.pdf": "41DA11C0-9AFD-4707-8331-2F0E82EE5D84",
    "Kathryn Kearns Requirement Letter.pdf": "7D93093C-9EC0-4E5A-A9B8-1D691E0E6F60",
    "Kathryn_Kearns_CV.pdf": "8C8A7E06-D8A3-469F-8C30-AE9F35394550",
    "Kathy Guzman award nomination_ARANS.pdf": "560AD207-0E6E-4D5C-A7A1-C58ACEC1DF11",
    "Katie O. Orrico has shared a payment remittance with you..pdf": "DB846B06-91A6-43B3-BCB1-CF46E0D1331D",
    "KatrinaFirlikCV.2023.pdf": "5FF90632-C60A-460E-B4F3-794A92221F6C",
    "Katz Verification.pdf": "E8640F00-73EE-4862-81BB-A8663755DE5C",
    "Katzir Spine Fellowship Certificate.pdf": "8453E2E7-E0A2-431E-B00B-508CC0673133",
    "Kawase, Takeshi.pdf": "70DB67E3-6763-47AA-9444-E2B1391AA635",
    "Kayla Byrne CV 5.3.24.pdf": "D56A292C-8D9D-458B-BA8F-17B63132434B",
    "Kayla Lauren Chin.pdf": "45F771B0-BC6C-4458-902E-542E5DF24CDF",
    "Kazi Fezaan_letter.pdf": "F8846E5B-44D5-4FDA-ADBD-1B34058B450C",
    "KBecker_2022_AM_Poster.pdf": "76D1FFFA-C099-4B92-AAF8-EC035B0E8729",
    "KC_Enrollment Verification.pdf": "DE7982B5-46C7-4CAD-919F-FE2FDA612C03",
    "KC_poster_final.pdf": "60F868BB-EA2F-4AD9-B5AC-28BCDC72E3C8",
    "KCU CAS CEA poster.pdf": "EC5EA2FA-A3F0-4CFA-B83C-BAF8D00A46B5",
    "KDyson UF COM Letter of Good Standing.pdf": "EB43542D-F41E-4B6C-BDA4-8CA5ED5DF06E",
    "Kedda  J 5.31.19.pdf": "FBAC01EE-6531-427C-9F93-EEFC006EA64A",
    "Kedda, J 5.31.19.pdf": "9ABDCB52-9C8C-4BC8-B3B6-A066A2D56581",
    "Kee Kim_CNS 2022 Hybrid Poster.pdf": "3DA8C5AC-A3A8-4D57-A424-3A5BE8A1DDA1",
    "Kee Letter of Good Standing.pdf": "E8BF402F-74F7-40A8-B62F-EB2A22BABBD8",
    "Keenan Piper Complete As of 1.5.22.pdf": "193284A7-4BC1-404A-9529-BB5D65600F84",
    "Keister_Alexander_LOGS_M2.pdf": "C5FAFA3D-0B0D-4828-A2B2-60948EB898FA",
    "KEL20994.pdf": "0765E9B5-AB94-4A96-9753-95539F568FC2",
    "Kelbert_LOGS.pdf": "017ACF3B-4B07-43CD-B7CE-B9277AB6A353",
    "Kelly Ryan Wackerle Murphy_9.12.2023_CV.pdf": "69F1A00F-3C75-48FB-9D80-EC67CA78D4D6",
    "Kelly, Pat.pdf": "BC99FC01-994C-4582-8C9D-2B0B6239B5FA",
    "Kelly, Patrick J.pdf": "637D046F-934D-4F40-9156-103A3920670F",
    "Kelly, Patrick.pdf": "BFF18FB3-6B52-4434-9C7C-46E2500997FA",
    "Kelsey Cobourn Good Standing Letter-2.pdf": "7E9769F9-0A66-4A3C-975D-EF34844B1D7F",
    "Kelsey Hundley-FellowVerif.pdf": "7D928DD0-E49C-4325-B52D-5D8D9743B27E",
    "Kelsi Chesney's Letter of Good Academic Standing.pdf": "F6242AA3-89B5-4B58-8954-C00534C4C580",
    "Kelsi-letter of support.pdf": "063315E9-455B-47D8-A9AE-5EC35CBFE812",
    "Kelsi_Chesney-letter of support.pdf": "58EA81D7-9756-46D6-BE26-81687A7DD477",
    "Ken Foxx cv resume.pdf": "AC97FD94-A661-42EC-B832-6D94795AD248",
    "Ken Maynard-RESverif.pdf": "CABDF461-C972-483C-BED1-A0E641CCC576",
    "ken porche cv 8_9_23.pdf": "990013F1-275B-4009-A468-42A993100DE7",
    "Ken Porche-Fellowship.pdf": "94B1DCFC-AA01-4C4E-B636-C91225674BFF",
    "Kenny Yu CV Apr 2017.pdf": "88E44A37-3D0B-4522-A179-CCEDFE5C5BC7",
    "KentLMarshall_CNS_Letter.pdf": "2B7AA77C-9264-4577-B6A4-2EE372DBEDD7",
    "KentSt_CCook_Johnson.pdf": "359CC338-CE0F-4CCE-93F7-A7CFC7A0CCE4",
    "KENYA SPECIALIST REGISTER CERTIFICATE.pdf": "B592B0BA-AC96-4328-ADDA-D06ADA2B4F37",
    "Kerezoudis_CV  (2).pdf": "E2F091AB-4DDE-411F-9CE5-4C245D5863DA",
    "Kerezoudis_CV .pdf": "DEEBC32C-A66F-43DB-9C50-2F9DE355FC68",
    "Kerezoudis_Panagiotis.pdf": "EC230531-B071-46BA-83AC-E89EC8A755A6",
    "Kern_LOGS.pdf": "6FFCDFA7-84D8-4CF1-BE23-2BE649197844",
    "kesarwani.Residencydiploma(UAlberta).pdf": "5698C812-EDDF-4444-9C37-1BF07990652A",
    "kesarwaniCNS2021poster.pdf": "D3A4BA0F-E9B1-4684-AA83-98011E548765",
    "Ket Verma CV Oct 2023.pdf": "BA0B53D6-ADE3-435E-84E7-0F2554D03DFD",
    "Ketan - CURRICULUM VITAE.pdf": "14D5264D-7308-4543-AB0F-4D4DD2CC2C61",
    "Ketchersid Morgan Class of 202422.pdf": "8B4B00A5-2AE0-4BD8-A9FF-1011CEE55C88",
    "Kevin Ding--Letter.pdf": "2F98A124-C23E-4975-A978-C5D153E98D71",
    "Kevin Kumar-FellowVerif.pdf": "36278DB5-C94B-4E4D-8659-1FD5685FEC1D",
    "KEVIN SANCHEZ TOACHE-RESconfirm.pdf": "9424E41D-91C6-4E4A-B628-FD997A0F6946",
    "Key for Images.pdf": "70FE4B42-E826-452C-AC32-BB37CB6E4B7F",
    "Keyan Peterson LOR.pdf": "A893A27B-4029-4F15-A8AA-D3B6573B281B",
    "Keyur_Shah_CV.pdf": "5BC0C680-1F40-4A4D-A993-A0A9F1B80B1A",
    "KFCV.pdf": "93F05052-D0A2-4B55-B9C0-38CDF65E2086",
    "Kfoury-Beaumont CV_04-04-2024.pdf": "E0A13920-3535-4EE8-B0D7-3136299FAA1A",
    "KFS_2023_Peds_DigitalPoster[5].pdf": "607B5DA2-AC78-419E-B28C-BBCD1D2BC499",
    "KGassie.emailverification.pdf": "C36FE389-1C1E-4EC8-B369-F886B8D6AEA9",
    "Khahera Letter of Good Standing.pdf": "C1D5BB84-9311-4727-9193-44DD23DC4A97",
    "Khalafallah.pdf": "F6E78302-03F3-49FC-824C-D78BF0F9084F",
    "Khaled Alok - AUBMC Residency Certificate.pdf": "34B86EC5-9E24-4694-9D5F-6B667A035633",
    "Khaled Alok CV August 2018.pdf": "3A21F798-130A-4B1F-9E6A-71469A36C403",
    "Khaled Alok Enrollment Letter.pdf": "8E546D00-AE0B-45A8-BADA-5EB49CFCCA2E",
    "Khalessi CNS Nomination CV.pdf": "03CBE307-E6D3-4E7E-BDA9-DB663FF4B509",
    "Khalessi CV.pdf": "5032939D-4ECE-4409-A799-5E06A594F7E2",
    "Khalessi CV_Update_2.24.22.pdf": "30ABEDBF-AA78-4F09-95C8-15B24FA3FB6A",
    "Khalid_Syed_CV_2024.pdf": "51AAB0E7-C271-48BE-8901-9C92831F7CA8",
    "Khalil  Al-Qadasi -2ndFellowship.pdf": "30BB4152-151C-4CA6-8496-BD92024CD3C5",
    "Khalil Receipt.pdf": "8B0CE3E2-5496-403A-90EB-D36CC5B53231",
    "Khalili Bobak_CNS.pdf": "DFFECB5D-C570-41F8-9DB0-8313EA26CD32",
    "khalili Hosseinali LOR.pdf": "EFBF84A2-1282-4856-9C65-477D3F433BED",
    "Khan.CVandGrant.pdf": "71AEAC3F-6053-437C-A70F-D20AB9BA64A0",
    "Khan.LORS.pdf": "D0DD2295-E497-4D40-95D1-B7E92128EAD6",
    "Khan_CNS_Letter_Good_Standing.pdf": "3A9A6A6B-DF38-491A-B0EB-4AB563FDF6AD",
    "Khan_CV-Jun21.pdf": "CD5FE44D-D4D0-49C7-88F4-4468A65859BF",
    "Khanna Arjun.pdf": "4BC7A9B3-2B76-4B14-9E14-BFC467961668",
    "Khanna-CV.pdf": "95851509-B0E3-4D05-A8C2-20BC743848D7",
    "Kharbat Abdurrahman_.docx.pdf": "F0BDF45A-0D91-4D1F-A2E3-82C4E3534CCE",
    "khart LOR.pdf": "75EA8AA5-F57D-4C83-83AE-FC2B54C3D89D",
    "Khishen Sarah - Letter of Good Standing.pdf": "2ED08925-80E8-4D88-90FE-59EA11281B3D",
    "Khoi Than CNS LOR 2021.pdf": "2C658D97-CDCD-465B-80F8-739DE0D0F975",
    "KHuang CV 2022.pdf": "3B581CAA-C4B8-4A11-8343-F2F981C09035",
    "KHuang.residencyverification.pdf": "26968E23-BB5F-4221-BC14-23D5A2CBEDAA",
    "Khurana-CV-1-09 copy.pdf": "D0B329F3-6F73-4E1E-B5A1-AAEB3963771E",
    "Ki-Eun Chang-RESverif.pdf": "A7FD0087-5014-4331-A438-EDEE24A4F1E1",
    "Kiarash Shahlaie CV.pdf": "26C6BB7A-9516-4AB8-987C-3CD5AA13BE51",
    "Kiarash Shahlaie Recommendation Letter.pdf": "6D8F0DA1-B5B4-4088-91B9-428AA8E12922",
    "Kiarash Shahlaie Sponsor Letter.pdf": "1F6A858B-84B3-474F-9E1C-BC3D0D088750",
    "Kicielinski HMS CV 1.20.22.pdf": "3C81F868-9E57-4B03-9D3A-86E23B4BD640",
    "Kicielinski HMS CV 2.9.24.pdf": "E2C41506-A65B-49A1-A1C1-8A8D6A84F4C8",
    "Kicielinski_Best Case Worst Case 2024.pdf": "0823A492-AD7A-42F7-A119-313B2EF039CD",
    "Kicielinski_What score to use 2024.pdf": "3F208DA6-96E7-4152-9932-120A15342865",
    "Kicielinski_When not to do HC 2024.pdf": "FA3DE7C7-D0DE-4223-AE4B-60A63455BCD0",
    "Kickstand Figure 1.pdf": "9452996B-70B6-49F4-A8DE-BD6BE3615331",
    "KID poster CV section.pdf": "6DD31FA1-475E-4480-8637-8F202BB7CFF1",
    "Kidwell_CNS letter.pdf": "65B7A83D-AEDB-4A66-8F31-498BEA384B17",
    "Kilgore Madison - CME.pdf": "56751CBF-5C7D-4965-B6E6-C0683E747634",
    "Kilgore Mitchell good standing.pdf": "DB4B4114-29F7-40D4-A65C-19BF33948FBD",
    "Kilianski J - Curriculum Vitae - updated Aug 1 2022.pdf": "53339C44-ED1C-4702-A13C-73B8667D401A",
    "Kilianski J - TMB - proof of residency completion.pdf": "3CCF938B-533C-4F05-9881-ADDDEABD94CD",
    "Kim CV 3-15.pdf": "EB44DEB8-0808-4252-BF46-32FEAF384740",
    "KIM DAHAM-Residency letter.pdf": "295BCBA6-A5FE-47D1-92C1-3F30F53BF6FC",
    "Kim John-Letter of Enrollment & Good Standing.pdf": "66E6159B-0EDD-4AE0-94D2-70EF5FB17C7D",
    "Kim Macon CV.pdf": "022A4385-B60B-463C-8317-066929448397",
    "Kim P CNS_HvL LOR.pdf": "DECBDDEE-7EF4-4FF4-B2FD-00B50677EAB0",
    "Kim Samuel 1.pdf": "CD0DC2B0-4372-4508-93B6-A91525B69A11",
    "Kim Virtual MRI Poster.pdf": "E047A82E-9372-48F8-9CE9-0F1BCD0EE55C",
    "Kim_Residency Diploma.pdf": "AA1F225F-7101-49BD-B73E-27C073E8D548",
    "KimAlexanderLetter of Certification.pdf": "F6DD3D87-F39E-40FC-9D90-DF063E0248CC",
    "Kimberly Ashayeri-FellowshipVerif.pdf": "9A22C164-ADBD-4990-BCE3-0D11A114A5E0",
    "Kimberly Tramte CV23.pdf": "D1C00B89-F1D0-4FB0-A452-A9147963C99E",
    "Kindrachuk.pdf": "87F2F783-3D1A-4391-9CEC-4A9707C2CDD5",
    "KING EDWARD MEDICAL UNIVERSITY LAHORE.pdf": "2C227784-BA36-4034-8D9B-5D0DBE95DDC3",
    "King Hunter AMES.pdf": "00AA6B36-D133-460F-8799-8CBFE36315FA",
    "King Letter of Verification.pdf": "21C69ABA-2E3C-476A-BAFE-1765C57DB733",
    "king mwila 25 July 20221.pdf": "ACAFB361-6169-425E-9C06-8130754F6AB1",
    "King Noah.pdf": "2D17F545-9D35-4722-8844-9CC5E905553A",
    "King, Molly.PDF": "AF799FE2-7D1A-4DF4-A9BA-6B92605AA963",
    "kinosada M 2.pdf": "04757EAA-655F-4032-B134-9FB0584E5B18",
    "kiran nsi.pdf": "13682F65-D497-42CF-8073-7DD4D5C0952E",
    "Kirk Manley_Cover Letter_Director Marketing Communications_CNS.pdf": "70FD4403-F4A4-48F5-970F-52A8F9CEF868",
    "Kirk Manley_Resume - Director Marketing_Congress of Neurological Surgeons.pdf": "E20AF534-1931-4196-84A8-38DE42898A23",
    "Kirsch, Wolff.pdf": "7C44C4EB-CBC6-4FD2-A423-A09B80AE5A39",
    "Kislay_CV  29 jun 2020.pdf": "94D33211-0A7F-40E1-91AA-C8D25C4D4269",
    "Kitagawa Slides.pdf": "27446632-2B64-4FC6-AE7D-AEC9BAF7776A",
    "Kitagawa_Neurosurgical Emergencies Surgical Decompression.pdf": "3340524F-5A26-46E3-A700-D8E9AB465546",
    "kivancyangi2022 1.pdf": "80C500A8-AA11-4472-BADE-4632C675E457",
    "Kiziltug_CNS_Poster.pdf": "A3354900-4877-4809-8706-A832578B8A28",
    "Kiziltug_Emre_Good Standing Letter (3).pdf": "B5B2F2C8-A70B-4546-B8AC-468EB3450DBC",
    "KJK CV.pdf": "249ED808-3C26-4BF8-86E9-B65A9D23DDE6",
    "kjm_combined_biosketch_CV.pdf": "719D157F-97F2-49EB-A6EF-6D23F81B7924",
    "kjmdcv10-22.pdf": "7A1C6E15-7DB1-4360-A4ED-0D5F9F78E660",
    "kjmdcv2021.pdf": "1C797F68-7644-45F9-9F7C-DDE9113EAA84",
    "KKwan.RESverf.pdf": "ED58B030-F7FF-4940-A2F0-C4851EFF2D69",
    "kL3Il.pdf": "D255DE7A-5EE5-4B21-AE47-248FD28227F9",
    "Klein Benjamin.pdf": "0F3D68F2-789D-4E2C-BC25-9F3AD6965792",
    "Klein Sierra-Letter for CNS.pdf": "D3288E8E-FE1B-4F0C-A0F9-24AAD41DF121",
    "Kline Interview Manuscript (Full online version).pdf": "A4A57883-BE30-4519-BCAD-9D680D28CF48",
    "Kline, Dave.pdf": "C365D2DA-DBD6-4551-94E9-079156BC4CA0",
    "Kline, David.pdf": "BF206F42-3457-4103-9CBA-1C73825BFBD8",
    "KLINGER DANIEL (MC).pdf": "C9B5CB95-8172-4D2B-B8E5-6E418B99DD75",
    "Klugh CV 2022.pdf": "F8E8608D-7C30-47F5-AD5E-84FE81FEF3B3",
    "KM curve.pdf": "10ECDADF-4B65-45B2-9496-B3D001F9FEFA",
    "KM Curves.pdf": "04C2C562-1386-46D1-AEB7-BDA9C7784B3F",
    "KM_PFS.pdf": "A35C4CE5-EE66-468F-936B-6B4CB0217E5F",
    "KMaynard.emailVerif.pdf": "4E06EB85-8D67-4AD2-8886-801963BF917C",
    "kmeansposter.pdf": "F0098E90-5758-4879-B074-145F02448587",
    "KMM LOR 2023J. Heth letter SNS.pdf": "F4BB721A-B008-4EF6-8A7A-0CA11A89D7C4",
    "kmp CV 2022.pdf": "F6F12953-4C82-4919-AECD-F5EDD63D5FF8",
    "KMP Fellowship Cert AFN.pdf": "21EBE5E8-FD44-418D-BD65-ED6FAFAB6A37",
    "Knapp Justin L - enrollment verification.pdf": "D2E7F913-195A-442F-9B1F-ED6BABB97015",
    "Knickerbocker Alicia - Letter of Good Academic Standing 03.26.24 bls.pdf": "F928CBEE-4EF2-4523-8E8C-059F2C1FE417",
    "Knight CV 17th August 2019.pdf": "FAE3748B-597A-418B-912A-3E03FBF3957A",
    "KNS_SEUNG YEOB YANG.pdf": "93DE1A78-9F89-49C0-972F-952E47E081FE",
    "KNSA membership letter.pdf": "F40C8359-2BCF-4DAE-857F-B2D56BBABA69",
    "KNSS.pdf": "6A5A8F45-8DEE-4BE0-9D6D-545F4F090640",
    "Knuckey from harbaugh.pdf": "20724B35-E64F-4F2E-A954-DB13FEC6514A",
    "Kobeissi Hassan - Letter of Good Standing .pdf": "57ACA941-93E5-4E11-BCE3-94178B141363",
    "KOCAELI UNIVERSITESI.pdf": "5AD5C506-C241-42E4-A4E6-11EBAD502CA7",
    "Koch_CNS verification_5.11.22.pdf": "5E9E951D-0F5E-4D27-B959-57ABB7211E1B",
    "Koch_CV_02.22.pdf": "F42F86C8-0886-46B9-8501-C0D3838EEB63",
    "Koenig Proof Of Enrollment.pdf": "B2812E98-FD26-42CB-B7DB-27EC25053572",
    "Koester Enrollment Letter.pdf": "5B7A707F-D0B2-46F9-A127-84F1B1F1EB4F",
    "Kohli Verification.pdf": "68A564A5-AC02-4A08-AED7-678413E86C74",
    "Koivuniemi.pdf": "A3E17499-31AA-4C87-AF7D-74FE865A4DA6",
    "Kolahi Sohrabi Arian - Completed LOA 2-14-2022.pdf": "5DBBA9D2-9AB3-49A1-B807-638737B92908",
    "Koleske Joshua.pdf": "1FD46B19-5F47-4AA0-A66C-2D40E3235548",
    "Kondziolka, Doug.PDF": "551EFBF2-E206-44DE-8608-7DA32CBF7C2C",
    "Kondziolka, Douglas.pdf": "A974679F-06B4-44D9-AC29-0774ECC93814",
    "Koneru Manisha Enrollment Ltr.pdf": "A429D9E6-3870-453C-B290-9E506015740A",
    "Koneru_CNS2023_VizTraumaPoster_v1.pdf": "2908938B-2416-4591-A484-BEF60DD9A5CD",
    "Kongkham SNS Nomination Letter_Fehlings.pdf": "74A4EC2D-0DDB-439B-8E07-5291CB6B902F",
    "Konrad cv.pdf": "051D9304-76D1-42B5-AF60-47C54965699E",
    "Konrad SNS letter Schulder 8-22.pdf": "25DF59B2-04F0-4E0F-9796-F189AA4EB333",
    "Konrad_Peter_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "4AB34082-8410-413A-8ABC-AED167D3B8F3",
    "Kons Z.docx.pdf": "285C5EAA-5A42-4B5F-9347-012EA179B26D",
    "Koo_Andrew_CV_2023.pdf": "0C4F2AC2-33E0-4F0F-B1E2-7A0783C5BC7A",
    "Koo_Andrew_CV_2024.pdf": "688E3997-FD28-4D3D-9E7E-B7154FC02679",
    "Koo_CV_CNSDS.pdf": "C26A1DBA-FCF2-4862-B980-BABACCB0C973",
    "Koo_LOR.pdf": "049E41FD-8FF6-4EBA-AD67-3C9BF8A8FC1E",
    "Koo_LOR_MD.pdf": "0F4DC288-FA53-4973-B8E6-8E188EF528B6",
    "Koo_Neurosurgery Publications Residency Fellowship LOR_Feb 2024.pdf": "9C8C4F96-FE1C-4C16-BB1F-7EBB7025E778",
    "Koo_Time Commitment Letter_DS.pdf": "6B342156-CC06-4BED-8F6C-A20DAE3A6EBE",
    "Koos IV.pdf": "AB53D41D-F24B-4436-9486-D92F1D8215C2",
    "Kosarchuk LET.pdf": "5628A38D-84CF-4E5D-BDB8-F56924EA3C21",
    "Koutsouras George CV 11.2022.pdf": "0F633D6C-84DD-4C79-B88A-357B65015BCD",
    "Koutsouras George CV 12.2022.pdf": "40F1CC92-CAAB-4CD7-8F9B-0829A67E015D",
    "Koutsouras George CV 2.2022.pdf": "20FA528B-711B-4689-9A1F-5DD3398FFCA9",
    "Koutsouras George CV 3.2022.pdf": "7360BE66-55A1-44EE-95EC-C4E3589CD3AE",
    "Koutsouras George CV.pdf": "532FF0B8-244F-410D-8A5C-944A70FBC544",
    "Kovach, C letter of support 2 2012.pdf": "04F7989F-FB73-4B20-98EA-8BA0A048A093",
    "KPB_CV 2020_full_2.pdf": "A5E3A536-77BC-4A72-9600-C1010BF2B9C1",
    "KPB_CV 2022.pdf": "4AA57E2A-9CB9-4942-9074-9C7B4F0B2002",
    "Kramer Residency Certs.PDF": "57B9C42A-471F-4431-BCE0-D0FDAE0AA6F5",
    "Krause Katie CV.pdf": "C2BFC11A-52A1-4532-AB3F-0C7540497F97",
    "Krause Recommendation.pdf": "318540F6-B54B-49E7-82BF-9897617761F7",
    "KRavina-Res.verification.pdf": "D235AB57-29ED-4897-AF34-77E0FAE643D9",
    "KRavina.verification.pdf": "32319676-2E88-4262-8C1F-36954985E239",
    "Kremer Digital Poster CNS.pdf": "17E10E77-1760-4C4F-89A5-674D9D9B307E",
    "Krieg Letter of Good Standing 1.pdf": "4DBAC828-6E56-4730-B871-38EDEF413583",
    "Krishnamurthy_SNS_LOR092622.pdf": "41AE5BC9-EFE5-435A-9C04-9CF494C2AFCD",
    "Krishnan_Nishanth_Verification_Letter.pdf": "2BDA27E3-E933-427C-8117-5DB9D94AAEE9",
    "KRISHNAPRASAD.pdf": "19E01C66-9D1C-41BA-B308-20626A1DA454",
    "Kristopher Lyon-RESVerif.pdf": "F6F87811-91A4-46C6-ABAE-75F1FB42B453",
    "Krivosheya.pdf": "4059A63E-01FE-487A-8555-3FAAF3BBED5C",
    "Krushelnytskyy, Mykhaylo Good Standing .pdf": "21C2455C-BD4B-4205-B8B6-44AE9F6F94E6",
    "Krystle Trosclair CV 10-1-20.pdf": "EE07C0B2-DAEA-44E4-A68C-3E1335E388FB",
    "Kshettry - Roger Murayi LOR CNS fellowship.pdf": "631E4BAE-99C0-4915-9F85-B182394B3250",
    "KStabingas.emailverification.pdf": "370F978B-5114-473F-B1E2-B680FB6547BD",
    "KTK-CV.pdf": "D14C6059-6A41-44F0-A7E8-B6D70A331EF1",
    "KTW_9.2.21_CNS Digital Poster.pdf": "F4BD80E1-BD0B-4414-879C-B9CC47291DA5",
    "Kuhia Regina-2023-LOG w GD 8 25 21.pdf": "4FAADCBB-68BE-4DA3-9772-881C44E14F0D",
    "Kumar Poster Electrical WADA poster.pdf": "75990249-205C-4314-BC53-1F2F50B78218",
    "Kumar, Prateek_GoodStandingLtr.pdf": "1227EA48-71BB-4C0D-952E-1C15D7A730E0",
    "Kumarapuram Ganapath Siddhant_Letter of Enrollment_good standing.pdf": "2F42CDEE-BF18-458E-A168-7FFA5A44BDD6",
    "Kunal Patel-CV.pdf": "E42D4A4D-F055-48C3-9E50-09F754D322FA",
    "Kunal Patel-RESverif.pdf": "70047766-1BB8-46F6-8E0F-18E5178F7AB1",
    "Kundu_Bornali_GoodStanding_112614.pdf": "78D94AD8-6C5F-40FC-B12E-2ABD3729B4FB",
    "Kunigelis VER.pdf": "93D4B33C-F612-4DFF-BA2F-8212055B32BC",
    "Kuo gsl.pdf": "8118FC50-4068-48BA-8AF9-809BC24CFFDE",
    "Kuo_SNS.pdf": "30BB16D2-7B4C-4D7B-9909-A6A077384EEA",
    "Kuribara Certificate.pdf": "0DDC42F7-790B-4A19-85DF-87496D98DC23",
    "Kurker_Good Standing.pdf": "2266D787-A128-4903-9C62-2FFBA6849403",
    "Kurland_good standing.pdf": "E4EF62D9-10F9-40B1-A414-A85DA98E5180",
    "Kurland_LOR_(Kondziolka).pdf": "FD937480-3C55-42BF-8AFD-E8A52F122B0C",
    "KurlandNSPubFellowLOR.pdf": "409E0612-5E97-4ECE-8412-8546A81E3132",
    "Kurt Yaeger CV 2021.pdf": "0CD2585E-8607-4B7F-AC9D-6484D1C16A06",
    "Kurt Yaeger CV 2023.pdf": "36A6AB49-3C1C-4B25-B43E-A566D8C93352",
    "Kurt Yaeger-RESVerif.pdf": "DD05CF0F-A0D8-438B-A1A4-D726BEB75EA7",
    "Kurt- Letter of Support.pdf": "B3A53A00-2565-4C07-BE61-66C6964F4DBA",
    "KurtLehner-RESverif.pdf": "BD706BDF-571F-4B15-9577-0F4F85E6B73A",
    "Kurtz Joshua_Enroll.pdf": "959FE798-245B-4EA4-BDB3-E45600B65E71",
    "Kurudza_Elena_MD20_CNS.pdf": "F70D0105-2552-422C-9D97-CB65FA70DDE0",
    "Kurudza_NSTP Application_Final.pdf": "7DF4837B-0E24-4043-9AAC-5EF807BA9703",
    "Kuwait society of Neurosurgery.pdf": "AFD9D059-C067-4DCE-8A0B-06AC6683AF2F",
    "KVelagapudi_ASSFNPoster.pdf": "4381DDB7-FCA7-4813-8417-28AED03A901A",
    "KW CV Feb 2024.pdf": "9A41E832-C8DD-4319-A5AD-270427D5C344",
    "KW CV v.6.pdf": "196AA7A6-A42F-4AF1-B572-0474C25E831D",
    "Kwan-Sung Lee, M.D..pdf": "DE76F5C1-7E1B-430A-B7F6-F55DB2CC3EF6",
    "KWeaver_CV_Jan 2022.pdf": "9F75717F-B7D5-4745-B0BC-168FE8F9C27B",
    "KWu.emailverification.pdf": "E470DEFB-6226-48DA-B549-412107D9F6C1",
    "Kwun, Byung Duk.pdf": "8784AB0B-E7FB-4434-9CDF-21C811134C67",
    "KY LoR_Nsgy Publication Fellowship.pdf": "72725E44-4473-4E09-B8E7-E22D41C974AA",
    "Kyle Mueller_Yoshor LOS CNS Leadership Prgm_020121.pdf": "26E00B17-CEC0-4EC7-BAFD-16F410452111",
    "Kyle Scott verification.pdf": "3983869E-2FBD-4DD8-8420-4BE368C47994",
    "Kyle Tuohy - Curriculum Vitae.pdf": "1E2AB10F-A63C-453A-BD2E-3DAA2AB524B3",
    "Kyle Tuohy Headshot Photo.pdf": "0844DAA4-CE4A-4C72-AFC9-7C2DF6FB1E51",
    "Kylie Smith Resume 2020.pdf": "357CEBC7-94D6-42B9-B04F-11C176E8042F",
    "Kylt Tuohy LET2.pdf": "D41C5537-11E3-4DC9-8F22-FBE16162E341",
    "L Kahn CNS LoR 2023.pdf": "88EA0C9A-B07E-409F-B61B-9CACC710457A",
    "L Massie Verification.pdf": "4F8C5895-B982-4D23-957E-82945CD914C3",
    "L. Brasiliense-verification.email.pdf": "89F62852-5B2E-4E71-A678-826DF6E0AB29",
    "L. Nkrumah VER.pdf": "39DAE243-956A-4EC8-AA86-4649201D91A2",
    "Labak Curriculum Vitae 042022.pdf": "054A02BB-1C44-4E73-AFD2-5A88D2DB4665",
    "Labak Curriculum Vitae 092122.docx.pdf": "93C5D042-B9D4-423C-B339-19FA35F9BE42",
    "Lacey Carter CV - 06.19.pdf": "C754B27D-A6FB-4984-BE20-359EA8651BA7",
    "Lacey Carter-RESverif.pdf": "9270B963-4471-48DA-B524-C46926CD6289",
    "Ladner CV 2022.pdf": "421FC60E-7641-4493-A7F1-42E1C8293AC6",
    "Ladner, T. 02.22.12.pdf": "D70E97EC-9B14-4B2D-98B4-E609DC1277F1",
    "Ladner_CV_2024.pdf": "EF50B621-DD88-4740-A4F8-6C2B79B5049C",
    "Lai_CNS PPT_2024 Emergencies.pdf": "ED18D302-6B50-419A-8924-056CD361DE9A",
    "Laila Mohammad_CV.pdf": "0057345C-883D-4D0E-9801-599C54680A21",
    "Laird_DGriffin_LoGS.pdf": "9123FBB3-518E-4BBC-A1A2-4FF0E984F0F0",
    "Laiwalla, Azim - Enrollment Verification.pdf": "81D4351A-980F-4391-BA89-2491904080F4",
    "Lak Offer Letter.pdf": "C9656F04-494F-460F-A5C5-D523A4806943",
    "Lall May 2023.pdf": "7F8D46A7-A9AA-4D8A-8737-6A6A74E4E082",
    "Lam et al CNS 2019.pdf": "5C6F3B4A-37BE-4D68-8047-4539B05E1015",
    "Lamanna_Jason_Enrollment Verification.pdf": "FDAA8100-BDAA-4F90-8985-E726DDE49ACA",
    "Lamano Letter.pdf": "EDAA8BB9-15D0-4E12-8798-58FEC6543E6A",
    "Laminectomy and Fusion.pdf": "9CE9ECAA-8826-4F06-903A-44FD4367CB54",
    "Lamsam NSTP Grant.pdf": "28115741-62CB-4E31-95BE-CB7D49F51A33",
    "Landon Ehlers-RESverif.pdf": "C972EDD5-F0F5-4616-9199-7A92DA71C8F1",
    "LandonHansen_NSTP_Year1_Submission.pdf": "72EB9562-E1FD-4801-8C8C-0377EAC5B69C",
    "Lang student letter request.pdf": "AEB4A82A-F23A-41AC-B4EF-B42A759F1B1D",
    "Langat Student Status Letter.pdf": "6D2B84DC-BEBF-4279-84B2-E941C8D92950",
    "Langer Bio.pdf": "6F8BB034-A0F7-48A9-80E8-6DC2A02B4981",
    "Large vs small WEB.pdf": "8B8A0164-6247-4B93-A300-4908D594FD3F",
    "Larissa Soares Cardoso.pdf": "CD50CBC6-4F16-4D67-993E-88B1CA515AA4",
    "Larkin - Letter of Good Standing.pdf": "612B5D9D-888A-4111-8805-CB39043667D3",
    "Larrew - CV (2022).pdf": "014CB728-E0D2-4825-A6C3-A00FEF6B31A3",
    "Larrew - CV.pdf": "79E72454-3D49-4229-9F16-52C8D9375DB8",
    "Larrew - LOR CNS Guidelines.pdf": "1EC56280-2EB1-4A1D-A38C-3B62C1B620FF",
    "Larrew - LOR for CNS Guidelines Scholarship.pdf": "58601B6F-10CC-4FFA-95C0-DAA741EE2902",
    "Larsen_Enrollment -3.pdf": "89864651-178A-46C9-8FC5-CA83A9F57258",
    "Laryngeal_severity_2021.pdf": "CA80145E-DBEB-42DB-A263-53C436BA34E9",
    "LarynxMapping_Poster.pdf": "8E09ED8D-2D05-4927-BB03-7BFF54FA7BB6",
    "Laser interstitial thermal therapy (LITT) for refractory epilepsy associated with periventricular nodular heterotopia (PVNH) - report of two cases and systematic review of the literature.pdf": "6574E9FC-EB56-47CD-9DF1-F80547329E05",
    "Last fellow CV.pdf": "43647D6E-D831-4D21-B0A5-4D68A8F54400",
    "Latest CV.pdf": "2DFF2413-7913-439D-B76E-0130C8D8F3B7",
    "LATEST DEE - Cohn Endowed Chair - Khalessi_v2.pdf": "624B904C-12D0-406A-B610-0CA666E56550",
    "Latest Nitin Agarwal CV.pdf": "DD2592FB-F56C-4852-9AAE-EA69C6B4FC12",
    "latex.pdf": "052ADAFF-4116-49E6-B001-C5CCD18872A7",
    "Lattes Saulo.pdf": "4DB67971-FD59-4EE3-A97E-3CE9F10606BE",
    "Lauinger.pdf": "D8710CDC-688E-4FF2-8BB3-0BA44E6BB0ED",
    "Laura Erhart - Cover Letter.pdf": "EA0354DC-E148-433E-BE3C-82C704B5C522",
    "Laura Erhart Resume.pdf": "606FE3E7-C660-4B0D-8096-074411BD4F65",
    "Laura Wolgamott-Fellowship.pdf": "5572CE18-66E1-461B-9285-147671D63670",
    "Lauren Ostrowski  – UCSD Medical Student.pdf": "1CD4E114-91F1-4DED-92B3-1910D504AA1D",
    "Lauren Rotman-RESverif.pdf": "2B4F9E8D-7B75-4663-8A8A-615BE66B53A2",
    "Laurence Rhines Nomination Letter.pdf": "ED674152-0EBE-4A27-9AEC-F92D57389BAB",
    "Laurence Rhines Recommendation Letter.pdf": "A5B524DD-9D49-4E05-BB59-CB446F91A6F2",
    "Laurence Rhines SNS LOR-Ziya Gokaslan.pdf": "EBFE38BA-78BD-49D2-BCE2-85BEE22C6522",
    "Laurence Rhines SNS Nomination_LON_Sawaya 9-5-18.pdf": "D6D473DE-B177-413B-BB4D-B2E658B1175F",
    "LaurenHobbs_CV.pdf": "3E9365F7-841C-4C03-801D-52594E5E763F",
    "Lawhon Sarah Letter of Good Standing.pdf": "E38D3601-D237-4D95-A7E7-9DCC263821DD",
    "Laws Letter.pdf": "204D5CCB-DC8F-46A9-BD5E-A5C3C4C7BDEB",
    "Laws, Ed.pdf": "89CFF468-03C9-4E0E-8C83-01059D669E14",
    "Laws, Edward R.pdf": "57026F81-178B-4679-90FA-32C815C421E4",
    "Laws, Edward.pdf": "57FE1533-25D5-494C-B477-43D38A005068",
    "Layton B. Parker - enrollment verification.pdf": "FE0EA986-67E1-4BAB-A068-31C054CEEFBE",
    "LBodden.RESverif.pdf": "745B51B4-14B6-4472-98C7-A70BC81F1032",
    "LBrown LOGS.pdf": "2EB4B5F1-CD64-40A0-932C-53557D3E21B4",
    "lbt.pdf": "4375058A-CE15-4A0E-9F1D-F0D5C0D33E45",
    "LC Abstract Figures 1.pdf": "C4D012B6-05B2-431B-B9C2-5057EEC3639F",
    "LC Abstract Figures 2.pdf": "4632AA72-ACA8-48AA-9291-AF7B843639A3",
    "LC Abstract Figures 3.pdf": "98D3B5E5-0422-4A5D-8BD0-52684EAD4700",
    "LCarter.emailverification.pdf": "8D2F3BCA-09A7-40D2-99A5-F2FD97A9A851",
    "LCDR SIMPSON Active Dury Orders.pdf": "5FD390FE-8AC7-41E0-BF72-7D0E14B51D1E",
    "LDaggubati_Verification email.pdf": "691B6124-0C6A-47FC-A613-2B5C3BE7E96C",
    "LDHA NSTP grant final.pdf": "E6451BD5-36BF-4A2B-BAB7-3E9B71D4E239",
    "Le_Letter of Good Standing.pdf": "929C20FD-D1D1-4C37-9FD1-4E983A742ECD",
    "leader and team burnout.pdf": "711C2F9D-B91A-4699-8927-F440D48FDB6A",
    "Leadership 1.0 Agenda 4_25.pdf": "BF4AC1C9-C857-44CF-9AD1-D37DDEADD396",
    "Leadership Institute letter of support.pdf": "C321DDAE-16A0-4FFF-8B9B-B11FD4B36E14",
    "Leah Paige Burroughs.pdf": "386D3EEF-1BE1-44C7-B241-C0EFD4947459",
    "leave Class ii Aneurysm alone! Only follow up is needed updated.pdf": "FE92ECF5-853E-4EA7-B07F-5D7BFAFB9A7C",
    "Leavitt Lydia neurological surgeons.pdf": "167B0CC9-CCAF-431B-99E8-8F09C3356761",
    "Lee Gina MSPE FINAL.pdf": "4A7E9517-A6CA-4873-833E-65184CDD45D4",
    "Lee Johan - Letter of Good Standing 080122.pdf": "6EC7D4C0-41D1-462F-923D-60AAFC8A4B58",
    "Lee Subin Letter of Good Standing.pdf": "1A50A830-29E3-4F9B-9963-7932947356D0",
    "Lee.Johan.CNS Summer Fellowship.pdf": "3FA80966-9B4D-4E54-B441-B78500525FE6",
    "Lee.pdf": "27DA1417-C488-48EF-BF8B-BE343BC2C2F6",
    "Lee_CNS 2021.pdf": "AFA7812C-3B6C-43BD-8155-3F0916DA5323",
    "Lee_CNS 2022.pdf": "20A79893-AAB7-43E9-B85C-1C668F536755",
    "Lee_JangBo_CV_2023.pdf": "57A84823-540A-4DEA-A87B-F9055C718428",
    "Lee_letterUntitled.pdf": "1D77FBA7-6386-4276-A61A-4D93C281F5AE",
    "Lega - LOR AANS 120619  White.pdf": "D2468AE8-9598-4F36-894E-4EF0B77406D2",
    "Lega for AANS Membership 2020 Batjer.pdf": "8462220C-AD8B-4751-B888-4C5B0CEA90FF",
    "Lega Standardized 12_2020.pdf": "E8E99DCE-0F9A-4794-A814-EF3479D945C7",
    "LegaB_CV_2022_FALL.pdf": "E9E1011E-DDE1-41CD-B7F3-311D0824DCAE",
    "LEhlers.emailverification.pdf": "FE818D07-86D2-43E1-864A-364C80B95EB3",
    "Leiphart SNS LOS_ Leiphart_LML_2021.pdf": "9A6F62B9-CF45-41D7-AC80-0972C3FE2398",
    "Lemiexu.pdf": "BB9F3D2C-6F08-4686-B9B3-44B57017B393",
    "Lena-Manchester-1 1.pdf": "ECD2730C-51B6-411E-8030-2C356158C677",
    "Lena-Manchester-1 2.pdf": "6AC24C29-3956-46DC-9097-84F15C381F6D",
    "Lena_Manchester.pdf": "29DE6C73-AE2B-40BB-9C7A-A52F142201E1",
    "Leo JP Clark photo.pdf": "CCFDA0FD-1503-47B4-AE0A-1ECCFD5C9292",
    "Leonardo Assinado.pdf": "045A37B3-083D-4537-A83E-448436871957",
    "Leonardo Brasiliense-Verif.pdf": "8649F968-84FF-44DC-B383-9F2CA6B751CD",
    "Leonel Ampie_LOA Certification.pdf": "4E811A84-9546-4658-B0F1-457151E82154",
    "leopold cv 11-14-2023.pdf": "B001994E-C966-41D9-9AF7-A0F39347EC1E",
    "Lepic Verification.pdf": "80F1F92E-A1E7-4A68-808F-9A3D847F9A16",
    "LES LOS_DataScience_wrt signed.pdf": "CA77CAA0-1B81-4322-A72C-7E64115825EF",
    "Lesiuk CNS Letter.pdf": "DCDA65A0-F184-41E1-BE06-894280BC74DF",
    "Lesniak - LOS Khan - CNS Leadership.pdf": "8EEA3A05-5B20-4A85-A116-C3017AF8BCC3",
    "LET.pdf": "F2667433-C1BB-423B-92FB-1ED9307271D5",
    "LetResident.pdf": "BEEAA91E-4227-4118-B62A-3D0478ADAEDA",
    "Letter  specialtyNeurosurgery.pdf": "78128AC6-615C-493A-B7D2-E3A5DC01E8CD",
    "Letter - Aaron Miller CNS.pdf": "A30797B5-0887-4A6D-A059-468DCB5CCF6E",
    "Letter - Arpan Patel.pdf": "8B85CDD6-57B1-440E-8EFF-1144438553AF",
    "Letter - Campbell J.pdf": "17BE785B-9797-491E-92BA-7842D7248B52",
    "Letter - Fitra.pdf": "64924431-D73B-4307-9B6A-1D3C1328B286",
    "Letter - Gamblin A CNS 2020.08.17.pdf": "612F4E38-6E1C-4738-83F4-625F1AC40C7B",
    "Letter - INK.pdf": "57C69BF7-C405-43A0-85F2-E2C986B48930",
    "Letter - Mohammad Bilal Alsavaf.pdf": "AE5B831B-2FA2-4CC0-9320-FE82BBC1B8C8",
    "Letter - Ronna B - CNS 2018.08.03.pdf": "59D0598D-9785-425D-858D-71B903DAD4D8",
    "Letter - Yeretsian.pdf": "5E1AFE90-7269-4C29-888B-CC85C11AF542",
    "Letter 1.pdf": "02129E44-E8E6-4B64-99A3-690F3710A1DB",
    "LETTER 21 de ago. de 2021.pdf": "D40FE17B-E77B-4BBA-983F-A9D67DDB6B91",
    "letter and CV for CNS.pdf": "E8D7DA92-2A50-4EBB-B955-A5598600E365",
    "letter bosotov d oct 2023 scan.pdf": "AC9D03E8-101C-4111-BFED-E0E858FFBD66",
    "Letter Bosotov, D Sept 2016 scan.pdf": "0D3FA9D8-815A-41D2-A270-310D8301C065",
    "Letter Director.pdf": "AF96C385-2037-4159-89D5-1BC67369DB68",
    "Letter Dr. Ramina.pdf": "41FBACEC-5A4C-4432-9CE2-27C6FD3A885D",
    "Letter fellowship status CNS.pdf": "9600EA99-46FD-45D4-8C38-895FD327C7D3",
    "Letter for Academic Standing Ryan Duff.pdf": "E6C31D93-71A8-4810-969D-ED9C42C43FF4",
    "Letter for Boeun Lee.pdf": "250F83E9-A1E6-4176-B359-7530CBC07D64",
    "Letter for CNS-Mekdes Awano.pdf": "E526D3C2-26DF-4D46-A21E-69CE1A902BA9",
    "Letter for CNS.PDF": "CA7C1A4D-1CE1-4557-A663-7BF98797AFB6",
    "Letter for Dr Kirrinya signed 2022.pdf": "0799766A-2FBD-41B3-B260-64D81F1BE980",
    "Letter for Dr Merenzon.pdf": "54B98AA3-20C1-43E8-81D5-62696C7F1104",
    "Letter for Dr. Kuo 09_14_18.pdf": "6161665F-B192-4CDE-AFFB-F17B40275707",
    "Letter for Dr. Meyer_CMichaelcheck.pdf": "E097457B-28B8-4398-9763-ABC0D287D6B9",
    "Letter for Dr. Thorell from Dr. Prabhu.pdf": "F0EAD4A2-061C-4D62-9F5E-13BBCBB30E1B",
    "Letter for FaresNigim PhD program.pdf": "CA564F0B-BA46-4A66-B808-061EEA05A750",
    "Letter for Jordan Xu cns fellowship.pdf": "2845121D-4901-4D7C-B25A-075B372DF387",
    "Letter for Junhyung Kim (1).pdf": "E89DE3B3-DB17-4CA6-99D2-00C205087EE8",
    "Letter for MG.pdf": "6161F32C-9415-4281-BC04-1A1E9FA2B4B3",
    "Letter for Michael Amoo May 2024.pdf": "6EC420ED-70E1-48EF-A396-1FE2CC3DE6E1",
    "Letter for S. Effendi CNS Leadership.pdf": "DF98D3DB-8FD4-4F87-A486-6D8B7E55FCED",
    "Letter for Time Committment.pdf": "26C794CD-5D9E-408F-B9B2-0948B84939B2",
    "Letter for Usman for CNS-Muhammad Usman Khalid.pdf": "92E7DF96-49A0-4F2D-A101-0C4551C77A26",
    "Letter for Youngsoo Chung.pdf": "D8FD76AD-C6AE-407B-9C00-C3335FB8E455",
    "Letter from Dean.pdf": "7FDCE542-C3CC-424E-8011-49E910EDB228",
    "letter from director.pdf": "DBB84668-98B8-4B57-82D2-AE6C44516E95",
    "Letter from DYU director - 2LT Ifeanyi Eke.pdf": "A803830E-D122-4C84-8B0F-515F495A8BD6",
    "Letter from ESNS.pdf": "A99C1553-EDB5-496E-B8DE-9D53E12F8717",
    "Letter from Head Department.pdf": "87C7F811-5DC3-49C6-BD60-55450A7BF8CE",
    "letter from Master of neurosurgery Coordinator.pdf": "33C8ABFC-C7B7-48F8-9345-18E6181AAC77",
    "letter from pd.pdf": "E1D0C05E-2A24-4E51-BF49-45275E5C11A6",
    "Letter from personal doctor should not travel at this time.pdf": "A9AA048D-A308-4306-BE7F-BC0EB4BE0A28",
    "Letter from program director and CV.pdf": "0BC181A0-2720-42EB-8262-91045C03368E",
    "Letter from program director.pdf": "BCF95558-FE45-4227-AC65-1506EAA5093D",
    "letter from residency program.pdf": "E588CF46-815E-4565-9821-ACAD7CA4C868",
    "letter good standing.pdf": "CB474929-6B95-4AF1-945F-344D30BE64AA",
    "Letter Gustavo .pdf": "B8D791D0-1A3C-4FC7-8183-CBEFB2E9241A",
    "Letter in good standing 9-1-15.pdf": "9D747B91-69DA-405E-97F4-FBCDFFB09571",
    "Letter in Good Standing Murtha.pdf": "672C1139-B20C-4F5D-874A-70584385312F",
    "Letter iof Verification.pdf": "1B27B144-75C9-46D7-B0BB-3C9AFB30D544",
    "Letter Juan Sebastian Solis.pdf": "8B6F31D3-966F-4430-B427-160115AABB02",
    "Letter Lee.pdf": "66645663-C1FB-4FF5-8DF7-53462FDC3371",
    "Letter Luz Camila Choque.pdf": "FFA5EDD7-30CE-4B6E-8D68-B2B03719C2D8",
    "Letter MStrong.PDF": "FB343F37-6AC3-47BB-9977-94925A17234E",
    "Letter Muhammad Jalal.pdf": "B23F8AEE-CB50-4512-94D3-9B4C593B5EAB",
    "Letter of  Support - Motiei.pdf": "A5EECEB0-B4D3-48FB-8C7A-52120D67C40E",
    "Letter of Academic Standing 08-14-2023 - Zain Syed.pdf": "0B87DB69-40F2-42EB-981D-D146E315E01C",
    "Letter of academic standing.pdf": "E7225739-700B-4BF2-8469-2FC33FC27053",
    "Letter of acceptance of Neurosurgery Programme.pdf": "B308A30F-C3C8-4F68-883E-A53C28601DC4",
    "Letter of approval and good standing .pdf": "F69EA8A6-AA1C-49FE-8CD7-9A5525063DB9",
    "Letter of Certification (Training).pdf": "A718C325-CAAD-4FEB-8983-68CA08F39B1C",
    "Letter of Certification for Matthew D Pichert .pdf": "21B8AD8D-1E61-471D-91E6-261A037D30EC",
    "LETTER OF CERTIFICATION URIEL TAGLE.pdf": "5F876575-B9C8-498D-B6E4-B805E6B4F818",
    "Letter of Cirtification.pdf": "44FAB0FD-D2F0-4EE9-A9DE-3AA1756E040A",
    "Letter of confirmation - Dr. Oumarou.pdf": "F447EF47-EC29-4E06-AFA0-75BE7CC3F944",
    "Letter of confirmation 18-Mar-2019 18-08-40.pdf": "D82D8F9E-DDD9-473D-983A-C5B628DB6D22",
    "Letter of confirmation.pdf": "41773993-C128-4554-BE9E-07CA0C68B083",
    "Letter of Employment.pdf": "529F08C0-535E-4E38-BD15-9DAE2B13356E",
    "Letter of enrollment Norris.pdf": "4CE07EFB-859D-4C35-99DB-058D367E0870",
    "Letter of Enrollment Rui Song.pdf": "8D627EBC-DFED-49A5-8D16-85B39365D1F4",
    "Letter of Enrollment Verification and Good Standing_C Porto.pdf": "9C8DDF37-363B-4907-AD51-619348BCC46A",
    "Letter of Enrollment Verification and Good Standing_Christopher Chang 1.pdf": "FD42006A-4B8B-4596-AE06-6C9EF256ECFA",
    "Letter of Enrollment Verification and Good Standing_M Singh.pdf": "5A9279AA-7907-429C-B492-F0A9111EFF50",
    "Letter of Enrollment Verification and Good Standing_Olivia Kozel.pdf": "88A06712-9BA7-4A75-B827-8C71F343DFA5",
    "Letter of Enrollment- Z. Corley.pdf": "B5A9F86D-6E11-4F20-9DE7-30C032044027",
    "Letter of Enrollment.ADuttaGupta.pdf": "5AC913D6-C8FA-4112-A5BB-BA163072A8C9",
    "Letter of Enrollment.pdf": "25788F14-BBE3-4282-AFA8-0C4720CC9793",
    "Letter of Good Academic Standing.pdf": "23E374C9-4933-436F-B131-2DCFDEE1953C",
    "Letter of Good Standing - 2023.pdf": "D045B251-DAED-46D1-A4A4-F5E144331BAA",
    "LETTER OF GOOD STANDING - CATARINA MENEZES.pdf": "F2358A93-EC94-424E-B41E-C6A581B2E6BE",
    "Letter of Good Standing - Chen Jia-Shu.pdf": "595C6947-07F9-44CB-8D60-FC34277C0A60",
    "Letter of Good Standing - CNS .pdf": "D47AF760-3D52-42EF-856D-E72055759A8D",
    "letter of good standing - De Leeuw.pdf": "FC84A069-B42A-48F2-BD17-21F0EF0C8005",
    "Letter of Good Standing - Draytsel Dan.pdf": "8C4C40AF-E4B2-48FF-8A9D-65923FEB6FC5",
    "Letter of Good Standing - E_Kim.pdf": "1B217272-26F4-4B28-8455-0448E849F352",
    "Letter of good standing - Hadjialiakbari.pdf": "7EAFC945-5197-4E1D-8F4F-0D9FB58DD17B",
    "Letter of Good Standing - James Bridges.pdf": "F4677E0C-1141-4BE6-839D-BCDAD7337C6F",
    "Letter of Good Standing - Jensen Kate.pdf": "2D8FD357-03D3-42FA-A694-9CE77276C69A",
    "Letter of Good Standing - M2 - Chetak Patel.pdf": "85F5A047-F086-42FE-9B93-F41376D47F77",
    "letter of good standing - Mehta (2).pdf": "0F03ED7F-DAF0-4195-B0F1-16F40B84895C",
    "Letter of Good Standing - Naib Chowdhury.pdf": "DD7DD766-32DE-4A69-A6BC-7943C7CC0FA2",
    "Letter of Good Standing - On Thomas.pdf": "628BF50A-A534-49BF-9F8C-A02AB4FE2D5C",
    "Letter of Good Standing - PatelJay.pdf": "00A22E94-5083-43AD-8D33-7FFDFE6424BF",
    "Letter of Good Standing - Phillips.pdf": "41B9BF39-EF8D-4C9B-8DAC-B63D926D3748",
    "letter of good standing - Shriver.pdf": "8A5D8BEE-F497-4060-9F58-DF0A24BDCF8B",
    "Letter of Good Standing - Soummitra Anand.pdf": "56EA8579-3F6D-42AE-B7F2-2759D66FEC95",
    "Letter of Good Standing - Taylor Faust-Signed.pdf": "D4A5E241-6602-4AC8-BB2E-19ACB0EA145C",
    "Letter of Good Standing - Theresa Gammel.pdf": "5F3864E2-68F2-4C18-BE90-D3C116F01D9B",
    "Letter of Good Standing - VUSOM.pdf": "6472EBDD-87B6-4EB5-9052-AEE945EDFC50",
    "Letter of Good standing .pdf": "BC83D668-3DB4-4354-8FE2-0AA6A83D4895",
    "Letter of Good Standing _ Hannah Daniel.pdf": "1484CFD1-5E71-4BBE-8E6D-6B80EB7D5A7D",
    "Letter of Good Standing 07182022.pdf": "BC19608A-9569-40F3-941D-A85D102A9023",
    "Letter of Good Standing 2.pdf": "C464E5D4-6E67-4822-932D-AEF2C84E2FF1",
    "Letter of Good Standing 2022.pdf": "B710C475-C99A-4638-890B-DB9C439B3804",
    "Letter of Good Standing 2022_Duquette Elizabeth.pdf": "7DA2F69C-5927-486D-8094-A81816E17B27",
    "Letter of Good Standing 2022_Lucas Sarah.pdf": "53A7EA44-6585-458E-A412-3BED0BC38685",
    "Letter of Good Standing 524.pdf": "F1F8E3FA-4753-49D3-B22F-1A4347F4E6CB",
    "Letter of Good Standing 7-26-18.pdf": "30D1F581-BC33-4A25-B080-2A5DECDB8E26",
    "Letter of Good Standing 9-12-22.pdf": "B2045FA6-8E0E-4A7F-85D7-27A3F9AAD401",
    "Letter of Good Standing Amar Shah.pdf": "ECEE3279-2D45-4FBA-B0C2-41A3525C0122",
    "Letter of Good Standing DIMC.pdf": "2147F9E6-9FD9-4408-9012-5BA838CA8DE3",
    "Letter of Good Standing for Christopher Allen Witt .pdf": "B3501C99-81D3-431B-8E1D-530BDFAA8AF4",
    "Letter of good standing for J. Fazzini Tracz - Jan 2022.pdf": "1B069818-EBA6-41F0-9979-25D579A85661",
    "Letter of good standing for Ms.Eniola Otukoya  .pdf": "EA9A4E02-AB21-49D9-8C24-62640717A6E8",
    "Letter of Good Standing from Dean.pdf": "AA34D5B4-0A47-443C-86AA-F2F9E5735C9C",
    "Letter of Good Standing Garcia Catherine.pdf": "A3CF52D1-3E03-4D15-A73A-7CFAE1416ABA",
    "Letter of Good Standing Goldschmidt.pdf": "6CC08E4F-38F9-40C1-AD9E-1E278F8800DA",
    "Letter of Good standing Isabella Sutherland.pdf": "3DD525E0-944D-4DF4-B3D4-B4A4FFEBFA3D",
    "Letter of good standing J Murchison.pdf": "10551DB8-AAC0-4128-BE08-7FA831FED4D8",
    "Letter of Good Standing Jordan Norris.pdf": "FE206E5D-2D68-44D3-BF1D-8E7D351AC5A5",
    "Letter of Good Standing Kyle Hulse.pdf": "6CE49AF4-E4CF-4158-A260-057F5DAEA4A0",
    "Letter of good standing LSU.pdf": "56B916B0-17C4-47F6-9B9A-61468DB4A36F",
    "Letter of good standing Luke Mugge 05162015.pdf": "1E13C4FD-F898-4857-9729-022E40B79CC7",
    "Letter of Good Standing Neubecker.pdf": "97A39A65-335A-4E35-B314-FD8A9E8C938C",
    "Letter of Good Standing Rotsides.pdf": "CE82473D-9848-4FF2-BE70-30CB3537F75E",
    "Letter of Good Standing Sept. 2022.pdf": "87A995D0-D6F5-484D-88AD-E2F3FE61A147",
    "Letter of good standing USACOM.pdf": "EA520E0B-3B31-42CC-B7AF-AB57A57E3930",
    "Letter of Good Standing, 082015.pdf": "7BE3959F-F44A-46FB-AAB1-F59C002AD7A8",
    "Letter of Good Standing-  Jason Liounakos.pdf": "C9017036-ED5C-4F4C-8D30-56032CC3D287",
    "Letter of Good Standing- Nicole Fuentes Rocabado.pdf": "8CFFAFAC-B108-426E-BDD5-652B191F16F2",
    "Letter of good standing-Barbieri.pdf": "10844743-0C1A-488E-A1C7-8A829CF88C68",
    "Letter of Good Standing-Dagli.pdf": "7FA7B3D6-DCB7-45F0-B62D-5A826F288CA6",
    "Letter of Good Standing-DWishart.pdf": "C1665ECB-4C8B-4FEB-8205-9B810024DD70",
    "Letter of Good Standing-Expected Graduation Date.pdf": "DCB4A8AD-3F96-4FA1-BF27-10831214ADB2",
    "Letter of Good Standing-Mehmet Denizhan Yurtluk.pdf": "46963CA4-CA27-4CA1-A46E-3947B53BD55B",
    "Letter of Good Standing-SR.pdf": "1F4F7FFB-C34C-4516-B5DF-3E2E4E4815AD",
    "Letter of Good Standing-Vaibhav Sharma .pdf": "C42B0422-E3BF-48FF-AC67-098ADACEBE55",
    "Letter of good standing.pdf": "5EEF1277-7A8C-4473-ABAF-000001F25DC6",
    "Letter of Good Standing_7-24-23.pdf": "96B8A032-E59E-47C5-A5A9-ECB91E96281C",
    "Letter of Good Standing_Abhyudaya.pdf": "45584666-F673-4D38-A9CD-8A08574D4A59",
    "Letter of Good Standing_Alex Miner.pdf": "5EC66732-0143-40E3-BDC7-38E45E19F358",
    "Letter of Good Standing_Chaudhari.pdf": "BD3D4451-A1E8-4275-9FCC-3D2D30BDC933",
    "Letter of good standing_Chitnis.pdf": "B3AF37F8-9092-4DC3-B8BC-1A9C9967C07E",
    "Letter of Good Standing_Deyer.pdf": "0E77DA0B-B5FC-487F-A271-A90205E80A24",
    "Letter of Good Standing_Diana Nwokoye.pdf": "43EE5DC2-DF83-4743-A512-DAED764CDFF5",
    "Letter of Good Standing_Emilija Sagaityte 1.pdf": "D27F505C-783E-4CD3-B6B4-A54EAD7E291D",
    "Letter of Good Standing_Holan Cole.pdf": "C5F32755-CEBC-4305-96F2-11738CC6C87E",
    "Letter of good standing_Karahalios.pdf": "838B0E68-6BD5-49F1-939A-517E4FA201B0",
    "Letter of Good Standing_KYS.pdf": "9A164E29-ACC8-42EA-B9D7-69C74377BEF6",
    "Letter of Good Standing_MathewEzek.pdf": "94A51955-F9BE-4BBA-B0B9-A0F9558E702A",
    "Letter of good standing_MC.pdf": "3BD00A28-7999-4D91-9E7E-B69D8DA62C25",
    "Letter of good standing_Neevya Balasubramaniam.pdf": "5DF221C8-DF48-45CF-AF34-29726D476D4B",
    "Letter of Good Standing_Spencer Raub.pdf": "B44FC03D-C6FC-4CEE-B7FF-4501BC546C7D",
    "letter of good standingoriginaltranslated.pdf": "A2211362-7667-4CF6-985F-657396334421",
    "Letter of GoodStanding Ali.pdf": "8D0B8E68-B450-459B-8DD0-2B417CF9C5DE",
    "letter of goodStanding-Marwa Emhemed.pdf": "6048F4D6-D3F4-4674-98C5-A08EFE12C73D",
    "Letter of goodstanding.PDF": "A5682C3C-BB99-4136-83C5-B94166ECB1CC",
    "Letter of Intent- Mobile health app for post-meningioma resection.pdf": "3DCB4FB7-C7AA-4E25-8D39-4113A72348DC",
    "Letter of nomination for Kaisorn Chaichana.pdf": "54EC6D52-1F56-4443-8425-9B4627963417",
    "Letter of Permission.pdf": "17F15443-6B10-4A57-820D-59A138496BD8",
    "Letter Of Rec AAizi Prof Suciu.pdf": "978DD3BB-0D8E-477A-9480-5FD6A04D7702",
    "Letter of Rec CNS Price Goodwin_final.pdf": "A9C10E05-04E0-4B1F-8E0B-3EA1FF5EEE27",
    "Letter of Rec Placeholder.pdf": "43DE9C98-3922-4D81-AE21-B472818AFBF2",
    "Letter of Recomendation for CNS.pdf": "B3C22BA8-99FD-4B4A-B03A-D349E8E39A48",
    "Letter of Recommendation  .pdf": "EED7C791-9467-4A62-B38C-1F39060141CA",
    "LETTER OF RECOMMENDATION  Alana Ferrufino Mejia.pdf": "21A46B5F-3936-44D3-8DF9-E4B8E3F98DD3",
    "Letter of recommendation - Bojan Jelaca 2021.pdf": "C09AA6D6-FDD5-4AEB-86F7-D815C889C2FC",
    "Letter of Recommendation - Dr Aniruddha TJ.pdf": "7AFEE271-F36D-4FC3-A403-3D8657A14772",
    "Letter of Recommendation - Peter Giannaris - CNS 4-13-23 1.pdf": "B9163B06-ACD7-4207-88EC-223E2CFAECF4",
    "Letter of Recommendation and Support.pdf": "4C3D6B3B-A3F2-4FEA-90C8-07B9A5917CE3",
    "letter of recommendation dept.pdf": "3C1C08F2-4C89-4E1B-A097-155891E31505",
    "Letter of Recommendation FAQ's.pdf": "1FC339E5-3B59-444F-9FF0-4346CD4614F8",
    "Letter of Recommendation for Gordon Li SNS.pdf": "F1165C44-6D3A-4C7F-8D76-8046FA256087",
    "Letter of Recommendation For Observership.pdf": "F8791942-EDB8-4BE2-B46B-30F6BE47F765",
    "Letter of recommendation Roger Josefsen.pdf": "2710B967-A443-4297-9B0D-2A0479243D84",
    "Letter of Recommendation SINDOU.pdf": "26C08F78-DBFF-42C6-8EF1-3007870D27B2",
    "Letter of recommendation.pdf": "13475E7F-2FB7-41D8-B772-2815C8063E13",
    "Letter of Recommendation1.pdf": "739AF406-A374-497E-86DA-EADE56E2640A",
    "Letter of Reference (Tommy A Nazwar).pdf": "22CC9CF5-06AF-4BD5-AE34-08B668D9493C",
    "Letter of Reference Brown.pdf": "67CA573F-0899-44D7-97E5-C4AC241E47F4",
    "letter of residency program.pdf": "98814814-3849-4918-99EB-FBA63B9B1572",
    "Letter of Residency Verification.pdf": "9BDE2C96-B5A6-4F56-B1CE-1CBD7857B3FD",
    "letter of statement and CV.pdf": "E8E3C092-3E1A-4855-82C3-351992EE3D82",
    "Letter of Support 1.pdf": "7DED2932-6930-418D-9D63-ECBDFF5AC4D1",
    "Letter of Support Alejandro Bugarini.pdf": "7D03D2E0-34FA-47D7-B8A3-7D7135FE10DA",
    "Letter of Support for Al Cohen SNS Medical Student Teaching Award.pdf": "9B1BEF4C-4BB4-4F59-8C82-096B47C7B9F5",
    "Letter of Support for Amani Carson - CNS Fellowship21.pdf": "402FC3F9-F0B8-4E9D-B865-12093ECFE7ED",
    "letter of support for CNS .pdf": "425BB953-3D51-4AC5-811E-0F7461EAD8DE",
    "Letter of support for CNS leadership institute.pdf": "34E2D5D8-CA99-4A49-BFD1-5C9EB243CA75",
    "Letter of support for CNS leadership programme.pdf": "20D2473A-7D99-4120-A8CA-0BB5EFC22BDA",
    "Letter of Support for Dr. Lin - Dr. Dinh.pdf": "DE5D8C55-C15E-4296-8310-DF0323797120",
    "Letter of Support for Dr. Lin - Dr. Elwood.pdf": "7F4347C5-F36A-4751-9966-392E50D1E524",
    "Letter of Support for SHJ.pdf": "25618E22-2524-4117-A1C7-19CD348DBC6E",
    "Letter of support from Tim Quinn.pdf": "9FC15334-AAAB-4DF3-9B1B-2F634A3FF6A9",
    "Letter of Support Jordan Roach _ Boop and Qaddoumi.pdf": "DD7F574A-8FF3-4760-869F-8CD5B0015C52",
    "letter of support justin ker.pdf": "031E622B-87AD-42B2-A9A4-7BC9083B4B96",
    "Letter of Support Manish Aghi.pdf": "71E462E9-DC09-4B20-AEF3-4123CF51E097",
    "Letter of Support Time Commitment for CNS Resident Publications Fellowship.pdf": "1977ABD0-7035-4F57-8377-1F8F05794109",
    "letter of support- MCOOLS.pdf": "9049D2B4-EF8C-4B10-838B-18221D72E7F5",
    "Letter of Support.pdf": "2C4AD721-B942-4DAB-863B-1CFA68E79405",
    "Letter of Support_Laskay.pdf": "6212B336-729B-4D31-906A-3A439F6BEC87",
    "Letter of support_leadership Godil.pdf": "E97445F2-F85B-4640-BCE9-0F6298A183AE",
    "Letter of Support_Nistal.pdf": "A849DF71-6427-4301-AF25-EA7AD93EECD7",
    "Letter of Support_Sanusi.pdf": "7BB43FE6-645D-47C8-96BA-963CE5C8B4CC",
    "Letter of the dean-Mustafa Ismail.pdf": "F7F83FE6-C202-4DD0-876E-E141398A8589",
    "Letter of Ver Siyuan Yu.pdf": "9538496C-C8E2-4D40-B459-4F5285B8022C",
    "Letter of Verif-Sayan Biswas.pdf": "2A87B2D9-A8E0-4123-9DB1-345BEBB9B2BB",
    "letter of verification .pdf": "C0803945-B18A-4759-8A17-1FF3C33FD911",
    "Letter of Verification Dr. Sandeep Kandregula 7.29.21.pdf": "72E9CD1F-D882-4DB8-AE66-89EEE3D07990",
    "Letter of Verification NYU.pdf": "7C860779-9A3C-4810-86DB-49AA78E869AE",
    "Letter of verification-Daniel Todd.pdf": "522D79D2-A1E3-4432-8A14-476B9375E7E6",
    "letter of verification-Ilayda Kayir.pdf": "C5F8627C-ADA9-4C42-8112-6A8AD3C255D3",
    "Letter of verification. CNS.pdf": "38B80396-C794-4AC8-A431-10510739C1A1",
    "Letter of verification.pdf": "AB095043-01A3-42AE-BE95-076435B88CFE",
    "Letter of Verification_ Peter Felton.pdf": "AC512A85-9BDB-4E8E-9C13-6BB536ADB5AB",
    "Letter of verification_Hasan.pdf": "CDA21F5C-C100-407D-ADC1-21CC313060EF",
    "Letter of Verification_Iarmoliuk.pdf": "173C432B-EAF9-44F0-904A-5594ECF19ADF",
    "Letter of Verification_PD.pdf": "FCFF11F1-9A93-4DBF-9FAC-5A2AD13441C7",
    "letter pf lee boreom.pdf": "D4B262BC-9505-4DC0-B82E-CC6F3A19EA1C",
    "Letter Prof.pdf": "6EEBF0B2-3CD7-4063-8E40-6FA50E0BB1C0",
    "Letter Request for Emeritus - Smith.pdf": "8FFF8772-5FDA-41CB-A231-24DA4170BF24",
    "Letter residency Vandertop (Arnts).pdf": "EB515B07-4215-4610-9CF8-08F7F52F81DE",
    "letter socolovsky International Observership .pdf": "6FBEEF37-046C-4F74-BB63-0FED338B31ED",
    "letter socolovsky International Tumor Observership .pdf": "436534F4-DB89-4266-A58E-D8A23465810E",
    "Letter support.pdf": "85E02406-FD9F-4CDA-A4E2-3E921FD71649",
    "Letter to Ashish Thapa.pdf": "F745461E-8DAF-4228-8B7F-4997826877F1",
    "Letter to CMS re Coverage of CPT Code 99072 11-10-20.pdf": "C3F99321-F2EA-406E-BA4F-843B3AAC2DB5",
    "Letter to Leadership on EM Code Cuts Before End of Year -- Final Letter.pdf": "DFF16EB2-DA9E-4F5A-884D-2C2CC8E3367F",
    "Letter VER for L. Lev Tov.pdf": "E37ED452-D60F-4676-B760-95DE89F2EB5F",
    "Letter%20of%20good%20standing%20-%20Sivakanthan.pdf": "F7D4B407-EE87-49CA-9125-990E36471B96",
    "Letter%20of%20Good%20Standing%20-%20Williamson.pdf": "5AC4F73E-E64E-46A4-A606-02AC2079FFDB",
    "Letter-  Jebet Beverly Cheserem MD.pdf": "94900EFC-35E8-47E7-9836-29F357A87006",
    "Letter- Lucas Pari Mitre.pdf": "B6B03194-86E9-4722-99A9-85F522F42ECF",
    "Letter-CNS-Daniel-Sconzo.pdf": "0B999C4A-0240-4B16-9D95-A458B8E98EF5",
    "Letter-Mariana Morais.pdf": "2EC3F557-CD8A-415A-85E9-D5CA040FA3C9",
    "letter-of-good-standing.pdf": "1E936FBF-BAA6-4E98-B7B2-E17E4AD7FC3B",
    "Letter-Of-Identification.pdf": "D112E465-D569-444E-B4D1-2DA09127A39C",
    "letter-Quentin Francois.pdf": "5076D164-2E5D-47EE-83C8-AC7BE6BE8ADE",
    "Letter.pdf": "21430498-392D-4EC8-9429-030D908BC58E",
    "Letter_ CNS Leadership Program.pdf": "BDC58B67-03F7-4A00-B9CC-E3D124D5E3CE",
    "letter_-_21st_century_cures_-_ryan_2016_-final.pdf": "AE6AD7BD-B464-4729-A155-74EEE4081724",
    "letter_Adogwa_O[1].pdf": "075B5A6F-A4C7-4063-8B61-44C13B84C2BA",
    "Letter_CNS membership.pdf": "A82B6F75-C5BD-4179-B66C-C140EF8DB037",
    "Letter_EnWithTable.pdf": "B5D7DBEC-AE5A-45BD-8385-7D43F8C24F42",
    "LETTER_FY21 MISSION ZERO Appropriations FINAL.pdf": "B0454839-E27A-4493-A097-B0BE2A93E7E5",
    "Letter_MGH_Neurosurgery (25).pdf": "BBDBA7E9-BF22-403D-9DC9-B2857964204E",
    "letter_munibe_busra_erdem.pdf": "0D665CEF-AD8C-4452-88BE-182DED2F8114",
    "Letter_of_Good_Standing_Baumgarten.pdf": "EC9D92E2-DBF0-489F-86C9-163110812599",
    "LETTER_OF_RECOMMENDATION_-_DR._K._ANSARI.pdf": "B8F76E12-DA05-4B6F-8F4E-9CA417D49C1B",
    "Letter_of_Support_Dr. Paff.pdf": "B0B1471C-A72F-4F40-830C-7CDA108A5158",
    "letter_on_2016_pqrs_feedback_and_informal_review_processes.pdf": "523A51B7-90F6-470C-B2D1-27C6011912F9",
    "Letter_Patrisha-Lazatin_2024-04-08.pdf": "8E0A5704-0C9C-4801-89E6-1B41E14256B1",
    "Letter_proof.pdf": "AE046970-C572-43FC-8F11-036E90FEA80E",
    "letter_supporting_medical_controlled_substances_transportation_act-ec_101515.pdf": "7B650EF5-771A-407A-9D47-F7F0544E4405",
    "letter_supporting_medical_controlled_substances_transportation_act-judiciary_101515.pdf": "D219A1DD-5FF6-4CDF-AE94-CBA9D595AA6E",
    "letter_supporting_medical_controlled_substances_transportation_act_101515.pdf": "517331DC-17AE-4D0A-917A-15F70372E59A",
    "letter_to_house_and_senate_leaders_urging_device_tax_repeal_111617.pdf": "F79F6689-3AEE-4A47-86A2-2617882A37E3",
    "letter_VML.pdf": "9646BA4E-D0E4-46EC-910B-CF8AF16D571E",
    "LetterBunevicius.pdf": "E72D82CC-7DF6-4168-BE7A-00E0998942BD",
    "LetterGoodStanding.pdf": "184F9C61-86A9-44EA-8C56-025C7CDF3B60",
    "LetterGoodStanding_CNS_EricMontgomery.pdf": "DC9B01A3-CBA2-4B5A-ACC5-53D4F5C7DBEA",
    "letterGoodStanding_psych.pdf": "5D4C4FE3-A6E8-41D2-A1E3-2D5D409CDD23",
    "LetterMartinsCInstitutionalSupport.pdf": "C182452D-22C0-4BF7-B634-2E76E46BCF29",
    "LetterofEnrollment.pdf": "9E9D5DAF-C84E-4FAA-977E-927BA500F543",
    "LetterOfEnrollmentQCOM.pdf": "FDA30773-60AA-4575-A4AA-F3C85C7FB6C6",
    "LetterOfGoodStanding.pdf": "F3EFCFBD-D122-4C22-9C4B-32AEBB0DF74E",
    "LetterofGoodStanding0001.pdf": "11A776F1-C7DB-4165-8C55-942765A7F5A4",
    "LetterofStanding.pdf": "1E8561FC-5108-45F9-A64D-8DA85B1630D8",
    "LetterOfVerifiation.pdf": "720C1387-6696-457D-A585-A4CB37FEC518",
    "LetterofVerification.pdf": "E9E782C9-76F3-4891-A514-D6D7D63871F5",
    "LetterOSHA-ResidentDutyHours120710.pdf": "07B65B7D-824D-4E1F-83DB-9146F470A913",
    "Letters.pdf": "6E107D7D-3808-47D9-B0CC-22CDB6B87E84",
    "LettertoAMAOpposingHousePassageofSenateBillFinal.pdf": "A0CBE275-44C0-45D4-BBE0-B7E0CEB97AF9",
    "LettertoSupportMPEA41013.pdf": "E7AF1D79-4E52-42F8-A857-7494EE8AF329",
    "Lettre dattestation - Alexis Marion.pdf": "B9D4D994-6D42-4349-A26A-9033DC15A104",
    "Lettre de Recommendation MERTENS.pdf": "7350031B-21FD-472C-B4A0-6194C435AEB3",
    "Lettre de soutien-A.B.BAH - copie.pdf": "E7A14C48-07FC-41AE-A6E3-C857C957E829",
    "Leuthardt et al 2004.pdf": "BFC61817-CDF5-4078-9A49-C2E677070F5B",
    "Levi_Danish Shabbar - SNS 2020.pdf": "C77ADFDE-51AC-426E-A5ED-D75E8268078C",
    "Levin-Carrion Yaxel Enrollment Letter.pdf": "75970F7B-CB25-4634-ACFF-D50241DDED0C",
    "Levine Resume.pdf": "B59017B2-F941-4F4D-A7DB-E1BE5E0B8D67",
    "Levinson Poster 2020.pdf": "10BF02E5-682E-4AED-823F-425FDE78670B",
    "Levitt SNS LOS__Ojemann.pdf": "5428BD64-3DB7-437D-B860-0402A687ABC1",
    "Levy Invoice.pdf": "E019A19E-FCA1-4A08-AB98-11CE43CC8635",
    "Levy LOR Martina Stippler[1119].pdf": "0AF10398-CB3F-490C-991D-1D73C86A95EE",
    "Levy, Elad I.pdf": "E31FC8F0-FE20-4E44-A29A-5C8178C6691B",
    "Levy, Elad.PDF": "1EE27169-792E-4F27-BF86-6FE1139BBE59",
    "Levy, Michael.pdf": "E3F16C17-535C-402F-9B03-C8F289A65686",
    "Lew_SNS.pdf": "03A7358A-77A6-460B-BB1F-FD24D81EEE85",
    "Leyva_CV_2020.pdf": "C099427D-7CEE-48F5-9496-4E993D3C1873",
    "LF Hgb Transfusion Poster (CNS).pdf": "2B17FE23-4802-407E-9FA1-F782D49E9A91",
    "LGG_CNS_2023.pdf": "2FEECC71-B88B-48A5-A307-F942EE9F549F",
    "LGS  Patel,  third year Ybarra  Signature.pdf": "73F2FFDE-6F87-4213-B729-9CE7B551E794",
    "LGS - Brandon Kaye CNS Membership 11.29.21.pdf": "F94508E5-FFAE-46C4-98F5-2C0200CDD6A0",
    "LGS - Congress of Neurological Surgeons Darisel Ventura Rodriguez.pdf": "1046F8D6-B411-40AB-B6E1-68E63521AE58",
    "LGS Anokwute,  third year.pdf": "C632CEA4-BC74-4DA5-A80E-1015BC91A22D",
    "lgs Charles Runyan.pdf": "BBAB61D6-B017-47BF-ADA5-035162B3943A",
    "LGS JAN_DAS SIDDARTH.pdf": "8CF52415-8E1E-4D42-9D6C-C1A3D8C8C564",
    "LGS Peitz fourth year.pdf": "AC603964-C19B-4C39-94A1-9245E2CA80E4",
    "LGS.pdf": "09966F7A-426B-4116-9730-5EA37AF77A20",
    "LGS_Alexandra Backlund -signed.pdf": "71CB955F-ADD6-4D9B-BBBE-3FA4BD81EC82",
    "LGS_JAN_PINEDA_JONATHAN-3.pdf": "6B12E211-D2A4-47CB-809B-0249D41C1C7C",
    "LGS_July_2021_Abongwa_Sijules.pdf": "0DD6CD16-AC1C-4985-83E3-F4082631ECF1",
    "LGS_July_2021_Lin_JuiJui.pdf": "D5B03DB1-3AC0-4281-8B5E-E477D09C29C7",
    "LGS_PCP_Ross.pdf": "AFE554A3-5FDD-4027-8F35-F7DAA856B208",
    "LGS_S. Walker.pdf": "41E5105C-CEF2-4D73-834C-9D72FDDCA244",
    "LGS_YeradiMichael_VSLO.pdf": "91DA80DA-0FA0-4D4B-8FEC-8115756ABF8F",
    "LGSCNS2023.pdf": "E538146B-CFCF-42C7-ADC6-2122DA73AC17",
    "LH CV updated 071223.pdf": "C7576C36-815F-454F-A202-19CA10AFC209",
    "LHCC Section-by-Section_FINAL.pdf": "8CD44B25-F796-4FE9-8525-C823A2499B15",
    "Li.pdf": "ED886F5B-00EE-4628-8FBE-1AE12EEA20C2",
    "Li_Danxun_ LOGS_01_11_2023.pdf": "3A45F59C-D5E3-4170-8342-B2E37B3EA23B",
    "LiamGoldman-CNS Letter of good standing.pdf": "7C1B7141-B500-4F09-A0CB-EF837F1FD3E9",
    "Lian Ming- LOGs  Enrollment Verification.pdf": "53D3D4D7-6BDC-4023-A95D-6BB6762853FB",
    "Liang_Allison_CNS abstract 1_2019.pdf": "7D20366B-5A0B-4B8B-9B26-087A64687265",
    "Liang_Allison_CNS abstract 2_2019.pdf": "12B66F75-2703-4356-888B-F66A7D017446",
    "Liau CV2024.pdf": "1FF7190A-9E8C-4768-ACB8-4D58B8E666A4",
    "Liau.CCR 2005.pdf": "511FAB71-9885-4929-9D59-C2F5C2A799F7",
    "license ministry of health.pdf": "DD53DA8D-B906-49A6-AAE4-AF3BD84A609E",
    "Lidia Lee CV 2023..pdf": "CCA8EEE3-69BE-490C-AA95-8D85E912710A",
    "lidocaine and blade.pdf": "442BA594-3651-481D-A644-5C1479619E29",
    "Liebelt LOR - Silveira Neurosurgery publications 1.pdf": "589EBBBE-57FC-42D2-A5EB-4673B0F2361F",
    "Liebelt_Brandon_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "5B1A863B-6844-4B50-BB10-AB33AF03EBDA",
    "Liebelt_Brandon_2024PositionalMembershipApplication_CVandBiography.pdf": "6F838AFC-5A8C-4A69-B198-246F2CC15F85",
    "Liebenow Brittany - Verification.pdf": "C84D77F0-CDC6-46B5-ACBD-B3B1D3D3DB66",
    "Lieber%2c%20Bryan%20Congress%20of%20Neurological%20Surgeons.pdf": "8B0ABEA2-9CA0-43E5-97AB-6DDE90578AF5",
    "LIEBER_inscription_odm_Stefan_Lieber.pdf": "5B958B27-7B25-4B17-A7A5-831F4CB2F77B",
    "Liew, Jason.pdf": "791F3F7C-C14A-4A3D-8442-4EBB03C78554",
    "Life Member ISSFN.pdf": "45DEDA41-328C-4A69-B492-30189EC01352",
    "LifeLock Flyer.pdf": "49445ACE-BE21-42A7-AF47-E397E68A2FA3",
    "Likert 2 (3D SIM retrosig).pdf": "B67303B0-DDE8-44F9-9D65-7825D6D222F1",
    "likowskydesir CNS_1.pdf": "5DC58A45-7646-460B-9AEA-1AE5CE85B9A5",
    "Lilin Tong Nature Neuroscience 2021.pdf": "7389630E-4ED7-43A8-A7A0-D7CF522FB981",
    "Lilin Tong_CV.pdf": "4AF1E20E-1C70-4CD6-9F63-B72DF7C97D87",
    "Lily Kim Program Director Statement CNS Scholarship.pdf": "CA8448B8-BFC8-423D-AD0B-CC0FD9D85D5D",
    "Lilyana Angelov CV November 2023.pdf": "601EDE2E-3AF3-4A08-A42B-06A7BD9FD939",
    "LilyHKim_CV.pdf": "BC4B6C46-8FC7-4F50-AF4D-14F8770A02E4",
    "Lim Orders 1.pdf": "F0007B6E-057E-40F9-85FB-BBB03C64DD34",
    "Lim Orders.pdf": "227B5F52-12B6-4B4C-8E4E-54E713CE104A",
    "LIM.pdf": "7040260E-33A1-40AD-94C7-74C866131862",
    "Lima Verification.pdf": "CE4666FA-E7DD-4C0E-9582-363782DE3ED9",
    "Limbrick.LOS Mike Chicoine.SNS.11-1-23.pdf": "1FB23DE0-3160-440A-8FA9-FB3D089F3858",
    "Lin, Leanne CNS member_021511.pdf": "C51866D1-E958-4CD9-9107-DB19E23926EA",
    "Lin28B Poster_CNS Annual Meeting_2023.pdf": "0BF21E58-E4A4-4356-B62D-441D2E1D8B90",
    "Lin28B Poster_CNS Tumor Symposium_2023.pdf": "E5DE8A29-E77B-4DB4-857F-76FD340D12DB",
    "Lin28B_2023_Peds_DigitalPoster.pdf": "9DBE70C2-F2F2-4DA8-827B-34C16519FD01",
    "Linda Liau Winn Prize LoR 1.15.2024 from Marjorie Wang.pdf": "CEDA080F-21F2-4BB0-84FF-3DF0CDFC1BAD",
    "Linda Liau- UCLA - PROFILES-CV-2024.pdf": "999D4D70-E72E-4179-B0FF-02B1D9242137",
    "Linda_Wang_LOGS[2].pdf": "60421B49-11B2-4853-9399-CE2790DDD0A5",
    "Linhares, MN CURRICULUM VITAE .pdf": "322784B7-1102-4195-B9AF-D74C32DC927A",
    "LINZEY Joseph Letter of Good Standing_07062015.pdf": "7B05BF00-5711-4DAC-AF15-D606107C7467",
    "Lior Lev Tov CV.pdf": "D27E7EAC-BAE6-4AD2-8EE6-5FCA4D6AA70A",
    "LiorUngarCV2015-forCNS.pdf": "0DAACA06-2883-46DE-BB45-6B203D271E07",
    "Lipovac_Verification.pdf": "D9902DF9-88C9-4014-98B1-7A72073E3163",
    "Lipsman Recommendation_Fehlings.pdf": "65E3DF61-EE79-4242-931E-5F6A71B94087",
    "Lipson Letter.pdf": "663BA0B0-9D50-41AB-AF1B-A77CBE1E6AE3",
    "liq.pdf": "47F7BF81-F36A-4660-8A09-CCADE14C975A",
    "Lisa Genadry CV 2021.pdf": "81D3D2E3-AF1E-452F-89B1-100D8FC0D710",
    "Lisa Scarpace_CV_2016.pdf": "E24DBDB3-4772-4BCE-AA26-E6FB398B4FF6",
    "Lisa Walsh University of Michigan Medical School Acceptance Letter.pdf": "226E3BAE-218C-422F-A889-1AAA693A1B86",
    "Lisa Ward CV 2022.pdf": "D7436E02-77A9-4B43-B95E-01B5C682F71E",
    "list of codes for global surgery reporting 2017.pdf": "DDB49529-1100-42A9-A4F1-AB8249063CA4",
    "List of North American PDs.pdf": "00F277E2-DE6A-44B3-A56F-6E3373AE68FC",
    "List of Registered Medical Practitioners.pdf": "0538CFE9-59C3-4F1F-A461-0D8FCF958ABB",
    "LIST of Resident Completion Dates 2021-22.pdf": "F5DD4E60-4C3E-469C-804F-AEA074A934D9",
    "LITT CC poster.pdf": "C82B7CF3-E69D-4749-9DB9-45D3705445C9",
    "LITT for RN CNS 2019 EL.pdf": "78FE82B0-3234-4205-AC2D-6D7487C7C500",
    "LITT Heatsinks.pdf": "04167A25-3F1A-43E5-BB8D-1BBF43869076",
    "LITT ML Supralesional Ablation Prediction.pdf": "6DA89970-5AD7-4F98-A7A5-3EB88A8D09A9",
    "LITT to PMC.pdf": "D4E19F86-07ED-4AB0-9DD7-D4716EDE0E6E",
    "LITT Tumor Sec. Table 1.pdf": "D21C9833-B2F2-4214-8EFB-D830FD94A463",
    "LITT_CNS.pdf": "1A8848F9-FDDB-4E86-8A13-B793B7143545",
    "LITTCC_HDFT_poster.pdf": "107CB503-F183-4D7B-9A94-1E41CA48275F",
    "LITTCCPoster.pdf": "0F75A46E-94FA-45B4-8FD5-338D94D73D8E",
    "Liu Alice - Letter of Good Standing.pdf": "818F815C-8620-413E-9F11-B55BCB540BD7",
    "Liu CNS fellow.pdf": "27A11571-93D5-4293-8760-9E98B825B18D",
    "Liu letter (1).pdf": "48AA15CA-0362-4D93-8998-3B4F43239AB0",
    "Liu Letter.pdf": "431A58C2-54BC-43AC-9574-22A7E58908E4",
    "Liu, Ann - Status-Enrollment Verification Letter.pdf": "05D32B6C-E2BD-47AB-BC83-2F3D4671849D",
    "Liu, Stanley_Letter of Goodstanding_CNS.pdf": "EE2FC0AB-7BBB-46AD-9010-96CD72B5FD34",
    "LiuLetter of Certification.pdf": "E2D2C577-AC42-4496-A016-040D4905015B",
    "Live In Person Events_Release_and_Waiver_COVID.pdf": "EC76EAB6-9874-413A-AB5F-50423691EABF",
    "Ljubimov CNS Abstract 028522 Figures  1.pdf": "F382F455-DD63-4CC4-9D64-FDE5DF8E4999",
    "Ljubimov CNS Abstract 028522 Figures  2.pdf": "9B40AC45-374E-4A3A-A655-B6FBD9EAE317",
    "Ljubimov CNS Abstract 028522 Figures  3.pdf": "D5DC57BC-D592-4C24-A74A-D1AE21B1A8C6",
    "Ljubimov CNS Abstract 028522 Figures  4.pdf": "17C6345D-9B4C-433C-853D-35D7119731E0",
    "Ljubimov CNS Abstract 028522 Figures  5.pdf": "423A6DDB-C017-44A6-97BB-03809D4768F1",
    "LL flyer 2 updated.pdf": "91A5DF16-7E93-486F-81A6-E84E94487120",
    "LL.pdf": "9E0163E3-BAB7-4349-BB7C-2077690EB390",
    "LLIF group.   Fig 2 Comparison of Postoperative Metrics and Costs Between Groups .pdf": "FF3F8FFE-D608-4971-8B59-D20FEEAD463A",
    "LLIF poster.pdf": "EEEDFD7D-A1C0-4270-BCED-69EEEF04A593",
    "LMcGuire.emailverification.pdf": "EFEBCB75-C568-438B-97CB-EC9B80D11711",
    "LMWHichOR.pdf": "69FEA058-7707-4A14-A9C6-442A33912C4D",
    "LMWHvteOR.pdf": "811DFD77-A259-4327-8661-FEEE5520F463",
    "LNC poster final.pdf": "A02648ED-F4CC-4B61-BB29-DAF83E2CAE9F",
    "Lo Presti Vega Anna - certificado de pertenencia SENEC.pdf 1.pdf": "434E75A6-12F5-4ED1-929D-EA5822644FAA",
    "LOA_B.Gupta.pdf": "7DC983F4-BD34-47E4-8DB9-1FFE87840202",
    "location.pdf": "156B861F-6A5D-4CB8-94E9-0CFE3695B89A",
    "Lockard Gavin-Letter for Congress of Neurological Surgeons.pdf": "21A7656F-BEB3-4CDF-B55F-8573EE94893D",
    "Lodi CV 08-13-23.pdf": "D1919215-BB79-4A09-9B38-C512DA29AFA3",
    "LoeMarenCV-cnsapp.pdf": "246A65FF-2913-4611-A611-79D3C4F6275C",
    "loetable1.pdf": "518E7B3C-5A8C-4EA8-8769-F9736452FACD",
    "LOG - Kortz Michael.pdf": "A03F2204-5272-4C50-91E1-57E6FB6DE6FE",
    "LoGS - DapashMark (1).pdf": "BDB638BD-0711-4C2D-AEEA-D6E0761DC0AB",
    "LoGS - JimenezMiguel Angel.pdf": "C8442340-A6F0-4FA8-BB8C-5A391CF59994",
    "LoGS - Malnik.pdf": "FE7DF064-DA2D-4E4B-A1F6-A0441AB7464A",
    "LoGS - Nichols.pdf": "5CCE9D13-FDFB-4C78-A825-8D21F15FE29C",
    "LoGS - Sacks.pdf": "76CB6699-C01E-4570-BBCD-76FF2A880AA2",
    "LoGS - Small Coulter.pdf": "655CD088-17EA-4B71-ABAE-06A70901E91C",
    "LOGS CNS KP.pdf": "98F9743D-A3C2-4CED-93C8-BD9430CA9169",
    "LOGS for CNS membership_JubranJubran_8-19-21.pdf": "46CB5872-8454-4C85-8E85-3A2B4BEB23C0",
    "LOGS for Congress of Neuro Surgeons_KhanNaushaba_3-2-21.pdf": "5FF02140-FC26-4507-B85B-9AA8068AE340",
    "LOGS Neil Almeida 2021.pdf": "3DD1B6BB-B9C2-4164-B0C1-07BE7266AAD7",
    "LOGS Sami Milan Pathak 08_11_2022  .pdf": "20949125-2081-4024-8F03-2D20B38EA2AE",
    "LOGS Shivok 1.pdf": "C6FA3835-3EC8-4FE0-85DD-A90CDD238C17",
    "LOGS with expected graduation_Cadigan_8-17-21.pdf": "E597C02C-36F4-4CD1-B212-1D6E627AD57B",
    "LOGS with expected graduation_Wellard_9-19-19.pdf": "2AFA5248-6A66-4FCB-95C2-35C62ADC0CD6",
    "LOGS with Liability - Jukic Alma.pdf": "DB8D679F-1B2D-41C0-8135-EB955B3B7CC0",
    "LOGS with Liability - MS1 Kheshtchin-Kamel N.pdf": "A625F517-43CB-4A1B-87D1-BCCC1B699EBF",
    "LOGS with Liability - MS1 Park E 2023 1.pdf": "FBDDBD4E-31D2-495B-97B8-816C42D3E3F6",
    "LOGS with Liability - MS1 Park E 2024.pdf": "239CDDC2-D54E-4228-A84A-30E555E733A6",
    "LOGS with Liability - MS1 Porwal A 2023.pdf": "13BFDF35-532E-4587-87C9-D77842EDE38E",
    "LOGS- Raunak.pdf": "6EFC181C-31C3-45B1-8366-0E4C34F9A8CB",
    "LOGS-with-Liability---MS1-Geiser-Rachel-2022.pdf": "C158C1A4-23B8-4339-8588-BA61CEEA3163",
    "LOGS.pdf": "7D18A98D-41A2-40D2-86A4-5B80F7F35483",
    "LOGS_2011_MSpasic.pdf": "6C1F5F93-4208-4381-B280-559B874DFC59",
    "LOGS_Anthony Tang.pdf": "9858C266-488C-4332-A21B-949F385D738C",
    "LOGS_Audrey De Paepe.pdf": "7F90112A-A862-4A1E-A819-4BFC28F300D1",
    "LoGS_CanadaMontgomery.pdf": "92EC5EB9-11C3-4EAB-B0C5-1E4A3F4F48E8",
    "LOGS_Cole_Chokran.pdf": "E88B0861-009B-4BA8-8DAF-8D48E88EAB0F",
    "LOGS_DholariaNikhil 11.15.22.pdf": "AAFC34E4-E472-477E-A04C-987B10074172",
    "LOGS_Folz.pdf": "ED59E59F-BD46-4729-9DF8-7CE15205AA4E",
    "LoGS_FrankFarokhi.pdf": "3A53A213-E7C5-41AE-BDFC-444CAD7FAD58",
    "LOGS_Hart Fogel.pdf": "0FF56B80-0BF5-4E09-84F5-514E3B6C0C74",
    "LoGS_IBSmith.pdf": "790DB300-8B5A-44C1-B2EA-5AB8A219E563",
    "LoGS_Opperman.pdf": "2D81349F-21E7-46C5-836E-A825E13519D3",
    "LOGS_PoojaDave.pdf": "80392884-85C8-46D5-91A4-599F7F2E7F2C",
    "LOGS_SolomonSantana 12.6.22.pdf": "E919ABFC-4F33-4486-8F13-EACA726B623D",
    "Lohit Velagapudi Letter of Good Standing.pdf": "A9D7D6B3-3FB8-4EE5-90FA-81421D9A6F6E",
    "LOI_Zamarud_08.31.21.pdf": "E7B516AF-AE0D-47ED-8F93-CFA84EFBB639",
    "Lola B Chambless Biosketch.pdf": "1F790ABC-E998-48B9-AC5B-050910B7FE14",
    "LON for Dr. Kuo - Society of Neurological Surgeons.pdf": "0856ABBB-2CEC-49AF-AFE0-18DE0F4AAF25",
    "LON_Dr. Sean Lew SNS.pdf": "17601B32-B6A1-4AC1-8361-82F1528FB592",
    "LON_Ian McCutcheon SNS Nomination 9-5-18.pdf": "C420AB72-F763-4313-BB1C-8724570E80C0",
    "Long Gun CNS 2023 ePoster.pdf": "A70164B3-087B-456F-BB6E-B85F94868DF3",
    "Long, Don M.PDF": "A76DA57F-0924-4A89-9726-376965A21A06",
    "Long, Don.pdf": "828E558A-7270-44F3-A0FB-A77B6A64FE07",
    "Lonser LOS - Shahid Nimjee - AAcadNS Membership.pdf": "7ACF7AC6-651B-44F8-A052-608EFB42ACFC",
    "Lopez Diana Letter of Good Standing Class 2023 CBW.PDF": "E25E5B73-DFC7-4D5C-9275-E0FB232EA3E6",
    "LOR (Dr. Dawn Bragg for Juan Carlos Vera).pdf": "829BEADC-94C9-40AA-8E58-B9B3B890A15A",
    "LOR - ConnolySNS Pandey.pdf": "79C9DFAC-5CD2-4B5F-9C60-C78ED2B257E8",
    "LoR - Jack Butterfield.pdf": "EA2FD065-3965-4A3A-8128-663EC294D598",
    "LOR - Marie Dahlin.pdf": "D864287F-E670-42ED-B297-DBDCF017F02F",
    "LOR - Maya Harary.pdf": "C53A58F5-0224-451E-8A50-4FA5C7CCBF93",
    "LOR - SNS Chou.pdf": "83FECF7B-035D-4865-87F5-92FB1798E7FE",
    "LOR - SNS Moliterno.pdf": "06026059-A3FE-4DA3-A142-AC98736853BE",
    "Lor .pdf": "A4B99CF8-39AE-497E-87BE-D5292BF77772",
    "LOR 1_WRS_pub fellow application 2-4-22 (2).pdf": "A34AC046-2E93-4905-8400-270180BDE9F6",
    "LOR 2 - Sudheesha Perera.pdf": "82B68A5B-7183-4F39-8D61-70A517C839EE",
    "LoR Aureliana Toma May 2024.pdf": "FAD4B3CD-783F-4A18-8E63-0FBDDF5B604D",
    "LOR by PD for HMGNS.pdf": "0AFE17DB-A2C2-44AC-9429-47D0585C0C96",
    "LOR Chad Washington society of neurological surgeons.pdf": "10455A5E-3400-4D89-B103-31C2E45CDC3B",
    "Lor Cloney_CNS_comittee.pdf": "A54BCF4D-8441-4766-9C9B-298703DF3C7D",
    "LOR CNS Fellowship.pdf": "F7845D1C-E5A6-4B0E-B565-5AEF0CDB5C3F",
    "LOR CNS Institute.pdf": "C9C3A514-4516-4052-8A0E-1E0087393FF2",
    "LOR Dan Hoh SNS 2022 Mummaneni.pdf": "8CCB04B5-4E81-4CBE-B066-D831F388620C",
    "LOR dario.pdf": "095E0570-720E-411B-B78B-8F7A6B26D542",
    "LoR Doberstein CNS.pdf": "688D4859-C85F-4AB9-ACB5-693D9CE7FC1A",
    "LOR Dr. ALOraidi.pdf": "38952282-3E51-4477-BF05-FBE3D508B4B0",
    "LoR Dr. Newman.pdf": "A777073F-EA27-4DD9-8667-9B7CB4A453E7",
    "LOR Dr. Russell Lonser.pdf": "83405D46-7176-43E0-900E-AAC22E228BDD",
    "LOR FOR Annelise Sprau.pdf": "9157F9D1-0860-4257-99CE-21D4FBC31B9C",
    "LOR for CNS - Dr. Deepak Jha.pdf": "FA1816C6-760D-4199-BBA9-8B1D299300B0",
    "LOR for CNS publications fellowship.pdf": "6E8182A7-A785-4D27-ABE9-3C19C39B3745",
    "LOR for CNS Student Membership (KJB)[2].pdf": "4EFCB0F6-0434-4666-9B95-1531BEEC0332",
    "LoR for Destiny Greet CNS-CSNS Socioeconomic Fellowship.pdf": "C70819AF-6260-4743-BC51-5C27BB3902B2",
    "LOR for Dr. Shrivastava's SNS Membership -  Dr. Aman Patel.pdf": "275ED289-46D2-4790-A904-093ED449A038",
    "LOR for Dr. Shrivastava's SNS Membership -  Dr. Judy Huang.pdf": "659806B6-7572-440A-B481-729D90D3E215",
    "LOR for Dr. Shrivastava's SNS Membership -  Dr. Louis Kim.pdf": "630D66FF-0D6C-48EE-A3B1-F5DF90309BA8",
    "LOR FOR Dr. Victor M. Lu.pdf": "F8EB375B-7413-435B-B9CF-467BFE88D561",
    "LOr for K Tomei SAH 8.22.pdf": "C7806B9E-65FE-47F9-8BA6-85D436905E02",
    "LOR for Prof. Manuel Ferreira Jr MD PhD for Nomination to SNS - from Dr. Laligam Sekhar - Sep 22 2022.pdf": "6E8DA15F-61D2-4224-8942-B83C8CEC43C2",
    "LOR from Chambless_CNS guideline scholarship.pdf": "1955E429-E1E5-4DB4-9470-6142617A77D2",
    "LOR HFAG Residency.pdf": "D02698D4-E74B-4E13-84C1-A0CA89BAF387",
    "LOR Humberto Madrin~a´n-Navia.pdf": "7D49FFDD-EFC5-455D-A772-F435F2A6AA0C",
    "LOR Javed Siddiqi MDMummaneni.pdf": "8A8F9DCC-5107-41D5-B6ED-BCE320E19390",
    "LoR JT.pdf": "7E5B6748-7F95-45BC-BA06-BB9CF28F1FC2",
    "LOR Kalkanis _ Macki.pdf": "B373943B-4621-4FCB-A4E5-C25778D1934B",
    "LOR KMC..pdf": "3AC10713-BF90-4DF2-9CD0-8A8761E999E0",
    "LOR N Khattar.pdf": "8C6E93D8-B522-4C89-87C9-54BE0EBD37ED",
    "LOR Resident CNS Committee.pdf": "BE4D1BDD-460A-4D43-B725-443B67757BBD",
    "LOR SGK n ojha sir.pdf": "2361445A-662E-41EF-88F5-A133AE0E337D",
    "LOR William Taylor 2020_11_02 copy.pdf": "160C1596-B72F-4352-B417-B124A1383B1D",
    "LOR WTC CNS_MKarsy 1-2022.pdf": "D262B99A-A868-4B27-A1F7-2E7E98E34AF0",
    "LoR-CierraHarper CNS CSNS Medical Student Fellowship.pdf": "439069D7-B734-4B2C-AC12-10C42B1D00EE",
    "LOR-Ganju-SNS2020.pdf": "55AB5730-FABB-4B30-9FA2-1357B2672F80",
    "LOR-Holste 1-2022.pdf": "86AF59EC-FC7F-43AC-9AB4-CABB51CF088C",
    "LOR-Levy.pdf": "84C0836B-5D6C-4078-92CD-827487AA7ED4",
    "LOR.Forbes.SNS.22.prestigiacomo.pdf": "86FC118D-9724-4208-840C-D65FAAAD6B02",
    "LOR.pdf": "668AB7EB-0008-40C9-8D2D-70FB55F8D9C1",
    "LOR_2.6.2023.pdf": "AE5BF368-5AEA-4167-98E0-958C5ADC1B3B",
    "LOR_Cohen_SNS_MedStudentTeachingAward.pdf": "6553A000-EE1E-4013-81DA-3E95BF8C66BC",
    "LOR_DKH_CNS Guidelines Fellow.pdf": "50AD34F8-AF36-4454-AE09-44C6D8E42295",
    "LOR_Dr. Jamie Toms CNS Leadership_2023 by Dr. Bharat Guthikonda.pdf": "7AD7ADEB-0A62-4DED-8991-3901E76CD71C",
    "LOR_Harbaugh.pdf": "BE0D92D5-7D54-47BB-9848-A7A5C0E025BC",
    "LOR_Leslie.pdf": "D09C55B9-EA23-4CD6-B187-F43D9BA0B4F8",
    "LOR_Pouratian_SNS_2022.pdf": "74AEED9C-012D-417E-A8FA-50A3CE567309",
    "LOR_sc_CW_SNS_2022.pdf": "2E30BEC1-D2D7-45E3-A58E-EF4DF603A96A",
    "LOR_sc_GM_SNS_2022.pdf": "E588CCCE-9FB5-45B2-AA26-029935761AFB",
    "LOR_Sharan.pdf": "17E043A7-8003-4824-AF84-3F762BF1EFBE",
    "LOR_SNS Status_SiddiqF.pdf": "5BA11C23-DB13-4E29-950A-80E5E99D812D",
    "LOR_Zhao.pdf": "20FB0ABE-6932-4DB9-B79F-B8C82F0C272D",
    "Lorenzo Rinaldo-RESVerif.pdf": "D7E825EC-D2AD-44DA-8F4D-FA0135AE8496",
    "LORJuanVV.pdf": "FB16E31C-1D25-4E84-BB7D-3A51D303BFFE",
    "LORS.pdf": "E9766A86-FD7D-46D8-B86B-1170440619B4",
    "lorsrcns.pdf": "2A9939F7-44E3-474F-9250-40658E6DD80C",
    "LOS - Ian McCutcheon MD.pdf": "8C887F63-C583-43DD-AE7C-0935371D3EEF",
    "LOS Bayley CNS Data Scholarship.pdf": "C69A5EAF-BEDF-474F-999B-9D816D7BFC39",
    "LOS Bradbury_Kazi.pdf": "94D59499-9851-4658-96F9-AE9932FB3930",
    "LOS Eddie Chang SNS Nomination.pdf": "A6C557AE-B1B2-4AA7-B126-52EFF649EFB5",
    "LOS for Dr. Anthony Sins SNS Application_by Bharat Guthikonda 8.31.22.pdf": "A2BED9CA-E546-489F-850A-12E95A832673",
    "LOS Majid MS1_1-13-20.pdf": "910B92E6-D8B9-421A-8E1C-3D7213CFD580",
    "LOS S.Nimjee 8.2023.pdf": "44CC202D-9D86-4FD5-A0BA-8DAD58059EA0",
    "LOS V. Chang CNS _1.11.23.pdf": "44C5D7F5-E92E-43BD-9BDF-825F89FE794F",
    "LOS-Reynolds.pdf": "0DBB935D-FB25-42D1-B35E-D0D6B121DE62",
    "LOS.pdf": "47263B9F-171F-4A14-AF06-0796F33A21E8",
    "LOS_ACDF_CSM_CNS_ID_029143.pdf": "868CB44B-C540-4497-8010-E1178E0149CC",
    "LOS_Anthony Sin_SNS Application_by Bharat Guthikonda 8.31.22.pdf": "B93A416A-EFBF-4793-8D55-2A60BAD6959B",
    "LOS_Couch Jonathan_01-20-2021_CNS Leadership Institute.pdf": "7CBE8ECD-6D77-4D11-807A-36AA8A8B7AC6",
    "LOS_Extended_ACDFMyelo_AE.pdf": "7FA84F9E-464E-4A3A-9C84-A82BB9EAC8E0",
    "LOS_Franco DeMonte SNS LOR-Ian McCutcheon 9-12-18.pdf": "9B9D0B4A-4B1B-4CBC-A574-7D0277B17F11",
    "LOS_Mansouri.pdf": "9CFAC150-00EB-42BB-8A6C-5EBE033A23F5",
    "LOS_Park_SNS.pdf": "432DBB74-D9B7-43C0-96EB-22A9B45CF4C8",
    "LOS_Selden Nathan - Lew SNS Letter 9-18-18.pdf": "067BDECE-19A3-4E57-AE68-5D04F00DFE47",
    "Loss to Follow-up Univariate and Multivariate Analysis Tables.pdf": "53EA7E35-AFD2-4DCC-BD35-087926D5C0AC",
    "LOV.pdf": "A4EAF533-45B6-41AE-90ED-5DA09C3DB56B",
    "Low NIH poster .pdf": "72D1C44B-1431-417A-8375-8DEAE5F2B767",
    "Low v High Dose RT for AM.pdf": "5BA771AE-0956-439E-A397-2B2366F170A2",
    "Low_vs_High_Poster_PDF.pdf": "BB873CBC-E6B8-4023-A32D-8EEF1BA75D8E",
    "Lowe Verfication.pdf": "89DE4A39-8357-4930-AF5D-83D761B31ABA",
    "Lowe_S Good Standing Ltr.pdf": "227C0057-3C25-4A2A-984F-107532550666",
    "Lox_CNS.pdf": "2B5FA950-129D-4708-B597-9A756591A926",
    "Loyola V gressot CV.pdf": "F607F87C-F362-4706-B20B-1BB8C920D289",
    "Lozano, Andres.pdf": "584BD328-DC02-4504-9A5B-549D7675D8DA",
    "LPhilipp Academic Standing.pdf": "8FDA7F13-7889-4F8A-B5DA-925269D32BB3",
    "LRinaldo.emailverification.pdf": "3C7FDE5E-E291-4615-B497-E827EC8BBAA5",
    "LRosalind Lai.verificationemail.pdf": "3D03D3F7-F6C1-4160-8359-A53A44DE0959",
    "LSC_CNS_Poster_Presentation.pdf": "E272A076-2652-494D-8161-67FA25816825",
    "LSmith.emailverification.pdf": "BD71D721-2158-4B1F-9F20-4429E1C87F32",
    "LSMU193180.pdf": "B0B8445B-AA2F-4DA7-8DC1-E99DC25D69D9",
    "LSU Health.pdf": "FC1D6FB3-C784-4F77-8F98-4BCD7F80BE80",
    "LTE New York Times Surprise Medical Bills.pdf": "18BC7E08-36B1-4317-AEBB-454314F07FBC",
    "lte_on_10.23.14_article_on_primary_care_final.pdf": "B589C3E0-008F-4CF5-B46A-7A9916FA756E",
    "lte_wsj_spinal_cord_stimulation_041614.pdf": "2EE635DC-FF82-4768-BD36-02B1D3F9C418",
    "Ltr of Support from Dr. Alfredo Quinones-Hinojosa for Dr. Chaichana.pdf": "A6809687-633A-44BF-9A6B-5F135C897544",
    "Ltr of Support from Dr. Henry Brem.pdf": "C75751F3-B3E7-4FC6-BD45-BB17CF293423",
    "Ltr of Support from Dr. Judy Huang for Dr. Bydon.pdf": "DD58752B-6E31-4ED1-A6F4-11B654A095BA",
    "Ltr of Support from Dr. Tony Asher.pdf": "03FBA0F0-6110-4115-ADBB-9888C1CF7B51",
    "Ltr of Support from Dr. Ziya Gokaslan for Dr. Mohamad Bydon.pdf": "038D070D-75F0-40F9-ADE1-C47A0BEB9174",
    "Ltr Support Rosalind LaiMD CNS Leadership Course.pdf": "5744805B-B552-4297-9D1C-487445834B0B",
    "ltr_good_acad_stand_AnthonyJang.pdf": "85D424D0-A198-4CFE-9B9A-CC8F9FC6B215",
    "ltr_good_acad_stand_SamanthaHoffman.pdf": "60DB004B-C502-449D-B531-8B7CBA31C0A2",
    "ltr_to_gop_health_care_reform_task_force_-_brady.pdf": "1556BEDF-0A18-4717-B68A-B98B4D00E806",
    "ltr_to_gop_health_care_reform_task_force_-_kline.pdf": "014D96DF-F400-4306-9ED5-2369AD1BBD20",
    "ltr_to_gop_health_care_reform_task_force_-_price.pdf": "283F6933-6DE5-47D5-877D-9257B697B126",
    "ltr_to_gop_health_care_reform_task_force_-_upton.pdf": "205CF214-FC3F-4CB2-8513-E605D98399FE",
    "LtrtoCongressionalleaders-budget.pdf": "FFA91602-530D-450F-A228-A495BFE3C558",
    "Lu_good standing.pdf": "38A288FF-5333-4618-B1BA-BE5052658AF8",
    "Lubelski CV Feb 2023.pdf": "F6722278-D07D-4CD9-9B80-9541DF617F15",
    "Lucar Figueroa_FWLNSchol_2021.pdf": "14F836F3-EDFF-49AB-BA15-09B0B9963123",
    "Lucas Carlstrom-fellow.Verif.pdf": "788076DB-18A3-45EA-99BA-B082F23FC755",
    "Lucke-Wold.pdf": "619A4532-3376-4B33-A1C1-9549DB05EB6C",
    "Lucy Li .pdf": "558439EE-3B97-4005-92EE-F69DDBD59846",
    "Lui-austin-verification-CNS membership-2022-04-05.pdf": "C5474283-1850-45E0-AA32-09448538EBA7",
    "Luis Alejandro Pe´rez Ruano CV.pdf": "A9397628-C6AB-458A-A709-8ADA248AAB4D",
    "Luis Armando Gallego Hermosillo-RESVerif.pdf": "3A5EC162-97B7-4C68-BA14-DD2CEED4E623",
    "Luis Everardo-Fellowship.pdf": "2E92CC54-C624-4DC6-878C-33AA0AB0C5D4",
    "Luis Horacio Ramirez Silva-Fellowship.pdf": "0BF70F9F-CD24-4CA0-85EE-3F1CDAA24896",
    "Luis Netto letter.pdf": "A5E8E6DA-D8E8-4B30-8167-4481D01E18B5",
    "Luis Ramirez Silva.pdf": "DF4A5883-4986-46AF-9C93-375DA23D4447",
    "LUIS SANDRO AGUILAR ALVAREZ - NATIONAL UNIVERSITY OF CAJAMARCA.pdf": "C802CDAE-AE43-49C2-9E78-4052ABF179AD",
    "Luiza Moraes.pdf": "25E8EB01-3D9E-433C-8B03-AD347A370D45",
    "Lukas_Rasulic_CV_English.pdf": "441A0016-480B-4001-A625-CF65AC7C6E37",
    "Luke Smith-RESVerif.pdf": "F05C8973-3E6F-4292-A356-353D26FB9AAB",
    "LUKS_11.11.2022_18-10-17.pdf": "66664A07-4C55-4B72-9FF9-2487D80EBAF2",
    "Lumbar burst surgery.pdf": "0E78F635-6536-4C1C-8458-301C391A2018",
    "Lumbar ROM Table update.pdf": "71F34631-69F7-4FBF-94D0-8F07DE5C264A",
    "LumbarDDD_Elderly_Readmission_AE.pdf": "65B90C55-109B-43F7-BC13-D174A694A5E6",
    "LUMC Flyer.pdf": "F4F4ED90-808D-41D5-85DE-06AB6AFBB8F1",
    "Lunsford  Thomas LORs Combined.pdf": "E872D224-C504-4961-92E7-39C2968D609D",
    "Lunsford LOR Final.pdf": "242AE184-3732-4ACB-89D8-3B145BFADEDE",
    "Lunsford, Dade.pdf": "C603D096-63F4-4FD8-AA55-85FEFFB15C1A",
    "Lunsford, L Dade.pdf": "65C87826-7FBE-4912-821F-23C5A703342D",
    "Luxwell jokonya CV.pdf": "04A6B1A9-5054-4DC5-91B0-3C0001EA3C2A",
    "Luy_Diego_Current_CV_2024.pdf": "5B5A2AF2-073C-45A2-98F0-392A6CBFF8C2",
    "Luy_Diego_Letter.pdf": "4407432D-F9A7-4D0E-B5F7-5F05DD46F1C9",
    "Luyuan Li-FellowVerif.pdf": "6244577A-2931-4A28-ADAD-D19BC748A9E8",
    "LVO MT BE poster.pdf": "DDE3ADD6-A1D4-4855-A7FA-01810EAE60CA",
    "LW reference.pdf": "36F86028-F2AF-469E-801B-7A8802D7ECC7",
    "Lynch.Daniel.Letter of Good Standing.8.22.22.pdf": "B530D48E-4754-4055-9376-D34F68CD8332",
    "Lynn_Anna_Good Standing Letter.pdf": "6E8BBB66-E545-4798-91D1-A6F7D3B6FF63",
    "LYONS_Samantha - Letter of Good Standing 051920.pdf": "6DF9F674-584E-4D2A-8274-79F4CBB58EC2",
    "lysouvakon24.pdf": "77193060-9586-48AC-BC67-722222B0F5A6",
    "M Ch degree 2.pdf": "02E64E6E-80E0-48FD-B227-3C0E10F0D907",
    "M Nouri - LOR 1 Program director.PDF": "38A62747-9182-4010-8C87-9A1868DEB2D8",
    "M Park Letter of Good Standing.pdf": "9D1E53A0-A5A5-4B2C-B44D-11B5E61C5F50",
    "M. Anderson LOGS.pdf": "52E379F8-D811-4521-B71F-6E1C564CA35D",
    "M. Burhan.pdf": "F2C80A82-5A99-42DF-BEE2-8BBA5646B365",
    "M. Ernst - Letter of Good Standing and Enrollment Verification 1.pdf": "4C149302-E767-4871-AC1C-647916C3CAE9",
    "M. Fana - Letter of Good Standing.pdf": "7D722EE4-351B-4767-8119-BAABFD17C374",
    "M. Fu Good Standing.pdf": "E1BF7128-0130-4655-BFC8-23984870BA61",
    "M. Jolayemi.pdf": "AD684EA1-BBF6-425F-A4F6-3FA81F3F1BCF",
    "M. Maher Hulou-Fellowship.pdf": "2892E074-0B98-4978-8356-BC75CA2E2F8D",
    "M. Schmidt SNS Nomination Letter for C. Bowers.pdf": "E9E30C87-7ADD-43DF-9B5A-9A4FEE145F86",
    "M.Catalino-Verification email.pdf": "313FE6DB-9623-4BAE-88D9-A4B5E97CBDD9",
    "M.Ch Certificate.pdf": "A0AF5BB0-57FC-4A72-8111-9A48982C44E9",
    "m.ch. gmc registration.pdf": "E3EA438A-D3C2-4071-93B5-E9E68F23B6CA",
    "M.Iqbal-RESverification.pdf": "7E522686-FD48-4C99-9C66-911FE3DBE618",
    "M.Macki_email.verification.pdf": "4DC075A0-9F42-4EEE-A600-2744E8EB9D1C",
    "M.P. CV 1.5.2023.pdf": "CB7A63E3-40B7-41F4-A65F-0C6961070607",
    "M.Torres Suarez-RES.Verif.pdf": "512D3124-DBF9-41E0-B92F-E719FEE0ACAD",
    "M.YS- RES verif.pdf": "AE9F1C7B-294D-4DF1-AF0D-45477F225DBB",
    "MA 1099-HC Aetna.pdf": "ADF376D2-FE8E-4E1A-90D6-769A929B5BE0",
    "ma_draft_2016_call_letter.pdf": "8F0CAEC0-0432-4FCA-A36B-8E3C37EB6D12",
    "MAA_CV4.docx 1.pdf": "8BEB5ECA-61B8-4D99-B2EB-0006457C0BD9",
    "Macdonald, Loch.PDF": "E1FC07C5-04E0-48A4-A54A-7BE7E6366D9D",
    "MacDougall CV 2019.pdf": "FB3E76F5-0C48-4949-8DAC-C69BF0450841",
    "Machado AG LoS SNS Membership ECB 09-28-22.pdf": "E17E6571-52FF-4B33-91FD-5479D4D0B499",
    "Machado AG LoS SNS Membership MPS 09-28-22.pdf": "D9F1C567-597C-4561-AA78-C1B1CFD5AF2C",
    "Machado Andre Nom Letter DB SNS 2022.pdf": "9806EAC3-4C15-4627-986E-9E851C391784",
    "Machado CV CCLCM 2022 Sept.pdf": "62FAAE6F-5E26-42A3-88DE-4470DA8B4B98",
    "machine learning's potential use.pdf": "95577E6C-D3C2-4599-B0E8-BAAED19556F0",
    "Macki CV.pdf": "0C440DAE-87A1-4DEC-B00B-CFA2E3E2CABB",
    "MACLEAN-CNS 2022.pdf": "46FAA51A-07E6-488D-8B1A-BD9034EDDACB",
    "macra_comment_letter_release_11.19.15.pdf": "7816C36A-509B-47FE-A2DC-74BA12306637",
    "macra_eac_-_final_letter.pdf": "0334E235-8E35-4EB5-A8CF-3AC76DFB3FED",
    "macra_finance_committee_release_7.13.16.pdf": "37E1235C-353C-4261-AEE0-6D64920321FF",
    "macra_joint_letter_with_physician_organizations_final_updated.pdf": "981FC86C-D989-4900-A109-4DC503AEE207",
    "macra_rfi_comments_11.17.15.pdf": "E49AB8E4-9EC0-405A-AF54-4B613A8E3509",
    "macra_rfi_sign-on_letter-nov_17_2015.pdf": "251893DC-4170-4B60-B137-6FFD246F5831",
    "macra_states_and_specialties_joint_letter_final.pdf": "98037DC7-1640-42B1-818F-24BF6DF8E104",
    "macra_update_7-17_websites_.pdf": "5EF1C995-5497-4DDD-9AF6-C65B5AAB5DCD",
    "macrophage-va.pdf": "992270F4-EA1F-4D6D-9971-FD7A9427B305",
    "Madeline Greil-FellowshipVerif.pdf": "2B7C020D-7FA8-44B7-90FA-3127DD3F02AE",
    "Madeline Kalenits CV.docx.pdf": "A7997E9E-4661-497A-9216-891AFF198DDF",
    "Madsen Residency Diplomas.pdf": "42A501F7-7E20-4818-A73A-2A55F3F3BDC4",
    "Magafossis.pdf": "87171626-1039-4421-A469-5EAF8C7B1EBF",
    "Magalhaes_cv.pdf": "C0E8287F-E2DD-40C1-B19D-C971E884E9F5",
    "Magen's Resume 2011.pdf": "A44B2040-5E97-42D7-8CB9-5446FCAB0F15",
    "Magnendo.pdf": "811BBE68-42E0-489D-919A-5AD750E23F19",
    "Magnitude and Origins of Gender Differences in Medicare Payments to Neurosurgeons.pdf": "BC642B86-5FF8-47B8-9BB9-432C73FF45AE",
    "Magown Verification.pdf": "DDE228ED-7480-42F9-AB23-9A246118C270",
    "magro_CURRICULUM VITAE.pdf": "CBD75C58-AA81-4DE5-8405-0937D63E6ACB",
    "Mahajan U (LOR).pdf": "8C417D2D-C2D7-4706-AA2F-09632FC9FF96",
    "Mahajan Uma- CSNSCNS LOR.pdf": "7BB0CEE3-8624-4295-B4C9-77987141F92A",
    "Mahajan_Uma.pdf": "27A4598F-C0D7-4000-A333-DE91DBC5D301",
    "Mahboob Khan.pdf": "50DE59E4-6BA6-4FD9-B8C4-589505450A8F",
    "Maheshwary 8.19.pdf": "7BEE04E9-267A-4937-9030-69DCC55E6840",
    "Mahmood F. Al-Zaidy ( Mar22 updated resume).pdf": "B8F3D0F3-19B7-4B5E-9478-DDF0E1DB80AE",
    "Mahmoud Elguindy MS3.pdf": "61CC33CF-3760-43B9-99E6-6C85B6E5EFA8",
    "Mahmoud Magdi Elsayed - CV for CNS Membership.pdf": "2CE5E025-01AE-4888-A927-9205A736DDBC",
    "Mahmoud Magdi Elsayed - CV.pdf": "176EDF03-8B07-4241-BB1C-ABF348706B63",
    "Mahmoud MD  CV .pdf": "043E07A5-C347-4EF7-ACCF-EBF2178F2CC4",
    "MaidanCVnew.pdf": "BD78ACEA-DA50-40B1-8A26-7CF9FBA7A2AF",
    "main.pdf": "67D7ACF4-C0F9-40DA-A71E-2C2BA3A53FFE",
    "Main4003 pdf.pdf": "9F620776-E368-4465-A09D-B10BDA9DC9EE",
    "MainFig1.pdf": "5900E0DF-FB7B-4718-BB9D-0D09FAE00DB0",
    "MainFig2.pdf": "3B60CEA3-2C44-435B-901A-9648D94A5E60",
    "MainFig3.pdf": "72DA6C89-17EB-4980-BB7E-8599C554CEF5",
    "MainFig4.pdf": "8F22B921-CC61-4BC9-BEBE-AD8FE26B55E7",
    "MainFig5.pdf": "4109CB38-0DE9-473F-90E3-A0AD6EA17010",
    "MAJ Hooten Kristopher Hawaii Edit.pdf": "80967E9D-9A77-4E4A-8935-1154A3D5F979",
    "MAJ STEPHENS BRADLEY H. ORDERS AMENDMENT AND ETP.pdf": "4AC2841F-985E-4D04-9D9B-03BA4AFF9E32",
    "Majid Aljoghaiman-2ndFellowship.pdf": "64687112-2361-4EF3-8869-96129D25B33A",
    "Majid Aljoghaiman-FellowshipVerif.pdf": "D095ADF4-5A14-423B-8215-3C1AA012CE8F",
    "Makhoul V.LOG.pdf": "A62AAC31-10D4-4B22-B8D0-78E75111873A",
    "Makler - DHMC NSGY Residency Certificate.pdf": "4D5425FE-3A45-4E6F-9335-350AA42692B3",
    "Makler - USN Active Duty Letter.pdf": "113AC5C7-602B-41ED-A06A-83DCDEFE1962",
    "Malacon Karen - Good Standing Enroll.pdf": "2B24BF7B-032B-4E54-9A69-68B68B86E783",
    "Malcolm McDonald[1].pdf": "BE64A7C9-F672-45A8-8E79-2245074327D5",
    "Malek_CNS_InnoAward.pdf": "776F9EB9-FBAA-491D-BD9B-860CA82AEA62",
    "Malhotra CNS Reference Letter.pdf": "D8609456-5FD5-4A87-B54D-ACA1613109C6",
    "Malik Nasyr.pdf": "49E2AA8D-6726-4C2B-89B3-475FD83DA83E",
    "Mallela Arka - LOR Hamilton.pdf": "933FE8ED-770D-4141-A8D3-E6F37B88FA23",
    "Mallela, Arka ltr. for CNS.pdf": "8C75F3E4-FBF0-4494-A954-0784EC706E3F",
    "Mallory Dacus CV.pdf": "6F739E5D-8CC1-429D-9251-0E1801D2EE8C",
    "Mallory Dacus-Fellowship.pdf": "063E1FA0-2496-4E54-94AD-E2CFC33D9196",
    "Mallory Dacus-RESverif.pdf": "D424F8EB-8C10-4C24-B99B-7D8BD9F78E4E",
    "Mallory Peterson-RESVerif.pdf": "34353CCD-2C56-4018-9B32-FB4594111A3C",
    "Mallory_Noah_LOGS_M1.pdf": "E46D6F04-8E9D-4040-8CC0-89AB0531781E",
    "Malone_Hani.pdf": "D40C4714-0E98-4163-8D85-E569B8F5CA8E",
    "MAlotaibi-RES.verif.pdf": "F2FACAA8-C2D6-41C3-A943-8F55FEA5AEA4",
    "Malpas Abstract ID 639 Towards long term intracranial pressure monitoring in hydrocephalus animal validation over 6 months.pdf": "608E5F90-3CFC-4B35-9C36-9A5FF25B00E9",
    "Malpas Abstract ID 680 Safety assessment of a discrete micro-implant.pdf": "10359D6B-A051-46F1-A3BF-384A8853C644",
    "Malpractice Approval letter.pdf": "74C8C85A-140E-4C50-BEBA-DB484A2D7D44",
    "MAlshareef.emailverification.pdf": "12E8D17A-30B5-4F84-9E41-ADA3F722B2BB",
    "Malueg gsl.pdf": "935B8D42-7860-4C5D-8B08-DF2A0F7AD132",
    "Management of Brain Tumors Presenting in Pregnancy_CNS Poster.pdf": "9E7E5C4A-A3E4-4B70-A5B9-80A207C1C8CA",
    "Management_of_Acute_Combination_Fractures_of_the.17.pdf": "BDD77308-B6CA-4309-A342-3BF4320F9518",
    "Management_of_Acute_Traumatic_Central_Cord.21.pdf": "7D3F6616-B0B1-4995-8279-2A49629362E9",
    "Management_of_Isolated_Fractures_of_the_Atlas_in.15.pdf": "01542026-10D2-4692-9129-A357F4413253",
    "Management_of_Isolated_Fractures_of_the_Axis_in.16.pdf": "0AAAE3D0-2AA0-4ED8-AB6F-34ABD979158C",
    "Management_of_Pediatric_Cervical_Spine_and_Spinal.22.pdf": "78C0285E-CF5D-4928-B659-8D5E429946BD",
    "Management_of_Severe_TBI_4th_Edition.pdf": "2CDC9F56-2C58-4414-A4AE-83548B9D3E3F",
    "Management_of_Vertebral_Artery_Injuries_Following.24.pdf": "43C6BA79-8E92-487B-BC6C-46B405F487F7",
    "Manager Corporate Development.pdf": "C5504DD7-AFF0-4612-9587-65890318B0AA",
    "Manakhe Nassiuma-program letter.pdf": "50083B69-37F2-4E06-8878-66851DAAF5DF",
    "Manakhe Nassiuma_CV_2023.pdf": "5DCF4EFE-A8D9-4C1A-BACE-0AD49F404CB3",
    "Manan Shah-RESVerif.pdf": "38AACA03-6F6F-47DF-9885-EC774F795232",
    "Mandura.pdf": "AAE58D46-2B74-4F15-9679-A7635874CB7B",
    "MANGEMENT OF HYDROCEPHALUS IN POSTERIOR FOSSA TUMORS IN CHILDREN.pdf": "91B5083B-9715-4EF8-A810-D491870FA71E",
    "ManghamCVAug2023.pdf": "C9F62862-EC54-48E7-A6E3-6767BFC780F4",
    "Manik Nangia-signed.pdf": "1643347A-B93F-47D7-A990-0C7FB5CE4F38",
    "Manjari Daniel – UCSD Medical Student Enrollment Verification.pdf": "CEFC4E64-EB50-4237-BEFA-0DABDDAD4FF4",
    "Manley LOR for Okonkwo.pdf": "9CDE8FBC-A7FD-48C5-A51C-0C72ED9A9B4C",
    "Mansouri_PAR-20-052.pdf": "48BC97BD-C8C9-4AAF-A499-C0251C05D2D2",
    "Manual Ferreira Recommendation Letter 1.pdf": "8241B26B-8213-42D7-89DB-AC554C6EF300",
    "Manual Ferreira Recommendation Letter 2.pdf": "A09E7BF4-1B8A-4339-BF06-EB0B4728EBE7",
    "Manuel Ferreira Nomination Letter.pdf": "D7ED4F81-29B2-4969-A4EA-2917FC722CC7",
    "Manuscript Mallela et al.pdf": "3A1E57DB-0286-44E0-8740-63D59F67A636",
    "Manuscript Toradol JANE Final 11-1.pdf": "0DBE799A-8DD5-4C2A-8A8A-36A35F5F5D6E",
    "Manuscript.pdf": "1F65D474-07C3-46A4-A420-642BF1E42EEF",
    "Manuscript_SRS.pdf": "1C0C032B-AD8D-4D13-AC0E-85FD337FB453",
    "Map of Charlotte Venues.pdf": "2264F5C3-A76A-48BA-A1C7-0BACB417E924",
    "MAqCI Technology.pdf": "25A38225-6EC7-4D21-812A-0E7D65B097C8",
    "mar17_entirereport.pdf": "DC55C386-EAB9-4173-A47B-30052B03C1B4",
    "Mara Nicole Lamothe.pdf": "ADF543D3-E603-4886-AFC4-6253F45AB621",
    "Maragkos Curriculum Vitae.pdf": "749285D1-45EB-472A-8C2F-5EAF704C6323",
    "Maragkos Personal Statement.pdf": "AC3959BE-473B-437B-BC9A-7420036B20CC",
    "Marc Prablek-Fellowship.pdf": "AC41A504-B300-4549-886B-04557207CAD9",
    "Marcelo Costa - CV 2024.pdf": "3A75367A-C085-4202-AD5E-2739344406DB",
    "Marcelo CV - english final.pdf": "7F9B3546-2B26-4790-A7CD-6695C29F9B6E",
    "Marcelo Hyczy DaCosta Neto.pdf": "4BB41B88-0420-4118-8DCA-5F263014EA9B",
    "March 2012 EC Agenda book_2012-03-03.pdf": "7EBF3BCC-4170-42CE-87F3-703C8CD10AA0",
    "March 2013 EC agenda book attachments_2014-03-05.pdf": "2039065D-19A8-4A65-82B8-B1D4B68A63A9",
    "March 2013 EC agenda book_2013-03-06.pdf": "B975CDBA-7437-4A6D-848E-C6347762F20C",
    "March 2013 EC Meeting Minutes_2013-03-06.pdf": "1CA4B725-DAC5-433B-8AF3-C20E8FA90113",
    "March 2014 EC Meeting Agenda Book_2014-03-05.pdf": "222DB941-DD8C-4E40-98D0-E7DC411A0DE7",
    "March 2015 EC Agenda Book_2015-03-04.pdf": "AA77F4C4-F3E4-4563-8F84-EBD4F35EDBA7",
    "March 2021 journal article FINAL.pdf": "13150A4B-335C-453F-A08D-FECBD8930EA5",
    "March 2021 podcast article.pdf": "3673C1CD-05D9-44FB-8B5B-1FA9BD6C348E",
    "March 2022 Journal Article.pdf": "89C685A2-8E91-45B2-A1E3-21EC31D6E8F7",
    "March 2022 Journal Article_updated.pdf": "6C08402A-D8FF-4583-844B-0BE680631D2B",
    "March 2023 Journal Club article.pdf": "1E49F690-1259-4785-8912-BBD639C5D8C8",
    "March 2024 journal article.pdf": "C51560A3-55DD-40CA-8A80-BAF6B5F0B6FE",
    "March2013EHRSupportLetterforRepBlack.pdf": "5C24B339-431B-4A39-9D74-33D3537E024B",
    "Marchetta-Cruz.pdf": "8BCA5149-4D44-42E2-8C7C-F279D522D7FE",
    "Marcus Alexander_Good Standing.pdf": "0052EA9D-3583-4546-98BD-B8AE25683A5B",
    "Marcus Gates VER from Mayo.pdf": "4C87F7A7-83B2-4FAA-8381-72D4CA1447F6",
    "Marcus Stephens-RESverif.pdf": "6052FDF5-2750-4A16-A92A-4557B3193CE9",
    "Maren Loe.pdf": "B1FD62FE-7D2E-48AF-A47D-45CB1A3C227E",
    "Maria Barros Guinle.pdf": "D41080C3-0C1C-4EDB-A510-5A2345CE4A50",
    "Maria CNS letter.pdf": "A09508D2-7429-41FA-A26F-4004A7CCBE80",
    "Maria de la Cerda Varg.pdf": "83C88A94-42F7-4661-A347-BAA1C9A5AEC6",
    "Maria Isabel Ocampo Navia CV CNS.pdf": "D1493B69-E62F-41B0-828B-C84C046E5B3A",
    "Marina Buryak.pdf": "B5D03429-B0A3-42CB-AB90-2F4F56AE13B5",
    "Maring.Ashley-Cover Letter-CNS.pdf": "D1AE70E0-8591-4089-A399-5EBB2E04D01D",
    "Maring.Ashley-Resume-2024-final.pdf": "907994F5-9F02-4B49-BB73-3D12832C820C",
    "Mariye Erol Demirturk Medical School Diploma.pdf": "1398FADB-B3E8-446F-848B-F93E285A1B37",
    "Marjan Moghaddam– UCSD Medical Student.pdf": "F08B310A-0DDD-4CAD-92E6-31391D9EE7E3",
    "Mark A. MacLean-Fellowship.pdf": "EAA8F9FF-8EA2-4BA0-AC0D-770589F4C1D6",
    "Mark Bain Recommendation Letter 1.pdf": "5135FD4A-4F24-4FED-9B8F-F84EAF1F5187",
    "Mark Bain Recommendation Letter 2.pdf": "D594B551-6BAC-4A11-B389-872E76D58B9B",
    "Mark Bain Sponsor Letter.pdf": "97AD5BD2-0248-4E9A-B330-C8798EE08EC8",
    "Mark Robinson Recommendation (1).pdf": "7E5AE355-FF5B-4154-BABF-7755E2CFE6DD",
    "Mark Stephens 11-4-2022 CNS Guidelines.pdf": "3F09E505-74AC-4A43-89A0-477A28252F44",
    "Markert, James M.pdf": "3C47E522-9DCB-48EA-AF40-B0A04736C832",
    "Markert, James.pdf": "4B790BE0-42AA-40DB-9272-EFEF6230CF80",
    "Marketing - a double edged sword - G. Barkhoudarian.pdf": "F235C4CF-EDCE-4536-8632-E898F0729346",
    "Markosian LET.pdf": "5B7995F5-B290-4421-BEFA-724AE29A0CFB",
    "Markowitz.Daniel.Letter of Good Standing 2017.pdf": "CBFE48F4-E1D1-4C63-A23D-2B43A8110F94",
    "Marlon_Reid_-_DR..pdf": "C88BAC07-77B7-4818-B160-02E39C2EF727",
    "Marotta LOS_Draft_Bentley_jnb SIGNED.pdf": "4DD357A3-D0FE-47B8-A3DD-C07CA8F3A099",
    "Marotta_CV.pdf": "E0FC0A8B-D572-48FE-9127-0394DC8F1738",
    "Marte VK CV.pdf": "C1582B93-225E-4739-BEB8-E3DE59581900",
    "Martin De la O Gonzalez-RESverif.pdf": "02EAF573-70B3-4E85-B33D-9211BB10DC36",
    "Martin Piazzaz-FellowVerif.pdf": "C1FAE2D6-DBFC-4771-B0CD-2161D3D9080D",
    "Martina Mustroph-Fellowship.pdf": "6A7B87A0-31AC-4D86-A388-C428E13D300B",
    "Martine Letter of Reference CNS Fellowship.pdf": "27D48352-D4F6-4DA6-B7E1-DB0EF4204191",
    "Martuza CNS letter.pdf": "ABFD2093-8FB6-47A4-8513-0C6F59EA94E4",
    "Martuza, Bob.pdf": "EE8F2C0B-AF11-4B8F-AFBA-17135A87F7A0",
    "Martuza, Robert L.pdf": "3409D9F2-29BA-4012-A913-5B4AA38D6D6B",
    "Martuza, Robert.pdf": "C5248780-E69E-4D1F-BFA8-2503675352DD",
    "Marwa Emehemd Membership.pdf": "B8B15899-2D31-4171-AE9E-9C4F33C80A80",
    "marwan.pdf": "2C0E920E-BCFF-408C-AC8E-D8F74FD39C73",
    "Mary Ashley Liu.pdf": "701645C8-1990-4153-844B-4A439D0F1F57",
    "Mary Solou CV 2023.pdf": "49B07D96-C896-426C-9DE4-341BC0D3F323",
    "Mashhour Alsuwat certificate.pdf": "F4166F63-5AFA-4B15-A0E9-D33153DACDAC",
    "Mashouf Student Status Letter.pdf": "D8723B76-E6C3-4F0E-9F62-C5FA55AECF43",
    "MASL Student Certification Card.pdf": "20D27A9B-54A0-41FE-B57C-42A064230EAE",
    "Masood Umar - LOGs  Enrollment Verification_.pdf": "9C63E155-EC54-47A0-B3B7-023A462BD6E4",
    "Mass IOP.pdf": "981797B1-4FD2-427A-8F8A-3C5F5FEA21D9",
    "Masten (2001) Resilience.pdf": "86E09ADD-5139-4543-80B7-1FFD013B88AD",
    "Master Tables 11.18.19 Final w legends.pdf": "06CCB6CF-0003-49E2-8733-123379F5742B",
    "Master.pdf": "9064C87C-5BC7-443D-AC0B-51A99CBDAABF",
    "MastorakosCV_2022.pdf": "7FE2314F-B924-4A6B-B264-C0398DD54B41",
    "MastorakosCV_2023.pdf": "3F968312-AD01-4262-9068-CF8966FE0B3D",
    "Mata.pdf": "D1CECA85-36F0-48D2-B5C7-420A09CBFA31",
    "Matassa Patrone. Certified .pdf": "E569034A-23EE-4C38-B3B5-A5A86378D986",
    "Match Letters Gaub_Michael.pdf": "7E225957-2745-4D98-BC0D-4EF94A9BE2B9",
    "Match Result - SF Match - Residency and Fellowship Matching Services.pdf": "98737E04-BF2E-4630-BD0D-4C653680E8D0",
    "Matei Banu-FellowVerif.pdf": "1CFF66DC-C1A3-435D-AAE3-35DDB81F8713",
    "Mateo Nouel.pdf": "C1E5DAAA-2212-42CE-9277-48745FBB09E6",
    "MateriJoshua.pdf": "82FD8458-A801-427F-8F86-98494231189C",
    "mathai sir certificate exp.pdf": "42B880E1-BCA1-4BF7-A0E2-DC3F0AC3E8E7",
    "Mathkour-CV 07_10.pdf": "8B742B8C-BBA4-47FD-9380-C8D0CC0C8E71",
    "Matricula.pdf": "64FE5EB0-4611-4DC5-9B05-146566709779",
    "matrma 3.pdf": "40AA4F5A-6620-4310-A785-5C2FBA67FA24",
    "Matt  Howard_ SNS Membership LOS Schuster_102320.pdf": "4F428CAD-6B61-4867-BFA0-49702EED7AC6",
    "Matt_Robbie_resume.pdf": "C745E27A-6DF5-45E6-98C7-4A8B29396444",
    "Mattar_Layth_2024_ASSFN_Poster - Final.pdf": "445559FA-3C01-4CE8-9732-0C618B97DF72",
    "Matthew A. Amarante - Curriculum Vitae.docx.pdf": "E4C6EEB3-8AB6-431E-B37A-C02360809BEA",
    "Matthew Agam's Letter of Good Academic Standing-School Enrollment Verification.pdf": "97207F3F-A0C6-4AA2-8DB1-351C9EAA695B",
    "Matthew Anderson-FellowVerif.pdf": "7511FB8E-5B97-4A66-B5BE-AA8C6C222362",
    "Matthew Cummock CV 1-1-24.pdf": "10DE8CBF-8665-4C57-AA5F-A05B0A6A4D93",
    "Matthew Eagles-FellowVerif.pdf": "478B7C02-0954-4CB9-8695-F687C6A5EA89",
    "Matthew Kole verification.pdf": "79ECA7EA-3A89-432F-A8C1-235DA869D611",
    "Matthew MacDougall.pdf": "B4A8CAFB-D06C-4BEF-8AB1-A6004936C5ED",
    "Matthew McPheeters-FellowVerif.pdf": "58C75C8E-7A60-4230-B729-7FA39B0908AC",
    "Matthew McPheeters-RESverif.pdf": "E9CF5DB9-1C6B-4AF1-8F25-E14F5FFE620B",
    "Matthew Muir - Enrollment Verification.pdf": "D2685CE7-B65C-47A8-886D-6725A9CB0343",
    "Matthew Ordon-FellowVerif.pdf": "AF711008-072E-4576-9953-FB4E17C749AD",
    "Matthew Parker Blackwell.pdf": "CF08BEE8-559C-4A8F-92F1-7C28A8F48E42",
    "Matthew Pease-RESverif.pdf": "8A97690B-6D13-42CF-9D9B-F0963DACA643",
    "Matthew Recker-Fellowship.pdf": "01F88421-A061-41DE-A7E2-FA115A8B02C1",
    "Matthew Smith-Cohn_letter of good standing.pdf": "27ECD212-660C-4D6A-9CF5-E1E0B1923802",
    "Matthew Sun-RESVerf.pdf": "54B5BBF5-5A28-42D5-A611-00E4FFB90A23",
    "Matthew Willsey-EmailVerf.pdf": "5EA8E7E4-12CC-4472-8425-6BC769C88288",
    "Matthew WillseyRESVerif.pdf": "5259CD24-1272-45A8-9659-1A8CF41DDB0F",
    "MatthewPease 2022-CV.pdf": "58276610-CDF6-4050-ABB5-CBECF67455FA",
    "MAUDE Poster .pdf": "54D9B3C1-21DF-40ED-9EB6-E50DCB822612",
    "MAUDE.pdf": "A017EACB-1DB5-4461-92EC-FB993C75839E",
    "Maureen Rakovec Certification Letter.pdf": "1EC62364-F043-49B0-8D43-4D73617F4A69",
    "Mauricio Avila-RESverif.pdf": "2A43852C-C41E-417A-A363-52903ACFC860",
    "Maurilio Ochoa -EmailVerif.pdf": "7049E449-B537-48EB-B079-6A5F32C5D9EF",
    "Maury Student Status Letter.pdf": "A956C185-973F-456E-873F-321EC6221A68",
    "Max Diameter.pdf": "5C479C7F-E05C-4A5A-9522-6726613B3AD6",
    "Max Kahn-RESverif.pdf": "C32F0AB4-1278-4490-8D31-7891F2105723",
    "Max Kahn.emailverification.pdf": "7C42C682-EA18-464D-A3CB-CE33F184164C",
    "Max Shutran CV Harvard format.pdf": "F0D67330-B244-4D7B-8C1B-F2E3CE0BA36D",
    "Max Shutran-RESverif.pdf": "F70A2436-C712-49CF-A745-20F7A4A7BA38",
    "Maximiliano NUN~EZ CV.pdf": "0B354100-9128-4B87-809C-B81FB7569C26",
    "Maxin Anthony.pdf": "49B7DF3D-3E71-48B8-8EA9-A7D199B511BC",
    "Maxin_PS_CNS_2021_CK.pdf": "875DCDDD-4E9F-4C83-957C-5A1C3DD85CD9",
    "Maxwell SNS Award documents.pdf": "506C2385-1FAB-401D-8DFD-85E4FC61BCE7",
    "May 2021 journal article.pdf": "BA68F394-33A0-4697-8DAF-5F55E198D43D",
    "May 2022 journal article.pdf": "A1E2C94C-9173-4C25-A9FD-38CC40639458",
    "May 2023 Journal Article.pdf": "B626217A-D503-483D-A181-AF26A3295742",
    "May 2024 Journal Article.pdf": "08554C17-759D-470B-9A62-589790944601",
    "Maya Harary - CV.pdf": "7448F83C-9E38-4E0C-9431-ACB267D7B72C",
    "Maya mam.pdf": "71526807-7FE5-4205-ABC4-430775AF5C12",
    "Maya Parker_Good Standing.pdf": "8C17FA3B-0128-4EF7-9E95-5C6F63B96D08",
    "mayank choudhary.pdf": "1E4E3B40-E115-438B-83EE-C2B8C080C281",
    "mayank CV.pdf": "27EEB59C-1521-4DE0-91A1-AA43EC3E1568",
    "Mayberg, Marc R.pdf": "301618F8-8502-4A24-8EC5-26E17A59FBE9",
    "Mayberg, Marc.pdf": "4B90B21C-EC32-4BA9-9B7A-1FF68072D1D1",
    "Mayeku Letter of good standing-Julie Mayeku.pdf": "45A42C30-9598-465F-B035-F4974A8BDC53",
    "Mayfield, John[236].pdf": "45E8CFF0-ECC8-44E6-9251-0E14C558531C",
    "Maynard_EV-2.pdf": "2C8BAA26-684E-4CEF-AE4D-354FE7FF4D13",
    "mayo burn out .pdf": "57303EE1-AC25-4967-8328-ECFF61D6D85A",
    "Mayo Clinic NS Program Flyer - CNS Fair.pdf": "F668ED49-9573-4FEB-A2EC-7C98A77E6A4B",
    "Mayur Sharma- RESverf.pdf": "24BCA381-B4AE-460C-A2DF-256960E86F1A",
    "Mayur Sharma_ recent CV_.pdf": "CA445650-F995-4041-BDAD-F9FB3DB8DE31",
    "Mazen Alotaibi CV 2022.pdf": "8B530F1F-386B-409A-B4A5-221ABC46B2D4",
    "Mazen Zaher-Fellowship.pdf": "F2D7479C-692A-4CFB-A93F-B8F186FF780B",
    "Mazor Leksell Table 1.pdf": "50222F91-5133-4886-9044-907F94FEC0D4",
    "Mazor Leksell Table 2.pdf": "CFAA4BB9-9810-4C7F-B2CD-3C0718A256A5",
    "MB_CNS_AlcTBI.pdf": "FACB8615-64D8-49AF-9623-543B147FE130",
    "MB_CNS_Cervical Trauma.pdf": "E8618DF0-C20D-4257-BC47-49C22EDD11B1",
    "MB_CNS_Jumped Facets.pdf": "5F17715F-E296-4736-8624-AA0975A9DE53",
    "MB_Seq_CNS_Fig1.pdf": "79C414D6-BAED-4A90-8DC7-F8EEF51E948F",
    "MB_Seq_CNS_Fig2.pdf": "94961C94-72FD-4AD2-A62B-7B5547A3FBEA",
    "MB_Seq_CNS_Fig3.pdf": "CC9E0272-B373-42E5-BE79-4D12F40E6B9F",
    "MBaier LoGS.pdf": "D2324D56-E39B-41D5-8ADE-8DD11C5535FC",
    "MBBS 1st Professional Examination Marks Statement.pdf": "BF6E7605-7384-4657-9914-574091C49BA4",
    "MBBS 2nd Professional Examination Marks Statement.pdf": "5E5D1240-C136-4C14-AE7C-5B9BFFD2B524",
    "MBBS DEGREE.pdf": "87DBDF03-6709-49BA-A1B1-12C5BA6AAD70",
    "MBBS Uni Degree.pdf": "4DE47EF5-D59B-498D-9AD9-A3CA9CEF1E13",
    "Mbilinyi Robert-.pdf": "EF16F023-8DB8-40F8-A53F-5A4C780029BF",
    "MBL BROCHUREwelcome_to_woods_hole.pdf": "685BAC34-B56C-4A9B-BEB7-B599D6A7D191",
    "MBL Liability WaiverJUNE272018.pdf": "0DF7C59C-57BB-4E09-8C99-25A2A93C410A",
    "MBL.Campus-Grouped-Colors-parking-bikes-2021-with-legend-2022.pdf": "0B8A2BEC-2701-4321-8685-8DD767B86291",
    "MBLcampus_map.pdf": "F9683F16-3741-4D94-9429-6478A2C52424",
    "MBM-CNS-0731.pdf": "1C027400-5EDB-40B1-91E2-B582460AE4DD",
    "MBydon_AAPC CV_June2020.pdf": "67907494-9CF1-44BB-AAE8-1F9A900FA08A",
    "MC RSN Angheluta.PDF": "93F4A2AB-13E6-4224-8DEF-CAB3EA645C6E",
    "McAlpine.pdf": "E0F5BE98-1964-401A-8E15-06521368CD15",
    "McAree Michael_verification.pdf": "C36C37F9-B5DE-4DAB-9935-02356FDC9260",
    "McAvoy.Malia_CV_01.08.24.pdf": "CE9EF0BE-FFD0-4D2D-9400-DF13AF47F475",
    "McBriar.Joshua.Letter of Good Standing.5.17.22.pdf": "7E0E3A9B-882B-4513-87AD-BF8ED47939E3",
    "McCann_student_letter.pdf": "B05FEFC7-A5C7-4502-AD85-44DFB31FF82D",
    "McCarty Patrick good standing letter.pdf": "6ABDF36D-4D30-49F3-ADF6-659F7F09A65C",
    "McCormack 12.17.14.pdf": "DF1806DC-41EE-40B2-B413-43497C338295",
    "McCormack CNS Cranioplasty 2023 FINAL.pdf": "5A79EF18-5D69-4CCE-89F3-60120F78AE76",
    "McCraySNS06092013.pdf": "30EA9F2B-E954-4574-A2F1-F9F87188A927",
    "McCririe-Balcom Morgan - Letter of Good Academic Standing 02.26.24 bls.pdf": "E6E0496C-8E1B-4460-8C34-86D3F1CA3F00",
    "McCutcheon_SNS.pdf": "9D1D1FBF-A14F-4FB4-AA75-6042FEA39960",
    "McDaniel Duke CV CNS 20220202.pdf": "006A57EA-53F6-4066-9CB6-3AC5227AD573",
    "MCDF&T.pdf": "2718A667-AD0B-4971-86E9-D660F9D3ADD2",
    "McGarvey Cade.pdf": "819A5ADE-97E6-4B16-B7BD-5BD8EFC9C502",
    "McGill Collin Good Standing Letter.pdf": "0E3F6DE1-E248-46AE-9B9E-D4E5EB84B528",
    "McGill_Univ_Letter (3).pdf": "6DF19173-2E5B-4DBC-B902-DBB011F2065E",
    "McGill_Univ_Letter (5).pdf": "AE5D881E-136E-4F6C-BCC5-0ACADE06930C",
    "McGill_Univ_Letter 3.pdf": "35C4E530-C805-46E7-8507-C4953B928823",
    "McGill_Univ_Letter 9.pdf": "3FC6BE2D-A10D-4AD7-BC1E-B67A65D74CF1",
    "McGill_Univ_Letter-12.pdf": "42702CCE-3318-419F-81C8-D49A67C308B0",
    "McGill_Univ_Letter-2.pdf": "4E952618-9710-4983-927E-5642BEE10D6D",
    "McGill_Univ_Letter-3.pdf": "18B74F78-922D-4CB9-94BC-A64EA096CAC1",
    "McGill_Univ_Letter.pdf": "366F57C5-D569-424C-AB30-4A31581A327C",
    "Mcgown Verification.pdf": "C18D9891-4B18-4F78-BA52-596D0BA13475",
    "McGrath Kyle - letter of good standing.pdf": "D5CBE6EE-4AE0-44C6-98A7-181935A1803C",
    "MCh Degree.pdf": "D99AC1E7-8A32-4E3A-990D-2A40C7C7C0DA",
    "MCh Neurosurgery Certificate.pdf": "4C253A2F-57A9-4A20-A96E-0277F951F9E0",
    "MChaga_ASSFNPoster_041524.pdf": "32130311-508A-40F7-BA70-3C3284BD6C56",
    "mci reg mch degree.pdf": "0F693B17-5F23-41FF-9E4C-17BEECF587D6",
    "McKhann, Guy.pdf": "58354362-5376-47DD-9DC1-8358C068911F",
    "MCM_RG_CE.pdf": "BCAE73C0-B51C-4880-8132-BE97540EF857",
    "mcmahon.pdf": "5E2D5CB4-18F1-4BBD-A3C1-12857625B7F2",
    "Mcmaster certificate - VER.pdf": "F4713FAA-AC4D-434D-B91D-487EA2EA56AB",
    "McMordie CV. PDF.pdf": "4678ACE6-25AA-4A82-BDAB-8353F46F9B92",
    "MCNEALCV16.pdf": "445E427B-9C78-4006-BD6E-DE5DE906C046",
    "McNeil Evan - Letter of Good Standing.pdf": "E531B8F3-10AA-4664-86A9-0621814B7036",
    "McNeill_Ian_LOR_CNS Leadership Institute.pdf": "D14A38F2-F584-4522-AD6E-D73C2671CD29",
    "MCPF Research Guidelines.pdf": "CAD170D8-BBA2-4A29-AD23-7551D58FDB41",
    "MCummock_emailverification.pdf": "24D9F004-9E1A-4074-BE9D-6D3F88D524FB",
    "MCW Letter.pdf": "5F62A333-8C65-40D3-AC8D-222EB83E97AE",
    "mcwstandardcv 2021-0315cns.pdf": "152AA93A-0CE2-4A4E-801B-59E6CD559ACF",
    "mcwstandardcv 2022-2a.pdf": "BB631036-5808-4F80-97BB-D19054B77159",
    "MD Anderson-Curriculum_vitae-_Claudio_Tatsui1.pdf": "D0E2EB37-57BA-4B7B-852A-7EEB4B141EFB",
    "MD certificate.pdf": "5E6B4410-D9C6-4584-997A-846FEAAC0FD7",
    "MD DBS Poster.pdf": "8D835742-5EE1-4C99-AE22-D54DDCC00B9F",
    "MD FT letter_Tusa Lavieri.pdf": "DF4984CB-08A6-44B5-9C31-D4150A9BDF0C",
    "MDA CV YOUNG.pdf": "2FD6E3C8-DB79-4E4D-92E0-332EBC90DB51",
    "MDACCenroll.pdf": "6C6DE359-DCD6-4DC0-9145-65464DFCCA5C",
    "MDelacerdfaVargas.verificationemail.pdf": "37C75BCF-5384-4998-BE9A-4A20E40805B3",
    "MdeLB_CNS_Poster.pdf": "8A2EA0B0-048C-4E3F-AA9D-7D3B71B71868",
    "MDSC_figures_final.pdf": "3B89BF0B-67E8-4C2A-8043-C39DCE996702",
    "Meade Seth - Letter of Good Standing - 2.8.22.pdf": "86747480-9F8A-40DE-B2B4-B34E0617877C",
    "Means .pdf": "DF5DC7AA-5991-4AA9-9C2C-99ECDC1DC8AF",
    "Mechanical Ventilation in aSAH.pdf": "D925EBDA-1C02-4E1E-9E74-F722C3BDBF5A",
    "Med School Enrollment Certification.pdf": "6F60A20E-5654-4779-9A44-09450F70C7D9",
    "Med School Letter ver B. Block.pdf": "F82462DE-C36B-43A9-AA56-352D0B03B72B",
    "Med Student Milestones Evaluation.pdf": "AF19DD75-A1A7-427A-BDD4-AC6B71F1A338",
    "Med Student Research_CNS poster.pdf": "AA3E06B1-E7BE-4B1C-A654-D20B086DD182",
    "Med. Stu. VER - S. Gandhi.pdf": "01CCE95E-BDF0-46CF-9808-14B4B6F76778",
    "med_student_letter_CNS_Fabiano.pdf": "C231976A-2C2C-4152-91CB-B81400145C5C",
    "Medical Certif Translat.pdf": "2BD69C3B-C141-4CA7-99E1-52BC0EB17227",
    "Medical Council Certificate.pdf": "8009C0B7-5BED-495D-AF04-A441EFFF72F1",
    "Medical Council Registration certificate of Doctor.pdf": "6A6CDA51-74C3-4453-AF7D-471D76A5A66D",
    "Medical CV - Halldór Skúlason - 2023.pdf": "B75FC256-EF8D-4C8D-B89F-8598FAF4E485",
    "MEDICAL DIPLOMA AND SPECIALITY CERTIFICATES-Khalil ALQADASI.REScert.pdf": "86C09389-EF7F-425F-954A-E54C6B7E9B30",
    "Medical Group Judgment Interest Letter.pdf": "E8AB2596-FC04-441D-B305-719B536AC8D5",
    "Medical Librarian.pdf": "0D305EB4-6FDD-439E-AF88-EED2AA235811",
    "Medical Memory Optimizing Patient Care and Satisfaction- A Single-Physician Study.pdf": "4AD84893-6C91-4016-AFA4-885F6824ADAC",
    "Medical Review Panels and Neurosurgery_CNS2021.pdf": "96E4EAC6-BBFF-41EC-80B8-8239391FBC46",
    "Medical School Diploma.pdf": "87482141-318A-4C79-9835-DC9DFFD6971D",
    "Medical School Enrollment Verification.pdf": "01C4B652-1B35-427A-A3EE-E8C7597B9E3C",
    "Medical School Letter_TM.pdf": "680A4CC8-F64B-4341-A3BA-3B9CB98A28EA",
    "medical school verification of enrollment.pdf": "C7228530-F970-4920-AFF2-52103288C38E",
    "Medical School Verification.pdf": "9066E351-B1E7-4739-8F94-DC60D39D601F",
    "Medical School Verification_Quoc-BaoNguyen.pdf": "BE13637B-30C4-41CE-865D-BE00C6AC0C43",
    "Medical Society Certification.pdf": "C088A2CD-F595-40AE-AE82-E3BD29A6B6B7",
    "Medical Student Member Application AANS.pdf": "A557FE4C-57BF-47A6-920E-D35194962029",
    "Medical University of South Carolina - Wright Machaj.pdf": "E41B6480-EFBF-41BA-8F05-E3986BC7996C",
    "Medical-school-certificate.pdf": "055C1ACB-9121-4F9F-B370-FDD05E2506B4",
    "medical_group_letter_7.20.16_edited.pdf": "9965B694-286D-4D23-9725-803469D3D3F1",
    "medical_specialty_support_letter_cmmi_poh_demo1.pdf": "AD657EA2-47C9-43AD-B480-D28650641108",
    "Medical_Student_Enrollment_Verification_Form_2010.pdf": "5E9C8F00-2D8B-4DFB-958C-5C1A512FCD42",
    "MedicalLiabilityReformNewsRelease100909FINAL.pdf": "65C31237-0403-49BC-BFA5-94176F6B3810",
    "Medicare Coalition.Payment Stability 2022 Letter.FINAL.11-29-21.pdf": "78BA24DF-7F02-4399-927F-F29DE48F52A7",
    "Medicare Deformity + Osteoporosis Data Final.pdf": "779C9D71-3F20-433A-A0D4-BED5734CC4D2",
    "Medicare EM Letter - Formatted - Signed.pdf": "8D250E67-5282-40D7-9D7C-8D56091EB1F8",
    "Medicare Payment Coalition.2022 Congressional Activity.FINAL.2-25-22.pdf": "45FE1BE9-47E9-4E88-95B6-785076E83DEE",
    "Medicare Sequester Moratorium - Health Care Org. Coalition Letter.2.2.21.pdf": "6AC73A4A-AC96-43D6-A4F4-54BBB65DF184",
    "MEDICARE-PARTICIPATION-OPTIONS-FOR-PHYSICIANS-2008.pdf": "22FE5BAE-E044-464B-BBA1-5778844CF9D4",
    "medicare_advantage_prior_authorization_letter_to_cms_-_signed.pdf": "F3EC9503-10F4-4327-ACFC-D23B3E531392",
    "medicare_physician_fee_schedule_2015_side-by-side.pdf": "1D26769D-988C-4B2E-B06F-7D2A07CD8230",
    "medicares_role_in_addressing_the_physician_shortage_briefing_invite_final.pdf": "067C3D01-DC42-4BB4-BC0E-06AF91C0C8D6",
    "MedicareSurveyReport2010Final.pdf": "DDD328E0-95E8-4B3D-8113-9A8471E7AA68",
    "MEDICINA.pdf": "3C2DE861-D682-46D8-AFC9-2752C3AF8B5D",
    "MedPAC Update Letter to Congress 031523.pdf": "8A560EE9-26CE-456E-B956-76FFF0FEF109",
    "medpac_letter_rebalancing_the_pfs_toward_primary_care_final.pdf": "DBD829E9-38D7-4EFE-97DC-7C44813CCD58",
    "medress, zachary v.2.pdf": "303B4916-C715-4575-9E76-64150BB0D4AD",
    "MedStudent_ID.pdf": "20CCB60C-FA5B-4694-AE29-1B8AAD2FFA02",
    "medstudent_verification 1.pdf": "0F1BA80E-E49B-46B4-9619-47714EE4F004",
    "Meeting Services Association Proposal_2016-01-27.pdf": "644896EB-854C-4357-915E-2DA73710B808",
    "Meeting Suites Application for Exhibitors _ 2024 CNS Annual Meeting.pdf": "62669FE0-D6DC-4A8B-BB07-3BBCB2D17BB4",
    "Meeting Suites Order Form for Exhibitors _ 2022 CNS Annual Meeting.pdf": "FE5F2D9A-A0F2-4105-98B3-2D32BB0711C0",
    "MEG.pdf": "38DEAE0F-972C-4197-8962-1C67DD19C183",
    "MEGA.pdf": "690ECBF9-BFF5-4A1C-85A2-6DE4ECB8677C",
    "Megan Everson-RESverif.pdf": "38CFE9A0-F962-4528-A6D5-087E1E67F3B2",
    "Megan Wetherbee CV CNS.pdf": "4162D6E4-0A87-4ECE-ABE7-A7963266B7FA",
    "Megana Saripella  – Verification Letter.pdf": "A4C4E7B4-84E8-4889-AB44-90A9F69080FF",
    "Meggyesy verification.pdf": "320F15C2-A625-4D6C-A0FA-022B6B73F9F0",
    "Megh Kumar_GS.pdf": "D150FCCE-477B-4B97-BE10-CBE7210FB6B0",
    "Meghan Price Recommendation Letter_final_goodman.pdf": "AA6772C8-4B4F-4B9E-8A0D-494D8450343B",
    "Meghdeep Sen-Letter of verification.pdf": "87847495-0722-4B1B-9AF5-26F9F45C6EAF",
    "Mehkri Yusuf (3).pdf": "C680A15E-2474-48D8-940B-861942281529",
    "Mehkri Yusuf.pdf": "37D9C814-EE6E-4228-9CE2-24941EE0BB9C",
    "mehmet volkan harput.pdf": "459CAC66-C98D-40D2-8C53-977F0156C1FE",
    "Mehra_neur.2022.0084.pdf": "A70FDF77-4642-43E1-95D9-10CE1A4C74EE",
    "Mehra_Todd Maugans MD CV March 2023.pdf": "C503037B-71E8-4067-AF22-2D72004AA371",
    "Mehra_Todd Maugans MD letter of reference for Mehul Mehra Summer Fellowship application.pdf": "27BA7D5C-EC3D-4A04-9924-0B1D36C6FA5A",
    "Mehra_Todd Maugans MD letter of support for Socioeconomic Fellowship application.pdf": "5AEDAFE3-32A7-4C3A-B9B3-74A09E34C1D0",
    "Mehra_Vale-Letter for MehulMehra- Socioeconomic.pdf": "CA9BEA15-DC13-488D-8256-305B7C05FDA6",
    "Mehran Ali Letter of Recomendation students.pdf": "C55E2C8A-303D-4540-9CA1-F9F60F33F9F1",
    "Mehta LET.pdf": "AB87AE0C-71FA-477A-86F4-CEFA557BEB34",
    "Mehta_Good Standing.pdf": "1673D5A8-4AB7-4B74-BFDB-FF04E4FD7B5F",
    "Mehtzamani Verduzco-RESverif.pdf": "7D74D34F-EB39-4A37-9DDA-844C04AEFF6C",
    "Mehul Mehra letter for CNS.pdf": "20A0996E-2928-418A-9F9A-028CC9219707",
    "MehulMehraCV.pdf": "678EC9C3-CCFE-4F77-8FCC-27FEDF326864",
    "Mekbib_Kedous_Good Standing Letter (1).pdf": "7F615D4F-129E-4479-9945-25D303327C01",
    "Melanie Alfonzo Horowitz Certification Letter.pdf": "AAEB0B4D-369D-4327-8AF3-D47935636D2A",
    "Melanoma.pdf": "39E93196-3520-411A-8A4C-13E70F2051E6",
    "Melih Bozkurt Turkish Neurosurgical Society membership.pdf": "5525FA0F-F224-48BF-9925-0F435A18C5E6",
    "MelikeMutAskun-CV-May2022.pdf": "816B0FCB-5220-493B-B009-B10E9F523EDA",
    "Melissa LoPresti-RESverif.pdf": "318DC088-591C-4B06-8A5B-FB3EDD8E9909",
    "Melmer.Patrick.UVASOM.Enrollment.Letter.pdf": "0A03DB26-1392-4EAA-981D-4745E24C410E",
    "MEM-EXEQUIELVERDIER.pdf": "29A00803-563F-4545-A34A-9C3F9BD8C00D",
    "MEmara.letterVerif.pdf": "AF427A97-8358-4FD3-B28A-005D4B702FE1",
    "member DGNC.pdf": "9A8FC721-22FD-4E34-80BF-57A908D0EB25",
    "member of eans.pdf": "8C17A74B-466E-4C1E-985E-14252E265444",
    "member of KNS Jin Mo Cho.pdf": "2E075C9E-C2C7-4773-9373-394D43398E2C",
    "Member Profile as of July 01 2022 Ellianne Dos Santos Rubio.pdf": "E4BD191E-6983-430E-8793-0DB24B2D4604",
    "Member SENEC.pdf": "1C74F3B0-4391-49D7-80B3-748B5C4B7DD8",
    "Member Services Manager.pdf": "0588942C-50A5-4079-BD99-057CFFF8AAB1",
    "Member Services Specialist.pdf": "7B362500-8915-44A9-84CE-8ED8CBEADE95",
    "member_societies.pdf": "E38E2C96-4824-404B-A236-2B6A1A7EB828",
    "Members as of August 1951.PDF": "CEC95CE9-49AB-4189-B8F5-CFFC00292E42",
    "Membership card.pdf": "1805604F-DDF5-4ED6-9C6D-92286C4E5525",
    "Membership Certificate - Dr Hrishikesh Sarkar.pdf": "480BD467-D036-407F-88D7-FFD0FBA64106",
    "Membership certificate German Society for Neurosurgery DGNC_Hecht.pdf": "B7FE36FA-5034-4110-B220-739478708DE1",
    "membership certificate.pdf": "AC7FE0F4-7430-4EE0-9CFC-0B3CA5E2993F",
    "Membership Certificate_Hanna Aliashkevich.pdf": "71E8EE0E-FE3A-490F-B3A2-67C5FE76C384",
    "membership certification.pdf": "C870C9DE-AEEF-41CA-B94F-F506A7060BCE",
    "membership cft.pdf": "E6D8931C-AADC-474A-A7A1-A6AA0BED3021",
    "Membership confirmation CNS.pdf": "78C517D4-4CE4-4BE4-9427-EE4DBA9A2BCA",
    "Membership Confirmation Letter.pdf": "ED884E31-53CA-4DE4-A07B-E4AF79769B70",
    "Membership confirmation_EbmeierK.pdf": "300A3F84-D5D8-41C8-919A-7AC4CDD50130",
    "Membership NVVN.pdf": "025EA2B2-2C50-4CFE-AA28-9126623AEB8E",
    "Membership of International Neuromodulation Society.pdf": "4A8EB900-187F-4642-A9BA-619BEA074E90",
    "Membership of peruvian of neurosurgical society compressed.pdf": "3F588C28-0F43-4A73-B3C1-CC2EBE528B3A",
    "Membership of the German Society of Neurosurgery.pdf": "42939295-8FA6-431C-9672-9506EDA853F4",
    "Membership verification letter to the Congress of Neurological Surgeons (Dr. Salah Abdulrahman Al-Akkad).pdf": "FC8F03E9-ABE5-44F6-8B10-F1A6AFCE78DC",
    "membership.pdf": "17C588DA-4559-40B7-B9D7-0AE822E1450B",
    "membership_confirmation_Buchalla_26-01-11.pdf": "8C4C6748-E2FD-4CD0-A749-D05BD41FAB7A",
    "membership_confirmation_Hagemann_14-01-11.pdf": "F61AEE4D-6E8D-46FD-B7B8-2EB51FD4CF17",
    "MembershipRoster.pdf": "5354BC34-F2D4-4581-842B-19E40D4FC1F3",
    "Membro Academia Brasileira de Neurocirurgia.pdf": "C5E4928F-47CE-4429-9EE7-39794B434B87",
    "Memorial University of Newfoundland (2).pdf": "4A26B8CA-F995-428F-9C6A-18A73624E2C6",
    "MENBRE CNS PDF EE.pdf": "800E5C48-282F-4580-9D82-6EA450C8FE70",
    "Mendelow, David A.pdf": "02233ADA-4995-4F58-ACCB-686DC2212985",
    "Meningioma Discharge Table 1 Patient Demographics.pdf": "3B6F12AE-A51A-414F-9071-905D49A9F65C",
    "Meningioma Discharge Table 2 Patient Clinical Characteristics.pdf": "AC336802-B068-4AB6-B9E6-8559B05E2CD5",
    "Meningioma Smoking Tables.pdf": "ABF0B859-A365-4F40-AD80-1B19C39BEB1F",
    "Meninioma Discharge Table 3 Multivariable Analysis.pdf": "F00F9520-7AD1-4A65-8E0F-EA0BCF0B0040",
    "Mensah-Brown Kobina (Ltr. for CNS).pdf": "5F17A925-DDC6-4682-9F3D-A4739E251DF5",
    "MentaA.CNS.pdf": "F3DA1DE8-532B-4471-91A0-04FCDA58A0A0",
    "Mentorship and Peer Network - A. Asthagiri.pdf": "A5CB9D80-F71E-44BB-B9C7-B841FAD88F27",
    "Mercer CV 2021.pdf": "593C47ED-DB99-40A5-A791-409AB894BA3E",
    "Mercy Mazurek letter 4.20.2023 2.pdf": "2803AD0E-A072-42CF-81F8-172500E650FC",
    "Merkaj_research_letter.pdf": "D6D8A735-1CD4-4A30-ACF8-29DF325F2506",
    "Mert Uzun - Transcript.pdf": "B6CDC1FA-11C0-4F21-8A6D-58CAF1308B9B",
    "Mert Uzun.pdf": "89078E67-2C26-4BF2-9705-868A30D336ED",
    "Meryem_Filiz_Enrollment_Letter.pdf": "8BADA749-3634-42D7-8418-46B44A645BFB",
    "mestet cv updated 2023 January 8 resident.pdf": "5431059B-321D-487A-ADB6-F492A9261220",
    "Mestet Yibeltal Curriculum Vitae CV Feb 2024.pdf": "740A4140-7559-4271-86FC-0B06D9F9B4BC",
    "Mestet Yibeltal Curriculum Vitae CV.pdf": "B0C2A546-9236-458E-8E66-9B806CDA93FA",
    "Metabolic Syndrome Poster (ASSFN).pdf": "F64DF33B-E666-4604-A72C-9F0D09E31B78",
    "methadology (1).pdf": "172CF84A-F060-4AE7-9B3B-D767E3B8C812",
    "Methodist Hospital (Houston) - Barber Scranton.pdf": "2EE7D7B8-F6EC-42E4-AE88-6911B8211FF8",
    "Methodology_of_the_Guidelines_for_the_Management.5.pdf": "A492D4B5-A6E2-4305-9B4B-B406F0ABB96C",
    "Methods- Imaging Analysis- ROI Measurement with Osirix MD.pdf": "8B4663CC-6959-4420-8ECF-3624586BAD24",
    "Methot Craig Letter of Good Standing.pdf": "FCC847C8-7345-4F8C-ACDC-E1CDC072688C",
    "Metixene paper - JCI.pdf": "D7EDC62D-27F8-43B6-9554-1B1A1085BB46",
    "MetseegoumSimoLeticia-LOGS.pdf": "EC988BD6-87DD-4607-A20D-409C89F19AA3",
    "metzger_bias_assfn_2022.pdf": "C9105140-D210-4E91-AD21-561ED4580A4E",
    "Mews.pdf": "62B64528-DCC7-4178-A3D9-7325F1A2D83A",
    "MexicanCongressCertificate.pdf": "0D48DF20-CE55-4E5A-B635-DD5F52CB578A",
    "Meyer Fredric CV.pdf": "CCDA1B2B-720A-4984-A172-30D42D001917",
    "Meyer Medical Student Teaching Award Bouchal 020824.pdf": "E260FB00-A138-41F1-8D73-B5DC0CB82086",
    "Meyer Medical Student Teaching Award Van Gompel 1_23_23-final_j.pdf": "F5D39F2C-F02E-4CEF-A29A-DB6F72A1B286",
    "Meyer on Orina Josiah Ltr of support for SNS Membership.pdf": "771E24F9-38F4-4529-AC86-61C73277F6FE",
    "Meyer, Fredric B.pdf": "DE248CCE-E5B4-4E0F-9AE7-4EF8A5403532",
    "Meyer, Fredric.PDF": "F995C088-6677-4A1F-8FC6-8ED6918460DD",
    "Meyers J - UBNS Residency verif - CNS- 21 0513.pdf": "A6B33C78-1CA8-4171-886C-B8E0D8558A7F",
    "MF_letter of good standing_2022.pdf": "07C99506-B6F4-4D7B-A513-3ECC6F397FDC",
    "MFayed_Verif.Letter.pdf": "3511EB21-6966-4CB5-BB3D-F90BBCE18F9E",
    "MFerrufino Mejia.emailverification.pdf": "6D4AF8C9-0B71-4F0A-B35A-6F29D6EE6F1B",
    "MG_CV_Jul15.pdf": "F4D65E40-6755-43F3-B849-B67DBA1A2E1E",
    "MGalgano CV 2022 - UNC.pdf": "C6216506-B558-40FF-9680-3072BE4E1D5A",
    "MGalgano CV 2023 - UNC.pdf": "0C9FF22D-B5BE-4967-B03B-22A369BB2774",
    "MGH residency cert-Sadegh.pdf": "5146F22C-6E82-4594-ABB6-1257F7BF8022",
    "mgmt of ant cerv pseud.pdf": "4F30D320-F42D-4B0C-8194-0BC4D04E9F1F",
    "mgmt principles.pdf": "065E11A7-43CA-49BE-AC1A-26BC4A0622AF",
    "MGupta.email.verification.pdf": "8CF9903B-2FF4-433E-B138-6774E6962580",
    "MH 2022 RUNN Application.pdf": "B62A164C-9022-461D-A54B-22D74A8A16AF",
    "MH CV 2022.pdf": "0F0EFF08-FED9-4C7E-BC3C-DDA87D08213C",
    "MH Pham CV.pdf": "D2F98F3A-C306-42FF-B751-9FBE932B2496",
    "mhd.pdf": "FDCBD4F5-7D98-4B28-8D36-5FB6EEA8D455",
    "MHG LOR 070821_Academy_Zipfel .pdf": "19994E6B-DC87-488F-9D10-6233B91199B4",
    "MHG LOR 102723_Lonser for SNS.pdf": "F60EA3BC-EBF3-4E31-A633-011817C50B49",
    "MHO_SR_digital_poster_v2.pdf": "93D73B8C-765B-490F-AB88-173A1AA8499C",
    "MI Cranial Base Course Agenda 12.3.19.pdf": "31594C54-BD14-4BB8-A36D-8D803DFF15D8",
    "Mi808.pdf": "6AFFF615-E618-4B2E-BC8C-36D773934C90",
    "Mi808_merged.pdf": "291971E6-1D81-4A63-9D40-13065D16B3E7",
    "Miao_Jingya_Erollment Verification.pdf": "5263FE23-833F-4FBA-A26B-7201303C337C",
    "Michael A Casey Curriculum Vitae February 2018 (14 Feb 2018).pdf": "45579799-2C09-43E7-9641-FF0FF54280EB",
    "Michael C Park CV 91321.pdf": "4BA2D98F-DF46-4110-9462-DCF84A2A4088",
    "Michael Catalino-RESverif.pdf": "30FC62EC-8E31-4EE0-90DF-D140B14E72AA",
    "Michael Chodakiewitz May 2021.pdf": "5AE61FED-E001-45E4-A386-87F5E4CFE8D8",
    "Michael Covell_Good Standing Letter_9-2-22.pdf": "15BDA963-77BC-426C-A35F-8756A3CD129D",
    "Michael Feldman-Fellow.pdf": "7AABBA02-44B1-4596-9783-6EDEBC4F57D3",
    "Michael Jensen-Fellowship.pdf": "33ACF093-9EB4-4A9C-99EB-13370AF7EB47",
    "Michael Jin letter.pdf": "C29C7D86-4127-4BEE-9754-4C4825989033",
    "Michael Jin_CV.pdf": "E127AF82-E448-4C3F-9534-CCE3149EC095",
    "Michael Jin_CV_FellowshipApplication.pdf": "872A82E2-6694-4647-97AA-179A1EA0676B",
    "Michael Keough-Fellowship.pdf": "D9CCFD55-4E0F-4FD2-8A6F-7CAD42232374",
    "Michael Kim CV.pdf": "CD905CD1-476E-4A8A-B036-2EC03197D050",
    "Michael Lang.pdf": "9FA11705-5CFF-4F41-BF65-92F54A429E23",
    "Michael Lim Nomination Letter.pdf": "3EA50AD2-93B0-4807-BC73-B9B8D378EC0F",
    "Michael Lim Recommendation Letter.pdf": "09A3318E-60D1-4F2E-A379-F6E3F52C6CF8",
    "Michael Lim Sponsor Letter.pdf": "752A0114-0538-4956-AA2B-2E30750B2976",
    "Michael Meggyesy-Fellowship.pdf": "71579816-C7BF-4FE8-9129-821204976503",
    "Michael Meyer-FellowVerif.pdf": "72A53E68-B969-4AEC-8377-3CD612111C1E",
    "Michael Meyer.pdf": "84C147EA-CA5A-4507-B778-AC0365BCC30E",
    "Michael Moran Letter of Good Standing.pdf": "49F9F687-B761-4F64-B148-99087C0AC871",
    "Michael Oh CV.pdf": "A1109EAC-3473-4E6F-AD94-51074525F8E9",
    "Michael Oh Nomination Letter.pdf": "38450A30-9EB3-46B8-8143-5FC1040F152F",
    "Michael Oh Recommendation Letter.pdf": "27A45A9F-3C0B-4741-803B-4C65893D1389",
    "Michael Oh Sponsor Letter.pdf": "50BE5B80-68A2-4DB6-A3CC-2C1DA6E9296E",
    "Michael Prim-RESverif.pdf": "95E6097B-1F5A-47D3-802E-FBDDF201079B",
    "Michael Prim.emailverification.pdf": "8E0F3610-55C6-47A0-9F34-1F6F9BE08C7E",
    "Michael Safaee CV.pdf": "C4563551-934E-4401-B0D2-5D4E50C37F82",
    "Michael Sawvel Curriculum Vitae.pages.pdf": "BB52769F-6B1F-4767-9F64-188A9B369F54",
    "Michael Sawvel, Curriculum Vitae.pdf": "8A4A67C4-09E5-4FD8-9BD9-016F1F0A6013",
    "Michael Strong-Fellowship.pdf": "9CAA5A9B-246B-4FD7-95C8-C46EF3AE2FDB",
    "Michael Taylor CV.pdf": "D9850A77-71BE-4356-BEDD-549EE1A3CD37",
    "Michael Taylor Nomination Letter.pdf": "1BBD18C8-84C0-4623-A130-50166ED79C52",
    "Michael Taylor Recommendation Letter.pdf": "1190CAFD-7E96-4CBC-ACDD-29A0DB4EEF56",
    "Michael Taylor Sponsor Letter.pdf": "F38BCF8E-89E3-4F80-8B0D-1708FB5D47BA",
    "Michael W. McDermott’s letter for support for the nomination by Dr. Edward Chang.pdf": "842015D7-C9FC-436B-AA39-648E9569538D",
    "Michael White.pdf": "6C99D2CF-F231-4DB6-A09D-385452B57C5C",
    "Michael Yong.verification email.pdf": "8C4A779D-744C-476E-8A0C-57528CFC1B68",
    "Michael_Amoo_C. vitae_19_05_24.pdf": "6BAAFA2F-8CFA-4710-92F5-90FFDB959B94",
    "MichaelJin_letterofsupport.pdf": "DD499CC1-D73F-42E8-BDBD-11396CBD9668",
    "MichaelWard_DGSOM_Enrollment_Verification_101321.pdf": "A8B34F38-470D-46F9-ABB1-B51D3DA48A0D",
    "Michel Michelot 2.PDF": "E0DC3DEA-963D-451E-B990-221E6C90A0BB",
    "Michel Sourour-FellowshipVerif.pdf": "E67E3750-7802-495E-A542-3DD4D623FC80",
    "Michelle De WittRESverif.pdf": "546B3E68-2F5F-407D-A104-3FD4D9087E12",
    "Michelle Fuller CV 2021.pdf": "5B1190E2-FDC0-4A19-9F55-1CBD5F80C768",
    "Michelle Kameda-Smith-FellowVerif.pdf": "589C553C-EB8E-4271-84AB-D4615A464B7F",
    "Michelle R. Connor's Transcript.pdf": "B8A401D5-D80A-4E90-B741-BA94FF8BB510",
    "Michigan.pdf": "72D7FA48-73C2-4AEE-ACCB-FDDA41ABAC7F",
    "Micro_adjus.pdf": "1E576E3A-1102-4944-81DF-4F956468080A",
    "Microclot poster.pdf": "617D5FDD-489C-40DE-830B-0983C96E3082",
    "Microdisc Time of Surg CNS 2019 Poster.pdf": "EF964ADA-BED3-4088-8A75-2C1ED491E341",
    "Microendoscopic Discectomy for Lumbar Disc Herniation-Surgical Technique and Outcome in 873 Consecutive Cases.pdf": "64A1D3BB-6974-4C54-9E5C-344766B7B48A",
    "Middleton_Andrew CV.pdf": "1C05EE8B-EFAE-4BD5-B292-F47581CE9D50",
    "Midha, Raj.pdf": "B850D5A5-D9F9-4316-9875-5FC60AD52187",
    "Midha, Rajiv.pdf": "8B72C1D7-750E-4995-8044-117D697AC9B2",
    "Miele CV 6-2016.pdf": "E9600B29-9FAA-4C27-9B67-EABA6F93F751",
    "miembro socneurochile.pdf": "E9BC6A0C-2E24-4310-ABDE-3DCC4155D475",
    "MIISA_REVTables.pdf": "7A3E785A-635C-4BD4-A6DF-BBE723043DB5",
    "Mika Niemelä LOR CNS.pdf": "61A9F14D-91A2-447D-91AE-89BA1C434A34",
    "mikell_biosketch_2022_2_pages.pdf": "A6506117-9F70-41DE-BB27-5FDDB0F15A53",
    "Mikhail DeSantos CNS.pdf": "F4FC0DCE-EAFE-4BB1-874D-070277042D38",
    "Miki Katzir CV.pdf": "315A074A-017B-445B-B6D7-3599476E2D2A",
    "Mikias_Negussie_CNS_poster.pdf": "8FC70335-7F8E-46D0-A083-575FD2191E3A",
    "MILAGROS TROCCOLI.pdf": "9BBF29EC-0D76-4CB2-841F-6A9279EE721D",
    "MilestonesFAQ.PDF": "924323F1-7F28-4E48-883F-94836E979425",
    "Miller Douglas.pdf": "E7BB10F5-2C42-49D5-90D6-14274A54B708",
    "Miller_James_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "2EB81352-6D32-4F59-B743-2EA6B110F18C",
    "Miller_Zachary 9.11.17.pdf": "789D1958-EA73-4D55-B534-28F0A321CBB8",
    "Milosavljevic-Elena%20re%20CNS%2006-16-16 copy.pdf": "FEC2F165-A95C-4AA5-ACC0-F47DC32659CB",
    "Min Jae Kim Enrollment Verification Letter 2023.pdf": "4B4262E2-2596-4663-A9B5-3F7F96F92D55",
    "Min Park Letter of Support from PD.pdf": "988BDE01-1254-46D3-A9C8-1E09532C1BBA",
    "Min residency diplomas.pdf": "F774AF3C-9F31-458D-9122-85F2F0C5B67E",
    "Mindfulness - M. Hadley.pdf": "6BDF9EFB-3764-49E8-A820-92FBDDA45B82",
    "Mindfulness_ASSFN2020_poster_final1.pdf": "D13FDF24-D233-4BF9-8ADB-F281375A4F78",
    "mini_CV.pdf": "A9AC8E8F-0E3A-45F9-9A31-1A8615C0CFF1",
    "Minimally invasive endoscopic intracerebral hemorrhage evacuation can safely be performed without repeat stability scans.pdf": "BB24C50E-80E0-4B46-AEBE-CA651CEDE74D",
    "Minimally Invasive Multilevel Percutaneous Correction and Fusion for Adult Lumbar Degenerative Scoliosis.pdf": "31391353-D896-4A9E-822B-647BF697BD97",
    "Minimally Invasive Operative video.pdf": "59F05515-ADCD-47D5-A3F6-9C8652E67138",
    "Minimizing pneumo for DBS.pdf": "5A66EBCA-105F-4DF8-85DE-23F89F62725D",
    "MINISTRY OF PUBLIC HEALTH OF CUBA.pdf": "34766D44-CE28-40C9-89F5-2F30B6787B82",
    "Minutes (ABM)_2003-03-07.pdf": "32EB8910-F93C-4571-850A-13E24FF5D652",
    "Minutes (ABM)_2005-03-11.pdf": "CE82982A-CFA1-4AE0-856E-576FC3794A05",
    "Minutes (SPC)_2003-03-05.pdf": "704E869E-C240-498C-9324-429FFBF2B953",
    "minutes 2010.pdf": "BB0C69AA-6E8D-42DB-A95A-D7C78762444F",
    "Minutes_1995-10-16.pdf": "524A23EE-D905-497C-AC8E-479FEEBDE65A",
    "Minutes_1996-02-28.pdf": "FF73E765-369F-454C-98EA-3893ADDB4D96",
    "Minutes_1996-04-30.pdf": "18369AFF-6663-40D9-8A64-7AACB1425523",
    "Minutes_1998-04-27.pdf": "43BC2FE5-1C3A-403C-BCE3-831505ED33CC",
    "Minutes_1999-10-01.pdf": "A33EA998-0C2B-4475-B7FB-486E27AE5DB1",
    "Minutes_2000-02-22.pdf": "533CBF77-87AE-410C-B379-CE32157E9E38",
    "Minutes_2000-04-10.pdf": "17EEA0FE-DEB4-4DC8-8473-41D194B0087C",
    "Minutes_2001-04-23.pdf": "50D5F16E-6A4D-4DF5-9685-E2C8736E6B77",
    "Minutes_2001-10-01.pdf": "91FFC78A-F3FE-4125-A64C-97E1CD069B0B",
    "Minutes_2002-02-27.pdf": "328C1B90-106F-4867-BB97-5A1655723E9A",
    "Minutes_2002-04-08.pdf": "AD895F82-0BA6-4361-80B7-F58D66985CC2",
    "Minutes_2002-09-23.pdf": "3A04668B-8306-4516-9655-5B37976739DA",
    "Minutes_2003-03-05.pdf": "0C489158-3038-4BE1-AD79-247AB8BEE795",
    "Minutes_2003-04-28.pdf": "17C1AC74-157B-4EC3-97F0-1B4FABE1550D",
    "Minutes_2003-10-20.pdf": "8653DFDE-CBDA-4713-9D97-B7A4C3ABCC95",
    "Minutes_2004-03-17.pdf": "973CD87D-07E4-4A40-9689-869CBDA65169",
    "Minutes_2004-05-03.pdf": "3B500E8C-B6B3-4C1F-8563-0766340BE131",
    "Minutes_2004-10-17.pdf": "6FCD2BA2-63FA-48EA-9C16-794C64ED7FFA",
    "Minutes_2005-03-08.pdf": "A39F68AB-0EEF-4C7D-BF2B-B2A530978C7B",
    "Minutes_2007-09-17.pdf": "9C5F8D32-43F0-4950-83B0-154A8837CFC2",
    "minutes_abm_2013.pdf": "9C885AF6-7152-4675-87D2-5E4A1953FF09",
    "MIPS Benchmarks Sign-on Letter to CMS.pdf": "AA331BD0-55AB-4EA3-8248-F91D39FFCEC4",
    "MIr Jamshaid LGS CNS 8-11-20.pdf": "5D37EEC0-2A02-4780-A975-ADF959FA899F",
    "MirAmaanAli_CNSMedStudentMembershipProofOfEnrollment.pdf": "12CCFE94-D91C-437E-B5B8-6019AB521D7C",
    "Miranda_2024 Emergencies_Communication Techniques.pdf": "F269341F-A6AB-4677-B29D-CC383320A4B3",
    "Miranda_Jorge.pdf": "A826F853-B8C0-4853-99F9-365D898AB4A8",
    "MirHojjat Khorasanizadeh-LOGS.pdf": "CF66EAAD-A2C6-4F7F-B770-A3C3FE269399",
    "Mirpuri Pranav cns.pdf": "48C37DC5-7265-4694-AF76-7F9DDB5D14A1",
    "Mishra.Akash.Letter of Good Standing.5.4.22.pdf": "2DAB3F00-36EE-493A-A3A9-168B5737055D",
    "Mishra_Anvita - Medical Student Verification.pdf": "A4B65980-DF53-4270-ABD0-8FCCA245279F",
    "MishraAkash_CV_05-2024.pdf": "6F7D92E0-2084-421F-827E-073BE0A1EB54",
    "Misra Arjit - Letter of Standing - 20240424.pdf": "15744DF2-B282-4BDE-A712-6CE6CC7842A0",
    "Misrepresentation_Poster.pdf": "5D4AAA2B-5A72-47FF-BCB9-C012FAD1C418",
    "Mitchell Macy - Letter of Good Academic Standing 04.28.24 bls1.pdf": "FDAA6E23-CD44-4123-897A-F0F8F72CE44D",
    "Mitgliedsurkunde DGNC.pdf": "958CB0DB-18C0-4648-A647-BF01BE63AE33",
    "Mitgliedsurkunde_RizkA.pdf": "9DF7B862-ED2C-49C5-917F-CCBEEA263F5E",
    "Mitgliedsurkunden_Koman.pdf": "B15F50DD-EB09-49CE-B385-D0582020BD62",
    "Mithani Verification.pdf": "D4557FA6-1FA4-4415-80D4-4E1FF77D0A42",
    "Miyagishima_Danielle_Good Standing Letter (1).pdf": "20E02723-E16E-44C6-9986-51B91ACB593B",
    "Mizuho CNS Letter.pdf": "0EB70484-2AE8-4878-8897-FEA53309996F",
    "Mizzou Academic Profile.PDF": "76C5358C-0686-4C29-A7AC-C946204B9F80",
    "MJ Avila-CV Dec 2021.pdf": "346FA971-CA39-4CB2-A67B-9D27A55266B8",
    "mjacksoncv 8-2021.pdf": "B7F677F0-2C25-47E7-8FF5-65DEBB8BC0E2",
    "MJanssen.RESverif.pdf": "9687FACE-E930-4A32-9129-3E3B5DF671C8",
    "MJayarao Residency Credit Letter.pdf": "89CA946C-0E9F-4F0C-9CCD-0C94282354E0",
    "MKandel.verification.email.pdf": "606E8930-0823-4573-BC9E-3CD94D59A48A",
    "MKhan.emailverification.pdf": "FA428935-4DF9-4A5D-B8F3-9FB7354819D0",
    "MKimmell LoGS.pdf": "58F2A029-CC17-4D2B-BAEA-4B6B8695E03B",
    "MKL LOR for MHG Academy NS.pdf": "C12E4EEE-0A26-43AE-A566-3D7B45F7B33B",
    "MKL LOR for MHG SNS.pdf": "83B835CE-2359-4CD8-A302-01555EB15564",
    "MKrel Verification.pdf": "FB9551A8-8696-4870-A389-1BB724C4FC85",
    "MKrel_ResidencyCertificate.pdf": "9A57C71F-DB27-4C21-A0C3-DEA87CC29014",
    "ML for ETV success poster - CNS 2023.pdf": "D9BADFB0-40B4-4626-AC4F-E03D6001839F",
    "ML_GattasCNS_2020_Figure.pdf": "E48C9782-541E-46D7-B464-707411D903B9",
    "MLGlio_2023_Tumor_Poster.pdf": "6BCFF8B8-3162-4E90-A275-5B02DBB95064",
    "MLoPresti.emailverification.pdf": "552291AC-4217-40AA-A97C-00ACAB69E256",
    "MLS_verification letter.pdf": "A17BF1B0-B3C8-4A59-9409-83BF7AE4CE35",
    "MM - FALOWSKI_CNS 2019 Poster_Evoke 3m Data.pdf": "FC77C0BF-BB7E-4BEB-ABE0-FF686CD846F0",
    "MM - FALOWSKI_CNS 2019 Poster_Neuromonitoring.pdf": "0358F20C-C3FA-4DC6-BC9B-520E3598A3C8",
    "MM letter of good standing.pdf": "5B16B6E9-0DAF-4C86-980D-4C66477345ED",
    "MM Table_CNS2021.pdf": "8CD77851-FF9C-4B93-BA16-64EB0D5C4D4A",
    "mm_0527_coveragepositioncriteria_cervical_fusion (2).pdf": "8D9A3E6D-3CEA-4E84-9662-3EFA6D18A204",
    "mm_chapter_1_intro_and_methods.pdf": "D04BFCB8-32CA-4D9C-A3C7-8BB53D960186",
    "mm_chapter_2_shunt-dependent_hydrocephalus.pdf": "3BC4BF14-F5F8-4CF4-99CA-213EBF85CC8E",
    "mm_chapter_3_pre_vs_postnatal_closure.pdf": "34F67DAB-0CD8-4567-A8AA-764E72EF5F16",
    "mm_chapter_4_closure_within_48_hours.pdf": "81634FA0-758C-4C59-A4DD-38F7642DB0BB",
    "mm_chapter_5_size_of_cerebral_ventricles.pdf": "33A74E57-608E-4975-BFA8-6EBA2CF436A6",
    "mm_chapter_6_incidence_of_tcs.pdf": "4B7DF60E-DB4D-4B8E-8787-3263C6E06881",
    "MMA Embolization Poster.pdf": "01C28939-B778-4CD1-9787-25307B582818",
    "mma patency.pdf": "85984EAB-2434-438A-A441-0539F841BBFE",
    "MMA table.pdf": "18A82043-0AF2-4C14-B7E2-612A27C014AF",
    "mmc_poster_2022peds.pdf": "E3E7E5E1-75FE-488E-816F-ADCC28E33300",
    "MMcPheeters-CV.pdf": "793E522D-7CF8-435E-A298-2684766B5F34",
    "MMD Tables.pdf": "20F0A166-0296-4642-BC45-35839D604C31",
    "mmg_digital_poster.pdf": "8214333E-6019-4485-B923-5B9548A9D6BE",
    "MMIshak-RESverif.pdf": "B47476FC-19A2-4231-86E9-C4EE90AD4514",
    "MMohsinShahMD_CV.pdf": "40E72A02-0860-4652-9B7E-777E3CF9A593",
    "Mnatsakanyan LGS .pdf": "FCE5E787-FA6B-489D-B167-2AEEE89ED5E2",
    "Mo resume_2021.pdf": "EB847A5C-2F9D-41E6-B34D-CD6694B0D63B",
    "Moatasem Azzam - Enrollment verification MD -2.pdf": "167B9FE9-B922-46C0-922E-74567399A55B",
    "Moatasem Azzam - Enrollment verification MD .pdf": "BF59C28C-2890-4921-895C-E6FA514C1E93",
    "Moath Transcript.pdf": "CEDC2D3D-81F9-4475-8084-19092FFDC780",
    "Moawad_C.pdf": "0B475675-57DB-419C-80A8-12CF0C6C67F3",
    "moc_letter_to_cms_global_payments_release.pdf": "9C781011-0D61-4806-B786-3AB1B3665689",
    "MoclairB Northwell Resume 11.2022 PDF.pdf": "C0A2BBBE-9318-47DC-8CE9-C01E672EFC0D",
    "model_bladder_mgmt.pdf": "BDBBA9A8-E270-4D5D-8743-4858367266CD",
    "Moderate TBI Poster.pdf": "492992B7-4C6C-4E5F-AA00-CEDEB8E8B6E6",
    "modern_healthcare_letter_to_the_editor_-_final.pdf": "A664A360-42DE-4E7E-8C93-C5349B6C736D",
    "Modhi Alhussenan CV 12th Feb 2024.pdf": "F34E3FD8-5AF8-4DC9-97DB-48BCAFF53AAF",
    "Modified Iliac Screw Figure 1.pdf": "044ACB2D-2929-4E55-93C3-99156A123C94",
    "Modified Iliac Screw Figure 2.pdf": "BF0C1109-36F8-42EE-8092-E409B24F98FD",
    "Modified Iliac Screw Figure 3.pdf": "82819032-C70D-47DE-82DC-DE6C1DC3ACAC",
    "Mohamad Assker-Good Standing letter.pdf": "FB13FBCE-8017-42A4-A932-C8FDE4D147E0",
    "Mohamad Bakhaidar-2ndFellowship.pdf": "DD3CE8E2-4622-4ABA-81DD-FD5B45B18904",
    "Mohamad Bakhaidar-FellowVERI.pdf": "C35C5D6F-185A-4E0E-A642-4A08F2709683",
    "Mohamed Badran-RES. _Fellow.Verification.pdf": "CE1A6E40-ECF1-4682-8633-C5BA5DDA2AEE",
    "MOHAMED FATHI C.V..pdf": "61DB850F-848A-4CF7-961F-5B3FB7F4A5B3",
    "Mohamed NEUROSURGERY Publications Fellowship Nominatinon Letter.pdf": "F5CA0B84-A5D5-4059-8781-CAC2F20E6C02",
    "Mohamed Soliman CV (March 2020) official.pdf": "D676A11F-B9EF-4358-BF0D-95727FF33364",
    "Mohammad Ali Aziz-Sultan Recommendation Letter 2.pdf": "A4CDF8A7-1AE3-46B0-8665-A4E055389D10",
    "Mohammad Ali Aziz-Sultan Sponsor Letter.pdf": "2474009B-6F94-4E82-AC49-9904EC8C71E0",
    "Mohammad Elbaroody.pdf": "9A225C40-04CC-45C1-B233-19B4B5AE7859",
    "MOHAMMAD_AMRO_POSTER_1188.pdf": "C63286B8-13A5-4C07-9F9D-687765AC4B32",
    "MOHAMMAD_AMRO_poster_867.pdf": "EE6729C8-5F72-430B-BEE7-0D243F1DA966",
    "Mohammed Abdul Rahman CV.pdf": "39717404-072B-4963-8D0A-63B98523C669",
    "Mohammed Alshareef-FellowVerif.pdf": "05BB761E-385C-44EF-93CC-29A9C11508D2",
    "Mohammed Hasen-FellowVerif.pdf": "C8DA093D-B2C6-46A7-AACF-551E14273151",
    "Mohis.Momin_GoodStandingLetter.pdf": "45200B0F-CA24-44EB-92AD-7E60EA2532CA",
    "Mohit Patel-Fellowship.pdf": "3B2FFCE3-FEB3-4915-91AE-E0218B087EDE",
    "Mohiuddin Ismail_ver.pdf": "525C165E-8755-4DB3-BDEA-17A94F621D7F",
    "Mohsens_CV_CNS.pdf": "C8D61717-A84E-4C29-BFD4-CA62FDC0194E",
    "Moleculat Targeted Therapies for Adult-Type Diffuse Glioma.pdf": "66880674-9684-4FFA-BFA3-0E7845C120C4",
    "Molina_CV_WashU  12-18-2022.pdf": "E646F08B-2470-44F7-812A-6DEAA39EB65E",
    "Moliterno Gunel Jennifer_CV_102022.pdf": "D0813949-93EE-48EA-AE37-1FF8CDCBDB96",
    "Momodou G. Bah_rTMS Poster.pdf": "B4CDE3F8-B6A7-4298-B12B-861D676576B1",
    "Momodou G. Bah_TBI Poster.pdf": "5A6C947C-F5A7-4D96-ABE9-B203367AFCC1",
    "Monda, Steven_8-20-15.pdf": "B7572B1D-CED7-4B8C-B7E7-6F509B694712",
    "Monday, December 16, 2013.pdf": "83EA4629-5EC1-45B2-A5E8-8F7656D54C7A",
    "Mondragon-Soto.pdf": "7809F748-090B-43B1-A88E-9A4EEE6D29DA",
    "Moniakis Alexandros CV.pdf": "FA57EC1F-2E89-4C1F-93A6-EAC418FCC161",
    "Monica Melgar's School Enrollment Verification Letter.pdf": "1B3927B3-AD0F-4DB7-A07A-1A3813F46A8A",
    "MonroeC_2019.pdf": "CEE75A8B-3F95-4EE7-90D2-B7AB55AF899C",
    "Monsour Molly -Letter for CNS Registration.pdf": "ED518319-695C-4702-8E9B-327F90C9903B",
    "Montefiore Medical Center.pdf": "896021BB-8745-4D4B-911A-DD606E620FF2",
    "Montgomery Deondra - Letter of Good Academic Standing 02.22.24 bls.pdf": "389E24DB-CFE1-4866-AA3E-C7CF1CE72975",
    "Montserrat Lara_CNS certification.pdf": "5D7B6B28-7BE0-4544-9281-10AF04C91F17",
    "Moon 2022 CV.pdf": "CA9A4545-17A1-427E-9054-38039FB21EEC",
    "Mor_LOR_CNS_CSNS fellowship.pdf": "CF557045-78BA-4D91-BDFA-049389EE8289",
    "Mor_Reference 1.pdf": "A6D94EB4-AF13-482D-BEB6-3ECA062C9C38",
    "Mor_Reference 2.pdf": "999BB907-77B7-4AB3-AB6C-FE9A17B64E05",
    "Mor_Verification.pdf": "900F6B3E-72A6-4F92-828B-18C7F8326970",
    "MORADEYO  ABDULRAHMON AKANMU AdmissionLetter.pdf": "F8B3F260-3AD1-43CA-9FD8-57C39C034D51",
    "Morawetz. Richard.PDF": "9D85AD7E-BE4E-4E4A-B598-C5C413B595CE",
    "Morcos, Jacques J.pdf": "DAE67AD7-CD87-4D93-8D74-4542E60C1C7A",
    "MORDEN_Frances Tiffany_Letter of Good Standing.pdf": "B582CB9B-C587-41D1-8CFA-1474AE42E5BF",
    "Morelle Shalala Roe Taylor Surprise Billing Dear Colleague with Sigs. 12.11.19.pdf": "D2C03AFD-B405-480A-BD05-EC7188BA8DD0",
    "Moreno_verification.pdf": "7E96504B-3AAF-4A92-AD60-6F72C95EF933",
    "Morgan, Michael K.pdf": "5415E522-2475-4FE9-9305-09AD51EDACE9",
    "Morgan, Michael.pdf": "662EFBED-572E-4E9A-8750-EF0C8097C224",
    "morgan.pdf": "90D422CD-21BB-4D6D-B2AC-AB559037634F",
    "Morphological Predictors of Recurrence in Endovascularly Treated Anterior Communicating Artery Aneurysms.pdf": "3EB42443-DA4D-4011-A64E-22467FAF59C9",
    "Morphological Study of SyNC Atheromas_Poster.pdf": "79DE92B7-6206-4993-BC00-88818086A24D",
    "Morphometric_review_GraphicalAbstract8x11.pdf": "3826CED1-F36A-41B0-B3D2-2B27A641C3E7",
    "Morrill HM552 FS22 Wajahath M 1.pdf": "181CFCF7-399A-445D-971C-C2F9BBCD6AAB",
    "Mortality transfer table.pdf": "7DC92F17-5281-483C-9963-B221E40B2879",
    "Moses_CNS_2024.pdf": "7C902687-6B12-48CF-A4F2-3DFE512E6F24",
    "Mosiman VOE.pdf": "7D8042DF-79A9-447E-94EE-752E55F783FB",
    "motivation letter.pdf": "B1C035C1-B41D-4CAE-8988-D553A6B489B0",
    "Mouchtouris_CV 2024.pdf": "93F7E9C9-8D6F-41C0-96BE-4F32D2F4A923",
    "Mousa Hamad-Fellowship.pdf": "8DEB155A-345F-4F18-9816-26C7E112139C",
    "Moustafa Mansour (April,2019..).pdf": "4D61435F-6FD8-4F45-9731-A62E3CE95DF8",
    "Moustafa Mansour (Letter of recommendation for research felloship)-signed.pdf": "7FE33264-7B29-43DF-B836-1FBCAD2D6404",
    "Moyamoya Figure 1.pdf": "D34922D3-B3E2-48CD-9CA8-75AEEFCA870F",
    "Moyamoya Figure 2.pdf": "5A17D46D-6477-4A66-931A-129DB2D04CA0",
    "Moyamoya Figure 3 AHA .pdf": "9316C52B-C414-43F9-8D41-3D5E7E972CC2",
    "Moyamoya Figure 5.pdf": "0FF94E58-AAC4-45DE-92F3-5EA03C85DD53",
    "moyamoya poster final.pdf": "C581BF8A-B0F7-4FF2-BDD7-8650EF1E3A81",
    "Moyamoya.pdf": "F101358D-74E3-4ABC-9A2D-20908009807D",
    "Mozaffari dean's letter .pdf": "4846870F-E5D7-4F8B-829C-FEB31BAD1F52",
    "Mozaffari, poster.pdf": "E420BAAA-DAFA-4EE0-8BD8-43122F43C0D2",
    "Mozaffari, Seizure outcomes in Children with SWS, CNS 2022.pdf": "A94CAB25-7ECF-4A89-9788-3B515133176F",
    "MP - Table 1 mod.pdf": "AD8D9F87-5D9B-4329-B405-8D8120DE4490",
    "MP_CNS_LOR.pdf": "49F91183-FC71-43B0-AEBD-0F2CB069F9E9",
    "MParker_CB Biosketch.pdf": "60551B39-D468-42C0-8A43-C8AF90071E9F",
    "MParker_CJ Letter of Support.pdf": "A539A4D9-6B53-4BBE-A4B9-7CFFBF1399CC",
    "MParker_CV.pdf": "A3F49839-4FAE-4639-82E1-362B67A2F715",
    "MParker_JRT Recommendation Letter.pdf": "699D684A-AA77-44BC-B1E0-DE28035F1DBB",
    "MPease.emailverification.pdf": "6555C821-57EC-4E89-86D1-0770C6B2299D",
    "MPFS CF Coalition Letter July 27 FINAL.pdf": "C3821F1B-A167-48F1-88AD-93A3BC5B20C3",
    "MPFS CY2021 Letter to Leadership updated.pdf": "33B8381C-7018-4F79-B8EE-BA6B5DE95BAA",
    "MPiazza NF1 GR poster.pdf": "3F29D13B-9EA5-436A-A823-E8589E4402CD",
    "MPiazza Publications Resident Fellowship CV.pdf": "9913AA45-EE46-4741-9275-6A74D5F812DD",
    "MPiazza Publications Resident Fellowship Letter - Hadar.pdf": "D453D2F5-B8CC-4F6A-A4EB-8A2D64A0A86A",
    "MPiazza Publications Resident Fellowship Letter - Quinsey.pdf": "EEFE3274-1A2B-46E5-B3A9-122D8C48D3C9",
    "MPiazza Publications Resident Fellowship PS .pdf": "612DBFE8-677B-426D-A6CA-0379BFDA6AB3",
    "MPomponioCNS.pdf": "19BF9B7E-D8F3-41EB-90A9-04E43CF48B4C",
    "Mr Dabbous 8130270.pdf": "13FB1487-45E6-4C89-A8C4-3D3252FD9350",
    "Mr Martin letter for CNS.pdf": "6A9B61D3-EAC1-4650-B669-1B6F02F84CED",
    "Mr Watkins letter confirming enrolled.pdf": "CD76C99B-E4A3-41BB-B0FD-918073D772BA",
    "Mr Watkins' letter confirming enrolled.pdf": "928D0B0D-5A88-4D25-8D84-1E06460C3A8A",
    "MRI photos of my Brain Tumor.pdf": "BCF4EEA1-2504-4541-AAFC-C871C56D3009",
    "MRI SDH figures and tables_revised (1).pdf": "A6D06627-0342-41CB-97AD-2E7BC353748C",
    "MRI.pdf": "656BA108-6E67-4D6E-8B5D-E28A8A2021B0",
    "MRobinson.emailverification.pdf": "0A3CD4EB-199F-4DBB-917C-975B56463E76",
    "Mrowczynski_Student Letter of good standing_Harbaugh_2017.pdf": "7FD0480B-57AB-4F02-B848-DC75CCEC45F9",
    "MS-CNS letter.pdf": "E8106323-C254-4DB5-982E-5DF0141238B9",
    "MS-Form.pdf Amercia Psyhtratist Assocation.pdf": "405968B2-C330-4FF6-804F-F37D906BD772",
    "ms_CNS.pdf": "3D7F7EE6-7AD7-409B-A1D1-6F5FEBE133CE",
    "MSalman Ali.emailverification.pdf": "D98D7DCF-F28C-49AF-BF5A-9BD6F57099C3",
    "MSanchez Calderón.emailverification.pdf": "6340DD2A-99B9-464E-A435-C506763D6345",
    "MSC_CSF_Poster_CNS2021_final.pdf": "636426A2-76D3-45DA-ABF8-B4EF58D5324C",
    "MSourour-emailverification.pdf": "E819DFF4-4F76-4B52-8130-3A5E2D74FB95",
    "MSPE_SiYYu.pdf": "26876ED6-4ECF-4C67-A919-42D00F057D08",
    "mStephens.emailverification.pdf": "1FB886E1-7C48-4107-8E02-C6D3AC0C3F96",
    "MSTP support letter_OCT 2019 FINAL.pdf": "C6DA7CA3-06E4-4BE3-BA56-3D6BB46CB72C",
    "MSTP Verification - JGILL-.pdf": "73422B51-06BA-4EF8-B251-D8E85928E081",
    "MStrong_CNS_CV.pdf": "B90CC524-AF02-414D-9DDF-16F91C694AE3",
    "MSU_VerificationLetter.pdf": "1A67B2EE-6329-40E1-8CE3-4B21AA1311C1",
    "MSUCOM Enrollment Verification.pdf": "1BB7A663-459E-4077-B0FE-77B145DACFA3",
    "mtbi2008.pdf": "B2F86EFD-5706-48FA-B578-BBD7F6FA9137",
    "MTVO Poster.pdf": "20BDCD63-16D8-4466-8DC4-C1E615F556AB",
    "MUA~_UZURI CAMACHO MARCO ANTONIO.pdf": "6A055EB8-8813-410C-BB64-8BA1EE450F95",
    "Mubita.pdf": "DF88B1C4-976F-4A6D-BD8C-E3471FA2C2E5",
    "Muehlschlegel_CNS Emerg Course 2024.pdf": "F121C000-E5C8-4311-BAAA-7CD020EA6ACB",
    "Muftuoglu_FWLNSchol_2021.pdf": "6D61EBB6-7547-484C-947D-559749176587",
    "Muhammad Aadil Qamar Letter.pdf": "D4481EBC-749E-43AE-8748-FEC15C0EB029",
    "Muhammad Akbar-Fellowship.pdf": "F0E64674-CB21-4EE9-B145-F5613259E81D",
    "Muhammad Ibrahim Jalals  Verification of Enrollment and Good Standing.pdf": "8ACA78CF-191C-4C19-B327-59890BA74D5C",
    "Muhammad M Ali.pdf": "B2C69845-9FD3-460F-9135-70D755DEC4BA",
    "Muili  Abdulbasit Opeyemi AdmissionLetter.pdf": "8BC3A1CC-5B2B-42C8-8EC7-1362A31917E2",
    "Mukherjee Biosketch 2022.pdf": "DC072C5C-0E9B-4B7B-BEF1-A5F95005E961",
    "Mukherjee CNS socioeconomic fellowship.pdf": "82B1ACEF-CE35-45F4-B6F9-E3511D01D9E1",
    "Multi-Society Aetna Letter ACDF 4-25-2024.pdf": "33429414-1058-4824-9C15-C8A180D75B31",
    "Multi-SocietyLettertoWashingtonStatereCervicalSpineFusion21413FINAL.pdf": "EC27C5AD-3016-4012-9563-2A742DDD46F8",
    "Multi-specailty Letter to CMS for NCCI PTP Edits for CPT codes 63052 63053 072523.pdf": "5ADC435E-4016-447F-BD50-948803E18A9A",
    "multi-specialty_society_reponse_to_wa_hca_hta_draft_key_questions_for_lumbar_radiculopathy_112717_final.pdf": "2132D833-BAF0-450F-AC2D-5436A5390E13",
    "multi-specialty_spine_society_letter_to_cigna_on_medical_coverage_policy_0527_for_cervical_fusion_052418.pdf": "B8FF8834-14C8-41FC-9A7A-57077B7B87EB",
    "multi_society_letter_wshca_-_sij_fusion_-_april_2018.pdf": "3B3DAFA5-7750-4C60-92E3-6A5B55BE1B98",
    "Multicenter_5ALA_JNS_Final.pdf": "44CC42D4-4D28-4EA6-89AE-5F56095B2200",
    "Multilevel ACDF subsidence - FIG 1.pdf": "DD67A74D-717D-48AE-A882-0536C55E3C62",
    "Multilevel ACDF subsidence - FIG 2.pdf": "FACA5BE9-020D-40B0-8F1E-0E9344DE699E",
    "Multilevel ACDF subsidence - FIG 3.pdf": "BB10E3E1-D9B5-4D2D-90BB-3AAF952D1818",
    "Multiple metastases image1.pdf": "5EC489B0-52E4-4FA1-ABFA-B943B4F8B1A8",
    "multisociety_comment_wa_hca_spinal_injection_questions_9-15-2015.pdf": "264B559B-088A-4D38-9C07-93CA46C2C601",
    "multistakeholder_value-based_letter_.pdf": "69D7FFF4-4631-4A9E-A886-6431160F9DDA",
    "Multivariate Analysis.pdf": "B6103F52-1EE6-4E60-BC0C-A88E7172F072",
    "Muluberhan  D verification.pdf": "22CA10B2-02F6-4CBD-BB09-663BB902E687",
    "Mulvaney_CV.pdf": "DDE90D6E-5F21-49DE-AE15-4775B0F105CB",
    "Mulvaney_Letter of Recommendation.pdf": "E3634C9D-3F80-4E63-99D4-CB4B176B1874",
    "Mummaneni.pdf": "2C6F2FAD-BF26-4F2B-9263-7CC4DE4E7149",
    "Mummaneni_LOR_2020_signed.pdf": "A4BFCE5C-CFD3-4E30-BEA8-CF564744FE74",
    "Mummareddy_ Nishit Good Standing and Proof of Insurance (1).pdf": "5A738591-74F2-49C2-A78F-97404EDB50B1",
    "Munjal.Vikas.LOGS.M1.pdf": "0AD22538-F2D1-4B60-B4C6-98F8410502A3",
    "Muntadher Hayder Almufadhal.pdf": "5A28C001-5F61-45C3-897B-5364E4C2A6CA",
    "Murad CNS Publications Fellow.pdf": "3BE6025F-889B-4985-86CD-0FA06D8159CD",
    "Murad Megan Still Guidelines Fellow 2023.pdf": "0DFFBFC1-F0AC-4F52-B4E1-97EAD709C946",
    "Murasko, Karin.PDF": "E6F15B5E-B586-4DEF-8BEF-D907D63A0D3D",
    "Murata Michelle - Certification of Attendance 8-16-23.pdf": "ED95C7FF-4DFA-4836-AE9B-2E34E3DAEE2B",
    "Murayi-CV.pdf": "EF141CDD-4051-4176-9DE7-7981726EE1AC",
    "Murphy_FWLNSchol_2021.pdf": "9540B6D0-364D-4943-8113-13ED5EBCD0AF",
    "MUSC acceptance letter.pdf": "C99CD7A9-0F15-4A0F-A421-89B9B552FBB6",
    "MusgraveN LoGS.pdf": "38EF2E9B-487B-4C4E-B51D-51042CC87EF8",
    "Mustapha.pdf": "7F562EAC-9AF8-4817-8759-99363550044E",
    "muthinja cv-1.pdf": "484674A7-9EBE-45FF-BF04-7DEFF65BFBA2",
    "Muzyka Logan_Letter of Good Standing.pdf": "D199BAE7-ED97-436C-A58A-FF6ADA688A00",
    "MV Cost Analysis CNS.pdf": "6F8B7E17-F126-4E38-8037-1C6BE2397F5A",
    "MVD_TGN_TABLE_UPDATED.pdf": "FA947C65-B5B9-4C21-A04C-BF0667E1EC10",
    "MVessell.emailverification.pdf": "1554B0B7-6014-4FE1-90E2-7AF6A5D29CCF",
    "MVoisin.emailverification.pdf": "C5FCE32B-B929-4E85-B6DE-1DA601803132",
    "MVP Sign-on Letter to CMS (Final).pdf": "67BDDD3E-9412-4F54-A17D-C9D2F1429A08",
    "Mwangi_Ken_CV_2023.pdf": "2024A8BB-0A48-4D12-BABF-1DBCC80498C6",
    "MX-4100N_20150518_183535.pdf": "6269A815-FFEC-4609-B473-E7F9235AD24A",
    "MX-M3158N_20230313_175340.pdf": "FA235FF2-CD8D-4301-A642-BF73BBF5C67F",
    "My C.V.pdf": "F8407415-0240-42BF-97CC-BBA772EF58C7",
    "My Class Schedule.pdf": "8D8CC1A9-19C0-4508-8E1F-117269629598",
    "My CV 2018 pub.pdf": "680E8065-49CF-4B2F-8986-5BE8F94E826B",
    "My Cv new version.pdf": "49933201-CB95-40D5-9FDE-283B79FE428A",
    "my cv.pdf": "45B92073-BE11-481D-BDBC-74A8A679924C",
    "My medical license.pdf": "21B6ECC4-14A6-4694-B1F1-9EA10D62BC9D",
    "My resume(send to Gaohen).pdf": "D517B016-BB25-439B-87E2-C4931505358C",
    "my SANS card.pdf": "547507C2-C09C-43F4-AC21-9BC378005F29",
    "My Updatd CurrentCV- copy.pdf": "C5D2FCED-71EE-4F24-A060-0A359E638DC9",
    "My.CV.pdf FINAL.pdf": "94D264EE-10C7-4C52-BE2D-738E0CC9679F",
    "My_CV.pdf": "091EE1CE-6E72-4BA7-B35C-DA7EF4771C47",
    "MYang.RESVerf..pdf": "8ACA5D98-2322-438F-86A9-0FBC5F5B0958",
    "MZ Support Letter_HOUSE.pdf": "11901765-BE9D-4D7E-8128-22B6055C0B5C",
    "MZ Support Letter_SENATE.pdf": "A01B77E9-F747-4453-A59D-B1831CC9FC4C",
    "MZanaty.emailverification.pdf": "BB1CFD13-9F81-4E9B-906E-147FF849C5F0",
    "N Moore Good Standing 7.5.2023.pdf": "6E0CAA27-8828-483A-B8F8-2036BCDF89C6",
    "N-O Figure 1.pdf": "59D015CF-192A-458C-BC28-532CEBB6DE19",
    "N. Majmundar.pdf": "379126C1-79CF-45BB-95AC-DF7AD9E7554F",
    "N. Ramu Letter.pdf": "5D9DEB8C-8F04-4ACC-8F9F-3F61C425CC70",
    "n.paidakakos.pdf": "23E8AE40-82E0-4975-84E0-59406996D41A",
    "NA-poster (ID 143).pdf": "F60ED816-3C89-4C76-B67A-048FDFFD915B",
    "Nabiha Quadri CV.pdf": "CFE2DAC8-1226-4215-B104-41CBA9291380",
    "Nader Delavari-FellowshipVerif.pdf": "94041D30-736D-4991-A0B1-F7D7EDA43BAB",
    "Nader support letter MH.pdf": "922390D5-9D5A-4D89-857A-6C4734807778",
    "NAgarwal.emailverification.pdf": "6DA1E4F2-F0DA-452A-9BCD-8B99BF11D68C",
    "NAGM prof Ellabbad_Letter_of_Recommendation SN.pdf": "ECE21DF2-914B-4AC4-BA2B-5C00A374E3BA",
    "Nahed SNS nomination_AK.pdf": "D50764CD-EBA3-4D36-A609-6B9E9F67A757",
    "Nahed_ SNS Nominating letter 9.30.21.pdf": "29B5098F-A664-4ECE-9F81-B2E6CFD8ABEF",
    "naic_stakeholder_letter-_network_adequacy.pdf": "812861A2-4160-4AF5-B164-FC30F4A73708",
    "naicstakeholderletter_.pdf": "935CE2EE-AFAA-4BF7-9BC8-D1B568FC0B22",
    "Naidich_Subramaniam_CNS Rec 2022.pdf": "905CBC85-A2A8-4938-9EF8-AC2661EC9252",
    "Naik Anant - Letter of Standing - 20210809.pdf": "7B3378C3-9CF3-431F-9B7E-7C3D7821C807",
    "Nair Letter of Rec.pdf": "5B88AD10-4C09-415E-A628-F06097BDECB8",
    "Nair S CNS 2003.pdf": "2EB80680-E510-4E89-91AD-BA5F9EE3FA27",
    "NairLOR.pdf": "8DBD147B-EB60-40AF-94C5-D797C9EB36E2",
    "Najib El Tecle-CV.pdf": "CDDF9A85-45EC-44DA-AA8B-5752D9E69316",
    "Najib El Tecle-RES-verification.pdf": "86B936DA-317F-4FC5-AC61-F57FD3AEF30D",
    "Najib El Tecle.pdf": "7424760E-D357-4AE0-BBCC-4E3F548559C5",
    "NAlan.emailverification.pdf": "FEAC4D1F-5ACA-4504-8600-80211320F99F",
    "Namburar, Sathvik - Letter of Good Standing.pdf": "46D428ED-CF7C-4CEF-A181-00F747EF5F80",
    "Namratha_Dean verification certificate.pdf": "DE76C9FF-28C5-415D-BF98-7D9AEB2C2530",
    "Nanayakkara CV.pdf": "2B028B60-7017-4ACC-976E-AB150539F32C",
    "Nancy Mize Gonzalez LOGS rev.pdf": "F4FAA18B-1F61-4709-A843-BF26EFEF4B77",
    "Nancy Mize Gonzalez LOGS.pdf": "98236227-3A1C-40F1-94F6-0CA8DEDF9CF4",
    "Nanda, Anil.pdf": "298201AB-D43F-496A-BC0D-0A472A9A1AAF",
    "Nanda_Ltr.pdf": "3A7740A9-59E1-4AF8-A9A1-76AA38735CCE",
    "Nandoliya_Khizar_GSLetter.pdf": "C402F820-38BC-4312-A872-83B922339ACA",
    "Nangorgo Oumar Coulibaly Curriculum Vitae.pdf": "A27B5DB8-E20C-42E1-A9DA-D3886DF406DF",
    "Nangunoori.pdf": "BCC83D9B-71DA-42E9-B741-B88A40647473",
    "Nanney CV Jan 2020.pdf": "2512AE20-F917-4F12-8636-8EC0563A62D6",
    "Nanthiya Sujijantarat-Fellowship.pdf": "686DD79A-1D72-4996-8A89-E77864DAC0BC",
    "NaomiOchiengCV.pdf": "BD3C1027-294B-487F-A087-9525C232CC26",
    "Naoya Suzuki certificate.pdf": "642EBD03-E9BF-42F1-AF08-0C10ABCB2518",
    "Narasimhan_ASSFN2020.pdf": "3E468284-BB36-4A63-9FC1-8944061925EC",
    "Naratadam Verification.pdf": "EAB9DE5C-1AE2-4B72-8851-DE646C460C20",
    "Narvacan.pdf": "EB362338-0D01-4BBC-BDCD-A4596D20B98C",
    "NASCCNS.pdf": "CF3B7713-2552-4247-8C21-56BB3FFD53F2",
    "Nasir-Moin Letter of Good Standing.pdf": "05328AB4-515B-4A7B-BEF4-FA393E2E5D40",
    "NASS Redefining.pdf": "A9EA8A46-2FA4-4A0B-8D56-FD86C3F24F3B",
    "NASS Spine summit minutes 2012 vol. I_2012-08-27.pdf": "0A3F705D-62BF-497A-A7A2-2131106DEB5F",
    "NASS Spine summit minutes 2012 vol. II_2012-08-27.pdf": "07C29507-D909-46BE-9EF3-55216E8DF5D9",
    "NASS Spine summit minutes 2012 vol. III_2012-08-27.pdf": "08A3D1BF-0769-4BFE-B405-C423B7CD4350",
    "NASS.pdf": "41D08C43-0C03-430D-9DA8-5B7FA27600AC",
    "Nasser K. Yaghi-FellowVerif.pdf": "E9A756EC-F5D0-4BD0-8EC9-B7DA1DE79DAD",
    "NAT_Preg_Poster_Updated.pdf": "A1B6DFCE-442A-446D-8A23-9A366C2D8FB9",
    "Natalie Limoges DO CV 2023.pdf": "00FBF94E-7AB3-4131-8E49-72978EF7DEC4",
    "Natalie Limoges-RES.verif.pdf": "646430A7-B4EF-4CA7-9068-2FDE3D587C31",
    "Nataly Raviv-RESVerif.pdf": "92DFF8CF-C385-4469-99DE-7421DF743219",
    "Natarajan Sabareesh - CV.pdf": "81F0F3BB-E50F-4666-8105-95B5CB9442D8",
    "Natarajan.pdf": "EE6273B2-5C20-4808-9716-9A8023850615",
    "Natasha L Frontera Jimenez-RESverif.pdf": "6580B754-EA81-461E-9D0E-4FE1F04766CD",
    "Nathan Han-Fellowship.pdf": "5A401C6A-31AE-4F96-A190-89436611ACBE",
    "Nathan Selden Letter of Support for Ciacci SNS Membership.pdf": "5B0DBBF7-0545-471C-A911-6947B3C5133F",
    "Nathan Shlobin-RESverification.pdf": "DBE05B85-C6A7-424D-B7EF-A38E10B12609",
    "Nathan_Pertsch Letter.pdf": "986EF8FF-0FED-4273-A44F-F9EA991C818F",
    "NathanNairCV.pdf": "D5178AFD-6F7C-4382-98F5-411DC5E9FD53",
    "NatkhaVitaliyVer.pdf": "DD7BF40D-3BD9-4776-8786-E2241390BF0A",
    "natural hist cerv spond myel.pdf": "6FDCDA6F-3E11-4B00-88F0-967C58CFFD6C",
    "Natural History of Vertebrobasilar Dolichoectasia - multinational study .pdf": "944F91DB-77B9-497D-9B1E-8062CA803924",
    "Navigation Clipping Brain Aneurysms.pdf": "9E3D31EB-0CC6-4F47-B76D-AF34520E30DA",
    "Navy 2019 Orders.pdf": "6B000296-4375-4FEE-9FDC-5268119E0693",
    "Nazzaro LOR for Tuchek 2021 CNS Resident Committee 8.19.2020.pdf": "A5339B9D-63B3-4B28-AB46-8FD21640F800",
    "NBME Clinical Mastery Percentile Rank Conversion for Internal Medicine 18.19.pdf": "38C4B7C2-57CF-4081-86A3-F9B03EECFB77",
    "NBO CV 12-2022.pdf": "2AE08224-F5D2-4BC1-862D-38383120B866",
    "NBPP Predictors Poster.pdf": "55A06DAB-3320-4FF1-9166-2F11E7461A46",
    "NCB stippler letter.pdf": "E50ED4F4-046D-4B42-A144-DDEF705F4FF9",
    "NCC Epilepsy Prevention Poster.pdf": "A1C36AEC-FF09-4718-8594-9E8B426A6F40",
    "NCF CV.pdf": "13EDF592-164A-45A0-98E1-263AC02B56D7",
    "NCHSR FY 2020 Conference Letter House.pdf": "4333474B-CE33-40E1-B0A0-F8EE16B86148",
    "NCHSR FY 2020 Conference Letter_Senate.pdf": "2E0BF55E-1E66-43B1-99E0-0BC9D7A60383",
    "NCHSR FY 2021 Letter.pdf": "22138D0E-FDFD-4958-A034-9B71CC00818E",
    "NCHSR FY 2022 Letter.pdf": "508F489E-294B-4438-B90F-9D4911ACB1A4",
    "ncpneuro0092.pdf": "178AA74F-A250-4FF2-9DF8-12C01FEDF6D4",
    "NCSS_Support_FY22_Senate.pdf": "B147C7F1-0A2E-41D8-80A8-B76358F98716",
    "NDelavari.emailverification.pdf": "99933D90-9674-4E80-973C-EEA5F2CDE881",
    "Ndi Geh-RESverif.pdf": "FE25F4B3-3C7F-42CA-9444-045D09C1C5CF",
    "NDI.pdf": "ED6017E0-0A12-4504-966D-0627089D1A33",
    "Neal CV Oct22.pdf": "4409CD6D-1118-474B-8BDC-6CC114EA61D1",
    "Neal Nathan Proof of Enrollment USC.pdf": "49918C2B-3F38-49DC-AB36-1C8ECEEA931E",
    "Neal SNS nomination 2022 Ball.pdf": "1E839096-5605-40CC-9B51-65D076F46F0E",
    "Neal SNS Nomination 2022 Ecklund.pdf": "6C4793BB-04D4-4396-B239-DFB045ADDB06",
    "Neal SNS Nomination 2022 Ecklund_Updated.pdf": "553B0DC3-0B9B-4884-85BC-59CDB9DDD104",
    "Neal SNS Nomination 2022 McInerney.pdf": "A75CB640-6766-45C4-9F18-697FE6F4C49D",
    "Neal SNS nomination 2022 Rosner.pdf": "01733198-7863-4EC4-B474-BF9ACAEF6F76",
    "Nealen Laxpati-FellowVerif.pdf": "A470F4A7-A42F-4475-80F1-B5762060A1E7",
    "Nedim Verification.pdf": "AA9824D6-CA68-44C3-A28C-85C83C17A492",
    "Neelan Marianayagam Cyberknife Fellowship Acceptance Letter (2).pdf": "2513FD3E-BEA5-4D4E-9210-F10DBCA1BC77",
    "Neelan.pdf": "1123B9B7-BCE5-4576-835B-3B28FF15B9BC",
    "Nefize Turan CV_07_31_2017.pdf": "82961E36-1ABC-4BB6-B615-4F4B06708F99",
    "Negash-Enrollment Verification.pdf": "19BD9420-B7D5-43BD-B73C-12919AA619BE",
    "Nehaw Sarmey-FellowVerif.pdf": "5986E5AD-D795-4DD8-B9A6-3D84CF878DB4",
    "Nehemiah Wilson-Letter.ofgood standing.pdf": "C47461CC-52FA-435D-BD69-1E7532ADCBA8",
    "Neil Majmundar-CV.pdf": "6902AE80-37EF-4938-961C-9435A681DF51",
    "Neil Malhotra CV.pdf": "2E820BF0-818B-46FD-B0A6-E810EE9132B8",
    "Neil Malhotra Nomination Letter.pdf": "17008104-1227-4E91-A632-E5BDD975DF2E",
    "Neil Malhotra Recommendation Letter.pdf": "74D288F5-A8AD-4A98-9131-920078A447AC",
    "Neil Malhotra Sponsor Letter.pdf": "CC9D7CFC-BFAA-4C08-8B92-2D5B6643D14D",
    "Neil R Malhotra UPenn CV 2020.pdf": "4461C404-BDA5-4B1B-AEE9-4F8CA79BBCC4",
    "Neill Ryan.pdf": "475E8139-B390-47CA-910F-BD4EA07426B2",
    "NEJM LTE Fusion for Degenerative Spondylolisthesis 050116.pdf": "6D24BA7A-79FB-408B-8CB3-70B3ED2E14E3",
    "NEJM_Intraventricular CARv3-TEAM-E T Cells in Recurrent Glioblastoma.pdf": "E4F08279-DE94-495A-AD37-109171DA85A4",
    "nejm_lte_fusion_for_degenerative_spondylolisthesis_050116.pdf": "7B6FEFEF-F0D9-4441-9F9E-C29C3E7B785C",
    "Nelson, Paul B.pdf": "CD24CCBC-7F4C-4508-B707-7FC0C29FB12A",
    "Nelson, Paul.PDF": "AFAC8D22-4CDD-47D7-9C05-B38B95CCD8C3",
    "Nenkimun Dirting Bakwa-SPECIALIZATION IN NEUROSURGERY.pdf": "5B156296-B222-47E7-847F-21FC1592F714",
    "Neoadjuvant SRS for Brain Mets_CNS 2022.pdf": "6BFCC45F-A463-4A8F-B253-47902A3F27E0",
    "Neonatal Brain Tumor CNS Poster.pdf": "A5431722-FB63-45A1-8994-087FC448531A",
    "Neria Douglass nomination package complete Dec 2023.pdf": "168E2C8E-43AF-46B4-B009-BB30AB32BDA9",
    "Nery Alexandra Lamothe 1.pdf": "966975C1-4587-42A2-8BC4-9B08B4417C20",
    "NES Department Letter.pdf": "05AFC3D3-6057-4D0A-A269-255D2143DC5C",
    "NES_Letter.pdf": "F9781096-89B5-48A6-B680-2F2562D1C47F",
    "NESIS_Withington_CNS-Poster-Template-48x36.pdf": "93EE5C0F-7188-427F-AC9A-330EAA3B83AA",
    "Nettnin_E.pdf": "FBF9228D-1CC7-406B-AD77-CFF7E00707E3",
    "Network image - authors w at least 20 pubs.pdf": "0DA8E08E-589A-433F-951A-6809DF06C366",
    "Network image - community G.01.pdf": "5F944362-E512-41F6-A2F4-4F77793EA73F",
    "NEU logo toc.pdf": "FF2313DC-6BA7-44A8-BC5D-00FAC376FDEC",
    "NEU-D-22-00887 870..875_April 2023.pdf": "AFC696E7-A55F-4502-B89B-55F420A6C9A5",
    "neu1115.pdf": "703DD57A-1A60-4ACD-96F9-B1636E2ECEDD",
    "neur.2022.0084.pdf": "6A4B0DC6-8619-48B4-8DBC-67E3365DB183",
    "Neural Symp Poster-final.pdf": "B64E3D0E-7D84-4E79-9CAA-D714E804D961",
    "NEURO CV.pdf": "ACFFAEB9-F995-424C-8C41-5D7F83377AD3",
    "neuro0001.pdf": "457F25C4-CEC1-4AB6-A14B-B18397D56C6D",
    "Neuro2023.pdf": "3F70B24F-F795-434F-8FCE-9CD7C1C105DD",
    "Neurocritical Care - Time Limited Trials.pdf": "FC900521-E62E-42C9-8174-7A30C6BE9AA5",
    "Neurocutaneous Syndromes.pdf": "FE6A8E20-BC62-4BC1-A720-93632D4BC7F2",
    "Neurological Society of India - Associate Membership.pdf": "30C7CF86-AE89-4693-85F2-00B9FADD2F94",
    "Neurological Society.pdf": "A3FB36F2-1642-45CC-B378-6E4F35565863",
    "Neurological surgeons Kashuba.pdf": "ADF0740D-AFB8-4B73-8D67-A6D067F6F54A",
    "Neurological Surgery Program Requirements 7.1.17.pdf": "6DF17932-7A25-4D69-A104-D565F0AA9C9E",
    "Neurological Surgery Program Requirements 7.20.pdf": "A302CFCE-E357-4211-9269-503A5A57687C",
    "NeurologicalSurgery FAQs 7.1.17.pdf": "2A0462CF-E20C-46C6-B0F0-276D6138A88F",
    "NeurologicalSurgeryMilestones 3.18.pdf": "55CED359-63E5-4DCC-8BCC-081F9C634888",
    "NeuroNewsSummer2002.pdf": "0624C7CC-2B2A-431D-8239-714D3D453307",
    "Neuroradiology_5-4-2022F (1).pdf": "8A529DAA-76C9-40FD-9E47-CEA7B2FB84FD",
    "Neuroscience Chair LOC for CNS leadership application.pdf": "BAD9D32A-0CCC-49D4-8C38-4AB3C12CC1E5",
    "Neurospine Society membership certificate.pdf": "63B03239-BFCE-4DBC-ADC1-7F86130DE38F",
    "neurosurg society 2014.pdf": "FC58B8B8-6596-4920-9794-60C08EF12639",
    "Neurosurgeon Certificate-Nobutaka Horie.pdf": "8284801F-5C4D-4203-BC77-CC473661B14E",
    "Neurosurgeon Certification.pdf": "72AAC4DD-BD87-476C-B83A-5303BC530F3C",
    "Neurosurgeon Welcome Letter.pdf": "5243EB7C-7C3B-4858-B168-18CA48A2B893",
    "Neurosurgeons Press Release Endorsing Reps. Bera and Bucshon Medicare Legislation 103020.pdf": "41F3C4D1-D9C8-4ECF-8DE4-7E065991DB31",
    "Neurosurgeons Press Release Re Medicare Payment Cuts 120221.pdf": "778CC49A-2CBC-498E-8F4F-7BA78E6473A3",
    "Neurosurgeons Press Release Supporting Medicare Providers Act  111921.pdf": "70BCEFCB-4D2A-44EC-ADB5-EC974CCF916D",
    "Neurosurgeons Press Release Thanking Reps. Bera Bucshon et al for Medicare Letter 101521.pdf": "79615F9A-56BA-4B53-95B9-30B6DB0EECCF",
    "Neurosurgeons Press Release Thanking Reps. Bera Bucshon et al for Medicare Letter 101920.pdf": "29D30B16-1705-478B-8FAE-9E2AD6790423",
    "Neurosurgeons Press Release Thanking Reps. Burgess-Rush for Medicare Legislation 100520.pdf": "A12956FC-A22E-4FC9-94DA-1D1B5530EE63",
    "neurosurgeons_commend_lawmakers_for_supporting_review_of_prior_auth_fina.pdf": "68B4BAF9-2D01-4647-B428-6E90DB4800EF",
    "neurosurgeons_express_serious_concerns_with_medicare_physician_payment_rules_6.27.16.pdf": "083A8318-0FFF-471E-B952-19BADB6C97F1",
    "NeurosurgeonScientist - C. Hadjipanayis.pdf": "A4CAA83E-A2E3-4919-9B5F-E44E694733C2",
    "Neurosurgery Board Certificate _ DW Zumofen.pdf": "EF7575CA-83C9-405A-AA50-CA4C8B73D69D",
    "Neurosurgery Board Certification-Keun Lee-South Korea.pdf": "63158FED-6C36-4EE1-BD10-39F806FB7BD5",
    "neurosurgery CERTIFICATE -FALSAIEGH.pdf": "B813C57B-88C2-4127-B199-DD31FAF13B1A",
    "Neurosurgery certificate.pdf": "A0A17984-9901-4BC2-BA0E-E9F529B7FCF4",
    "Neurosurgery Degree(1).pdf": "361496A7-70A1-4837-8B7C-45BBF157A6EA",
    "Neurosurgery diploma.pdf": "3627EB1D-8F7A-48AF-A714-B396F5342025",
    "Neurosurgery General.pdf": "AC090109-BBE0-422B-B2BF-FC7B615A4C3E",
    "Neurosurgery Interview Dates as of 11.16.2023.pdf": "321EB11A-067B-4D1A-B1B2-D11675D00AA5",
    "Neurosurgery Interview Dates as of 8_31_22.pdf": "FCD05361-3C12-4BBC-8BEB-4ADB3E6DBCA1",
    "Neurosurgery Match Series Industry Support-2.pdf": "2682F1E6-1D94-4DFF-91A5-749AB1CAC731",
    "Neurosurgery Match Series Industry Support.pdf": "7E01C3B1-404B-4EC4-AD2F-B82F0766D71A",
    "NEUROSURGERY MEMBERSHIP.pdf": "6CFF4159-A3D0-44D2-924B-60127D91FCE9",
    "Neurosurgery Paper.pdf": "589885F9-0AF0-43E6-8207-1412B95EDA88",
    "NEUROSURGERY PROGRAM LETTER.pdf": "582FEBB4-0F33-4AE3-8E4F-74CC57B57CC9",
    "Neurosurgery Publication Personal Statement.pdf": "A5530CD2-7745-443E-9F52-4C66879EB8F9",
    "Neurosurgery Publications Fellow Application.pdf": "0287724F-85B4-4D0D-BB68-589B033A8684",
    "Neurosurgery Publications Resident Fellowship.pdf": "EDA583D2-8723-412A-87D9-242F4AAFAF9F",
    "Neurosurgery Residencies on Twitter.pdf": "BDA97326-3396-4586-A0FE-72D84BD93954",
    "Neurosurgery Residency Ain Shams.pdf": "2F02ED41-1E3B-4B20-9A63-6A7DA8A47C67",
    "Neurosurgery Residency Diploma.pdf": "7AA4EEE0-B843-40BF-B19B-6DB719BBC226",
    "Neurosurgery Scholarship acceptance letter.pdf": "A6364556-714A-4F15-9895-D851A5B03643",
    "Neurosurgery Spine CFP_Final_Marks.pdf": "3D524DDB-FDE0-4AD2-AFB5-61D4CAB17739",
    "Neurosurgery Standardized Letter of Recommendation - away template - updated 3.29.23.pdf": "2BD1F749-2A6B-44C2-8793-0EFE10904921",
    "Neurosurgery Standardized Letter of Recommendation - away template - updated 7.18.22.pdf": "3694F686-7019-4E1D-8E60-E892F86042FF",
    "Neurosurgery Standardized Letter of Recommendation - home template- updated 3.29.23.pdf": "FB073F45-E8CD-46B7-8857-8B77A7644491",
    "Neurosurgery Standardized Letter of Recommendation - home template- updated 7.18.22.pdf": "0262ACE3-E1D8-43E2-BE75-482B28DBB484",
    "Neurosurgery Standardized Letter of Recommendation 2.0 v2.pdf": "7A57E003-477A-4C66-8568-232559B75B8A",
    "Neurosurgery Standardized Letter of Recommendation_Final.pdf": "6EBF4CEE-C443-4949-A5A5-314BBCF2B122",
    "Neurosurgery's Recommendations to ACGME re COI FINAL.pdf": "8017CA64-3168-409D-8495-E81F11032CCD",
    "Neurosurgery-MS-Table1 (2).pdf": "6235AFBF-F2C7-4A2D-BDD1-06FD84C9F930",
    "Neurosurgery-MS-Table2 (2).pdf": "877C22CB-7702-469A-81F6-D629E0C18022",
    "Neurosurgery.pdf": "1E9E4CDB-FD6B-4B88-8EFB-4598BD575CC2",
    "neurosurgery_acgme_letter_common_program_requirements_vi_121916.pdf": "4B5B63A4-1C0A-4B22-8AB8-1FA744F8805D",
    "neurosurgery_comment_letter_on_2016_mpfs_final_rule_122915_final.pdf": "2EFB1A5D-281B-49B1-945D-DA84A3999407",
    "neurosurgery_comment_letter_to_noridian_on_proposed_lcd_dl37729_mrgfus_080618.pdf": "7B31B38F-48D4-40BC-8E53-CBB37532B743",
    "neurosurgery_comment_letter_to_novitas_solutions_lcd_dl35094_mrgfus_100918.pdf": "F559D9E0-4446-433E-9423-DE2FE7B2C3A7",
    "neurosurgery_comments_medication_assisted_treatment_for_opioid_use_disorders.pdf": "B103FA6C-5F7A-439D-BD17-3056DAA7662C",
    "neurosurgery_comments_on_cms_quality_measure_development_plan_022916.pdf": "045A669C-877C-4167-8A41-CD1312A5A510",
    "neurosurgery_duty_hours_letter_to_acgme_011416.pdf": "9C1CB28F-457B-4680-B6A7-39378466E3ED",
    "neurosurgery_iom_gme_release.pdf": "AAAA3E0B-7502-4216-9CD3-34B59A2DA2F2",
    "neurosurgery_nam_collaborative_050519.pdf": "B7C603D1-E058-472C-97B8-089D55B12FCD",
    "neurosurgery_position_statement_on_overlapping_surgery_final.pdf": "C97BF100-5F36-49E1-9048-80C33A57614B",
    "neurosurgery_position_statement_on_overlapping_surgery_final_0.pdf": "0AF1C268-08A3-4C9A-94F2-7F878599542E",
    "neurosurgery_prior_authorization_survey_results.pdf": "764050E8-76C6-4BA5-A671-A326C2E5383D",
    "neurosurgery_sir_acr_asnr_snis_svin_comment_letter_on_2016_mpfs_final_rule_122915_final.pdf": "788BEA2F-63F4-42B3-8B98-1D8711D8729E",
    "Neurosurgery2021_STD.pdf": "CA4DF115-B38C-41FA-9DB3-750C4D7E946A",
    "Neurosurgery2023_Brochure.pdf": "4FE62591-49BC-452F-B277-085BE792492B",
    "NeurosurgeryChoosingWiselyRelease062414.pdf": "7FADD05E-5F8C-4ED1-A4AB-984F46270E29",
    "NEUROSURGERYFellowship-CV.pdf": "6A2A220C-48A8-4ABE-866A-C3DF9E35DF75",
    "NEUROSURGERYFellowship-PersonalStatement.pdf": "875F2CD3-3D01-49E0-9079-CF3C50D4578E",
    "NeurosurgeryIOMGMEPaper121912.pdf": "E5B64409-568C-4867-9065-522491319DCC",
    "NeurosurgeryResponsetoSACHRPOct2012Recomendations31113.pdf": "791CCA52-D168-4860-8A1F-E603EB196A35",
    "Neurosurgical Complications Agenda - 10-23.pdf": "7769E0C5-D211-480C-9866-25EE8E870DBA",
    "Neurosurgical Emergencies Agenda Draft 1-2.pdf": "8F3034B6-1497-4E17-901E-283AC67CC1D2",
    "Neurosurgical Emergencies Course Agenda.pdf": "B5FF8056-F4BA-4525-858A-891AFBF455E6",
    "Neurosurgical Emergencies Draft Agenda 1-31.pdf": "520B5770-FA8D-4EC4-9302-4F1D264A6959",
    "Neurosurgical Emergencies Virtual Course Agenda.pdf": "012E31E7-EDA3-4D67-8CC9-FB9D5B04BB81",
    "Neurosurgical Society of Australasia - My Account.pdf": "65D453DC-DB09-48CE-A074-92242F21272D",
    "Neurosurgical Society of Trinidad & Tobago.pdf": "6641D5E6-0294-4ADF-9FE2-B4016FADA226",
    "Neurosurgical Training 1997-2003.pdf": "EEC74214-B06B-4A97-A34A-72157FB29D40",
    "Neurotrauma_Muehlschlegel.pdf": "8B7D397E-7247-451E-B15B-BE035DF02DB5",
    "NeuroUpdate 2021 brochure.pdf": "0719E3CE-5C2F-4BC7-8B7D-770C08BE3510",
    "NeuroUpdate 2022 brochure.pdf": "51B40DFA-E626-4629-B926-223503D59535",
    "Nevada.pdf": "F5C0683A-18CB-42D2-9E6E-EE8E5090EFB3",
    "Nevan Baldwin-Headshot- (1) copy.pdf": "EE8CA11B-A419-47DF-8953-E6FA1E985895",
    "Neville Knuckey Recommendation Letter 2.pdf": "56B675C5-9777-48A5-AEC2-B2424911C9DE",
    "NEW CNS Electronic Payment Authorization Form.pdf": "0691E557-EAAC-4B25-9F68-08B939CA8A6F",
    "New Curriculum Vitae LHRS.pdf": "D1D4A077-5E65-4D64-A745-E181F568E354",
    "New CV .pages 2021-2.pdf": "15F9EC42-5B89-4918-90D0-3C7302C9AE45",
    "New Doc 14.pdf": "7F54592F-606E-4370-9659-0317D4A48EAD",
    "new doc 19.pdf": "AB6BD072-16AB-408C-9B7D-EE030E7B8052",
    "New Doc 2018-05-28 (2) 2.pdf": "EAB8C217-0482-4756-92AC-B69FC8DD4941",
    "new doc 2018-08-01 14.21.08_20180801142128.pdf": "8B40D6BC-58CA-491B-82C1-0F60A59C9C5E",
    "new doc 2018-09-05 19.17.09-20180905192335.pdf": "BA5F423D-C86F-484B-9D6C-65057FF7CB6D",
    "New Doc 2020-01-09 22.36.38.pdf": "37E088FC-EA24-4A79-AFD0-77E5F8E0764D",
    "new doc 2022-08-27 10.11.46.pdf": "0CD32EFF-91F3-4CE5-8363-505D574FB809",
    "new doc 2022-12-06 08.27.54_20221206083703.pdf": "D58C8212-05FC-4CC7-A591-D3B41F0B4592",
    "new doc 2023-08-24 19.51.29.pdf": "6E009CEC-6D06-4644-BB9E-E415DDC24413",
    "New Doc 7.pdf": "89937200-2CD2-425C-921C-51752E3BDDFE",
    "New Document(4) 22-Feb-2021 23-11-09(1).pdf": "108058C1-1D31-4D1A-94E1-52E7B688592C",
    "New Document(4) 22-Feb-2021 23-11-09.pdf": "CD3831C4-D57B-4A4A-B7DD-AA16C06CCB3E",
    "New LSU Health Sheveport.pdf": "CA128DC8-3A8E-44FD-9541-3342959F5E4A",
    "New Member Materials Deadline.pdf": "CE5D2E34-67CF-4472-BB28-F445E097D442",
    "New Res  NY Presbyterian Cornell.pdf": "01A05048-9013-47B0-89D1-40158AB51688",
    "New Res - U. of Missouri.pdf": "C62BED77-C4F0-4A66-AD75-04236C2C299E",
    "New Res AHN Allegheny.pdf": "B4449F99-2B19-4FDC-AC12-52EE2A4AE9D7",
    "New Res Albany Medical College.pdf": "965EEE7D-DD54-486E-95FA-BAE005657C80",
    "New res Albert Einstein-Montefiore.pdf": "AC13F091-255E-4EC5-94D3-61E470127D26",
    "New res Augusta U..pdf": "3F5F659B-90C7-4D29-B960-F23E46072E0B",
    "New res Barrow Neurological Institute.pdf": "74E879E2-041A-45B3-9D89-4826F52B0787",
    "New res Baylor Scott and White.pdf": "EC2970EA-DC7E-4437-B917-B2F81D74B16D",
    "New res Baylor.pdf": "EC6923B5-3182-42C1-A72B-361EC8E32387",
    "New res Beaumont.pdf": "2880E90A-D37E-4C7F-A87F-6523D8D91E54",
    "New Res Beth Israel Deaconess Med Center Boston.pdf": "41684A11-7ECF-4B6C-A93A-3760F2F09DC7",
    "New Res Brown U..pdf": "F042403E-300E-457C-9491-8FB9E3E86041",
    "New Res Carilion Virginia Tech.pdf": "560F6C4B-F3FE-435E-A3F2-0B69D08B7884",
    "New Res Case Western UH Hospitals.pdf": "14852DD5-B5D7-46EA-8F85-5219D57E3B79",
    "New Res Cleveland Clinic.pdf": "2A7BD104-1279-44F2-81F3-63ACC9EA77FF",
    "New res Duke U..pdf": "22750C8B-6023-40E0-9F73-83427EE58B9A",
    "New Res Emory.pdf": "4EE4A0E6-56F1-4970-B376-88AFE90882E1",
    "New Res Geisinger.pdf": "682095C0-D3EF-4857-AA9E-953141705650",
    "New res George Washington U..pdf": "1C576991-F90B-43FD-8DD2-01E0AA9DBF56",
    "New res Georgetown.pdf": "328D57DD-2B02-42ED-8B68-32A7552AF79F",
    "New res Henry Ford Hospital.pdf": "29FEDC28-2E1C-46B5-A90A-5673B4F5DE85",
    "New res Houston Methodist.pdf": "88FB993D-582C-47A0-98EE-4454E36B55AA",
    "New Res Icahn Mt. Sinai.pdf": "1D85F75C-A3C0-4D75-988A-2FA46F3957BE",
    "New res Indiana U..pdf": "94264685-4927-41F9-A62C-2FAEA06ADF2F",
    "New Res Loma Linda.pdf": "FA7F2FA0-1E58-48A6-96C0-B802B380BFB4",
    "New Res Louisville.pdf": "E7D42925-B403-4BA8-8968-78D80F77BDAD",
    "New res LSU.pdf": "6AE064BC-1AEA-4880-BBE1-18B82D530D12",
    "New Res Massachusetts General Hospital.pdf": "D545CF88-9414-40FC-BE4F-6CAB42F9D4D7",
    "New res Mayo Clinic Rochester.pdf": "EF730474-2574-413A-A06F-0DDCFA5A7C9A",
    "New res McGaw Med Center of Northwestern U..pdf": "41BEC209-B6F0-404B-86DC-8AC955324E80",
    "New res medical University of South Carolina.pdf": "C234F6DF-92BF-42D8-8BCD-191F3B933AF8",
    "New res National Capital Consortium Walter Reed.pdf": "1CF3DAAD-DE3F-43CF-9FDF-3D50F8D9889F",
    "New Res NY Presbyterian Columbia.pdf": "84657A5D-9443-4ABA-902F-781D763133BB",
    "New Res NYU Grossman.pdf": "7CD3FA29-5BDF-4A4D-838A-09B736B2A721",
    "New res Ohio State U..pdf": "C03E99F5-F2D4-4FB7-9AC3-B1B0E6B070CD",
    "New res Penn State.pdf": "8F32F840-8755-4C9F-983B-2C58F7A0D7B6",
    "New res Philadelphia College of Osteopathic Medicine.pdf": "95367BB1-54E5-4F58-98BA-DB0EC4BADE0D",
    "New res Riverside U..pdf": "5D32D7FE-39CB-42AF-AD03-0FC786A87CB8",
    "New res Rush U..pdf": "66810BD2-3517-4179-AA96-3B52F29FE7D6",
    "New res Rutgers New Jersey.pdf": "0B14439D-3503-4145-B8BD-155C090DFEBE",
    "New Res Southern Ill U..pdf": "4380BDC7-3FA3-46D0-BE74-4B697B762FF2",
    "New Res Spectrum Health MSU.pdf": "0CBA3A6B-BAD7-4976-B11D-780E9CFC359E",
    "New res St. Louis.pdf": "9B237E52-6A54-403D-B9FA-C76CEC14D5C8",
    "New Res Stanford.pdf": "3E812B3D-B5C0-4527-A160-15EE4BFE5EC4",
    "New Res Stony Brook.pdf": "9EBCE75A-43F1-4FFF-984E-25DA70985FFB",
    "New res SUNY Upstate Syracuse.pdf": "50AA512B-275F-4BAB-9B0D-20BD84822E9D",
    "New res Thomas Jefferson U..pdf": "A3FE60A8-1C46-4D0C-8CB6-283A3E78F482",
    "New res Tufts Medical Center.pdf": "B07F55CF-ED50-4551-BA46-0F01FBB40FEE",
    "New res Tulane U..pdf": "681E5DC6-D95A-452E-AF09-17C35D9347F0",
    "New res U. at Buffalo.pdf": "772E2A20-CE8E-4FB2-AB8C-AA5877DCF8B8",
    "New res U. of Alabama.pdf": "87F85DE3-1E82-471E-93A1-2C45FFD5DF80",
    "New res U. of Arkansas.pdf": "21CE98FF-DFC3-4C35-BBA6-187CA240EB59",
    "New res U. of California Davis.pdf": "9649CA3F-65BD-477F-ADA4-0651C6250CEA",
    "New res U. of California San Francisco.pdf": "BD966937-5933-404C-9F4D-4E5A25B7EF81",
    "New res U. of Chicago.pdf": "D3E2C7A2-CD20-4F42-A85E-DAD7C8DA8ECA",
    "New Res U. of Colorado.pdf": "59553DD1-090D-4940-AA13-F6A0D94C9B6B",
    "New res U. of Connecticut.pdf": "2E70CA38-42BC-49D5-9D05-0F6C36A9DDE7",
    "New Res U. of Ill. Peoria.pdf": "39340C24-EF4F-4E97-ADB8-9605953B97C1",
    "New res U. of Illinois at Chicago.pdf": "550506BB-153C-4AC1-8CF5-14BA6088D5F0",
    "New Res U. of Iowa.pdf": "C985CFC6-952F-4505-A82D-227F3F38562D",
    "New Res U. of Kansas.pdf": "22411141-CF25-41E6-82FB-50E0DE3486E3",
    "New Res U. of Maryland.pdf": "14CD5017-C0F1-41F6-AE09-28FE09F93CE2",
    "New res U. of Miami Miller SOM.pdf": "4462208A-6570-4200-9962-936E52F811C2",
    "New res U. of Minnesota.pdf": "53203A11-AF25-4E27-92E5-42C7BF78A637",
    "New res U. of Nebraska.pdf": "0E84C9EF-24FB-4BCA-8A9B-2F1609B713D7",
    "New res U. of North Carolina.pdf": "356AC278-A2D9-42F9-AD04-127114700682",
    "New Res U. of Oklahoma.pdf": "4BB061E1-C43D-4580-9D15-DF40D9543215",
    "New res U. of Penn.pdf": "5F9B6D88-C801-4292-82B9-F6FA5185A9A0",
    "New Res U. of Puerto Rico.pdf": "76E7A65C-47D6-4E99-A3B4-45E58F290348",
    "New res U. of South Florida Morsani.pdf": "A664B21E-383E-4EEC-8D46-5EEE035B5173",
    "New res U. of Tennessee.pdf": "73217E62-F23C-4ADD-A2D9-7C1C1BC559D6",
    "New res U. of Texas Houston.pdf": "1902AE23-132E-498F-905E-376C557B3429",
    "New res U. of Utah.pdf": "A4153E5D-34ED-4359-BAE6-E21AC8292205",
    "New res U. of Virginia.pdf": "9180D229-67C4-4079-AE68-160888A2BF6F",
    "New Res U. of Washington.pdf": "34DFEBE7-B85F-49B3-AAD4-8AFCE7776D5E",
    "New Res UCLA.pdf": "4F1D2F32-8780-4F76-8A2C-0E0E82F10A3D",
    "New res UPMC.pdf": "A81E1175-666B-4067-9F09-8B42929BC940",
    "New Res Vanderbilt.pdf": "961421B4-3CAE-48FF-A844-838121CCA784",
    "New res ver Ascension.pdf": "C3AC8D2E-0214-47EE-9971-3C2FC984B4F4",
    "New res ver Clinical Center at the National Institutes of Health.pdf": "9628220E-C8E4-4EF9-87DE-55B1D1BF6C2B",
    "New Res ver Dartmouth.pdf": "390E44DF-2D77-470C-B749-CA09F8942EB4",
    "New Res VER Jenna Meyer.pdf": "30B399EA-64E3-44EF-8483-ADEFB5B02F53",
    "New Res ver Johns Hopkins.pdf": "E99355BA-36BE-4373-8B70-734180EBC05C",
    "New Res ver Loyola.pdf": "994AC00B-4DC0-4FA1-9D45-61FC661379D5",
    "New Res Ver Mayo Jacksonville.pdf": "00F3F06F-6C2A-49F5-8A6C-13B736275C03",
    "New res ver Medical College of Wisconsin.pdf": "4BE478A3-C94A-4B84-A5DE-8CF3866A6F79",
    "New res ver Stanford.pdf": "4C456C13-28F3-49CF-8A7B-FC74A21FC60D",
    "New res ver Temple U..pdf": "D2316B3D-88FE-4A1E-8B1C-7D332DE1CF98",
    "New Res Ver U of Massachusetts.pdf": "C6BC97B9-71DB-4401-9929-789A7841C52D",
    "New res ver U. of California Irvine.pdf": "1E73BBFA-74BB-4FD5-8426-B6B3D6810E4F",
    "New res ver U. of California San Diego.pdf": "D26D9CD4-63BE-4012-8E13-3C799F9EFCBC",
    "New res ver U. of Florida.pdf": "937FF334-66D3-4DC5-B524-8B22F91EDD04",
    "New Res Ver U. of Kentucky.pdf": "63996185-E796-48B8-A1EE-ABF39E3DC4E3",
    "New Res Ver U. of Mississippi.pdf": "1753BB01-2012-4234-9AF3-0B912A938706",
    "New res ver U. of Pennsylvania.pdf": "301C7015-015C-44A7-92C4-4CC1600968D0",
    "New Res Ver U. of Rochester.pdf": "79579536-B945-4879-BD84-1FC63486B378",
    "New Res VER U. of Vermont.pdf": "58262DDD-F0D5-4EA3-BF41-8911303C5A7B",
    "New res ver U. of Wisconsin Madison.pdf": "DDCC1E10-2B9A-42BF-9AE1-48A9798C112A",
    "New Res VER UT Southwestern.pdf": "62FE5C8B-6ABB-4340-B007-3908A3BF6B5A",
    "New res ver Wake Forest.pdf": "B185729A-D1BF-4597-8D5B-2E3A18BAEDB0",
    "New res ver Washington U..pdf": "D1C96DD2-6115-4304-BA5F-1D973051A733",
    "New Res West Virginia.pdf": "875FF849-5458-4D1B-8D38-705FAA41963A",
    "New Res Westchester Medical Center.pdf": "00F33C56-B420-413E-B250-19CF0147FC27",
    "New Res Yale U..pdf": "AB616636-4DD6-411B-A10C-1DE56C1D5275",
    "New Resident membership for Jenna Meyer-10.17.22.pdf": "22169AD6-2819-4CAA-9707-754372CB177D",
    "new york 84_compressed.pdf": "1E4150AD-9E01-44F5-86D0-34B54FFC8ED5",
    "NEW YORK UNIVERSITY_enrollment_certificate.pdf": "B04D70AA-3773-4400-B874-39E7FA1E688E",
    "New York.pdf": "AD85D423-C752-494E-9496-09DB5C06AC55",
    "New Zealand Neurosurgery certification.pdf": "73D610EF-1E1D-4D98-B60A-D1C13913A794",
    "New-Member-Engagement-Study.pdf": "9D5A6285-1EDB-46D5-AAE2-4507BDB7259D",
    "new_doc.pdf": "B524D72A-7957-4C36-B3E6-DE0EFD290145",
    "New_Microsoft_Office_Word_Document_2014_09_25_19_29_08_137.pdf": "069BE3EA-F9AB-4445-B1CD-204E652E4EE6",
    "NewDoc 9.pdf": "EA75D54A-0FEF-40E2-9334-8F89F20E7F1B",
    "Newell, David.PDF": "25870971-7953-4473-ADB5-1035DF8AC668",
    "NewFig1_2.pdf": "B9419D91-460E-477B-B777-E66DE91768BF",
    "NewFig2_3.pdf": "19D3367A-3138-4FBC-8EAF-32439F409D6C",
    "Newman Verification.pdf": "2084E299-934A-4EE0-9FBB-EE08C9862FA7",
    "Newsletter Summer 2002.pdf": "2DBB19EC-38C6-4474-9469-17CFA163F9DF",
    "Newsletter. COVID-19 issue. August 2020.pdf": "3019B7C7-321B-4D97-AF99-D4AD8CE67C48",
    "Newsletter_December1999.pdf": "795774D9-181A-41F6-8476-9F9EDD5ED0AE",
    "NewsletterAugust1998.pdf": "9858C0E2-5A95-48CC-91B1-546215B64813",
    "NewsletterDecember1996.pdf": "21472556-6E0A-41CF-94A6-0EDBB5C7783E",
    "NewsletterDecember1997.pdf": "1A20D981-A58F-4ADE-AF46-4BE254F4556F",
    "NewsletterDecember1998.pdf": "AA30F1BC-7EA7-42B2-BE5D-F6155F0961F4",
    "NewsletterFall2003.pdf": "B23E4ACF-9C9A-4D7D-8E8C-375BC6943249",
    "NewsletterFall2006.pdf": "7F5AF7CB-9E67-4404-99BC-40475630627D",
    "NewsletterFall2009.pdf": "634232D0-0A36-4DFD-AE99-BF874E5DED7F",
    "NewsletterFall2010.pdf": "D84F28B8-17A7-4069-9B7E-F8646E2EFD07",
    "NewsletterFall2011.pdf": "AA9744ED-1BCC-4E54-85DD-F6887450028D",
    "NewsletterFall2012.pdf": "49576CA1-BBC2-4A8E-9EBF-DF14363014AF",
    "NewsletterFall2014.pdf": "F16C30D1-FD05-4B21-8D15-2EFBE3772874",
    "NewsletterFall2015.pdf": "ECCA5A05-DA73-49DB-9BD6-E70BC268CAD5",
    "NewsletterFall2016.pdf": "0ABE8484-DA41-471C-AA42-83F742E69933",
    "NewsletterFall2017.pdf": "E6081471-1724-4D77-BEBA-F59A913E8872",
    "NewsletterJanuary1996.pdf": "4BCCE2F4-78BD-4628-9586-6F5DC2BA80C7",
    "NewsletterJuly1999.pdf": "1524F36D-E2E4-43AE-AD86-956A46298A8E",
    "NewsletterJune2012 .pdf": "B6386F85-0982-4B8B-9AA5-C2A95DBC3842",
    "NewsletterSeptember1996.pdf": "B1136744-5B84-4C91-B605-E5DDF46609B1",
    "NewsletterSeptember1997.pdf": "CCC9969A-D7AA-4A82-801B-ABDE92B18134",
    "NewsletterSpring2002.pdf": "0A458BDF-84ED-4467-8602-65892F5763B3",
    "NewsletterSpring2015.pdf": "3A7B18E4-5C85-41BB-AA57-61E22A9B0E09",
    "NewsletterSpring2016.pdf": "DD658069-42CC-4B69-B1FC-0576E5298F0B",
    "NewsletterSpring2017.pdf": "C510562E-F215-4DF1-B8D5-ACC664CE116C",
    "NewsletterSpring2018.pdf": "BE15AAFF-AC25-4E9D-803D-299432504845",
    "NewsletterSummer2000.pdf": "5CA176B7-5832-4AF2-BDBF-4EA2B6988DCA",
    "NewsletterSummer2001.pdf": "F7E5B1FB-9FAC-4E90-8402-3841514CF087",
    "NewsletterWinter2001.pdf": "BC5B7DE4-6E24-44F3-A218-136B431ECADC",
    "NewsletterWinter2002.pdf": "B540A6F4-64E2-49E9-A3CB-4340E95FC3F9",
    "NewsletterWinter2013.pdf": "AD8FE915-7DD3-43D1-9BC7-3A5BAEE480AC",
    "NewsletterWinter2014.pdf": "2A243429-5FB4-420E-B2A2-C6C0E2737765",
    "NewsletterWinter2019.pdf": "982A9912-8E46-473A-BC60-B64F84EE0D76",
    "NEWSOME-CUBY Takara OMSIIVerificationLetter.pdf": "250CA543-A29D-4ADF-AC42-9C4A9C27221F",
    "newtest0007sa.pdf": "0B25D2D7-5640-49B4-B5EF-6FD02065D69D",
    "Newton Cho-RES.Verif.pdf": "1396729D-DA6B-476C-971D-7719180C7A92",
    "NewtonCho- 2ndFellowship.pdf": "CC716940-543C-4ECB-9C79-E9A1779F8E79",
    "Next Steps in Physician-patient Communication.pdf": "27BF384A-9AB5-44D8-978E-34E7B4A1DE9A",
    "Nexus Opportunities.pdf": "8205D26A-88C9-4D89-BF69-4C1D03FFADFB",
    "nexus_support_opportunities.pdf": "30F60A70-2BE4-4783-8433-4FC6377695C2",
    "NF1 CNS Poster 2022.pdf": "00907352-1992-494B-83E4-FEC5F6D4010E",
    "nfield_CV 10-2-2022.pdf": "5141380F-DB8C-40E2-B9E7-8281F76CAC44",
    "nfield_CV 2-1-2022.pdf": "0D378D73-FDCF-4E39-8D45-9E1625B6065A",
    "NFL Team Performance.pptx.pdf": "C6AAF59C-A9F8-4841-894E-0C553BAB2E22",
    "nfpa-chapter-1-table-1_0.pdf": "9A223F2D-EECB-4F90-A452-EF9B494445AC",
    "nfpa-chapter-1.pdf": "E9AE3DC3-B7EF-478A-816D-C720361F04EF",
    "nfpa-chapter-2-figure-1.pdf": "E2CB2C03-3F40-462D-8284-238539204F92",
    "nfpa-chapter-2-table-1.pdf": "93F62FD1-C33D-4009-97DF-EA517451293F",
    "nfpa-chapter-2-table-10.pdf": "68987F40-4DE7-46F1-A7D9-ACF6B955BA23",
    "nfpa-chapter-2-table-11.pdf": "8CEA0967-6823-4F7A-A46D-D1EEFAC35493",
    "nfpa-chapter-2-table-2.pdf": "EA2FF502-9D1C-4B74-92A3-801A1074515F",
    "nfpa-chapter-2-table-3.pdf": "6CDAF986-1B2C-452D-B7C2-D52C943C16FB",
    "nfpa-chapter-2-table-4.pdf": "414152A6-CC1A-40A8-96FB-2D9B5ECCAE82",
    "nfpa-chapter-2-table-5.pdf": "F7999B04-E2B8-4A6F-A943-25A2FAB3F581",
    "nfpa-chapter-2-table-6.pdf": "4FB72BB6-E9D3-41A8-8BBE-B2F2006247AE",
    "nfpa-chapter-2-table-7.pdf": "879A3DCF-6B09-4059-A914-87FC47A7AE1A",
    "nfpa-chapter-2-table-8.pdf": "1EF02861-0304-46FE-A239-29DE2C6BCCBD",
    "nfpa-chapter-2-table-9.pdf": "F45E70E9-8F92-42E1-A4D8-EEAC1F5A2C9B",
    "nfpa-chapter-2.pdf": "DC4B2B0E-F516-4625-B5FE-36110EB6F9D8",
    "nfpa-chapter-3-figure-1.pdf": "B194D776-9B7D-4134-9359-A517728848FC",
    "nfpa-chapter-3-table-1.pdf": "12A39E23-3D4E-4051-BA5B-DB54DB45F92D",
    "nfpa-chapter-3-table-2.pdf": "A9914239-73BD-4D28-8D27-53B9752DD4DB",
    "nfpa-chapter-3-table-3.pdf": "3F1BD57F-E8D0-4FE9-81F0-99BC06161A9B",
    "nfpa-chapter-3-table-4.pdf": "F3225FB2-E425-437E-B9EC-D446D03FFF0E",
    "nfpa-chapter-3-table-5.pdf": "A395A7A1-8CC2-4D0F-B39C-7CA680FFF123",
    "nfpa-chapter-3-table-6.pdf": "D38032F8-D249-44F1-9C49-E6A2AE1CD038",
    "nfpa-chapter-3-table-7.pdf": "EFD7F732-0950-4FEF-87F0-863A53A4EFFF",
    "nfpa-chapter-3.pdf": "A2FF1E77-902F-4F65-99B2-315452CE511F",
    "nfpa-chapter-4-figure-1.pdf": "58E86CD2-8F64-4EAC-A6B7-255D94BD57A6",
    "nfpa-chapter-4-table-1.pdf": "A1B094CF-E870-4D3E-8068-0EE4896A5FC1",
    "nfpa-chapter-4.pdf": "37395C66-30B8-4F22-AB5D-67600B6A1478",
    "nfpa-chapter-5-figure-1.pdf": "CA449707-BFA6-49E2-AB4F-054670138825",
    "nfpa-chapter-5-table-1.pdf": "88629D0E-D766-43F7-BDA5-F58AD61E110F",
    "nfpa-chapter-5.pdf": "0E63AB88-F4B6-4F6C-A45D-4D7D45940B5B",
    "nfpa-chapter-6-figure-1.pdf": "7100C55E-F2F0-4A20-9125-0B41758EDAE9",
    "nfpa-chapter-6-table-1.pdf": "33BA1C47-517C-4510-8D9B-EC5AE9764BE6",
    "nfpa-chapter-6-table-2.pdf": "77A0E527-F8CC-4573-A963-516DA279E797",
    "nfpa-chapter-6-table-3.pdf": "4E49F3E2-9614-40AA-8009-E066579ECE40",
    "nfpa-chapter-6-table-4.pdf": "5DBB1876-1ACC-4116-91ED-E54522FF35B8",
    "nfpa-chapter-6-table-5.pdf": "F819C55E-73D9-4AEC-A48C-73A0919C2996",
    "nfpa-chapter-6.pdf": "D01F4290-74EC-4C6B-A5D2-220745DAA788",
    "nfpa-chapter-7-figure-1.pdf": "01BD5911-DBA5-4527-8369-750A02466CFD",
    "nfpa-chapter-7-figure-2.pdf": "2F6D4DF5-4A44-458E-A460-E304550523EC",
    "nfpa-chapter-7-table-1.pdf": "A7FD7696-FA2F-4D00-9254-DC5B76AB47BE",
    "nfpa-chapter-7-table-2.pdf": "CFB3791F-43CB-4311-89AB-DC03E7A0B474",
    "nfpa-chapter-7-table-3.pdf": "76090B11-81B4-4439-8890-0D5D99CC95E7",
    "nfpa-chapter-7.pdf": "BA7521D8-A799-4075-AB76-7CE5799262C8",
    "nfpa-chapter-8-figure-1.pdf": "41129061-AC69-4BE8-BFEF-01E16416F615",
    "nfpa-chapter-8-table-1.pdf": "7A260816-FBCC-4A00-B70F-2CAE1BBA0F33",
    "nfpa-chapter-8-table-2.pdf": "507040AA-3E58-4477-B222-C91387022783",
    "nfpa-chapter-8-table-3.pdf": "83489986-B471-4359-BFBA-D41E425479F4",
    "nfpa-chapter-8.pdf": "8740C1FB-E6AA-4C2D-9192-0427BD1A0FBF",
    "NG - Letter of Good Standing.pdf": "0D6C4AB7-F1A1-44C5-A607-18E80F23E345",
    "Ng Student Status Letter.pdf": "E462C83C-A37C-49EA-8DB6-CE80A98D767F",
    "Ng_Pseudomeningocele_PNS_Final.pdf": "5D6DD32C-62D2-4845-9B3B-FD362B30FE57",
    "NGA residency Diploma.pdf": "13DD780B-B03C-41EF-B32B-2CAC6A562439",
    "Ngo Hannah - enrollment verification (1).pdf": "83193483-E103-42FB-A603-D9C5EFDC7584",
    "Nguonly Dellvin - EV Issued to Student.pdf": "866A8AED-D2CB-46A6-8474-23CB30CEA319",
    "Nguy Austin Good Standing.pdf": "9721F71A-8BAE-4A48-BAE3-516BBB75EAEA",
    "Nguyen Andrew - letter of good standing 8-21-2392.pdf": "45BB5A1A-2604-4CA1-99F3-488FE5510422",
    "Nguyen Brandon_Student Enrollment Agreement.pdf": "809FF150-6288-4D6B-84B8-BCA5B02A2782",
    "Nguyen Sarah - MD Transcript.pdf": "F3EB389B-7DD6-4E22-8C19-05133B0390B5",
    "Nguyen verification.pdf": "C802F8A6-7E65-44EB-BC16-AE35789A3EA0",
    "NH Letter of Support.pdf": "BE5C207A-59DD-4838-8B42-1A4D50A7B946",
    "NHLBI FY 22 Appropriations Letter.pdf": "BED8A580-2573-4491-8BAE-D2CA8F6CED63",
    "Nicholas Ahye.CV.pdf": "3B46CD56-C6BE-40CE-B0A0-AE10875AB2C5",
    "Nicholas Ahye.Residency Certificate pdf.pdf": "51218451-E2B1-4F93-B4CB-7ED2F98F367E",
    "Nicholas Borg CV Jul 22.pdf": "CBF1BDF6-BD77-4876-8B47-2B18DF9506E0",
    "Nicholas Cassimatis.pdf": "CA28AF17-C81F-4502-AA12-56D233899DCD",
    "Nicholas Field-emailVerif.pdf": "B688B089-977E-4F14-BF0E-3F70EAB8BBF0",
    "Nicholas Rabah - letter of good standing (1).pdf": "668A6DCE-7007-4A71-9318-2E93FBD23730",
    "Nicholas Rossi MD - Neurosurgery CV.pdf": "1574AEEB-3968-43C6-A986-DD9D308E1E65",
    "Nicholas Sader-FellowVerif.pdf": "43DEB73C-6ABD-49A7-9529-060BC4AE27CA",
    "Nicholas Szuflita-FellowVerif.pdf": "D15FF1E0-75AF-4801-9357-5E3D217C2144",
    "Nick Panos resume.pdf": "450E0ABF-7E21-423F-B015-977961EDB964",
    "Nico Elsa_GS.pdf": "92E97FCF-DB0C-4739-B2BD-6E59155B4A13",
    "Nicola Pio Fochi.pdf": "BD83B3DD-C6CB-46F6-B821-236C6CF8B170",
    "Nicolas K. Khattar-FellowVerif.pdf": "539CE3B6-9C37-4F8E-A1BD-CCF56D1F7076",
    "Nicole Pendleton-RESverif.pdf": "833D8F1D-B80D-42EE-B455-BFDF1819A441",
    "Nie James_GS1.pdf": "5302A3F6-2A9D-4103-8049-ED996105EE17",
    "Nigam CV.pdf": "71F6485B-D7F6-4E81-BA80-47D543B3FCFD",
    "Nigam Verification.pdf": "306F30DF-7C39-47C9-81C7-A2D3D82F97EE",
    "NIH_CV_Dmytriw.pdf": "70426A19-F5CE-428E-8BE6-D4FB87E655AD",
    "Nikhil Murthy - CV.pdf": "D980348D-D928-405E-94C6-16989E03E902",
    "Nikita Das Enrollment Verification.pdf": "8B67125D-BF56-41BE-BF82-EA0382F5ADC2",
    "Nikolaos Mouchtouris - SKMC 2017 logs.pdf": "FA80DB6C-4563-418B-B360-FE325BB92FF6",
    "Nikolaos Mouchtouris-Fellowship.pdf": "7D712C2D-B18F-4CE9-A6C7-ACC05BE6AC28",
    "Nikpour Enroll Verification.pdf": "95E81AAB-EDCF-44D2-8553-0FBE8E13968C",
    "Nilo.pdf": "2500FF5C-5274-4827-820D-53CE11FEAD7E",
    "Nima Alan-CV Feburary 2021.pdf": "08F7EF3C-B462-4E19-87F6-47ECF002CEA8",
    "Nimer Adeeb LOR to NSGY Pub Res Fellowship .pdf": "CA383A7F-D2D1-41DC-8B7E-8A4D3E6302F9",
    "Nimer Adeeb LOR to NSGY Pub Support of Time Commitment 1.19.21.pdf": "1429CA7E-1C96-4328-8CFC-6A0187F1A2E3",
    "Nina Zobenica Moore_CV_9_17_2019.pdf": "C3A4953B-5964-4E7A-8763-727FD32925F7",
    "ninds_letter_093019.pdf": "894BB241-F123-4485-AB93-196F7A21D927",
    "NinetydayAdmissionModel.pdf": "B9554288-5014-4095-89B6-508D7E0065C7",
    "NinetydayNeurosurgicalAdmissionModel.pdf": "EF9A8EE3-9C9B-4207-B149-E5C7336A09EB",
    "Niqad Ahmad - CV.pdf": "E764BB1D-D47D-43CE-831D-30F9A556B654",
    "Nir Lipsman - CV.pdf": "B8BB803F-65DA-449E-90E2-D454FBA7C3D9",
    "Nir Shimony SM CV 2023.pdf": "EA464A8B-1E24-4051-A015-8A9EBBEF9901",
    "Niranjan_Poster_1134.pdf": "22724C26-4E74-43EF-807C-59294EBB4BDB",
    "Nisha Giridharan CV.pdf": "F58FCD48-69DE-4F9B-AF23-E44C807191A0",
    "Nishanth Krishnan.pdf": "03C5AC84-B349-46B1-BA0C-430DDD8E12B3",
    "Niti Dharwadkar CNS Poster.pdf": "E7B43488-AD00-4C9E-9B70-777CF8212B47",
    "Nitin Jagdhane- CV July 2023.docx-2.pdf": "1B99319A-4A6B-4D71-93B8-B49D3A3F093D",
    "Nitin Membership Certificate.pdf": "A1E2BEB5-144B-479D-B528-20945968E8CF",
    "Nitin Tandon CV.pdf": "B8791714-1C03-4ECC-B1B0-A8874B892917",
    "Nitin Tandon Nomination Letter.pdf": "5DC9744F-F76A-4F70-A7AD-02AC23EA7191",
    "Nitin Tandon Recommendation Letter.pdf": "A6B9C25B-E386-467D-8FC4-83AA00BF6D1D",
    "Nitin Tandon Sponsor Letter.pdf": "9553BE03-89B1-4BD3-A82D-42BE54BD58E1",
    "Niu CV_2021.pdf": "AEC7D764-B19C-449D-A555-7B19BAA4D140",
    "Niu Letter.pdf": "B624DEE9-558A-420B-9A6B-DCA2A0D902D4",
    "Niznik - Graffeo - NIH Biosketch Stats_Epi.pdf": "E6FA8D22-0229-4555-9851-C4DFE5AE0769",
    "Niznik - May 24.docx.pdf": "74200859-8922-49F1-8397-E8C152C43EEC",
    "Niznik verification48.pdf": "43DA2253-4479-43B9-8473-E031BC9268E3",
    "NJLTE7813.pdf": "A7DB9195-47F4-4CED-B257-E6DE346A2966",
    "NJM 2023 Digital Poster.pdf": "C9B42229-D76A-4392-8C78-854409E77BCE",
    "NLimoges.emailverification.pdf": "919BEC44-FCE0-4D82-B4E6-3F550E87969E",
    "NLP in Comorbidity Documentation Rounds Presentation.pdf": "AE67D9CF-61AA-4716-A12D-8C544A7AA35C",
    "NMO.pdf": "E8254A5D-D006-4E01-B9E6-75762EABB489",
    "NMurthyResidency Certificate.pdf": "FE13624C-F9F3-449A-BC0B-1521C4469761",
    "NMW_CV.pdf": "225ACE0F-1FBF-45FB-B8DA-83136F731298",
    "nnet partial plots.pdf": "E4BE2C82-7B7C-4EA9-BCE3-665DF2237A1F",
    "no 76.pdf": "E6267172-1887-4CF8-AD26-B1A81B68ED80",
    "no 97_compressed.pdf": "048020C8-8937-4661-86B5-1F8CD8000C45",
    "Noah Jacob Burket.pdf": "0D8104C1-BE25-4407-A89B-92312FA48269",
    "Noah Pardell - Letter of Good Standing.pdf": "63713DE3-A48F-4BF3-9831-0F67A1EF9C49",
    "Nobuhito Saito.pdf": "A5CF541A-A61D-4F80-9F2F-F901F012636B",
    "noc cchr cns poster.pdf": "3D215DBE-C3E5-447E-9172-CF30034C3657",
    "Noche R_ Good Standing 2020Dec7.pdf": "43C52937-E0C1-4D59-B71C-C64397582425",
    "Noel Balli - Program-Verification-Form-3.pdf": "F5F16DFE-7FE0-4A2C-8FF5-C76EC5CAB150",
    "Noel Verification.pdf": "38243E77-2059-4E6A-BDDD-D94B0C506C55",
    "Nolan Winslow-fELLOW_Verif.pdf": "50EBC2B0-34AE-4516-A195-3D73E05617AE",
    "nom.pdf": "00804FBC-79F2-49E8-AB48-448BCCDC38D5",
    "NOmar-RES.Verf.pdf": "01DE6FD7-6DC5-4E3F-A7D9-27097281F328",
    "Nombramiento Dr Lopez Sociedad de Neurocirugia..pdf": "FB03BC7D-A145-4552-B0DD-DCBA402E08A9",
    "Nominating Letter Grossbach.pdf": "464A79A1-57AD-41C4-B217-E6E02E8F3C26",
    "Nominating Weinstein for Aghi Sr Society .pdf": "648FCA5E-D3AF-4441-B3F9-1A7CCCC14362",
    "Nomination Aghi SNS Sept 20222.pdf": "80A98E26-01FC-4F77-94ED-3EE78B67361A",
    "Nomination Letter from Dr. Fredric Meyer.pdf": "F2BC1332-7A3B-4D7C-B4CA-61A1410CF90A",
    "Non-Covered Category III CPT Codes DL34555.pdf": "C62A00E8-C863-4490-AD90-55CFB093397B",
    "Non-Elective Admission (ACDF) - Table 1.pdf": "3037C7EB-B2F8-48D6-B4FF-009DA4B18ABA",
    "Non-Elective Admission (ACDF) - Table 2.pdf": "3F4B53F0-2B2D-4AE1-BAA9-ED87407C076A",
    "Non-Elective Admission (ACDF) - Table 3 .pdf": "900C5096-FFCF-4B73-ACA5-040E37E1E4A5",
    "Non-Elective Admission (Spinal Deformity) - Table 1.pdf": "F3D5E3F0-FD99-484F-8DC0-7B0EF08839F2",
    "Non-Elective Admission (Spinal Deformity) - Table 2.pdf": "7BB22E30-0E6F-4E5D-BEFB-461705A8465E",
    "Non-Elective Admission (Spinal Deformity) - Table 3.pdf": "9E67FC48-D7C5-4213-A94A-48751C0D8523",
    "Non-Meeting Digital  Support Prospectus_2023 Congress of Neurological Surgeons CNS.pdf": "4D46965E-8045-415A-868F-15270ADA0EB1",
    "Non-traumatic Spinal Cord Injuries. Poster - CNS21.pdf": "E2F7B112-3B3D-4AA8-80D6-7708B1A7D0E6",
    "Nonoperative Management_Peds EDH_CNS poster.pdf": "76B0A8AF-A111-4DB5-B5DC-D1AF66ACAA6B",
    "Nonroutinedischarge_analyses.pdf": "9CBADF70-9962-4B40-991F-9107394BE8DC",
    "Nontraumatic subdural burr hole.pdf": "B924C031-A8EE-4B58-91AF-06E284E2EDB2",
    "Noor Malik.pdf": "57D7E43F-D1B2-43A9-A4E6-4B729F6B4AA3",
    "Normal RAPID CTP Curves.pdf": "68DEB380-EFE6-4AF6-B3EC-B161ADC2F62B",
    "North SOLIS CNS 2023_poster.pdf": "D4D59AAE-3AF4-4F09-A34F-A792DF4E8FBA",
    "Northwestern Acceptance Letter.pdf": "54628CD6-0100-47E1-A0EC-D339B2CCAF2E",
    "Northwestern Neurosurgery.pdf": "80D657BB-0AD9-4D3D-BF2B-218899B23B98",
    "Notable Past Meetings New Orleans.pdf": "9678AB30-24C0-4618-83FC-3BE3FC42CAC9",
    "Notable Past Meetings san fran.pdf": "C30FDD11-6D8C-473E-9BD0-B2DEAA4548EA",
    "Notarised fellowship degree.pdf": "11FA6285-9EBF-4359-9A7A-D7FE093F1DE3",
    "Notes_240220_012354.pdf": "F158F34F-220A-4E42-93A9-4F4A4003F3B7",
    "Nour El Dine_LOR.pdf": "AF3CE7C5-9140-419F-B65D-42172A6D1D22",
    "Noureldine CV.pdf": "81C451C4-EF5F-4508-8D1F-0CDFD69D4008",
    "Noureldine_Letter of Support of Time Commitment.pdf": "B8E5463D-C005-4A83-8E98-36BD36C627C1",
    "Nouveau document 2021-12-05 21.38.31.pdf": "1655917F-390D-427B-B86D-06996FBD8DB0",
    "Nouveau document 2021-12-05 21.58.04.pdf": "9F0CCD39-82FE-4185-BC83-5AAB0576156B",
    "Nov 2021 Journal Article.pdf": "9123E28D-240C-4289-866E-E38189FDB215",
    "Nov 2021 journal article_updated.pdf": "E8F04CB0-016F-43A2-8F4B-D40D7B3EF629",
    "Nov 2022 journal article.pdf": "48669F36-B1A8-417F-823D-E0E439467B19",
    "Nov podcast article.pdf": "7806D3CD-4D1C-46D6-BF99-9F4FB2FD394C",
    "Nova Kristine de los Reyes - Curriculum Vitae.pdf": "B7AE89D8-3DA6-4FF7-9102-A4D2EEA511D1",
    "Novak V.pdf": "D4C73A25-1A17-476F-B2D9-1C5B4F554ABA",
    "NOVAKOVIC_Nemanja - Letter of Good Standing 050120.pdf": "D80F8C72-825B-4CAA-8E44-3BD0C3674A14",
    "NP  CNS letter.pdf": "249729D0-9F99-4383-8DC8-30F447322961",
    "NPH Poster Final Draft.pdf": "CB2CBCD1-291A-487B-B69A-3A1BF3972CC5",
    "Nprasad_ CV_2015_Aug.pdf": "7D7620C0-D6EA-4569-891A-690E023C3DCD",
    "nqf_measure_endorsement_process.pdf": "3CCF348D-1CD9-4EBC-B043-0F097EF3C2C1",
    "NRaviv.emailverification.pdf": "57163D3F-C930-4AA8-A339-F0D1DB3BF703",
    "NRDCP BioSketch.pdf": "4699187D-FA9C-45A2-B12A-5702CD0A5777",
    "NREF HYM fund status 2018.pdf": "9EE96B7F-42CF-4D8D-9579-BFC8230F91CD",
    "NREF.pdf": "B706A817-314E-4108-8C57-8851AA186A5C",
    "NS - CV - Kelkar 03232018docx.pdf": "00019987-01CF-4C3C-8649-F1FA7501E497",
    "NS CV Soo Teck 1.27.2022.pdf": "F98C0BB4-3987-4279-A217-5949003D8800",
    "NS CV Soo Teck 9.27.2022 2.pdf": "92818ECF-0462-43C6-84FA-C4E9DA81355E",
    "NS_ACGMEResidentStandardsNewsRelease062410FINAL.pdf": "6FBAA100-02DA-4F1D-B934-2F4802FC4889",
    "NS_Tables File.pdf": "E4A3E7B9-DD61-40DD-96C4-DB1FF4750CE8",
    "NSA - D Oehme Membership Confirmation.pdf": "9CDF6658-932C-4AB4-A176-1CBCA23627AD",
    "NSA Letter.pdf": "B578A9EA-A460-4E1B-8A6D-3615BE5714BB",
    "NSAmembletter.pdf": "1E4D432A-9131-418B-BBD3-D183F8B0C87E",
    "NSASLdocs_0002.pdf": "90EE952B-041E-4B24-96DF-FFBA418C3B09",
    "NSE Program Agenda Draft 3-1.pdf": "B27F24B0-F956-4FC4-96A6-919505AB57E9",
    "NSG CV.pdf": "0C808045-3295-4F69-992D-640E488FF540",
    "NSG publications fellowship - Cetas letter of recommendation - Erik Brown.pdf": "8566A322-83C5-4484-9A29-4109719A358E",
    "NSG publications fellowship - Personal Statement - Erik Brown.pdf": "F8BDF9F2-2A0E-4D0F-B3C5-62719767C114",
    "NSG publications fellowship - Raslan letter of recommendation - Erik Brown.pdf": "6BA75701-0795-4467-AAAD-F0817BA56B9C",
    "NSGY Residency Certificate.pdf": "AA088540-A5E8-4B46-B550-262178A44CE0",
    "NSGY Residency Program.pdf": "30B02057-E808-4A8D-852D-23EC772EC9B7",
    "NSGY_Complaint_Merged_File.pdf": "90C62B52-7CA3-410F-A93B-3A9F79656974",
    "NSGYPublications.pdf": "CF6525BA-A6FD-445D-B542-4B01D264C806",
    "NSI - Membership.pdf": "3D93CE3E-3BD7-427C-BBD5-767FD200BDA3",
    "NSI 2.pdf": "2CCD146E-82DE-421C-B7C7-862A3665C2B4",
    "nsi certi.pdf": "EEEF31B0-4221-4E65-A015-0803B90CBB16",
    "NSI Certificate.pdf": "62E390C2-6A50-4B44-A9E7-2161D346E0DD",
    "NSI DATA.pdf": "6C9A5BB0-AFA4-49F6-B590-3B9D11B973E4",
    "NSI Full Membership Certificate Dr Hitesh Kumar.pdf": "13ED064E-9899-4D0E-8CAE-9B3191BDBB53",
    "NSI Full Membership Certificate Dr Jayun Maheshchandra Shah.pdf": "9917CA62-C5C0-4A7C-8085-5B009EB51D05",
    "NSI Full Membership Certificate Dr Mahesh Ramola.pdf": "B4FC8659-240A-4A20-9684-04201BA6453C",
    "Nsi Full Membership Certificate Dr Mohamed Shoaib 1.pdf": "1DA18618-E429-493B-9239-9A906BB0446D",
    "NSI Full Membership Certificate Dr Rajan Kumar.pdf": "6112D58A-096D-4AE7-B8F3-AABFE3E4A80C",
    "Nsi Full Membership Certificate Dr Sunil Shetty KR.pdf": "9580A3C8-B35E-433B-BC78-025E56EBBD57",
    "NSI Letter of Recommendation - Dr Jos Jasper.pdf": "47330602-70F8-4D18-BBDA-6EE1854EECAE",
    "NSI Letter of Recommendation - Dr Kocherry.pdf": "68076347-0007-4AD8-A921-99633B2A0DD7",
    "NSI Letter of Recommendation - Dr Rajneesh Misra.pdf": "65BB127F-C973-42BD-992C-7425A9847B73",
    "NSI Letter of Recommendation - Dr. P. Palaniyappan.pdf": "1D5407EF-86E5-4F54-B43C-91B060F2898B",
    "NSI Letter of Recommendation - Dr. Shashwat Mishra.pdf": "36DFCF76-F4FD-4CF9-AB94-3B0499BB5320",
    "NSI Life Membership.pdf": "74842961-5408-41EB-9CD1-068D86BA3FE0",
    "NSI Member.pdf": "5698B8F3-9BA6-4E3F-AFB4-6BCD9A88AA2C",
    "NSI members page.PDF": "50E23335-526D-4B36-8ADE-592FF4C2B8B1",
    "NSI Membership Certificate - Dr Subramanian K.pdf": "1A9284B5-D5BB-456C-A523-4A0A79E33F7C",
    "NSI Membership Certificate - Dr. Somil Jaiswal.pdf": "19192791-7AA5-41B2-9AFA-A06D8AA45A89",
    "NSI Membership Certificate - RNS-65.pdf": "6CD3B9A8-6221-46E8-88EB-2B590747FE77",
    "NSI Membership Certificate PDF.pdf": "5A2F410A-5779-4B99-A5E8-75CEFB4592A2",
    "NSI Membership Certificate.pdf": "43184345-8AB0-499D-A07B-2CBFD99776BE",
    "NSI Membership Letter.pdf": "1A5BFB26-6FC0-4CD5-8651-40CCBAA487C2",
    "NSI membership pdf file.pdf": "4B1E12F6-C711-4B7C-8DF1-2C567F45546F",
    "NSI Membership PDF.pdf": "6187D1C4-FB4A-49D8-8FC0-EA3C146E03BB",
    "NSI Membership proof.pdf": "187D52BC-6AFC-488B-B22E-640E4CC78C41",
    "NSI membership verification letter.pdf": "33911F30-FCD1-40F3-BFFD-AAD1A62691DF",
    "NSI MEMBERSHIP-converted.pdf": "30586615-3592-4597-8B9D-212010EDA841",
    "NSI Membership.pdf": "F6143033-9E61-4855-8CEF-26752ED65D37",
    "NSI Rabi.pdf": "1D0EA907-720F-4037-B54C-560D6B3EE907",
    "NSI-CERTIFICATE.pdf": "AF84D2C4-8406-4CD7-9C45-D574BC1B6244",
    "NSI.pdf": "F4B7696C-134B-4711-BE65-46986CC38858",
    "NSI0001.pdf": "3C1AD657-33D9-4685-9720-91F2E3AA2A53",
    "NSIG - Updated CVI.pdf": "17A469DD-97F4-49B7-BA9C-FCDEC9DD8BA2",
    "NSIMEM[1].pdf": "488999FE-C68C-470B-BF2F-75020AD0C778",
    "NSImembershipcert": "AA8F2DA0-8D56-49A1-A9A0-465F3C46B7FA",
    "NSMedicareSurveyNewsRelease21010FINAL.pdf": "E0164B44-3C6A-486B-9BA9-14C7CF562BE6",
    "NSNavEjectButton.pdf": "3B514319-1A36-42CE-91D5-09E0792B7250",
    "NSNavEjectButton_rollover.pdf": "2D513904-FD99-47E9-87A9-0B47D96A918D",
    "NSofoluke.emailverifications.pdf": "9F9A62DE-B5A4-4E47-A6F0-013F4A40D7DD",
    "NSP Differential Standards Sign-on Letter_02-21-2024 .pdf": "255D60B6-ED16-4B4A-B798-CA86C08F978A",
    "NSRBP-CRLBP HCRU CNS.pdf": "25A9801C-0C3B-4127-B6DE-34F712644C33",
    "NSRBP-CRLBP Management CNS.pdf": "4B6A6DE3-89CD-4E32-A939-8B362F7EEDAA",
    "NSSI Dr Nissar.pdf": "128F7760-3C7B-467E-B9BB-510DC67312FB",
    "NSSI membership.pdf": "51FB6958-7C38-475B-9FA0-F0CF7F67681F",
    "NSSI-A-10[1].pdf": "1CBF6A73-2F71-4B83-8C0D-AB2413C7DC1A",
    "nssimembership - krishna joshi.pdf": "572541FD-D7EA-40DA-9E6F-46610C8B6BAC",
    "NSTP Application 2023 - Year 1.pdf": "605F34B6-EFE4-4597-BA97-9F4868B630A6",
    "NSTP Application 2023 - Year 2.pdf": "1CFDB3D4-B35F-466C-9E2E-74C51C3C1B96",
    "NSTP Application 2024 - Year 1 - Updated 1.29.2024.pdf": "F9DDCDF8-28AF-4606-BAAD-FD3F8E655EAC",
    "NSTP Application 2024 - Year 1.pdf": "819556DE-B54D-470F-9BCA-D916DF3B59D6",
    "NSTP Application 2024 - Year 2 - Updated 1.29.2024.pdf": "5A4FFD25-67C3-4CA4-8305-3B5FF9C844C0",
    "NSTP Application 2024 - Year 2.pdf": "660070DC-0DF5-4B11-B162-BF210E8A80BA",
    "NSTP Grant.pdf": "B5E48E32-2B0D-4725-BFEA-6F6EB87B0226",
    "NSTP MUFTUOGLU.pdf": "92385F45-A957-48AD-BCBF-C8CA94D382BE",
    "NSTP Proposal.pdf": "77E30EF8-D1D1-462B-A1D6-B2A7734BF320",
    "NSTP_Lucke-Wold_Brandon.pdf": "8299BA6B-1119-495D-B900-861F2556F3B9",
    "NSTPApplication.pdf": "F05B415D-1214-4849-A8DC-4D0B7546D296",
    "NTN.pdf": "DDE0CF4C-E77C-4CFB-ACE2-23238A584766",
    "NTSI Membership.pdf": "441C10CF-0A90-48E3-B63D-9285AF84E01B",
    "Nturibi_LetterOfGoodStanding.pdf": "01F4AE1A-4986-4828-894B-5F0E2115FCB5",
    "NTY CV.pdf": "BEDD253C-E35E-46E8-8219-93FFCE4D2D53",
    "Nugent, Joseph - Letter of Enrollment and Good Standing - 12.13.18.pdf": "449620FF-F15F-468E-BDE3-1BA5C85CF5EC",
    "number 2-092622.pdf": "531AF376-B6B0-49E7-922C-CE86E849AC35",
    "NUNNA_CV.pdf": "6767F599-F4B0-4D93-859D-3612395CF442",
    "Nutritional_Support_After_Spinal_Cord_Injury.26.pdf": "CB48745C-0F63-4D43-8603-2C04A507CF4F",
    "NVP-CoverageLetter.pdf": "3853D023-C4AF-4671-8B9C-386BE6F70485",
    "NVPResidencyDiplomaChief.pdf": "7F2BE864-F859-4D97-A897-1FF4F79A1213",
    "NVVN membership.docx.pdf": "670F266D-F7BA-435E-A154-8BD38E188376",
    "NVVN membership.pdf": "41B626C0-DDB6-42D5-BF48-42676EEFA710",
    "Nwachuku Enyinna - Graduation Certificate.pdf": "433897C9-5AFA-4895-97E4-4664C6F21A0B",
    "Nwagwu.pdf": "EDC91B78-1325-4091-82E8-D578E65D27EC",
    "Nwanze_Chiadika_MD20_090718.pdf": "23127294-A56C-46AF-934E-C543B5976EE5",
    "NWilson_DrZ_CNS_Poster_2023 JMZ.pdf": "2891CA36-E591-4DBB-B93D-84EB8F29263C",
    "Nwojo Macaulay.pdf": "121CD829-EE1E-4A61-8E32-FE987BAA4B2F",
    "Nwosu Gerald.pdf": "86C0E7BF-DA59-454E-8CDA-21FFC0B974E6",
    "nyaa564[1].pdf": "F1D694F1-801E-461F-8465-2EC9857A6C34",
    "NYIT Verification Letter.pdf": "152285FB-58A0-488B-AF43-7E4464DE2746",
    "NYT LTE - letterhead final.pdf": "A83638F4-0687-4FFD-9250-827F5C97BDF5",
    "NYT LTE for CTE Research.pdf": "A2778AE0-309A-4A96-B742-DE004933B503",
    "NYT LTE on Head Trauma 002.pdf": "165BE153-9B9A-49D3-B269-AEE06188453D",
    "nyt_lte_-_letterhead_final.pdf": "0E093927-2343-4D22-87F5-4E7CB0DE8E70",
    "nyt_lte_for_cte_research.pdf": "B36367D4-05E5-433E-BBBB-463293BC6A54",
    "nyt_lte_on_head_trauma_002.pdf": "B8D14B6D-AA15-48B4-ACE4-626DF52753E9",
    "NYU Acceptance Letter.pdf": "11CC0869-7D83-4A1C-8671-02A3BB0984B8",
    "NYU Grossman Acceptance Letter.pdf": "17FA1D45-766A-4840-B5CE-C92151643509",
    "NYU Langone.pdf": "6276A3EC-C7C8-4026-9C6D-FDFA9E98EC13",
    "NYUSOM_attendance.pdf": "8ABE4526-6981-48EA-9774-94D869F826ED",
    "NYUTron_CNS_v2.pdf": "162A11C8-0B09-4402-9818-D8E7B676D78C",
    "NZagzoog-emailverification.pdf": "5DF38FF9-5D38-4E96-863D-B74C1EF60DCF",
    "O. Choudhry.pdf": "CF496C2B-2402-4B21-81FC-8ED30EAD7817",
    "O.Alsharif_emailverif..pdf": "6E9B5555-A1A2-4631-9D5B-09BD90C82366",
    "O¨g?renci Belgesi-1.pdf": "61A58431-E236-4CC0-BE86-2AD112AA0E64",
    "Oakes, Jerry.pdf": "B22478E5-558B-4797-B4CA-095CBB8A30AE",
    "oAkinduro.FellowVerif.pdf": "0391B679-1FCD-49F0-BF60-DB710DD8F8D1",
    "OAyling.emailverification.pdf": "5E4B08C7-0485-4AE5-9A38-CD3ADC5F0887",
    "Oberoi.pdf": "460D74C3-131A-4229-811D-478EE387970D",
    "Obiora Carl OkoyeMDMPH_ Resume.pdf": "9941D330-2D69-4300-AE74-B5161F674060",
    "Objectives for 8 wk Neurosurgical Acting Internship (final v2).pdf": "6E22F041-266D-4D85-87FA-73C3F09BE4C1",
    "Observership 2022 FMP.pdf": "21442F88-7ECE-46FE-8B28-8D0601A9FD3D",
    "OC 101020 S Byrne - Confirmation of Training-1.pdf": "5B80A2F4-1D45-40FA-B81D-9C37F834FD08",
    "OC 121101 R Reddy - Fulll Membership Approval.pdf": "D6392414-46E1-4BEF-8242-C37ED62EC0F1",
    "OC 140318 NSA - A Gogos Membership Confirmation.pdf": "4F6C3B26-8516-4B96-95CE-97AFD0B696B8",
    "OC 140718 NSA - H Alexander Membership Confirmation.pdf": "1A35B3FD-53BD-4869-98BC-7259E754B7F4",
    "OC 150727 S Hall - Application Outcome.pdf": "D54483C1-7BAF-403B-9C95-4F563DC3ABA9",
    "OC 160118 NSA - C Daly Membership Confirmation Training.pdf": "D74E0FA8-9391-48D3-ACDD-080342F61D13",
    "OC 160519 R Mitchell - Surgical Education and Training Program in Neurosurgery.pdf": "B4953128-7E6F-446B-85A8-0BE644427D6C",
    "OC 161122 G Shivapathasundram - Surgical Education and Training Program in Neurosurgery.pdf": "B5A95945-E307-4050-9E3F-18EF25AF7547",
    "OC 190627 J Jones - Surgical Education and Training Program in Neurosurgery.pdf": "4781C9C9-53ED-479A-8E1F-60651399DA53",
    "OC 200310 NSA - H Alexander Membership Confirmation.pdf": "F4652710-C19F-47CD-AA27-2A429750E243",
    "OC 210723 L Coulthard - SET Offer Letter.pdf": "F904A0CA-74FE-4695-89D1-1E22964804C3",
    "OC_090803_L_Lai_-_Research_Year_Proposal_for_2010.pdf": "F45CCF95-8A58-4D4E-9B47-88E94157D129",
    "OC_100811_-_Leon_Lai_-_Confirmation_of_Training.pdf": "99094AF7-165B-4273-9BC6-47AE0792591C",
    "Occipital_Condyle_Fractures.13.pdf": "66ABD385-4E09-4F8C-99AF-8C7BE8A478E3",
    "OCF Manuscript_Nwachuku_Table.pdf": "E4489BF6-62DC-4BA8-9B0D-B947874291B8",
    "Oct 19 Doc 1.pdf": "7C5D90DF-2575-4E07-A168-91F68489F72F",
    "Oct 2022 journal article.pdf": "D03C0FCF-4537-488A-9A55-184BCCE61ADD",
    "Oct 2023 article.pdf": "40076C3E-5BAE-454C-9C5F-A76D386E737E",
    "Octavia McKinney  Rec Letter MWinterton 12.2022.pdf": "FDEF2D14-DC76-4E41-8998-FAB024FB7F78",
    "October 2014 EC agenda book_2015-03-03.pdf": "B84CA4FB-DB10-416D-9CED-23FB365D6778",
    "October 2021 journal article.pdf": "09455EED-1627-40AA-B33B-ADDAD055C083",
    "October 2023 HDHP Letter House Leadership - FINAL October 31 2023.pdf": "9E9E3507-5844-4725-A589-7721DBF4C982",
    "October 2023 HDHP Letter Senate - FINAL - October 18 2023.pdf": "31D2474A-0330-47BA-80E7-0B8206CA1630",
    "October journal article.pdf": "C55FD3F8-C15E-47BF-B30B-E870D762ED3B",
    "Oded Goren MD.pdf": "865F7D4A-093B-42C3-8EFB-90389019BEDE",
    "Oded_Goren_CV.pdf": "5653593C-176B-4682-BBD8-40AA83C71F19",
    "Oded_Goren_CV_.pdf": "F5647203-9534-4289-A791-31301981EE27",
    "Odell DRMC Residency Diploma.pdf": "321C9E9C-93C3-41C7-87BD-CF00F59F4680",
    "Odell Tiffany CV 2022.pdf": "F48A70C2-8D1E-48A2-A877-78068073D09D",
    "Odland Unofficial Transcript.pdf": "59AD6341-0E1D-4027-A094-6E000080ADD8",
    "Odland_CNS_Lobar_ABSTRACT_2022.pdf": "013D7E14-D370-4FFD-9392-E7325A3BA4E6",
    "Odland_CNS_NIS_ABSTRACT_2022.pdf": "831E45CF-F70E-4C0E-8F6C-969F6C647A29",
    "Odonkor Michelle LOA MPH.pdf": "73972AF7-B4F0-4ADE-BFC8-AEC58C2BDACC",
    "odusplus-ss.kau.edu.sa_PROD_xwsksdet.p_dispappdet_en.pdf": "4979153B-EFEC-494E-8427-47AB17780B71",
    "OE poster.pdf": "87A2258E-4F99-49B7-96FB-67E1B21E633A",
    "OED Program Information 2022.pdf": "1C757FAA-877F-4FAA-89C0-6D00FD95A0B6",
    "OFC as a predictor.pdf": "2C59B37D-E2E8-4BEF-BCE2-A198BBFA4073",
    "OFC_Modulation.pdf": "83D7787C-F225-4A7A-8D5F-E8F98C999FC8",
    "Off Broadway - Event Listing.PDF": "112A0B91-DB90-4845-906D-830E55770AB2",
    "Offer letter-Atakan Orscelik MUSC.pdf": "DD8EB533-616D-4E1E-9D3E-E5DB0455577D",
    "offer letter-signed.pdf": "72897F7C-FA4F-4651-BE65-F4BDC8DC9518",
    "Offer Letter.pdf": "598F1AEB-2755-41B8-BB7F-920DC75BB1C2",
    "Offer Letter_GPS Yr2_Maxwell.pdf": "B1D42238-8259-4C20-9A45-9B7A91E9B3CC",
    "Offer_Letter_-_CCLCM.pdf": "C8AD856C-426B-4A26-8864-F4CAD695C965",
    "Offer_Letter_6736-Natasha Hughes.pdf": "968A2A54-8D55-4DA8-B82A-50618D73A188",
    "Offered_Letter_with_Fellowship-_MD-PhD_MN_7919.pdf": "BCD7522C-FAFA-45BE-96F6-BCDA9F378902",
    "Official Acceptance.pdf": "7CBE16C5-8416-498A-8FE3-2A6B9A67BF55",
    "Official Active Duty Orders for J. Milton.pdf": "B9D5AB2D-5FD1-4F43-AB36-5154A154B731",
    "Official CNS Poster Submission.pdf": "45E46E8A-1CB8-4EE8-8B0B-AA3C4DF60333",
    "Official Letter from Head of Neurosurgery Department.pdf": "AF7C2D19-8184-445E-80FE-49BD30D53B1E",
    "Official Letter of VER - Adena Zadourian.pdf": "6C9BF226-5046-4C9B-B25D-81782095BB84",
    "OFFICIAL TRANSLATION PAGE 1.pdf": "744AB864-6957-47A3-A820-8CF9EAA95940",
    "oficio aceptación ten 04-Mar-2024 11-07-41.pdf": "34E55D3E-9171-42AC-8BA8-047E45793813",
    "Ogando-Rivas Complex.pdf": "1258D87E-92CC-4882-858F-43EAB5F47223",
    "Ogando-Rivas Hydrocephalus.pdf": "3F2968D7-0DA8-470E-8CF2-0686A06F20CC",
    "Ogando-Rivas_FWLNShol_2021.pdf": "83FF18D2-AFD2-4940-8389-4E62D885DA52",
    "Ogando_Rivas.pdf": "383358F4-EEA2-48D1-932B-B817E298A34B",
    "Ogilvy_Aneurysm salvage surgery_CNS_final.pdf": "AA4CCDD9-3241-4F60-8391-025676C55D13",
    "ogrenciBelgesi_23f71ba377b84fc094a02856428a3907.pdf": "B919E307-1A6B-45C5-9907-A8BE91E77712",
    "Ogunlade CV.pdf": "258EB9F1-E531-4238-A9B5-C877681F1453",
    "Oh - VER.pdf": "4B644997-D5B9-4CFD-A983-736C1F3B5B03",
    "Oh.pdf": "777882D2-70F3-4023-963B-F3B43AB87100",
    "Oh_M_CV_Sep2021.pdf": "0222E974-475E-44B4-BD1A-3FD4836B7D78",
    "Oh_M_CV_Sept 2022.pdf": "97679E39-A599-44E6-80B4-77EE87DA2F8D",
    "OHBM Poster.pdf": "B8FD5E92-3A09-42DB-B240-BBD5C8E1B95F",
    "Ohene-Adjei.Michael.Letter of Good Standing.8.28.23.pdf": "AD2D7A09-4702-48E6-BF3D-DB00CC5589AD",
    "OHSU Neurosurgery Residency One Page 2024-25.pdf": "70EE7FC4-1B02-43F0-A50E-5873D59E6021",
    "OHSU Neurosurgery.pdf": "C87ECBE5-8141-4CBB-AF63-48BA53DE73BA",
    "OIGAdvisoryOpinionRECallStipends_20071001.pdf": "08923E02-1B91-4B6F-A4CD-F027343497C2",
    "Ojemann, George.pdf": "148E7616-6C98-4EAE-BB0F-2F0860F0B236",
    "Okai Bernard  - LOGs  Enrollment Verification .pdf": "C6710E48-CCFE-4ED6-8EE2-E33FDC57A96B",
    "OKI-Orders-IKEDA-2019-Modified.V2.0.pdf": "A159F262-1CC1-4172-BC14-5F85D7778C7A",
    "OKMC residency certificate.pdf": "249F625E-EE8E-4FD8-A90B-407B59F7B8BF",
    "Okonkwo SNS Nomination.pdf": "F29771AE-3C0B-4319-B92E-6EE0AC1B9A0E",
    "Okonkwo_Treatment of Hypotension in SCI - 2024-04-17 v2.pdf": "45922A26-C376-4488-9877-5AB0EB0B97B8",
    "Okonwko_Spine Stabilization- When and Why - CNS Emergencies Course - 2024-04-17.pdf": "7C693AD3-A805-4D4A-BD45-500F474B4456",
    "OKOYE_CHIBUIKEM_FRANCISJOSEPH_Admission_Letter.PDF": "7BCA02F3-425E-4272-9704-5C75F2405279",
    "Okun DBS Registry_USA 2024 ASSFN_Poster draft_FINAL.pdf": "273C1E01-3BBE-41F2-8B89-9AFE1DAAC3C4",
    "Okun Prospective DBS Outcomes CNS 2022.pdf": "D0206EC0-7CCB-453A-89ED-097A9F4BFA42",
    "Okun Prospective DBS Outcomes CNS 2023.pdf": "11874718-E3F2-47ED-8F4B-9D80C78881DB",
    "OlatilewaAweCV_2018.pdf": "BE7064BE-6E02-4420-8B0A-61879C2FFBCA",
    "OligosCNSAbstract.pdf": "9833B86A-E2CC-492A-9683-C1BE65B1FA8C",
    "Oliver Tang CNS Verification Letter.pdf": "C384D6C2-FB79-477B-A255-C965A4A10280",
    "Oliver_Flouty.pdf": "6B9B9D55-39EB-47BE-BC64-49350DDFB15E",
    "Olivi, Alessandro.PDF": "16060C35-F95C-424A-92BB-07293A277E40",
    "Olmeda Barrientos Robert Good Standing.pdf": "C05093B7-675B-408E-887B-FB688609459F",
    "Olszewski CV 2023.pdf": "D1278D53-45DD-4FCA-A96B-04A2078974A3",
    "Olufawo Enrollment Verification Letter.pdf": "2B640854-22CA-4AB9-BB90-189533273B5F",
    "Oluwatomi Akinduro 8.24.201708242017145056.pdf": "BF26D2D2-E9B2-429E-BA2E-E4839612E1BB",
    "OLVM.pdf": "58E7F501-81A4-45F1-BB77-6503FA706B2F",
    "Omaditya Khanna-FellowVerif.pdf": "FF754208-A799-409C-9E8E-A45FB0AC730B",
    "Omar Hazam Al-fareh.pdf": "7EF82184-6F40-4BDA-927A-7092E693220F",
    "Omar Nabulsi.pdf": "DECD28B8-0C4A-4A0F-A86E-24F318451A5B",
    "Omar Tanweer CV 3.1.2021.pdf": "78398ABB-7AC5-4196-BA9D-87F34814B693",
    "Omar Tanweer CV 9.16.2023.pdf": "372B4329-AD2A-467B-A40F-8E527EDFC629",
    "ON-189 SVCC Register Now mailer 10.pdf": "CD166BE1-24D5-4552-983F-3758E4C1EE9D",
    "on-call_position_statement_final.pdf": "9C7A91FF-750A-4822-9815-2299FCBD9B5B",
    "Onboarding Toolkit - A Supervisors Guide.pdf": "E8910AD3-49A3-41AE-841D-C57C7AFE11D0",
    "onc_interoperability_comment_letter.alliance_of_specialty_medicine.060319.pdf": "454DB98C-77EF-4053-9D95-D135CAA592DD",
    "One Neurosurgery Summit  Professionalism and Harassment Model Policy FINAL 120420.pdf": "672EE26E-3DDF-4E6F-AE06-05DABE0B1837",
    "One Neurosurgery Summit NINDS Strategic Plan Letter 031521.pdf": "CDDE7398-8B43-48B6-8AA7-D2681221673C",
    "ONeeley_verificationemail.pdf": "B8BBB651-AF9F-41E3-A602-618CA4B4968E",
    "Ong-going employment.pdf": "BE66C143-4A7F-49A6-9A45-89148DC974FF",
    "Online Prelim Edited 2.pdf": "6E60BCBD-A14C-49D8-8618-05FB1A752A00",
    "Online Prelim Edited.pdf": "86499CB7-3374-47BA-AAC4-6878B086B896",
    "Online Webcast - Defending the Integrity of the Biomedical Literature.pdf": "DEDBEB98-6306-4E9C-8589-C4986ECCCE81",
    "ONS logo toc.pdf": "5365095E-CF20-4B4B-B24E-C41B38439E9E",
    "OOkoye_Appointment Letter.pdf": "3D813E6F-A960-41AB-ADAD-B2A0CFCE1608",
    "Opalak CV.pdf": "FC5297A6-7F4D-4EAD-ACBD-AC323280D649",
    "Open vs Radio Abstract Final.pdf": "171E08B7-A474-40D1-AA06-9202EB9B0580",
    "open_payments_educational_materials_cme_letter_final.pdf": "5BC24921-5F5F-4E2D-BB3C-936F98861E43",
    "openpaymentsregistrationinformation.pdf": "D0B57E66-4610-4C17-A9BE-2BB4C9A654D4",
    "Operative DISH fractures_HD_Figure 1.pdf": "BA7BAE57-6656-4580-ADDE-EA23974CC932",
    "Operative Failure of Percutaneous Endoscopic Lumbar Discectomy-A Radiologic Analysis of 55 Cases.pdf": "041D054C-B221-4373-BD8E-57FCE1D90DC5",
    "Operative vs. Nonoperative Level Measurements.pdf": "E407E0EA-AE60-450F-A094-9D29166F7613",
    "Ophthalmic-Poster-Visual-1.pdf": "DF4C9E85-7E85-49BD-8670-DA7BF82612F3",
    "Opioid Funding 114th Congress Close -- 11282016.pdf": "8886B183-D4C5-4EB5-B80B-8A88992A7C48",
    "opioid use MSSIC poster .pdf": "E5DBCC8E-593A-4820-9AFE-64A015393C0E",
    "opioid_funding_114th_congress_close_-_11282016.pdf": "EC61D4BD-A577-48AE-B8F9-1E4FAA653ED8",
    "Opioid_NIS_Tables_ACDF.pdf": "3A243A0D-6356-48FE-8FA9-9ED691EDCFD8",
    "Opioid_NIS_Tables_PLF.pdf": "7423D130-29C1-4B9C-A5D4-85093862160E",
    "Opioids 2012.pdf": "5BD7AB30-A8C5-4EDB-8EA0-9A3AE87FAF46",
    "Opoku-Darko Verification.pdf": "1348C77E-771D-4DFE-B77C-E8081AFF254A",
    "OPPS-ASC 2021 PA 2020.10.30.pdf": "443F36F7-5EB4-42B5-B26C-6B6F5BB2FF8A",
    "Optional Recommendation Letter_Alterman_Stippler.pdf": "52C2EE13-3D1E-450D-861C-029D9521AF1B",
    "Optional Recommendation Letter_Britz_Liebelt.pdf": "8777A26C-1BC6-4856-A151-CD2994623D67",
    "Optional Recommendation Letter_Chambless_Shrivastava.pdf": "4D72EB44-C863-4174-AE16-B0411A7148AB",
    "Optional Recommendation Letter_Hadjipanayis_Shrivastava.pdf": "D248BAE8-069C-413F-9E52-3EA784D3B4D5",
    "Optional Recommendation Letter_Hoh_Pandey.pdf": "A7BB720D-6F6B-49D0-A996-89ABF788F028",
    "Optional Recommendation Letter_Huang_Riesenburger.pdf": "E629349B-478F-4A6E-9B65-3026C24ED659",
    "Optional Recommendation Letter_Huang_Shrivastava.pdf": "A2434471-D37B-4C18-B95F-AE87038244F0",
    "Optional Recommendation Letter_Limbrick_Heth.pdf": "08946696-5040-438E-AB86-056F46328475",
    "Optional Recommendation Letter_Maher_Heth.pdf": "756E8D2F-82CA-4405-A678-A977BBEA385F",
    "Optional Recommendation Letter_Robinson_Pandey.pdf": "7896F744-C5F9-4B42-BEEB-DDEC961A914B",
    "Optional Recommendation Letter_Selden_Pandey.pdf": "6D51EE01-44C6-4E53-88D9-68E4DA00118F",
    "Optional Recommendation Letter_Wolfe_Heth.pdf": "19D36529-A21E-49A5-B972-E986C236BEF1",
    "or plot 6 month.pdf": "96DA3FB5-0AA6-4F1E-80D6-CB8B3E2B1996",
    "or plot discharge.pdf": "D9571AB4-3663-4B2A-AD5B-88843751A5D0",
    "Oral Boards General Sheth.pdf": "0A916077-6168-428B-B479-BF119797A5B0",
    "Oral Boards Neurology Germanwala.pdf": "474651F2-44CA-4ABC-9F6F-D7F929C70D7A",
    "Oral Boards Peds Bragg.pdf": "878301B9-8F20-450C-A03B-D13740BAEC02",
    "Oral Boards Peds Cases Hauptman.pdf": "BBEBA503-470C-4120-8EA0-63AC10958B4B",
    "Oral Boards Peds Hauptman.pdf": "928FE371-FBB1-4FFC-AFBF-C1CA52F73AC5",
    "Oral Boards Spine Ropper.pdf": "E1E1D282-31A8-4AD3-95CC-8C450F43D704",
    "Oral Boards Trauma Kitagawa 1.pdf": "432AA20A-58A5-455E-9840-BD9E39D4082B",
    "Oral Boards Trauma Kitagawa 2.pdf": "4AF1C7A2-BDB4-4E40-B433-BE40ABC6999B",
    "Oral Boards Trauma Raksin.pdf": "A9939AE3-D818-4178-A1AD-95A5F54EC608",
    "Oral Boards Tumor Barkhoudarian.pdf": "12063D29-8350-48AC-B8F1-F3A2F4714BA9",
    "Oral Boards Vascuclar Tjoumakaris.pdf": "3BB531D7-18D7-4BD4-B67B-9C87590F8CBE",
    "orbital growing skull #.pdf": "E2E4D619-3224-4F9C-8520-F7A793B8B2D6",
    "Order 20220207-0185_Paid.pdf": "C4D13226-0F88-4B08-9703-D275E2183C67",
    "Order Detail 20210201-0007.pdf": "F9009714-B317-429E-84C5-30E75F554FD7",
    "Order Detail 20210725-0006.pdf": "6E7F42C4-5F3C-49D7-9DD7-74BB6467E6DA",
    "Order Detail 20220122-0038.pdf": "5FB78EBB-6C91-49E0-9B18-5F52CD146D84",
    "Order Detail 20220404-0007.pdf": "5F60F443-7003-4197-A12D-93DF70C41FC6",
    "Order Detail 20221024-1311.pdf": "B7247963-7110-4393-9082-FFBE48F12D45",
    "Order Detail 20240523-0013.pdf": "2BE44C42-B102-47F7-B193-81FF424595A7",
    "Orders 1 Voucher.pdf": "722388AC-D300-46E8-82F7-1C6270930D8C",
    "Orders Deployment Dec 2019 -redacted.pdf": "F625E059-27C0-41D9-8143-345A94F80AC4",
    "Orders for CNS.pdf": "0B549811-1265-4F7F-8485-9C14AB1A0FE5",
    "Orders HAWKSWORTH (FT. BLISS) Edit.pdf": "5EFE6AB4-12FE-4863-8619-D700FA1FB858",
    "Orders LCDR Taylor.pdf": "827D682A-CE9B-4397-A7C2-D967A1ECDC8A",
    "ORDERS PDF.pdf": "9DF6DC42-02CD-4C56-93AB-FF562F7200DF",
    "Orders.pdf": "08B0DA9C-7ED3-4B5E-9AAC-83ADF6A67B00",
    "ORDERS_Morton_Ft SAM.pdf": "A0DF6492-2925-4414-8480-4F85ADE03CD5",
    "Orenday.pdf": "EE63C13A-3F87-4F4A-B0F6-B66261D8A50A",
    "organiz mgmt lessons learned.pdf": "9184094B-6828-4619-857C-8A5AA61516F3",
    "Organization Letter to CMS on Step Therapy_4-22_FINAL.pdf": "D2D6C840-7F19-4DCF-BADE-12F6B33E216D",
    "Organized Neurosurgery Statement on Events in the Middle East 101323.pdf": "D582F19B-98D8-421E-99B7-ABB4B062CE91",
    "Orgest Lajthia_Good Standing.pdf": "88D70B45-8C2D-44CE-A6CA-3CEBA830EA30",
    "Orillac Cordelia CV.pdf": "F0A31DFD-5E49-4126-B0A4-9369F45470BD",
    "Orina_Josiah_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "6F6C8E9A-2A32-4DEB-9471-543323922FDA",
    "Orina_Josiah_2024PositionalMembershipApplication_CVandBiography.pdf": "3BC2E11E-8ACB-4EBF-BA7F-D050AE8A4320",
    "orlando 1998.pdf": "E1C2174A-EA2C-4305-A6AC-1F2525159AE0",
    "Orlando 2016 Agenda and Minutes_2016-03-16.pdf": "AEA78188-8621-459A-9656-79C451042C37",
    "Orofacial_severity_2021.pdf": "F1076DF6-DF1A-4A54-A815-E947BCA283D6",
    "Orr - Letter in Good Standing.pdf": "5F351609-6E17-4B45-85E2-8D48D601ACDC",
    "Orringer_CNS_EC.pdf": "4BB5EA12-5418-45E7-93F0-578886413A6F",
    "Orscheln_2021 CV.pdf": "D0998078-B613-469C-BA3E-8301BDE8A49D",
    "Orton Cody - Good Standing Letter.pdf": "584A15B1-5E78-4D89-B3FB-277012BB6687",
    "Os Odontoideum Poster PDF.pdf": "4EF09F9D-32D0-458A-9249-97462F55DC0E",
    "Os_Odontoideum.18.pdf": "CDDF1987-0644-41E3-84F9-1C519587CAB9",
    "Osama Aglan CV Nov2021 .pdf": "51924C9B-110A-4F68-ADA7-7EA5048D5FA2",
    "osha-participant-guidelines.pdf": "94718179-E72D-4943-BF67-C39FA45C8159",
    "Osmani Wasif - Enrollment Verification  Academic Standing Letter.pdf": "70120C4A-283E-496A-87B9-0A0DDFF7F6AC",
    "OToole Francis Ed good standing letter 03.19.24.pdf": "A24ED74A-54B8-4280-AC2C-3BEF8582B90A",
    "OUMedicalSchoolAcceptance-SpencerOslin.pdf": "FFBB2D57-3F25-44AD-BE8F-150889E7628C",
    "out_of_network_consensus_principles.pdf": "B58D6FD6-6B53-4315-9337-D9DA8CC40734",
    "out_of_network_consensus_principles_0.pdf": "83D0B390-E958-4723-BF7D-280F90772180",
    "Outcome of Proximal Ventricular Catheter Placement in Ventriculoperitoneal Shunt Operati.pdf": "5E644A8C-4481-40C4-9491-91D90F6949E2",
    "Outcomes after reoperation - 3-yr results_poster_08-08-19_reduced.pdf": "5534A627-EDF2-4F3D-AD0C-69FBA593446A",
    "Outcomes and Healthcare Utilization.pdf": "4A477EDA-3F37-4AD4-883B-3EAD97FAF413",
    "Outcomes in SCI Poster.pdf": "B1B92055-3BDB-4717-A74B-5C3B13E58D20",
    "Overlay_wo.pdf": "51FF2A41-42FC-46AE-BD2B-AB1AE2F0D249",
    "OverpaymentsComments.pdf": "B894DE5A-551B-49A1-99FC-1C8A8E9A4F31",
    "OverpaymentsComments_0.pdf": "5F051415-9994-4EDB-B1A6-654527CDD46D",
    "overview_of_2013_qrurs.cms_slides.10.2014.pdf": "7034EEC3-C421-4BB1-A7D8-22E4E78286AC",
    "overview_of_coverage_policies-2017_and_2018_11-18.pdf": "E8229E2B-7CD6-4787-AFAB-411DA9B160F2",
    "overview_of_final_stage_3_rules.pdf": "911D437A-8F39-4CFE-A5E9-621AA21DEDB2",
    "OwenB GSL.pdf": "A88EB330-B495-40A2-BBB3-0EBB3488356F",
    "Owens Monica-Rae - Letter.pdf": "BFB39091-F234-418C-B28A-ED3A6CE87FA2",
    "Owiti Verification.pdf": "B78C6D84-4FC5-45FB-AED9-CF7358AD35BE",
    "Owolo - Letter of Good Standing - Jan 2023.pdf": "54311BD2-186F-4D5D-AA56-6F5863D47210",
    "Oxford Enrolment Certificate.pdf": "C97A13B7-7CD3-4CA2-A018-DB06BACB6D22",
    "Oxidative Stress.CNS.pdf": "A07D6184-05A0-4924-BBC2-209A9FA527A6",
    "Oyesiku, Nelson M.pdf": "0D48D009-DCCA-4E3D-8C78-33CCAC7CFCD6",
    "oYq4fRDtu1U.pdf": "19EBDAF5-1AD0-4402-B93A-6F73DFEACADA",
    "OZCV.docx.pdf": "5D2B6645-BA62-4BFC-89B9-2FCE4B78448C",
    "OZCV.pdf": "AA2395A1-B867-4C07-9496-3CF3116AC970",
    "Ozge Vural CV.pdf": "B1EE9590-1577-43F6-9880-E53A95DDA031",
    "Ozge Vural VER.pdf": "6F8F50EB-61FB-4FAC-9904-F960892534B1",
    "Ozobu.pdf": "61B83163-2B4B-4506-A696-64325855FA68",
    "P Fossa LITT CNS 2021.pdf": "301C7A9A-93EC-4EFD-9051-BE614D60B414",
    "P. Halloran LOGS.pdf": "0555366D-2A99-4B14-B48C-B4611C358391",
    "P. Joseph Letter.pdf": "19479293-55E4-4A0D-A514-6FCF7B9727E4",
    "P.Lozier.Informations.pdf": "03F9FEFE-7A90-4694-A1A9-21068646764A",
    "P.Pair.pdf": "6A47476C-4840-49F5-8280-876108335DAF",
    "p.pdf": "D8AEEFF6-E9E6-43B4-8F36-95838FDDAF6D",
    "P.Tsai WRC Enrollment Confirmation letter Oct2023.pdf": "01D652D8-02F4-4B91-A2DD-309D1A8C2518",
    "P_20160809_123449_1 (1).jpg.pdf": "23400F00-9C64-478C-A912-17D739E9D0B2",
    "PA Nomination - Weill Cornell- Neria Douglas- 02.10.2023.pdf": "7912C77C-4FB4-4F1B-90D5-D6A6DF6270A8",
    "PA Resume-Megan Wienecke 2021.pdf": "1103908F-5512-437B-B0A0-BD1D67627062",
    "PA sign on letter Part C and D rule 003.pdf": "7659891C-2579-4412-9274-8D52D8009377",
    "PA Tables Final (1).pdf": "AEBF1E31-2932-49F3-8A0B-91DDB542D10A",
    "Pabaney Aqueel CNS LOR - Barrow-Cawley 2.15.2021-1.pdf": "C93A89E3-52A3-4473-9C6A-1ED205AD1C47",
    "Pabaney Aqueel CV 2020.pdf": "7483CB3F-C284-4A79-B008-52ABEA63EECA",
    "Pablo Ajler MD.pdf": "CD705D9A-FF8C-4C36-AF69-646F13F07D2A",
    "Pablo Gomez Letter of Recommendation - IHernandezcns.org.pdf": "7A3AD1FD-6455-4F1F-8FB6-286A750EEAF9",
    "paca_support_to_goodlatte_-_hcla.pdf": "824DA9DB-2C13-4E44-85E3-61B5D0468DBD",
    "paca_support_to_speaker_-_june_2017.pdf": "C6C65029-5ED7-4EF6-BAF4-688C18E368D9",
    "PACU Hold CNS 2019 Poster.pdf": "E3DECCBD-3C7E-4FC4-89B7-00F0BCB2B93E",
    "Pacult Cover Letter.pdf": "1F24E7CE-0A14-432C-AEEF-F528FF276299",
    "Pacult CV.pdf": "7F8DAAD5-905E-4F46-B71E-64AE0210052A",
    "pagesfromcnsq_10winter.pdf": "3F7F50F9-12D4-40C8-8705-092952BD465A",
    "pagesfromcnsq_12fall.pdf": "C94B1331-1848-4191-AB77-B91D03AA198C",
    "PAI and Physician Organizations Amicus Brief 3-20-2024 TMA v. HHS 5th Cir no. 23-40605.pdf": "7E31C08F-F022-4B1D-94AE-743CE3E172A3",
    "PAI-Neurosurgery Press Release - Amicus Supporting AMA and AHA - final.pdf": "85E4D42C-13F1-48FE-A19F-2A71B99305CF",
    "PAI-Neurosurgery-Specialty-State Amicus Brief in AMA-AHA NSA Lawsuit 010722.pdf": "52C8BB75-0694-48D7-8202-724EB9014940",
    "Paige Lundy-emailverification.pdf": "A812BD21-0E6D-4376-B089-7685C0785E34",
    "Pain 2020 Exhibitor Prospectus and Support Opportunities.pdf": "C43BFAEE-5982-4CB9-ADDB-CA462C93913A",
    "Pain Section Symposium Agenda for marketing 1.12.24.pdf": "4CC5586A-E445-4147-AA74-393C960D693E",
    "Pain Section Symposium Agenda for marketing 11.2.23.pdf": "8099D440-19D9-4BFC-AAE2-BE2EBCB4CDBB",
    "pain-mgmt-best-practices-draft-final-report-05062019.pdf": "C324C01C-5830-42D9-99B5-B88B6FDEF927",
    "Paker_Berkay_ CV.pdf": "7F5FD8AD-408D-4499-9F59-25D6D9ADAD71",
    "Palan Mihir -  Letter of Enrollment and Good Standing - 7.11.23.pdf": "6CAAD9CB-3ABF-4D28-B5AC-E6DB36BAB46C",
    "Palejwala Verification.pdf": "AB32ADAE-F234-4C5C-AEE5-70897F8D4D8B",
    "palliative care.pdf": "4BCEFD87-C678-426B-A4E1-700A1E4A3BBD",
    "palliative care3.pdf": "0BC2F3B4-AA1E-4FBE-9C1F-47E4A046C818",
    "PalomoJoslynnCV_8.5.2021.pdf": "C07966E2-C7EF-40D9-99C9-AED3C5A53A3C",
    "Paluzzi, Jason - Verification Letter.pdf": "35B5E48E-736B-4F1A-BF83-F924491C55D2",
    "Pam Lane 2021 CV.pdf": "18682974-2CA2-4191-9A4F-8669BC0D4062",
    "Pam Lane Packet Society of Neurological Surgeons 12 05 2023.pdf": "FEBA287F-0C7D-47F3-8EF7-170DA6B39346",
    "Pamir, Necmettin.pdf": "3035CE87-0DF3-40BB-AAA7-F04C7FBF5B5B",
    "Pampori, Adam.pdf": "57917E63-7B2E-4FEB-B6B8-D9D27D84F7E4",
    "Panagiotis Mastorakos-RESverif.pdf": "0200B5E2-926E-4516-ADE1-441EC1CD4C54",
    "Panama Board.pdf": "F678033D-7D40-4D33-9D74-F161A6A872B6",
    "Panayiotis Pelargos CV.pdf": "EDD246C2-A752-45A1-859C-5F0830EDC93D",
    "Pandey Abhinav_MD FT letter.pdf": "F9ACF0BB-FD63-4B42-8431-EA0327A6B912",
    "Pandey RobinsonSNS 10.17.23.pdf": "5670A852-FB36-486F-92F2-6883B7198C89",
    "PANDEY- CohenLOR- OCTOBER 18 2023.pdf": "A8E9FF02-4E9A-4856-A15B-562DD007AD8E",
    "Pangal_CNS_Poster.pdf": "50B25E02-CAD5-4C63-88F2-0D8B634A17AF",
    "Panov Slides.pdf": "E335C778-173C-49C9-953B-93A30126C899",
    "Paolillo, Allegra - LECOM Good Standing.pdf": "7FD3D39E-5F85-4A5A-8277-61FDE40A296F",
    "Paolo Offer letter_3.1.2022.pdf": "87A25C41-0079-43C4-A5E5-BEF6255A3C88",
    "Papanikolaou.pdf": "876FB12C-D447-4181-9A56-2FB3ADD695F9",
    "Papanikolaou.pdf.pdf": "88C60254-8DB2-4463-AB8F-613DB4970E3A",
    "Papaverine Poster.pdf": "736049EB-EEB9-4742-AA8D-6795BD0CAE10",
    "parafalcine meningiomas.pdf": "C5A692C8-CF9A-4CE8-A38F-B00FBCB86FFE",
    "Paralysis TBI.pdf": "CB0B0663-3171-40D5-8F13-034D16FFD7D2",
    "Parastou Fatemi-FellowshipVerif.pdf": "7409E538-156D-4747-B48E-69F6100D6C18",
    "Parchure AAC Amygdala Poster v2 for CNS.pdf": "7222F0BB-FAFC-44A1-9C74-FEE90A51088F",
    "Parchure Shreya Ltr. for CNS.pdf": "D345DC69-E9A7-4E1A-AC64-803E0CDBC125",
    "Parental Factors_poster_final.pdf": "5A4BECD7-3FB9-4A8D-BCD8-F47D5CC64061",
    "Park -Verification Letter - MD.pdf": "5A5C8801-DB63-40B0-ADB3-F812533016AC",
    "Park Certificate of Membership.pdf": "3F612F6E-BA77-442B-8E80-C9E93B33CFDA",
    "Park CV dtd 11.09.21.doc.pdf": "EA156B82-F9D0-4064-BD2C-55E7188331ED",
    "Park Michael_SNS Membership LOS_Sept 2021_Neimat.pdf": "19A800E9-9429-45C4-A8F4-32D63642EF9E",
    "Park Nomination Letter SNS 2021.pdf": "18E6EBEF-DF87-4AEB-B350-38E2363EDE41",
    "Park, T.S. .PDF": "01373D86-79F2-4733-8D51-F851157A0F84",
    "Parker Jonathan Standard CV -.pdf": "1A35EF56-2065-42F1-95E7-FBFEF09A4092",
    "Parker_CNS_Abstract_Figs.pdf": "55D52700-7582-48B9-9DED-60176EF2AE65",
    "Parks SNS_Doberstein.pdf": "ADE17714-905E-48E3-925F-1BCC4F5CD2CB",
    "PARR_Pro Debate Central Cord.pdf": "C30C8279-A518-406C-99F4-DAE31A9B13A4",
    "Parr_SCI Assessment_Whats the Level_Final.pdf": "0516CCAE-4771-492C-BA6A-1F7E93AB85B0",
    "Parra.pdf": "5D4054B5-E1DE-4ED9-9C65-970D7BB77C53",
    "Parth Patel Good Standing certification.pdf": "894589B0-292A-4C9F-B053-77F64D3B5D26",
    "Passer verification.pdf": "0291FD1D-8FFF-4A9A-958C-18E127892F3C",
    "Passman Justin-2025-LOG 3 10 22[68].pdf": "62B82A83-035D-4CA8-93B7-456D5F511A9E",
    "Passport abdulghani.PDF": "67CC7716-7E9A-4384-A664-3B6427F15DB3",
    "Past Nominating Committee Members and Years Served.pdf": "291BA199-3836-481B-BEFC-A34FE705785E",
    "Patel Aneek-Enrollment.pdf": "DA42C916-52AE-42F3-BDE0-84096CB6504F",
    "Patel CV 7_2021.pdf": "907F7D7F-4630-4DFC-AA97-2D7A6B2E7DED",
    "Patel CV_JUL21.pdf": "C8C0D127-A2C9-4124-8F89-9A3654B39946",
    "Patel for Frankel.pdf": "932E1590-3BAE-4598-8654-13F9A93F73EE",
    "Patel LOR_10.5.22.pdf": "B24DFEB4-E244-4AB2-8358-1EC534E9D61F",
    "Patel LOR_10.5.22_Hoh.pdf": "86106785-8A60-4762-96F8-3065C7F242A3",
    "Patel SNS letter 2022.pdf": "0F68D60B-F4B5-4A93-8FDB-5FF7C9A8FC22",
    "Patel SNS letter 2022_Rao.pdf": "52E25495-DD3F-4078-9F64-873231E155B9",
    "Patel Verification of training CNS 2022.pdf": "32C1D408-8856-49C3-87C3-38B669A775DC",
    "Patel Z - Confirmation Ltr.pdf": "89A19F43-4796-4BF6-9FBA-31F07F06C68F",
    "Patel, Yatindra Enrollment Verification.pdf": "0DDCCAF4-D713-4C2F-BF24-CFE724AE06E7",
    "Patel-Letter of Good Standing.pdf": "B54497D6-4395-4581-ACB0-A7FE69C59961",
    "Patel_CT_GAN_Poster_CNS2023.pdf": "E31368EA-9F5D-4405-8EBD-4C706C015B68",
    "Patel_FWLNSchol_2022.pdf": "FCBE5AE2-95FC-4524-BD92-28FD2C39A60D",
    "Patel_Gene_Therapy_Poster_CNS2023.pdf": "952644C0-A870-498D-9373-FCFF916B60ED",
    "Patel_H3K27_Poster_CNS2023.pdf": "DBC0DF48-ECA4-4141-9068-CE9518C33F35",
    "Patel_Head_Bleeds_Poster_CNS2023.pdf": "836A32F1-563C-4614-807C-67995F41A503",
    "Patel_J Enrollment Verification.pdf": "2D9FE1D4-23CA-48CD-808E-4BE51E539950",
    "Pathoma Runtimes.pdf": "3A6BBDB6-46E5-4AA7-B03D-4789E69AAD0A",
    "Pathway to Neurosurgery Week_23.pdf": "298309B7-1087-49CD-8C75-0F341FB94929",
    "Pathway to Neurosurgery_Description and Agenda.pdf": "A3F77B75-FBEF-404E-808A-73C775E45D4E",
    "pathways decade of the brain NL.PDF": "15BA5B91-18EF-4F43-A200-B62ADB504FCC",
    "Pathways to Neurosurgery Program_infographic.pdf": "E05EF77C-3712-4543-839D-BF06436CF3AC",
    "Patient and Treatment Characteristics.pdf": "987BA68F-9566-4ABB-ACC4-5F74316C1655",
    "Patient characteristics.pdf": "9B7C5D29-A521-4452-88B4-BD9BFA350283",
    "Patient Demographics Table.pdf": "32D4129C-A5ED-47FD-9F51-92E9C3AB6DCA",
    "Patient Education - CNS Poster.pdf": "3BE2172D-97E9-44A1-AF22-645AD4E1B2EE",
    "Patient Satisfaction MIS Open.pdf": "7B7D04ED-54E8-4F80-8165-5C4815EA654F",
    "Patient Satisfaction Poster (1).pdf": "1EC30CE0-EDE8-40FB-A639-3601B4CA7E60",
    "Patient specific factors that predict.pdf": "70A6E31D-F749-40FF-9C94-9E1B0933137F",
    "Patient- and Hospital- Factors Associated with Increased Admission Costs after Posterior Spinal Fusion for AIS - Peds Joint Section 2022 Poster.pdf": "A095CF0E-F1CF-4329-A4DA-10C85974A7C1",
    "Patient_fly-AANS-CNS.pdf": "2C786C32-8FFA-43E4-AEC1-91A7AB532171",
    "Patient_Survey_Report_Final_2014-1.pdf": "FF69F3DC-1406-4F20-A640-22EBE76D2793",
    "PatientFAQ-AANS-CNS.pdf": "49F3738D-68CC-4B79-820F-194FB3E8CEF8",
    "PatientProtectionAffordableCareActNewsRelease112009FINAL.pdf": "FD4AD73A-B193-4793-B086-DDA51AEE5A34",
    "patientsatisfactionspinetable1_25april2020.pdf": "2D9C589E-5904-4F5D-AA0D-12071FA9BACE",
    "patientsatisfactionspinetable2_25april2020.pdf": "AD265EBC-5458-433B-96A3-85E759A0E13C",
    "Patrick Belton-RESVerfi.pdf": "72168329-711A-402B-9DB4-DA6BEDC284AB",
    "Patrick Karas LOGS.pdf": "8066ED4B-A90C-44C6-B8EF-F6A3709C49E2",
    "Patrick Kelly-RESVerif.pdf": "DECDB77C-3B2F-4DE2-AED4-3FCFAD5D7ABE",
    "Patrick OBrien-FellowVerif.pdf": "F9C078E1-8523-4345-8CD9-78D8BA35800E",
    "Patterson Russel.pdf": "2D7D1381-255F-4CD4-86B8-4A178CF303D9",
    "Patterson, Russel H, Jr.pdf": "4ACA5C5E-372C-4ED8-B81F-077F07CFFBCC",
    "Patterson, Russel.pdf": "1E3ECB3D-B7E1-4EDF-9D66-115BEC915062",
    "Patterson, Thomas. Ltr of Good Stng..pdf": "9DA58E47-E6B8-4122-957E-DE9EF2FBAF17",
    "Paul E and M Code Bill.pdf": "92D53C24-F7D7-40B9-BBBC-7ECF39426062",
    "Paul S Page CV.pdf": "651D24D0-6212-406B-9E90-496AD508FC0A",
    "Paula Beltran  Recommendation Letter.pdf": "79345386-6EA6-4C97-B4D4-4C5A03C698C5",
    "Paula Wilson Aug 2020 -CV.pdf": "D5E76FC0-BF6A-43F4-9F61-E1C9DB931621",
    "PaulGlobalSupportLetter Final.pdf": "B5E3533F-B38F-41B9-B49E-6C0A09AB81C1",
    "Paulo Tabera Tarello - Fellowship 2025.pdf": "4D572F76-43A4-4808-A697-368AD5800652",
    "PAULO_Frishan_Letter of Good Standing_2.29.24.pdf": "CCB54B30-070C-4838-B1B0-8A0BD3EE6308",
    "payment curse pdf.pdf": "C9D8A402-24D0-46BA-8D94-1046FE89FAD3",
    "Payton Tayler Letter of Good Standing 1.pdf": "C8A44826-342B-4978-862F-07DC9780BF9F",
    "PBE-Meningioma - CNS 2023 poster.pdf": "16D8CA0A-9936-4C23-956A-1EBE6E271D1B",
    "PBelton.emailverification.pdf": "465D5EAE-A127-49B6-A0FD-23C74AAF2D38",
    "PCA_poster_CNS2022_RC.pdf": "16F49525-3837-44A2-8F3C-A7D1FF7FE10C",
    "PCD DJK Etiologies.pdf": "2CB40A80-9BD1-43C2-B518-ADA8C03058DD",
    "PCDF LOS Readmit CNS 2019 Poster.pdf": "E45DCECB-E6E7-4D2F-B6BA-1F989A933DB7",
    "PCDF Time of Surg CNS 2019 Poster.pdf": "0660A4DF-3E18-47F4-837E-0ABC7D648234",
    "PCezayirli-Verification email.pdf": "FE8C6F8F-D4AD-4E56-9088-46F5FD03E445",
    "PCRC Comments re 2022 QPP Proposed Rule (D0965791).pdf": "24EF7E33-5DAB-4C8B-A5AE-081706C6432F",
    "PCRC Comments re FDA Medical Device RWE Draft Guidance D1103724.pdf": "7FEEED0F-3828-4D03-8FBF-436D5DD60D9F",
    "PCRC Comments Re. 2023 QPP Proposed Rule D1019163.pdf": "3527A340-9543-42E7-A72E-3FCEC5F85B0F",
    "PCRC Comments re. Information Blocking Enforcement Proposed Rule D1095428.pdf": "9A092175-CFC9-4147-B233-E0FF96498001",
    "PCRC Letter of Support for Sections 309 and 411 of the Cures 2.0 Act (D0976856).pdf": "B63A8B52-76D2-4501-B1EF-2B772FBC56F0",
    "PCRC Letter re. Request for Information- Episode-Based Payment Model D1073186.pdf": "684F458E-119E-4BCF-A09F-42FEAE229343",
    "PCRC Letter to CMS re MIPS MVP Program 041320 (pdf) (D0884679-4).pdf": "318AB537-7B8C-4AD3-8488-66EAC513DB74",
    "pcrc_comment_on_qualified_entity_proposed_rule_3.29.16.pdf": "90A521CF-4251-410B-970C-FF43404F16C7",
    "pcrc_comment_on_the_macra_proposed_rule_d0671895-2.pdf": "2CEDC18E-0D30-45B5-A16D-377107901555",
    "PCRC_Comments_on_CY_2018_QPP_Proposed_Rule.pdf": "4CA1C213-D363-46DB-8679-F9E704D2D449",
    "pcrc_letter_to_cms_re_qualified_entity_final_rule_d0682757-4.pdf": "E36B6E7C-99C1-4655-8583-305B8997284B",
    "PCS Figures.pptx.pdf": "C056236E-3E63-4A1D-8BF9-39D94610CB22",
    "PD Letter CNS Guidelines Scholarship - Zaazoue.pdf": "3CC8AD69-84D5-428D-988C-46D084CD1CF9",
    "PD letter Nov 2022.pdf": "14568BE9-A78F-48BB-A43B-F3C0C968E214",
    "pd letter.pdf": "CF76B7E1-7FEE-44B9-88E1-11B1B5C5ED88",
    "PD Letter_DD.pdf": "F9E45A7C-DF41-424B-B708-59B073B94F4B",
    "PD.pdf": "DD25839D-88B4-46B0-B53B-DD6626A3B2C9",
    "PD_Letter.pdf": "D6E43807-BD4A-4F20-80B2-3C061B67BB4F",
    "PD_posterv3.pdf": "7A858FA3-34ED-4A3E-AFD9-1402DF5A2550",
    "PDC Dear Conferees Letter FY22 Final.pdf": "87AC9A80-7325-41F8-9415-967232A5D0BC",
    "pdcc.pdf": "B4569502-60D8-481B-874E-11F79ED3DAE3",
    "PDF Accuracy MIS, Open OnPoint AIm-AR.pdf": "D6704706-C388-4768-A741-52D68DDE26FB",
    "pdf Anterior Fontanelle CNS Poster 083123.pdf": "6CFA27B6-7F27-4ACA-B825-9C71CDD2B7AF",
    "PDF Expert.pdf": "6DF50AC5-8D6C-4FBB-8F60-C21D5E3EA7A0",
    "PDF OnPoint AIm-AR Open Technique poster.pdf": "E43CB978-13EA-4E34-93BF-D78DE64456F7",
    "pdf Subdural Empyema CNS Poster 083123.pdf": "D3F638A9-CC7D-4A85-9963-BC02267AB2FC",
    "PDF xinli's CV 2019 OCT final version.pdf": "75C61826-DDD0-4785-8CAA-EBCF6B77438D",
    "pdf-sample.pdf": "CFB9E7BF-DA32-4E4A-B287-09F220DD0F63",
    "PE final 2011.pdf": "AA71D3B5-3343-4BB6-B04C-4C5AB0321192",
    "Pearson correlation CNS.pdf": "3DD6F26E-CF37-4124-AF28-C6EE127BB0EA",
    "Pease CNS Leadership.pdf": "4677E371-F73C-459B-A04D-F9F41AB3FE46",
    "Pease Resume 2023.pdf": "D820C630-DB6A-42B4-B211-FBF51B1FE045",
    "Pebble Beach Resorts Activities.pdf": "7A3B973F-738E-475E-993C-7480D8F19044",
    "Ped IMSCT Poster CNS.pdf": "26A8DC76-3A41-4522-8DAC-607D25FDF4A0",
    "Ped LITT Tumor Poster.pdf": "AA6B82DC-8BF7-406D-92F8-D1C528982ACA",
    "PED Review summary of studies.pdf": "88E47A23-513B-41EE-A03F-7C2BD7711DEA",
    "PED vs PED Shield.pdf": "7B22B456-88AF-47E6-BB6B-E53913537C41",
    "Ped_ATV_Poster_CNS_2023.pdf": "20F063E6-FCC7-4654-A587-9FEF804C3728",
    "Pediatric CNS Meeting Poster.pdf": "C0143385-C4AD-449C-92A5-D2610FDCA01F",
    "Pediatric COVID Relief Congressional Letter Final May 2020.pdf": "7E390114-8D77-421A-9C70-47BC98665A46",
    "Pediatric Craniopharyngioma_Kids Inpatient Database (KID)_CNS 19.pdf": "3A5CBF70-B422-4F43-838E-A2400985E0CB",
    "Pediatric Didactic Slides - Hauptmann.pdf": "CF81CD4F-15EA-4C54-BBA6-210B78BB50CA",
    "Pediatric Program Preview Web.pdf": "39152E89-B3A3-47AA-A7E3-0F9237320F99",
    "Pediatric Pseudoaneurysm - CNS 2022.pdf": "1FF0062E-1F71-4056-A4C2-C3F69029E71B",
    "Pediatric trifold brochure WEB VERSION 2 11012022.pdf": "7EED37A0-3B05-46C7-93E5-6AB564569187",
    "Pediatric VSS CNS Poster Presentation_KEL080419.pdf": "FF8BAFE6-41EC-4134-9A14-2F7E96D6CAF9",
    "pediatric_emergency_care_position_statement.pdf": "78F7AAE9-128B-43AF-9EBA-5DD5CB036273",
    "pediatric_trauma_white_paper_final.pdf": "475727EA-305A-4FD4-89A7-C4195962A129",
    "pediatrics.pdf": "EEE7987C-0A3C-4D95-A2A7-A02748B0F4B7",
    "PediatricSubspecialtyLoanRepaymentProgramEndorsementLetter.pdf": "2501EAEF-D84F-42B4-887F-1EEF5BE6E635",
    "PediatricSubspecialtyLoanRepaymentProgramEndorsementLetter_0.pdf": "322AF57C-1657-47BD-A77B-6149D7909556",
    "Pedro Branco certificate.pdf": "5C5C519F-A57E-4A90-84A3-F757E96E34B4",
    "Peds 22 Poster- Craniopharyngioma.pdf": "2CC9196C-FE72-423E-B0B0-3F8491920DCD",
    "Peds Angio Poster CNS 2022.pdf": "EEF16BEF-CDE3-4406-B577-2BE2FA9AEDD5",
    "Peds Conference Poster Final.pdf": "3E9C6925-85B7-4D04-8F97-F20D2F47747A",
    "Peds connectomics case series.pdf": "906B9769-E04F-437E-AEE4-89BB1BF28E16",
    "Peds Disc.pptx.pdf": "7CD58ECA-7B45-4AF6-B064-4415523FCBA5",
    "Peds Hospital MMC CNS Poster Final PDF.pdf": "255D6B2A-4D48-4660-8F23-9402DB83FB23",
    "Peds ICAs Poster CNS 2021.pdf": "589838D2-A861-4948-B4EB-2D3A7F6CA5BD",
    "peds poster.pdf": "82078F8B-5F06-428F-9C85-98FEC36E069F",
    "Peds Section - plagiocephaly poster  -  final.pdf": "09EEAAC4-C759-4EB4-9CAE-700942C132C5",
    "Peds Section 2022 Poster.pdf": "C5CF7582-949A-414C-B36D-C1F8B4203BB8",
    "Peds Section 2022 Poster_Breath Control SDR (NM edits).pdf": "1E0B2694-90A9-44B5-A268-E6EC562E0FFE",
    "Peds Section 2023 Poster.pdf": "1659DFDF-012F-459C-941B-4476D5463272",
    "Peds Section 22 Poster – Peds Supraorbital.pdf": "DB5157B0-3A2B-4A58-8603-F75894810DC2",
    "Peds Section 22 Poster- Petrous Apex.pdf": "9E2138CB-AEFB-4939-B4C6-19911AC777DC",
    "Peds Section Achon Abstract.pdf": "0408FD60-CF1B-43F8-9089-FF9847DB2E41",
    "Peds Section Fetal Survey Poster.pdf": "78AF0B5A-99FE-4C13-9B00-5C8EDA93E97F",
    "Peds Section LR Poster.pdf": "5D62F52C-A9A7-4552-AC73-0CBC00A5302F",
    "PEDS Section Poster (2).pdf": "B482B6F4-FF10-42B2-978F-332E478B5A43",
    "Peds Section Poster 2022.pdf": "5DED3A4B-AABD-4F3C-B47E-A0BD6902E85F",
    "peds section poster 8.pdf": "D4EE1540-C05A-41BB-BDE1-9993733E9808",
    "Peds Section Poster Chiari JHACH Format.pdf": "A6B2EA0C-5009-4A5C-9B6B-4606D037EACF",
    "Peds Section Poster Dermal IC BBest 11.11.22.pdf": "C899F225-2592-424E-AC3A-AD4974C0DF02",
    "Peds Section Poster ETVSS JHACH Format.pdf": "05183951-5CBD-481E-B543-6C7ABC95450E",
    "peds section poster pan.pdf": "68440E1C-A07F-4CA7-880F-D9E92CBE3074",
    "Peds Section Poster-HealthDisparitiesPostNatalMMC JHACH version.pdf": "6E085F76-ED34-4651-881D-27DEF50C8673",
    "Peds Section Poster.pdf": "894CE5F6-6C2C-4C12-A93B-17A59698589D",
    "Peds section Poster_22.pdf": "AD86805B-D675-4C5D-A80C-4FE827BA4025",
    "Peds Section SEEG Poster.pdf": "CAC9DD4C-FEF0-4831-8B8C-8F7908130D8C",
    "Peds Section_R2EDavm.pdf": "7A600CD3-A145-435F-9564-ADF79C7F07B4",
    "Peds Section_VOGM.pdf": "4824F939-378A-4F96-A1A6-F92D88C723EB",
    "Peds SES Shunt Failures CNS Poster.pdf": "F4157FD3-07A5-44C6-8A73-AF01658E0AF4",
    "Peds Spine Onc CNS.pdf": "547BFBED-2EC9-4889-8F38-66A4C5AE9524",
    "Peds TBI Poster.pdf": "2B29D2B3-DE4A-4571-8BE3-7B6F1CA51A10",
    "peds.pdf": "5C8B7BF2-906C-4E9E-A23C-28208CF60AE9",
    "Peds_Ramantani.pdf": "02DCA06E-2267-4017-B999-CD3CBF006742",
    "PedSpecialtiyCoalitionLetterFY2014_FINAL.PDF": "4061D60C-4E2B-4AFC-B41B-A0FD36091263",
    "PedsSection_3VAbstract_Poster_v1.pdf": "27410610-8343-4CDE-9CCA-DBB7A4897C72",
    "PedsSection_CEDAbstract_Poster_v1.pdf": "160D0E2B-F915-4999-A790-685BA2E3DA69",
    "PedsSection_LVAbstract_Poster_v1.pdf": "F7CEAB8A-DA20-4DB3-956F-4C5306FBBBED",
    "PedsSection_NBAbstract_Poster_v1.pdf": "FE73EA53-71ED-48C5-A583-2819D93126A0",
    "PedsSectionPoster_Final.pdf": "5064A34C-92F4-433A-B875-D2CE552DB442",
    "PedsSectionPoster2022_withKM.1.pdf": "EC075785-D656-4F0A-BD54-8A962FFB9C0D",
    "Peek Poster.pdf": "9199F553-9E99-4035-A9A1-F1B02FDEB366",
    "Pegah Ghamasaee-Fellowship.pdf": "BA9AD791-F082-43F0-81B7-D14438A5A337",
    "Peggy Harris CNS HBD3 Poster 2021_updated_3.pdf": "541F7CB2-87E2-4F17-98EF-F29F84394A3A",
    "Pelcher.Isabelle.Letter of Good Standing.2.13.23.pdf": "6BF35934-0CE6-4CC3-ACF5-5A47CFFC971C",
    "pelvicfixation_figures.pdf": "889D2310-05B4-44B0-B53B-4CD330237171",
    "PEMF v2.pdf": "FC7430F6-F1CF-467E-93A9-833E6F7E0CF9",
    "Pemla Jagtiani Letter.pdf": "B6D939BE-C183-40CE-88F6-31866371E5FE",
    "PenaltiesLetterRevised31412.pdf": "D400C4C7-D53A-4374-A80C-A4D903412089",
    "penalty_signon_letter.pdf": "20943875-BB32-48DB-9DDF-495A70942CCB",
    "Peng_Bo_Verification of Enrollment_042820.pdf": "579F73CD-037A-458E-9B4D-4CEBEDBF47FF",
    "Penn Resilience Executive Summary Dec 2018.pdf": "DAF6D292-F06B-45D1-A845-111CCDA88BB9",
    "Pennant Letter.pdf": "8050EEE7-4402-490C-BFC0-59A866AA0467",
    "Penner.pdf": "1F5CB6D6-B5E7-41E3-B90D-617EE506CFEE",
    "PENNING DONALD CV_09_21_2022.pdf": "008E1B46-8C1C-46C3-88CE-4C69576B537B",
    "PercEndoFesslerEmail.pdf": "C35D13C5-0E13-4FB7-80A9-9DA9518E34BB",
    "Percutaneous Endoscopic Lumbar Discectomy for Recurrent Disc Herniation.pdf": "F39F8228-AA81-42E1-A25A-6F1085F8BC7D",
    "Pereira CNS.pdf": "D4CAF74B-8D54-4935-8E5E-AB77191CB85B",
    "Perera Ishan Letter of Good Standing.pdf": "E7EEC86B-8AFE-436E-8A75-7CC618BD4274",
    "Perez Carrillo.pdf": "6E59C43F-772C-4325-A7F2-718DDE4F5C7B",
    "Perez Castell.pdf": "C730D1CB-B4E2-47E8-9293-18CEC2FDBAB0",
    "Perez Limon.pdf": "DF33EB57-A65D-48CA-8111-57534234B719",
    "Perez N Student Status Letter.pdf": "02A3ECA5-9A24-471E-BFD6-6A8E36406B10",
    "Performance_score.pdf": "8B45A6AE-DF8A-41D5-8D2E-052C50769D55",
    "Peri-tumoral resection GBM_CNS.pdf": "F8592CE5-1695-47B4-934A-AA6B829F29D0",
    "Periclinoid.pdf": "6517573F-6C0D-409F-9C7F-D552DF1DFAF3",
    "Peripheral Nerve Curriculum Outline 5-23-22.docx.pdf": "86A66ED3-3E8D-4F1D-9BAD-5E0B55DD929C",
    "Peripheral Nerve Didactic Slides - Spinner.pdf": "249D2353-1473-4696-8DF4-CEA9DE217C3D",
    "Peripheral Nerve Fellowship List.pdf": "B4025823-3E22-4D70-B8E8-065878F097CF",
    "Peripheral Nerve Membership List.pdf": "66E784A4-9526-4A4C-86CE-82EB80939E13",
    "Peris-Celda CV.pdf": "BF028313-C9FE-4AB7-8E07-DD5CB84C4888",
    "Perry Matthew T Indiana University.pdf": "8756B989-4B32-4432-ABBA-D9F277512CC7",
    "Person-Jones. Karlisha- Signed CNS letter.pdf": "8B1E92A1-4220-4112-A760-08BFB4C22242",
    "personal CV.pdf": "2F7B894E-7F6F-4A7B-9AAA-FBBFE692B339",
    "Personal Finance for Neurosurgeons - J. MacDonald.pdf": "E6E881C8-25EF-4357-8C12-8343DB38E5D2",
    "PERSONAL INFORMATION.pdf": "1AC04A75-909D-4127-A548-04BB2D250DFC",
    "Personal Protective Equipment.pdf": "89275BA7-5C3B-4F27-9FF2-16B5413370D6",
    "Personal Statement - Publications Fellowship.pdf": "0E481598-A1DA-4BAC-9E54-89C5C88E78AE",
    "Personal Statement .pdf": "F157C345-FB68-4BFD-B738-01956BBAC159",
    "Personal Statement CNS2324.pdf": "22E07FEC-6CA5-492D-8622-D80B7DBD5920",
    "Personal Statement.pdf": "C1D46B99-5D5E-442D-918D-1BDD273461F6",
    "Personal Statement_Elsamadicy .pdf": "DCFDC48C-8F55-42EF-8EBD-1C09A10A8184",
    "Personal Statement_Elsamadicy_2023 .pdf": "F107854D-FB95-48C8-B3DB-B36EFB9306D5",
    "Personal Statement_Nsgy Publication Fellowship.pdf": "8FA8ACA1-245B-4577-A3FB-82759147F471",
    "Personal Statement_VML.pdf": "9A6BC059-9556-4CED-8D4A-06A3A75890B0",
    "Personal-statement.pdf": "1693753A-DE96-4D99-AD40-60F5EB1F4F75",
    "Personal_statement (1).pdf": "7208BAA0-E663-483A-8452-1A367B3345D0",
    "PERSONAL_STATEMENT_2020-10-16-132848_b71c342d-d3ae-49d7-b8ef-851e79cdc385.pdf": "52971470-FA23-4E28-B0EA-E4968CBAFB48",
    "Personal_Statement_mstrong.pdf": "86A1D09A-1A77-414E-82B5-EB18B2CBEBE1",
    "Pestereva(1).pdf": "676A6300-8DA1-466B-A648-7D88A6AD5E66",
    "Peter Bouz CV w PDF.pdf": "37BF0D5A-521C-48C9-92AE-B6894FF9BAC1",
    "Peter Fecci CV.pdf": "EAC113CD-751B-4ECE-8AC4-1BF990C6E08F",
    "Peter Fecci Nomination Letter.pdf": "666D1587-3FF9-46F6-9F9D-AD7D6011C9F4",
    "Peter Fecci Recommendation Letter 1.pdf": "6B6605ED-D11B-4A0A-A409-80A97E41A784",
    "Peter Fecci Recommendation Letter 2.pdf": "2E0B975F-79C6-44C9-8A69-93F8D7730E2D",
    "peter fusco GOODSTANDING ENROLLMENT  7-21 .pdf": "2BA50F3F-BF07-43FE-81C7-5D7319FF9ADA",
    "Peter Giannaris CV .pdf": "386D58E6-13D7-4FF6-BE55-C48116519E81",
    "Peter Giannaris CV 2023.pdf": "1B981EDC-A32D-4E70-86E1-978F0743F6A2",
    "peter giannaris GOODSTANDING  4-23.pdf": "E88D9D1E-72EB-434A-8C78-117BC78476F8",
    "Peter Giannaris letter of recomendation.pdf": "5BB02BD0-B1B7-4EA0-B682-8B9D72BDB758",
    "Peter Giannaris Resume 2023.pdf": "563AAE4C-8E52-4AA4-9F0F-A7A182246B5A",
    "Peter Kan (Levi).pdf": "2E3C063A-EBB3-4171-AA45-0633979E3A0C",
    "Peter Kan (Nominating).pdf": "2554843C-BB58-407E-9867-47A6FD52D6B0",
    "PeterMadsen_CV.pdf": "0D5528AA-B94B-4282-A6B0-FB044BE8420F",
    "Petersen - PDN 12 mo data poster - CNS 2021.pdf": "A0E51151-93A9-43BB-B2BB-65C87068CCFE",
    "Petersen_Erika_2024_PositionalMembershipApplication_ConfirmationLetter.pdf": "B75A93A3-67B1-4A37-9324-03703986CAAF",
    "Petersen_Erika_2024PositionalMembershipApplication_CVandBiography.pdf": "9D0C877C-E246-4A32-A20E-736261E1BB41",
    "Petersen_SNS LOS_Ewend 2023.1033.pdf": "0E3D005A-FFD8-4715-BF42-7ACDB49A3C8F",
    "Peto.pdf": "B2470A16-56CD-48C6-A064-19C1F2F4352B",
    "Petteys_CV_Aug_2017.pdf": "259529C2-2DEE-41C4-939E-CCBD35D2ACC9",
    "Pevehouse, Cone.PDF": "C0B58C23-F90A-49A4-901B-30EBD742B73A",
    "PFH CNS Poster.pdf": "889283C1-8722-4E64-A23C-F597ADD5FF22",
    "PHAES Score Digital presentation.pdf": "667FB191-C88C-4547-A7F3-BE9C19B35B1B",
    "Pham - CNS Back Pain in the ER.pdf": "C00E4EB1-6E79-4CA0-97F9-224D53348BDA",
    "Pham_LOR CNS Leadership.pdf": "A058ACB5-C622-415A-9910-86DFCCEB3359",
    "Phan LET.pdf": "09B69927-C3AA-4B44-A21C-FA3279589F4D",
    "Phan, Sheshanna DO.pdf": "D0F12B06-FC81-4C81-8FC3-8EE6235A509D",
    "Pharmacological_Therapy_for_Acute_Spinal_Cord.12.pdf": "C7E16F6D-2001-43F2-A63F-EA50703A7C55",
    "pharmfUS_ASSFN_poster_v2-converted.pdf": "F19AB6C2-FB6C-4C6B-A88F-DD01AF911250",
    "Phd carrier-certifcate-Antonella Mangraviti.pdf": "7D113A47-F2DC-4494-B10A-139932E65225",
    "Philip%20Schmalz.pdf": "BB45CF74-6815-4C4B-BE19-D034DFD8F47A",
    "Phillip Bonney-FellowVerif.pdf": "0DE42CF9-9A75-4FA8-ABB3-9948BD851BF7",
    "Phoenix Budget Final.pdf": "D2B59F0E-C6A2-4691-B075-3954CFC16A61",
    "Phoenix CNS CV 4.14.22.pdf": "FF68B323-E5D3-43E7-BA24-565C1DB9C689",
    "Phoenix Enrollment Verfication.pdf": "52F5F63D-0C78-4D44-8ACE-32C25B4F3888",
    "Phoenix Letter of Support-WolfeSQ.pdf": "B1391B4F-7AD1-4B0D-8490-8598A3402FF7",
    "Phoenix Letter-Bebok.pdf": "5EA29D50-2655-4FE1-AA37-99CD1E4FBDFD",
    "Phoenix Letter-Ergul.pdf": "5465E6E2-17DD-4872-8BE2-9007CD24761A",
    "Phoenix Manuscript1.PDF": "5812DF3D-B06E-4558-8F3E-231E55F97BF3",
    "Phoenix Sponsor Biosketch-WolfeSQ.pdf": "CA62A819-5DE9-4513-B07D-480ABA03899F",
    "Photo 5.pdf": "C611A24D-E041-4046-A046-B67EE03F6979",
    "Photo Harbring.pdf": "22420BF4-988F-4174-A402-C78D6DC975C5",
    "photo id 3.24.2020.pdf": "1174E1A2-8303-4EAA-87BE-9F4A74CF62F5",
    "PHOTO.pdf": "1A6E967F-5605-4053-AC7C-6D8F67BF524C",
    "PHOTOGRAPH.pdf": "D147773A-D91D-4D6D-9629-F5BE2F5CA8F5",
    "photos-touchbar-timescrubber-needle.pdf": "671C6C99-C139-41CA-BE2B-35CFA9A8505D",
    "Physician Clinical Registry Coalition (CMS-1715-P) (D0855322).pdf": "EF2A2D12-47E6-45D5-88B6-E83AE7762CD9",
    "Physician Clinical Registry Coalition Letter re. Cures 2.0 Discussion Draft (D0956431).pdf": "34567A45-19ED-4AD2-863F-13C1EB1CF2F4",
    "Physician Clinical Registry Coalition Letter to MedPAC (D0974279).pdf": "42D97BC5-7EA3-43A9-8098-52DE62513F01",
    "Physician Clinical Registry Coalition's Comments on the Proposed CY 2021 Revisions to Payment Policies Under the Medicare Ph (D0911992).pdf": "799FA3A6-1B15-48A9-BB7B-38ABA80117C9",
    "Physician Clinical Registry Coalition's Recommendations for Cures 2.0 Legislation (D0896509).pdf": "C8DA2FA0-2D28-409D-9E00-961EDCACB7B5",
    "Physician Clinical Registry Coalitions Comments on the Proposed 2024 Updates to the Quality Payment Program CMS-1784-P D1076747.pdf": "F72BD5AE-42BC-4B66-B9F3-6DB6E2F71D64",
    "Physician Community Statement on EC-HELP Surprise Medical Bills Proposal 10.06.20.pdf": "4ED0B3BF-F239-418E-8142-103888251D6F",
    "physician dentist coalition letter to committee leaders re REDI Act.pdf": "D64C8FD9-32DD-46C8-A5FD-295FCEEA23B7",
    "physician dentist coalition letter to HELP leaders re REDI Act.pdf": "D9A7B7A2-B55F-40F2-873E-8B2F204DE766",
    "Physician dentist coalition letter to House REDI Act sponsors.pdf": "85C25C17-3A35-4B65-9C77-672FC48BEB40",
    "physician dentist coalition letter to Senate REDI Act sponsors.pdf": "79A8BEA4-7036-4A65-B7D0-6528289CFF1F",
    "Physician dentist coalition thank you letter to REDI Act sponsors.pdf": "0CE1F326-17CD-4E2E-89B6-24AEBD7FA0DE",
    "Physician-Led-Hospitals-Coalition-Letter-7.27.23.pdf": "E6FDDF47-D859-462B-B5CE-51AD387ED578",
    "physician_community._letter_of_support.medicare_care_coordination_improv.pdf": "4B7F1D1B-2404-4E70-B1A4-6AFD03909D5C",
    "physician_community_letter_of_support_on_s._2051._medicare_care_coordina.pdf": "8EE96D8D-6829-4719-8E1E-DF6C0460A81B",
    "physician_community_senate_letter_of_support_on_medicare_care_coordination_improvement_act._4.2.19.pdf": "057431A8-D8D2-4D69-A4B7-84FC6503F9ED",
    "physician_letter_opposing_h.r._2143._4.23.19._final.pdf": "A4C27F13-2372-4428-A03F-3EF002BB0830",
    "physician_statement_for_the_regulations_of_new_cv_devices-final.pdf": "D1A3879B-3394-49BE-B738-8B2CF60886C9",
    "physician_surpise_billing_letter_to_congress_020719.pdf": "9641B7BB-3178-4C36-9238-23FE3E047C55",
    "PhysicianFAQ-AANS-CNS.pdf": "BFC75DBC-3D98-4FFB-847C-22DF5196C8DC",
    "PhysicianOrganizationsthatOpposeSenateList12-10-09.pdf": "6C5EE109-22F7-49DE-82CE-D320E6B84993",
    "PhysiciansUnitedforPatientsLaunchNewsRelease121709FINAL.pdf": "4EE5D79D-0742-4C48-B52D-C28BAE33A5A3",
    "PhysiciansUnitedforPatientsLaunchNewsRelease121709FINAL_0.pdf": "725DDF1C-5D10-4431-A054-8D2824696744",
    "PhysiciansUnitedforPatientsLaunchNewsRelease121709FINAL_1.pdf": "CC69E769-D852-4470-BF9A-91F38D0B8D5E",
    "Pial Collateral.pdf": "F49C527F-0EA2-4DC5-AF36-FE4F5CC95AE8",
    "Pico Annemarie_ LOGS with Expected Grad Date.pdf": "80AD6D22-77A8-4824-A64F-19D0FDF95544",
    "Picton Bryce_GdStg-1.pdf": "A7CE4023-0030-4AE5-BA4F-3693ACFF2B2A",
    "Picture1.pdf": "8716C8E4-4AA2-4701-83BF-16D8B44725B3",
    "Picture2.pdf": "611DB827-FC77-4984-8036-A5CA2EE57124",
    "Picture3.pdf": "84CD314D-71C8-4B89-93E4-904A46D879C3",
    "Picture4.pdf": "AD75309B-F083-4D07-B3F9-BDF7E16E6C8F",
    "Picture5.pdf": "5C8C6E73-19B3-4ABD-B760-9E7EFE056CF8",
    "Piepgras, David G.pdf": "63BCA948-DA19-46D9-A8A5-2E66C74AD81B",
    "Piepgras, David.pdf": "1CD63013-2D4A-4F5E-89C1-C2011D997CC7",
    "PIERACCI letter of support 01.12.23.pdf": "FAB54544-2D1F-4A58-875D-F8F7F60AEA0B",
    "Piers Klein Cert of Good Standing.pdf": "27FCCCC7-5911-48B6-8DC9-FC2B79907F73",
    "Pieters_Thomas_ABNS_Verification_Apr2023.pdf": "74C8F926-A70C-4589-93E6-3428588AD7B3",
    "Pineal Tumor Poster.pptx.pdf": "D9285709-2A5A-4A09-86E3-48D55B6D65EB",
    "PIO LETTER.pdf": "0D3E1799-2CA2-4CF9-9A1D-CCF89292E5A7",
    "Pipeline poster .pdf": "17DAE8BC-72E4-4D00-A36F-CF3484C38281",
    "PiscitelliCletter 070723 2_WSA_7_7_23.pdf": "F3A67B9E-5706-4C95-B741-77617ACD9D94",
    "PISHVA Seyedamirhossein OMSIIVerificationLetter.pdf": "645E5DF2-E840-4A2F-954D-3584CFF045A7",
    "Pit NIS poster.pdf": "A7152999-BF9A-444B-9D60-EAF1F30934E4",
    "Pitt Enrollment Verification.pdf": "7653A343-50FC-448A-943C-EB2BF6667791",
    "Pittman_Thomas_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "19E4998B-3641-4FE1-A6AC-54A5132CD38C",
    "Pittman_Thomas_2024PositionalMembershipApplication_CV.pdf": "8ED48F7B-0985-4907-BF07-DADC171A129C",
    "Pituitary_poster_CNS.pdf": "57A2BC55-F45B-4EA2-AA51-A81B7292CF4D",
    "Piwowarczyk_CardiacDefectsVGAM_CNS2022.pdf": "CA7D1F27-DCD5-40CD-B7DD-C66044DA568D",
    "Piyush Mittal (CV).pdf": "1EC4508F-2E6C-4288-A8F9-9FC495572D6A",
    "PJH_MedSchool_POE.pdf": "E8F6EC7C-9C5B-4BDB-B2FD-F74F3892928B",
    "PKelly.emailverification.pdf": "921C40BF-7BBB-484B-8488-3C19E0DFE429",
    "PKim.emailverification.pdf": "E43D2215-04B1-4F97-96B0-1B40B3E8DCA3",
    "PKoch_Residency_Fellowship_Diplomas.pdf": "6D10E0A9-1A50-40B6-A239-0DBAB4970941",
    "PKrafft.RESverif..pdf": "A5FFD2D4-2873-4C37-AC3E-3E3B202B3DF1",
    "placement.pdf": "AFB6FA13-65E8-4D15-AE1E-79AFD0842C19",
    "plagio-chapter-1-figure-1.pdf": "32AC1F33-4C81-4099-BC63-8EC36FCF9DC1",
    "plagio-chapter-2-figure-1.pdf": "1393C4FB-6F92-48CF-95F3-CFCD427B93E4",
    "plagio-chapter-2-table-1.pdf": "8772F3CB-0939-430F-B468-4F465D951247",
    "plagio-chapter-2-table-2.pdf": "3022C1D4-F38C-44DF-94BF-5BC1EB25DD4F",
    "plagio-chapter-3-figure-1.pdf": "1157892F-EE33-4EC4-9383-209AE9FBC341",
    "plagio-chapter-3-table-1.pdf": "38E2E1A2-8CF5-4853-9624-B8E518E1D6B0",
    "plagio-chapter-4-figure-1.pdf": "67170DC2-6DCB-49AD-9600-2C245CD5B365",
    "plagio-chapter-4-table-1.pdf": "997F4D52-5E30-42B3-9A71-07E5EA33A207",
    "plagio-chapter-5-figure-1.pdf": "922EF373-F643-43E6-BA27-04C9A8D8C15F",
    "plagio-chapter-5-table-1.pdf": "B83731C9-5DFD-462B-8661-469919F28265",
    "plagio-chapter-5-table-2.pdf": "44902DFB-4609-4E2B-8759-7490AFCD20A3",
    "plagiocephalygl_ch._1_intro.pdf": "71CE8AD3-9E53-4E37-8ED5-36BA0C56698D",
    "plagiocephalygl_ch._2_imaging.pdf": "7A9E5CFB-9E37-4DAE-8F0A-7940FC98040B",
    "plagiocephalygl_ch._3_repositioning.pdf": "9399473F-64FA-47E6-86DC-2CC21F6BE206",
    "plagiocephalygl_ch._4_pt.pdf": "6D09DE86-5D14-4432-9997-44B1DC6F3ED4",
    "plagiocephalygl_ch._5_orthotic_tx.pdf": "16CA1501-E637-41A5-BE54-287209BFBA87",
    "Planchard, Ryan Good Standing Ltr.pdf": "7231E228-E8D0-4366-9FAE-174CD8BA0662",
    "Platelets_poster.pdf": "5E31F64F-402A-4300-8B26-8EB72F2F1576",
    "PLAW-111publ148 (1).pdf": "A4CA1E7B-A69B-48F4-83B2-F86283D130B8",
    "PLAW-111publ148.pdf": "7F3942EC-27EC-4DFA-B0F0-275817EFA889",
    "PLE Poster .pdf": "4533FA96-67FC-4A24-9685-89638D622E25",
    "Please_DocuSign_Verification_Request_Form.pdf": "E7CBB729-05D9-4DA9-93C3-C070539ECDCA",
    "PLF LOS Readmit CNS 2019 Poster.pdf": "9CBC65F3-EEC4-4218-BA11-38DC03D404AE",
    "PLF Time of Surg CNS 2019 Poster.pdf": "78566733-D11F-483C-90DB-72253121D771",
    "PLI signon letterv7 FINAL.pdf": "836BF378-DE98-4D95-ADBE-3CEC65AB8C0F",
    "PLundy-RESverif.pdf": "01509F5F-3FC2-4DAB-AF14-5D4CF2ECB92C",
    "PLUSS_matched cohort study.pdf": "4A18A84C-EC8A-409D-A209-0F6661B08480",
    "PMastorakos.emailverification.pdf": "AA389CC1-44FA-4EC3-9B11-008C11BAF5BF",
    "PMummaneni CV 02.28.20.pdf": "0E2271DF-4BFA-461B-BABD-F7B198D43984",
    "PN_Levi.pdf": "3DAB781B-1521-4962-BEBC-1AA3C54CFFA8",
    "PNisson.pdf": "D5C88226-30FF-4241-897F-472DC90180FC",
    "PNS ACGME- Manuscript- Spine Summit.pdf": "F9DD641D-CF0B-4FCA-A6C5-FD9E8564B098",
    "PNS Poster V1.pdf": "E0861CCA-633D-44E0-BC30-D4A5F07BC088",
    "Podet CV 6.21.23.pdf": "B66A0E0D-39A2-424E-97DB-1CA7AE6E0C80",
    "poh_specialty_group_letter_to_congress-2015.pdf": "E77AA213-077E-45D6-883C-4960832FFE19",
    "Point of View.pdf": "17873FD3-9951-4689-832D-A6CDBB1C34E3",
    "policyonendorsementofprdtsinaanscnsendorsedguideline.pdf": "2408F145-5A03-4366-95D4-96097226594B",
    "Pollock, Bruce.PDF": "78B23FEE-A503-4858-9045-57DCEB05F7D5",
    "Polyneurotrauma Poster_Villahermosa_2023.pdf": "A6D15881-8492-4B3A-AE05-90EB2173500F",
    "Pomeraniec CV Nov 2022.pdf": "6EAF221C-AB32-4A59-B5FD-E0FAC593B145",
    "Pooja Venkatesh MS2.pdf": "34422648-688B-4D55-BF3A-16B4BA6A8E93",
    "Poole2561.pdf": "16478890-E482-4BC2-B6FA-8AB970BA4A68",
    "Poon Verification.pdf": "9E3E6B8C-6957-476B-B80C-A4687D932BA4",
    "Poor Pain_Mayfield_Michael Yang.pdf": "985B4C35-68AC-4126-B83D-F45EBB5EEAFE",
    "Popoola_Daniel CV September 2023.pdf": "393A9E48-5C4E-4450-855F-2C024EC8D673",
    "Popp, John.PDF": "ED28C0E1-F62A-4FA9-B5C2-22E699F3852F",
    "Population.pdf": "CBD667AB-F03D-4F9C-8B99-553B8B3FD9F4",
    "Porras, Jose-BLE.pdf": "2FB1EBE8-338C-4907-8635-E2AED06EEC8F",
    "Porwal Mokshal- Enrollment Verification.pdf": "FA1D1758-D5EA-47C1-94A8-88FAAF6134C4",
    "Post Card Brain Mets 2021 Register Now as of 7.23.21.pdf": "AD18D814-D09F-4281-AE00-0938698DBB12",
    "Post graduate training.pdf": "3EBB8A7E-21AA-4009-9797-B65AC88986F7",
    "Post Graduation Certificate.pdf": "42474319-B3C6-49DD-9DA1-85E8D1E35584",
    "Post, Kalmon D.PDF": "7653E7BA-CCD5-4747-99E5-20B147545550",
    "Post, Kalmon.pdf": "CE0D7ED2-9CE0-4E28-9BAB-5BDE3730C1BE",
    "post-ARUBA AVM CNS 2021.pdf": "92A18C53-7272-4B99-BC37-0EED45DB86E2",
    "Post-Hemispherectomy HCP_AANS-CNS Pediatric Section Mtg 2022 Poster.pdf": "7610303A-12B4-40FA-9295-28D60E9A5ACD",
    "Post-op Descent of Diaphragma.pdf": "ED6AE79A-FC77-4502-A2C5-77092CFB4A93",
    "postage 20230508 koo cho 4X super antioxid.pdf": "4CB934B8-42DB-4C4F-B3DE-C3F0677A0DFA",
    "Poster (CNS).pdf": "45BA0524-6213-4C57-B30A-3420E8D8CED3",
    "poster (CV1).pdf": "3CA166BD-F092-4C98-80BD-0703C4C98EE3",
    "Poster - Abstract - Adult Circle of Willis Plasticity After Flow Diversion.pdf": "08C99346-A340-4B33-856A-E951775A8335",
    "Poster - AComA retrograde technique for rescue strategy of proximal stent occlusion.pdf": "90FEC38A-670A-4B61-A28B-DA91FFE07673",
    "Poster - Basilar stenting.pdf": "D14DCBE6-426F-44E3-90A6-CC6B32BB3155",
    "Poster - Petrosal Dural arteriovenous fistula.pdf": "0F6C1237-18D4-4975-B66D-8016AE68EF14",
    "Poster 035936_SDSC surgery cranial fossa approach.pdf": "08011D3E-E1EB-46F6-BF73-DBD67B83FC20",
    "Poster 035958_SCDS surgery and Quality of life poster.pdf": "60E8CFFA-66E7-478C-A340-760C79166BF7",
    "Poster 1.pdf": "45A49B69-08A9-4EBE-903E-B200134EDE6F",
    "Poster 1086_RM & PC.pdf": "1CA7DBDA-9DED-4541-8751-95D071F8E839",
    "Poster 2. Meditation and Seizures 9.30.pdf": "82BFB018-C474-4750-93B8-AC0948F50733",
    "Poster 2.pdf": "FE5584CA-1375-4C1F-8C4E-C68E143984CB",
    "POSTER 2555.pdf": "D13BE7C8-29AD-4974-AC57-99E7E17B81BC",
    "Poster 3. sEEG .pdf": "9B7F2368-C24E-4E9B-BC1D-57C7AAA5DF4C",
    "Poster 3.pdf": "C33C5313-AF34-4AAC-AA41-95FF4EC24611",
    "Poster 330.pdf": "9D645E11-A3D0-4ADF-88EF-150023F6C507",
    "Poster 4.pdf": "88941D26-45DC-42BE-AC9F-8B5DDE6E405B",
    "Poster 445 .pdf": "8D110FEC-7F8F-42DA-81D7-9D040D99E98C",
    "Poster 5. BG network.pdf": "757818AC-8E40-4C04-AF17-F060C86B6BC4",
    "Poster 5.pdf": "A6F069C6-E500-43BF-9D1F-B0CF82EEF2CE",
    "Poster 6.pdf": "10B9F8A0-E7F3-4F11-A31D-6592D71C01CF",
    "Poster 7.pdf": "1D51DAFD-DD72-4915-8759-A67C2655FE0D",
    "Poster 704_INSPIRE 2.0 study design CNS 2022 poster.pdf": "24A34A07-0CB3-4BC1-8CBB-57DB640EBCC5",
    "Poster 8.pdf": "AA617B06-D7D5-4D06-A122-D904A3144168",
    "Poster 9.pdf": "FBD1C1EA-9FF1-49CD-9A15-18DD0E35B911",
    "Poster Akmal Masyhudi Final.pdf": "9FE2759B-6D7A-4E33-9BB3-6FBE8EEB7245",
    "Poster ASSFN - Gustavo Campos.pdf": "EEBCE9EE-8BC0-4F8D-A56A-E1606466AB6E",
    "Poster ASSFN 2019.pdf": "D14B48AA-76F9-49FC-A424-9CF2D44301DE",
    "Poster ASSFN PTSD 2022 Willie.pdf": "CD22950F-9DAA-4742-BCD8-825B0C10CACD",
    "Poster biomarkers autism.pdf": "3830E40A-97F4-49D1-BE31-42222295AA34",
    "Poster cavernomas.pdf": "CCF6CB8F-7FFE-455F-90C7-DDF9439B86D8",
    "Poster Characterization of Unplanned 90-day Readmission after Pediatric Brain Tumor Resection- Readmission Rates, Reasons, and Destinations.pdf": "C59069D9-B280-4BD7-9D28-AB657647D8DC",
    "POSTER CNS 2020.pdf": "27A402BA-4BFD-4C4E-B303-9F0C5D59EFEE",
    "Poster CNS 2022 -final.pdf": "6ACDE828-3CC6-41DC-A439-CDBF9C76EC3C",
    "poster CNS 2022 definitive.pdf": "901E224E-D585-44BC-AC92-51C4DDA03DC7",
    "Poster CNS 2022.pdf": "9AC789C8-69B4-46CE-BEE0-42C21CDA62C4",
    "Poster CNS 2023 Victor Andres Arrieta.pdf": "0AFFD832-7FE0-4379-A516-5E77880739F0",
    "poster CNS Aug 22.pdf": "7CC07338-3BFC-4738-8CE2-455BEF1F9B33",
    "Poster CNS Congress 2019.pdf": "13C87FCA-1BC4-452A-BE11-04F618AC5B46",
    "Poster CNS Congress Hemispherectomy 2019.pdf": "E25EF542-3412-4CB5-983A-FF6DB1407496",
    "poster CNS final.pdf": "8E0DE611-9F51-46FE-AD33-6023CCA1AC93",
    "Poster CNS oligo cell culture.pdf": "0C5567AA-9E69-452A-B3B0-59A4FA972961",
    "Poster CNS.pdf": "966B1E1F-44B9-450B-8F18-03932FAD1E12",
    "Poster CNS2019.pdf": "623A4E17-FA4E-4963-A76F-08CACEA4AEC2",
    "Poster CNS23Rousseaux.pdf": "DFB1AAA5-FA99-49E0-B2D8-56FDA40AD83D",
    "Poster dAVFs CV Section.pdf": "4928A515-5636-49CE-A5CE-A018A9F6D2B3",
    "Poster Dr. Harrop.pdf": "45C5623E-1323-4013-BA8D-ECA0D70FF6B4",
    "Poster draft_10302023.pdf": "378194BE-60C9-4458-B634-4E23FA63BB14",
    "Poster figure 1.pdf": "DBF02CBE-C2C5-4388-9A11-C6155755D996",
    "Poster figure 2.pdf": "B2713B1D-2ADD-43FB-B46F-07D971D81B1E",
    "Poster figure 3.pdf": "93CF2688-756C-4731-B28D-911CC1A8C49C",
    "poster for CNS 2021.pdf": "D3A292A6-18FA-45CC-BA42-EA72CE102C6C",
    "Poster for CNS 2022.pdf": "65263035-ADEC-49A5-973B-FD92E7B21C94",
    "Poster for Frailty Index as Predictors of Loss of Cervical Lordosis Following Laminoplasty in Patients with Cervical Spondylotic Myelopathy  .pdf": "FAAF11D5-C94A-4592-B8D0-793BBF7A8326",
    "Poster for Initial Experience Using an Augmented Reality Head-Mounted Display System During Surgical Management of Thoracolumbar Spinal Trauma  .pdf": "03C02848-1184-47C1-BCBD-11B67D01EF04",
    "Poster for Peds Section Color.pdf": "82BAFD30-C5D3-43BB-823B-C87DD3124BEF",
    "Poster ID_036143_YBSeo.pdf": "B746F4FF-B504-47EA-BED1-099D5A26CDFE",
    "Poster Joint Section.pdf": "38D7AFCF-719E-4EEC-B53B-154FE5431623",
    "Poster Karschnia Tonn CNS 2022.pdf": "E73A7824-9AB9-447C-BA19-E53FACC50DFA",
    "Poster Maria Garcia Bonilla.pdf": "89BB2A9A-8B11-4915-BC03-B14196F77C2D",
    "POSTER MIR.pdf": "5A51E73C-5490-490B-8A42-1FC0DD502AF8",
    "Poster Non invasive intracranial pressure TBI.pdf": "D3B59CEA-3D71-43B4-A7E7-0C4CC5A6DB9D",
    "Poster Noninvasive Intracranial Pressure in Patients Undergoing Myocardial Revascularization Surgery.pdf": "38BA86F9-92CF-4D85-A79F-20162F036159",
    "poster occipital head ache.pdf": "505E484F-D154-4EC9-BE4A-CF6E5D844366",
    "poster postop SRS meta analysis.pdf": "AEA40387-B875-44DC-B9B3-C2C49484DEFB",
    "Poster Presentation - SAH Peds v. 2.pdf": "094A2313-6E4F-436A-9C9A-D1E0B2749F11",
    "Poster Rahme redo 2.pptx (3).pdf": "2593B92A-0C4B-4A8D-B6D2-9B9DA2527CAC",
    "Poster Rahme redone 1.pptx.pdf": "8B0BDC06-1676-4558-B9E4-7420196CA927",
    "Poster revised 4.pdf": "FFEA36B6-33B5-4C9B-82C1-1AF99F0067B0",
    "Poster San Francisco.pdf": "38594416-AE14-4825-8D4D-91EB2E836A3D",
    "Poster Smoking is associated with increased 30-day and 90-day readmission rates  among patients undergoing spine surgery.pdf": "39975B3C-6EAF-4761-8903-9462C3CC387F",
    "Poster Smoking is associated with increased 90-day readmission rates  after craniotomy .pdf": "D73BCDD5-330B-48E7-8123-AB26F503B7F6",
    "Poster Submission Final.pdf": "A518C6A2-BC54-4159-906B-81D3C712BE9E",
    "Poster Template CNS.pdf": "5ECFBD04-CC89-4030-9F25-E99F21415BDB",
    "Poster VHL.pdf": "FCCF1A89-4EEC-4A3C-8CA0-305D98AFBDF3",
    "Poster-RFTC-Shadi Bsat.pdf": "D916336C-B257-49C7-BB25-94DD58A8FEE9",
    "Poster.pdf": "C69CF3D7-959B-4FC0-967D-0290810983C4",
    "Poster_036645_Differential biochemical diagnosis of parasellar xanthogranuloma and RCCs using 1H MRS 9272022.pdf": "F2FB916D-843A-4311-BACA-12D5694366FE",
    "Poster_037419_Oncomagnetic therapy for glioblastoma_A novel powerful and minimally invasive thechnology.pdf": "BC5CC1E8-4E60-4312-AB79-E366CF517F01",
    "Poster_1054_Jeffery_Head.pdf": "E1A241E0-059C-4563-A629-4629F7EADD8F",
    "Poster_2007.pdf": "AFFA49EA-0422-4D7A-8765-BB4FD3CC96BF",
    "Poster_AFIDs.pdf": "3C9258F3-A4ED-415B-8C8C-39A84894923C",
    "Poster_Aneurysmal Bone Cyst.pdf": "07D798ED-7EF6-4E1A-A524-1A57E85B2EF1",
    "Poster_AOL.pdf": "29A58C17-98AE-4FFF-A370-58B801040D0D",
    "Poster_AOP Systematic Review_CNS.pdf": "24F64BCC-18B9-49FB-BB17-3787593CAC06",
    "Poster_ASSFN_2022.pdf": "8C7C72EB-A147-41B9-9E5D-A1316F3868D8",
    "Poster_ASSFN_final.pdf": "350EC107-19ED-4F14-902D-790D544AD063",
    "poster_assfn2020_ms_draft2.pdf": "9DDA6CE6-561C-4EE1-B587-CEC3D36E45F1",
    "Poster_CNS.pdf": "C841B1AC-49C7-43D6-9639-B61D21759AF2",
    "poster_CNS_2019.pdf": "D2E93382-F870-453F-80DF-4B4EB53512D7",
    "Poster_CNS_23_Ng.pdf": "BB0D724D-F0E5-43F4-A996-B4A5AE33C185",
    "Poster_Design and Validation of Double Lumen Cerebrospinal Fluid Shunt Catheter System.pdf": "A66C9F62-8880-4E95-9B56-E02EC95723FB",
    "Poster_EarlyFocusI_ENV_Atlanta_JO.pdf": "3536ECA5-C65C-4C1F-9851-56AACA3D794C",
    "Poster_Fazlollahi.pdf": "935B042A-9487-4BE0-89F1-F69AF74C8A54",
    "poster_fschaper_ASSFN2020.pdf": "88453E4D-AC15-48E8-AF0E-D9BFED279865",
    "poster_Heuiseung Lee_2019CVsection_2.pdf": "1924B8CB-B3AB-423A-9E2B-E9D43D5F4E9A",
    "Poster_ID_664.pdf": "4D8F0374-9117-4F0F-A76A-2F3999BE478E",
    "Poster_Lesha.pdf": "C1D0F2B8-3EEA-492F-9C1C-41542607738A",
    "Poster_Longterm_FUS-STN_ENV_RMF_JO.pdf": "32E915AD-01AA-4D5A-AFD8-721BDC47B04F",
    "Poster_Monsour.pdf": "CCB26EFD-0E4A-4780-8906-BBDE837570C8",
    "poster_navy.pdf": "9158166C-FE8E-4186-9BD3-721509CAD02A",
    "Poster_PSD.pdf": "367801D7-DCC8-475F-B6E3-9033C73D7E85",
    "POSTER_ROSS CNS 2019 v2.pdf": "3FD7C3BF-5FAD-45DD-9219-2A2B0E9971FA",
    "poster_surv analysis.pdf": "2263ACD2-726E-4FB6-8EA1-4CA4F510BF1E",
    "Poster_Unruptured pediatric foramen ovale dermoid cyst_23-10-18.pdf": "1949A6D2-B031-4C69-A059-A9C55847FE10",
    "Poster1000.pdf": "E6227511-757E-4AEA-92AE-A32B9F91F62B",
    "Poster1022.pdf": "269CC9E8-DB8B-42A5-BB60-B02CF8F2A194",
    "Poster2.pdf": "848E7077-1128-4D02-91B4-9C52ED76EEA3",
    "Poster2759.pdf": "6C80383E-3504-427B-8FB9-CE0174178841",
    "Poster4.pdf": "D61374E0-D8A8-4D29-AD9B-954BC680D8EF",
    "PosterASSFN2020-05-10-Sherry.pdf": "194154C1-BBE6-4B3D-910D-CDD2FC3EC16D",
    "PosterGBFinal.pdf": "ACC193D3-5F4C-4564-8A7A-0DCB87A2D0BD",
    "Posterior Fixation of the Sacroiliac Joint Removes Less Bone and Increases Surface Area- A Computer Model Analysis.pdf": "CA98EDB5-16B2-4873-80EC-FCE403A72850",
    "Posterior Fossa Craniectomy with Endovascular Therapy of Giant Fusiform Basilar Artery.pdf": "8EAA8ACB-8611-443F-A4EB-86955C00C478",
    "Posterior fossa surgeries.pdf": "5BAB678C-DC8C-4ECE-9E2E-771E077F223E",
    "Posterior fossa Table 1.pdf": "B9F0B99C-D8CE-48F5-8279-5C5A7EEA1A5C",
    "posterior fossa Table 2.pdf": "048E4460-2671-41B5-912D-761560A95B5E",
    "posterior transfixation.pdf": "DA84CA8D-AEDF-4656-869D-3309E26B8E43",
    "POSTERLP.pdf": "F1F4C791-591B-4348-884D-A53BF028F848",
    "Posterolateral Endoscopic Excision for Lumbar Disc Herniation.pdf": "4E435477-CE2E-44D4-A2C5-835EFD75FFF8",
    "PosterPresentation.pdf": "41251BCD-8A26-49BF-A9EC-55D617FD7631",
    "Posters for Association Between Age and Frailty with the Development of Chronic Dysphagia following Anterior Cervical Discectomy and Fusion  .pdf": "83E9D72F-B17F-4F32-80AE-E8447AB70A5F",
    "Posters for Pre-Operative Radiographic Measurements for Predicting Loss of Cervical Lordosis Following Laminoplasty in Patients with Cervical Spondylotic Myelopathy  .pdf": "837D97F1-CC24-4049-902F-1933D533966E",
    "PostgraduateTrainingVerificationForm.12-12-2019_Whitney James MD.pdf": "7E4ED828-1E7D-4844-83C7-618693427167",
    "Potts-CV 2015-12-07.pdf": "6841E688-5CE4-4AF4-9752-0EDB0295C040",
    "POUR CNS Abstract_tja1_07202023.pdf": "205C8C15-3723-43DE-BDF9-0856BF7D6735",
    "Pouratian_CV_20_02_12.pdf": "010F5DF5-382A-4654-BBFF-5BF7C600AD03",
    "Pouratian_CV_Feb2024.pdf": "617C148E-B3ED-467A-97CE-4D32E3DC85C3",
    "Pouratian_CV_Jan2023.pdf": "964FF38C-C81E-4558-81EA-16DB21ED7261",
    "POUYA ENTEZAMI - CV.pdf": "F95A0533-E6B1-4ED8-9EC2-90367290CB59",
    "PowerEMG_ePoster_CNS2019.pdf": "3C2F7814-DD34-43C9-BF59-554138324858",
    "PowerSafety_ePoster_CNS2019 vR.pdf": "0C7C6633-A6B7-47DF-B192-5EFDD896A51D",
    "PowerWobble_ePoster_CNS2019.pdf": "A000068B-6E4A-4A1E-8EB1-5682C9A0DA03",
    "PPACStateSpecMedCoalitionNewsRelease120809FINAL.pdf": "55F04F0F-FA7C-436A-BC0E-F30CA31F10CE",
    "PPACSurgCoalitionNewsRelease120109FINAL.pdf": "1FADC92E-253D-4A61-985D-C76414529ABC",
    "PPage_FellowVerif.pdf": "8155A6F6-5293-4431-9A6C-33FC7534ED48",
    "PPatel LoGS.pdf": "0A105DBA-69AE-46EB-9CD7-BB894732D7A7",
    "PPSACMSSFAQs.pdf": "955AE219-4234-47F5-8883-BE81571F33E0",
    "PQRI2010Info.pdf": "DFEBEADB-3CB7-42F9-9D71-1814B53236BD",
    "pqrs_2014_what_you_need_to_know_neurosurgery.pdf": "40C6EDC6-96F9-4715-976E-D05519AD417B",
    "pqrs_2015_what_neurosurgeons_need_to_know.pdf": "AEE977ED-4A92-41E4-BADC-63EDCFB3F32E",
    "pqrs_2016_what_neurosurgeons_need_to_know.pdf": "44DD9E13-F2EE-4E20-9260-7D3D00F12957",
    "PQRSInANutshell.pdf": "6A1AE1BB-8482-42B0-8A90-11D3C71B60C3",
    "Prabhala - Certify MD-Currently Enrolled- Good Standing.pdf": "4EF73184-6F25-4AE9-A578-0B3936ECD055",
    "Prabhu for Amin.pdf": "7687BCC2-BECD-4A0E-8CE0-8CBC9D6702B3",
    "Prabu Verification.pdf": "29659F93-4D98-4C34-984D-058A56A2D91E",
    "Practical Consideration- Literature and Learning - A. Asthagiri.pdf": "C43AD901-D200-4AE5-9068-B5F49C0EB122",
    "Practice Licence.pdf": "29EEC9BE-3E7E-4055-8C2A-D24B054A50C6",
    "practise certification.pdf": "DC2551DD-DC4A-4F81-B5A3-620BBCB3D4D3",
    "Practitioner_Registration.pdf": "2005652F-3747-431B-B729-13C278AC4056",
    "Pradilla CV SOM format.pdf": "136C5AC4-727C-43AF-A8AD-8255C567F0DB",
    "Pranjan Gandhi - Letter of Good Standing.pdf": "14BE9D38-6D28-4A4F-A5DF-298EC1648CD2",
    "Prasanna Karki Membership Certificate.pdf": "D19D18F7-4D14-4AA3-867C-14A6D087B50C",
    "Prashin CV 08172017.pdf": "04058E6C-5902-4DA5-AEB5-600BF5C5E279",
    "Pratik Talati-Fellowship.pdf": "3D947218-4CDA-4E68-BFFE-0EF703C89B79",
    "Prazwal Athukuri .pdf": "5C4EEBD1-32DB-4714-93A6-3C1EA1FAE383",
    "PRE-blastBlurb62212AANS-CNSCommentsBCBSIL6-1-12.pdf": "71435415-C73D-4A07-8277-1FE56D0562CC",
    "Predicting High-Value Care Outcomes After Surgery for Skull Base Meningiomas.pdf": "8520C515-2E0D-41A0-96D7-D188B7DF45EF",
    "Predicting_STN_Center_AT.pdf": "6FB3C256-F1A2-4622-8F04-95F305D32FA8",
    "Prehospital_Cervical_Spinal_Immobilization_After.6.pdf": "4EC417B4-0503-4879-B53E-7DBAE31A2F61",
    "Prelim Final.pdf": "D446E287-DE10-4308-A883-0038FD4FB1A9",
    "Prelim Final_web.pdf": "77B1F8B7-0365-42B4-A7C2-54ABF129CF47",
    "Preliminary Agenda - Combined Complications Course 7.28.23.pdf": "89CC5C63-197C-4141-8F1D-A4B30F3DEDA7",
    "Preliminary Agenda for Website.pdf": "BECCD07D-0FD3-43D8-8CA6-4775312588FD",
    "Preliminary Exhibitor Prospectus _ 2023 CNS Annual Meeting.pdf": "A8AD9594-7DDB-4B6D-9AE5-131067574BA7",
    "Preliminary Exhibitor Prospectus _ 2023 Joint Pediatric Section Annual Meeting.pdf": "4AC54018-C8E9-4778-830A-7AC137C65AAB",
    "Preliminary Industry Support Prospectus_ 2023 CNS Annual Meeting.pdf": "EDBA59D1-E94A-432C-AAFF-274D6FB7509A",
    "preliminary program.pdf": "1620A594-ED17-467C-B81A-74B3F00C3ABC",
    "Preliminary_Programme_2021_V5.pdf": "DDBCCE73-6DD0-4F97-8315-E2497D6BCA2B",
    "Preliminary_Programme_Outline_MICN2022_V3.pdf": "04342FFA-C86D-415A-8BC4-3FC3A1C6B66E",
    "preop6.pdf": "318A793E-39BA-4081-9CEA-8889D26E8F91",
    "Preoperative, Intraoperative, and Postoperative MRI .pdf": "0C4B8518-A9D0-4B4A-853E-576000F3CA19",
    "PrePost Tables.pdf": "F5746F63-9133-4EF1-B93B-C3587ED4BD32",
    "PRES.pdf": "585D9964-8354-48B7-BB3C-5B8EA1F8956D",
    "Presentation Guidelines - ASSFN.pdf": "F8D910BA-8B90-4300-9F1B-9123C2958E99",
    "Presentation-CNS2019.pdf": "324414A7-2BF9-4E22-9DEC-A56572737AB3",
    "Presentation1 3.pdf": "EA9D809A-9E7B-4DF1-95EA-456167DC0AD3",
    "Presentation1.pdf": "D4694224-C434-4FCC-A962-6A9A93356248",
    "PreserveGMEFunding_Feb2013_ad.pdf": "3087E26A-F90F-4F87-9C94-339C7203B191",
    "Presidential Address 2009.pdf": "7B505380-E8AE-49A2-BEBF-C5B821F7D3BE",
    "PresidentObamaHCRletter021910.pdf": "F89421F3-FFB3-4494-AF30-98C0992ED37B",
    "Prestigiacomo, Charles J.pdf": "CFBF09B5-ACC2-4838-9D0B-2C283017A246",
    "PRF poster 9.30.pdf": "C83A2DEF-6A09-4E9B-8249-0A4777A42811",
    "Price - LOGS_CNS.pdf": "7E4D1436-97AF-4588-981A-A0026A42C702",
    "Price Meghan_CNS_Summer Medical Student fellowship_Sampson_final.pdf": "35991651-E6F6-4C42-BA90-ACF4C1B8A634",
    "Price-Moyer Alexis.pdf": "3F240AB0-C2CC-4F72-ABC0-8FDAC6B1A28D",
    "price_confirmation_letter_020317.pdf": "7B7B72A5-0430-40BA-9383-9380ACC48082",
    "Priddy, Blake.pdf": "50B28A6E-9364-4239-BCA0-75DEC0A12D56",
    "Prim CV July 2023.pdf": "27090CD6-8839-4F9E-9D32-45546BB90E3F",
    "Primary Spine Cord Tumors and Race_SEER_AE  (1).pdf": "948E11CE-E4A5-4290-B8AA-BDAC6A1EFDB9",
    "Priming ADSCP2 and BMSCP2 with BMP-2 CNS2019.pdf": "C63D6BFD-0D59-4BAF-BAF0-5A9169E2A0F4",
    "Print and Publication Advertising.pdf": "6CD67224-AC09-4008-B9D6-8F51862F4C8D",
    "Print and Publications Advertising_2023 Congress of Neurological Surgeons CNS.pdf": "345AC456-0AE0-49AE-A0BF-FEB2F95FA94A",
    "Printable CNSF DONATION FORM_PDF 2021 (1).pdf": "34F8CDC1-56C5-4F75-9216-2819F45340CC",
    "Printable CNSF DONATION FORM_PDF 2023.pdf": "16DB7708-BB97-4CD7-B67F-813523D13AA9",
    "Printed_Poster_Photo-Stacking.pdf": "B36E952D-412A-4AF1-A385-F193E9B78ABD",
    "Prior Authorization.pdf": "E35B83A3-39F8-4157-B7C0-7BA63A83D52A",
    "prior-authorization-consensus-statement.pdf": "EB0D321F-ED7D-479C-AC1D-BEA0E08AFFDD",
    "prior_auth_auc_sign-on_3-9-15.pdf": "BAA179DA-8791-4F69-9A81-A2088CBC9090",
    "prior_authorization.pdf": "B38993DF-186E-472E-A96B-11750AFA025E",
    "prior_authorization_survey_results-final.pdf": "342E20EB-78CA-4728-87AF-F720E7188558",
    "PRISMA 2009 flow diagram HANAN ALGETHAMI c.pdf": "02E15CBD-7F6F-4F25-A9CA-AA09297F119A",
    "PRISMA 2009 Flow Diagram_DSH.pdf": "B2FC80E5-AA5B-480E-A414-CB3E4A2DBF27",
    "PRISMA Flow Diagram.pdf": "C7ECBEDF-CB15-4F33-BA21-14DCDB0CB302",
    "PRISMA IPD checklist.pdf": "A74F0FCB-10E5-41EE-9BB0-AEA61EA76291",
    "pro zhang recommodation.pdf": "2F8DBF02-4BBD-4C3E-AEF0-844A164D731F",
    "ProcedureBreakdown.pdf": "40AD4326-C225-499F-B1E5-A267D798D875",
    "Produturi Gautam.pdf": "BF568759-3132-491B-86F4-AE991EE7E11F",
    "Prof D Singh Sir.pdf": "68993EC4-3434-4D5D-981C-4415EF4D09AA",
    "Prof Dr Tuncer Suzer.pdf": "AB6AC412-F549-4C8C-9D1E-442720CD1E59",
    "PROF GOEL RECOMMENDATION LETTER.pdf": "565777AF-CF89-43A2-AA11-08236D741162",
    "Prof SSK ; VS poster 2.pdf": "C459C55A-279F-46CB-984C-7A0D455FA8A9",
    "Prof Wu letter_cns.pdf": "EDA651EB-295F-4690-939B-5BB0A6DCCCCB",
    "professional certeficate-Byulhee Yoon.pdf": "13B33590-577E-458C-8790-BD732F7D15CB",
    "professional registeration scfhs.pdf": "80102538-5C76-466D-B412-757CF68B0AAA",
    "Professor and chief Certificate.pdf": "37CCDD84-CE39-4F94-A09C-F2D7E1B65514",
    "ProfHadani_Lior_Ungar_CNS.pdf": "423A7AB8-E377-482B-ADC9-62DC064E886A",
    "Profile - cns.org.pdf": "8E9B4687-3C56-427F-B907-438DC85FECD8",
    "Profile 1.pdf": "8E7F293B-8C62-423E-AE13-97FA682511C1",
    "Profile.pdf": "FB18C988-6511-497A-B351-E3E9526DB5C9",
    "prog 1.pdf": "2AED7B50-E3FE-4B17-8597-9BA4F2C68871",
    "prog 2.pdf": "EB9C458B-9B94-4EA2-9B1B-6E0081A6673C",
    "Prognostic factors for haemorrhagic progression of contusion after trauma brain injury a multicenter cohort.pdf": "9738C506-8528-43B1-BD29-2F1F05A537B7",
    "prognostic indic CSM.pdf": "4802E475-0D72-4107-B5CA-B19C8B5B044D",
    "PROGNOSTICATION SDM REVIEW 2022 BMJ.FULL from UMMS MacBookAir Rose.PDF": "4421FDDE-74BE-44BE-AA02-DD9BB0745C39",
    "Program Brochure.pdf": "6E3FF626-24AA-4560-B72C-B0BA5AC55EE2",
    "Program Completion letter.pdf": "7EDFB748-CC00-42AE-8A37-4621E2E2821D",
    "Program director letter for Data Science Scholarship - DR. LUTHER.pdf": "23635FB4-03D1-4EEF-B8B5-82D5C7EF543E",
    "Program Director Letter Komotar Eichberg 2019.pdf": "6FB42095-1DD1-4074-B372-C49EA7BB961E",
    "Program Director's Letter.pdf": "2F28D969-20A5-4816-88CC-4F8AAA96DDF8",
    "Program Interview Dates 11_30_2022.pdf": "CEA190AE-83A0-49CF-B4C7-064AB2BB00F9",
    "Program Interview Dates 9_19_2022 2nd Rev.pdf": "C68B0F6A-0A9E-453A-B879-D39AFF6598A4",
    "Program Letter - Michael Condell.pdf": "8E98DA1E-BAC6-4208-A6A4-8EB5585C75B3",
    "Program Manager - Santos.pdf": "8D503C5C-697F-40B8-9F0C-9F6D084CFD51",
    "Program Manager - Shapiro.pdf": "0B5F6BBD-A4AA-4B80-8EDB-B85FDCF33738",
    "Program Specialist Angelos.pdf": "76C1897B-AABA-4CFE-AA2E-7218CEC4E9BE",
    "Program Specialist Gagliano.pdf": "DBDFE00C-6BED-4259-AA38-1F2B5123DE32",
    "Program Specialist Hietpas.pdf": "87C32EBA-EC52-4F0B-BE0C-8DF27AC91C0C",
    "Program Specialist Hollister.pdf": "878B7978-F9B2-4F12-9E0D-3C0827637E68",
    "Program-2000.02.23-26.pdf": "742BD2B2-67E4-4288-8386-45EEA28BB26B",
    "Program-2001.02.14-17.pdf": "1506773B-E7A8-4D8F-9C73-5E9FC38CCDFA",
    "Program-2002.02.27-03.02.pdf": "498FE332-3DA0-484A-B727-D6251C3362C2",
    "Program-2004.03.17-20.pdf": "68B142E1-E729-435F-9ED9-11D26CB90D5D",
    "Program-2006.03.15-18.pdf": "FF4EE50B-5291-49AD-9FCF-17A174253CB6",
    "Programme Letter UWI.pdf": "C1E3F0EA-D426-4A6F-9A65-43C12CC1F8F3",
    "ProgramVerification.pdf": "CDA9CD18-5A19-4FC5-9153-CD761770C0A2",
    "progress report  May 2014 Dr. N Mohan.pdf": "9D118CAE-0E66-4412-A557-172A403270D3",
    "progressionofmoyamoyaposter.pdf": "F4F99E2E-27DA-4D5F-8AFC-6C2A2ABF4208",
    "Project Brenda Iglesias Argentina.pdf": "89885DE9-9BAE-47C1-B1E5-652E991BC458",
    "Project Description.pdf": "54F5E2FB-211B-45D9-B017-2C9C54D6FBAB",
    "Project N95 FAQs Final.pdf": "A524D8F6-590D-4840-8271-B15F7367AC94",
    "Project on Basic or Clinical Research.pdf": "9648B87A-1B6F-45D5-9421-D0A40EB10BA9",
    "Prolong Ext PLF-HT.pdf": "47D6F62B-D131-436E-9CC1-929882B73BB3",
    "PROMIS-PF Utility.pdf": "F17D9EE4-E001-4466-A1F5-4CFFF89523D8",
    "Proof of concept wellness in residency.pdf": "2173FEB4-C639-4A51-8B67-A80591C3196F",
    "Proof of Enrollment - Cuoco.pdf": "034254A7-9657-4108-96F4-0884CC1A0B87",
    "Proof of enrollment and good standing_TouponseGavin.pdf": "CEDDE5A0-29A9-4F14-993A-981A00737D95",
    "Proof of Enrollment for Anjali Pradhan.pdf": "7065598A-6EB1-4174-B40A-368D4837A76C",
    "proof of enrollment MD brooke Belanger.pdf": "63FDD93E-6E2C-450B-9178-B1E9357A76DF",
    "Proof of Enrollment Winkler-Schwartz.pdf": "DB905621-3848-4DEB-BA3F-2DC65E65EE49",
    "proof of enrollment.pdf": "D652C56F-8628-4E7B-B10B-65366CA03799",
    "Proof of Enrollment_Sp23.pdf": "F20E4914-79A2-4153-959F-534922902E8D",
    "Proof of Enrolment New.pdf": "8E87A2AB-E37F-4E1C-A008-8438646612C7",
    "Proof of NS resident letter.pdf": "83B19A64-15D9-4038-9E12-DA6ADFE529F7",
    "proof of registration.pdf": "A4E3D59F-3859-44FE-80A7-82F8D24F55F1",
    "proof of studies-2.pdf": "FF3AF6C5-B809-4A40-A0C9-BA9966C5D34D",
    "Proof_of_residency.pdf": "4D368E68-2046-45F5-8478-DBDCB34C1302",
    "proof2.pdf": "BCB4A82E-29EA-490D-A967-2C9CEA32A249",
    "Proposed 2023 Medicare Physician Fee Schedule Rule Summary 07-22.pdf": "230DEDDD-FAC0-4E80-9187-F51F61723217",
    "Proposed 2024 Medicare Physician Fee Schedule Summary.pdf": "84117C0A-1FAF-4F4E-818F-8BCAD216150B",
    "PROPOSED USE OF PROPHYLACTIC DECOMPRESSIVE.pdf": "5A0CFA88-2E9B-4690-A751-DCD3BC84D17E",
    "ProposedResearch_Youngblood.pdf": "954EFF21-F68B-40AD-A674-45176E2201C3",
    "PRORAM DIRECTORS LETTER.pdf": "159CE0E5-B22F-4B28-A86A-E39225190CF8",
    "prospective_timing_data.pdf": "9E34F1A2-7494-46B1-AB9A-A8A3E92B30A2",
    "ProtectingSeniorsAccesstoMedicareActEndorsement11813.pdf": "7DB37F42-1F8D-4E91-8122-5F39C940C04F",
    "ProtectingSeniorsAccesstoMedicareActEndorsementSenate21413.pdf": "20B0FD5C-B620-4AB8-84F2-F3311E29CFA9",
    "Proteostasis Missense.pdf": "1D2D8E09-186B-44E8-B3A1-9946C83AA358",
    "Provider Letter BN EM Final.pdf": "2D734FC9-D4F7-49FC-8B7B-7037E20C9E21",
    "provider_groups_to_fda_re_physician_directed_applications.pdf": "3390B9AB-C6B0-4993-BA46-DF6BC61ECB30",
    "provider_sign-on_letter_on_cara.pdf": "F760B835-6800-4FB1-AC09-F258541560D0",
    "Proximal ROM.pdf": "B1C2CF84-6896-4884-AD25-51AF074496F3",
    "proyect basic research merenzon.pdf": "F0E3FEBB-FE6C-4F7C-93CA-E99B103CBC11",
    "proyect Yasuda.pdf": "D483CDF0-D70A-4211-8570-0C981F3ECA08",
    "PROYECT.pdf": "9A3A5704-1CF5-4F0D-8A55-7DCD32933AC1",
    "PRozman-emailverification.pdf": "E23793DB-8EFE-4AC4-8140-534793BE0C0E",
    "prp-fewer-owners-benchmark-survey-2018.pdf": "51795997-67C4-4F11-860F-793759955506",
    "Pruitt LOR.pdf": "F49B078D-DDEB-4F88-810C-33EDF3809C29",
    "Prx Poster.pdf": "8207031D-7092-42BF-A363-0A24D311CF03",
    "PS for Research.pdf": "6D408185-E17D-4C5D-B0BD-4F089A527A22",
    "Pseudoangina Pectoris.pdf": "8BACCD9E-6070-43C6-A9D8-05ED0F6CE356",
    "PSIR CNS digital poster.pdf": "7B7BE910-FA0B-4840-8A9E-7396F196B0EF",
    "PSO Outcomes_Table 1_ Basline Demo, Risk Factors.pdf": "45EC5B6C-876F-419B-AF02-4A7875EDA555",
    "PSO Outcomes_Table 2_Complication Rates.pdf": "DBA07C85-91D7-47F1-865A-DF17EFE8BD7B",
    "PSO Outcomes_Table 3_Annual PSO incidence.pdf": "64BE321E-5CEA-4331-88AF-528A7A902B7B",
    "PSO Outcomes_Table 4_PSO per lumbar fusion.pdf": "F66F3771-15C4-4879-B3A6-BD344EEE750C",
    "PSU residency dipolma.pdf": "5D1AAECE-0B77-4E94-8518-4D55823C0A09",
    "Psychedelics.pdf": "FC702953-777D-476E-98A1-C769536BE0C9",
    "Pt Perception SCI CNS Tables.pdf": "FA463067-96E4-43B8-BA29-C7D5F2091164",
    "Publication Policy.pdf": "E7252201-5D61-4A1F-A024-EC2BD89D4990",
    "publications fellowhship application.pdf": "A9FD594C-BF2B-4154-9E3F-B536FC94C616",
    "Publications.pdf": "D5F6A9C1-407E-4309-A4B3-EB577DAEB4B0",
    "PubsAd.pdf": "48D24881-F1DB-4D5A-8552-04A650C90731",
    "Pucci Poster 2020.pdf": "2EA6E7AB-58F2-4A55-BDB3-A491F7F7B2BA",
    "Pugazenthi CV.pdf": "C9B10956-3480-4889-BB95-118CC4A500DE",
    "Pugazenthi LOR GZ.pdf": "8E301609-387F-43BD-AA4C-003B2E2E51DE",
    "Pugazenthi LOR Strahle.pdf": "AFDEC69E-CC0C-4B46-AE23-AA7EB978002E",
    "Pugazenthi LOS Strahle.pdf": "FFA91C1E-DD94-438B-9366-7DA1207E0964",
    "Pugazenthi Strahle Bioketch.pdf": "12F1C033-19E0-4C6A-89DE-DA857753AD0A",
    "Pugh B. LOGS.pdf": "9E1B2B72-1FAA-4AA0-8F05-3F592B72E2B8",
    "Pulvinar_CNS_Poster.pdf": "7ECF5357-1529-4D7C-B779-FA9D4A0B0766",
    "PupilScreen Indices_TBI_CNS_Table2_6.8.2021.pdf": "11083DDD-9EBC-4F47-BD39-197DDACA320C",
    "PupilScreen_TBI_CNS_Table1_Demographics.pdf": "24F4D69F-2CAC-4E1A-B135-67BD6B5A6D6C",
    "PUSHKRAJ CV.pdf": "8DD30B9F-1890-4671-8162-0E1B70F05265",
    "PUTZLER_Dillon_Letter of Good Standing_2.29.24.pdf": "5DB905B2-3C8A-4097-8C8B-55E3CA1CE420",
    "PXA_NCDB_Figure_1.pdf": "11BA9823-DB31-44B9-A2FA-1802E73555A8",
    "PXA_NCDB_Figure_2.pdf": "1440D069-2AAD-48D3-8EFB-D81621E87783",
    "PXA_NCDB_Table_3.pdf": "266B58E5-DF2C-4BA2-8A1C-FE7933E36CB5",
    "Qaiser LOS 1.16.23.pdf": "DF1D9364-4D62-43BB-B3FE-B590DD065264",
    "Qamar Haris.pdf": "F256FDE7-5C56-4C7C-967D-DA8EA6D3B5B1",
    "qazi_muhammad_zeeshan_supervisor_cert.pdf": "DC94D9C9-4056-4145-AB55-7E06D7E7E26C",
    "qcdr-mips-letter-to-hhs.pdf": "4FB76547-5962-4826-87DF-32BDD2DFFA9A",
    "QCTC1.pdf": "320329EE-2864-467C-BBBD-EE6B21A50934",
    "Qian LOR  Letterhead THOMPSON 2021.pdf": "D2B8DD36-6B6D-457E-8CEC-14CAB352671D",
    "QIframework .pdf": "E5BD27E4-804C-462B-9F69-0DA849A48495",
    "qin cns confirmation.pdf": "4C09D56C-68E3-4DCA-B086-2E08625EC629",
    "QMUL Confirmation of Study.pdf": "4736E0BB-7C63-49C9-B4C1-A2A5E9061786",
    "QOD 3-Level CNS Poster - Dr. Chan.pdf": "CEC7AB1C-DB06-4D1C-9BC2-558F9D2C5F0A",
    "QOD figures.pdf": "A0252D06-AE82-4B07-BE35-3E7B4B3A4CA0",
    "QUADRISaifOMSIVerificationofEnrollmentKansas.pdf": "2942B7E3-D4E5-42DD-9050-0AFD571CF088",
    "Quality Improvement - D. Hoh.pdf": "5D366A7B-378B-4A34-9DD2-29495EBC0DE3",
    "Quality New Techpptx.pdf": "5410A7B4-A08B-4B49-B214-D7CC1E3CF077",
    "Quest, Don.PDF": "21A0F485-75DD-4D11-BD2D-0EDB8A4C60C9",
    "Quest, Ilona.pdf": "E89F0787-52DE-4A5D-B2E8-8BE6D13182AB",
    "Quinones Local Therapy for Brain Cancer Academy figures (AANS).pdf": "EE15D243-7D88-4123-9E60-51E070FB12C3",
    "Quinones_Addison Enrollment Letter 6-3-21.pdf": "A837EEDA-7516-4D0F-B335-457417843F24",
    "QuinonesHinojosa LOR  for Dr Siddiqi.pdf": "13BFD835-5B00-43A3-ADF7-20ED0674071B",
    "Quinsey_SNS_Ewend - LON.pdf": "07C68E9C-840A-41B5-BCEB-3D1E3EDDB8FD",
    "Quinsey_SNS_Grant - LOS.pdf": "3363B0EB-6D48-49CA-A1E6-BED4E54CB3C5",
    "Quinsey_SNS_Oyesiku - LOS.pdf": "ADEDCDFB-265C-4C95-86F0-23435AA38464",
    "Quinsey_SNS_Selden - LOS - Updated 1.25.2024.pdf": "B7314F2C-DFB4-4718-AC67-9385FB2C9293",
    "Quinsey_SNS_Selden - LOS.pdf": "ADFDA4FF-41DA-4B54-A03D-1D9A38D6D271",
    "Quintero-Consuegra CV .pdf": "01FE25AF-5C8E-4B4B-9AAE-3709E49FD4C8",
    "QURESHI Aamir_CNS 2017.pdf": "0B060E67-2871-47E1-BB0B-EA4D8C8B3023",
    "Qureshi_Good Standing Letter.pdf": "C14E4EC7-D719-48EF-A615-837D9749F4AC",
    "Qusay Alfaori CNS Resume 4-29-24.pdf": "9BCD2EC5-F949-450A-B336-FD9411C7C8AC",
    "R Raio SNS Program Administrator of the Year resident letter.pdf": "D17B72A4-8F35-4744-AF36-C6E1AC5A631A",
    "R Raio SNS Program Adminstrator of the Year PD-Chair letter.pdf": "E161481A-8B39-4EC7-86B4-7FC07A97D454",
    "R. Calafiore Letter of Good Standing.pdf": "125DFBC6-08D8-4037-A186-387A1244063C",
    "R. Mikijanskis VER.pdf": "87EA39DB-3720-41B4-B297-23E935FED190",
    "R. Romano Good Standing.pdf": "7C8CFEB9-7688-4DE8-B02A-28B68026E769",
    "R_Abusuwwa_Curriculum_Vitae 2.pdf": "CBF942AA-1149-4962-AE9C-0106BC52049C",
    "R4292CP.pdf": "381B1A3E-F389-423E-A3EC-76EFEEC49BBE",
    "RA Fx and SCI.pdf": "08713C48-DADB-415B-A4E8-95870F299BA5",
    "RABIEL_FWLNSchol_2021.pdf": "2C0D762A-A57C-416F-9DA7-755EC6785700",
    "Rabih Bou Nassif-Fellow.EmailVERIF.pdf": "A8A8DC32-0F66-4C16-80F6-F73B097CDCC4",
    "rac_final_surgical_coalition_hr2568_support_letter_2.pdf": "0DCE5318-036C-430F-AC73-5BEC3616EECB",
    "Race and AIS_FV.pdf": "386E9DC2-D19E-420E-AD5B-4F04274362E7",
    "Race and LOS_AE_FV.pdf": "68A89583-E739-4336-B185-3A16557570B5",
    "Race_AIS_AE_Final_FV (1).pdf": "EB2A3FF5-416E-4331-910D-00798933EBE8",
    "Race_Discharge_Intradural Spine Tumor.pdf": "2D5F2F3D-72FC-48A3-A8EE-7E9EAD688C2C",
    "Rachel Blue-Fellowship.pdf": "CC8C77A0-AF14-4843-B72A-086BFF91AE96",
    "Rachel Hunt-Fellowship.pdf": "4ED406E1-227A-48FE-B410-B1F737E24700",
    "Rachel Jacobs.pdf": "C80E3D1E-C070-41CD-B18B-CAA58757F1AE",
    "Rachel Pruitt-FellowVERIF.pdf": "0027A3FC-20E1-4A69-8FA7-234DF54FC54C",
    "Rachel Vaughan-RESverif.pdf": "7C73954D-29D5-4A06-BEB0-28A2098DC9AE",
    "Rachlin_LOR.pdf": "01069D93-02CE-4B42-9BEA-2E741EB23CA0",
    "Radiation Oncology-Fernandes Castro-Gil Nuno-Initial Appointment-effective 10_01_23.pdf": "5BD54316-680F-46C1-BE36-59EEB278E11C",
    "Radiculomyelopathy_analyses.pdf": "B350BC46-F9D4-4F4C-93DF-E6E50241D38D",
    "Radiogenomics poster.pdf": "13E2556B-0840-409A-908D-61AB2BFA15A7",
    "radiograph ass cerv sub fusion.pdf": "92CE0AC6-C1BB-4EF5-8DA0-FE42936068FD",
    "Radiographic_Assessment.9.pdf": "E4492A0B-0E60-44D5-9622-19BE33AD7BDC",
    "RadionuclideShuntograms_CNS2022.pdf": "0BF9FDF0-87AB-4603-A298-083E1F4268E1",
    "Radmehr Torabi-RESverif.pdf": "F3AAF403-2FCD-4722-92F3-FFA3F5528607",
    "RadReport.pdf": "FC1793B6-06D5-4394-B20D-3C26A32C34ED",
    "RadReports_Poster_CNS23.pdf": "BB669C42-A4D3-4457-9184-AFF0F8376F05",
    "Radwan Takroni-fellow.email.VERIF.pdf": "FC56C335-0D11-482D-B44B-F76863D8B551",
    "Radwanski CV.pdf": "DFDB417B-EEF0-4CBF-A000-B42773D7741E",
    "Rae.LOGS.pdf": "B5F11CDB-1F26-462B-98CA-637381A56910",
    "Raenette David CV 02. 2024 CNS.pdf": "F0365201-0EDD-48B4-9563-CE493894FD88",
    "RAETV_CNS2023_MD_LES_Aug27.pdf": "55984F7B-9DC5-4320-96F6-8A7A043C70C5",
    "Rafael De la Garza Ramos-FellowVerif.pdf": "5AFBBE60-1A40-4DDB-9779-8AE57A575074",
    "Rafael Martinez-Perez-Specialty Board Certificate.pdf": "37BF5FCF-E4CC-443D-93DE-56588E96B07E",
    "Raffel, Corey.pdf": "35350829-E388-46F8-A274-10EE60C2FC5A",
    "Raghuram Sampath CV.pdf": "7BF82DF8-FF6C-48D0-A971-1929EC09CFA0",
    "Ragulojan.pdf": "E3577DA0-2647-40D6-BEDC-EFC00F8F9DE8",
    "Rahimov CV.pdf": "026EBBB7-F591-4CB2-9D1F-8F339D1735DA",
    "Rahman Mahmudur- MCW Enrollment Verification 1.pdf": "010B3DCD-1D4E-4A63-B369-EF7C58BC6999",
    "Rahman_Raphia Enrollment Verification.pdf": "1AB82609-7FF0-4245-9CE9-850264DED2A8",
    "RahulShahCNS.pdf": "C64E378E-6056-4DC0-BA84-A96704A41EB5",
    "Rai Karan - Verification of Student Status - 8.3.20.pdf": "FB805899-4BFB-4E01-9F40-5BF9796C4F61",
    "Raj Lavadi.pdf": "51017AD7-1F46-4CB8-87DE-C36F55941975",
    "Raj Shrivastava Recommendation Letter 1.pdf": "87B5DE4A-7B6E-4F73-BD1F-921F8E0DE8D8",
    "Raj Shrivastava Recommendation Letter 2.pdf": "F71E8852-C847-42D9-A653-B09379DB78A8",
    "Raj Shrivastava Recommendation Letter 3.pdf": "413FEB9B-4386-40EF-9E18-7694C6D1E05F",
    "Raj Thakrar-Fellowship.pdf": "8287CA47-B097-4431-9956-2FEA9115DCE5",
    "Rajendra's Verification Letter and CV .pdf": "FE58B2F9-070E-4BB7-B922-27A6FBAD81B2",
    "Rajendran_RUNN.pdf": "049E1918-52DE-40FC-97DC-949565371B39",
    "Raji's recommendation to CNS.pdf": "0A56200E-F4CC-4DA0-A0FB-689BA5593A31",
    "Raksin_2024_3 Things Not to Do for TBI.pdf": "B84D5B83-4D42-4A53-9986-6F2B0D332DEF",
    "Ramesh Grandhi MD - CV.pdf": "815BB5E7-671B-41DF-AF42-51521172E2A6",
    "Ramesh Grandhi Slides.pdf": "16D6B872-BD1C-4064-AAC3-5AB11B2ACE0D",
    "Ramin Morshed-FellowVerif.pdf": "28381735-3C1B-4CF7-B7CD-89BEAA15F9C5",
    "ramirez silva imss.pdf": "3D95FADD-0986-42F4-A997-2D258D213FA5",
    "Ramirez-Agudelo_Luis_GSL.pdf": "EA540A38-C4AC-4AF3-BC47-C0839236033A",
    "Ramirez_Juan.pdf": "A28CDB40-612E-4BA6-A20E-54B0BA5121FB",
    "Ramirez_Landeros_Ve.pdf": "5E26D3B9-AB0C-48EF-867C-B0C9D9D8DD58",
    "Ramos Delgado.pdf": "72801B63-2521-4F64-B531-D94246348C29",
    "Ramos Gabriel Enrollment Verification.pdf": "74724D24-8507-4A6C-9861-C216C568F0C2",
    "Ramos SNS Nomination-September 2021.pdf": "2DD76E78-6ED9-4CEE-88C5-D3FD5B77F93D",
    "Ramos.pdf": "31D7F442-059A-471D-B128-F97C3DA6983A",
    "Ramya_8.25.22.pdf": "47F91C3B-EBDD-4BFD-9309-3DCB18740A15",
    "rand_survey_surgical_society_sign-on_letter.pdf": "85DD9EB4-4E74-42A7-A2A6-6DDA07B1EC9D",
    "Randaline Barnett-FellowVerif.pdf": "F0005F48-695A-4497-8F6B-753289BEB2B4",
    "Randy A.pdf": "5BFEBF6C-7F71-4400-995C-CA3B457E3A1F",
    "Raney Poster.pdf": "CC7AE31A-5F0D-4B91-9D3C-854C9FF41806",
    "Rangan_Vasundhara_CV_2023.pdf": "6D7276E6-6FA0-4F37-A31A-0D4D7863FB1C",
    "Rangarajan Shreya - Letter of Standing - 20220207.pdf": "251F4006-C2B8-452D-B95B-F94FAE3A8584",
    "Rangwalla Taiyeb_Letter of Good Standing.pdf": "7DE22AF7-C92E-48FE-A969-D6DD0A8AB4BD",
    "Rao.pdf": "D2697A3B-32A4-4298-9804-B27C1DBF46A6",
    "Raper Verification.pdf": "99EDB635-2474-4FD5-89C0-06DB64C7BAB5",
    "Raphael_Bastianon_Letter_of_Offer-2ndfellowship.pdf": "B07A9A1C-CC0B-46FB-A471-95468DC52476",
    "Rapid Deterioration of TBI Patients_Tables_042820.pdf": "E99B7A8D-9768-44BE-B833-5AC130107EFF",
    "Rapp Verification.pdf": "D4831DA2-C17D-41DD-992F-73A9E769A4CB",
    "Rappoport Digital Poster CNS.pdf": "1A6713A7-C40A-4E80-9625-7A958FB3FBA2",
    "Rare-association-of-traumatic-diaphragmatic-her_2021_International-Journal-o.pdf": "882DF9EB-0A14-44FA-9C01-4592DF5618A4",
    "RASCV-2021-Final+Biblio.pdf": "859AD89D-F04B-45DC-B1C5-9E66C6198F6C",
    "Rashdan Dean OR23CL .pdf": "759CF1F4-9A3F-4E59-ABB5-1CBA8CEC3902",
    "RATE OF RECURRENT HERNIATIONS AND REOPERATIONS FOLLOWING NON-ENDOSCOPIC MICRODISCECTOMY FOR SYMPTOMATIC PEDIATRIC LUMBAR DISC HERNIATIONS_ A FIVE-YEAR SINGLE SURGEONS CASE SERIES AND COMBINED SYSTEMATIC REVIEW OF THE.pdf": "F56D4C37-9198-4E2F-A8AB-DC7F2A25A44D",
    "Rate of RN associated with isodose volume.pdf": "492D1483-252E-4ECD-A2B5-3D02ACADCE36",
    "Rate of RN associated with maximum tumor dimension.pdf": "FECDC44C-622B-4F01-B7CA-DAB12CBB5F08",
    "Rate of RN associated with tumor volume.pdf": "5C5471E3-779E-471C-83EE-0243B3D32CA0",
    "Ratliff_Jody Leonardo - nomination letter.pdf": "9FF29D59-4337-49C7-99FD-299B6C8D1FFD",
    "Raturi Vid.pdf": "2DD50EA5-9B87-43EB-B0C7-B03EBE9DB6A0",
    "Ravi Singh.pdf": "427199DE-9C14-40C0-956E-541940DFFBD3",
    "Ravindra Active Duty VER.pdf": "517B3D45-4F21-4468-9942-460564B2C150",
    "Ray B Jun 2016.pdf": "E180C188-FBC4-4F26-806F-AF83E38995BA",
    "Raymond CV.pdf": "437181B2-AF35-4837-BA15-69D2147DC2C5",
    "Raymond Michael Meyer Residency Certificate 1.pdf": "921E03B6-56BB-4E23-9C5C-348762149A4E",
    "Razak Student Status Letter.pdf": "125B16A3-EE6B-4D7E-AA8E-C4B38517542B",
    "RBurke CV 2.16.23.pdf": "BE2AEFC0-8CD9-41DB-B898-F9BF0FE5F4D3",
    "RBurke.RESVerf..pdf": "919A5F85-3533-4E3D-B954-A15C106145C8",
    "RCC2_figure_1.pdf": "E9175683-AA32-4863-AA29-591E8B6CF144",
    "RCC2_figure_2.pdf": "C1A16D4C-43A5-4999-8FF7-38F35D2910E8",
    "RCC2_figure_3.tiff.pdf": "17191873-EC8F-452E-8DF7-E3D6021B3E85",
    "RCPS 2022.pdf": "1DD473AA-015F-4F8F-9CC1-268EC41CE5BF",
    "rct wellness ontervention.pdf": "08B0FF64-22F9-4678-B3B3-0947C4F95F4A",
    "RCTDiscontinuation.pdf": "3025CAA9-6B0E-4D1A-8716-5D606661E90E",
    "RDLGR CV Einstein.pdf": "5A1D83AC-F1C2-454E-89E7-7D11B3436300",
    "RDLGR CV.pdf": "31CBB0A9-4EA4-4CD5-8069-99990EC0E500",
    "RE NYITCOM  EPP  Welcome Accepted Student-Chukwuyem Ekhator.pdf": "126092FD-78A2-4E22-BE15-8550BB621A6F",
    "Re_ CNS Membership- Incomplete Application Follow Up.pdf": "19ABAC89-8541-44D3-8AE0-69415A53F7C1",
    "Re_ Verification of Enrollment in Residency Program- Dr. Armaan Malhotra.pdf": "136A12B1-E476-4F1B-AB6E-8BF680A1D578",
    "RE_ Verification of Enrollment in Residency Program- Dr. Juan Ulises Villanueva.pdf": "A116D014-DCB6-43B9-9D0C-633EEC3E0C0A",
    "Re_ Verification of Enrollment in Residency Program- Dr. Rodrigo Fraga.pdf": "C195D51C-815A-4414-AB55-659B00C8E798",
    "RE_ Verification of Enrollment- Dr. Christian Daniel Barrera Maldonado.pdf": "D36B8C80-F1B4-4054-A50C-A0D773EEE733",
    "Re_ Verification of Enrollment- Dr. Christian Ramirez.pdf": "FF011CD1-96A4-4CBE-870A-7BA3EB04CA6E",
    "RE_ Verification of Enrollment- Dr. Cody Wolfe.pdf": "1A56D488-F0C4-471F-BA19-6856A4D868BC",
    "Re_ Verification of Enrollment- Dr. Federico Chavez Peon Perez.pdf": "9F786C15-3E51-4DF0-9020-045458C0EB57",
    "Re_ Verification of Enrollment- Dr. Mauricio Frias Paz.pdf": "7E2B3474-754C-41D6-AA31-5C0E25DCE55D",
    "Re_ Verification of Enrollment- Dr. Melissa Lannon.pdf": "C5E70BEC-1629-4D2E-A9D3-311B5A3D52D5",
    "RE_ Verification of Enrollment- Dr. Ryan Palsma.pdf": "AF90E0B7-A104-449A-BF7D-DC99C9320EF3",
    "RE_ Verification of Enrollment- Dr. Ryan Sandarage.pdf": "21878289-9E47-41EB-B58D-A18C3D014801",
    "RE_ Verification of Good Standing- Anthony Schulien.pdf": "0CC23EAC-B2FD-43FE-89C6-5639717A48CD",
    "Re_ Verification of Good Standing- Jenny Perez.pdf": "A3953E84-CB12-4C01-9F08-80DCEDF73490",
    "Re_ Verification of Residency- Dr. Leonel Ramirez Abrego.pdf": "0FDE47F9-CBFB-4FAC-A114-3D50E95BDC73",
    "Readability of Online Materials Related to Craniosynostosis  .pdf": "0CB8B7FA-CCA7-4ACE-A766-D1867F1F7C9C",
    "Real World Analysis of Outcomes for SyNC_Poster.pdf": "228DC6C7-7F6E-48DB-AAC2-5D334519B25A",
    "realtime_US_brainmachine_interface.pdf": "5158B1D9-3837-4AF6-978A-60C0A511E3A5",
    "Reardon_Taylor_LOS for CNS membership_pdf.docx.pdf": "B8139804-4F57-4AAD-ACEF-AEDBA55D98C7",
    "ReasonOverall.pdf": "7FBEB300-F9B6-4327-8A4C-C9C609D2F17D",
    "Rebchuk_verify.pdf": "58FFF493-1E73-45F8-A4D5-076955DB2FE3",
    "rec - rstromblypdf.pdf": "898F4347-1C2F-4B10-9BA0-5A3A152F63F9",
    "Rec Letter Reem Elwy.PDF": "E339C6D0-DC7A-4686-A1F8-9CD3342239DA",
    "Recc Lett MZA for ACNS membership.pdf": "0202E4AD-F403-4410-A537-29017414EFC6",
    "reccomendation.pdf": "773CFAB2-F1B7-4A3A-8F4C-06090D896A29",
    "RECEIPT CNS board review.pdf": "11EF36DB-F700-4EF0-AF84-D03239008AED",
    "Receipt for dues - S. O'Leary.pdf": "B78682DC-14BF-4A78-88B0-DF4A1327F6B8",
    "Receipt NAM Dr. Low Peh Hueh.pdf.pdf": "0CF7E8ED-F431-4C17-B9AE-B51D17395A49",
    "Receipt of NSA.pdf": "E7193633-7673-4D13-B5E5-C1B707C47657",
    "RECENT CV.pdf": "D5A0AFEB-4D6C-48FC-A5E7-DB2D67A74E19",
    "recent LMS Completion analysis.pdf": "89AAD5FF-6463-4E49-B6FB-A6E642CEDEB2",
    "Receptionist.pdf": "2E2C2424-7395-4682-8048-7211424FBB13",
    "Rechberger LOR Weinshilboum.pdf": "0F94BE5A-AA8A-4534-979E-D85E7001A174",
    "Rechberger-Development of a high-throughput drug screen for identification of targeted therapies in diffuse midline gliomas with the H3K27M mutation.pdf": "8D1BC883-658A-499D-AA62-8EF73E335F3C",
    "Rechberger-Drug-tumor residence time drives efficacy of convection-enhanced delivery in H3 K27-altered diffuse midline glioma.pdf": "F12E51EA-4561-4DFC-A44A-C33BF3C2FD7B",
    "Rechberger-Radiotherapy plus concurrent IL13Ra2-directed convection-enhanced drug delivery for diffuse intrinsic pontine glioma.pdf": "DF578A35-EE5C-47F3-A202-813E8A98E4DE",
    "RECK.pdf": "ACEC71AF-B86C-43EC-A9BE-4C9DBEF5713F",
    "recom.pdf": "8E926F2B-8345-464C-81A4-28EBCB42EB63",
    "Recomendation Letter Dr Mirna for Harvard.pdf": "8E9830EB-4CAE-4F08-827A-27007E6D524F",
    "recomendation letter from suassuna.pdf": "7DED7B58-65CD-4887-BE5D-EA6D15834F32",
    "Recomendation letter.Lux.pdf": "B2A663D2-466A-4636-A958-6EAE5452EB19",
    "Recomendation letter.pdf": "81868B4A-B4AC-452E-86F4-5D2D89E20F30",
    "Recomendation.pdf": "C55C32C9-8EE0-4E7E-94EA-5B2FE9982A83",
    "Recomendation_letter_AANS.rtf.pdf": "7DC8CF15-A69B-451F-8C65-2A21F79F5544",
    "recommandation EANS.pdf": "3A295821-F945-4366-8621-0A0A9CE8CF03",
    "recommandation for CNS(Dr Wen)001.pdf": "55242B5F-2887-4CAC-AEED-2A44F2C76696",
    "Recommedation Letter - Siddha Vetha Center.pdf": "6C4E9AE9-EEFC-4891-AFBC-8F474071520E",
    "recommend khalili to harvard.pdf": "A2744850-EB45-4B20-BF1C-172FB6092F2B",
    "Recommendation - Arsene Daniel NYALUNDJA MD.pdf": "5C2BA0C8-0A2E-4252-BE64-6CE02B156C85",
    "RECOMMENDATION 2021P Lawrence.pdf": "D11F0B0F-9B2C-4D61-A2F1-B054507C1A09",
    "recommendation dr abdihafiz.pdf": "712DF958-7C3C-4E97-82AE-F170FC5D1EAE",
    "Recommendation Jarmula for SER fellowship from P Recinos 4.30.2021.pdf": "4285F832-4F72-408C-9A07-72BE40953A83",
    "Recommendation letter - Dr. Okanga.pdf": "5FF34D1C-F6EC-4571-BCCE-D7D2F1151C4E",
    "Recommendation letter CNS MB IOP 2022.pdf": "1EE8777D-CE89-4DAE-949F-634B71CB5888",
    "Recommendation Letter Dr. Munoz.pdf": "1C1FC92B-551D-4DB9-ABF4-D56B9F122FBE",
    "Recommendation Letter for Nasar Khan (2).pdf": "64A34403-BEE8-43B6-8101-A06C668B84FA",
    "Recommendation Letter for Olivier UWISHEMA.pdf": "604E4F7B-8BEE-4BB6-981C-07AA1ED610EC",
    "Recommendation Letter from SNS Member_Bambakidis_Stippler.pdf": "FA5B6470-48B9-4437-A787-3F6EBE1B4ED3",
    "Recommendation Letter from SNS Member_Chambless_Heth.pdf": "AE0F3AA3-CB23-4813-92DB-586BF5653A34",
    "Recommendation Letter from SNS Member_Connolly_Pandey.pdf": "9544E081-5AF9-44C4-B11E-F569C70F4CD9",
    "Recommendation Letter from SNS Member_Nakajis_Liebelt.pdf": "12F139FA-AFB4-4A41-8029-E6DCA3BD902B",
    "Recommendation Letter from SNS Member_Zipfel_Shrivastava.pdf": "E0AB1D59-1651-499F-B3CC-EC790ED0F93B",
    "Recommendation letter from trainig program Director.pdf": "E6B8B705-ED99-4C35-B11D-98F515441BCF",
    "recommendation letter Harvard.pdf": "03649AD2-A918-47FC-BA75-C351CF5B5A17",
    "Recommendation letter of Jesabelle Dominguez to CNS.pdf": "B3EC1CFB-70F2-4FFF-997F-9CC9D8413B27",
    "Recommendation letter Raphael Santiago.pdf": "C261E358-65E8-4EDC-ABF7-925A31903ABD",
    "recommendation letter sherif PDF.pdf": "52876116-4100-4CBB-851F-206F93A86AFF",
    "recommendation letter.pdf": "9B307BC6-C82B-48F5-9409-0124C061DDAF",
    "recommendation lettres.pdf": "2E2084D9-5B37-4409-9F3E-C79626A9D7AF",
    "Recommendation LR.pdf": "85EC63F9-287C-42A3-A022-C378CF48AF8F",
    "Recommendation to Rahimov.PDF": "B76E7B49-74D3-4237-9438-3583BEA6BBE9",
    "recommendation.pdf": "1F19960B-73AD-4AC7-A702-8A6CE8AC8C26",
    "Recommendation_Modelo_assinado.pdf": "AAD62AC2-AEF1-49E4-982D-E90D7C227771",
    "RecruitmentINTNLpostcard5USB.pdf": "8C6C482F-4F9C-41E0-B37B-169FAB476615",
    "Recurrent Apoplexy Poster 60x42 CNS_PDF.pdf": "E34864B2-C28C-40D8-AD8F-255D5CA44065",
    "red 2011 Scientific Program_FINAL.pdf": "F76CD881-615A-4334-81E4-C2C271A4E181",
    "red 2012 Pocket Guide_FINAL.pdf": "51CE81E8-EFB6-479E-A454-38D73E5DE29A",
    "red 2013 Pocket Guide_FINAL.pdf": "5A69BDB3-B07D-45FE-9849-7FBC651C36A4",
    "red Program Book from LCP_FINAL 2010.pdf": "BC7CE033-A60F-404F-9B08-7F95CB871068",
    "Reddy-LOG.pdf": "791388AC-DFA0-433C-845D-5725C86F1F30",
    "Reddy_abhinav_gsl.pdf": "1847BB57-BDA0-46C2-8429-317287341D04",
    "Redi Rahmani-FellowshipVerif.pdf": "FA2C885C-5997-466B-B83E-65F59CF976B2",
    "Reed-MurrayTrauma-EMSLetterToSebeliusApril2012.pdf": "21222CD4-AD06-420B-BFA2-D96F298CB0DE",
    "ReedLK CV 2022.pdf": "9B996985-235F-412A-9F14-E6C6427F5405",
    "Reem Elwy CV.pdf": "D18E5096-0B01-4347-810E-728DEE943F1F",
    "Ref Akmalsb.pdf": "C0716E5B-19FB-47B9-AC64-BB4F9CAC8216",
    "Ref.Letter (1).pdf": "CDECA096-113C-4536-935C-032E36E13975",
    "Reference - M. Acocella.pdf": "14E46F64-DA95-4619-8EA4-6E35E446A887",
    "Reference - Sankhya Prakashvel - 29.11.2021.pdf": "E304D080-812E-4718-B733-CAEB3F73CF06",
    "REFERENCE FOR CNS.jpg.pdf": "A941F0D8-0B7E-470D-95F0-5094055D9695",
    "Reference for Corliss Neece.pdf": "A3C5DA9C-9F6D-461F-BB9E-49A8CB6C60BE",
    "Reference for Craig Caldwell.pdf": "DF97CE13-58B1-4726-893F-0D5E9D9B1F49",
    "Reference for Daniel Wojcik.pdf": "73AD63DC-42A9-4733-8438-18364F3B86B9",
    "Reference for J. Farley.pdf": "D71A8149-13DD-4250-B2B8-DF26E222456A",
    "Reference for Lucas Acton.pdf": "A416C806-FF60-45B3-AA1A-D8D929846C39",
    "Reference for Maria Vessell.pdf": "77EA22D8-A1E5-4F40-9988-66FAB55BB81A",
    "Reference from Dr. Bambakidis.pdf": "6328C42C-7210-4FB2-92ED-BAA3685816B3",
    "Reference Letter  Reem Abdulaziz H Albuhairan .pdf": "8CA971A5-53C8-4C76-B725-9BB41385AB55",
    "Reference letter 1.pdf": "9029479B-FF9B-436F-8D15-752108F19A05",
    "Reference letter 2.pdf": "A4B05A7F-3E23-48FA-A2F3-6428D215398B",
    "Reference Letter for.pdf": "B9271D86-F8A9-4C16-B9F3-216B28A40666",
    "Reference letter from Dr Farrash_Fatima Fakhroo.pdf": "A44BB676-1DBE-492F-9716-60409509E033",
    "Reference letter.pdf": "E665EC12-883C-4A04-AC45-F8D98CCEE77C",
    "Reference Seltmann.pdf": "1727FAF6-CF39-4707-AE14-81338411AEDE",
    "References .pdf": "590EB387-9736-453A-8066-A51E0B23492D",
    "references CNS 2021 abstract.pdf": "D1D23F6F-9EEE-4A37-B03E-3483EF94000E",
    "References cns abstract Yasmeen Elsawaf.pdf": "14EEC9B1-BABA-433E-9EFE-11DBD0D1C04E",
    "References, ITN.pdf": "82261A22-FEEC-46E8-A8EF-6DF341547C52",
    "References.pdf": "1D5665E7-B915-41E7-A9BD-04163259F2EF",
    "References_DCLN_CNS.pdf": "E47CAEB5-E08E-46ED-BA15-7A4946A4992B",
    "Referencia.pdf": "B17DBF6F-BF4C-407F-A102-4973744614CB",
    "ReFlowCNS.pdf": "BA51A7A1-0E44-44A5-98F0-E9A2918F29DF",
    "Reg Appl 001.pdf": "B2480E2A-8361-426B-AAAC-722A5FCB9965",
    "RegCert.pdf": "BD94A913-17A0-40B9-A3D5-A88B31DB818F",
    "Reggie Fong-FellowVerif.pdf": "6164B2A5-DCA2-449B-B44C-C1189516E9C9",
    "Region AIS_AE_FV.pdf": "A82304B9-7A76-4698-A71E-A35E48E62DFE",
    "Registrar Clerkship.pdf": "A52C8637-58FD-480E-8DD4-8B8C5B4A15DC",
    "registrar_letter.pdf": "4D3A49A8-275D-48C4-971F-E1361CB6A28C",
    "registrarletter.pdf": "B5EFB26E-1D8C-47C0-9BCE-5E984750B7E4",
    "Registration - D. Dubinski.pdf": "699B0FE0-DDFD-4C3E-9C94-BE56F3072DA9",
    "Registration combined_arun.pdf": "F42076DF-5CD4-43DA-BB75-6AEF3116D335",
    "Registration status 2021-2022 year Kelly Shaftel.pdf": "010B5D40-A815-4527-ABAE-2036D6419DD8",
    "Regression.pdf": "58DCB14F-9455-43C5-BAEA-341500B9DE9E",
    "RegressionASCCNS.pdf": "3D70BA7C-CC52-4284-B040-E4CE4B1BCE96",
    "Regularidad 2023 abril-Guillermo Agustín Nuñez.pdf": "2FE8A050-7ED1-46FA-8D49-BDFD6A00A085",
    "Regularidad 2023 abril.pdf": "BF981B84-CAD6-44B2-BBBD-27BDA3DEE04B",
    "regulatory_relief_letter_-_4-12-17final.pdf": "31E972FF-C8B1-4091-A66E-099AEEC1D408",
    "Reisen Breanne - Enrollment Verification Letter - 4.12.21.pdf": "29FE2FAE-239B-4E3F-BF83-99D0BC745D17",
    "Reivich Seligman and McBride. Master Resilience Training in the U.S. Army.pdf": "61659458-0C72-4295-9832-018698F5A662",
    "Rekomendasi Prof.pdf": "0604B11E-FAE0-40A4-97AD-B48D78A4CB2C",
    "Rekomendasi SIP Dr.Arwinder Singh,Sp.BS(45).pdf": "72842C1C-5610-47D5-B2DB-3FD63BE93CA6",
    "Ren Alexander - Good Standing Enroll.pdf": "C168D02E-6CA5-4710-897D-F70D1391B4ED",
    "Renedo_FWLNSchool_2022.pdf": "074874D8-C241-4357-A75F-21C55481B1DA",
    "Renne Verification 2019.pdf": "FAF0AEA2-9E47-494B-ADF9-E8534C43040C",
    "RENNER CV.pdf": "4A73E881-B9F3-44CE-80C1-E2ED8DCDB806",
    "Rennert.Robert - CV - 03-30-2023.pdf": "5A3AA9B9-A606-4F50-8485-5A33A116B03E",
    "Rentzeperis Frederika I.   - Enrollment Verification.pdf": "13030C84-CF79-4CD8-B689-8934047ADE91",
    "Repair of Brachial Plexus Injury Utilizing a Novel Technique.pdf": "350B90C3-FE10-4FE2-B7AD-7BC4B897686E",
    "Repeat laser interstitial thermal therapy (LITT) after failed LITT amygdalohippocampectomy for refractory mesial temporal lobe epilepsy – a systematic review.pdf": "2AD8C2B5-260A-4B04-90B1-DA499B07A423",
    "Reportafab4d8c-5af4-4422-84a0-b1a3ce6d7d6c.pdf": "E9155963-A2F6-47B6-B5CF-87D77F1E44F7",
    "Request EnrollmentVerification_RFaraj.pdf": "3EA553A2-6D7E-4896-86A3-85556BC7BFE5",
    "Request for Proposal Terms 2017_2015-11-17.pdf": "DD33A552-A215-4BF0-A439-9F792AF3C912",
    "Request International Vista Resident Membership.pdf": "66141546-9C6C-42A4-925B-42F4488368D9",
    "request.pdf": "BE6E330D-0AAB-42A5-9760-8BAC2B206822",
    "Res Ver Garcia and Miller.pdf": "76326DDF-F502-48A0-B27A-0EE3CFF9EFD8",
    "Res VER Sumdani.pdf": "59C26FDF-D6A7-481D-8531-46EBAC4E4894",
    "Res Ver UTHSCSA.pdf": "EC3DAC9E-2DA7-494C-BD31-20EDD80B016B",
    "Res Ver VanHorn.pdf": "A155F697-6D35-45C0-95C5-D31A1B754BCD",
    "RES.verifRecommendation letter Raphael Santiago.pdf": "C874A72B-647B-48A8-A7F1-000A387B9C49",
    "Rescheduled Course Agenda - 2023 Fall SRC.pdf": "5F2299DD-DCA2-456C-8148-513D2C97B3D5",
    "Research Awards 2018 mw.pdf": "8E9D8B26-87C8-4830-A9FB-23DE99059D88",
    "Research Description ADN.pdf": "DFFBDB2C-E8F7-45C5-9A02-BF81F734BDE4",
    "Research Description.pdf": "71041DCF-4FDC-4916-A054-2D94BE5DFA39",
    "Research Fellow Letter.pdf": "A0AC6C39-B055-4CF2-BD78-2E08C5AF0189",
    "Research in Seroprevalence SARS COV 2.pdf": "C0F24A8B-98B3-4583-9298-942C7BA170CB",
    "Research Interest Byron Hontiveros NS 2021.pdf": "82697C9E-0A25-4E3D-A68F-EA0C5444A6C7",
    "Research Poster CNS 2019.pdf": "35CC05D6-B44D-4460-A2B5-1F12D736A8C5",
    "Research Project - ONC.pdf": "70649518-23F3-4CED-B53C-CC026ABA1CD2",
    "Research Projects.pdf": "4BCFAB3F-BE1E-4502-93E1-B0CE93036A3A",
    "Research proposal.pdf": "1F0BF72A-89D7-4C78-AD61-30C913A4C3FD",
    "Research Proposal_Rahimov.pdf": "EEEC3667-3F00-4E6B-9880-232C2C8B0209",
    "Research Resident Fellowship.pdf": "126F8835-5F69-4433-83D3-6010CC8FB676",
    "Residency acknowledgement.PDF": "990330FD-4F90-4891-8195-20C60088E3CD",
    "Residency and Fellowship Completion Certificates- Ndi Geh.pdf": "3E5F3E77-3C6B-4E41-95E4-8B9C97A4D68F",
    "Residency Certificate 2016.pdf": "85EACB83-0C1A-4858-B52D-043654837645",
    "Residency Certificate and Spine Fellowship Certificate.pdf": "4A518817-C16D-472F-880E-685647152B07",
    "Residency certificate diploma U of MI_Bruzek.pdf": "06375566-3CA9-4CAC-892C-EFC1C8BB3B08",
    "Residency Certificate.pdf": "6AE6506A-891F-4005-ABBC-0E187A99FB10",
    "Residency Certification.pdf": "E0755C2F-D1BD-4349-91AB-421B47497F48",
    "Residency Confirmation.pdf": "90EC5170-27BC-4844-A1F1-2CBEF237B12E",
    "Residency CV.pdf": "60037C65-94EA-4490-8498-886DF69216D2",
    "Residency Diploma - Liounakos.pdf": "0BA57CA5-C0B1-49F0-8C48-631BAB9EA1E0",
    "Residency Diploma - Reduced Size.pdf": "BA1281B1-C98D-4940-A356-FA52F47C8240",
    "Residency diploma - Vikram Mehta.pdf": "B376386F-4540-41DF-A120-CE07EDB640B8",
    "Residency Diploma Kim Michael.pdf": "3B6202B9-B996-45C1-87DB-32F4018EE76D",
    "Residency Diploma-Anthony Alvarado.pdf": "FC30A19A-A6F0-46C5-AA6B-740783A4CD9B",
    "Residency Diploma.pdf": "074E04B7-A279-4486-97AA-44611D42C2BD",
    "Residency Enrollment Verification Letter.pdf": "A557CEE2-06C7-4361-8095-CF5FDD6EEC03",
    "Residency in Neurosurgery Diploma - translation.pdf": "D8FE4426-3016-4379-BCB7-DB7025CE881A",
    "Residency letter.pdf": "F75C030E-27A2-4847-A1FA-52CE8317B09B",
    "Residency Match Poster.pdf": "54D8DEE4-0085-447F-ACC1-0F2C6A97A1B3",
    "Residency prof.pdf": "5838540C-8575-4C54-87F2-78544687B58D",
    "Residency Program Berkay Paker.pdf": "C31F5D47-2F65-45A2-ABB2-7790BD588CFD",
    "Residency Program Deniz Kiliç.pdf": "4D9F3C44-8C02-48DE-9384-039C36640EE7",
    "Residency Program Deniz Kilic¸.pdf": "7E51036F-1E61-488D-8974-C862C9EED227",
    "Residency program letter.pdf": "B7BC15E9-EFBD-4390-BFFB-ACE6D4C96A63",
    "Residency proof.pdf": "EEA6E32F-2B38-41A3-BD92-67DC03BD0421",
    "Residency Verification.pdf": "81061B3A-365B-4AAD-9F0D-3A6407DE76B4",
    "residency.pdf": "39BC4B0F-6A60-4BB4-B062-152B430955B3",
    "ResidencyCertificate.pdf": "E98F9EA7-12AA-4111-8A40-4BB9E5D904C4",
    "ResidencyCompletionVerification-Bernstein.pdf": "2541E2E6-ED3F-46D5-A028-50E7686F2D6A",
    "Residensy completion.pdf_SamDayawansa.pdf": "A9A2810F-B4D3-4371-B944-84C90719EBB3",
    "Resident  Verification Letter - Mahmoud Elsayed.pdf": "C283E744-7A38-4C99-8195-01579D43E2FE",
    "Resident Certificate.jpg.pdf": "D2F5BBEA-3272-4D66-88A7-39D5E7F003BC",
    "Resident confirmation Davide Croci.pdf": "60BD4518-C530-4C74-AFB6-C46772DB9755",
    "Resident confirmation.pdf": "DFBC9EF0-BA6C-4C8A-AAE6-39347512AFB6",
    "Resident letter.pdf": "7F9A9B8C-3D87-4011-B462-5324F9249FDA",
    "Resident Neurological Surgery Diploma Dr. Shah Jackson 2014 2021.pdf": "ABEF65C0-27FB-43FC-B492-91F7DF1D042F",
    "Resident NS status.pdf": "A67EB9C0-004E-403F-A8EB-B69391FEA928",
    "Resident Program Reg Instructions - 2020 Skull Base.pdf": "DFF55AAB-C21F-4041-ACB2-C40554870E72",
    "Resident Program Reg Instructions - 2021 Data AI.pdf": "FAE30356-D5AF-4559-A298-9232506049C9",
    "Resident proof.pdf": "8C49E3E5-1536-4858-9694-ED348C83B583",
    "Resident status verification letter.pdf": "FEE70DEC-0C5C-4020-9BF0-EB743D000CD9",
    "Resident VER - Mayo Clinic Phoenix Arizona.pdf": "84D89BA6-88D8-4472-9089-72ED1FD66E09",
    "Resident VER - Sattur.pdf": "30B32B63-4546-4828-8FAA-FE2533653ECE",
    "Resident Ver Lotbiniere-Bassett.pdf": "8E18BB92-19EA-49AC-A671-227E756C5A79",
    "Resident verification.pdf": "79C86BAC-186C-4EDD-8C11-B9187FAC0CF7",
    "resident.pdf": "0572D7CA-4050-4F4C-A118-17346206C252",
    "resident_CNS.pdf": "FED45275-F881-4573-ACDC-6243F3CBC994",
    "resident_housing_guidelines_and_application_combined.pdf": "269272DC-1E0F-44BD-9A5B-13689E1E7939",
    "ResidentAppGuidelines.pdf": "E9A50777-9940-47E5-B914-6280CA99A07C",
    "ResidentReviewBoard-Overview.pdf": "8AFC8CDC-17DC-4EEB-8913-553723F8AB5E",
    "Residents letter.pdf": "D3AA73BB-5A43-404B-B054-552984CE24BF",
    "Resp_Severity_2021.pdf": "946019BB-50B8-4E83-A2B5-0436D8A69156",
    "response_letter_to_ncci_02.13.17_final.pdf": "E11CC180-1DA1-4019-919A-2181D3B37361",
    "Restated Bylaws - Side by Side Comparisons Chart [8.17.21].pdf": "23660D54-2C03-4E6C-A21E-621F4002848A",
    "restrepo_ver.pdf": "9F0F1FB0-CD28-48A4-9ED1-DEABA9DE4CA0",
    "Results tables 1-3 CNS.pdf": "96883D17-A388-47D6-A148-8F7E98E29FDB",
    "Results- Bivariate Comparison of Lesions within Brainstem.pdf": "360FFC3B-45C7-4A39-B0F5-D4C416276D5C",
    "Results- Bivariate comparison.pdf": "5453B27B-CDDA-4604-81DC-5752CB4EC43D",
    "Results_MCID.pdf": "D267B929-267B-4E85-846B-A66ADA125A60",
    "Resume - Ho Jun Yun.pdf": "CC599F03-9F43-425C-9C8D-AC4ECEA34229",
    "Resume - Muralidhara Raju 4 (California).pdf": "BBF1FC0F-82D5-4E83-9D86-35B26CF4175D",
    "Resume - P.Ng 04_24_24.pdf": "61129EB2-74B2-48BC-8381-64C36A05426D",
    "Resume - Roopesh Dinakaran 1.pdf": "FB587FFC-ADCA-4D40-A247-31A184CD1D6C",
    "resume 2020 exp.pdf": "9EE519C7-C0DC-46D6-93E2-5A1F0F0048A9",
    "Resume 2021-2.pdf": "C645D06E-0E57-4B20-8392-4BCB4795BEB0",
    "Resume 2023.pdf": "B15CD11F-46EF-438C-BA76-63EFA118A4E5",
    "resume 3.pdf": "173502F6-E564-4152-8E71-8CA55531A60F",
    "Resume ADN CNS.pdf": "A690D67E-DD34-4B67-96CC-A28E524F2A9F",
    "Resume Anvar Hoja May 2018.pdf": "14EA22CC-3734-4F22-8073-7ED7422666BA",
    "Resume Brenda Iglesias 2021.pdf": "D665DBEB-83EB-4316-8F4F-B11801FA6357",
    "Resume CNS.pdf": "91D9B3DA-2658-47E5-BF3A-D78571770651",
    "Resume Curriculum Vitae CV - Juan M. Ramos Acevedo.pdf": "A1C78B2E-84D1-4618-87F3-E9236B695D56",
    "Resume Curriculum Vitae- Alyssa Lee.pdf": "E3443E81-F981-48EA-BB87-5748EA917B48",
    "Resume Dr. Keyur Shah.pdf": "6DA03E5D-959F-4163-B9B5-B6A2EF6C88CD",
    "Resume GS 2018.pdf": "05B2E745-15DC-4860-8F99-5426BAB32692",
    "Resume Jeffrey Wagner.pdf": "087A2D59-8AB6-4DF2-AF27-C62C1D1C522B",
    "Resume Olindi Wijesekera 2-3-22.pdf": "9EB702DD-0094-4C58-B559-07EFA5587FB4",
    "Resume Sept 2021.pdf": "0667D599-478F-459B-A999-67F5D7D50C97",
    "Resume-2.pdf": "92A75611-92D4-46BE-8DBF-EF849090D3F2",
    "RESUME-CNS 9.53.50 PM.pdf": "6B8388A5-4FCE-429E-81E6-D137596AB04A",
    "Resume-Emal Lesha.pdf": "432300B4-E54D-4302-AEF3-5CB56E47122E",
    "Resume-Felix-Montenegro 2022-2023.pdf": "45C7C0B1-8476-49F8-AD92-102AFC4ED5F3",
    "Resume-Felix-Montenegro 5.pdf": "B2FC476C-0EB3-4782-A1ED-876E41118B48",
    "resume.pdf": "2C38C3B6-2CC7-44B0-8008-3CFF49335C84",
    "Resume_4.pdf": "AB039CD2-672D-4A8D-8306-2E86B6F6AC20",
    "Resume_George_Galvan_MBA_Application 2023.pdf": "22B45154-3FFD-4601-B032-6FB0FB0008A2",
    "resume_Hesham_Radwan (1).pdf": "CBD25272-4200-4A20-8815-C21F264A7EA5",
    "Resume_Smith_061121.pdf": "43FA70D4-FD0D-4980-806D-75E00ECBB72D",
    "Resume_Sonia Bakke April 2024.pdf": "89331D24-A402-48C2-976F-98BE57F341FA",
    "ResumeforDeniseBatchelor-Updated 12.2014.pdf": "DD26D2E5-7639-4343-80CC-8E50A71BEC7F",
    "ResumeTH.pdf": "2481B26E-ACDD-4B8F-8B0A-C6BBFBDD1AA4",
    "RESVerif-Brij Karmur.pdf": "4559E772-8DEA-4E74-A265-27B6AD83BD7B",
    "RESverif-Dr. Kevin Zhao.pdf": "E001DC28-D560-4EC4-B850-13E488987A96",
    "RESVerif-Justin Cappuzzo.pdf": "EEA3E973-3938-47B2-95FB-97979E0E790C",
    "Retail Shops at Pebble Beach.pdf": "ED4D5EA5-02C0-4370-88A9-9392D2E82715",
    "Retzlaff, Amber 9.9.15.pdf": "CE5AC67A-A82A-4113-AC7A-5C48C23D03F3",
    "rev - Tables.pdf": "633B997C-4D1F-49A2-B6F3-753EF1DDF46D",
    "Review and Evidence-Based Guidelines for Occipital Nerve Stimulation for the Treatment.pdf": "3C9D962C-FD35-4DFE-82F7-A33C64F21B32",
    "Review Records - MedHub-CarsonMcCann.pdf": "49721009-FBAE-4714-BE99-1DD92259DB1C",
    "Review- Caroline Elton Helps Doctors Heal Themselves.pdf": "DB277CC3-16DA-4E6E-950B-EC078A3A874A",
    "Review- How to balance family with career- A man’s perspective.pdf": "55E19975-B241-4D48-A40C-6381C002185C",
    "Review- Surgical personalities surgical burnout and surgical happiness .pdf": "AA8D814A-AED6-4A9D-B206-CEB627140323",
    "REVISED 2024 ASSFN Bifold_rev 01122024.pdf": "E295C1F7-A647-4BD0-896F-6558F0863765",
    "Revised CNS Neurosurgical Emergencies Agenda_2.7 04082024.pdf": "FB90E2DC-2B07-4759-A441-43E7102B1A6E",
    "Revised Governance Grid.pdf": "C0CCB516-2531-442F-925D-1855EB9F9CEE",
    "Revised_Head growth in patients with MMC treated with pre and postnatal surgery.pdf": "AD628901-8B58-4167-B771-00F3E0D2DCFC",
    "revisionstocehrtandmunprmaans-cnscommentletter.pdf": "3BACDD7C-98AF-45AC-ABBD-ACD651DC79B0",
    "revisionstocehrtandmunprmaans-cnscommentletter_0.pdf": "57D4566F-0186-4C09-9A9D-7AD7D88940B3",
    "Revisiting The Concept Of.pdf": "13EACB84-31CB-4652-B396-73A88C330595",
    "Revisiting The Role Of MRI In Patients With Stable Traumatic Cervical Spine .pdf": "B18E40BF-2D8E-4DE9-A076-E5377CC3355F",
    "RevueltaEmployment Letter.pdf": "4E2045BC-907C-4362-816C-7F2035CFD3F6",
    "Reynolds_Renee_2024ProvisionalMembershipApplication_ConfirmationLetter.pdf": "F7DC8694-9A76-44C5-9AA7-869737B67266",
    "Reza Dashti- Curriculum Vitae-July 2016.pdf": "7EF05A65-DD49-4696-8BCD-4D78791A2EA2",
    "Rezai's Poster CNS 2022_FINAL.pdf": "F1290AE3-AB04-40FE-BA2F-DCC6E8D851FE",
    "Rezaii.pdf": "2A4E4BC8-0884-460D-B301-411E028442A2",
    "RF CV.pdf": "E2C9A668-E503-4E9F-B243-98B86D1BDB61",
    "RFA ASSFN Poster.pdf": "73330438-150C-4F5C-8F1B-27AC8A91BEE9",
    "rfi_november_20_-_aim_comments_final.pdf": "2EC53F89-A1C4-4906-A266-C4141F4167CD",
    "RFI_ONC_CMS_Advancing_Interoperability_and_HIE_4_2013_v6FINAL.pdf": "CCDF5FE6-82A2-4602-8009-0AEE6A3E49A7",
    "RFIMedicareDataResponseSign-On9-5-13FINAL.pdf": "1B789A47-21BD-44BC-B0DC-C6513FABF753",
    "RFS_TaliaWenger Keck Registration.pdf": "19037100-C416-448D-AA4E-90098469BACF",
    "RG CV 1.18.24.pdf": "CE642434-67D6-4F04-BD3A-184569A6C992",
    "RG_Biosketch_FINAL.pdf": "727A927B-70E1-46B9-AFB6-11133C6AB137",
    "RG_LOR_FINAL.pdf": "29E27A5F-34AC-41FE-B55B-38B70109A0C7",
    "RGarner_VerificationLetter.pdf": "7DE752F4-9585-4F00-AB57-61F2B6DDAD93",
    "RGB17-016_CNS_DigitalPoster.pdf": "6A82B3B4-6701-4FA4-BBF9-C8ECD4723BB7",
    "RGC18-008_CNS_DigitalPoster.pdf": "196F0B4A-9F88-46A3-9C61-4FE77CC83F94",
    "RGC19-020_CNS_DigitalPoster.pdf": "D4AA7CA2-EBFC-46AD-BFA2-A12632D23533",
    "RGS.pdf": "2A38C2B5-2966-48DF-9481-F2C34D288EAB",
    "Rheaume.pdf": "1FAC23B6-EBC4-4649-BB05-CCAF282142F7",
    "Rhines_Nom Letter.pdf": "923B0034-2979-4413-A894-8529F8BE8383",
    "Rhines_SNS.pdf": "5C57AC54-0145-465B-A69B-C0C94D282FE9",
    "Rhodenhiser Emmajane-Letter of Good Standing and Liability Insurance.pdf": "ED065DCB-A8FE-4002-8443-3EE7532089DE",
    "Rhoton Review (reduced).pdf": "B658ACD3-2EE2-49A0-A519-5E15A34BD3D2",
    "RHouston_VerificationEnrollment.pdf": "85486ABD-9A24-4644-8559-FD3AD970D32B",
    "Ricardo Andres Najera - Enrollment Verification.pdf": "1F66640C-908B-4F24-BD30-966C32B38803",
    "Ricardo Resume 2017.doc (1).pdf": "747254AA-8099-4A31-BCCE-47C794C583F8",
    "Riccardo Serra CV.pdf": "F7A8C16C-71DD-4A66-835B-C33CD8066815",
    "Richard Cilia - Marketing Strategist.docx.pdf": "A9165711-BC17-42F7-A28E-5A6A349501FA",
    "Richard J.docx.pdf": "CFABE4FA-69EB-4738-B6A7-1FCB72C62213",
    "Richard Jiyoung Chung_Good Standing.pdf": "5EDA02A2-69EE-42C4-8FE0-34677C69454F",
    "Richard Lefevre CV July 2011.pdf": "99832077-4CAD-429D-A47A-E521FE8AE76B",
    "Richardson William-LOGS.pdf": "0E094795-489B-4921-9DD8-1E4257EDCF6B",
    "Richardson William.pdf": "4AC7A906-CDB2-4F5A-8671-9D4D26F0B877",
    "Rick's CV New.pdf": "D243F2B2-C0F2-4678-B9BA-6A3E4A859330",
    "Riestenberg Robert Letter.pdf": "F3AB033A-1A4F-43AA-A731-E62D65D52031",
    "Rigney Student Status Letter.pdf": "D1158E3A-2ABC-47A6-886F-4CB99E6EDC04",
    "Rimal Dossani CV _ April 2023 .pdf": "E3716FA9-2BFE-40A7-9344-91F44F37A5FD",
    "Rincon-Torroella CV 8.2023 FINAL.pdf": "9E4589A2-D28D-4B03-8C81-55ED6123E5B7",
    "Rincon-Torroella_FWLNSchol_2021.pdf": "016DCF96-25B1-4DEB-9099-696DD7C18A26",
    "RinconTorroella_FWLNSchol_2021.pdf": "6F81DB3F-B170-4982-9312-B6B73FA4BFC7",
    "Rindler Rima REAIMS CV.pdf": "BED77DB6-47BA-44ED-97F2-F7835DF0879E",
    "Ringer_CNS_DAPT_Poster.pdf": "A1C6CA5F-F38E-43A0-B947-7A3B39CF8043",
    "rink.pdf": "747CF37F-1B13-4C07-BF1D-E9AF04F4A335",
    "Riordan Coleman  LoG Congress of Neurological Surgeons.pdf": "AE325AC2-6B25-474F-9144-59AA87B728CF",
    "RishengXu_AnnieTrang_LOS.pdf": "177417DF-E2F4-4001-A3C8-2514450E9057",
    "RISHI_DEVULAPALLI_CV_.pdf": "8902F052-5F76-43C2-BB50-FAF17320E890",
    "Risk Factors and Outcomes of Abusive Neurotrauma in Geriatric Patients.pdf": "D93596AB-167C-41D4-A36D-CD33AC0E5DBB",
    "Risk of Subsequent Primary Cancers after Radiotherapy_CNS Poster.pdf": "6591A7F9-4B18-494F-97AD-2D971984DFB1",
    "Rita Nguyen-FellowVERIF.pdf": "9E92BDC8-F23F-446C-B8D7-2968AB2262FC",
    "Rita Snyder LGS.pdf": "3CA8B99B-26CC-411A-9958-ABCA0BCD5E58",
    "Ritz_Moyamoya_CNS.pdf": "AEA47342-DED4-4649-A9ED-A3470CF9D0AC",
    "Rivera good standing.pdf": "F8B0EEE4-22F2-44B9-AC19-FA3B0BA07C41",
    "Rivera.pdf": "5492EA81-7D68-43A5-BA62-8DD36064DCFE",
    "Riviere-Cazaux Cecile - enrollment verification.pdf": "EA0917BC-128C-45D0-A082-3DC9F10E2C28",
    "Rizk MD Elias_SNS Nomination Ltr_10-24-23.pdf": "8928D6A6-A214-4108-9531-F8D103ED6309",
    "Rizk_CV.pdf": "95EB8CFD-D7AD-4AB3-87CB-39A19BB20B71",
    "Rizk_SNS_CockroftLON10102023.pdf": "5DFB6E48-02D3-435B-B86C-D17BBB85B184",
    "Rizk_SNS_DiasLOS2023.pdf": "41DD43F3-1FB6-4D0F-A3D0-C53222007922",
    "Rizk_SNS_HarbaughLOS10102023.pdf": "B1AE5F89-40CF-4C49-B708-180CD218DED4",
    "Rizk_SNS_LON10102023.pdf": "2BB3AEE3-4446-440A-AF69-F3DFD908BE65",
    "Rizzoli, Hugo.PDF": "A48EABD4-2620-48FE-A8AA-28874E514085",
    "RJ CV 2020.pdf": "F436BC01-CCF9-4DAD-8E77-6B891BB3C6B9",
    "RKhanna-fellowVerif.pdf": "FDC48336-B975-49EE-893A-506A2A8A0BBF",
    "RKhazanchi_CV_4_15.pdf": "AC78BE8D-634C-45FE-AAE2-8570ED071821",
    "RKhazanchi_LOR_Divi.pdf": "248FE566-E9E0-4B09-A6A7-305138AA847A",
    "RKhazanchi_LOR_OBrien.pdf": "D5ECAEA5-CF18-4680-8E59-0FFB8C1E87B8",
    "RKhazanchi_LOS_Magill.pdf": "379FA703-9FC9-46D8-9A4E-AD97C1F072FC",
    "RKhazanchi_PHR_Publication.pdf": "E75640F3-8880-4286-B292-0BEBD084C1C2",
    "RL.pdf": "733F4AF4-873A-4D61-BF55-26F1B0481CF0",
    "RM Meyer CV 14Jan24.pdf": "76AC45CE-3EAA-40DC-AE4C-53CFC97E1366",
    "RMotiei CV.pdf": "D5A364DF-0EE3-48A0-8652-02C17CAAE29B",
    "RN GW CV .pdf": "A431B9BD-9512-4AE2-A765-E7D0EB8E8273",
    "RNI-2019-Summer-Brochure-reduced-size-1.pdf": "6C7AC969-1B6D-4715-8F36-6C6986C98438",
    "RNLetter.pdf": "CC833420-186B-40C3-BD7E-C55791C6DF31",
    "RNS Poster_CNS.pdf": "C11E156B-849A-4D44-9123-DF8B4A2D652E",
    "Roach_Martine Letter of Reference CNS Fellowship.pdf": "9A014656-2DD1-46A0-ACC0-E9E3701CDBDB",
    "Rob Osorio.pdf": "2156B1FF-1671-4E6D-B0FE-1852ECA982D8",
    "Rob_Dambrino_CV_CNS.pdf": "9B6D0AF4-384E-4378-B8E0-ECB2E1219BB8",
    "Robb Resume.pdf": "7E9BBB5D-3D9D-4C6C-90F4-13C8CA1741F2",
    "Robert Bejnarowicz-RESVerif.pdf": "1BDDF2B6-DD4D-44FD-B80D-1AE790B1FF58",
    "Robert Louis CV Revised 1_20.pdf": "2D62C67A-470F-4053-A829-4DFE7E1AD179",
    "Robert M Lober CV July 2017.pdf": "F0F0B49E-5EC3-4DE4-A692-4F014D0E5F9B",
    "Robert Rennert-RESverif.pdf": "7BAB3862-0FF5-4693-AF30-EF582BB29EF9",
    "Robert Spinner Slides.pdf": "F29F4D2F-F3FB-453F-94A7-5BEA12CA36E9",
    "Robert Stephanie.pdf": "81409872-4E82-4DD0-9468-5E2B21CC1B0C",
    "Robert Young Student Status Certification.pdf": "35ABBC57-657A-42E4-8A14-506E5B22FE04",
    "RobertRudy_EnrollmentVerification_9.29.2014.pdf": "2F114AB1-4AA4-490D-A302-8A7DCDDAAE5F",
    "Robertson Jermaine-2025-LOG LOA.pdf": "29E9BAA9-C436-4D7E-AAF6-F0F23746D52E",
    "Robertson, Jim.pdf": "BCB2365D-FCEE-48B0-B8A7-4616502A324D",
    "Robin Babadjouni's Letter of Good Academic Standing.pdf": "113115F2-951B-476B-8A9B-74E478515C91",
    "Robinson, Shenandoah.pdf": "0B4DB9CA-F083-41D9-8B7B-6E41F38BFAEF",
    "Robles Maria - Letter of Good Academic Standing 12.07.2022 bls.pdf": "843303D2-96E9-4636-9EC3-4B7F6AB1FD6F",
    "Robles-Lopez-Luis-MD.Hurlbert-R-John-MD-PhD.PAL.02-20-2024 1.pdf": "AB671136-8DC6-4995-B760-C608AE2CDA76",
    "RoblesMarcelo_VerificationLetter.pdf": "B82C5CE3-4258-47A9-8B7B-8440F70AC1E4",
    "Robotic or navigated vs freehand poster.pdf": "10BBD1F8-528B-4BDE-86FF-0D14903C704A",
    "Robotic Trauma.pdf": "ED92D259-C230-4951-B1FA-C625BC662F68",
    "Robotic_sEEG.pptx.pdf": "262CA42D-6924-4022-8B7E-599B4B5B4B67",
    "Robotics in Neurointervention_cns abstract.pdf": "9F62A4DB-946C-438C-AE1A-40C27655917C",
    "Robotics Systematic Review CNS Poster 2023.pdf": "F5B6DAAE-5C53-4600-A400-25B8D2B03DBD",
    "ROC Analysis DEXA T-Scores -2.5, -2.0, -1.5.pdf": "52F8D3BF-B7B4-4019-BDA0-2EB860925052",
    "ROC.pdf": "71826D55-6149-4FD2-B5C5-59CC87B345A1",
    "Roche Kayla - Letter of Good Standing 050124.pdf": "3A265C0C-CD52-4190-99C0-303154CB455F",
    "Rod Samuelson passport photo Nov 2019.pdf": "0B9191CB-3C26-49FE-8074-AE44DE0C384E",
    "Rodgers CNS 2023 Presentation Final.pdf": "263356F3-A76A-44FC-ADFA-B2F76825AD93",
    "RODRIGO KEI KUROMOTO 2.pdf": "E98152B4-1A85-4CFB-9E4C-DFD7DE7BC884",
    "Rodrigues Adrian - Good Standing.pdf": "8FB645E7-0FF7-4D08-85D0-93596F95754E",
    "Rodrigues_R Letter of Good Standing.pdf": "A5D2559C-E968-4D89-9C02-527472C4F38D",
    "Rodriguez Hernandez.pdf": "93E1910F-0EB2-4456-A916-2146956F5738",
    "Rodriguez_FWLNSchol_2021.pdf": "277CFC7C-D93A-4B02-A38E-043427F77C6B",
    "roe-bera_prior_authorization_sign-on_letter.pdf": "5880C61D-2DBD-43D8-B901-1A1B6CA5E666",
    "roe-ruiz_ipab_repeal_bill_endorsement_031617.pdf": "0C27C256-6A05-4FE7-BF42-0685C009AD45",
    "roe-sanchez_ipab_repeal_legislation_letter_012215.pdf": "0CCD11AD-68F3-40C4-A0AE-1DAD61ADA893",
    "ROE_056_xml.pdf": "4A77B0DC-4486-462D-ABD7-F6BF84CE3FCF",
    "roe_final_roe_ma_prior_authorization_v2.pdf": "7C19EF21-EF2B-497D-89D9-807180F622BD",
    "Roehrkasse_LetterofGoodStanding.pdf": "355B1F56-CCEC-4734-9C0A-57458329EBBD",
    "Rogelio Nava Esquivel-RESverif.pdf": "1D3422A3-A803-4B8F-8454-E854EF70526C",
    "Roger Murayi-Fellowship.pdf": "36782D2E-2D7B-419A-B3AC-A44D21B4E410",
    "Rogers Cara.pdf": "818C0474-4470-4598-B8D0-577342313AA0",
    "Rohaid C.V..pdf": "BCD37866-2917-4CFF-93DE-37692892FAE4",
    "Rolandic_Motor_Association_Area.pdf": "4EC7DD4F-7444-45F9-8BBA-0E234C9F50C9",
    "ROLANDO V ROJAS-APAZA CV english version.pdf": "F37CBA35-4809-4D0D-AC7D-8D5D279FFB1C",
    "ROLANDO V ROJAS-APAZA CV.pdf": "2B8BA5E5-24AA-4FFD-A40C-A477B48CA62E",
    "Role of the Interthalamic Adhesion Brahimaj CNS Poster .pdf": "B950FB63-C079-4DB5-B151-4E37137FE6DE",
    "Romano CNS-CSNS Medical Student Fellowship Support Letter 043021_Mukherjee.pdf": "6902ED97-B3CD-4EDD-BAAB-06721170B2E5",
    "Romeo Boulles.pdf": "BE7CF427-1679-4E59-AD2C-8C6C56A5641A",
    "Romeo Dominic.pdf": "68B31214-26AD-4B7C-80C3-1E518FC0456B",
    "Romero Acosta VER.pdf": "DBD47D93-5101-4F02-8637-B6ACB5E7652F",
    "Romero_Bethsabe_ LOGS 12_22_2020.pdf": "839E1463-5826-4B95-B273-2C2464CA555E",
    "Ronecker.pdf": "F597A4ED-4545-4E89-AECD-EECCAB654AE0",
    "Ronkon C 4-12-2021.pdf": "345D60D3-7C65-40F4-B834-200E31A78D3C",
    "Rory Goodwin - NIH biosketch 2019 New Format.pdf": "04636640-5F95-4DB0-8D70-1C341EAEC218",
    "Rory Goodwin - NIH Clinical Research biosketch 2020_final.pdf": "3B2B2D5D-D72B-4850-BDC4-248143E1856C",
    "ROSA_poster_peds_section_2023.pdf": "8E50903E-8EE9-4CB5-81FB-33C45BC9326F",
    "Rosen Kate - Letter of Enrollment and Good Standing - 9.14.21.pdf": "C14369A9-80A4-4D4D-8BD2-7ECAB25C72A1",
    "Rosenberg, David_GS_NS.pdf": "49827EB7-60A1-4013-BD90-F39A02546A43",
    "Rosenwasser, Robert.PDF": "A8C9D6FC-28C9-4689-AAAA-31B9D2E026A0",
    "RoshBharthi_CNS Letter of Good Standing.pdf": "83283E5C-4AF6-4424-9D13-29B1478DF06F",
    "RoshBharthi_CV.pdf": "B83D6303-0214-4997-9AC3-F0B224DE0C09",
    "Rosoklija Gavril Good Standing.pdf": "7C81803C-E14F-4E20-9E09-649C9D11F971",
    "Ross Green-RES_Verif.pdf": "0535B5C0-9DFE-4ACF-9BC4-9F4AEA2DD649",
    "Rossi- CV 1.31.21.pdf": "12D1789A-7A42-4303-AE1E-3583FF26E95A",
    "Rossitto_Christina Enrollment Verification.pdf": "59B8B2AF-41E3-4029-9A79-985DA65A86DF",
    "rotem.pdf": "0FA92960-7D7A-4263-89E7-78CB50F6992F",
    "ROTEM_Abstract Tables_SpineSummit.pdf": "F497F904-9925-41AB-AD14-61794001FA55",
    "Rotter J. CNS Quality Scholarly LoR Van Gompel 3-26-2021.pdf": "3B00F12F-0C21-4813-B73C-FF7409074AED",
    "Rotter Verification.pdf": "8FD59F6C-4B8D-44E0-9F1D-C3F13846E004",
    "Round Robin 2019.pdf": "0413A8B4-A4D9-423E-AB41-260E5E157705",
    "Round Robin Letter, Vol 11, No. 1, 1941 to 1951.pdf": "43A62992-E9EC-4A16-B3CD-434A139FC6F4",
    "Roxanna Garcia CV Feb 2023.pdf": "EC769630-BAC3-48E5-9F9B-7D8755A0C6B1",
    "Roy Souvik LGS.pdf": "9A364954-63CB-485D-86A1-8DBE67B63CDB",
    "Royal Australasian College of Surgeons Membership Confirmation.pdf": "F2D68E14-8E84-43A8-AC1B-8508768373D1",
    "Rozman CV 07_2023 MMYY.pdf": "F98CED91-CF7D-4EBE-A6D1-6F6CC8A983CB",
    "RPA meningoma.pdf": "A3B1009B-9A97-4405-B52C-F420E4393A14",
    "RPeterson.FellowVerif.pdf": "7CFE971E-3751-44FC-B1AD-5795A0E0B022",
    "RPGM_Table 1.pdf": "7B4A91A6-12C4-476B-AAA8-5A0202960466",
    "RPM_CNSPoster.pdf": "BF615A9F-D330-4132-A4AF-06287B351186",
    "RRC Coalition Press Release re HR 3107 06.11.20.pdf": "D267715C-19B7-44DD-9BA6-A08FF2C01C15",
    "RRC Comments on ePA rule. final D1049184.pdf": "B394ECBD-CD77-4923-A32B-466BB5F3A186",
    "RRC Comments on Medicare Advantage Policy Revisions for 2024 - Final D1044512.PDF": "2727D4AC-AF04-42F0-9DC6-DC4C25AA82D3",
    "RRC comments on PA Proposed Rule 010420.pdf": "D50729BC-9092-46CC-BE17-1C2E81937E51",
    "RRC comments on PA Proposed Rule FINAL 01.04.20.pdf": "77402478-3E12-4EAE-822C-ACA31418998A",
    "RRC Dec. Letter to H-S Leadership D1033327.pdf": "86B5C4FF-F5C6-4239-AD47-CC9844FBD43C",
    "RRC GOLD CARD Act Letter of Support D1072123.pdf": "10E46F69-4DBC-434D-984C-728723CDB0AF",
    "RRC Letter of Support for H.R. 5213 D1089931.pdf": "4F898734-62EA-4A0D-9B17-7BB00F7CE6B7",
    "RRC Letter of Support_No Fees for EFTs Act S. 3805 D1115387.pdf": "872D5FD1-4FBE-4795-90D0-A91A468DF364",
    "RRC Letter of Support_No Fees for EFTs Act_Jan 2024 D1099518.pdf": "0A466B44-CFED-4A22-B75D-3D7E71E11D4C",
    "RRC Letter Regarding ASC PA D1111741-6.pdf": "57A7C5A8-6221-41DA-9D77-4B1B832E6EA1",
    "RRC Letter to Brooks LaSure (D0967299-7).pdf": "7C1B82F7-62E2-43E7-9D21-CC26751A4E01",
    "RRC Letter to EC O I D1007949.pdf": "038FCF8F-FFC3-4253-9F26-8C7CEA7EDE69",
    "RRC Letter to Governor.pdf": "50BD9755-05BF-45E0-86C9-28B1CABC161E",
    "RRC Letter to Insurance Commissioner.pdf": "085DB4DC-A0DF-4292-93B1-42D5DADCFF73",
    "RRC Press Release - EC Hearing 10-19-2023 D1082936.pdf": "0BDA8C02-E402-4708-8470-42DECA7FBB9E",
    "RRC PRESS RELEASE WAYS AND MEANS D1012588.pdf": "55E766CF-6D2F-4D59-A798-52EF69EE408E",
    "RRC Press Release. RRC Comments on PA Rule D1044523.pdf": "F85CF125-6892-4A63-BDA9-47693C2F3B71",
    "RRC Prior Auth COVID Letter 032720.pdf": "67351F17-0D39-4252-885A-BFE03F0A940A",
    "RRC Response to Budget Committee RFI. revised. pdf. D1083526.pdf": "FC7486BF-2C17-4BCD-BE4E-2AC2B83820DE",
    "RRC Support for Improving Seniors Timely Access to Care Act D1012523.pdf": "06197CD6-7A32-471C-B789-D1A4EF4E87C9",
    "RRC Thank You Letter.pdf": "058CEC4F-08C7-4746-982A-B33BF87FEADC",
    "RRC-Letter-to-Governor 041620.pdf": "64B04182-E377-46A3-A94E-C8F39C3D171C",
    "RRC-Letter-to-Insurance-Commissioner 041620.pdf": "EFEA312B-87CE-4C8A-9613-6809F559AF46",
    "rrc_comments_on_patients_over_paperwork_rfi_final_8.12.2019.pdf": "D47ADF7F-F309-42AD-822E-6AD175308F08",
    "rrc_final_comments_on_step_therapy_rule_jan_2019.pdf": "22BA26BC-4459-4588-AEC9-8DDFAD70FFA1",
    "rrc_prior_authorization_survey_2.0.pdf": "C29681E7-4E32-42C1-919F-354ED66D7DC1",
    "RReynolds.eamilverifications.pdf": "8A6305B7-028F-437A-AB3B-6B485CCED727",
    "RS.pdf": "B7DC20B7-F98F-4BC9-9A85-0D00D6151DF1",
    "RS_CV_Format_UW_10_13_22.pdf": "DF02B65B-95F9-4905-A6F8-733AF380D43C",
    "RSFM CV 04222024.pdf": "0AE023FE-A498-411F-9699-730662B732E5",
    "RSFM_CV_02022024.pdf": "143E4E45-EFE0-4452-8F9F-F60D00FD0626",
    "rss.pdf": "0548BC61-552E-4015-B841-535A3838E979",
    "RT CT CNS Poster.pdf": "962E362A-2CEE-4810-8E6E-EDF31FC3E392",
    "RTA-PGM CNS 2022.pdf": "559CDB3A-A94E-4CC5-A63E-D853574501B1",
    "RTakroni.emailverification.pdf": "1359A463-CFBD-4195-A72D-28350CE2EE6D",
    "RTOR poster Final.pdf": "6859DC1A-0BB6-48D5-AA10-C01AECC8C229",
    "RTurner.emailverification.pdf": "F07D18C4-E57E-4C4B-9A37-1EFC21DE48B1",
    "Ruchit Student Status Letter.pdf": "CDE5A2B7-A9F6-4E94-8C2A-DCB7E54FED6B",
    "Ruff_A.pdf": "28863DA5-B00A-4C14-93FD-EC68D87ADCDE",
    "Ruggiero Nicco - LOGs  Enrollment Verification.pdf": "81D98D07-366C-473F-B4DD-3540B1B8A31B",
    "Ruh, Nicole CNS Letter of Good Standing.pdf": "AF6922C7-41FB-4222-A25A-FAEEF84F33DF",
    "RUI LOR 1.26.2021.pdf": "D41A8E1E-6124-446A-B433-EB93111781D7",
    "RuiCNS.pdf": "EF31D09E-04A3-4F8A-B143-1C672AC366FA",
    "Ruiz Colon Gabriela - Good Standing Enroll.pdf": "CA6111B5-4C51-40A4-96D3-EFF68D6D0013",
    "Ruiz Munoz.pdf": "9E4EDF1C-1462-48CD-A2F8-D064F337036D",
    "ruiz-roe_surprise_billing_legislation_section_by_section.pdf": "4E91E69E-0B3C-4DF2-8A45-371963D92FE3",
    "Rukstalis_CNS April 24 2024_v3_Final.pdf": "7E5ED87B-3CCD-491D-8CF4-977AFFE6FEDE",
    "Rules & Regs-2004.3.19.pdf": "16E16CCE-FA3E-4AD1-BA8B-5B0D1FE80CB2",
    "Rules and Regulations Report_2007-09-17.pdf": "941DEB75-0079-46A6-9E5A-B8C3615D8E76",
    "Rumalla Dean's Letter NREF.pdf": "CEF0E048-7279-48E5-BC65-1A716AC8AAFE",
    "RUNN application Approval.pdf": "181A3321-65AC-49A5-868E-E3C235FB5D9A",
    "RUNN Course Agenda for Website 10.27.2023.pdf": "5F60B6A1-C391-431E-B714-159A38785E8E",
    "RUNN Course Faculty List.pdf": "0D323419-1113-4D5F-B866-2D61E28279E0",
    "RUNN Course Registration List as of 10.19.2023.pdf": "B4D15C28-5B60-4A90-AABC-6821E4FF5A9C",
    "RUNN Course Registration List as of 10.24.2022.pdf": "6A7AA2E5-DF78-4569-92D2-24827448915F",
    "RUNN Grant.pdf": "DBE548CB-82FC-447F-A8A8-8750BADDC3F4",
    "RUNN Research Grant Murad Letter.pdf": "DDD8A542-8B17-467B-BA20-D2BD6E44B61F",
    "RUNN2016ANNUALREPORT.pdf": "1A52014C-33A4-4441-8976-C2C323EEDB0D",
    "RUNN2017AnnualReport.pdf": "E927EE49-1EA4-4F46-AA47-C9D6FA9E9E4F",
    "RUNNPROCEDURELISTANDTIMELINEJAN62020.pdf": "33CBB5B5-0A5E-4D43-A2DE-A089464170C0",
    "Rupen Desai-fellowVerif.pdf": "1C924710-3E41-42CB-90FE-50C91D4393C4",
    "Rush CV.pdf": "0E2EDBC5-A741-413D-9109-EC33F4983F6F",
    "Rush fellowship letter of appointment.pdf": "BFF0EAD7-4947-4984-8E97-3FDC6049791E",
    "RUSH Medical Acceptance Letter.pdf": "9E3667ED-1E4F-42A0-88E1-145954B53472",
    "Rush Medical College Congratulations.pdf": "FD2A5113-27C4-43EE-93C5-929ABCEFC931",
    "Russell R_LOGS.pdf": "FFD46C83-56D6-4525-BCD3-387D811C9614",
    "Russo Digital Poster CNS.pdf": "2801ECF3-CAF2-4C12-867C-95654A683517",
    "Rutka, James T.pdf": "2B1A032A-47B9-4410-B382-3AB041AC5EF2",
    "Rutka, James.pdf": "82893C43-20C8-4C74-A2CB-2541D4FA99A4",
    "Rutka, Jim.PDF": "E9E26430-C8ED-402D-B1C8-E5B8E3114D4C",
    "RutledgeResidency diploma.pdf": "009E8AAB-0651-4605-95B4-21436F0D8187",
    "Ruzevick UW verification.pdf": "D1C76E53-FBC4-4F7F-A452-02D70A7FAD7C",
    "RW CV final 3-12-19 (002).pdf": "0ABD611F-0027-4D6B-9C52-816F2DB72ADA",
    "RWJMSEnrollment.pdf": "BC2F5455-7191-4472-A02B-40633EA8E553",
    "Ryan Austerman.FellowVErif.pdf": "507DE4B3-B270-4177-9EBA-7BCD06DCB1AA",
    "Ryan Chase Ransom Letter of Enrollment and Standing.pdf": "9378518F-CE01-40B0-B54C-B599579B833D",
    "Ryan Holland-Fellowship.pdf": "16D50B55-08FA-4F3E-82C3-58DCD0FF1DF2",
    "Ryan Kellogg CV.pdf": "D8008EED-D317-4D3B-964E-9E6F23335AFD",
    "Ryan Khanna CV 07-19-23.pdf": "376679AE-9AF4-41D1-AB9C-F75C1627E4AA",
    "Ryan Kitagawa Slides.pdf": "6D68E5CF-F948-46ED-AED0-020BF2FAB0C9",
    "ryan-pelosi_ipab_repeal_bill_110117.pdf": "775E7913-6728-4ECC-9E76-38B0988DFAF7",
    "Ryan_Casey_Chair Letter_042021.pdf": "BE546F94-E5E0-4388-9ADB-A63AE0A125E2",
    "Ryba_Bryan LGS_ May 2020.pdf": "6C6FF081-7938-4F77-BB61-BBB00961EAED",
    "Ryoo James_GS1.pdf": "F8B49D35-6450-45AD-98B5-85916197CD02",
    "Ryu.Brendan.Letter 10.1.19[136828].pdf": "3308B15F-787A-444E-8383-16543DB2A33E",
    "S Jalal.pdf": "AF6F0147-2D12-4A7D-9515-00E8E3606623",
    "S Sabourin - Letter of Good Standing.pdf": "852E7D93-6255-49E7-97C3-419348C6BD1C",
    "S. 2443 Support Letter_10.24.19_Updated.pdf": "C71E52C5-F8F3-4501-9EA7-3C197B0BE92D",
    "S. 3390-CCM-CARE Act 121521.pdf": "14DF2CD9-F9CC-4727-9755-CC3839139641",
    "S. Flynn M232.pdf": "4F15081D-2DAC-4AA0-99F3-6D112A2EFEF3",
    "S. Hanft CNS LOR.pdf": "F2C6EEAD-6594-40EC-83F1-4D6A99A986F9",
    "S. Mullinax CV.pdf": "B7FFE65A-0FAF-483F-9BBD-BA1F82BFE237",
    "S. Patel CV.pdf": "4F58F7F9-A7CB-4793-9BCE-A20A87EB9664",
    "S.Avula_LOGS.pdf": "B49B9505-BDB4-4600-B89B-1B1D6A72059C",
    "S.B.Ramírez-ResVerification.pdf": "5B9609AB-4B22-4A38-B620-2F6A6170547F",
    "S.Byer_Good Standing Letter VSAS VSLO.pdf": "2BC2C4C1-0C22-4BCB-98E4-E1E14D1FB90A",
    "S.Daly_Letter of Good Standing.pdf": "5F7EF1C4-9C78-4712-BABB-D83DC6C72D68",
    "S_Taha_CV_8_22.pdf": "2B11F3E4-6ECC-4E90-B9F7-F27851BF919B",
    "S4275460_ENRL_STAT_20150804020530.pdf": "6980CD12-4865-4848-8239-4D9C9260A63B",
    "S4443027_ENRL_STAT_20220629033935.pdf": "68D58882-0028-4F90-A736-28D9632B201F",
    "S4652251_ENRL_STAT_20230804081855.pdf": "8A6BCB20-9CA9-4B0A-A172-2FD44350F825",
    "S6305-SEC121100612050.pdf": "E5449F3C-08AF-4F33-8870-AE195674F202",
    "SA_LOS_LeadershipAcademy.pdf": "AF5995FD-CE51-45E8-BA6F-1BB60B48C03B",
    "Saad Hassan LOR - Barrow 1.6.2022.pdf": "D58866EF-70E7-433A-BBC3-2FBA06065CC9",
    "Saad Hasson LOR Publication Fellowship - Barrow 1.6.2022.pdf": "53C98A73-5943-4214-BD23-AFE26153ECAC",
    "Saadon Jordan-2023-LOG 9 7 22.pdf": "6A5DDC85-D9EF-45C7-A84B-766676F88F7C",
    "Saar_Kariv_Resume.pdf": "FBDA2B92-5545-401C-84BA-BBB1241A1D92",
    "Sabal letter emailed to student 8.5.22.pdf": "0ABC57C2-2729-4B34-8FFD-B9C5FFD8644F",
    "sabastianhajtovic GOODSTANDING cns 4-20.pdf": "344D0C24-B96A-4CE2-A223-E3C1495503FE",
    "Sabih Effendi Career 2021.pdf": "81DBD718-EE84-47B5-9761-2CAA4E8EEB45",
    "Sabih Effendi Career.pdf": "DB86A2A3-6741-46F3-BEAD-21AAC97D9865",
    "Sabrina CNS.pdf": "A5034613-FE86-4FCA-99E3-0FE1F13537A8",
    "Sachdeva.pdf": "99A75FD8-D517-4580-94A4-9E6F006672FA",
    "Sacino_AANSCNS2020Figure1.pdf": "DC31BBFB-AD37-4F59-ADFF-9FC9A775060A",
    "Sacino_AANSCNS2020Figure2.pdf": "250B1356-7049-46B4-BCB6-2FA3DFF6C9F7",
    "Sacino_AANSCNS2020Table1.pdf": "0D7F74B8-6607-4F26-885A-C3C5F65DEE98",
    "Sacino_AANSCNS2020Table2.pdf": "9CC63CB6-7B4C-4770-931C-305C857842C1",
    "Sack Kenneth CV.pdf": "45B87E2F-C113-45EC-8283-80CC373DEC63",
    "Sacknovitz Enrollment Update.pdf": "353374D0-B33F-4B1B-B671-541E7B520105",
    "Sacknovitz Enrollment.pdf": "1B1A50AB-7D6E-46F9-82D5-9B6C2128F508",
    "SACM Vertrag Jena .pdf": "174548A5-3A2F-4885-911D-7E321F88C19A",
    "SAdamczak.residencyverification.pdf": "8A94DDB0-FE23-419B-A01C-56D1F4C49196",
    "SAdams.emailverification.pdf": "2F0057E5-C138-4236-8764-EDEF6C839644",
    "Sadegh CV - 2023OCT.pdf": "57F10DCF-5074-49D4-872A-030459E3F208",
    "Sadeghzadeh CV_CNS.pdf": "BA89FF9B-31DE-4FB0-BDEB-8BC189AF4495",
    "Sadeghzadeh Sina - Letter of Good Standing.pdf": "030B1910-1120-40AC-B4DA-6203D6A17137",
    "Sadeghzadeh_CSNS_VB.pdf": "9229F37A-F9CC-4F72-8EE5-090CA6499733",
    "Sadhwani Nidhisha CV 2023 .pdf": "618AFA0B-B929-4B60-8BFF-485641F49E80",
    "Sadik Tokar TND.pdf": "F1138D1D-0D17-4979-97BA-9C9ADFE4A923",
    "Sadrameli - status letter 2022.pdf": "A5F3D553-62E8-44F4-A928-97E18BD0305B",
    "Saeed Ansari, Curriculum Vitae.pdf": "81F1A3CA-EA90-46D7-A74A-9A58D4EC5426",
    "Saeed Sadrameli-verification email.pdf": "4F77F501-1885-4459-8985-2C077F48B50C",
    "Safdar Poster CNS 2023 BMI PLF copy.pdf": "CD0CFD29-F4C8-4AA0-A6C9-C968CA512767",
    "Safdar Poster CNS 2023 Cobb anggle PLF.pdf": "6E709F16-58A4-4C2F-A638-7B4C3C7D94EF",
    "Safe Entry Zones to the Anterolateral Pons.pdf": "7D015D24-CD26-4CA1-9865-36CE602FF24D",
    "Safety and costs analysis of a “Fast-track%22 algorithm for early hospital discharge after brain tumor surgery .pdf": "77E9CABB-6BC7-4DAD-8463-4090D20F103F",
    "SAH COVID Poster PDF.pdf": "0D8C2268-CA27-4376-968F-BC3A2C4104B8",
    "SAH hispanics.pdf": "93349326-5AF8-4E41-8714-4E33F660F9FC",
    "SAH.pdf": "E54CDF52-00DA-4417-B178-A9232C495FA1",
    "sahyadri completion bonafide.pdf": "A1AAE6F3-4836-4C3B-8BDD-5A5368C599CB",
    "Sai Susheel Chilakapati-RESverif.pdf": "2B4411C2-8662-4B56-B19F-108BB29A38F0",
    "Sai_Sriram_Letter_of_Verification.pdf": "49E1567C-F0C2-4346-90FA-1C8971F812FF",
    "Saif Yousif CV pdf.pdf": "402B77FC-AB80-4B5B-BC23-D7C15E683703",
    "Saira Alli-FellowshipVerif.pdf": "B7EA0CDD-80A1-45D9-ADA1-377A59855FC3",
    "Sajjad Saghebdoust - CNS 2022.pdf": "04509983-1C51-4282-830F-C5300F511042",
    "SAl-Sulaimani-emailverification.pdf": "F16791B2-08BF-41CC-BC8F-04F06D722FE5",
    "Salas, Sebastian Enrollment Verification.pdf": "B92A59CD-8F6F-458D-9EF4-BDD19276A559",
    "SAlbano.email.verification.pdf": "44C74B1E-788D-4918-BF10-AF854E392862",
    "Salcedo_Verification.pdf": "6CF6DAE6-D963-4D14-B7D9-81BBDB17800B",
    "Saleh Baeesa VL.pdf": "B677C333-1FF1-4571-8B5C-9EB8C151A7B3",
    "Salem_Hmoud_Salem_Al_Ameri_222222.pdf": "66912ED8-3423-4F55-A587-89C0832C0930",
    "salman aldakhil-Fellowship.pdf": "9AC2F428-33F0-4A02-BDAC-E3A8627E7134",
    "Salmanian Enrolment 20.04.23.pdf": "C6760944-DC5C-4FB6-B178-2AC960DEB73B",
    "Saluja Sabir-2024-LOG 8 24 22.pdf": "1CD4B620-0CDF-481E-A484-9142927EF105",
    "Salvage Operation.pdf": "A5D542F2-7E0D-4C56-A3D5-28EC791702EB",
    "Salvati - Society.pdf": "9E83CD81-0922-4C65-BF12-7D3225397251",
    "Sam Emerson CV 2023.pdf": "0D7BA862-F4EC-4B4B-8537-47A4476EBA0D",
    "Sam Emerson-RESverif.pdf": "96E6A56E-5191-4D2C-A81E-925BB9FFEFAE",
    "Sam Tavakoli-FellowVerif.pdf": "C7A6876B-BFBF-44FC-B27A-6DCAD1942F77",
    "Samadani cv Aug 2012.pdf": "E077AF7E-6077-4867-A1D6-7FCAE3204A24",
    "Samantha Spellicy Letter for CNS.pdf": "6B9DC649-6621-477E-AEE0-7116A4EB25F4",
    "SamCritides 2019.pdf": "EB0FC93D-0323-4B5D-8233-2AB20A53A12A",
    "Sameah Haider-FellowVerif.pdf": "D005133B-0328-4497-A812-338959C658E5",
    "Sameer Rajesh.pdf": "AD31E434-B909-4EFC-BE3A-71CC701A04F2",
    "Samer K Elbabaa MD CV January 2020.pdf": "67098B9D-1027-4F34-B186-A856D6E3378B",
    "Samer Zammar-FellowVerif.pdf": "D31E4674-D8E2-4972-B91A-5D1E65939429",
    "Samer_Hoz_Resume 06.13.2022.pdf": "81F0F918-1523-49C9-8BC8-E5C057CDF481",
    "SamLORGuideline4-21-2024.pdf": "CB4453CC-5BF8-49CD-80D9-86CD788F807F",
    "Sample Academic Action Flowchart.pdf": "A0E16AE5-4A1E-4356-9B69-EE3C790A3C4D",
    "sample_pdffile.pdf": "5CD82311-25FC-41DF-AE71-87BE3A9FB93E",
    "SampleLetterOnHealthcareReform10-09.pdf": "8C4D0970-D712-40CE-AA9E-C78FC6075452",
    "SAMPLESGRLETTER2-24-10.pdf": "F027ADDC-8D7C-4788-A745-CD803A3B85FF",
    "Samual Barnett Recommendation Letter 2.pdf": "5E61E104-D775-430B-9F04-9AA8F63DA66A",
    "Samuel Barnett CV.pdf": "00F16519-FB93-4B9B-B14C-F60420C24F4F",
    "Samuel Barnett Recommendation Letter 1.pdf": "E3C8999E-7C96-4FBE-9749-08D8F6858998",
    "Samuel Barnett Recommendation Letter 3.pdf": "0C80B6A0-DE1D-42D3-8C71-81792F29326D",
    "Samuel Cramer_RESverif.pdf": "5E826BFC-629D-438B-91FA-FD11539C7206",
    "Samuel Nodal - Letter of Good Standing.pdf": "0B01D4BF-24FE-4E65-B487-B8DAE8A4AB2E",
    "Samuel%2c%20N%20-%20AANS%20form%20Jan%206.pdf": "017325FB-F48A-4501-BFA3-3D42DAB81966",
    "SANAliOMSIVerificationEnrollment.pdf": "993DA8ED-D99D-4ECB-89F7-5B4FAD6CACE2",
    "Sananthan Sivakanthan-Fellowship.pdf": "7ABC73CB-251B-4018-B2DE-6CA05E3A4039",
    "SANDHU Mani - Reappointment Letter 10-1-21.pdf": "521EFB6B-9768-4FD6-A1DF-4F58C58F6CA5",
    "Sandhya_Rmc_2018.pdf": "4C78639C-CA19-44B9-A78D-2DFDEAC21B46",
    "Sandra Leskinen Letter.pdf": "308A3401-05BF-4EF7-B7E5-4173B024A0E3",
    "Sangami Pugazenthi CV 4.4.23.pdf": "C768FC2D-05D4-46F7-BC38-75534F53899A",
    "sangwon_e-poster_v03.pdf": "C4FBEAAA-BCD2-4CBD-A847-059E8E3CEAEE",
    "Sanjeev Sreenivasan-2yrFellowship.pdf": "27B4F2BD-ACF2-47FC-8125-E948430D6B56",
    "Sanjeev Sreenivasan-RESVerif.pdf": "373F6E69-5A48-4F41-B8B7-77BA66FB0298",
    "Sanjeev-CV.pdf": "2C421CF0-DBC4-4AE2-A09D-D1EF839EC6D9",
    "Sanlier_Nafiye_CV_2024.pdf": "F9F1F239-2623-458F-B443-13EFBA428995",
    "Sanlier_Nafiye_LOR.pdf": "1B7E00F6-66B6-4346-93EC-3DF5EFA1B559",
    "SANS ABNS Written Board Review 1.pdf": "A087DBB1-B8DE-4F9C-BE33-733525B39DE4",
    "SANS Functional Spec_RMTComments.pdf": "AE3F14DD-CF72-409A-84A8-0BDAED19AFAA",
    "SANS pain.pdf": "B174F5F5-C2E1-41A5-9A18-314358884FAC",
    "SANS Questions.pdf": "8B2E7270-2FDB-4B24-B7A7-5249C0D0F854",
    "SANS_Receipt.pdf": "A12AB76A-FDC1-4A13-95D4-6B888204FFCE",
    "Santellan.pdf": "E0F4B91B-1144-4ED3-8BC2-BE68481BCA8E",
    "Santhumayor.Brandon.Letter of Good Standing.5.4.23.pdf": "3BB0D2D3-829C-40D2-BCB9-3EC0BFFBBB2D",
    "SANTIAGO MENDOZA.pdf": "89C782E0-F2D6-4154-8261-BFD421F2C292",
    "Santo_Tutino_microCT_ISC2023.pdf": "27F332DF-CE69-4344-BAAE-40867566C8E4",
    "Sara Al-Sulaimani-2ndFellowship.pdf": "8C78D8F8-FC77-408F-B307-B9DC70E48DC8",
    "Sara Fathala Letter of Good Standing.pdf": "41CFB931-0B43-4F37-81D5-657D41F5D0C0",
    "Sara Fauzia HUCM Web Transcript 07.09.2023.pdf": "3EC04CDB-CFAB-4128-B98B-DBEC86AE7C60",
    "sara henao romero 3.pdf": "412CDBA2-61E4-45CA-A553-6FA8D27B2775",
    "Sara Zandpazandi.pdf": "ED0CB857-BAB7-4F2D-86A0-12AB46ECB1C9",
    "Sarabia-Estrada R_CV_ Sept2012.pdf": "D601D366-1194-47BD-AF5D-768C7CB29D8A",
    "Sarah Nanziri Notice of Acceptance.pdf": "BD6AFE6C-B94D-4C05-9459-170AE249D317",
    "Sardam Ahmad Cv-converted.pdf": "D25BC4F0-240F-43FA-BD7A-1A8617F39114",
    "Sardam Ahmad.pdf": "7B99CF79-C472-4620-85E2-61BB3B98779B",
    "Sarosh Madhani-Curriculum Vitae.pdf": "87E92886-45C0-4B1F-805C-2DFFCA1E4813",
    "SarpongLetter of Certification.pdf": "1698D98D-C4AF-4605-96A3-86622E531ED2",
    "Sarris Christina_Signed Residency Diploma.pdf": "439CC491-B74E-4CBF-A4F4-2D3E493B1D07",
    "Sasha Vaziri-RESverif.pdf": "2E7EFB9C-CE23-4FEC-BCEF-A76B8658187C",
    "Sastry CNS Letter.pdf": "65CE2CA3-1453-40EB-A987-5805CDBE12E7",
    "Satellite Symposia Application _ 2021 CNS Annual Meeting.pdf": "65BC5865-019F-42FB-BD9A-3E2C36F856C5",
    "Satellite Symposia Guidelines _ 2022 CNS Annual Meeting.pdf": "D1103BA8-7F41-43D5-986D-269AEE9E39CD",
    "Satellite Symposia Guidelines _ Congress of Neurological Surgeons.pdf": "7DE7BB31-E3B4-4684-BBC9-28E50141A5E2",
    "Satellite Symposium Application _ 2022 CNS Annual Meeting.pdf": "382EA9FA-2ADE-46CD-9C5C-69FC650A9452",
    "Satellite Symposium Application _ 2023 CNS Annual Meeting.pdf": "43278F2D-35D9-423E-979B-70027AB1A346",
    "Satellite Symposium Application _ 2024 CNS Annual Meeting.pdf": "294AB955-F89D-44EF-A236-9824A989272A",
    "Satellite Symposium Application_2022 CNS Annual Meeting.pdf": "C8F46C84-54E3-4DE0-AE27-5187D42BD000",
    "Satellite Symposium Guidelines _ 2023 CNS Annual Meeting.pdf": "7FE80631-80F8-4623-A21D-38917B1FE625",
    "Satellite Symposium Guidelines _ 2024 CNS Annual Meeting.pdf": "2DA4F1EC-AAAD-46DD-86EB-0B0D0ADE3859",
    "satellite_symposia_guidelines.pdf": "E183524F-FD84-4322-9F77-B917BA079D6C",
    "Sathi Chidambaram_Good Standing-2.pdf": "45B3A59E-28DB-4D5E-9D29-4D08BD7F4451",
    "Satpathy Yasoda UCSD Medical Student.pdf": "8587F60F-DE11-45BD-81E3-E2F2EE102C03",
    "Sattarov CV.pdf": "B36F148E-DEDD-4D34-8FA7-AF31C009A3E5",
    "Satzer CV.pdf": "453DB73C-1493-4168-B1F8-49763D2AE1CF",
    "Satzer residency diploma.pdf": "8D118892-3697-467E-9927-423E58AC3D21",
    "Saud Alzahrani-emailVerification.pdf": "B7B35A8C-A3E2-4D5C-B8CB-8603E8376BE2",
    "SAUL MORALES -  CV Apr 2023.pdf": "1524E15E-CA23-411C-A8D2-35C87E4D453C",
    "Saul Morales Valero-RESverif.pdf": "70524A8A-70F0-4E5D-AF42-A050802640A3",
    "Saunders LOGS.pdf": "32C4E932-7F51-4AF6-BA65-3DB83AD42A29",
    "SausonSoldozy_VerificationLetter.pdf": "16E21C06-99A7-4A7B-81C0-E30800D9DA18",
    "Sawaya, Raymond.pdf": "5D3773D0-FA61-426D-9E1C-149710EB4F1C",
    "Sawaya_Verification.pdf": "4E309D62-1E96-4F5C-B851-898033C45A98",
    "Say_FWLN_Schol_2022.pdf": "35B5C64E-4DC2-4692-A4A2-6D6BB2EDBB1C",
    "Sayeed Sumaiya_Good Standing Letter.pdf": "B28769EF-06D4-4C22-923C-9429C3C63962",
    "Sayyahmelli Sima CV 28 November 2019.pdf": "2E7AE575-E65B-48F6-B126-6606875F28A3",
    "SBN 19_07_2022.pdf": "09CF6F5C-48A7-4DBF-B559-F91961AC47EC",
    "SBN certificate-1.pdf": "F51D76FF-4FB2-4958-9604-DA5C0965C903",
    "SBN LARA.pdf": "EE44E3B2-CBDC-402D-ACCE-B30CE8EDFE4F",
    "SBN Member.pdf": "8D856711-301C-4C08-BD51-4B1E81A2208F",
    "sbn.pdf": "756F6971-15EE-4962-9ECB-38D12BC9A64B",
    "SBN_Brandt.pdf": "6EB0571F-5F4E-44F5-8CF8-94E1B360D011",
    "SBN_membership.pdf": "553C2189-DFFA-4F56-A34C-24509FD3411C",
    "SBNS - My Profile.pdf": "421E83D7-6AF1-461D-A026-005E5A6BC2B0",
    "SBNS CERTIFICATE.pdf": "B631FCB8-A4A6-4514-A1D8-4889350C0986",
    "SBNS letter.pdf": "7AA22656-95E9-4CF8-8852-8E0D91A216C1",
    "SBNS Membership - P Bhatt.pdf": "8A98CC96-B0B6-404A-9C19-5B3703DD5E65",
    "SBNS Membership.pdf": "D45E655A-A572-426E-86E8-5AFA234A2369",
    "SBNS.pdf": "9BDFF8BB-F8B4-48C9-A330-490F0A312833",
    "SBOnlineSemesterbescheinigung.pdf": "5680170F-2AFD-48FB-8E21-C5BBF2C2CEF3",
    "sbssi.pdf": "4140D985-BA74-4D60-BCBE-37A285F1CE15",
    "SC - Poster.pdf": "F77183A4-02A4-4D4C-A3A6-2289DBDFDABC",
    "SC LOGO (1) copy.pdf": "6C1C29C3-925F-4CFC-9EC8-99DA3497329F",
    "Scan 110600000.pdf": "0C0FE977-27C3-42E9-800D-C080054231B4",
    "Scan 13 Mar 24 23·33·01.pdf": "C03B419A-C83F-408F-B56C-DB1A78693448",
    "Scan 29-Dec-2022.pdf": "901FEFB9-4A3E-4DC3-87A7-8A459D80E9F1",
    "Scan 3.pdf": "FFCAEABB-54FF-46F0-BB6C-60E555B5C4E6",
    "Scan collins 2.pdf": "E6F0B094-5E7F-4A6E-AC95-0062F8AEB8E4",
    "Scan Mar 15 2024.pdf": "61503F9A-6A2A-4F55-A5BC-B707FBAB5AB1",
    "Scan sociedad peruana de neurocirugia.pdf": "865714DC-AD9F-479E-AEE2-9633B5DD42F4",
    "scan(4).pdf": "775CE4EA-AEDB-4646-B8C1-72C95E1DFE2A",
    "scan-8.pdf": "110E514D-C7A1-499B-9083-12F1381A2A34",
    "Scan-to-Me from cns-ug-lab-ms01a-prn1.wits.ac.za 2014-09-02 155519.pdf": "2F32AEA2-2E88-4232-BF49-72506D3B9CD3",
    "scan.pdf": "F54E287A-9DAC-469E-98ED-5127799A1832",
    "Scan_0028.pdf": "78D6714D-3F5F-4CB3-A642-2427B4E9910E",
    "Scan_Doc0001.pdf": "C3A7902C-14BC-4890-BC9A-762042C2E237",
    "Scan_Doc0101.pdf": "1215CD19-BD3D-4174-8534-8638F44F3730",
    "SCAN0001-1.pdf": "11531D8E-1D26-4E66-87F0-8A4059D4994A",
    "Scan0001.pdf": "86C64A17-B92B-4918-AB1F-33667A42CE21",
    "Scan0002pgm.pdf": "CCA0DA7C-29D5-472B-8DEE-C09D8147DE7A",
    "SCAN0003.PDF": "BE4A6489-FD03-4CE6-8805-BB84C1285431",
    "scan0104 1.pdf": "C43B496B-DB18-468A-B785-1984FFBE2BA8",
    "Scan0147.pdf": "A7346C61-4CC2-40FD-83FD-3123F0891D19",
    "scan0370.pdf": "A2DBC7EE-0F6B-4B9A-8CA6-2F344274A9B0",
    "Scan1.PDF": "F99A19AC-A1A7-49D2-98CD-C6D2459B014F",
    "Scan10072018.pdf": "BD5DD8CC-64CE-4364-A5E7-E6773EF52776",
    "Scanlon Letter of Good Standing.pdf": "8FFFF6BE-1FDC-4B35-9102-03A90735289A",
    "Scannable Document on Feb 2 2023 at 6-01-38 PM.pdf": "AD01A640-A0D1-4AA8-96A7-45BB3F784C05",
    "Scanned Documents.pdf": "8ABDB97C-9DA1-4112-B13B-BCBBD8793580",
    "Scanned from a Xerox multifunction device001.pdf": "05E80B28-2F7F-408D-9B2B-23FF3ECFB8D9",
    "Scanned from the University of Rochester.pdf": "033FC776-2FDA-4955-A579-991BE8FFF10B",
    "Scapular Pain Figure 3.pdf": "9A5429D6-157E-4EF8-ADBA-99D87356157B",
    "Scatterplot, C3-C7HU vs. DXA T-score.pdf": "F82D0678-9661-498B-948B-0EA08BFB93C7",
    "SCC_2020_Online_Agenda_final.pdf": "7FB54598-B4D8-42CC-91AC-4920F1DD3F83",
    "SCC_2020_Online_Agenda_final_11.26.19.pdf": "585FAA14-A289-45EB-8D28-21660DF47DF5",
    "SCC_2020_Online_Agenda_final_12.11.19.pdf": "D01A90C2-BF19-4778-8D50-DD0637A200E7",
    "SCC_2020_Online_Agenda_final_12.12.19.pdf": "D4BE253B-4356-4D6E-A2A2-F7B5089F06ED",
    "SCFguideline (1).pdf": "03928FA9-4A0B-4988-AA33-032FDC5C1485",
    "SCFguideline.pdf": "584BACDD-BC17-41D9-945E-2E26F4D00551",
    "Scheitler Kristen_Academy Manuscript_2024.pdf": "8903FEE3-E184-4B5D-A056-171E748877C3",
    "SChen.emailconfirmation.pdf": "F49DD8DD-7082-4321-9B1C-5D645D2F50ED",
    "Schen.emailverification.pdf": "CBB8E20B-5B65-4055-8EC0-747CAF9E490F",
    "SCheok.pdf": "0D24669D-15CB-449E-BCB7-47B6C59A113E",
    "Schimmel Samantha-Letter of Enrollment  Good Standing92.pdf": "B09A7E92-576A-4700-8A14-2DC860A9FF15",
    "Schirmer_2024 CNS NS emergencies Antiplatelet reversal.pdf": "B3700601-DF2D-4406-AD98-9DC043175541",
    "Schirmer_2024 CNS NS emergencies ICH operative management.pdf": "D7C15FC1-21CE-4589-BB1D-7D065B981BC7",
    "Schlauderaff CV 12.29.pdf": "FD13127A-D46A-4292-8412-AC868CB1FE74",
    "Schlenk-MurayiResidentFellowship2021rev(RM).pdf": "0158B9FE-172F-4E1F-8947-0B81DA59019A",
    "Schnurman_CV_2021.pdf": "03AF0A53-5ABA-4C6D-A5BD-902BE67D50F1",
    "School Enrollment Verification-Letter of Good Academic Standing.pdf": "F2BB9658-1199-45D8-A8DA-0D41C7E04114",
    "School letter_Support_elective21.pdf": "30307DF7-550C-462B-8ECF-27F241FAB889",
    "School of Arts  Sciences Mail - JHU SOM Decision Notification.pdf": "C3E23F09-AFE6-413D-96D5-E0025DF23611",
    "Schramm, Johannes.PDF": "C09F84EA-39B6-4F17-9583-76F509669433",
    "Schriber.pdf": "821F5BE1-3E75-4CFF-9F2E-73A9F1BE0B61",
    "Schulder, Michael.PDF": "17DA78DF-6499-4F16-BF6B-06BDFE4C86ED",
    "Schulder. Michael.pdf": "B2246EDE-D4CA-4D6F-B383-22D6096F62FE",
    "Schumacher CNS 5.16.24.pdf": "1971C6FD-F46B-4514-9389-5DC9805D6016",
    "Schuster_LOS Malhotra SNS_100322.pdf": "96BD4C93-5665-4493-B68A-CDEBC54733C0",
    "Schwab Resume 2020.pdf": "5501AB19-D2A3-4A71-976B-EC1205E9FB38",
    "Schwalb Executive CV Summary July 2023-combined.pdf": "5C6ADD4F-17AD-4220-A585-096DA350660B",
    "Schwalb Executive CV Summary Oct 2022.pdf": "31F1814E-2E30-4C8D-B7CA-111F234125FD",
    "Schwalb SNS Nomination 093022.pdf": "DE2F63B6-BA09-4376-9184-110550FE6868",
    "Schwalb SNS Nomination 9.30.22.pdf": "89773C74-035E-4072-9030-9CCF5F2DC19D",
    "Schwannoma Poster.pdf": "6FB84E54-F105-430A-9F50-ED46C7D683CC",
    "Schwartz LOR.pdf": "FD66FCE1-CD37-4095-86BE-5544B185D712",
    "Schwartzbauer_CV-2018.pdf": "5233FDB1-6AFD-447D-8991-6FDD7122BB3A",
    "SCI CNS abstract .pdf": "C123D9FD-4130-46B3-9E23-316ED10E06CF",
    "SCI DVT poster_CNS 2023.pdf": "7B5BCB74-C4E1-4C77-82D2-68BCE4031F78",
    "sci pro 80.PDF": "56025006-EB26-4C9B-956C-C313C8C3F54B",
    "SCI QOL poster_CNS 2023.pdf": "9C4C885C-DBC6-4FE9-A77B-2019E6365ABC",
    "sci_group_mccain_admendment_letter.pdf": "42E901DC-AB5C-45C9-BDEF-760A4D92FFDA",
    "Sciarra Mariano CV SummEnglish version.pdf": "4A74D5D1-E62A-4787-9F23-62857DFF0AF5",
    "Sciatic nerve Stimulation Accelerates recovery after Experimental Spinal revised.pdf": "03AE5004-68AC-47CA-A3F7-9082FD3ECCC6",
    "Scientifi Program 1962.PDF": "0F470FDD-1C79-4E7A-96FC-FBA261882DE1",
    "Scientific Program 1952.PDF": "CFEBA988-A132-4207-94BA-D4FBB2D03617",
    "scientific program 1999.pdf": "C98F14B6-B67D-4ECD-9F9D-452AB6C533CA",
    "Scientific Program 2009.pdf": "7026AB38-561A-4CC6-9876-58394F7F3AE8",
    "Sciscent_CNS.pdf": "4B33E40F-B32A-4312-B4B2-1316218FF365",
    "Sciubba AAcNS sponsor letter Schulder.pdf": "7A91F38D-761D-49B9-8AFE-7811381331E6",
    "SciubbaAAcNS Brem.pdf": "BF9D17AC-4176-4859-8C62-93C31429ED5F",
    "SciubbaAAcNS Riina.pdf": "8CFD2365-F6CB-4528-97F7-7DEF3473B38C",
    "Scizar_Samuels_-_Neurosurgery.pdf": "D84000E5-3E62-4218-A5D3-7218EBCF78C1",
    "SCN_0003.pdf": "1C366821-60D9-426F-8FE9-4D3D3903DFCA",
    "Scoliosis LOS Readmit CNS 2019 Poster.pdf": "9086BD8B-E54E-4A43-B000-5BCD47F594AF",
    "Scoliosis Poster- CNS.pdf": "DEB3AE85-E1CB-484D-9DB4-53E446C76EB0",
    "Scoliosis Prolonged Intub CNS 2019 Poster.pdf": "23560D53-1B00-458B-8244-99CAD689A746",
    "SCOM-DEAN-K18092809070.pdf": "EAA61AA7-990B-49E8-9791-78993C1D3C83",
    "Score_Report_M1_M2026_B4_V3_212-212.pdf": "69965FE3-CB00-4908-83C8-A17241F6FFE7",
    "Score_Report_M2_2025_B6_V2_58-58.pdf": "EDFC30FC-8D84-4D63-965F-935BA41CEC66",
    "Scott Connors-FellowVer.pdf": "2B425939-0154-4BE0-9421-94A4FCF86DF7",
    "Scott Seaman-FellowVERif.pdf": "DBE90CC3-C900-41E5-A3EF-E33B5FC519B4",
    "Scott, Michael R.pdf": "26B5C222-2DAF-4CE4-82F1-D55F7A2FD3F9",
    "Scott, Michael.pdf": "FEBFD646-D78A-4591-A44D-07AD787A757D",
    "Scott, Mike.pdf": "0423C496-2558-45CA-8CCC-7D611B8EF5BD",
    "Scott, R Michael.pdf": "79AEECFC-3A00-4233-BE69-1AE0C40FC3CA",
    "Scott, R. Michael.pdf": "6ED15466-D902-4E1C-9E12-741F8C297207",
    "Scoville_CNS_WELLNESSPOSTER_done.pdf": "31EDB4EC-AB4D-42C3-A947-B0DE7DE0147C",
    "SCPP_Table_1.pdf": "EBD67091-3B39-43A9-9A6F-4FB6EF176F24",
    "SCPZ?-17855 About Studies.pdf": "5996ECE4-60D5-4FC0-B2A2-5402F9F55197",
    "Screen Shot 2017-11-23 at 4.32.45 PM.pdf": "80A5F560-B944-4282-9720-4E2D76015D22",
    "Screen Shot 2019-02-20 at 4.55.02 PM.pdf": "5C363DEB-5CFE-455F-A9D6-C075BA1F0F36",
    "Screenshot 2023-08-05 at 2.16.08 PM.pdf": "56D397E2-96E3-4762-876B-6CD2DA74B197",
    "Screenshot_20200115-080242_Chrome.PDF": "A2F6377E-477E-4FBC-8EFE-1CF8FADEF636",
    "Screenshots 2020-05-30 15.30.31_20200530153131.pdf": "3F000113-92E6-41C3-A144-A753A6594949",
    "SCS copper def poster final.pdf": "539802C9-631D-4011-B0CC-628F2D6ADF43",
    "SCS Poster.pdf": "8D575427-9A07-401B-BF6B-FB6FEFCFDBA2",
    "SCSupportLetterBennetBarrassoImmediateReliefAct.pdf": "5A9CE08E-4A7F-4547-A3B1-4C3DB8A00D09",
    "SDayawansa-verification email.pdf": "BF551FF2-B74B-4FE4-9ABC-853E4124F052",
    "SDCs.pdf": "BC5CF10B-1515-4272-B634-D3A40142856F",
    "SDE Peds section poster.pdf": "42B61C7B-8292-46A1-B0C3-E53A466C8F35",
    "SDH Burr Hole - CNS Poster.pdf": "3A967C58-3432-460D-8373-A54DCBD5C248",
    "SDH Craniotomy - CNS Poster.pdf": "DAA09986-FB5F-4A8E-B0C9-51757BCAEFDB",
    "SDH Trends CNS 2019 Poster.pdf": "D2707D5E-E4CA-479F-B2CA-B005CA790624",
    "sdonovan_mu_sign-on_ltr_9-15.pdf": "68390A4E-70F3-43D3-83E1-EA4A8A3F7DAD",
    "SDR Poster CNS.pdf": "8BFB8027-FA42-4DE9-8D67-0A83456493D1",
    "SDR0.4.pdf": "2854380B-DD59-437B-8304-2C60DC3218E1",
    "SDT Review Poster.pdf": "107802CF-5585-4B71-9DD5-AD024BBA7C67",
    "Sean Grady_Jim Schuster Nominating letter for SNS Membership_102520.pdf": "616C89B7-0B54-46B6-91FB-0840FBB3E9E2",
    "Sean Lazaro Proof of Enrollment.pdf": "8701351F-B2D9-4C43-A4E7-39100CE3D8FC",
    "Sean Lew Nomination Letter.pdf": "E6F9CA5A-FD5B-4E02-8E7D-84ACEA2E48C9",
    "Sean Lew Sponsor Letter.pdf": "98CF2A30-FAFB-4408-B967-1E6C0EC5FEFE",
    "Sebastian Koga CV Jan 2023.pdf": "44336752-85C1-46CB-AA9A-6BA87AC390A3",
    "SEC 2023 Agenda Book- Final.pdf": "017097EC-6325-4006-82C7-11FBFE951589",
    "SECRETARIA DE SALUD.pdf": "DDD501A1-50B6-4154-87C1-14A02A0B4675",
    "SECTION GOVERNANCE.pdf": "BAB0FF32-40E8-48B8-A6FF-3AB4C12FA884",
    "Sedney_Cara_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "75A14954-2E99-4FDA-8584-2C56A509CBB7",
    "Sedney_Cara_2024PositionalMembershipApplication_CVandBiography.pdf": "157EB1F9-EE53-4300-9E28-32F9B73D263C",
    "SEEG Accuracy Poster.pdf": "0470FF95-8DC6-4905-9C52-8AE26311C43A",
    "sEEG NeuroOne poster CNS 2022.pdf": "9E7F8C63-0277-4E78-BB18-ACA8291FA513",
    "sEEG RFA poster PDF 11.11.22 .pdf": "7856F253-9A3C-47CB-9CCE-BEF362B68E3E",
    "sEEG Robotics Poster.pdf": "DFD4F691-552B-4B1B-B366-3C88E98A1CD0",
    "sEEG Table 1.pdf": "AD730B10-DCC8-436F-A14A-D3CE3000CB35",
    "SEER Epidemiology of Brain Mets - CNS Poster FINAL.pdf": "616A6E7B-9016-4785-8178-D0F44D1B2566",
    "Sefcik_CV_CNS 1.pdf": "4E0784A8-F3F8-4BE5-84BB-833D1D1E4AC6",
    "Seifert, Volker.PDF": "C9B18B64-DF99-4AEA-8E56-ED0B8A0C5CCE",
    "Seizure-freedom with anterior nucleus of the thalamus deep brain stimulation.pdf": "AC9E1CD8-84D7-4946-ABC5-225C755F1F8E",
    "Seizures 2014 final.pdf": "A6E2D084-6570-4A5D-A1BF-5CBF8B313594",
    "Sekhon.pdf": "579E2121-F60E-4743-8E2E-EB8F47B97BB8",
    "Sekula Raymond - CV.pdf": "61959F21-8712-40EE-8403-07B9EFC507A0",
    "Sekula Raymond - LOR IFP.pdf": "44C9EABC-7C9A-49C9-9B91-6833E7F50619",
    "Sekula Raymond - LOR LDL.pdf": "3B70AC65-B81E-4154-8FBD-BA643969617A",
    "Sekula Raymond - LOR RMF.pdf": "1C7174D0-8BF9-42D4-934D-839BD2DF2823",
    "Selcuk YILMAZLAR CURRICULUM VITAE.pdf": "B10F255E-16DE-4998-8333-D625F72E57E1",
    "Selden on Orina SNS nomincation letter 9.27.2022.pdf": "8E66399F-F3D9-4D4E-8713-D5B644EAD922",
    "Selden Sayama SNS nomincation letter 9.27.2022.pdf": "052FFABA-ADE1-4576-B9CC-764428652F4C",
    "selection letter.pdf": "8E869F09-E0BE-4C9A-BAB5-524D6526021E",
    "selfie sign.pdf": "732453A8-7DC2-4CE9-9522-9F885927FC96",
    "Selim Ayhan certificate_EANS.pdf": "DD016005-CE2F-4AE2-A063-FE6B750C82A3",
    "Seljeskog, Ed.PDF": "05F11FA2-36C8-43CF-86B1-D3E3F8252113",
    "Selma Z Kominek CV.pdf": "43F00B7C-9B81-44B8-AED8-285E2A91EE57",
    "Selman, Warren.pdf": "22A91186-B697-4940-9EC6-0D650DAE230A",
    "Selner Ashley_GS.pdf": "FBDCD1FA-7AA4-42F8-A0E9-590738AD5B36",
    "SEmerson.verification.pdf": "97839B24-6DE1-4CF2-ABDF-4EA9A8D38423",
    "Senate GMAC Sign on_Resident Physician Shortage Reduction Act of 2021_FINAL.pdf": "2A91BC5B-2442-4FA8-BD99-5ED590B105B4",
    "Senate GMEAC Sign on_Resident Physician Shortage Reduction Act of 2023_FINAL.pdf": "2FFAB863-9684-4D7E-9EF1-15366A33E086",
    "Senate Innovations Sign-On Letter 4 27 16.pdf": "0ABBDFBF-5918-48F6-80F4-48EE0D26A135",
    "Senate MA PA Letter to CMS 6.21.23 FINAL.pdf": "04684B06-CBF6-4032-9EF3-D3367203DE13",
    "Senate_Holding Providers Harmless_Boozman.pdf": "C3E8DCB7-2D69-4B8C-99E5-D67DFE52AF23",
    "senate_innovations_sign-on_letter_4_27_16.pdf": "A91EEFA5-2226-4D11-A070-A4D5D0A341E3",
    "senate_mu_sign_on.pdf": "45EFBA98-F996-46DD-8E7E-BB95ACC36FAE",
    "SenateGMEAdvocacyCoalitionletter11-20-13.pdf": "6464B6F8-B946-4AF1-BF20-56289BAB9A84",
    "SENATEHEALTHCARE.pdf": "B83E26B1-9B4E-4E8D-95DF-FB81CECD88E6",
    "SENEC.pdf": "EEA0E795-1FA4-4808-A4F4-A85B6A910DDE",
    "Senft_ DGNC_2014.pdf": "23A7F6CB-B965-4F96-84EF-027304541824",
    "Senior Accountant.pdf": "0F860AFA-0908-447B-BCC5-FF67CBE28280",
    "Senior Digital Solutions Project Manager.pdf": "029D5FB0-3173-45F7-8228-A86894F8153A",
    "Senior Industry Relations Specialist.pdf": "9CDB5277-C126-48BD-8500-FD866007A890",
    "Senior Marketing Specialist - Allard.pdf": "03FBFC09-3567-4898-9CCC-793BA8B7AE9C",
    "Senior Marketing Specialist - Gbur.pdf": "3A349B10-57DF-447F-97D9-F114608B643E",
    "Senior Marketing Specialist - Morris.pdf": "78BCBC2B-06B7-44A7-8745-2457F7A1E81E",
    "Senior Program Manager.pdf": "767F1C1B-B3FC-46A0-9057-32FED9AA557F",
    "SENS_SPEC.pdf": "052CA105-DF27-4831-83BF-FBA21411B04E",
    "Seok Yoon Oh Enrollment Verification Letter.pdf": "F24A61F0-74D7-4B68-AA64-90D8185ED3F2",
    "Seokchun Lim-RESverif.pdf": "B86C2C31-1E96-4FE4-B873-5CEC8C3AD664",
    "Sepher Saberian LOGS.pdf": "8CCE82F0-8400-40A9-9259-873A2D297B4F",
    "Sept 2022 journal article.pdf": "C3748AE0-DF32-43D2-A9EA-1AEA1344723B",
    "September 2021 journal article.pdf": "DCA5B343-8ED7-4D0F-B61F-7E15FB4C585D",
    "Septostomy Complications Tables_CNS_051420.pdf": "AA78276F-82D1-4B62-84D4-181B1F29B88E",
    "Sequester Moratorium.Coalition Letter.2.28.22.pdf": "F03F7473-707A-42CB-86B0-DD4718B622C2",
    "SequesterSignOnLetter_House.pdf": "E74ECA16-6F56-420E-A4B9-43171A7B8491",
    "Serdar Rahmanov_CV_2023.pdf": "953002A2-5650-4A61-8FFA-522244678077",
    "Sergio Arizona Garcia-Fellowship.pdf": "E928A4C8-D845-4FE4-B658-C726D66C54D4",
    "Sergio Arizona-emailVerif.pdf": "75B1A66A-BA1A-4000-BDC8-7B3CD850622B",
    "Sérgio Sousa Curriculum Vitae Final Euro 2021.pdf": "D0988617-4977-48E8-99A5-585A0276A3F8",
    "serkom BS.pdf": "841581B9-E1F9-47BD-9D81-CE82B5B8D2FC",
    "Serkom dr. Onie.pdf": "D9511259-7CBB-4D41-B6B1-F1ECE9B3DA13",
    "Serkom.pdf": "8253DE02-202D-4867-87A2-E6169CF88BF6",
    "SEROTONIN Poster3.pdf": "703A035B-CF6F-4489-AE7D-DD22FBF815A4",
    "Serra.pdf": "07612167-F89E-4CE7-99C5-81D2544EDC9B",
    "Sertifikat Kompetensi AWN.pdf": "EED5D92D-34D8-4BCD-84C6-91A6E72AE737",
    "sertPDF.pdf": "E0AABAB4-2ABD-41EC-9FC1-04CAA7FD454B",
    "SES PRL Poster.pdf": "A4443A70-6801-488D-8860-0E594F2CA26F",
    "SEshraghi.emailverification.pdf": "E319FEEC-BAA2-45B6-9135-63F34A971F1B",
    "Session 1 Breakout 1.pdf": "D3632925-F4B2-4A09-98E3-AD052292135A",
    "Session 1 Breakout 2.pdf": "1A94D1CB-C5F4-4C2D-AAB3-6B3F3024A1A0",
    "session 2_ Developmental Stages PDF.pdf": "ACA05FFE-FA2B-4CD8-85C7-A06A39C2C13C",
    "Session 3 - Case  conset session.pdf": "60B16D56-34B6-4344-A5FC-77CDB1CA0C0C",
    "Session 3 Breakout Room Instructions.pdf": "6342A515-4C2F-4DD1-9796-6010BB5B5B5C",
    "Session 3 Breakout.pdf": "FEDABC1E-5332-41D1-9E40-6164794D7651",
    "Session 4 - Case 1.pdf": "CEFB07A1-618F-405E-801F-46C6D0E58B41",
    "Session 4 - Case 2.pdf": "F625A5AD-7853-48F0-AA6F-9DC9D4C40787",
    "Session 4 - Case 3.pdf": "571F7511-AAC8-4A31-B851-C622EA866B24",
    "Session 4 - Case 4.pdf": "399E25BA-E840-4489-A7C8-53FF6BE7FBBF",
    "Session 4 Breakout.pdf": "9F6F6D9C-A105-450E-8EF3-F9647BF61A8C",
    "SET Trainee Agreement.pdf": "37C4F4DC-FBE8-4821-8970-7678C7B508FB",
    "Sewell Brice - Letter of enrollment and good standing.pdf": "308080DB-5CFF-4D31-86E9-483582965636",
    "SexDiff.pdf": "B4F2FD50-4509-43A9-B40F-B5B3FB9B169E",
    "Sexton Letter.pdf": "8C854F02-769F-4964-A204-0069A5F10205",
    "sf 2010-compressed.pdf": "ACD043BF-B7D7-4068-8F5D-DACAB62F0A44",
    "SGB Poster V2 pdf.pdf": "4F1DE616-4110-4DE8-9D91-526B8DB28F60",
    "Sge83029mfp14080810130.pdf": "8EC56C37-6609-446A-BF89-0030FC0D58D7",
    "sgr_group_letter_final.pdf": "8DDAC843-F569-4DDC-B446-6DFEE93FA300",
    "sgr_release_3_19_15.pdf": "4FF7D13B-A0A9-45F0-B356-7B3A62500F46",
    "sgr_release_4_14_15.pdf": "4DB2D050-4DEF-4FC3-A5AC-2817F12DEF3B",
    "SGRcommentstoECFinal.pdf": "E7193207-40FC-4A82-A7CD-CEDC4DF29E49",
    "SGRcommentstoWM.pdf": "A72C31AD-3821-49D1-A299-7D9AE9765424",
    "SGRcommentstoWM_0.pdf": "E2FF7FBC-C2A9-4D54-86BC-71EC280E8F20",
    "SGRGrassrootsAlert2-24-10.pdf": "2803578B-46D7-4337-851A-FCC5199BDB39",
    "SGRPatchOppositionLetter.pdf": "6B755660-33CC-4D7F-B8F7-44AFB9E6759F",
    "SGRTransistionPrinciplesSign-OnletterSFCfinal.pdf": "104216F3-0EDC-4EFF-AD4E-1AB899EF2493",
    "Shaaya, Elias_Enrollment.pdf": "64814CF6-3FA6-49C1-9D23-4E539AEE07E7",
    "Shadi Abulhala CV English word 28.1.23.pdf": "A6211F41-96C6-41A3-9613-77260AF61FDC",
    "Shafi Hamid CNS Poster.pdf": "F7554311-4166-4385-A893-85DF9E974747",
    "Shafiq_verification.pdf": "CFD208CE-CD30-4689-8EF1-32D789516EBE",
    "Shah Darsh_LGS_07192021.pdf": "F0FD2E25-195A-40BB-A31B-51774D958336",
    "Shah Shrey Enrollment Letter.pdf": "FC7DB618-D939-4FB5-9908-0846D40DC646",
    "Shah Vaibhavi - Good Standing Enroll.pdf": "4137D53F-D31F-42DC-8ABA-8C5B6EC4F9FC",
    "Shah%2c Varun LOGS.pdf": "1C6D9B88-781B-4BB5-B047-FA54864B4F8A",
    "Shah, Aakash.pdf": "5F6CE1FE-571D-4E74-90C8-F18501BB193E",
    "Shah.Aakash_Good Standing Letter.pdf": "0D14624D-559C-45D7-80C3-FDA763B0D61F",
    "Shah.Harshal.Letter of Good Standing.2.14.22.pdf": "0C4EB72F-5CDC-479C-80B4-72B21A89C746",
    "Shah_Mitesh_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "53E266B4-EFD7-4598-8803-96F2F4450488",
    "Shah_MItesh_2024PositionalMembershipApplication_CVandBiography.pdf": "0F1F5D93-CFC0-4BBD-ABC1-0AD8F4A6E153",
    "shah_syed_mansoor. cv 2024.pdf": "90250A06-AB08-4420-AFA3-EFBE49C9587B",
    "Shahbandi.pdf": "CB64E781-1618-4C91-9AAA-185F3981E672",
    "Shahed Elhamdani CV.pdf": "46C362C3-2CC3-4DEF-B89B-189D1C1BE26C",
    "Shahid M Nimjee CV .pdf": "32AF2396-6F57-4E6D-8CA0-8946C69A7506",
    "Shahjehan Ahmad-FellowVerif.pdf": "BA56836D-17E1-4E12-B5FA-D8425343E88D",
    "Shahlaie Bergsneider Letter.pdf": "8C770065-5453-4DAF-9DF8-3B0F0E7A117E",
    "shahlaie bio.pdf": "0FD1C9C6-21D9-4214-97DA-AAD2353F4294",
    "ShahrestaniShane_KeckUSC .pdf": "AAA658C2-B6F3-4709-B9E8-67EDCE7C66A5",
    "SHaider_ABNS_Exam_Passed_Board_Eligble.pdf": "2376FDF8-AB83-4EEE-8365-7D0728F13250",
    "Shaikh Sami Letter of Good Standing.pdf": "16726C89-3130-4113-AD2A-F3C63FAB4581",
    "Shamulzai Ahmad - Verification.pdf": "EC417F8A-7803-4EE8-BB4C-5E1D491FF70A",
    "Shanahan_Good Standing LTR.pdf": "0AB954D6-F16F-4449-99A3-C421819E4A42",
    "Shanahan_SAFAR Draft v3_4-27.pdf": "54AC19BF-D554-42A5-917D-BC8B564B705B",
    "Shank LOR.pdf": "34CBBF9C-F156-4241-8E2E-2E4EE026E155",
    "Shankar_CV_021924.pdf": "712D4B0E-596E-4E23-96A7-AF073412D486",
    "Shankar_G.pdf": "01E114D2-C7B2-44A0-A844-3FBF3D67C129",
    "Shankar_HarvardCV_120321.pdf": "0435000F-493C-459F-8325-127C4CE9A4B6",
    "Shao Certify MD-Currently Enrolled- Good Standing.pdf": "019B15F0-B74F-4569-B211-65F7DB540920",
    "Share Sonal CV .docx.pdf": "D33E5064-D68B-40DD-B57B-F753D0CD6139",
    "Shareef_CNS_Letter.pdf": "ADA8E93A-1EEA-4F70-8A53-37AEEAB83806",
    "Sharkey Brandon  Letter of Good Standing.pdf": "2549A5EA-6099-43C2-992E-A66CEC5DC9AB",
    "Sharle Nichole Newman.pdf": "D54A8B4B-9E6C-4B18-A336-C087FB7630A3",
    "Sharma Eshita 2028 Expected Enrollment.pdf": "534A9071-1A8B-4F15-B8A3-BD3A94FCA065",
    "Sharma letter of good standing.pdf": "C60A0B7A-EEAD-4BC9-B62C-F9EA5CE538F6",
    "SHartnett-residency certificate.pdf": "ABD1C26E-4A15-4975-8470-45775C468F6F",
    "Shashwat poster.pdf": "7B6E2D59-2BA2-4DE3-A60D-A8949D14544B",
    "Shawn Adams-RESverif.pdf": "6B691567-FE1E-49B9-9330-1C81A23D41BE",
    "ShawnHerveyJumperCV10.9.2022.pdf": "2E7C2469-D1E7-4DD1-AB67-9182F3F0C296",
    "shayan huda GOODSTANDING  7-23.pdf": "EE3516B0-103E-4819-8AF3-03B44D071BFD",
    "Shayan Rahman MD.pdf": "3E0F7F50-D87D-4230-B116-507B172EC930",
    "Sheila Eshraghi CV 2024.pdf": "8691219F-2556-4DEC-9523-5ED1597254F3",
    "Shekara_ASSFN Poster_V2.pdf": "DD80749D-53D0-4D9C-8FEC-90E1A2762C22",
    "Shelley Flecky - CV 2014.pdf": "4A84ED1E-90D0-4FD7-8CEF-1A68E02576A7",
    "Shelley India - SKMC 2025 LOGS.pdf": "8380CCA6-1B63-4591-8EE8-ED3B2F9043F2",
    "ShelvinKierany Letter of Good Standing.pdf": "7FD6BF59-D194-49AB-BC2F-2D24DB841AAB",
    "SHEN_CV_04282021.pdf": "03E8DEA2-9F04-4545-B19F-E1D4D1C77D3A",
    "Shena Ayobello-RESVerif.pdf": "940D8B71-2AA5-463C-A43B-C1D94AFA30CF",
    "Shena Ayobello_fellowship.pdf": "55D22014-0B6B-4FB9-9E73-AF9777DC7BD7",
    "Shenai_LOR.pdf": "F1AA6956-2F95-42DF-BE41-A5F1FE06BB10",
    "Shentu Yujia - Letter of Good Standing for Away Rotations.pdf": "1ADBF80C-2AA9-4D0F-BC5D-73F2270D6176",
    "sheppard_UCLA_enrollment_verification.pdf": "4A6CC688-11EB-405D-8210-B0D7AE714809",
    "Sheri Palejwala CV.pdf": "A189D73A-B46D-43B2-8711-B5CFD9EFB14C",
    "Sherman_Josiah_Verification letter.pdf": "0BD8CE44-5FFA-4F59-87BF-DCB7FD6B0F98",
    "Sherry Fischer MBA  2020.pdf": "0434ED78-24A6-4B14-BCFC-D45C1FB0A73B",
    "Shervin Hosseingholi - Enrollment Verificataion.pdf": "1A41F5C2-63DE-4F19-B09E-4B12D09099A6",
    "Sherwin Andrew Tavakol's ver.pdf": "F6F58D57-5025-4314-B2B9-68538FF96D01",
    "Shi Shi_Letter of Good Standing.pdf": "817869D0-7763-47B5-B488-079DD02AC49F",
    "Shin LOGS.pdf": "BDA95F34-0204-4C0E-87C3-85B56BF0AFE2",
    "Shivani Rangwala-Fellowship.pdf": "200AB780-7CB3-43B8-8A03-987F9EBB3062",
    "Shivani Venkatesh Letter of Good Standing Final 05192021.pdf": "8B7F06C6-42CC-4BC4-98E9-048BFB656D28",
    "Shoaib.pdf": "E818AA16-9E48-42DA-AA5D-2E679B487F16",
    "Short Biopdata.pdf": "FBA48A42-A64D-49D7-A40A-6689A91C144B",
    "Short-term Morbidity and Mortality in Pediatric Deformity Surgery.pdf": "10BD9C47-C63A-4EFE-A764-B177BF2CCEEB",
    "ShortCVMariaCarolinaMartinsdeLima2020.pdf": "33CE5EFA-9D7D-4D22-BF89-00E5DD8E3F64",
    "shost_transcript.pdf": "8F70E5C4-EA7D-4CBC-B508-2D9D90B817A9",
    "Shravan Atluri-Verification Letter with Graduation Date.pdf": "9824BB28-DA70-4860-8EDF-1D9165E509E5",
    "ShresthaKeshari Elyse.pdf": "3DE4E85C-BD5C-4FAE-AD81-8D5A8F4D2397",
    "Shrivastava_ Updated SNS Membership Nomination.pdf": "A30F99C8-5BA7-4C34-9F4C-B23D6E9C37A9",
    "Shrivastava_NEUROSURGERY_Residentfellowship.pdf": "A883F6F8-CE88-40AA-A416-0AB5648CB920",
    "SHRIVASTAVA_RAJ_2024CVandBiography.pdf": "C86C0750-5E89-475F-933D-B92619BC3533",
    "SHRIVASTAVA_RAJ_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "DB6DD2D8-5743-4673-9E4D-60923DA545E1",
    "SHRIVASTAVA_RAJ_2024PositionalMembershipApplication_CVandBiography.pdf": "B36DDA4D-B884-45CC-8CAD-A2CB333B1F27",
    "Shrivastave_Nomination Letter_Letterhead.pdf": "A6F12057-50D7-46B9-BE7F-54FC19AC68A0",
    "Shunt dependency for hydrocephalus in cerebellar arteriovenous malformations.pdf": "273007B4-2A4C-428C-9A5C-F6FC110C3BEB",
    "Shunt Poster.pdf": "01C7A169-2C62-4141-AD0C-3ABFC2D785E3",
    "Shunt Protocol Poster.pdf": "7EF720E3-0A27-4724-8CFA-60FC78BF00ED",
    "shuntbot_viz.pdf": "A65A996C-DFFA-462E-85C0-005B9E832A63",
    "Shutran_Blunt vascular injury.pdf": "61409998-7BB7-44D8-B002-B0E61AD5B915",
    "Shutter_CNS Emerg Course 2024_Things to do.pdf": "F95DA9F7-761F-43E9-8A4D-68E6D2D306D7",
    "SHW_CV_112023.pdf": "9F52DE6B-51A0-49BA-B86B-8DE90CF60108",
    "SI Incidence Manuscript with tables.pdf": "1520FF85-3DFC-4207-999F-54D79DDF5021",
    "Siddiqi CV Updated 07082019.pdf": "0DFD5C49-2A68-4A7F-B2D6-B8655AEA5D9E",
    "Siddiqi gsl.pdf": "4933910C-FD15-43D8-A226-3E7212A1D29D",
    "Siddiqi Javed SNS Membership 10-20-20.docx.pdf": "DF95A16D-3B66-4DCC-8C70-26E62ED31B2C",
    "Siddiqi Javed SNS Membership LOR 4-16-19 SPINNER.pdf": "CD5127EF-C1ED-4975-98A9-D0359CD5487A",
    "Siddiqui for Frankel.pdf": "824B5FE8-D1AB-4B3B-B96B-50330F80BFD6",
    "Siddiqui Letter of Good Standing 2019.pdf": "EC24B1AD-D250-4BD4-AE32-4E731CC4DA22",
    "Sieg_2024 Emergencies Course_DOACs 2.pdf": "7C300644-18F9-4A16-BADA-556D33446708",
    "Sign on Letter to CMS MIPS Value Pathways FINAL.pdf": "C96CB8D9-A8D8-4F36-B5C2-3426FF2C95E1",
    "sign-on letter NSA IFR 2 (002) (002) (004).pdf": "DA3F2DA8-0D5C-44C3-B2BB-01BFF9E87E02",
    "Sign-on letter to CMS on MVP 04-10-20.pdf": "6C9CE1B2-96A4-42C7-AE63-0BC5EE9DEE1E",
    "Sign-on letter to CMS re Split or Shared Visits Final 03-29-22.pdf": "FEC4E334-5559-4640-9886-CB84EABFE29F",
    "sign-on-data-blocking-alexander-murray-10-21-15.pdf": "E9A0F6AC-13E7-450E-9EE8-9D1CD0802C5C",
    "sign-on-data-blocking-cassidy-whitehouse-10-21-15.pdf": "F010FDD8-173F-423D-960C-E8AD0462B4C0",
    "Sign-OnFieldEMSBill113thCongress111813.pdf": "3FCFE165-BD01-43DE-A920-AF785912179F",
    "Signal Localization Poster (1).pdf": "F3C48A16-3D30-44E1-860F-D3CA2D913F58",
    "Signature Experience Handout PDF.pdf": "D7E06828-97F3-44CC-A534-6501ED419BE9",
    "SIGNED BENTLEY LOS SNS.pdf": "4AEDB2DC-4FC5-469C-AAC1-0B8B10DFE772",
    "Signed CV Ahmed Elghity.pdf": "8CDA37B2-2C8C-4BEC-9860-00C50B65AD47",
    "Signed CV-1-merged.pdf": "2EFC3295-2DE5-4656-9241-E310403DEDAE",
    "Signed Letter of Support to CNS Leadership Institute for Sikorski -010422.pdf": "1A1A3CF1-E26D-42B3-8815-1205AA0B80B1",
    "Signed letter.pdf": "FB8A539D-CAA4-4540-9CC9-1BEC4C7266CE",
    "SIGNED LON JMarkert CJRozzelle SNS.pdf": "E0A4F9BE-12B3-4B84-AD23-1B694EF96E9D",
    "SIGNED MNHadley SNS LON CJRozzelle 2021.pdf": "D0156FF4-8F63-4C14-837A-32F54D695341",
    "signed_macra_doc_caucus_letter_final_10-6-16.pdf": "33154841-1BD6-4883-8AAB-77A1645DD56C",
    "Signing letter.pdf": "EECF6ED1-F937-4AFD-940B-0802B0526DBB",
    "SignOn 2020 MIPS Measure Removal Policy (008).pdf": "84EDBE76-E67A-43AE-BB20-D8657C25FCBF",
    "Sigounas_CV.pdf": "ED85B14B-4FFA-4C21-B176-E15CA55B69C6",
    "SIJ Fusion Manuscript_DR_2.21.21.pdf": "C7669DE5-FC23-46DF-A95A-56B08B37DE14",
    "SIJFusionDeviceTesting_CNSPosterFinal72023.pdf": "C64EB254-F70E-46BA-9016-D607F1BD2DB4",
    "SIJReview_CNN_Poster.pdf": "03557CDA-5214-4E3C-89CA-8F3173C7ABA3",
    "Silky Chotai-Fellowhsip.pdf": "2BD4F36B-DBF6-417A-B67B-4AD4FF045EFA",
    "Silva CVS Poster 1-28-19 PDF.pdf": "FF093058-FA46-48C0-A4CA-8D0A640DDB1D",
    "Silva_CNS_Letter.pdf": "74D805F1-F241-439D-88FE-6355153EFF81",
    "Silveira Neurosurgery Publications Personal Statement.pdf": "3E7FDE43-2377-43AF-9BF2-03D1D190DDD3",
    "Sima Mofakham LOR for Justin Passman.pdf": "F7F7C427-884B-4326-AC91-21D3EBAF271A",
    "Sima Mofakham LOR.pdf": "545B29B0-085D-48C5-BD48-98BE8173E92B",
    "Sima Sayyahmelli 6-4-2019.pdf": "F5BD5405-3C9E-499D-AC2E-A2AFF1D14810",
    "Simeone, Fred.pdf": "7F58AF20-D463-4169-9070-00167B37076F",
    "Simeone, Frederick.pdf": "39E91FF4-17BF-4568-97D2-AEDE83F5343E",
    "simfukwe specialist rc.pdf": "998A3B93-3A8B-49D0-847D-EE910EE7865E",
    "Simon MD Scott_SNS Nomination Ltr_10-24-23.pdf": "9BED8C3E-45FB-4749-AA98-00B01B0AF3CE",
    "Simon Mukuye_Curriculum Vitae.pdf": "77BB1C29-8AAB-43D9-AEB7-05DC2860C55E",
    "Simon Nerve.pdf": "FEE49B13-1A30-4445-AE0F-046513280954",
    "Simon_SNS_CockroftLON102523.pdf": "8CEF52BC-3DFF-416B-90D7-E99DED232516",
    "Simon_SNS_HarbaughLOS102423.pdf": "FD8D7FA7-4E05-4487-9701-B79FECC6EB2E",
    "Simranjeet CV 1.pdf": "5692F025-785A-4F41-AB0F-7B7D7A9EF3A7",
    "Sims_Jason_Enrollment verification letter 1.pdf": "2729AD46-936B-4B29-A6FB-7372CD68BD77",
    "Simulation-Based Educational Models.pdf": "0BB246B0-CA5B-4A23-B17F-8F5223E3B450",
    "Simulator Description for Faculty - Day 2 - JRC.pdf": "CAEEFC7C-603D-473A-B81F-61B7E7FFB985",
    "Simultaneous Treatment of Tumor and TN for Petroclival Meningioma CNS 2019.pdf": "03EFA6AD-962C-476C-8E2E-7513A81AC6EB",
    "Sin Anthony_09.08.2022 LOR from Dr. Jacques Morcos.pdf": "9195D623-7DF1-41F4-9466-056B95BA7667",
    "Sina-Ahmadi-CV.pdf": "FDD1EC52-D2C0-4E07-A9CA-79DA6D2B8DD1",
    "SINCH ATTESTATO SOCIO POMPILI.pdf": "9E3422FC-D8BB-4683-B56E-A1E6E0D699DA",
    "Singer Jorie good standing.pdf": "CB097D64-4993-48A2-A224-31E6F60E11AB",
    "Single Nostril Approach__Maala.pdf": "6859DF49-DC94-48DF-93A7-B45D4D589D67",
    "Single Vendor Study Poster - CNS (64x42).pdf": "B5D844ED-B683-41B9-800A-655765B93E17",
    "SingleUnitCNSPoster.pdf": "C5F351FE-4DDB-44C2-AA41-092D948870D2",
    "Sintesis Curricular Daniela Diaz Vasquez Mayo 2024.pdf": "B2938B09-1621-43CD-9834-9B9BEAC050EC",
    "Siri Sahib Khalsa-RESverif.pdf": "26DAEB49-0C2E-458C-9D0A-EF601E226094",
    "Siri Tumala.pdf": "E463F728-C822-4FE7-9D91-03BBD54D5D4F",
    "SIRI_KHALSA_CV_2023-08.pdf": "0B880573-AF68-4428-BEED-DDF46EAA6299",
    "Sisay CV.pdf": "7FD478CC-3314-4B1C-A4DF-4CD75D0172A3",
    "Sisroe Talia Letter of Good Standing10.pdf": "92450F94-4189-4AFC-851C-43B79A504BE2",
    "Sisterson - Letter of Good Standing.pdf": "39776120-29A7-4F2A-9D75-15F1BAA77BF2",
    "Sisti__Jonathan.pdf": "14CB583E-6957-4DA8-84E7-6DCAF0CF7123",
    "Siu CV.pdf": "58CD512F-00F9-41F2-A0CE-B679E7D0DDAF",
    "Siu,Jason.pdf": "116E0ACC-FEC2-41A9-8D50-D608BA6ADFEA",
    "SivaganesanA_CV_Aug2021 3.pdf": "F4830A84-3041-4B66-852D-6C24AF4626CF",
    "SIvankovic_LiabilityForm.pdf": "701CB632-A5A2-4DB1-958C-974C4D3055CA",
    "SJ Han CV Aug-2017.pdf": "9D4C1031-AF40-4809-8F25-FB5EB03EAF28",
    "SK Figures_Spine Summit_final.pdf": "94B493C0-81B8-4814-9C89-81887B4EAAF2",
    "SKashyap.emailverification.pdf": "DDD8E5D3-A75C-4ECD-9ADC-3D69764ED076",
    "SKaur_2023_Letter of Good Standing.pdf": "498378D6-1A1E-48F8-B833-8CB67CB53811",
    "SKhalsa.emailverification.pdf": "5E3A871E-BE2E-48C4-AA40-575EA295D92B",
    "SKM_C36820040210121.pdf": "0E68A5E1-A58D-4676-98EC-CE5332FE55D7",
    "SkonakondlaCV.pdf": "05562111-A5E6-4346-A99C-141576C47713",
    "SKSGRPRT16014100209300.pdf": "E370AB16-678D-4EC5-B8EE-5DC89ACA5DFB",
    "Skull Base Poster.pdf": "1B934131-4F85-4519-87E4-56F40F6D957B",
    "SLang.emailverification.pdf": "23AFC500-FEE3-4FA6-ACAB-177F05EB1229",
    "SLee.residency certificate.pdf": "A7F6A501-1351-4FE4-99EB-52C32B55D7D5",
    "Sleep Apnea ALIF CNS 2019 Poster.pdf": "D37E877F-7227-4FAE-A39E-B2F66CB3B6DE",
    "Sleep Apnea Spinal Deformity CNS 2019 Poster.pdf": "9AB7C11D-7EB1-473F-A056-66A8EA3F1495",
    "Sloan AE-LGG Fig 1.pdf": "FC70D678-3B78-4957-B545-0D983AF00D64",
    "Sloan AE_LGG_Fig 2.pdf": "72F6DC70-FA1C-4E45-831F-FEBF88A13C92",
    "SM_Good standing_2022.pdf": "FB406F94-724D-4FE0-8E96-88C47A46B262",
    "SMA - Information letter.pdf": "6C1EC9BA-B0F1-4D00-893F-0BBE225A1F04",
    "SMA Table 1.pdf": "15BFADEE-6588-4438-AA05-DFB8EC48790E",
    "SMA Table 2.pdf": "26FF8B45-FE4F-4933-9ED1-DA8E6C344337",
    "SMA Table 3.pdf": "C12AE93A-587E-47C9-8455-9FE9C082CBED",
    "SMagill_Biosketch_Final.pdf": "2738ABD7-8394-4AF2-8931-4822EE5E98CF",
    "Small Businesses Resources.pdf": "6B885C7A-5509-43E2-9EEA-8FC4F62C18DF",
    "Small Group Scenarios for Faculty - Day 1 - JRC.pdf": "D73ED25C-8646-4C03-AAA9-71C5D7CE79E0",
    "Small Independ Group Surprise Billing Congressional Letter 12.05.2019.pdf": "E01A40DE-DFFC-47D6-ACC4-B4EA620CB84C",
    "Small things are big copy.pdf": "448D3BBB-EBCF-45B0-824A-507692D93A87",
    "Small things are big.pdf": "6CB89BB2-22D7-42C0-BAAB-5B51B106BBB3",
    "SMB Federation Sign-on Letter (7-29-20 Final).pdf": "B138738D-E11F-473E-8D8D-201DC0FB8B65",
    "SMB Section by Section NW.pdf": "9CB9BB7A-5544-4717-BC7A-CE8A183A7B2E",
    "SMendenhall-RESVerif.pdf": "26164310-D31C-4B68-A4CC-9F738325F786",
    "Smit_R.pdf": "2C7A652D-E6F1-42AD-AB8A-1AF527A76E92",
    "Smith Alexander - Letter of Standing - 20210706.pdf": "DDAE780F-984F-41B2-86AE-3E669B2A3332",
    "Smith CV.pdf": "A869B344-F39E-4254-8FCB-827AA50FD85E",
    "Smith Emily - Letter of Standing - 20210817.pdf": "058EC6BB-1825-4CB5-91C7-EB1850BA6AD8",
    "Smith J - LoGS - CNS Application 9-14-2021.pdf": "5A612CDA-2BE5-45CE-8092-645A86792FAF",
    "Smith Letter.pdf": "F3D87662-34B7-4F18-98A3-92033B9EB214",
    "Smith William - Letter of Good Standing.pdf": "9B09270B-4CFF-413A-9647-B8FBB773E5A5",
    "Smith.IED.Figure1.C5.file195ied35.pdf": "34FF031E-847A-42EB-A1D3-E5981D3D72BD",
    "Smith.IED.Figure2.IEDdistributions.pdf": "F04FF06B-8DC5-4D5D-A5AD-D1990AC4C0FE",
    "Smith.IED.Figure4.AllDistsReIW.pdf": "0B73719A-3489-4B5E-A007-30965E2CA254",
    "Smith.IED.Figure5.subDistributionDynamics.pdf": "B2BB5111-58A8-42AC-94DE-79E903B9A94C",
    "Smith.IED.graphical Abstract.pdf": "4FB5FB43-1760-4F52-B64B-927885B64AE5",
    "SmithKitaraLetterofGoodStanding.pdf": "A2F6C360-E350-453C-B483-901B2185773A",
    "Smoking Fx and SCI.pdf": "A6A6D3CB-000C-4842-805D-5E4DDCC1EABC",
    "Smoking TBI.pdf": "7FF445C1-5B65-4B75-AD72-E2F8001FC0C0",
    "Smoking Tpa.pdf": "55C0B1E7-84C9-42FB-82D5-B9694E5ED2B2",
    "Smolar CV_May2023.pdf": "DDB9841D-DFC1-4DF2-9C34-F32EE519D758",
    "SMoosa.Residencyverification.pdf": "C68C7C66-2707-400E-98AC-063190634210",
    "SMorales-Valero-Verification email.pdf": "4D38C0C2-E30B-40E5-94A7-C442AEF20BF2",
    "Smyth_Matt_LOR_CNS Leadership Institute.pdf": "C8998B9A-9A07-4C8E-915D-AA621E541A26",
    "SNeerukonda CNS Enrollment Verification.pdf": "FF151177-D3B4-4B8D-A9BC-30CC2B3D3250",
    "SNI offer- Basamh.pdf": "0B548F63-D73E-47D0-8A7B-1405A83D5DC9",
    "SNIS_Covid.pdf": "2A994098-633E-43FA-9D09-F3A2A4420812",
    "SNS 2020 AM webpage 1.pdf": "04A7235E-698C-47E5-BB54-6D9562498CFC",
    "SNS 2020 Annual Meeting Program.pdf": "646F1B58-DF7E-4F93-99F4-22BA04EF1F86",
    "SNS Ad Hoc Newsletter Committee 8-20-21.pdf": "DD347498-30BB-45B0-906C-A44549C684FB",
    "SNS Constitution  Bylaws Final 5_23_22.pdf": "FA01D380-13F8-48B0-BE02-992FEB689874",
    "SNS Constitution Bylaws Proposed Changes Final.pdf": "4ED3338C-1B80-4B23-B907-97EBB1F27C2F",
    "SNS Curriculum Committee 11-18-21.pdf": "E8FF847F-598C-4AE8-AAA7-42E42A576B6C",
    "SNS Executive Council Members Policy Agreement.pdf": "5729D3C0-8D8E-4B88-90C6-BA48695F7E22",
    "SNS External Representatives Protocol copy.pdf": "1253F5C1-64FC-4BD0-BCA5-D218BEB28866",
    "SNS Geoff Colby.pdf": "F6A6B51A-391E-4147-9DBC-5C97917CA9B6",
    "SNS Letter - POURATIAN 12.12.22.pdf": "27E1831B-EC9E-4D7F-A42F-56BC1A5FBADF",
    "SNS letter for Eddie.pdf": "04D8773F-B68C-4E99-9DD1-4F7796879D67",
    "SNS Letter of Support for Dr Amy Lee from Dr Judy Huang.pdf": "DF87EE47-E978-4821-B3B0-4F4B4BAD59EA",
    "SNS LF Gonzalez.pdf": "25853B7D-ADE9-465B-941D-275768496BF8",
    "SNS LOR- Greg Murad - Stacey Wolfe.pdf": "1B75F9BB-EAE1-4245-8009-641DB2256735",
    "SNS LOS Okonkwo November 2020.pdf": "7A499F9E-11DB-44D4-B9D4-EEB577A3D1A9",
    "SNS Medical Student Education Committee Report 4.22.20.pdf": "F98CC834-5FA0-4A61-9869-FF1F7AF12C58",
    "SNS Medical Student Teaching Award.pdf": "E4F57140-D0BC-4AD7-9F76-D3F33B33904A",
    "SNS Meeting Agenda 2_22_2013 FINAL (2).pdf": "6EAE51E0-3387-4EBB-AC77-868C7A583B4E",
    "SNS Membership - Dean Chou.pdf": "FF75491A-BDE1-4818-9FB6-605508CF0BBB",
    "SNS Membership Chair and Committee 12.16.21.pdf": "1E581696-F949-4CFB-BC02-6A4B485BDFC5",
    "SNS Neurosurgeon Scientist Application - Hael Abdulrazeq 312023.pdf": "B99599FC-4D2B-44A1-889E-18C6302343E1",
    "SNS Newsletter Spring 2022.pdf": "B3B651B2-FC53-43C4-ADB7-37C4D7F8E36B",
    "SNS Newsletter Winter 2022.pdf": "CAC6BDC1-16AB-484B-A4A9-45D45BCB8AC4",
    "SNS Nominating Ltr Renee Reynolds.pdf": "0A4678A0-7B70-490B-99B5-1AA1163E2469",
    "SNS nomination - Dr. S. Blackburn.pdf": "1182C7C5-93CF-4DCF-A547-84CE868766D4",
    "SNS Nomination Sam Barnett_HvL.pdf": "C3F46219-65DE-43A4-AD0F-4C6109780D49",
    "SNS Nomination_Arnett Klugh_N.Selden.pdf": "1D17806F-7F76-469F-AEAC-E28CFB1EF665",
    "SNS nomination_Dr. Mojgan Hodaie-signed.pdf": "4D68BA1D-4D01-4E94-8DED-CC7EAC0EB431",
    "SNS P Konrad - Chambless letter.pdf": "79A48EC1-45E5-46CB-9A26-0393EF252C56",
    "SNS P Konrad - Neimat letter.pdf": "4B301801-F0EE-47FA-AA7A-CD82A85C9BE8",
    "SNS Policy on External Medical Student Rotations 2021-2022 v2.pdf": "AFD5BF01-5795-4AB9-A819-7DC40CAF9975",
    "SNS Policy on External Medical Student Rotations 2021-2022.pdf": "77AC0D6F-34E9-431D-8600-A036B096D9B3",
    "SNS Policy on External Medical Student Rotations during the COVID 19 pandemic (final v2).pdf": "992DA5A5-B2F3-4AD5-B6BB-268BC1FDC6B3",
    "SNS Policy Virtual Recruitment During the COVID 19 Pandemic.pdf": "EB6310CF-5116-4592-94A1-61A4454F8DC8",
    "SNS Preliminary Program_Final.pdf": "AE0A7AB4-1850-4C70-8BB9-4197A2E55675",
    "SNS Professionalism Policy Final 1-27-22.pdf": "3903EF0D-9283-440C-B65B-84D7FE5FA839",
    "SNS QIPS Committee 12-9-21.pdf": "42152314-C691-4DBB-B879-841C0CB54F8E",
    "SNS Raj Shrivastava 102123.pdf": "3730253C-139C-4B30-AC5E-6646EDC66422",
    "SNS Recruitment Policy 2021-2022- Final.pdf": "4B8F3A1F-430E-4A8D-BA0B-5F0C4F25D9FA",
    "SNS Research Committee SOP 12-4-21.pdf": "F909BFA3-AC1A-45AC-8D99-B47FDE1D1825",
    "SNS Scientific Program Committee Procedures DRAFT 4-20-12.pdf": "5D9CDE3B-ABD8-4EBC-8696-5DA6F6E5B403",
    "SNS Site Proposal 2026_Final.pdf": "E596FD56-9598-4CA6-BFB1-6BE795C64367",
    "SNS Social Media Committee 12-27-21.pdf": "2008D474-7CC6-4BFC-A1B1-F6043FA8292D",
    "SNS Sponsorship Letter_Shahlaie HARSH.pdf": "51DBBF56-7274-415D-88E9-C624BB2F64D8",
    "SNS Strategic Planning Procedure.pdf": "3330CBDD-EFE1-422F-91E3-2DC33042F0EE",
    "SNS T Soo -Kalkanis letter.pdf": "88D2077B-082F-4BEA-91E9-57CE454D8701",
    "SNS Webinar Agenda v3.pdf": "C94C0A7B-D264-4DAF-B7E2-D3D593C60BCD",
    "SNS Website SOP 8-16-21.pdf": "AAD2A3B5-C925-4AAA-9DA1-968817240B03",
    "SNS Winn Prize Nomination Instructions.pdf": "075A031A-0D37-4ADC-8E77-79B7C069CE75",
    "SNS Winter Newsletter 2023.pdf": "6AFD78AB-EB3F-483C-B7CB-25179C2789ED",
    "SNS_Bootcamp.pdf": "EB806DBC-CE6F-4D9E-95B4-CEEBCEADC536",
    "SNS_CohenGadol.pdf": "4FCC0971-2649-49E7-A21C-3C30125914F9",
    "SNS_Crone.pdf": "3DD57C56-3A04-48C9-9324-23F9E7EF2EEB",
    "SNS_Grady_Host Letter_ 011620.pdf": "84392C13-6583-490D-A2C5-34AA70C85E15",
    "SNS_LOR_Stippler_final.pdf": "44A9026A-1858-4732-95D3-3E256320AC89",
    "SNSA membership.pdf": "B4D89CAC-BF04-4EAE-8FD8-9C67E5CC4ED6",
    "SNSletter_riviere-cazaux.pdf": "1F7EE41C-8723-4268-955F-77E261ACB630",
    "SNSNominationChrisWinfree.pdf": "00514712-A3F3-478D-B8D4-5DFEA5033F60",
    "SNSNominationGuyMcKhann.pdf": "82549902-C186-411C-A11A-4E5BE63B9A21",
    "Snyder, Daniel _Verification - Enrollment in Goodstanding MD.pdf": "8E037AB6-93BB-4952-ABC4-57EDDE59C925",
    "SOCAL Shannon Entropy poster v1.pdf": "9377AB12-334D-471F-B8E9-721279D5895B",
    "Social determinants of health and the prediction of 90-day mortality among brain tumor patients.pdf": "BF8269A1-B42D-4833-B8CC-0274B7DEFCF7",
    "social disparities poster.pdf": "00163759-7F4B-44CC-8966-8176EC97DEAC",
    "Social Media & Other Resources.pdf": "1CE152D1-9294-4FA6-BFFA-CEB62253E9D5",
    "Social Media.pdf": "A11AA1E3-BCCE-406C-91A5-61BFBBFE75F0",
    "social_deprivation.pdf": "EE4D6689-0B59-4BB4-84E7-34A91E3C32A2",
    "Sociedad de Neurocirugia.PDF": "9B008C1F-E514-402A-A38F-C785430009AF",
    "sociedad.pdf": "1743B40E-81D8-415B-998C-1E322C2C0623",
    "Society - Ferraresi.pdf": "7281D796-67C9-44A9-9B2E-3923A2172812",
    "Society - FRATI.pdf": "50481CC8-E040-4AFB-81C9-AF804CED8554",
    "society Certificate.pdf": "94EB975A-73B5-4CB7-9CC6-C248CDCC1E50",
    "society_talks_2017_fda_aneurysm_panel.pdf": "27C2FF76-FF37-44B7-B1FA-52EA9A8CEEA2",
    "Socioeconomic UCS_AE_Final.pdf": "44334930-B9FF-4314-8B6B-13697B48B5C3",
    "Socioeconomics_Robertson.pdf": "70A2677A-79F6-4E83-A301-AA974E033753",
    "Socity - SALVATI.pdf": "095D8576-1125-4386-8EFE-ACC9777846AA",
    "Sofia_letterFECHA.pdf": "EAB761AB-0CFE-4638-BADC-8D88FFB560E8",
    "Sohum Desai Letter of Standing.pdf": "B4335B8A-1AA9-4A7D-9A37-FDABEA238766",
    "Sokol 2022 CNS poster.pdf": "894687D8-3EA3-4C43-85CC-C56490B60443",
    "SokolCNS2023.pdf": "079E3C50-46FA-4D2D-86FF-E290A0F3039A",
    "Soliman CV.pdf": "15BB545B-4D54-462A-977D-292A0205E98C",
    "Soliman Mohamed LOA.PDF": "1534E348-370A-45BE-A814-37EA98C8B1B9",
    "Soliman Oushy-Fellowship.pdf": "A8FDCCF2-7AFA-44AF-AB9A-6384A5D12ED4",
    "Soliman_Letter_of_Support.pdf": "3B0ECCB7-017F-4F36-B2C5-F064C23FFD85",
    "Solitaire stent-retrievers-Poster.08-31.pdf": "915896E2-E4CC-4AFB-8C0C-6C3B42178089",
    "Solomon, Robert.pdf": "1DCE7325-A997-402C-95B1-B59BE7D5FA84",
    "Solou_Mary_CV_2024.pdf": "03957535-F9B8-40EB-9CB0-413ABECBBDD1",
    "SOM-CV-Raslan_10_26_2023.pdf": "F5DC415B-5470-4289-AF8F-A5C2B754E987",
    "SOM.pdf": "A86D5913-D99B-478F-982E-1B20BADC4FAC",
    "Somnath Das Letter of Good Standing.pdf": "C5D9A4D7-8B78-4F1A-BB48-9DB2C64FA8BC",
    "Song, Seo Ho - Letter of Good Standing.pdf": "E32F5A76-F3A4-4175-89E7-0982D1124587",
    "Soni Pranay CV.pdf": "DEC96FF9-F14B-492E-94A4-425CAFD99726",
    "Sonia Pulido 5.25.pdf": "65A76F89-3185-4CB9-A42B-25E3D7FA2090",
    "Sonntag, Volker K H.pdf": "1B2B1884-D4C7-4193-B055-CD3627A473CF",
    "Sonntag, Volker.pdf": "B6459525-663C-4B04-97A1-35D3CEA7FD3D",
    "Sonolucent.pptx.pdf": "C7B036A9-72DE-47ED-993D-9FBABB36E086",
    "Soo SNS letter Schulder 8-22.pdf": "E494E74C-8E77-4E82-A277-AC1E6316A633",
    "Soo_TeckMun_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "5D1C5EA8-000B-4FBF-B0EC-DC98E9C4490F",
    "Soo_TeckMun_2024PositionalMembershipApplication_CVand Biography.pdf": "DD78B602-C190-4799-BDE0-0A407F85C960",
    "Sophie Peeters Fellowship.pdf": "AB467A51-0AD9-4CAF-BAA0-0457FDC2B617",
    "Sophie Peeters.pdf": "3F29AAE1-8BD1-49E6-93AB-14A360B46FCF",
    "Sophie Su CV 2023.pdf": "54C3759F-7111-403D-80FF-4E4BDFE2C828",
    "SOSPINE CNS 2022 poster v1 final.pdf": "CBBDEBA2-CA35-4F3D-97E4-B310FE481C1A",
    "Soto Rubio.pdf": "ED7CCBF7-5638-47C0-9D60-1111A26B0316",
    "Soufi Gd Stndng Lttr 1.pdf": "E4D21304-110A-4B57-816F-E93E2C822254",
    "Souweidane's reference leter.pdf": "32F45ABB-0D62-4026-8FAB-232B8C2650FA",
    "Sowah.pdf": "27A79B88-23F2-4C8B-AA50-04F65E412096",
    "SP 2007 Fall Membership Report.pdf": "4429A9ED-09DA-4E96-BA55-6B9567973BB8",
    "SP Financial Statements 6.30.17.pdf": "FA301DF2-8C42-42BC-A20E-3F2B9FAD0DEB",
    "SP Semi.Annual Membership Reports 3.07.pdf": "881F5083-EA89-47B6-8516-0DA4CEC39EEE",
    "Sp09p200011082210130.pdf": "4D9558AA-03EE-4FD2-8B30-82C4DF2E4D45",
    "Specialist Certificate_Daniel Umansky.pdf": "F8216F20-41DD-42FF-AC49-634505D16A11",
    "specialist license E.pdf": "7595A0F3-1EF6-4B56-911E-9D0F54222C57",
    "Speciality Certificate SBN.pdf": "9F5A6F59-86BD-4B90-AAD0-2C3497200327",
    "Specialty Society EM Letter to CMS CY 2021 PFS Final.pdf": "1421974F-786B-4D91-9374-8D966310A6A9",
    "Specialty Society EM Letter to CMS CY 2022 PFS Final.pdf": "D9D5F825-E53F-4F43-AF6F-F33C83BF526E",
    "specialty_letter_to_waysmeansedlabor_-_09.04.2019.pdf": "61DB1263-3F24-45BB-A360-2C0B3106B3F5",
    "specialty_society_letter_to_fda_regarding_inthrathecal_drugs_121318.pdf": "5875F0CE-773C-4513-99A9-280F74CCA642",
    "Specificty Table.pdf": "5C847D36-488F-4028-A1AE-1782E2538F2D",
    "Speech_severity_2021.pdf": "023FD6A1-2E63-4DC0-991B-6D4C469F9DC7",
    "Sperber Letter Of Good Standing.pdf": "9A038141-1533-44C0-AD72-9FA3BF494FF7",
    "Spesialistdiplom.pdf": "EDE8B310-6032-4826-8CC0-6E4927B2B09E",
    "Spetzler, Robert F.pdf": "A98E6DB4-6C24-45B6-86F1-04DE88498F6B",
    "Spetzler, Robert.PDF": "E0750FD1-6CAE-42CE-8FD4-52722D4F6E05",
    "Spinal cord QMRI comparison.pdf": "A7C6BA85-D66B-4EFD-B1D3-9E08899CC588",
    "Spinal Lilies.pdf": "3A79FF9D-419B-4A94-BBD6-955BC1CD8541",
    "Spinal Triage Pathway.pdf": "5CDCEC21-2051-4771-9AAD-D595F014A813",
    "Spinal_Cord_Injury_Without_Radiographic.23.pdf": "5DE41D29-106C-4B81-B7A3-98194A7DB70B",
    "SpinalMeningiomas_CNS_2023.pdf": "C30180F5-45F0-4898-BFA8-523D073A7EBE",
    "Spinazzi_PGY7_CV.pdf": "4804C131-4A6B-4640-83E2-479776AD1516",
    "Spine fellowship PD letter.pdf": "2E891610-CDB4-48DA-9EA2-12E903493552",
    "Spine Indications Conference Tables.pdf": "103C087B-9F37-4EE0-BB32-2DA6FB7E3ED1",
    "Spine Prelim Update 1_20_20.pdf": "3A7AEE2F-52E0-47E3-ABDE-C99153D3B4C5",
    "Spine Prelim.pdf": "B689CD5D-AAB1-4563-9B7F-DD5C79CDF154",
    "Spine Ropper Fridley.pdf": "6B67D058-FE6D-4632-B521-3F1FB710767A",
    "Spine Section EC agenda Fall 2011_2011-10-25.pdf": "FD12D2DF-A367-42EA-9B2A-218522F63A36",
    "Spine Section Tables.pdf": "E8FCBC31-EA43-41D4-B3CE-79CFE7B6AA9B",
    "spine section.pdf": "07D567D7-3A88-438E-8BF4-3835BF4F8C6D",
    "Spine Small Group Cases - Bydon.pdf": "37A34928-BB64-472A-BF89-2CFA4261BCF2",
    "Spine Small Group Cases - Ropper.pdf": "E31387C5-DF01-4B30-B4FB-6B3AE8F82D41",
    "Spine Societies Letter to CMS re Spine Add-on Codes 042324.pdf": "5AB7F8A5-8ABC-4977-90F1-A39A03497EE1",
    "Spine societies letter.pdf": "5EE49550-43E0-4D9F-960C-22F52846B8FC",
    "Spine Society Degenerative Spine MVP Comments 030121.pdf": "6AC162CB-0A92-467B-B52D-4DC4B6C02BB3",
    "spine submission-converted.pdf": "A636B2FB-32BA-4C65-BA39-E9BA0DAB6425",
    "Spine Summit - Healthgrades.pdf": "0D8E9928-8942-4CAF-BAB7-D692CBD3106C",
    "Spine Summit 19_PocketGuide.pdf": "5C13DFFD-9643-4350-978F-FAD96975455D",
    "Spine Surgeons Position Statement on Arthrodesis of the Spine FINAL 101421 (002).pdf": "307A0BA2-96D3-4F43-832B-D17E3F87864F",
    "Spine Surgeons Position Statement on Arthrodesis of the Spine FINAL 101421.pdf": "A2DB2F54-897F-4508-A798-1C2C38398392",
    "Spine Surgeons Position Statement on Image Requirement for Prior Authrorization FINAL 060722.pdf": "0CBAED28-205D-4342-B80D-107DE9F8141D",
    "Spine Track Thank You 2024 Course Supporters _ Flyer for Courses Landing Page _ 2024 CNS Complications Course.pdf": "FD2A0756-E144-4456-9C9C-7424702E8718",
    "spine-tuning-va.pdf": "C6A4BD58-C2A7-421B-8192-45FD0C3E8155",
    "Spine_Naik.pdf": "CBF801BE-A6E5-4C26-8C80-146C25BA51BA",
    "Spine_Section_2020_Figures.pdf": "4433A3D8-7ECE-46FC-81AD-14FDC02713D1",
    "spine_section_wsans_letter_to_washington_state_hca_hta_icer_draft_ta_report_091715_on_letterhead.pdf": "C08F3AED-B17D-4D8B-808C-2BBAE18A2D53",
    "spine_societies_letter_re_wa_hca_hta_draft_report_for_radiculopathy_032118_1.pdf": "830E21DC-CB7E-4CE0-A0C2-066DF97BDC6C",
    "Spine2022Brochure.pdf": "AB122D8C-A82D-42B9-979B-9787EDD7B4FE",
    "Spinelli Casey Letter of Good Standing.pdf": "22E607AC-2B55-4EF5-926C-9721E750613B",
    "SpineMets Surgery_AE_FV.pdf": "AB428CFA-9C71-4860-88D7-886CE9215FF1",
    "SpineMets_Readmissions_CNS_ID_029138.pdf": "9FDC82B8-8FA6-46EA-984B-D84567C5B7BD",
    "SpineSummit2020_Table.pdf": "582CFE83-93FD-4F99-95B6-216636CA6C80",
    "SpineSymposium2021_STD.pdf": "E9F2498D-1158-4A46-BCA4-82371FE25349",
    "Spinner Slides.pdf": "E7ABF703-E8F0-4006-BFCF-05BABBC6CE35",
    "Spinner SNS Sponsor Ltr 8-27-22.pdf": "8C206FFE-F0C7-47C5-B3BF-984923BE2227",
    "Spinner, Robert J.pdf": "5AA7C869-5BC3-4827-8A01-23084265FFDB",
    "Spinner, Robert.pdf": "57866735-379D-4F9A-A521-2147065DAA9B",
    "Spinner_LOR_Pubs.pdf": "0646EB15-911B-4DCE-8AA3-895FB1645CD8",
    "SpiNS Scoring System.pdf": "77DDD62D-5EDC-41EA-BB81-5AD00C8004F8",
    "SpirosBlackburn.SNS.Nomination- Dr. Zipfel.pdf": "0744B5A3-4805-42CF-8C4B-DF377A3B3F0D",
    "SPNC statment.pdf": "69194A8E-6148-460F-8165-EF23156F779D",
    "Spondy_DischargeDisposition_CNS.pdf": "DC493D0A-2F10-478E-81A4-718FBDB2C832",
    "Spondy_Frailty_AE_Final.pdf": "E8F1C2AF-4E20-4DD5-BA14-17244F238E66",
    "Sponser-signed.pdf": "962CB1F5-8CD0-472E-8DF8-9A3FCC50BA29",
    "Sponsor Letter - Dr. Reiss.pdf": "E0D42873-B372-4CB0-BF43-E53F257233E4",
    "Sponsor Letter from SNS Member_Mocco_Shrivastava.pdf": "A0A4DB40-AF59-460D-B9F7-1F07D3FD3424",
    "Sponsor Letter from SNS Member_Muraszko_Heth.pdf": "ECBA2CD5-A2AB-4445-9446-978A7E87A4E1",
    "Sponsor Letter from SNS Member_Muraszko_Pandey.pdf": "B5F3A3AD-D5E9-4553-81AA-7DABA91077BE",
    "Sponsor Letter from SNS Member_Timmons_Stippler.pdf": "6533E554-0DDD-4EC7-88EE-CBB9FC68BCCA",
    "Sponsor Letter from SNS Member_Tranmer_Liebelt.pdf": "A950137E-8BA3-4FF4-BF0D-40D1154700D5",
    "Sponsors Letter_Dr. Hodaie-signed.pdf": "F9CC68C8-A3EF-49A8-A9B1-8ABD00777A51",
    "Sponsors Letter_Dr. Kongkham-signed.pdf": "42563E7D-DB55-4ADD-8DF8-2659E12BBFB8",
    "Sponsors Letter_Prof. Ibrahim-signed.pdf": "96298D97-5469-4D61-BBD8-6730F838622B",
    "Sponsorship letter for Amir Dehdashti MD - Jacques Morcos.pdf": "3B7C5331-BE2A-4043-9806-7EA0A7757676",
    "sports TBI_CNS 2023 poster.pdf": "9D214250-9489-4860-8D8B-545AB042E559",
    "sports_med_coalition_letter_for_senate_help.pdf": "9040E589-D963-489D-9DEC-E2CD08DC976A",
    "Spring 2020 Newsletter-Final.pdf": "03056C33-35FE-4E86-B891-C550A766C99A",
    "Spring Oral Board Agenda 12072023.pdf": "664E837D-2E10-4447-8F97-EAFBB05C3CEA",
    "Spring Oral Board Course Agenda 2-23.pdf": "2B7A1676-3291-4259-9123-1ECD5B98ADDA",
    "Spring Oral Boards Agenda 02272023.pdf": "95AAC407-7CAD-443B-8E9E-6A76176A10E4",
    "Spring Oral Boards Agenda 1-27.pdf": "BA413DDD-694B-4312-976B-F9DF5862178F",
    "Spring Oral Boards Agenda 2-12.pdf": "5B3CBC7E-0D9E-4A08-ADCF-7FF705EC48F4",
    "Spring Oral Boards Agenda_.pdf": "B00BCAF0-35DE-4EE6-A22F-7C55810937BE",
    "Spring Oral Boards Draft Agenda.pdf": "B5854DC0-36F6-42C3-8303-C12E99CE3E5D",
    "Spring09.pdf": "B0004C5B-160B-40A6-8BDA-EF6F00EA41ED",
    "SpringOralBoardsFinalAgenda_02072022.pdf": "53BC7726-4D09-486E-8BA3-29EAA7B78B50",
    "SPugazenthi 7-15-21[44].pdf": "0C5E02A5-79C6-46B4-B552-69E356693E19",
    "Spyridon Karadimas-FellowVErif.pdf": "8D92D428-B2B7-482C-96F0-15432C2D319D",
    "SRai.emailverification.pdf": "CCC1FA4E-A95D-4CBA-8FAE-C4D18709B3DB",
    "Sravanthi Koduri CV.pdf": "060D261C-ED06-4385-A971-8B3B5B2EB9BA",
    "Sravanthi Koduri-Fellowship.pdf": "14824DCB-3B6C-43AB-97FB-E20CD6D7EF84",
    "Srinivasan_ms 612-20_2.04.21_FigsRev.pdf": "8B85F532-30C6-4EF9-AADB-D516768216D8",
    "SRO Figure 1 CNS 2020.pdf": "3B2539C5-128F-4F31-91F7-6FC22F4821DE",
    "SRO Figure 2 CNS 2020.pdf": "5C4AF86C-D5F7-4369-A21F-5CBDD81D7D5A",
    "SRS for Greater than 25 Mets CNS 2019.pdf": "16C0F607-ED32-4912-A8F5-9D8B0C0C3AF9",
    "SRS Seizures_updated.pdf": "EB217A6B-611B-4309-8A4A-E1575549D1DD",
    "SRSforTNtumor_CNS22.pdf": "AB6FE3DB-4AE3-4206-9122-51AD7CFB9B52",
    "SRSGrassrootsAlert.pdf": "A81213F9-3DAF-4A01-9BBA-23866048AA8F",
    "Srujana Vedicherla CV. .pdf": "728D521B-4DA8-49F1-827E-2DC28B890ED2",
    "sruthii-peds section meeting 2022.pdf": "F4FABA9A-0989-40E2-A32C-FCF8A2EE97B1",
    "ss2.pdf": "AC0E9444-C30A-4BF5-8443-AEF97B0DBB72",
    "SSA Provider LoE.pdf": "0F3F126A-8CB7-48C1-882A-05F6DA945B5F",
    "SSC Recommendation Letter - Year 3 - Sandhiya Shweta 1.pdf": "E49E8D91-3DA0-4B12-87AB-EAFB555B88FE",
    "SSD CV.pdf": "6A61E63C-82C9-4F9D-AC6E-BCEC4485EBE0",
    "SSEH.pdf": "832A456F-773C-4199-91B8-B7522682CE0B",
    "SShabani.Enrollment verification.pdf": "17DA5F7D-85B1-4FE3-AA59-A7DFA8B004B3",
    "SSOM_Official_Letter_of_Acceptance.pdf": "F24FCFF8-9CD5-4DC2-861C-2207712D9EA6",
    "SSR_TSRPT - Nataleen Albekairy.pdf": "CC52D101-F857-4B13-8B9E-A7F892E18AC2",
    "SSR_TSRPT 1.pdf": "091C61E2-D255-4D9A-A190-931FF383E764",
    "SSR_TSRPT.pdf": "C651670A-187C-4D67-A347-28D69AA240AA",
    "Sstudent su14032612180.pdf": "5D3F3DCA-E634-49E0-A479-B43FF358D279",
    "SSu_Verificationemail.pdf": "4FD20DAB-A332-40BD-8A00-01D27B1F0822",
    "SSWhiteHouseLetterFINAL.pdf": "4EA58439-F6D8-4DCC-9A82-6E3B791C37BB",
    "SSyed.verificationemail.pdf": "B40C4860-AE38-4C19-9298-B95157F74193",
    "ST Follow-up sign on letter_FINAL.pdf": "82C9A01E-4EC5-4B12-9E16-8F5BD3DE1FB7",
    "St Morris orders.pdf": "BA54E69F-865B-4FA3-8A3C-10EDEB109EAA",
    "Stabilty Scan Figure.pdf": "BB06FB71-463F-4752-9B85-F3A65913CC56",
    "Staff Business Travel-2023.pdf": "BD24F722-44FA-4C5B-9A42-5E0C95276F40",
    "Staff Directory 5-2024.pdf": "C6CE8EA5-2DD0-4518-8CA2-59CFE207306E",
    "Stafstrom Enrollment Verification.pdf": "2A2D1670-3683-4050-95A0-E7F094BDA3A9",
    "Stakeholder-Congressional-Letter_Testing-Fund-July-21-202031.pdf": "CCA0FBC6-23AE-4DDA-A2C5-C1C96AE69E5A",
    "stakeholder_letter_to_cms_and_fda_re_x12_udi_recommendation.pdf": "7D6905DA-7C4D-45EE-9EAB-2B12930DFE45",
    "stakeholder_letter_urging_repeal_of_device_tax_-_dec_2018.pdf": "69CB4CEF-F9AC-424D-9D0F-2AFBCA7EFE8A",
    "StakeholdersLettertoHHSreMUin 2014_FINAL.pdf": "C2D96F23-E90A-4342-B1F1-6E1C650DAA91",
    "Stallard Good Standing.pdf": "8826A681-F62F-42F6-BD99-9D9D415FA539",
    "StandardCV 8-21.pdf": "44C2AED9-766B-427E-8D82-1E0F653BF303",
    "StandardCV MoBydon 11252019.pdf": "36AF4B41-C3FC-434E-933A-E09CF371C5F3",
    "StandardCV.pdf": "5465F04D-CF1F-4EDF-8F61-9089DFC8EA11",
    "Standing Wilson, Thomas.pdf": "B5427887-3AB8-43E4-B450-650C47B0AF8C",
    "standing.pdf": "3CEC8AA0-8242-4D67-976A-97E555340579",
    "Stanford Resident Certificate.pdf": "152768CE-44BB-44EA-8604-C9E2201E5F1C",
    "Stanford University.pdf": "09092521-A2B7-4AA8-BE7D-5FD27085F8E6",
    "Starr CV updated dec 2023.pdf": "FEF85035-E16F-4225-8A26-8E4E2F485922",
    "Starr, Philip.pdf": "FB3F5AE8-061B-4564-B765-249F96060086",
    "Starr_Winn2024.pdf": "73C638AE-A16F-4D22-94F2-080923108C15",
    "start time pt saftey.pdf": "0A0F543B-8E05-4189-AA01-8FA28F54AFCB",
    "State Resources.pdf": "FF589930-2E83-4292-8C72-C92455AED597",
    "State-SpecialtyMedicalSocietyCoalitionStatement7-28-09.pdf": "D0AD921C-9151-4DAA-BCB4-D326EAF64CB2",
    "State-SpecialtySocietyFinanceSGRLetter092013.pdf": "3C8A48B2-D98D-4762-8DD4-3C9B2F3F52D7",
    "State-SpecialtySocietyWaysandMeansSGRLetter091713.pdf": "A28A1222-B58A-4B11-8241-777CE35836AA",
    "StateAndSpecialtyStmt_MedicarePhysicianPaymentCrisis_6-10.pdf": "E8FD2FC4-8267-4F71-B6EA-B698428173CB",
    "StateAndSpecialtyStmt_MedicarePhysicianPaymentCrisis_6-10_0.pdf": "4533807D-3923-4F2E-B2BD-861FB6F07B6E",
    "Statement of Attachment for Dr Teo Kok Ann Colin 3 Jul 23 - 2 Jan 24.pdf": "7CEADD63-14B2-430C-9B48-CA74009863A6",
    "Statement of Service - Middleton.pdf": "AFC01F9C-BEEF-4BFE-915F-D9A3F1A18890",
    "statement.pdf": "08CF48C0-4B06-4E00-8048-EA50D8600246",
    "StateSpecialtyCoalitionAnnoucement31010FINAL.pdf": "D5D796D7-FE19-4440-870A-DCE2133F470A",
    "Status letter.pdf": "8E290C01-F1A8-4048-9239-AB60D1E66B1E",
    "Status of Current Medicolegal Reform in the United States_CNS2021.pdf": "D439CDEB-B28A-4106-AC25-D104ED4D666E",
    "Staudinger Knoll Verification.pdf": "4E52FF74-D01F-4EBA-BCEC-D1AD1096DC05",
    "Staudt Verification.pdf": "9C30D043-FFAC-4047-8F3A-8C9D8D4CB234",
    "Steed Kerry_letter good standing.pdf": "C366A1AA-94BE-4D6E-9D81-0D1799D2A38C",
    "Steed Tyler CNS CNS Scholarship LOR - Barrow 3.4.2022.pdf": "13FA53A4-0B83-43C1-81EE-9E4EDB35211C",
    "Stefan Koester CV 4-2022.pdf": "59F34810-A60F-46F8-A946-8BBAF4858C83",
    "Stefano Priola.pdf": "7F614892-30D4-49BB-98C0-8AE3E37E4658",
    "Stein, Bennett M.PDF": "E32CC4F0-347F-45CD-853D-FB003CD1E883",
    "Stein, Bennett.PDF": "B800EA4F-32E1-414B-A056-76CC4D4CAA2F",
    "Steineke CV 2021.2.docx.pdf": "0187907F-9FC0-4F09-A333-E1A716609EEB",
    "Stem Cell Hydrogel Matrix CNS 2021.pdf": "0FEE7FE3-81E5-4306-A649-3339217E3F59",
    "Stem cell research - edited.pdf": "EFE4143F-36FE-466E-9E95-69ECD0DAC842",
    "Stenerson UWSMPH Enrollment Verification.pdf": "E3CEC5FD-6CFD-47A3-9D5C-1F85E9C24E48",
    "StenLetter of Certification.pdf": "964C0983-DF1A-4CA9-A426-9391E7611FFE",
    "Step Therapy Coalition - Comments to CMS - 6.1.22 Letter.pdf": "BA7A6CB1-F9CB-4514-B3D3-20B99CC41CE8",
    "Step Therapy Coalition - MA Rules Comment - Submitted 2.13.23.pdf": "790B5B6B-8E26-464A-AB91-43143FAA36E3",
    "step_therapy_sign-on_letter.pdf": "0798CC9E-B1FB-434E-91C7-7A05D3709DC0",
    "Stepan Capek-FellowVerif.pdf": "36932ABD-8B21-4577-9F61-BA73D8D4D578",
    "Stephan Bergin LOR.pdf": "D8C5840E-4B5D-4423-B1B6-9564C414973D",
    "Stephanie Moawad CV.docx (9).pdf": "223BC9E2-32F7-4EB5-8385-423240F7EFAD",
    "Stephano Chang-Fellow.pdf": "CA4D4105-D75B-4731-9C39-D373E1F1C6E2",
    "Stephen Bowden-Fellowship.pdf": "7199D72A-AAA6-4CF3-BC3B-15BA306C712F",
    "Stephen Burks-RESverif.pdf": "C9A61D90-6D81-4F11-B127-CB8E02D13C08",
    "Stephen Fielding -  Enrollment Verification Letter.pdf": "1F792064-4C4D-44EE-9580-1DF9F52DBD52",
    "Stephen M Bergin - CV Duke University.pdf": "5BFE429D-AD9F-4F6C-B660-87C785144F5D",
    "Stephen Monteith.pdf": "210F772A-F535-42B2-865F-A72EEB84624C",
    "Stephen Shelby Burks CV.pdf": "B2EED603-61F4-4ECC-B2CE-BA62774794E3",
    "Stepp_2019 CV.pdf": "8C1301D6-5EAF-41DE-AF0D-158008B4A6B2",
    "Steps to CPT Code Audit.pdf": "D46040E4-9508-4DFB-992A-3EB571E8B205",
    "Stereotactic ablation via laser interstitial thermal therapy (LITT) or radiofrequency thermocoagulation (RFTC) for insular epilepsy – a systematic review.pdf": "1DC8E54D-D667-4E34-982A-AF7BD74E61A5",
    "Stereotactic Radiosurgery and Highly Active Antiretroviral Therapy for HIV-Related Pri-mary Central Nervous System Lymphomas Analysis of Efficacy and Safety. (7).pdf": "6A23FABE-8327-441C-8646-622FA1EE8C31",
    "Stereotactic_Godinho.pdf": "9D583853-E330-4FA5-8DE1-2E36676BA997",
    "SternMatthewA_ASSFN2020Poster_Final_Compressed.pdf": "5E39ADCA-1B84-43EC-8C28-DAF8DF7FD4F9",
    "Stetler CNSA CV.pdf": "C5772459-7493-4B5C-A545-7B29506E6ABB",
    "Steven Dillavou Resume January 2021.pdf": "51532614-6DBE-49A8-9D64-BF91E9C91350",
    "Steven Roth-FellowVerif.pdf": "67D94410-8437-429C-BB9B-A88D38E1A071",
    "Steven Wakeman - verification.pdf": "47C0D25B-125F-4F88-88A4-B26A99F3675C",
    "STF_SSRTSRPT.pdf": "3CD19A24-C5A7-4E39-A4AF-7DC2FB0A4F2F",
    "Stiep Apathy INTREPID ASSFN 2022 eposter.pdf": "7AEB923B-3577-49E6-AE5A-AC453375F4E2",
    "Stippler LOR from Zipfel.pdf": "D3C61F64-8E95-4CA8-885B-EFEC74A6F580",
    "Stippler Martina HMS CV .pdf": "A40F77BD-7975-4503-8AC7-A4EAE5307E22",
    "Stippler Martina HMS CV 2020 February.pdf": "DE2B5A35-0B27-45CC-BD41-2EF13C7BA204",
    "Stippler Martina HMS CV 2020 sep.pdf": "AC7E7FE8-839E-4A66-906C-472F0862FBDF",
    "Stippler Martina HMS CV Mar 2021.pdf": "FF665A1A-ACBD-43D7-9575-9FE9CB00F398",
    "Stippler SNS Nomination.pdf": "0A8EE41E-491D-4C86-ADDD-76CB36FFE907",
    "Stippler_VTE Prophylaxis- Do Lovenox or Not.pdf": "956CA581-A3FC-4621-8DAF-70D469A4B70C",
    "STN ASSFN poster.pdf": "F1E40505-1381-4121-8514-4FB2FC29328A",
    "Stockman gsl.pdf": "CAAC6EE8-6351-48BB-B347-52DEE8861F28",
    "Stockwell CV PT 2023_11.pdf": "9FD842A8-29F3-41AC-A708-AB33F198A4C6",
    "Stockwell LOR CNS 11 14 23.pdf": "3DECAC9D-0F68-4FAF-A996-C916FCD2919E",
    "Stogniy_Good Standing Letter.pdf": "8325FD63-38DD-415C-998B-E3320FD3D77C",
    "Stopa_VTC Unofficial Academic Transcipt_082021.pdf": "C21DE8F2-C31A-46FB-A909-8B01DF81ED72",
    "Story, Jim.pdf": "B65F49A4-D868-492A-AE52-1EAE2B490CFE",
    "STR.0000000000000015.pdf": "D7CE9CF2-3B66-40B2-812E-38338848BA18",
    "STR.0000000000000016.pdf": "767E69D0-2278-4494-8F24-A5E7D99E6C6F",
    "STR.0000000000000046.pdf": "BBB92AF2-C9B5-40CA-9BC7-0758C6AEC682",
    "STR.0000000000000069.pdf": "D27D07CD-F1E3-4B6B-8240-24F35EF2DBB0",
    "STR.0000000000000070.pdf": "1E774DC6-BA95-4A48-B5E3-7DEC5BE2C2AC",
    "STR.0000000000000074.pdf": "16F3AB10-ED3E-4BD5-9652-BC6370331A82",
    "STR.0b013e31820a8364.pdf": "CC4B1DC2-409C-431A-B5A9-F28C96382DAE",
    "STR.0b013e3182587839.pdf": "E40031B6-3386-4DC3-9C0B-75EC87BC8D66",
    "STR_OS.pdf": "AC0B8A9F-F3E5-4671-A27C-C58CF8F00656",
    "Straus--CV 9.13.16.pdf": "8B143BA2-96BA-4BEC-986F-3BB605ED56EE",
    "Strelko_CV_13Apr2023.pdf": "785C581A-746A-4108-93C1-656C24401FB0",
    "Strelko_CV_13Apr23.pdf": "A7289675-8D81-4F30-A572-4DBADAC95226",
    "Strelko_fellowship_budget_for_application.pdf": "707011B2-D276-43F1-A3CF-CB76A0A19CA9",
    "Strickland - Publication fellowship LOR support.pdf": "96ACAB8A-BA94-49CF-A80F-B6E96ECAAA0C",
    "stroke #1.pdf": "FF311347-B470-49F6-A951-1DBFE76CA9A3",
    "stroke #2.pdf": "703D57DF-84F0-4FDC-92CC-CF2E4A579DF9",
    "Stroke #3.pdf": "36EA422C-5D66-4279-B536-B656B6EBB303",
    "Stroke #4.pdf": "0FCC3236-5FB2-40AF-AF76-92E777E76513",
    "Stroke AI_CNS Poster.pdf": "D5C5939F-EDF9-4D3C-AA75-44E867820428",
    "Stroke in ONC CV Section 2019.pdf": "E9FAE0EF-A098-4EEC-A5D8-FF8D21647D6F",
    "Stroke-2011-Furie-227-76.pdf": "9101821D-2249-418B-95FE-399361DC2C55",
    "Stroke-2011-Saposnik-1158-92.pdf": "609543A7-CAFF-428C-B2D7-01C4BA4D70E9",
    "Stroke-2011-Saposnik-1158-92_0.pdf": "E7CC6DF7-2E0C-445E-B2F1-0B1EF503900A",
    "Stroke-2012-Connolly-STR.0b013e3182587839.pdf": "CB827ABE-3BBE-43BB-8473-503DB7A6BA80",
    "Stroke-2013-Sacco-2064-89.pdf": "A98335AA-3ED9-4857-B685-5E7BA2FBE0B7",
    "Stroke-2013-Sacco-2064-89_0.pdf": "1FDB00B1-9D9B-4B8F-848C-56F951BDCF7E",
    "Structural connectome.pdf": "22BE59BD-8E2C-44B7-909E-D810BDE0E430",
    "Structure and Policy for SNS Courses v.1 10-14-21.pdf": "E0EB9C46-4378-45F2-9568-D3EA5231EC81",
    "STU-Enrollment Letters-CC2022.pdf": "941AC744-7C54-4B9A-A5C6-9639602D70C9",
    "student 1.pdf": "B8CA88DD-A74D-426D-9A31-1EBA42C2B9D5",
    "student certificate.pdf": "8C7B9BBE-A1F8-43DE-AA4D-58C5365BA3C3",
    "Student Certification.pdf": "4069AD64-EF6C-4FA7-8781-F1BE47F8AF84",
    "Student Detail Schedule.pdf": "EDA128B1-7B95-4FE9-A10A-DAC338D99ADB",
    "Student Enrollment .pdf": "9B0266F4-227E-45FF-B900-CD63B0FED36D",
    "Student ID 2021.pdf": "C383C584-72DC-43ED-A8AC-0C8FF1B6E5DC",
    "Student ID.pdf": "9579C698-A7F7-4079-947A-091ABD5749B2",
    "Student Info Student Records and Transcripts Registration and Records Tab.pdf": "7C9B0223-D1F6-416D-9068-F697B27CA919",
    "Student Information.pdf": "CB8BAF95-26E7-4ADA-A758-320C696A7F18",
    "Student Letter - Jordan Petitt.pdf": "CD6ED532-E18F-4A96-8AC1-35CD3A2178FB",
    "Student Membership Letter CNS Braun Meryl Aug 2022 PDF-signed.pdf": "662553FA-1EE6-4C1D-9FBB-F5A43A67FBF5",
    "Student Self-Service Site Portal.pdf": "5FD6C959-BA3D-4641-BD13-91078F96B7EF",
    "student statement .pdf": "1A0A6967-C9A6-4865-9A56-E4DC12AE18F1",
    "Student status .pdf": "6072225E-0BEE-4118-98CB-7307BF10BCDD",
    "Student status letter for Jordan Roach 102821 (2).pdf": "30E1E867-653E-4700-94ED-BB32245ED0F1",
    "Student Status Verification - Dominique Higgins.pdf": "ECB35A81-8456-45F9-A938-DD648AA63623",
    "Student Status_Corella.pdf": "C287E385-F880-44D5-B92C-DF77F56323DA",
    "Student_Confirmation_of_Study_Letter_1701810 1.pdf": "B0E6BD2F-A0A1-4E11-814C-95E6E4B780E1",
    "Student_Status_Letter-_Gustavo_Oliveira_Almeida.pdf": "6C235A73-6FF3-43EB-A62A-51FFE6463E86",
    "Studentship.pdf": "275DFCBC-CF76-486D-B3DE-5CC6C5B6BEFA",
    "StudentStatus-Kumar.pdf": "A65A0562-049F-489F-84EC-7EE3F2BEC40F",
    "studentsummary.pdf": "EB80DF67-B3B1-41D0-8CBC-32EFA3ADDEB2",
    "Studienbestätigung.pdf": "F0FE884E-C51E-4FBE-8AC1-251F4B04D602",
    "Studienblatt.pdf": "25DBABF1-0DCA-416C-B1E9-E4A6D1A483D6",
    "StuebeCNSETVposter.pdf": "E2A182F0-5242-4D70-944B-F236FA6CC3BA",
    "Styliani_Triantafyllidou_Proof of Studies.pdf.pdf": "273C7C85-1B29-4563-9B2A-6BB290AD408A",
    "SU_Enrollment_Verification_Report.pdf": "DA3352B3-5213-44A2-A155-77C5B991F2DC",
    "Suarez-Meade Paola M.D..pdf": "8350E7E6-AF0C-453B-A297-DDE916486C4C",
    "Suarez-Nieto Maria - Good Standing Enroll.pdf": "21005D46-74A5-46DF-857C-E446E0B7AC2A",
    "Subaxial_Cervical_Spine_Injury_Classification.19.pdf": "2601378E-DD2F-4FAD-90D9-0E96D708CE6D",
    "Subbaraju Arava certificate.pdf": "7742A53E-5E3D-4DCE-BAB6-4488181F7BBD",
    "Subependymoma Poster CNS.pdf": "4DDCA078-3090-4764-9C91-18D2768FB900",
    "Subhiksha Srinivasan CV February 2024.pdf": "F7F7F7BC-157A-45B5-ADE8-1116769A5DA1",
    "Subhiksha Srinivasan Enrollment Verification.pdf": "068E5409-5475-4203-BD65-8D7F40C86F7C",
    "Subject S.3101 Good Samaritan Health Professionals Act of 2016.pdf": "825D950B-610E-48B3-B484-189006AEC2CD",
    "SubjectIndex.59.pdf": "106FCAC6-9856-4151-ABC4-F5A44FEC053B",
    "subjectindex_53.pdf": "BA229996-4CE4-44BF-A23F-9964CC30EC7C",
    "subjectindex_54.pdf": "A1405613-F6AF-4F92-9161-79B2E1FC2523",
    "subjectindex_55.pdf": "D49C9F7E-4795-40DE-A231-E2DE655979D9",
    "subjectindex_56.pdf": "C848A0B2-EC9D-4EBE-97ED-67DDA4E8ECD2",
    "SubjectIndex_57.pdf": "639477E3-BCE3-41D1-B5B0-C698D3F4BD9F",
    "SubjectIndex_58.pdf": "A1D7CA6E-C6A3-4EB2-9276-96D7CB3AB74C",
    "Suboccipital craniectomy  C1.pdf": "C2F7D4EE-19C8-4573-B0DC-201E4AE3A757",
    "Subramaniam CV- April 2022 CNS.pdf": "6A8259A4-8751-42E0-9CA5-E72D5B57EE37",
    "Subramaniam_Biosketch Kwon CNSCSNS.pdf": "32C07ED8-6D61-40C3-869D-AF0178946C91",
    "Subramaniam_Kwon LOR CNS.pdf": "0DF58505-A5C4-47A1-8D4E-07720599FE94",
    "Subramanian-CV.pdf": "4FEF2486-2086-466B-AA11-0E105176AE29",
    "Subscription to Italian Society of Neurosurgery.pdf": "B57CB5BB-E1B7-4744-93B7-977E8BB3A897",
    "Subspecialty Interest and Research Focus - N. Bentley.pdf": "6B8BD3CA-4483-4693-81AC-F67793B88E7F",
    "Subthalamic and pallidal stimulations in patients with Parkinson’s disease- common and dissociable connections.pdf": "C28345AB-5F28-4A75-B72E-D832CFE1510F",
    "Succop Benjamin - Letter of Good Standing 1.pdf": "113E4813-286C-4133-8774-4D4C9F5E6AFA",
    "Suehiro-2667.pdf": "03FAF55A-426D-4408-8083-76B0EC9FAD1F",
    "Sufyan - Medical School Transcript.pdf": "041A5741-70B2-4C9D-88F0-58FDD99380C9",
    "Suheel Abdul Salam Verification.pdf": "596527AE-3998-47C4-A637-B6FF1CD53487",
    "Suheel Verification.pdf": "39A4E42C-98F4-41CE-B1EF-E77619915AA5",
    "Sujay Ratna-MD Student-Enrollment Verification.pdf": "58A75CCA-7A60-4CD6-A492-BF919DF20D98",
    "Sujijantarat_FWLNSchol_2022.pdf": "E7216222-E812-4456-8C6A-ADE956CF1D19",
    "Sulangi Albert - Anticipated Good Standing Letter.pdf": "FBEEA27C-1938-41E7-A165-C964FC399165",
    "Sulhan_RUNN.pdf": "7D36A040-8045-4AF9-B80C-3AC6EDE96784",
    "Sulman_Goodstanding.pdf": "7070FB6E-E695-4D56-81A3-059E2CAE552A",
    "Sumbul Murtaza Dean's Letter.pdf": "F862C9F6-96A6-4D56-9F60-236823108FB9",
    "SUMMARY CV.pdf": "0D6E9823-4F76-4CDF-A08A-3E1607298349",
    "Summary of Research Efforts.pdf": "8730BBC2-5030-49CA-8536-7AA82403547D",
    "Summary of Student Status.pdf": "449D8547-007D-4DD7-9843-2AAA912369A4",
    "summary_with_recommendations_final_12.1.16.pdf": "7AA3DCE8-DA2E-44D8-9E27-8B6AF8DF1332",
    "Summer 2021 Newsletter.pdf": "784C2681-EE3C-4F8B-8515-2328626F969D",
    "Summit Imaging.pdf": "F5AA5B25-6C97-46E2-B0E5-0BAE8A558EF7",
    "Sun,Xiaonan_Enrollment-Letter_06-09-14.pdf": "41A3BEA8-BF07-4D63-AD01-4DB3B89B433E",
    "Sun_et_al_2020_ASSFN_Poster_v1 copy.pdf": "75BE2515-6108-4378-B9B4-DA0C23329651",
    "Sunny Moon enrolled.pdf": "E1B7D3DC-797D-407A-AC72-EAC7EB0051D6",
    "sunshine_independent_educations2978_sign-on_ltr_06-29-16.pdf": "D00970F0-0399-45D3-BBDB-C55CC36F07F8",
    "Supplemental 1 - CSDH Methods Flow Sheet-converted.pdf": "24FF67CE-26B4-43C3-A6BA-9131CA1ECA10",
    "Supplemental 2a.pdf": "41391A4C-349D-4226-AC06-E161E6E7352C",
    "Supplemental 3 - Reference.pdf": "311A8239-B9D9-4F95-9491-119D25BD0684",
    "Supplemental Table 1 ASD.pdf": "62A0E25B-7E68-40D3-8CB9-FEF0635AB6A2",
    "Supplemental Table 3.pdf": "E52F3E60-507C-4E98-B89B-B2F509F2903D",
    "supplementary table.pdf": "E57EB229-EAF1-473A-9AE6-261541DF1C6C",
    "Supplementary Tables (v4).pdf": "2E88F69B-7222-4D6E-9529-14CD22BBB523",
    "Support Letter - Department Chief.pdf": "E78B54B0-3923-4441-BFFC-A91B16905DEB",
    "Support letter.pdf": "F18FD0FF-E3E6-471D-B280-9AE141C8CAA8",
    "Support of Time Commitment.pdf": "FE180104-30A3-4A85-B64E-E582082DBF06",
    "Support Opportunities _ 2020 CNS Annual Meeting.pdf": "CB1F50E2-9166-4881-A5B6-7E2FB2DBE6AC",
    "Supporting continuity of studies.pdf": "13278A02-DC51-4BD9-985F-D3DE0C3FCE06",
    "supporting data.pdf": "F51B0B50-076E-4F25-9DC6-91CB2303D363",
    "Supportive docs.pdf": "98F6D4EA-4589-4565-90E8-3AC7314DC4EC",
    "sural biopsy incision.pdf": "F61B8379-F80F-4C05-8AF3-90D71AB60F3B",
    "sural nerve anatomy.pdf": "713A85E0-8614-40D5-A123-761D9CEC270D",
    "sural nerve sensory territory.pdf": "23D69B55-2C77-47F9-8A63-5AA04D2E0179",
    "Surat Keterangan Jeremiah 1.pdf": "5842D9B1-F566-4CF4-B573-F88090F1AD55",
    "Surg mgmt cervical degen disease.pdf": "E93D6F58-2763-41EE-8F0D-E64B69F17707",
    "Surgery Comments on Requirements Related to Surprise Billing Part I IFR.pdf": "4E0C51DE-BFF1-435C-A0C8-2EF53907A603",
    "Surgery protocol poster ASSFN v2.pdf": "9467EECA-4DDF-49CC-85AD-44A7CBD7766E",
    "Surgial Malpractice Myth and Realities.pdf": "B0DF31FF-12B9-4BB2-967F-7BA63ED1B217",
    "Surgial Malpractise Myth and Realities .pdf": "3DD9BAA6-0FD5-4562-A80E-CA8872438336",
    "Surgical Coalition CONGRESS 41320.pdf": "8D0F46A1-34AD-40A3-8BEF-77303E3A89AF",
    "Surgical Coalition Dec 20 letter.pdf": "C8144434-400C-40DB-99B6-201861EE13DD",
    "Surgical Coalition Global Codes Comments_090622_FINAL.pdf": "C314EC43-62E9-47B5-A0B9-3903CBE28270",
    "Surgical Coalition HHS COVID Letter_QPP and Interoperability Recommendations_4.24.2020.pdf": "40572A6B-49A8-4268-BBB2-90438F78C4F5",
    "Surgical Coalition Letter on Future of MACRA FINAL 1.0.pdf": "9DDD4AEB-9E1B-4E8C-A8CA-0AC99B8A5A15",
    "Surgical Coalition letter on Medicare package_December 2022 Final.pdf": "841EEC3F-A3AD-432B-9408-BDD137DE3794",
    "Surgical coalition NSA Part II Sign on Letter.pdf": "82B41158-686B-4BF1-B26E-FAFA9F5CBEB3",
    "Surgical Coalition statement June 2023.pdf": "64FC07F7-FC70-47FA-960C-3075EE2DC796",
    "Surgical Groups letter to the Hill E-M 091820.pdf": "13A8DB0D-52A9-42CD-9475-FD46A670F238",
    "Surgical groups support letter for Bera Bucshon Bill.pdf": "9A245A79-2BA4-481B-8EAB-87D10A9753FC",
    "Surgical letter to Leadership and Committees_December Final.pdf": "4DF1BBBC-6BF6-4900-9F9B-50C6F920C280",
    "Surgical Organization VA Residency Training Letter March 2021 Final.pdf": "71AEAD91-42F6-4DBC-A3CD-B39B581E402E",
    "Surgical Orgs Letter to CMS re CY 2024 PFS G2211 FINAL.pdf": "A3B0999E-30BC-465C-9C4D-CD3C048A8191",
    "Surgical outcome.pdf": "3B5B1DB6-0BE1-4931-A3C0-B12DBA6E9495",
    "Surgical Outcomes in Post-Traumatic Epilepsy_ A Single Institutional Experience.pdf": "F26B738F-5C74-46BB-9B5C-2EC051377F8D",
    "Surgical personalities burntout .pdf": "A9B53E8C-4BDC-4AF5-9FC9-9AB2A4C67C91",
    "Surgical Safety Data Table COMPLETE.pdf": "D9A38E80-EB9B-473F-A64A-5D5F69028014",
    "surgical techniques CSM.pdf": "225D7119-6E17-423F-8310-254EAD8A1C78",
    "surgical_coalition_letter_re_extenders_and_sequestration_dec_2017.pdf": "634E2C70-1F86-40B0-A0B3-A354DB5CC559",
    "surgical_coalition_medpac_letter_release_1.12.18.pdf": "F609A8FE-EC87-4C63-857D-46B8EA02B036",
    "surgical_gobal_codes_reporting_requirements_letter.pdf": "23AD98C3-8B0F-415F-936C-0EF633C3487D",
    "SurgicalCoalitionConsiderationsCOVID-19FINAL.pdf": "B123D87C-E89C-49D4-ADC7-06FE5FBCA940",
    "SurgicalCoalitionHealthCareReformPrinciples9-09.pdf": "A373FFE9-98CB-4AAD-B999-35A4EF2C59A5",
    "SurgicalCoalitionLettertoFinancereHealthcareReformOptionsPaper1--5-14-09.pdf": "30E24B39-42A6-4DE8-898D-901648F5012D",
    "SurgicalCoalitionLettertoFinancereHealthcareReformOptionsPapers2and3--5-29-09.pdf": "1E579744-55A7-485E-AB53-E15F90D419A9",
    "SurgicalCoalitionLettertoHouseTriCommittee--HealthcareReformFeedback--6-30-09.pdf": "718D041B-622E-45B4-951C-889C288B2C1F",
    "SurgicalCoalitionMedPACIMACLetter7-21-09.pdf": "25271EF8-68D7-449A-8D8A-1FF597238BFE",
    "SurgicalLetterFinanceFINAL.pdf": "DA98E938-B550-44FA-A53B-B82C3D96FB1E",
    "SurgicalLetterFinanceFINAL_0.pdf": "0A6F01B6-B5EB-42FA-A07B-BB026F4AFAC7",
    "SurgicalLetterWaysandMeansFINAL.pdf": "F11C2B80-3F3E-4F6D-9B10-3AFE0974583D",
    "SurgicalLetterWaysandMeansFINAL_0.pdf": "4D1B5CDC-6E20-4CB9-810B-8C559AA2E67A",
    "SurgSenateFinal.pdf": "7F688F82-1F5B-4EA7-BF92-556F322247C1",
    "Surjo BandyopadhyayLOGS.pdf": "A5A1FE29-F2EC-440E-B6C5-0DDCA268C257",
    "Surprise Billing Three Committee Letter 030420 FINAL.pdf": "6F7D9368-80DC-4AAE-B856-1597B0651D96",
    "surprise-billing-regs-Neal-Brady-letter.pdf": "C6402C22-AE1B-4C07-AF45-8430FB3905E4",
    "Sursal CV 2023.pdf": "B89B9B5A-6EA1-403F-ADEE-82C2856281E7",
    "Susan Hobbs CV.pdf": "9CDF9999-F699-4946-8774-372E1859B665",
    "Susan O'Brien Cover Letter.pdf": "E549749D-037E-41D2-88EB-111C0D987F34",
    "Susan O'Brien Resume.pdf": "87ADF375-7970-4BBF-9346-1EFCA858EBA0",
    "Susan Pannullo Nomination Letter.pdf": "681EF0AF-FA84-4648-8BCD-C70F3A414220",
    "Susan Pannullo Recommendation Letter.pdf": "56E5B68D-8FD7-4C24-B686-B0BB75387CF4",
    "Susan Pannullo Sponsor Letter.pdf": "BB36D8DE-84E8-45DC-981B-7FD2FB184CF3",
    "Susan Pannullo- LOR Dr. Benzil.pdf": "98B8FDB5-3C24-4050-8DE7-B9374275FA12",
    "Suyufeng TNS membership.pdf": "EB069C7D-138E-455E-B0F8-1F62563E61D5",
    "SV_CNS Poster_082122.pdf": "E6936862-9B5C-4FA4-8915-937D853939CE",
    "SVaziri.emailverification.pdf": "0E86400D-C0DB-454C-857B-DD0267130D23",
    "Svetlana KvintFellowVerif.pdf": "43169673-DCC1-4353-9644-E7FAB1096AAB",
    "SVivekanandan.emailverification.pdf": "6F2D9A03-8549-40BD-9BC1-8BC625C047F0",
    "SWA CV - 7-23.pdf": "FBC4BFB7-BAA2-4CB1-A3E9-45E7252635C1",
    "Swaid Swaid-RESverifi.pdf": "F5869BA9-2DFB-4D8C-B564-83BD2133295F",
    "Swartz Karin SNS Membership Nomination 2023.10.30.pdf": "FBC47C25-06EF-488B-B175-08C46DB77509",
    "Sweet LoR Guidelines Fellowship 2022.pdf": "A53CB3CB-B769-4E9D-AF25-03445325EFF6",
    "Sweet+Tomei LoRs Guidelines Fellowship 2022-merged.pdf": "E6E4A30A-9F83-44DF-BCBA-70BF1A91F998",
    "SWellness Index word.pdf": "6458F7CA-8C19-4DB5-9D1A-DF780EAE1EBC",
    "Swetha Sundar-FellowshipVERIF.pdf": "E783D8A1-36BD-466D-811C-E699ED054B62",
    "swiss member.pdf": "E402A4CE-DA53-4B1C-B6C5-BDAC84461D5D",
    "SWOT Analysis.pdf": "B4FD11FB-B814-4A67-A11A-3C6644C3919F",
    "Sy Chris LOGS.pdf": "1202E604-D374-4E76-98BD-1B748AD2B6BE",
    "Sydney Adams CV.pdf": "CB2C5475-A9F8-46F3-AA86-154894B5FB13",
    "Syed Enrollment.pdf": "03075CFB-0FBD-4139-9719-A3D108625D04",
    "Sylvester verification.pdf": "DF5F3044-B049-4A77-832B-9A676B737C27",
    "Symon, Lindsay.PDF": "A2D3347E-05F4-4A0A-8F0D-70ED6D725B29",
    "syncope2007.pdf.pdf": "8994DA8C-7546-424D-A735-03E878CE378D",
    "SynerFuse_POCS_CNS_2023.pdf": "14A92A22-4A6B-4961-B6CE-686602CE3CE5",
    "Syrix project Peds Section Poster.pdf": "20ECCE64-100D-4EB8-986F-6DA653F10134",
    "Systematic Review Poster.pdf": "AF1B341C-85D1-4F30-9D80-B038977A3217",
    "Szpila resume Feb 22 2023.pdf": "FA9C1273-4D0C-470C-BE05-5C3F50781BBE",
    "Szs_meningioma_CNS2022.pdf": "08703B32-73A6-4CFD-9844-411A5124A507",
    "szSusceptibilityRegions.pdf": "0664DBC7-A90F-44D6-856D-00B91EAEE9CA",
    "Szymanski Caleb Letter of Good Standing 1.19.22af.pdf": "55D32B17-E1B5-4CBE-8C39-BF3663FE6141",
    "T Huynh-RESVerif.pdf": "BA0B3BA2-CE0B-4439-A536-7D4872E6941F",
    "T. Chopko College of Neurological Surgeons Letter.pdf": "201DE363-A0DD-4D15-99FC-A29B40308720",
    "T. Marc Eastin Resume 2022.pdf": "FDA02891-D612-4244-9F63-B9DD1685DC66",
    "T.I.Papasilekas.pdf": "28216A44-A55B-4EB2-B42E-4C57F08F398B",
    "T.Kim-ResVerif..pdf": "3B7D14AE-2D55-476C-90FA-1FF3ECD4A897",
    "T201600497 Ghady Babiker Osman Babiker.pdf": "7970DA8C-42AE-4561-AD15-F46A1E86428D",
    "T410YD9D.pdf": "D18F771A-D4B0-40B6-9DAB-5A7BF6C06A6E",
    "Tabani_Resume .pdf": "92EF0EDB-D513-47DE-A99B-32BC71D62947",
    "TabarestaniTVerification.pdf": "329F21B9-B47F-46D4-ABCB-6A2B7DA0C391",
    "TAbel-RESVerf..pdf": "C37EA740-DAF0-4A84-9C9A-90C599FBFCBB",
    "Tabera Tarello Ver form 2019.pdf": "87DD7FFF-3A32-4905-AA4B-4C0B5520C503",
    "Table  - radiographic parameters.pdf": "CC886C06-4668-459D-9B1D-8339ECDB1E98",
    "Table  - risk factors for adverse outcomes.pdf": "3AE2A7D4-3966-427D-A8C6-258EF53A1E3E",
    "Table  - selection factors for management.pdf": "D9A05E36-FE5E-46AF-BC0A-3887DE7C7736",
    "Table 1   .pdf": "C2246571-AA59-4D19-9451-1B3838A62FB2",
    "Table 1  Previous papers.pdf": "844DEF65-9637-4CD9-836A-E746E983374F",
    "Table 1 (1).pdf": "C58F49C5-FACA-4A2C-BE1B-EED7F9FA7BE8",
    "table 1 (2).pdf": "5CCAD820-2A73-4654-995C-8F2CD0184994",
    "Table 1 (CNS).pdf": "5D68735F-979C-460C-8EEC-D6DE2D9B3E6B",
    "Table 1 (spine summit).pdf": "CB1281AB-672F-41CD-BDC8-DDB30F33A918",
    "Table 1 (v.5).pdf": "D024F337-6CF0-4AC2-AC87-4D8F2CD5E64C",
    "Table 1 - BSAVM modified techniques.pdf": "5FAD1BCC-555B-41C7-8C86-7C2E29282BB3",
    "Table 1 - Clinical Variables.pdf": "DCC1BF6B-B906-4734-808C-763F44999598",
    "Table 1 - Demographics.pdf": "D9008F48-8CAF-4283-95ED-35FD096035B2",
    "Table 1 - Peyote Protocol.pdf": "E5326D99-6D63-407F-9D7B-A00C463E8B61",
    "Table 1 - Repeat Seizure Incidence.pdf": "C5ACE7C8-799B-49F6-A609-541CA2B6A31B",
    "Table 1 - Seizure incidence at presentation of SBM and within 90 days after SRS by histology .pdf": "1CB5A109-AE68-44C7-BCF8-CD26FDF96F0F",
    "Table 1 - Species identified by percutaneous biopsy.pdf": "E9E5DB1A-5ECB-4465-93E0-CF36FA20EF79",
    "Table 1 .pdf": "E305626F-B3C4-4525-A598-2EA97AE73A0C",
    "Table 1 _.pdf": "7341E8E1-14AE-4F1F-A0F8-3B66C9AA8171",
    "Table 1 and 2.pdf": "DCFC8B8C-F73D-4F86-B939-910688D0CD17",
    "Table 1 and table 2.pdf": "8E2280AC-3E6A-4A1C-9DBD-E03A948A60F9",
    "Table 1 as document (1).pdf": "4209C667-ABDF-4179-8F55-14C22E2E6AF0",
    "Table 1 ASD.pdf": "F155633F-EB47-48A1-B4BA-91497F1D6499",
    "Table 1 CFR.pdf": "1F51BE55-B304-4127-9865-64DC9D9397D5",
    "Table 1 Clinical and Demographic variables.pdf": "32047394-2D8E-45A7-B6E6-B24C3D77099F",
    "Table 1 CNS abstract.pdf": "4042ED15-18CE-49EF-9BA5-346ABF9F91F9",
    "Table 1 CNS.pdf": "55D8B12C-106C-4378-8288-1018ACD25759",
    "Table 1 Contra MIS lami for facet cyst.pdf": "2056CB84-82B1-4E2A-B792-272E7BDFA6B0",
    "Table 1 COVID Survey CNS.pdf": "34CCF63B-1FD9-4942-993F-A97BD6E129BF",
    "Table 1 Demographics and Comorbidities.pdf": "6F59C546-BF8A-43D8-842C-433BE1C94416",
    "Table 1 Demographics.pdf": "75C01265-6C1D-4163-947B-53BD43713AAE",
    "Table 1 Descriptive statistics.pdf": "901392F7-6451-4104-BD87-722995D129C8",
    "Table 1 disposition.pdf": "A0D692B3-75DA-4A91-B7AB-C0C84AD6A48C",
    "Table 1 edited .pdf": "EC3ED7E3-2044-4206-ABF5-C1DEF57CD3E7",
    "Table 1 ESD Abstract.pdf": "B1DD80FC-1EA0-4AA9-B83E-E4585E46F1D9",
    "table 1 final.pdf": "96E03E57-8779-44D0-A171-B4D11002D778",
    "Table 1 FL CNS abstract.pdf": "F4E3F59A-A2F5-4BDF-A729-18297AFEADE4",
    "Table 1 for CNS cSDH statins abstract.pdf": "08E0807C-BB7C-471A-B3E2-A9790BA86CDC",
    "Table 1 gtr_ntr_er-converted.pdf": "8A5DEF34-B3C0-4502-B091-F69BAD145CE3",
    "Table 1 insurance study.pdf": "854BFA38-8E25-4B60-882C-FFABAE24B3C0",
    "Table 1 ISPS Demographics CNS.pdf": "1D15F8E5-D150-4019-B5BD-055A69305805",
    "Table 1 LOS predictors.pdf": "1022207E-E515-4F9D-A032-E855439020D0",
    "Table 1 MEG PTs.pdf": "4D1F3EA7-F18E-48B8-8563-1ECA200ED896",
    "Table 1 Mets Demographics CNS.pdf": "B7A69DAE-9B4F-4DCF-BA25-93BB12FA50D5",
    "Table 1 MIS.pdf": "45738608-80F6-487D-88AB-D3D91225A5F7",
    "Table 1 Molecular Information .pdf": "C1854BE7-D46D-463F-965B-361F600A8126",
    "Table 1 Patient Characteristics at Baseline.pdf": "74E97661-C799-478B-BA20-E16C8069F6F0",
    "Table 1 Patient Characteristics.pdf": "60050399-3795-42DA-BCDC-D4FD6F1F83AB",
    "Table 1 Patient Demographics CNS.pdf": "E433B634-D581-4D63-AB6A-416FD3D012C8",
    "Table 1 Patient Demographics.pdf": "6177E184-4C64-4B53-A063-52250EEAB400",
    "TABLE 1 PDF.pdf": "76B88FDB-FC5F-46C0-893D-011455E05004",
    "Table 1 PreOp.pdf": "A50CD966-1060-4912-A368-BC43824CAF59",
    "Table 1 SAH.pdf": "CB60DAF6-E51A-428C-A35A-84DFA9C65022",
    "Table 1 School List CNS.pdf": "B7951380-4333-4490-B43D-8BC00538360D",
    "Table 1 Total Vault Reconstruction Project-Intracranial Volumes.pdf": "D6EFEA11-95C0-4C2E-82C0-7F8D813A5104",
    "Table 1 VPl final .pdf": "F3636042-E373-47A5-A7D0-994DB07ABF01",
    "Table 1- Demographic Information.pdf": "B720EBA2-34D0-4FA8-8C50-508EBE4B7BCA",
    "Table 1- demographics of included studies.pdf": "9458AC29-158B-426F-93A2-050AD7996604",
    "Table 1- Descriptives'.pdf": "80732844-91D1-4476-957B-D70AB0846D03",
    "Table 1-2.pdf": "8FC7F919-1DCE-4EAA-AC41-86A54F5DEB48",
    "Table 1-DI project.pdf": "8C21938C-CEBA-4212-A0C7-C5BA894A9AEC",
    "Table 1-Ranking probability of lumbar interbody fusion rate.pdf": "6F460456-6FC3-40E3-A637-9EFB76469CC8",
    "Table 1-Summary of Demographics and Index Surgery.pdf": "E1D9E2CE-B142-4DD0-9D40-B644EEF37C2C",
    "Table 1. Baseline characteristics and initial data .pdf": "71811884-6849-4517-BB5D-24EE55E8C6EA",
    "Table 1. CNS 2020.pdf": "5EF47EA2-31D6-410E-B92A-5A9CCD8AFBE6",
    "Table 1. Demographics and presenting symptomatology of the study population by surgical intervention.pdf": "0EE8DDD7-04D0-46AD-A2D1-D142946F13EF",
    "Table 1. Demographics, co-morbidities and intraoperative characteristics of patients in the studies.pdf": "8F47D51D-C16C-48C1-A50D-AF9417961E02",
    "Table 1. Demographics.pdf": "241BCF76-8907-4B96-9AFE-BA6CB1053924",
    "Table 1. MEC lit rev CNS2021.pdf": "A968470E-E5A4-4573-98BB-9D8D9F805659",
    "Table 1. Patient Demographics.pdf": "8CBDBFC4-D605-4146-A26A-3199C0363F48",
    "Table 1. Study Characteristics.pdf": "799C18D5-3650-4AE8-AC36-F44870836498",
    "Table 1. Total Study Cohort Characteristics.pdf": "D3ADE486-E3C2-4335-A4DD-3F5A673EB9C1",
    "Table 1.0.pdf": "2E754EEE-1EAF-4ABE-A85B-B6F90195D5EA",
    "Table 1.Baseline.pdf": "55BFAEFB-ABE5-4D8E-B74F-2887694AEE9B",
    "Table 1.docx.pdf": "443A4238-9D61-47CD-9A1F-3E9A2B01EC31",
    "Table 1.pdf": "B9A7F2AF-90F3-4222-AFE0-0112BC38B828",
    "Table 1_CNS abstract.pdf": "994B0586-8A3B-402A-AB48-CFEC56CE2C91",
    "Table 1_CNSAbstract.pdf": "E940C0B8-4C71-4CF9-A558-B5B9E6BEE6B4",
    "Table 1_Cox Regression.pdf": "310F452D-DC9A-446C-9202-8B39DE8BD020",
    "Table 1_Demographics_Clinical Variables.pdf": "CECE977C-2FF4-448C-8CB4-9E6EF006C086",
    "Table 1_Descriptive.pdf": "31484940-9997-442D-95C6-B9BF8CFCCE35",
    "Table 1_Final.pdf": "5A964F26-ABC7-430D-8FEE-7955BD06E7DC",
    "Table 1_NonFusion_Demographics.pdf": "20A61A8F-EAC4-466F-AA87-1F56278581C5",
    "Table 1_Patient_treatment_and outcomes.pdf": "4906CA71-39C8-43B4-AB49-539C21E90B2A",
    "Table 1_TCT Monitoring.pdf": "07ADD7A4-7C95-4365-B3E7-19926CB5990D",
    "Table 1_v2.pdf": "A22F5D5D-1595-43E4-B812-5DADF5295C22",
    "Table 1cns.pdf": "70D9C5B1-115A-4AA5-83E7-F0EEAF85C046",
    "Table 1MLBS.pdf": "709B6E6A-4B7A-457E-9CDF-5CFA78F9B061",
    "TAble 2 (1 of 2).pdf": "747A69BB-1337-48CE-B548-7EB35B7A4CE6",
    "Table 2 (2 of 2).pdf": "C035E483-BBA9-4B95-891D-B42DC677D5F0",
    "Table 2 (CNS).pdf": "730521E4-C469-4A63-818F-1CDA035CFA73",
    "Table 2 (spine summit).pdf": "A24AE084-B823-48BF-B0AF-EC73412CD577",
    "Table 2 (v2).pdf": "D6976BD6-78B9-4DA1-ABB3-CB2A408D56FB",
    "Table 2 - 3 - Studies included in analysis of fusion and complications.pdf": "90692C9B-65C4-49A7-BA59-C96FF3AC85A6",
    "Table 2 - BSAVM Anatomy.pdf": "9A0D4B1F-471E-4CDD-A352-2DD85602055D",
    "Table 2 - independent predictors of personal view.pdf": "1BA78250-0DDC-46F8-8DF2-BE2EDDE038AC",
    "Table 2 - Length of Stay and Cost.pdf": "0A2267C8-CBEF-4AB3-BAA2-0029D4AA4320",
    "Table 2 - Overall Results.pdf": "6F402A0C-88F1-4202-BA29-8706EE3EFF5B",
    "Table 2 - Seizure incidence within 90 days after SRS by treatment modality.pdf": "F6A8E925-10B9-4A49-9E7F-F9A75FD13C7C",
    "Table 2 - Studies included in analysis of fusion.pdf": "AE9DD958-35CA-4DF1-97CB-A30E7E91F271",
    "Table 2 - Univariate predictors of positive cultures.pdf": "CDF4F4D8-9B0B-4EB9-9804-EA72BCBC0710",
    "Table 2 Analysis of Risk Factors for Incidental Durotomy.pdf": "1028BC4C-C73D-4C35-AD58-A93005043A07",
    "Table 2 ASD.pdf": "215E5202-6030-499A-B9F7-85D8AB014873",
    "Table 2 Attendance and Participation CNS.pdf": "464C6FF7-CCC4-4084-B22A-1BD4BC695D41",
    "Table 2 Baseline and Postoperative Radiographic Variables.pdf": "76B190D1-FBED-46C5-A984-EA00E3038550",
    "Table 2 CFR.pdf": "D8BD386B-4247-4489-9FE7-FA016D1C273F",
    "Table 2 Classification Model Performance Metrics.pdf": "54C13A39-33ED-4942-A6A0-27AEB3C790DE",
    "Table 2 Clinic visits CNS.pdf": "538D9E9B-35B6-450C-96BC-78F98BD3255E",
    "Table 2 CNS abstract.pdf": "02F98158-81F6-4387-BE33-03BF8A16BE08",
    "Table 2 CNS cSDH statins abstract.pdf": "95423E3B-03EA-46CB-9DF5-B80F5D5CF89E",
    "Table 2 CNS.pdf": "C849B7AA-654B-4993-A0A0-FDBA4A31C04E",
    "Table 2 Complications.pdf": "EDEB8C52-5710-41F1-9F4E-5390D6E416C4",
    "Table 2 Demographics CNS.pdf": "B3636534-6972-4AA6-AE99-41A4C7AA43DF",
    "Table 2 disposition.pdf": "1B44B1FF-8066-40DA-9CB7-E5C7481C48DD",
    "Table 2 ESD Abstract.pdf": "E5DBCDDA-F8AE-4BD6-9D05-B6104EB11070",
    "Table 2 FL CNS abstract.pdf": "3CFB0E1C-9022-4745-B489-9B012F5C1FF9",
    "Table 2 Hospital Factors .pdf": "4C3AE4E6-0FEF-4BFE-8292-FC51513AD2E6",
    "Table 2 insurance study.pdf": "BF17D0EF-F44C-4506-88C0-831CEC1266E8",
    "Table 2 ISPS Comparative.pdf": "5C21049E-24BA-4A7E-B2BD-A62CD0794656",
    "Table 2 linear regression analysis.pdf": "21FEF647-7407-4ACB-ABED-37A07FEC4925",
    "Table 2 Logistic Regression LOS Calculator.pdf": "A43FAF21-CACE-422D-86AA-05F6AB7D6E35",
    "Table 2 LOS predictors.pdf": "D564CE1B-2E0B-45B7-B4D1-EC98C2E398B4",
    "Table 2 Mets Primary Site.pdf": "B3935600-C84C-4985-9E64-7C74EAF50CCC",
    "Table 2 MIS.pdf": "4B897AB5-C831-4F58-A76E-360BF55CA348",
    "table 2 non fn-converted.pdf": "DDF3C133-1985-4760-AFF8-35EEC5C1FA0B",
    "Table 2 Patient Clinical Characteristics.pdf": "349B301F-7B6E-4E1E-B356-837C90414F21",
    "Table 2 PCA Hazard Ratios CNS.pdf": "828FF9C6-E2AA-4F8A-89EA-6BD72895B81A",
    "TABLE 2 PDF.pdf": "FF1AD5CD-9E5A-45B2-9870-73E0AE77F657",
    "Table 2 Peri- and post-operative outcomes following ALIF, obese vs. non-obese patients .pdf": "B180D2A8-850A-4091-B0BC-4FFCAF5B8F43",
    "Table 2 PostOp.pdf": "0EC6A034-1A04-4073-978F-7E75E6DEB2B9",
    "Table 2 Regression Model.pdf": "66012F61-D527-41A8-8596-FFCF8B959E92",
    "Table 2 SAH.pdf": "ACFA84A6-720C-4699-B8F5-37C0161BAFEC",
    "Table 2- Infection History.pdf": "87924BAC-13D9-466C-A497-40DA6864BF71",
    "Table 2- Patient characteristics.pdf": "7B685B2F-94B7-4D46-8278-547630EE4752",
    "Table 2-Summary of esophageal perforation repair.pdf": "44BF7432-C050-4357-AF96-6480A071C0DF",
    "Table 2. Analysis of Socioeconomic Predictors 1-Updated.pdf": "398EC32A-F55F-486D-B632-0E4879C3900B",
    "Table 2. LOS and Direct Costs.pdf": "0B959592-8AD9-4A08-BEE4-45D7AD1C84F3",
    "Table 2. MEC.pdf": "A28C918B-54C2-49F5-975C-411DF7F16402",
    "Table 2. Morbidity and mortality of the study population by surgical intervention..pdf": "149EA148-3A2D-4420-B955-5E76CE810F25",
    "Table 2. Operative Characteristics.pdf": "243D2DF5-6667-4804-BA48-62241B1363A9",
    "Table 2. Post-operative complications associated with blood transfusion in the studies.pdf": "4A5AF65F-60BE-4831-B421-637C7E234C5D",
    "Table 2. Risk Factors for Hyponatremia.pdf": "7DAFD4EC-F672-479B-A793-AA3B895B7F64",
    "Table 2. Surgical and postoperative outcomes .pdf": "E6A697AC-0B23-48F0-A1BF-DC9BE2DA1335",
    "Table 2..pdf": "ACAF6730-F3FC-413C-A852-543883214DE6",
    "Table 2.docx.pdf": "D960FB7C-01C1-4D63-98F8-4AF2A4FF89A9",
    "Table 2.pdf": "68E23F69-39A3-4B0C-B463-003037AFD9FE",
    "Table 2.Surgical characteristics.pdf": "B2955031-314F-4195-BF44-A106C18FBB94",
    "Table 2_NonFuion_HospitalFactors.pdf": "E5AD1B03-54DF-46A8-999A-615E08AE410E",
    "Table 2_Outcomes.pdf": "91C2D259-0BD4-4D61-A6C4-33D56AE4C082",
    "Table 2MLBS.pdf": "00CB2129-5B9F-48F3-83B8-9860BF4567DC",
    "Table 3 (CNS).pdf": "52A1B4B9-4EF0-4608-9F98-CF6116A97DF0",
    "Table 3 (v.4).pdf": "7FE439DE-102B-4358-B20E-E8295D5C090B",
    "Table 3 - Complications.pdf": "9D84FCB7-302D-4028-B405-CF666EC2BEAB",
    "Table 3 - Positive cultures may not lead to changes in clinical management.pdf": "AA093CE9-2AF7-4117-A62E-236BD648FB95",
    "Table 3 - Postoperative Outcomes.pdf": "B81384D8-9522-444C-B520-41816CFF745D",
    "Table 3 - Studies included in analysis of complications.pdf": "F340F781-ECBA-4B72-BB4F-0309A4AD9CC3",
    "Table 3 .pdf": "BC070ABB-2422-4985-88A6-1D95EB7A79AD",
    "Table 3 and 4.pdf": "A4C62844-D287-4D13-BB8D-023A289898A1",
    "Table 3 ASD.pdf": "83DFD187-A306-49A3-B096-1BA35FBE3833",
    "Table 3 Baseline and Postoperative Patient Reported Outcomes Measures.pdf": "98ADA38F-4AD5-47EB-B81F-7566868931DB",
    "Table 3 Bivariate Analysis CNS.pdf": "662BCF95-FCED-4193-90FF-440B82A37E83",
    "table 3 cfr.pdf": "DB8A3281-21D5-4960-8D8E-F3839A0091B5",
    "Table 3 CNS PDF.pdf": "F38817A0-DE59-4122-894B-C6474C8DBF94",
    "Table 3 Compliations.pdf": "D65D5750-5E8F-4AF6-B090-B6AB3DA3F331",
    "Table 3 FL CNS abstract.pdf": "032DA49A-8DB3-4CC3-848E-2D96D6974DB6",
    "Table 3 ISPS 2010 Bivariate.pdf": "6B4077FE-E539-4A88-86EB-017DA40A9997",
    "Table 3 Logit Performance Metrics.pdf": "A23C5789-F7B6-43E5-AFDA-3C915F33FA25",
    "Table 3 Mets Bivariate Analysis.pdf": "081A3A38-DA41-4A40-9673-B4C158EA0483",
    "Table 3 MIS.pdf": "8CFED6D7-9C3F-46A7-8205-3B299697F49D",
    "Table 3 Outcomes CNS.pdf": "3B390444-2F21-4C93-A138-71204396D4D0",
    "Table 3 Postoperative Outcomes.pdf": "E106B032-C3EC-430B-BAA1-507B76DF139D",
    "Table 3 Propensity Matched Analysis .pdf": "D6F95723-2414-4458-A842-D34B3247DA1A",
    "Table 3 Regression Model Performance Metrics.pdf": "04C4B180-EA46-4F7E-A974-F4B1A3BDB311",
    "Table 3 TJS (1).pdf": "94879AD3-5F56-4D70-BE66-D67315D48D99",
    "Table 3- Cohort Characteristics.pdf": "43772E9F-0472-470D-8845-9CB0266E6238",
    "Table 3- Spine Infetion Information.pdf": "25947C6C-94D2-4057-B6B1-A6E7DF009486",
    "Table 3-Summary of Patient Outcomes.pdf": "3D3F87D0-FC33-4D4C-8A90-601DEA1917F3",
    "Table 3. Clinical characteristics.pdf": "CF85E10E-1B73-46F4-A167-D3F7874B63C7",
    "Table 3. Multivariable logistic regression analysis of functional outcomes .pdf": "AA5AF5DF-5681-43D7-84C4-03F151E24D7E",
    "Table 3. Perioperative and Postoperative Outcomes.pdf": "952DEE69-BF0A-4C54-ADC8-8447B4063561",
    "Table 3. Perioperative Procedure Requirement.pdf": "79885C5C-C3BF-4D0C-8B64-5BC7F2D8D213",
    "Table 3. Radiographic Data.pdf": "5E58AB13-B52A-4849-83F4-B3117C9E4748",
    "Table 3. Univariate analysis evaluating the impact of surgical approach on relative clinical outcome measures..pdf": "0FB45200-710F-4B12-BE80-4C762971265D",
    "Table 3.docx.pdf": "8FC2F258-5ACF-4205-B867-E3FF52E646E2",
    "Table 3.pdf": "CD81D28D-350E-44F7-B9B9-04486AA41226",
    "Table 3_4.pdf": "04B055F0-D6D7-4AE2-AB48-FF5D5A39F9AC",
    "Table 3_NonFusion_PropensityMatch.pdf": "4283AF74-A8DA-40B1-A5FD-5816FA837AE5",
    "TABLE 3MLBS.pdf": "A06D79AD-4443-4F25-90BA-CB303D8CF012",
    "Table 4 (CNS).pdf": "30394E7E-A749-4EE3-8AAF-992E53E91745",
    "Table 4 - independent predictors of hospital response.pdf": "7F075D81-1141-4600-B272-8B65C2DC022D",
    "Table 4 2020 Bivariate.pdf": "0CA9F624-38E8-4E4C-A654-03C53AD18BB2",
    "Table 4 Additional Diagnoses.pdf": "8A0E7DCC-9B69-4E1F-BBCD-EAE22CB3E215",
    "Table 4 Asymptomatic Thrombosis Patients.pdf": "66551703-1F93-4F3C-BE36-3D3928436A72",
    "table 4 cfr.pdf": "A956A948-1D1A-435C-9472-40BF1BC78575",
    "Table 4 Mets Multivariate Analysis.pdf": "35921C63-A983-4700-B388-1ACDF5197B34",
    "Table 4 MIS.pdf": "399E182A-C18D-4728-B6F9-2670FE57F2C5",
    "Table 4 Multivariate Analysis CNS.pdf": "CE9D5657-442D-488E-B006-69D89BBC1697",
    "Table 4 Multivariate poor outcomes NSQIP.pdf": "1CA5EBD1-3CA9-4037-8CA3-281D2D1032FD",
    "Table 4 Selected Complication Profile.pdf": "2B85C719-A4F3-418B-A905-CAA6D89BE0D0",
    "Table 4 TSJ (1).pdf": "8BE38E47-587D-4128-B545-930B8FE73660",
    "Table 4. Multivariable logistic regression analysis of clinical events .pdf": "E99CB8DB-BF83-416C-9A6B-8E3C7732856E",
    "Table 4. Outcomes of Hyponatremia.pdf": "4F2F2DC3-EFDA-44FC-997E-77DB2F9C5C2B",
    "Table 4.docx.pdf": "0E9B05B9-961B-4656-8118-74BC1E2E6C31",
    "Table 4.pdf": "2E8B24EE-2F25-41D3-B470-0959CF1FFB88",
    "Table 5 (CNS).pdf": "70DB9C76-499E-47DC-BA66-204AB57E82A2",
    "Table 5 ISPS Additional Responses.pdf": "C2A43172-EFF4-4C85-A1FE-529A0C5EF917",
    "Table 5 MIS.pdf": "E8D5F5AC-FD54-4A41-8354-7C620A53D096",
    "Table 5 Symptomatic Thrombosis Patients.pdf": "F5635D15-E1EC-46A6-96DA-D70EC1E793FA",
    "Table 5. Multivariate Logistic for Readmin NSQIP.pdf": "E7F7DA1E-35F6-4395-A6E6-7D94EEAD4613",
    "Table 5.pdf": "004117B7-7AE6-4584-991D-1388A14A0EAE",
    "Table 6 - independent predictors of hypothetical questions.pdf": "4EC6D4E4-99DE-438F-8B86-A4E88D8BBE84",
    "Table 6 SPINEr1.pdf": "F645D073-1B57-4F0D-860D-F2F743D5DCA2",
    "Table 6.pdf": "1004F031-0270-4895-98F3-268C958B5AEA",
    "Table 7.pdf": "555AC2D9-04AB-4762-9F81-EDB70DD0D4A1",
    "Table and Figure.pdf": "198C5EAD-D245-43C3-881D-2583AE718BC8",
    "Table CNS.pdf": "3D08A237-6F05-41A5-9EFB-9FDEEE4BE642",
    "table for abstract.pdf": "209C1626-E7F4-4752-87F3-1C52AE76C6E0",
    "Table I - Observed Survival in Spinal Astrocytoma.pdf": "CDF210CE-7298-4FD0-8FF0-01DC08534656",
    "Table I - Participant demographics and characteristics.pdf": "089C5BB3-B681-480C-9575-29D663A79E92",
    "Table I OP+CPM.pdf": "5E21A0B8-9073-49C6-B5F6-57096A904A4F",
    "Table II - Fluid, electrolyte response and postoperative characteristics following endoscopic, endonasal craniopharyngioma resection.pdf": "D45E08C8-8350-4F99-8D92-61CFB07304E7",
    "Table III - Discharge antidiuretic hormone (ADH) replacement therapy.pdf": "DEAC4F0F-4A3A-4CCD-A3AB-08A68014A6B8",
    "Table.1.pdf": "A08141C0-005E-43BF-B279-099A05AD768B",
    "Table.pdf": "D8CC742A-DDE3-4FFA-B36B-110D8EC44677",
    "Table_1.pdf": "3744A7C0-4B6F-430B-802D-24469D0A468F",
    "Table_1_Demographics.pdf": "928FD384-4E1C-4436-A0CC-85FE2CD405C7",
    "Table_1docx.pdf": "5043B462-8EF8-4551-A183-F12647B84892",
    "Table_2.pdf": "C9100D6F-BAAA-4701-BEE0-3B74F9294308",
    "Table_2_Operative.pdf": "A11C06E3-28CF-4952-AD7C-7CD502F0BBD9",
    "Table_3.pdf": "68CCB823-6B05-4E75-8F64-3B4562D862F5",
    "Table_3_Complications.pdf": "AB8F6B6A-3062-4A45-8260-A36B759B0F8F",
    "Table_4.pdf": "5F0B0A78-D122-49FB-A97F-0608A10E17F2",
    "Table_5.pdf": "F2C100A8-7F5A-4BDE-9CAE-8CB28503342D",
    "Table_saccular.pdf": "B093B906-178D-414B-AE93-3F9FB210AFC5",
    "Table1.pdf": "CEF92F8A-6C38-4398-97D3-117F2BF8898A",
    "Table1_ACDFabstract.pdf": "F1CCCA80-E8C5-4A70-9B9D-A6B0CD4484CC",
    "Table1_aICH.pdf": "F69CD353-EE14-4D5D-B70A-B39CF1C91864",
    "Table1_AutoML.pdf": "B8DDEF11-46A9-4F72-A596-2D301DC64080",
    "Table1_brainMets.pdf": "AFBBFD63-E7D6-4F76-A034-AB4C276ED8EA",
    "Table1_DCLN_CNS.pdf": "FE0239DA-3021-490F-BDCA-D63B356EC911",
    "Table1_demographics.pdf": "77C92D0C-9612-41BE-AFE9-0214FF64CDAC",
    "Table1_DSH.pdf": "27ACD455-2045-45A0-9604-47AFE3BE7C29",
    "table1_TA20210608.pdf": "8A886949-DC8F-4D30-A93C-7E05704D37A9",
    "Table130Day.pdf": "B06ABC23-4187-4401-909A-0A16933BA6F3",
    "table1DBSreadmissions6april2020pdf.pdf": "AA65708F-3A97-4E79-9916-5A081ED22997",
    "table1ependymomaabstract.pdf": "08E20764-ED4F-4FE3-9C22-3C9976AF00AF",
    "Table2.pdf": "B00023F7-1558-452C-9FF6-1C111373D741",
    "Table2_ACDFabstract.pdf": "68A15B8C-5699-4B1C-B02E-676ECF610415",
    "Table2_aICH.pdf": "616EE537-5ABB-4245-80A2-65A9A70165ED",
    "Table2_DSH0609.pdf": "5668E1DA-6B84-422E-A21F-629578BBC445",
    "Table2_survival.pdf": "78E91E6D-1FEE-4C95-9AD8-2D84F278C005",
    "table2_TA20210608.pdf": "559091CA-816A-4432-AE83-011A0D4AD7C9",
    "Table230DayReadmission.pdf": "EF0E6B42-83F4-4A89-A196-C4F7464F72AC",
    "table2ependymomaabstract.pdf": "B1218A8A-ED5B-4EA0-8D37-75065B3B7ED4",
    "Table3.pdf": "9A5E9FAC-C121-4AC8-B478-1CC3AD674FC6",
    "Table3_ACDFabstract.pdf": "250A2BE0-EFB2-4446-886D-4604C14437F4",
    "table3_TA20210608.pdf": "DC120162-47AA-4495-A185-09690E45E7DD",
    "table3ependymomaabstract.pdf": "7A0A5400-1E4E-45EB-B35C-93E3C63D99B4",
    "Table4.pdf": "1B0E4DF4-9596-4482-8E68-11462DDF1525",
    "Table5.pdf": "E74C33BE-BEFE-47BE-94FB-35B0633E2827",
    "Tables & Figures.pdf": "808F3783-F1CE-45A8-9D3A-A1C77D47C496",
    "Tables (CN mets).pdf": "F25D4B14-CFF4-4D72-A608-4AAEFF60144D",
    "Tables 1-3.pdf": "0C3BF908-0CCC-456C-9322-4EC17C99261B",
    "Tables 1.pdf": "A9E996C6-4F14-40F9-BB3C-B3131DA7A39F",
    "Tables 2 and 3.pdf": "0E1B370C-1C1A-4655-AFC9-5E5D0CF063CF",
    "Tables 4 and 5.pdf": "4E06A516-5F7D-4C04-99DB-6482CF046C5C",
    "Tables 5.pdf": "83211E6D-B3BC-4C67-9223-FDC8C7E1C8C8",
    "TABLES AND FIGURE1.pdf": "28F0039A-3226-4C43-A725-1688BAEFB45F",
    "Tables and Figures cns abstracts.docx.pdf": "75A1CAC6-06FC-4B39-8745-70A3D2C4ED4B",
    "TABLES AND FIGURES.pdf": "C071EA90-1B3D-4410-9532-04EA14D69DAF",
    "Tables for CNS abstract.pdf": "6FCF9381-A3B8-4347-9435-84C2284BDEF2",
    "Tables for CNS petrosal abstract.pdf": "BE160C68-B785-43DC-9CA9-40F95E01B392",
    "Tables for CNS spine in Las vigas.pdf": "083C8BB9-9710-41FE-89FD-826A16E3E3AD",
    "Tables PDF.pdf": "FCCA94DD-0388-40B5-9229-1E8917597A3D",
    "Tables-converted.pdf": "87DBDA7A-FC6F-4C73-BC3C-0ED4C40E71BE",
    "Tables. Side Effects prolactinonma.pdf": "7D6EAED5-6DCC-4400-8655-DB02B1EEB07B",
    "Tables.pdf": "F0CC2E0A-EEC6-4468-A649-0398A279633D",
    "Tables_1_2.pdf": "2274FB7B-7117-42CD-81B8-BB9380055907",
    "Tables_ASA Cost ACDF_CNS2020_LambColin.pdf": "90D65478-02C0-4E06-B72F-940213AEE751",
    "Tables_final.pdf": "ED899B0B-E872-4D45-8962-A7845A6AF041",
    "TABLES_MENTALILLNESS.pdf": "027B4239-DB0D-4B3C-8DA4-8465A1197D81",
    "Tables_Modified Iliac Screw.pdf": "C4A7A6B4-E84F-41B3-AF84-D1923EA4AB92",
    "Tabrizi_CNSLeadershipLetter.pdf": "AFAC38D2-BB59-420F-B415-CCEA9523AA81",
    "Taemin Oh-FellowEmailVerif.pdf": "692A0C86-91B8-4197-A60C-7E0CF5A03E62",
    "Taimur Hassan - enrollment verification for org membership.pdf": "8A32F20B-2A07-41F2-94AE-5544F3D0727F",
    "Takacs CV 2023.pdf": "CAF644FB-12B7-4472-B276-63F2F24B4537",
    "TAKEAWAYS.pdf": "B9656384-94EC-4912-9E45-692FC3A15C6C",
    "Takeda CV 2010.pdf": "B884FA06-75DB-48C7-B6B5-4A5B80E6BB50",
    "Talbot-Stetsko 4.2021.pdf": "CEB0DF78-5FC4-4B53-B38C-081715ED7315",
    "Tamargo, Rafael J.pdf": "732B876C-B1DE-43ED-A31C-23A04875FAE0",
    "Tammys Resume.pdf": "312F14C2-6EFB-447E-9D3F-4120E702A41F",
    "Tan CV 2020.pdf": "4BBE70F4-C752-48FF-954B-DBA415E275CE",
    "Tan Enrollment Verification Letter.pdf": "247ADBD0-91C2-4634-B6F1-0BF6220D5D91",
    "Tan Verification.pdf": "2AD7714D-10E3-463B-91E4-BCB2377A3269",
    "Tanchanco Ocampo_Collin_CV_May2024.pdf": "6DC7AD00-3FC4-451F-A270-3467FB2DA28F",
    "Tandon_SNS_Duke Chair LOS.pdf": "00476CA3-C28C-482E-9E0B-30C7B87F6144",
    "Tanweer CNS Leadership.pdf": "3A00D2CB-ECD7-40A6-84CA-C85D5B190231",
    "TapScanner_20190517_120754_77.pdf": "78A3E9B7-1782-4F76-A75E-D1F12BFF6EE1",
    "Tara Nail-FellowVerif.pdf": "86E9CEF7-8AF7-400C-9E84-E489DB2551BB",
    "TatendaMahlokozera_Letter_of_Good_Standing_CNS.pdf": "52E420FD-8AAA-486B-B5CD-59CD1A950435",
    "Tatsuhiro Fujii's School Enrollment Verification Letter.pdf": "D46E634E-3665-460E-A4F4-867A3D25B318",
    "Tatton Ryan W - enrollment verification.pdf": "A26128B8-0096-4E55-AC8D-D2952E18FB37",
    "Taussky_2024 Whats New_FINAL.pdf": "5A4E997F-6A93-4064-850B-977DD2BF9265",
    "Tavakkoli Armin - Letter of Good Standing.pdf": "CBC2378F-13E7-415E-A84B-00A3F271F6AB",
    "TAVR_082222.pdf": "173C753E-4266-4BB7-8F39-F39BB77ADDCB",
    "Taylor Bauer Resume__.pdf": "28CC0225-46F8-4666-A397-7CCC232224F8",
    "Taylor Duda- Fellowship.pdf": "AE8EC274-4D7A-4D52-AB1F-E6FB1E677E87",
    "Taylor%20Evelle%20Smith-2.pdf": "54A14C98-7627-4A84-ADDD-C960889A46C3",
    "TBI Polytrauma Poster.pdf": "A75703E2-631A-412A-B8AB-9A41E93AD3A2",
    "TBI poster AP.pdf": "8E0A1FE7-2CE0-4209-9BAB-6FC4AEE34B09",
    "TBI poster social.pdf": "7DFB3F19-5345-45A8-B067-8922C8A2F6CA",
    "TBI.pdf": "22E44D54-C472-447C-A5BC-39BEDF413286",
    "TBI_AED_Tables.pdf": "F43DADF0-0F95-42B8-900E-E4CC44E50DE7",
    "tbi_position_statement.pdf": "99A563AD-1CA1-4E1F-A027-87CAE236C83A",
    "TBImicrobiome_CNSposter.pdf": "2F15AA61-DAE9-48E9-82C5-7515B54902FE",
    "TBIReauthorizationhr1098_injury_prevention_support_letter_2013.pdf": "C124A995-8EAB-496B-A9BC-9D5D682D90FC",
    "TBIReauthorizationSenateivp_network_support_tbi_act2013.pdf": "6118760B-C0CB-48BC-B4A4-8EC2D1C199B5",
    "TC Letter of Support PDF.pdf": "039BBE69-D2D5-4F57-8D38-36D9FF4A64DC",
    "TCAR CNS Chart 1.pdf": "BBB7B41D-B105-4EEF-A423-1FE6388F0C05",
    "TCC Agenda as of 11-7-22.pdf": "C6FF80B5-9CDC-45FB-A72C-62AF506A4E02",
    "TCC Agenda.pdf": "5A9CE01D-4B9E-498D-8C20-6F6529B384E2",
    "TChryssikos_email.verification.pdf": "742486A8-7EE3-41CA-918B-F9AFF7275FA5",
    "TCMC.pdf": "4332B981-BAE2-47C6-806B-261A051F00A2",
    "TCole-email verification.pdf": "74B71DD9-AA91-405F-B679-C67CF45C3701",
    "TCR 27_17_Croci_CV_publi.pdf": "78E61730-5026-42FB-8BE9-9DDE28899BA6",
    "TCR IN MVD POSTER.pdf": "92A971A8-313C-41E6-90EB-A6235E1A6E16",
    "TCSOutcomes_CNSPosterFinal.pdf": "25AB7753-C933-4027-BE67-E74A53AB46A7",
    "TCU_Verification_Certificate_254799483.pdf": "4787F805-6055-4F5F-9312-2DBD66D6B637",
    "Team communication in the operating room.pdf": "508866B2-EBB0-42FD-B8C3-D468C5DB1B09",
    "Teboh Carlson - Letter of good standing for CNS.pdf": "F4B48A6B-FDED-46A1-AB72-D3EDD3ED9070",
    "tech 4 cerv inter graft.pdf": "A739EC7A-BB4F-414F-83B2-DC0028FD9EBB",
    "tech for ant cerv decom for radiculopathy.pdf": "4661B896-0E7A-4B01-9415-88CB511B15A4",
    "Ted Weber - DeansCertification Letter.pdf": "BB13D28A-832C-4511-BD67-6FF8AAFD8CF2",
    "Teddy Kim CV 2022.pdf": "1766D6E6-7115-4338-8248-859B970B9CC4",
    "Teddy Totimeh CV.pdf": "207B59E7-0310-417F-9CE9-9C6C80A1EA8F",
    "Teixeira Verification.pdf": "05B3BCDD-D372-41C4-BF01-EB54F9697FDF",
    "Tejas Sardar Letter of Good Standing 2022.pdf": "B11A9287-E24D-4BB2-B135-A58F56D3E493",
    "Telehealth Appointment Types.pdf": "771B526F-7B70-4039-9261-18BCECAD9058",
    "Telehealth Overview 032020.pdf": "1904E688-2FAA-4425-9FC0-38E8E95914AF",
    "Telehealth Stakeholder Letter to Senate - FINAL - 9.13.22.pdf": "3FC12E87-A282-4860-A992-73C4A7F5C053",
    "Telemedicine in Stroke Where have We Come so Far A Comprehensive Systematic Review of the Literature.pdf": "7EA01D2B-F55A-4498-AF11-624934DAE05B",
    "Telemedicine.pdf": "F35AD6A9-7D8E-4495-9653-F88A488E2C59",
    "Telestroke.pdf": "401C12F7-BB87-443A-8EFE-E17143CC8ACB",
    "Telfeian SNS.pdf": "58D50E25-5C7B-4BF1-9FFB-06BA72B77D42",
    "Temitope Oshinowo Letter of Good Standing.pdf": "F35F63E1-9445-4D39-BDBF-5D51A55F2BB9",
    "tempfile7073206100223538866.PDF": "3CEDBBA1-1042-4B0E-9028-010076196509",
    "Temporary Letter - Acceptance to Program.pdf": "495D5955-8237-485B-8A91-55B6A40076C3",
    "Temporary VER - Carminucci.pdf": "A25CC41E-13DA-4B28-965D-E265369C86FF",
    "TENTATIVE 2024 Essentails Agenda Updated.pdf": "7A782DED-19E1-4ABB-80E4-74266F061BB1",
    "Tenwek_HendersonJr_CV 002.pdf": "9E6AE2AD-34AE-4A31-BB3F-47E2F4D23F53",
    "Tenwek_HendersonJr_CV.pdf": "6018ED22-2EAC-447A-A2A1-5013A6BC0E09",
    "TEQ4M1UJ.pdf": "9BE0D4C2-4EEF-4D0C-B111-1BB01EE1F10E",
    "Terai Hiromi_Enrollment Verification.pdf": "0D35E8D4-80EA-4E8B-BAE5-0209A7040210",
    "Teshera Hull Resume 2020.pdf": "8F18982E-064D-48CB-B901-10018C402C24",
    "Teshera's Cover Letter CNS.pdf": "01742EA8-B154-40A7-8848-D5880EC94183",
    "Test 1.pdf": "EC6D5D0B-5EA2-4C78-82B2-586C30A42505",
    "Test Doc to Upload.pdf": "72D66CD3-36E4-43A7-A896-37A93B71B5B7",
    "TEST DOCUMENT.pdf": "2C564EED-8E59-4D4E-A2B3-05EF4565A197",
    "test predict outcome CSM.pdf": "1F5C0E4D-CD6F-4AF4-89AB-78647829B556",
    "TestSummaryReport (2).pdf": "23B716DC-53FF-470D-A608-CB4E933CEC6C",
    "Tethered spinal cord syndrome about 12 cases.pdf": "2C8B6B2F-0276-4ACB-91BB-8AC71B384375",
    "Tetz_Scott_EV09242014_2.pdf": "727ADF63-58F9-4E84-B5E3-AAE590F18E42",
    "TEXAS AM UNIVERSITY_enrollment_certificate 1.pdf": "B7939973-93F7-4FE6-9C6F-0D331D56884A",
    "TEXAS AM UNIVERSITY_enrollment_certificate.pdf": "63979C6D-88FB-4DC3-A43E-2492709AD1D9",
    "Texto Dean Letter UIC_Fernando Gomes.pdf": "BE9058BC-6E16-4A12-AB0D-E9A2EBA3E019",
    "TF Access Site.pdf": "9FDBBD3E-A4F5-4801-85C2-74BBDD308D41",
    "TGS Poster.pdf": "306F1034-55DE-49CE-8A6C-63AB8650D49F",
    "Thai Neurosurgery Board.pdf": "E0BBCF34-FA89-4096-95C2-4D71BC69CA51",
    "Thakkar Rut.pdf": "DD0A2790-197E-488D-A3DC-75602C198FD9",
    "Thalamic seeg CNS poster.pdf": "F89C5B2A-95C5-4AE7-8401-C537DE5D9018",
    "Thalamocortical_targeting_LGS_CNS2023.pdf": "F101557D-30B0-436C-8A2B-BADBEBD6B3B3",
    "Than_C2 Fractures_final.pdf": "47ECB999-891E-4AD2-A9D7-D83E317F9696",
    "Than_SCI Management_FINAL.pdf": "F49CA75E-987C-4016-8B89-869163669C37",
    "Thana Nemer.pdf": "46D160A3-4A15-421F-BB9E-9DFF12FFD6C8",
    "Thank You 2023 Course Supporters _ Flyer for Courses Landing Page _ 2023 Neurosurgical Emergencies Virtual Course.pdf": "12C73AA0-0539-49B5-9D6E-B83106308698",
    "Thank You 2023 Course Supporters Flyer _ 2023 CNS Data Science Models for Neurosurgeons Virtual Course.pdf": "562F063A-E714-4D5B-B72F-19F195587285",
    "Thank You 2024 Course Supporter Flyer _ 2024 CNS Data Science for Neurosurgeons Course.pdf": "48E950D1-662A-4553-A6CE-7C0F28DFBF22",
    "Thank You 2024 Course Supporters _ Flyer for Courses Landing Page _ 2024 CNS Essentials Course.pdf": "05F08A3B-1B6E-46B1-966D-D23406C385AC",
    "Thank You 2024 Course Supporters Flyer _ 2024 CNS Data Science for Neurosurgeons Course.pdf": "E0F402DB-DD52-46D0-A73A-2B86DFF91400",
    "Thank You 2024 Course Supporters Flyer _ 2024 CNS Neurosurgical Emergencies Virtual Course.pdf": "66CF4274-CE09-4201-92BE-10A62F3815F4",
    "Thankyouindapp.pdf": "7063072D-C676-4F87-9435-F35F78BFE6E0",
    "Thapliyal Mihika - Letter of Good Standing - for Society Memberships.pdf": "D23D005D-2138-4768-921E-C741B7B1623F",
    "Thatcher, Joshua (Letter of Good Standing)-1.pdf": "086B01B5-EF2C-4397-A823-235C1AB9B3C2",
    "THE AGA KHAN UNIVERSITY_ Ayesha_Waheed.pdf": "A19ACC5A-3691-4A09-B9D3-6BB264E1F412",
    "The Effect of an Interspinous Process Implant on Facet Loading During Extension.pdf": "2B77D61B-67EE-401D-A90D-A57CED0B61D5",
    "The Erroneous Infamy of Trans Femoral Access(TFA) abstarct number 42268.pdf": "AD68ECD0-8077-4ED7-A05A-F7FD67761442",
    "The Evolution of Extracranial-Intracranial Bypass Surgery Indication and Complication Rates.pdf": "2B69010B-D4A3-441E-B449-53E0ADD11BA5",
    "The First Hour - What Matters.pdf": "E433CC96-6AB4-4CF4-99C3-ACEBAD39A8DE",
    "The Impact of Insulin-Like Growth Factor Index and Biologically Effective Dose on Outcomes After Stereotactic Radiosurgery for Acromegaly_ Cohort Study.pdf": "D76B803E-C04E-4038-AD55-548B4BA4A69C",
    "The Impact of Prenatal MRIs on Neurosurgical Treatment Recommendations.pdf": "9E50A15A-FFD8-474C-BCFB-A49F091F5F1E",
    "The Korean Neurosurgical Society Certificate-Korean Version.pdf": "4149AAFC-588B-4FB0-A1D1-01DB1F5E2E80",
    "The Outcome of Endoscopic Surgical Management of Post-Infectious Hydrocephalus with Multiple Intraventricular Septations.pdf": "4006C116-2433-4BBE-86E4-26AE4EB55498",
    "The Outcomes of Traumatic Acute Subdural Hematoma in a Tertiary Center in Abuja.pdf": "95A5E595-3932-452D-B0B6-4CEEA26ABB8F",
    "The Positional Magnetic Resonance Imaging Changes in the Lumbar Spine Following Insertion of a Novel Interspinous Process Distraction Device.pdf": "22D3ED85-EF9F-411D-B0F5-30C97FF48920",
    "The role of anticoagulation for superior sagittal sinus thrombosis following craniotomy for resection of parasagittal parafalcine meningiomas.pdf": "3949805D-6B23-40F8-8EE0-6169625AEF9A",
    "The Role of RB1 Alteration and 4q12 Amplification in IDH-WT Glioblastoma  .pdf": "DD02DF72-BA31-42CC-AD7C-5D7AF191EA73",
    "The Role of Stroke Prophylaxis in Traumatic Vertebral Artery Injury_ A Literature-Based Cohort Studyplate (1).pdf": "273B3BC8-5D38-4CCD-B1F6-052D5471422E",
    "The Rose.pdf": "C4BB42D6-A4B2-43F4-861E-03E2587D2DE5",
    "The Socioeconomic and Racial Determinants of Severe Traumatic Brain Injury Outcomes.pdf": "C8981DF0-E962-4C85-8F6D-EB6D6F918BF5",
    "The transcripts00.pdf": "B8F78FC5-B6D7-424E-B95D-289EBCD23B3A",
    "The Treatment Mechanism of an Interspinous Process Implant for Lumbar Neurogenic Intermittent Claudication.pdf": "6E9A7FD9-0720-4E0B-BD5E-F9FA23E786D8",
    "The Use of an Interspinous Implant in Conjunction With a Graded Facetectomy Procedure.pdf": "BDB0ED23-6C02-49A1-8807-88D291EA795A",
    "The Westin OHare Airport Shuttle.pdf": "68C65921-74D9-4657-82B4-F0B1E97952E0",
    "The_Acute_Cardiopulmonary_Management_of_Patients.11.pdf": "2F6D97A7-1217-4EDB-878E-5D8D0B962427",
    "The_Diagnosis_and_Management_of_Traumatic.14.pdf": "FA15A5FD-E0FB-400F-9917-ED461E4FA0A3",
    "Theo_Committment_signed.pdf": "7721749E-802D-44E0-BD5E-4D27740121FF",
    "Theodore, Nicholas.pdf": "6B53DD95-861B-4126-9E46-4E794F9DC237",
    "Theophilus Barasa ID.pdf": "2D5268EB-EEEA-42C0-B009-5C280874DF10",
    "thesis_cover_JTS.pdf": "4F1BA312-42C9-4A06-B4BF-85F087326BF6",
    "thesis_manuscript_JTS.pdf": "BE8E2D5A-3B83-46E4-ABA6-A2C1F0B20B1A",
    "ThetaBurst_ASSFN_poster_v2.pdf": "35050DCD-863A-4DB5-B015-06532A520CA4",
    "Thien Nguyen's Medical student confirmation.pdf": "CF85D3B1-97C9-4EDD-B8F7-45C8EA3CF87E",
    "Thijs_verification.pdf": "CB4E90A9-23DD-4FD6-8FD0-1475465A20C5",
    "Thilan Mackenzie Tudor CV_VSAS.pdf": "76AE91F7-2DB6-443F-8FB6-19565C0023D4",
    "Thilan Tudor LOR.pdf": "FC32DBAA-227A-4016-BC6D-6307CDBD21C2",
    "Thinkfirst prgrm.PDF": "C3CB7975-C10E-4292-8518-B96E668BB68A",
    "ThirtydayAdmissionModel.pdf": "2DD1CA2E-FCD7-4941-BD04-241487B6D517",
    "ThirtydayNeurosurgicalReAdmissionModel.pdf": "745F1941-E905-4F97-AE2A-3AA1D329445F",
    "This is a test.pdf": "D82D65F0-7774-412B-980D-249A2DD51EB0",
    "This is Neuro CNS 2019 Poster.pdf": "6CC7B69A-705D-4CC1-902B-F54A455F6B73",
    "This is not brain surgery CNS Final.pdf": "D1E96FD6-A818-4827-BDAF-2D0FD0C5BA2E",
    "This is to certity that the above named person has been serving as a Clinical Fellow6404.pdf": "C6AEBBB9-E645-48C1-855E-3446AE9FCBDE",
    "This letter will be added to the application since the deadline is in less than 24 hours.pdf": "C5211D5F-24BA-468E-B8FE-E875E99ADD01",
    "Thomas CV 2023 reduced.pdf": "946B6351-D4EA-4E1A-BA0C-0A729F64A21C",
    "Thomas Hines-Verification.pdf": "7A3AE799-C120-45D5-8F73-9006EBB098A3",
    "Thomas Larrew LOR CNS Guidelines.pdf": "85333249-E92A-4E13-8F09-2AFF51336F7D",
    "Thomas Larrew-RES_VERIF.pdf": "A74A0F87-9E85-4963-B5F1-B9001C1401C8",
    "Thomas Nilles-Melcheft.pdf": "E3CA6D7D-B4C4-49A9-B9EC-B790379363CF",
    "Thomas Noh-2ndFellowsihp.pdf": "E94EE484-9B5D-487F-9A2F-E0B04EBD7546",
    "Thomas Oxley-RES_VERIF.pdf": "421D4592-7D70-4BBC-9EF5-E5BEB133BA38",
    "Thomas Russell-FellowVerif.pdf": "0BCC08B2-07A9-46B5-A9FA-B385F7502421",
    "thomas society neurological surgeons.pdf": "A831BF35-F9D2-4163-AEA6-C52B167FA431",
    "Thomas Wilson.pdf": "5F360488-CAF7-4D8F-937C-9CCFF235172F",
    "Thomas, Evan MD PHD program enrollment verif 10 13.pdf": "B609C874-6019-4FBE-B8B1-96CE8913D369",
    "Thomas_GoodStanding.pdf": "0310C490-FB88-4C85-8AEE-D9CC07C60DE3",
    "ThomasHines - Verification.pdf": "A6E14071-7E9D-4F30-A683-4B0A303A7F76",
    "ThomasNoh-Fellowship2023_VERIF.pdf": "18A511C2-20C8-4C2A-BDBE-B9AC9B04CFC3",
    "Thompson Dylan LoGS 1.9.24.pdf": "E944DD86-2D5A-415F-9209-718F02C98F05",
    "Thompson Eric_AANS_Friedman LOS_20200519.pdf": "0DBEDCC0-07AA-465F-80CD-730355C8B221",
    "Thompson Eric_AANS_Sampson LOS_20200518.pdf": "2FA40CE0-FCBE-4FB1-A72A-4ED59DB1015A",
    "Thompson Kamala -Letter of Good Standing.pdf": "2111CD8E-4007-4BDD-B956-2274E15E863F",
    "Thoracic Microendoscopic Discectomy- A Human Cadaver Study.pdf": "2CDF010D-EE36-4298-B8FA-7E1B771D2507",
    "Thoracolumbar Trends CNS 2019 Poster.pdf": "5AE996E4-49F7-4991-BC0A-FBBB845027FE",
    "thoracolumbar_trauma_ch._1_intromethods.pdf": "31D019E1-562A-4274-A2C9-02F38487208C",
    "thoracolumbar_trauma_ch._10_timing_0.pdf": "1CC83A7B-F1B2-4851-81F3-64E1CA179C03",
    "thoracolumbar_trauma_ch._11_surg_approach_0.pdf": "2EC80D7B-2014-488D-A77F-37956351F170",
    "thoracolumbar_trauma_ch._12_novel_strategies_0.pdf": "8A212579-0608-4C01-9EF6-62042C595DC0",
    "thoracolumbar_trauma_ch._2_inj_class_0.pdf": "2D68F1A0-D139-4F29-9632-5FB103BCB770",
    "thoracolumbar_trauma_ch._3_radiol_eval_0.pdf": "7EDE1FD3-7D85-4ADD-BB83-813E541D0F09",
    "thoracolumbar_trauma_ch._4_neuro_assess.pdf": "EB6FDC10-91EA-49E4-9753-1908922810D8",
    "thoracolumbar_trauma_ch._5_pharm_trmt_0.pdf": "E658A963-F41C-4CEF-B289-298B44DB2424",
    "thoracolumbar_trauma_ch._6_hemodyn_mgmt_0.pdf": "38038615-6AC3-4777-8712-43C3D7635789",
    "thoracolumbar_trauma_ch._7_thromboembolism_0.pdf": "ED2B6B7C-C86E-4845-81AC-9B571EF55A59",
    "thoracolumbar_trauma_ch._8_nonop_care_0.pdf": "D97DE1D2-DE64-4BF1-897A-99359457C2A9",
    "thoracolumbar_trauma_ch._9_op_vs_nonop_0.pdf": "AF1B2947-FF34-4568-8E9C-8E0772B48704",
    "Thorell SNS Biography(1).pdf": "655887C0-A7E2-4FA6-BF2B-DFDCC6AE1E88",
    "Thorell William SNS nomination 10-23-2020.pdf": "CC1BF824-1404-4EB8-A986-5CA6F98CFA7F",
    "Ti´tulo - SBC.pdf": "ED39E6BA-1D94-45A4-832D-FD78CE07D408",
    "Ti´tulo de Especialista - AMB.pdf": "1CDE1721-903B-4DB0-9883-84D8D805C229",
    "tiantan hospital.PDF": "77FFB4FA-D812-4401-A9D8-34A87CD78B99",
    "Tibbs, Phillip A.pdf": "8C62E359-3E7F-4B1F-BC37-342FD5904285",
    "Tibbs, Phillip.pdf": "4241B202-607B-413C-94A0-7872FDB60E61",
    "Ticketed Session Instructions 3.12.24.pdf": "D3D83D1B-58A6-4B31-9F30-891128408BC8",
    "Ticketed Sessions Instructions 2023.pdf": "BAE4BC1D-B5DA-4636-AF8B-D811125E3940",
    "Ticketed Sessions Instructions v2.pdf": "9A21511B-1737-4A6F-9373-65F340753CF0",
    "Tien ASSFN Poster 2022.pdf": "5E04CC46-A1BE-4C9B-9D83-2B2B16B89C65",
    "Tiffany Ejikeme_Letter of Good Standing.pdf": "441FB3A4-076D-4F25-93F2-1AE0AB8DEEE2",
    "Tiffany Reichert Resume.pdf": "BF106090-2643-4CBC-8A02-A85AA0984F43",
    "TIHANcurriculum2022-March.pdf": "B501A8A4-CE37-4FCA-B04B-8CA73D2CFFE8",
    "Tim Beutler CNS letter 2 11 2021.pdf": "4AA30203-C72F-4A47-824E-79A67BD34978",
    "Time commitment letter Zaazoue.pdf": "0358283D-6F85-4B38-A800-D470D84A5330",
    "Time Commitment Letter_Elsamadicy.pdf": "F6CDBEFD-28B3-4998-88EB-E2D22144ECE8",
    "Time Table and Ladies Programme in Prague.PDF": "1853D8DC-AF87-42E5-83DD-A0D6358BA699",
    "Time-commitment.pdf": "7329FD44-5ECD-4EA5-8145-1F78EA03BBD1",
    "Time_taken.pdf": "EEF22F76-2EEF-4FB7-835B-0E88511B4601",
    "TimedESPB_JP.pdf": "4F61BBB6-E7C6-419B-ADB8-8451BDFF4A2D",
    "Timeline Progression.pdf": "D49B2C47-B153-42A2-AFE1-73D6A2FB8448",
    "Timi Akinwunmi-Williams enrollment verification and letter of good standing.pdf": "B9BA626E-1A54-4E90-ABB2-7823160D9B49",
    "Timmons Sponsor Nomination Letter for Stippler.pdf": "50FE9309-DAD3-46BD-86B0-42B2E4ED9402",
    "Timothy Ung-RESVerif.pdf": "18B8C4D0-A90A-4343-9DB9-6E4B86890435",
    "TimothyKim.pdf": "B48F3F38-EA8C-4110-9DD8-87B96B1A5DB0",
    "TimothyUngCV_2023.05.30.pdf": "1D30242C-DC48-41F6-87A6-AE5E68FFEA04",
    "Tipping the Three Legged Stool_Seminar Summary and Recommendations Final.pdf": "B48644C1-3E97-4337-84DB-A7AD484B521E",
    "Tirado Verification.pdf": "F74DCC71-BE9B-4151-8295-60CCB76805F1",
    "Tirone Young Letter of Good Standing.pdf": "9B3BE98A-645B-4017-8B90-572EF347004F",
    "Tiruvadi Poster ASSFN.pdf": "2851E359-EF8E-4CDF-8AE5-79BC7D978B5E",
    "Titanium microsphere - CNS 2023 poster.pdf": "5802F004-8282-498A-808C-8B19A191D18D",
    "title_vii_pediatric_subspecialty_letter_final.pdf": "A10BE65E-6248-48A9-BF83-98A2CEA67D89",
    "title_vii_pediatric_subspecialty_letter_updated_sept._2018.pdf": "4B7401B3-8C3A-4D72-88D4-8AA74CD32572",
    "title_vii_pslrp_coalition_sign-on_february_2019.pdf": "FB42B7E9-CFA2-4B21-AADD-107786B22007",
    "Titsworth - VER.pdf": "D4F8F6E5-47A1-47DE-AD71-4A94F8DCC043",
    "Titular_SBN.pdf": "E432786B-B65E-42B9-ADAA-2B1240DE9505",
    "Titulo de especialista em neurocirurgia pela SBN - DR JOSE´ LOPES .pdf": "02A31406-6849-4F6F-AA50-57277854DEAE",
    "Título de especialista Frente.pdf": "9A0F0BEC-135B-4A17-8AF2-155C7BA9B7EF",
    "Titulo de Especialista.pdf": "349F8ECB-6698-40E3-BFB9-62C7F1EE692D",
    "titulo de neurocirujano.pdf": "6997DE7A-39E3-4599-8C42-B1732382F78A",
    "Título especialista pela SBN.pdf": "17653230-8239-435E-BF47-E06F6D59EA95",
    "Título NCx 002-EVERARDO GARCIA-ESTRADA.pdf": "B9070BBF-8BB4-4F57-B97A-7CE4E2CCE47C",
    "Titulo SBN.JPG.pdf": "900E2C28-C40A-4FB2-A43B-93CF8B10E9FC",
    "TITULO SBN.pdf": "8C3C203F-E93B-4EF7-9A04-3A87A04B1482",
    "titulo y consejo neurocirugia.pdf": "0199F0A9-12EE-4920-A7E0-7CB97B0544A1",
    "Titulo_especialidad_Neurocirugia_Roberto_segura_lopezES0005455614127281.pdf": "1C0C9F7B-B34A-4F3C-891A-2511B6FDC698",
    "TITULO_MEC_2.pdf": "A2E60063-3A03-463F-B317-0A1A66348768",
    "TITULO_SBN_2.pdf": "884C531E-8513-488A-B150-106A2DC13729",
    "TITULONEUROCIRUGIA.pdf": "6184798C-539C-440F-90BB-F76D31B870A2",
    "Titulos.pdf": "2A437A2A-CF01-4465-86B4-AED9469A1F94",
    "TL.pdf": "865A30C8-4491-4715-A065-48FB928C372F",
    "TLICS 4 manuscript- submitted for JANE 2020.pdf": "808FB991-09A7-4374-8AB4-BA8B0D9FEB42",
    "TLIF poster CNS 2019.pdf": "8560CD35-B401-484A-99A9-E03CFC1288DC",
    "TLIF UR.pdf": "5122D635-9E83-4368-A931-7D4EC6E4A5FC",
    "TN Incidental Findings - CNS Poster Final.pdf": "01C95F16-704B-40DF-9B92-4B1D6381F78A",
    "TN Poster.pdf": "B09D4476-3D7D-4191-A376-436DEDF5600E",
    "TN_CNS19 (1).pdf": "E521818F-C49E-4F7D-A903-20EBDF4AD717",
    "TND (1).pdf": "68ADC7F0-1586-4BA5-BC4D-D34493ABC4BC",
    "TNK TPA Poster.pdf": "2E486046-C26A-459B-B2BA-006EC3B2739C",
    "TNS member certificate .pdf": "2DB730AA-6197-42CC-9919-423E3BF23C11",
    "TNS membership certificate.pdf": "991C367B-9FAD-4980-937B-4CA018CC2D51",
    "To the Congress of Neurological Surgeons-1.pdf": "67856E21-E80C-49CE-B3E7-43AE13DACB64",
    "To who may concern.pdf": "BBEE2635-2CDD-4BBF-A4DB-3AF19D581141",
    "To whom it may concern (1).pdf": "82CA8A5C-A6A4-46FC-8F5E-5C817A59A3EF",
    "To Whom It May Concern. - Ahmed.pdf": "E35A6F9E-87B7-409E-BEAF-786972BC0149",
    "To whom.pdf": "DF9A41D3-251E-42A1-9364-E812A2DDB3AD",
    "Todd Maugans MD letter of reference for Mehul Mehra Summer Fellowship application.pdf": "210AC148-CB00-4DAB-9A4F-2DDCA81A4B96",
    "Together we are strong CNS Final.pdf": "E4565487-CA15-4C56-97D4-4518B5EA47B8",
    "Toll_B_2023_Lumbar fusion.pdf": "77F7C0DE-2A5B-4366-8CEC-3980A36ECC3F",
    "tom_price_hhs_confirmation_release_021017_003.pdf": "35BC4C71-5BBB-4B60-8D19-91B9B0C21821",
    "tom_price_hhs_release_11.29.16.pdf": "C4069C75-B19D-41C3-8207-1E14E7D001A9",
    "Tomei LoR CNS Guidelines Fellowship 2022.pdf": "DB791CDA-B297-4F70-A010-DA853B35864D",
    "Tomei LOR NCB.pdf": "7AD28F82-F5C8-4C0A-B7BB-465FE37B794F",
    "Tomei SNS LOR WRS Letterhead Signed.pdf": "E0B1EA9C-9509-4411-ACB9-B5DC5EECEFC0",
    "TompkinsCVfinal.pdf": "7BADD975-F7FC-4B1C-A2F2-710A4A0E813A",
    "Tomycz_LOR.pdf": "2BB176EA-84D0-4B22-8550-15557246961F",
    "TONES Latin America.pdf": "5419AD05-11AA-4BAA-8346-C2B17CE30B06",
    "Tonetti - Enrollment verification letter.pdf": "88626282-EB37-46AA-866F-3C84F7D5ED72",
    "Tong et al. J Perinatology. 2021.pdf": "F27E0171-7AB0-4698-B4BF-03A1BA0A8BC9",
    "TonmetryTable 1-CNS.pdf": "5FFD92AE-77D8-44E9-BD1B-687E50134139",
    "toolkit.pdf": "0EE1D1BB-6380-48E4-A552-86140B571A57",
    "Top Things to Do in Montery - COVID.pdf": "927C4CE7-0BE2-46E5-9A71-3CB2410EDB9B",
    "TOPS_poster.pdf": "41D4C70B-6D8C-4486-A863-F4D8314A79DC",
    "Tora M .pdf": "97328E7F-A1D7-4825-B7FF-2097600CA732",
    "Tora_Cranioplasty_CNS2019.pdf": "E4F2B24D-9B08-4B08-9A13-CD7255CA2CB5",
    "ToraMS - Letter of Good Standing.pdf": "BAC78F03-3A27-4508-A72B-158241DB1863",
    "Torin.Karsonovich-FellowVerif.pdf": "5C0B8098-3DDB-4416-94E5-B113C5119B7A",
    "Torres Gonzalez Luis_GS2.pdf": "54DA5CB9-7485-4FFD-BD8E-95032EEF9402",
    "torso intervention in shi 2002-2020.pdf": "4C49B83F-9D69-426C-AFA2-A04DB1D53311",
    "Tosi, Umberto LoE CSN-2.pdf": "92E58CD0-AD74-4E96-A301-DA19BF348802",
    "Total Cranial Vault Reconstruction for Severe Hydrocephalic Plagiocephaly Using Virtual Surgical Planning-final.pdf": "131F04DE-018F-4E80-8F97-ACA1C9017356",
    "Total Spinal Surgeons by Primary Specialty 2012-2017.pdf": "DFCAB6BA-BA68-49B6-96C6-73B02BECC2C1",
    "Townsend CV pdf.pdf": "EF302178-9591-4810-9759-CCEFB3F648C0",
    "Townsend CV.pdf": "D981286E-EAD9-432D-9CA9-5C0505375466",
    "tPA clinical policy.pdf": "5954C002-B4E7-46D3-8ADB-0649816ED747",
    "tpa_pbrat.pdf": "0F8D3A64-04BE-45E2-ABC9-2AD6FB7CF274",
    "TPFF poster.pdf": "CBF41997-9F67-4877-B016-0760F06A065E",
    "TPieters-verification.email.pdf": "884FADE2-1DC2-4191-B7D4-1B2B07C0AF97",
    "TPieters_CV.pdf": "B0AE72C5-1D74-49F2-99FF-01DA6E6963D0",
    "TPittman CV.pdf": "B0806380-87D9-4CC4-9B8A-7D2BB15287EE",
    "TPTCV2009_1.pdf": "36AF1DB1-87EA-4AB9-8C1F-F0704C4C2E97",
    "TR moyamoya CNS 2021.pdf": "D919F55C-C8D3-4612-B804-9F13D2B51648",
    "tr.pdf": "DE58E5C4-1C76-4A0F-8F93-48307AEDEF0A",
    "TRA_CNS_poster.pdf": "A5DD6517-C222-4A0B-9D1C-D35C4FD40A51",
    "TRA_TFA_CNS_2022.pdf": "83FA85FA-A5D9-4C27-92B4-81C1424CF044",
    "Trach after craniectomy CNS poster_rev1.pdf": "5F363CD5-E31C-4796-8F67-C76949D0EA50",
    "Tractography in BPD- Figure 1.pdf": "D1406B55-CF3F-4E47-B33B-7DF38F5E3DF3",
    "Tractography in BPD- Figure 2.pdf": "3D3C880F-DB73-4B60-BBFC-4BD656EF0ED0",
    "Training letter .pdf": "F0DC40A4-E488-4F52-8F2D-81910A38DBEE",
    "training programme.pdf": "16FC3FFD-3B6B-44A8-B1B7-56C40E9E40CD",
    "Training&GoodStandingLetter.AndrewRock.12.02.15.pdf": "F4ADCB40-98A5-4AD9-945B-6E96D9AAA278",
    "TrainingAnnualReport20140531265372021-01-21T10_14_21.pdf": "B889D38A-D528-4301-BFE2-A7A5129F05A3",
    "trainning verification letter(1).pdf": "92CEC337-A49C-461C-B54B-C7BF373A6A1C",
    "Trajectory localization poster 220419 (ASSFN).pdf": "657C5895-76DD-47CD-9121-85CE7E359A51",
    "Tran Certificate - resident chief.pdf": "B65B7031-F1B6-4EC3-91CA-C56491619042",
    "TranCV.pdf": "AA176754-7D73-4E9F-AE6F-24E18BDCFB07",
    "TrangAnnie_CV_Fellowship.pdf": "ABE0EAD4-51FA-498E-B769-83B371F61FDA",
    "TrangAnnie_Team_effectiveness_questionnaire-12.pdf": "00789FA6-7479-4279-A4B7-ED03B3F1D8D8",
    "Tranmer LOR - Neurosurgery Publications Resident Fellowship.pdf": "2F3F8868-68D3-444E-94DD-7480A3E59236",
    "transcipt and good standing.pdf": "C5DB77D9-F2BC-4A52-A5B1-B27463D901D9",
    "Transcirculation Approach in Endovascular Neurosurgery - A Multicenter Study.pdf": "0AD64DCB-43F0-4D07-8E3F-25FD8C3F3770",
    "transcortical mening CNS 2021.pdf": "7723E22E-993D-4770-B6E9-03D7BBE5863B",
    "Transcript Duke.pdf": "A9AF77BD-4B2B-43A7-AE85-5FB52ADE4422",
    "Transcript-Saavan Patel.pdf": "2D90761A-B680-4C80-999E-BEFBAFAC00D2",
    "TRANSCRIPT.pdf": "27EAF016-4A5D-404A-9A99-0E6D896C9E18",
    "Transcript_MEDJIMSONJIMENEZ.pdf": "FCD11B84-5B7D-4194-BBB8-67FB3990381F",
    "Transcript_MEGANPARKER.pdf": "ECA1A33D-077A-461D-A046-815A0D373712",
    "Transcript_RYANWANG.pdf": "45F5DB8F-B06A-473C-8598-DC0D73796D4E",
    "Transcript_SIGNED.pdf": "FC62F237-2A88-4183-A275-1D0855F93EA4",
    "Transcript_VICTORIAHORAK.pdf": "5005B306-253E-4D09-A190-E3F1027918E7",
    "Transfer out.pdf": "E5DB0614-E39D-485F-97C4-AE40046845F5",
    "Transforaminal Posterolateral Endoscopic Discectomy With or Without the Combination of a Low-Dose Chymopapain.pdf": "73AE2A3B-843C-4118-ADF5-68BDF574F56D",
    "Transfusion after Fusion Tables Table 1.pdf": "29B80CAF-A023-4A67-8741-0898E78F71E3",
    "Transfusion after Fusion Tables Table 2.pdf": "68BC023A-7204-4464-9CBC-7B50AE355FC4",
    "Transfusion after Fusion Tables Table 3.pdf": "AEFDC78B-AC37-4846-A960-4F6509B5FC74",
    "Transfusion after Fusion Tables Table 4.pdf": "0CD82F36-1B63-46AB-B823-8FEBD79CC430",
    "Transfusion complication regression tables.pdf": "528FDC38-36BD-4E4D-B710-E4DE89A08734",
    "Transfusion complication tables.pdf": "16DE90E2-59ED-45D8-B982-C14C62C980DD",
    "Transition to Practice - D. Raper.pdf": "517E7455-50BD-449C-BCEA-717389A98F5B",
    "Translated Neurosurgery Diploma.pdf": "2DD9C297-DA19-4200-89E8-838BB15BEA9A",
    "Transorbital.pdf": "A6865EA0-AC4D-4EDB-9EE4-EA0EE3092B1D",
    "Transportation_of_Patients_With_Acute_Traumatic.7.pdf": "BE261031-AA56-41F0-806E-8FE365B30FC8",
    "Trauma Coalition FY24 House letter FINAL 1.pdf": "6C37846B-845B-4DFD-AF68-7669D199BC21",
    "Trauma Coalition FY24 House letter FINAL 3.22.23.pdf": "C091074E-0E65-44E5-8488-FAC69D357901",
    "Trauma Coalition Letter to Leadership_PAHPA_12-13-23.pdf": "D7DB8E02-0CB8-44FE-9EA7-08E88C18F360",
    "Trauma Coalition MZ Approps Outside Witness Testimony May 2020 .pdf": "D714E295-033E-45DC-8183-24CD431A733C",
    "Trauma Didactic Slides - Kitigawa.pdf": "11A61FB1-E1DC-4ACD-B5D3-6A074782B5BF",
    "Trauma Small Group Cases - Kitigawa.pdf": "ABF39CA0-AEB7-4C9D-9D31-B0E1C79CF3D1",
    "Trauma_12_Poster.pdf": "C9E976CC-9E64-4E2B-885E-CA421422784B",
    "trauma_statement_revised_7-01.pdf": "A3D8F958-511E-4D66-AC50-199651AFDDCF",
    "Trauma_time_poster.pdf": "CC6325A9-4205-4AFB-B182-0650144AF872",
    "TraumaFY2014FundingRequest41213.pdf": "AC826CF1-4254-44D2-AD8A-D9ACBCDEFD84",
    "Travel Information for RUNN Course Attendees.pdf": "D878787D-08D9-4C0A-8D4F-B5CB96010753",
    "Travel Policy.pdf": "22D0D16C-8C6C-40C4-B0A2-297207CD2197",
    "Travis Dumont Nomination Letter.pdf": "D53C9A79-22B6-46F2-B90E-715BF8E77C5F",
    "Travis Dumont Recommendation Letter 1.pdf": "7F0A58BE-2F33-446A-B84E-2538700C9C31",
    "Travis Dumont Recommendation Letter 2.pdf": "4069D66B-8784-41D3-A128-E8407D517C8B",
    "Travis Hill-FELLOWverif.pdf": "EE896F85-9268-483D-8482-A32646D57F83",
    "Travis Quinoa Letter of Good Standing[2].pdf": "67DB4F33-A858-49E4-92A3-9A01B53CD8A5",
    "Treatment of extracranial ICA with PED_HONOLULU .pdf": "2E28B32C-EEFD-4FEA-8003-0FA7295F76CA",
    "Treatment_of_Subaxial_Cervical_Spinal_Injuries.20.pdf": "2D97B974-43BD-491C-80D8-018FFCBCD11D",
    "trend s in meningioma management.pdf": "1F80E3BE-3EEB-48EC-B769-1F7637B8194D",
    "trends.pdf": "C2ABE0E6-B627-4395-A32F-F926A0A84DB7",
    "Trends_aSAH.pdf": "BB9C0EB2-FA96-4159-8CFE-02CD3C9AEC61",
    "Trevathan_MotorThalamusDBS_ASFFN_Poster.pdf": "8FFBD212-9914-44BC-801A-C92F7144116E",
    "Trial Format.pdf": "B094AA3E-414C-4464-B529-DED0B643DE33",
    "TRICKS MRI.pdf": "FE4BFD51-5721-41CA-8E72-D24F1A2692FD",
    "TriebwasserGoodStanding.pdf": "259BCCDE-2D83-4FED-82B7-473739F55C25",
    "Trieu-Thi-Thanh-Hang-CV.pdf": "5FC3B66A-8549-4BDF-833D-D79C31EF7B5A",
    "Triple Injection Poster - CNS 2022.pdf": "558557D7-7AA4-4D9E-841F-95786754D804",
    "TRSRPT ADAM LEVY.pdf": "2E0762BD-EC3C-408A-B088-BDC68EDD6A80",
    "TSA ICH.pdf": "6ED01DDE-58BB-49BE-BB31-F3C0D672AC1C",
    "tSAH Figure 1-Table 1-3.pdf": "74725950-B021-4879-B744-9D08CA40FA4A",
    "Tsai CNS Letter - Signed.pdf": "8B4570DF-D5EE-4802-AEBF-EAEC321360A3",
    "Tsehay.pdf": "EB0A1997-B0F2-436F-BB66-9BACEC575218",
    "Tshimbombu Tshibambe - Letter of Good Standing 2021.pdf": "904D4CBB-ED88-4E0F-9845-87D834A50610",
    "TSI delay poster.pdf": "340556FA-F438-44D4-B78E-1692752AE11A",
    "Tsiang letter request.pdf": "02D07526-1448-48FD-82AA-080CE3250562",
    "TSingh. RESverif.pdf": "496A505A-1583-41B3-90B9-9280FCF4C919",
    "TST CV 2023.pdf": "F99B0C80-E18C-4AEF-9350-57EB9577623E",
    "TSursal.emailverification.pdf": "6451BA9E-7E08-4861-A4F2-AAC2CB0B1291",
    "TTR.pdf": "01D7A459-407C-4F56-A52E-4BD2A2C4562C",
    "Tu.pdf": "8A4E58BB-1E3E-4192-B052-F71FAAB56002",
    "tu_enrollcert_online_610006741_202210_20220103_084509 (1).pdf": "DB3B6F98-5D08-4E09-9DE8-F003CDEAA7D9",
    "Tua.pptx.pdf": "1410956F-C739-4C8C-A5D5-8162A8B3E153",
    "Tuchman_CV.pdf": "D18CFBA6-429F-4F65-9AA3-42E97674BB1E",
    "Tudor Thilan Ltr. for CNS.pdf": "98C3BC50-BA7F-47A2-8C0C-EF6447216122",
    "Tudor_LOR_CNS.pdf": "4CB8F396-9E83-432D-91F8-339A4C2C5883",
    "Tufts Acceptance Letter.pdf": "D09FE28F-15DC-41A7-9FA7-F675692BAA22",
    "Tufts LOGS.pdf": "E7449825-D24F-454C-AF88-4B57E1E49EB8",
    "Tulane Enrollment Verification .pdf": "8E3F1A45-C54E-4010-97D0-E6E4CCBB3B97",
    "Tuleasca_CV.pdf": "E534A129-3E0E-41FE-AF18-9D90090CF0FE",
    "Tuleasca_manuscript_1500.pdf": "DF285CD0-CE95-4836-803B-9B28A3209874",
    "Tuleasca_SwissSociety__New_member2020.pdf": "9E3545EB-2F1C-4FD7-8A2B-1218C4A74F76",
    "Tumor Complications Course Agenda - Final.pdf": "40F3A30D-1879-493C-BB36-06C7009B5590",
    "Tumor Complications Course Initial Agenda For Web.pdf": "D7667599-8956-4A32-BCF4-DE02D52188ED",
    "Tumor Complications Course_postcard_proof.pdf": "B287AEC4-6260-4317-8BBE-5904CAC12F77",
    "Tumor Didactic Slides - Patel.pdf": "EAEED9F5-6626-440F-8191-77B9094652A8",
    "Tumor Neurosurgery - Private - G. Barkhoudarian.pdf": "3499E034-D560-48B0-BB7D-980E2A803146",
    "Tumor Section - J. Sheehan.pdf": "FDD927E7-8F4C-4F1F-892D-558C2C8FBF04",
    "Tumor Section Figure 1.pdf": "57E39362-1DBA-4353-814C-E157E249EF8B",
    "Tumor Section Figure 2.pdf": "C6CA8935-4B6D-499A-B48C-02840DD60C45",
    "Tumor Section Figure 3.pdf": "B80235E1-5184-466D-9396-DA5655CF02E1",
    "Tumor Section Program at-a-glance 2.8.23.img.pdf": "3F1183C5-4B43-40A3-B16E-813CAB0C9645",
    "Tumor Small Group Cases - Das.pdf": "244F27A5-0164-4D5B-B957-F797CFE63466",
    "Tumor Track Thank You 2024 Course Supporters _ Flyer for Courses Landing Page _ 2024 CNS Complications Course.pdf": "EC919ABE-40B7-498D-B772-45AD0C668ABB",
    "tumor_complications_course_agenda_08_01_19.pdf": "55685F26-241B-422E-868E-267269C329D8",
    "Tumor_Complications_Course_postcard_proof.pdf": "58DC1127-9B2D-4EEF-A47A-130486515062",
    "Tumor_Muther.pdf": "F1197279-E775-4885-995E-C43B4EC223E7",
    "TumorSM_Survey_CNS_Poster.pdf": "6B8BD79E-0043-4FE9-9495-722A9DCA9772",
    "Tuohy_Kyle_Verification Letter.pdf": "F5FA9B64-4377-40A9-A86A-E28A1AB30A06",
    "Turner Alexander_ACS.pdf": "CE761983-588B-41D5-9B5F-803C9FE07FC5",
    "Turner Alexander_CNS Verification.pdf": "3BD71649-4096-407B-9A23-FF56BE3203E7",
    "TW1BE5UI.pdf": "FC1EBE25-0F3D-4BB9-B107-4CA2E9F03B69",
    "TW252SSJ.pdf": "ED67238A-CC88-4520-982D-86842CABC21D",
    "TWF0D74F.pdf": "EEB645A8-615F-4DEF-93EB-88186A9EC863",
    "TWIMC_20221122220011.pdf": "5024D86E-B54A-4956-82FF-1B2FE40EC5F9",
    "TWIMC_20240606061014.pdf": "78F0933D-C4D4-4501-A100-37587DB5F418",
    "Twiner_E_Resume.pdf": "32A7C1C3-A782-4CD3-8224-30517976ABD2",
    "TWV40MSL.pdf": "0E842901-9343-40C6-899F-146EFF7EF5A5",
    "TXAvAmicar poster.pdf": "251F731D-61D9-4DB2-956F-C38B66C90001",
    "TYee.FellowVerif.pdf": "2A38DEC2-F0DE-4980-A080-3CAA9C5746B6",
    "Tykwinski Verification.pdf": "636E2A64-3D19-436C-AC78-852152AEE93D",
    "Tyler Christian Steed MD PhD - Resume.pdf": "FE4A7F6E-A681-4E19-BB44-68B1175590CD",
    "Tyler Cole Cedars-Sinai Formatted CV - generated 2023-11-20.pdf": "6ED68706-28B9-41B8-B88E-E5D0A00D6DBD",
    "Tyler Cole enrollment verification.pdf": "C1DC94AE-7E67-406C-9577-F1E8F2EC25E0",
    "Tyler Cole-RESverif.pdf": "D007A411-7519-4980-AEA4-D7FE72F4BF07",
    "Tyler Lazaro-Fellowship.pdf": "AB7DB967-7A5D-4AA2-BA4F-0AE7197388CE",
    "Tyler Schmidt DO Verification of Training 4.6.21.pdf": "A83E0C44-8814-48B7-A03C-7E34DE1EE0F6",
    "Tyler Sparks-FellowshipVerif.pdf": "072E5C23-82D0-48F8-BC61-49C709C54D27",
    "TYLER'S  RESUME.pdf": "56347609-110F-4CC2-B35A-CCA8DC2D2AFE",
    "U Ottawa Neurosurgery Completion Certificate.pdf": "FC391C5F-050D-4BC2-AD3E-598A82FA7E28",
    "U.Boddeti_CNS_Poster_Presentation.pdf": "14BBA1C8-2DAA-44EE-9300-3043AC2F50BC",
    "U.Okakpu_GoodStanding_Letter_UPDATED.pdf": "20F50E9B-B665-4DE5-8FBE-403E72088FAF",
    "UA enrollment verification fall 2021 priya ramaiah.pdf": "013D34C6-921D-42D2-A5CF-CC5A0CE76077",
    "UA_ENRL_VER DaraFarhadi.pdf": "A9B21974-9DA8-4AFE-AB32-158429028C2C",
    "UA_ENRL_VER-Pooja Dhupati.pdf": "492C5C53-28E5-45F9-904E-E0C0662FD46C",
    "UA_ENRL_VER.pdf": "0C85A2C2-03B7-4CE9-B5CE-11A688B0AF21",
    "UAB Diplomas.pdf": "C5ACE524-9E63-4DAD-9CE9-D8143C27C6E3",
    "UAG_HISTACAD_3231890_59844254.PDF": "2C1F9255-F990-4953-B528-F2CE99CC6F19",
    "UC for CNS.pdf": "22D9727C-80C9-40CD-8FED-DA74788070B0",
    "UC San Diego.pdf": "DD8010CF-42DA-4862-B9E9-8202E9ED1237",
    "UCF COM Enrollment Verification - Ning.pdf": "A74761C6-ED02-4A72-B138-5BF8D595F278",
    "UCF_Admission_Letter-Zhener Zhang.pdf": "FE54DFC1-3C4F-4979-8B58-6E055D9D1B19",
    "UCF_Admission_Letter.pdf": "117E907C-5163-4245-859A-7D442FBF514C",
    "UCLA David Geffen School of Medicine-UCLA Medical Center - Rao Yong Hirt Ooi Garrett.pdf": "40E61F25-081B-4F36-846A-931699809DF5",
    "UCLA.pdf": "B6DEDDA4-CB7E-4562-B9E5-65E4E0A62762",
    "UCSD SOM verification of enrollment.pdf": "F84A8F12-353B-466C-BEBD-B866B16EB6DE",
    "UERM COE.pdf": "AD9683DC-F5FF-489F-95C2-CEBF6C784696",
    "UFHichOR.pdf": "F8666844-2B6F-44D9-9775-87F2027D1EFA",
    "UFHvteOR.pdf": "1E79CCD9-F0A0-46D3-AE7D-809CDF8D7BEC",
    "UHB Letter - Hassan Bin Ajmal.pdf": "D0B63F3D-8CEE-4F61-A79F-A7388FF1BB88",
    "UHC Response to Neurosurgery re Imaging Prior Auth Policy.pdf": "DD32724A-E4D8-4901-88BF-7DF529A5A362",
    "UIC acceptance letter.pdf": "A6DD139B-2A07-4FB4-95F4-47BD7F164D27",
    "UM Neurosurgery Residency Diploma.pdf": "BEBFA754-9C22-4B31-AE55-BE5C681BE451",
    "UM_CV_Dec.pdf": "88D11009-71AA-4446-9780-87E33931F2B4",
    "umansky cns.pdf": "50CF5AF5-C1A6-4659-A302-EEBCB9B2F9DA",
    "Umansky_D.pdf": "3EFF1330-7123-4632-AC87-66156D9C088E",
    "unanticipated_medical_bills.pdf": "CB080C7A-0049-46F8-852D-A98DB94FF13D",
    "Under the Knife Final.pdf": "0490CA60-1626-4D52-AF26-152F1ECE1E02",
    "Understand2018MedicarePayAdjs.pdf": "00A4D669-AA1E-4635-849C-D306AF7EF38A",
    "UNI ID.pdf": "FF1DF0C8-B4F4-402A-B62C-44B48F9C1BE3",
    "UNi letter-Mohannad Mallat.pdf": "A19C2BAF-8D74-45D5-9089-418FEF2255EB",
    "uni prog.pdf": "E60500CA-2747-40B2-9537-85F054EF0FFF",
    "United sign-on letter ED policy 06.16.2021.pdf": "9789C2E5-A6C7-487E-8E63-6496C1D17970",
    "Univ. Mauricio Guerrero.pdf": "6E65DF15-5D36-4175-B671-DFD1A125DD22",
    "Univariate Association Table 2 NSQIP.pdf": "9B362F0A-9281-4421-A220-623E6D9BAF6A",
    "Univariate LR Table 3 NSQIP.pdf": "3F7FC61C-FF5E-4F09-8AF7-C46325A1E504",
    "Universidad Autónoma 5.pdf": "AB44CF55-9FFD-4741-AD60-EFC513282134",
    "Universidad Autónoma.pdf": "EDF65E36-445A-4DCE-BA4E-4DB79D45F515",
    "Universitv of.pdf": "037613E3-087B-4977-B9B4-6A72447FAAF5",
    "University at Buffalo.pdf": "66EB02A8-784F-4047-BA50-05AC7F74772E",
    "UNIVERSITY CERTIFICATION.pdf": "10BDD59B-1ADC-4570-9993-E00C7F8BF43F",
    "University letter 1.pdf": "CAE5C372-76F6-483B-87B5-3092C30A28B4",
    "University letter.pdf": "A88E4D58-1103-40C5-A78B-1E0093E2901F",
    "University of Buffalo.pdf": "7F6AD295-B7E2-4045-9B5F-09DD1A397044",
    "University of Kansas.pdf": "1D2872C2-761D-4F22-9E24-EB76A9E84A0C",
    "University of Louisville School of Medicine - Rob Hruska MD.pdf": "11AA9887-8324-4282-A966-E5CC69DCFEAB",
    "University of Louisville.pdf": "CB124206-95BB-41A9-9996-3F1208296943",
    "UNIVERSITY OF MARYLAND BALTIMORE_enrollment_certificate 1.pdf": "A3C6C9D9-3470-4E9A-B442-27BC571B8BD1",
    "University of Maryland.pdf": "8C9FA74D-3DA0-4B29-8E88-2489D7E9944E",
    "University of Miami Miller School of Medicine Formal Acceptance Letter MD.pdf": "B2CE3CD8-78D0-4AA8-A63E-4931CFB3799A",
    "University of Missouri - Columbia.pdf": "269D390A-32A9-4BBB-B8D1-D5AAAC674313",
    "UNIVERSITY OF MISSOURI-COLUMBIA_enrollment_certificate 2.pdf": "A6C39284-68CD-4B2C-A52D-D183EA8D2D38",
    "UNIVERSITY OF MISSOURI-COLUMBIA_enrollment_certificate.pdf": "A99D9964-3C4D-4C56-B8CD-D3E73CB10CC3",
    "University of Rochester Medical Center.pdf": "EF74C058-CEAE-444E-A2D6-FB582E560411",
    "University of Rochester Residency Certificate.pdf": "131499F7-BF14-45D6-B952-20E5E244C6DA",
    "University of South Florida.pdf": "C91ABCE7-FA47-4E07-8F16-72C4695B960C",
    "University of Utah Health Care.pdf": "A9119222-E9DE-4870-89F0-5974C6375941",
    "Unoffical Transcript M1.pdf": "BD719A27-C66B-4DC0-869C-4881398080B9",
    "Unofficial Transcript .pdf": "A17086F2-E510-4183-B605-0FFCD7CD7708",
    "Unofficial Transcript _ ISMMS.pdf": "19DD04FF-EF5A-4B70-A8D5-0B5ED9CCEE5F",
    "unofficial transcript _071523.pdf": "DCE7E7A1-5FC0-43B8-B67E-07D4FF27C696",
    "unofficial transcript.pdf": "6C26C8CF-EBAF-441E-80E8-155E86A41E7A",
    "Untitled 1.pdf": "435F0C88-028B-4ABD-8FF3-C457DD5992FC",
    "Untitled 10.pdf": "4023363B-1181-41F4-830F-D4A267CD51C4",
    "Untitled.pdf": "296072B0-8A72-4B33-8C98-413B3020839A",
    "Untitled3.pdf": "62099B2D-08EB-47DA-AF12-3C3680E4BAB0",
    "UOD_ACAD_IDN.pdf": "F84C7532-B19D-4410-81FA-00BCC69E5BD8",
    "UofU 2022 Residency Diploma.pdf": "8820C917-5411-4163-8E85-5C72238D5937",
    "UoI.pdf": "F41694FD-9162-427A-BFB7-42FD2DF80C36",
    "Upadhyay_Abstract_4082.pdf": "2381845E-2013-4166-AE2D-FF8566A91F93",
    "Updated CNS.pdf": "B9997473-4CB3-424D-8D70-CF26EDEED918",
    "Updated CV 2022.pdf": "5D101355-0E7D-4E4F-A5F5-0521E89C0913",
    "updated CV October 2022Dr.Fayez.pdf": "629F9CC4-5A7C-4E94-B6D9-F43F4174E3EB",
    "Updated CV.3.1.20.pdf": "CA467EA4-3B8E-4DDE-88BB-9D39EC77672E",
    "Updated CV.7.1.231.pdf": "C83C0A69-2957-4FAB-B8E7-18706AD2FED6",
    "updated CV.pdf": "B9358FA1-674B-4839-A23E-098C847CC2D5",
    "Updated english CV.pdf": "6A3F1AAD-A4F2-44E0-835D-38B9B6D45BF7",
    "updated Louis Chinedu Onyiriuka 2022 CV.pdf": "7A9DAA1E-828C-402E-BF71-FF0FC6C00E6B",
    "Updated Piyanats CV 2022.pdf": "D1F634C6-FC6C-4E9C-832D-BF77E5D1215D",
    "Updated_2023 NSTP_Year 2 Application_Asuzu_David.pdf": "B691DA5C-7048-4832-851C-3D55B668A949",
    "updated_aans-cns_release_re_ruiz-bucshon_statement_on_idr_amendment_071719.pdf": "D5DECA75-D732-4DCF-94F7-AFF17DCE069C",
    "UPDATED_md_CNS_poster.pdf": "0DB266AB-5046-42DD-AC73-FA5C607A328F",
    "UpdatedCV - Copy.pdf": "546CCB3E-BA03-4D56-8DC6-2A36B055FF9F",
    "UpdatedResidentEJBurkhardtCV.pdf": "B1DA54AB-A12E-4440-A876-56D2EF7E6064",
    "UpdatedResume-edit.pdf": "4F50CF34-93A3-4E14-9E7F-167505A12219",
    "UpdatedResume-Neurosurgery.pdf": "25D2FF8F-1B42-43F1-97D3-4D249BFC7644",
    "Urbonas - AOBS certified.pdf": "A6934AE9-FE51-455C-9166-F60C274509D4",
    "Urgent need for neurological care in Ukraine_Lancet_Strelko.pdf": "8DC4AF9F-0454-41A6-8E80-4479BDBA6AA2",
    "Uri Hadelsberg CNS Application.pdf": "F928C839-A16F-464E-B9B6-2EC5367668B9",
    "Uribe Pacheco.pdf": "7F1AC731-9544-48D7-9106-B6A17FF47FE5",
    "Uribe-Pacheco.LOR-CNS Guideline Fellow.pdf": "CE498BE5-5B19-4127-BAFD-B3436137E3BE",
    "URM CNS Med Student Poster.pdf": "8F734FAB-116C-49C5-86D6-5F6CAA242DB2",
    "Urooj Imdad - COE OCT-22.pdf": "B1682A75-B3D8-443C-8627-F17AE692C58E",
    "Urreola Good Standing Letter.pdf": "8F02CE91-934E-44E4-AC7E-D0FF9859D67A",
    "USC Neurosurgery Residency Diploma-Michelle Wedemeyer.pdf": "0FD29C39-6403-4555-8629-616C5B049457",
    "USC Neurosurgery Residency flyer.pdf": "7D8C3927-59D9-4FD5-A235-E1DCD81BA92D",
    "USC SOM Offer Letter.pdf": "42648355-110D-43ED-8EE8-D95A0624EEA7",
    "Use of Minimally Invasive Surgical Techniques in the Management of Thoracolumbar Trauma.pdf": "3FA047D5-8FC0-4D8E-9BDA-FB07E1E73652",
    "User Information.pdf": "307608FF-CFCA-481C-AC71-F08E6F91DA84",
    "USF NS Resident List 1.pdf": "C3307A7E-1B89-4B43-BE56-F0DC4267C88F",
    "USF NS Resident List.pdf": "CAD5B3D5-5A5A-4E7D-9523-94365581D3CB",
    "usg.pdf": "4D4EB5D2-A59D-4566-9C27-778AD6259F53",
    "Using Provider Relief for EM Support Letter Final 121420.pdf": "407B205D-6621-46EA-8996-683B5AFC9FD0",
    "Using Supervised Machine Learning to Validate a Novel Scoring System for the Prediction of Disease Remission of Functional Adenomas Following Transsphenoidal Resection  .pdf": "C87E84B1-CB03-48E7-8C5A-FB64356B2CF3",
    "Usman Khan-FellowVerif.pdf": "A038BDE6-3788-4DAE-A83F-BD34E90394FB",
    "USPTO-Patent-US10207123-B2-Magnetic-Brain-Stim.pdf": "AB972923-8F80-45C8-B9E2-A0A182A4BE57",
    "USPTO-Patent-US10780264-B2-Electric-Brain-Stim.PDF": "82F6C6F5-EF3F-42BC-B23D-A2A0FD3C86F4",
    "UT Letter of Good Standing.pdf": "F82872AC-C22B-4675-B83E-22AC028DB936",
    "UTH School of Medicine - VerifLetter.MaxSkibber.pdf": "F8731487-C9F2-4B86-84BE-8FEDB207FC84",
    "UTHSCSA transcript.pdf": "267B7129-CD4D-4DE5-8963-75B5BB150216",
    "UTI - Table 1.pdf": "C72316B2-7BEB-4D5D-ABB0-38F21D2D5B0E",
    "UTI - Table 2.pdf": "46301F69-8E4E-457C-89B7-BF48419A0417",
    "UTI - Table 3.pdf": "8C88D21B-1E72-4821-B9AC-758FB758A128",
    "UTI - Table 4.pdf": "ECFC8B58-DDDB-4A78-A539-96F20DA75935",
    "UTI - Table 5.pdf": "FE564794-3A9B-4A6E-B68B-2381EE43A435",
    "UTSW Kailee Furtado Letter.pdf": "8CAF17C1-12C6-4DBB-A403-0B79CF2F032A",
    "UTSW Medical Student Letters.pdf": "4F75E425-58CF-4AF5-9355-27DC52ACC8AB",
    "Uzoukwu Cynthia LGS CNS 3-21-22.pdf": "C8D8D7EE-929A-468A-A680-DA7B96E3AD6C",
    "V. Sharma Enrollment Letter.pdf": "DA9E5E51-B0A3-47D6-99E0-86D8B0A7DE9B",
    "v2 MRIgLITT CNS Peds.pdf": "C07EA995-B258-489C-B0ED-AF58F21761D7",
    "VA 10091 Congress of Neurological Surgeons.pdf": "343161D3-CA4E-460B-88AE-55D050F7B1B1",
    "VA poster CNS.pdf": "5E558886-021A-442B-BE94-3A24FC7ABFF1",
    "VA vs VP shunts for NEC.pdf": "DFE534B9-0BDA-4F66-86AE-CF5E1A222F48",
    "Va_squez_Franly_CV_2022 1.pdf": "5CB8149D-6E7E-494F-A835-3781D759B47D",
    "Va_squez_Franly_LOR 1.pdf": "81B4D0EC-29E4-4AC7-9565-D400AB464C06",
    "Vaccine Distribution Sign On Letter FINAL.pdf": "F0AE7A2B-54FA-4B9C-B44E-6C3D582C0CD2",
    "Vadivelu[15cchmc]_curriculum_vitae .pdf": "696BA71C-56F0-4B18-9B12-8169009AF03B",
    "Vaibhavi_Shah_MedicalSchool_CV.pdf": "E8CF614A-02B6-455F-94E0-7397A29216D7",
    "Vakharia CV.pdf": "09FB60F7-22ED-4B72-AB62-0A92BFFB107A",
    "Vakharia Kunal CNS LOR - HVL.pdf": "C13D7F9C-8676-40E3-B49A-212D017A7355",
    "Valid Student ID_Olivier UWISHEMA_ Forbes Africa 30 Under 30.pdf": "A659466A-6891-4E8E-91F8-88D7DA828FDD",
    "Vallabh letter request.pdf": "25D3FBF5-EE2F-4331-BD7F-ACD3F53FA639",
    "value_of_membership_presentation_2019.pdf": "21805780-9B99-4EF8-BEEE-B105F8B3F18D",
    "Vamsi CV 1.20.pdf": "52F52E86-A34A-49E9-AC96-410C99E11097",
    "Vamsi LOR.pdf": "22BB5474-E87F-4888-8BFD-6D702F96EB24",
    "Vamsi NA CSNS LOR.pdf": "0097700A-622E-4F4D-98DD-3774F7E024C1",
    "Vamsi Reddy LOR.pdf": "9079DD5C-720D-4051-A674-5D2F93CE8CC4",
    "Van Hollen-Cassidy LETTER_FY22 MISSION ZERO FINAL SIGNED 6.17.21.pdf": "A9346E30-CECF-4A99-BA72-ECA552FFBF47",
    "van Loveren, Harry.pdf": "06EEC7C1-C852-43BD-93BA-C55FCCB7047C",
    "van Niftrik Christiaan AANS Award Full manuscript stroke  Editorial.pdf": "832021DB-1B77-4025-85D4-90EA8A555DCD",
    "Vanderbilt enrollment letter.pdf": "E0CB9757-0D9B-43F6-B0D7-6BFFA4C7DCD9",
    "Vanderbilt graduation certificate.pdf": "5AA817F3-9B08-44F9-9B9D-82D920BCCD7F",
    "Vanderbilt Neurosurgery Program.pdf": "9401FB2D-B979-41B8-9317-D551B7D7881F",
    "vanderbilt_transcript_24.pdf": "9B7A0175-93A2-4BF4-B775-19495DE9F5E7",
    "Vanguard 2.0 Agenda 4_25.pdf": "57ECA6A9-1E29-4DD5-B2A1-AC4FED05F1D0",
    "VanHorn - Status Enrollment Verification Letter.pdf": "1D13B186-0469-467C-B373-C1403A2B9DB5",
    "VANLEUVEN_Enrollment_18JUL23.pdf": "3B4BBA6A-8525-4232-9182-CC666A560CEA",
    "Vans Verification.pdf": "C13884DF-AB0F-4DE8-991F-CC924AF0F15F",
    "Varadarajan Vishnu - Enrollment Verification Letter.pdf": "3D9246D8-162C-4C1C-81AE-DE7A39CD084F",
    "VarelaS_MS2023_GS_Y3 80.pdf": "16CAE1F9-2A7B-4174-9BBB-24DCAF825EBB",
    "Varun Ashutosh Rao.pdf": "9278CA34-1CEE-4AFC-AFEB-05C915770060",
    "VarunSubramaniam-MD-Enrollment Verification April 2022.pdf": "DEDF4E18-EECC-4156-AD11-B76987B98332",
    "Vasani Verification.pdf": "28977641-1237-46CF-B2D8-72D8AFA24A04",
    "Vasavada CV .pdf": "7396CF3B-1ADE-4069-A661-8F9802517F22",
    "Vasco Sá Pinto - Curriculum Vitae Europass 2.0.pdf": "EA8D02A1-076D-4266-94D1-644F84AFBB92",
    "Vasco Sá Pinto - Curriculum Vitae Europass 3.0.pdf": "903A1199-156E-4E2B-A2B4-17D20EA5FE31",
    "Vascular Didactic Slides - Tjoumakaris.pdf": "D63C67AB-2860-4A20-8056-834AF323DCE1",
    "VasileiosKokkinosCNS2022DigitalPoster.pdf": "915C8966-3A0C-43DE-8269-AD7AB7638DB2",
    "Vasospasm 101.pdf": "AED2589A-4C4B-4B1B-919E-96074D469A63",
    "Vasospasm poster.pdf": "65FB6773-194B-4BEE-95A8-311FCF3E160F",
    "Vasquez Betsy_Letter of Enrollment_good standing.pdf": "0B69489D-BBBD-41E8-AC70-2CAE476DB7CB",
    "Vasudeva.pdf": "4142976A-E980-4787-90A3-660DE3764521",
    "Vates CV_updated9-2022SNS.pdf": "DFC380EC-EC4E-4E66-997F-D24B666F67DC",
    "VB Shunt_2.pdf": "9F4D9778-7515-424E-AF53-6C5307326D08",
    "vbm_2015_what_you_need_to_know_aans_cns.pdf": "2B5EB904-5283-444F-9F4D-B3702CAB368E",
    "vbm_2016_what_you_need_to_know_aans_cns.pdf": "F6BD9BA8-51B5-4F22-82EA-B68E04D1F8E8",
    "vbm_2016_what_you_need_to_know_aans_cns_0.pdf": "9E61B0DE-DD80-4F31-9DB8-8A6FF2263EC9",
    "VC Carlos Corte´s.pdf": "DFB2C722-F54D-4F57-9C72-1073C8F537E2",
    "VClarck-FellowVerif.pdf": "471C9315-100B-429E-B8DA-00DF66037C98",
    "Vecchi.pdf": "C3E340AB-C602-49C6-8E29-E13B0F672A16",
    "VEERAPANENI SRI LAXMI.pdf": "72B016BF-F771-4EB6-AE84-A666CC2F9478",
    "Vega CNS LI letter.pdf": "1D3209D5-D6F1-4E1F-8170-D7378FBE5630",
    "Vega Perez, Ruben_Verification .pdf": "A407ADA5-A3D6-4D97-9334-955FA95E6640",
    "vendor_guidelines.pdf": "6578D016-DA0F-4942-938A-D629383C4910",
    "Venita M. Simpson CV.pdf": "6F851D33-B3CD-4BC8-A61A-C608BDA8CB65",
    "Venography Poster- CNS _ENW.pdf": "E325958D-5E63-4E44-9491-72B8A42E9A88",
    "Ventriculoatrial Shunting (VAS) for idiopathic Normal Pressure Hydrocephalus (iNPH) Review of the clinical outcomes.pdf": "FCE9ADA8-23E9-47D0-9834-A682BC0629E3",
    "Venugopal_Sandya_CV-8-10-2011.pdf": "9F2CC6E4-E721-4F09-813F-5D55D185C5EE",
    "VER -  S. Hoang.pdf": "62867DDB-71D1-4AF1-A83C-FADF16662F14",
    "VER - A Martin.pdf": "41B1BDBD-7368-4E30-9C6B-F7FC4D386BF7",
    "VER - A. Almansouri.pdf": "B02ED903-F9FC-45EA-B2A5-43F086056B83",
    "VER - A. Carminucci.pdf": "D9EFE575-6F01-4502-9AA9-E361239DAE9D",
    "VER - A. Hammed.pdf": "47C17D5A-3AD8-49BA-A21C-679EEB1CBD70",
    "VER - A. Jack.pdf": "27243688-D014-41B1-8F2D-388687A648A6",
    "VER - A. Martin.pdf": "974758ED-4012-4A1C-9468-BE9B302ED061",
    "VER - A. Seas.pdf": "4A9626E0-5DA5-4380-9A91-DCD086045920",
    "VER - A. Sharma.pdf": "8F907049-9C6B-4C52-A09C-5FF2CE240A5C",
    "VER - A. Sonig.pdf": "E73E0307-8321-4B0A-B221-BBAF9ED2C813",
    "VER - Abecassis.pdf": "7CABC791-4B08-4BC0-AB95-CA8F030B4972",
    "VER - Akbari.pdf": "98A5A65E-5921-4B57-9070-46046BF25C33",
    "VER - Alex Michael.pdf": "ED93C3E5-147B-459B-9024-14D804CE8747",
    "VER - Anil Roy MD.pdf": "81704647-C66E-4970-8EB0-0D9146FF3B4F",
    "VER - Aoun.pdf": "A518ED35-EB42-47DC-9F41-B1B1F2FB16D8",
    "VER - Arko.pdf": "C6AE51C8-16B9-4E28-B56F-BDE362EBC0F2",
    "VER - Avery.pdf": "4750D4CA-38D9-4E7F-B5C0-6864C2235B69",
    "VER - B. Lane.pdf": "175D43E3-2FB8-43ED-B4FC-C0F69EF4B3BA",
    "VER - B. Smith.pdf": "6455D767-D300-47AE-AB85-E9C903027E05",
    "VER - B. Zussman.pdf": "849306D4-56CB-4360-83A1-5913552757B7",
    "VER - Ball.pdf": "6BDFC86E-A655-4B41-9379-9AAE492A7E17",
    "VER - Barnard.pdf": "1F3F359E-4CBB-4EF3-9780-25C7B5C3E4DD",
    "VER - Beutler.pdf": "7B18D135-A1E0-4896-8118-6596CB19C11B",
    "VER - Bina.pdf": "71B20D6B-A74B-414D-AA36-8179834CB304",
    "VER - Boghani.pdf": "963064B7-AF9D-46BC-A603-5BE8B03BA97C",
    "VER - Bohl.pdf": "54E23B1B-B515-4726-9736-E5B7919C08CE",
    "VER - Bonow.pdf": "0558387E-F94C-4E87-B928-2689C4B69836",
    "VER - Brenton Pennicooke.pdf": "5CB0C2CC-F80D-4AE6-B32A-430AC77ECB2D",
    "VER - Brock.pdf": "B78644B3-58A9-4F39-A101-16104A7771B6",
    "VER - Brunozzi.pdf": "54663B24-767D-4EBB-BC4E-1410BC0EEE02",
    "VER - Buchanan.pdf": "F8546748-A2FB-40D1-B411-FC7952CF150F",
    "VER - Buell.pdf": "4B9956AF-0D14-4B7F-A591-E752FFA2BE10",
    "VER - C. Elia.pdf": "4E80A3AC-2258-4F22-AECA-C30A11E398C0",
    "VER - Carius Estrada.pdf": "740B5B11-4E7C-48E0-BB5D-21F5C14A48FB",
    "VER - Carminucci.pdf": "F8BD546C-09EF-4C12-BC8B-DBE3FB36A96D",
    "VER - Chen Xu.pdf": "CE5B0270-8E8D-45A5-B77F-9C85D4EB1724",
    "VER - Choudhry.pdf": "64ECF8BA-CD55-4381-8C68-7CFFEDC9270B",
    "VER - Chow.pdf": "BC7523AB-5601-4702-8C4F-570D4EF4E254",
    "VER - Collins.pdf": "93091087-8589-49DE-862D-1C278E044FA1",
    "VER - Crutcher.pdf": "E40DD5AF-72EB-4927-A65F-68927D732412",
    "VER - Cuellar.pdf": "4836C3F8-9AB2-4C3A-86CA-9C360F9DFBCE",
    "VER - D. Samples.pdf": "B64DD1B1-7F30-4104-AC35-EF7699DEA7AB",
    "VER - Damian Arellano Contreras.pdf": "56D90EAC-5240-475C-AFE6-CA4789BF79DD",
    "VER - Darrow.pdf": "2216C3AD-9A85-4715-B13A-231914C81010",
    "VER - Davanzo.pdf": "C1D3192B-A255-467C-A483-D8F95FA3E720",
    "VER - Davis.pdf": "E54E2324-05B1-4FF2-A76B-434464328CD0",
    "VER - Decker.pdf": "3122BF70-1061-4C6E-A942-06C4393D91E3",
    "VER - Desai.pdf": "A2D7F1D6-ACC1-425B-9A65-3C3D0937F265",
    "VER - Dewan.pdf": "F739CC64-7E0B-45DD-B1B2-C0727142B4FA",
    "VER - Donnelly.pdf": "05C9AF75-4CA3-4490-98AB-D8ABFD3E9F81",
    "VER - Donoho.pdf": "00333897-946E-4DDC-B95E-D098214C53C7",
    "VER - Dupepe (maiden name Beeson).pdf": "C5D9E8C8-7A3D-4D34-B3A5-831C0A07F9F5",
    "VER - E. Wafula Wekesa.pdf": "3732C541-CD54-47FF-AB0B-6D185A33D73B",
    "VER - Edem.pdf": "6796EA63-2B85-4E49-BDC5-863A68F88542",
    "VER - Erwood.pdf": "3D3548E8-0247-45B0-ACD2-82891B3FA543",
    "VER - Farokhi.pdf": "D112B709-FCC1-4488-A6EF-6EE4D81A2D0D",
    "VER - Fredrickson.pdf": "3FCCF457-F100-47BA-8C67-EDC40BF04949",
    "VER - Frisoli.pdf": "05BDC9EF-F876-42D0-8380-77B76BA0AD7A",
    "VER - Gelfand.pdf": "42D6DC75-79BB-4144-B944-5EE4F7EB14A3",
    "VER - George Hanna.pdf": "22DFF8D7-77B6-4192-926F-AF63C83B73F4",
    "VER - Gianaris.pdf": "98872B7D-A7B6-47BE-BA22-6B26E13BCACA",
    "VER - Goldstein.pdf": "FF194FAF-5CE1-4C4D-8FDC-92F692D1F17D",
    "VER - Gordon.pdf": "C2925C67-4480-4683-9FC7-0C8946D22E07",
    "VER - Grewal.pdf": "0C29517B-FE86-4953-A285-412F0E0119C9",
    "VER - Gupta.pdf": "E41739E8-FD05-489E-89D5-304006499F4A",
    "VER - Haas.pdf": "D380D182-EBE8-4AED-BEB0-E48F12528F4D",
    "VER - Habboub.pdf": "4C68F1D3-30B4-4D8E-A9B6-BE4F573FD32F",
    "VER - Haldeman.pdf": "602F3F3B-F024-47E6-A0A5-7E70CECF165B",
    "VER - Hernandez.pdf": "83A52ED4-2C74-450E-BD47-5293616788C0",
    "VER - Herschman.pdf": "5D32B782-EC6B-471C-951C-11685196AE4C",
    "VER - Holland.pdf": "2A814B7A-BAFA-4E0F-BF4F-8270E3CA72ED",
    "VER - Hollon.pdf": "2951045D-3175-4683-8D79-FA0F035FB729",
    "VER - Huang Wright.pdf": "941AA944-37AF-4E47-8E5A-06AA3EB719F8",
    "VER - Huang.pdf": "6764F360-5705-4B71-AEBC-0F1BCE264CE6",
    "VER - Iyer.pdf": "F72A5163-1F25-4B44-800E-4350382F0912",
    "VER - J. Lim.pdf": "6859329B-5C8E-4AB2-BDAC-8E2A675A25AA",
    "VER - J. Nathan.pdf": "0214CDD5-C960-4DE9-BF1A-0CDC7ADD9550",
    "VER - J. Peterson.pdf": "00D18001-4A24-46D4-9B92-75C947AE0011",
    "VER - J. Toms.pdf": "C680D6B8-C7B8-4052-B72F-66D6226E3340",
    "VER - Janjua.pdf": "07126968-4604-45A5-82F5-87462D0CA2CC",
    "VER - Jilian Ploof.pdf": "FED09111-D78E-4F91-B023-19813E60FB57",
    "VER - Joseph.pdf": "9318B4CD-C5C0-4887-995C-F32BFFBC43D9",
    "VER - K. Hamilton.pdf": "0532580C-5C75-4E37-850C-8D68FD3B4983",
    "VER - K. Krause.pdf": "05627016-26ED-4DD0-AB5C-353B8E78FDBE",
    "VER - K. Mueller.pdf": "6C43CF19-5B56-4A57-8E0E-E05DF3CFFB23",
    "VER - K. Schmidt.pdf": "E5F4D695-4342-4F7C-A9E0-C25DA0171137",
    "VER - K. Walsh.pdf": "47045A72-3BC7-4D08-8E28-AC3B24E3D7B3",
    "VER - Kamath.pdf": "967A0B8A-3E98-4C23-84D4-9BDD95A3C742",
    "VER - Karsy.pdf": "975B48DD-E5AC-43DA-9FFC-C654618BC45C",
    "VER - Katzen.pdf": "89EA1891-5556-4011-8DCE-E75233D426DF",
    "VER - Kellogg.pdf": "24ECFC7B-4B56-43A5-A26A-A44356F10343",
    "VER - Kerr.pdf": "6F6B39F6-923A-4EAE-A809-D14FA5F05387",
    "VER - Knudson.pdf": "B496FC27-B991-4B2E-9D48-151D96A9A2D1",
    "VER - Komisarow.pdf": "E5FB042D-EBC5-472F-A4A9-C43E391A22DA",
    "VER - Kong.pdf": "5117C892-4327-4361-BDBB-F06CEA7AB0AB",
    "VER - Kosty.pdf": "D6A648DC-7710-4D68-BFFF-08EBB2259243",
    "VER - Krucoff.pdf": "9B85B76D-EBDC-4180-A9AB-6031EDAE0320",
    "VER - Kuzmik.pdf": "4E0BC04F-81E7-4230-A6F0-7D067CBB3753",
    "VER - Kyle Schmidt.pdf": "B105F72B-3CCD-411A-8AA4-BDC2937D4395",
    "VER - L. Arko.pdf": "5F66D206-2387-4188-8FFB-C9CC4CA18FCB",
    "VER - L. Close.pdf": "E0CC5359-E6FF-4127-BDD4-AF78D4F98F5F",
    "VER - L. Robinson.pdf": "EF053947-1FA1-4D0F-BB23-BF4FC059FDF4",
    "VER - Laila Mohammad.pdf": "A79296B4-E55F-4CBE-B10F-1D3B84BFFD38",
    "VER - letter.pdf": "A2E1B1AB-D709-4439-960A-C71271F45FB9",
    "VER - Link.pdf": "B233B0C4-35BA-4EE3-9C38-0B1059280960",
    "VER - M. Aref.pdf": "DE0FE94E-FB0B-455C-A0AF-9654A20F5EB9",
    "VER - M. Jack.pdf": "0D229DDF-E13A-4040-B99F-5C101AC9E3C1",
    "VER - M. Krucoff.pdf": "CC73FADA-509E-49BF-BE6E-E323B4447206",
    "VER - M. Mian.pdf": "F1DBB3BD-43AF-4257-BDA1-2A498291A751",
    "VER - M. Smith.pdf": "2E893B6E-2A9E-43A9-876A-9A5B2BC7700E",
    "VER - Magill.pdf": "58BFC218-78A5-4210-8EBC-3D44C48D79FC",
    "VER - Maldonado.pdf": "260DD4E7-18A1-47F4-9CDC-C08BF7BE91CD",
    "VER - Manoranjan.pdf": "950BB6C7-26C1-4E8E-9FD6-A554416A1708",
    "VER - Martinez-Sosa.pdf": "C5290A3D-2529-494C-8C4E-3544FA093878",
    "VER - Martirosyan.pdf": "97F74DB2-490D-402A-AC42-59D159C5FDC5",
    "VER - McClugage.pdf": "A592E521-C0D4-4822-A2C1-ABEEBFC3EADC",
    "VER - Menger.pdf": "D72B5840-B3A1-4755-A7EB-549B5DBCAB53",
    "VER - Michael Tso.pdf": "ADB2F43F-6BF8-44E1-AD86-6A4E182D6AE2",
    "VER - Mirza.pdf": "898DB2CC-8D51-4BEA-A87C-6992E2BDA826",
    "VER - Monaco.pdf": "DBD31AA3-42FB-42AC-B453-F7890EBECBF6",
    "VER - Mooney.pdf": "7E9C2925-E950-45FF-898D-2CEC283C779E",
    "VER - Moses.pdf": "A8C0184C-97B6-4BC7-AB7D-620FA73FEF79",
    "VER - N. Doan.pdf": "CDD58310-2DB4-458F-9160-56B5DD8453C5",
    "VER - Nathan Davis.pdf": "F888365A-D330-4387-8DDB-0F342558AC9C",
    "VER - Newman.pdf": "5F6CC78C-4108-4007-9745-DB977DB3FC2B",
    "VER - Niu.pdf": "C7984E50-F534-44A5-8C66-3487626D2306",
    "VER - Noh.pdf": "37D84950-24CF-41B6-AE81-E9E90F08D91C",
    "VER - Nwojo.pdf": "C58E6A06-B9C4-4C40-AD16-09D23F2A2C11",
    "VER - Nzokou.pdf": "56E433A8-72DB-4068-9C23-3D1AEA025263",
    "VER - Ogbuji.pdf": "878E7C15-F341-4C18-8FD6-FF1C8E1FEC49",
    "VER - Ohiorhenuan.pdf": "5EC710C4-A6FF-4DD9-959A-E1CBE06CCCDF",
    "VER - PACE.pdf": "30ED0CD8-6163-4A82-8489-BAE4AEEA911C",
    "VER - Paff.pdf": "E685CE65-E38E-4534-A6F6-C2F3FA5C17DD",
    "VER - Parikh.pdf": "C73C0605-A94E-446D-A168-5229667227B9",
    "VER - Pascal L..pdf": "2ECB21E8-26E2-443C-BBF9-F186A8FC3B84",
    "VER - Pascal.pdf": "23A2907F-48F2-4042-B8F6-65A2CC0EC366",
    "VER - Pavel.pdf": "C3022A1F-4E98-4CDB-A02A-714E87EAD24C",
    "VER - Pendleton.pdf": "6FA676D8-7592-47F5-866E-901D3EB32DB8",
    "VER - Penn.pdf": "E66EDF71-F4CA-41DA-BCBE-5032524437B3",
    "VER - Pennicooke.pdf": "D1236940-1410-495D-B47F-EA17577EF635",
    "VER - Pierson.pdf": "710728F4-7EDB-4F2B-88EB-D7E0F0FC3479",
    "VER - Pinckard-Dover.pdf": "C7E65F5C-2E6F-46BF-B8B5-65151A629115",
    "VER - Polster.pdf": "6F82A8DB-5FEB-4551-8392-F6AB0452E43C",
    "VER - Prashant.pdf": "30808B2D-5B24-4604-9273-0EB12C62C6B5",
    "VER - Puffer.pdf": "1A98D148-6B52-4F3C-8E5B-2C1DD9E62BE6",
    "VER - Quinn.pdf": "F4A59C1F-6930-43E9-A4F9-E4EBC618C9D4",
    "VER - Rahme.pdf": "F0AF90CA-3E4A-442D-BF7E-5808C7A8AD3E",
    "VER - Rapoport.pdf": "7F6CEC0B-DAFE-4EA3-AB95-29282FA51D5F",
    "VER - Raufail.pdf": "B109D001-286A-453D-913B-17631039B903",
    "VER - Reintjes.pdf": "D618A4A4-A4AB-45C8-AB73-77DBD1A61F08",
    "VER - Renfrow.pdf": "66E79DA9-CADE-42B0-91A5-D5EF932DC13B",
    "VER - Richter.pdf": "BABBD1B4-45B7-445F-9099-5141104B7E17",
    "VER - Robinson.pdf": "0E11F269-4143-4B60-BE0D-C2FAC567CC98",
    "VER - Root.pdf": "21303BE1-CF33-4C27-8E68-A01B293A5E82",
    "VER - Ruano.pdf": "6023D552-7FA4-4D8C-A0FC-065E98F13323",
    "VER - Rutkowski.pdf": "2A6F01A2-6501-41D0-A811-CBF48CCD3510",
    "VER - Ryu.pdf": "AA0B4180-3684-468E-8DF4-29D4A9BB1D21",
    "VER - S. Bick.pdf": "12FEF4E8-6B91-4409-B040-F4DB10B41D4A",
    "VER - S. Tenny.pdf": "4A40B37F-B7A7-4F7A-857F-418B787195B1",
    "VER - Sami.pdf": "5F524EBC-853B-4129-AD14-9594DCE6BA56",
    "VER - Samir Sur.pdf": "505C0050-6B89-4F5C-8164-B540707430C1",
    "VER - Santiago-Dieppa.pdf": "57188243-36AB-4C82-86CE-7D3C7E45ACC2",
    "VER - Savastano.pdf": "D8AF5372-39ED-4B1C-99A0-E4C9E2571386",
    "VER - Schmalz.pdf": "58817669-0C37-4E90-B12B-7D3788410C84",
    "VER - Schmitt.pdf": "DFC37E50-B1F2-4500-8428-AFC7CC62C293",
    "VER - Seward.pdf": "EB9F2D8C-E708-4851-8650-84649F7B93A1",
    "VER - Shank.pdf": "B6A37D38-8DEF-4E64-B1C1-BCDF106D8192",
    "VER - Sheehy.pdf": "07DE60D8-BD2B-40DA-90B3-A1EA39514625",
    "VER - Shepard.pdf": "F25FCB8F-B42B-410F-84D4-42482F1CFFCE",
    "VER - Smitherman.pdf": "DB52D347-811D-4900-BCC2-7A0E467386C8",
    "VER - Smruti Patel.pdf": "AB26E6FB-49D6-4FBB-BEAE-4824E862EFAD",
    "VER - Spears.pdf": "E885B9D5-4A54-4D0A-B4DA-D20128D030A6",
    "VER - Stani.pdf": "7B1C8973-C62D-4FD6-B494-223981274027",
    "VER - Steele.pdf": "0B667D8A-FD41-4EDE-8D8D-E4BDAA74E740",
    "VER - Steinberg.pdf": "6CF858AC-0CA5-47ED-95CC-CA39B4CDC485",
    "VER - Storey.pdf": "894A4F22-AD62-4EF7-ACCA-1EA172870C5A",
    "VER - Strickland.pdf": "2D3A5915-2E28-4871-BD38-D546EA0F2F7E",
    "VER - Stricsek.pdf": "8EF063AD-27D3-4B74-89E4-A2056AD2ED9C",
    "VER - T. Wang.pdf": "6EE828BA-33D1-438E-B31D-D1FFA8FA13B1",
    "VER - Tahir.pdf": "2683A39D-9DA3-4D2A-9BE3-38B122835D78",
    "VER - Tenny.pdf": "1AC8E936-DD65-4815-B45F-5E36A77B5DDD",
    "VER - Thakur.pdf": "D4584668-2D3A-4398-AE23-C01FB2325E3B",
    "VER - Tomasiewicz.pdf": "464067C3-30F8-4B0D-9ADD-1A6E0BB45040",
    "VER - Towner.pdf": "8059599E-AA8C-4C61-9CF4-35A360F8B8FE",
    "VER - Tran.pdf": "0AD3C15F-215A-428D-8E34-794988508DED",
    "VER - Tsvankin.pdf": "93643D0D-2A08-4EE8-B763-CD097B3C27D4",
    "VER - Tymchak.pdf": "24E8B862-3610-41C3-B481-E3D08BF7C927",
    "VER - Vakharia.pdf": "404F670F-C7A2-43FE-85D7-0B9276A5B83F",
    "VER - Vasudevan.pdf": "DC3BE628-5820-49C6-8518-BA8F87E08FAE",
    "VER - Vigneswaran.pdf": "51DE7AC6-D80A-4D23-8CAE-461F35855B20",
    "VER - Waits.pdf": "09CA4834-3DC8-42BF-8370-57B92851AF08",
    "VER - Wallace.pdf": "90F74EB9-805E-480B-950C-37EE8A4C9A67",
    "VER - Walters.pdf": "BE9F58A2-CF0A-4095-A207-E3A0F708CF6A",
    "VER - Wild.pdf": "E615C953-4659-468F-A897-77EEA881037E",
    "VER - Wilkinson.pdf": "EFEE4BE8-D0E8-4BDB-B53F-ABAC4048BDAC",
    "VER - Zakaria.pdf": "BA5AD979-9C98-4221-9D52-7235534ECA99",
    "VER - Zampella.pdf": "F3C4B772-ECB4-4630-8CB7-A6D283C725E5",
    "VER -Arocho-Quinones.pdf": "4E1E8A3B-D1CE-4D74-84D7-1C7847E2692D",
    "VER -DiGiorgio.pdf": "73E82539-CD4E-472F-8CFE-2B2B9773C085",
    "VER -Elizabeth J. Lee-Liptrap.pdf": "E6EC8E62-A9A3-4BBB-AA98-63007976FA76",
    "ver 20170126 CV Yamaguchi.pdf": "980FBD00-F6C2-436B-BFB6-64F7988A621F",
    "Ver 2021 New Res and Grads for U. of Michigan.pdf": "3C7A9D90-94CC-467D-9F03-0C99F42C7F6C",
    "VER A. Sivaganesan.msg.pdf": "BE6DCCAE-AF58-48EE-9106-3E1DFF5616D7",
    "VER Aljuboori.pdf": "275369F2-C45B-4E3C-A84B-9F3B0B8E258C",
    "VER Alvarez-Breckenridge.pdf": "44C8B883-4AE6-4222-A6E9-51271B9355EC",
    "VER Amin.pdf": "9969D56B-9114-4CE0-8BAA-97DDDB315671",
    "VER Anene-Maidoh.pdf": "5B405207-D5FF-4581-AE4C-A42275E670D0",
    "VER Arthur Wang.pdf": "9180ADF7-2654-4F91-A14C-B99824A25C8C",
    "VER B. Choi.pdf": "E97414A3-8319-4596-822A-21229738E506",
    "VER B. Snyder.pdf": "947A71E3-F859-4B6F-84B8-38A399B0F122",
    "VER Bhimireddy.pdf": "429DCBA3-7181-4EAE-9C39-5B7BCBFC9812",
    "VER Borghei-Razavi.pdf": "81A23FA1-F3E8-46FC-A084-D7683F862017",
    "VER Boucher.pdf": "F8C80852-C328-411C-9D74-6AB3D4B4B412",
    "VER Brooking.pdf": "1D8C12DF-F8B9-4758-A23D-641E8BADEE66",
    "VER Bullis.pdf": "AB325938-D2F9-4A93-A0F9-CD3A0277A913",
    "VER Busch.pdf": "F400D757-7C4D-4754-AA4B-47D7C2D2E881",
    "VER Buttrick.pdf": "246D65C3-23B2-490E-B5D4-51269B52E90C",
    "VER Chakravarthy.pdf": "939CB509-D921-42B4-B671-D49A8E8B3137",
    "VER Chivukula.pdf": "7547B0E7-CD9D-45AA-A1BC-F7E709A14FC1",
    "VER Christina Huang Wright.pdf": "3301DC9A-3F27-46DC-B906-F45BB456D15C",
    "VER Conte.pdf": "9CB6EF6C-892B-4E27-BC77-0A1B6DB4468F",
    "VER D'Amico.pdf": "6A05E4B5-D01D-481B-8CCF-3EC856D376F1",
    "VER D. Branch.pdf": "8D08586F-957C-4A3C-87B4-0290AE4112DA",
    "VER D. Ghinda.pdf": "F8908FCE-D29B-483B-B7BE-89B49CA4E9B7",
    "VER D. Taylor.pdf": "CC5BA80E-F73B-406B-8F71-E0F32EFCE68E",
    "VER Dillen.pdf": "ED78F92F-F056-435A-8BF8-5843A378468C",
    "VER Dornbos.pdf": "B02ECA05-38F6-4E18-91BC-05AC3EBFA64D",
    "VER Dupepe or maiden name Beeson.pdf": "6760657A-9716-4EF1-BEE4-7512F9309CCE",
    "VER E. Alford.pdf": "6F2FA70E-4008-4090-A1B2-703554A195D6",
    "VER Eddelman.pdf": "03C25DD6-EB0A-4BEF-A4A1-5F5264E59565",
    "VER El Ahmadieh.pdf": "EC63F589-EB00-4826-98B2-6BEEA1E8C69A",
    "VER El Chalouhi.pdf": "31F72E1C-478F-4BA1-9FB2-DE2EDB746CA8",
    "VER Folzenlogen.pdf": "492D59AA-ED5C-4E8C-B741-825E1D1B142B",
    "VER Fong.pdf": "103EAE66-BD91-45A3-8D45-8966F99EC759",
    "VER Frenkel.pdf": "D08874C1-907F-4F47-9285-8F3A2DDF430D",
    "VER Freyvert.pdf": "CE4572BF-8FD2-47BC-A51D-EDDAB7E943EC",
    "VER G. Kuzmik.pdf": "3C6F5D68-B299-47F2-A7C4-F094185F1C5D",
    "VER Gelsomino.pdf": "C48878EE-3DF8-420F-89BB-7C1F3E73CFF9",
    "VER Godzik.pdf": "CCDF7A39-0584-4D27-B06A-A6D31EBC147B",
    "VER Goldschmidt.pdf": "F87B1AB6-47A1-4DF0-8911-9C3D1819E1ED",
    "VER Grabowski.pdf": "D0294BB4-A39D-42F2-B6F4-F4B75C694619",
    "VER Hanna.pdf": "6A917957-A29B-4682-9F91-1F354B64434B",
    "VER Hills.pdf": "0238E854-866C-4D47-82B5-A43D659A5C29",
    "VER Hoelscher.pdf": "87580D96-3572-4C5D-B571-13FAE893B1BC",
    "VER Huff.pdf": "0374ABA7-5F0E-474E-9B37-3EAE2B9E50DF",
    "VER Hussain.pdf": "3E61097A-733E-4E4D-9E6D-F6A9026DA48B",
    "VER I. Eli.pdf": "9C249B11-2B8A-4E2E-B008-D084105DEA15",
    "VER J. Barry.pdf": "8F4DA69B-0E00-43E1-81DA-EE6CB3A24C0B",
    "VER J. Hosein.pdf": "D4E8B77B-A83F-46BC-AE61-ECD7781E2BDD",
    "VER J. Magarik.pdf": "6B57774A-9E88-4461-AF4F-893CE69FAFF2",
    "VER J. Riffle.pdf": "CFDA2FFC-AC12-4858-8637-78E89BB7064C",
    "VER J. Shah.pdf": "732FFAAA-F62F-4C46-9104-C43C7BCE506E",
    "VER James Wright.pdf": "8D0B1BD2-C306-424E-966A-35CF574E43F4",
    "VER Jason Hall.pdf": "511C5DAC-FAF9-4B60-AF91-750998256C0A",
    "VER Johnson.pdf": "013C09D5-4506-4BB2-82AA-CF3146DED3C3",
    "VER Juan Vicenty Padilla.pdf": "1E7E17FD-9E02-4BBE-95F1-82FD4C439799",
    "VER K. Kesavabhotla.pdf": "C0F47B3B-D4B9-4242-BB07-C374743B72BC",
    "VER K. Snyder.pdf": "E83F832F-6767-40F5-BDBC-52AFA0105510",
    "VER Kathryn Wagner.pdf": "216C37DC-6EF8-42EB-84D9-DA919322296C",
    "VER Kemp.pdf": "4AA6CB55-C697-49D3-BE1E-B218AC1FF75B",
    "VER Kerolus.pdf": "F3CFCF38-EA01-4105-94FD-A2A37012CF78",
    "VER Kobets.pdf": "EED37D15-F87E-4EEF-93C3-5197848923AB",
    "VER Koch.pdf": "306F2B3F-835C-462C-BFC4-343D41F01CD5",
    "VER Kochanski.pdf": "27D24C75-5A63-41F1-B9C3-ED4F53248166",
    "VER L. Mohammad.pdf": "25BD9F43-0EB9-4CBD-9C2D-49725BA23F9F",
    "VER L. Ross.pdf": "7668BE91-AFBA-4684-825B-7163A699432E",
    "VER Labib.pdf": "523B244F-11F4-483F-819B-8903CBCFEDE5",
    "VER Lamm.pdf": "0501FC1F-0702-42D9-A607-FA44E3FFB3EA",
    "VER Lasseigne.pdf": "FFE838CE-C1EB-4837-9F3F-6D73ACA81B85",
    "VER Lebovitz.pdf": "8363B542-415B-498E-BB57-BC1B483009E1",
    "VER Leschke.pdf": "73FA1FE9-1D81-41DB-A18C-D57B9E7EB1CC",
    "VER letter Fareed Jumah.pdf": "0B7892EC-2157-420E-A2D8-7F13F4619E73",
    "VER Luis A. P. Ruano.pdf": "A848E159-D9F5-494F-BDC1-C045F808D13A",
    "VER M. Kakareka.pdf": "068EB7B0-8330-4BD8-B784-03EA7AABD779",
    "VER M. Kerolus.pdf": "2F184EF1-D6DE-4FAE-8664-1A10F06AF0F7",
    "VER MacMahon.pdf": "1E122D75-199B-4501-A46A-F37E18EA7953",
    "VER Madhavan.pdf": "1DB611E7-95E1-4EAD-A247-CEC1D0226A5C",
    "VER Mayer.pdf": "CA86A24D-243E-41C8-8EF3-5BAB07E23986",
    "VER McNeill.pdf": "4C65D26C-2390-47CF-9FE6-7E51127E4458",
    "VER Meleis.pdf": "83F4C051-E097-4259-874C-A1DFB8FA1FC9",
    "VER Mickey Smith.pdf": "AC47348C-09CC-438F-90ED-3A37934F001E",
    "VER Milton.pdf": "A203D9BC-AC00-4F55-978C-DBDED5230ED3",
    "VER Mohammad.pdf": "1F40133A-304F-4B7B-8FEF-A02D6D2341DB",
    "VER Molenda.pdf": "E7634B1B-5B28-4386-B698-D31F9BF347E8",
    "VER Mushlin.pdf": "803EFDB5-0A58-4565-AA95-0C5E217E8371",
    "VER N. R. Khan.pdf": "0309C997-705F-46CD-9929-FC50FA9404FC",
    "VER N. Villelli.pdf": "15317FED-C455-46B5-BCC0-7269CCF8E953",
    "VER Naeem.pdf": "C9D70AA2-3392-4340-8524-58DEC3DB8C26",
    "VER Nagahama.pdf": "A28F9BF7-4B9A-4331-9897-BD9E6519B271",
    "VER Nathan Oh.pdf": "7E694EE6-BB97-43DE-A7A9-C11FE3A76352",
    "VER Oermann.pdf": "D661EAAD-A7E2-43AE-8B93-6FC699958170",
    "VER of end date extension Fareed Jumah.pdf": "F94AF23D-568F-48BF-9D67-B4EDB7CD4CEA",
    "VER Omofoye.pdf": "D9D46299-D2E9-4E34-B987-459549DEFA35",
    "VER Osburn.pdf": "85967958-CE8F-495E-ABC0-3CB27FD598F7",
    "VER Otvos.pdf": "A40D5160-89B6-4AAD-A779-22B0F2CB62BE",
    "VER P. Paullus.pdf": "73CD640B-C06E-4F63-A0F6-0BEA4355C2ED",
    "VER Palmer.pdf": "2483CE29-80B6-4D08-B643-25D9FA6BF7A1",
    "VER Prashant.pdf": "78527FA8-8746-48F5-8D65-00FE832B1533",
    "VER Pratt.pdf": "326286BC-AEB4-4968-ADC0-26D71B23B99F",
    "VER Quillin.pdf": "53A66C78-007F-4E1B-AD06-9DD84289C7F1",
    "VER R. Babu.pdf": "3939F305-26D7-4063-8C10-08F3128F5862",
    "VER R. Gorrepati.pdf": "7C40EDE9-E222-4514-BFAD-172DD8D4A63F",
    "VER R. North.pdf": "883260A5-307C-4864-B7C2-BD9E6647342A",
    "VER R. Price.pdf": "C3A74FCF-DCAC-4D83-84B5-594B336BE587",
    "VER R. Rammo.pdf": "322904DE-20C9-4863-8E23-E909D22E9AA3",
    "VER Rahimpour.pdf": "5D18C583-CE87-48A9-9581-AB2547FE0491",
    "VER Ravindra.pdf": "02AAFD80-2399-4DB5-9EB4-88ECE5372A6F",
    "VER Richardson.pdf": "C832C12A-41D9-40C4-BA37-BBF0366567D2",
    "VER S. Rahimpour.pdf": "105CFA47-9771-4FD3-8C1F-083C45ACDEF9",
    "VER Sachdeva.pdf": "71B67722-1F3E-4514-A7E0-D00E085FFAEE",
    "VER Salehi.pdf": "0CD9098D-2BC8-4AFF-894B-B83CBAB38A8A",
    "VER Sanusi.pdf": "2D98C248-FF15-47BD-B6B7-DC9E8105E244",
    "VER Schmidt.pdf": "CB0CDE8F-3A0C-4F2C-B9B1-E6A812216852",
    "VER Schunemann.pdf": "B7565166-29CC-4E84-854A-9E9270646616",
    "Ver Schwarz.pdf": "0F7A285F-55B0-4A07-92EE-EBA3BC09F58B",
    "VER Shank.pdf": "7456FA28-E285-40C9-86C4-970F7203A1DE",
    "VER Simpson.pdf": "BD273384-9D53-4BB1-8F86-AD12FDFECDEC",
    "VER Stone.pdf": "37997900-AA13-4FBD-BF7B-5214F2D6B2F4",
    "VER Sublett.pdf": "C4B569B6-70B3-4FD3-B4DA-778B053EBBB0",
    "VER Sweiss.pdf": "ACA72F93-1AAE-4969-9DEB-F596B03AE21A",
    "VER T. Jefferson.pdf": "217B6DFA-DB45-4EDF-8B3D-D206BFB6CA2D",
    "VER T. O'Connor.pdf": "C73553D8-871C-4765-9696-52B9470D725A",
    "VER T. Robinson.pdf": "F2CCD73A-2327-48F5-AEFD-0637E7401A34",
    "VER T. Williamson.pdf": "CED616B6-8950-45D6-A2E6-0A892FF2633A",
    "VER Theofanis.pdf": "093EBEBE-5DA0-4684-8AA7-C26785422EE9",
    "VER Thind.pdf": "8BD3043B-2143-40CC-A6A3-20D8D657D7F6",
    "VER Thomas.pdf": "D7DF341F-EB8B-43F9-B5D3-867B9992A752",
    "VER Thoms.pdf": "4273D0D7-C3D7-4A5B-8E82-A6580AAF7D2B",
    "VER U. of Southern Cal..pdf": "CB044121-1429-4465-85EA-4E7F60FC545A",
    "VER V Buch.pdf": "076F58DF-D1EB-46DA-9618-2B06C63FBC90",
    "VER V. Padilla.pdf": "273C4EF9-F035-4BB8-BCD9-19739CB40EAF",
    "VER V. Simpson.pdf": "B07B3046-7F24-478D-9D70-0DAD3E01FDE3",
    "VER Vance.pdf": "F395F66B-2D94-4641-A5B0-750A38973F68",
    "VER Vasenina.pdf": "7CA1F547-9CAB-4ECE-823A-AAFCA49DA366",
    "VER Vedantam.pdf": "58F59D05-294B-40D7-A2C1-874615EF38F5",
    "VER W. Ramey.pdf": "836D346A-3C6F-43DC-8EAB-DE7917B4DACF",
    "VER Wainwright.pdf": "A25F649C-9444-4A31-9443-7EE1142BBE7B",
    "VER Walker.pdf": "A612F41F-17D5-4C70-91E5-A8C08D7C1F40",
    "VER Wessell.pdf": "77BD708B-C3F3-4216-BE27-3BBA99C7431C",
    "VER Whiting.pdf": "A81AD826-36AD-42FD-9EFF-D122C643A264",
    "VER Woodroffe.pdf": "4158219D-D30E-4EFA-AE16-52383070F630",
    "VER Yang.pdf": "95EBE0F4-94B8-4D99-90ED-8C67CB2F49A9",
    "VER- Beer-Furlan.pdf": "4C23CD55-54E7-4663-9C33-760DC887AA41",
    "VER- J. Rasouli.pdf": "1AC54F4C-1732-4023-9228-E0B1F664391E",
    "VER-Ali Turkmani.PDF": "F8E06008-FC11-4BAD-AC0F-153458FFF40C",
    "VER-Almeida.pdf": "72B1F2B9-765D-4BAD-B09F-08EB51ABA3E9",
    "VER-Strong.pdf": "500FF858-64BE-433D-9B96-CF922E9FF017",
    "VER.pdf": "4AA475DE-58C9-4CDF-A593-3371EAFAEBF7",
    "Verfication Letter.pdf": "88D1EE59-6B4A-40DE-98A8-21616D6282A4",
    "Verication of Attendance and Enrollment-Leon Chen.pdf": "DDEF9197-E47F-481C-9504-D770B861ED8D",
    "verif_letter_defer.pdf": "60BEE641-3D6F-4639-9A0C-769B66702932",
    "verificaiton.pdf": "21436F10-E25B-46DA-81C7-95109B887269",
    "Verification - Enrollment in Goodstanding MD MergeTemplate.pdf": "D33C76A8-4F19-455C-AC39-067445302403",
    "Verification Certificate.pdf": "BA3D2074-78DC-4072-8E09-2D5B68AB8CDB",
    "Verification Contract Letter.pdf": "17E5323F-B982-48B5-B2A4-65D60F5327FD",
    "verification document-converted.pdf": "8DDD6479-BA1B-48F1-B19F-BBCDEC7EBF49",
    "Verification for Ian White MD.pdf": "EA1B9A9A-88D6-425E-985B-9A284A3ACD83",
    "Verification letter & CV SHYAM.pdf": "BBC15DD2-05AC-4750-A38B-E65B155CA6DD",
    "Verification Letter - Aarti J.pdf": "EB482794-2BF6-48B2-855C-77688FBB8B3F",
    "Verification Letter - Arunachalam.pdf": "B545F35B-6CFF-4CDC-A170-212B8596D18C",
    "Verification Letter - Jorge Roa MD.pdf": "DA7C2AAE-9B98-4DBF-9451-4FE5505893CB",
    "Verification Letter - NSI Membership.pdf": "C31D3E71-C709-41C3-BEC4-41EC46BF5D4C",
    "Verification Letter - Prof. Danil Adam.pdf": "609E075B-40CC-4983-848F-9242D28D3A8E",
    "Verification Letter - Reese J - 2018.07.31.pdf": "6E4C543F-EBF5-4F8D-9D53-4D83A0553D94",
    "Verification Letter - Sasidhar Karuparti 1.pdf": "E8C75281-2968-4BBC-9849-954EEADE3C26",
    "Verification Letter - Sasidhar Karuparti.pdf": "118F0AF4-A5B4-44CC-9A38-98191FFF1F48",
    "Verification letter .pdf": "8F19E940-0050-4B49-B424-355BFF39FE2B",
    "verification letter 001.pdf": "FBB2EA41-8A05-4708-A8F0-3ADB7D582FFD",
    "Verification Letter CNS 2023.pdf": "0016C576-9FE3-4568-8F46-5871252B7758",
    "Verification Letter CNS.pdf": "D369BF9C-9ACC-4FF1-B8ED-292F111F49AE",
    "verification letter for CNS.pdf": "5D9E51FF-1DD0-4F5E-B6BB-2D49A4061F15",
    "Verification Letter IACOANGELI ALESSIO.pdf": "2786FA15-C365-4825-AD18-74AEFF5F66FC",
    "Verification Letter Iracema Neurosurgery Residency.pdf": "45882C51-9BD2-440B-9E6F-F0D41C5203E8",
    "verification letter of residency.pdf": "D932E226-76C8-45DC-8511-985CC3E0D2CD",
    "Verification letter-Maria Tejada.pdf": "08B368C6-1E1E-4470-B940-7667865854CE",
    "verification letter.pdf": "B06A6D94-AC47-463E-A5AD-0353CF288D06",
    "verification letter_20170522194139.pdf": "587E5DEE-D15E-4A24-BB95-D6B941657824",
    "Verification Letter_Acharya.pdf": "DE8CDA8F-B561-46A4-AE7A-30583BD7A938",
    "Verification Letter_FarrellMolly[1].pdf": "87B5EC8B-C596-453F-947B-8896AD13F763",
    "Verification Letter_Georgios Mantziaris.pdf": "4C0E43F6-6D68-416D-8719-646EB5A7253A",
    "Verification Letter_M. Martini (1).pdf": "46DAF646-AE0A-4FF0-8952-3B26F15D1521",
    "Verification María Eduarda Montaño Prüss.pdf": "AB304754-6C4D-4B1B-80B0-07286F58FDF5",
    "Verification medical student in good standing with Dean Densmore signature.pdf": "C2687444-25B2-4A8A-8B64-6F01683D98BD",
    "Verification neuro letter.pdf": "0E698A73-F975-47D2-8B87-345EB2B58014",
    "Verification of AMC enrollment.pdf": "983A13D6-566A-4FB0-8804-9FEDAF3EF829",
    "Verification of attendance.pdf": "1B3E0A58-09AC-4C35-834E-8E4F569EC556",
    "verification of certification for Ellen Bielak.pdf": "D62B5A70-09BA-421E-8A1A-9450ADEE2CEB",
    "verification of current membership at SENSA.pdf": "11D58E2E-A256-473F-8928-064DBD7C8D70",
    "verification of employment dr. Selfy Oswari26062018144559.pdf": "89F5E4EF-F199-4E29-9CD5-2DD54668D368",
    "Verification of Enrollemnt.pdf": "B8ECC7B1-2D6F-48A2-BC6B-BC9EE49BD47A",
    "Verification of Enrollment Gregory Amy4.pdf": "6458E01A-8FAC-4B2F-9821-BD20F6D4D917",
    "Verification of Enrollment Takayanagi.pdf": "E19C2081-84FA-4E22-AA7B-1734C0AB65CD",
    "Verification of enrollment.pdf": "7BE4F203-5465-44A3-8C6C-142461CD953C",
    "Verification of Residency Training3236.pdf": "189EE016-D8E9-4185-972F-A3AE1D3360CB",
    "Verification of Residency.pdf": "CBC5FEC6-D877-422D-A98A-887AE0AFB57F",
    "Verification of student status for Alvaro Amorin.pdf": "DE1F981C-22FF-43DB-BC45-3DC4D7A346DC",
    "Verification of Training Program.pdf": "26D395AA-F7C5-4D92-9332-04BCA678B2D9",
    "Verification of Training.pdf": "A5034F47-C65A-4DD1-93CF-B2FE154A1369",
    "Verification page-public directory.pdf": "D3210294-C57C-42CA-A678-13E7947409EF",
    "Verification residency.pdf": "8205C25A-7D89-4CB6-A3F9-EFFEAD12A08A",
    "Verification Trainee Letter .pdf": "A7C51FC5-3D83-4995-B8F1-1527BDC6179B",
    "Verification-of-Training-Form.pdf": "FBAA5B7A-4C57-492D-98D9-42FE6B8CA24B",
    "verification.pdf": "66F511D4-33C5-4724-8477-00422FA1A712",
    "verification_01290203-3.pdf": "9B2A682D-27F8-486D-A2C7-EE18DCA7A21D",
    "Verification_Certificate_273559933.pdf": "4A26C8F9-0D30-4E2A-B801-3599A0A133BE",
    "Verification_Certificate_275867491.pdf": "A14F6093-99FB-4E5C-BED6-D79D94752FFB",
    "Verification_Feldstein Eric.pdf": "788E55E5-0C63-4F74-9F0D-1686BF395E41",
    "Verification_letter-1.pdf": "605CF7AC-E126-451A-A73D-14ED89365153",
    "Verification_WashU_Jul2023.pdf": "6016A389-A4BF-4ABC-BB36-EE3FB199D15C",
    "VerificationLetter-Garrett Thrash.pdf": "F03B5C5F-6876-41AE-AE1F-27229695E1A3",
    "VerificationLetter.pdf": "B4E929FE-D0AC-46DF-9C8A-5A98FB72779B",
    "verificationletter1.pdf": "2CED00A4-85FB-4659-8860-EFCA396A0635",
    "verifications - Abbas 1.pdf": "1B6E05C0-B9CF-45D3-8B74-037AFDFE36F7",
    "VerifMoradNasseri.pdf": "F84A259A-82CA-42DD-8801-83ABBC87EAA9",
    "VERLA.PDF": "AF6CA91A-0235-461F-A130-AD876789BF8C",
    "VERLA_CV_01.2023.pdf": "384F9E37-12E2-443C-A409-AAC03DF108C8",
    "version 2 CVi-chrismunozbendix-2020.pdf": "79807706-B5F5-4286-8ADB-7DF725042727",
    "Vertebral_Augmentation.pdf": "B443BE4E-8B52-4892-910D-41CBBB3D03B1",
    "Vertebrobasilar Insufficiency Table.pdf": "76BC99F1-1BA3-45CE-A178-09DEECB21840",
    "Vesper DBS Registry CNS 2022.pdf": "D431C45A-AB82-45AC-B48B-C773DA84040B",
    "Vesper DBS registry outcomes CNS 2021 #031987.pdf": "DC04B086-6EB6-405E-869D-D1FE638DA35F",
    "Vesper DBS Registry Outcomes OUS ASSFN 2022 eposter.pdf": "020CD0CE-BEF0-4525-9730-A37F48EB038B",
    "Vesper Directional DBS Registry CNS 2022.pdf": "F982910F-3443-4A0C-92AD-56A077854BB2",
    "Vesper Directional DBS Registry OUS ASSFN 2022 eposter.pdf": "B913E9D6-B730-4696-9F27-49CCAA6BDC27",
    "Vesper DLead Registry ASSFN 2020.pdf": "818B5B0D-8531-40EE-A104-F7BCAB873E75",
    "Vesper Registry ASSFN 2020.pdf": "011D0B3B-9872-4765-AAE4-09D96513C966",
    "Vesper-Awake vs Asleep DBS Outcomes ASSFN 2024_Poster.pdf": "93DC5860-7B96-41AB-8C77-E2F1E6865810",
    "Vestal CV Duke Format July 2020.pdf": "225404E3-E2BD-44A4-ACAF-A368AAB91863",
    "Vestal Neurosurgery Residency Completion Certificate Brigham.pdf": "21D55CC0-2B26-4F99-9F40-F5ABDE11BE31",
    "Vestibular Schwannoma Figure 1.pdf": "1265176B-F392-4C44-A5E0-A95AD3086140",
    "Vestibular Schwannoma Figure 2.pdf": "B7D6D55E-DB69-48FD-981E-24B517081DBE",
    "Vestibular Schwannoma Table 2.pdf": "0F82F7B0-9666-4AA7-B43A-6C410DC50E3A",
    "Vestibular Schwannoma Table 4.pdf": "C57C93A9-15D3-4D6A-8EFB-872C003AF346",
    "Vestibular Schwannoma Table 5.pdf": "A2BA44E7-2699-42C5-8DF2-6CE0A3A41616",
    "vestibular-schwannoma-audiologic-screening.pdf": "4DFB586E-6C66-4C74-91D7-A3B02DA72D2A",
    "vestibular-schwannoma-hearing-preservation.pdf": "4CEF6059-B4C4-4F4A-94BA-81132829C1F2",
    "vestibular-schwannoma-imaging.pdf": "70486945-1483-4CD9-93D2-4C451550DEA7",
    "vestibular-schwannoma-introduction-methods.pdf": "37F557DE-E1CC-451B-82BE-AD8C66835573",
    "vestibular-schwannoma-radiosurgery.pdf": "C293DBEF-F644-4151-8D85-3F7A56B9F6AE",
    "vestibular-schwannoma-surgery_0.pdf": "1EF31A83-525F-4B6E-9562-3DF4970FEF26",
    "vestibular_schwannoma.pdf": "36CE2452-5705-4775-8DBB-C2838B2F8A8F",
    "VGSC.pdf": "E43E1433-B8C9-4ACC-A1DC-B783A5942BF0",
    "vha_aprn_proposed_rule_final_comment_letter.pdf": "3F43E475-2D10-434F-975A-49DC8B2AB1EA",
    "vha_aprn_proposed_rule_final_comment_letter_0.pdf": "B3EC9293-10DE-487A-A665-FD967942CD9D",
    "ViBone CNS Digital Presentation Poster_7Aug2019.pdf": "CC838489-B038-4B21-B434-78BB3FD44E88",
    "Vicari CNS Poster.pdf": "DB9D00E3-4324-4CB2-A175-44C2188FF0BE",
    "Victor CV update.pdf": "FE6D5E04-7158-4F55-B27F-73CE897B1395",
    "Victor Gabriel El-Hajj-enrollment verif.pdf": "A9E1E35D-DCBF-4BF1-98A6-B4D13DAF1EC6",
    "Victor Hugo Brazilian Neurosurgery Board Certification.pdf": "959314BE-4C5B-4C79-B382-DDB53E2E6D75",
    "Victoria Sanchez LET.pdf": "B81B50B4-D1B9-41B9-B117-59F5F5AA332D",
    "View Academic Program Information.pdf": "2DEAC750-8F34-4558-A80B-F54E7568E3E6",
    "vignette_exp_poster.pdf": "17443923-282B-4826-9ED6-912AAC228DA4",
    "Vignolles_Jeong_Joshua_LOGS_M2.pdf": "30DDF3BD-428D-4B78-B591-50C88A6FA764",
    "Vijay Ravindra CV.pdf": "EC4669CB-98DB-4273-B1A8-38D4F5B48135",
    "Viljoen, JJ DR. Membership confirmation letter.pdf": "15B97415-436B-4D41-8026-8983472021B3",
    "Villalba_Nicole_GS-letter.pdf": "C552797A-DA04-4426-BB5F-D298DFE9694E",
    "Villanueva-Castro_ver.pdf": "2501E3B5-CB6F-45F4-816E-1BD1A12684F2",
    "Vin Shen Ban-FellowVerif.pdf": "36C4D3C6-C619-4C4E-ACE1-0C59705BD7E2",
    "Vincent Cheung.pdf": "ECC52DD4-B108-43E2-A4A9-8E624F1A24C5",
    "Vincent Nguyen CV 1.26.22.pdf": "34E6A387-76DC-4649-AA20-E9C1231E3A3E",
    "Vincent Nguyen CV 1.4.22.pdf": "D31AAC9B-F24C-4F6C-9A3A-0C127CE2DFD9",
    "vincent's letter.pdf": "0B3C00E3-F8C0-4641-A385-A544A0599C3C",
    "Vincy Mathew 2025.pdf": "0DB9D5B6-F170-46E2-A90E-BA8F557D2CC1",
    "VinShenBan_CV_20200831_Fellowship.pdf": "26568BAE-2C68-4E12-B1C8-DA22C05DBE0E",
    "vinshenban_CV_20221129 CNS Essentials for a Rewarding Neurosurgical Career Course.pdf": "F76CADFC-DCCB-452F-8BE6-9355B3B316D0",
    "Viren Desai 10-5-2022 CNS Leadership Institute.pdf": "C12F21C8-8465-4E2B-A92B-D66AB9893203",
    "VirenVasudeva_CV_20201224 for AANS.pdf": "83B373EA-CB29-41AA-806A-672CA03F9C5E",
    "Virginia Commonwealth University.pdf": "E501D6CE-D6C6-45C1-BE7A-89320E6E19E9",
    "Virtual Learning .pdf": "0E260740-396F-49D1-9FF5-600F5F3F1015",
    "Virtual PC PD Meeting Agenda_Final.pdf": "6894A3E6-56E0-4EFB-B7CE-5FC72C7E8360",
    "Vishal J. Patel - CV.pdf": "9472C2C0-2801-4D0E-91E1-8D8CA1466C6C",
    "Vishal Patel-Fellowship.pdf": "97879296-D965-41A0-8CD1-8AE28EF907CD",
    "Vishal_Patel_enrollment_verification.pdf": "E70DF8C0-62FA-41AF-A518-32ADBCA13AF1",
    "Visish CV - 041823.pdf": "FC4A4E44-FA45-4179-A092-5CD5A080A3ED",
    "Visish CV - 090722_signed.pdf": "03E0B964-8707-4900-9B04-93852E9C7448",
    "Visiting Clinical Observer Acceptance Letter.pdf": "161261AE-410C-4803-A2BF-7C86546B0E59",
    "Visual Abstract AVM repeat SRS.pdf": "9AB6CD18-9F13-4C6D-AD79-0E8EA4EB3042",
    "Visual Abstract.Age.CNS23.pdf": "7849938F-0913-4C3E-AFA2-9E2590F428F4",
    "Visual Abstract.Race&ethnicity.CNS23.pdf": "9E24B7F3-E0E3-433B-A49A-989D34BF1E1F",
    "Visual Abstract.Smoking.CNS23.pdf": "ED6B34DE-E987-4462-8C68-29163322C804",
    "Visual Abstract_Barrington3641.pdf": "E1C2881A-5FEA-4594-BF6E-294A379F2EE8",
    "VisualAbstract1.pdf": "6B221EFD-3F44-4A29-A756-28CD1810BCE7",
    "VisualAbstract2.pdf": "96DE5157-4A4C-4D38-A951-3AA9B71759C9",
    "Vivas Verification.pdf": "38ABF86D-6D66-4E24-993A-97C3F3344500",
    "Vivek_Sanker_Resume.pdf": "75A4C04F-AF1E-4EDB-B77A-398A6BBB9FAC",
    "Vivian Lee Letter of Good Standing.pdf": "00FAEE9F-163D-4382-B16E-E1980B06DB67",
    "VKA-Poster-Visual.pdf": "87D4AC6F-6CC9-402C-AE2B-C214D0EEB544",
    "VKhristov_AANS 2022_48wx36h.pdf": "84C53986-9C5C-442F-8559-7219C49DED42",
    "VL.pdf": "7C81CFA7-238C-49F9-8258-A3501D58AF7D",
    "Vladimir Ljubimov-Verification email.pdf": "36AD7A38-1D8C-41C1-AC85-4F112CB97086",
    "Vladyslav_Kaliuzhka_CV_2024.pdf": "E67A0880-E40F-4140-B364-704BF0572B61",
    "Vlasak Alexander Registration Verification.pdf": "0CD332D7-0BB6-4BC2-A1FB-331D81CBF13D",
    "VM CV.pdf": "CE273818-11F6-4913-950A-60646E84DE68",
    "VMS_Letter.pdf": "42ECCE1C-D9A3-4D7A-BD80-FDD8C2113210",
    "VNguyen-emailverification.pdf": "26CE2DED-42B3-46E7-B14D-C45243D4CAD3",
    "VNS Removal Tables PDF.pdf": "7833DD1C-29D1-40D7-9796-EA2A922F8EB3",
    "Vo Michael - Enrollment SKMC.pdf": "B2B9894A-BE08-4263-BDF4-8EA752612F99",
    "Voa vop dual lesion - 2024 ASSFN - Pertsch.pdf": "754FE0F4-E619-4EB0-8120-F59D7D2AF357",
    "Voce, David CNS.pdf": "C234C15B-FDD8-44F2-A885-97C165942A94",
    "VOD-423543132987517128895154.pdf": "8E70F5B4-8AAA-4FBA-BC5B-FD3EF505EC35",
    "VOD-469134132755999858412468.pdf": "AF073057-E268-4A52-BA9F-D0FAE22306B9",
    "VOE_Al-Adli Nadeem.pdf": "D0C4B553-67C3-463C-B4F5-9FC5D9B9EFD7",
    "VOE_UTSW.pdf": "A5641640-8795-4AEB-93E2-5E654CD01930",
    "Vohra Sanah 2026 LOGS.pdf": "1123700C-7D75-45C4-AD79-09B507CCC878",
    "Volunteer for the CNS Sergeant Instructions.pdf": "97513CB7-FFC8-4FCD-8564-796C46F2C893",
    "VOP_set_at_the_LPOP_decreases_over-drainage_TABLES.pdf": "DB708CD8-FACC-4E2D-A9E7-71D86EFE4B58",
    "VP Programs  Communications.pdf": "4E2C8B3A-0336-4E6E-A74E-A3B1B09703B6",
    "VPL CNS Poster.pdf": "DBF9048D-2C96-4DAD-BAD4-5151278882CD",
    "VPS_Gensurg_BUMCP_Poster.pdf": "E45AC5C0-6308-435B-8B09-3F32BB3229C4",
    "VPS_Gensurg_NRD_Poster.pdf": "665F7349-88C5-4B82-99CF-B62D2A0FF1A8",
    "VR Cavernoma Poster CNS2019.pdf": "76705549-CB8B-44FF-99D9-6C916F266454",
    "VR DBS Poster.pdf": "95BE86F1-E6EB-4E05-9938-C6EF47362B43",
    "vs_cranial_nerve_monitoring.pdf": "0B25B26E-E95C-43A2-94DA-4443BE58B1DC",
    "vs_emerging_therapies_0.pdf": "24D2B607-2127-4963-A073-25132ABC98F8",
    "VS_Frailty_CNS_Poster.pdf": "BEA65F68-C38A-4DF3-9C2C-4414027C8477",
    "vs_pathology_0.pdf": "4BB96C9C-8BD0-4FEB-A856-8785A6DA91EF",
    "VS_Stephens_CNS.pdf": "6780B9A3-E687-4F73-B68D-7D6C545405F2",
    "VS_Volumetrics_CNS_2022.pdf": "5BCF0F92-D984-49E3-92EA-C70B34147115",
    "VSekar.emailverification.pdf": "3FBA18F2-999C-49AC-AA15-716A6C161F5E",
    "VSLO Letter of Good Standing.pdf": "755A64CB-382B-4E5F-B84F-34C437FCA351",
    "VSrinivasan-RESverif.pdf": "AF0E80B2-0DC1-4AB8-A6AD-3F03E993195F",
    "VTA & fibercounts with TEED.pdf": "B1D7A85A-3E16-403C-82DA-A3D8A803C955",
    "VTA prediction poster 220419 (ASSFN).pdf": "802918CD-4255-45F9-83C4-9E7A0C91DA5D",
    "VTAs with E-field.pdf": "AD87CF1B-D04A-451E-83A3-08EDFBFD9B43",
    "VTC acceptance offer letter 2022_CTO.pdf": "E6D157A9-6DAA-4F73-AEF9-D933C5497557",
    "VTCSOM CV 2020 Signed.pdf": "7BCA308A-E528-4357-AE06-A346AB7C3222",
    "VTE Prophylaxis CNS 2019 Poster.pdf": "45793FAE-CD2C-4180-93CE-0E99753E37E2",
    "VVF poster.pdf": "193B4DD9-A7FA-4D6C-8D61-0BF0F388EE7C",
    "Vyacheslav Makler CV.pdf": "8D2C54DA-1834-42DA-8229-E91B401517C3",
    "Vyas Daivik - Letter of Good Standing Enroll.pdf": "FFD15460-DE17-42C0-B97E-723F00FA63DC",
    "VYCORTubesCNS2022.pdf": "7D2BE5B1-F107-4201-B3FD-AFC742D3B31C",
    "Waack Andrew  Letter of Good Standing.pdf": "74C5E2FF-7DDC-488D-8193-D62958A9DC67",
    "WACS Fellowship Certificate.pdf": "6EFBEEDB-F12B-4280-8EED-A799735583DF",
    "WACS Part 2 result.pdf": "4641BC41-CE4A-43C1-8B21-3D3E38764444",
    "WagnerCV. 2022.pdf": "8F4F46D8-44CB-459E-B061-7337E168606C",
    "Wait Letter of Support.pdf": "AEDBC5C4-7843-411E-BA59-82CBDC2078C6",
    "Wait SNS letter.pdf": "AB74D0B6-A71E-4F24-ADAB-28A3994A62F8",
    "wait_scott_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "1ACFE892-66CE-4DF9-A515-7CF8955A37B9",
    "wait_scott_2024PositionalMembershipApplication_CVandBiography.pdf": "2A892D0E-96F6-4C66-B244-1E2970CE30C8",
    "WaitScott LOR 11-1-2020.pdf": "753E8F8B-BDA8-4B60-9ACD-12BF46CEB5FC",
    "WaldmanAlex_Letter of Good Standing.pdf": "3B848C89-F77D-489E-8C6B-83485A57D1A3",
    "Waleed AlQurashi certificate.pdf": "4D6F6463-699D-4C6F-AC93-FAEEFE386693",
    "Walker, Agnes.PDF": "F54B65F9-AC78-4D36-ADE4-652D003C2F1E",
    "Walker_Good Standing Letter.pdf": "6DEA43D8-CA28-4B5F-B89A-59CD4EC384B2",
    "WalkerAriel.pdf": "2418E70F-3206-4C3A-BE3F-FCD8479F501B",
    "Walter Jean LON Roberto Heros.pdf": "11AC8AE7-E424-4F6F-8C75-D9BA0F7ED2E0",
    "Walter Jean LOS Jacques Morcos MD.pdf": "82A2CB2E-090F-49E3-87A0-052B23C5FDA4",
    "Walter Jean LOS Stephen Haines.pdf": "F66944B6-D1DC-4175-8F56-1C7161139F06",
    "Walter Jean Nomination Letter.pdf": "6061AD08-9515-4B0F-A09E-FDCC16F90E76",
    "Walter Jean Recommendation Letter 1.pdf": "DD3D1BAD-61DB-4B43-AA84-70DC2BF8A023",
    "Walter Jean Recommendation Letter 2.pdf": "48895FB7-DBCE-45AD-A6F7-2A459B66E694",
    "Wang 2023_CNS_Poster.pdf": "1D9A4054-8A17-42D7-9766-EC439D9F4FEC",
    "Wang CNS Fellow LOR Dr. Haglund.pdf": "0772D911-405B-4F29-BF6D-2088ED9749FB",
    "Wang Verification.pdf": "503D4511-077E-4841-8E15-1AAD637BD4BC",
    "Wang-CNSLeadershipInstitute.pdf": "EB48DF51-B2E5-4024-A3A0-41A15DDD1472",
    "Wang.pdf": "01951E20-5B31-4C80-8356-C758AD3BE6E0",
    "Warsi Nebras - CNS Letter - 2021.pdf": "9813C29D-0519-410A-80EA-58A0F5DE5572",
    "Was told I can submit this at a later date.pdf": "CF6E3031-8806-4760-B79B-C95B26E912DA",
    "WashU Agenda Draft12.14.19.pdf": "F5B7F009-212C-4295-8054-CB9A84AF0F24",
    "washu_enrollment_verification.pdf": "0EB37DA0-4501-4045-8FA5-AF267F0D401A",
    "Watanabe_G_Enrollment_Verification_Ltr_06.21.pdf": "1C9EA92C-BE29-438B-9B63-08F0E552B9E4",
    "Watters Amanda good standing.pdf": "D67F2733-6671-418E-870E-358218B3A702",
    "Watts, Clark.PDF": "EDF7F7A3-3A89-4891-800D-B9847CA2E838",
    "Ways and Means SMB Letter FINAL 012120.pdf": "9DE09E76-E0D9-441A-9161-A051B4CB596B",
    "ways_and_means_ipab_repeal_legislation_letter_060115.pdf": "74893A7B-0F80-42F0-828C-1BD9AD7F5AEF",
    "WBDECBAW.pdf": "30A6493A-2ABC-4CFC-B217-F66ABA6339A9",
    "wbrc_winter_2019_onsite_agenda_02.22.19.pdf": "BA3BD04D-8608-4E0F-8C24-D88C00BA9A0F",
    "WCM-Greenfield CV-2022.pdf": "4A153D65-5D88-4C15-8B75-17040AB2AC2F",
    "Weaver_LOR).pdf": "D7966480-2A20-4C01-985B-F190FD3015AF",
    "WEB FILE 16x20 in CNS membership poster 12-web.pdf": "603F6735-5CA7-459A-987F-87D11A86A7F8",
    "WEB retreatment poster .pdf": "BF974224-5A9B-4109-BA0A-525339E27DF4",
    "WEB Stenting and Coiling_CNS_ePoster.pdf": "DB248C2E-8801-473A-9607-008E1D244E7C",
    "WEB vs Coiling.pdf": "D5F8D39C-B376-4B25-A0C9-88C07C7AC47C",
    "Weber Daniel - Letter of Good Standing 1.pdf": "27375180-B05E-409E-9939-AC9D79F54491",
    "Weber.Matthieu.LOGS.M147.pdf": "9CE034F6-4BB8-4DEC-9D3C-080E902666CC",
    "Webinar_30th October Event_V3_FINAL_09.10.2020.pdf": "2E141B20-75CB-4C75-BC19-D6E19BF265EC",
    "WEBLIB_NBA_DSBI.FUNCLIB.FieldFormula(1).IScript_getPDF.pdf": "05525332-7FF3-4285-A006-0BB6415EBE12",
    "WEBLIB_NBA_DSBI.FUNCLIB.FieldFormula.pdf": "BC2E8CDA-4F1B-4E5A-8B69-0FD205117188",
    "Websites_Abstract.pdf": "383A5375-961A-48BE-9E3E-168BF9656CBB",
    "Week3-July23-28-LINDSEY Spine 2003.pdf": "D5F8C6C8-4E97-4D2B-8219-00D155E5C798",
    "Week3-July23-28-SWANSON Spine2003.pdf": "8C661DAA-4FFF-4EBB-A4D4-17A41CAD8678",
    "Week3-July23-28-WISEMAN Spine2005.pdf": "19DE2C00-16D1-41D1-B46F-9496E2B46091",
    "Week4-July30-Aug4-KONDRASHOV J Spinal Disord Tech2006.pdf": "18E21026-5C9C-4565-9EF1-05A2226A5C80",
    "Weekend Effect CNS Table 1.pdf": "5DA7CF2A-5A64-4796-91C3-8EC5084428C2",
    "Weiner_good_standing.pdf": "8D95C949-975F-4958-ADE6-43DBB86C892C",
    "Weiss_CNS2019_Poster1028.pdf": "19E56A73-1DE3-44E7-AB70-57E891BAB3DD",
    "Weiss_FWLNSchol_2022.pdf": "6ACE2565-6B98-4595-BF54-826007AB8C59",
    "Welby.E_letter_1.pdf": "5138329C-FA75-4AE3-B736-7A21AE3DB923",
    "Welch_Shabbar Danish LOR for SNS Membership_102920.pdf": "63912187-FEB0-40FA-ADE8-7F50605D18D3",
    "Welcome Letter - K. Templeton.pdf": "D4384256-D7E7-4C2D-8371-A9EF6DB7B4A9",
    "WELCOME TO HOUSTON 2.pdf": "BAC6460B-F849-4B03-BE03-B7C5322A0982",
    "well rounded physician exercise and burnout.pdf": "20C5A4E5-359D-4EAC-B22C-50B837A2E4D0",
    "Wellpoint Perc Endo Spine Response 3-2011.pdf": "820646CB-3000-421A-B9A2-03A4C850A44C",
    "Wellpoint Perc Endo Spine surgery policy 3-2011.pdf": "5F00567E-7C35-435D-A833-FB693F9A8ED9",
    "WEM-CV-Best-New.pdf": "ECD719C0-B8D4-49F3-AAFB-0F6FD28688E6",
    "Wenxin Zhu Letter of Enrollment 2021-22.pdf": "58B2C2ED-DACB-41B2-ABA7-48A63607A03E",
    "Wenya Linda Bi CV 2023.pdf": "2A1F822D-96FE-4836-928E-BC8B2FF34CAC",
    "Wenya Linda Bi_Biosketch.pdf": "6F89E282-7057-4DA9-9418-26E5A7E11BD7",
    "Werner, Cassidy good standing.pdf": "820A2E07-6611-42BB-9A9F-C77AA2C360BF",
    "Wes Marsh FACHE Resume 20230331.pdf": "1F0D88F8-3FDA-41CF-ADBD-C3141CCC6AB4",
    "Weston Troja.pdf": "BB68DEA5-4E69-4A20-A679-5445383966EE",
    "WF0005_205661428_V_M1_2022-09-13_15-50-01-50_0_TRN_TFM.PDF": "AA6B4CFE-06F0-492B-95C6-0F35A10E45EF",
    "wfbCV8.32pdf.pdf": "3432D517-95E3-41BF-A903-343D197678BB",
    "What Score to Use.pdf": "6DB638ED-DC5E-4479-AD5B-55599D8B5860",
    "Whats New.pdf": "625DAFA7-65D2-447A-9793-C8A590787648",
    "WhatsApp Image 2023-07-02 at 12.05.38 PM 1.pdf": "A6A716CC-2287-405B-A7B8-7A9796BFC720",
    "When Not to do HC.pdf": "31AEA356-83A9-4917-B223-319711E9D63A",
    "When The Brain Spits The Bo.pdf": "7FEBFBF5-3D70-4EC7-828B-B2C7AAF07DA0",
    "When to do HC.pdf": "788C12C8-EEE9-40C5-A250-FD6866E11143",
    "Whicker Wyatt Enrollment Letter 4.27.20.pdf": "F08249C3-A18E-4646-A17A-3E1AB1891708",
    "Whitaker-Lea_CV.pdf": "AAB5E449-3509-4AD6-8A50-F8B8EF7425B5",
    "White Alexandra Letter of Good Standing 2023.pdf": "C45B5B4E-D659-4CCA-98A2-15B66071E619",
    "White Coat Pic.pdf": "8B8E6FD6-02B0-4037-A2B4-289B9C19878D",
    "White-Dzuro_Letter of Good Standing.pdf": "3171F394-2913-4033-BD4B-04F0FFBBBAC2",
    "Whitney Parker-RESverif.pdf": "9E2AF997-2CB6-4006-AC65-097F63EA7A42",
    "WHO grade 2 CNS poster.pdf": "E4DEECA7-2321-430A-9881-421104B64D4A",
    "WHO grade 3  CNS poster.pdf": "E21C0191-57DB-4BA7-A1F8-622A727B93A9",
    "Who Needs a Mentor Anyway.pptx.pdf": "14C7722E-06FF-45F8-ABC2-7F79012BD727",
    "WHoyt LoGS.pdf": "A14A4149-1830-4080-A195-2A2D5091F9D6",
    "Wicks Elizabeth-.pdf": "00674373-5B05-4437-AA9A-7E91DA25F73E",
    "Wicks Medical Student Confirmation of Enrollment.pdf": "109D6997-C967-4D85-91A7-CA38765D9A53",
    "Wicks Robert CV.pdf": "598ED712-74B9-4043-9715-8A0079FADE35",
    "Widjaya_FWLNSchol_2022.pdf": "7DEA2312-C530-4B95-9952-B529971BDC65",
    "Wijesekera CNS application_NB.pdf": "CA32D362-808F-45CB-B500-F8D5E01FBBAB",
    "WIjesekera CNS Guidelines App 2024.pdf": "A336EBC5-2A0F-4AD2-AFDA-283EA544F61F",
    "Wilkerson_Enrollment.pdf": "70222952-F51F-425D-8475-6764F01B6855",
    "William J Shelton.pdf": "75CE5221-83C6-41F4-B777-9DFA67542217",
    "William Kuhn Board Certification.pdf": "47731EFA-C7A7-446F-B66B-522E85270F7B",
    "William Mangham.RESverif.pdf": "3C102774-8702-4912-9D92-7A7943AEC6AD",
    "William Updated CV..pdf": "E7429B18-E864-4448-A8CD-45458E1306F4",
    "WilliamMetcalf-Doetsch-RESVerif.pdf": "A5C46A73-C702-4AC0-8739-F50757B0F71C",
    "Williams Ashley 202105 M2 Enrollment Verif.pdf": "7ACB49AA-5772-4598-8142-7D24412C085B",
    "Williams LOR for SNS Membership_Nauta.pdf": "4A2CBC81-E951-430C-9FCF-060040994A15",
    "Williams support letter from Chambless .pdf": "26A4C086-CE3E-4656-8931-1569456979D0",
    "Williams_Lucas_Dowlati_CNS2022 v2.pdf": "E96B89A8-84D7-40C6-9C96-C050C4B9B344",
    "Williamson_Tyler_GoodStanding 031821.pdf": "477E0480-CB80-4BC1-8362-80EFFBD15D22",
    "WilliamThorellCurriculumVitae 2020.pdf": "FB5BD3C0-724D-4121-A012-9C8E95580C2C",
    "Willis Alyson.pdf": "BD281C9A-2D0B-4C65-900A-4F9DCB41FD3E",
    "Willis Kameron.pdf": "1496302D-3B7E-471F-BA54-739F0CB06468",
    "Willman Jonathan - LoGS 1.pdf": "FFEFA2E8-5C3F-4912-B520-BFE57CA45B78",
    "Willman Matthew - LoGS.pdf": "5AD6E34A-53A1-4952-BDF7-D9AC2AB6FB51",
    "Wills_ASSFN_2020.pdf": "88879037-DC4C-47C8-90EA-C6B161EB45DD",
    "Willsey_CV.pdf": "F1B18FE0-CBB0-476C-83EC-34CA7F302075",
    "Wilson Jamie ver.pdf": "F387F077-8503-402B-8B75-1F7EA3147DA0",
    "Wilson Ray CV.pdf": "DBCE4810-4D4E-43D5-9E73-AEBBA493955A",
    "Wilson Ray Nomination Letter.pdf": "4AD389BF-BE72-43CA-9648-2B058833AEB7",
    "Wilson Ray Recommendation Letter.pdf": "AAC4C159-3D6F-485C-959A-746F28D09D5C",
    "Wilson Ray Sponsor Letter.pdf": "509AE1BA-C62A-444E-B8DE-DCE84BA23928",
    "Wilson, Charles.pdf": "CFC749B6-6EFA-4F14-8E35-E4817927B527",
    "Wilson. Charlie.PDF": "BCE5FEDB-C6C4-4C0A-BEAA-DF5EBDA79EE2",
    "Wilson_Seth_LOGS_M2.pdf": "9CA78101-6B71-4EEA-B1F6-85FC11CFF245",
    "Wimber CV 2020.pdf": "BFA96543-7516-4D9C-8EDF-FD9BAFEF0456",
    "Winfree_01 Neuralgic Amyotrophy.No Videos.pdf": "2314E179-82AE-495F-AE01-419C31775F10",
    "Winkler-Schwartz verification.2022.pdf": "8A01742B-6DA4-4F6D-8E7A-090AE9806721",
    "Winn Prize Check Donation.pdf": "CFD0BA9A-EB46-4D9C-8D44-041835F1F84B",
    "Winn Prize MOU Final.pdf": "DD24C22A-19C6-4CED-893A-1D0ADC72B515",
    "WINS.pdf": "A8373534-A447-44AC-858E-B8C11AAD0C82",
    "Winter 2020 Newsletter-Final v2.pdf": "4374BA14-4B13-4CC9-8EE9-08AEC43B0AEB",
    "Winter 2021 Newsletter.pdf": "C3497C3B-7C09-4ACF-B4DC-94BFD7764859",
    "Winter Clinics_Cranial and Spinal Surgery 2023.pdf": "80D9A89D-ED0A-41AA-9A11-6A6A5EFBE256",
    "Winterhalter_Emily_GSLetter.pdf": "31DE6AB3-DC63-417D-AF5B-1616E6507555",
    "Wirth, Fremont P.pdf": "356ED22E-A1DE-4640-9AD3-BE435EDB9FE8",
    "Wirth, Phil.PDF": "C94256FA-3ADD-48F7-BFAE-0B1423B12C09",
    "Wisconsin.pdf": "C949AAFF-10E7-40AB-B6DB-8610EE6097A4",
    "Wissam Al Mugheiry-RES verif.pdf": "C1494736-76B0-497D-AF2D-AED19773BEE9",
    "Witcher_Mark_2024PositionalMembershipApplication_ConfirmationLetter.pdf": "6ED8D9AA-9B73-4F2C-B1E6-04F660B88CE8",
    "Witcher_Mark_2024PositionalMembershipApplication_CVandBiography.pdf": "C2EE1760-C510-4937-BF89-A6507E0AA1AC",
    "Withrow Joseph - LOR CNS Fellowship.pdf": "6795D1C9-EC8C-41B7-93C0-2DEF0B4AF84F",
    "Wittenberg%2c Blake Letter of Good Standing (1).pdf": "413F35F0-5ECB-4E78-B4EC-09EFBAE165FA",
    "Wittstatt (Alex) Whitaker-Lea- Verification.pdf": "5C7FBE73-2C3E-4AD6-B041-30CA97E8A831",
    "WJeong.REScertf.pdf": "6C0A87A6-E48D-4CD9-8DB3-12722D823E44",
    "WKemp.RESVerif.pdf": "9C7CFFD6-6B8D-4548-9638-6A9A3A9C61ED",
    "WLU.pdf": "D46551D5-F48C-4888-8899-66B4226F9C94",
    "wm_letter_on_red_tape_relief_for_physicians.pdf": "3EA2C701-59C9-45DB-974A-DF14505D8824",
    "WMostafa_LetterofRec.pdf": "D2AFA273-F333-47DC-8CB6-3A1329305A1A",
    "Wolfe CNS Scholarship Letter.pdf": "E2A315FA-CE19-46C3-A820-2696380F1B60",
    "Wolinsky JP_Society letter_1009'020.pdf": "99A5EE59-260C-4316-AAAB-DECAA5CB5977",
    "Wolinsky SNS.pdf": "2AA9D32C-AD94-4A73-A39C-C4D7B7D6C6E8",
    "Wolinsky SNS_Final.PDF": "46340376-0469-4B29-9AFC-74F5FC67EE0A",
    "Wong Gunther_Good Standing.pdf": "06DAF3A9-A78F-4F71-A01E-351CB4C2AF2D",
    "WongLetter of Certification.pdf": "4D954285-3DD8-4FF5-967F-9B7E2226951B",
    "Woo J..pdf": "AF29052E-439E-49AB-9EC6-161E3E5FB1E9",
    "Woo MD Henry H. 10-28-2020 SNS.pdf": "45B87B96-CBEC-4C35-B9BD-232E1AA4672E",
    "Woodhouse_CNS.pdf": "2E3D0351-5755-4B01-95D1-57EB79478EF7",
    "Woodrow CV Exec Jan 2022.pdf": "A1F3D422-063A-44FC-9956-6BCB71AFC7B4",
    "Woodrow S LoS CNS Ldrshp Inst 01-05-22.pdf": "3B8C68B1-8691-4E45-A6BC-6E759F8E974D",
    "Wooseong Choi's School Enrollment Verification-Letter of Good Academic Standing.pdf": "61383B37-BE54-469D-B09A-D227BFFE9FE4",
    "Worden Kurtresha 10-24-2023.pdf": "04112412-9CB5-437E-A5FB-BE3E432F53DB",
    "work orders.pdf": "AB0EE649-6535-4107-97D3-B54F34BEB505",
    "workingforyouinwashington.pdf": "6BC91AC1-6617-48D5-BE00-1D2E9AE8E14C",
    "Workshop slides.pdf": "222BC7C2-A71D-434F-94D4-0485A3B62EB8",
    "world_bank_economy_doc.pdf": "AED24C6B-7D6E-475A-80FC-CDB1DFA11B67",
    "Wright_Weston Unofficial Transcript.pdf": "FAB4B471-20D7-4968-B649-2FBF4564802B",
    "wsj_lte_july_2017.pdf": "C8CB7354-F621-4AD5-9F44-0563AF2F87D8",
    "WSJLTR-Final.pdf": "EE6CB649-7080-4669-BE93-8A3B50FF1DA8",
    "WSSFN2012.pdf": "773643E4-F49E-4D46-926A-E95C2ED1DD6C",
    "Wu Verification.pdf": "89C745A1-C4A5-4CD9-8093-DDF1DA276524",
    "WUSM Offer letter.pdf": "B14F2A44-08C0-4798-A18E-B42859DD65CA",
    "Wuyang Yang CV (New)_(Short)_August_2016.pdf": "3F72AA9C-DF22-4E52-AACF-DE8C88C193AD",
    "WWhitaker-Lea.ResVerif.pdf": "610D4B90-A7F1-40BB-9984-ED50FD183B7E",
    "wyden_ipab_repeal_bill_endorsement_031617.pdf": "FCBF65D1-4BDA-4337-AF6A-5792C5DDE580",
    "Wydh Verification.pdf": "9EE97441-B0F1-4485-B598-84B285583F00",
    "X Doorenbosch.pdf": "270E78B1-6D3D-42E6-927B-8AA4C07B91B3",
    "X Li-RESverif.pdf": "FF369DBB-5280-4F82-9866-EC5262880614",
    "X Sun.emailverification.pdf": "7C3092E3-E18B-4936-91F1-AFDD891AA4D4",
    "Xerox Scan_01062020154217.pdf": "8ADCBAA7-5FB5-4607-B0EE-991433D63E1E",
    "Xerox Scan_09122022104117.pdf": "6EC0328C-86F1-49EA-B99F-9B35B0748187",
    "Xerox Scan_16112023125814.pdf": "A6D6230E-35F3-4A89-9BFE-1101820D3E63",
    "XHLOS_Poster_CNS_2022.pdf": "20625128-FDAC-415B-B56D-6490A7117FDB",
    "Xia Terry.pdf": "C7072DB1-1563-4861-9F2C-59EF1C3CF2CF",
    "Xiaofei Sophie Zhou-RESverif.pdf": "3B7B3999-270F-4C36-8EC8-18B7BCCF0B08",
    "Xiaofei Zhou_RESVerif.pdf": "4CE7E6DD-3D3D-416E-A338-4A40DC01CC24",
    "Xiaonan Sun-fellow.emailVERIF.pdf": "B3679549-A27F-4E97-8B4C-8A7405E12BC6",
    "Xiaoran Zhang.FellowVerif.pdf": "EB74D449-F222-4866-9C92-FB88F9F1012D",
    "Xiaoran Zhang.pdf": "214E0FF9-E395-4CA8-ADEA-9BAF1D5AE5FC",
    "Xin VER - Philadelphia College of Osteopathic Medicine.pdf": "BE479AC2-F2E9-48C6-83A9-AC1FB5E770A2",
    "Xinli You - CV 2019.pdf": "01AB0172-084A-4D26-B4B6-C0E0F29C1390",
    "xinli's.pdf": "63F24722-C05E-4B02-A219-E01BAF805367",
    "XLIF Complications WNS Figures 10-09-2019.pdf": "879C24A0-0371-4551-90C3-0C467A0CF5A7",
    "Xu Emily Ltr. for CNS.pdf": "072C6C4B-0BFB-4BBD-993B-F4AE342A9902",
    "Xun Li CV 02-2023.pdf": "BBC636FE-F405-4229-AE70-249C08537DC1",
    "Y Kim.pdf": "F5096F72-F7B1-443C-A00F-D7BAAA9B157B",
    "Y.Jeelani.email verification.pdf": "434F3BEF-F72C-4AF4-825E-5EA41026F900",
    "Y_stenting_poster.pdf": "47882077-44F4-4879-88A4-0CB783AC64CE",
    "Yacine FELISSI 2021.pdf": "5C201B8B-EEC6-4D53-A577-D09C6FD4AF85",
    "Yadav A LoGS.pdf": "C743AE96-347B-43B6-AABD-9394583B8628",
    "Yager Brock Robert-EVL-CNS-MD 7.11.23.pdf": "D541F6AD-26D0-481A-A1E7-27F26470ED48",
    "Yahoo Mail document_ Membership Application - Neurological Society of India.PDF": "02AEB75E-722C-4CD6-82DB-CF4497688CA6",
    "Yale - Merenzon LOI.pdf": "B91BDBF0-7597-4104-ABCD-05DB0926E66F",
    "Yale Letter of Good Standing.pdf": "29CD1964-EFB9-4811-B2B9-3FAF17E34444",
    "Yale Proof of Residency.pdf": "874EB518-049B-4F85-A986-68847510F97A",
    "Yamini SNS Nomination-September 2021.pdf": "FA5CE4FC-0E6E-4425-A5B2-621205591AEB",
    "Yamini.pdf": "13BB67CA-B361-482B-8DED-DE07FF45816D",
    "YanCNSPoster2022.pdf": "80039B60-1770-472C-8908-D422A362334A",
    "Yang Chen_2021_1027_Enroll.pdf": "4E3E7CA6-2A92-4883-BDB5-7CBD28A588AA",
    "Yang Kai Yun-CV.pdf": "3D4F1D21-4A60-44AE-A1B1-1001D9536C55",
    "Yang_CNS_DecisionTree_Poster.pdf": "2E7D2458-94DA-4721-A742-AD694A80BD74",
    "Yang_CNS_mJOAvsExtremity_Poster.pdf": "810129BB-9BD1-4039-986F-44FF06C6EF58",
    "yaser cv 5.pdf": "56D5E148-C8AC-4E26-BC9E-523448165CF7",
    "Yash Patel 08.06.20.pdf": "BDB2A102-577C-47DD-A701-AD6165AC5D5E",
    "Yasmeen LOR PDF.pdf": "62127DC8-60BD-4EFF-A5A5-12AC6CC52943",
    "Yasmeen LOR.pdf": "B91BBF9D-CD26-47D0-93FE-887040690C7B",
    "Yassari Letter.pdf": "8F017440-452E-4A9C-962A-F3CC438DBDB8",
    "Yasser Jeelani-FellowVerific.pdf": "671D8DE2-61A2-4610-8D5E-7CE8B6D169ED",
    "Yazan Shamli Oghli 4920.pdf": "61CE647E-3548-441D-9F3B-F7A02EC48553",
    "Ye_Donald-Congr of Neurologic Surg 081611.pdf": "FAF49357-24B0-43AC-A5D5-45768F7DF082",
    "Year 2.pdf": "CF17C871-AA05-47AA-84A9-B06DC95BE5CC",
    "Yearley Student Status Letter.pdf": "D076CDBC-540C-4D2D-8025-79A9BEFC2514",
    "Yearwood-06 CNS poster subthreshold.pdf": "B7448370-C5B9-4EB7-8027-E121FE2428DC",
    "Yeni Belge.pdf": "A77A6DEB-07AA-4BD2-9BB0-2D12E7574E1E",
    "yenicelik-letter of enrolment.pdf": "D4662724-551C-4E37-8C3D-799FC67AD777",
    "Yerokhin_Enrollment_Letter_3_28_2016.pdf": "59E68287-6CF8-4B84-BF5D-A2373A70989A",
    "Yerokhin_Letter_Good_Standing_2_24_2016_2.pdf": "DC349427-47B8-4DCC-8054-084904E95641",
    "Yerokhin_Letter_Good_Standing_Enrollment_Combined_5_10_2016.pdf": "5603C0A6-FA46-4943-A051-9622D316F990",
    "Yi_Biosketch.pdf": "595E65E8-9043-447E-A5E5-C19D928DFA46",
    "Yi_LOS_CNS_AnnieTrang.pdf": "58F235FF-BB25-4DC9-BE31-6C91092E98DA",
    "Yim residency diplomas.pdf": "C3B3AAE0-A0C0-45E6-ABEA-D9BC2D818988",
    "Yirui Sun member application.pdf": "5A57F7BA-4A7B-45ED-A838-F590B04CB7BD",
    "YJin.Proof of Completion of Residency.pdf": "5B57BF0C-C7B7-49BF-AB30-559CBE555837",
    "YKayResume070316.pdf": "6F9A923F-13B8-4853-86EC-267A677EC9D4",
    "Yoav Ritter D.O. Resume .pdf": "F02A0ED4-1A91-4A1D-8B7C-195D3611F908",
    "Yocom-CV revised 11.2014.pdf": "BCC774DD-F53F-466D-B20B-34E21C573253",
    "Yoon CV.pdf": "5DEED4AB-01AF-4341-A92E-37AC9BEFF054",
    "Yosef Dastagirzada-Fellowship.pdf": "5C4FEED8-5A49-44EF-8644-5B0D5F3171D4",
    "YosefEllenbogen-RESverif.pdf": "DE17D203-D36B-474E-8DFF-0DBEF58B45EE",
    "Yoshor Letter CNS Leadership - Srinivasan.pdf": "63C996F1-9A2E-4CE9-BBDB-264A2D028907",
    "Yoshor_LOS 2022 Neil R. Malhotra Senior Society_100322.pdf": "F2D19164-9716-4EE1-856B-770404772FA7",
    "You Hyo Bin - enrollment verification 1.pdf": "6882428B-A7DC-4BB5-8888-D6B19BDDCFCA",
    "Youn Letter of Good Standing.pdf": "8C930EB7-5E1D-47BE-93A2-C0580C679727",
    "Youn_verification.pdf": "320960E9-B19C-4E66-936C-673B98FA009A",
    "Young Jacob.pdf": "E1DF6BBB-827A-485C-86F1-140E9D1D216B",
    "Young Lee-Fellow.pdf": "9C437B09-235E-483A-97DA-09865ACF3D84",
    "Young, Jake_Letter of good standing 7.28.2015.pdf": "4EFA722A-C14D-4E13-95E2-27BB8606AFDA",
    "YoungC_fellowship contract.pdf": "B6BDF5D1-AE26-4B7B-972D-5215B31D5E0E",
    "Youngerman Residency Certificate.pdf": "90CD8F59-75C6-4129-8DC6-E4D18F8047F0",
    "Your @Attending Will #Tweet You Now_ Using Twitter in Medical Education.pdf": "5430EBD5-D024-4D2E-8332-AEC259B2FB9D",
    "Youre Invited_ The Sixth Annual MHFP Symposium _ Friday May 24th.pdf": "99D398D1-5EB4-4DA8-AAE1-774CBF518D49",
    "Yr 3.pdf": "65725F10-FEF9-4C20-9006-04F4EE334CAD",
    "YSChung_Visual Abstract Template.pdf": "5D4C91A2-8A87-42FD-B216-216CA92818B1",
    "Ysibih UCSF Enrollment verification.pdf": "45819F98-F28F-4F14-AEAA-970B675C6102",
    "Yu Jennifer- Enrollment Verification.pdf": "6FC2F135-5CCE-4ADB-B8D4-F386031145FF",
    "Yu LOR 10.21.pdf": "11F8AE71-1108-4FA1-B5D4-416B8A70F212",
    "Yu Tung Lo CV.pdf": "AE97448E-6F06-4422-A169-BAE0BAB024CB",
    "Yu.pdf": "6F2E7C18-F67F-4CF1-9692-CF193B8A0391",
    "YUE CNS Data Science LOR 2022.pdf": "CAE84435-8BD0-4BFE-BB90-EA5F5689982B",
    "Yue_John_2015_9_2_Good_Standing.pdf": "B88CBAD2-2586-43BB-B12C-EF324CDF8A32",
    "Yue_Poster_176_Repetitive_TBI_CNS_2023.pdf": "B9DE5198-5AE2-4967-8C07-86D91863F8A1",
    "Yue_Poster_2123_CNS_2023.pdf": "42A2B6C9-B246-4C7F-BD0E-38D0B13BA52A",
    "Yue_Poster_248_Isolated_TSAH_CNS_2023.pdf": "631518D8-E686-495A-899C-9D4B0D2A970C",
    "Yue_Poster_2886_CNS_2023.pdf": "ACB3F66C-5B71-46FC-9C6C-43CDF0943872",
    "Yue_Poster_325_Medicaid_FFS_MCO_CNS_2023.pdf": "0DBFD07B-183E-479A-AC07-53089A4366B8",
    "Yue_Poster_445_CNS_2023.pdf": "8BC0FC62-B5F8-4E72-A696-76D6A2A8ADC3",
    "Yuhan Wei - CNS poster.pdf": "45FB68F1-0482-4CD5-A4A1-C943F3F56475",
    "Yukihiko Sonoda CV.pdf": "CCCF5F77-9173-4CB0-939B-6914A28C167F",
    "Yunus Kuntawi Aji - RS Pusat Otak Nasional Prof. Dr. dr. Mahar Mardjono.pdf": "C89DDD58-785C-435F-B479-6605FF6A1FB7",
    "Yuri Estevam Banderia.pdf": "0FB02082-52D9-490A-8E14-E05A3EE4EE02",
    "Z. Hawa Letter 07.14.23.pdf": "94A55835-95EE-4326-AC5C-54CDB875B118",
    "Z. Mendelson.pdf": "686FEE63-9EDC-412D-9707-C1F759FF6D85",
    "ZA CNS 2023.pdf": "56E35332-5C35-4D2E-84BA-C32CDC88B0C4",
    "Zach Bernstein aug2022.pdf": "C7E8597F-992C-4773-B388-2823D6254F78",
    "Zachary Gersey-Fellowhsip.pdf": "8A25FFB8-1B3B-4269-9944-05787F12E246",
    "Zachary Jensen.pdf": "D6DA9136-42BC-44F0-8B25-8BB31CB91A24",
    "Zachary Olmsted Letter.pdf": "4991DCE4-69F5-4688-B94F-6026D7451C82",
    "Zada updated LOR for SNS from Giannotta.pdf": "0509170D-7548-4EF9-B559-D59FFC5E583D",
    "Zadeh LOS SNS.pdf": "E2474BDD-6CCC-4A11-9390-36FE6E873FD4",
    "Zager, Eric.PDF": "D0FD15C3-5988-404D-9584-D3C391D178DB",
    "Zaid Aljuboori CV.pdf": "4C1527E9-66DD-4B9F-8A80-2302FFE653C0",
    "Zaman Mirzadeh Curriculum Vitae Aug2021.pdf": "0CD23D52-B6BF-420C-BD55-81A6BCC208E1",
    "Zane Cooke.pdf": "CDAC046D-39DB-4925-B1B1-BC794796CBA0",
    "Zarnett CNS membership Verification_KM.pdf": "85323986-8B49-4264-9C7F-00B579929B60",
    "Zayed Almadidy CV 2023.pdf": "2E9EA65F-E062-410F-8A6C-5B99088E37E0",
    "Zemla Roland-Enrollment Letter.pdf": "3F3BC536-DBA1-49B5-A243-E5DDC055E3E9",
    "Zenonos VER.pdf": "2AB541E1-D737-4DBD-8CC2-FAC5A3F20EF5",
    "Zesiewicz DBS Retro CNS 2022.pdf": "3B9F280D-8FD5-4002-90CF-EFEB20034F99",
    "zeyad nasr case medical report.pdf": "6382DB3E-809A-480A-BC80-1CAB9CC147D5",
    "Zhang AB LOGS.pdf": "33B44755-AAAB-484B-A64B-B7C13F517E0B",
    "Zhang Justin_GoodStandingLtr_9-28-21.pdf": "D1E59870-63A3-4445-A3D4-76F305AE94FA",
    "Zhang Man.pdf": "33EC47C5-419C-4D70-8B93-50BA364EADDE",
    "zhang, angie-letter of good standing.pdf": "D516CC34-C39F-4751-A58E-83C75E1BD64D",
    "Zhao_Stopping_v6_combined.pdf": "4012FB04-3584-4A13-B794-488567CA784B",
    "Zhou James Letter.pdf": "DB8D5964-E77C-4BED-973A-42FDC56B89E6",
    "zhou-proofenrollment.pdf": "7EBE1A84-F257-4A4B-B8B9-B66AFA2469BA",
    "ZIBA DAMANWALLA.pdf": "EF57FB67-B353-4040-BE4D-ABB4D6492153",
    "Zion Zibly CV 5 2022.pdf": "6B9F7230-67E9-41ED-BD80-BC22FCB8B6FC",
    "Zipfel, Greg.pdf": "8591FC90-F39D-43AB-B99D-938C97F1EE25",
    "ZIR.pdf": "1A612231-4269-4434-8793-93A73F1A877D",
    "Ziyad Makoshi C.V..pdf": "565CAE96-B42D-4640-9397-897F64225C65",
    "Zoey Pettit.pdf": "83A2DE5A-81DD-42E6-A81F-D8BDBC83A5BC",
    "Zohdy Ismail Employment Letter 10.01.2022.pdf": "2139B4B7-6477-40F7-9244-F2BFF463A863",
    "Zubair Humza 2028 LOA Approved.pdf": "51235A66-871D-43E3-899B-DA65EE923D53",
    "Zucker Residency Certificate 2014-2021.pdf": "F446752E-9B11-476D-AE96-2E194DF6BEA7",
    "Zucker School of Medicine at Hofstra Northwell.pdf": "90A99D23-B766-43A2-BB75-8AF8377BF961",
    "Zulifiqar Amna - July 30 Bank Letter.pdf": "5D44242A-3AA4-4ADF-A5C2-61A0A7531883",
    "Zumofen CV 02_02_2022.pdf": "A96D70E1-C013-4097-9881-8670EFFF3977",
    "Zumofen Fellowship Certificate.pdf": "4A308F32-D0F8-4E61-84A8-D3A3DB4A3F69",
    "Zvipo Chisango Atypical Meningioma Poster for CNS - Final.pdf": "9B9148E5-3D3A-4E26-AB30-18E0A4CE158B",
    "ZVoronovich.Diploma - Residency.pdf": "7D032F91-3927-4DD1-8500-5F682CDF268D",
    "Zwagerman CNS Leadership Institute.doc.pdf": "44793218-44C8-489C-A248-968E84712745",
    "Zwagerman Nathan CNS Leadership Institute 2023.pdf": "0A11E461-DA3A-45D4-AB1C-23FA0DFF7357",
    "Zwagerman Nathan LOR from Marjorie Wang 10.2023.pdf": "3F8CDCF0-A636-4D2C-BABB-1C9BED85E71E",
    "Zwagerman Nathan SNS Membership Nomination 2023.10.30.pdf": "637ED9F9-6027-4035-8598-2A6DEDD16863",
    "Zwick Sarah_letter of good standing.pdf": "CED7B9F7-38EF-4CDA-8F00-BF4FF0D518A8",
    "Zyck Good Standing Ltr.pdf": "F5BE9883-9AD1-4FA3-838D-554706CEDE2E",
    }
        ;
