import {createApp} from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import {router} from "./router";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
app.use(pinia);
app.use(router);
app.mount("#app");

axios.defaults.withCredentials = false;


