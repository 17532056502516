import CognitoIdentity from '@/services/index.js';
import { useAuthStore } from '@/store';
import {router} from "@/router"
import {useThreadStore} from "@/store/modules/thread";
const CognitoIdentityService = CognitoIdentity();


export async function signin(username, password) {

    const cognitoParams = {
        username: username,
        password: password,
    };

    try {

        const cognitoUser =
            await CognitoIdentityService.signin(cognitoParams);
        const auth = useAuthStore();
        auth.setUserToken(cognitoUser.idToken);
        auth.setUser(username);
        auth.setData(JSON.stringify(cognitoUser));
        auth.setName(cognitoUser.userAttributes.given_name, cognitoUser.userAttributes.family_name);
        router.push('/ask');
    } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
            console.error('User has not confirmed their email:', error.message);
            throw("Account has not be confirmed by Admins");
          }
          throw("There was an error logging in. Please verify that you entered in the correct username and password.")
    }
}

export async function signup(username, password, firstname, lastname) {

    const cognitoParams = {
        username: username,
        password: password,
        givenname: firstname,
        familyname: lastname
    };

    try {
        await CognitoIdentityService.signup(cognitoParams);
        router.push('/login');
    } catch (error) {
        throw(error.message);

    }
}
export async function refreshToken() {

    try {
        const auth = useAuthStore();

        const cognitoUser = await CognitoIdentityService.refreshToken(auth.user, auth.data);
        auth.setUserToken(cognitoUser.idToken);
        auth.setData(JSON.stringify(cognitoUser));
    }catch (error) {
       console.log(error);
       throw(error.message);
    }
}
export async function logout() {

    try {
        useAuthStore().logout();
        useThreadStore().resetThread();
        router.push('/login');
    }catch (error) {
       console.log(error);
       throw(error.message);
    }
}

export async function validateToken() {
    try {
        const cognitoUser =  await CognitoIdentityService.validateToken();

        return cognitoUser;
      }  catch (error) {
            console.log(error);
            logout();
         }

}
