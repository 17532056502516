import { fileDict } from '@/store';

export function cleanupSources(sources, showScores) {
    // Split the sources string by newlines to create an array of elements
    const sourcesArray = sources.split('\n');

    // Filter and map the array elements
    const filtered = sourcesArray.filter(element => {
        // Remove the relevance score part
        const cleanedElement = removeRelevanceScore(element);

        // Regular expression to match UUIDs
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;

        // Filter out null elements and elements matching the UUID format
        return cleanedElement !== null && cleanedElement.trim() !== '' && !uuidRegex.test(cleanedElement);
    });

    const cleaned = filtered.map(item => {
        const cleanedElement = removeRelevanceScore(item);
        const relevanceScore = getRelevanceScore(item);

        let formattedItem = '';

        if (cleanedElement.startsWith("rhoton_split_pdfs/")) {
            formattedItem = "Rhoton Pdf";
        } else if (cleanedElement.startsWith("pdfs/")) {
            const lookup = lookupUrl(cleanedElement.replace("pdfs/", ""));
            if (lookup.startsWith("http")) {
                formattedItem = `<a target='_blank' href='${lookup}'>${cleanedElement}</a>`;
            } else {
                formattedItem = lookup;
            }
        } else if (cleanedElement.startsWith("http")) {
            formattedItem = `<a target='_blank' href='${cleanedElement}'>${cleanedElement}</a>`;
        } else {
            formattedItem = cleanedElement;
        }

        // Append the relevance score in a new div
        if (relevanceScore) {
            formattedItem += `<div class="relevance" style="display: ${showScores ? 'block' : 'none'};">Relevance Score: ${relevanceScore}</div>`;
        }

        return formattedItem;
    });

    // Remove duplicates
    const dedup = Array.from(new Set(cleaned));

    // Get the first 5 sources
    let firstFiveSources = dedup.slice(0, 5);

    // Check if any of the first 5 sources contain the specific URL
    const specificUrl = 'https://www.cns.org/wkhjournal?';
    const containsSpecificUrl = firstFiveSources.some(source => source.includes(specificUrl));

    // If the specific URL is not in the first 5, find and add it
    if (!containsSpecificUrl) {
        const additionalSource = dedup.find(source => source.includes(specificUrl));
        if (additionalSource) {
            firstFiveSources.push(additionalSource);
        }
    }

    // Return the cleaned up sources as an HTML unordered list
    return "<ul>" + firstFiveSources.map(item => `<li>${item}</li>`).join("") + "</ul>";
}

function removeRelevanceScore(element) {
    return element.replace(/, Relevance Score: \d+(\.\d+)?%$/, '');
}

function getRelevanceScore(element) {
    const match = element.match(/, Relevance Score: (\d+(\.\d+)?%)$/);
    return match ? match[1] : null;
}

function lookupUrl(filename) {
    if (Object.prototype.hasOwnProperty.call(fileDict, filename)) {
       
        return  `https://www.cns.org/Assets/${fileDict[filename]}/638537119614270000/${filename}` ;
    } else {
        return "URL not found for filename";
    }
}
