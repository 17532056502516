<template>
  <div class="textinput_wrapper pt-4">
    <div class="askform_container container">
      <h1>Ask CNS<span style="color: #549E93;">+</span></h1>
      <form @submit.prevent="sendRequest">
        <div class="row">
          <div class="col-md-11">
            <div class="form-group askform_text mb3">
              <label for="request"></label>
              <textarea
  @keydown="handleKeydown"  
  ref="requestInputElement"
  class="form-control"
  v-model="requestText"
  id="request"
  required
></textarea>
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group d-grid askform_button mb3">
              <button class="btn btn-primary btn-block" type="submit">Ask</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="isLoading" class="d-flex justify-content-center pb-3">
      <div class="spinner-border" role="status"></div>
    </div>
    <!-- Content to display after loading -->
    <div v-else>
      <div ref="responseWrapper" class="response_wrapper">
        <div class="container pt-3">
          <div v-if="response">
            <h2>Response</h2>
            <p v-html="response"></p>
          </div>
          <div v-if="sources">
            <h2>
              Sources
              <button @click="toggleScores" class="btn btn-link">{{ showScores ? 'Hide Scores' : 'Show Scores' }}</button>
            </h2>
            <div v-html="sources"></div>
            <div class="feedback">
              <h6>How was the response from CNS+?</h6>
              <i @click="sendFeedback('positive')"
                 :class="{ 'feedbackGiven': feedbackGivenUp }"
                 data-bs-toggle="tooltip" data-bs-placement="top" title="Good Response" class="fas fa-thumbs-up" style="cursor:pointer;"></i>
              <i @click="showFeedbackFormAndSendFeedback"
                 :class="{ 'feedbackGiven': feedbackGivenDown }"
                 data-bs-toggle="tooltip" data-bs-placement="top" title="Bad Response" class="fas fa-thumbs-down" style="cursor:pointer; margin-left: 1rem;"></i>
            </div>
            <div v-if="showFeedbackForm" class="feedback-form">
              <textarea v-model="feedbackText" class="form-control" placeholder="Enter your feedback"></textarea>
              <button @click="sendFeedback('negative')" class="btn btn-primary mt-2">Submit Feedback</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cleanupSources } from '@/controllers';
import { useAuthStore } from '@/store';
import {useThreadStore} from "@/store/modules/thread";
import {watch} from "vue";

export default {
  data() {
    return {
      threadStore: useThreadStore(),
      requestText: '',
      response: '',
      isLoading: false,
      sources: '',
      sourcesRaw: '',
      showScores: false,
      showFeedbackForm: false,
      feedbackText: '',
      feedbackGivenUp: false, // Track if feedback was given
      feedbackGivenDown: false // Track if feedback was given
    };
  },
  created() {
    this.threadWatcher = watch(() => this.threadStore.threadId, this.handleThreadChange);
  },
  beforeUnmount() {
    this.threadWatcher();
  },
  methods: {
async handleKeydown(event) {
      if (event.key === 'Enter') {
        if (event.shiftKey) {
          // Allow new line if Shift + Enter
          return;
        } else {
          // Prevent default form submission and call sendRequest
          event.preventDefault();
          this.sendRequest();
        }
      }
    },
    async sendRequest() {
      try {
        this.isLoading = true;
        this.response = '';
        this.sources = '';
        this.sourcesRaw = '';
        this.showFeedbackForm = false;
        this.feedbackText = '';
        this.feedbackGivenUp = false; // Reset feedback status
        this.feedbackGivenDown = false;
        const user = useAuthStore();
        const threadStore = useThreadStore();
        const data = {
          topic: this.requestText,
          user_id: user.user,
          session_id: threadStore.threadId ?? ''
        };

        const response = await fetch('https://pocbys2sutj7gsjt4ladpl6ylu0mheqn.lambda-url.us-east-1.on.aws/api/story', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.token
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = '';
        let isDataSourceSection = false;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;


          let decodedPart = decoder.decode(value, { stream: true });

          const threadIdRegex = /Session_ID: (.+)/;
          const match = decodedPart.match(threadIdRegex);
          if (match) {
            const threadId = match[1];
            threadStore.setThreadId(threadId)
            decodedPart = decodedPart.replace(threadIdRegex, '')
          }


          result += decodedPart

          const dataSourcesIndex = result.indexOf('Data_Sources:');
          if (dataSourcesIndex !== -1 && !isDataSourceSection) {
            isDataSourceSection = true;
            const responsePart = result.slice(0, dataSourcesIndex).replace(/\n/g, '<br>');
            this.response += responsePart;
            result = result.slice(dataSourcesIndex + 'Data_Sources:'.length);
          }

          if (isDataSourceSection) {
            this.sourcesRaw += result.replace("Sources:", '');
            this.sources = cleanupSources(this.sourcesRaw, this.showScores);
          } else {
            this.response += result.replace(/\n/g, '<br>');
          }

          if (this.isLoading && result.length > 0) {
            this.isLoading = false;
          }

          // Ensure UI updates and scrolls to the latest content
          this.$nextTick(() => {
            const responseWrapper = this.$refs.responseWrapper
            if (responseWrapper) {
              responseWrapper.scrollTop = responseWrapper.scrollHeight;
            }
          });

          result = ''; // Reset result to accumulate new chunks
        }

      } catch (error) {
        this.response = 'An error occurred: ' + error.message;
        this.sources = '';
      } finally {
        this.isLoading = false;
      }
    },
    handleThreadChange(newThreadId, oldThreadId){
      if (newThreadId == null && oldThreadId != null) {
        //thread was reset
        this.requestText = ''
        this.clearAllResponseContent()
        this.$refs.requestInputElement.focus()

      }
    },
    toggleScores() {
      this.showScores = !this.showScores;
      this.sources = cleanupSources(this.sourcesRaw, this.showScores);

      this.$nextTick(() => {
        const responseWrapper = this.$refs.responseWrapper
        if (responseWrapper) {
          responseWrapper.scrollTop = responseWrapper.scrollHeight;
        }
      });
    },
    async sendFeedback(type) {
      try {
        const user = useAuthStore();
        const feedbackData = {
          question: this.requestText,
          response: this.response,
          feedback: type === 'positive' ? 'positive' : 'negative',
          email: user.user,
          feedback_text: this.feedbackText
        };

        const response = await fetch('https://pocbys2sutj7gsjt4ladpl6ylu0mheqn.lambda-url.us-east-1.on.aws/feedback/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.token
          },
          body: JSON.stringify(feedbackData)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (type === 'positive') {
          this.showFeedbackForm = false;
          this.feedbackText = '';
          this.feedbackGivenDown = false;
          this.feedbackGivenUp = true;
        } else {
          this.feedbackGivenDown = true;
          this.feedbackGivenUp = false;
          if (this.feedbackText.trim().length > 0) {
            this.showFeedbackForm = false;
            this.feedbackText = '';
          }
        }

      } catch (error) {
        console.log('An error occurred while submitting feedback: ' + error.message);
      }
    },
    showFeedbackFormAndSendFeedback() {
      this.showFeedbackForm = true;
      this.sendFeedback('negative');
      this.$nextTick(() => {
        const responseWrapper = this.$refs.responseWrapper
        if (responseWrapper) {
          responseWrapper.scrollTop = responseWrapper.scrollHeight;
        }
      });
    },
    clearAllResponseContent() {
      const responseWrapper = this.$refs.responseWrapper
      while (responseWrapper.firstChild) {
        responseWrapper.removeChild(responseWrapper.firstChild)
      }

    }
  }
};
</script>

<style scoped>
.textinput_wrapper {
  padding-top: 1rem;
}

.askform_container {
  margin: auto;
}

.askform_text {
  margin-bottom: 1rem;
}

.askform_button {
  margin-bottom: 1rem;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.response_wrapper {
  padding-top: 1rem;
  overflow-y: auto; /* Enable vertical scrolling */
}

.container {
  max-width: 800px;
}

.relevance {
  display: none;
}

.relevance.visible {
  display: block;
}

.feedback {
  margin-top: 1rem;
}

.feedback-form {
  margin-top: 1rem;
}

.feedbackGiven {
  background-color: #c8d2ea; /* Add your desired background color */
}
</style>
