import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

/**
 * Signin
 * @param {*} poolData
 * @param {{username, password}} body
 * @returns {Promise<Object>} Authentication data or error
 */
const signin = async (poolData, body) => {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool(poolData);
    const { username, password } = body;

    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res) => {
        
        cognitoUser.getUserAttributes((err, attributes) => {
          const userAttributes = {};
          if (err) {
            reject(err);
          } else {
            
            attributes.forEach(attribute => {
              userAttributes[attribute.Name] = attribute.Value;
            });
            
          }
          const data = {
            refreshToken: res.getRefreshToken().getToken(),
            accessToken: res.getAccessToken().getJwtToken(),
            accessTokenExpiresAt: res.getAccessToken().getExpiration(),
            idToken: res.getIdToken().getJwtToken(),
            idTokenExpiresAt: res.getIdToken().getExpiration(),
            userAttributes: userAttributes
          };
          resolve(data);
        });
       
      },
      onFailure: (err) => {
        reject(err);
      },
      mfaRequired: (codeDeliveryDetails) => {
        const data = {
          nextStep: 'MFA_AUTH',
          loginSession: cognitoUser.Session,
          codeDeliveryDetails: codeDeliveryDetails,
        };
        resolve(data);
      },
      totpRequired: (secretCode) => {
        const data = {
          nextStep: 'SOFTWARE_TOKEN_MFA',
          loginSession: cognitoUser.Session,
          secretCode: secretCode,
        };
        resolve(data);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        const data = {
          nextStep: 'NEW_PASSWORD_REQUIRED',
          loginSession: cognitoUser.Session,
          userAttributes: userAttributes,
          requiredAttributes: requiredAttributes,
        };
        resolve(data);
      },
    });
  });
};

export default signin;
