
<script setup>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { signup } from '@/controllers';

const schema = Yup.object().shape({
username: Yup.string().email('Invalid email address').required('Username is required'),
password: Yup.string().min(8, 'Password must be at least 8 characters long')
  .matches(/[0-9]/, 'Password must contain at least one number')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .matches(/[a-z]/, 'Password must contain at least one lowercase letter').required('Password is required'),
firstname: Yup.string().required('First Name is required'),
lastname: Yup.string().required('Last nName is required')
});

function onSubmit(values, { setErrors }) {

const { username, password, firstname, lastname } = values;

return signup(username, password, firstname, lastname)
    .catch(error => setErrors({ apiError: error }));
}
</script>
<template>
  <div class="container">
      <h2>Signup</h2>
      <div>
        All new accounts need to be confirmed by an Administrator.  This can take up to 48 hours.
      </div>
      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
          <div class="form-group">
              <label>Username/Email</label>
              <Field name="username" type="text" class="form-control" :class="{ 'is-invalid': errors.username }" />
              <div class="invalid-feedback">{{errors.username}}</div>
          </div>            
          <div class="form-group">
              <label>Password</label>
              <Field name="password" type="password" class="form-control" :class="{ 'is-invalid': errors.password }" />
              <div class="invalid-feedback">{{errors.password}}</div>
          </div>    
          <div class="form-group">
              <label>First Name</label>
              <Field name="firstname" type="text" class="form-control" :class="{ 'is-invalid': errors.firstname }" />
              <div class="invalid-feedback">{{errors.firstname}}</div>
          </div>  
          <div class="form-group">
              <label>Last Name</label>
              <Field name="lastname" type="text" class="form-control" :class="{ 'is-invalid': errors.lastname }" />
              <div class="invalid-feedback">{{errors.lastname}}</div>
          </div>          
          <div class="form-group">
              <button class="btn btn-primary mt-3" :disabled="isSubmitting">
                  <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
                  Sign Up
              </button>
          </div>
          <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
      </Form>
  </div>
</template>
  
  <style scoped>
  * 
  #error {
    color: red;
  }
  </style>