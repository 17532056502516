import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoIdToken
} from 'amazon-cognito-identity-js';
import { useAuthStore } from '@/store';

export const validateToken = async() => {
    return new Promise((resolve, reject) => {
        const auth = useAuthStore();
        const data = JSON.parse(auth.data);
        var CognitoRefreshToken = require('amazon-cognito-identity-js').CognitoRefreshToken;
        var token = new CognitoRefreshToken({ RefreshToken: data.refreshToken })
        const AccessToken = new CognitoAccessToken({ AccessToken: data.accessToken });
        const IdToken = new CognitoIdToken({ IdToken: data.idToken });

        const sessionData = {
            IdToken: IdToken,
            AccessToken: AccessToken,
            RefreshToken: token
        };
        const cachedSession = new CognitoUserSession(sessionData);

        if (cachedSession.isValid) {
            resolve(true);
        }
        reject(false);
    });

}
const refresh = async (poolData,user,authData) => {
    return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool(poolData);
        
        const data = JSON.parse(authData);
    
        var CognitoRefreshToken = require('amazon-cognito-identity-js').CognitoRefreshToken;
        var token = new CognitoRefreshToken({ RefreshToken: data.refreshToken })
        const AccessToken = new CognitoAccessToken({ AccessToken: data.accessToken });
        const IdToken = new CognitoIdToken({ IdToken: data.idToken });

        const sessionData = {
            IdToken: IdToken,
            AccessToken: AccessToken,
            RefreshToken: token
        };
        const cachedSession = new CognitoUserSession(sessionData);
        const userData = {
            Username: user,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
    
        if (!cachedSession.isValid()) {
            cognitoUser.refreshSession(token, {
                onSuccess: (res) => {
                    const data = {
                        refreshToken: res.getRefreshToken().getToken(),
                        accessToken: res.getAccessToken().getJwtToken(),
                        accessTokenExpiresAt: res.getAccessToken().getExpiration(),
                        idToken: res.getIdToken().getJwtToken(),
                        idTokenExpiresAt: res.getIdToken().getExpiration(),
                    };
                    console.log(data);
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                },
                mfaRequired: (codeDeliveryDetails) => {
                    const data = {
                        nextStep: 'MFA_AUTH',
                        loginSession: cognitoUser.Session,
                        codeDeliveryDetails: codeDeliveryDetails,
                    };
                    resolve(data);
                },
                totpRequired: (secretCode) => {
                    const data = {
                        nextStep: 'SOFTWARE_TOKEN_MFA',
                        loginSession: cognitoUser.Session,
                        secretCode: secretCode,
                    };
                    resolve(data);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    const data = {
                        nextStep: 'NEW_PASSWORD_REQUIRED',
                        loginSession: cognitoUser.Session,
                        userAttributes: userAttributes,
                        requiredAttributes: requiredAttributes,
                    };
                    resolve(data);
                },
            });
        } else {
            console.log(data);
            resolve(data);
        }
    });

};

export default refresh;
